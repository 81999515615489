<template>
  <sa-page-layout :titolo="titolo" :showModalLoading="showModalLoading" :pathResource="pathResource" :btnSaveVisible="btnSaveVisible" :data="jsonData" :linkback="linkback" :linkSaveAndClose="linkSaveAndClose" :btnBackVisible="true" class="sa-no-space">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblDateDischarge") }}</label>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" type="date" v-model="jsonData.dataDimissioni" :disabled="!editSezioneClinica"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblDischargeDiagnosis") }}</label>
              <b-form-textarea v-model="jsonData.diagnosiDimissioni" no-resize rows="6" :disabled="!editSezioneClinica"></b-form-textarea>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <h4>{{ getLabelTraduora("adi.lblReference") }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblFamilyReference") }}</label>
              <b-form-input v-model="jsonData.familiareRiferimento" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblfamilyPhone") }}</label>
              <b-form-input v-model="jsonData.telefonoFamiliare" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('adi.lblUOdoctor')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblUOdoctor") }}</label>
              <b-form-input v-model="jsonData.medicoUnitaOperativa" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblUOdoctorPhone") }}</label>
              <b-form-input v-model="jsonData.telefonoMedicoUnitaOperativa" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('adi.lblCoordinatorUO')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblCoordinatorUO") }}</label>
              <b-form-input v-model="jsonData.cordinatoreUnitaOperativa" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblCoordinatorUoPhone") }}</label>
              <b-form-input v-model="jsonData.telefonoCoordinatoreUnitaOperativa" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('adi.ClinicalCareData')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblPerceptionOfReality") }}</label>
              <b-form-select v-model="jsonData.percezioneRealta" :options="percezioniRealtaOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblBreathing") }}</label>
              <b-form-select v-model="jsonData.respirazione" :options="respirazioniOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblFeed") }}</label>
              <b-form-select v-model="jsonData.alimentazione" :options="alimentazioniOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblintestinalElimination") }}</label>
              <b-form-select v-model="jsonData.eliminazioneeIntestinale" :options="elimenazioniIntestinaleOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
              <b-row v-if="jsonData.eliminazioneeIntestinale === 'Stomia di tipo'">
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("adi.lblTypology") }}</label>
                  <b-form-input v-model="jsonData.tipoStomia" :disabled="!editSezioneClinica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("adi.lblFrequencyManagement") }}</label>
                  <b-form-input v-model="jsonData.frequenzaGestione" :disabled="!editSezioneClinica"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('adi.lblVesicalCatheter')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblUrinaryElimination") }}</label>
              <b-form-select v-model="jsonData.eliminazioneUrinaria" :options="eliminazioniUrinarieOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblTypology") }}</label>
              <b-form-input v-model="jsonData.tipologia" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblCaliber") }}</label>
              <b-form-input v-model="jsonData.calibro" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblReplacementDate") }}</label>
              <date-picker v-model="jsonData.dataSostituzione" format="DD-MM-YYYY" value-type="timestamp" :disabled="!editSezioneClinica"></date-picker>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblMobilization") }}</label>
              <b-form-select v-model="jsonData.mobilizzazione" :options="mobilizzazioniOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblDressed") }}</label>
              <b-form-select v-model="jsonData.autosufficienzaVestirsiSpogliarsi" :options="autosufficienzaOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
              <b-row v-if="jsonData.autosufficienzaVestirsiSpogliarsi === 'Non autosufficiente e aiutato da'">
                <b-col>
                  <label class="sa-label-data">{{ getLabelTraduora("adi.lblFirstName") }}</label>
                  <b-form-input v-model="jsonData.aiutante" :disabled="!editSezioneClinica"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblPersonalHygiene") }}</label>
              <b-form-select v-model="jsonData.igienePersonale" :options="igienePersonaleOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
              <b-row v-if="jsonData.igienePersonale === 'Si'">
                <b-col>
                  <label class="sa-label-data">{{ getLabelTraduora("adi.lblFirstName") }}</label>
                  <b-form-input v-model="jsonData.nomeIgienePersonale" :disabled="!editSezioneClinica"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblRestSleep") }}</label>
              <b-form-select v-model="jsonData.riposoSonno" :options="riposoSonnoOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblComunication") }}</label>
              <b-form-select v-model="jsonData.comunicazione" :options="comunicazioneOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblSkinLesions") }}</label>
              <b-form-select v-model="jsonData.lesioniCutanee" :options="lesioniCutaneeOptions" :value="null" value-field="value" text-field="text" :disabled="!editSezioneClinica"></b-form-select>
            </b-col>

            <template v-if="jsonData.lesioniCutanee === 'Si'">
              <b-table sticky-header ref="tblLesioniCutanee" stacked="xl" striped hover itemscope :items="jsonData.adiSezioneClinicaLesioneCutaneaDettaglioBeans" :fields="fieldsLesioniCutanee" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="item">
                  {{ item.index + 1 }}
                </template>
                <template #cell(tipoLesione)="item">
                  <b-form-input v-model="item.item.tipoLesione" :disabled="!editSezioneClinica"></b-form-input>
                </template>
                <template #cell(sedeLesione)="item">
                  <b-form-input v-model="item.item.sedeLesione" :disabled="!editSezioneClinica"></b-form-input>
                </template>
                <template #cell(medicazione)="item">
                  <b-form-input v-model="item.item.medicazione" :disabled="!editSezioneClinica"></b-form-input>
                </template>
                <template #cell(actions)="row">
                  <b-button v-if="row.index == jsonData.adiSezioneClinicaLesioneCutaneaDettaglioBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddLesioni(jsonData.adiSezioneClinicaLesioneCutaneaDettaglioBeans, row.item)" :disabled="!editSezioneClinica">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                  <b-button v-if="row.index !== jsonData.adiSezioneClinicaLesioneCutaneaDettaglioBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.adiSezioneClinicaLesioneCutaneaDettaglioBeans, row)" :disabled="!editSezioneClinica">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </template>
          </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('adi.lblPainTherapy')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-table sticky-header ref="tblTerapie" stacked="xl" striped hover itemscope :items="jsonData.adiSezioneClinicaTerapiaBeans" :fields="fieldsTerapia" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" :disabled="!editSezioneClinica">
              <template #cell(index)="jsonData">
                {{ jsonData.index + 1 }}
              </template>
              <template #cell(farmaco)="item">
                <b-form-input v-model="item.item.farmaco" :disabled="!editSezioneClinica"></b-form-input>
              </template>
              <template #cell(posologia)="item">
                <b-form-input v-model="item.item.posologia" :disabled="!editSezioneClinica"></b-form-input>
              </template>
              <template #cell(viaSomministrazione)="item">
                <b-form-input v-model="item.item.viaSomministrazione" :disabled="!editSezioneClinica"></b-form-input>
              </template>
              <template #cell(actions)="row">
                <b-button v-if="row.index == jsonData.adiSezioneClinicaTerapiaBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddTerapia(jsonData.adiSezioneClinicaTerapiaBeans, row.item)" :disabled="!editSezioneClinica">
                  <b-icon icon="plus"></b-icon>
                </b-button>
                <b-button v-if="row.index !== jsonData.adiSezioneClinicaTerapiaBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.adiSezioneClinicaTerapiaBeans, row)" :disabled="!editSezioneClinica">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblNecessityOfPrincipals") }}</label>
              <b-form-select v-model="jsonData.necessitaPresidiSanitari" :disabled="!editSezioneClinica" :value="null" value-field="value" text-field="text" :options="necessitaPresidiOptions"></b-form-select>
              <b-row v-if="jsonData.necessitaPresidiSanitari === 'Si'">
                <b-col>
                  <label class="sa-label-data">{{ getLabelTraduora("adi.lblTypology") }}</label>
                  <b-form-input v-model="jsonData.tipologiaPresidi" :disabled="!editSezioneClinica"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblVenousCatheterWearer") }}</label>
              <b-form-select v-model="jsonData.portatoreCatetere" :disabled="!editSezioneClinica" :options="catetereVenosoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
              <b-row v-if="jsonData.portatoreCatetere === 'Si'">
                <b-col>
                  <label class="sa-label-data">{{ getLabelTraduora("adi.lblTypology") }}</label>
                  <b-form-input v-model="jsonData.tipologiaCatetere" :disabled="!editSezioneClinica"></b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblMedicationAsProtocol") }}</label>
              <b-form-select v-model="jsonData.medicazioneComeProtocollo" :disabled="!editSezioneClinica" :options="protocolloMedicazioneOptions" :value="null" value-field="value" text-field="text"></b-form-select>
              <!-- <b-row v-if="jsonData.medicazioneComeProtocollo === 'NO'">
                            <b-col>
                                <label class="sa-label-data">{{ getLabelTraduora("adi.lblProduct") }}</label>
                                <b-form-input v-model="jsonData.prodotto"></b-form-input>
                            </b-col>
                            <b-col>
                                <label class="sa-label-data">{{ getLabelTraduora("adi.lblFrequency") }}</label>
                                <b-form-input v-model="jsonData.frequenza"></b-form-input>
                            </b-col>
                        </b-row> -->
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblAwareness") }}</label>
              <b-form-select v-model="jsonData.consapevolezzaPatologia" :disabled="!editSezioneClinica" :value="null" value-field="value" text-field="text" :options="consapevolezzaPatologiaOptions"></b-form-select>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('adi.lblDyingPatient')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <h4>{{ getLabelTraduora("adi.lblDyingPatient") }}</h4>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblPalliativeCare") }}</label>
              <b-form-select v-model="jsonData.pazienteNecessitaCurePalliative" :disabled="!editSezioneClinica" :value="null" value-field="value" text-field="text" :options="curePalliativeOptions"></b-form-select>
            </b-col>
          </b-row>
          <b-row v-if="jsonData.pazienteNecessitaCurePalliative === 'Si'">
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblIndexKarnofsky") }}</label>
              <b-form-input v-model="jsonData.indiceKarnofsky" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblPainScale") }}</label>
              <b-form-select v-model="jsonData.scalaDolore" :value="null" :disabled="!editSezioneClinica" value-field="value" text-field="text" :options="scalaDoloreOptions"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblSettingType") }}</label>
              <b-form-select v-model="jsonData.tipologiaSettingAssistenziale" :disabled="!editSezioneClinica" :value="null" value-field="value" text-field="text" :options="tipologiaAssistenzialeSettingOptions"></b-form-select>
            </b-col>
            <b-col cols="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblNote") }}</label>
              <b-form-textarea v-model="jsonData.note" rows="6" no-resize :disabled="!editSezioneClinica"></b-form-textarea>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('adi.lblNotDyingPatient')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col>
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblHospitalizationRegime") }}</label>
              <b-form-select v-model="jsonData.regimeRicovero" :value="null" :disabled="!editSezioneClinica" value-field="value" text-field="text" :options="regimiRicoveriOptions"></b-form-select>
              <!-- <b-row v-if="jsonData.regimeRicovero === 'altro'">
                            <b-col>
                                <label class="sa-label-data">{{ getLabelTraduora("adi.lblTypology") }}</label>
                                <b-form-input v-model="jsonData.altroRegimeRicovero"></b-form-input>
                            </b-col>
                        </b-row> -->
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('adi.lblDischarge')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="4" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblRequestedDate") }}</label>
              <date-picker v-model="jsonData.dataCompilazioneModulo" :disabled="!editSezioneClinica" format="DD-MM-YYYY" value-type="timestamp" type="date"></date-picker>
            </b-col>
            <b-col xs="12" sm="4" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblOperator") }}</label>
              <b-form-input v-model="jsonData.operatore" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
            <b-col xs="12" sm="4" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblMobilePhone") }}</label>
              <b-form-input v-model="jsonData.telefono" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblHeadNurse") }}</label>
              <b-form-input v-model="jsonData.caposala" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblReferalDoctor") }}</label>
              <b-form-input v-model="jsonData.medicoRiferimento" :disabled="!editSezioneClinica"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout, DatePicker },
  data() {
    return {
      pathResource: "/adisezionicliniche",
      sezioneSezioneClinica: "adidimissioniprotette",
      btnSaveVisible: true,
      editSezioneClinica: true,
      linkback: null,
      linkSaveAndClose: null,
      showModalLoading: false,
      currentPage: 1,
      perPage: 100,
      titolo: this.getLabelTraduora("adi.lblClinicalSection"),
      linkPercezioneRealta: "/adisezioniclinichepercezionerealta",
      linkRespirazione: "/adisezioniclinicherespirazione",
      linkAlimentazioni: "/adisezioniclinichealimentazione",
      linkEliminazioniIntesitnali: "/adisezioniclinicheeliminazioneintestinale",
      linkEliminazioniUrinarie: "/adisezioniclinicheeliminazioneurinaria",
      linkMobilizzazioni: "/adisezioniclinichemobilizzazione",
      linkRiposoSonno: "/adisezioniclinicheripososonno",
      linkAutosufficienza: "/adisezioniclinicheautosufficienza",
      linkIgienePersonale: "/adisezioniclinicheigienepersonale",
      linkComunicazione: "/adisezioniclinichecomunicazione",
      linkLesioniCutanee: "/adisezioniclinichelesionicutanee",
      linkNecessitaPresidi: "/adisezioniclinichenecessitapresidisanitari",
      linkCatetereVenoso: "/adisezioniclinicheportatorecatetere",
      linkMedicazione: "/adisezioniclinichemedicazionicomeprotocollo",
      linkConsapevolezzaPatologia: "/adisezioniclinicheconsapevolezzapatologie",
      linkCurePalliative: "/adisezioniclinichenecessitacurepalliative",
      linkScalaDolore: "/adisezioniclinichescaladolore",
      linkTipologiaAssistenzialeSetting: "/adisezioniclinichesettingassistenziale",
      linkRegimeRicovero: "/adisezioniclinicheregimericovero",
      jsonData: {
        id: null,
        diagnosiDimissioni: "",
        dataDimissioni: null,
        familiareRiferimento: "",
        telefonoFamiliare: "",
        medicoUnitaOperativa: "",
        telefonoMedicoUnitaOperativa: "",
        cordinatoreUnitaOperativa: "",
        telefonoCoordinatoreUnitaOperativa: "",
        percezioneRealta: null,
        respirazione: null,
        alimentazione: null,
        eliminazioneeIntestinale: null,
        tipoStomia: "",
        frequenzaGestione: "",
        eliminazioneUrinaria: null,
        tipologia: "",
        calibro: "",
        dataSostituzione: null,
        mobilizzazione: null,
        riposoSonno: null,
        autosufficienzaVestirsiSpogliarsi: null,
        igienePersonale: null,
        nomeIgienePersonale: "",
        comunicazione: null,
        lesioniCutanee: null,
        necessitaPresidiSanitari: null,
        tipologiaPresidi: "",
        portatoreCatetere: null,
        tipologiaCatetere: "",
        medicazioneComeProtocollo: null,
        consapevolezzaPatologia: null,
        pazienteNecessitaCurePalliative: null,
        indiceKarnofsky: "",
        scalaDolore: null,
        tipologiaSettingAssistenziale: null,
        note: "",
        regimeRicovero: null,
        dataCompilazioneModulo: null,
        operatore: "",
        telefono: "",
        caposala: "",
        medicoRiferimento: "",
        adiSezioneClinicaTerapiaBeans: [{ farmaco: "", posologia: "", viaSomministrazione: "" }],
        adiSezioneClinicaLesioneCutaneaDettaglioBeans: [],
      },
      percezioniRealtaOptions: [],
      respirazioniOptions: [],
      alimentazioniOptions: [],
      elimenazioniIntestinaleOptions: [],
      eliminazioniUrinarieOptions: [],
      mobilizzazioniOptions: [],
      riposoSonnoOptions: [],
      autosufficienzaOptions: [],
      igienePersonaleOptions: [],
      comunicazioneOptions: [],
      lesioniCutaneeOptions: [],
      necessitaPresidiOptions: [],
      catetereVenosoOptions: [],
      protocolloMedicazioneOptions: [],
      consapevolezzaPatologiaOptions: [],
      curePalliativeOptions: [],
      scalaDoloreOptions: [],
      tipologiaAssistenzialeSettingOptions: [],
      regimiRicoveriOptions: [],
      fieldsLesioniCutanee: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo Lesione",
          key: "tipoLesione",
          sortable: false,
        },
        {
          label: "Sede Lesione",
          key: "sedeLesione",
          sortable: false,
          thStyle: "width: 15rem",
        },
        {
          label: "Medicazione",
          key: "medicazione",
          sortable: false,
          thStyle: "width: 15rem",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsTerapia: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Farmaco",
          key: "farmaco",
          sortable: false,
        },
        {
          label: "Posologia",
          key: "posologia",
          sortable: false,
          thStyle: "width: 15rem",
        },
        {
          label: "Via Somministrazione",
          key: "viaSomministrazione",
          sortable: false,
          thStyle: "width: 15rem",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  computed: {},
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.id = this.$route.params.id;
    me.linkback = "/adi/dimissioniprotette/edit/" + me.id;
    me.linkSaveAndClose = "/adi";
    me.loadDefaultData();
    me.impostaPermessi();
    me.loadData();
  },
  watch: {
    "jsonData.lesioniCutanee": function (value) {
      let me = this;
      if (value === "Si") {
        me.jsonData.adiSezioneClinicaLesioneCutaneaDettaglioBeans.push({ tipoLesione: "", sedeLesione: "", medicazione: "" });
      } else {
        me.jsonData.adiSezioneClinicaLesioneCutaneaDettaglioBeans = [];
      }
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      me.editSezioneClinica = UtilityMixin.methods.verificaPermessi(me.utente, me.sezioneSezioneClinica, 2) || UtilityMixin.methods.verificaPermessi(me.utente, me.sezioneSezioneClinica, 4);
      me.btnSaveVisible = UtilityMixin.methods.verificaPermessi(me.utente, me.sezioneSezioneClinica, 2) || UtilityMixin.methods.verificaPermessi(me.utente, me.sezioneSezioneClinica, 4);
    },
    loadDefaultData() {
      let me = this;
      me.loadPercezioniRealta();
      me.loadRespirazione();
      me.loadAlimentazioni();
      me.loadEliminazioniIntestinali();
      me.loadEliminazioniUrinarie();
      me.loadMobilizzazioni();
      me.loadRiposoSonno();
      me.loadAutosufficienza();
      me.loadIgienePersonale();
      me.loadComunicazione();
      me.loadLesioniCutanee();
      me.loadNecessitaPresidi();
      me.loadCatetereVenoso();
      me.loadProtocolloMedicazione();
      me.loadConsapevolezzaPatologia();
      me.loadCurePalliative();
      me.loadRegimeRicovero();
      me.loadScalaDolore();
      me.loadTipologiaAssistenzialeSetting();
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
          })
          .catch((error) => {
            this.$bvModal
              .msgBoxOk(link + " \n" + error.message, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
    loadPercezioniRealta() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkPercezioneRealta + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.percezioniRealtaOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.percezioniRealtaOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadRespirazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkRespirazione + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.respirazioniOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.respirazioniOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadAlimentazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkAlimentazioni + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.alimentazioniOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.alimentazioniOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadEliminazioniIntestinali() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkEliminazioniIntesitnali + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.elimenazioniIntestinaleOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.elimenazioniIntestinaleOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadEliminazioniUrinarie() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkEliminazioniUrinarie + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.eliminazioniUrinarieOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.eliminazioniUrinarieOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadMobilizzazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkMobilizzazioni + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.mobilizzazioniOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.mobilizzazioniOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadRiposoSonno() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkRiposoSonno + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.riposoSonnoOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.riposoSonnoOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadAutosufficienza() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkAutosufficienza + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.autosufficienzaOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.autosufficienzaOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadIgienePersonale() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkIgienePersonale + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.igienePersonaleOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.igienePersonaleOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadComunicazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComunicazione + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.comunicazioneOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.comunicazioneOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadLesioniCutanee() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkLesioniCutanee + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.lesioniCutaneeOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.lesioniCutaneeOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadNecessitaPresidi() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkNecessitaPresidi + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.necessitaPresidiOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.necessitaPresidiOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadCatetereVenoso() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkCatetereVenoso + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.catetereVenosoOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.catetereVenosoOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadProtocolloMedicazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkMedicazione + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.protocolloMedicazioneOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.protocolloMedicazioneOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadConsapevolezzaPatologia() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkConsapevolezzaPatologia + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.consapevolezzaPatologiaOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.consapevolezzaPatologiaOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadCurePalliative() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkCurePalliative + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.curePalliativeOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.curePalliativeOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadScalaDolore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkScalaDolore + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.scalaDoloreOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.scalaDoloreOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadTipologiaAssistenzialeSetting() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkTipologiaAssistenzialeSetting + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.tipologiaAssistenzialeSettingOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.tipologiaAssistenzialeSettingOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadRegimeRicovero() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkRegimeRicovero + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.regimiRicoveriOptions.push({ value: element.descrizione, text: element.descrizione });
          });
          me.regimiRicoveriOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onAddLesioni(array, value) {
      if (value.tipoLesione != "" && value.sedeLesione != "" && value.medicazione != "") {
        array.push({ tipoLesione: "", sedeLesione: "", medicazione: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono OBBLIGATORI!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddTerapia(array, value) {
      if (value.farmaco != "" && value.posologia != "" && value.viaSomministrazione != "") {
        array.push({ farmaco: "", posologia: "", viaSomministrazione: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono OBBLIGATORI!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        array.splice(row.index, 1);
      }
    },
  },
};
</script>
