<template>
    <div class="mb-5">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblTrofism") }}</label>
                    <b-form-input v-model="data.trofismo" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblFlanks") }}
                        <eventi-storico-misure-component misura="corpo" misuraLabel="Fianchi" unitaMisura="cm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.corpo" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblWaist") }}
                        <eventi-storico-misure-component misura="vita" misuraLabel="Vita" unitaMisura="cm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.vita" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblWeight") }}
                        <eventi-storico-misure-component misura="peso" misuraLabel="Peso" unitaMisura="Kg" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.peso" :disabled="!edit" @input="onCalcoloBmiBsa(data.altezza, data.peso)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblHeight") }}
                        <eventi-storico-misure-component misura="altezza" misuraLabel="Altezza" unitaMisura="cm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.altezza" :disabled="!edit" @input="onCalcoloBmiBsa(data.altezza, data.peso)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblBMI") }}
                        <eventi-storico-misure-component misura="bmi" misuraLabel="BMI" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.bmi" :disabled="true"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data mr-1">
                        {{ getLabelTraduora("patient.afa.lblBSA") }}
                        <eventi-storico-misure-component misura="bsa" misuraLabel="BSA" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.bsa" :disabled="true"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblGlycemia") }}
                        <eventi-storico-misure-component misura="glicemia" misuraLabel="Glicemia" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.glicemia" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblPAMax") }}
                        <eventi-storico-misure-component misura="pa_max" misuraLabel="Pa Max" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.paMax" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblPAMin") }}
                        <eventi-storico-misure-component misura="pa_min" misuraLabel="Pa Min" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.paMin" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblCholesterol") }}</label>
                    <b-form-input v-model="data.colesterolo" :disabled="!edit" type="number"></b-form-input>
                    <!-- <b-form-select v-model="data.colesterolo" :disabled="!edit" :options="selectOption" value-field="value" text-field="text">
          <template #first>
            <b-form-select-option :value="null" disabled>{{ firstElementForOptions }}</b-form-select-option>
          </template>
        </b-form-select> -->
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblDiabetes") }}</label>
                    <b-form-select v-model="data.diabete" :disabled="!edit" :value="null" :options="selectOption" value-field="value" text-field="text">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ firstElementForOptions }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patients.afa.lblBloodGroupRHFactor") }}</label>
                    <b-form-input v-model="gruppoSanguignoTraduora" :disabled="true"></b-form-input>
                </b-col>
                <!-- <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblRHFactor") }}</label>
        <b-form-input v-model="data.fattoreRh" :disabled="!edit"></b-form-input>
      </b-col> -->

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblCirculatorySystem") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoCardiocircolatorio" :disabled="!edit"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblRespiratorySystem") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoRespiratorio" :disabled="!edit"></b-form-textarea>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblLocomotorSystem") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.apparatoLocomotore" :disabled="!edit"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblAbdomenGeneralOrgans") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.addomeOrgGen" :disabled="!edit"></b-form-textarea>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblLimbs") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.arti" :disabled="!edit"></b-form-textarea>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblNaturalSightRightEye") }}
                        <eventi-storico-misure-component misura="vista_n_od" misuraLabel="Vista Naturale Occhio Destro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.vistaNOd" :disabled="!edit" step="0.05"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblNaturalSightLeftEye") }}
                        <eventi-storico-misure-component misura="vista_n_os" misuraLabel="Vista Naturale Occhio Sinistro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.vistaNOs" :disabled="!edit" step="0.05"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCurrentSightRightEye") }}
                        <eventi-storico-misure-component misura="vista_c_od" misuraLabel="Vista Corrente Occhio Destro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.vistaCOd" :disabled="!edit" step="0.05"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCurrentSightLeftEye") }}
                        <eventi-storico-misure-component misura="vista_c_os" misuraLabel="Vista Corrente Occhio Sinistro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.vistaCOs" :disabled="!edit" step="0.05"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblCromaticSense") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.sensoCromatico" :disabled="!edit"></b-form-textarea>
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHearingSense") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.udito" :disabled="!edit"></b-form-textarea>
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblExaminationConclusion") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.conclusioniEsObiettivo" :disabled="!edit"></b-form-textarea>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("therapy.lblStatus") }}</label>
                    <br />
                    <span class="sa-data">{{ data.firmaEo }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
//import axios from "axios";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
//import moment from "moment";
export default {
    //components: { DatePicker },
    mixins: [UtilityMixin],
    props: {
        tipoEvento: { type: String, require: true },
        gruppo: { type: String, require: true },
        edit: { type: Boolean, require: true },
        data: { type: Object, require: true },
    },
    components: { EventiStoricoMisureComponent },

    data() {
        return {
            gruppoSanguignoTraduora: this.$i18n.t(this.data.gruppoSanguigno),
            firstElementForOptions: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            selectOption: [
                { text: this.getLabelTraduora("patient.gastro.Options.No"), value: this.getLabelTraduora("patient.gastro.Options.No") },
                { text: this.getLabelTraduora("patient.gastro.Options.Yes"), value: this.getLabelTraduora("patient.gastro.Options.Yes") },
            ],
        };
    },
    computed: {
        idPaziente() {
            let me = this;
            // console.log(me.data.idPaziente);
            return me.data.idPaziente;
        },
    },
    methods: {
        onCalcoloBmiBsa(altezza, peso) {
            let me = this;
            me.data.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
            me.data.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
        },

        formattGruppoSanguigno(value) {
            this.getLabelTraduora(value);
        },
    },
};
</script>
