<template>
    <sa-page-layout :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource">
        <template slot="toolbar">
            <b-button variant="success btn-toolbar sa-margin-left float-sm-left" size="sm" @click="onStart" :disabled="btnStartDisabled">
                <b-icon icon="caret-right-fill"></b-icon>
                Start
            </b-button>
            <b-button variant="danger btn-toolbar sa-margin-left float-sm-left" size="sm" @click="onStop">
                <b-icon icon="stop-fill"></b-icon>
                Stop
            </b-button>
        </template>
        <template slot="table-filter">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Stato:</label>
                    <span :class="setColorStato(jsonData.statoServer) + ' sa-data'">{{ jsonData.statoServer }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Porta:</label>
                    <span class="sa-data">{{ jsonData.port === 0 ? "-" : jsonData.port }}</span>
                </b-col>
            </b-row>
            <b-row v-if="!errorMessageDisabled">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Error Message:</label>
                    <b-alert show variant="danger">{{ jsonData.errorMessage }}</b-alert>
                    <!-- <span class="sa-data">{{ jsonData.errorMessage }}</span> -->
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" v-model="tabIndex">
                <b-tab active>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>Stato Attuale</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <div class="b-table-sticky-header">
                            <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="jsonData.threadsInfo" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                                <template v-slot:cell(nominativo)="{ item }">
                                    <router-link class="sa-edit-link" :to="'/paziente/view/' + item.id">{{ item.cognome }} {{ item.nome }}</router-link>
                                    <br />
                                    {{ item.identificativo }}
                                </template>
                            </b-table>
                        </div>
                        <hr />
                    </div>
                </b-tab>
                <b-tab active>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>Storico Messaggi</span>
                            </div>
                        </div>
                    </template>
                    <div class="b-table-sticky-header">
                        <b-table sticky-header ref="table" :filter="filtroStorico" stacked="lg" striped hover :items="jsonDataStorico" :fields="fieldsStorico" :per-page="perPageStorico" :current-page="1" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(nominativo)="{ item }">
                                <router-link class="sa-edit-link" :to="'/paziente/view/' + item.id">{{ item.cognome }} {{ item.nome }}</router-link>
                                <br />
                                {{ item.identificativo }}
                            </template>
                            <template #cell(actions)="row">
                                <b-row>
                                    <!-- <b-button variant="btn btn-outline-info waves-effect waves-light width-sm no-text" style="margin-right: 3px" @click="onShareItem(row.item)" size="sm"><b-icon icon="share"></b-icon></b-button> -->

                                    <b-button variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
                                </b-row>
                            </template>
                        </b-table>
                    </div>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            perPageStorico: 100,
            currentPageStorico: 1,
            tabIndex: 1,
            errorMessageDisabled: true,
            btnStartDisabled: false,
            pathResource: "/hl7integratormllpserver",
            pathResourceStorico: "/hl7integrator",
            jsonData: {
                statoServer: "",
                port: "",
                errorMessage: "",
                threadsInfo: [],
            },
            jsonDataStorico: [],
            filter: {},
            filtro: {},
            filtroStorico: {},
            fieldsStorico: [
                {
                    label: "Id",
                    key: "idMessage",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "Data Ora Messaggio",
                    key: "dataOraMessaggio",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Tipo",
                    key: "tipo",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Messaggio",
                    key: "message",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 7rem" },
            ],
            fields: [
                {
                    label: "IP",
                    key: "ip",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "Data Ora Start",
                    key: "connectionStartTime",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Data Ora Stop",
                    key: "connectionStopTime",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Messaggio",
                    key: "connectionMessage",

                    sortable: true,
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadData();
        me.loadDataStorico();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/info";
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.verificaStato(me.jsonData.statoServer);
                    me.verificaErrorMessage(me.jsonData.errorMessage);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadDataStorico() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStorico;
            me.showModalLoading = true;
            me.filtroStorico.page = me.currentPageStorico;
            me.filtroStorico.forPage = me.perPageStorico;
            axios
                .get(link, { params: me.filtroStorico })
                .then((response) => {
                    me.jsonDataStorico = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        verificaStato(stato) {
            let me = this;
            if (stato === "AVVIATO") {
                me.btnStartDisabled = true;
            } else {
                me.btnStartDisabled = false;
            }
        },
        verificaErrorMessage(errorMessage) {
            let me = this;
            if (errorMessage !== "") {
                me.errorMessageDisabled = false;
            } else {
                me.errorMessageDisabled = true;
            }
        },
        setColorStato(value) {
            let color = value == "AVVIATO" ? "text-success" : "text-danger";
            return color;
        },
        onStart() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/start";
            axios
                .post(link)
                .then(() => {
                    me.loadData();
                })
                .catch(() => {});
        },
        onStop() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/stop";
            axios
                .post(link)
                .then(() => {
                    me.loadData();
                })
                .catch(() => {});
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
