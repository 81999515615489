<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provenienza</label>
                    <b-form-input v-model="jsonData.provenienza"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Lavaggio/Disinfezione</label>
                    <b-form-select v-model="jsonData.lavaggioDisinfezione" :options="lavaggioDisinfezioneOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Strumento</label>
                    <b-form-select v-model="jsonData.strumento" :options="strumentiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Indicazione</label>
                    <b-form-select v-model="jsonData.indicazione" :options="indicazioniOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Referto</label>
                    <b-form-textarea v-model="jsonData.referto" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Conclusioni</label>
                    <b-form-textarea v-model="jsonData.conclusioni" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <eventi-operatori-component :operatori="jsonData.listaOperatori" @update="onUpdateListaOperatori" :isEdit="isEdit"></eventi-operatori-component>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
    components: { DatePicker, EventiOperatoriComponent },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        me.loadStrumenti();
        me.loadLavaggioDisinfezione();
        me.loadIndicazione();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            isEdit: true,
            pathResource: "/gastropancolonscopia",
            pathResourceStrumenti: "/pazienteeventistrumenti",
            pathResourceIndicazioni: "/gastroindicazioni",
            pathResourceLavaggioDisinfezione: "/gastrolavaggiodisinfezione",
            lavaggioDisinfezioneOptions: [{ value: "", text: "-Seleziona Valore-" }],
            strumentiOptions: [{ value: "", text: "-Seleziona Valore-" }],
            indicazioniOptions: [{ value: "", text: "-Seleziona Valore-" }],
            filtro: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        loadStrumenti() {
            let me = this;
            me.filtro.gruppo = "PANCOLONSCOPIA";
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrumenti;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.showModalLoading = false;
                    response.data.data.list.forEach((element) => {
                        me.strumentiOptions.push({ value: element.modello + " (MAT: " + element.codice + ")", text: element.modello + " (MAT: " + element.codice + ")" });
                    });
                })
                .catch();
        },
        loadIndicazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceIndicazioni;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        me.indicazioniOptions.push({ value: element.descrizione, text: element.descrizione });
                    });
                    me.showModalLoading = false;
                })
                .catch();
        },
        loadLavaggioDisinfezione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceLavaggioDisinfezione;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        me.lavaggioDisinfezioneOptions.push({ value: element.descrizione, text: element.descrizione });
                    });
                    me.showModalLoading = false;
                })
                .catch();
        },
        onUpdateListaOperatori(listaOperatori) {
            let me = this;
            me.jsonData.listaOperatori = listaOperatori;
        },
    },
};
</script>
