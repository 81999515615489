<template>
    <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
        <template slot="tab-content-event">
            <ecocolordoppler-aorta-addominale-view-component :idEvento="id" @update="onUpdateJsonData"></ecocolordoppler-aorta-addominale-view-component>
        </template>
    </eventi-view-component>
</template>
<script>
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixins from "../../../utility/mixins/EventiMixins";
import EcocolordopplerAortaAddominaleViewComponent from "./EcocolordopplerAortaAddominaleViewComponent.vue";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";

export default {
    mixins: [UtilityMixins, EventiMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { EcocolordopplerAortaAddominaleViewComponent, EventiViewComponent },
    data() {
        return {
            linkback: null,
            titoloEvento: "Ecocolordoppler Aorta Addominale",
            pathResource: "/cardioecocolordoppleraortaaddominale",
            pathResourceFirma: "/cardioecocolordoppleraortaaddominale",
            linkPrintData: null,
            jsonData: {},
            id: null,
            showModalLoading: false,
            fontScale: 2,
            linkedit: null,
            pathRestValidation: "/validation",
            isThisFirmato: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.id = this.$route.params.id;
    },
    methods: {
        // checkNonEmoSignOnLoad() {
        //   let me = this;
        //   if (me.jsonData.aterosclerosiNonEmoSign === "S") {
        //     me.aterosclerosiNonEmoSign = "true";
        //   } else {
        //     me.aterosclerosiNonEmoSign = "false";
        //   }
        // },
        // checkSeveraOnLoad() {
        //   let me = this;
        //   console.log(me.jsonData.aterosclerosiSevera)
        //   if (me.jsonData.aterosclerosiSevera === "S") {
        //     me.aterosclerosiSevera = "true";
        //   } else {
        //     me.aterosclerosiSevera = "false";
        //   }
        // },

        updateStatoFirma(firmato) {
            console.log(firmato);
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
        },
    },
};
</script>
