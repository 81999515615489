<template>
    <div class="sa-tab-scroll">
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblGeneralData')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                            <date-picker format="DD-MM-YYYY hh:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"> </date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFirstImplantDate") }}</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrimoImpianto" type="date" placeholder="Data"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFirstImplantDescription") }}</label>
                            <b-form-input v-model="jsonData.indicazioneImpianto" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNewImplantSubstitutionDate") }}</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataSostituzione" type="date" placeholder="Data"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantDescription") }}</label>
                            <b-form-input v-model="jsonData.descrizioneNote" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantStimulationSystem") }}</label>
                            <b-form-input v-model="jsonData.sistemaDiSistemazione" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantBaseFrequency") }}</label>
                            <b-form-input v-model="jsonData.frequenzaBase" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantModel") }}</label>
                            <b-form-input v-model="jsonData.modelloCatVentrSx" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterSerialNumber") }}</label>
                            <b-form-input v-model="jsonData.serialeIcd" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantType") }}</label>
                            <b-form-input v-model="jsonData.tipoCostrutCatVentrSx" type="text"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAtrialCatheter')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterConstructor") }}</label>
                            <b-form-input v-model="jsonData.costruttoreCatAtriale" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterModel") }}</label>
                            <b-form-input v-model="jsonData.modello1" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterImplantDate") }}</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataImpiantoAtriale" type="date" placeholder="Data"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterType") }}</label>
                            <b-form-input v-model="jsonData.tipoCostruttoreCatAtriale" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterSerialNumber") }}</label>
                            <b-form-input v-model="jsonData.serialeNumero2" type="text"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightVentricularCatheter')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterConstructor") }}</label>
                            <b-form-input v-model="jsonData.costruttore" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterModel") }}</label>
                            <b-form-input v-model="jsonData.modalita2" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterImplantDate") }}</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataParamProgrControllo2" type="date" placeholder="Data"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterType") }}</label>
                            <b-form-input v-model="jsonData.tipoCostruttore" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterSerialNumber") }}</label>
                            <b-form-input v-model="jsonData.seriale" type="text"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLeftVentricularCatheter')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterConstructor") }}</label>
                            <b-form-input v-model="jsonData.costruttoreCatVentrSx" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterModel") }}</label>
                            <b-form-input v-model="jsonData.modalita4" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterImplantDate") }}</label>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataImpiantoVentrSx" type="date" placeholder="Data"></date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterType") }}</label>
                            <b-form-input v-model="jsonData.tip2" type="text"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterSerialNumber") }}</label>
                            <b-form-input v-model="jsonData.serialeCatetVentrSx" type="text"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblElectricalParameters')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVentricularThreshold") }}</label>
                            <b-form-input v-model="jsonData.sogliaVentricolareDx" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftVentricularThreshold") }}</label>
                            <b-form-input v-model="jsonData.sogliaVentricolareSx" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialThreshold") }}</label>
                            <b-form-input v-model="jsonData.sogliaAtriale" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVentricularFlowing") }}</label>
                            <b-form-input v-model="jsonData.correnteVentricolareDx" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftVentricularFlowing") }}</label>
                            <b-form-input v-model="jsonData.correnteVentricolareSx" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialFlowing") }}</label>
                            <b-form-input v-model="jsonData.correnteAtriale" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVentricularImpedance") }}</label>
                            <b-form-input v-model="jsonData.impedenzaVentrDx2" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblleftVentricularImpedance") }}</label>
                            <b-form-input v-model="jsonData.impedenzaBatteria1" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialImpedance") }}</label>
                            <b-form-input v-model="jsonData.impedenzaBatteria3" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVentricularWaveR") }}</label>
                            <b-form-input v-model="jsonData.ondaRVentricolareDx" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftVentricularWaveR") }}</label>
                            <b-form-input v-model="jsonData.ondaRVentricolareSx" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialWaveR") }}</label>
                            <b-form-input v-model="jsonData.ondaRVentrContrSx" type="number"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblShockImpedance") }}</label>
                            <b-form-input v-model="jsonData.impedenzaShock1" type="number"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return { dataEvento: null };
            },
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            pathResource: "/cardioelettrostimolatori",
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    // me.jsonData = response.data.data;
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
