import saAside from './saAside.vue';
import saAsideBrand from './saAsideBrand.vue';
import saAsideContainer from './saAsideContainer.vue';
import saAsideNav from './saAsideNav.vue';
import saAsideNavHeader from './saAsideNavHeader.vue';
import saAsideNavItem from './saAsideNavItem.vue';
import saAsideNavItemDropdown from './saAsideNavItemDropdown.vue';
import saAsideUser from './saAsideUser.vue';
import directives from './directives';

const components = {
  saAside,
  saAsideBrand,
  saAsideContainer,
  saAsideNav,
  saAsideNavHeader,
  saAsideNavItem,
  saAsideNavItemDropdown,
  saAsideUser,
};

const VuePlugin = {
  install(Vue) {
    Object.keys(components).forEach((component) => {
      Vue.component(component, components[component]);
    });

    Object.keys(directives).forEach((directive) => {
      Vue.directive(directive, directives[directive]);
    });
  },
};

export default VuePlugin;