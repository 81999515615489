import GruppiList from "./components/GruppiList.vue";
import GruppoEdit from "./components/GruppoEdit.vue";
import GruppoView from "./components/GruppoView.vue";
import UserList from "./components/UserList.vue";
import UserEdit from "./components/UserEdit.vue";
import SessionList from "./components/SessionList.vue";

export default {
    GruppiList,
    GruppoEdit,
    GruppoView,
    UserList,
    UserEdit,
    SessionList,
};
