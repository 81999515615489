<template>
    <div class="sa-form-section">
        <div class="sa-tab-scroll">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>

                    <span class="sa-data"> {{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.EcoReporting.lblHeight") }}(cm)</label>

                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.EcoReporting.lblWeight") }}(Kg)</label>

                    <span class="sa-data"> {{ jsonData.peso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBSA") }}(m2) </label>

                    <span class="sa-data"> {{ jsonData.bsa }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}(Kg / m2) </label>

                    <span class="sa-data">{{ jsonData.bmi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAS") }} (mmHg)</label>

                    <span class="sa-data">{{ jsonData.pas }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAD") }} (mmHg)</label>

                    <span class="sa-data"> {{ jsonData.pad }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFC") }} (opm)</label>

                    <span class="sa-data">{{ jsonData.fc }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.EcoReporting.lblAcousticImpedance") }}</label>

                    <span class="sa-data">{{ getLabelTraduora(jsonData.impedenzaAcustica) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVideotape") }}</label>

                    <span class="sa-data">{{ jsonData.videotape }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblLeftVentricle") }}</h5>
            <b-row>
                <b-col xs="12" sm="12" md="2" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVStd") }} (mm)</label>

                    <span class="sa-data">{{ jsonData.vstd }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="2" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPPtd") }} (mm)</label>

                    <span class="sa-data">{{ jsonData.pptd }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVMha") }} (g/m2,7) </label>

                    <span class="sa-data">{{ jsonData.lvmha }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVSts") }} (mm)</label>

                    <span class="sa-data">{{ jsonData.vsts }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="2" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbleft") }}[%]</label>

                    <span class="sa-data">{{ jsonData.eft }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="2" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRWT") }}</label>

                    <span class="sa-data"> {{ jsonData.rwt }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSIVtd") }} (mm)</label>

                    <span class="sa-data">{{ jsonData.sivtd }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblsft") }} [%]</label>

                    <span class="sa-data"> {{ jsonData.sft }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblESS") }}(cm/d)</label>

                    <span class="sa-data">{{ jsonData.ess }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVMid") }}(P)[g/m2]</label>

                    <span class="sa-data">{{ jsonData.lvmidp }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVV") }}</label>

                    <span class="sa-data"> {{ jsonData.lvv }}</span>
                </b-col>
            </b-row>

            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnteriorBasalSeptum") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.settoanteriore) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnteriorMiddleSeptum") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.settoanterioreMedio) }} </span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnteriorApexSeptum") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.apiceSetto) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnteriorBasalWall") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.pareteanteriore) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnteriorMiddleWall") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.pareteanterioreMedia) }} </span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnteriorApexWall") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.apiceAnt) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorBasalWall") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.pareteLatAnteriore) }} </span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorMiddleWall") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.pareteLatAnterioreMedia) }} </span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLateralPosteriorBasalWall") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.pareteLatPosterioreMedia) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLowerMiddleWall") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.pareteInferioreMedia) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPosteriorMiddleSeptum") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.settoposterioreMedio) }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLateralPosteriorApexWall") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.apicePost) }} </span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLowerApexWall") }}</label>

                    <span class="sa-data">{{ getDescrizioneSegmento(jsonData.apiceInf) }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLateralPosteriorBasalWall") }}</label
                    ><span class="sa-data">{{ getDescrizioneSegmento(jsonData.pareteLatPosteriore) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLowerBasalWall") }}</label
                    ><span class="sa-data"> {{ getDescrizioneSegmento(jsonData.pareteInferiore) }} </span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPosteriorBasalSeptum") }}</label
                    ><span class="sa-data">{{ getDescrizioneSegmento(jsonData.settoposteriore) }} </span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTotal") }}</label>

                    <span class="sa-data">{{ jsonData.totale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIMA") }}</label>

                    <span class="sa-data">{{ jsonData.estensioneDanno }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>

                    <span class="sa-data">{{ jsonData.notevs }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblRightVentricle") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVDtd") }}</label>

                    <span class="sa-data"> {{ jsonData.vdtd }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblSystolicPressure") }}</label>

                    <span class="sa-data">{{ jsonData.pressSist }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTAPSE") }}</label>

                    <span class="sa-data">{{ jsonData.tapse }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>

                    <span class="sa-data">{{ jsonData.notevd }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblRightAtrium") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVCIi") }}</label>

                    <span class="sa-data"> {{ jsonData.vcii }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVCIe") }}</label>

                    <span class="sa-data">{{ jsonData.vcie }}</span>
                </b-col>
                <b-col> </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDesription") }}</label>

                    <span class="sa-data">{{ jsonData.descrAtriodestro }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblLeftAtrium") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftAtrium") }}</label>

                    <span class="sa-data">{{ jsonData.atrioSx }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLavi") }}</label>

                    <span class="sa-data">{{ jsonData.lavi }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDesription") }}</label>

                    <span class="sa-data">{{ jsonData.descrAtriosinistro }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblAorta") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAorta") }}</label>

                    <span class="sa-data">{{ jsonData.aorta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVOT") }}</label>

                    <span class="sa-data">{{ jsonData.lvot }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEscVal") }}</label>

                    <span class="sa-data">{{ jsonData.escval }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2D") }}</label>

                    <span class="sa-data">{{ jsonData.duedAorta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIA") }}</label>

                    <span class="sa-data">{{ jsonData.ia }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDoppler") }}</label>

                    <span class="sa-data">{{ jsonData.doppleraorta }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAortaArea") }}</label>

                    <span class="sa-data">{{ jsonData.area }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMax") }}</label>

                    <span class="sa-data">{{ jsonData.pgmaxAorta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMean") }}</label>

                    <span class="sa-data">{{ jsonData.pgmeanAorta }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblMitral") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2D") }}</label>

                    <span class="sa-data">{{ jsonData.mitrale2d }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIM") }}</label>

                    <span class="sa-data">{{ jsonData.im }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEE") }}</label>

                    <span class="sa-data">{{ jsonData.ee }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDoppler") }}</label>

                    <span class="sa-data">{{ jsonData.dopplemitrale }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVE") }}</label>

                    <span class="sa-data">{{ jsonData.pfve }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVA") }}</label>

                    <span class="sa-data">{{ jsonData.pfva }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDT") }}</label>

                    <span class="sa-data">{{ jsonData.dt }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIRT") }}</label>

                    <span class="sa-data">{{ jsonData.irt }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMean") }}</label>

                    <span class="sa-data">{{ jsonData.pgmeanMitrale }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMVA") }}</label>

                    <span class="sa-data">{{ jsonData.mva }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblPulmonary") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2D") }}</label>

                    <span class="sa-data">{{ jsonData.polmonare2d }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIM") }}</label>

                    <span class="sa-data">{{ jsonData.ip }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDoppler") }}</label>

                    <span class="sa-data">{{ jsonData.dopplerpolmo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMax") }}</label>

                    <span class="sa-data">{{ jsonData.vmaxPolmonare }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMax") }}</label>

                    <span class="sa-data">{{ jsonData.pgmaxPolmonare }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblPulmonaryVeinsDoppler") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblS") }}</label>

                    <span class="sa-data">{{ jsonData.s }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblD") }}</label>

                    <span class="sa-data">{{ jsonData.d }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPV") }}</label>

                    <span class="sa-data">{{ jsonData.apv }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPD") }}</label>

                    <span class="sa-data">{{ jsonData.apd }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblTricuspid") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2D") }}</label>

                    <span class="sa-data"> {{ jsonData.tricuspide2d }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIT") }}</label>

                    <span class="sa-data">{{ jsonData.it }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDoppler") }}</label>

                    <span class="sa-data"> {{ jsonData.dopplertricuspide }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMax") }}</label>

                    <span class="sa-data">{{ jsonData.vmaxTricuspide }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMaxIT") }}</label>

                    <span class="sa-data">{{ jsonData.vmaxitTricuspide }}</span>
                </b-col>
            </b-row>
            <hr />
            <h5>{{ getLabelTraduora("patient.cardiology.lblPericardium") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-data">{{ jsonData.pericardio }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
                    <span class="sa-data">{{ jsonData.conlusioni }}</span>
                </b-col>
            </b-row>

            <hr />
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <div class="sa-view-form-data">
                        <user-information :data="jsonData"></user-information>
                    </div>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import UserInformation from "../../../../../utenti/components/UserInformation.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: { UserInformation },
    // data() {
    //     return {
    //         linkback: null,
    //         pathResource: "/cardioecocardirefertazioni",
    //         jsonData: { createDate: 0, createUser: "prova" },
    //         data: {},
    //         tabIndex: 0,
    //         id: null,
    //         showModalLoading: false,
    //         fontScale: 2,
    //         linkedit: null,
    //         pathRestValidation: "/validation",
    //         isThisFirmato: false,
    //     };
    // },
    data() {
        return { pathResource: "/cardioecocardirefertazioni", id: null, jsonData: {}, fontScale: 2 };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        console.log(me.id);
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        getTranslate(text) {
            return this.$i18n.t(text);
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        getDescrizioneSegmento(id) {
            return id;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
