<template>
  <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
    <template slot="tab-content-event">
      <cardio-ecostress-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></cardio-ecostress-edit-component>
    </template>
  </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import CardioEcostressEditComponent from "../components/CardioEcostressEditComponent.vue";
export default {
  mixins: [UtilityMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { EventiEditComponent, CardioEcostressEditComponent },
  data() {
    return {
      linkback: null,
      titoloEvento: "ECOSTRESS",
      pathResource: "/cardioecostress",
      pathRestValidation: "/validation",
      id: "-1",
      isFirmato: false,
      childKey: 0,
      tabIndex: 0,
      showModalLoading: false,
      jsonData: {
        dataEvento: null,
        apicePostIsc: "",
        apiceInfIsc: "",
        apiceAntIsc: "",
        pareteInferioreIsc: "",
        estensionedannoBas: "",
        totaleBas: "",
        pareteanterioreVit: "",
        pareteanterioreMediaVit: "",
        pareteLatAnterioreVit: "",
        pareteLatAnterioreMediaVit: "",
        idRicovero: "",
        apicePostBas: "",
        apiceInfBas: "",
        apiceAntBas: "",
        pareteInferioreMediaBas: "",
        pareteInferioreBas: "",
        apiceSettoBas: "",
        settoposterioreMedioBas: "",
        settoanterioreMedioBas: "",
        pareteanterioreMediaBas: "",
        pareteLatAnterioreBas: "",
        pareteLatAnterioreMediaBas: "",
        pareteLatPosterioreBas: "",
        pareteLatPosterioreMediaBas: "",
        estensionedannoVit: "",
        totaleVit: "",
        apicePostVit: "",
        apiceInfVit: "",
        apiceAntVit: "",
        pareteInferioreMediaVit: "",
        pareteInferioreVit: "",
        apiceSettoVit: "",
        settoposterioreMedioVit: "",
        settoanterioreVit: "",
        settoposterioreBas: "",
        settoanterioreBas: "",
        apiceSettoIsc: "",
        settoposterioreMedioIsc: "",
        settoanterioreMedioIsc: "",
        pareteanterioreIsc: "",
        pareteanterioreMediaIsc: "",
        pareteLatAnterioreIsc: "",
        pareteLatAnterioreMediaIsc: "",
        pareteLatPosterioreIsc: "",
        settoposterioreIsc: "",
        settoanterioreIsc: "",
        conclusioni: "",
        commento4: "",
        estensionedannoIsc: "",
        totaleIsc: "",
        commento3: "",
        commento2: "",
        commento1: "",
        avimng1: "",
        avimng2: "",
        conclusioni2: "",
        ecg1: "",
        ecg2: "",
        ecg3: "",
        pasBasale: "",
        padBasale: "",
        fcBasale: "",
        sintomiBasale: "",
        pasVit: "",
        padVit: "",
        fcVit: "",
        sintomiVit: "",
        pasIsc: "",
        padIsc: "",
        fcIsc: "",
        sintomiIsc: "",
        noteFinali: "",
        versioneEsame: "",
        esaminatore: "",
        storicoReferti: "",
        checkFi: "",
        pareteInferioreMediaIsc: "",
        pareteLatPosterioreMediaIsc: "",
        pareteLatPosterioreMediaVit: "",
        pareteanterioreBas: "",
        pareteLatPosterioreVit: "",
        settoanterioreMedioVit: "",
        settoposterioreVit: "",
        conclusioniBasale: "",
      },
      key: 0,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    this.jsonData.dataEvento = new Date().getTime();
  },
  methods: {
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
  },
};
</script>
