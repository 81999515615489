<template>
    <div class="mb-3">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblDateVisit") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataEvento" :disabled="!edit" type="datetime"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblNextVisit") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.prossimaVisita" :disabled="!edit" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSportPracticed") }}</label>
                    <b-form-select v-model="data.sportPraticato" :disabled="!edit" :options="tipiSportOptions" value-field="codiceSport" text-field="codiceSport">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ firstElementSelect }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHourNextVisit") }}</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="data.oraAppuntamento" :disabled="!edit" type="time"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblFollowingVisit") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.visitaSuccessiva" :disabled="!edit" type="timestamp"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblYearsNumber") }}</label>
                    <b-form-input v-model="data.numeroAnni" :disabled="true" type="number"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblWeeklyWorkouts") }}
                        <eventi-storico-misure-component misura="allenamenti_settimana" misuraLabel="Allenamenti Settimanali" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input v-model="data.allenamentiSettimana" :disabled="!edit" type="number"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblHoursWorkout") }}
                        <eventi-storico-misure-component misura="ore_allenamento" misuraLabel="Ore Allenamento" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input v-model="data.oreAllenamento" :disabled="!edit" type="number"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSpeciality") }}</label>
                    <b-form-input v-model="data.specialita" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblCorporation") }}</label>
                    <b-form-input v-model="data.societa" :disabled="!edit"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblFederation") }}</label>
                    <b-form-input v-model="data.federazione" :disabled="true"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblLHB") }}</label>
                    <b-form-input v-model="data.aslNew" :disabled="!edit"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblOtherSports") }}</label>
                    <b-form-input v-model="data.altriSport" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblMenarche") }}</label>
                    <b-form-input v-model="data.menarca" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblAntitetanus") }}</label>
                    <b-form-select v-model="data.antitetanica" :options="optionsYesNo" :disabled="!edit" :value="null" value-field="value" text-field="text">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSmoking") }}</label>
                    <b-form-select v-model="data.fumo" :options="optionsFumo" :disabled="!edit" :value="null" value-field="value" text-field="text">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblAlcohol") }}</label>
                    <b-form-select v-model="data.alcool" :disabled="!edit" :options="optionsAlcool">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblCoffee") }}</label>
                    <b-form-select v-model="data.caffe" :disabled="!edit" :value="null" value-field="value" text-field="text" :options="optionsCaffe">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblNarcoticSubstances") }}</label>
                    <b-form-select v-model="data.sostenzeStupefacenti" :disabled="!edit" :value="null" value-field="value" text-field="text" :options="optionsYesNo">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblMilitary") }}</label>
                    <b-form-select v-model="data.militare" :value="null" value-field="value" text-field="text" :options="optionsYesNo" :disabled="!edit">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>

                <b-col v-if="data.militare === this.getLabelTraduora('patient.lblYes')" cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblMilitaryMotif") }}</label>
                    <b-form-input v-model="data.militareMotivo" :disabled="!edit"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocument") }}</label>
                    <b-form-select v-model="data.documentoIdentitaTipo" :disabled="!edit" :options="optionsCartaID">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocumentReleaser") }}</label>
                    <b-form-input v-model="data.documentoIdentitaRilasciatoDa" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocumentNumber") }}</label>
                    <b-form-input v-model="data.documentoIdentitaNumero" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocumentDateRelease") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.documentoIdentitaRilasciatoIl" :disabled="!edit" type="date"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
import axios from "axios";
export default {
    mixins: [UtilityMixin],
    props: ["data", "edit", "etaPaziente", "tipoEvento", "gruppo"],
    components: { EventiStoricoMisureComponent, DatePicker },
    computed: {
        idPaziente() {
            let me = this;
            return me.data.idPaziente;
        },
    },
    data() {
        return {
            tipiSportOptions: [],
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            optionsYesNo: [
                { text: this.getLabelTraduora("patient.lblNo"), value: this.getLabelTraduora("patient.lblNo") },
                { text: this.getLabelTraduora("patient.lblYes"), value: this.getLabelTraduora("patient.lblYes") },
            ],
            optionsCartaID: [
                { text: this.getLabelTraduora("patient.afa.lblIdentityCard"), value: "CI" },
                { text: this.getLabelTraduora("patient.afa.lblPassport"), value: "PASS" },
            ],
            optionsAlcool: [
                { text: this.getLabelTraduora("patient.afa.lblAbstinent"), value: this.getLabelTraduora("patient.afa.lblAbstinent") },
                { text: this.getLabelTraduora("patient.afa.lblLessHalfLiter"), value: this.getLabelTraduora("patient.afa.lblLessHalfLiter") },
                { text: this.getLabelTraduora("patient.afa.lblLessOneLiter"), value: this.getLabelTraduora("patient.afa.lblLessOneLiter") },
                { text: this.getLabelTraduora("patient.afa.lblLessTwoLiter"), value: this.getLabelTraduora("patient.afa.lblLessTwoLiter") },
                { text: this.getLabelTraduora("patient.afa.lblMoreTwoLiter"), value: this.getLabelTraduora("patient.afa.lblMoreTwoLiter") },
            ],
            optionsFumo: [
                { text: this.getLabelTraduora("patient.afa.lblNotSmoker"), value: this.getLabelTraduora("patient.afa.lblNotSmoker") },
                { text: this.getLabelTraduora("patient.afa.lblUpTo10Cigarettes"), value: this.getLabelTraduora("patient.afa.lblUpTo10Cigarettes") },
                { text: this.getLabelTraduora("patient.afa.lblUpTo20Cigarettes"), value: this.getLabelTraduora("patient.afa.lblUpTo20Cigarettes") },
                { text: this.getLabelTraduora("patient.afa.lblmore20Cigarettes"), value: this.getLabelTraduora("patient.afa.lblmore20Cigarettes") },
            ],
            optionsCaffe: [
                { text: this.getLabelTraduora("patient.afa.lbl0Cups"), value: this.getLabelTraduora("patient.afa.lbl0Cups") },
                { text: this.getLabelTraduora("patient.afa.lbl1Cup"), value: this.getLabelTraduora("patient.afa.lbl1Cup") },
                { text: this.getLabelTraduora("patient.afa.lbl2Cups"), value: this.getLabelTraduora("patient.afa.lbl2Cups") },
                { text: this.getLabelTraduora("patient.afa.lbl3Cups"), value: this.getLabelTraduora("patient.afa.lbl3Cups") },
                { text: this.getLabelTraduora("patient.afa.lblMore3Cups"), value: this.getLabelTraduora("patient.afa.lblMore3Cups") },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.showModalLoading = true;
            let linkTipiSport = process.env.VUE_APP_PATH_API + "/tipisport";
            axios
                .get(linkTipiSport)
                .then((response) => {
                    me.showModalLoading = false;
                    me.tipiSportOptions = response.data.data;
                })
                .catch((e) => {
                    me.showModalLoading = false;
                    console.log(e);
                });
        },
    },
    watch: {
        "data.sportPraticato": function (params) {
            let me = this;
            me.tipiSportOptions.forEach((element) => {
                if (element.codiceSport === params) {
                    me.data.federazione = element.federazione;
                }
            });
        },
        "data.militare": function (params) {
            let me = this;
            if (params === this.getLabelTraduora("patient.lblNo") || params === null) {
                me.data.militareMotivo = "";
            }
        },
    },
};
</script>
