<template>
  <div>
    <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
          <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="VALORI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Altezza(cm)</label>
          <span class="sa-data">{{ jsonData.altezza }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Peso(kg)</label>
          <span class="sa-data">{{ jsonData.peso }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">FVC (L/s)</label>
          <span class="sa-data">{{ jsonData.fvc }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">FEV1 (L)</label>
          <span class="sa-data">{{ jsonData.fev1 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">FEV1/FVC% (%)</label>
          <span class="sa-data">{{ jsonData.fev1Fvc }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">PEF(L/s)</label>
          <span class="sa-data">{{ jsonData.pef }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">FEF-25-75% (L/s)</label>
          <span class="sa-data">{{ jsonData.fef2575 }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF25% (L/s)</label>
          <span class="sa-data">{{ jsonData.mef25 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF50% (L/s)</label>
          <span class="sa-data">{{ jsonData.mef50 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF75% (L/s)</label>
          <span class="sa-data">{{ jsonData.mef75 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MVV(L/m)</label>
          <span class="sa-data">{{ jsonData.mvv }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="VALORI NORMALI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">FVC (L/s)</label>
          <span class="sa-data">{{ jsonData.fvcNormale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">FEV1 (L)</label>
          <span class="sa-data">{{ jsonData.fev1Normale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">FEV1/FVC% (%)</label>
          <span class="sa-data">{{ jsonData.fev1FvcNormale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">PEF(L/s)</label>
          <span class="sa-data">{{ jsonData.pefNormale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">FEF-25-75% (L/s)</label>
          <span class="sa-data">{{ jsonData.fef2575Normale }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF25% (L/s)</label>
          <span class="sa-data">{{ jsonData.mef25Normale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF50% (L/s)</label>
          <span class="sa-data">{{ jsonData.mef50Normale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF75% (L/s)</label>
          <span class="sa-data">{{ jsonData.mef75Normale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MVV(L/m)</label>
          <span class="sa-data">{{ jsonData.mvvNormale }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="CONCLUSIONI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Conclusioni</label>
          <span class="sa-data">{{ jsonData.conclusioni }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="FIRMA" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <span class="sa-data">{{ jsonData.firma }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <div style="height: 100%; display: inline-flex; flex-direction: column; width: 100%">
        <div class="sa-tab-scheda-row-fill-table-content">
          <mir-list-component :idRisorsa="idRisorsa" @updateBase64="onUpdateBase64" :listaRichieste="listaRichieste" @update="onUpdateLista"></mir-list-component>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import MirListComponent from "../../../../../integrazioni/mir/components/MirListComponent.vue";
import axios from "axios";
export default {
  components: { MirListComponent },
  mixins: [UtilityMixin],
  props: {},
  data() {
    return { pathResource: "/pazienteeventispirometrie", id: null, jsonData: {}, listaRichieste: [], idRisorsa: "" };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.idRisorsa = me.id;
    me.loadData();
  },
  computed: {
    isFirmato() {
      let me = this;
      let isFirmato = !me.jsonData.firma;
      console.log(isFirmato, "FIRMA");
      me.$emit("updateStatoFirma", isFirmato);
      return !me.jsonData.firma;
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
    onUpdateBase64() {},
    onUpdateLista(data) {
      let me = this;
      me.listaRichieste = data;
      if (me.listaRichieste.length > 0) {
        me.disableCampi = true;
        if (me.listaRichieste[0].statoAttuale !== "IN VISITA") {
          me.disableCampi = false;
        }
      }
    },
  },
};
</script>

<style></style>
