<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
        <template slot="tab-content-event">
            <gastro-anamnesi-familiare-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></gastro-anamnesi-familiare-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import GastroAnamnesiFamiliareEditComponent from "./GastroAnamnesiFamiliareEditComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    mixins: [UtilityMixin],
    components: { EventiEditComponent, GastroAnamnesiFamiliareEditComponent },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    data() {
        return {
            linkback: null,
            titoloEvento: this.getLabelTraduora("patients.gastroenterology.familyHistory"),
            pathResource: "/gastroanamnesifamiliare",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            jsonData: {
                dataEvento: null,
                madre: null,
                padre: null,
                fratelli: null,
                sorelle: null,
                coniuge: null,
                numeroFratelli: 0,
                numeroSorelle: 0,
                coniugeSino: null,
                numeroFigli: 0,
                calcolosiDellaColecisti: null,
                ulceraGastrica: null,
                ulceraDuodenale: null,
                carcinomaDelloStomaco: null,
                linfomaGastrico: null,
                esofagiteDaReflusso: null,
                carcinomaColon: null,
            },
        };
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
