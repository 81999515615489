<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="14" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblCertificateType") }}</label>

                    <span class="sa-data">{{ jsonData.tipoCertificato }}</span>
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblSportPracticed") }}</label>

                    <span class="sa-data">{{ jsonData.sportPraticato }}</span>
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblEligibilityJudgement") }}</label>

                    <span class="sa-data">{{ jsonData.giudizio }}</span>
                </b-col>

                <b-col cols="12" xs="14" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblPlace") }}</label>

                    <span class="sa-data">{{ jsonData.luogo }}</span>
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblEligibilityPeriod") }}</label>

                    <span class="sa-data">{{ jsonData.periodoIdoneita }}</span>
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblDateDeadline") }}</label>

                    <span class="sa-data">{{ jsonData.dataScadenza }}</span>
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblCertificateNumber") }}</label>

                    <span class="sa-data">{{ jsonData.numCertifNew }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</label>

                    <span class="sa-data">{{ jsonData.giudizioFinale }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="height: 250px">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data-no-new-line">{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}:</label>
                        <span class="sa-data">{{ jsonData.firmaEo }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data-no-new-line">{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}:</label>
                        <span class="sa-data">{{ jsonData.firmaEcg }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data-no-new-line">{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}:</label>
                        <span class="sa-data">{{ jsonData.firmaGf }}</span>
                    </b-col>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
    },
};
</script>
