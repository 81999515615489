<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="14" sm="4" md="4" lg="4" xl="12">
                    <strong>{{ this.$i18n.t("patient.afa.lblCertificateType") }}</strong>
                    <b-form-select v-model="data.tipoCertificato" :options="tipoCertificatoOptions" value-field="id" text-field="descrizione" :disabled="!edit">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
                    <strong>{{ this.$i18n.t("patient.afa.lblSportPracticed") }}</strong>
                    : {{ data.sportPraticato }}
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
                    <strong>{{ this.$i18n.t("patient.afa.lblEligibilityJudgement") }}</strong>
                    <b-form-select v-model="data.giudizio" :options="opzioniGiudizioIdoneita" :disabled="!edit">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <strong>{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</strong>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.giudizioFinale" :disabled="!edit"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="14" sm="4" md="4" lg="4" xl="12">
                    <strong>{{ this.$i18n.t("patient.afa.lblPlace") }}</strong>
                    <b-form-input v-model="data.luogo" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
                    <strong>{{ this.$i18n.t("patient.afa.lblEligibilityPeriod") }}</strong>
                    <b-form-select v-model="data.periodoIdoneita" :options="opzioniPeriodoIdoneita" @input="onInputIdoneita" :disabled="!edit">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
                    <strong>{{ this.$i18n.t("sogei.lblEndingValidityDate") }}</strong>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataScadenza" :disabled="true" type="datetime"></date-picker>
                </b-col>
                <b-col cols="12" xs="4" sm="4" md="4" lg="4" xl="12">
                    <strong>{{ this.$i18n.t("patient.afa.lblCertificateNumber") }}</strong>
                    <b-form-input v-model="data.numCertifNew" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-form-label">{{ this.$i18n.t("therapy.lblStatus") }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblPhysicalExamination") }} :</span>
                    {{ data.firmaEo }}
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblElectrocardiogram") }} :</span>
                    {{ data.firmaEcg }}
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-form-label">{{ this.$i18n.t("patient.afa.lblFinalJudgement") }} :</span>
                    {{ data.firmaGf }}
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
export default {
    props: ["data", "edit", "tipoCertificatoOptions"],
    components: { DatePicker },
    data() {
        return {
            prova: null,
            selectFirstElement: this.$i18n.t("patient.ecoreferting.lblSelected"),
            opzioniPeriodoIdoneita: [
                { text: this.$i18n.t("patient.eligibility.select1mounth"), value: this.$i18n.t("patient.eligibility.select1mounth") },
                { text: this.$i18n.t("patient.eligibility.select3mounth"), value: this.$i18n.t("patient.eligibility.select3mounth") },
                { text: this.$i18n.t("patient.eligibility.select6mounth"), value: this.$i18n.t("patient.eligibility.select6mounth") },
                { text: this.$i18n.t("patient.eligibility.select1years"), value: this.$i18n.t("patient.eligibility.select1years") },
                { text: this.$i18n.t("patient.eligibility.select2years"), value: this.$i18n.t("patient.eligibility.select2years") },
            ],
            opzioniGiudizioIdoneita: [
                { text: this.$i18n.t("patient.idoneita.lblIsuitable"), value: this.$i18n.t("patient.idoneita.lblIsuitable") },
                { text: this.$i18n.t("patient.idoneita.lblINoSuitable"), value: this.$i18n.t("patient.idoneita.lblINoSuitable") },
            ],
        };
    },
    methods: {
        onInputIdoneita(value) {
            switch (value) {
                case this.$i18n.t("patient.eligibility.select1mounth"):
                    this.data.dataScadenza = moment(moment(), "DD-MM-YYYY").add(1, "M").toDate().getTime();

                    break;
                case this.$i18n.t("patient.eligibility.select3mounth"):
                    this.data.dataScadenza = moment(moment(), "DD-MM-YYYY").add(3, "M").toDate().getTime();

                    break;
                case this.$i18n.t("patient.eligibility.select6mounth"):
                    this.data.dataScadenza = moment(moment(), "DD-MM-YYYY").add(6, "M").toDate().getTime();

                    break;
                case this.$i18n.t("patient.eligibility.select1years"):
                    this.data.dataScadenza = moment(moment(), "DD-MM-YYYY").add(1, "Y").toDate().getTime();

                    break;
                case this.$i18n.t("patient.eligibility.select2years"):
                    this.data.dataScadenza = moment(moment(), "DD-MM-YYYY").add(2, "Y").toDate().getTime();

                    break;

                default:
                    break;
            }
        },
    },
};
</script>
