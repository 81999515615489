<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-card class="sa-card" header="Dati Prenotazione" header-tag="header" footer-tag="footer" title="">
                            <b-card-text>
                                <b-row>
                                    <b-col xs="12" sm="12" md="6" lg="2" xl="2">
                                        <label class="sa-label-data">Ambulatorio</label>
                                        <b-form-select v-model="jsonData.idAmbulatorio" :options="ambulatoriOptions" :value="null" value-field="id" text-field="nome"></b-form-select>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                                        <label class="sa-label-data">Data Ora</label>
                                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOra" type="datetime"></date-picker>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="3" lg="2" xl="2">
                                        <label class="sa-label-data">Cod. Prestazione</label>
                                        <b-form-input v-model="jsonData.codicePrestazione"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="9" lg="5" xl="5">
                                        <label class="sa-label-data">Descrizione Prestazione</label>
                                        <b-form-input v-model="jsonData.descrizionePrestazione"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer">
                            <b-card-text>
                                <b-row>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Codice Fiscale</label>
                                        <b-input-group class="mb-3">
                                            <b-form-input maxlength="16" class="text-uppercase" @keypress="onKeyPress" v-model="jsonData.codiceFiscalePaziente" placeholder="Codice Fiscale"></b-form-input>
                                            <b-button size="sm" variant="" @click="onClickSearchDatiPaziente"><b-icon icon="search"></b-icon></b-button>
                                        </b-input-group>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Cognome</label>
                                        <b-form-input v-model="jsonData.cognome"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Nome</label>
                                        <b-form-input v-model="jsonData.nome"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                        <label class="sa-label-data">Data Nascita</label>
                                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.nascitaData" type="date"></date-picker>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Provincia Nascita</label>
                                        <b-form-select v-model="jsonData.nascitaProvincia" :options="provinceNascitaOptions" :value="null" value-field="sigla" text-field="provincia" @input="loadComuneNascita(jsonData.nascitaProvincia)"></b-form-select>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Comune Nascita</label>
                                        <b-form-select v-model="jsonData.nascitaComuneCodiceIstat" :options="comuniNascitaOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onInputComuneNascita"></b-form-select>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Cap Comune Nascita</label>
                                        <b-form-input v-model="jsonData.nascitaCap"></b-form-input>
                                    </b-col>
                                </b-row>
                                <hr />
                                <b-row>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Provincia Residenza</label>
                                        <b-form-select v-model="jsonData.residenzaProvincia" :options="provinceResidenzaOptions" :value="null" value-field="sigla" text-field="provincia" @input="loadComuneResidenza(jsonData.residenzaProvincia)"></b-form-select>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Comune Residenza</label>
                                        <b-form-select v-model="jsonData.residenzaComuneCodiceIstat" :options="comuniResidenzaOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onInputComuneResidenza"></b-form-select>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Cap Comune Residenza</label>
                                        <b-form-input v-model="jsonData.residenzaCap"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <label class="sa-label-data">Indirizzo Residenza</label>
                                        <b-form-input v-model="jsonData.residenzaIndirizzo"></b-form-input>
                                    </b-col>
                                </b-row>
                                <hr />
                                <b-row>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Provincia Domicilio</label>
                                        <b-form-select v-model="jsonData.domicilioProvincia" :options="provinceDomicilioOptions" :value="null" value-field="sigla" text-field="provincia" @input="loadComuneDomicilio(jsonData.domicilioProvincia)"></b-form-select>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Comune Domicilio</label>
                                        <b-form-select v-model="jsonData.domicilioComuneCodiceIstat" :options="comuniDomicilioOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onInputComuneDomicilio"></b-form-select>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                        <label class="sa-label-data">Cap Domicilio</label>
                                        <b-form-input v-model="jsonData.domicilioCap"></b-form-input>
                                    </b-col>
                                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                        <label class="sa-label-data">Indirizzo Domicilio</label>
                                        <b-form-input v-model="jsonData.domicilioIndirizzo"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <div></div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
    mixins: [UtilityMixin],
    components: { SaPageLayout, DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            pathResource: "/prenotazionicup",
            pathResourceAmbulatori: "/ambulatori",
            pathResourceProvince: "/province",
            pathResourceComune: "/comuni/provincia",
            linkback: null,
            showModalLoading: false,
            id: "-1",
            disabledValue: {},
            ambulatoriOptions: [],
            provinceNascitaOptions: [],
            provinceResidenzaOptions: [],
            provinceDomicilioOptions: [],
            comuniNascitaOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            comuniResidenzaOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            comuniDomicilioOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
            jsonData: {
                idAmbulatorio: null,
                idPaziente: null,
                dataOra: null,
                codicePrestazione: null,
                descrizionePrestazione: null,
                codiceFiscalePaziente: null,
                nascitaData: null,
                nascitaComuneCodiceIstat: null,
                nascitaComune: null,
                nascitaProvincia: null,
                nascitaCap: null,
                residenzaIndirizzo: null,
                residenzaComuneCodiceIstat: null,
                residenzaProvincia: null,
                residenzaCap: null,
                domicilioIndirizzo: null,
                domicilioComuneCodiceIstat: null,
                domicilioProvincia: null,
                domicilioCap: null,
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
        me.loadAmbulatori();
        me.loadData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvince();
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                me.showModalLoading = true;
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.loadComuneNascita(me.jsonData.nascitaProvincia);
                        me.loadComuneResidenza(me.jsonData.residenzaProvincia);
                        me.loadComuneDomicilio(me.jsonData.domicilioProvincia);
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
        loadAmbulatori() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAmbulatori;
            me.showModalLoading = true;
            axios
                .get(link, { params: { idStruttura: "4ebbaf08-04f4-4097-8ef1-aa7a1eea2cc0" } })
                .then((response) => {
                    me.ambulatoriOptions = response.data.data;
                    me.ambulatoriOptions.unshift({ id: null, nome: "-Selezionare Ambulatorio-" });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
            axios.get(link).then((response) => {
                me.provinceNascitaOptions = response.data.data;
                me.provinceNascitaOptions.unshift({ sigla: null, provincia: "- Seleziona Provincia -" });
                response.data.data.forEach((element) => {
                    me.provinceDomicilioOptions.push(element);
                    me.provinceResidenzaOptions.push(element);
                });
            });
        },
        setDatiNascita(siglaProvincia, codiceIstatComuneNascita = null) {
            let me = this;
            me.jsonData.nascitaComuneCodiceIstat = codiceIstatComuneNascita;
            me.provinceNascitaOptions.forEach((provincia) => {
                if (provincia.value === siglaProvincia) {
                    me.jsonData.nascitaProvincia = provincia.value;
                    me.loadComuneNascita(provincia.value);
                }
            });
        },
        loadComuneNascita(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniNascitaOptions = [];
                me.comuniNascitaOptions = response.data.data;
                me.comuniNascitaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            });
        },
        onInputComuneNascita(codiceIstatComuneNascita) {
            let me = this;
            me.comuniNascitaOptions.forEach((comune) => {
                if (comune.codiceIstat === codiceIstatComuneNascita) {
                    me.jsonData.nascitaCap = comune.cap;
                }
            });
        },
        setDatiResidenza(siglaProvincia, codiceIstatComuneResidenza = null) {
            let me = this;
            me.jsonData.residenzaComuneCodiceIstat = codiceIstatComuneResidenza;
            me.provinceResidenzaOptions.forEach((provincia) => {
                if (provincia.value === siglaProvincia) {
                    me.jsonData.residenzaProvincia = provincia.value;
                    me.loadComuneResidenza(provincia.value);
                }
            });
        },
        loadComuneResidenza(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniResidenzaOptions = response.data.data;
                me.comuniResidenzaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            });
        },
        onInputComuneResidenza(codiceIstatComuneResidenza) {
            let me = this;
            me.comuniResidenzaOptions.forEach((comune) => {
                if (comune.codiceIstat === codiceIstatComuneResidenza) {
                    me.jsonData.residenzaCap = comune.cap;
                }
            });
        },
        loadComuneDomicilio(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniDomicilioOptions = response.data.data;
                me.comuniDomicilioOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
            });
        },
        onInputComuneDomicilio(codiceIstatComuneDomicilio) {
            let me = this;
            me.comuniDomicilioOptions.forEach((comune) => {
                if (comune.codiceIstat === codiceIstatComuneDomicilio) {
                    me.jsonData.domicilioCap = comune.cap;
                }
            });
        },
        onKeyPress() {
            // let me = this;
            // if (e.keyCode === 13) {
            //     me.onClickSearchDatiPaziente();
            // }
        },
        onClickSearchDatiPaziente() {
            let me = this;
            console.log("OK");
            me.showmolalloading = true;
            if (me.jsonData.codiceFiscalePaziente.trim().length === 0) {
                me.showmolalloading = false;
            } else {
                me.jsonData.codiceFiscalePaziente = me.jsonData.codiceFiscalePaziente.toUpperCase();
                let link = process.env.VUE_APP_PATH_API + "/anagrafica";
                axios
                    .get(link, { params: { codiceFiscale: me.jsonData.codiceFiscalePaziente } })
                    .then((response) => {
                        me.jsonData.nome = response.data.data[0].nome;
                        me.jsonData.cognome = response.data.data[0].cognome;
                        me.jsonData.nascitaData = response.data.data[0].dataNascita;
                        me.jsonData.nascitaProvincia = response.data.data[0].provinciaNascita;
                        // me.setDatiNascita(response.data.data[0].provinciaNascita, response.data.data[0].codiceIstatComuneNascita);
                        // me.jsonData.residenzaProvincia = response.data.data.provinciaResidenza;
                        // me.setDatiResidenza(response.data.data.provinciaResidenza, response.data.data.codiceIstatComuneResidenza);
                        // me.jsonData.residenzaIndirizzo = response.data.data.indirizzoResidenza;
                        me.showmolalloading = false;
                    })
                    .catch(() => {
                        me.showmolalloading = false;
                    });
            }
        },
    },
};
</script>
