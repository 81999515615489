<template>
  <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
    <template slot="tab-content-event">
      <spirometria-view-component @update="onUpdateJsonData"></spirometria-view-component>
    </template>
  </eventi-view-component>
</template>

<script>
import SpirometriaViewComponent from "../components/SpirometriaViewComponent.vue";
// import axios from "axios";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  components: { EventiViewComponent, SpirometriaViewComponent },
  mixins: [UtilityMixin, EventiMixin],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      titoloEvento: "Spirometria",
      linkback: null,
      linkedit: null,
      jsonData: {},
      pathResourceFirma: "/pazienteeventispirometrie",
      pathResource: "/pazienteeventispirometrie",
      tabIndex: 0,
      id: null,
      pathRestValidation: "/validation",
      isThisFirmato: false,
      showModalLoading: false,
      linkPrintData: null,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.id = this.$route.params.id;
  },

  methods: {
    updateStatoFirma(firmato) {
      console.log(firmato);
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
      // console.log(me.jsonData);
      me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
    },
  },
};
</script>

<style></style>
