<template>
    <div class="sa-tab-scheda">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="E.C.G. A RIPOSO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                                    <label class="sa-label-data">Freq. media</label>
                                    <span class="sa-data">{{ data.frequenzaMediaRiposo }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                                    <label class="sa-label-data">PR</label>
                                    <span class="sa-data">{{ data.prRiposo }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                                    <label class="sa-label-data">QT</label>
                                    <span class="sa-data">{{ data.qtRiposo }}</span>
                                </b-col>
                            </b-row>
                            <hr />
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">Referto</label>
                                    <span class="sa-data">{{ data.refertoRiposo }}</span>
                                </b-col>
                            </b-row>
                            <hr />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="E.C.G. DOPO SFORZO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                                    <label class="sa-label-data">Freq. media</label>
                                    <span class="sa-data">{{ data.frequenzaMediaDopoSforzo }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                                    <label class="sa-label-data">PR</label>
                                    <span class="sa-data">{{ data.prDopoSforzo }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                                    <label class="sa-label-data">QT</label>
                                    <span class="sa-data">{{ data.qtDopoSforzo }}</span>
                                </b-col>
                            </b-row>
                            <hr />
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">Referto</label>
                                    <span class="sa-data">{{ data.refertoDopoSforzo }}</span>
                                </b-col>
                            </b-row>
                            <hr />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="4" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="INDICE RAPIDO DI IDONEITA'" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                                    <label class="sa-label-data">Durata eser.(Sec.)</label>
                                    <span class="sa-data">{{ data.durataEsercizio }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                                    <label class="sa-label-data">Num. battiti da 60" a 90"</label>
                                    <span class="sa-data">{{ data.numeroBattiti }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                                    <label class="sa-label-data">I.R.I.</label>
                                    <span class="sa-data">{{ data.iri }}</span>
                                </b-col>
                            </b-row>
                            <hr />
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-card class="sa-card" header="FIRMA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-data">{{ data.firma }}</span>
                </b-col>
            </b-row>
        </b-card>
        <div class="sa-tab-scheda-row-fill" header="" header-tag="header" footer-tag="footer" title="">
            <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <cardioline-richieste-list-component :idRisorsa="idRisorsa" @updateBase64="onUpdateBase64" :btnResendVisible="false"></cardioline-richieste-list-component>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <!-- <b-embed type="iframe" src="http://192.168.16.201/EcgWebApp/Ecg/EditReport?id=18" allowfullscreen></b-embed> -->
        <!-- <b-embed type="iframe" :src="file" allowfullscreen></b-embed> -->
    </div>
</template>
<script>
import CardiolineRichiesteListComponent from "../../integrazioni/cardioline/components/CardiolineRichiesteListComponent.vue";
export default {
    props: {
        idRisorsa: {
            type: String,
            require: true,
        },
        data: {
            Type: Object,
            default: function () {
                return {
                    frequenzaMediaRiposo: null,
                    prRiposo: null,
                    qtRiposo: null,
                    refertoRiposo: "",
                    frequenzaMediaDopoSforzo: null,
                    prDopoSforzo: null,
                    qtDopoSforzo: null,
                    refertoDopoSforzo: "",
                    durataEsercizio: null,
                    numeroBattiti: null,
                    iri: null,
                };
            },
        },
    },
    components: { CardiolineRichiesteListComponent },
    methods: {
        onUpdateBase64() {},
    },
};
</script>
