import Main from "./components/Main.vue";
import Login from "./components/Login.vue";
import NotFound from "./components/NotFound.vue";
import DashboardAslNa1 from "./components/DashboardAslNa1.vue";

export default {
    Main,
    Login,
    NotFound,
    DashboardAslNa1
};
