<template>
    <div class="sa-tab-scheda">
        <div class="sa-tab-scheda-row-fill" header="" header-tag="header" footer-tag="footer" title="">
            <b-row style="height: 100%">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-card class="sa-card" header="Anamnesi" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                                <label class="sa-label-data">Anamnesi Familiare</label>
                                <span class="sa-data">{{ data.anamnesiFamiliare }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                                <label class="sa-label-data">Anamnesi Fisiologica</label>
                                <span class="sa-data">{{ data.anamnesiFisiologica }}</span>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Fumo</label>
                                <span class="sa-data">{{ data.fumo }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Alcool</label>
                                <span class="sa-data">{{ data.alcool }}</span>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                                <label class="sa-label-data">Anamnesi Patologica malettie sofferte</label>
                                <span class="sa-data">{{ data.anamnesiPatologicaMalattieSofferte }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                                <label class="sa-label-data">Interventi Chirurgici</label>
                                <span class="sa-data">{{ data.interventiChirurgici }}</span>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <label class="sa-label-data">Infortuni</label>
                                        <span class="sa-data">{{ data.infortuni }}</span>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                        <label class="sa-label-data">Firma</label>
                                        <span class="sa-data">{{ data.firma }}</span>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    anamnesiFamiliare: "",
                    anamnesiFisiologica: "",
                    fumo: "",
                    alcool: "",
                    anamnesiPatologicaMalattieSofferte: "",
                    interventiChirurgici: "",
                    infortuni: "",
                };
            },
        },
    },
    data() {
        return {
            pathRestFirma: "/certificatianamnesi",
        };
    },
};
</script>
