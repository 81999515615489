import DataAnalysisTablesListPage from "./pages/DataAnalysisTablesListPage.vue";
import DataAnalysisTablesEditPage from "./pages/DataAnalysisTablesEditPage.vue";
import DataAnalysisQueryListPage from "./pages/DataAnalysisQueryListPage.vue";
import DataAnalysisQueryEditPage from "./pages/DataAnalysisQueryEditPage.vue";

export default {
    DataAnalysisTablesListPage,
    DataAnalysisTablesEditPage,
    DataAnalysisQueryListPage,
    DataAnalysisQueryEditPage,
};
