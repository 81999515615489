<template>
  <!-- <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="isThisFirmato" :btnAnnullaVisible="false"
        :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="jsonData"
        class="sa-no-space">
        <template slot="toolbar">
        </template>
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header">
            <h3 class="sa-event-title">Test Provocativi</h3>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <test-provocativi-view-component :idEvento="id"></test-provocativi-view-component>
            </div>
        </template>
    </sa-page-layout> -->
  <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
    <template slot="tab-content-event">
      <test-provocativi-view-component :idEvento="id" @update="onUpdateJsonData"></test-provocativi-view-component>
    </template>
  </eventi-view-component>
</template>
<script>
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixins from "../../../utility/mixins/EventiMixins";
import TestProvocativiViewComponent from "./TestProvocativiViewComponent.vue";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";

export default {
  mixins: [UtilityMixins, EventiMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { TestProvocativiViewComponent, EventiViewComponent },
  data() {
    return {
      titoloEvento: this.getLabelTraduora("patient.menu.cardiology.menuProvocativeTest"),
      linkback: null,
      id: null,
      pathResource: "/cardiotestprovocativi",
      pathResourceFirma: "/cardiotestprovocativi",
      jsonData: {},
      tabIndex: 0,
      showModalLoading: false,
      fontScale: 2,
      linkedit: null,
      pathRestValidation: "/validation",
      isThisFirmato: false,
      linkPrintData: null,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.id = this.$route.params.id;
  },
  methods: {
    updateStatoFirma(firmato) {
      console.log(firmato);
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
      me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
    },
  },
};
</script>
