<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Caso</label>
          <span class="sa-data">{{ jsonData.tipoCaso }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Inizio Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricovero Ospedaliero</label>
          <span class="sa-data">{{ jsonData.ricoveroOspedaliero }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nome Ospedale</label>
          <span class="sa-data">{{ jsonData.nomeOspedale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Ricovero</label>
          <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Dimissione</label>
          <span class="sa-data">{{ formatDate(jsonData.dataDimissione) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Protocollo Diagnostico" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Diagnosi Clinica</label>
          <span class="sa-data">{{ jsonData.diagnosiClinica }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Reazione di Wright</label>
          <span class="sa-data">{{ jsonData.reazioneWright }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Reazione</label>
          <span class="sa-data">{{ formatDate(jsonData.dataReazioneWright) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Titolo</label>
          <span class="sa-data">{{ jsonData.titolo }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ha una stalla o zona dedicata agli animali?</label>
          <span class="sa-data">{{ jsonData.proprietaStalle }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.animaliProprietaStalle" :fields="fieldsAnimali" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Sono transitati greggi di pecore negli ultimi 20 giorni?</label>
          <span class="sa-data">{{ jsonData.transitoGreggi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Esistono Stalle?</label>
          <span class="sa-data">{{ jsonData.presenzaStalle }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.animaliPresenzaStalle" :fields="fieldsAnimali" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Nei 20 giorni precedenti la comparsa della sintomatologia ha consumato uno dei seguenti alimenti?" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Latte Appena Munto</label>
          <span class="sa-data">{{ jsonData.latte }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Luogo Acquisto</label>
          <span class="sa-data">{{ jsonData.latteLuogoAcquisto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Mozzarella</label>
          <span class="sa-data">{{ jsonData.mozzarella }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Luogo Acquisto</label>
          <span class="sa-data">{{ jsonData.mozzarellaLuogoAcquisto }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricotta di Pecora/Mucca</label>
          <span class="sa-data">{{ jsonData.ricottaPecoraMucca }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Luogo Acquisto</label>
          <span class="sa-data">{{ jsonData.ricottaPecoraMuccaLuogoAcquisto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Formaggi Freschi di Pecora/Mucca</label>
          <span class="sa-data">{{ jsonData.formaggiFreschi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Luogo Acquisto</label>
          <span class="sa-data">{{ jsonData.formaggiFreschiLuogoAcquisto }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Carne Cruda/Al Sangue</label>
          <span class="sa-data">{{ jsonData.carneCruda }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Luogo Acquisto</label>
          <span class="sa-data">{{ jsonData.carneCrudaLuogoAcquisto }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.contattoSanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Notifica</label>
          <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {},
  data() {
    return {
      pathResource: "/malattieinfettiveiebrucellosi",
      id: "-1",
      currentPage: 1,
      perPage: 100,
      jsonData: {
        tipoCaso: "",
        dataInizioSintomi: null,
        ricoveroOspedaliero: "",
        nomeOspedale: "",
        dataRicovero: null,
        dataDimissione: null,
        diagnosiClinica: "",
        reazioneWright: "",
        dataReazioneWright: null,
        proprietaStalle: "",
        animaliProprietaStalle: [],
        animaliPresenzaStalle: [],
        transitoGreggi: "",
        presenzaStalle: "",
        latte: "",
        latteLuogoAcquisto: "",
        mozzarella: "",
        mozzarellaLuogoAcquisto: "",
        ricottaPecoraMucca: "",
        ricottaPecoraMuccaLuogoAcquisto: "",
        formaggiFreschi: "",
        formaggiFreschiLuogoAcquisto: "",
        carneCruda: "",
        carneCrudaLuogoAcquisto: "",
        sanitarioNotificante: "",
        contattoSanitarioNotificante: "",
        dataNotifica: null,
      },
      fieldsAnimali: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Animali",
          key: "animale",
          sortable: false,
        },
        // {
        //     label: "",
        //     key: "actions",
        //     sortable: false,
        //     thStyle: "width: 4rem",
        // },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.$emit("showmodalloading", true);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.$emit("showmodalloading", false);
        })
        .catch(() => {
          me.$emit("showmodalloading", false);
        });
    },
  },
};
</script>
