<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Id Scheda:</label>
                <span class="sa-label-data">{{ jsonData.idScheda }}</span>
            </b-col>
        </b-card>
        <b-card class="sa-card" header="Triage socio sanitario" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Ora Registrazione:</label>
                    <span class="sa-label-data">{{ formatDateTime(jsonData.dataOraRegistrazione) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia:</label>
                    <span class="sa-label-data">{{ jsonData.provinciaRegistrazioneDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune:</label>
                    <span class="sa-label-data">{{ jsonData.comuneRegistrazioneDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Area/Centro Assistenza:</label>
                    <span class="sa-label-data">{{ jsonData.areaCentroAssistenza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Fornitore Informazioni:</label>
                    <span class="sa-label-data">{{ jsonData.informazioniSchedaFornita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Fornitore Informazioni Dettaglio:</label>
                    <span class="sa-label-data">{{ jsonData.informazioniSchedaFornitaAltro }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome Sanitario Compilatore:</label>
                    <span class="sa-label-data">{{ jsonData.operatoreNome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome Sanitario Compilatore:</label>
                    <span class="sa-label-data">{{ jsonData.operatoreCognome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Associazione Sanitario Compilatore:</label>
                    <span class="sa-label-data">{{ jsonData.operatoreAssociazioneVolontariato }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Assistito" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoNome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoCognome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Nascita:</label>
                    <span class="sa-label-data">{{ formatDate(jsonData.assistitoDataNascita) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia Nascita:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoProvinciaNascitaDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune Nascita:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoComuneNascitaDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provincia Residenza:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoProvinciaResidenzaDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Comune Residenza:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoComuneResidenzaDescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Indirizzo:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoIndirizzo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Fiscale:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoCodiceFiscale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoTelefono }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Animali Domestici:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoAnimaliDomestici }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Animali Domestici:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoAnimaliDomesticiNumero }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Medico di Famiglia:</label>
                    <span class="sa-label-data">{{ jsonData.asssititoMmg }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">ASL:</label>
                    <span class="sa-label-data">{{ jsonData.assistitoAsl }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatto Necessita'" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Nome:</label>
                    <span class="sa-label-data">{{ jsonData.personaContattareNecessitaNome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Cognome:</label>
                    <span class="sa-label-data">{{ jsonData.personaContattareNecessitaCognome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Qualifica:</label>
                    <span class="sa-label-data">{{ jsonData.personaContattareNecessitaQualifica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Telefono:</label>
                    <span class="sa-label-data">{{ jsonData.personaContattareNecessitaTelefono }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="Comportamenti" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.comportamentoCollaborante" :checked="jsonData.comportamentoCollaborante" switch disabled>Collaborante</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.comportamentoNonCollaborante" :checked="jsonData.comportamentoNonCollaborante" switch disabled>Non collaborante</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.comportamentoViolento" :checked="jsonData.comportamentoViolento" switch disabled>Violento</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                <b-card class="sa-card" header="Tipo di disabilità oggettiva" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaMotoria" :checked="jsonData.tipoDisabilitaOggettivaMotoria" switch disabled>Motoria</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaUditiva" :checked="jsonData.tipoDisabilitaOggettivaUditiva" switch disabled>Uditiva</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaVisiva" :checked="jsonData.tipoDisabilitaOggettivaVisiva" switch disabled>Visiva</b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-row>
                                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaAltro" :checked="jsonData.tipoDisabilitaOggettivaAltro" switch disabled>Altro</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.tipoDisabilitaOggettivaAltroTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <b-form-checkbox v-model="jsonData.tipoDisabilitaOggettivaCognitiva" :checked="jsonData.tipoDisabilitaOggettivaCognitiva" switch disabled>Cognitiva</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-card class="sa-card" header="Valutazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Valutazione:</label>
                    <span class="sa-label-data">{{ jsonData.codiceValutazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">Descrizione Valutazione:</label>
                    <span class="sa-data">{{ descrizioneValutazione }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note:</label>
                    <span class="sa-label-data">{{ jsonData.codiceValutazioneNote }}</span>
                </b-col>
            </b-row>
        </b-card>
        SEZIONE 2 - COMPETENZE INFERMIERISTICHE
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="In carico ai servizi sociali" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziAssistenzaInfermieristicaDomiciliare" :checked="jsonData.caricoServiziAssistenzaInfermieristicaDomiciliare" switch disabled>Assistenza Infermieristica Domiciliare</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziAssistenzaSociale" :checked="jsonData.caricoServiziAssistenzaSociale" switch disabled>Assistenza Sociale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziSert" :checked="jsonData.caricoServiziSert" switch disabled>SerT (Tossicodipendenza)</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziCentroSaluteMentale" :checked="jsonData.caricoServiziCentroSaluteMentale" switch disabled>Centro Salute Mentale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.caricoServiziServizioPsicologia" :checked="jsonData.caricoServiziServizioPsicologia" switch disabled>Servizio di Psicologia</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.caricoServiziAltro" :checked="jsonData.caricoServiziAltro" switch disabled>Altro</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.caricoServiziAltroTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="note infermieristiche" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"><i>Si richiede valutazione medica per visita:</i></b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.noteInfermieristichePrescrizioneFarmaci" :checked="jsonData.noteInfermieristichePrescrizioneFarmaci" switch disabled>Prescrizione farmaci quotidiani per patologie croniche</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.noteInfermieristichePediatrica" :checked="jsonData.noteInfermieristichePediatrica" switch disabled>Pediatrica</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.noteInfermieristicheInfettivologa" :checked="jsonData.noteInfermieristicheInfettivologa" switch disabled>Infettivologa</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.noteInfermieristichePsicologica" :checked="jsonData.noteInfermieristichePsicologica" switch disabled>Psicologica</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.noteInfermieristicheAltro" :checked="jsonData.noteInfermieristicheAltro" switch disabled>Altro</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.noteInfermieristicheAltroTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="patologie in corso" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoIntolleranzeAlimentari" :checked="jsonData.patologieCorsoIntolleranzeAlimentari" switch disabled>Intolleranze Alimentari, Farmaci/Allergie</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.patologieCorsoIntolleranzeAlimentariTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoNefropatia" :checked="jsonData.patologieCorsoNefropatia" switch disabled>Nefropatia</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.patologieCorsoNefropatiaTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoFistole" :checked="jsonData.patologieCorsoFistole" switch disabled>Fistole</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.patologieCorsoFistoleTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoMetabolica" :checked="jsonData.patologieCorsoMetabolica" switch disabled>Metabolica</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.patologieCorsoMetabolicaTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoCardiopatia" :checked="jsonData.patologieCorsoCardiopatia" switch disabled>Cardiopatia</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.patologieCorsoCardiopatiaTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoRespiratoria" :checked="jsonData.patologieCorsoRespiratoria" switch disabled>Respiratoria</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.patologieCorsoRespiratoriaTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.patologieCorsoOssigenoTerapia" :checked="jsonData.patologieCorsoOssigenoTerapia" switch disabled>O2 TP</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.patologieCorsoOssigenoTerapiaTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="assunzione farmaci" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.assunzioneFarmaciSi" :checked="jsonData.assunzioneFarmaciSi" switch disabled>Si (indicare il nome del Farmaco)</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.assunzioneFarmaciSiTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                    <b-form-checkbox v-model="jsonData.assunzioneFarmaciSiSconosciuti" :checked="jsonData.assunzioneFarmaciSi" switch disabled>Si (se non ricorda, indicare la funzione)</b-form-checkbox>
                                </b-col>
                                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                                    <span class="sa-label-data">{{ jsonData.assunzioneFarmaciSiSconosciutiTesto }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="Ausili per l'incontinenza" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliIncontinenzaCatetereVescicale" :checked="jsonData.ausiliIncontinenzaCatetereVescicale" switch disabled>Catetere Vescicale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliIncontinenzaSacchettoStomie" :checked="jsonData.ausiliIncontinenzaSacchettoStomie" switch disabled>Sacchetto per stomie</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliIncontinenzaPresidiAssorbenti" :checked="jsonData.ausiliIncontinenzaPresidiAssorbenti" switch disabled>Presidi Assorbenti</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="Ausili per il movimento" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoSediaMotorizzata" :checked="jsonData.ausiliMovimentoSediaMotorizzata" switch disabled>Sedia Motorizzata</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoSediaManuale" :checked="jsonData.ausiliMovimentoSediaManuale" switch disabled>Sedia Manuale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoSistemaPosturale" :checked="jsonData.ausiliMovimentoSistemaPosturale" switch disabled>Sistema Posturale</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoBastone" :checked="jsonData.ausiliMovimentoBastone" switch disabled>Bastone</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoDeambulatore" :checked="jsonData.ausiliMovimentoDeambulatore" switch disabled>Deambulatore</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.ausiliMovimentoArtoArtificiale" :checked="jsonData.ausiliMovimentoArtoArtificiale" switch disabled>Arto Artificiale</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">E' possibile reperire in loco i farmaci necessari?:</label>
                            <span class="sa-label-data">{{ jsonData.reperimentoLocoFarmaciNecessari }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">La struttura in cui si trova l'assistito consente adeguate possibilita' di gestione delle sue necessita'?:</label>
                            <span class="sa-label-data">{{ jsonData.strutturaAssistitoGaranziaGestioneNecessita }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <!-- <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.reperimentoLocoFarmaciNecessari" :checked="jsonData.reperimentoLocoFarmaciNecessari" switch disabled>E' possibile reperire in loco i farmaci necessari?</b-form-checkbox>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-form-checkbox v-model="jsonData.strutturaAssistitoGaranziaGestioneNecessita" :checked="jsonData.strutturaAssistitoGaranziaGestioneNecessita" switch disabled>La struttura in cui si trova l'assistito consente adeguate possibilita' di gestione delle sue necessita'</b-form-checkbox>
                        </b-col>
                    </b-row>
                </b-card> -->
            </b-col>
        </b-row>
        <b-card class="sa-card" header="alloggiamento consigliato" header-tag="header" footer-tag="footer" title="">
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Alloggio Consigliato:</label>
                <span class="sa-label-data">{{ jsonData.alloggioConsigliato }}</span>
            </b-col>
        </b-card>
        <b-card class="sa-card" header="Rifiuto" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rifiuto:</label>
                    <b-form-select v-model="jsonData.sceltaRifiuto" :options="rifiutoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.rifiutaDestinazione" :checked="jsonData.rifiutaDestinazione" switch disabled>Rifiuto Destinazione</b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-form-checkbox v-model="jsonData.rifiutaFirmaDocumento" :checked="jsonData.rifiutaFirmaDocumento" switch disabled>Rifiuto Firma Documento</b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="jsonData.rifiutaDestinazione" class="sa-card" header="Rifiuto Destinazione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Il Sottoscritto:</label>
                    <span class="sa-label-data">{{ jsonData.sceltaRifiutoNomeCognome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Identificato Da Documento:</label>
                    <span class="sa-label-data">{{ jsonData.sceltaRifiutoNumeroDocumento }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Rifiuta il trasferimento presso struttura proposta, assumendosi ogni respnsabilita in merito</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data:</label>
                    <span class="sa-label-data">{{ formatDateTime(jsonData.sceltaRifiutoDataOra) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card v-if="jsonData.rifiutaFirmaDocumento" class="sa-card" header="Rifiuto Fima Documento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">La persona rifiuta verbalmente davanti a testimoni la destinaziomr consigliata e rifiuta di firmare il presente documento</label>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data:</label>
                    <span class="sa-label-data">{{ formatDateTime(jsonData.sceltaRifiutoDataOra) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mixins: [UtilityMixin],
    data() {
        return {
            showModalLoading: false,
            linkback: null,
            currentPage: 1,
            perPage: 100,
            id: "-1",
            descrizioneValutazione: "",
            pathResource: "/schedavalutazioneesigenzeimmediate",
            linkCodiciValutazione: "/schedavalutazioneesigenzeimmediatecodicevalutazione",
            codiciValutazione: [],
            jsonData: {
                id: "-1",
                dataOraRegistrazione: null,
                provinciaRegistrazioneDescrizione: "",
                comuneRegistrazione: "",
                areaCentroAssistenza: "",
                informazioniSchedaFornita: "",
                informazioniSchedaFornitaAltro: "",
                operatoreNome: "",
                operatoreCognome: "",
                operatoreAssociazioneVolontariato: "",
                assistitoNome: "",
                assistitoCognome: "",
                assistitoDataNascita: null,
                assistitoComuneNascita: "",
                assistitoIndirizzo: "",
                assistitoCodiceFiscale: "",
                assistitoTelefono: "",
                assistitoNumeroPersoneNucleoFamiliare: "",
                assistitoAnimaliDomestici: "",
                assistitoAnimaliDomesticiNumero: "",
                asssititoMmg: "",
                assistitoAsl: "",
                assistitoProvinciaNascitaDescrizione: "",
                assistitoProvinciaResidenzaDescrizione: "",
                assistitoComuneResidenzaCodiceIstat: "",
                personaContattareNecessitaNome: "",
                personaContattareNecessitaCognome: "",
                personaContattareNecessitaQualifica: "",
                personaContattareNecessitaTelefono: "",
                comportamentoCollaborante: false,
                comportamentoNonCollaborante: false,
                comportamentoViolento: false,
                tipoDisabilitaOggettivaMotoria: false,
                tipoDisabilitaOggettivaVisiva: false,
                tipoDisabilitaOggettivaCognitiva: false,
                tipoDisabilitaOggettivaUditiva: false,
                tipoDisabilitaOggettivaAltro: false,
                tipoDisabilitaOggettivaAltroTesto: "",
                codiceValutazione: "",
                codiceValutazioneNote: "",
                caricoServiziAssistenzaInfermieristicaDomiciliare: false,
                caricoServiziAssistenzaSociale: false,
                caricoServiziSert: false,
                caricoServiziCentroSaluteMentale: false,
                caricoServiziServizioPsicologia: false,
                caricoServiziAltro: false,
                caricoServiziAltroTesto: "",
                noteInfermieristichePrescrizioneFarmaci: false,
                noteInfermieristichePediatrica: false,
                noteInfermieristicheInfettivologa: false,
                noteInfermieristichePsicologica: false,
                noteInfermieristicheAltro: false,
                noteInfermieristicheAltroTesto: "",
                patologieCorsoIntolleranzeAlimentari: false,
                patologieCorsoIntolleranzeAlimentariTesto: "",
                patologieCorsoNefropatia: false,
                patologieCorsoNefropatiaTesto: "",
                patologieCorsoFistole: false,
                patologieCorsoFistoleTesto: "",
                patologieCorsoMetabolica: false,
                patologieCorsoMetabolicaTesto: "",
                patologieCorsoCardiopatia: false,
                patologieCorsoCardiopatiaTesto: "",
                patologieCorsoRespiratoria: false,
                patologieCorsoRespiratoriaTesto: "",
                patologieCorsoOssigenoTerapia: false,
                patologieCorsoOssigenoTerapiaTesto: "",
                assunzioneFarmaciSi: false,
                assunzioneFarmaciSiTesto: "",
                assunzioneFarmaciSiSconosciuti: false,
                assunzioneFarmaciSiSconosciutiTesto: "",
                ausiliIncontinenzaCatetereVescicale: false,
                ausiliIncontinenzaSacchettoStomie: false,
                ausiliIncontinenzaPresidiAssorbenti: false,
                ausiliMovimentoSediaMotorizzata: false,
                ausiliMovimentoSediaManuale: false,
                ausiliMovimentoSistemaPosturale: false,
                ausiliMovimentoBastone: false,
                ausiliMovimentoDeambulatore: false,
                ausiliMovimentoArtoArtificiale: false,
                reperimentoLocoFarmaciNecessari: "",
                strutturaAssistitoGaranziaGestioneNecessita: "",
                // reperimentoLocoFarmaciNecessari: false,
                // strutturaAssistitoGaranziaGestioneNecessita: false,
                alloggioConsigliato: "",
                rifiutaDestinazione: false,
                rifiutaFirmaDocumento: false,
                sceltaRifiutoDataOra: null,
            },
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadCodiceValutazione();
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.setValutazione(me.jsonData.codiceValutazione);
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadCodiceValutazione() {
            let me = this;
            me.codiciValutazione = [];
            let link = process.env.VUE_APP_PATH_API + this.linkCodiciValutazione;
            axios.get(link).then((response) => {
                me.codiciValutazione = response.data.data.list;
                me.loadData();
            });
        },

        setValutazione(value) {
            let me = this;
            me.codiciValutazione.forEach((element) => {
                if (value === element.codice) {
                    me.descrizioneValutazione = element.dettaglio;
                }
            });
        },
    },
};
</script>

<style></style>
