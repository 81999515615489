<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label
                    ><span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span></b-col
                >
            </b-row>
            <b-row>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label
                    ><span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label
                    ><span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBMI") }}</label
                    ><span class="sa-data">{{ jsonData.supCorporea }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSpecilisticUnit") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.unitaSpecialistica) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArchive") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.archivio) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArchiveNum") }}</label
                    ><span class="sa-data">{{ jsonData.narchivio }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQuality") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.qualita) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDevice") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.apparecchio) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProbe") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.sonda) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIP") }}</label
                    ><span class="sa-data">{{ jsonData.ip }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnesthetistAssistance") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.assAnest) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLocalPre-AnesthetistEvaluation')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRhythm") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.ritmo) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFC") }}</label
                    ><span class="sa-data">{{ jsonData.fc }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBloodPressure") }}</label
                    ><span class="sa-data">{{ jsonData.pas }} / {{ jsonData.pad }}</span></b-col
                >
                <b-col xs="12" sm="3" md="3" lg="3" xl="3"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOxigenSaturation") }}</label
                    ><span class="sa-data">{{ jsonData.satO2 }} / {{ jsonData.satO2Unit }}</span></b-col
                >
                <b-col xs="12" sm="11" md="11" lg="11" xl="11">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPremedication") }}</label
                    ><span class="sa-data">{{ jsonData.premedicazione }}</span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMidazolam") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.midazolam) }} / {{ jsonData.doseMidazolam }}</span></b-col
                >
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnexate") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.anexate) }} / {{ jsonData.doseAnexate }}</span></b-col
                >
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSprayLidocaine") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.lidocainaSpray) }}</span>
                </b-col>
                <b-col xs="12" sm="8" md="8" lg="8" xl="8">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOtherDrugs") }}</label
                    ><span class="sa-data">{{ jsonData.altriFarmaci }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label
                    ><span class="sa-data">{{ jsonData.complicanze }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRequester") }}</label
                    ><span class="sa-data">{{ jsonData.richiedente }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDepartment") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.reparto) }}</span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRecovery") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.ricovero) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndications") }}</label
                    ><span class="sa-data">{{ jsonData.motivoRicovero }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLeftAtriumAndAuricle')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftAtriumDiameter") }}</label
                    ><span class="sa-data">{{ jsonData.diametroML }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftAuricleArea") }}</label
                    ><span class="sa-data">{{ jsonData.areaAuricolaSin }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxOutflowSpeed") }}</label
                    ><span class="sa-data">{{ jsonData.vmaxEfflusso }}</span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSpeedPattern") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.patternVelocita) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAorticValve')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRingDiameter") }}</label
                    ><span class="sa-data">{{ jsonData.diametroAnelloVa }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsufficiency") }}</label
                    ><span class="sa-data">{{ getLabelTraduora(jsonData.insufficienzaVa) }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOutflowSectionDiameter") }}</label
                    ><span class="sa-data">{{ jsonData.diametroTrattoEfflusso }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPlanimetricArea") }}</label
                    ><span class="sa-data">{{ jsonData.areaPlanimetricaVa }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProssimalJetWidth") }}</label
                    ><span class="sa-data">{{ jsonData.ampiezzaProssJetVa }}</span>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProssimalJetTrOutflow") }}</label
                    ><span class="sa-data">{{ jsonData.jetProssimaleTrVa }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblMitralValve')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label
                    ><span class="sa-data">{{ jsonData.valvolaMitrale }}</span>
                </b-col>
                <b-col xs="12" sm="8" md="8" lg="8" xl="8">
                    <b-row>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRingDiameter4Cam") }}</label>
                            {{ jsonData.diametroAnello4camVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConvergenceArea") }}</label>
                            {{ jsonData.rareaConvergenzaVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLongAxisRingDiameter") }}</label>
                            {{ jsonData.diametroAnelloAsseLVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxRegurgitatingSpeed") }}</label>
                            {{ jsonData.velRigMaxVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPalimetricArea") }}</label>
                            {{ jsonData.areaPlanimetricaVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxEroa") }}</label>
                            {{ jsonData.eroaMaxVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAverageDiastolicGradient") }}</label>
                            {{ jsonData.gradDiastMedVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTentingArea") }}</label>
                            {{ jsonData.areaTentingVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsufficiency") }}</label>
                            {{ jsonData.insufficienzaVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCoaptationDistance") }}</label>
                            {{ jsonData.distanzaCoaptazioneVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblJetDirection") }}</label>
                            {{ getLabelTraduora(jsonData.direzioneJetVm) }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFrontFlapThickness") }}</label>
                            {{ jsonData.spessLemboAntVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProssimalJetWidth") }}</label>
                            {{ jsonData.ampiezzaProssJetVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBackFlapThickness") }}</label>
                            {{ jsonData.spessLemboPostVm }}
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAliasing") }}</label>
                            {{ jsonData.velAliasingVm }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                    <h5 class="sa-text-left">{{ getLabelTraduora("patient.cardiology.lblProlapseEvaluation") }}</h5>
                    <b-row>
                        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblA1") }}</label>
                            <span :class="setColor(jsonData.a1)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.a1)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblA2") }}</label>
                            <span :class="setColor(jsonData.a2)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.a2)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblA3") }}</label>
                            <span :class="setColor(jsonData.a3)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.a3)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblP1") }}</label>
                            <span :class="setColor(jsonData.p1)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.p1)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblP2") }}</label>
                            <span :class="setColor(jsonData.p2)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.p2)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblP3") }}</label>
                            <span :class="setColor(jsonData.p3)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.p3)"></b-icon>
                            </span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAorta')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBulb") }}</label
                    ><span class="sa-data">{{ jsonData.bulbo }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSTGiunction") }}</label
                    ><span class="sa-data">{{ jsonData.giunzioneSenoTub }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAscendingSection") }}</label
                    ><span class="sa-data">{{ jsonData.aortaAscendente }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescendingSection") }}</label
                    ><span class="sa-data">{{ jsonData.aortaDiscendente }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label
                    ><span class="sa-data">{{ jsonData.conclusioni }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {},
    components: {},
    data() {
        return {
            linkback: null,
            pathResource: "/cardioecotransesofageo",
            jsonData: {},
            tabIndex: 0,
            id: null,
            showModalLoading: false,
            fontScale: 2,
            linkedit: null,
            pathRestValidation: "/validation",
            isThisFirmato: false,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
