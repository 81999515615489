<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data insorgenza primi sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.dataInsorgenzaPrimiSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ricovero Ospedaliero</label>
          <span class="sa-data">{{ jsonData.ricoveroOspedaliero }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Ricovero</label>
          <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome Ospedale</label>
          <span class="sa-data">{{ jsonData.nomeOspedale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome Reparto</label>
          <span class="sa-data">{{ jsonData.nomeReparto }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Terapia Antivirale (Oseltamivir-Tamiflu/Zanamivir-Relenza) </label>
          <span class="sa-data">{{ jsonData.terapiaAntivirale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Inizio Terapia</label>
          <span class="sa-data">{{ formatDate(jsonData.dataInizioTerapia) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Durata Terapia (in giorni)</label>
          <span class="sa-data">{{ jsonData.durataTerapia }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Presenza Patologie Croniche</label>
          <span class="sa-data">{{ jsonData.patologieCroniche }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tumore</label>
          <span class="sa-data">{{ jsonData.tumore }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diabete</label>
          <span class="sa-data">{{ jsonData.diabete }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malattie Cardiovascolari</label>
          <span class="sa-data">{{ jsonData.malattieCardiovascolari }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Deficit Immunitari</label>
          <span class="sa-data">{{ jsonData.deficitImmunitari }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malattie Respiratorie</label>
          <span class="sa-data">{{ jsonData.malattieRespiratorie }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malattie Renali</label>
          <span class="sa-data">{{ jsonData.malattieRenali }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malattie Metaboliche</label>
          <span class="sa-data">{{ jsonData.malattieMetaboliche }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Obesita Bmi</label>
          <span class="sa-data">{{ jsonData.obesitaBmi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Obesita Bmi Intervallo</label>
          <span class="sa-data">{{ jsonData.obesitaBmiIntervallo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altra Patologia Cronica</label>
          <span class="sa-data-justify">{{ jsonData.altraPatologiaCronica }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Confermato A(H1N1)v</label>
          <span class="sa-data">{{ jsonData.confermatoAH1n1V }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Conferma A(H1N1)v</label>
          <span class="sa-data">{{ formatDate(jsonData.confermatoAH1n1VDataConferma) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Confermato A(H1N1)</label>
          <span class="sa-data">{{ jsonData.confermatoAH1n1 }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Conferma A(H1N1)</label>
          <span class="sa-data">{{ formatDate(jsonData.confermatoAH1n1DataConferma) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Confermato AH3n2</label>
          <span class="sa-data">{{ jsonData.confermatoAH3n2 }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Conferma AH3n2</label>
          <span class="sa-data">{{ formatDate(jsonData.confermatoAH3n2DataConferma) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Confermato B</label>
          <span class="sa-data">{{ jsonData.confermatoB }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Conferma B</label>
          <span class="sa-data">{{ formatDate(jsonData.confermatoBDataConferma) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Invio del campione al Laboratorio del Centro Nazionale Influenza presso ISS</label>
          <span class="sa-data">{{ jsonData.invioCampioneLaboratorioIss }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Complicanze</label>
          <span class="sa-data">{{ jsonData.complicanze }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Complicanza</label>
          <span class="sa-data">{{ formatDate(jsonData.dataComplicanze) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Radiografia Eseguita</label>
          <span class="sa-data">{{ jsonData.radiografiaEseguita }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Polmonite Influenzale Primaria</label>
          <span class="sa-data">{{ jsonData.polmoniteInfluenzalePrimaria }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Polmonite Batterica Secondaria</label>
          <span class="sa-data">{{ jsonData.polmoniteBattericaSecondaria }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Polmonite Mista</label>
          <span class="sa-data">{{ jsonData.polmoniteMista }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Grave insufficienza respiratoria acuta (SARI)</label>
          <span class="sa-data">{{ jsonData.graveInsufficienzaRespiratoriaAcuta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Acute Respiratory Distress Syndrome (ARDS)</label>
          <span class="sa-data">{{ jsonData.acuteRespiratoryDistressSyndrome }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altre Complicanze</label>
          <span class="sa-data-justify">{{ jsonData.altraComplicanza }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Guarigione</label>
          <span class="sa-data">{{ formatDate(jsonData.dataGuarigione) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Decesso</label>
          <span class="sa-data">{{ formatDate(jsonData.dataDecesso) }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Causa Iniziale</label>
          <span class="sa-data-justify">{{ jsonData.certifdicatoMorteCausaIniziale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Causa Terminale</label>
          <span class="sa-data-justify">{{ jsonData.certifdicatoMorteCausaTerminale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altri stati morbosi rilevanti che hanno contribuito al decesso</label>
          <span class="sa-data-justify">{{ jsonData.certifdicatoMorteAltriStatiMorbosi }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.telefonoSanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Notifica</label>
          <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveieinfluenza",
      id: "-1",
      jsonData: {
        dataInsorgenzaPrimiSintomi: null,
        ricoveroOspedaliero: null,
        dataRicovero: null,
        nomeOspedale: "",
        nomeReparto: "",
        terapiaAntivirale: null,
        dataInizioTerapia: null,
        durataTerapia: 0,
        patologieCroniche: null,
        tumore: null,
        diabete: null,
        malattieCardiovascolari: null,
        deficitImmunitari: null,
        malattieRespiratorie: null,
        malattieRenali: null,
        malattieMetaboliche: null,
        obesitaBmi: null,
        obesitaBmiIntervallo: null,
        altraPatologiaCronica: "",
        confermatoAH1n1V: null,
        confermatoAH1n1: null,
        confermatoAH3n2: null,
        confermatoB: null,
        confermatoAH1n1VDataConferma: null,
        confermatoAH1n1DataConferma: null,
        confermatoAH3n2DataConferma: null,
        confermatoBDataConferma: null,
        invioCampioneLaboratorioIss: null,
        complicanze: null,
        dataComplicanze: null,
        radiografiaEseguita: null,
        polmoniteInfluenzalePrimaria: null,
        polmoniteBattericaSecondaria: null,
        polmoniteMista: null,
        graveInsufficienzaRespiratoriaAcuta: null,
        acuteRespiratoryDistressSyndrome: null,
        altraComplicanza: "",
        dataGuarigione: null,
        dataDecesso: null,
        certifdicatoMorteCausaIniziale: "",
        certifdicatoMorteCausaTerminale: "",
        certifdicatoMorteAltriStatiMorbosi: "",
        sanitarioNotificante: "",
        telefonoSanitarioNotificante: "",
        dataNotifica: null,
      },
    };
  },
  computed: {},
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.$emit("showmodalloading", true);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.$emit("showmodalloading", false);
        })
        .catch(() => {
          me.$emit("showmodalloading", false);
        });
    },
  },
};
</script>
