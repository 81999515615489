<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCV") }}
                        <eventi-storico-misure-component misura="cv" misuraLabel="Cv" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.cv" step="0.01" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCVF") }}
                        <eventi-storico-misure-component misura="cvf" misuraLabel="Cvf" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.cvf" step="0.01" :disabled="!edit"></b-form-input>
                    <br />
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblVEMS") }}
                        <eventi-storico-misure-component misura="vems" misuraLabel="Vems" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.vems" step="0.01" :disabled="!edit"></b-form-input>
                    <br />
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblVemsCvf") }}
                        <eventi-storico-misure-component misura="indice_tiffeneau" misuraLabel="Indice Tiffeneau" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.indiceTiffeneau" step="0.01" :disabled="!edit"></b-form-input>
                    <br />
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="2" xl="2">
                    <span class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblMVV") }}
                        <eventi-storico-misure-component misura="mvv" misuraLabel="Mvv" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </span>
                    <b-form-input type="number" v-model="data.mvv" step="0.01" :disabled="!edit"></b-form-input>
                    <br />
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="2" xl="2">
                    <span class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCVNormal") }}
                        <eventi-storico-misure-component misura="normale_cv" misuraLabel="normaleCv" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </span>
                    <b-form-input type="number" v-model="data.normaleCv" step="0.01" :disabled="!edit"></b-form-input>
                    <br />
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="2" xl="2">
                    <span class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCVFNormal") }}
                        <eventi-storico-misure-component misura="normale_cvf" misuraLabel="NormaleCvf" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </span>
                    <b-form-input type="number" v-model="data.normaleCvf" step="0.01" :disabled="!edit"></b-form-input>
                    <br />
                </b-col>
                <b-col>
                    <span class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblVEMSNormal") }}
                        <eventi-storico-misure-component misura="normale_vems" misuraLabel="Normale Vems" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </span>
                    <b-form-input type="number" v-model="data.normaleVems" step="0.01" :disabled="!edit"></b-form-input>
                    <br />
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="2" xl="2">
                    <span class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblVemsCvfNormal") }}
                        <eventi-storico-misure-component misura="normale IndiceTiffeneau" misuraLabel="Normale Vems" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </span>
                    <b-form-input type="number" v-model="data.normaleIndiceTiffeneau" step="0.01" :disabled="!edit"></b-form-input>
                    <br />
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="2" xl="2">
                    <span class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblMVVNormal") }}
                        <eventi-storico-misure-component misura="normale_mvv" misuraLabel="Normale Mvv" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </span>
                    <b-form-input type="number" v-model="data.normaleMvv" step="0.01" :disabled="!edit"></b-form-input>
                    <br />
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="conclusioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-form-textarea v-model="data.conclusioniSpirografia" type="text" no-resize :disabled="!edit"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("therapy.lblStatus") }}</label>
                    <span class="sa-data">{{ data.firma }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { EventiStoricoMisureComponent },
    props: ["data", "edit", "tipoEvento", "gruppo"],
    data() {
        return {};
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.data.idPaziente;
        },
    },

    methods: {},
};
</script>
