import Test from "../";

const routes = [
    {
        name: "TestList",
        path: "/testlist",
        component: Test.TestList,
    },
];

export default routes;
