<template>
    <sa-page-layout :titolo="titolo" :pathResource="pathResource" :linkedit="linkedit" :btnRefreshVisible="true" :btnNewVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6" lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(reportName)="{ item }">
                        <router-link class="sa-edit-link" :to="'/printserver/view/' + item.id">{{ item.reportName }}</router-link>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-button variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6" lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    mounted() {
        let me = this;
        me.loadData();
    },
    data() {
        return {
            currentPage: 1,
            perPage: 30,
            rows: 0,
            titolo: "Print Server List",
            showModalLoading: false,
            linkedit: "/printserver",
            pathResource: "/printserver",
            filtro: {},
            items: [],
            fields: [
                {
                    label: "Report Name",
                    key: "reportName",
                    sortable: true,
                },
                {
                    label: "nome File",
                    key: "reportName",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Versione",
                    key: "versione",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.forPage = me.perPage;
            me.filtro.page = me.currentPage;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onDeleteItem(row) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
            axios
                .delete(link)
                .then(() => {
                    me.showModalLoading = false;
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
