<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-row>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblClinicalData") }}</h5>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza" switch :checked="jsonData.datoDiEmergenza" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblInfectiveDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice" type="text"></b-form-input>
                    <b-button primary @click="onClickBtnRicercaIcdIx(1)">
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza2" switch :checked="jsonData.datoDiEmergenza2" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblCardiovascularDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione2" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice2" type="text"></b-form-input>

                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi2" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza3" switch :checked="jsonData.datoDiEmergenza3" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblRespiratoryDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione3" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice3" type="text"></b-form-input>

                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi3" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza4" switch :checked="jsonData.datoDiEmergenza4" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblDigestiveSystemDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione4" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice4" type="text"></b-form-input>
                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi4" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza5" switch :checked="jsonData.datoDiEmergenza5" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblUrinarySystemDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione5" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice5" type="text"></b-form-input>

                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi5" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza6" switch :checked="jsonData.datoDiEmergenza6" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblHemopathies") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione6" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice6" type="text"></b-form-input>

                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi6" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza7" switch :checked="jsonData.datoDiEmergenza7" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblNervousSystemDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione7" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice7" type="text"></b-form-input>

                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi7" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza8" switch :checked="jsonData.datoDiEmergenza8" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblVenerealDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione8" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice8" type="text"></b-form-input>

                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi8" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza9" switch :checked="jsonData.datoDiEmergenza9" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblNeoplasticDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione9" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice9" type="text"></b-form-input>

                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi9" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza10" switch :checked="jsonData.datoDiEmergenza10" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblTrauma") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione10" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice10" type="text"></b-form-input>
                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi10" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza11" switch :checked="jsonData.datoDiEmergenza11" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblMetabolicDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione11" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice11" type="text"></b-form-input>
                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi11" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza12" switch :checked="jsonData.datoDiEmergenza12" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblTransfusionalReactions") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione12" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice12" type="text"></b-form-input>
                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi12" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza13" switch :checked="jsonData.datoDiEmergenza13" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblDiabetes") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione13" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice13" type="text"></b-form-input>
                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi13" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza14" switch :checked="jsonData.datoDiEmergenza14" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblGlaucoma") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione14" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice14" type="text"></b-form-input>
                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi14" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza15" switch :checked="jsonData.datoDiEmergenza15" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblEndocrineDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione15" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice15" type="text"></b-form-input>
                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi15" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza16" switch :checked="jsonData.datoDiEmergenza16" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblImmuneSystemDiseases") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                <b-form-input v-model="jsonData.definizione24" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                <b-input-group>
                    <b-form-input v-model="jsonData.codice24" type="text"></b-form-input>
                    <b-button primary>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDiagnosi24" type="datetime"></date-picker>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblProcedureType") }}</h5>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza17" switch :checked="jsonData.datoDiEmergenza17" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblDialyticTreatment") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza18" switch :checked="jsonData.datoDiEmergenza18" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblOrganExplant") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza19" switch :checked="jsonData.datoDiEmergenza19" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblOrganTransplant") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza20" switch :checked="jsonData.datoDiEmergenza20" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblRemovableProthesis") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-checkbox v-model="jsonData.datoDiEmergenza21" switch :checked="jsonData.datoDiEmergenza21" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblPacemaker") }} </b-form-checkbox>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblAllergies") }}</h5>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-select v-model="jsonData.datoDiEmergenza22" :options="optionsAllergie">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-select v-model="jsonData.datoDiEmergenza23" :options="optionsAllergie">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <b-form-select v-model="jsonData.datoDiEmergenza24" :options="optionsAllergie">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBloodGroup") }}</label>
                <b-form-select v-model="jsonData.gruppoSanguigno" :options="optionsGruppoSangue">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRhFactor") }}</label>
                <b-form-select v-model="jsonData.fattoreRh" :options="optionsRH">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLastTransfusionDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataUltimaTrasfusione" type="datetime"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                <b-form-textarea v-model="jsonData.altro" rows="5" max-rows="5" no-resize></b-form-textarea>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblPharmacologicalData") }}</h5>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco" switch :checked="jsonData.tipoDiFarmaco" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAntiarrhythmics") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco2" switch :checked="jsonData.tipoDiFarmaco2" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAnticoagulants") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione2" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco3" switch :checked="jsonData.tipoDiFarmaco3" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAnticonvulsants") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione3" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco4" switch :checked="jsonData.tipoDiFarmaco4" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblOralAntidiabetics") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione4" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco5" switch :checked="jsonData.tipoDiFarmaco5" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAntihistamines") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione5" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco6" switch :checked="jsonData.tipoDiFarmaco6" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAntihypertensives") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione6" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco7" switch :checked="jsonData.tipoDiFarmaco7" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblBetaBlockers") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione7" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco8" switch :checked="jsonData.tipoDiFarmaco8" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblCorticosteroids") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione8" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco9" switch :checked="jsonData.tipoDiFarmaco9" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblCytotoxicsCytostatics") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione9" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco10" switch :checked="jsonData.tipoDiFarmaco11" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblDigitalis") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione10" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco11" switch :checked="jsonData.tipoDiFarmaco12" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblDiuretics") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione11" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco12" switch :checked="jsonData.tipoDiFarmaco12" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblInsuline") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione12" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco13" switch :checked="jsonData.tipoDiFarmaco14" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblMonoamineOxidaseInhibitors") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione13" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco14" switch :checked="jsonData.tipoDiFarmaco15" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblPsychotropicDrugs") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione14" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco15" switch :checked="jsonData.tipoDiFarmaco15" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblOther") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                <b-form-input v-model="jsonData.nomeFarmaco15" type="text" :disabled="!jsonData.tipoDiFarmaco15"> </b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione15" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <b-form-checkbox v-model="jsonData.tipoDiFarmaco16" switch :checked="jsonData.tipoDiFarmaco16" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblPsychotropicDrugs") }} </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label>
                <b-form-select v-model="jsonData.tipoDiAssunzione16" :options="optionsAssunzione">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOpticalPrescription") }}</label>
                <b-form-input v-model="jsonData.prescrizioniOttiche" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPrescriptionDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataPrescrizioniOttiche" type="datetime"></date-picker>
            </b-col>
        </b-row>
        <b-modal ref="mdlCodiciIcdIx" id="mdlCodiciIcdIx" title="CODICI ICD-IX" size="xl" @ok="onOkMdlCodiciIcdIx">
            <codici-icd-ix-list-component @update="getSelectedRow"></codici-icd-ix-list-component>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import CodiciIcdIxListComponent from "../../../../../configurazione/components/CodiciIcdIxListComponent.vue";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker, CodiciIcdIxListComponent },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    datoDiEmergenza22: null,
                    datoDiEmergenza23: null,
                    datoDiEmergenza24: null,
                    gruppoSanguigno: null,
                    fattoreRh: null,
                    tipoDiAssunzione: null,
                    tipoDiAssunzione2: null,
                    tipoDiAssunzione3: null,
                    tipoDiAssunzione4: null,
                    tipoDiAssunzione5: null,
                    tipoDiAssunzione6: null,
                    tipoDiAssunzione7: null,
                    tipoDiAssunzione8: null,
                    tipoDiAssunzione9: null,
                    tipoDiAssunzione10: null,
                    tipoDiAssunzione11: null,
                    tipoDiAssunzione12: null,
                    tipoDiAssunzione13: null,
                    tipoDiAssunzione14: null,
                    tipoDiAssunzione15: null,
                    tipoDiAssunzione16: null,
                };
            },
        },
    },

    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            indexSelezionato: 0,
            showModalLoading: false,
            pathResource: "/cardioemergenze",
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            rigaSelezionata: [],
            optionsAllergie: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAntibiotics"), value: this.getLabelTraduora("patient.cardiology.Options.lblAntibiotics") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAnalgesics"), value: this.getLabelTraduora("patient.cardiology.Options.lblAnalgesics") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblDriedFruits"), value: this.getLabelTraduora("patient.cardiology.Options.lblDriedFruits") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblIodine"), value: this.getLabelTraduora("patient.cardiology.Options.lblIodine") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblCitrusFruits"), value: this.getLabelTraduora("patient.cardiology.Options.lblCitrusFruits") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblMilk"), value: this.getLabelTraduora("patient.cardiology.Options.lblMilk") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAnimalFur"), value: this.getLabelTraduora("patient.cardiology.Options.lblAnimalFur") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblFishesCrostaceans"), value: this.getLabelTraduora("patient.cardiology.Options.lblFishesCrostaceans") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblEggs"), value: this.getLabelTraduora("patient.cardiology.Options.lblEggs") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblDust"), value: this.getLabelTraduora("patient.cardiology.Options.lblDust") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPollen"), value: this.getLabelTraduora("patient.cardiology.Options.lblPollen") },
            ],
            optionsGruppoSangue: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblZero"), value: this.getLabelTraduora("patient.cardiology.Options.lblZero") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblA"), value: this.getLabelTraduora("patient.cardiology.Options.lblA") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblB"), value: this.getLabelTraduora("patient.cardiology.Options.lblB") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAB"), value: this.getLabelTraduora("patient.cardiology.Options.lblAB") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblUnknown"), value: this.getLabelTraduora("patient.cardiology.Options.lblUnknown") },
            ],
            optionsRH: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNegative"), value: this.getLabelTraduora("patient.cardiology.Options.lblNegative") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPositive"), value: this.getLabelTraduora("patient.cardiology.Options.lblPositive") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblUnknown"), value: this.getLabelTraduora("patient.cardiology.Options.lblUnknown") },
            ],
            optionsAssunzione: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblSuspended"), value: this.getLabelTraduora("patient.cardiology.Options.lblSuspended") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPrevious"), value: this.getLabelTraduora("patient.cardiology.Options.lblPrevious") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNotContinuous"), value: this.getLabelTraduora("patient.cardiology.Options.lblNotContinuous") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblContinuous"), value: this.getLabelTraduora("patient.cardiology.Options.lblContinuous") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblInProgress"), value: this.getLabelTraduora("patient.cardiology.Options.lblInProgress") },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        getSelectedRow(row) {
            let me = this;
            me.rigaSelezionata = row;
        },
        onClickBtnRicercaIcdIx(index) {
            let me = this;
            me.indexSelezionato = index;
            this.$refs.mdlCodiciIcdIx.show();
        },
        onOkMdlCodiciIcdIx() {
            let me = this;
            switch (me.indexSelezionato) {
                case 1:
                    console.log(me.indexSelezionato);
                    me.jsonData.codice = me.rigaSelezionata[0].codiceIcdix;
                    me.jsonData.definizione = me.rigaSelezionata[0].descrizioneIcdix;
                    break;
            }
        },
        // checkAltro() {
        //     console.log(!this.jsonData.tipoDiFarmaco16);
        // },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
