<template>
  <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Tipo Caso</label>
              <b-form-select v-model="jsonData.tipoCaso" :options="tipoCasiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Data Inizio Sintomi</label>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataInizioSintomi" type="date"></date-picker>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Ricovero Ospedaliero</label>
              <b-form-select v-model="jsonData.ricoveroOspedaliero" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputRicoveroOspedaliero"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Nome Ospedale</label>
              <b-form-input v-model="jsonData.nomeOspedale" :disabled="disabledValue.ricoveroOspedalieroDisabled"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Data Ricovero</label>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataRicovero" type="date" :disabled="disabledValue.ricoveroOspedalieroDisabled"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Data Dimissione</label>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataDimissione" type="date" :disabled="disabledValue.ricoveroOspedalieroDisabled"></date-picker>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <h4>Protocollo Diagnostico</h4>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Diagnosi Clinica</label>
              <b-form-select v-model="jsonData.diagnosiClinica" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Coprocultura</label>
              <b-form-select v-model="jsonData.coprocultura" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">In Famiglia Qualcuno Ha Già Sofferto Di Questa Malattia?</label>
              <b-form-select v-model="jsonData.salmonellosiFamiliari" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
          <b-row style="margin-top: 5px">
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaFamiliari" :fields="fieldsFamiliari" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(tipoFamiliare)="item">
                  <b-form-input v-model="item.item.tipoFamiliare"></b-form-input>
                </template>
                <template #cell(dataMalattia)="item">
                  <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.dataMalattia" type="date"></date-picker>
                </template>
                <template #cell(actions)="row">
                  <b-button v-if="row.index == jsonData.listaFamiliari.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFamiliari(jsonData.listaFamiliari, row.item)">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                  <b-button v-if="row.index !== jsonData.listaFamiliari.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaFamiliari, row)">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Abitualmente Consuma Verdure Crude</label>
              <b-form-select v-model="jsonData.consumoVerdureCrude" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Nei due/tre giorni precedenti la malattia ha consumato uova crude o poco cotte o alimenti a base di uova?</label>
              <b-form-select v-model="jsonData.consumoUova" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputConsumoUova"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">Presso la propria abitazione</label>
              <b-form-select v-model="jsonData.consumoUovaAbitazione" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.consumoUovaDisabled" @input="onInputConsumoUovaAbitazione"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Dove sono state acquistate</label>
              <b-form-input v-model="jsonData.luogoAcquistoUova" :disabled="disabledValue.luogoAcquistoDisabled"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">Presso ristoranti</label>
              <b-form-select v-model="jsonData.consumoUovaRistorante" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.consumoUovaDisabled" @input="onInputConsumoUovaRistorante"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">Nome Ristorante</label>
              <b-form-input v-model="jsonData.nomeRistorante" :disabled="disabledValue.ristoranteDisabled"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Indirizzo Ristorante</label>
              <b-form-input v-model="jsonData.indirizzoRistorante" :disabled="disabledValue.ristoranteDisabled"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Sanitario Notificante</label>
              <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Contatto Sanitario Notificante</label>
              <b-form-input v-model="jsonData.contattoSanitarioNotificante"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Data Notifica</label>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataNotifica" type="date"></date-picker>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../paziente/components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  mixins: [UtilityMixin],
  components: { SaPageLayout, PazienteAnagrafica, DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/malattieinfettiveiesalmonellosinontifoidea",
      pathResourceTipiCasi: "/malattieinfettiveietipicasi",
      linkback: null,
      showModalLoading: false,
      id: "-1",
      currentPage: 1,
      perPage: 100,
      disabledValue: {
        ricoveroOspedalieroDisabled: true,
        consumoUovaDisabled: true,
        luogoAcquistoDisabled: true,
        ristoranteDisabled: true,
      },
      filtro: {},
      jsonData: {
        tipoCaso: "",
        dataInizioSintomi: null,
        ricoveroOspedaliero: "",
        nomeOspedale: "",
        dataRicovero: null,
        dataDimissione: null,
        diagnosiClinica: "",
        coprocultura: "",
        salmonellosiFamiliari: "",
        listaFamiliari: [],
        consumoVerdureCrude: "",
        consumoUova: "",
        consumoUovaAbitazione: "",
        luogoAcquistoUova: "",
        consumoUovaRistorante: "",
        nomeRistorante: "",
        indirizzoRistorante: "",
        sanitarioNotificante: "",
        contattoSanitarioNotificante: "",
        dataNotifica: null,
      },
      fieldsFamiliari: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
          tdClass: "text-center",
        },
        {
          label: "Familiare",
          key: "tipoFamiliare",
          sortable: false,
        },
        {
          label: "Data Malattia",
          key: "dataMalattia",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
          thStyle: "width: 13rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      tipoCasiOptions: [],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.id = this.$route.params.id;
    let malattiaInfettivaSegnalazione = sessionStorage.getItem("MalattiaInfettivaSegnalazione");
    let malattiaInfettiva = {};
    if (malattiaInfettivaSegnalazione !== null && malattiaInfettivaSegnalazione !== "null") {
      malattiaInfettiva = JSON.parse(malattiaInfettivaSegnalazione);
      me.setDatiMalattia(malattiaInfettiva);
    }
    sessionStorage.setItem("MalattiaInfettivaSegnalazione", null);
    if (me.id !== "-1") {
      me.linkback = "/malattieinfettive/indagineepidemiologica/salmonellosinontifoidea/view/" + me.id + "?idPaziente=" + me.idPaziente;
    } else {
      me.linkback = "/malattieinfettive/all";
    }
    me.loadData();
    me.loadTipiCasi();
  },
  watch: {
    "jsonData.salmonellosiFamiliari": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaFamiliari.push({ tipoFamiliare: "", dataMalattia: null });
      } else {
        me.jsonData.listaFamiliari = [];
      }
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.setDatiMedico();
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        me.showModalLoading = true;
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
    loadTipiCasi() {
      let me = this;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceTipiCasi;
      axios.get(link, { params: me.filtro }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipoCasiOptions.push({ value: element.descrizione, text: element.descrizione });
        });
        me.tipoCasiOptions.unshift({ value: "", text: "-Seleziona Tipo Caso-" });
        me.showModalLoading = false;
      });
    },
    onInputRicoveroOspedaliero(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.ricoveroOspedalieroDisabled = false;
      } else {
        me.disabledValue.ricoveroOspedalieroDisabled = true;
        me.jsonData.nomeOspedale = "";
        me.jsonData.dataRicovero = null;
        me.jsonData.dataDimissione = null;
      }
    },
    onAddFamiliari(array, value) {
      if (value.tipoFamiliare !== "" && value.dataMalattia !== null) {
        array.push({ tipoFamiliare: "", dataMalattia: null });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onInputConsumoUova(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.consumoUovaDisabled = false;
      } else {
        me.disabledValue.consumoUovaDisabled = true;
        me.jsonData.consumoUovaAbitazione = "";
        me.jsonData.consumoUovaRistorante = "";
      }
    },
    onInputConsumoUovaAbitazione(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.luogoAcquistoDisabled = false;
      } else {
        me.disabledValue.luogoAcquistoDisabled = true;
        me.jsonData.luogoAcquistoUova = "";
      }
    },
    onInputConsumoUovaRistorante(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.ristoranteDisabled = false;
      } else {
        me.disabledValue.ristoranteDisabled = true;
        me.jsonData.nomeRistorante = "";
        me.jsonData.indirizzoRistorante = "";
      }
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = me.utente.firstname + " " + me.utente.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
  },
};
</script>
