<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data insorgenza primi sintomi</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataInsorgenzaSintomi" type="timestamp" placeholder="Data Insorgenza Sintomi"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricovero Ospedaliero</label>
          <b-form-select v-model="jsonData.ricoveroOspedaliero" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputRicoveroOspedaliero"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Ricovero</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataRicovero" type="timestamp" placeholder="Data Ricovero" :disabled="ricoveroDisabled"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nome Ospedale</label>
          <b-form-input v-model="jsonData.nomeOspedale" placeholder="Nome Ospedale" :disabled="ricoveroDisabled"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Sintomatologia Clinica" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Febbre</label>
          <b-form-select v-model="jsonData.febbre" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Brividi</label>
          <b-form-select v-model="jsonData.brividi" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Cefalea</label>
          <b-form-select v-model="jsonData.cefalea" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tosse</label>
          <b-form-select v-model="jsonData.tosse" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Espettorazione</label>
          <b-form-select v-model="jsonData.espettorazione" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dispnea</label>
          <b-form-select v-model="jsonData.dispnea" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Emoftoe</label>
          <b-form-select v-model="jsonData.emoftoe" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Toracologie</label>
          <b-form-select v-model="jsonData.toracologie" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Versamento Pleurico</label>
          <b-form-select v-model="jsonData.versamentoPleurico" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ipossia</label>
          <b-form-select v-model="jsonData.ipossia" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Opacita Polmonare</label>
          <b-form-select v-model="jsonData.opacitaPolmonare" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nausea/Vomito</label>
          <b-form-select v-model="jsonData.nauseaVomito" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dolori Addominali</label>
          <b-form-select v-model="jsonData.doloriAddominali" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Diarrea</label>
          <b-form-select v-model="jsonData.diarrea" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Peritonite</label>
          <b-form-select v-model="jsonData.peritonite" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Confusione Mentale</label>
          <b-form-select v-model="jsonData.confusioneMentale" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Atassia</label>
          <b-form-select v-model="jsonData.atassia" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Afasia</label>
          <b-form-select v-model="jsonData.afasia" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Extrapiramidale</label>
          <b-form-select v-model="jsonData.extrapiramidale" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Aritmia Cardiaca</label>
          <b-form-select v-model="jsonData.aritmiaCardiaca" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pericardite</label>
          <b-form-select v-model="jsonData.pericardite" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Osteoartromialgia</label>
          <b-form-select v-model="jsonData.osteoartromialgia" :options="standardSelectOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Altre Manifestazioni Cliniche Rilevanti</label>
          <b-form-select v-model="jsonData.altreManifestazioni" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputAltreManifestazioni"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altre Manifestazioni Cliniche Dettaglio</label>
          <b-form-textarea v-model="jsonData.altreManifestazioniDettaglio" rows="10" no-resize :disabled="altreManifestazioniDettaglioDisabled"></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Malattie concomitanti</label>
          <b-form-select v-model="jsonData.malattieConcomitati" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiMalattieConcomitantiBeans" :fields="fieldsMalattieConcomitanti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(malattia)="item">
              <b-form-input v-model="item.item.malattia"></b-form-input>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.malattieInfettiveIeLegionellosiMalattieConcomitantiBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddMalattieConcomitanti(jsonData.malattieInfettiveIeLegionellosiMalattieConcomitantiBeans, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.malattieInfettiveIeLegionellosiMalattieConcomitantiBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeLegionellosiMalattieConcomitantiBeans, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">In terapia sistemica con corticosteroidi o immunosoppressori</label>
          <b-form-select v-model="jsonData.terapiaCorticosteroidiImmunosoppressori" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Antibiotici (e dosi) assunti durante il ricovero</label>
          <b-form-select v-model="jsonData.antibioticiAssuntiDuranteRicovero" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiAntibioticiAssuntiBeans" :fields="fieldsAntibioticiAssunti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(antibiotico)="item">
              <b-form-input v-model="item.item.antibiotico"></b-form-input>
            </template>
            <template #cell(dose)="item">
              <b-form-input v-model="item.item.dose"></b-form-input>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.malattieInfettiveIeLegionellosiAntibioticiAssuntiBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddAntibiotici(jsonData.malattieInfettiveIeLegionellosiAntibioticiAssuntiBeans, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.malattieInfettiveIeLegionellosiAntibioticiAssuntiBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeLegionellosiAntibioticiAssuntiBeans, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Cure odontoiatriche nelle due settimane precedenti l’esordio</label>
          <b-form-select v-model="jsonData.cureOdontoiatricheDueSettimanePrecedenti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ricovero ospedaliero nelle due settimane precedenti l’esordio</label>
          <b-form-select v-model="jsonData.ricoveroOspedalieroDueSettimanePrecendenti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiRicoveroOspedalieroBeans" :fields="fieldsRicoveroOspedaliero" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(ospedale)="item">
              <b-form-input v-model="item.item.ospedale"></b-form-input>
            </template>
            <template #cell(reparto)="item">
              <b-form-input v-model="item.item.reparto"></b-form-input>
            </template>
            <template #cell(dal)="item">
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.dal" type="timestamp"></date-picker>
            </template>
            <template #cell(al)="item">
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.al" type="timestamp"></date-picker>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.malattieInfettiveIeLegionellosiRicoveroOspedalieroBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddRicoveroOspedaliero(jsonData.malattieInfettiveIeLegionellosiRicoveroOspedalieroBeans, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.malattieInfettiveIeLegionellosiRicoveroOspedalieroBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeLegionellosiRicoveroOspedalieroBeans, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Soggiorno, nelle due settimane precedenti l’esordio, in luoghi diversi dalla propria abitazione</label>
          <b-form-select v-model="jsonData.soggiornoDueSettimanePrecedentiDiversoDomicilio" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiSoggiornoBeans" :fields="fieldsSoggiorno" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(tipoStruttura)="item">
              <b-form-input v-model="item.item.tipoStruttura"></b-form-input>
            </template>
            <template #cell(localita)="item">
              <b-form-input v-model="item.item.localita"></b-form-input>
            </template>
            <template #cell(operatoreTuristico)="item">
              <b-form-input v-model="item.item.operatoreTuristico"></b-form-input>
            </template>
            <template #cell(gruppoIndividuale)="item">
              <b-form-input v-model="item.item.gruppoIndividuale"></b-form-input>
            </template>
            <template #cell(dal)="item">
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.dal" type="timestamp"></date-picker>
            </template>
            <template #cell(al)="item">
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.al" type="timestamp"></date-picker>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.malattieInfettiveIeLegionellosiSoggiornoBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddSoggiorno(jsonData.malattieInfettiveIeLegionellosiSoggiornoBeans, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.malattieInfettiveIeLegionellosiSoggiornoBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeLegionellosiSoggiornoBeans, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Frequentazione di piscine nelle due settimane precedenti</label>
          <b-form-select v-model="jsonData.frequentazionePiscineDueSettimanePrecedenti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Abitudine al fumo di sigaretta</label>
          <b-form-select v-model="jsonData.fumatore" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputFumatore"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Fumatore da (Anni)</label>
          <b-form-input v-model="jsonData.anniFumatore" :disabled="fumatoreDisabled"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Quantità Sigarette Fumate</label>
          <b-form-input v-model="jsonData.fumoQuantita" :disabled="fumatoreDisabled"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Abitudine all’alcool</label>
          <b-form-select v-model="jsonData.alcool" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputAlcool"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Quantità Assunta</label>
          <b-form-input v-model="jsonData.alcoolQuantita" :disabled="alcoolDisabled"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <h4>Diagnosi di Legionellosi</h4>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Isolamento del germe specificare da quale materiale patologico</label>
          <b-form-select v-model="jsonData.isolamentoGerme" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputIsolamentoGerme"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Materiale Patologico</label>
          <b-form-input v-model="jsonData.isolamentoGermeMaterialePatologico" :disabled="isolamentoGermeDisabled"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Rilevazione Antigene Urinario</label>
          <b-form-select v-model="jsonData.rilevazioneAntigeneUrinario" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Sirerologia</label>
          <b-form-select v-model="jsonData.sierologia" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiSierologiaBeans" :fields="fieldsSierologia" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(tipoSierologia)="item">
              <b-form-select v-model="item.item.tipoSierologia" :options="tipoSierologiaOptions" :value="''" value-field="descrizione" text-field="descrizione"></b-form-select>
            </template>
            <template #cell(titolo)="item">
              <b-form-input v-model="item.item.titolo"></b-form-input>
            </template>
            <template #cell(giorniInizioSintomi)="item">
              <b-form-input v-model="item.item.giorniInizioSintomi"></b-form-input>
            </template>
            <template #cell(specie)="item">
              <b-form-input v-model="item.item.specie"></b-form-input>
            </template>
            <template #cell(sierogruppo)="item">
              <b-form-input v-model="item.item.sierogruppo"></b-form-input>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.malattieInfettiveIeLegionellosiSierologiaBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddSierologia(jsonData.malattieInfettiveIeLegionellosiSierologiaBeans, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.malattieInfettiveIeLegionellosiSierologiaBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeLegionellosiSierologiaBeans, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Indagine Ambientale</label>
          <b-form-select v-model="jsonData.indagineAmbientale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiIndagineAmbientaleBeans" :fields="fieldsIndagineAmbientale" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(materialeAnalizzato)="item">
              <b-form-input v-model="item.item.materialeAnalizzato"></b-form-input>
            </template>
            <template #cell(indagine)="item">
              <b-form-select v-model="item.item.indagine" :options="risultatoIndagineOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </template>
            <template #cell(specie)="item">
              <b-form-input v-model="item.item.specie"></b-form-input>
            </template>
            <template #cell(sierogruppo)="item">
              <b-form-input v-model="item.item.sierogruppo"></b-form-input>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.malattieInfettiveIeLegionellosiIndagineAmbientaleBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddIndagineAmbientale(jsonData.malattieInfettiveIeLegionellosiIndagineAmbientaleBeans, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.malattieInfettiveIeLegionellosiIndagineAmbientaleBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeLegionellosiIndagineAmbientaleBeans, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sanitario Notificante</label>
          <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
          <b-form-input v-model="jsonData.contattoSanitarioNotificante"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Notifica</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataNotifica" type="timestamp" placeholder="Data Notifica"></date-picker>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import EventiMixins from "../../../paziente/eventi/utility/mixins/EventiMixins";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { DatePicker },
  data() {
    return {
      pathResource: "/malattieinfettiveielegionellosi",
      pathResourceTipoSierologia: "/malattieinfettiveielegionellositiposierologia",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      linkback: null,
      id: "-1",
      showModalLoading: false,
      ricoveroDisabled: true,
      altreManifestazioniDettaglioDisabled: true,
      fumatoreDisabled: true,
      alcoolDisabled: true,
      isolamentoGermeDisabled: true,
      currentPage: 1,
      perPage: 100,
      jsonData: {
        dataInsorgenzaSintomi: null,
        ricoveroOspedaliero: "",
        dataRicovero: null,
        nomeOspedale: "",
        febbre: "",
        brividi: "",
        cefalea: "",
        tosse: "",
        espettorazione: "",
        dispnea: "",
        emoftoe: "",
        toracologie: "",
        versamentoPleurico: "",
        ipossia: "",
        opacitaPolmonare: "",
        nauseaVomito: "",
        doloriAddominali: "",
        diarrea: "",
        peritonite: "",
        confusioneMentale: "",
        atassia: "",
        afasia: "",
        extrapiramidale: "",
        aritmiaCardiaca: "",
        pericardite: "",
        osteoartromialgia: "",
        altreManifestazioni: "",
        altreManifestazioniDettaglio: "",
        malattieConcomitati: "",
        terapiaCorticosteroidiImmunosoppressori: "",
        antibioticiAssuntiDuranteRicovero: "",
        cureOdontoiatricheDueSettimanePrecedenti: "",
        ricoveroOspedalieroDueSettimanePrecendenti: "",
        soggiornoDueSettimanePrecedentiDiversoDomicilio: "",
        frequentazionePiscineDueSettimanePrecedenti: "",
        fumatore: "",
        fumoQuantita: "",
        anniFumatore: "",
        alcool: "",
        alcoolQuantita: "",
        isolamentoGerme: "",
        isolamentoGermeMaterialePatologico: "",
        sierologia: "",
        rilevazioneAntigeneUrinario: "",
        indagineAmbientale: "",
        sanitarioNotificante: "",
        contattoSanitarioNotificante: "",
        dataNotifica: null,
        malattieInfettiveIeLegionellosiMalattieConcomitantiBeans: [],
        malattieInfettiveIeLegionellosiRicoveroOspedalieroBeans: [],
        malattieInfettiveIeLegionellosiIndagineAmbientaleBeans: [],
        malattieInfettiveIeLegionellosiAntibioticiAssuntiBeans: [],
        malattieInfettiveIeLegionellosiSoggiornoBeans: [],
        malattieInfettiveIeLegionellosiSierologiaBeans: [],
      },
      fieldsMalattieConcomitanti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Malattia",
          key: "malattia",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsAntibioticiAssunti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Antibiotico",
          key: "antibiotico",
          sortable: false,
        },
        {
          label: "Dose",
          key: "dose",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsRicoveroOspedaliero: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Ospedale",
          key: "ospedale",
          sortable: false,
        },
        {
          label: "Reparto",
          key: "reparto",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Dal",
          key: "dal",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Al",
          key: "al",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsSierologia: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo Sierologia",
          key: "tipoSierologia",
          sortable: false,
        },
        {
          label: "Titolo",
          key: "titolo",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Giorni Inizio Sintomi",
          key: "giorniInizioSintomi",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Specie",
          key: "specie",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Sierogruppo",
          key: "sierogruppo",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsSoggiorno: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo Struttura",
          key: "tipoStruttura",
          sortable: false,
        },
        {
          label: "Localita'",
          key: "localita",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Operatore Turistico",
          key: "operatoreTuristico",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Gruppo/Individuale",
          key: "gruppoIndividuale",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Dal",
          key: "dal",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Al",
          key: "al",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsIndagineAmbientale: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Materiale Analizzato",
          key: "materialeAnalizzato",
          sortable: false,
        },
        {
          label: "Indagine",
          key: "indagine",
          sortable: false,
          thStyle: "width: 12rem",
        },
        {
          label: "Specie",
          key: "specie",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Sierogruppo",
          key: "sierogruppo",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      filtro: { forPage: 100, page: 1 },
      tipoSierologiaOptions: [],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      standardSelectOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      risultatoIndagineOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafuca = me.idPaziente;
    me.jsonData.id = me.id;
    me.loadMalattiaInfettivaSegnalazione();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.malattieConcomitati": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.malattieInfettiveIeLegionellosiMalattieConcomitantiBeans.push({ malattia: "" });
      } else {
        me.jsonData.malattieInfettiveIeLegionellosiMalattieConcomitantiBeans = [];
      }
    },
    "jsonData.antibioticiAssuntiDuranteRicovero": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.malattieInfettiveIeLegionellosiAntibioticiAssuntiBeans.push({ antibiotico: "", dose: "" });
      } else {
        me.jsonData.malattieInfettiveIeLegionellosiAntibioticiAssuntiBeans = [];
      }
    },
    "jsonData.ricoveroOspedalieroDueSettimanePrecendenti": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.malattieInfettiveIeLegionellosiRicoveroOspedalieroBeans.push({ ospedale: "", reparto: "", dal: null, al: null });
      } else {
        me.jsonData.malattieInfettiveIeLegionellosiRicoveroOspedalieroBeans = [];
      }
    },
    "jsonData.sierologia": function (value) {
      let me = this;
      if (value === "SI") {
        me.loadTipoSierologia();
        me.jsonData.malattieInfettiveIeLegionellosiSierologiaBeans.push({ tipoSierologia: "", titolo: "", giorniInizioSintomi: "", specie: "", sierogruppo: "" });
      } else {
        me.jsonData.malattieInfettiveIeLegionellosiSierologiaBeans = [];
      }
    },
    "jsonData.soggiornoDueSettimanePrecedentiDiversoDomicilio": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.malattieInfettiveIeLegionellosiSoggiornoBeans.push({ tipoStruttura: "", localita: "", operatoreTuristico: "", gruppoIndividuale: "", dal: null, al: null });
      } else {
        me.jsonData.malattieInfettiveIeLegionellosiSoggiornoBeans = [];
      }
    },
    "jsonData.indagineAmbientale": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.malattieInfettiveIeLegionellosiIndagineAmbientaleBeans.push({ materialeAnalizzato: "", indagine: "", specie: "", sierogruppo: "" });
      } else {
        me.jsonData.malattieInfettiveIeLegionellosiIndagineAmbientaleBeans = [];
      }
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;

      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
        });
    },
    loadMalattiaInfettivaSegnalazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.setDatiMalattia(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadTipoSierologia() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceTipoSierologia;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.tipoSierologiaOptions = response.data.data.list;
        me.showModalLoading = false;
      });
    },
    onInputRicoveroOspedaliero(value) {
      let me = this;
      if (value === "SI") {
        me.ricoveroDisabled = false;
      } else {
        me.ricoveroDisabled = true;
        me.jsonData.dataRicovero = null;
        me.jsonData.nomeOspedale = "";
      }
    },
    onInputAltreManifestazioni(value) {
      let me = this;
      if (value === "SI") {
        me.altreManifestazioniDettaglioDisabled = false;
      } else {
        me.altreManifestazioniDettaglioDisabled = true;
        me.jsonData.altreManifestazioniDettaglio = "";
      }
    },
    onInputFumatore(value) {
      let me = this;
      if (value === "SI") {
        me.fumatoreDisabled = false;
      } else {
        me.fumatoreDisabled = true;
        me.jsonData.fumoQuantita = "";
        me.jsonData.anniFumatore = "";
      }
    },
    onInputAlcool(value) {
      let me = this;
      if (value === "SI") {
        me.alcoolDisabled = false;
      } else {
        me.alcoolDisabled = true;
        me.jsonData.alcoolQuantita = "";
      }
    },
    onInputIsolamentoGerme(value) {
      let me = this;
      if (value === "SI") {
        me.isolamentoGermeDisabled = false;
      } else {
        me.isolamentoGermeDisabled = true;
        me.jsonData.isolamentoGermeMaterialePatologico = "";
      }
    },
    onAddMalattieConcomitanti(array, value) {
      if (value.malattia !== "") {
        array.push({ malattia: "" });
      } else {
        this.$bvModal
          .msgBoxOk("La Malattia è OBBLIGATORIA", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddAntibiotici(array, value) {
      if (value.antibiotico !== "" && value.dose !== "") {
        array.push({ antibiotico: "", dose: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Antibiotico e Dose sono OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddRicoveroOspedaliero(array, value) {
      if (value.ospedale !== "" && value.reparto !== "" && value.dal !== null && value.al !== null) {
        array.push({ ospedale: "", reparto: "", dal: null, al: null });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono  OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddSierologia(array, value) {
      if (value.tipoSierologia !== "" && value.titolo !== "" && value.giorniInizioSintomi !== "" && value.specie !== "" && value.sierogruppo !== "") {
        array.push({ tipoSierologia: "", titolo: "", giorniInizioSintomi: "", specie: "", sierogruppo: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono  OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddSoggiorno(array, value) {
      if (value.tipoStruttura !== "" && value.localita !== "" && value.operatoreTuristico !== "" && value.gruppoIndividuale !== "" && value.dal !== null && value.al !== null) {
        array.push({ tipoStruttura: "", localita: "", operatoreTuristico: "", gruppoIndividuale: "", dal: null, al: null });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono  OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddIndagineAmbientale(array, value) {
      if (value.materialeAnalizzato !== "" && value.indagine !== "") {
        array.push({ materialeAnalizzato: "", indagine: "", specie: "", sierogruppo: "" });
      } else {
        this.$bvModal
          .msgBoxOk("I campi MATERIALE ANALIZZATO ED INDAGINE SONO OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
      console.log(me.jsonData.id);
    },
  },
};
</script>
