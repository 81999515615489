<template>
  <div class="sa-tab-list">
    <div class="sa-tab-list-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Farmaci Attivi: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-button class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onAggiungiFarmaco()" v-if="isEdit">
            <b-icon icon="plus"></b-icon>
            Aggiungi Farmaco
          </b-button>
        </b-col>
      </b-row>
    </div>
    <div class="sa-tab-list-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="terapieAttive" :fields="fieldsTerapieAttive" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(farmaco)="item">
            <span v-if="item.item.stato === '1'" class="sa-label-data success">{{ item.item.farmaco }}</span>
            <span v-else class="sa-label-data">{{ item.item.farmaco }}</span>
          </template>
          <!-- <template #cell(actions)="row" v-if="isEdit"> -->
          <template #cell(actions)="row">
            <b-button variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onViewDetails(row.item)">
              <b-icon icon="eye"></b-icon>
            </b-button>
            <b-button v-if="checkClose(row.item)" variant="btn btn-outline-warning waves-effect waves-light width-sm no-text" @click="onClose(row.item)">
              <i class="far fa-times-circle"></i>
            </b-button>
            <b-button v-if="checkDetele(row.item)" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(row.item)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-tab-list-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>Totale Farmaci Attivi: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <!-- <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" size="sm" align="right"></b-pagination> -->
        </b-col>
      </b-row>
    </div>
    <b-modal id="edit-terapia" title="Aggiungi Terapia" size="lg" centered hide-footer>
      <paziente-terapia-edit-farmaco-component :idPaziente="idPaziente" @update="onUpdate"></paziente-terapia-edit-farmaco-component>
    </b-modal>
    <b-modal id="view-terapia" title="Visualizza Terapia" size="lg" centered hide-footer>
      <paziente-terapia-view-farmaco-component :jsonData="rowTerapia" @update="onUpdate"></paziente-terapia-view-farmaco-component>
    </b-modal>
    <b-modal id="close-terapia" title="Chiudi Terapia" size="lg" centered hide-footer>
      <paziente-terapia-edit-farmaco-component :terapia="rowTerapia" @update="onUpdateChiudira" :isForClose="true"></paziente-terapia-edit-farmaco-component>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import PazienteTerapiaEditFarmacoComponent from "./PazienteTerapiaEditFarmacoComponent.vue";
import PazienteTerapiaViewFarmacoComponent from "./PazienteTerapiaViewFarmacoComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin.js";
export default {
  mixins: [UtilityMixin],
  components: { PazienteTerapiaEditFarmacoComponent, PazienteTerapiaViewFarmacoComponent },
  created() {},
  mounted() {
    let me = this;
    me.loadData();
  },
  props: {
    isEdit: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.terapieAttive.length;
    },
  },
  data() {
    return {
      show: true,
      perPage: 1000,
      currentPage: 1,
      terapieAttive: [],
      pathResource: "/pazienteterapie",
      pathResourceTerapiaAttiva: "/pazienteterapie",
      // pathResourceTerapiaAttiva: "/pazienteterapie/attiva",
      rowTerapia: {},
      fieldsTerapieAttive: [
        {
          label: this.$i18n.t("therapy.lblDrug"),
          key: "farmaco",
          sortable: true,
        },
        {
          label: "Posologia",
          key: "posologia",
          thStyle: "width: 15rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("therapy.lblStartDate"),
          key: "dataInizio",
          thStyle: "width: 14rem",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY");
          },
        },
        {
          label: this.$i18n.t("therapy.lblEndDate"),
          key: "dataFine",
          thStyle: "width: 14rem",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY");
          },
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 10rem",
        },
      ],
    };
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceTerapiaAttiva + "?idPaziente=" + me.idPaziente;

      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.terapieAttive = response.data.data;
          me.keyTerapiaAttiva = me.keyTerapiaAttiva + 1;
          me.showModalLoading = false;
        })
        .catch((e) => {
          console.log(e);
          me.showModalLoading = false;
        });
    },
    onAggiungiFarmaco() {
      let me = this;
      me.$bvModal.show("edit-terapia");
    },
    onOk(value) {
      console.log(value);
    },
    onUpdate(value) {
      let me = this;
      me.$bvModal.hide("edit-terapia");
      if (value !== null) {
        me.loadData();
        me.$bvToast.toast(value.messaggio, {
          title: "",
          variant: "success",
          solid: true,
        });
      }
    },
    onUpdateChiudira(value) {
      let me = this;
      me.$bvModal.hide("close-terapia");
      if (value !== null) {
        me.loadData();
        me.$bvToast.toast(value.messaggio, {
          title: "",
          variant: "success",
          solid: true,
        });
      }
    },
    onClose(row) {
      let me = this;
      me.rowTerapia = row;
      me.rowTerapia.dataFine = new Date().getTime();
      me.$bvModal.show("close-terapia");
    },
    onDelete(row) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
          title: "",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
            axios.delete(link).then(() => {
              me.loadData();
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    checkDetele(item) {
      let me = this;
      let returnValue = me.formatDate(item.createDate) === me.formatDate(new Date());
      return returnValue;
    },
    checkClose(item) {
      let returnValue = item.dataFine > new Date();
      return returnValue;
    },
    onViewDetails(value) {
      let me = this;
      me.rowTerapia = value;
      me.$bvModal.show("view-terapia");
    },
  },
};
</script>
<style scoped>
.col-lg-12 {
  height: 35px;
}
.danger {
  color: red;
}
.success {
  color: green;
}
.blue {
  color: rgb(0, 30, 128);
}
.warning {
  color: #ffc107;
}
</style>
