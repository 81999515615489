<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblSG") }}
                        <eventi-storico-misure-component misura="sg" misuraLabel="Sg" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.sg }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblPh") }}
                        <eventi-storico-misure-component misura="ph" misuraLabel="Ph" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.ph }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblUBJ") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.ubg) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblUBJValue") }}
                        <eventi-storico-misure-component misura="valore_ubg" misuraLabel="Ubg" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.valoreUbg }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblGLU") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.glu) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblNIT") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.nit) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblLeu") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.leu) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblLeuValue") }}
                        <eventi-storico-misure-component misura="valore_leu" misuraLabel="Leu" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.valoreLeu }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblPRO") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.pro) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblPROValue") }}
                        <eventi-storico-misure-component misura="valore_pro" misuraLabel="Pro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.valorePro }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblKET") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.ket) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblKETValue") }}
                        <eventi-storico-misure-component misura="valore_ket" misuraLabel="Ket" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.valoreKet }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblBIL") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.bil) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblERY") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.ery) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblReport") }}</label>
                    <span class="sa-data">{{ jsonData.refertoUrine }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Stato</label>
                    <span class="sa-data">{{ jsonData.firma }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { EventiStoricoMisureComponent },
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
        tipoEvento: {
            Type: Object,
            require: true,
        },
        gruppo: {
            Type: Object,
            require: true,
        },
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.jsonData.idPaziente;
        },
    },
    data() {
        return {};
    },
    methods: {
        convertData(data) {
            let returnValue = "error translation";
            if (data == 0) {
                returnValue = this.getLabelTraduora("patient.afa.lblNegative");
            }
            if (data == 1) {
                returnValue = this.getLabelTraduora("patient.afa.lblPositive");
            }
            return returnValue;
        },
    },
};
</script>
