import PazienteTeleconsultiList from "./components/PazienteTeleconsultiList.vue";
import PazienteTeleconsultoEdit from "./components/PazienteTeleconsultoEdit.vue";
import PazienteTeleconsultoView from "./components/PazienteTeleconsultoView.vue";
import PazienteTeleconsulto from "./components/PazienteTeleconsulto.vue";
import PazienteTeleconsultoHassisto from "./components/PazienteTeleconsultoHasssito.vue";
import PazienteTeleconsultoGoogleMeet from "./components/PazienteTeleconsultoGoogleMeetComponent.vue";
export default {
    PazienteTeleconsultiList,
    PazienteTeleconsultoEdit,
    PazienteTeleconsultoView,
    PazienteTeleconsulto,
    PazienteTeleconsultoHassisto,
    PazienteTeleconsultoGoogleMeet,
};
