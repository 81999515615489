<template>
  <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :showModalLoading="showModalLoading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <!-- <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template> -->
    <template slot="table-filter">
      <div class="bg-picture card-box">
        <div class="profile-info-name">
          <div class="container-fluid">
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <h5 class="m-0">{{ this.$i18n.t("videocare.lblDoctorData") }}</h5>
              </b-col>
            </b-row>
          </div>
          <hr />
          <div class="container-fluid">
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblName") }}</label>
                <span class="sa-data">{{ data.nomeMedicoPresente }}</span>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblSurname") }}</label>
                <span class="sa-data">{{ data.cognomeMedico }}</span>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblDoctorCode") }}</label>
                <span class="sa-data">{{ data.codiceMedicoPresente }}</span>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblPhoneNumber") }} </label>
                <span class="sa-data">{{ data.telefonoMedico }}</span>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data"> {{ this.$i18n.t("videocare.lblEmail") }} </label>
                <span class="sa-data">{{ data.emailMedico }}</span>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <h5 class="m-0">{{ this.$i18n.t("videocare.lblPatientData") }}</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Username</label>
                <span class="sa-data">{{ data.usernameRichiedente }}</span>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblPhoneNumber") }}</label>
                <span class="sa-data">{{ data.telefonoPaziente }}</span>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblEmail") }}</label>
                <span class="sa-data">{{ data.emailPaziente }}</span>
              </b-col>
            </b-row>
            <hr />
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <h5 class="m-0">{{ this.$i18n.t("videocare.lblReservation") }}</h5>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblBookingDateAndTime") }}</label>
                <span class="sa-data">{{ formatDate(data.dataOraPrenotazione) }}</span>
              </b-col>
            </b-row>
            <hr />
          </div>
        </div>
      </div>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <!-- <template v-slot:cell(nominativo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/paziente/view/' + item.id">{{ item.nome }} {{ item.cognome }} </router-link>
                        
                        {{ item.codiceFiscale }}
                    </template> -->
        </b-table>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { PazienteAnagrafica, SaPageLayout }, //PazienteDashboardComponent
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function () {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      showModalLoading: false,
      linkedit: null,
      linkback: "/paziente/teleconsulti",
      elaborazione: false,
      currentPage: 1,
      filter: "",
      pathResource: "/teleconsultoprenotazioni",
      // pathRest: "/teleconsultopartecipanti",
      fields: [
        {
          label: "Nome",
          key: "nomeMedicoPresente",
          sortable: true,
          //thStyle: "width: 7rem",
        },
        {
          label: "Cognome",
          key: "cognome",
          //thStyle: "width: rem",
          sortable: true,
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
        },
        {
          label: "Numero di Telefono",
          key: "numeroTelefono",
          sortable: true,
        },
        { class: "sa-table-column-action-single", key: "actions", label: "" },
      ],
      items: [],
      data: {},
    };
  },
  created: function () {},
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkback = "/paziente/teleconsulti?idPaziente=" + me.idPaziente;
    me.linkedit = "/paziente/teleconsulti/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.loadData();
  },

  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios.get(link + me.id).then((response) => {
        me.data = response.data.data;
        me.showModalLoading = false;
        me.loadDataPartecipanti();
      });
      me.showModalLoading = false;
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    loadDataPartecipanti() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .get(link + me.data.id)
        .then((response) => {
          me.items = response.data.data;
          me.showModalLoading = false;
        })
        .catch((err) => {
          console.log("ERRORE:    " + err);
        });
    },
  },
};
</script>
