<template>
    <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
        <template slot="tab-content-event">
            <gastro-anamnesi-patologica-view-component @update="onUpdateJsonData"></gastro-anamnesi-patologica-view-component>
        </template>
    </eventi-view-component>
</template>
<script>
import GastroAnamnesiPatologicaViewComponent from "./GastroAnamnesiPatologicaViewComponent.vue";
// import axios from "axios";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    components: { GastroAnamnesiPatologicaViewComponent, EventiViewComponent },
    mixins: [UtilityMixin, EventiMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            id: null,
            linkback: "",
            linkedit: "",
            titoloEvento: "ANAMNESI PATOLOGICA",
            pathResource: "/gastroanamnesipatologica",
            pathResourceFirma: "/gastroanamnesipatologica",
            tabIndex: 0,
            isThisFirmato: false,
            pathRestValidation: "/validation",
            jsonData: {},
            data: {},
            linkPrintData: null,
            showModalLoading: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.id = this.$route.params.id;
    },

    methods: {
        updateStatoFirma(firmato) {
            console.log(firmato);
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            console.log(me.jsonData);
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
        },
    },
};
</script>
