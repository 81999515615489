import GestioneAttivitaAttivitaListPage from "./pages/GestioneAttivitaAttivitaListPage.vue";
import GestioneAttivitaAttivitaEditPage from "./pages/GestioneAttivitaAttivitaEditPage.vue";
// import AttivitaBachecaView from "./pages/AttivitaBachecaView.vue";
// import AttivitaBachecaEdit from "./pages/AttivitaBachecaEdit.vue";

export default {
    GestioneAttivitaAttivitaListPage,
    GestioneAttivitaAttivitaEditPage,
    // AttivitaBachecaView,
    // AttivitaBachecaEdit,
};
