<template>
  <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData" :isImmagini="true" :isPrestazioni="true" :isAllegati="false">
    <template slot="tab-content-event">
      <ecografia-addominale-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></ecografia-addominale-edit-component>
    </template>
  </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EcografiaAddominaleEditComponent from "./EcografiaAddominaleEditComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { EventiEditComponent, EcografiaAddominaleEditComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    this.jsonData.dataEvento = new Date().getTime();
  },
  data() {
    return {
      linkback: null,
      titoloEvento: "Ecografia Addome Superiore",
      pathResource: "/gastroecografiaaddominale",
      pathRestValidation: "/validation",
      id: "-1",
      isFirmato: false,
      childKey: 0,
      tabIndex: 0,
      showModalLoading: false,
      jsonData: {
        dataEvento: null,
        provenienza: null,
        motivoEsame: null,
        fegato: null,
        colecisti: null,
        vieBiliariExtraepatiche: null,
        pancreas: null,
        milza: null,
        reneSx: null,
        reneDx: null,
        asseSplenoPortale: null,
        varie: null,
        diagnosi: null,
        corpoReferto: null,
        premedicazione: null,
        consigliTerapeutici: null,
        strumento: null,
        listaOperatori: [],
      },
    };
  },
  methods: {
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
  },
};
</script>
