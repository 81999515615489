<template>
    <sa-page-layout :showmolalloading="showmolalloading">
        <!-- <template slot="toolbar">
            <b-button class="float-sm-right " v-b-modal.inviaRichiesta variant="info btn-toolbar sa-margin-right"><b-icon icon="cart-plus"></b-icon>&nbsp;{{ this.$i18n.t("global.lblSendRequest") }}</b-button>
            <b-button class="float-sm-right " v-b-modal.visualizzaCarrello variant="info btn-toolbar"><b-icon icon="cart-plus"></b-icon>&nbsp;{{ this.$i18n.t("global.lblShowCart") }}</b-button>
        </template> -->
        <template slot="table-filter">
            <ricerca-anagrafica-paziente :link="indirizzoLink" @update="onUpdateData"></ricerca-anagrafica-paziente>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <b-tabs ref="tbPrenotazioniAgende" class="sa-tabs" content-class="mt-3" @activate-tab="onActiveTabs" fill>
                <b-tab title="Agende">
                    <div class="b-table-sticky-header">
                        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="data" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(titolo)="{ item }">
                                <!--<b-link class="sa-edit-link" :to="'/prenotazioniagenda/appuntamenti/' + item.id">{{ item.titolo }}</b-link>-->
                                <b-link class="sa-edit-link" @click="onAgendaClick(item)">{{ item.titolo }}</b-link>
                            </template>
                        </b-table>
                    </div>
                </b-tab>
                <b-tab title="Storico Prenotazioni">
                    <div class="b-table-sticky-header">
                        <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="storicoPrenotazioni" :fields="fieldsStoricoPrenotazioni" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(oggetto)="{ item }">
                                <b-link v-b-modal.visualizzaPrescrizioniMessaggio>{{ item.oggetto }}</b-link>
                            </template>
                        </b-table>
                    </div>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>
<script>
// import axios from "axios";
import moment from "moment";
import RicercaAnagraficaPaziente from "./RicercaAnagraficaPaziente.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { RicercaAnagraficaPaziente, SaPageLayout },
    computed: {
        rows() {
            return this.data.length;
        },
        indirizzoLink() {
            return process.env.VUE_APP_PATH_API + this.pathRest;
        },
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: null,
            pathRest: "/prenotazioniagende",
            rigaSelezionata: null,
            filtro: { codiceFiscalePaziente: "", codiceFiscaleMedico: "" },
            filterOn: [],
            showmolalloading: false,
            fields: [
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblTitle"),
                    key: "titolo",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblDescription"),
                    key: "descrizione",
                    sortable: true,
                    thStyle: "width: 20rem",
                },
                // { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            fieldsStoricoPrenotazioni: [
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblObject"),
                    key: "oggetto",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblMessage"),
                    key: "messaggio",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblStatus"),
                    key: "stato",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: this.$i18n.t("repeatablePrescriptions.lblMessageDate"),
                    key: "dataMessaggio",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
                {
                    label: "",
                    key: "aggiungi",
                },
                // { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            data: RicercaAnagraficaPaziente.data,
            storicoPrenotazioni: [],
            datiPaziente: {},
        };
    },
    created: function () {},
    mounted: function () {},
    methods: {
        onUpdateData(newValue, filtro) {
            this.data = newValue;
            this.datiPaziente = filtro;
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        onActiveTabs(newTabIndex) {
            // let me = this;
            console.log(newTabIndex);
            if (newTabIndex === 1) {
                console.log(newTabIndex);
            }
        },
        onAgendaClick(item) {
            let me = this;
            let prenotazione = {
                titolo: me.datiPaziente.nominativoPaziente,
                dataOraInizio: "",
                dataOraFine: "",
                idAgenda: item.id,
                codiceFiscaleAssistito: me.datiPaziente.codiceFiscalePaziente,
                codiceFiscaleMedico: me.datiPaziente.codiceFiscaleMedico,
                codicePrestazione: "",
                descrizionePrestazione: "",
                costo: 0,
                note: "",
            };
            console.log(prenotazione);
            let sessionStorage = window.sessionStorage;
            sessionStorage["prenotazione"] = JSON.stringify(prenotazione);

            //prenotazioniagenda/appuntamenti/' + item.id"
            me.$router.replace("prenotazioniagenda/appuntamenti/" + item.id).catch((err) => {
                err;
            });
        },
    },
};
</script>
