<template>
    <sa-page-layout ref="pgaView" :items="items" :pathResource="pathResource" :btnBackVisible="true" :btnEditVisible="false" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <!-- <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template> -->
        <template slot="table-header">
            <b-row cols="12">
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("riskAnalysis.lblResultMessage") }}: </label>
                    <span class="sa-data">{{ items.resultmessage }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("riskAnalysis.lblResultCode") }}: </label>
                    <span class="sa-data">{{ items.resultcode }}</span>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patients.lblBirthDate") }}:</label>
                        <span class="sa-data">{{ formatDate(items.birthdate) }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patients.lblPatientAge") }}: </label>
                        <span class="sa-data">{{ items.age }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patients.lblGender") }}: </label>
                        <span class="sa-data">{{ items.sex }}</span>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("riskAnalysis.lblCigarette") }}: </label>
                        <span class="sa-data"><font-awesome-icon :icon="check(items.cigaretteSmoker)"></font-awesome-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblAlcohol") }}: </label>
                        <span class="sa-data"><font-awesome-icon :icon="check(items.alcoholConsuption)"></font-awesome-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblStrokeTransientIschemia") }}: </label>
                        <span class="sa-data"><font-awesome-icon :icon="check(items.ictusIschemiaTransitoria)"></font-awesome-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblHypertension") }}: </label>
                        <span class="sa-data"><font-awesome-icon :icon="check(items.ipertensione)"></font-awesome-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblChronicKidneyDisease") }}: </label>
                        <span class="sa-data"><font-awesome-icon :icon="check(items.malattiaRenaleCronica)"></font-awesome-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblHeartDiseaseChronic") }}: </label>
                        <span class="sa-data"><font-awesome-icon :icon="check(items.cardiopatiaCronica)"></font-awesome-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblDiabetes") }}: </label>
                        <span class="sa-data"><font-awesome-icon :icon="check(items.diabete)"></font-awesome-icon></span>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblCholesterol") }}: </label>
                        <span class="sa-data">{{ items.hdlCholesterol }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">{{ this.$i18n.t("patients.lblTotalCholesterol") }}: </label>
                        <span class="sa-data">{{ items.totalCholesterol }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">{{ this.$i18n.t("patients.lblMeanSystolicPressure") }}: </label>
                        <span class="sa-data">{{ items.pressioneSistolicaMedia }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">{{ this.$i18n.t("patients.lblBloodPressureTreatment") }}: </label>
                        <span class="sa-data">{{ items.trattamentoPressioneSanguigna }}</span>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col id="Calcolo-pressiometro" cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
                        ><h4>{{ this.$i18n.t("riskAnalysis.lblGoals") }}</h4>
                        <br />
                        <div v-for="tott in patientGoals" :key="tott.id">
                            <label class="sa-label-data">{{ getTranslationLabel(tott.id) }}: </label>
                            <span class="sa-data">{{ approssimazione(tott.value) }}</span>
                        </div>
                    </b-col>
                    <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6" id="Livello-di-rischio" v-if="noData !== null">
                        <h4>{{ this.$i18n.t("riskAnalysis.lblResults") }}</h4>
                        <br />
                        <p>{{ noData }}</p>
                    </b-col>
                    <b-col id="Livello-di-rischio" v-else cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
                        ><h4>{{ this.$i18n.t("riskAnalysis.lblResults") }}</h4>
                        <br />
                        <span class="sa-data">
                            <h4 v-if="resultData[0]" :style="'color:' + coloreRischio + ' !important;'">{{ this.$i18n.t("riskAnalysis.lblRisk") }}: {{ resultData.CalculatedRisText }}</h4>
                        </span>
                        <br />
                        <div v-if="resultRecomendation[0]">
                            <h5>{{ this.$i18n.t("riskAnalysis.lblRecommendations") }}</h5>
                            <div v-for="tott in resultRecomendation" :key="tott.id">
                                <span class="sa-data">{{ tott }}</span>
                                <hr />
                            </div>
                        </div>
                        <div v-if="resultRoutine[0]">
                            <h5>{{ this.$i18n.t("riskAnalysis.lblRoutineTesting") }}</h5>
                            <div v-for="tott in resultRoutine" :key="tott.id">
                                <span class="sa-data">{{ tott.ProcedureName }}</span>
                                <hr />
                            </div></div
                    ></b-col>
                </b-row>

                <!-- <b-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2"
            ><h4>{{ this.$i18n.t("riskAnalysis.lblMeasurements") }}</h4>
            <b-row>
              <b-col>
                <div v-for="tott in misure" :key="tott.id_measure + tott.measurement_id">
                  {{ checkName(tott.measurement_name) }}: <span class="sa-data">{{ tott.measurement_value }}</span>
                </div>
              </b-col>
            </b-row>
          </b-col> -->
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteDashboardComponent from "../PazienteDashboardComponent.vue";
export default {
    components: { PazienteAnagrafica, SaPageLayout }, //PazienteDashboardComponent
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            id: -1,
            pathRest: "/pazienteanalisirischi",
            pathResource: "/analisirischi",
            linkback: "/analisirischi",
            linkSomministrazione: "/pazienteterapiesomministrazioni",
            linkedit: null,
            items: [],
            iterator: 0,
            patientGoals: [],
            resultRecomendation: [],
            resultData: {},
            noData: null,
            coloreRischio: "#000000",
            resultRoutine: [],
            misure: [],
            showModalLoading: false,
            data: {
                dataInizio: null,
                dataFine: null,
                farmaco: null,
                quantita: null,
                frequenza: null,
                posologia: null,
                stato: null,
                note: null,
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function () {
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                                return "/paziente/view/" + this.idPaziente;
                            } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "view/" + this.idPaziente;
                            } else {
                                return "homepaziente";
                            }
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            },
        },
        // rows() {
        //     return this.somministrazioneItems.length;
        // },
    },

    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/analisirischi/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/analisirischi?idPaziente=" + me.idPaziente;

        this.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.calcoloRischio();
        },
        approssimazione(value) {
            let lung = value.length;
            if (value !== null || value !== undefined) {
                let checkValue = String(value);

                if (checkValue.indexOf(",") > -1) {
                    let str = checkValue.replace(",", ".");
                    checkValue = str;
                }
                let numValue = Number(checkValue);
                if (isNaN(numValue) || isNaN(numValue) || lung === 0) {
                    return value;
                } else {
                    return numValue.toFixed(2);
                }
            }
        },
        calcoloRischio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    try {
                        let json = JSON.parse(me.items.patientGoalBpResult);
                        for (var prop in json) {
                            let obj = {
                                id: prop,
                                value: json[prop],
                            };
                            me.patientGoals.push(obj);
                        }
                        let results = JSON.parse(me.items.riskCalculationResult);
                        if (results.data.RiskCalculationResult) {
                            me.resultData = results.data.RiskCalculationResult;
                            let rischio = me.resultData.CalculatedRisText;
                            let check = rischio.charAt(0);
                            if (check === ",") {
                                let format = me.resultData.CalculatedRisText.replace(",", "0,");
                                me.resultData.CalculatedRisText = format;
                            }
                            me.coloreRischio = me.rischioColor(me.resultData.CalculatedRisk);
                            let raccomandazioni = [];
                            let jsonData = me.resultData.Recomendations;
                            if ("LifestyleInterventions" in jsonData) {
                                let lifeSytle = me.resultData.Recomendations.LifestyleInterventions.LifestyleIntervention;
                                for (const [value] of Object.entries(jsonData)) {
                                    switch (value) {
                                        case value !== lifeSytle:
                                            raccomandazioni.push(value);
                                            break;
                                        default:
                                            break;
                                    }
                                }
                                lifeSytle.forEach((obj) => {
                                    raccomandazioni.push(obj);
                                });
                            } else {
                                for (const [key, value] of Object.entries(jsonData)) {
                                    key;
                                    raccomandazioni.push(value);
                                }
                            }
                            me.resultRecomendation = raccomandazioni;
                            me.resultRoutine = me.resultData.RoutineTests.Procedure;
                        } else {
                            me.noData = "Insufficient data for calculation";
                        }
                    } catch (e) {
                        me.resultRecomendation = [];
                        me.patientGoals = [];
                        console.log(e);
                    }
                    try {
                        let json = JSON.parse(me.items.measurements);
                        me.misure = json;
                    } catch {
                        me.misure = [];
                    }
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        resultValutazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    try {
                        let json = JSON.parse(me.items.patientGoalBpResult);
                        for (var prop in json) {
                            let obj = {
                                id: prop,
                                value: json[prop],
                            };
                            me.patientGoals.push(obj);
                        }
                    } catch {
                        me.patientGoals = [];
                    }
                    try {
                        let json = JSON.parse(me.items.measurements);
                        me.misure = json;
                    } catch {
                        me.misure = [];
                    }
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        check(bool) {
            if (bool === true) {
                return "check";
            } else {
                return "times";
            }
        },
        rischioColor(rischio) {
            if (rischio.includes(",")) {
                let str = rischio.replace(",", ".");
                rischio = str;
            }
            let livello = Number(rischio);
            let returnValue = "#02b939";
            if (livello >= 1 && livello < 2) {
                returnValue = "#88b902";
            } else if (livello >= 2 && livello < 3) {
                returnValue = "#fffb07";
            } else if (livello >= 3 && livello < 5) {
                returnValue = "#ff7707";
            } else if (livello >= 5 && livello < 10) {
                returnValue = "#fa1010";
            } else if (livello >= 10 && livello < 15) {
                returnValue = "#b91600";
            } else if (livello >= 15) {
                returnValue = "#830f00";
            }

            return returnValue;
        },
        checkName(name) {
            let label = "";
            switch (name) {
                case "hr_average":
                    label = this.$i18n.t("patient.card.lblHrAvarage");
                    break;
                case "totalcalories":
                    label = this.$i18n.t("patient.card.lblTotalCalories");
                    break;
                case "calories":
                    label = this.$i18n.t("patient.card.lblCalories");
                    break;
                case "active":
                    label = this.$i18n.t("patient.card.lblActive");
                    break;
                case "total_sleep_time":
                    label = this.$i18n.t("patient.card.lblTotalSleepTime");
                    break;
                case "sleep_efficiency":
                    label = this.$i18n.t("patient.card.lblSleepEfficiency");
                    break;
                case "hr_min":
                    label = this.$i18n.t("patient.card.lblHrMin");
                    break;
                case "hr_max":
                    label = this.$i18n.t("patient.card.lblHrMax");
                    break;
                case "elevation":
                    label = this.$i18n.t("patient.card.lblElevation");
                    break;
                case "out_of_bed_count":
                    label = this.$i18n.t("patient.card.lblOutOfBedTime");
                    break;
                case "Skin Temperature (celsius)":
                    label = this.$i18n.t("patient.card.lblSkinTemperature");
                    break;
                case "Bone Mass (kg)":
                    label = this.$i18n.t("patient.card.lblBoneMass");
                    break;
                case "Body Temperature (celsius)":
                    label = this.$i18n.t("patient.card.lblBodyTemperature");
                    break;
                case "Temperature (celsius)":
                    label = this.$i18n.t("patient.card.lblTemperature");
                    break;
                case "Pulse Wave Velocity (m/s)":
                    label = this.$i18n.t("patient.card.lblPulseWaveVelocity");
                    break;
                case "SP02 (%)":
                    label = this.$i18n.t("patient.card.lblSPO2");
                    break;
                case "Heart Pulse (bpm) - only for BPM devices":
                    label = this.$i18n.t("patient.card.lblHeartPulseBPM");
                    break;
                case "Systolic Blood Pressure (mmHg)":
                    label = this.$i18n.t("patient.card.lblSystolicBloodPressure");
                    break;
                case "Diastolic Blood Pressure (mmHg)":
                    label = this.$i18n.t("patient.card.lblDiastolicBloodPressure");
                    break;
                case "delete-unlink-update":
                    label = this.$i18n.t("patient.card.lblDeleted");
                    break;
                case "sleep_score":
                    label = this.$i18n.t("patient.card.lblSleepScore");
                    break;
                case "Weight (kg)":
                    label = this.$i18n.t("patient.card.lblWeight");
                    break;
                case "Fat Free Mass (kg)":
                    label = this.$i18n.t("patient.card.lblFatFreeMass");
                    break;
                case "Height (meter)":
                    label = this.$i18n.t("patient.card.lblHeight");
                    break;
                case "Fat Mass Weight (kg)":
                    label = this.$i18n.t("patient.card.lblFatMassWeight");
                    break;
                case "distance":
                    label = this.$i18n.t("patient.card.lblDistance");
                    break;
                case "Fat Ratio (%)":
                    label = this.$i18n.t("patient.card.lblFatRatio");
                    break;
                case "steps":
                    label = this.$i18n.t("patient.card.lblSteps");
                    break;
                case "Hydration (kg)":
                    label = this.$i18n.t("patient.card.lblHydration");
                    break;
                case "Muscle Mass (kg)":
                    label = this.$i18n.t("patient.card.lblMuscleMass");
                    break;
                default:
                    label = "unknown";
                    break;
            }
            return label;
        },
        getTranslationLabel(label) {
            let returnValue = label;
            switch (label) {
                case "GoalSistolicBP":
                    returnValue = this.$i18n.t("patient.jsons.lblGoalSistolicBP");
                    break;
                case "GoalDiastolicBP":
                    returnValue = this.$i18n.t("patient.jsons.lblGoalDiastolicBP");
                    break;
                case "AvarageSistolicBP":
                    returnValue = this.$i18n.t("patient.jsons.lblAvarageSistolicBP");
                    break;
                case "AvarageDiastolicBP":
                    returnValue = this.$i18n.t("patient.jsons.lblAvarageDiastolicBP");
                    break;
                case "AvarageBPResult":
                    returnValue = this.$i18n.t("patient.jsons.lblAvarageBPResult");
                    break;
            }
            return returnValue;
        },
    },
};
</script>

<style scoped>
.alert {
    border-color: #830f00 !important;
}
.warning {
    border-color: #ff7707 !important;
}
.success {
    border: 2px solid !important;
    border-color: #88b902 !important;
}
.secondary {
    border: 2px solid !important;
    border-color: #6c757d !important;
}
</style>
