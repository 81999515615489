<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="612">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"> </date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblTherapiesComplications')" header-tag="header" footer-tag="footer" title="">
            <b-row id="diuretici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.diuretici" switch :checked="jsonData.diuretici" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblDiuretics") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoDiuretici" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzadiuretici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciadiuretici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="alfabloccanti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.alfabloccanti" switch :checked="jsonData.alfabloccanti" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAlphablockers") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAlfabloccanti" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaalfa" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaalfa" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="betabloccanti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.betabloccanti" switch :checked="jsonData.betabloccanti" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblBetablockers") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoBetabloccanti" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzabeta" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciabeta" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="alfabetabloccanti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.alfabetabloccanti" switch :checked="jsonData.alfabetabloccanti" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAlphabetablockers") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAlfabetabloccanti" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaalfabeta" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaalfabeta" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="aceinibitori">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.aceinibitori" switch :checked="jsonData.aceinibitori" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblACEinhibitors") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAceinibitori" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaace" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaace" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="at1antagonisti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.at1antagonisti" switch :checked="jsonData.at1antagonisti" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAT1antagonists") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAt1" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaat1" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaat1" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="diidropiridinici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.diidropiridinici" switch :checked="jsonData.diidropiridinici" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblDihydropyridines") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoDiidropiridinici" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzadiidropiridinici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciadiidropiridinici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="fenilalchilamine">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.fenilalchilamine" switch :checked="jsonData.fenilalchilamine" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblPhenylalkylamines") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoFenilalchilamine" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzafenilalchilamine" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciafenilalchilamine" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="benzotiazepine">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.benzotiazepine" switch :checked="jsonData.benzotiazepine" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblBenzothiazepines") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoBenzotiazepine" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzabenzotiazepine" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciabenzotiazepine" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="nitroderivati">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.nitroderivati" switch :checked="jsonData.nitroderivati" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblNitroderivatives") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoNitroderivati" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzanitroderivati" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficacianitroderivati" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="antiaggreganti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.antiaggreganti" switch :checked="jsonData.antiaggreganti" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAntiplateletAgents") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAntiaggreganti" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaantiaggreganti" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaantiaggreganti" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="digitale">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.digitale" switch :checked="jsonData.digitale" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblDigitalis") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoDigitale" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzadigitale" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciadigitale" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="antiaritmici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.antiaritmici" switch :checked="jsonData.antiaritmici" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAntiarrhythmics") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAntiaritmici" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaantiaritmici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaantiaritmici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="anticoagulanti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.anticoagulanti" switch :checked="jsonData.anticoagulanti" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAnticoagulants") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAnticoagulanti" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaanticoagulanti" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaanticoagulanti" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="antidiabetici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.antidiabetici" switch :checked="jsonData.antidiabetici" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblOralAntidiabetics") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAntidiabetici" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaantidiabetici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaantidiabetici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="antidislipidemici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <b-form-checkbox v-model="jsonData.antidislipidemici" switch :checked="jsonData.antidislipidemici" style="margin-top: 30px">{{ getLabelTraduora("patient.cardiology.lblAntidyslipidemics") }} </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAntidislipidemici" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaantidislipidemici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaantidislipidemici" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="altro">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                    <b-form-input v-model="jsonData.altrofarmaco" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAltro" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaaltro" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaaltro" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row id="altro2">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                    <b-form-input v-model="jsonData.altrofarmaco2" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-input v-model="jsonData.tipoAltro2" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <b-form-select v-model="jsonData.tolleranzaaltro2" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <b-form-select v-model="jsonData.efficaciaaltro2" :options="optionsEfficaciaTolleranza">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblOrganComplications')" header-tag="header" footer-tag="footer" title="">
            <b-row id="vasculo-cerebrale">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCerebralVasculopathy") }}</label>
                    <b-form-select v-model="jsonData.vasculocerebrale" :options="optionsVascoCere">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <b-form-input v-model="jsonData.anniVasculocereb" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="vasculo-periferica">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPeripheralVasculopathy") }}</label>
                    <b-form-select v-model="jsonData.vasculoperiferica" :options="optionsVascoPeriferica">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <b-form-input v-model="jsonData.anniVasculoperi" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="neuropatia-diabetica">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabeticNeuropathy") }}</label>
                    <b-form-select v-model="jsonData.neuropatiaDiabetica" :options="optionsNeuroDiab">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <b-form-input v-model="jsonData.anniNeurodiab" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="retinopatia">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRetinopathy") }}</label>
                    <b-form-select v-model="jsonData.retinopatia" :options="optionsRetinopatia">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <b-form-input v-model="jsonData.anniRetinopatia" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="nefropatia">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNefropathy") }}</label>
                    <b-form-select v-model="jsonData.nefropatia" :options="optionsNefropatia">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <b-form-input v-model="jsonData.anniNefropatia" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="ipertrofia-ventricolare">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVetricularIpertrophy") }}</label>
                    <b-form-select v-model="jsonData.ipertrofiaVentricolare" :options="optionsConferma">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <b-form-input v-model="jsonData.anniIpertrofia" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="coronaropatia">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCoronaropathy") }}</label>
                    <b-form-select v-model="jsonData.coronaropatia" :options="optionsCoronaropatia">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <b-form-input v-model="jsonData.anniCoronaro" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="scompenso-cardiaco">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartFailure") }}</label>
                    <b-form-select v-model="jsonData.scompensoCardiaco" :options="optionsConferma">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <b-form-input v-model="jsonData.anniScompenso" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return { dataEvento: null };
            },
        },
    },

    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            pathResource: "/cardioterapiecomplicanze",
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            optionsEfficaciaTolleranza: [
                { text: this.getLabelTraduora("patient.cardiology.Options.NotSatisfied"), value: "patient.cardiology.Options.NotSatisfied" },
                { text: this.getLabelTraduora("patient.cardiology.Options.Satisfied"), value: "patient.cardiology.Options.Satisfied" },
            ],
            optionsCoronaropatia: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblHeartAttack"), value: "patient.cardiology.Options.lblHeartAttack" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblIschemia"), value: "patient.cardiology.Options.lblIschemia" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
            ],
            optionsNeuroDiab: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblOrtostaticHypertension"), value: "patient.cardiology.Options.lblOrtostaticHypertension" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblParesthesias"), value: "patient.cardiology.Options.lblParesthesias" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblSensoryDeficit"), value: "patient.cardiology.Options.lblSensoryDeficit" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
            ],
            optionsRetinopatia: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblHypertensive"), value: "patient.cardiology.Options.lblHypertensive" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblComplicated"), value: "patient.cardiology.Options.lblComplicated" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblDiabetic"), value: "patient.cardiology.Options.lblDiabetic" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNotDetermined"), value: "patient.cardiology.Options.lblNotDetermined" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
            ],
            optionsVascoCere: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblStrumentalTia"), value: "patient.cardiology.Options.lblStrumentalTia" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblStroke"), value: "patient.cardiology.Options.lblStroke" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
            ],
            optionsVascoPeriferica: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblIschemia"), value: "patient.cardiology.Options.lblIschemia" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblCramps"), value: "patient.cardiology.Options.lblCramps" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblClaudicatio"), value: "patient.cardiology.Options.lblClaudicatio" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAmputation"), value: "patient.cardiology.Options.lblAmputation" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
            ],
            optionsNefropatia: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblHypertensive"), value: "patient.cardiology.Options.lblHypertensive" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblEdematousDiabetic"), value: "patient.cardiology.Options.lblEdematousDiabetic" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
            ],
            optionsConferma: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblYes"), value: "patient.cardiology.Options.lblYes" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>

<style></style>
