<template>
  <div v-if="isEdit">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Ricerca IgM</label>
        <b-form-select v-model="confermaLaboratorio.ricercaIgm" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Metodo</label>
        <b-form-select v-model="confermaLaboratorio.metodo" :options="metodiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Specificare Altro Metodo</label>
        <b-form-input v-model="confermaLaboratorio.metodoAltro" :disabled="confermaLaboratorio.metodo !== 'ALTRO'"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data raccolta campione</label>
        <date-picker v-model="confermaLaboratorio.dataRaccoltaCampione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Esito</label>
        <b-form-select v-model="confermaLaboratorio.esitoIgm" :options="esitiIgmOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Tipo di Campione</label>
        <b-form-select v-model="confermaLaboratorio.tipoCampioneIgm" :options="tipiCampioniOptions" :value="''" value-field="value" text-field="text" @input="onInputTipoCampioneImg"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Altro</label>
        <b-form-textarea v-model="confermaLaboratorio.campioneAltro" rows="4" no-resize :disabled="campioneAltroDisabled"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Ricerca IgG<b-icon icon="info-circle" v-b-popover.hover.top="'La misurazione deve essere effettuata su due campioni, il primo prelevato in fase acuta e il secondo in fase convalescente. Il risultato è positivo se si verificano o una siero conversione o un aumento di almeno 4 volte del titolo anticorpale'"></b-icon></label>
        <b-form-select v-model="confermaLaboratorio.ricercaIgg" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Metodo</label>
        <b-form-select v-model="confermaLaboratorio.metod" :options="metodiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Specificare Altro Metodo</label>
        <b-form-input v-model="confermaLaboratorio.metodAltro" :disabled="confermaLaboratorio.metod !== 'ALTRO'"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data raccolta campione Fase Acuta</label>
        <date-picker v-model="confermaLaboratorio.dataRaccoltaCampioneFaseAcuta" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data raccolta campione Fase Convalescente</label>
        <date-picker v-model="confermaLaboratorio.dataRaccoltaCampioneFaseConvalescente" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Esito</label>
        <b-form-select v-model="confermaLaboratorio.esitoIgg" :options="esitiIggOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Tipo di Campione</label>
        <b-form-select v-model="confermaLaboratorio.tipoCampioneIgg" :options="tipiCampioniOptions" :value="''" value-field="value" text-field="text" @input="onInputTipoCampioneIgg"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Altro</label>
        <b-form-textarea v-model="confermaLaboratorio.tipoCampioneIggAltro" rows="4" no-resize :disabled="campioneIggAltroDisabled"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">P.C.R.</label>
        <b-form-select v-model="confermaLaboratorio.pcr" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Data raccolta campione</label>
        <date-picker v-model="confermaLaboratorio.dataRaccoltaCampionePcr" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Esito</label>
        <b-form-select v-model="confermaLaboratorio.esitoPcr" :options="esitiIggOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Tipo di Campione</label>
        <b-form-select v-model="confermaLaboratorio.tipoCampionePcr" :options="tipiCampioniOptions" :value="''" value-field="value" text-field="text" @input="onInputTipoCampionePcr"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Altro</label>
        <b-form-textarea v-model="confermaLaboratorio.tipoCampionePcrAltro" rows="4" no-resize :disabled="campionePcrAltroDisabled"></b-form-textarea>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Specificare Altro Test</label>
        <b-form-input v-model="confermaLaboratorio.specificareAltroTest"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data Raccolta campione</label>
        <date-picker v-model="confermaLaboratorio.altroTestDataRaccoltaCampione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Esito</label>
        <b-form-select v-model="confermaLaboratorio.altroTestEsito" :options="esitiIgmOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Tipo Campione</label>
        <b-form-select v-model="confermaLaboratorio.altroTestTipoCampione" :options="tipiCampioniOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Specificare Altro Tipo Campione</label>
        <b-form-input v-model="confermaLaboratorio.altroTestSpecificareAltreo" :disabled="confermaLaboratorio.altroTestTipoCampione !== 'ALTRO'"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Genotipizzazione</label>
        <b-form-select v-model="confermaLaboratorio.genotipizzazione" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Genotipo</label>
        <b-form-input v-model="confermaLaboratorio.genotipo"></b-form-input>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Ricerca IgM</label>
        <span class="sa-data">{{ confermaLaboratorio.ricercaIgm }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Metodo</label>
        <span class="sa-data">{{ confermaLaboratorio.metodo }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data raccolta campione</label>
        <span class="sa-data">{{ confermaLaboratorio.dataRaccoltaCampione }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Esito</label>
        <span class="sa-data">{{ confermaLaboratorio.esitoIgm }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Tipo di Campione</label>
        <span class="sa-data">{{ confermaLaboratorio.tipoCampioneIgm }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Altro</label>
        <span class="sa-data-justify">{{ confermaLaboratorio.campioneAltro }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Ricerca IgG<b-icon icon="info-circle" v-b-popover.hover.top="'La misurazione deve essere effettuata su due campioni, il primo prelevato in fase acuta e il secondo in fase convalescente. Il risultato è positivo se si verificano o una siero conversione o un aumento di almeno 4 volte del titolo anticorpale'"></b-icon></label>
        <span class="sa-data">{{ confermaLaboratorio.ricercaIgg }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Metodo</label>
        <span class="sa-data">{{ confermaLaboratorio.metod }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data raccolta campione Fase Acuta</label>
        <span class="sa-data">{{ formatDate(confermaLaboratorio.dataRaccoltaCampioneFaseAcuta) }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data raccolta campione Fase Convalescente</label>
        <span class="sa-data">{{ formatDate(confermaLaboratorio.dataRaccoltaCampioneFaseConvalescente) }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Esito</label>
        <span class="sa-data">{{ confermaLaboratorio.esitoIgg }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Tipo di Campione</label>
        <span class="sa-data">{{ confermaLaboratorio.tipoCampioneIgg }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Altro</label>
        <span class="sa-data-justify">{{ confermaLaboratorio.tipoCampioneIggAltro }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">P.C.R.</label>
        <span class="sa-data">{{ confermaLaboratorio.pcr }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Data raccolta campione</label>
        <span class="sa-data">{{ formatDate(confermaLaboratorio.dataRaccoltaCampionePcr) }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Esito</label>
        <span class="sa-data">{{ confermaLaboratorio.esitoPcr }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Tipo di Campione</label>
        <span class="sa-data">{{ confermaLaboratorio.tipoCampionePcr }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Altro</label>
        <span class="sa-data-justify">{{ confermaLaboratorio.tipoCampionePcrAltro }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Specificare Altro Test</label>
        <span class="sa-data">{{ confermaLaboratorio.specificareAltroTest }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data Raccolta campione</label>
        <span class="sa-data">{{ formatDate(confermaLaboratorio.altroTestDataRaccoltaCampione) }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Esito</label>
        <span class="sa-data">{{ confermaLaboratorio.altroTestEsito }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Tipo Campione</label>
        <span class="sa-data">{{ confermaLaboratorio.altroTestTipoCampione }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Specificare Altro Tipo Campione</label>
        <span class="sa-data">{{ confermaLaboratorio.altroTestSpecificareAltreo }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Genotipizzazione</label>
        <span class="sa-data">{{ confermaLaboratorio.genotipizzazione }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Genotipo</label>
        <span class="sa-data">{{ confermaLaboratorio.genotipo }}</span>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  props: {
    isEdit: Boolean,
    confermaLaboratorio: {
      Type: Object,
      return: {
        malattia: "",
        ricercaIgm: "",
        metodo: "",
        dataRaccoltaCampione: null,
        esitoIgm: "",
        tipoCampioneIgm: "",
        campioneAltro: "",
        ricercaIgg: "",
        metod: "",
        dataRaccoltaCampioneFaseAcuta: null,
        dataRaccoltaCampioneFaseConvalescente: null,
        esitoIgg: "",
        tipoCampioneIgg: "",
        tipoCampioneIggAltro: "",
        pcr: "",
        dataRaccoltaCampionePcr: null,
        esitoPcr: "",
        tipoCampionePcr: "",
        tipoCampionePcrAltro: "",
        genotipizzazione: "",
        genotipo: "",
      },
    },
  },
  components: { DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      campioneAltroDisabled: true,
      campioneIggAltroDisabled: true,
      campionePcrAltroDisabled: true,
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      metodiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "ELISA", text: "ELISA" },
        { value: "ALTRO", text: "ALTRO" },
      ],
      esitiIgmOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
        { value: "BORDERLINE O INDETERMINATO", text: "BORDERLINE O INDETERMINATO" },
      ],
      tipiCampioniOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SIERO O SANGUE", text: "SIERO O SANGUE" },
        { value: "SALIVA O FLUIDI ORALI", text: "SALIVA O FLUIDI ORALI" },
        { value: "ALTRO", text: "ALTRO" },
      ],
      esitiIggOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO (SOLO SE AUMENTO DI 4 VOLTE DEL TITOLO ANTICORPALE O SIEROCONVERSIONE)" },
        { value: "NEGATIVO", text: "NEGATIVO (IN TUTTI GLI ALTRI CASI)" },
      ],
      esitiPcrOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
      ],
    };
  },
  watch: {
    confermaLaboratorio: {
      handler(confermaLaboratorio) {
        // console.log(confermaLaboratorio);
        this.$emit("update", confermaLaboratorio);
      },
      deep: true,
    },
  },
  methods: {
    onInputTipoCampioneImg(value) {
      let me = this;
      if (value === "ALTRO") {
        me.campioneAltroDisabled = false;
      } else {
        me.campioneAltroDisabled = true;
        me.confermaLaboratorio.campioneAltro = "";
      }
    },
    onInputTipoCampioneIgg(value) {
      let me = this;
      if (value === "ALTRO") {
        me.campioneIggAltroDisabled = false;
      } else {
        me.campioneIggAltroDisabled = true;
        me.confermaLaboratorio.tipoCampioneIggAltro = "";
      }
    },
    onInputTipoCampionePcr(value) {
      let me = this;
      if (value === "ALTRO") {
        me.campionePcrAltroDisabled = false;
      } else {
        me.campionePcrAltroDisabled = true;
        me.confermaLaboratorio.tipoCampionePcrAltro = "";
      }
    },
  },
};
</script>
