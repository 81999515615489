import MalattieInfettiveList  from "../malattieinfettive/pages/MalattieInfettiveList"
import MalattieInfettiveView  from "../malattieinfettive/pages/MalattieInfettiveView"
import MalattieInfettiveEdit from "../malattieinfettive/pages/MalattieInfettiveEdit"
import MalattieInfettiveSegnalazioniList from "../malattieinfettive/pages/MalattieInfettiveSegnalazioniList"
import MalattieInfettiveArchivioList from "../malattieinfettive/pages/MalattieInfettiveArchivioList"

export default {
    MalattieInfettiveList,
    MalattieInfettiveView,
    MalattieInfettiveEdit,
    MalattieInfettiveSegnalazioniList,
    MalattieInfettiveArchivioList
};