<template>
    <div class="sa-container">
        <sa-sidebar-paziente :idPaziente="idPaziente" v-bind:externalmenu="menu" v-bind:nominativoUtente="nominativoUtente" v-bind:ruolo="ruolo"></sa-sidebar-paziente>
        <div class="sa-main">
            <sa-nav-bar :visible="true" :pageTitle="pageTitle" :userName="nominativoUtente" :idUtente="user.id" />
            <div class="sa-main-container">
                <router-view :utente="user" :key="key" />
            </div>
        </div>
        <div id="sa-sidebar-overlay" v-sa-aside-toggle></div>
        <sa-modal-loading ref="sa-modal-loading" />
    </div>
</template>

<script>
import axios from "axios";
import { SaSidebarPaziente, SaNavBar } from "../../../template/layout";
import { SaModalLoading } from "../../utility";
export default {
    components: { SaSidebarPaziente, SaNavBar, SaModalLoading },
    data() {
        return {
            idPaziente: null,
            avatar: "",
            pageTitle: "",
            nominativoUtente: "",
            ruolo: "Super Administrator",
            user: { idUtente: null },
            key: 0,
            menu: [],
        };
    },
    created() {
        let me = this;
        me.idPaziente = this.$route.params.id;
        if (this.$route.query.idPaziente !== undefined) {
            me.idPaziente = this.$route.query.idPaziente;
        }
        this.loadUserData();
        this.loadPatientMenu();
        this.interval = setInterval(() => me.verificaSessione(), 180000);
    },
    mounted() {
        let me = this;
        if (this.$route.query.idPaziente !== undefined) {
            me.idPaziente = this.$route.query.idPaziente;
        }
    },
    methods: {
        verificaSessione() {
            let link = process.env.VUE_APP_PATH_API + "/sessioncontrol";
            axios
                .get(link)
                .then(() => {})
                .catch(() => {
                    clearInterval(this.interval);
                    this.$router.push("/login");
                });
        },
        loadUserData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/users/profile";
            axios
                .get(link)
                .then((response) => {
                    me.utente = response.data.data;
                    if (me.id === null) {
                        me.id = me.utente.id;
                    }
                    me.user = me.utente;
                    me.nominativoUtente = me.utente.lastname + " " + me.utente.firstname;
                    me.ruolo = me.utente.profilo;
                    me.key = me.key++;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        loadPatientMenu() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazientemenu";
            axios
                .get(link, { params: { tipo: "left" } })
                .then((response) => {
                    me.menu = response.data.data;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },
        getIcon(item) {
            let returnVale = "bi bi-" + item.iconCls;
            return returnVale;
        },
        onMenuDownClick(event) {
            event.currentTarget.classList.toggle("sa-show-sub-menu");
        },
    },
};
</script>

<style></style>
