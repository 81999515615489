<template>
  <div style="display: inline-block">
    <b-button v-if="typeButton === 'normal'" v-b-modal.stampaReferto variant="btn--md btn-light waves-effect waves-light btn-toolbar sa-margin-right" size="sm" @click="onPrint">
      <b-icon icon="printer" />
      {{ nomeCampo }}
    </b-button>
    <b-button v-if="typeButton === 'small' && !firmaGrafometrica" size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onPrint">
      <b-icon icon="printer"></b-icon>
    </b-button>
    <b-button v-if="typeButton === 'small' && firmaGrafometrica" size="sm" variant="outline-secondary no-text" style="margin-right: 3px" @click="onPrint">
      <b-icon icon="pencil-square"></b-icon>
    </b-button>
    <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%">
      <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
    </b-modal>
    <!-- Modal per la Firma Grafometrica -->
    <b-modal ref="mdlStampaFirmaGrafometrica" size="xl" style="height: 100%" scrollable>
      <!-- <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed> -->
      <vue-pdf-embed ref="pdfNotSignedEmbeddedComponent" :source="stampaReport"></vue-pdf-embed>
      <template #modal-footer>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-label-data">Dispositivo:</label>
            <!-- <b-form-select v-model="dispositivo" :options="dispositiviOption" value-field="value" text-field="text"></b-form-select> -->
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <!-- <label class="sa-label-data">Dispositivo</label> -->
            <b-form-select v-model="dispositivo" :options="dispositiviOption" value-field="value" text-field="text"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-button size="sm" variant="danger" @click="onCloseClick()">Chiudi</b-button>
            <b-button size="sm" variant="outline-secondary" @click="onFirmaGrafometrica()">
              <b-icon icon="pencil-square"></b-icon>
              Firma Grafometrica
            </b-button>
            <b-button size="sm" variant="outline-secondary" @click="onPrintNotSigned()">
              <b-icon icon="printer"></b-icon>
              Stampa
            </b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
    <!-- Modal per la Firma Grafometrica Fine -->
    <b-modal :static="true" ref="mdlGenerazioneReportInCorso" hide-footer hide-header no-close-on-esc no-close-on-backdrop>
      <div class="text-center">
        <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner" variant="info"></b-spinner>
      </div>
      <p class="my-4" style="text-align: center">Generazione Stampa in corso...</p>
    </b-modal>
    <b-modal :static="true" ref="mdlInvioAlDispositivoInCorso" hide-footer hide-header no-close-on-esc no-close-on-backdrop>
      <div class="text-center">
        <b-spinner style="width: 3rem; height: 3rem" label="Large Spinner" variant="info"></b-spinner>
      </div>
      <p class="my-4" style="text-align: center">Invio al dispositivo in corso...</p>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
export default {
  components: { VuePdfEmbed },
  props: {
    reportName: String,
    printData: null,
    linkPrintData: null,
    jsonData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    firmaGrafometrica: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    typeButton: {
      type: String,
      default: function () {
        return "normal";
      },
    },
    nomeCampo: {
      type: String,
      default: function () {
        return "Stampa";
      },
    },
  },
  data() {
    return {
      stampaReport: null,
      linkStampa: "/print/createreport",
      pathResourceAllegati: "/allegati",
      pathResourceDispositivi: "/digitalsigndevice",
      pathDigitalSign: "/sign",
      dispositiviOption: [{ value: "", text: "-Seleziona Dispositivo-" }],
      dispositivo: "",
    };
  },
  mounted() {
    let me = this;
    if (me.firmaGrafometrica) {
      me.loadDevice();
    }
  },
  methods: {
    loadDevice() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDispositivi;
      let filtro = { tipoFirma: "GRAFOMETRICA", gestore: "KEFIRMA" };
      me.dispositiviOption = [];
      axios.get(link, { params: filtro }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.dispositiviOption.push({ value: element.codiceDispositivo, text: element.descrizioneDispositivo + " (" + element.codiceDispositivo + ")" });
        });
        me.dispositiviOption.unshift({ value: "", text: "-Seleziona Dispositivo-" });
      });
    },
    onPrint() {
      let me = this;

      if (me.printData != null) {
        me.$refs.mdlGenerazioneReportInCorso.show();
        me.print(me.printData);
      } else if (me.linkPrintData != null) {
        let link = process.env.VUE_APP_PATH_API + me.linkPrintData;
        me.$refs.mdlGenerazioneReportInCorso.show();
        axios
          .get(link)
          .then((response) => {
            me.print(response.data.data);
          })
          .catch((error) => {
            me.$refs.mdlGenerazioneReportInCorso.hide();
            let messaggio = error.response.data.messaggio ? error.response.data.messaggio : "Errore non gestito, Codice: " + error.response.status;
            this.$bvModal
              .msgBoxOk(messaggio, {
                title: this.$i18n.t("ERROR!"),
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        this.$bvModal
          .msgBoxOk("Dati da stampare mancanti", {
            title: this.$i18n.t("ERROR!"),
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            this.boxTwo = value;
          })
          .catch((err) => {
            console.log(err);
          });
        console.log("Dati da stampare mancanti");
      }
    },
    print(data) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkStampa;

      axios
        .post(link, data)
        .then((response) => {
          me.$refs.mdlGenerazioneReportInCorso.hide();

          if (me.firmaGrafometrica) {
            me.$refs.mdlStampaFirmaGrafometrica.show();
          } else {
            me.$refs.mdlStampaReferto.show();
          }
          me.stampaReport = "data:application/pdf;base64," + response.data.base64;
        })
        .catch((error) => {
          me.$refs.mdlGenerazioneReportInCorso.hide();
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onCloseClick() {
      let me = this;
      me.mdlStampaFirmaGrafometricaClose();
    },
    mdlStampaFirmaGrafometricaClose() {
      let me = this;
      me.$refs.mdlStampaFirmaGrafometrica.hide();
    },
    onFirmaGrafometrica() {
      let me = this;
      let obj = me.getDataPdf(me.data);
      let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegati;
      me.showModalLoading = true;
      //   console.log(me.dispositivo);
      if (me.dispositivo !== null && me.dispositivo !== "") {
        axios
          .post(link, obj)
          .then((response) => {
            let signBean = {
              gestore: "KEFIRMA",
              tipo: "GRAFOMETRICA",
              username: "sisto.andolfi@gesan.it",
              pin: "j!ghsrfugR46G",
              codiceDispositivo: me.dispositivo,
              codiceApplicativo: "SVEI-01",
              files: [{ idRiferimento: response.data.data.id, filename: response.data.data.nomeFile, fileBase64: response.data.data.file }],
            };
            me.digilatSignRequest(signBean);
          })
          .catch((error) => {
            me.$emit("closeModal");
            let message = "";
            error.response ? error.response.data.messaggio : "Errore non gestito";
            if (error.response) {
              message = error.response.data.messaggio ? error.response.data.messaggio : "Codice Errore: " + error.response.status + ", non gestito";
            }
            if (error.response) {
              this.$bvModal
                .msgBoxOk(message, {
                  title: "ATTENZIONE",
                  size: "sm",
                  okVariant: "outline-danger",
                  headerClass: "sa-msg-header-danger",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                })
                .then(() => {})
                .catch((err) => {
                  console.log(err);
                });
            }
            me.showModalLoading = false;
          });
      } else {
        this.$bvModal
          .msgBoxOk("Dispositivo non selezionato", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }
    },
    digilatSignRequest(signBean) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathDigitalSign;
      // me.showModalLoading = true;
      me.$refs.mdlInvioAlDispositivoInCorso.show();
      axios
        .post(link, signBean)
        .then((response) => {
          me.$refs.mdlInvioAlDispositivoInCorso.hide();
          let message = response.data.messaggio;

          this.$bvModal
            .msgBoxOk(message, {
              title: "Info",
              size: "sm",
              okVariant: "outline-success",
              headerClass: "sa-msg-header-success",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {
              me.$refs.mdlStampaFirmaGrafometrica.hide();
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((error) => {
          me.$emit("closeModal");
          let message = "";
          error.response ? error.response.data.messaggio : "Errore non gestito";
          if (error.response) {
            message = error.response.data.messaggio ? error.response.data.messaggio : "Codice Errore: " + error.response.status + ", non gestito";
          }
          if (error.response) {
            this.$bvModal
              .msgBoxOk(message, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                console.log(err);
              });
          }
        });
    },
    getDataPdf() {
      let me = this;
      let objPdf = {};
      objPdf.idRisorsa = me.jsonData.id;
      objPdf.idAnagrafica = null;
      objPdf.nomeFile = "Scheda SVEI_" + moment(new Date()).format("YYYY_MM_DD_HHmmss") + ".pdf";
      objPdf.size = 0;
      objPdf.file = me.stampaReport;
      objPdf.tipo = "application/pdf";
      // objPdf.dataCaricamento = obj.dataCaricamento;
      objPdf.descrizione = "Scheda SVEI";
      objPdf.tipoDocumento = "SCHEDA SVEI";
      return objPdf;
    },
    onPrintNotSigned() {
      this.$refs.pdfNotSignedEmbeddedComponent.print();
    },
  },
};
</script>
