<template>
  <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-input v-model="filtro.nome" type="search" id="nome" placeholder="Nome"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-input v-model="filtro.cognome" type="search" id="cognome" placeholder="Cognome"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" :placeholder="this.$i18n.t('adi.lblFiscalCode')"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3" class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
        <!-- <b-row style="margin-left: 0.5rem; margin-right: 0.5rem"> </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row> -->
      </b-form>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="items" :filter="filtro" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(codiceFiscale)="{ item }">
            <router-link class="sa-edit-link" :to="'/adipazienti/richieste/scheda/edit/' + item.id + '?fonte=' + fonte"> {{ item.codiceFiscale }} </router-link>
          </template>
          <template v-slot:cell(paziente)="{ item }">
            <a> {{ item.nome }} {{ item.cognome }} </a>
          </template>
          <!-- <template #cell(actions)="row">
            <div v-if="row.item.statoAttuale !== 'INVIATA' && row.item.statoAttuale !== 'ELIMINATA'">
              <b-button size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm" @click="onCambiaStato(row.item.id, 'INVIATA')"> Invia </b-button>
              <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm" @click="onCambiaStato(row.item.id, 'ELIMINATA')"> Elimina </b-button>
            </div>
          </template> -->
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import AdiPropostaAccessoStatiCompnent from "../components/AdiPropostaAccessoStatiCompnent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    pathResource: String,
    fonte: String,
  },
  components: { SaPageLayout },
  mixins: [UtilityMixin],
  data() {
    return {
      filtro: {},
      currentPage: 1,
      perPage: 50,
      rows: null,
      showModalLoading: false,
      btnNewVisible: false,
      linkedit: "/adipazienti/richieste/scheda",
      linkback: "/adipazienti" + this.fonte,
      pathCambiaStato: "/adiproposteaccesso/aggiornaStato",
      idPua: null,
      //   pathResource: "/adiproposteaccesso",
      items: [],
      fields: [
        {
          label: this.getLabelTraduora("adi.lblFiscalCode"),
          key: "codiceFiscale",
          thStyle: "width: 10rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("adi.lblPatient"),
          key: "paziente",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("adi.lblState"),
          key: "statoAttuale",
          thStyle: "width: 15rem",
          sortable: true,
        },
        {
          label: this.getLabelTraduora("adi.lblCreateDate"),
          key: "dataCreazione",
          thStyle: "width: 8rem",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 12rem",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["idPua"]) {
      // console.log(sessionStorage.getItem("idPua"));
      me.idPua = sessionStorage.getItem("idPua");
    }
    // console.log(me.idPua);
    me.loadData();
    me.impostaPermessi();
  },
  watch: {
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      if (me.fonte === "/richieste") {
        me.btnNewVisible = UtilityMixin.methods.verificaPermessi(me.utente, "adiproposteaccesso", 2);
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.idPuaAppartenenza = me.idPua;
      me.filtro.forPage = me.perPage;
      me.filtro.page = me.currentPage;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onUpdateStato() {
      let me = this;
      me.loadData();
    },
    onCambiaStato(riga, stato) {
      // console.log(stato);
      // console.log(riga);
      let messaggio = "";
      switch (stato) {
        case "INVIATA":
          messaggio = "inviare la richiesta?";
          break;
        case "ELIMINATA": {
          messaggio = "eliminare la richiesta?";
        }
      }
      this.$bvModal
        .msgBoxConfirm("Sicuro di voler " + messaggio, {
          title: "Titolo",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            let json = { idPropostaAccesso: riga, stato: stato };
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathCambiaStato;
            axios
              .post(link, json)
              .then((response) => {
                // console.log(response);
                this.$bvToast.toast("Cambio Stato: " + response.data.data.stato, {
                  title: this.titleModal,
                  variant: "success",
                  autoHideDelay: 1000,
                });
                me.loadData();
              })
              .catch(() => {});
            axios;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
