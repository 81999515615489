<template>
    <div class="sa-form-section">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblDateVisit") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataEvento) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSportPracticed") }}</label>
                    <span class="sa-data">{{ jsonData.sportPraticato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblFollowingVisit") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.visitaSuccessiva) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblWeeklyWorkouts") }}
                        <eventi-storico-misure-component misura="allenamenti_settimana" misuraLabel="Allenamenti Settimanali" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.allenamentiSettimana }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSpeciality") }}</label>
                    <span class="sa-data">{{ jsonData.specialita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblFederation") }}</label>
                    <span class="sa-data">{{ jsonData.federazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblOtherSports") }}</label>
                    <span class="sa-data">{{ jsonData.altriSport }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblAntitetanus") }}</label>
                    <span class="sa-data">{{ jsonData.antitetanica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblAlcohol") }}</label>
                    <span class="sa-data">{{ jsonData.alcool }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblNarcoticSubstances") }}</label>
                    <span class="sa-data">{{ jsonData.sostenzeStupefacenti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblNextVisit") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.prossimaVisita) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHourNextVisit") }}</label>
                    <span class="sa-data">{{ formatTime(jsonData.oraAppuntamento) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblYearsNumber") }}</label>
                    <span class="sa-data">{{ etaPaziente }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblHoursWorkout") }}
                        <eventi-storico-misure-component misura="ore_allenamento" misuraLabel="Ore Allenanemnto" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.oreAllenamento }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblCorporation") }}</label>
                    <span class="sa-data">{{ jsonData.societa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblLHB") }}</label>
                    <span class="sa-data">{{ jsonData.aslNew }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblMenarche") }}</label>
                    <span class="sa-data">{{ jsonData.menarca }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSmoking") }}</label>
                    <span class="sa-data">{{ jsonData.fumo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblCoffee") }}</label>
                    <span class="sa-data">{{ jsonData.caffe }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblMilitary") }}</label>
                    <span class="sa-data">{{ jsonData.militare }}</span>
                </b-col>
                <b-col v-if="jsonData.militare !== 'No'" cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblMilitaryMotif") }}</label>
                    <span class="sa-data">{{ jsonData.militareMotivo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocument") }}</label>
                    <span class="sa-data">{{ jsonData.documentoIdentitaTipo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocumentNumber") }}</label>
                    <span class="sa-data">{{ jsonData.documentoIdentitaNumero }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocumentReleaser") }}</label>
                    <span class="sa-data">{{ jsonData.documentoIdentitaRilasciatoDa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocumentDateRelease") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.documentoIdentitaRilasciatoIl) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],

    components: { EventiStoricoMisureComponent },
    props: {
        tipoEvento: { type: String, require: true },
        gruppo: { type: String, require: true },
        jsonData: { Type: Object, require: true },
        etaPaziente: { Type: String },
    },
    data() {
        return {};
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.jsonData.idPaziente;
        },
    },
    mounted() {
        // console.log(this.jsonData.oraAppuntamento);
    },
};
</script>
