<template>
  <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
    <template slot="tab-content-event">
      <ecocarotidi-view-component :idEvento="id" @update="onUpdateJsonData"></ecocarotidi-view-component>
    </template>
  </eventi-view-component>
</template>
<script>
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixins from "../../../utility/mixins/EventiMixins";
import EcocarotidiViewComponent from "./EcocarotidiViewComponent.vue";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";

export default {
  mixins: [UtilityMixins, EventiMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { EcocarotidiViewComponent, EventiViewComponent },
  data() {
    return {
      titoloEvento: "Ecocarotidi",
      linkback: null,
      linkedit: null,
      pathResource: "/cardioecocarotidi",
      pathResourceFirma: "/cardioecocarotidi",
      id: null,
      jsonData: {},
      showModalLoading: false,
      aterosclerosiNonEmoSign: false,
      aterosclerosiSevera: false,
      fontScale: 2,
      pathRestValidation: "/validation",
      isThisFirmato: false,
      linkPrintData: null,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.id = this.$route.params.id;
  },
  methods: {
    updateStatoFirma(firmato) {
      console.log(firmato);
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
      me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
    },
  },
};
</script>
