<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Data Ora Accettazione</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraAccettazione" type="datetime"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Data Ora Inizio Esame</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraInizioEsame" type="datetime"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Data Ora Fine Esame</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraFineEsame" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Peso</label>
                    <b-form-input v-model="jsonData.peso"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Altezza</label>
                    <b-form-input v-model="jsonData.altezza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Accesso</label>
                    <b-form-input v-model="jsonData.tipoAccesso"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Esame</label>
                    <b-form-input v-model="jsonData.esame"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Provenienza</label>
                    <b-form-input v-model="jsonData.provenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Mobilità</label>
                    <b-form-input v-model="jsonData.mobilita"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Accompagnatore</label>
                    <b-form-input v-model="jsonData.accompagnatore"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Comunicazione Con Accompagnatore</label>
                    <b-form-input v-model="jsonData.comunicazioneAccompagnatore"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Comorbidità" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Comorbidità</label>
                    <b-form-input v-model="jsonData.cormobidita"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note Comorbidità</label>
                    <b-form-textarea v-model="jsonData.noteCormobidita" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="INTERVENTI ADDOMINALI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ha subito interventi</label>
                    <b-form-select v-model="jsonData.interventi" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaInterventiAddominali" :fields="fieldsInterventi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                        <template #cell(tipoIntervento)="item">
                            <b-form-input v-model="item.item.tipoIntervento"></b-form-input>
                        </template>
                        <template #cell(descrizione)="item">
                            <b-form-input v-model="item.item.descrizione"></b-form-input>
                        </template>
                        <template #cell(data)="item">
                            <date-picker format="DD-MM-YYYY" v-model="item.item.data" value-type="timestamp" type="date"></date-picker>
                        </template>
                        <template #cell(actions)="row">
                            <b-button v-if="row.index == jsonData.listaInterventiAddominali.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddInterventi(jsonData.listaInterventiAddominali, row.item)">
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                            <b-button v-if="row.index !== jsonData.listaInterventiAddominali.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaInterventiAddominali, row)">
                                <b-icon icon="trash"></b-icon>
                            </b-button>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Allergie</label>
                    <b-form-textarea v-model="jsonData.allergie" rows="6" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Antiaggreganti</label>
                    <b-form-textarea v-model="jsonData.antiaggreganti" rows="6" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Anticoagulanti</label>
                    <b-form-textarea v-model="jsonData.anticoagulanti" rows="6" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Terapia Domiciliare</label>
                    <b-form-textarea v-model="jsonData.terapiaDomiciliare" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Valutazione Pre-Sedazione ASA</label>
                    <b-form-select v-model="jsonData.valutazionePreSedazioneAsa" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Eligibile NAAP</label>
                    <b-form-select v-model="jsonData.eligibileNaap" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Preparazione Intestinale Digiuno</label>
                    <b-form-select v-model="jsonData.preparazioneIntestinaleDigiuno" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Preparazione Intestinale Prodotto</label>
                    <b-form-select v-model="jsonData.preparazioneIntestinaleProdotto" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Stato di Coscienza</label>
                    <b-form-input v-model="jsonData.statoCoscienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Effetti Personali</label>
                    <b-form-textarea v-model="jsonData.effettiPersonali" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Monitoraggio Paziente - PRE" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ore</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.preOre" type="time"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere</label>
                    <b-form-input v-model="jsonData.preInfermiere"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">F.C. (b/m)</label>
                    <b-form-input v-model="jsonData.preFcBm"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Sistolica (mmHg)</label>
                    <b-form-input v-model="jsonData.prePressioneSistolica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Diastolica (mmHg)</label>
                    <b-form-input v-model="jsonData.prePressioneDiastolica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SatO2 (%)</label>
                    <b-form-input v-model="jsonData.preSaturazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">O2 (l/m)</label>
                    <b-form-input v-model="jsonData.preO2"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dolore</label>
                    <b-form-input v-model="jsonData.preDolore"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Farmaci e Dosi</label>
                    <b-form-textarea v-model="jsonData.preFarmaci" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Monitoraggio Paziente - INTRA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ore</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.intraOre" type="time"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere</label>
                    <b-form-input v-model="jsonData.intraInfermiere"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">F.C. (b/m)</label>
                    <b-form-input v-model="jsonData.intraFcBm"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Sistolica (mmHg)</label>
                    <b-form-input v-model="jsonData.intraPressioneSistolica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Diastolica (mmHg)</label>
                    <b-form-input v-model="jsonData.intraPressioneDiastolica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SatO2 (%)</label>
                    <b-form-input v-model="jsonData.intraSaturazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">O2 (l/m)</label>
                    <b-form-input v-model="jsonData.intraO2"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dolore</label>
                    <b-form-input v-model="jsonData.intraDolore"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Farmaci e Dosi</label>
                    <b-form-textarea v-model="jsonData.intraFarmaci" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Monitoraggio Paziente - POST" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ore</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.postOre" type="time"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere</label>
                    <b-form-input v-model="jsonData.postInfermiere"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">F.C. (b/m)</label>
                    <b-form-input v-model="jsonData.postFcBm"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Sistolica (mmHg)</label>
                    <b-form-input v-model="jsonData.postPressioneSistolica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Diastolica (mmHg)</label>
                    <b-form-input v-model="jsonData.postPressioneDiastolica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SatO2 (%)</label>
                    <b-form-input v-model="jsonData.postSaturazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">O2 (l/m)</label>
                    <b-form-input v-model="jsonData.postO2"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dolore</label>
                    <b-form-input v-model="jsonData.postDolore"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Farmaci e Dosi</label>
                    <b-form-textarea v-model="jsonData.postFarmaci" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Monitoraggio Paziente - SALA RISVEGLIO DIMISSIONE" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ore</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.salaRisveglioDimissioniOre" type="time"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere</label>
                    <b-form-input v-model="jsonData.salaRisveglioDimissioniInfermiere"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">F.C. (b/m)</label>
                    <b-form-input v-model="jsonData.salaRisveglioDimissioniFcBm"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Sistolica (mmHg)</label>
                    <b-form-input v-model="jsonData.salaRisveglioDimissioniPressioneSistolica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Diastolica (mmHg)</label>
                    <b-form-input v-model="jsonData.salaRisveglioDimissioniPressioneDiastolica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SatO2 (%)</label>
                    <b-form-input v-model="jsonData.salaRisveglioDimissioniSaturazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">O2 (l/m)</label>
                    <b-form-input v-model="jsonData.salaRisveglioDimissioniO2"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dolore</label>
                    <b-form-input v-model="jsonData.salaRisveglioDimissioniDolore"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Farmaci e Dosi</label>
                    <b-form-textarea v-model="jsonData.salaRisveglioDimissioniFarmaci" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Richiesta Istologico</label>
                    <b-form-select v-model="jsonData.richiestaIstologica" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Complicanze</label>
                    <b-form-textarea v-model="jsonData.complicanze" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dimissione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Ora Dimissioe</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dimissioniDataOra" type="datetime"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere Di Sala Endoscopica</label>
                    <b-form-input v-model="jsonData.infermiereSalaEndoscopica"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere Di Sala Risveglio</label>
                    <b-form-input v-model="jsonData.infermiereSalaRisveglio"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Medico</label>
                    <b-form-input v-model="jsonData.medico"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <eventi-operatori-component :operatori="jsonData.listaOperatori" @update="onUpdateListaOperatori" :isEdit="isEdit"></eventi-operatori-component>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import moment from "moment";
// import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
    components: { EventiOperatoriComponent, DatePicker },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        // me.loadStrumenti();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        "jsonData.interventi": function (value) {
            let me = this;
            if (value === "SI") {
                me.jsonData.listaInterventiAddominali.push({ tipoIntervento: "", descrizione: "", data: null });
            } else {
                me.jsonData.listaInterventiAddominali = [];
            }
        },
    },
    data() {
        return {
            isEdit: true,
            pathResource: "/gastroschedainfermieristica",
            currentPage: 1,
            perPage: 10,
            fieldsInterventi: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo Intervento",
                    key: "tipoIntervento",
                    thStyle: "width: 15rem",
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                },
                {
                    label: "Data",
                    key: "data",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 12rem",
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
            ],
            siNoOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "SI", text: "SI" },
                { value: "NO", text: "NO" },
            ],
            filtro: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        onAddInterventi(array, value) {
            if (value.tipoIntervento !== "" && value.descrizione !== "" && value.data !== null) {
                array.push({ tipoIntervento: "", descrizione: "", data: null });
            } else {
                this.$bvModal
                    .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onUpdateListaOperatori(listaOperatori) {
            let me = this;
            me.jsonData.listaOperatori = listaOperatori;
        },
    },
};
</script>

<style></style>
