<template>
    <div class="b-table-sticky-header" style="padding-top: 10px">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template v-slot:cell(farmaco)="{ item }">
                <router-link class="sa-edit-link" :to="'/paziente/propostaterapeutica/view/' + item.id + '?idPaziente=' + idPaziente">{{ item.farmaco }}</router-link>
            </template>
            <template v-slot:cell(descrizioneFrequenza)="{ item }"> {{ getLabelTraduora(item.descrizioneFrequenza) }} </template>
            <template v-slot:cell(posologia)="{ item }">
                <span>{{ getLabelTraduora(item.posologia) }}</span>
            </template>
            <template v-slot:cell(statoDescrizione)="{ item }">
                <span>{{ getLabelTraduora(item.statoDescrizione) }}</span>
            </template>
            <template #cell(actions)="row">
                <b-button :disabled="row.item.statoDescrizione !== 'paziente.terapia.lblATTIVA'" v-b-modal.modalSomministrazione size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onOpenModal(row.item, row.index, $event.target)">
                    <b-icon icon="pencil"></b-icon>
                </b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../../utility/UtilityMixin.js";
export default {
    mixins: [UtilityMixin],
    mounted() {
        let me = this;
        me.loadData();
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    data() {
        return {
            currentPage: 1,
            pathResource: "/pazienteterapie",
            perPage: 100,
            jsonData: [],
            fields: [
                {
                    label: this.$i18n.t("therapy.lblDrug"),
                    key: "farmaco",
                    thStyle: "width: 15rem text-center",
                    tdStyle: "text-center",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("therapy.lblQuantity"),
                    key: "quantita",
                    thStyle: "width: 5rem text-align:center",
                    tdStyle: "text-right",
                },
                {
                    label: "Orario",
                    key: "frequenza",
                    tdStyle: "text-align:center",
                    thStyle: "width: 14rem text-center",
                },
                {
                    label: this.$i18n.t("therapy.lblPosology"),
                    key: "posologia",
                    thStyle: "width: 14rem",
                },
                {
                    label: this.$i18n.t("therapy.lblStatus"),
                    key: "statoDescrizione",
                    thStyle: "width: 14rem",
                },
                {
                    label: this.$i18n.t("therapy.lblNote"),
                    key: "note",
                    thStyle: "width: 14rem text-right",
                },

                {
                    label: this.$i18n.t("therapy.lblStartDate"),
                    key: "dataInizio",
                    thStyle: "width: 14rem",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY");
                    },
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.idPaziente;
            me.isBusy = true;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.showModalLoading = false;
                    me.jsonData = response.data.data;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style></style>
