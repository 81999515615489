<template>
  <eventi-list-component :titoloEvento="titoloEvento" :gruppo="'MEDICINADELLOSPORT'" :utente="utente" :key="keyListaEventi" :pathEventResource="pathEventResource" :linkEvent="linkEvent" tipoEvento="IDONEITA"></eventi-list-component>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin.js";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import EventiListComponent from "../../../base/components/EventiListComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin, EventiMixin],
  components: {
    EventiListComponent,
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  data() {
    return {
      titoloEvento: this.$i18n.t("patient.eligibility.title"),
      pathEventResource: "/medicinadellosportidoneita",
      pathResourceMedicinaDelloSportAfa: "/medicinadellosportidoneita",
      keyListaEventi: 0,
      linkEvent: "/paziente/medicinadellosportidoneita",
      currentPage: 1,
      perPage: 30,
      rows: 0,
      showModalLoading: false,
    };
  },
};
</script>
