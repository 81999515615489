import MalattieInfettiveHivList from "./pages/MalattieInfettiveHivList.vue";
import MalattieInfettiveHivView from "./pages/MalattieInfettiveHivView.vue";
import MalattieInfettiveHivEdit from "./pages/MalattieInfettiveHivEdit.vue";
import MalattieInfettiveHivDashboard from "./pages/MalattieInfettiveHivDashboard.vue";

export default {
    MalattieInfettiveHivList,
    MalattieInfettiveHivView,
    MalattieInfettiveHivDashboard,
    MalattieInfettiveHivEdit
};