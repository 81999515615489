<template>
    <p>menu eventi</p>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style></style>
