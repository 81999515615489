<template>
    <div>
        <p>Edit Filter</p>
        <b-alert variant="danger" :show="alertShow">{{ alertMessage }}</b-alert>

        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <div style="overflow: scroll; height: 100%">
                    <div v-for="(filter, index) in queryFilters" :key="index">
                        <div class="sa-data-analysis-filter">
                            <span class="sa-data-analysis-filter-operator">{{ filter.filterOperation }}</span>
                            <span class="sa-data-analysis-filter-table">{{ filter.tableName }}</span>
                            <span class="sa-data-analysis-filter-field">{{ filter.fieldName }}</span>
                            <span class="sa-data-analysis-filter-operator">{{ filter.operatorName }}</span>
                            <span class="sa-data-analysis-filter-value">{{ filter.value }}</span>
                            <span class="sa-data-analysis-filter-button-remove" @click="onRemoveFilter(index)">
                                <i class="fas fa-times-circle"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="2">
                <label class="sa-label-data">Op.:</label>
                <b-form-select v-model="jsonData.filterOperation" :options="booleanOperatoreOptions" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="2">
                <label class="sa-label-data">Tabella:</label>
                <b-form-select v-model="jsonData.tableName" :options="tablesOptions" value-field="dbName" text-field="viewName" @change="onChangeTable()"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                <label class="sa-label-data">Campo:</label>
                <b-form-select v-model="jsonData.fieldName" :options="fieldsOptions" value-field="fieldName" text-field="fieldName"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                <label class="sa-label-data">Operatore:</label>
                <b-form-select v-model="jsonData.operatorName" :options="operatorOptions" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
                <label class="sa-label-data">Valore:</label>
                <b-form-input v-model="jsonData.value"></b-form-input>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">WHERE:</label>
                <span class="sa-data-analysis-join-edit-join">{{ where }}</span>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        queryFilters: [],
        tablesOptions: [],
    },
    data() {
        return {
            alertShow: false,
            alertMessage: "",
            where: "",
            jsonData: { filterOperation: "", tableName: null, fieldName: null, operatorName: "", value: "" },
            fieldsOptions: [],
            booleanOperatoreOptions: [
                { value: "", text: "" },
                { value: "AND", text: "AND" },
                { value: "OR", text: "OR" },
            ],
            operatorOptions: [
                { value: "", text: "" },
                { value: "=", text: "Uguale a" },
                { value: "!=", text: "Diverso da " },
                { value: "LIKE", text: "Contiene.. " },
            ],
        };
    },
    methods: {
        onChangeTable() {
            let me = this;
            this.$store.dispatch("dataanalysisTablesStore/setTableFields", { tableName: me.jsonData.tableName }, { root: true }).then(() => {
                me.setFieldsOptions();
            });
        },
        setFieldsOptions() {
            let me = this;
            me.tablesOptions.forEach((element) => {
                if (element.dbName === me.jsonData.tableName) {
                    me.fieldsOptions = element.tableFields;
                }
            });
        },
        getWhere() {
            return this.jsonData;
        },
        isValid() {
            return true;
        },
    },
};
</script>

<style></style>
