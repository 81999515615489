<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPrescriptionDate") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPrescrizione) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCodePrescription") }}</label>
                    <span class="sa-data">{{ jsonData.codicePrescrizione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
                    <span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
                    <span class="sa-data">{{ jsonData.bmi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblResult") }}</label>
                    <span class="sa-data">{{ jsonData.esito }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data"> {{ getLabelTraduora("patient.cardiology.lblRequestingDoctor") }}</label>
                    <span class="sa-data">{{ jsonData.medicorich }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data"> {{ getLabelTraduora("patient.cardiology.lblReasonExam") }}</label>
                    <span class="sa-data">{{ jsonData.motivoesame }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data"> {{ getLabelTraduora("patient.cardiology.lblTherapy") }}</label>
                    <span class="sa-data">{{ jsonData.terapia }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRegistration')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStartTime") }}</label>
                    <!-- <span class="sa-data">{{ formatTime(jsonData.iniziorec) }}</span> -->
                    <span class="sa-data">{{ stringToHours(jsonData.iniziorec) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEndTime") }}</label>
                    <span class="sa-data">{{ stringToHours(jsonData.finerec) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblTotalTime") }}</label>
                    <span class="sa-data">{{ stringToHours(jsonData.totorerec) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQuality") }}</label>
                    <span class="sa-data">{{ jsonData.qualita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ jsonData.tolleranza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMisureTotal") }}</label>
                    <span class="sa-data">{{ jsonData.totmisure }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValidMeasure") }}</label>
                    <span class="sa-data">{{ jsonData.misureval }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndicativeTest") }} </label>
                    <span class="sa-data">{{ jsonData.testindic }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <span class="sa-data">{{ jsonData.testindicomm }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPressureControl')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPressureControlDay") }}</label>
                    <span class="sa-data">{{ jsonData.ctrlpressoriodiurno }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPressureControlNight") }}</label>
                    <span class="sa-data">{{ jsonData.ctrlpressorionotturno }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPASValueDay") }}</label>
                    <span class="sa-data">{{ jsonData.pasdieperc }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPADValueDay") }}</label>
                    <span class="sa-data">{{ jsonData.paddieperc }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPASValueNight") }}</label>
                    <span class="sa-data">{{ jsonData.pasnotperc }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPADValueNight") }}</label>
                    <span class="sa-data">{{ jsonData.padnotperc }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObservation") }}</label>
                    <span class="sa-data">{{ jsonData.osservazioni }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import moment from "moment";
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            pathResource: "/cardiomonitoraggipressione",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        stringToHours(value) {
            if (value) {
                return moment(new Date(parseInt(value))).format("HH:mm");
            } else {
                return "---";
            }
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
