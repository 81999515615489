<template>
    <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
        <template slot="tab-content-event">
            <visita-controllo-ecg-view-component :idEvento="id" @update="onUpdateJsonData"></visita-controllo-ecg-view-component>
        </template>
    </eventi-view-component>
</template>
<script>
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import VisitaControlloEcgViewComponent from "./VisitaControlloEcgViewComponent.vue";

export default {
    mixins: [UtilityMixins, EventiMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { EventiViewComponent, VisitaControlloEcgViewComponent },
    data() {
        return {
            titoloEvento: this.getLabelTraduora("patient.menu.cardiologyEcg"),
            linkback: null,
            linkedit: null,
            jsonData: {},
            pathResourceFirma: "/cardiovisitecontrolliecg",
            pathResource: "/cardiovisitecontrolliecg",
            tabIndex: 0,
            id: null,
            pathRestValidation: "/validation",
            isThisFirmato: false,
            showModalLoading: false,
            linkPrintData: null,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.id = this.$route.params.id;
    },
    methods: {
        updateStatoFirma(firmato) {
            console.log(firmato);
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
        },
    },
};
</script>
