<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-body">
            <malattie-infettive-hiv-view-component @showmodalloading="onShowModalLoading" @update="onUpdateJsonData"></malattie-infettive-hiv-view-component>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import MalattieInfettiveHivViewComponent from "../components/MalattieInfettiveHivViewComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SaPageLayout, MalattieInfettiveHivViewComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            linkedit: null,
            linkback: null,
            pathResource: "/malattieinfettivehiv",
            jsonData: {},
            showModalLoading: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/malattieinfettive/hiv/edit/" + me.id;
        me.linkback = "/malattieinfettive/hiv";
    },
    methods: {
        onShowModalLoading(value) {
            let me = this;
            me.showModalLoading = value;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
