<template>
  <div>
    <b-row>
      <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
        <label>{{ this.$i18n.t("therapy.lblDrug") }}</label>
        <b-input-group class="mb-3">
          <b-form-input maxlength="16" class="text-uppercase" v-model="jsonData.farmaco" :placeholder="this.$i18n.t('therapy.lblDrug')" disabled></b-form-input>
          <b-button size="sm" variant="" @click="onOpenModalRicercaFarmaco" :disabled="isForClose">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-input-group>
      </b-col>
      <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
        <label>{{ this.$i18n.t("therapy.lblQuantity") }}</label>
        <template>
          <b-input-group class="mb-3"><b-form-spinbutton id="sb-step" v-model="jsonData.quantita" min="0" max="1000000000" step="0.50" :disabled="isForClose"></b-form-spinbutton></b-input-group>
        </template>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label>{{ this.$i18n.t("therapy.lblFrequency") }}</label>
        <div>
          <b-card class="sc-card-max-height" style="height: 100%; width: 100%" no-body>
            <div class="sa-calendar-day-grid-slot">
              <table class="sa-calendar-day-grid-table-slot">
                <tr v-for="(item1, i) in griglia" :key="i">
                  <td v-for="(item2, j) in item1" :key="j">
                    <div class="text-center">{{ item1 * i + j }} - {{ item1 * i + (j + 1) }}</div>
                    <b-form-checkbox class="text-center" :value="item1 * i + j" v-model="frequenzeSelezionate" @change="onSelectedFrequenza" :disabled="isForClose"></b-form-checkbox>
                  </td>
                </tr>
              </table>
            </div>
          </b-card>
        </div>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label>{{ this.$i18n.t("therapy.lblPosology") }}</label>
        <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.idPosologia" class="mb-2 mr-sm-2 mb-sm-0" :value="null" :options="posologiaItems" value-field="value" text-field="text" :disabled="isForClose"></b-form-select>
        <b-row>
          <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
            <label>{{ this.$i18n.t("therapy.lblStartDate") }}</label>
            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataInizio" type="datetime" :disabled="isForClose"></date-picker>
          </b-col>
          <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
            <label>{{ this.$i18n.t("therapy.lblEndDate") }}</label>
            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataFine" type="datetime"></date-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <label>{{ this.$i18n.t("therapy.lblStatus") }} :</label>
            <br />
            <span class="sa-data">{{ this.$i18n.t(jsonData.statoDescrizione) }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label>{{ this.$i18n.t("therapy.lblNote") }}</label>
        <b-form-textarea id="textarea" v-model="jsonData.note" no-resize :disabled="isForClose"></b-form-textarea>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col class="sa-padding-right text-right">
        <!-- <b-button size="sm" variant="outline-danger" @click="onAnnulla">Annulla</b-button>zzzzz -->
        <b-button size="sm" variant="default" @click="onAnnulla">Annulla</b-button>
        <b-button size="sm" variant="success" @click="onSalva">Salva</b-button>
      </b-col>
    </b-row>
    <b-modal id="mdlRicercaFarmaco" scrollable :title="this.$i18n.t('therapy.lblDrugSearch')" @show="onShow" @ok="onOk">
      <b-form @submit.prevent="onClick">
        <b-input-group class="mb-3">
          <b-form-input maxlength="16" class="text-uppercase" v-model="descrizioneFarmaco" :placeholder="this.$i18n.t('therapy.lblDrug')"></b-form-input>
          <b-button size="sm" variant="" v-b-modal.ricercaFarmaco @click="onClick">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-input-group>
        <b-input-group class="mb-3">
          <b-form-input maxlength="16" class="text-uppercase" v-model="atcGmpFarmaco" placeholder="ATC"></b-form-input>
          <b-button size="sm" variant="" v-b-modal.ricercaFarmaco @click="onClick">
            <b-icon icon="search"></b-icon>
          </b-button>
        </b-input-group>
      </b-form>
      <b-table id="tabellaFarmaci" :items="tabellaFarmaci" :fields="farmaciFields" selectable select-mode="single" @row-selected="onRowSelected"></b-table>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  data() {
    return {
      pathResource: "/pazienteterapie",
      pathRestFarmaci: "/farmaci",
      descrizioneFarmaco: "",
      atcGmpFarmaco: "",
      griglia: [6, 6, 6, 6],
      jsonData: { quantita: 0.5, farmaco: "", atcGmp: "", codiceProdotto: "", iterazioneFarmaco: "", dataInizio: new Date().getTime(), dataFine: new Date().getTime(), idStato: null, idPosologia: null },
      frequenzeSelezionate: [],
      posologiaItems: [],
      frequenzaItems: [],
      statoOption: [],
      tabellaFarmaci: [],
      farmaciFields: [
        {
          label: this.$i18n.t("agendas.lblDescription"),
          key: "descrizione",
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: "ATC",
          key: "atcGmp",
          sortable: true,
          thStyle: "width: 7rem",
        },
      ],
    };
  },
  props: {
    idPaziente: {
      type: String,
      default: function () {
        return null;
      },
    },
    isForClose: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    terapia: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mounted: function () {
    let me = this;
    me.jsonData.idPaziente = me.idPaziente;
    if (me.terapia !== null) {
      me.jsonData = me.terapia;
    }
    me.loadPosologiaData();
    me.loadFrequenzaData();
    me.loadStatiSomministrazione();
  },
  methods: {
    onSelectedFrequenza(value) {
      let me = this;
      me.frequenzeSelezionate = value;
      me.jsonData.orarioSomministrazione = me.frequenzeSelezionate;
    },
    onOpenModalRicercaFarmaco() {
      let me = this;
      me.$bvModal.show("mdlRicercaFarmaco");
    },
    onShow() {
      let me = this;
      me.tabellaFarmaci = [];
    },
    onOk() {
      let me = this;
      me.showCodici = false;
      me.showModalLoading = true;
      me.jsonData.farmaco = me.rigaSelezionata.descrizione;
      me.jsonData.atcGmp = me.rigaSelezionata.atcGmp;
      me.jsonData.codiceProdotto = me.rigaSelezionata.codiceProdotto;
      me.componentKey += 1;
      me.showCodici = true;
    },
    onRowSelected(items) {
      if (items.length > 0) {
        this.rigaSelezionata = items[0];
      }
    },
    onClick() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRestFarmaci + "/";
      if (me.descrizioneFarmaco !== "") {
        axios
          .get(link, { params: { descrizione: me.descrizioneFarmaco, atcGmp: me.atcGmpFarmaco } })
          .then((response) => {
            me.tabellaFarmaci = response.data.data;
            me.showModalLoading = false;
          })
          .catch((error) => {
            me.showModalLoading = false;
            me.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        axios
          .get(link, { params: { atcGmp: me.atcGmpFarmaco } })
          .then((response) => {
            me.tabellaFarmaci = response.data.data;
            me.showModalLoading = false;
          })
          .catch((error) => {
            me.showModalLoading = false;
            me.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((error) => {
                console.log(error);
              });
          });
      }
    },
    loadPosologiaData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazienteterapieposologia";
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.posologiaItems = [];
          response.data.data.forEach((element) => {
            let option = { text: element.posologia, value: element.id };
            // let option = { text: this.getLabelTraduora(element.posologia), value: element.id };
            me.posologiaItems.push(option);
          });
          me.posologiaItems.unshift({ value: null, text: this.$i18n.t("therapy.lblSelectPosology") });
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadFrequenzaData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazienteterapiefrequenza";
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.frequenzaItems = [];
          response.data.data.forEach((element) => {
            let option = { text: this.$i18n.t(element.descrizione), value: element.id };
            me.frequenzaItems.push(option);
          });
          if (me.id !== "-1") {
            me.loadData();
          }
        })
        .catch(() => {});
    },
    loadStatiSomministrazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazienteterapiestatidescrizione";
      me.showModalLoading = true;
      axios(link)
        .then((response) => {
          let obj = {};
          me.showModalLoading = false;
          response.data.data.forEach((element) => {
            obj = { text: this.$i18n.t(element.descrizione), value: element.id };
            me.statoOption.push(obj);
          });
        })
        .catch((e) => {
          me.showModalLoading = false;
          console.log(e);
        });
    },
    onAnnulla() {
      this.$emit("update", null);
    },
    onSalva() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      if (me.jsonData.id === "-1" || me.jsonData.id === null || me.jsonData.id === undefined) {
        axios
          .post(link, JSON.stringify(me.jsonData))
          .then((response) => {
            me.id = response.data.data.id;
            me.showModalLoading = false;
            this.$emit("update", response.data);
          })
          .catch((error) => {
            me.showModalLoading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        link = link + "/" + me.jsonData.id;
        axios
          .put(link, JSON.stringify(me.jsonData))
          .then((response) => {
            me.showModalLoading = false;
            //me.linkback = me.linkback + "/view/" + response.data.data.id;
            this.$emit("update", response.data);
          })
          .catch((error) => {
            console.log(error);
            me.showModalLoading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                console.log(value);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
  },
};
</script>

<style></style>
