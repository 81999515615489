<template>
    <sa-page-layout :btnNewVisible="true" :linkedit="linkedit" :pathResource="pathResource" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <proposta-terapeutica-list-component ref="propostaTerapeuticaListComponent"></proposta-terapeutica-list-component>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import PropostaTerapeuticaListComponent from "../components/PropostaTerapeuticaListComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SaPageLayout, PazienteAnagrafica, PropostaTerapeuticaListComponent },
    data() {
        return {
            rows: 1,
            pathResource: "",
            perPage: 10,
            currentPage: 1,
            showModalLoading: false,
            linkedit: "/paziente/propostaterapeutica",
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    mounted() {},
    methods: {
        onRefresh() {
            let me = this;
            me.$refs["propostaTerapeuticaListComponent"].onRefresh();
        },
    },
};
</script>
