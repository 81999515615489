<template>
  <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :utente="utente" :btnFirmaVisible="btnFirmaVisible" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData" :isPrestazioni="true" :isImmagini="true" :isAllegati="false" @updateFirma="onFirmaUpdate">
    <template slot="tab-content-event">
      <egds-view-component @update="onUpdateJsonData" :idEvento="id" :key="egdsViewKey"></egds-view-component>
    </template>
  </eventi-view-component>
</template>

<script>
import EgdsViewComponent from "./EgdsViewComponent.vue";
// import axios from "axios";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
  components: { EventiViewComponent, EgdsViewComponent },
  mixins: [UtilityMixin, EventiMixin],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      titoloEvento: this.getLabelTraduora("patients.gastroenterology.egds"),
      linkback: null,
      linkedit: null,
      jsonData: {},
      pathResourceFirma: null,
      pathResource: "/gastroegds",
      btnFirmaVisible: true,
      tabIndex: 0,
      id: null,
      pathRestValidation: "/validation",
      isThisFirmato: false,
      showModalLoading: false,
      linkPrintData: null,
      egdsViewKey: 0,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.id = this.$route.params.id;
    me.egdsViewKey++;
  },
  methods: {
    updateStatoFirma(firmato) {
      console.log(firmato);
    },
    onFirmaUpdate() {
      let me = this;
      me.btnFirmaVisible = false;
      me.egdsViewKey++;
      // this.$emit("updateFirma");
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
      if (me.jsonData.firma) {
        me.btnFirmaVisible = false;
      } else {
        me.btnFirmaVisible = true;
      }
      // console.log(me.jsonData);
      // me.pathResourceFirma = me.pathResourceFirma + "/" + me.id;
      me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
    },
  },
};
</script>

<style></style>
