<template>
  <sa-page-layout :btnRefreshVisible="true" :btnNewVisible="true" :linkedit="linkedit" :showModalLoading="showModalLoading" :pathResource="pathResource" @refresh="onRefresh" :items="items">
    <!-- <sa-page-layout :btnRefreshVisible="true" :btnNewVisible="true" :linkedit="linkedit" :showModalLoading="showModalLoading" :pathResource="pathResource" @refresh="onRefresh" :btnDownalodExcelVisible="true" :nomeFileExcel="nomeFileExcel" :items="items" :pathResourceDownloadExcel="pathResourceDownloadExcel"> -->
    <template slot="toolbar">
      <b-button variant="btn-toolbar btn btn-outline-primary" size="sm" @click="onClickExportExcel">
        <i class="bi bi-download sa-icon-i"></i>
        Export Excel
      </b-button>
    </template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Numero Codice Identificativo</label>
            <b-form-input maxlength="16" v-model="filtro.numeroCodiceIdentificativo" type="search" id="codice"></b-form-input>
          </b-col>
          <b-col cols="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Data Compilazione</label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataCompilazione" type="date"></date-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <!-- <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill>
                <b-tab active>
                    <template #title>
                        <span class="sa-data">Lista HIV</span>
                    </template> -->

      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filtro" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template v-slot:cell(numeroCodiceIdentificativo)="{ item }">
            <router-link class="sa-edit-link" :to="'/malattieinfettive/hiv/view/' + item.id">{{ item.numeroCodiceIdentificativo }}</router-link>
          </template>
        </b-table>
      </div>
      <!-- </b-tab> -->
      <!-- <b-tab>
                    <template #title>
                        <span class="sa-data">SCHEDA RIEPILOGATIVA DA INVIARE CON CADENZA TRIMESTRALE</span>
                    </template>
                    <div>
                        <malattie-infettive-hiv-scheda-riepilogativa-trimestrale-component></malattie-infettive-hiv-scheda-riepilogativa-trimestrale-component>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <span class="sa-data">SCHEDA RIEPILOGATIVA DA INVIARE ALL’ASSESSORATO ALLA SANITÀ</span>
                    </template>
                    <div>
                        <malattie-infettive-hiv-scheda-riepilogativa-annuale-component></malattie-infettive-hiv-scheda-riepilogativa-annuale-component>
                    </div>
                </b-tab> -->
      <!-- </b-tabs> -->
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import MalattieInfettiveHivSchedaRiepilogativaTrimestraleComponent from "../components/MalattieInfettiveHivSchedaRiepilogativaTrimestraleComponent.vue";
// import MalattieInfettiveHivSchedaRiepilogativaAnnualeComponent from "../components/MalattieInfettiveHivSchedaRiepilogativaAnnualeComponent.vue";
export default {
  components: { SaPageLayout, DatePicker }, //MalattieInfettiveHivSchedaRiepilogativaTrimestraleComponent, MalattieInfettiveHivSchedaRiepilogativaAnnualeComponent
  data() {
    return {
      pathResource: "/malattieinfettivehiv",
      pathResourceDownload: "/malattieinfettivehiv/download",
      linkedit: "/malattieinfettive/hiv",
      nomeFileExcel: "Riepilogo HIV",
      perPage: 50,
      currentPage: 1,
      rows: 0,
      showModalLoading: false,
      filtro: { numeroCodiceIdentificativo: "", dataCompilazione: null },
      items: [],
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "N. Codice Identificativo",
          key: "numeroCodiceIdentificativo",
          sortable: true,
        },
        {
          label: "Data Compilazione",
          key: "dataCompilazione",
          formatter: (value) => {
            return value ? moment(new Date(value)).format("DD/MM/YYYY") : "";
          },
          thStyle: "width: 12rem",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
    me.loadData();
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = { numeroCodiceIdentificativo: "", dataCompilazione: null };
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onClickExportExcel() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDownload;
      let fileName = me.nomeFileExcel + moment(new Date()).format("DD MM YYYY HH-mm") + ".csv";
      axios({
        url: link, // File URL Goes Here
        params: me.filtro,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        // console.log(response.data);
        var blob = new Blob([response.data]);
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); //create the download url
        downloadElement.href = href;
        downloadElement.download = fileName; //the name of the downloaded file
        document.body.appendChild(downloadElement);
        downloadElement.click(); //click to file
        document.body.removeChild(downloadElement); //remove the element
        window.URL.revokeObjectURL(href); //release the object  of the blob
      });
    },
  },
};
</script>
