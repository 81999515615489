<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" @update="onUpdateJsonData" :titoloEvento="titoloEvento">
        <template slot="tab-content-event">
            <terapie-complicanze-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></terapie-complicanze-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import TerapieComplicanzeEditComponent from "./TerapieComplicanzeEditComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { TerapieComplicanzeEditComponent, EventiEditComponent },
    data() {
        return {
            linkback: null,
            titoloEvento: "Terapie Complicanze",
            pathResource: "/cardioterapiecomplicanze",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            jsonData: { dataEvento: null },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
