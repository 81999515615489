<template>
  <sa-page-layout :btnBackVisible="true" :btnEditVisible="btnEditVisible" :linkback="linkback" :linkedit="linkedit" class="sa-no-space" :data="jsonData">
    <template slot="table-body">
      <malattie-infettive-view-component :jsonData="jsonData"></malattie-infettive-view-component>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import MalattieInfettiveViewComponent from "../components/MalattieInfettiveViewComponent.vue";
export default {
  props: {
    utente: { type: Object },
  },
  components: { SaPageLayout, MalattieInfettiveViewComponent },
  mixins: [UtilityMixin],
  data() {
    return {
      isDomicilioUgualeResidenza: false,
      linkedit: null,
      btnEditVisible: false,
      pathResource: "/malattieinfettivesegnalazioni",
      linkback: "/malattieinfettive/segnalazioni",
      showModalLoading: false,
      jsonData: {
        provinciaInizioSintomi: "",
        professione: "",
        dataInizioSintomi: null,
        comuneSintomiCodiceIstat: null,
        vaccino: null,
        numeroDosi: 0,
        dataUltimaDose: null,
        tipoVaccino: "",
        nomeMedicoSegnalante: "",
        cognomeMedicoSegnalante: "",
        contattoMedicoSegnalante: "",
        dataSegnalazione: null,
        ricoveroLuogoCura: null,
        comuneResidenzaCodiceIstat: null,
        indirizzoResidenza: "",
        comuneDomicilioCodiceIstat: null,
        indirizzoDomicilio: "",
        statoAttuale: null,
        statoAttualeDataOra: null,
        uosdDiagnosi: null,
        sesso: null,
        pazienteBean: {
          identificativo: "",
          cognome: "",
          nome: "",
          dataNascita: null,
          comuneNascitaCodiceIstat: null,
          comuneResidenzaCodiceIstat: null,
          comuneDomicilioCodiceIstat: null,
          indirizzoResidenza: "",
          indirizzoDomicilio: "",
          telefono: "",
          sesso: null,
          provinciaNascita: null,
          provinciaResidenza: null,
          provinciaDomicilio: null,
        },
        malattieInfettiveSegnalazioneStatoMalattiaBeans: [],
      },
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/malattieinfettive/edit/" + me.id;
    me.loadData();
    me.impostaPermessi();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      //   console.log(me.utente);

      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    impostaPermessi() {
      let me = this;
      me.btnEditVisible = UtilityMixin.methods.verificaPermessi(me.utente, "malattieinfettive", 4);
    },
  },
};
</script>
