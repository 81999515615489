<template>
    <div>
        <template>
            <table class="body-wrap">
                <tr>
                    <td class="container">
                        <div class="content">
                            <table class="main" width="100%" cellpadding="0" cellspacing="0">
                                <tr>
                                    <td class="content-wrap aligncenter">
                                        <table width="100%" cellpadding="0" cellspacing="0">
                                            <h3>{{ this.$i18n.t("therapy.lblGeneralWarnings") }}</h3>
                                            <tr v-if="avvertenzeCheck === 0">
                                                <div style="height: 20px"></div>
                                                <h4>{{ this.$i18n.t("therapy.lblNoWarnings") }}</h4>
                                                <div style="height: 20px"></div>
                                                <hr />
                                            </tr>
                                            <tr v-if="avvertenzeCheck !== 0">
                                                <td class="content-block">
                                                    <table class="invoice" :key="componentKey">
                                                        <tr>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in avvertenzeFarmaco" :key="item.product.id + '-avv-farma' + Math.random()">
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <td colspan="2" style="border: 0px; padding-bottom: 5px; padding-top: 0px">
                                                                        <table width="100%" cellpadding="0" cellspacing="0">
                                                                            <tr>
                                                                                <td :style="'width: 20px; background-color:' + item.color.htmlCode">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                                                <td style="padding: 10px; border-bottom: 0">
                                                                                    {{ item.product.name }} :
                                                                                    {{ item.description }}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <hr />
                                            <h3>{{ this.$i18n.t("therapy.lblInteractions") }}</h3>
                                            <tr v-if="interazioniCheck === 0">
                                                <div style="height: 20px"></div>
                                                <h4>{{ this.$i18n.t("therapy.lblNoInteractions") }}</h4>
                                                <div style="height: 20px"></div>
                                                <hr />
                                            </tr>
                                            <tr v-if="interazioniCheck !== 0">
                                                <td class="content-block">
                                                    <p>
                                                        {{ descrizione }}
                                                    </p>
                                                    <table class="invoice">
                                                        <tr v-if="farmaciCheck !== 0">
                                                            <td>
                                                                <h4>{{ this.$i18n.t("therapy.lblDrugToDrugInteraction") }}</h4>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="farmaciCheck !== 0">
                                                            <td>
                                                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoFarmaco" :key="item.product.id + '-farma-farma' + Math.random()">
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <td colspan="2" style="border: 0px; padding-bottom: 5px; padding-top: 0px">
                                                                        <table width="100%" cellpadding="0" cellspacing="0">
                                                                            <tr>
                                                                                <td :style="'width: 20px; background-color:' + item.color.htmlCode">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                                                <td style="padding: 10px; border-bottom: 0">
                                                                                    {{ item.affectingProduct.name }}
                                                                                    + {{ item.product.name }} :
                                                                                    {{ item.description }}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table class="invoice">
                                                        <tr v-if="dietaCheck !== 0">
                                                            <td>
                                                                <h4>{{ this.$i18n.t("therapy.lblDrugToDietInteraction") }}</h4>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="dietaCheck !== 0">
                                                            <td>
                                                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoDieta" :key="item.product.id + '-farma-dieta' + Math.random()">
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <td colspan="2" style="border: 0px; padding-bottom: 5px; padding-top: 0px">
                                                                        <table width="100%" cellpadding="0" cellspacing="0">
                                                                            <tr>
                                                                                <td :style="'width: 20px; background-color:' + item.color.htmlCode">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                                                <td style="padding: 10px; border-bottom: 0">
                                                                                    {{ item.affectingProduct.name }} + {{ item.product.titckName }} :
                                                                                    {{ item.description }}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table class="invoice">
                                                        <tr v-if="icdCheck !== 0">
                                                            <td>
                                                                <h4>{{ this.$i18n.t("therapy.lblDrugToICDInteraction") }}</h4>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="icdCheck !== 0">
                                                            <td>
                                                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoICD" :key="item.product.id + '-farma-icd' + Math.random()">
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <td colspan="2" style="border: 0px; padding-bottom: 5px; padding-top: 0px">
                                                                        <table width="100%" cellpadding="0" cellspacing="0">
                                                                            <tr>
                                                                                <td :style="'width: 20px; background-color:' + item.color.htmlCode">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                                                <td style="padding: 10px; border-bottom: 0">
                                                                                    {{ item.affectingProduct.name }} + {{ item.product.titckName }} :
                                                                                    {{ item.description }}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table class="invoice">
                                                        <tr v-if="pazienteCheck !== 0">
                                                            <td>
                                                                <h4>{{ this.$i18n.t("therapy.lblDrugToPatientInteraction") }}</h4>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="pazienteCheck !== 0">
                                                            <td>
                                                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoPaziente" :key="item.product.id + '-farma-paz' + Math.random()">
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <td colspan="2" style="border: 0px; padding-bottom: 5px; padding-top: 0px">
                                                                        <table width="100%" cellpadding="0" cellspacing="0">
                                                                            <tr>
                                                                                <td :style="'width: 20px; background-color:' + item.color.htmlCode">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                                                <td style="padding: 10px; border-bottom: 0">
                                                                                    {{ item.affectingProduct.name }} + {{ item.product.titckName }} :
                                                                                    {{ item.description }}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table class="invoice">
                                                        <tr v-if="sintomiCheck !== 0">
                                                            <td>
                                                                <h4>{{ this.$i18n.t("therapy.lblDrugToSymptomsInteraction") }}</h4>
                                                            </td>
                                                        </tr>
                                                        <tr v-if="sintomiCheck !== 0">
                                                            <td>
                                                                <table class="invoice-items" cellpadding="0" cellspacing="0" v-for="item in farmacoSintomi" :key="item.product.id + '-farma-sintomi' + Math.random()">
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <td colspan="2" style="border: 0px; padding-bottom: 5px; padding-top: 0px">
                                                                        <table width="100%" cellpadding="0" cellspacing="0">
                                                                            <tr>
                                                                                <td :style="'width: 20px; background-color:' + item.color.htmlCode">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                                                <td style="padding: 10px; border-bottom: 0">
                                                                                    {{ item.affectingProduct.name }} + {{ item.product.titckName }} :
                                                                                    {{ item.description }}
                                                                                </td>
                                                                            </tr>
                                                                        </table>
                                                                    </td>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            <hr />
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </td>
                </tr>
            </table>
        </template>
    </div>
</template>

<script>
export default {
    props: ["interazioneFarmaci"],
    data() {
        return {
            risposta: null,
            componentKey: 0,
            data: [],
            interazioniColore: {},
            avvertenzeFarmaco: [],
            farmacoFarmaco: [],
            farmacoNutrizione: [],
            farmacoICD: [],
            farmacoPaziente: [],
            farmacoSintomi: [],
            interazioniCheck: 0,
            avvertenzeCheck: 0,
            farmaciCheck: 0,
            dietaCheck: 0,
            icdCheck: 0,
            pazienteCheck: 0,
            sintomiCheck: 0,
        };
    },
    computed: {},
    methods: {
        loadData() {
            let me = this;
            me.checkInterazioni();
        },
        checkInterazioni() {
            let me = this;
            me.descrizione = me.interazioneFarmaci.interactionColor.interactionDescription;
            me.avvertenzeFarmaco = me.interazioneFarmaci.productWarning;
            me.farmacoFarmaco = me.interazioneFarmaci.productInteraction;
            me.farmacoDieta = me.interazioneFarmaci.productNutritionInteraction;
            me.farmacoICD = me.interazioneFarmaci.productICDInteraction;
            me.farmacoPaziente = me.interazioneFarmaci.productPatientCharacteristicInteraction;
            me.farmacoSintomi = me.interazioneFarmaci.productSymptomInteraction;
            me.avvertenzeCheck = me.avvertenzeFarmaco.length;
            me.farmaciCheck = me.farmacoFarmaco.length;
            me.dietaCheck = me.farmacoDieta.length;
            me.icdCheck = me.farmacoICD.length;
            me.pazienteCheck = me.farmacoPaziente.length;
            me.sintomiCheck = me.farmacoSintomi.length;
            me.interazioniCheck = me.farmaciCheck + me.dietaCheck + me.icdCheck + me.pazienteCheck + me.sintomiCheck + me.avvertenzeCheck;
            me.componentKey += 1;
        },
        returnProdCode() {
            return this.$i18n.t("therapy.lblProductCode");
        },
    },
    mounted: function () {
        let me = this;
        me.loadData();
    },
};
</script>

<style scoped>
.invoice {
    border-color: #c2c2c200;
}
.box-medicina {
    min-height: 50px !important;
    width: 300px !important;
    border-width: 2px !important;
    border-color: rgb(52, 153, 248) !important;
    border-style: solid groove !important;
    border-radius: 16px !important;
    display: flex;
    flex-direction: column;
}
.centrale {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    color: rgb(52, 153, 248);
}
</style>
