<template>
    <div class="sa-form-section">
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.events.lblEventDate')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                            <span class="sa-data"> {{ formatDateTime(jsonData.dataEvento) }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <test-provocativi-view-component :jsonData="jsonData" @update="onUpdateJsonData"></test-provocativi-view-component>
            </b-col>
        </b-row>
        <!-- 
        <h5>{{ getLabelTraduora("patient.cardiology.lblAngiocardiopneumoscintrigraphy") }}</h5>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasalEF") }}</label>
                <span class="sa-data">{{ jsonData.efBasale }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterStressEF") }}</label>
                <span class="sa-data">{{ jsonData.efDopostress }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
                <span class="sa-data">{{ getLabelTraduora(jsonData.conclusione1) }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                <span class="sa-data"> {{ jsonData.noteAngiocardiopnemoscintigrafia }}</span>
            </b-col>
        </b-row>
         -->
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblMiocardicSpet')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNormoperfusion") }}</label>
                            <span :class="setColor(jsonData.normoperfusione) + ' sa-text-data'">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.normoperfusione)"> </b-icon>
                            </span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeficRev") }}</label>
                            <span class="sa-data"> {{ getLabelTraduora(jsonData.deficRev1) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeficRev") }}</label>
                            <span class="sa-data"> {{ getLabelTraduora(jsonData.deficRev2) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTessutoIpoper") }}</label>
                            <span class="sa-data"> {{ getLabelTraduora(jsonData.tessutoIpoper1) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTessutoIpoper") }}</label>
                            <span class="sa-data"> {{ getLabelTraduora(jsonData.tessutoIpoper2) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeficIrr") }}</label>
                            <span class="sa-data">{{ getLabelTraduora(jsonData.deficIrr1) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeficIrr") }}</label>
                            <span class="sa-data"> {{ getLabelTraduora(jsonData.deficIrr2) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
                            <span class="sa-data">{{ getLabelTraduora(jsonData.conclusione2) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                            <span class="sa-data">{{ jsonData.noteSpetMiocardica }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <!-- <b-row style="align-items: baseline" v-if="this.id != '-1'">
            <label class="sa-label-data mr-2"> {{ this.$i18n.t("repeatablePrescriptions.lblStatus") }} : </label>
            <span class="sa-data" v-if="jsonData.firma"> {{ jsonData.firma }} </span>
            <span v-else>
                <span class="sa-data">{{ this.$i18n.t("patient.anamnesis.lblFirma") }}</span></span
            >
        </b-row> -->
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import TestProvocativiViewComponent from "../../components/TestProvocativiViewComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: { TestProvocativiViewComponent },
    data() {
        return {
            pathResource: "/cardiospectmiocardica",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
