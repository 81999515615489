<template>
    <div class="sa-button-menu">
        <b-row class="sa-button-menu-container">
            <b-col v-for="buttonMenu in buttonsMenu" :key="buttonMenu.key" xs="12" sm="12" md="3" lg="3" xl="3">
                <div v-if="buttonMenu.click != null" class="sa-button-menu-button" @click="buttonMenu.click">
                    <div class="sa-button-menu-button-icon">
                        <i :class="buttonMenu.icon"></i>
                    </div>
                    <div class="sa-button-menu-button-text">
                        <span>{{ buttonMenu.text }}</span>
                    </div>
                </div>
                <div v-else class="sa-button-menu-button">
                    <div class="sa-button-menu-button-icon"><i :class="buttonMenu.icon"></i></div>
                    <div class="sa-button-menu-button-text">
                        <span>{{ buttonMenu.text }}</span>
                    </div>
                </div>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        buttonsMenu: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    methods: {
        onClick() {},
    },
};
</script>

<style>
.sa-button-menu-button {
    height: 12rem;
    border: var(--bg-main-nav-bar) solid 5px;
    border-radius: 18px;
    text-align: center;
    margin: 5px;
    height: 14rem;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 14rem;
}
.sa-button-menu-container {
    max-width: 1024px;
    margin-left: auto !important;
    margin-right: auto !important;
}
.sa-button-menu-button-text {
    height: 50%;
    padding-top: 55px;
}
.sa-button-menu-button:hover {
    background-color: rgb(218, 218, 218);
    cursor: pointer;
}
.sa-button-menu-button-text span {
    text-transform: uppercase;
    color: var(--cl-sidebar-item);
    font-weight: 700;
}
.sa-button-menu-button-icon {
    height: 50%;
    padding-top: 25px;
}
.sa-button-menu-button-icon i {
    font-size: 5rem;
}
</style>
