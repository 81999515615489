<template>
  <div>
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label
          ><span class="sa-data">
            {{ formatDateTime(jsonData.dataEvento) }}
          </span></b-col
        >
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRoutineExams')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGlicemy") }} <eventi-storico-misure-component misura="glicemia" misuraLabel="Glicemia" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component></label>
          <span class="sa-data">
            {{ jsonData.glicemia }}
          </span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCreatininemia") }}</label
          ><span class="sa-data">
            {{ jsonData.creatininemia }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAzotemia") }}</label
          ><span class="sa-data">
            {{ jsonData.azotemia }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUricemia") }}</label
          ><span class="sa-data">
            {{ jsonData.uricemia }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSodiemia") }}</label
          ><span class="sa-data">
            {{ jsonData.sodiemia }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPotassiemia") }}</label
          ><span class="sa-data">
            {{ jsonData.potassiemia }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCalcemia") }}</label
          ><span class="sa-data">
            {{ jsonData.calcemia }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTrigliceridemia") }}</label
          ><span class="sa-data">
            {{ jsonData.trigliceridi }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColesterolemia") }}</label
          ><span class="sa-data">
            {{ jsonData.colesterolo }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHDL") }}</label
          ><span class="sa-data">
            {{ jsonData.hdl }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLDL") }}</label
          ><span class="sa-data">
            {{ jsonData.ldl }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPCR") }}</label
          ><span class="sa-data">
            {{ jsonData.pcr }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCTnl") }}</label
          ><span class="sa-data">
            {{ jsonData.ctnl }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCK-Mb") }}</label
          ><span class="sa-data">
            {{ jsonData.ckMb }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHbA1CPercentage") }}</label
          ><span class="sa-data">
            {{ jsonData.hba1cPerc }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHbA1Cmmol") }}</label
          ><span class="sa-data">
            {{ jsonData.hba1cMmol }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPOA") }}</label
          ><span class="sa-data">
            {{ jsonData.apoa }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPOB") }}</label
          ><span class="sa-data">
            {{ jsonData.apob }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLPa") }}</label
          ><span class="sa-data">
            {{ jsonData.lpa }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPLA2") }}</label
          ><span class="sa-data">
            {{ jsonData.pla2 }}
          </span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">NT-proBNP (pg/ml)</label>
          <span class="sa-data">{{ jsonData.ntProBnp }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Vitamina D-25 OH (mg/ml)</label>
          <span class="sa-data">{{ jsonData.vitaminaD25Oh }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Cloremia</label>
          <span class="sa-data">{{ jsonData.cloremia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Magnesemia</label>
          <span class="sa-data">{{ jsonData.magnesemia }}</span>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Quadro Prot. Elettrof.</label>
                    <span class="sa-data">{{ jsonData.quadroProtElettroforetico }}</span>
                </b-col> -->
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Amilasi</label>
          <span class="sa-data">{{ jsonData.amilasi }}</span>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Microalbuminuria</label>
                    <span class="sa-data">{{ jsonData.microalbuminuria }}</span>
                </b-col> -->
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Sideremia</label>
          <span class="sa-data">{{ jsonData.sideremia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ferritinemia</label>
          <span class="sa-data">{{ jsonData.ferritinemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Transferrinemia</label>
          <span class="sa-data">{{ jsonData.transferrinemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Troponinemia T</label>
          <span class="sa-data">{{ jsonData.troponinemiaT }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Troponinemia I</label>
          <span class="sa-data">{{ jsonData.troponinemiaI }}</span>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">TSH</label>
                    <span class="sa-data">{{ jsonData.tsh }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">FT3</label>
                    <span class="sa-data">{{ jsonData.ft3 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">FT4</label>
                    <span class="sa-data">{{ jsonData.ft4 }}</span>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Anticorpi Anti-TG</label>
                    <span class="sa-data">{{ jsonData.anticorpiAntiTg }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Anticorpi Anti-TPO</label>
                    <span class="sa-data">{{ jsonData.anticorpiAntiTpo }}</span>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPraAndBasalAldosteronemia1hAfterCaptopril") }}</label>
                    <span class="sa-data">{{ jsonData.dosaggioDopoCaptopril }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPraAndBasalAldosteronemiaOnClinic") }}</label>
                    <span class="sa-data">{{ jsonData.dosaggioClinoOrto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Cortisolemia 8-20</label>
                    <span class="sa-data">{{ jsonData.cortisolemia820 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinaryCatecholaminesMetanephrinesDosage24hSample") }}</label>
                    <span class="sa-data">{{ jsonData.dosaggioCatecolamine }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">ACTH</label>
                    <span class="sa-data">{{ jsonData.acth }}</span>
                </b-col> -->
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblUrineExams')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGlucose") }}</label>
          <span class="sa-data">
            {{ getLabelTraduora(jsonData.urineGlucosio) }}
          </span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBlood") }}</label>
          <span class="sa-data">
            {{ getLabelTraduora(jsonData.urineSangue) }}
          </span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteins") }}</label>
          <span class="sa-data">
            {{ getLabelTraduora(jsonData.urineProteine) }}
          </span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSediment") }}</label
          ><span class="sa-data">
            {{ jsonData.sedimento }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrineVolume24h") }}</label
          ><span class="sa-data">
            {{ jsonData.volUrine24 }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinocolture") }}</label
          ><span class="sa-data">
            {{ jsonData.urinocoltura }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMicroalbuminuria") }}</label>
          <span class="sa-data">
            {{ getLabelTraduora(jsonData.malbuminuria) }}
          </span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMicroalbuminuriaQuantity") }}</label
          ><span class="sa-data">
            {{ jsonData.albuminuria }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCreatininaClearancePerMinute") }}</label
          ><span class="sa-data">
            {{ jsonData.clearance }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCalciuria") }}</label
          ><span class="sa-data">
            {{ jsonData.calciuria }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteinuria") }}</label
          ><span class="sa-data">
            {{ jsonData.proteinuria24 }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFosfaturia") }}</label
          ><span class="sa-data">
            {{ jsonData.fosfaturia }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDigossinemia") }}</label
          ><span class="sa-data">
            {{ jsonData.digossinemia }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOmocisteinemia") }}</label
          ><span class="sa-data">
            {{ jsonData.omocisteinemia }}
          </span></b-col
        >
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Dosaggio degli Elettroliti Urinari (Na+ e K+) su campione delle 24h</label>
                    <span class="sa-data">{{ jsonData.dosaggioElettrolitiUrinari }}</span>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2"
            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSideremia") }}</span
            ><span class="sa-data">
            {{ jsonData.sideremia }}
          </span></b-col> 
          <b-col xs="12" sm="12" md="2" lg="2" xl="2"
            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFerritina") }}</span
            ><span class="sa-data">
            {{ jsonData.ferritina }}
          </span></b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2"
            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTransferrina") }}</span
            ><span class="sa-data">
            {{ jsonData.transferrina }}
          </span></b-col>
          <b-col xs="12" sm="12" md="2" lg="2" xl="2"
            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNT-proBNP") }}</span
            ><span class="sa-data">
            {{ jsonData.ntProBnp }}
          </span></b-col>-->
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Q. P. E." header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteineTotali") }}</label
          ><span class="sa-data">
            {{ jsonData.proteineTotali }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlbumine") }}</label
          ><span class="sa-data">
            {{ jsonData.albumine }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlfa1Globuline") }}</label
          ><span class="sa-data">
            {{ jsonData.alfa1Globuline }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlfa2Globuline") }}</label
          ><span class="sa-data">
            {{ jsonData.alfa2Globuline }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBetaGlobuline") }}</label
          ><span class="sa-data">
            {{ jsonData.betaGlobuline }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGammaGlobuline") }}</label
          ><span class="sa-data">
            {{ jsonData.gammaGlobuline }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGOT") }}</label
          ><span class="sa-data">
            {{ jsonData.got }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGPT") }}</label
          ><span class="sa-data">
            {{ jsonData.gpt }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCPK") }}</label
          ><span class="sa-data">
            {{ jsonData.cpk }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLDH") }}</label
          ><span class="sa-data">
            {{ jsonData.ldh }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblALP") }}</label
          ><span class="sa-data">
            {{ jsonData.alp }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Y-GT</label>
          <span class="sa-data">{{ jsonData.yGt }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Emocromo" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRedBloodCells") }}</label
          ><span class="sa-data">
            {{ jsonData.globuliRossi }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWhiteBloodCells") }}</label
          ><span class="sa-data">
            {{ jsonData.globuliBianchi }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPlatelets") }}</label
          ><span class="sa-data">
            {{ jsonData.piastrine }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEmatocrito") }}</label
          ><span class="sa-data">
            {{ jsonData.ematocrito }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFibrinogeno") }}</label
          ><span class="sa-data">
            {{ jsonData.fibrinogeno }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHB") }}</label
          ><span class="sa-data">
            {{ jsonData.emoglobina }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPT") }}</label
          ><span class="sa-data">
            {{ jsonData.pt }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblINR") }}</label
          ><span class="sa-data">
            {{ jsonData.inr }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPTT") }}</label
          ><span class="sa-data">
            {{ jsonData.aptt }}
          </span></b-col
        >
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHepatitisMarkerA") }}</label>
          <span class="sa-data">
            {{ getLabelTraduora(jsonData.markerEpatiteA) }}
          </span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHepatitisMarkerB") }}</label>
          <span class="sa-data">
            {{ getLabelTraduora(jsonData.markerEpatiteB) }}
          </span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHepatitisMarkerC") }}</label>
          <span class="sa-data">
            {{ getLabelTraduora(jsonData.markerEpatiteC) }}
          </span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label
          ><span class="sa-data">
            {{ jsonData.altro }}
          </span></b-col
        >
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
  mixins: [UtilityMixins],
  props: {
    idEvento: { Type: String, require: true },
  },
  components: { EventiStoricoMisureComponent },
  data() {
    return {
      pathResource: "/cardioesamilaboratorio",
      jsonData: {},
      id: null,
      fontScale: 2,
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  computed: {
    isFirmato() {
      let me = this;
      let isFirmato = !me.jsonData.firma;
      console.log(isFirmato, "FIRMA");
      me.$emit("updateStatoFirma", isFirmato);
      return !me.jsonData.firma;
    },
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    tipoEvento() {
      return this.jsonData.tipoEvento;
    },
    gruppo() {
      return this.jsonData.gruppo;
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
          me.showModalLoading = false;
        });
      }
    },
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
  },
};
</script>

<style></style>
