<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row v-if="jsonData.statoAttuale === 'INVIATA STRUTTURA'">
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <span class="sa-label-data">
            Questa scheda e' stata conclusa ed e' stata scelta la struttura: <strong>{{ jsonData.strutturaAssegnataDescrizione }}</strong> in data: <strong>{{ formatDate(jsonData.statoAttualeDataOra) }}</strong>
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblProtocolNumber") }}</label>
          <b-form-input v-model="jsonData.numeroProtocollo" disabled></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblProtocolDate") }}</label>
          <!-- <b-form-input v-model="jsonData.dataProtocollo" readonly></b-form-input> -->
          <date-picker format="DD-MM-YYYY" value-type="timestamp" type="date" v-model="jsonData.dataProtocollo" disabled :editable="false"></date-picker>
        </b-col>
      </b-row>
      <hr />
      <h4>{{ getLabelTraduora("adi.lblPatientRegistry") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFiscalCode") }} *</label>
          <b-input-group>
            <b-form-input maxlength="16" v-model="jsonData.paziente.identificativo" @keyup.enter="onSearchAnagraficaClick()" style="text-transform: uppercase" :disabled="!editPropostaAccesso || id !== '-1'"></b-form-input>
            <b-input-group-append>
              <b-button @click="onSearchAnagraficaClick()" :disabled="!codiceFiscaleValido || !editPropostaAccesso || id !== '-1'"><b-icon icon="search"></b-icon></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFirstName") }}</label>
          <span class="sa-data">{{ jsonData.paziente.nome }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblSecondName") }}</label>
          <span class="sa-data">{{ jsonData.paziente.cognome }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDateOfBirth") }}</label>
          <span class="sa-data">{{ formatDate(jsonData.paziente.dataNascita) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAge") }}</label>
          <span class="sa-data">{{ calcoloEta(jsonData.paziente.dataNascita) }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblBirthPlace") }}</label>
          <span class="sa-data">{{ jsonData.paziente.comuneNascita }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblGender") }}</label>
          <!-- <b-form-select v-model="jsonData.generePaziente" :options="generePazienteOptions" :value="null"></b-form-select> -->
          <span class="sa-data">{{ jsonData.paziente.sesso }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblHealthInsuranceCard") }}</label>
          <span class="sa-data">{{ jsonData.tesseraSanitaria }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblTicketExemptionCode") }}</label>
          <b-form-input v-model="jsonData.codiceEsenzioneTicket" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblNationality") }} *</label>
          <b-form-select v-model="jsonData.nazionalita" class="text-upper" :options="nazioniOptions" :value="null" value-field="comune" text-field="comune" :disabled="!editPropostaAccesso"></b-form-select>
        </b-col> -->
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblCitizenship") }} *</label>
          <b-form-input v-model="jsonData.cittadinanza" class="text-upper" :disabled="true"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAddress") }}</label>
          <span class="sa-data">{{ jsonData.paziente.indirizzoResidenza }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblPhone") }} *</label>
          <b-form-input v-model="jsonData.paziente.telefono" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblCity") }}</label>
          <span class="sa-data">{{ jsonData.paziente.comuneResidenza }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblRegion") }} *</label>
          <!-- <b-form-input v-model="jsonData.regioneResidenza"></b-form-input> -->
          <b-form-select v-model="jsonData.regioneResidenza" :options="regioniOptions" :value="null" value-field="regione" text-field="regione" :disabled="!editPropostaAccesso"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblNation") }} *</label>
          <b-form-select v-model="jsonData.nazioneResidenza" :options="nazioniOptions" :value="null" value-field="comune" text-field="comune" :disabled="!editPropostaAccesso"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDoctor") }}</label>
          <span class="sa-data">{{ jsonData.paziente.medicoCurante }}</span>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Cap Nascita</label>
          <b-form-input v-model="jsonData.paziente.capNascita" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Cap Domicilio</label>
          <b-form-input v-model="jsonData.paziente.capDomicilio" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col> -->
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cap Residenza</label>
          <b-form-input v-model="jsonData.paziente.capResidenza" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Email</label>
          <b-form-input v-model="jsonData.paziente.email" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblMmgContacts") }}</label>
          <b-form-input v-model="jsonData.contattiMmg" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblCSST") }}</label>
          <b-form-input v-model="jsonData.csst" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblSocialWorker") }}</label>
          <b-form-input v-model="jsonData.assistenteSociale" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAsl") }} *</label>
          <b-form-input v-model="jsonData.asl" class="text-upper" disabled></b-form-input>
          <!-- <b-form-select v-model="jsonData.asl" :options="aslOptions" :value="null" value-field="value" text-field="text"></b-form-select> -->
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblHealthDistrict") }} *</label>
          <!-- <b-form-input v-model="jsonData.distrettoSanitario" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input> -->
          <b-form-select v-model="jsonData.distrettoSanitario" :options="distrettiOptions" :value="''" :disabled="!editPropostaAccesso" text-field="text" value-field="value" @change="onSetIdPua"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pua Appartenenza</label>
          <!-- <b-form-input v-model="jsonData.distrettoSanitario" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input> -->
          <b-form-select v-model="jsonData.idPuaAppartenenza" :options="puaAppartenenzaOptions" :value="''" :disabled="attivaCampoPua()" text-field="descrizione" value-field="id"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblMunicipality") }}</label>
          <b-form-input v-model="jsonData.municipalita" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblConstituency") }}</label>
          <b-form-input v-model="jsonData.circoscrizione" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <b-form-group>
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblAccessPoint") }} *</label>
            <b-form-select v-model="jsonData.puntoAccesso" :options="puntiAccessoOptions" :value="null" value-field="value" text-field="text" :disabled="!editPropostaAccesso"></b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblProposedDate") }}</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" type="date" v-model="jsonData.dataProposta" :disabled="!editPropostaAccesso"></date-picker>
        </b-col>
      </b-row>
      <hr />

      <h4>{{ getLabelTraduora("adi.lblRequestByOtherSubjects") }} *</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-group>
            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.richiestaSegnalazioneCuraAltriSoggetti" :options="segnalazioneAltriSoggettiOptions" value="null" value-field="value" text-field="text" :disabled="!editPropostaAccesso"></b-form-select>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <br />
              <b-form-textarea v-model="jsonData.richiestaSegnalazioneCuraAltriSoggettiAltro" class="text-upper" v-if="jsonData.richiestaSegnalazioneCuraAltriSoggetti === 'ALTRO'" no-resize rows="8" :disabled="!editPropostaAccesso"></b-form-textarea>
            </b-col>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>{{ getLabelTraduora("adi.lblRequestType") }} *</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-group>
            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.richiestaEspressaSegnalante" :options="tipiRichiestaOptions" value="null" value-field="value" text-field="text" @input="onInputRichiestaEspressaSegnalante" :disabled="!editPropostaAccesso"></b-form-select>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <br />
              <b-form-textarea v-model="jsonData.richiestaEspressaSegnalanteAltro" class="text-upper" v-if="jsonData.richiestaEspressaSegnalante === 'ALTRO'" no-resize rows="8" :disabled="!editPropostaAccesso"></b-form-textarea>
            </b-col>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>{{ getLabelTraduora("adi.lblReferencePerson") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Referente:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.referente" :options="siNoOptions" value="NO" value-field="value" text-field="text" :disabled="!editPropostaAccesso"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblReferenceName") }} *</label>
          <b-form-input v-model="jsonData.nomeReferente" class="text-upper" :disabled="validaCondizione(jsonData.referente)"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblReferenceLastname") }} *</label>
          <b-form-input v-model="jsonData.cognomeReferente" class="text-upper" :disabled="validaCondizione(jsonData.referente)"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblReferenceResidence") }} *</label>
          <b-form-input v-model="jsonData.residenzaReferente" class="text-upper" :disabled="validaCondizione(jsonData.referente)"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblReferenceContacts") }} *</label>
          <b-form-input v-model="jsonData.contattiReferente" class="text-upper" :disabled="validaCondizione(jsonData.referente)"></b-form-input>
        </b-col>
      </b-row>
      <hr />
      <h4>{{ getLabelTraduora("adi.lblLegalProtection") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tutore:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.tutore" :options="siNoOptions" value="NO" value-field="value" text-field="text" :disabled="!editPropostaAccesso"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblLegalName") }} *</label>
          <b-form-input v-model="jsonData.nomeTutore" class="text-upper" :disabled="validaCondizione(jsonData.tutore)"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblLegalLastname") }} *</label>
          <b-form-input v-model="jsonData.cognomeTutore" class="text-upper" :disabled="validaCondizione(jsonData.tutore)"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblLegalResidence") }} *</label>
          <b-form-input v-model="jsonData.residenzaTutore" class="text-upper" :disabled="validaCondizione(jsonData.tutore)"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblLegalRole") }} *</label>
          <b-form-input v-model="jsonData.ruoloTutore" class="text-upper" :disabled="validaCondizione(jsonData.tutore)"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblLegalContacts") }} *</label>
          <b-form-input v-model="jsonData.contattiTutore" class="text-upper" :disabled="validaCondizione(jsonData.tutore)"></b-form-input>
        </b-col>
      </b-row>
      <hr />
      <h4>{{ getLabelTraduora("adi.lblProposalMotivation") }} *</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-group>
            <b-form-select v-model="jsonData.motivoProposta" :options="motivazioniPropostaOptions" :value="null" value-field="value" text-field="text" :disabled="!editPropostaAccesso"></b-form-select>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-form-textarea v-model="jsonData.motivoPropostaAltro" class="text-upper" v-if="jsonData.motivoProposta === 'ALTRO'" no-resize rows="8" :disabled="!editPropostaAccesso"></b-form-textarea>
            </b-col>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>{{ getLabelTraduora("adi.lblProposalType") }} *</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-group>
            <b-form-select v-model="jsonData.tipologiaProposta" :options="tipologiePropostaOptions" :value="null" value-field="value" text-field="text" :disabled="!editPropostaAccesso"></b-form-select>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <br />
              <b-form-textarea v-model="jsonData.tipologiaPropostaAltro" class="text-upper" v-if="jsonData.tipologiaProposta === 'ALTRO'" no-resize rows="8" :disabled="!editPropostaAccesso"></b-form-textarea>
            </b-col>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>{{ getLabelTraduora("adi.lblFirstEvaluation") }} *</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-group>
            <b-form-select v-model="jsonData.primaValutazione" :options="primeValutazioniOptions" :value="null" value-field="value" text-field="text" :disabled="!editPropostaAccesso"></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <hr />
      <h4>{{ getLabelTraduora("adi.lblAttachments") }} *</h4>
      <b-row>
        <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-group>
            <b-form-select v-model="jsonData.allegatiPropriaCompetenza" :options="allegatiOptions" :value="null" value-field="value" text-field="text" :disabled="!editPropostaAccesso"></b-form-select>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <br />
              <b-form-textarea v-model="jsonData.allegatiPropriaCompetenzaAltro" class="text-upper" v-if="jsonData.allegatiPropriaCompetenza === 'ALTRO'" no-resize rows="8" :disabled="!editPropostaAccesso"></b-form-textarea>
            </b-col>
          </b-form-group>
        </b-col> -->
        <b-table sticky-header stacked="md" striped hover itemscope :items="jsonData.listaAllegatiPropriaCompetenza" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template #cell(tipologia)="item">
            <!-- <b-form-input v-model="item.item.tipologia" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input> -->
            <b-form-select v-if="item.index == jsonData.listaAllegatiPropriaCompetenza.length - 1" v-model="item.item.tipologia" :options="allegatiOptions" :value="null" value-field="value" text-field="text" :disabled="!editPropostaAccesso" @change="onCambiaAllegato(item)"></b-form-select>
            <b-form-select v-if="item.index != jsonData.listaAllegatiPropriaCompetenza.length - 1" v-model="item.item.tipologia" :options="allegatiOptions" :value="null" value-field="value" text-field="text" disabled></b-form-select>
          </template>
          <template #cell(descrizione)="item">
            <b-form-input v-if="item.index == jsonData.listaAllegatiPropriaCompetenza.length - 1" v-model="item.item.descrizione" class="text-upper" :disabled="!abilitaCasella"></b-form-input>
            <b-form-input v-if="item.index !== jsonData.listaAllegatiPropriaCompetenza.length - 1" v-model="item.item.descrizione" class="text-upper" disabled></b-form-input>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="row.index == jsonData.listaAllegatiPropriaCompetenza.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddAllegato(jsonData.listaAllegatiPropriaCompetenza, row.item)" :disabled="!editPropostaAccesso">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== jsonData.listaAllegatiPropriaCompetenza.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaAllegatiPropriaCompetenza, row)" :disabled="!editPropostaAccesso">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDocumentDate") }}</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" type="date" v-model="jsonData.dataFirma" :disabled="!editPropostaAccesso"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblUser") }}</label>
          <b-form-input v-model="jsonData.utenteProposta" class="text-upper" :disabled="!editPropostaAccesso"></b-form-input>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("adi.lblSignature") }}</label>
                <b-form-input v-model="jsonData.firmaProposta"></b-form-input>
            </b-col> -->
      </b-row>
      <hr />
      <h4>Informativa al trattamento dei dati personali</h4>
      <!-- <b-row>
                    <b-col cols="3">
                        <p>
                            Il/La sottoscritto/a
                        </p>
                    </b-col>
                    <b-col cols="9">
                        <b-form-input></b-form-input>
                    </b-col>
                    <p>
                        dichiara di essere stato informato, ai sensi dell’art. 13 del D.Lgs.196 /2003 sulla tutela dei dati personali, che i propri dati personali forniti all’atto della compilazione della presente richiesta saranno trattati in conformità alle norme legislative e regolamentari vigenti e applicabili, con modalità automatiche, anche
                        mediante sistemi informatizzati; di acconsentire con la presente dichiarazione, al trattamento dei propri dati personali, svolto con le modalità e per le finalità sopra indicate, ed in conformità alle norme legislative e regolamentari vigenti e applicabili e di essere a conoscenza del fatto di poter esercitare i diritti
                        previsti dall’art. 7 della Legge 196/2003, tra i quali il diritto di ottenere la conferma dell’esistenza o meno di dati nonché la loro cancellazione, mediante comunicazione scritta da inoltrarsi al titolare del trattamento dei dati personali ai sensi e per gli effetti della stessa legge.
                    </p>
                </b-row> -->
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <span class="sa-data">
            Il/La sottoscritto/a
            <strong>{{ jsonData.nome }} {{ jsonData.cognome }}</strong>
            dichiara di essere stato informato, ai sensi dell’art. 13 del D.Lgs.196 /2003 sulla tutela dei dati personali, che i propri dati personali forniti all’atto della compilazione della presente richiesta saranno trattati in conformità alle norme legislative e regolamentari vigenti e applicabili, con modalità automatiche, anche mediante sistemi informatizzati; di acconsentire con la presente dichiarazione, al trattamento dei propri dati personali, svolto con le modalità e per le finalità
            sopra indicate, ed in conformità alle norme legislative e regolamentari vigenti e applicabili e di essere a conoscenza del fatto di poter esercitare i diritti previsti dall’art. 7 della Legge 196/2003, tra i quali il diritto di ottenere la conferma dell’esistenza o meno di dati nonché la loro cancellazione, mediante comunicazione scritta da inoltrarsi al titolare del trattamento dei dati personali ai sensi e per gli effetti della stessa legge.
          </span>
        </b-col>
      </b-row>
      <!--<b-row>
             <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></b-col> 
            <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8"></b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <p class="text-center">
                    <strong>{{ getLabelTraduora("adi.lblSignature") }}</strong>
                    <b-form-input v-model="jsonData.firmaInformativa"></b-form-input>
                </p>
            </b-col>
        </b-row>-->
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    linkback: String,
    linkPrintData: String,
    idPropostaAccesso: String,
    fonte: String,
  },
  mixins: [UtilityMixin],
  components: { DatePicker },
  updated() {},
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    if (me.idPropostaAccesso != "-1") {
      me.id = me.idPropostaAccesso;
      // me.idPropostaAccesso = me.id;
    }
    me.loadData();
    me.loadDefaultData();
    me.impostaPermessi();
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      abilitaCasella: false,
      jsonData: {
        codiceEsenzioneTicket: "",
        nazionalita: null,
        cittadinanza: "",
        regioneResidenza: null,
        nazioneResidenza: null,
        contattiMmg: "",
        csst: "",
        richiestaSegnalazioneCuraAltriSoggetti: null,
        motivoProposta: null,
        puntoAccesso: null,
        numeroProtocollo: "",
        dataProtocollo: new Date().getTime(),
        asl: "",
        municipalita: "",
        distrettoSanitario: "",
        circoscrizione: "",
        dataProposta: new Date().getTime(),
        dataFirma: new Date().getTime(),
        richiestaEspressaSegnalante: null,
        tipologiaProposta: null,
        primaValutazione: null,
        allegatiPropriaCompetenza: null,
        nomeReferente: "",
        cognomeReferente: "",
        residenzaReferente: "",
        contattiReferente: "",
        nomeTutore: "",
        cognomeTutore: "",
        residenzaTutore: "",
        contattiTutore: "",
        ruoloTutore: "",
        utenteProposta: "",
        gruppo: "ADI",
        tipoEvento: "PROPOSTA ACCESSO",
        tutore: "NO",
        referente: "NO",
        idPuaAppartenenza: "",
        paziente: {
          cognome: "",
          nome: "",
          identificativo: "",
          dataNascita: null,
          sesso: null,
          telefono: "",
          medicoCurante: "",
          capNascita: "",
          capDomicilio: "",
          capResidenza: "",
        },
        listaAllegatiPropriaCompetenza: [],
      },
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipologia",
          key: "tipologia",
          thStyle: "width: 25rem",
          sortable: false,
        },
        {
          label: "Descrizione",
          key: "descrizione",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      pathResource: "/adiproposteaccesso",
      linkPuntoAccesso: "/adiproposteaccessopuntoaccesso",
      linkRichiestaAltriSoggetti: "/adiproposteaccessorichiestesegnalazionecuraaltrisoggetti",
      linkRichiestaSegnalante: "/adiproposteaccessorichiesteespressesegnalante",
      linkMotivazioniProposta: "/adiproposteaccessomotivazioniproposte",
      linkPrimeValutazioni: "/adiproposteaccessoprimevalutazioni",
      linkTipologiaProposta: "/adiproposteaccessotipologieproposta",
      linkAllegatiPerCompetenza: "/adiproposteaccessoallegatipropriacompetenza",
      linkNazioni: "/nazioni",
      linkRegioni: "/regioni",
      linkDistretti: "/distretti",
      disableSelectPuaAppartenenza: true,
      puaAppartenenzaOptions: [],
      pathPua: "/adipua",
      siNoOptions: [
        // { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      generePazienteOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "M", text: "Maschio" },
        { value: "F", text: "Femmina" },
      ],
      aslOptions: [{ value: null, text: "-Seleziona Asl-" }],
      puntiAccessoOptions: [{ value: null, text: "-Seleziona Valore-" }],
      segnalazioneAltriSoggettiOptions: [{ value: null, text: "-Seleziona Valore-" }],
      tipiRichiestaOptions: [{ value: null, text: "-Seleziona Valore-" }],
      motivazioniPropostaOptions: [{ value: null, text: "-Seleziona Valore-" }],
      tipologiePropostaOptions: [{ value: null, text: "-Seleziona Valore-" }],
      primeValutazioniOptions: [{ value: null, text: "-Seleziona Valore-" }],
      allegatiOptions: [{ value: "", text: "-Seleziona Valore-" }],
      nazioniOptions: [{ value: null, text: "-Seleziona Valore-" }],
      regioniOptions: [{ value: null, text: "-Seleziona Valore-" }],
      distrettiOptions: [],
      codiceFiscaleValido: false,
      editPropostaAccesso: false,
    };
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.paziente.identificativo": function (value) {
      if (value) {
        var regexCodiceFiscale = /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
        if (regexCodiceFiscale.test(value.toUpperCase())) {
          this.codiceFiscaleValido = true;
        } else this.codiceFiscaleValido = false;
      }
    },
    "jsonData.tutore": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.nomeTutore = "";
        me.jsonData.cognomeTutore = "";
        me.jsonData.residenzaTutore = "";
        me.jsonData.ruoloTutore = "";
        me.jsonData.contattiTutore = "";
      }
    },
    "jsonData.referente": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.nomeReferente = "";
        me.jsonData.cognomeReferente = "";
        me.jsonData.residenzaReferente = "";
        me.jsonData.contattiReferente = "";
      }
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },

  methods: {
    onCambiaAllegato(row) {
      let me = this;
      me.abilitaCasella = false;
      if (me.editPropostaAccesso) {
        if (row.item.tipologia === "ALTRO") {
          me.abilitaCasella = true;
          row.item.descrizione = "";
        } else {
          row.item.descrizione = row.item.tipologia;
        }
      }
      // console.log(row);
    },
    impostaPermessi() {
      let me = this;
      let fonte = this.$route.query.fonte;
      if (fonte === null || fonte === undefined || fonte === "undefined" || fonte === "") {
        fonte = "/richieste";
      }
      if (fonte === "/richieste") {
        me.editPropostaAccesso = UtilityMixin.methods.verificaPermessi(me.utente, "adiproposteaccesso", 2);
      }
    },
    loadDefaultData() {
      let me = this;
      me.loadRichiestaAltriSoggetti();
      me.loadRichiestaSegnalante();
      me.loadMotivazioniProposta();
      me.loadPrimeValutazioni();
      me.loadTipologiaProposta();
      me.loadAllegatiCompetenza();
      me.loadPuntoAccesso();
      me.loadNazioni();
      me.loadRegioni();
      me.loadDistretti();
      // me.loadData();
    },
    loadData() {
      let me = this;
      me.setDatiUtente();
      me.$emit("showmodalloaging", true);
      if (me.id === "-1") {
        me.jsonData.listaAllegatiPropriaCompetenza.push({ tipologia: "", descrizione: "" });
        me.$emit("update", me.jsonData);
        me.$emit("showmodalloaging", false);
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            me.jsonData.listaAllegatiPropriaCompetenza.push({ tipologia: "", descrizione: "" });
            me.$emit("update", me.jsonData);
            me.$emit("showmodalloaging", false);
            me.loadPua();
          })
          .catch(() => {
            me.$emit("update", me.jsonData);
            me.$emit("showmodalloaging", false);
          });
        // me.loadPua();
      }
      me.$emit("update", me.jsonData);
    },
    getJsonData() {
      let me = this;
      return me.jsonData;
    },
    loadPuntoAccesso() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkPuntoAccesso + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.puntiAccessoOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadRichiestaAltriSoggetti() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkRichiestaAltriSoggetti + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.segnalazioneAltriSoggettiOptions.push({ value: element.descirizione, text: element.descirizione });
        });
      });
    },
    loadRichiestaSegnalante() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkRichiestaSegnalante + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipiRichiestaOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadMotivazioniProposta() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkMotivazioniProposta + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.motivazioniPropostaOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadPrimeValutazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkPrimeValutazioni + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.primeValutazioniOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadTipologiaProposta() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkTipologiaProposta + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipologiePropostaOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadAllegatiCompetenza() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkAllegatiPerCompetenza + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.allegatiOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadNazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkNazioni;
      axios.get(link).then((response) => {
        me.nazioniOptions = response.data.data;
      });
    },
    loadRegioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkRegioni;
      axios.get(link).then((response) => {
        me.regioniOptions = response.data.data;
      });
    },
    loadDistretti() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkDistretti;
      me.distrettiOptions = [];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.distrettiOptions.push({ value: element.id, text: "(" + element.codiceDistretto + ") " + element.descrizione });
        });
        me.distrettiOptions.unshift({ value: "", text: "-Seleziona Distretto-" });
      });
    },
    setDatiUtente() {
      let me = this;
      me.jsonData.utenteProposta = me.utente.firstname + " " + me.utente.lastname;
    },
    onInputRichiestaEspressaSegnalante(value) {
      let me = this;
      if (value === "RICOVERO IN STRUTTURA RESIDENZIALE" && me.isOverAge(me.jsonData.dataNascitaAnagrafica, 65)) {
        this.$bvModal.msgBoxOk("Produrre richiesta dematerializzata di visita Geriatrica domiciliare per rilascio SVAMA B ", {
          title: "",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        });
      } else if (value === "RICOVERO IN STRUTTURA SEMI-RESIDENZIALE" && !me.isOverAge(me.jsonData.dataNascitaAnagrafica, 65)) {
        this.$bvModal.msgBoxOk(this.getLabelTraduora("adi.msgRicoveroStrutturaSemiResidenziale"), {
          title: "",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        });
      }
    },
    validaCondizione(value) {
      let me = this;
      let returnValue = true;
      if (me.editPropostaAccesso) {
        if (value === "SI") {
          returnValue = false;
        }
      }
      return returnValue;
    },
    attivaCampoPua() {
      let me = this;
      let returnValue = true;
      if (me.editPropostaAccesso) {
        returnValue = me.disableSelectPuaAppartenenza;
      }
      return returnValue;
    },
    onAddAllegato(array, value) {
      if (value.tipologia && value.descrizione) {
        array.push({ tipologia: "", descrizione: "" });
        this.abilitaCasella = false;
      } else {
        this.$bvModal
          .msgBoxOk("I campi sono obbligatori", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onSetIdPua() {
      let me = this;
      me.loadPua();
    },
    loadPua() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathPua;
      me.disableSelectPuaAppartenenza = true;
      me.puaAppartenenzaOptions = [];
      // console.log(me.jsonData.distrettoSanitario);
      let filtro = { idDistretto: me.jsonData.distrettoSanitario };
      axios.get(link, { params: filtro }).then((response) => {
        me.puaAppartenenzaOptions = response.data.data.list;
        if (me.puaAppartenenzaOptions.length > 1) {
          me.puaAppartenenzaOptions.unshift({ id: "", descrizione: "-Seleziona Pua-" });
          me.disableSelectPuaAppartenenza = false;
        } else if (me.puaAppartenenzaOptions.length === 1) {
          me.jsonData.idPuaAppartenenza = me.puaAppartenenzaOptions[0].id;
        }
      });
    },
    onSearchAnagraficaClick() {
      let me = this;
      if (!me.codiceFiscaleValido)
        return me.$bvModal.msgBoxOk("Codice Fiscale non corretto", {
          title: [this.$i18n.t("adi.lblError")],
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "sa-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      me.jsonData.paziente.identificativo = me.jsonData.paziente.identificativo.toUpperCase();
      let link = process.env.VUE_APP_PATH_API + "/adiproposteaccesso/verificapropostaaperta?codiceFiscale=" + me.jsonData.paziente.identificativo + "&tipologia=MMG/PLS";
      axios.get(link).then((response) => {
        me.$emit("update", me.jsonData);
        if (response.data.data === null) {
          let link = process.env.VUE_APP_PATH_API + "/utility";
          axios
            .get(link, { params: { codiceFiscale: me.jsonData.paziente.identificativo } })
            .then((response) => {
              // console.log(response);
              if (response.data.data !== null) {
                me.jsonData.paziente.nome = response.data.data.nome;
                me.jsonData.paziente.cognome = response.data.data.cognome;
                me.jsonData.paziente.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY").toDate().getTime();
                me.jsonData.paziente.sesso = response.data.data.sesso;
                me.jsonData.paziente.comuneNascita = response.data.data.comuneNascita;
                me.jsonData.paziente.comuneResidenza = response.data.data.comuneResidenza;
                me.jsonData.paziente.indirizzoResidenza = response.data.data.indirizzoResidenza;
                me.jsonData.paziente.indirizzoDomicilio = "";
                me.jsonData.paziente.identificativo = response.data.data.codiceFiscale;
                me.jsonData.paziente.medicoCurante = response.data.data.medico;
                me.jsonData.tesseraSanitaria = response.data.data.numeroTesseraSanitaria;
                me.jsonData.asl = response.data.data.aslDiResidenza;
                me.jsonData.paziente.comuneNascitaCodiceIstat = response.data.data.codiceIstatComuneNascita;
                me.jsonData.paziente.comuneResidenzaCodiceIstat = response.data.data.codiceIstatComuneResidenza;
                me.jsonData.paziente.comuneDomicilioCodiceIstat = "";
                me.jsonData.paziente.capNascita = "";
                me.jsonData.paziente.capDomicilio = "";
                me.jsonData.paziente.capResidenza = "";
                me.jsonData.cittadinanza = response.data.data.cittadinanza;

                me.$emit("update", me.jsonData);
              } else {
                return me.$bvModal.msgBoxOk("Codice Fiscale non corretto", {
                  title: "Errore",
                  size: "sm",
                  okVariant: "outline-danger",
                  headerClass: "sa-msg-header-danger",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                });
              }
            })
            .catch(() => {
              me.$emit("update", me.jsonData);
            });
        } else {
          me.$emit("update", me.jsonData);
          this.$bvModal
            .msgBoxConfirm(response.data.messaggio, {
              title: "Proposta di accesso gia' presente",
              buttonSize: "sm",
              okVariant: "secondary",
              cancelVariant: "danger",
              okTitle: "SI",
              cancelTitle: "NO",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value) {
                let me = this;
                me.$router.replace("/adipazienti/richieste/scheda/edit/" + response.data.data.id).catch((err) => {
                  err;
                });
                me.id = response.data.data.id;
                me.loadData();
              } else {
                let me = this;
                me.$router.replace(me.linkback).catch((err) => {
                  err;
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
  },
};
</script>
<style></style>
