<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAortoCoronaryBypass')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBloodVaseNumPathology") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.patologiaNumVasi) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMammaryArteryUse") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.arteriaMammariaInterna) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDistalAnastomosisNumber") }}</label>
                    <span class="sa-data">{{ jsonData.numAnastomosiDistali }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGraftMammaryArteryNumber") }}</label>
                    <span class="sa-data">{{ jsonData.numGraftArteriaMammariaInt }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGastroepiploicMammaryArteryGraftNumber") }}</label>
                    <span class="sa-data">{{ jsonData.numGraftArteriaMammariaGastroepi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProssimalAnastomosisNumber") }}</label>
                    <span class="sa-data">{{ jsonData.numAnastomosiProssimali }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDistalAnastomosisMammaryArteryNumber") }}</label>
                    <span class="sa-data"> {{ jsonData.numAnastomosiDistArteriaMamInt }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDistalAnastomosisGastroepiploicaArteryNumber") }}</label>
                    <span class="sa-data"> {{ jsonData.numAnastomosiDistArteriaGastroepi }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCommonTrunk')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.percentualeDiStenosiTronco) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.condottoTronco) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tecnicaTronco) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightCoronary')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.percentualeDiStenosiCoronariaDx) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.condottoCoronariaDx) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tecnicaCoronariaDx) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblFrontalDescendant')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.percentualeDiStenosiDiscendenteAnt) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.condottoDiscendenteAnt) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tecnicaDiscendenteAnt) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblFirstDiagonal')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.percentualeDiStenosiPrimoDiagonale) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.condottoPrimoDiagonale) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tecnicaPrimoDiagonale) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSecondDiagonal')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.percentualeDiStenosiSecondoDiagonale) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.condottoSecondoDiagonale) }}</span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.tecnicaSecondoDiagonale) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {},
    components: {},
    data() {
        return {
            pathResource: "/cardiochirurgia",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
