<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" @update="onUpdateJsonData" :titoloEvento="titoloEvento">
        <template slot="tab-content-event">
            <refertazione-ecocardio-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></refertazione-ecocardio-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import RefertazioneEcocardioEditComponent from "./RefertazioneEcocardioEditComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { RefertazioneEcocardioEditComponent, EventiEditComponent },
    data() {
        return {
            linkback: null,
            titoloEvento: this.getLabelTraduora("menu.patient.echocardiumReporting"),
            pathResource: "/cardioecocardirefertazioni",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            jsonData: {
                dataEvento: null,
                impedenzaAcustica: null,
                /**/
                vstd: null,
                vsts: null,
                sivtd: null,
                pptd: null,
                eft: null,
                sft: null,
                lvmha: null,
                rwt: null,
                ess: null,
                lvmidp: null,
                lvv: null,
                /**/
                settoanteriore: null,
                settoanterioreMedio: null,
                pareteanteriore: null,
                pareteanterioreMedia: null,
                apiceSetto: null,
                apiceAnt: null,
                apiceInf: null,
                apicePost: null,
                pareteLatAnteriore: null,
                pareteLatAnterioreMedia: null,
                pareteLatPosteriore: null,
                pareteLatPosterioreMedia: null,
                pareteInferiore: null,
                pareteInferioreMedia: null,
                settoposterioreMedio: null,
                settoposteriore: null,
                /**/
                it: null,
                ip: null,
                im: null,
                ia: null,
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.dataEvento = new Date().getTime();
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
