<template>
    <div style="float: left">
        <b-button v-if="typeButton === 'normal'" class="float-sm-left btn-toolbar sa-btn-icon-text" variant="outline-secondary sa-margin-left" @click="onFirma" :disabled="disabled">
            <b-icon icon="vector-pen"></b-icon>
            Firma
        </b-button>
        <b-button v-if="typeButton === 'small'" size="sm" class="btn btn-outline-info waves-effect waves-light width-sm no-text" variant="outline-secondary sa-margin-left" @click="onFirma" :disabled="disabled">
            <b-icon icon="vector-pen"></b-icon>
        </b-button>

        <b-modal ref="mdlFirma" title="Firma" @hidden="onHidden" @ok="onOk">
            <form ref="form" @submit.stop.prevent="onFirmaSubmit">
                <b-form-group label="Password" label-for="name-input" invalid-feedback="Name is required" :state="passwordState">
                    <b-form-input type="password" v-model="password" :state="passwordState" required placeholder="Password"></b-form-input>
                </b-form-group>
            </form>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: {
        idEvento: { type: String, require: true },
        pathRestFirma: { type: String, require: true },
        pathRestValidation: { type: String, require: true },
        disabled: { type: Boolean, require: true, default: false },
        fieldName: { type: String, require: true },
        data: { Type: Object, require: true },
        firmaData: {
            Type: Object,
            default: function () {
                return { idEvento: this.idEvento, key: null, fieldName: this.fieldName };
            },
        },
        typeButton: {
            type: String,
            default: function () {
                return "normal";
            },
        },
    },
    data() {
        return {
            password: "",
            passwordState: null,
        };
    },
    methods: {
        checkFormValidity() {
            const valid = this.$refs.form.checkValidity();
            this.passwordState = valid;
            return valid;
        },
        onFirma() {
            let me = this;
            if (me.idEvento === "-1" || me.data.id === null) {
                this.$bvModal
                    .msgBoxOk("Salvare prima di effettuare la validazione.", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        this.boxOne = value;
                    });
            } else {
                let jsonData = JSON.stringify(me.data);
                if (me.pathRestValidation) {
                    let link = process.env.VUE_APP_PATH_API + me.pathRestFirma + me.pathRestValidation;
                    axios
                        .post(link, jsonData)
                        .then(() => {
                            me.$refs["mdlFirma"].show();
                        })
                        .catch((e) => {
                            let message = "";
                            if (e.response.data.messaggio) {
                                message = e.response.data.messaggio;
                            } else {
                                message = e.response.status;
                            }
                            this.$bvModal.msgBoxOk(message, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            });
                        });
                } else {
                    me.$refs["mdlFirma"].show();
                }
            }
        },
        onFirmaSubmit() {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return;
            }
            let key = btoa(btoa(this.password));
            this.firmaData.fieldName = this.fieldName;
            this.firmaData.key = key;
            let jsonData = JSON.stringify(this.firmaData);
            let link = process.env.VUE_APP_PATH_API + this.pathRestFirma + "/firma/" + this.idEvento;
            axios
                .put(link, jsonData)
                .then((response) => {
                    let me = this;
                    me.$emit("update");
                    this.$bvToast.toast(response.data.messaggio, {
                        title: "Firma effettuata",
                        variant: "success",
                        solid: true,
                        noCloseButton: true,
                        autoHideDelay: 2000,
                    });
                })
                .catch((err) => {
                    this.$bvModal
                        .msgBoxOk(err.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxOne = value;
                        });
                });

            this.$nextTick(() => {
                this.$refs["mdlFirma"].hide();
            });
        },
        onHidden() {
            this.passwordState = null;
            this.password = "";
        },
        onOk(bvModalEvt) {
            let me = this;
            bvModalEvt.preventDefault();
            me.onFirmaSubmit();
        },
    },
};
</script>
