var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sa-page-layout',{ref:"pgaView",attrs:{"btnBackVisible":true,"btnEditVisible":true,"titolo":_vm.gruppo.nome,"linkback":_vm.linkback,"linkedit":_vm.linkedit,"showModalLoading":_vm.showModalLoading}},[_c('template',{slot:"table-filter"},[_c('b-row',[_c('b-col',{staticClass:"sa-text-right",attrs:{"cols":"12","md":"2"}},[_vm._v("Nome:")]),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b',[_vm._v(_vm._s(_vm.gruppo.nome))])]),_c('b-col',{staticClass:"sa-text-right",attrs:{"cols":"12","md":"2"}},[_vm._v("Descrizione:")]),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b',[_vm._v(_vm._s(_vm.gruppo.descrizione))])])],1)],1),_c('template',{slot:"table-body"},[_c('b-tabs',{staticClass:"sa-tab",staticStyle:{"height":"100%"},attrs:{"content-class":"mt-3","fill":""}},[_c('b-tab',{attrs:{"title":"Menu","active":""}},[_c('div',{staticClass:"b-table-sticky-header"},[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.gruppo.menu,"fields":_vm.fieldsMenu,"head-variant":"light","sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(iconCls)",fn:function(ref){
var item = ref.item;
return [_c('i',{class:'fas fa-' + item.iconCls})]}},{key:"cell(text)",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('span',{staticClass:"sa-text-bold"},[_vm._v(_vm._s(item.text)+" ("+_vm._s(item.routeId)+")")])]),_c('div',[_c('span',[_vm._v(_vm._s(item.view))])])]}},{key:"cell(selected)",fn:function(ref){
var item = ref.item;
return [(item.selezionato)?_c('b-icon',{attrs:{"icon":"check-square-fill","variant":"success"}}):_c('b-icon',{attrs:{"icon":"x-square-fill","variant":"danger"}})]}}])})],1)]),_c('b-tab',{attrs:{"title":"Permessi"}},[_c('div',{staticClass:"b-table-sticky-header"},[_c('b-table',{attrs:{"striped":"","hover":"","filter":_vm.filter,"filterIncludedFields":_vm.filterOn,"items":_vm.gruppo.sezioni,"fields":_vm.fieldsSezioni,"head-variant":"light","sort-icon-left":""},scopedSlots:_vm._u([{key:"cell(permessiSezione)",fn:function(ref){
var item = ref.item;
return _vm._l((item.permessi),function(permesso,index){return _c('div',{key:index},[(permesso.attivo)?_c('div',[_c('b-icon',{attrs:{"icon":"check-square-fill","variant":"success"}}),_vm._v("  "+_vm._s(permesso.descrizione)+" ")],1):_c('div',[_c('b-icon',{attrs:{"icon":"x-square-fill","variant":"danger"}}),_vm._v("  "+_vm._s(permesso.descrizione)+" ")],1)])})}}])})],1)])],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }