<template>
    <div>
        <b-row>
            <b-col class="sa-padding-right text-right">
                <b-button variant="outline-info" @click="onTblCardiolineRefresh">
                    <b-icon icon="arrow-clockwise" />
                    Aggiorna
                </b-button>
            </b-col>
        </b-row>
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="richiesteCardiolineitems" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" no-header>
            <template v-slot:cell(nominativo)="{ item }">
                <router-link class="sa-edit-link" :to="'/worklist/visitamedicosportiva/view/' + item.id">{{ item.nome }} {{ item.cognome }}</router-link>
            </template>
            <template #cell(actions)="row">
                <b-button v-if="btnResendVisible" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onClickResend(row.item)">
                    <i class="bi bi-send sa-icon-btn-sm-i"></i>
                </b-button>
                <!-- <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onClickDelete(row.item)">
                    <b-icon icon="trash"></b-icon>
                </b-button> -->
                <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onClickViewPdf(row.item)" :disabled="!row.item.base64">
                    <i class="bi bi-file-earmark-pdf sa-icon-btn-sm-i"></i>
                </b-button>
                <b-button size="sm" variant="btn btn-cardioline-outline waves-effect waves-light width-sm no-text" @click="onClickOpenCardiolineApp(row.item)" :disabled="!row.item.examId">
                    <i class="bi bi-activity sa-icon-btn-sm-i"></i>
                </b-button>
            </template>
        </b-table>
        <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%">
            <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
export default {
    props: {
        idRisorsa: {
            type: String,
            require: true,
        },
        btnResendVisible: {
            type: Boolean,
            default: function () {
                return true;
            },
        },
    },
    mounted() {
        let me = this;
        console.log(this.btnResendVisible);
        me.loadData();
    },
    data() {
        return {
            pathResource: "/cardioline",
            currentPage: 1,
            perPage: 50,
            filtro: {},
            stampaReport: null,
            // richiesteCardiolineitems: [{ dataOra: 0, stato: "INVIO IN CORSO", risposta: "Antonio Mona è strunz." }],
            richiesteCardiolineitems: [],
            fields: [
                {
                    label: "Data Ora",
                    key: "dataOra",
                    formatter: function (value) {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
                    },
                    thStyle: "width: 10rem",
                },
                {
                    label: "Tipo Esame",
                    key: "tipoEsame",
                },
                {
                    label: "Stato",
                    key: "stato",
                    thStyle: "width: 11rem",
                },

                { class: "sa-table-column-action-single", key: "actions", label: "", thStyle: "width: 10rem" },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.forPage = me.perPage;
            me.filtro.page = me.currentPage;
            me.filtro.idRisorsa = me.idRisorsa;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.richiesteCardiolineitems = response.data.data.list;
                    response.data.data.list.forEach((element) => {
                        this.$emit("updateBase64", element);
                    });
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            console.log(value);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onTblCardiolineRefresh() {
            let me = this;
            me.loadData();
        },
        onClickResend(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/send/" + item.id;
            // let cardiolineRequest = { examType: "ECG", unitId: 1, firstName: me.jsonData.nome, lastName: me.jsonData.cognome, patientId: me.jsonData.codiceFiscale, gender: me.jsonData.sesso, birthdate: moment(new Date(me.jsonData.dataNascita)).format("YYYYMMDD"), action: "", idRisorsa: me.jsonData.id };
            me.showModalLoading = true;
            axios
                .put(link) //cardiolineRequest
                .then(() => {
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    console.log(error);
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            console.log(value);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        onClickDelete(item) {
            console.log(item);
        },
        onClickViewPdf(item) {
            let me = this;
            // me.$refs.mdlGenerazioneReportInCorso.hide();
            me.$refs.mdlStampaReferto.show();
            me.stampaReport = "data:application/pdf;base64," + item.base64;
        },
        onClickOpenCardiolineApp(item) {
            let link = "http://192.168.16.201/EcgWebApp/Ecg/EditReport?id=" + item.examId;
            window.open(link);
        },
    },
};
</script>
<style scoped>
.btn-cardioline-outline:hover {
    color: #fff;
    background-color: #ee5b00;
    border-color: #ee5b00;
}

.btn-cardioline-outline {
    color: #ee5b00;
    background-color: transparent;
    border-color: #ee5b00;
}
.sa-icon-i-sm {
    margin-right: 10px;
    height: 21px;
    width: 21px;
    font-size: 1.4rem;
    font-size: 1.4rem;
    margin-top: -5px;
}
</style>
