<template>
  <div>
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
          <span class="sa-data"> {{ formatDateTime(jsonData.dataEvento) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <!-- <hr /> -->
    <b-card class="sa-card" header="Anamnesi Familiare" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <!-- <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
          <h5>{{ getLabelTraduora("patient.cardiology.lblFamilyAnamnesys") }}</h5>
        </b-col> -->
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensiveFather") }}</label>
          <span :class="setColor(jsonData.padreIperteso) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.padreIperteso)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensiveMother") }}</label>

          <span :class="setColor(jsonData.madreIpertesa) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.madreIpertesa)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensiveSibilings") }}</label>

          <span :class="setColor(jsonData.fratelliIpertesi) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fratelliIpertesi)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabeticRelatives") }}</label>

          <span :class="setColor(jsonData.parentiDiabetici) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.parentiDiabetici)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNephropathicRelatives") }}</label>

          <span :class="setColor(jsonData.parentiNefropatici) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.parentiNefropatici)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemiaRelatives") }} </label>

          <span :class="setColor(jsonData.parentidislip) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.parentidislip)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIschemicRelatives") }}</label>
          <span class="sa-data">{{ getLabelTraduora(jsonData.parentiCardiopatiaIsch) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCerebrovascularAccidentsRelatives") }}</label>
          <span class="sa-data">{{ getLabelTraduora(jsonData.parentiCerebrovasc) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <!-- <hr /> -->
    <b-card class="sa-card" header="Anamnesi Personale" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <!-- <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
        <h5>{{ getLabelTraduora("patient.cardiology.lblPersonalAnamnesys") }}</h5>
      </b-col> -->

        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensiveYears") }}</label>
          <span class="sa-data"> {{ getLabelTraduora(jsonData.anniIpertensione) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPaBeforeTherapy") }}</label>
          <span class="sa-data"> Pa Min :</span>
          <span class="sa-data"> {{ jsonData.paminBeforeterapia }}</span>
        </b-col>
        <b-col class="ft-color-section" style="display: flex; align-items: end" xs="12" sm="12" md="12" lg="3" xl="3">
          <span class="sa-data"> Pa Max :</span>
          <span class="sa-data"> {{ jsonData.pamaxBeforeterapia }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOnTherapy") }}</label>

          <span :class="setColor(jsonData.terapiaUltimoAnno) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.terapiaUltimoAnno)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCarbohydrateIntolerance") }}</label>

          <span :class="setColor(jsonData.intolleranzaCarboidrati) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.intolleranzaCarboidrati)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemia") }}</label>
          <span class="sa-data"> {{ getLabelTraduora(jsonData.dislipidemia) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabetesMellitus") }}</label>

          <span :class="setColor(jsonData.diabeteMellito) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diabeteMellito)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabeticYears") }}</label>
          <span class="sa-data"> {{ jsonData.anniDiabetici }}</span>
        </b-col>

        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPregIMA") }}</label>

          <span :class="setColor(jsonData.pregIma) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.pregIma)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>

          <span class="sa-data"> {{ getLabelTraduora(jsonData.sedePregIma) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPregPTCA") }}</label>

          <span :class="setColor(jsonData.pregPtca) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.pregPtca)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>

          <span class="sa-data"> {{ getLabelTraduora(jsonData.sedePregPtca) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPregCABG") }}</label>

          <span :class="setColor(jsonData.pregCabg) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.pregCabg)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>

          <span class="sa-data"> {{ getLabelTraduora(jsonData.sedePregCabg) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValvulopathy") }}</label>

          <span :class="setColor(jsonData.valvulop) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.valvulop)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>
          <span class="sa-data"> {{ jsonData.sedeValvulop }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPastCrerebralIschemia") }}</label>
          <span :class="setColor(jsonData.pregIschemia) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.pregIschemia)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSede") }}</label>

          <span class="sa-data"> {{ jsonData.cmbPregIschemia }}</span>
        </b-col>
      </b-row>
    </b-card>
    <!-- <hr /> -->
    <b-card class="sa-card" header="Positivita' anamnesi" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <!-- <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
        <h5>{{ getLabelTraduora("patient.cardiology.lblAnamnesysPositivity") }}</h5>
      </b-col> -->
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRenalLithiasis") }}</label>
          <span :class="setColor(jsonData.litiasi) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.litiasi)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinaryInfections") }}</label>
          <span :class="setColor(jsonData.infezioni) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.infezioni)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlcoholAbuse") }}</label>
          <span :class="setColor(jsonData.alcool) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.alcool)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLicoriceAbuse") }}</label>
          <span :class="setColor(jsonData.liquirizia) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.liquirizia)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoseSpray") }}</label>
          <span :class="setColor(jsonData.sprayNasali) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.sprayNasali)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLipidloweringDrugs") }}</label>
          <span :class="setColor(jsonData.ipolipemizzanti) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.ipolipemizzanti)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMenoAndroPause") }}</label>
          <span :class="setColor(jsonData.menopausa) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.menopausa)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOralContraceptives") }}</label>
          <span :class="setColor(jsonData.contraccettivi) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.contraccettivi)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPregnancyHypertension") }}</label>
          <span :class="setColor(jsonData.ipertGravidanz) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.ipertGravidanz)"></b-icon></span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlcoholConsumption") }}</label>
          <span class="sa-data"> {{ getLabelTraduora(jsonData.consumoDiAlcool) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQualification") }}</label>
          <span class="sa-data"> {{ getLabelTraduora(jsonData.titoloDiStudio) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWorkActivity") }}</label>
          <span class="sa-data"> {{ getLabelTraduora(jsonData.attivitaLavorativa) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIrrascibilityWork") }}</label>
          <span class="sa-data"> {{ getLabelTraduora(jsonData.problemiLavoro) }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPersonalMatters") }}</label>
          <span class="sa-data"> {{ getLabelTraduora(jsonData.problemiCasa) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <!-- <hr /> -->
    <b-card class="sa-card" header="Livelli di consumo di alimenti particolari nella dieta" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <!-- <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
        <h5>{{ getLabelTraduora("patient.cardiology.lblDietActivityHabits") }}</h5></b-col
      > -->

        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFruit") }}</label>
          <span class="sa-data"> {{ jsonData.frutta }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFish") }}</label>
          <span class="sa-data"> {{ jsonData.pesce }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOil") }}</label>
          <span class="sa-data"> {{ jsonData.olio }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCheese") }}</label>
          <span class="sa-data"> {{ jsonData.formaggio }}</span>
        </b-col>

        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPersonality") }}</label>
          <span class="sa-data"> {{ jsonData.personalita }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPhysicalActivity") }}</label>
          <span class="sa-data"> {{ jsonData.attivitaFisica }}</span>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
          <span class="sa-data"> {{ jsonData.altro }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixins],
  props: {},
  components: {},
  data() {
    return {
      pathResource: "/cardioanamnesi",
      jsonData: {},
      id: null,
      fontScale: 2,
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  computed: {
    isFirmato() {
      let me = this;
      let isFirmato = !me.jsonData.firma;
      console.log(isFirmato, "FIRMA");
      me.$emit("updateStatoFirma", isFirmato);
      return !me.jsonData.firma;
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
          me.showModalLoading = false;
        });
      }
    },
    setColor(value) {
      let color = value == "true" ? "text-success" : "text-danger";
      return color;
    },
    setIcon(value) {
      let icon = value === "true" ? "check" : "x";
      return icon;
    },
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
  },
};
</script>

<style></style>
