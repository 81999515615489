<template>
    <div class="sa-tab-scroll">
        <!-- InizioBody  -->
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.events.lblEventDate')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                            <date-picker format="DD-MM-YYYY hh:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <!-- <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblAngiocardiopneumoscintrigraphy") }}</h5>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasalEF") }}</label>
                <b-form-input v-model="jsonData.efBasale" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterStressEF") }}</label>
                <b-form-input v-model="jsonData.efDopostress" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>

                <b-form-select v-model="jsonData.conclusione1" :options="conclusioniOptions">
                    <template #first>
                        <b-form-select-option :value="null" disabled>
                            {{ firstElementSelect }}
                        </b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                <b-form-textarea v-model="jsonData.noteAngiocardiopnemoscintigrafia" rows="5" max-rows="5" no-resize></b-form-textarea>
            </b-col> -->
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <test-provocativi-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></test-provocativi-edit-component>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblMiocardicSpet')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNormoperfusion") }}</label>
                            <b-form-checkbox v-model="jsonData.normoperfusione" switch :checked="jsonData.normoperfusione"></b-form-checkbox>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeficRev") }}</label>
                            <b-form-select v-model="jsonData.deficRev1" :options="deficitOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeficRev") }}</label>
                            <b-form-select v-model="jsonData.deficRev2" :options="deficitOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTessutoIpoper") }}</label>
                            <b-form-select v-model="jsonData.tessutoIpoper1" :options="deficitOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTessutoIpoper") }}</label>
                            <b-form-select v-model="jsonData.tessutoIpoper2" :options="deficitOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeficIrr") }}</label>
                            <b-form-select v-model="jsonData.deficIrr1" :options="deficitOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeficIrr") }}</label>
                            <b-form-select v-model="jsonData.deficIrr2" :options="deficitOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>

                            <b-form-select v-model="jsonData.conclusione2" :options="conclusioniOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                        </b-col>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                            <b-form-textarea v-model="jsonData.noteSpetMiocardica" rows="5" max-rows="5" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import TestProvocativiEditComponent from "../../components/TestProvocativiEditComponent.vue";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker, TestProvocativiEditComponent },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return { dataEvento: null };
            },
        },
    },
    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            pathResource: "/cardiospectmiocardica",
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            conclusioniOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NORMALE", text: "NORMALE" },
                { value: "PATOLOGICO", text: "PATOLOGICO" },
                { value: "DUBBIO", text: "DUBBIO" },
            ],
            deficitOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "NORMALE", text: "NORMALE" },
                { value: "DEFICIT SETTALE", text: "DEFICIT SETTALE" },
                { value: "DEFICIT LATERALE", text: "DEFICIT LATERALE" },
                { value: "DEFICIT ANTERIORE", text: "DEFICIT ANTERIORE" },
                { value: "DEFICIT POSTERO-LATERALE", text: "DEFICIT POSTERO-LATERALE" },
                { value: "DEFICIT POSTERIORE", text: "DEFICIT POSTERIORE" },
                { value: "DEFICIT FRONT-SEPTAL", text: "DEFICIT FRONT-SEPTAL" },
                { value: "DEFICIT ANTERO-APICALE", text: "DEFICIT ANTERO-APICALE" },
                { value: "DEFICIT ANTERO-LATERALE", text: "DEFICIT ANTERO-LATERALE" },
                { value: "DEFICIT INFERIORE", text: "DEFICIT INFERIORE" },
                { value: "DEFICIT APICALE", text: "DEFICIT APICALE" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
