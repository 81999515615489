<template>
  <div class="sa-tab-scroll">
    <b-card v-if="isUltimaVisita" class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-alert show class="alert-danger">
            <p style="padding-top: 10px; padding-bottom: 2px"><i class="bi bi-exclamation-triangle-fill"></i> Data Ultima Visita Controllo ECG : {{ formatDateTime(dataUltimaVisita) }}</p>
          </b-alert>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRoutineExams')" header-tag="header" footer-tag="footer" title="">
      <template #header>
        <b-row>
          <b-col cols="12" xs="10" sm="10" md="10" lg="10" xl="10">
            <span>Esami di Routine</span>
          </b-col>
          <b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
            <b-button variant="outline-secondary outline-secondary-noborder pull-right" size="sm" @click="onKeyPressEnterCalcoli">
              <i style="margin-top: 2px" class="fas fa-calculator"></i>
              <span style="padding-left: 5px">Calcola</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Età</label>
          <span class="sa-data">{{ jsonData.eta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Peso</label>
          <span class="sa-data">{{ jsonData.peso }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sesso</label>
          <span class="sa-data">{{ jsonData.sesso }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGlicemy") }}</label>
          <b-form-input v-model="jsonData.glicemia"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCreatininemia") }}</label>
          <b-form-input v-model="jsonData.creatininemia" type="number"></b-form-input>
          <!-- <b-form-input v-model="jsonData.creatininemia" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input> -->
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAzotemia") }}</label>
          <b-form-input v-model="jsonData.azotemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUricemia") }}</label>
          <b-form-input v-model="jsonData.uricemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSodiemia") }}</label>
          <b-form-input v-model="jsonData.sodiemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPotassiemia") }}</label>
          <b-form-input v-model="jsonData.potassiemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCalcemia") }}</label>
          <b-form-input v-model="jsonData.calcemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColesterolemia") }}</label>
          <b-form-input v-model="jsonData.colesterolo" type="number"></b-form-input>
          <!-- <b-form-input v-model="jsonData.colesterolo" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input> -->
          <!-- <b-form-input v-model="jsonData.colesterolo"></b-form-input> -->
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHDL") }}</label>
          <b-form-input v-model="jsonData.hdl" type="number"></b-form-input>
          <!-- <b-form-input v-model="jsonData.hdl" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input> -->
          <!-- <b-form-input v-model="jsonData.hdl"></b-form-input> -->
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTrigliceridemia") }}</label>
          <b-form-input v-model="jsonData.trigliceridi"></b-form-input>
          <!-- <b-form-input v-model="jsonData.trigliceridi" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input> -->
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLDL") }}</label>
          <b-form-input v-model="jsonData.ldl" disabled></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPCR") }}</label>
          <b-form-input v-model="jsonData.pcr" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCTnl") }}</label>
          <b-form-input v-model="jsonData.ctnl" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCK-Mb") }}</label>
          <b-form-input v-model="jsonData.ckMb" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHbA1CPercentage") }}</label>
          <b-form-input v-model="jsonData.hba1cPerc" type="number"></b-form-input>
          <!-- <b-form-input v-model="jsonData.hba1cPerc" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input> -->
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHbA1Cmmol") }}</label>
          <b-form-input v-model="jsonData.hba1cMmol" disabled></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPOA") }}</label>
          <b-form-input v-model="jsonData.apoa" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPOB") }}</label>
          <b-form-input v-model="jsonData.apob" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLPa") }}</label>
          <b-form-input v-model="jsonData.lpa" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPLA2") }}</label>
          <b-form-input v-model="jsonData.pla2" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">NT-proBNP (pg/ml)</label>
          <b-form-input v-model="jsonData.ntProBnp" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Vitamina D-25 OH (mg/ml)</label>
          <b-form-input v-model="jsonData.vitaminaD25Oh" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Cloremia</label>
          <b-form-input v-model="jsonData.cloremia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Magnesemia</label>
          <b-form-input v-model="jsonData.magnesemia" type="number"></b-form-input>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Quadro Prot. Elettrof.</label>
                    <b-form-input v-model="jsonData.quadroProtElettroforetico" type="number"></b-form-input>
                </b-col> -->
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Amilasi</label>
          <b-form-input v-model="jsonData.amilasi" type="number"></b-form-input>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Microalbuminuria</label>
                    <b-form-input v-model="jsonData.microalbuminuria" type="number"></b-form-input>
                </b-col> -->
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Sideremia</label>
          <b-form-input v-model="jsonData.sideremia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ferritinemia</label>
          <b-form-input v-model="jsonData.ferritinemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Transferrinemia</label>
          <b-form-input v-model="jsonData.transferrinemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Troponinemia T</label>
          <b-form-input v-model="jsonData.troponinemiaT" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Troponinemia I</label>
          <b-form-input v-model="jsonData.troponinemiaI" type="number"></b-form-input>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">TSH</label>
                    <b-form-input v-model="jsonData.tsh" type="number"></b-form-input>
                </b-col> 
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">FT3</label>
                    <b-form-input v-model="jsonData.ft3" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">FT4</label>
                    <b-form-input v-model="jsonData.ft4" type="number"></b-form-input>
                </b-col>-->
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Anticorpi Anti-TG</label>
          <b-form-input v-model="jsonData.anticorpiAntiTg" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Anticorpi Anti-TPO</label>
          <b-form-input v-model="jsonData.anticorpiAntiTpo" type="number"></b-form-input>
        </b-col> -->
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPraAndBasalAldosteronemia1hAfterCaptopril") }}</label>
                    <b-form-input v-model="jsonData.dosaggioDopoCaptopril" type="number"></b-form-input>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPraAndBasalAldosteronemiaOnClinic") }}</label>
                    <b-form-input v-model="jsonData.dosaggioClinoOrto" type="number"></b-form-input>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Cortisolemia 8-20</label>
                    <b-form-input v-model="jsonData.cortisolemia820" type="number"></b-form-input>
                </b-col> -->
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinaryCatecholaminesMetanephrinesDosage24hSample") }}</label>
                    <b-form-input v-model="jsonData.dosaggioCatecolamine" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">ACTH</label>
                    <b-form-input v-model="jsonData.acth" type="number"></b-form-input>
                </b-col> -->
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblUrineExams')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGlucose") }}</label>
          <b-form-select v-model="jsonData.urineGlucosio" :options="optionsPresente">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBlood") }}</label>
          <b-form-select v-model="jsonData.urineSangue" :options="optionsPresente">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteins") }}</label>

          <b-form-select v-model="jsonData.urineProteine" :options="optionsPresente">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSediment") }}</label>
          <b-form-input v-model="jsonData.sedimento" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrineVolume24h") }}</label>
          <b-form-input v-model="jsonData.volUrine24" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinocolture") }}</label>
          <b-form-input v-model="jsonData.urinocoltura" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMicroalbuminuria") }}</label>
          <b-form-select v-model="jsonData.microalbuminuria" :options="optionsPresente">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMicroalbuminuriaQuantity") }}</label>
          <b-form-input v-model="jsonData.albuminuria" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCreatininaClearancePerMinute") }}</label>
          <b-form-input v-model="jsonData.clearance" disabled></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCalciuria") }}</label>
          <b-form-input v-model="jsonData.calciuria" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteinuria") }}</label>
          <b-form-input v-model="jsonData.proteinuria24" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFosfaturia") }}</label>
          <b-form-input v-model="jsonData.fosfaturia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDigossinemia") }}</label>
          <b-form-input v-model="jsonData.digossinemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOmocisteinemia") }}</label>
          <b-form-input v-model="jsonData.omocisteinemia" type="number"></b-form-input>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Dosaggio degli Elettroliti Urinari (Na+ e K+) su campione delle 24h</label>
                    <b-form-input v-model="jsonData.dosaggioElettrolitiUrinari" type="number"></b-form-input>
                </b-col> -->
      </b-row>
    </b-card>
    <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4"
            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSideremia") }}</span
            >
            <b-form-input v-model="jsonData.sideremia" type="number"></b-form-input>
          </b-col> 
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"
            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFerritina") }}</span
            >
            <b-form-input v-model="jsonData.ferritina" type="number"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"
            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTransferrina") }}</span
            >
            <b-form-input v-model="jsonData.transferrina" type="number"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4"
            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNT-proBNP") }}</span
            >
            <b-form-input v-model="jsonData.ntProBnp" type="number"></b-form-input>
          </b-col>-->
    <b-card class="sa-card" header="Q. P. E." header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteineTotali") }}</label>
          <b-form-input v-model="jsonData.proteineTotali" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlbumine") }}</label>
          <b-form-input v-model="jsonData.albumine" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlfa1Globuline") }}</label>
          <b-form-input v-model="jsonData.alfa1Globuline" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlfa2Globuline") }}</label>
          <b-form-input v-model="jsonData.alfa2Globuline" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBetaGlobuline") }}</label>
          <b-form-input v-model="jsonData.betaGlobuline" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGammaGlobuline") }}</label>
          <b-form-input v-model="jsonData.gammaGlobuline" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGOT") }}</label>
          <b-form-input v-model="jsonData.got" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGPT") }}</label>
          <b-form-input v-model="jsonData.gpt" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCPK") }}</label>
          <b-form-input v-model="jsonData.cpk" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLDH") }}</label>
          <b-form-input v-model="jsonData.ldh" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblALP") }}</label>
          <b-form-input v-model="jsonData.alp" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Y-GT</label>
          <b-form-input v-model="jsonData.yGt" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Emocromo" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRedBloodCells") }}</label>
          <b-form-input v-model="jsonData.globuliRossi" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWhiteBloodCells") }}</label>
          <b-form-input v-model="jsonData.globuliBianchi" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPlatelets") }}</label>
          <b-form-input v-model="jsonData.piastrine" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEmatocrito") }}</label>
          <b-form-input v-model="jsonData.ematocrito" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFibrinogeno") }}</label>
          <b-form-input v-model="jsonData.fibrinogeno" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHB") }}</label>
          <b-form-input v-model="jsonData.emoglobina" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPT") }}</label>
          <b-form-input v-model="jsonData.pt" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblINR") }}</label>
          <b-form-input v-model="jsonData.inr" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPTT") }}</label>
          <b-form-input v-model="jsonData.aptt" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHepatitisMarkerA") }}</label>
          <b-form-select v-model="jsonData.markerEpatiteA" :options="optionsEpatite">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHepatitisMarkerB") }}</label>

          <b-form-select v-model="jsonData.markerEpatiteB" :options="optionsEpatite">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHepatitisMarkerC") }}</label>

          <b-form-select v-model="jsonData.markerEpatiteC" :options="optionsEpatite">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
          <b-form-textarea v-model="jsonData.altro" type="text" rows="5" max-rows="5" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },

  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      isUltimaVisita: false,
      dataUltimaVisita: null,
      pathResource: "/cardioesamilaboratorio",
      pathResourceLastVisita: "/cardiovisitecontrolliecg/last",
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      // optionsConclusioni: [{ text: "Selezione di test", value: "Selezione di test" }],
      // optionsSostenuta: [{ text: "Selezione di test", value: "Selezione di test" }],
      // optionsTipologia: [{ text: "Selezione di test", value: "Selezione di test" }],
      // optionsMorfologia: [{ text: "Selezione di test", value: "Selezione di test" }],
      // optionsSedeStimolazione: [{ text: "Selezione di test", value: "Selezione di test" }],
      // optionsFarmacologia: [{ text: "Selezione di test", value: "Selezione di test" }],
      // optionsInterruzione: [{ text: "Selezione di test", value: "Selezione di test" }],
      // optionsEseguito: [{ text: "Selezione di test", value: "Selezione di test" }],
      // optionsEfficace: [{ text: "Selezione di test", value: "Selezione di test" }],
      optionsPresente: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPresent"), value: "patient.cardiology.Options.lblPresent" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblAbsent"), value: "patient.cardiology.Options.lblAbsent" },
      ],
      optionsEpatite: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPositive"), value: "patient.cardiology.Options.lblPositive" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNegative"), value: "patient.cardiology.Options.lblNegative" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.getLastVisita();
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
    getLastVisita() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceLastVisita + "/" + me.idPaziente;
      axios.get(link).then((response) => {
        me.isUltimaVisita = true;
        me.dataUltimaVisita = response.data.data.dataEvento;
        me.jsonData.sesso = response.data.data.sessoAnagrafica;
        me.jsonData.eta = UtilityMixin.methods.calcoloEtaYearOnly(response.data.data.dataNascitaAnagrafica, new Date(response.data.data.dataEvento)).toString();
        me.jsonData.peso = response.data.data.peso;
      });
    },
    onKeyPressEnterCalcoli() {
      let me = this;
      me.showModalLoading = false;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/calcola";
      axios
        .post(link, me.jsonData)
        .then((response) => {
          me.jsonData.ldl = "";

          me.jsonData.clearance = "";
          me.jsonData.ldl = response.data.data.ldl;
          me.jsonData.trigliceridi = 0;
          me.jsonData.trigliceridi = response.data.data.trigliceridi;

          me.jsonData.creatininemia = 0;
          me.jsonData.creatininemia = response.data.data.creatininemia;
          me.jsonData.clearance = response.data.data.clearance;

          me.jsonData.hba1cPerc = 0;
          me.jsonData.hba1cPerc = response.data.data.hba1cPerc;
          let hba1cMmol = parseFloat(response.data.data.hba1cMmol.replace(/,/g, "."));
          me.jsonData.hba1cMmol = Math.round(hba1cMmol);

          me.showModalLoading = false;
        })
        .catch((error) => {
          me.$bvToast.toast(error.response.data.messaggio, {
            title: "",
            variant: "danger",
            autoHideDelay: 4500,
            solid: true,
          });
        });
    },
  },
};
</script>
<style scoped>
.alert-danger {
  text-align: center;
  color: red !important;
  border-color: red;
  background-color: white;
}
.ft-space {
  margin-top: 10px;
}
</style>
