<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <test-provocativi-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></test-provocativi-edit-component>
      </b-col>
    </b-row>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Conclusioni</label>
          <b-form-select v-model="jsonData.conclusioniErgometria" :options="optionsConclusioni">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
          <b-form-textarea v-model="jsonData.noteConclusiveErgometria" rows="5" max-rows="5" no-resize> </b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import TestProvocativiEditComponent from "../../components/TestProvocativiEditComponent.vue";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker, TestProvocativiEditComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },

  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardiotestprovocativi",
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      optionsTest: [
        { text: this.getLabelTraduora("patient.cardiology.Options.Normal"), value: "patient.cardiology.Options.Normal" },
        { text: this.getLabelTraduora("patient.cardiology.Options.Abnormal"), value: "patient.cardiology.Options.Abnormal" },
        { text: this.getLabelTraduora("patient.cardiology.Options.MaximalTest"), value: "patient.cardiology.Options.MaximalTest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.SubMaximalTest"), value: "patient.cardiology.Options.SubMaximalTest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.StopAngina"), value: "patient.cardiology.Options.StopAngina" },
        { text: this.getLabelTraduora("patient.cardiology.Options.StopMuscleExhaustation"), value: "patient.cardiology.Options.StopMuscleExhaustation" },
        { text: this.getLabelTraduora("patient.cardiology.Options.Other"), value: "patient.cardiology.Options.Other" },
      ],
      optionsConclusioni: [
        { text: this.getLabelTraduora("patient.cardiology.Options.NegativeCadTest"), value: "patient.cardiology.Options.NegativeCadTest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.PositiveCadTest"), value: "patient.cardiology.Options.PositiveCadTest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.MaximalTest"), value: "patient.cardiology.Options.MaximalTest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.SubMaximalTest"), value: "patient.cardiology.Options.SubMaximalTest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.NotDiagnosticTest"), value: "patient.cardiology.Options.NotDiagnosticTest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.TherapyTest"), value: "patient.cardiology.Options.TherapyTest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.DoubtfulTest"), value: "patient.cardiology.Options.DoubtfulTest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.BRUCE"), value: "patient.cardiology.Options.BRUCE" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
  },
};
</script>

<style></style>
