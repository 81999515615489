<template>
  <b-modal id="modalInfo" class="modal-content" title="Informazioni" size="lg" slot="modal-header" no-close-on-backdrop no-close-on-esc cancel-title="Chiudi" ok-title="OK" scrollable>
    <table class="table">
      <tbody>
        <tr>
          <th>Sesso</th>
          <th>Agonisti</th>
          <th>Non Agonisti</th>
          <th>Totali</th>
        </tr>
        <tr>
          <th>M</th>
          <td>{{ this.listaMaschi }}</td>
          <td>{{ this.listaMaschiNonAgo }}</td>
          <td>{{ this.listaMaschi + this.listaMaschiNonAgo }}</td>
        </tr>
        <tr>
          <th>F</th>
          <td>{{ this.listaFemmine }}</td>
          <td>{{ this.listaFemmineNonAgo }}</td>
          <td>{{ this.listaFemmine + this.listaFemmineNonAgo }}</td>
        </tr>
        <!-- <tr>
          <th>Annullati</th>
          <td></td>
          <td></td>
          <td></td>
        </tr> -->
        <tr>
          <th>Totali</th>
          <td>{{ this.listaMaschi + this.listaFemmine }}</td>
          <td>{{ this.listaMaschiNonAgo + listaFemmineNonAgo }}</td>
          <td>{{ this.listaMaschi + this.listaFemmine + this.listaMaschiNonAgo + listaFemmineNonAgo }}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <table class="table">
      <tbody>
        <tr>
          <th>Età</th>
          <th>Agonisti</th>
          <th>Non Agonisti</th>
          <th>Totali</th>
        </tr>
        <tr>
          <th>Maggiorenni</th>
          <td>{{ this.listaAgonistiMagg }}</td>
          <td>{{ this.listaNonAgonistiMagg }}</td>
          <td>{{ this.listaAgonistiMagg + this.listaNonAgonistiMagg }}</td>
        </tr>
        <tr>
          <th>Minorenni</th>
          <td>{{ this.listaAgonistiMin }}</td>
          <td>{{ this.listaNonAgonistiMin }}</td>
          <td>{{ this.listaNonAgonistiMin + this.listaAgonistiMin }}</td>
        </tr>
        <!-- <tr>
          <th>Annullati</th>
          <td>5</td>
          <td>5</td>
          <td>5</td>
        </tr> -->
        <tr>
          <th>Totali</th>
          <td>{{ this.listaAgonistiMagg + this.listaAgonistiMin }}</td>
          <td>{{ this.listaNonAgonistiMagg + this.listaNonAgonistiMin }}</td>
          <td>{{ this.listaAgonistiMagg + this.listaAgonistiMin + this.listaNonAgonistiMagg + this.listaNonAgonistiMin }}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <table class="table">
      <tbody>
        <tr>
          <th>Tipo</th>
          <th>Accettati</th>
          <th>Da accettare</th>
          <th>Totali</th>
        </tr>
        <tr>
          <th>Normale</th>
          <td>{{ this.listaAccettazioni.length }}</td>
          <td>{{ this.listaPrenotazioniLeonida.length }}</td>
          <td>{{ this.listaAccettazioni.length + this.listaPrenotazioniLeonida.length }}</td>
        </tr>
        <tr>
          <th>Annullati</th>
          <td>0</td>
          <td>0</td>
          <td>{{ this.listaArchiviati }}</td>
        </tr>
        <tr>
          <th>Totali</th>
          <td>{{ this.listaAccettazioni.length + this.listaArchiviati }}</td>
          <td>{{ this.listaPrenotazioniLeonida.length }}</td>
          <td>{{ this.listaArchiviati + this.listaAccettazioni.length + this.listaPrenotazioniLeonida.length }}</td>
        </tr>
      </tbody>
    </table>
  </b-modal>
</template>

<script>
export default {
  props: {
    listaMaschi: Number,
    listaFemmine: Number,
    listaMaschiNonAgo: Number,
    listaFemmineNonAgo: Number,
    listaAgonistiMagg: Number,
    listaAgonistiMin: Number,
    listaNonAgonistiMin: Number,
    listaNonAgonistiMagg: Number,
    listaAccettazioni: Array,
    listaPrenotazioniLeonida: Array,
    listaArchiviati: Number,
  },
  data() {
    return {
      fieldsInfoPazienti: [
        {
          label: "Sesso",
          key: "sesso",
        },
        {
          label: "Agonisti",
          key: "agonisti",
        },
        {
          label: "Non Agonisti",
          key: "nonAgonisti",
        },
        {
          label: "Totali",
          key: "totali",
        },
      ],
      items: [
        {
          sesso: "",
          agonisti: "",
          nonAgonisti: "",
        },
      ],
    };
  },
};
</script>
