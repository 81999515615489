<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :data="jsonData" :linkback="linkback" :btnBackVisible="true" :btnSaveVisible="btnSaveVisible" :btnSaveDisabled="btnDisabled" :linkSaveAndClose="linkSaveAndClose" :labelSaveAndClose="labelSaveAndClose" class="sa-no-space">
    <template slot="toolbar">
      <b-button v-if="btnDisabled" variant="outline-success btn-toolbar sa-margin-left float-sm-left" @click="onAvanti">
        <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
        Avanti
      </b-button>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <!-- <b-row>
                    <b-col>
                        <h5 class="sa-text-left">{{ getLabelTraduora("adi.lblPatientData") }}</h5>
                    </b-col>
                </b-row> -->
        <b-card class="sa-card" :header="getLabelTraduora('adi.lblPatientData')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblFiscalCode") }}</label>
              <!-- <span v-if="jsonData.codiceFiscale !== ''">{{ jsonData.codiceFiscale }}</span> -->
              <b-input-group>
                <b-form-input maxlength="16" v-model="jsonData.paziente.identificativo" @keyup.enter="onSearchAnagraficaClick()" style="text-transform: uppercase" :disabled="!editDimissioniProtette"></b-form-input>
                <b-input-group-append>
                  <b-button @click="onSearchAnagraficaClick()" :disabled="!codiceFiscaleValido || !editDimissioniProtette"><b-icon icon="search"></b-icon></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblFirstName") }}</label>
              <span class="sa-data">{{ jsonData.paziente.nome }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblSecondName") }}</label>
              <span class="sa-data">{{ jsonData.paziente.cognome }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblBirthPlace") }}</label>
              <span class="sa-data">{{ jsonData.paziente.luogoNascita }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblDateOfBirth") }}</label>
              <span class="sa-data">{{ formatDate(jsonData.paziente.dataNascita) }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblResidence") }}</label>
              <span class="sa-data">{{ jsonData.paziente.comuneResidenza }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblAddress") }}</label>
              <span class="sa-data">{{ jsonData.paziente.indirizzoResidenza }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblGender") }}</label>
              <span class="sa-data">{{ jsonData.paziente.sesso }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblHealthInsuranceCard") }}</label>
              <span class="sa-data">{{ jsonData.tesseraSanitaria }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblDoctor") }}</label>
              <span class="sa-data">{{ jsonData.paziente.medicoCurante }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblDoctorContacts") }}</label>
              <b-form-input v-model="jsonData.contattiMmg" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblTicketExemptionCode") }}</label>
              <b-form-input v-model="jsonData.codiceEsenzioneTicket" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Cap Nascita</label>
              <b-form-input v-model="jsonData.paziente.capNascita" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Cap Domicilio</label>
              <b-form-input v-model="jsonData.paziente.capDomicilio" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Cap Residenza</label>
              <b-form-input v-model="jsonData.paziente.capResidenza" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Email</label>
              <b-form-input v-model="jsonData.paziente.email" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblNationality") }}</label>
              <b-form-input v-model="jsonData.nazionalita" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblCitizenship") }}</label>
              <b-form-input v-model="jsonData.cittadinanza" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblNation") }}</label>
              <b-form-input v-model="jsonData.nazioneResidenza" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblRegion") }}</label>
              <b-form-input v-model="jsonData.regioneResidenza" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblPhone") }}</label>
              <b-form-input v-model="jsonData.paziente.telefono" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblHospitalizationWard") }}</label>
              <b-form-input v-model="jsonData.repartoRicovero" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <!-- </b-row>
          <b-row> -->
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblCSST") }}</label>
              <b-form-input v-model="jsonData.csst" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblSocialWorker") }}</label>
              <b-form-input v-model="jsonData.assistenteSociale" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" lg="12" md="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblReceptionType") }}</label>
              <b-form-select v-model="jsonData.tipoAccoglienza" :options="tipiAccoglienzaOptions" :value="null" value-field="value" text-field="text" :disabled="!editDimissioniProtette"></b-form-select>
            </b-col>
          </b-row>
          <b-row class="mt-4">
            <b-col xs="12" sm="12" lg="12" md="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblOther") }}</label>
              <b-form-textarea v-model="jsonData.altro" class="text-upper" no-resize :disabled="!editDimissioniProtette"></b-form-textarea>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('adi.lblDeclare')" header-tag="header" footer-tag="footer" title="">
          <!-- <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <h5>{{ getLabelTraduora("adi.lblDeclare") }}</h5>
          </b-col>
        </b-row> -->
          <b-row>
            <b-col v-for="(dichiarazione, index) in dichiarazioniOptions" :key="index" xs="12" sm="12" md="12" lg="12" xl="12">
              <!-- v-model="dichiarazione.rispostaSelezionata" -->
              <b-form-checkbox :checked="getChkDichiarazione(jsonData, dichiarazione)" size="md" class="sa-normal-label" @change="onCheckboxChange(dichiarazione, $event)" :disabled="!editDimissioniProtette">{{ dichiarazione.text }}</b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <h5>{{ getLabelTraduora("adi.lblConsents") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-data">{{ consenso1 }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <span class="sa-data">{{ consenso2 }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblFirstName") }}</label>
              <b-form-input v-model="jsonData.nomePersonaRiferimento" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblSecondName") }}</label>
              <b-form-input v-model="jsonData.cognomePersonaRiferimento" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblRole") }}</label>
              <b-form-input v-model="jsonData.ruoloPersonaRiferimento" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblResidence") }}</label>
              <b-form-input v-model="jsonData.residenzaPersonaRiferimento" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblAddress") }}</label>
              <b-form-input v-model="jsonData.indirizzoPersonaRiferimento" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblStreetNumber") }}</label>
              <b-form-input v-model="jsonData.civicoPersonaRiferimento" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblPhone") }}</label>
              <b-form-input v-model="jsonData.telefonoPersonaRiferimento" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblMobilePhone") }}</label>
              <b-form-input v-model="jsonData.telefonoCellularePersonaRiferimento" class="text-upper" :disabled="!editDimissioniProtette"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="mt-4">
              <b-form-checkbox v-model="jsonData.consenso">
                <span class="sa-data">{{ getLabelTraduora("adi.consentsAccepted") }}</span>
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout },
  data() {
    return {
      linkback: null,
      gruppo: "ADI",
      tipoEvento: "DIMISSIONI PROTETTE",
      linkTipiAccoglienza: "/adidimissioniprotettetipoaccoglienza",
      linkDichiarazioni: "/adidimissioniprotettedichiarazioni",
      pathResource: "/adidimissioniprotette",
      id: "-1",
      labelSaveAndClose: "global.lblBtnNextPage",
      linkSaveAndClose: null,
      btnDisabled: true,
      btnSaveVisible: true,
      editDimissioniProtette: true,
      sezioneDimissioniProtette: "adidimissioniprotette",
      jsonData: {
        gruppo: "ADI",
        tipoEvento: "DIMISSIONI PROTETTE",
        codiceFiscale: "",
        repartoRicovero: "",
        tipoAccoglienza: null,
        altro: "",
        nomePersonaRiferimento: "",
        cognomePersonaRiferimento: "",
        ruoloPersonaRiferimento: "",
        residenzaPersonaRiferimento: "",
        indirizzoPersonaRiferimento: "",
        civicoPersonaRiferimento: "",
        telefonoPersonaRiferimento: "",
        telefonoCellularePersonaRiferimento: "",
        consenso: false,
        adiDimissioneProtettaAdiDimissioneProtettaDichiarazione: [],
        paziente: { identificativo: "" },
      },
      rispostaSelezionata: false,
      showModalLoading: false,
      tipiAccoglienzaOptions: [{ value: null, text: "-Seleziona Valore-", disabled: true }],
      dichiarazioniOptions: [],
      consenso1: "Il/La sottoscritto/a  consente che i propri dati anagrafici e/o sensibili alla conclusione del procedimento (Medico di Medicina Generale, Case di Riposo, Comuni o altri Enti pubblici, altre Aziende Sanitarie…) e per gli adempimenti di legge.",
      consenso2: "Il consenso di cui sopra, ai sensi dell’art. 13 e dell’art. 24, comma e) del D.lgs. n. 196/2003 e succ.mod. è reso dal sig./ra",
      codiceFiscaleValido: true,
    };
  },
  computed: {},
  watch: {
    jsonData: {
      handler() {
        let me = this;
        me.btnDisabled = !(me.jsonData.adiDimissioneProtettaAdiDimissioneProtettaDichiarazione.length === 6 && me.jsonData.consenso && me.editDimissioniProtette);
      },
      deep: true,
    },
    "jsonData.paziente.identificativo": function (value) {
      var regexCodiceFiscale = /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
      if (regexCodiceFiscale.test(value.toUpperCase())) {
        this.codiceFiscaleValido = true;
      } else this.codiceFiscaleValido = false;
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  mounted() {
    let me = this;
    me.linkback = "/adi";
    me.id = this.$route.params.id;
    // me.linkSaveAndClose = "/sezioneclinica/edit/-1";
    me.linkSaveAndClose = "/adi/sezioneclinica/edit/{id}";
    me.loadDefaultData();
    me.impostaPermessi();
    me.loadData();
  },
  methods: {
    onAvanti() {
      let me = this;
      // console.log(me.linkSaveAndClose.replace("{id}", me.id));
      me.$router.replace(me.linkSaveAndClose.replace("{id}", me.id)).catch((err) => {
        err;
      });
    },
    impostaPermessi() {
      let me = this;
      me.editDimissioniProtette = UtilityMixin.methods.verificaPermessi(me.utente, me.sezioneDimissioniProtette, 2) || UtilityMixin.methods.verificaPermessi(me.utente, me.sezioneDimissioniProtette, 4);
      me.btnSaveVisible = UtilityMixin.methods.verificaPermessi(me.utente, me.sezioneDimissioniProtette, 2) || UtilityMixin.methods.verificaPermessi(me.utente, me.sezioneDimissioniProtette, 4);
    },
    getChkDichiarazione(jsonObject, descrizione) {
      let returnValue = false;
      jsonObject.adiDimissioneProtettaAdiDimissioneProtettaDichiarazione.forEach((element) => {
        if (element.descrizioneAdiDimissioneProtettaDichiarazione === descrizione.text) {
          returnValue = true;
        }
      });
      return returnValue;
    },
    loadDefaultData() {
      let me = this;
      me.loadTipiAccoglienza();
      me.loadDichiarazioni();
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
          })
          .catch((error) => {
            this.$bvModal
              .msgBoxOk(link + " \n" + error.message, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then(() => {})
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
    loadTipiAccoglienza() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkTipiAccoglienza + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.tipiAccoglienzaOptions.push({ value: element.descrizione, text: element.descrizione });
          });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadDichiarazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkDichiarazioni + "?page=1&forPage=50";
      axios
        .get(link)
        .then((response) => {
          response.data.data.list.forEach((element) => {
            me.dichiarazioniOptions.push({ value: element.descrizione, text: element.descrizione });
          });
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onCheckboxChange(value, newValue) {
      let me = this;
      if (newValue) {
        me.jsonData.adiDimissioneProtettaAdiDimissioneProtettaDichiarazione.push({ descrizioneAdiDimissioneProtettaDichiarazione: value.text });
      } else {
        const indexOf = me.jsonData.adiDimissioneProtettaAdiDimissioneProtettaDichiarazione.findIndex((element) => element.descrizioneAdiDimissioneProtettaDichiarazione === value.text);
        me.jsonData.adiDimissioneProtettaAdiDimissioneProtettaDichiarazione.splice(indexOf, 1);
      }
      console.log(me.jsonData.adiDimissioneProtettaAdiDimissioneProtettaDichiarazione);
    },
    nextPage() {
      let me = this;
      me.$router.push("/sezioneclinica/edit/" + me.jsonData.id);
    },
    onSearchAnagraficaClick() {
      let me = this;
      if (!me.codiceFiscaleValido)
        return me.$bvModal.msgBoxOk("Codice Fiscale non corretto", {
          title: [this.$i18n.t("adi.lblError")],
          size: "sm",
          okVariant: "outline-danger",
          headerClass: "sa-msg-header-danger",
          footerClass: "p-2 border-top-0",
          centered: true,
        });
      me.showModalLoading = true;
      me.jsonData.paziente.identificativo = me.jsonData.paziente.identificativo !== "" ? me.jsonData.paziente.identificativo.toUpperCase() : me.jsonData.paziente.identificativo;
      let link = process.env.VUE_APP_PATH_API + "/utility";

      axios
        .get(link, { params: { codiceFiscale: me.jsonData.paziente.identificativo } })
        .then((response) => {
          // me.response = response.data.data[0];
          // me.jsonData = me.response;
          console.log(response.data.data);
          me.jsonData.paziente.nome = response.data.data.nome;
          me.jsonData.paziente.cognome = response.data.data.cognome;
          me.jsonData.paziente.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY").toDate().getTime();
          me.jsonData.paziente.sesso = response.data.data.sesso;

          me.jsonData.paziente.comuneNascita = response.data.data.comuneNascita;
          me.jsonData.paziente.comuneResidenza = response.data.data.comuneResidenza;
          me.jsonData.paziente.indirizzoResidenza = response.data.data.indirizzoResidenza;
          me.jsonData.paziente.indirizzoDomicilio = "";
          me.jsonData.paziente.identificativo = response.data.data.codiceFiscale;
          me.jsonData.paziente.medicoCurante = response.data.data.medico;
          me.jsonData.tesseraSanitaria = response.data.data.numeroTesseraSanitaria;
          me.jsonData.asl = response.data.data.aslDiResidenza;
          me.jsonData.paziente.comuneNascitaCodiceIstat = response.data.data.codiceIstatComuneNascita;
          me.jsonData.paziente.comuneResidenzaCodiceIstat = response.data.data.codiceIstatComuneResidenza;
          me.jsonData.paziente.comuneDomicilioCodiceIstat = "";
          me.jsonData.paziente.capNascita = "";
          me.jsonData.paziente.capDomicilio = "";
          me.jsonData.paziente.capResidenza = "";

          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    setSesso(value) {
      if (value === "M") {
        return "MASCHIO";
      } else {
        return "FEMMINA";
      }
    },

    onSave() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      if (me.jsonData.id === "-1" || me.jsonData.id === null || me.jsonData.id === undefined) {
        axios
          .post(link, JSON.stringify(me.jsonData))
          .then((response) => {
            console.log(me.jsonData);
            me.id = response.data.data.id;
            me.showModalLoading = false;
            me.nextPage();
          })
          .catch((error) => {
            me.showModalLoading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        link = link + "/" + me.jsonData.id;
        axios
          .put(link, JSON.stringify(me.jsonData))
          .then(() => {
            me.showModalLoading = false;
            me.nextPage();
          })
          .catch((error) => {
            console.log(error);
            me.showModalLoading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                console.log(value);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
  },
};
</script>
