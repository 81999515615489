<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
          <b-form-input v-model="jsonData.peso" type="number" placeholer="Kg"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
          <b-form-input v-model="jsonData.altezza" type="number" placeholer="cm"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
          <b-form-input v-model="jsonData.supCorporea" type="number" placeholer="Kg/m2"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSpecilisticUnit") }}</label>
          <b-form-select v-model="jsonData.unitaSpecialistica" :options="optionsUnitaSpecial">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArchive") }}</label>
          <b-form-select v-model="jsonData.archivio" :options="optionsArchivio">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArchiveNum") }}</label>
          <b-form-input v-model="jsonData.narchivio" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQuality") }}</label>
          <b-form-select v-model="jsonData.qualita" :options="optionsQualita">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDevice") }}</label>
          <b-form-select v-model="jsonData.apparecchio" :options="optionsApparecchio">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProbe") }}</label>
          <!-- <b-form-select v-model="jsonData.sonda" :options="optionsSonda">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select> -->
          <b-form-input v-model="jsonData.sonda" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIP") }}</label>
          <b-form-input v-model="jsonData.ip" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnesthetistAssistance") }}</label>
          <b-form-select v-model="jsonData.assAnest" :options="optionsAnestesista">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLocalPre-AnesthetistEvaluation')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRhythm") }}</label>
          <b-form-select v-model="jsonData.ritmo" :options="optionsRitmo">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFC") }}</label>
          <b-form-input v-model="jsonData.fc" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBloodPressure") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <b-form-input v-model="jsonData.pas" type="number" placeholder="max"> </b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <b-form-input v-model="jsonData.pad" type="number" placeholder="min"> </b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOxigenSaturation") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <b-form-input v-model="jsonData.satO2" type="number" placeholder="%"> </b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <b-form-select v-model="jsonData.satO2Unit" :options="optionsO2">
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    {{ firstElementSelect }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="11" md="11" lg="11" xl="11">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPremedication") }}</label>
          <b-form-select v-model="jsonData.premedicazione" :options="optionsPremedicazione">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMidazolam") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <b-form-select v-model="jsonData.midazolam" :options="optionsConferma">
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    {{ firstElementSelect }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <b-form-input v-model="jsonData.doseMidazolam" type="number" placeholder="mg"> </b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnexate") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <b-form-select v-model="jsonData.anexate" :options="optionsConferma">
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    {{ firstElementSelect }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <b-form-input v-model="jsonData.doseAnexate" type="number" placeholder="mg"> </b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSprayLidocaine") }}</label>
          <b-form-select v-model="jsonData.lidocainaSpray" :options="optionsLicodaina">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="8" md="8" lg="8" xl="8">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOtherDrugs") }}</label>
          <b-form-input v-model="jsonData.altriFarmaci" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
          <b-form-textarea v-model="jsonData.complicanze" rows="5" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRequester") }}</label>
          <b-form-input v-model="jsonData.richiedente" type="text"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDepartment") }}</label>
          <b-form-select v-model="jsonData.reparto" :options="optionsReparto">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRecovery") }}</label>
          <b-form-select v-model="jsonData.ricovero" :options="optionsRicovero">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndications") }}</label>
          <b-form-textarea v-model="jsonData.motivoRicovero" rows="5" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLeftAtriumAndAuricle')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftAtriumDiameter") }}</label>
          <b-form-input v-model="jsonData.diametroML" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftAuricleArea") }}</label>
          <b-form-input v-model="jsonData.areaAuricolaSin" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxOutflowSpeed") }}</label>
          <b-form-input v-model="jsonData.vmaxEfflusso" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSpeedPattern") }}</label>
          <b-form-select v-model="jsonData.patternVelocita" :options="optionsVelocita">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAorticValve')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRingDiameter") }}</label>
          <b-form-input v-model="jsonData.diametroAnelloVa" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsufficiency") }}</label>
          <b-form-select v-model="jsonData.insufficienzaVa" :options="optionsInsufficienza">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOutflowSectionDiameter") }}</label>
          <b-form-input v-model="jsonData.diametroTrattoEfflusso" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPlanimetricArea") }}</label>
          <b-form-input v-model="jsonData.areaPlanimetricaVa" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProssimalJetWidth") }}</label>
          <b-form-input v-model="jsonData.ampiezzaProssJetVa" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProssimalJetTrOutflow") }}</label>
          <b-form-input v-model="jsonData.jetProssimaleTrVa" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblMitralValve')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
          <b-form-textarea v-model="jsonData.valvolaMitrale" rows="5" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="8" md="8" lg="8" xl="8">
          <b-row>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRingDiameter4Cam") }}</label>
              <b-form-input v-model="jsonData.diametroAnello4camVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConvergenceArea") }}</label>
              <b-form-input v-model="jsonData.rareaConvergenzaVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLongAxisRingDiameter") }}</label>
              <b-form-input v-model="jsonData.diametroAnelloAsseLVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxRegurgitatingSpeed") }}</label>
              <b-form-input v-model="jsonData.velRigMaxVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPlanimetricArea") }}</label>
              <b-form-input v-model="jsonData.areaPlanimetricaVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxEroa") }}</label>
              <b-form-input v-model="jsonData.eroaMaxVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAverageDiastolicGradient") }}</label>
              <b-form-input v-model="jsonData.gradDiastMedVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTentingArea") }}</label>
              <b-form-input v-model="jsonData.areaTentingVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsufficiency") }}</label>
              <b-form-select v-model="jsonData.insufficienzaVm" :options="optionsInsufficienza">
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    {{ firstElementSelect }}
                  </b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCoaptationDistance") }}</label>
              <b-form-input v-model="jsonData.distanzaCoaptazioneVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblJetDirection") }}</label>
              <!-- <b-form-select v-model="jsonData.direzioneJetVm" :options="optionsDirezione">
                <template #first>
                  <b-form-select-option :value="null" disabled>
                    {{ firstElementSelect }}
                  </b-form-select-option>
                </template>
              </b-form-select> -->
              <b-form-input v-model="jsonData.direzioneJetVm" type="text"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFrontFlapThickness") }}</label>
              <b-form-input v-model="jsonData.spessLemboAntVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProssimalJetWidth") }}</label>
              <b-form-input v-model="jsonData.ampiezzaProssJetVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBackFlapThickness") }}</label>
              <b-form-input v-model="jsonData.spessLemboPostVm" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAliasing") }}</label>
              <b-form-input v-model="jsonData.velAliasingVm" type="number"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <h5 class="sa-text-left">{{ getLabelTraduora("patient.cardiology.lblProlapseEvaluation") }}</h5>
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblA1") }}</label>
              <b-form-checkbox v-model="jsonData.a1" switch :checked="jsonData.a1"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblA2") }}</label>
              <b-form-checkbox v-model="jsonData.a2" switch :checked="jsonData.a2"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblA3") }}</label>
              <b-form-checkbox v-model="jsonData.a3" switch :checked="jsonData.a3"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblP1") }}</label>
              <b-form-checkbox v-model="jsonData.p1" switch :checked="jsonData.p1"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblP2") }}</label>
              <b-form-checkbox v-model="jsonData.p2" switch :checked="jsonData.p2"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblP3") }}</label>
              <b-form-checkbox v-model="jsonData.p3" switch :checked="jsonData.p3"></b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAorta')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBulb") }}</label>
          <b-form-input v-model="jsonData.bulbo" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSTGiunction") }}</label>
          <b-form-input v-model="jsonData.giunzioneSenoTub" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAscendingSection") }}</label>
          <b-form-input v-model="jsonData.aortaAscendente" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescendingSection") }}</label>
          <b-form-input v-model="jsonData.aortaDiscendente" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
          <b-form-textarea v-model="jsonData.conclusioni" rows="5" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },
  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardioecotransesofageo",
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      optionsRitmo: [
        { text: "SINUSALE", value: "SINUSALE" },
        { text: "FLUTTER ATRIALE", value: "FLUTTER ATRIALE" },
        { text: "FIBRILLAZIONE ATRIALE", value: "FIBRILLAZIONE ATRIALE" },
        { text: "GIUNZIONALE", value: "GIUNZIONALE" },
        { text: "ELE VVI", value: "ELE VVI" },
        { text: "ELE DDD", value: "ELE DDD" },
      ],
      optionsUnitaSpecial: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblALP"), value: "patient.cardiology.Options.lblALP" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblMedicArea"), value: "patient.cardiology.Options.lblMedicArea" },
      ],
      optionsArchivio: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblServer"), value: "patient.cardiology.Options.lblServer" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblVCR"), value: "patient.cardiology.Options.lblVCR" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblMO"), value: "patient.cardiology.Options.lblMO" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblVCRMO"), value: "patient.cardiology.Options.lblVCRMO" },
      ],
      optionsQualita: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblGood"), value: "patient.cardiology.Options.lblGood" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblAdeguate"), value: "patient.cardiology.Options.lblAdeguate" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPoor"), value: "patient.cardiology.Options.lblPoor" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblInadeguate"), value: "patient.cardiology.Options.lblInadeguate" },
      ],
      optionsApparecchio: [
        { text: "HP SONOS 5500", value: "HP SONOS 5500" },
        { text: "GE VIVID 7", value: "GE VIVID 7" },
      ],
      optionsAnestesista: [
        { text: "SI", value: "SI" },
        { text: "NO", value: "NO" },
      ],
      optionsO2: [
        { text: "AA", value: "AA" },
        { text: "O2", value: "O2" },
      ],
      optionsPremedicazione: [
        { text: "NESSUNA", value: "NESSUNA" },
        { text: "BUSCOPAN 1 FL IM", value: "BUSCOPAN 1 FL IM" },
        { text: "PLASIL 1 FL IM", value: "PLASIL 1 FL IM" },
        { text: "ANSIOLIN 10 GTT", value: "ANSIOLIN 10 GTT" },
        { text: "BUSCOPAN 1 FL IM, PLASIL 1 FL IM", value: "BUSCOPAN 1 FL IM, PLASIL 1 FL IM" },
        { text: "BUSCOPAN 1 FL IM, PLASIL 1 FL IM, ANSIOLIN 10 GTT", value: "BUSCOPAN 1 FL IM, PLASIL 1 FL IM, ANSIOLIN 10 GTT" },
      ],
      optionsConferma: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblYes"), value: "patient.cardiology.Options.lblYes" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
      ],
      optionsLicodaina: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblTwoPhases"), value: "patient.cardiology.Options.lblTwoPhases" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblThreePhases"), value: "patient.cardiology.Options.lblThreePhases" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblFourPhases"), value: "patient.cardiology.Options.lblFourPhases" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblFibrillatory"), value: "patient.cardiology.Options.lblFibrillatory" },
      ],
      optionsReparto: [{ text: "Select di test", value: "Select di test" }],
      optionsRicovero: [{ text: "Select di test", value: "Select di test" }],
      optionsVelocita: [{ text: "Select di test", value: "Select di test" }],
      optionsInsufficienza: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPlus4One"), value: "patient.cardiology.Options.lblPlus4One" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPlus4Two"), value: "patient.cardiology.Options.lblPlus4Two" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPlus4Three"), value: "patient.cardiology.Options.lblPlus4Three" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPlus4Four"), value: "patient.cardiology.Options.lblPlus4Four" },
      ],
      optionsDirezione: [{ text: "Select di test", value: "Select di test" }],
      optionsSonda: [{ text: "Select di test", value: "Select di test" }],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onCalcoloBmiBsa(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
      me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
