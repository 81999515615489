<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblOrmonalExams')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTSH") }}</label>
          <b-form-input v-model="jsonData.tsh" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFT3") }}</label>
          <b-form-input v-model="jsonData.ft3" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFT4") }}</label>
          <b-form-input v-model="jsonData.ft4" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anticorpi Anti-TG</label>
          <b-form-input v-model="jsonData.anticorpiAntiTg" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anticorpi Anti-TPO</label>
          <b-form-input v-model="jsonData.anticorpiAntiTpo" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMA") }}</label>
          <b-form-input v-model="jsonData.vma" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinaryMetanephrines") }}</label>
          <b-form-input v-model="jsonData.metanefrineUrinarie" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblACTH") }}</label>
          <b-form-input v-model="jsonData.acth" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoNoradrenaline") }}</label>
          <b-form-input v-model="jsonData.noradrenalinaorto" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblClinoNoradrenaline") }}</label>
          <b-form-input v-model="jsonData.noradrenalinaclino" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoAdrenaline") }}</label>
          <b-form-input v-model="jsonData.adrenalinaorto" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblClinoAdrenaline") }}</label>
          <b-form-input v-model="jsonData.adrenalinaclino" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinaryCatecolamine") }}</label>
          <b-form-input v-model="jsonData.catecolamine" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrynaryNormetanefrine") }}</label>
          <b-form-input v-model="jsonData.normetanefrine" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblOralGlucoseLoadCurve')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicGlycemia") }}</label>
          <b-form-input v-model="jsonData.glicemiaBase" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterOneHour") }}</label>
          <b-form-input v-model="jsonData.glicemia1h" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterTwoHours") }}</label>
          <b-form-input v-model="jsonData.glicemia2h" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicInsulinemia") }}</label>
          <b-form-input v-model="jsonData.insulinemiaBase" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterOneHour") }}</label>
          <b-form-input v-model="jsonData.insulinemia1h" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterTwoHours") }}</label>
          <b-form-input v-model="jsonData.insulinemia2h" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCaptorilTest')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicPRA") }}</label>
          <b-form-input v-model="jsonData.prabase" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoPRA") }}</label>
          <b-form-input v-model="jsonData.praorto" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterOneHour") }}</label>
          <b-form-input v-model="jsonData.pra1h" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicAldosterone") }}</label>
          <b-form-input v-model="jsonData.aldosteronebase" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoAldosterone") }}</label>
          <b-form-input v-model="jsonData.aldosteroneorto" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterOneHour") }}</label>
          <b-form-input v-model="jsonData.aldosterone1h" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicPlasmaticRenina") }}</label>
          <b-form-input v-model="jsonData.reninaPlasmaticaBase" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoPlasmaticRenina") }}</label>
          <b-form-input v-model="jsonData.reninaPlasmaticaOrto" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Sistolica Base</label>
          <b-form-input v-model="jsonData.pabasemax" type="number" placeholder="max"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Diastolica Base</label>
          <b-form-input v-model="jsonData.pabasemin" type="number" placeholder="min"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Sistolica Dopo 1h</label>
          <b-form-input v-model="jsonData.pa1hmax" type="number" placeholder="max"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Diastolica Dopo 1h</label>
          <b-form-input v-model="jsonData.pa1hmin" type="number" placeholder="min"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl24HoursSodiura") }}</label>
          <b-form-input v-model="jsonData.sodiuria24h" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl24HoursPotassiuria") }}</label>
          <b-form-input v-model="jsonData.potassiuria24h" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCaptorilTestResults") }}</label>
          <b-form-select v-model="jsonData.test" :options="optionsCaptorilTest">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEightHoursCortisolemia") }}</label>
          <b-form-input v-model="jsonData.cortisolemiaore8" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTwentyHoursCortisolemia") }}</label>
          <b-form-input v-model="jsonData.cortisolemiaore20" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCortisoluria") }}</label>
          <b-form-input v-model="jsonData.cortisoluria" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAldosteronuria") }}</label>
          <b-form-input v-model="jsonData.aldosteronuria" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return {
          dataEvento: null,
          test: null,
        };
      },
    },
  },

  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardioesamispecialistici",
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      optionsCaptorilTest: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPositive"), value: "patient.cardiology.Options.lblPositive" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNegative"), value: "patient.cardiology.Options.lblNegative" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onCalcoloBmiBsa(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
      me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>

<style></style>
