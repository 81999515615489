<template>
  <sa-page-layout :btnSaveVisible="btnSaveVisible" :btnPrintVisible="true" :btnBackVisible="true" :pathResource="pathResource" :showModalLoading="showModalLoading" :data="jsonData" :linkback="linkback" :linkPrintData="linkPrintData" class="sa-no-space">
    <template slot="toolbar-title">
      <adi-anagrafica-component :id="idPropostaAccesso"></adi-anagrafica-component>
    </template>
    <template slot="table-body">
      <adi-pai-cure-domiciliari-edit-component :jsonData="jsonData" @showmodalloading="onShowModalLoading" @update="onUpdateJsonData" :utente="utente"></adi-pai-cure-domiciliari-edit-component>
    </template>
  </sa-page-layout>
</template>
<script>
// import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import AdiAnagraficaComponent from "../components/AdiAnagraficaComponent.vue";
import AdiPaiCureDomiciliariEditComponent from "../components/AdiPaiCureDomiciliariEditComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { SaPageLayout, AdiAnagraficaComponent, AdiPaiCureDomiciliariEditComponent },
  data() {
    return {
      pathResource: "/adipaicuredomiciliari",
      linkback: "",
      linkPrintData: null,
      showModalLoading: false,
      backAfterSave: false,
      btnSaveVisible: false,
      id: "-1",
      index: 0,
      idPropostaAccesso: "",
      pathResourceStampa: null,
      key: 0,
      jsonData: {
        dataOra: null,
        utente: null,
        listaObiettiviUtente: [{ descrizione: "", personaObiettivo: "UTENTE" }],
        listaAzioniInterventiProgrammati: [{ tipologiaPrestazione: "", numeroAccessi: "", oreDiePerGiorniSettimane: "" }],
        listaPianoTerapeutico: [{ descrizione: "" }],
        listaUvi: [{ titolo: "", nomeCognome: "" }],
      },
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    let fonte = this.$route.query.fonte;
    me.idPropostaAccesso = this.$route.query.idPropostaAccesso;
    let path = this.$route.path.replace(/\/edit\/.*/, "");
    me.linkNotBack = path + "{editid}?";
    me.linkPrintData = me.pathResource + "/print/" + me.id;
    me.linkback = "/adipazienti/richieste/scheda/edit/" + me.idPropostaAccesso + "?fonte=" + fonte;
    me.loadPermessi();
  },
  methods: {
    onShowModalLoading(value) {
      let me = this;
      me.showModalLoading = value;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    loadPermessi() {
      let me = this;
      me.btnSaveVisible = UtilityMixin.methods.verificaPermessi(me.utente, "adipaicuredomiciliari", 2) || UtilityMixin.methods.verificaPermessi(me.utente, "adipaicuredomiciliari", 4);
    },
  },
};
</script>
