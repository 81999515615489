<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblDateVisit") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataAppuntamento" :disabled="!edit" type="datetime"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSportPracticed") }}</label>
                    <b-form-select v-model="data.sportPraticato" :disabled="!edit" :options="tipiSportOptions" value-field="codiceSport" text-field="codiceSport">
                        <template #first>
                            <b-form-select-option :value="null" disabled>- Seleziona Sport Praticato -</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblFollowingVisit") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.visitaSuccessiva" :disabled="!edit" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblYearsNumber") }}</label>
                    <b-form-input v-model="etaPaziente" :disabled="true"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSpeciality") }}</label>
                    <b-form-input v-model="data.specialita" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblFederation") }}</label>
                    <b-form-input v-model="data.federazione" :disabled="true"></b-form-input>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblOtherSports") }}</label>
                    <b-form-input v-model="data.altriSport" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patients.lblFirstExamination") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.primaVisita" :disabled="!edit" type="date"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblAntitetanus") }}</label>
                    <b-form-select v-model="data.antitetanica" :disabled="!edit" :options="opzioniYesNo"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHourVisit") }}</label>
                    <date-picker format="HH:mm" value-type="timestamp" v-model="data.oraAppuntamento" :disabled="!edit" type="time"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblWeeklyWorkouts") }}
                        <eventi-storico-misure-component misura="allenamenti_settimana" misuraLabel="Allenamenti Settimanali" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input v-model="data.allenamentiSettimana" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHoursWorkout") }}</label>
                    <b-form-input v-model="data.oreAllenamento" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblCorporation") }}</label>
                    <b-form-input v-model="data.societa" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblLHB") }}</label>
                    <b-form-input v-model="data.aslNew" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblMenarche") }}</label>
                    <b-form-input v-model="data.menarca" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSmoking") }}</label>
                    <b-form-select v-model="data.fumo" :disabled="!edit" :options="opzioniFumo"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblAlcohol") }}</label>
                    <b-form-select v-model="data.alcool" :disabled="!edit" :options="opzioniAlcool" :value="null" text-field="text" value-field="value"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblNarcoticSubstances") }}</label>
                    <b-form-select v-model="data.sostenzeStupefacenti" :disabled="!edit" :options="opzioniYesNo"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblCoffee") }}</label>
                    <b-form-select v-model="data.caffe" :disabled="!edit" :options="opzioniCaffe"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblMilitary") }}</label>
                    <b-form-select v-model="data.militare" :disabled="!edit" :options="opzioniYesNo"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblMilitaryMotif") }}</label>
                    <b-form-input v-model="data.militareMotivo" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocument") }}</label>
                    <b-form-select v-model="data.documentoIdentitaTipo" :disabled="!edit" :options="opzioniCartaID"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocumentNumber") }}</label>
                    <b-form-input v-model="data.documentoIdentitaNumero" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocumentReleaser") }}</label>
                    <b-form-input v-model="data.documentoIdentitaRilasciatoDa" :disabled="!edit"></b-form-input>
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIdentityDocumentDateRelease") }}</label>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.documentoIdentitaScadenzaIl" :disabled="!edit" type="date"></date-picker>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],
    props: ["data", "edit", "tipiSportOptions", "federazioniOptions", "etaPaziente", "tipoEvento", "gruppo"],
    components: { DatePicker, EventiStoricoMisureComponent },
    computed: {
        idPaziente() {
            let me = this;
            return me.data.idPaziente;
        },
    },
    data() {
        return {
            opzioniYesNo: [
                { text: this.getLabelTraduora("patient.lblNo"), value: "patient.lblNo" },
                { text: this.getLabelTraduora("patient.lblYes"), value: "patient.lblYes" },
            ],
            opzioniCartaID: [
                { text: this.getLabelTraduora("patient.afa.lblIdentityCard"), value: "CI" },
                { text: this.getLabelTraduora("patient.afa.lblPassport"), value: "PASS" },
            ],
            opzioniAlcool: [
                { text: this.getLabelTraduora("patient.afa.lblAbstinent"), value: "patient.afa.lblAbstinent" },
                { text: this.getLabelTraduora("patient.afa.lblLessHalfLiter"), value: "patient.afa.lblLessHalfLiter" },
                { text: this.getLabelTraduora("patient.afa.lblLessOneLiter"), value: "patient.afa.lblLessOneLiter" },
                { text: this.getLabelTraduora("patient.afa.lblLessTwoLiter"), value: "patient.afa.lblLessTwoLiter" },
                { text: this.getLabelTraduora("patient.afa.lblMoreTwoLiter"), value: "patient.afa.lblMoreTwoLiter" },
            ],
            opzioniFumo: [
                { text: this.getLabelTraduora("patient.afa.lblNotSmoker"), value: "patient.afa.lblNotSmoker" },
                { text: this.getLabelTraduora("patient.afa.lblUpTo10Cigarettes"), value: "patient.afa.lblUpTo10Cigarettes" },
                { text: this.getLabelTraduora("patient.afa.lblUpTo20Cigarettes"), value: "patient.afa.lblUpTo20Cigarettes" },
                { text: this.getLabelTraduora("patient.afa.lblmore20Cigarettes"), value: "patient.afa.lblmore20Cigarettes" },
            ],
            opzioniCaffe: [
                { text: this.getLabelTraduora("patient.afa.lbl0Cups"), value: "patient.afa.lbl0Cups" },
                { text: this.getLabelTraduora("patient.afa.lbl1Cup"), value: "patient.afa.lbl1Cup" },
                { text: this.getLabelTraduora("patient.afa.lbl2Cups"), value: "patient.afa.lbl2Cups" },
                { text: this.getLabelTraduora("patient.afa.lbl3Cups"), value: "patient.afa.lbl3Cups" },
                { text: this.getLabelTraduora("patient.afa.lblMore3Cups"), value: "patient.afa.lblMore3Cups" },
            ],
        };
    },
    watch: {
        "data.sportPraticato": function (params) {
            let me = this;
            console.log(params);
            me.tipiSportOptions.forEach((element) => {
                if (element.codiceSport === params) {
                    me.data.federazione = element.federazione;
                }
            });
        },
    },
    methods: {},
    mounted() {},
};
</script>
