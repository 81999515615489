<template>
  <div>
    <b-table sticky-header stacked="md" striped hover itemscope :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
      <template #cell(index)="row">
        {{ row.index + 1 }}
      </template>
      <template #cell(titolo)="item">
        <b-form-select v-model="item.item.titolo" :options="titoliUviOptions" :value="''" value-field="value" text-field="text" :disabled="edit"></b-form-select>
      </template>
      <template #cell(nomeCognome)="item">
        <b-form-input v-model="item.item.nomeCognome" class="text-upper" :disabled="edit"></b-form-input>
      </template>
      <template #cell(actions)="row">
        <b-button v-if="row.index == items.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddComponentiUvi(items, row.item)" :disabled="edit">
          <b-icon icon="plus"></b-icon>
        </b-button>
        <b-button v-if="row.index !== items.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(items, row)" :disabled="edit">
          <b-icon icon="trash"></b-icon>
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    edit: Boolean,
    items: {
      type: Array[Object],
      default: function () {
        return {
          titolo: "",
          nomeCognome: "",
        };
      },
    },
  },
  mounted() {
    let me = this;
    me.loadTitoliUvi();
  },
  data() {
    return {
      pathResourceTitoliUvi: "/adipaiuvititolo",
      titoliUviOptions: [],
      currentPage: 1,
      perPage: 100,
      filtro: {},
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Titolo",
          key: "titolo",
          thStyle: "width: 20rem",
          sortable: false,
        },
        {
          label: "Nome e Cognome",
          key: "nomeCognome",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
    };
  },

  methods: {
    loadTitoliUvi() {
      let me = this;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceTitoliUvi;
      axios.get(link, { params: me.filtro }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.titoliUviOptions.push({ value: element.descrizione, text: element.descrizione });
        });
        me.titoliUviOptions.unshift({ value: "", text: "-Seleziona Valore-" });
      });
    },
    onAddComponentiUvi(array, value) {
      if (value.titolo !== "" && value.nomeCognome !== "") {
        array.push({ titolo: "", nomeCognome: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updatePaiUvi() {
      let me = this;
      me.$emit("updatePaiUvi", me.items);
    },
  },
};
</script>
