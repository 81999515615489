<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataEvento" type="date"> </date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPrescriptionDate") }}</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrescrizione" type="date"> </date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCodePrescription") }}</label>
          <b-form-select v-model="jsonData.codicePrescrizione" :options="codiciPrescrizioneOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }} </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
          <b-form-input v-model="jsonData.peso" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
          <b-form-input v-model="jsonData.altezza" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
          <b-form-input v-model="jsonData.bmi" readonly></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblResult") }}</label>
          <b-form-select v-model="jsonData.esito" :options="esitiOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }} </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.cardiology.lblRequestingDoctor") }}</label>
          <b-form-input v-model="jsonData.medicorich"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.cardiology.lblReasonExam") }}</label>
          <b-form-input v-model="jsonData.motivoesame"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.cardiology.lblTherapy") }}</label>
          <b-form-textarea v-model="jsonData.terapia" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRegistration')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStartTime") }}</label>
          <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.iniziorec" type="time"> </date-picker>
          <!-- @input="onCalcoloTotaleOre(jsonData.iniziorec, jsonData.finerec)" -->
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEndTime") }}</label>
          <date-picker format="HH:mm" value-type="timestamp" v-model="jsonData.finerec" type="time"></date-picker>
          <!-- @input="onCalcoloTotaleOre(jsonData.iniziorec, jsonData.finerec)" -->
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblTotalTime") }}</label>
          <b-form-input format="HH:mm" value-type="timestamp" v-model="jsonData.totorerec" type="time"> </b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQuality") }}</label>
          <b-form-select v-model="jsonData.qualita" :options="qualitaOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }} </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
          <b-form-select v-model="jsonData.tolleranza" :options="tolleranzaOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }} </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMisureTotal") }}</label>
          <b-form-input v-model="jsonData.totmisure" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValidMeasure") }}</label>
          <b-input-group size="md" append="%">
            <b-form-input v-model="jsonData.misureval" type="number"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-input-group>
            <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndicativeTest") }} </label>
              <b-form-select v-model="jsonData.testindic" :options="testIdicOptions" :value="null" value-field="value" text-field="text">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }} </b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndicativeTestComment") }} </label>
              <b-form-input v-model="jsonData.testindicomm"></b-form-input>
            </b-col>
          </b-input-group>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPressureControl')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPressureControlDay") }}</label>
          <b-form-select v-model="jsonData.ctrlpressoriodiurno" :options="controlloPressorioDiurnoOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }} </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPressureControlNight") }}</label>
          <b-form-select v-model="jsonData.ctrlpressorionotturno" :options="controlloPressorioNotturnoOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }} </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPASValueDay") }}</label>
          <b-input-group size="md" append="%">
            <b-form-input v-model="jsonData.pasdieperc" type="number"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPADValueDay") }}</label>
          <b-input-group size="md" append="%">
            <b-form-input v-model="jsonData.paddieperc" type="number"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPASValueNight") }}</label>
          <b-input-group size="md" append="%">
            <b-form-input v-model="jsonData.pasnotperc" type="number"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPADValueNight") }}</label>
          <b-input-group size="md" append="%">
            <b-form-input v-model="jsonData.padnotperc" type="number"></b-form-input>
          </b-input-group>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObservation") }}</label>
          <b-form-textarea v-model="jsonData.osservazioni" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },

  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardiomonitoraggipressione",
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      codiciPrescrizioneOptions: [{ value: "Selezione di test", text: "Selezione di test" }],
      esitiOptions: [
        { value: "NORMALE", text: "Normale" },
        { value: "PATOLOGICO", text: "Patologico" },
      ],
      qualitaOptions: [
        { value: "SODDISFACENTE", text: "Soddisfacente" },
        { value: "NON SODDISFACENTE", text: "Non Soddisfacente" },
      ],
      tolleranzaOptions: [
        { value: "SODDISFACENTE", text: "Soddisfacente" },
        { value: "NON SODDISFACENTE", text: "Non Soddisfacente" },
      ],
      controlloPressorioDiurnoOptions: [
        { value: "EFFICACE", text: "Efficace" },
        { value: "NON EFFICACE", text: "Non Efficace" },
      ],
      controlloPressorioNotturnoOptions: [
        { value: "EFFICACE", text: "Efficace" },
        { value: "NON EFFICACE", text: "Non Efficace" },
      ],
      testIdicOptions: [
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onCalcoloBmi(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
