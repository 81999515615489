<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :data="jsonData" :pathResource="pathResource" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <proposta-terapeutica-view-component :idPaziente="idPaziente"></proposta-terapeutica-view-component>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import PropostaTerapeuticaViewComponent from "../components/PropostaTerapeuticaViewComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SaPageLayout, PropostaTerapeuticaViewComponent, PazienteAnagrafica },
    data() {
        return {
            pathResource: "",
            linkback: "/paziente/propostaterapeutica",
            linkedit: null,
            jsonData: {},
            showModalLoading: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/propostaterapeutica/edit/" + me.id;
    },
};
</script>
