<template>
    <div>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblGeneralData')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataEvento) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFirstImplantDate") }}</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataPrimoImpianto) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFirstImplantDescription") }}</label>
                            <span class="sa-data">{{ jsonData.indicazioneImpianto }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNewImplantSubstitutionDate") }}</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataSostituzione) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantDescription") }}</label>
                            <span class="sa-data">{{ jsonData.descrizioneNote }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantStimulationSystem") }}</label>
                            <span class="sa-data">{{ jsonData.sistemaDiSistemazione }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantBaseFrequency") }}</label>
                            <span class="sa-data">{{ jsonData.frequenzaBase }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantModel") }}</label>
                            <span class="sa-data">{{ jsonData.modelloCatVentrSx }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterSerialNumber") }}</label>
                            <span class="sa-data">{{ jsonData.serialeIcd }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblnewImplantType") }}</label>
                            <span class="sa-data">{{ jsonData.tipoCostrutCatVentrSx }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAtrialCatheter')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterConstructor") }}</label>
                            <span class="sa-data">{{ jsonData.costruttoreCatAtriale }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterModel") }}</label>
                            <span class="sa-data">{{ jsonData.modello1 }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterImplantDate") }}</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataImpiantoAtriale) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterType") }}</label>
                            <span class="sa-data">{{ jsonData.tipoCostruttoreCatAtriale }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterSerialNumber") }}</label>
                            <span class="sa-data">{{ jsonData.serialeNumero2 }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightVentricularCatheter')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterConstructor") }}</label>
                            <span class="sa-data">{{ jsonData.costruttore }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterModel") }}</label>
                            <span class="sa-data">{{ jsonData.modalita2 }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterImplantDate") }}</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataParamProgrControllo2) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterType") }}</label>
                            <span class="sa-data">{{ jsonData.tipoCostruttore }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterSerialNumber") }}</label>
                            <span class="sa-data">{{ jsonData.seriale }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLeftVentricularCatheter')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterConstructor") }}</label>
                            <span class="sa-data">{{ jsonData.costruttoreCatVentrSx }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterModel") }}</label>
                            <span class="sa-data">{{ jsonData.modalita4 }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterImplantDate") }}</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataImpiantoVentrSx) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterType") }}</label>
                            <span class="sa-data">{{ jsonData.tip2 }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCatheterSerialNumber") }}</label>
                            <span class="sa-data">{{ jsonData.serialeCatetVentrSx }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblElectricalParameters')" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVentricularThreshold") }}</label>
                            <span class="sa-data">{{ jsonData.sogliaVentricolareDx }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftVentricularThreshold") }}</label>
                            <span class="sa-data">{{ jsonData.sogliaVentricolareSx }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialThreshold") }}</label>
                            <span class="sa-data">{{ jsonData.sogliaAtriale }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVentricularFlowing") }}</label>
                            <span class="sa-data">{{ jsonData.correnteVentricolareDx }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftVentricularFlowing") }}</label>
                            <span class="sa-data">{{ jsonData.correnteVentricolareSx }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialFlowing") }}</label>
                            <span class="sa-data">{{ jsonData.correnteAtriale }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVentricularImpedance") }}</label>
                            <span class="sa-data">{{ jsonData.impedenzaVentrDx2 }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblleftVentricularImpedance") }}</label>
                            <span class="sa-data">{{ jsonData.impedenzaBatteria1 }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialImpedance") }}</label>
                            <span class="sa-data">{{ jsonData.impedenzaBatteria3 }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVentricularWaveR") }}</label>
                            <span class="sa-data">{{ jsonData.ondaRVentricolareDx }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftVentricularWaveR") }}</label>
                            <span class="sa-data">{{ jsonData.ondaRVentricolareSx }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialWaveR") }}</label>
                            <span class="sa-data">{{ jsonData.ondaRVentrContrSx }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblShockImpedance") }}</label>
                            <span class="sa-data">{{ jsonData.impedenzaShock1 }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            pathResource: "/cardioelettrostimolatori",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>

<style></style>
