<template>
    <div>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
                    <span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
                    <span class="sa-data">{{ jsonData.bmi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBSA") }}</label>
                    <span class="sa-data">{{ jsonData.bsa }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCardiovascularRiskFactors')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.ipertensione)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.ipertensione)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertension") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.dislipidemia)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.dislipidemia)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemia") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.diabete)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diabete)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabetes") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.obesita)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.obesita)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.fumo)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fumo)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSmoking") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.familCerebrovascolari)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCerebrovascolari)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCerebrovascularAccidentsFamiliarity") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.familIpertensione)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familIpertensione)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.familCardiopatiaIsch)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCardiopatiaIsch)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCardiovascularAccidentsFamiliarity") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>

            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArterialReport") }}</label>
                    <span class="sa-data">{{ jsonData.refertoArterioso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVenousReport") }}</label>
                    <span class="sa-data">{{ jsonData.refertoVenoso }}</span>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="8" md="8" lg="8" xl="8">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.esameNellaNorma)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.esameNellaNorma)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExamInTheNorm") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.stenosiCcSx)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCcSx)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisOnLeft") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.stenosiCcDx)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCcDx)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisOnRight") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aterosclerosiLieve)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiLieve)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLightAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSignificativeStenosis") }}</label>
                    <span class="sa-data">{{ jsonData.stenosiSignificativa }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aterosclerosiModerata)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiModerata)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModerateAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aterosclerosiNonEmoSign)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiNonEmoSign)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotSignificativeEmodinamAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aterosclerosiSevera)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiSevera)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSevereAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {},
    components: {},
    data() {
        return {
            pathResource: "/cardioecocolordopplerartisuperiori",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        getTranslate(text) {
            return this.$i18n.t(text);
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
