<template>
    <div class="sa-form-section">
        <div class="sa-tab-scroll">
            <!-- InizioBody  -->
            <b-row>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                    ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label
                    ><br />
                    {{ formatDateTime(jsonData.dataEvento) }}
                </b-col>
            </b-row>
            <hr />
            <h5 class="sa-text-left">{{ getLabelTraduora("patient.cardiology.lblClinicalData") }}</h5>
            <div>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblInfectiveDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza2)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza2)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblCardiovascularDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione2 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice2 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi2) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza3)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza3)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblRespiratoryDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione3 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice3 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi3) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza4)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza4)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblDigestiveSystemDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione4 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice4 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi4) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza5)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza5)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblUrinarySystemDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione5 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice5 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi5) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza6)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza6)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblHemopathies") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione6 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice6 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi6) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza7)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza7)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblNervousSystemDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label
                        ><br />
                        {{ jsonData.definizione7 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice7 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi7) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza8)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza8)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblVenerealDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione8 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice8 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi8) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza9)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza9)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblNeoplasticDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione9 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice9 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi9) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza10)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza10)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblTrauma") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione10 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice10 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi10) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza11)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza11)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblMetabolicDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione11 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice11 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi11) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza12)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza12)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblTransfusionalReactions") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione12 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice12 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi12) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza13)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza13)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblDiabetes") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione13 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice13 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi13) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza14)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza14)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblGlaucoma") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione14 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice14 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi14) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza15)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza15)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblEndocrineDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione15 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice15 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi15) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.datoDiEmergenza16)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza16)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblImmuneSystemDiseases") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                        {{ jsonData.definizione24 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblICD9CM") }}</label>
                        {{ jsonData.codice24 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInsurgenceDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataDiagnosi24) }}
                    </b-col>
                </b-row>
            </div>
            <hr />
            <h5 class="sa-text-left">{{ getLabelTraduora("patient.cardiology.lblProcedureType") }}</h5>
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <span :class="setColor(jsonData.datoDiEmergenza17)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza17)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblDialyticTreatment") }} </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <span :class="setColor(jsonData.datoDiEmergenza18)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza18)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblOrganExplant") }} </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <span :class="setColor(jsonData.datoDiEmergenza19)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza19)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblOrganTransplant") }} </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <span :class="setColor(jsonData.datoDiEmergenza20)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza20)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblRemovableProthesis") }} </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <span :class="setColor(jsonData.datoDiEmergenza21)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.datoDiEmergenza21)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblPacemaker") }} </span>
                </b-col>
            </b-row>
            <hr />
            <h5 class="sa-text-left">{{ getLabelTraduora("patient.cardiology.lblAllergies") }}</h5>
            <div>
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        {{ getLabelTraduora(jsonData.datoDiEmergenza22) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        {{ getLabelTraduora(jsonData.datoDiEmergenza23) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        {{ getLabelTraduora(jsonData.datoDiEmergenza24) }}
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblBloodGroup") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.gruppoSanguigno) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                        ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblRhFactor") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.fattoreRh) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLastTransfusionDate") }}</label>
                        {{ formatDateTime(jsonData.dataUltimaTrasfusione) }}
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                        {{ jsonData.altro }}
                    </b-col>
                </b-row>
            </div>
            <hr />
            <h5 class="sa-text-left">{{ getLabelTraduora("patient.cardiology.lblPharmacologicalData") }}</h5>
            <div>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblAntiarrhythmics") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco2)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco2)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblAnticoagulants") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione2) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco3)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco3)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblAnticoagulants") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione3) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco4)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco4)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblOralAntidiabetics") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione4) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco5)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco5)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblAntihistamines") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione5) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco6)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco6)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblAntihypertensives") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione6) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco7)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco7)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblBetaBlockers") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione7) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco8)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco8)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblCorticosteroids") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione8) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco9)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco9)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblCytotoxicsCytostatics") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione9) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco10)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco10)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblDigitalis") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione10) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco11)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco11)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblDiuretics") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione11) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco12)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco12)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblInsuline") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione12) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco13)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco13)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblMonoamineOxidaseInhibitors") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione13) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco14)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco14)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblPsychotropicDrugs") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione14) }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco15)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco15)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblOther") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                        {{ jsonData.nomeFarmaco15 }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione15) }}
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <span :class="setColor(jsonData.tipoDiFarmaco16)"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.tipoDiFarmaco16)"></b-icon> {{ getLabelTraduora("patient.cardiology.lblPsychotropicDrugs") }} </span>
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIntake") }}</label
                        ><br />
                        {{ getLabelTraduora(jsonData.tipoDiAssunzione16) }}
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblOpticalPrescription") }}</label>
                        {{ jsonData.prescrizioniOttiche }}
                    </b-col>
                    <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                        <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPrescriptionDate") }}</label
                        ><br />
                        {{ formatDateTime(jsonData.dataPrescrizioniOttiche) }}
                    </b-col>
                </b-row>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            pathResource: "/cardioemergenze",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
