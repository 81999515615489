<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" @update="onUpdateJsonData" :titoloEvento="titoloEvento">
        <template slot="tab-content-event">
            <emergenza-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></emergenza-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EmergenzaEditComponent from "./EmergenzaEditComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { EmergenzaEditComponent, EventiEditComponent },
    data() {
        return {
            linkback: null,
            titoloEvento: "Emergenza",
            pathResource: "/cardioemergenze",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            jsonData: {
                dataEvento: null,
                datoDiEmergenza22: null,
                datoDiEmergenza23: null,
                datoDiEmergenza24: null,
                gruppoSanguigno: null,
                fattoreRh: null,
                tipoDiAssunzione: null,
                tipoDiAssunzione2: null,
                tipoDiAssunzione3: null,
                tipoDiAssunzione4: null,
                tipoDiAssunzione5: null,
                tipoDiAssunzione6: null,
                tipoDiAssunzione7: null,
                tipoDiAssunzione8: null,
                tipoDiAssunzione9: null,
                tipoDiAssunzione10: null,
                tipoDiAssunzione11: null,
                tipoDiAssunzione12: null,
                tipoDiAssunzione13: null,
                tipoDiAssunzione14: null,
                tipoDiAssunzione15: null,
                tipoDiAssunzione16: null,
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
