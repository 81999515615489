<template>
  <div class="sa-component-patient-info">
    <b-row>
      <b-col>
        <img :src="patientAvatar" class="rounded-circle avatar-toolbar img-thumbnail float-left mr-3" alt="profile-image" />
        <h4 class="m-0">
          <a>{{ jsonData.cognome }} {{ jsonData.nome }}</a>
          <!-- <span v-else class="">{{ data.cognome }} {{ data.nome }}</span> -->
        </h4>
        <p class="text-muted">
          <span><b>Data Nascita: </b> {{ formatDate(jsonData.dataNascita) }} </span> - <span> <b>Età:</b> {{ calcoloEta(jsonData.dataNascita) }} </span> - <i> {{ jsonData.codiceFiscale }} </i>
        </p>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  name: "PazienteAnagrafica",
  components: {},
  mixins: [UtilityMixin],
  props: {
    id: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/adianagrafiche",
      jsonData: {},
    };
  },
  created() {},
  mounted() {
    // let me = this;
    // if (me.id !== null) {
    //     me.loadData();
    // }
  },
  watch: {
    id: {
      immediate: true,
      handler(value) {
        let me = this;
        if (value !== "-1") {
          if (value) {
            me.loadData();
          }
        }
      },
    },
  },
  computed: {
    patientAvatar: {
      get: function () {
        let me = this;
        if (me.jsonData.genere === "M") {
          return male;
        } else {
          return female;
        }
      },
    },
    // dataValue() {
    //     let me = this;
    //     if (me.data === null) {
    //         return me.patientData;
    //     }

    //     return me.data;
    // },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
      me.showmolalloading = true;
      axios
        .get(link)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showmolalloading = false;
        })
        .catch((error) => {
          me.showmolalloading = false;
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
  },
};
</script>
