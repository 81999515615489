<template>
  <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <b-button v-if="btnNewVisible" class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd">
        <b-icon icon="plus" variant="secondary"></b-icon>
        {{ this.$i18n.t("global.lblBtnNew") }}
      </b-button>
      <!-- <b-button class="float-sm-right" variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center>
                <b-icon icon="arrow-clockwise" variant="secondary" />
                Refresh
            </b-button> -->
    </template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Cognome</label>
            <b-form-input v-model="filtro.cognome" type="search" id="cognome" :placeholder="this.$i18n.t('anagrafica.lblLastName')"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Nome</label>
            <b-form-input v-model="filtro.nome" type="search" id="nome" :placeholder="this.$i18n.t('anagrafica.lblFirstName')"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-label-data">Codice Fiscale</label>
            <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" :placeholder="this.$i18n.t('patients.lblFiscalCode')"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(nominativo)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/view/' + item.id">{{ item.cognome }} {{ item.nome }}</router-link>
            <br />
            {{ item.identificativo }}
          </template>
          <template #cell(actions)="row">
            <b-row>
              <!-- <b-button variant="btn btn-outline-info waves-effect waves-light width-sm no-text" style="margin-right: 3px" @click="onShareItem(row.item)" size="sm"><b-icon icon="share"></b-icon></b-button> -->

              <b-button variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
            </b-row>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  mixins: [UtilityMixin],
  computed: {},
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      filter: null,
      pathDeleteAnagrafica: "/anagrafica",
      pathRest: "/pazienti",
      pathRESTRequest: "/pazienti",
      btnNewVisible: false,
      filtro: {},
      filterOn: [],
      showModalLoading: false,
      fields: [
        {
          label: this.$i18n.t("patients.lblNominative"),
          key: "nominativo",
          sortable: true,
          //thStyle: "width: 7rem",
        },
        {
          label: this.$i18n.t("patients.lblTelephoneNumber"),
          key: "telefono",
          //thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("patients.lblEmail"),
          key: "email",
          sortable: true,
        },
        {
          label: this.$i18n.t("patients.lblBirthDate"),
          key: "dataNascita",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD/MM/YYYY");
          },
        },
        { key: "actions", label: "", thStyle: "width: 7rem" },
      ],
      items: [],
    };
  },
  created: function () {},
  mounted: function () {
    let me = this;
    // console.log("Lista Pazienti 1");
    me.impostaPermessi();
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRest;
      me.showModalLoading = true;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          // me.$refs["SaPageList"].close();
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onAdd() {
      let me = this;
      me.$router.replace(me.pathRESTRequest + "/edit/-1").catch((err) => {
        err;
      });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onDeleteItem(item) {
      let me = this;
      let domanda = this.$i18n.t("patient.event.lblConfirmDeleteQuestion");
      let titolo = this.$i18n.t("patient.event.lblConfirmDelete");
      let si = this.$i18n.t("patient.lblYes");
      let no = this.$i18n.t("patient.lblNo");
      this.$bvModal
        .msgBoxConfirm(domanda, {
          title: titolo,
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: si,
          cancelTitle: no,
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathDeleteAnagrafica + "/" + item.id;
      axios
        .delete(link)
        .then((Response) => {
          console.log(Response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onShareItem(item) {
      console.log(item);
    },
    impostaPermessi() {
      let me = this;
      me.btnNewVisible = false;
      me.btnNewVisible = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "pazienti", 2);
    },
  },
};
</script>
