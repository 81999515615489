<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("lbl.measure.weight") }}</label>
                    <b-form-input v-model="jsonData.peso" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("lbl.measure.height") }}</label>
                    <b-form-input v-model="jsonData.altezza" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.afa.lblBMI") }}</label>
                    <b-form-input v-model="jsonData.bmi" disabled></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.afa.lblBSA") }}</label>
                    <b-form-input v-model="jsonData.bsa" disabled></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.card.lblRiskCalculationResultFactors')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.afa.lblHypertension") }}</label>
                    <b-form-checkbox v-model="jsonData.ipertensione" switch :checked="jsonData.ipertensione"> </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.Dyslipidemia") }}</label>
                    <b-form-checkbox v-model="jsonData.dislipidemia" switch :checked="jsonData.dislipidemia"> </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.afa.lblDiabetes") }}</label>
                    <b-form-checkbox v-model="jsonData.diabete" switch :checked="jsonData.diabete"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</label>
                    <b-form-checkbox v-model="jsonData.obesita" switch :checked="jsonData.obesita"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.afa.lblSmoking") }}</label>
                    <b-form-checkbox v-model="jsonData.fumo" switch :checked="jsonData.fumo"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCardiovascularAccidentsFamiliarity") }}</label>
                    <b-form-checkbox v-model="jsonData.familCerebrovascolari" switch :checked="jsonData.familCerebrovascolari"></b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</label>
                    <b-form-checkbox v-model="jsonData.familIpertensione" switch :checked="jsonData.familIpertensione"> </b-form-checkbox>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblIDiseaseFamiliarity") }}</label>
                    <b-form-checkbox v-model="jsonData.familCardiopatiaIsch" switch :checked="jsonData.familCardiopatiaIsch"></b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Origine" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">DX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">PSV</label>
                    <b-form-input v-model="jsonData.vpsDx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">EDV</label>
                    <b-form-input v-model="jsonData.edvVpsDx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label" style="color: red">IR</label>
                    <b-form-input v-model="jsonData.irDx" type="number"></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">SX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">PSV</label>
                    <b-form-input v-model="jsonData.vpsSx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">EDV</label>
                    <b-form-input v-model="jsonData.edvVpsSx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label" style="color: red">IR</label>
                    <b-form-input v-model="jsonData.irSx" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Tratto Medio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">DX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">PSV</label>
                    <b-form-input v-model="jsonData.vpsDxTrattoMedio" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">EDV</label>
                    <b-form-input v-model="jsonData.edvVpsDxTrattoMedio" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label" style="color: red">IR</label>
                    <b-form-input v-model="jsonData.irDxTrattoMedio" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">SX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">PSV</label>
                    <b-form-input v-model="jsonData.vpsSxTrattoMedio" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">EDV</label>
                    <b-form-input v-model="jsonData.edvVpsSxTrattoMedio" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label" style="color: red">IR</label>
                    <b-form-input v-model="jsonData.irSxTrattoMedio" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - llo" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">DX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">PSV</label>
                    <b-form-input v-model="jsonData.vpsDxIlo" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">EDV</label>
                    <b-form-input v-model="jsonData.edvVpsDxIlo" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label" style="color: red">IR</label>
                    <b-form-input v-model="jsonData.irDxIlo" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">SX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">PSV</label>
                    <b-form-input v-model="jsonData.vpsSxIlo" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">EDV</label>
                    <b-form-input v-model="jsonData.edvVpsSxIlo" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label" style="color: red">IR</label>
                    <b-form-input v-model="jsonData.irSxIlo" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Intraparenchimale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">DX</label>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-form-label">PSV</label>
                    <b-form-input v-model="jsonData.vpsAtDx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-form-label">EDV</label>
                    <b-form-input v-model="jsonData.edvAtDx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-form-label" style="color: red">IR</label>
                    <b-form-input v-model="jsonData.irDxIntrapar" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">AT</label>
                    <b-form-input v-model="jsonData.atDx" type="number"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">SX</label>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-form-label">PSV</label>
                    <b-form-input v-model="jsonData.vpsAtSx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-form-label">EDV</label>
                    <b-form-input v-model="jsonData.edvAtSx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-form-label" style="color: red">IR</label>
                    <b-form-input v-model="jsonData.irSxIntrapar" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">AT</label>
                    <b-form-input v-model="jsonData.atSx" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Soprarenale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">Diametro AP</label>
                    <b-form-input v-model="jsonData.vpsAtSx2" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">Diametro LL</label>
                    <b-form-input v-model="jsonData.vps2" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Sottorenale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">Diametro AP</label>
                    <b-form-input v-model="jsonData.vps3" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">Diametro LL</label>
                    <b-form-input v-model="jsonData.vps4" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Referto" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-form-label">Referto</label>
                    <b-form-textarea rows="6" no-resize v-model="jsonData.referto"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="Conclusioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <b-form-checkbox v-model="jsonData.esameNellaNorma" switch :checked="jsonData.esameNellaNorma"> </b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblExamInTheNorm") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <b-form-checkbox v-model="jsonData.stenosiCcSx" switch :checked="jsonData.stenosiCcSx"> </b-form-checkbox>
                        </b-col>
                        <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblStenosisOnLeft") }}</label>
                        </b-col>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <b-form-checkbox v-model="jsonData.stenosiCcDx" switch :checked="jsonData.stenosiCcDx"> </b-form-checkbox>
                        </b-col>
                        <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblStenosisOnRight") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <b-form-checkbox v-model="jsonData.aterosclerosiLieve" switch :checked="jsonData.aterosclerosiLieve"> </b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLightAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSignificativeStenosis") }}</label>
                    <b-form-input v-model="jsonData.stenosiSignificativa" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <b-form-checkbox v-model="jsonData.aterosclerosiModerata" switch :checked="jsonData.aterosclerosiModerata"> </b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblModerateAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <b-form-checkbox v-model="jsonData.aterosclerosiNonEmoSign" switch :checked="jsonData.aterosclerosiNonEmoSign"> </b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblNotSignificativeEmodinamAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <b-form-checkbox v-model="jsonData.aterosclerosiSevera" switch :checked="jsonData.aterosclerosiSevera"> </b-form-checkbox>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSevereAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return { dataEvento: null };
            },
        },
    },
    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            pathResource: "/cardioecocolordoppleraortaaddominale",
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        onCalcoloBmiBsa(altezza, peso) {
            let me = this;
            me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
            me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
