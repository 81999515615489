<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipo Caso</label>
          <b-form-select v-model="jsonData.tipoCaso" :options="tipiCasiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Inizio Sintomi</label>
          <date-picker v-model="jsonData.dataPrimiSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Associato a gravidanza</label>
          <b-form-select v-model="jsonData.associatoGravidanza" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Patologia Neonato</label>
          <b-form-input v-model="jsonData.patologiaNeonato"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Sintomi" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Gastroenterite</label>
          <b-form-select v-model="jsonData.gastroenterite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data</label>
          <date-picker v-model="jsonData.gastroenteriteDataOra" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Mal di Testa</label>
          <b-form-select v-model="jsonData.malTesta" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data</label>
          <date-picker v-model="jsonData.malTestaDataOra" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Meningite</label>
          <b-form-select v-model="jsonData.meningite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data</label>
          <date-picker v-model="jsonData.meningiteDataOra" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Encefalite</label>
          <b-form-select v-model="jsonData.encefalite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data</label>
          <date-picker v-model="jsonData.encefaliteDataOra" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Batteriemia/Sepsi</label>
          <b-form-select v-model="jsonData.batteiemiaSepsi" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data</label>
          <date-picker v-model="jsonData.batteiemiaSepsiDataOra" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Perdita Appetito</label>
          <b-form-select v-model="jsonData.perditaAppetito" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data</label>
          <date-picker v-model="jsonData.perditaAppetitoDataOra" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Aborto Spontaneo</label>
          <b-form-select v-model="jsonData.abortoSpontaneo" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data</label>
          <date-picker v-model="jsonData.abortoSpontaneoDataOra" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Parto Anticipato</label>
          <b-form-select v-model="jsonData.partoAnticipato" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data</label>
          <date-picker v-model="jsonData.partoAnticipatoDataOra" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altri Sintomi</label>
          <b-form-textarea v-model="jsonData.altriSintomi" rows="8" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Ricovero Ospedaliero" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricovero Ospedaliero</label>
          <b-form-select v-model="jsonData.ricoveroOspedaliero" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Motivo Ricovero</label>
          <b-form-input v-model="jsonData.motivoRicovero"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Ricovero</label>
          <date-picker v-model="jsonData.dataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Dimissioni</label>
          <date-picker v-model="jsonData.dataDimissioneRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diagnosi</label>
          <b-form-input v-model="jsonData.diagnosiRicovero"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ospedale e Reparto di Ricovero</label>
          <b-form-input v-model="jsonData.ospedaleRepartoRicovero"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Diagnosi di Laboratorio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diagnosi di Laboratorio</label>
          <b-form-select v-model="jsonData.diagnosiLaboratorio" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo</label>
          <b-form-input v-model="jsonData.tipoDiagnosiLaboratorio"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risultato</label>
          <b-form-select v-model="jsonData.risultatoDiagnosiLaboratorio" :options="risultatiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="Patologie Pregresse" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Patologie Pregresse</label>
              <b-form-select v-model="jsonData.patologiePregresse" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaPatologiePregresse" :fields="fieldsPatologiePregresse" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(patologia)="item">
                  <b-form-input v-model="item.item.patologia"></b-form-input>
                </template>
                <template #cell(actions)="row">
                  <b-button v-if="row.index == jsonData.listaPatologiePregresse.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPatologiePregresse(jsonData.listaPatologiePregresse, row.item)">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                  <b-button v-if="row.index !== jsonData.listaPatologiePregresse.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaPatologiePregresse, row)">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="Farmaci" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Farmaci Assunti</label>
              <b-form-select v-model="jsonData.farmaciAssunti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaFarmaciAssunti" :fields="fieldsFarmaciAssunti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(farmaco)="item">
                  <b-form-input v-model="item.item.farmaco"></b-form-input>
                </template>
                <template #cell(actions)="row">
                  <b-button v-if="row.index == jsonData.listaFarmaciAssunti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddFarmaci(jsonData.listaFarmaciAssunti, row.item)">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                  <b-button v-if="row.index !== jsonData.listaFarmaciAssunti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaFarmaciAssunti, row)">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="Prodotti Omeopatici, Integratori, Prodotti Erboristici" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Prodotti Omeopatici, Integratori, Prodotti Erboristici</label>
              <b-form-select v-model="jsonData.prodottiOmeopaticiIntegratoriProdottiErboristici" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaProdottiOmeopatici" :fields="fieldsProdottiOmeopatici" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(tipoProdotto)="item">
                  <b-form-select v-model="item.item.tipoProdotto" :options="prodottiOmeopaticiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </template>
                <template #cell(nomeProdotto)="item">
                  <b-form-input v-model="item.item.nomeProdotto"></b-form-input>
                </template>
                <template #cell(actions)="row">
                  <b-button v-if="row.index == jsonData.listaProdottiOmeopatici.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddProdottiOmeopatici(jsonData.listaProdottiOmeopatici, row.item)">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                  <b-button v-if="row.index !== jsonData.listaProdottiOmeopatici.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaProdottiOmeopatici, row)">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="Day Hospital" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">E' stato ricoverto o ha effettuato day hospital nel mese precedente la comparsa dei Sintomi</label>
              <b-form-select v-model="jsonData.ricoveroDayhospitalMesePrecedente" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeListeriosiRicoveroDayHospitalBeans" :fields="fieldsDayHospital" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(luogo)="item">
                  <b-form-input v-model="item.item.luogo"></b-form-input>
                </template>
                <template #cell(ricoveroDal)="item">
                  <date-picker v-model="item.item.ricoveroDal" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </template>
                <template #cell(ricoveroAl)="item">
                  <date-picker v-model="item.item.ricoveroAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
                </template>
                <template #cell(actions)="row">
                  <b-button v-if="row.index == jsonData.malattieInfettiveIeListeriosiRicoveroDayHospitalBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddDayHospital(jsonData.malattieInfettiveIeListeriosiRicoveroDayHospitalBeans, row.item)">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                  <b-button v-if="row.index !== jsonData.malattieInfettiveIeListeriosiRicoveroDayHospitalBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeListeriosiRicoveroDayHospitalBeans, row)">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Qualcuno della sua famiglia/amici con cui ha condiviso pasti ha avuto sintomi gastroenterici</label>
          <b-form-select v-model="jsonData.pastiAmiciFamiliariConoscenti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeListeriosiPastiAmiciFamiliariBeans" :fields="fieldsPastiAmiciFamiliari" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(nome)="item">
              <b-form-input v-model="item.item.nome"></b-form-input>
            </template>
            <template #cell(cognome)="item">
              <b-form-input v-model="item.item.cognome"></b-form-input>
            </template>
            <template #cell(telefono)="item">
              <b-form-input v-model="item.item.telefono"></b-form-input>
            </template>
            <template #cell(data)="item">
              <date-picker v-model="item.item.data" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.malattieInfettiveIeListeriosiPastiAmiciFamiliariBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPastiAmiciFamiliari(jsonData.malattieInfettiveIeListeriosiPastiAmiciFamiliariBeans, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.malattieInfettiveIeListeriosiPastiAmiciFamiliariBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeListeriosiPastiAmiciFamiliariBeans, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Viaggi" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Negli Ultimi 70gg prima dell'inizio dei sintomi ah fatto qualche viaggio</label>
          <b-form-select v-model="jsonData.viaggiSettantaGiorni" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeListeriosiViaggiSettantaGiorniBeans" :fields="fieldsViaggi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(luogoViaggio)="item">
              <b-form-input v-model="item.item.luogoViaggio"></b-form-input>
            </template>
            <template #cell(dataPartenza)="item">
              <date-picker v-model="item.item.dataPartenza" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </template>
            <template #cell(dataRitorno)="item">
              <date-picker v-model="item.item.dataRitorno" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </template>
            <template #cell(strutturaSoggiorno)="item">
              <b-form-input v-model="item.item.strutturaSoggiorno"></b-form-input>
            </template>
            <template #cell(assunzionePastiAliementiSoggiorno)="item">
              <b-form-select v-model="item.item.assunzionePastiAliementiSoggiorno" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </template>
            <template #cell(qualiAlimenti)="item">
              <b-form-input v-model="item.item.qualiAlimenti"></b-form-input>
            </template>
            <template #cell(luogoAcquisto)="item">
              <b-form-input v-model="item.item.luogoAcquisto"></b-form-input>
            </template>
            <template #cell(dataAcquisto)="item">
              <date-picker v-model="item.item.dataAcquisto" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </template>
            <template #cell(luogoConsumo)="item">
              <b-form-input v-model="item.item.luogoConsumo"></b-form-input>
            </template>
            <template #cell(dataConsumo)="item">
              <date-picker v-model="item.item.dataConsumo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.malattieInfettiveIeListeriosiViaggiSettantaGiorniBeans.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddViaggi(jsonData.malattieInfettiveIeListeriosiViaggiSettantaGiorniBeans, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.malattieInfettiveIeListeriosiViaggiSettantaGiorniBeans.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.malattieInfettiveIeListeriosiViaggiSettantaGiorniBeans, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import EventiMixins from "../../../paziente/eventi/utility/mixins/EventiMixins";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { DatePicker },
  data() {
    return {
      pathResource: "/malattieinfettiveielisteriosi",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      currentPage: 1,
      perPage: 100,
      linkback: null,
      showModalLoading: false,
      disabledValue: {},
      jsonData: {
        tipoCaso: "",
        dataPrimiSintomi: null,
        associatoGravidanza: "",
        patologiaNeonato: "",
        gastroenterite: "",
        gastroenteriteDataOra: null,
        malTesta: "",
        malTestaDataOra: null,
        meningite: "",
        meningiteDataOra: null,
        encefalite: "",
        encefaliteDataOra: null,
        batteiemiaSepsi: "",
        batteiemiaSepsiDataOra: null,
        perditaAppetito: "",
        perditaAppetitoDataOra: null,
        abortoSpontaneo: "",
        abortoSpontaneoDataOra: null,
        partoAnticipato: "",
        partoAnticipatoDataOra: null,
        altriSintomi: "",
        ricoveroOspedaliero: "",
        motivoRicovero: "",
        dataRicovero: null,
        dataDimissioneRicovero: null,
        diagnosiRicovero: "",
        ospedaleRepartoRicovero: "",
        diagnosiLaboratorio: "",
        tipoDiagnosiLaboratorio: "",
        risultatoDiagnosiLaboratorio: "",
        patologiePregresse: "",
        listaPatologiePregresse: [],
        farmaciAssunti: "",
        listaFarmaciAssunti: [],
        prodottiOmeopaticiIntegratoriProdottiErboristici: "",
        listaProdottiOmeopatici: [],
        ricoveroDayhospitalMesePrecedente: "",
        malattieInfettiveIeListeriosiRicoveroDayHospitalBeans: [],
        pastiAmiciFamiliariConoscenti: "",
        malattieInfettiveIeListeriosiPastiAmiciFamiliariBeans: [],
        viaggiSettantaGiorni: "",
        malattieInfettiveIeListeriosiViaggiSettantaGiorniBeans: [],
      },
      fieldsPatologiePregresse: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Patologia",
          key: "patologia",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsFarmaciAssunti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Farmaco",
          key: "farmaco",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsProdottiOmeopatici: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo",
          key: "tipoProdotto",
          sortable: false,
        },
        {
          label: "Nome Prodotto",
          key: "nomeProdotto",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsDayHospital: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Luogo",
          key: "luogo",
          sortable: false,
        },
        {
          label: "Dal",
          key: "ricoveroDal",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          thStyle: "width: 11.5rem",
          sortable: false,
        },
        {
          label: "Al",
          key: "ricoveroAl",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          thStyle: "width: 11.5rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsPastiAmiciFamiliari: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Nome",
          key: "nome",
          sortable: false,
        },
        {
          label: "Cognome",
          key: "cognome",
          sortable: false,
        },
        {
          label: "Telefono",
          key: "telefono",
          sortable: false,
        },
        {
          label: "Data",
          key: "data",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsViaggi: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Luogo Viaggio",
          key: "luogoViaggio",
          sortable: false,
        },
        {
          label: "Data Partenza",
          key: "dataPartenza",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "Data Ritorno",
          key: "dataRitorno",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "Struttura",
          key: "strutturaSoggiorno",
          sortable: false,
        },
        {
          label: "Pasti/Alimenti Acquistati",
          key: "assunzionePastiAliementiSoggiorno",
          sortable: false,
        },
        {
          label: "Pasti/Alimenti Consumati",
          key: "qualiAlimenti",
          sortable: false,
        },
        {
          label: "Luogo Acquisto",
          key: "luogoAcquisto",
          sortable: false,
        },
        {
          label: "Data Acquisto",
          key: "dataAcquisto",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "Luogo Consumo",
          key: "luogoConsumo",
          sortable: false,
        },
        {
          label: "Data Consumo",
          key: "dataConsumo",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      tipiCasiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "PROBABILE", text: "PROBABILE" },
        { value: "ACCERTATO", text: "ACCERTATO" },
      ],
      risultatiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
      ],
      prodottiOmeopaticiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "PRODOTTI OMEOPATICI", text: "PRODOTTI OMEOPATICI" },
        { value: "INTEGRATORI", text: "INTEGRATORI" },
        { value: "PRODOTTI ERBORISTICI", text: "PRODOTTI ERBORISTICI" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.id = me.id;
    me.loadMalattiaInfettivaSegnalazione();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.patologiePregresse": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaPatologiePregresse.push({ patologia: "" });
      } else {
        me.jsonData.listaPatologiePregresse = [];
      }
    },
    "jsonData.farmaciAssunti": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaFarmaciAssunti.push({ farmaco: "" });
      } else {
        me.jsonData.listaFarmaciAssunti = [];
      }
    },
    "jsonData.prodottiOmeopaticiIntegratoriProdottiErboristici": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaProdottiOmeopatici.push({ tipoProdotto: "", nomeProdotto: "" });
      } else {
        me.jsonData.listaProdottiOmeopatici = [];
      }
    },
    "jsonData.ricoveroDayhospitalMesePrecedente": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.malattieInfettiveIeListeriosiRicoveroDayHospitalBeans.push({ luogo: "", ricoveroDal: null, ricoveroAl: null });
      } else {
        me.jsonData.malattieInfettiveIeListeriosiRicoveroDayHospitalBeans = [];
      }
    },
    "jsonData.pastiAmiciFamiliariConoscenti": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.malattieInfettiveIeListeriosiPastiAmiciFamiliariBeans.push({ nome: "", cognome: "", telefono: "", data: null });
      } else {
        me.jsonData.malattieInfettiveIeListeriosiPastiAmiciFamiliariBeans = [];
      }
    },
    "jsonData.viaggiSettantaGiorni": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.malattieInfettiveIeListeriosiViaggiSettantaGiorniBeans.push({ luogoViaggio: "", dataPartenza: null, dataRitorno: null, strutturaSoggiorno: "", assunzionePastiAliementiSoggiorno: "", qualiAlimenti: "", luogoAcquisto: "", dataAcquisto: null, luogoConsumo: "", dataConsumo: null });
      } else {
        me.jsonData.malattieInfettiveIeListeriosiViaggiSettantaGiorniBeans = [];
      }
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
        });
    },
    loadMalattiaInfettivaSegnalazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.setDatiMalattia(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onAddPatologiePregresse(array, value) {
      if (value.patologia !== "") {
        array.push({ patologia: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Il Campo PATOLOGIA è OBBLIGATORIO", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddFarmaci(array, value) {
      if (value.farmaco !== "") {
        array.push({ farmaco: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Il Campo FARMACO è OBBLIGATORIO", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddProdottiOmeopatici(array, value) {
      if (value.tipoProdotto !== "" && value.nomeProdotto !== "") {
        array.push({ tipoProdotto: "", nomeProdotto: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Il Campo FARMACO è OBBLIGATORIO", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddDayHospital(array, value) {
      if (value.luogo !== "" && value.ricoveroDal !== null && value.ricoveroAl !== null) {
        array.push({ luogo: "", ricoveroDal: null, ricoveroAl: null });
      } else {
        this.$bvModal
          .msgBoxOk("Il Campo FARMACO è OBBLIGATORIO", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddPastiAmiciFamiliari(array, value) {
      if (value.nome !== "" && value.cognome !== "" && value.telefono !== "" && value.data !== null) {
        array.push({ nome: "", cognome: "", telefono: "", data: null });
      } else {
        this.$bvModal
          .msgBoxOk("TUTTI I CAMPI SONO OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddViaggi(array, value) {
      if (value.luogoViaggio !== "" && value.dataPartenza !== null && value.dataRitorno !== null && value.strutturaSoggiorno !== "" && value.assunzionePastiAliementiSoggiorno !== "" && value.qualiAlimenti !== "" && value.luogoAcquisto !== "" && value.dataAcquisto !== null && value.luogoConsumo !== "" && value.dataConsumo !== null) {
        array.push({ luogoViaggio: "", dataPartenza: null, dataRitorno: null, strutturaSoggiorno: "", assunzionePastiAliementiSoggiorno: "", qualiAlimenti: "", luogoAcquisto: "", dataAcquisto: null, luogoConsumo: "", dataConsumo: null });
      } else {
        this.$bvModal
          .msgBoxOk("TUTTI I CAMPI SONO OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
  },
};
</script>
