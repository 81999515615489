<template>
  <sa-page-layout ref="pgaView" :data="utente" :pathResource="pathResource" :btnBackVisible="true" :btnSaveVisible="true" :titolo="utente.username" :linkback="linkback" :linkedit="linkedit" :showmolalloading="showmolalloading" class="sa-no-space">
    <template slot="table-body">
      <b-card class="sa-card" header="Dati Utente" header-tag="header" footer-tag="footer" title="">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
            <label class="sa-label-data">Cognome:</label>
            <b-form-input v-model="utente.lastname" placeholder="Cognome"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
            <label class="sa-label-data">Nome:</label>
            <b-form-input v-model="utente.firstname" placeholder="Nome"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
            <label class="sa-label-data">Username:</label>
            <b-form-input v-model="utente.username" placeholder="Username"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
            <label class="sa-label-data">E-Mail:</label>
            <b-form-input type="email" :state="null" v-model="utente.email" placeholder="E-Mail"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
            <label class="sa-label-data">Profilo:</label>
            <b-form-select v-model="utente.profilo" :options="profili"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
            <label class="sa-label-data">Scadenza:</label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="utente.scadenzaUtenza" type="date" placeholder="Data Scadenza"></date-picker>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
            <label class="sa-label-data">Password:</label>
            <b-form-input type="password" v-model="utente.password" placeholder="Password"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
            <label class="sa-label-data">Verifica Password:</label>
            <b-form-input type="password" v-model="utente.verificaPassword" placeholder="Verifica Password"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
            <label class="sa-label-data">Stato:</label>
            <b-form-checkbox v-model="utente.attivo" size="lg" switch class="sa-normal-label"></b-form-checkbox>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </sa-page-layout>
</template>
<script>
//import moment from "moment";
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  components: { SaPageLayout, DatePicker },
  data() {
    return {
      pathResource: "/users",
      linkback: "/users",
      linkedit: null,
      showmolalloading: false,
      id: "-1",
      profili: [],
      verificaPassword: "",
      utente: { lastname: "", firstname: "" },
    };
  },
  computed: {
    /*dataScadenzaUtenza: {
            get: function() {
                return new Date(this.utente.scadenzaUtenza);
            },
            set: function(value) {
                this.utente.scadenzaUtenza = moment(String(value)).valueOf();
            },
        },*/
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    let today = new Date();
    let dataScadenzaUtenza = today.setMonth(today.getMonth() + 3);
    me.utente = { lastname: "", firstname: "", attivo: true, scadenzaUtenza: dataScadenzaUtenza };
    me.loadDefaultData();
  },
  methods: {
    loadDefaultData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/gruppi";
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.profili.push({ value: element.nome, text: element.nome });
        });
        me.loadData();
      });
    },
    loadData() {
      let me = this;

      if (me.id === "-1") {
        me.showmolalloading = false;
      } else {
        me.showmolalloading = true;
        let link = process.env.VUE_APP_PATH_API + "/users/";
        axios.get(link + me.id).then((response) => {
          me.utente = response.data.data;
          me.showmolalloading = false;
        });
      }
    },
  },
};
</script>
