<template>
    <div class="sa-tab-scheda">
        <div class="sa-tab-scheda-row-fill">
            <b-row style="background-color: var(--bg-main-container); height: 100%">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-card class="sa-card" header="Esame ORL" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                        <b-row>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <label class="sa-label-data">Prog. Anno</label>
                                <b-form-input v-model="data.progressivoAnno" readonly></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <label class="sa-label-data">Data Visita</label>
                                <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataVisita" type="timestamp" placeholder="Data Visita"></date-picker>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <label class="sa-label-data">Tipo Atleta</label>
                                <b-form-select v-model="data.tipoAttleta" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiAltetaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <label class="sa-label-data">Num. Certificato</label>
                                <b-form-input v-model="data.numeroCertificato"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <label class="sa-label-data">Validità</label>
                                <b-form-input v-model="data.validitaCertificato"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                <label class="sa-label-data">Data Scadenza</label>
                                <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataScadenza" type="timestamp" placeholder="Data Scadenza"></date-picker>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-row>
                                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <label class="sa-label-data">Esame Urine</label>
                                        <b-icon v-if="data.visitaEsameUrine.firma !== null" class="sa-size-icon-15" icon="check2"></b-icon>
                                        <b-icon v-else icon="exclamation-triangle" variant="danger"></b-icon>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <label class="sa-label-data">Esame Spirografia</label>
                                        <b-icon v-if="data.visitaSpirografia.firma !== null" class="sa-size-icon-15" icon="check2"></b-icon>
                                        <b-icon v-else icon="exclamation-triangle" variant="danger"></b-icon>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <label class="sa-label-data">Esame Spirografia</label>
                                        <b-icon v-if="data.visitaEcg.firma !== null" class="sa-size-icon-15" icon="check2"></b-icon>
                                        <b-icon v-else icon="exclamation-triangle" variant="danger"></b-icon>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <label class="sa-label-data">Sport</label>
                                        <b-form-select v-model="data.sport" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiSportOptions" :value="null" value-field="codiceSport" text-field="sport" @input="onInputTipoSport($event)"></b-form-select>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <label class="sa-label-data">Societa' Sportiva</label>
                                        <b-form-input v-model="data.societaSportiva"></b-form-input>
                                    </b-col>
                                    <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                        <label class="sa-label-data">Tipo Visita</label>
                                        <b-form-input :disabled="disabilitato" v-model="data.tipoVisita"></b-form-input>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import DatePicker from "vue2-datepicker";
import axios from "axios";
export default {
    components: { DatePicker },
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    progressivoAnno: null,
                    dataVisita: null,
                    tipoAttleta: null,
                    numeroCertificato: "",
                    validitaCertificato: null,
                    dataScadenza: null,
                    provenienza: "",
                    sport: "",
                    tipoVisita: "",
                    urineMancanti: false,
                    spirografiaNonEseguita: false,
                    ecgNonEseguito: false,
                    visitaEsameUrine: { firma: null },
                    visitaSpirografia: { firma: null },
                    visitaEcg: { firma: null },
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.loadTipiSport();
    },
    data() {
        return {
            linkTipiSport: "/tipisport",
            disabilitato: false,
            tipiAltetaOptions: [
                { value: null, text: "-Seleziona Valore-" },
                { value: "AGONISTA", text: "AGONISTA" },
                { value: "NON AGONISTA", text: "NON AGONISTA" },
            ],
            tipiSportOptions: [],
        };
    },
    methods: {
        returnCheckboxUrineValue(value) {
            let me = this;
            if (value === null) {
                return (me.data.urineMancanti = true);
            }
        },
        returnCheckboxSpirografiaValue(value) {
            let me = this;
            if (value === null) {
                return (me.data.spirografiaNonEseguita = true);
            }
        },
        returnCheckboxEcgValue(value) {
            let me = this;
            if (value === null) {
                return (me.data.ecgNonEseguito = true);
            }
        },

        loadTipiSport() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTipiSport;
            axios.get(link).then((response) => {
                me.tipiSportOptions = response.data.data;
                me.tipiSportOptions.unshift({ codiceSport: null, sport: "-Seleziona Sport-" });
            });
        },
        onInputTipoSport(value) {
            let me = this;
            me.tipiSportOptions.forEach((sportSelezionato) => {
                if (sportSelezionato.codiceSport === value) {
                    me.data.validitaCertificato = sportSelezionato.validita;
                    me.data.tipoVisita = sportSelezionato.tipoTar;
                    let dataScadenzaCertificato = moment(Date()).add(me.data.validitaCertificato, "months");
                    me.data.dataScadenza = moment(dataScadenzaCertificato).toDate().getTime();
                    me.data.dataScadenza = moment(me.data.dataScadenza).add(-1, "days");
                    me.data.dataScadenza = moment(me.data.dataScadenza).toDate().getTime();
                }
            });
        },
    },
    watch: {
        "data.tipoAttleta": {
            immediate: true,
            handler(value) {
                let me = this;
                if (value === "AGONISTA" || value === null) {
                    me.disabilitato = true;
                } else {
                    me.disabilitato = false;
                }
            },
        },
        "data.sport": function (value) {
            let me = this;
            me.tipiSportOptions.forEach((element) => {
                if (element.codiceSport === value) {
                    console.log(element.codiceSport);
                    // me.data.validitaCertificato = element.validita;
                    let dataScadenzaCertificato = moment(Date()).add(me.data.validitaCertificato, "months");
                    me.data.dataScadenza = moment(dataScadenzaCertificato).toDate().getTime();
                    me.data.dataScadenza = moment(me.data.dataScadenza).add(-1, "days");
                    me.data.dataScadenza = moment(me.data.dataScadenza).toDate().getTime();
                    console.log(me.data.dataScadenza);
                }
            });
        },

        // "data.sport": {
        //     immediate: true,
        //     handler(value) {
        //         let me = this;
        //         if (value) {
        //             console.log(me.tipiSportOptions);
        //             me.tipiSportOptions.forEach((element) => {
        //                 if (element === value) {
        //                     me.data.validitaCertificato = element.validita;
        //                     let dataScadenzaCertificato = moment(Date()).add(me.data.validitaCertificato, "months");
        //                     me.data.dataScadenza = moment(dataScadenzaCertificato)
        //                         .toDate()
        //                         .getTime();
        //                 }
        //             });
        //         }
        //     },
        // },
    },
};
</script>
