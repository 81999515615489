<template>
  <sa-page-layout :btnBackVisible="true" :btnPrintVisible="false" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback">
    <template slot="table-header">
      <div style="max-height: 18rem; overflow: auto">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="6">
            <fieldset class="sa-fieldset-intestazione">
              <legend>Intestazione:</legend>
              <p>{{ jsonData.cognomeRagioneSoggetto }} &nbsp; {{ jsonData.nomeSoggetto }}</p>
              <p>{{ jsonData.indirizzoSoggetto }}</p>
              <p>{{ jsonData.capSoggetto }} {{ jsonData.localitaSoggetto }} {{ jsonData.provinciaSoggetto }}</p>
              <p>
                <label>P. I.V.A.:</label>
                {{ jsonData.partitaIvaSoggetto }}
              </p>
              <p>
                <label>Cod. Fisc.:</label>
                {{ jsonData.codiceFiscaleSoggetto }}
              </p>
            </fieldset>
          </b-col>

          <!-- <b-col lg="1"> </b-col> -->

          <b-col lg="6">
            <fieldset class="sa-fieldset-intestazione">
              <legend>Dati Documento:</legend>
              <p>
                <label>Numero:</label>
                <span class="sa-data-text">{{ jsonData.numeroDocumento }}</span>
              </p>
              <p>
                <label>Data:</label>
                {{ formatDate(jsonData.dataDocumento) }}
              </p>
              <p>
                <label>Tipo:</label>
                {{ jsonData.tipoDocumentoDescrizione }}
              </p>
              <p>
                <label>Causale:</label>
                {{ jsonData.causaleDocumento.descrizioneCausale }}
              </p>
            </fieldset>
          </b-col>
          <!-- <b-col lg="1"> </b-col> -->
        </b-row>
      </div>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filtro="filtro" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="data">
            {{ data.index + 1 }}
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info">
        <b-col cols="4">
          <p>
            <span class="sa-data">Totale Marca da Bollo:</span>
            <span class="sa-data">{{ jsonData.marcaBolloImporto | toCurrency }}</span>
          </p>
        </b-col>
        <b-col cols="4" class="text-center">
          <span class="sa-data">Marca da Bollo Virtuale:</span>
          <span class="sa-data">{{ jsonData.marcaBolloVirtuale == true ? " SI" : " NO" | toCurrency }}</span>
        </b-col>
        <b-col cols="4">
          <span class="sa-data">Marca da Bollo Numero:</span>
          <span class="sa-data">{{ jsonData.marcaBolloNumero | toCurrency }}</span>
        </b-col>
      </b-row>
      <b-row class="sa-label-info">
        <b-col cols="4">
          <p>
            <label>Totale Imponibile:</label>
            <span class="sa-data-text">{{ jsonData.totaleImponibileDocumento | toCurrency }}</span>
          </p>
        </b-col>
        <b-col cols="4" class="text-center">
          <p>
            <label>Totale Iva:</label>
            <span class="sa-data-text">{{ jsonData.totaleImpostaDocumento | toCurrency }}</span>
          </p>
        </b-col>
        <b-col cols="4" class="text-right">
          <p>
            <label>Totale Iva:</label>
            <span class="sa-data-text">{{ jsonData.totaleDocumento | toCurrency }}</span>
          </p>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
require("moment/locale/it");
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";

moment.locale("it");
export default {
  components: { SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      rigaSelezionata: null,
      tabellaArticoli: [],
      pathRest: "/documenti",
      pathRestIva: "/tabellaiva",
      pathRestUnitaMisura: "/unitamisura",
      pathRestTipoSpesa: "/tipispesa",
      linkedit: null,
      linkback: "/contabilita/fatturazione",
      currentPage: 1,
      perPage: null,
      filtro: "",
      value: 0,
      fields: [
        {
          label: "N.",
          key: "index",
          sortable: false,
          thStyle: "width: 4em",
        },
        {
          label: this.$i18n.t("billing.lblDescription"),
          key: "descrizione",
          sortable: true,
        },
        {
          label: this.$i18n.t("billing.lblQuantity"),
          key: "quantita",
          sortable: true,
          thStyle: "width: 5em",
        },
        {
          label: this.$i18n.t("billing.lblUnitOfMeasure"),
          key: "unitaMisura",
          sortable: true,
          thStyle: "width: 8em",
        },
        {
          label: this.$i18n.t("billing.lblAmount"),
          key: "prezzoUnitario",
          sortable: true,
          formatter: (value) => value.toLocaleString("it-IT", { style: "currency", currency: "EUR" }),
          thStyle: "width: 8em",
        },
        {
          label: this.$i18n.t("billing.lblDiscountPercentage"),
          key: "sconto",
          sortable: true,
          formatter: (value) => {
            value = value / 100;
            return value.toLocaleString("it-IT", { style: "percent" });
          },
          thStyle: "width: 8em",
        },
        {
          label: this.$i18n.t("billing.lblTotalLineAmount"),
          key: "totaleRiga",
          sortable: true,
          formatter: (value) => value.toLocaleString("it-IT", { style: "currency", currency: "EUR" }),
          thStyle: "width: 8rem",
        },
        {
          label: this.$i18n.t("billing.lblIva"),
          key: "descrizioneIva",
          sortable: true,
        },
        {
          label: this.$i18n.t("billing.lblTipoSpesa"),
          key: "codiceTipoSpesa",
          sortable: true,
          thStyle: "width: 8rem",
        },
      ],
      items: [],
      jsonData: { causaleDocumento: { descrizioneCausale: "" } },
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/contabilita/fatturazione/edit/" + me.id;
    this.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
      me.isBusy = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.items = [];
          me.items = response.data.data.righeDocumento;
          me.jsonData = response.data.data;
          console.log(link + me.id);
          me.loadIva();
          me.loadTipoSpesa();
          me.loadUnitaMisura();
          //me.$refs["SaPageList"].close();
          me.perPage = me.jsonData.length;
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    formatDate(value) {
      if (value) {
        //return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onSave() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pagelink;
      let jsonData = JSON.stringify(me.jsonData);

      me.showmolalloading = true;
      if (me.id == "-1") {
        axios
          .post(link, jsonData)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showmolalloading = false;
            me.$router.replace(me.routeLink).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            me.showmolalloading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        axios
          .put(link + "/" + me.id, jsonData)
          .then((response) => {
            me.gruppo = response.data.data;
            me.$refs["sa-modal-loading"].close();
            me.$router.replace(me.routeLink).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            me.$refs["sa-modal-loading"].close();
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
  },
};
</script>
<style lang="css">
/*.sa-documenti-azienda {
    font-size: 1.05em;
    font-weight: bold;
}

.sa-fieldset-intestazione legend {
    font-size: 1em;
    font-weight: bold;
    text-decoration: underline;
}
.sa-fieldset-intestazione p {
    margin-bottom: 0.5em;
    line-height: 1.1em;
}
.sa-fieldset-intestazione label {
    padding-right: 0.3em;
    margin-bottom: 0em;
}
.sa-documenti-table {
    font-size: 0.75rem;
}*/
</style>
