<template>
    <sa-page-layout :data="jsonData" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-component-content">
                    <div class="sa-form-section">
                        <h4 class="sa-text-left">{{ this.$i18n.t("structure.lblLogo") }}</h4>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <b-form-file v-model="file1" :state="Boolean(file1)" placeholder="Trascina un file qui" @change="handleImage" class="custom-input" type="file" accept="logo/*"></b-form-file>
                                <div class="mt-3">File Selezionato: {{ file1 ? file1.name : "" }}</div>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="sa-form-section">
                        <h4 class="sa-text-left">{{ this.$i18n.t("structure.lblClinicRegistry") }}</h4>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblName") }}</label>
                                <b-form-input v-model="jsonData.denominazione" placeholder="Nome Struttura"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblRegionCode") }}</label>
                                <b-form-input v-model="jsonData.codiceRegione" placeholder="Codice Regione"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblCompanyCode") }}</label>
                                <b-form-input v-model="jsonData.codiceStruttura" placeholder="Codice Struttura"></b-form-input>
                            </b-col>
                        </b-row>

                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblVatNumber") }}</label>
                                <b-form-input v-model="jsonData.partitaIva" placeholder="Partita Iva"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblFiscalCode") }}</label>
                                <b-form-input v-model="jsonData.codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblIban") }}</label>
                                <b-form-input v-model="jsonData.iban" placeholder="IBAN"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblTaxRegime") }}</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.codiceRegimeFiscale" class="mb-2 mr-sm-2 mb-sm-0" :options="codiciRegimiFiscaliOptions" :value="null"></b-form-select>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="sa-form-section">
                        <h4 class="sa-text-left">{{ this.$i18n.t("structure.lblClinicInformation") }}</h4>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblAddress") }}</label>
                                <b-form-input v-model="jsonData.indirizzo" placeholder="Indirizzo"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblProvince") }}</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.provincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOption" :value="null" @change="loadComune(jsonData.provincia)"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblCity") }}</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.localita" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneOption" :value="null" @change="loadCap(jsonData.localita)"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblPostalCode") }}</label>
                                <b-form-input v-model="jsonData.cap" placeholder="CAP"></b-form-input>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="sa-form-section">
                        <h4 class="sa-text-left">{{ this.$i18n.t("structure.lblContacts") }}</h4>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblTelephoneNumber") }}</label>
                                <b-form-input v-model="jsonData.telefono" placeholder="Telefono"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblEmail") }}</label>
                                <b-form-input v-model="jsonData.email" placeholder="Email"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblFax") }}</label>
                                <b-form-input v-model="jsonData.fax" placeholder="Fax"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblUrl") }}</label>
                                <b-form-input v-model="jsonData.url" placeholder="Url"></b-form-input>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="sa-form-section">
                        <h4 class="sa-text-left">{{ this.$i18n.t("structure.lblNote") }}</h4>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label class="sa-label-data">{{ this.$i18n.t("structure.lblNote") }}</label>
                                <b-form-textarea v-model="jsonData.note" placeholder="Note"></b-form-textarea>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            pathResource: "/strutture",
            linkback: null,
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkComuneByIstat: "/comuni/istat",
            linkRegimiFiscali: "/regimifiscali",
            linkStrutture: "/strutture",
            showModalLoading: false,
            file1: null,
            id: null,
            strutturaOption: [{ value: null, text: "-Seleziona Struttura-" }],
            provinciaOption: [{ value: null, text: "-Seleziona Provincia-" }],
            comuneOption: [{ value: null, text: "-Seleziona Comune-" }],
            codiciRegimiFiscaliOptions: [{ value: null, text: "-Seleziona Regime Fiscale-" }],
            jsonData: { localita: null, provincia: null, cap: null, codiceRegimeFiscale: null },
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/strutture";
        } else {
            me.linkback = "/struttura/view/" + me.id;
        }
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvince();
            me.loadData();
            me.loadRegimiFiscali();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.loadComune(me.jsonData.provincia);
                    me.loadCap(me.jsonData.localita);
                    me.showModalLoading = false;
                });
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadComune(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/";
            axios.get(link + sigla).then((response) => {
                me.comuneOption = [];
                response.data.data.forEach((element) => {
                    me.comuneOption.push({ value: element.codiceIstat, text: element.comune });
                });
            });
        },
        loadCap(localita) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComuneByIstat + "/" + localita;
            axios.get(link, { params: {} }).then((response) => {
                me.jsonData.cap = response.data.data.cap;
            });
        },
        loadRegimiFiscali() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRegimiFiscali;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    let descrizione = "(" + element.codiceRegimeFiscale + ") " + element.descrizioneRegimeFiscale;
                    me.codiciRegimiFiscaliOptions.push({ value: element.codiceRegimeFiscale, text: descrizione });
                });
            });
        },
        handleImage(e) {
            const selectedImage = e.target.files[0]; // get first file
            this.createBase64Image(selectedImage);
        },
        createBase64Image(fileObject) {
            let me = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                me.logo = e.target.result;
                me.jsonData.logo = me.logo;
                // this.uploadImage();
            };
            reader.readAsDataURL(fileObject);
        },
        // uploadImage() {
        //     const { logo } = this;
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + me.pathResource
        //     axios.post(link, { logo })
        //         .then((response) => {
        //         this.remoteUrl = response.data.url;
        //     })
        //     .catch((err) => {
        //         return new Error(err.message);
        //     })
        // },
    },
};
</script>
