<template>
    <div class="sa-tab-scheda">
        <b-card class="sa-card" header="Esame Obiettivo" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
                    <label class="sa-label-data">Trofismo</label>
                    <b-form-textarea v-model="data.trofismo" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="trofismoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onTrofismoInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.trofismo, linkTrofismo)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.trofismo, linkTrofismo, trofismoOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="1">
                    <label class="sa-label-data">Altezza(cm)</label>
                    <b-form-input v-model="data.altezza" @input="onCalcoloBmi(data.altezza, data.peso)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="1">
                    <label class="sa-label-data">Peso(kg)</label>
                    <b-form-input v-model="data.peso" @input="onCalcoloBmi(data.altezza, data.peso)"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="1">
                    <label class="sa-label-data">B.M.I.</label>
                    <b-form-input v-model="data.bmi" readonly></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
                    <label class="sa-label-data">Apparato Locomotore</label>
                    <b-form-textarea v-model="data.apparatoLocomotore" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="apparatoLocomotoreOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onApparatoLocomotoreInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.apparatoLocomotore, linkApparatoLocomotore)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.apparatoLocomotore, linkApparatoLocomotore, apparatoLocomotoreOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
                    <label class="sa-label-data">Torace</label>
                    <b-form-textarea v-model="data.torace" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="toraceOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onToraceInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.torace, linkTorace)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.torace, linkTorace, toraceOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
                    <label class="sa-label-data">Apparato Respiratorio</label>
                    <b-form-textarea v-model="data.apparatoRespiratorio" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="apparatoRespiratorioOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onApparatoRespiratorioInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.apparatoRespiratorio, linkApparatoRespiratorio)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.apparatoRespiratorio, linkApparatoRespiratorio, apparatoRespiratorioOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="3">
                    <label class="sa-label-data">Apparato Cardiocircolatorio</label>
                    <b-form-textarea v-model="data.apparatoCardiocircolatorio" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="apparatoCardiocircolatorioOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onApparatoCardiocircolatorioInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.apparatoCardiocircolatorio, linkApparatoCardiocircolatorio)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.apparatoCardiocircolatorio, linkApparatoCardiocircolatorio, apparatoCardiocircolatorioOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="2">
                    <label class="sa-label-data">P.A. Riposo</label>
                    <span class="sa-data" style="margin-right: 10px">Sistolica</span>
                    <span class="sa-data" style="margin-right: 10px">Diastolica</span>
                    <b-row>
                        <b-form-input v-model="data.pressioneArteriosaRiposoSistolica" style="width: 50px; margin-left: 10px" maxlength="3"></b-form-input>
                        <b-form-input v-model="data.pressioneArteriosaRiposoDiastolica" style="width: 50px; margin-left: 10px" maxlength="3"></b-form-input>
                    </b-row>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                    <label class="sa-label-data">Addome</label>
                    <b-form-textarea v-model="data.addome" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="addomeOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onAddomeInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.addome, linkAddome)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.addome, linkAddome, addomeOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                    <label class="sa-label-data">Organi Genitali</label>
                    <b-form-textarea v-model="data.organiGenitali" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="organiGenitaliOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onOrganiGenitaliInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.organiGenitali, linkOrganiGenitali)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.organiGenitali, linkOrganiGenitali, organiGenitaliOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                    <label class="sa-label-data">Arti</label>
                    <b-form-textarea v-model="data.arti" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="artiOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onArtiInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.arti, linkArti)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.arti, linkArti, artiOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="4">
                    <label class="sa-label-data">Senso Cromatico</label>
                    <b-form-textarea v-model="data.sensoCromatico" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="sensoCromaticoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onSensoCromaticoInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.sensoCromatico, linkSensoCromatico)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.sensoCromatico, linkSensoCromatico, sensoCromaticoOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Acuità visiva naturale</label>
                    <span class="sa-data">O.D.</span>
                    <span class="sa-data" style="margin-left: 50px">O.S.</span>
                    <b-row>
                        <b-form-input v-model="data.acuitaVisivaNaturaleOcchioDestro" style="width: 50px" maxlength="3"></b-form-input>
                        <span class="sa-data" style="margin-top: 10px; margin-left: 2px">/10</span>
                        <span style="width: 10px"></span>
                        <b-form-input v-model="data.acuitaVisivaNaturaleOcchioSinistro" style="width: 50px" maxlength="3"></b-form-input>
                        <span class="sa-data" style="margin-top: 10px; margin-left: 2px">/10</span>
                    </b-row>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Corretta</label>
                    <span class="sa-data">O.D.</span>
                    <span class="sa-data" style="margin-left: 50px">O.S.</span>
                    <b-row>
                        <b-form-input v-model="data.correttaOcchioDestro" style="width: 50px" maxlength="3"></b-form-input>
                        <span class="sa-data" style="margin-top: 10px; margin-left: 2px">/10</span>
                        <span style="width: 10px"></span>
                        <b-form-input v-model="data.correttaOcchioSinistro" style="width: 50px" maxlength="3"></b-form-input>
                        <span class="sa-data" style="margin-top: 10px; margin-left: 2px">/10</span>
                    </b-row>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Obbligo Lenti Correttive</label>
                    <b-form-checkbox v-model="data.obbligoLentiCorrettive" :checked="data.obbligoLentiCorrettive" @click="data.obbligoLentiCorrettive = !data.obbligoLentiCorrettive"></b-form-checkbox>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Udito</label>
                    <b-form-textarea v-model="data.udito" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="uditoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onUditoInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.udito, linkUdito)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.udito, linkUdito, uditoOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Conclusioni esame obiettivo</label>
                    <b-form-textarea v-model="data.conclusioniEsameObiettivo" no-resize></b-form-textarea>
                    <b-input-group style="margin-top: 5px">
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="conclusioniEsameObiettivoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onConclusioniEsameObiettivoInput($event)"></b-form-select>
                        <b-button variant="outline-secondary" size="sm" @click="onClickAggiungiDescrizione(data.conclusioniEsameObiettivo, linkConclusioniEsameObiettivo)"><b-icon icon="plus"></b-icon></b-button>
                        <b-button variant="outline-danger" size="sm" @click="onDeleteDescrizione(data.conclusioniEsameObiettivo, linkConclusioniEsameObiettivo, conclusioniEsameObiettivoOptions)"><b-icon icon="trash"></b-icon></b-button>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Note su scheda di valutazione</label>
                    <b-form-textarea v-model="data.noteSuSchedaDiValutazione" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
export default {
    components: {},
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    trofismo: "",
                    peso: null,
                    altezza: null,
                    apparatoLocomotore: "",
                    torace: "",
                    apparatoRespiratorio: "",
                    apparatoCardiocircolatorio: "",
                    pressioneArteriosaRiposoSistolica: null,
                    pressioneArteriosaRiposoDiastolica: null,
                    addome: "",
                    organiGenitali: "",
                    arti: "",
                    acuitaVisivaNaturaleOcchioDestro: null,
                    acuitaVisivaNaturaleOcchioSinistro: null,
                    correttaOcchioDestro: null,
                    correttaOcchioSinistro: null,
                    sensoCromatico: "",
                    obbligoLentiCorrettive: null,
                    udito: "",
                    conclusioniEsameObiettivo: "",
                    noteSuSchedaDiValutazione: "",
                    firma: "",
                    bmi: "",
                };
            },
        },
    },
    data() {
        return {
            linkTrofismo: "/visiteesameobiettivotrofismo",
            linkApparatoLocomotore: "/visiteesameobiettivoapparatolocomotore",
            linkTorace: "/visiteesameobiettivotorace",
            linkApparatoRespiratorio: "/visiteesameobiettivoapparatorespiratorio",
            linkApparatoCardiocircolatorio: "/visiteesameobiettivoapparatocircolatorio",
            linkAddome: "/visiteesameobiettivoaddome",
            linkOrganiGenitali: "/visiteesameobiettivoorganigenitali",
            linkArti: "/visiteesameobiettivoarti",
            linkSensoCromatico: "/visiteesameobiettivosensocromatico",
            linkUdito: "/visiteesameobiettivoudito",
            linkConclusioniEsameObiettivo: "/visiteesameobiettivoconclusioniesameobiettivo",
            trofismoOptions: [],
            apparatoLocomotoreOptions: [],
            toraceOptions: [],
            apparatoRespiratorioOptions: [],
            apparatoCardiocircolatorioOptions: [],
            addomeOptions: [],
            organiGenitaliOptions: [],
            artiOptions: [],
            sensoCromaticoOptions: [],
            uditoOptions: [],
            conclusioniEsameObiettivoOptions: [],
        };
    },
    mounted: function () {
        let me = this;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadTrofismo();
            me.loadApparatoLocomotore();
            me.loadTorace();
            me.loadApparatoRespiratorio();
            me.loadApparatoCardiocircolatorio();
            me.loadAddome();
            me.loadOrganiGenitali();
            me.loadArti();
            me.loadSensoCromatico();
            me.loadUdito();
            me.loadConclusioniEsameObiettivo();
        },
        loadTrofismo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTrofismo;
            axios.get(link).then((response) => {
                me.trofismoOptions = response.data.data;
            });
        },
        loadApparatoLocomotore() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkApparatoLocomotore;
            axios.get(link).then((response) => {
                me.apparatoLocomotoreOptions = response.data.data;
            });
        },
        loadTorace() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTorace;
            axios.get(link).then((response) => {
                me.toraceOptions = response.data.data;
            });
        },
        loadApparatoRespiratorio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkApparatoRespiratorio;
            axios.get(link).then((response) => {
                me.apparatoRespiratorioOptions = response.data.data;
            });
        },
        loadApparatoCardiocircolatorio() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkApparatoCardiocircolatorio;
            axios.get(link).then((response) => {
                me.apparatoCardiocircolatorioOptions = response.data.data;
            });
        },
        loadAddome() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkAddome;
            axios.get(link).then((response) => {
                me.addomeOptions = response.data.data;
            });
        },
        loadOrganiGenitali() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkOrganiGenitali;
            axios.get(link).then((response) => {
                me.organiGenitaliOptions = response.data.data;
            });
        },
        loadArti() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkArti;
            axios.get(link).then((response) => {
                me.artiOptions = response.data.data;
            });
        },
        loadSensoCromatico() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSensoCromatico;
            axios.get(link).then((response) => {
                me.sensoCromaticoOptions = response.data.data;
            });
        },
        loadUdito() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkUdito;
            axios.get(link).then((response) => {
                me.uditoOptions = response.data.data;
            });
        },
        loadConclusioniEsameObiettivo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkConclusioniEsameObiettivo;
            axios.get(link).then((response) => {
                me.conclusioniEsameObiettivoOptions = response.data.data;
            });
        },

        onTrofismoInput(value) {
            let me = this;
            me.data.trofismo = value;
        },
        onApparatoLocomotoreInput(value) {
            let me = this;
            me.data.apparatoLocomotore = value;
        },
        onToraceInput(value) {
            let me = this;
            me.data.torace = value;
        },
        onApparatoRespiratorioInput(value) {
            let me = this;
            me.data.apparatoRespiratorio = value;
        },
        onApparatoCardiocircolatorioInput(value) {
            let me = this;
            me.data.apparatoCardiocircolatorio = value;
        },
        onAddomeInput(value) {
            let me = this;
            me.data.addome = value;
        },
        onOrganiGenitaliInput(value) {
            let me = this;
            me.data.organiGenitali = value;
        },
        onArtiInput(value) {
            let me = this;
            console.log(value);
            me.data.arti = value;
        },
        onSensoCromaticoInput(value) {
            let me = this;
            me.data.sensoCromatico = value;
        },
        onUditoInput(value) {
            let me = this;
            me.data.udito = value;
        },
        onConclusioniEsameObiettivoInput(value) {
            let me = this;
            me.data.conclusioniEsameObiettivo = value;
        },
        onCalcoloBmi(altezza, peso) {
            let me = this;
            me.data.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
        },
        onClickAggiungiDescrizione(descrizione, path) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + path;
            let jsonData = { descrizione: descrizione };
            axios
                .post(link, JSON.stringify(jsonData))
                .then((response) => {
                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                        title: "",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                    me.loadDefaultData();
                })
                .catch((e) => {
                    // console.log(e);
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },

        onDeleteDescrizione(descrizione, path, array) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + path;
                        array.forEach((element) => {
                            if (element.descrizione === descrizione) {
                                axios.delete(link + "/" + element.id).then((response) => {
                                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                                        title: "",
                                        size: "sm",
                                        okVariant: "outline-danger",
                                        headerClass: "sa-msg-header-danger",
                                        footerClass: "p-2 border-top-0",
                                        centered: true,
                                    });
                                    me.loadDefaultData();
                                });
                            } else if (descrizione === null || descrizione === "") {
                                this.$bvModal.msgBoxOk("Nessun elemento da cancellare.", {
                                    title: "Attenzione!",
                                    Text: "Nessun valore da cancellare!",
                                    size: "sm",
                                    okVariant: "outline-danger",
                                    headerClass: "sa-msg-header-danger",
                                    footerClass: "p-2 border-top-0",
                                    centered: true,
                                });
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },
    },
};
</script>
<!-- <style>
.span-label {
    font-size: 16px;
    margin-top: 7px;
    margin-left: 3px;
}
</style> -->
