<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblInducedArrhythmias')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSustained") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.sostenuta) }}</span>
                </b-col>
                <b-col xs="12" sm="8" md="8" lg="8" xl="8">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQrsType") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.qrsTipologia) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label>{{ getLabelTraduora("patient.cardiology.lblQrsDurata") }}</label>
                    <span class="sa-data"> {{ jsonData.qrsDurata }}</span>
                </b-col>
                <b-col xs="12" sm="8" md="8" lg="8" xl="8">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQrsMorphology") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.qrsMorfologia) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCycle") }}</label>
                    <span class="sa-data"> {{ jsonData.ciclo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblInductionMode')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStimulationSite") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.sedeStimolazione) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExtrastimuliNumber") }}</label>
                    <span class="sa-data"> {{ jsonData.nExtrastimol }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOnDriveCycle") }}</label>
                    <span class="sa-data"> {{ jsonData.cicloDrive }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblS1S2") }}</label>
                    <span class="sa-data"> {{ jsonData.s1S2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblS2S3") }}</label>
                    <span class="sa-data"> {{ jsonData.s2S3 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblS3S4") }}</label>
                    <span class="sa-data"> {{ jsonData.s3S4 }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCycleStimulationBurst") }}</label>
                    <span class="sa-data"> {{ jsonData.stimolazioneConBurst }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIncreasingFrequencyStimulation") }}</label>
                    <span :class="setColor(jsonData.stimolazioneFrequenzaCrescente) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stimolazioneFrequenzaCrescente)"> </b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPharmacology") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.farmacologia1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInterruptionMode") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.modalitaDiInterruzione) }}</span>
                </b-col>
                <b-col xs="12" sm="8" md="8" lg="8" xl="8"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPharmacology") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.farmacologia2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDcShock") }}</label>
                    <label :class="setColor(jsonData.dcShock) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.dcShock)"></b-icon>
                    </label>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                    <span class="sa-data">{{ jsonData.aritmieIndotteNote }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPharmacologyTest')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExecuted") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.eseguito) }}</span>
                </b-col>
                <b-col xs="12" sm="8" md="8" lg="8" xl="8"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExecutedWith") }}</label>
                    <span class="sa-data"> {{ jsonData.eseguitoCon }}</span>
                </b-col>
                <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                    <span class="sa-data"> {{ jsonData.descrizioneTestFarmacologico }}</span>
                </b-col>
                <b-col xs="12" sm="2" md="2" lg="2" xl="2"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEffectiveness") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.efficace) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProceduralComplications") }}</label>
                    <span class="sa-data"> {{ jsonData.complicanzeProcedurali }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
                    <span class="sa-data"> {{ jsonData.conclusioni }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            pathResource: "/cardiostudielettrofisiologici",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
