<template>
    <div style="height: 100%">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Id Riferimento:</label>
                            <span class="sa-data">{{ jsonData.idChiamante }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Nome File:</label>
                            <span class="sa-data">{{ jsonData.nomeFile }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Tipo:</label>
                            <span class="sa-data">{{ jsonData.tipo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Signed Document:</label>
                            <span class="sa-data">{{ jsonData.signedDocuments }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Stato:</label>
                            <span class="sa-data">{{ jsonData.stato }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Log" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-data">{{ jsonData.log }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Body" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <pre>{{ prettyFormat }}</pre>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                    <user-information :data="jsonData"></user-information>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";
import UserInformation from "../../utenti/components/UserInformation.vue";
export default {
    components: { UserInformation },
    data() {
        return {
            pathResource: "/digitalsignlog",
            id: null,
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        prettyFormat: function () {
            let me = this;
            me.jsonerror = "";
            let jsonValue = "";
            try {
                jsonValue = JSON.parse(me.jsonData.body);
            } catch (e) {
                console.log(e.message);
                me.jsonerror = JSON.stringify(e.message);
                var textarea = document.getElementById("jsonText");
                if (me.jsonerror.indexOf("position") > -1) {
                    // highlight error position
                    var positionStr = me.jsonerror.lastIndexOf("position") + 8;
                    var posi = parseInt(me.jsonerror.substr(positionStr, me.jsonerror.lastIndexOf('"')));
                    if (posi >= 0) {
                        textarea.setSelectionRange(posi, posi + 1);
                    }
                }
                console.log(me.jsonerror);
                return "";
            }
            return JSON.stringify(jsonValue, null, 2);
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>

<style></style>
