<template>
  <sa-page-layout :btnSaveVisible="true" :data="jsonData" :btnAnnullaVisible="true" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" class="sa-no-space">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Segnalazione" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Codice IcdIX</label>
              <b-form-input v-model="jsonData.codiceIcdix" disabled></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Malattia infettiva</label>
              <b-input-group>
                <b-form-input v-model="jsonData.malattiaSegnalata" disabled></b-form-input>
                <b-button size="sm" variant="" @click="onClickBtnMalattiaSegnalata" :disabled="btnRicercaMalattiaDisabled"><b-icon icon="search"></b-icon></b-button>
                <!-- v-b-modal.mdlRicercaMalattia -->
              </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">UOSD di diagnosi</label>
              <b-form-select v-model="jsonData.uosdDiagnosi" :options="uosdDiagnosiOptions" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Data Segnalazione</label>
              <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataSegnalazione" type="date" required></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Nome Medico</label>
              <b-form-input v-model="jsonData.nomeMedicoSegnalante"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Cognome Medico</label>
              <b-form-input v-model="jsonData.cognomeMedicoSegnalante"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Contatti Medico</label>
              <b-form-input v-model="jsonData.contattoMedicoSegnalante"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Anagrafica" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Codice Fiscale/STP/ENI</label>
              <b-input-group class="mb-3">
                <b-form-input maxlength="16" class="text-uppercase" v-model="jsonData.pazienteBean.identificativo" @keypress="onKeyPress"></b-form-input>
                <b-button size="sm" variant="" @click="onSearchAnagraficaClick"><b-icon icon="search"></b-icon></b-button>
              </b-input-group>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Data Nascita</label>
              <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.pazienteBean.dataNascita" type="date" required></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Cognome</label>
              <b-form-input v-model="jsonData.pazienteBean.cognome"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Nome</label>
              <b-form-input v-model="jsonData.pazienteBean.nome"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Sesso</label>
              <b-form-select v-model="jsonData.pazienteBean.sesso" :options="sessoOptions" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Contatto Telefonico</label>
              <b-form-input v-model="jsonData.pazienteBean.telefono"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Professione</label>
              <!-- <b-form-select v-model="jsonData.professione" :options="professioniOptions" value-field="value" text-field="text"></b-form-select> -->
              <b-form-input v-model="jsonData.professione"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Provincia Nascita</label>
              <b-form-select v-model="jsonData.pazienteBean.provinciaNascita" :options="provinceNascitaOptions" @change="loadComuneNascita(jsonData.pazienteBean.provinciaNascita)" :value="null"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Comune Nascita</label>
              <b-form-select v-model="jsonData.pazienteBean.comuneNascitaCodiceIstat" :options="comuniNascitaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Email</label>
              <b-form-input v-model="jsonData.pazienteBean.email" type="email"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Residenza" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Provincia</label>
              <b-form-select v-model="jsonData.provinciaResidenza" :options="provinceResidenzaOptions" @change="loadComuneResidenza(jsonData.provinciaResidenza)" :value="null"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Comune</label>
              <b-form-select v-model="jsonData.comuneResidenzaCodiceIstat" :options="comuniResidenzaOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onComuneResidenzaInput"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Indirizzo</label>
              <b-form-input v-model="jsonData.indirizzoResidenza"></b-form-input>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <b-form-checkbox v-model="isDomicilioUgualeResidenza" switch>Residenza uguale a Domicilio ?</b-form-checkbox>
            </b-col>
          </b-row>
          <template v-if="!isDomicilioUgualeResidenza">
            <hr />
            <h5 class="sa-text-left">Domicilio</h5>
            <b-row>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Provincia</label>
                <b-form-select v-model="jsonData.provinciaDomicilio" :options="provinceDomicilioOptions" @change="loadComuneDomicilio(jsonData.provinciaDomicilio)" :value="null"></b-form-select>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Comune</label>
                <b-form-select v-model="jsonData.comuneDomicilioCodiceIstat" :options="comuniDomicilioOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onComuneDomicilioInput"></b-form-select>
              </b-col>
              <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Indirizzo</label>
                <b-form-input v-model="jsonData.indirizzoDomicilio"></b-form-input>
              </b-col>
            </b-row>
          </template>
        </b-card>
        <b-card class="sa-card" header="Segnalazione Informazioni" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Data Inizio Sintomi</label>
              <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataInizioSintomi" type="date" required></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Provincia Inizio Sintomi</label>
              <b-form-select v-model="jsonData.provinciaInizioSintomi" :options="provinceSintomiOptions" @change="loadComuneInizioSintomi(jsonData.provinciaInizioSintomi)" :value="null"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Comune Inizio Sintomi</label>
              <b-form-select v-model="jsonData.comuneInizioSintomiCodiceIstat" :options="comuniSintomiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Sintomi</label>
              <b-form-textarea v-model="jsonData.descrizioneSintomi" rows="4" no-resize></b-form-textarea>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Ricovero in luogo di cura</label>
              <b-form-select v-model="jsonData.ricoveroLuogoCura" :options="ricoveroLuogoCuraOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Data Ricovero</label>
              <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataRicovero" type="date" :disabled="jsonData.ricoveroLuogoCura !== 'SI'"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Vaccino ( se esiste )</label>
              <b-form-select v-model="jsonData.vaccino" :options="vacciniOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Numero Dosi</label>
              <b-form-input v-model="jsonData.numeroDosi" type="number" :disabled="jsonData.vaccino !== 'VACCINATO'"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Data ultima dose</label>
              <date-picker format="DD/MM/YYYY" value-type="timestamp" v-model="jsonData.dataUltimaDose" type="date" required :disabled="jsonData.vaccino !== 'VACCINATO'"></date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Tipo di Vaccino</label>
              <b-form-input v-model="jsonData.tipoVaccino" :disabled="jsonData.vaccino !== 'VACCINATO'"></b-form-input>
              <!-- <b-form-select v-model="jsonData.tipoVaccino" :options="tipiVacciniOptions" :value="null" value-field="value" text-field="text" :disabled="jsonData.vaccino !== 'VACCINATO'"></b-form-select> -->
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Dati Clinici</label>
              <b-form-select v-model="jsonData.datiClinici" :options="siNoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Esami Culturali</label>
              <b-form-select v-model="jsonData.esamiCulturali" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="jsonData.datiClinici !== 'SI'"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Esame Diretto</label>
              <b-form-select v-model="jsonData.esameDiretto" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="jsonData.datiClinici !== 'SI'"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Esami Sierologici</label>
              <b-form-select v-model="jsonData.esamiSierologici" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="jsonData.datiClinici !== 'SI'"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Dati Clinici</label>
              <b-form-textarea v-model="jsonData.esamiSierologiciDettagli" rows="10" no-resize :disabled="jsonData.esamiSierologici !== 'SI'"></b-form-textarea>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
    <template slot="table-footer">
      <b-modal ref="mdlRicercaMalattia" id="mdlRicercaMalattia" title="Ricerca Malattia" size="xl" @hidden="onHiddenMdlRicercaMalattia" @ok="onOkMdlRicercaMalattia" scrollable>
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-form @submit.prevent="onClickRicercaMalattiaMdl">
                <b-input-group>
                  <b-form-input v-model="filtro.descrizione"></b-form-input>
                  <b-button size="sm" @click="onClickRicercaMalattiaMdl"><b-icon icon="search"></b-icon></b-button>
                </b-input-group>
              </b-form>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="sa-padding-right text-right">
              <b-button type="reset" v-on:click="onResetFiltroMdl" variant="danger">Resetta Filtro</b-button>
            </b-col>
          </b-row>
          <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
            <b-col lg="3">
              <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
            </b-col>
            <b-col lg="9">
              <b-pagination v-model="filtro.page" :total-rows="rows" :per-page="filtro.forPage" align="right" size="sm"></b-pagination>
            </b-col>
          </b-row>
          <!-- <div class="b-table-sticky-header"> -->
          <b-table sticky-header ref="tblMalattie" stacked="xl" striped hover itemscope :items="malattieSegnalate" :fields="fieldsMlattieSegnalate" :current-page="filtro.page" :per-page="filtro.forPage" sort-icon-left head-variant="light" class="sa-b-table" selectable select-mode="single" @row-selected="onRowSelectedMalattia">
            <template #cell(selezionato)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selezionato</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Non Selezionato</span>
              </template>
            </template>
          </b-table>
          <!-- </div> -->
        </div>
      </b-modal>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return { gruppo: { menuGruppo: [] } };
      },
    },
  },
  components: { SaPageLayout, DatePicker },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id !== "-1") {
      me.linkback = "/malattieinfettive/view/" + me.id;
      me.btnRicercaMalattiaDisabled = true;
    } else {
      me.linkback = "/malattieinfettive/all";
      me.btnRicercaMalattiaDisabled = false;
    }
    me.loadData();
    me.loadDefaultData();
  },
  computed: {
    rows() {
      return this.malattieSegnalate.length;
    },
  },
  data() {
    return {
      isDomicilioUgualeResidenza: false,
      pathResource: "/malattieinfettivesegnalazioni",
      pathResourceMalattie: "/malattieinfettivemalattie",
      showModalLoading: false,
      btnRicercaMalattiaDisabled: false,
      linkback: null,
      linkProvince: "/province",
      linkComune: "/comuni/provincia",
      malattiaSelezionata: "",
      codiceIcdixSelezionato: "",
      jsonData: {
        malattiaSegnalata: "",
        professione: null,
        dataInizioSintomi: null,
        comuneInizioSintomiCodiceIstat: null,
        provinciaInizioSintomi: null,
        vaccino: null,
        numeroDosi: 0,
        dataUltimaDose: null,
        tipoVaccino: null,
        nomeMedicoSegnalante: "",
        cognomeMedicoSegnalante: "",
        contattoMedicoSegnalante: "",
        dataSegnalazione: null,
        ricoveroLuogoCura: null,
        comuneResidenzaCodiceIstat: null,
        indirizzoResidenza: "",
        comuneDomicilioCodiceIstat: null,
        indirizzoDomicilio: "",
        statoAttuale: null,
        statoAttualeDataOra: null,
        provinciaResidenza: null,
        provinciaDomicilio: null,
        uosdDiagnosi: null,
        dataRicovero: null,
        datiClinici: "",
        esamiCulturali: "",
        esameDiretto: "",
        esamiSierologici: "",
        esamiSierologiciDettagli: "",
        descrizioneSintomi: "",
        pazienteBean: {
          identificativo: "",
          cognome: "",
          nome: "",
          dataNascita: null,
          comuneNascitaCodiceIstat: null,
          comuneResidenzaCodiceIstat: null,
          comuneDomicilioCodiceIstat: null,
          indirizzoResidenza: "",
          indirizzoDomicilio: "",
          telefono: "",
          sesso: null,
          provinciaNascita: null,
          provinciaResidenza: null,
          provinciaDomicilio: null,
          codiceFiscaleMedicoCurante: "",
          medicoCurante: "",
          capResidenza: "",
          capDomicilio: "",
          capNascita: "",
          codiceStruttura: "000000",
        },
      },
      filtro: { descrizione: "", forPage: 100, page: 1 },
      malattieSegnalate: [],
      uosdDiagnosiOptions: [],
      nazionalitaOptions: [],
      sessoOptions: [
        { text: "- Seleziona - ", value: null },
        { text: "Maschio", value: "M" },
        { text: "Femmina", value: "F" },
      ],
      vacciniOptions: [],
      provinceNascitaOptions: [],
      comuniNascitaOptions: [{ value: null, text: "-Seleziona-" }],
      provinceResidenzaOptions: [],
      comuniResidenzaOptions: [{ codiceIstat: null, comune: "-Seleziona-" }],
      provinceDomicilioOptions: [],
      comuniDomicilioOptions: [{ codiceIstat: null, comune: "-Seleziona-" }],
      ricoveroLuogoCuraOptions: [],
      provinceSintomiOptions: [{ value: null, text: "-Seleziona-" }],
      comuniSintomiOptions: [{ value: null, text: "-Seleziona-" }],
      tipiVacciniOptions: [],
      // professioniOptions: [],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      fieldsMlattieSegnalate: [
        {
          label: "",
          key: "selezionato",
          thStyle: "width: 2.5rem",
        },
        {
          label: "Codice IcdIX",
          key: "codiceIcdix",
          thStyle: "width: 4rem",
          sortable: false,
        },
        {
          label: "Descrizione",
          key: "descrizioneIcdix",
          sortable: false,
        },
      ],
    };
  },
  watch: {
    isDomicilioUgualeResidenza: function (value) {
      let me = this;
      if (value) {
        console.log(value);
        me.jsonData.provinciaDomicilio = me.jsonData.provinciaResidenza;
        me.jsonData.comuneDomicilioCodiceIstat = me.jsonData.comuneResidenzaCodiceIstat;
        me.jsonData.indirizzoDomicilio = me.jsonData.indirizzoResidenza;
        me.jsonData.pazienteBean.capDomicilio = me.jsonData.pazienteBean.capResidenza;
      } else {
        me.jsonData.provinciaDomicilio = null;
        me.jsonData.comuneDomicilioCodiceIstat = null;
        me.jsonData.indirizzoDomicilio = null;
      }
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.setDatiMedico();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        me.showModalLoading = true;
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.loadComuneNascita(me.jsonData.pazienteBean.provinciaNascita);
            me.loadComuneResidenza(me.jsonData.provinciaResidenza);
            me.loadComuneDomicilio(me.jsonData.provinciaDomicilio);
            me.loadComuneInizioSintomi(me.jsonData.provinciaInizioSintomi);
            me.showModalLoading = false;
          })
          .catch((e) => {
            e;
            me.showModalLoading = false;
          });
      }
    },
    loadDefaultData() {
      let me = this;
      // me.loadMalattieInfettiveIcdIx();
      me.loadUosdDiagnosi();
      me.loadLuogoCasaCure();
      me.loadVaccino();
      me.loadProvince();
      me.loadNazioni();
      // me.loadTipoVaccino();
      // me.loadProfessioni();
    },
    onClickBtnMalattiaSegnalata() {
      let me = this;
      me.$refs.mdlRicercaMalattia.show();
      me.loadMalattieInfettiveIcdIx();
    },
    loadMalattieInfettiveIcdIx() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattie; //"/serviziicdix/malattieinfettive?descrizione=" + me.filtro.descrizione;
      axios.get(link, { params: me.filtro }).then((response) => {
        // console.log(response);
        me.malattieSegnalate = response.data.data.list;
        me.showModalLoading = false;
      });
    },
    loadNazioni() {
      let me = this;
      let linkNazioni = process.env.VUE_APP_PATH_API + "/nazioni";
      axios.get(linkNazioni).then((response) => {
        me.nazionalitaOptions = response.data.data;
      });
    },
    loadUosdDiagnosi() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/malattieinfettiveuosd?page=1&forPage=100";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.uosdDiagnosiOptions.push({ text: element.descrizione, value: element.descrizione });
        });
        me.uosdDiagnosiOptions.unshift({ text: "-Seleziona-", value: null });
      });
    },

    loadLuogoCasaCure() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/malattieinfettivericoveriluoghicure?page=1&forPage=100";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.ricoveroLuogoCuraOptions.push({ text: element.descrizione, value: element.descrizione });
        });
        me.ricoveroLuogoCuraOptions.unshift({ text: "-Seleziona-", value: null });
      });
    },
    loadVaccino() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/malattieinfettivevaccini?page=1&forPage=100";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.vacciniOptions.push({ text: element.descrizione, value: element.descrizione });
        });
        me.vacciniOptions.unshift({ text: "-Seleziona-", value: null });
      });
    },
    // loadProfessioni() {
    //     let me = this;
    //     let link = process.env.VUE_APP_PATH_API + "/malattieinfettiveprofessioni?page=1&forPage=100";
    //     axios.get(link).then((response) => {
    //         response.data.data.list.forEach((element) => {
    //             me.professioniOptions.push({ text: element.descrizione, value: element.descrizione });
    //         });
    //         me.professioniOptions.unshift({ text: "-Seleziona-", value: null });
    //     });
    // },
    // loadTipoVaccino() {
    //     let me = this;
    //     let link = process.env.VUE_APP_PATH_API + "/malattieinfettivetipivaccini?page=1&forPage=100";
    //     axios.get(link).then((response) => {
    //         response.data.data.list.forEach((element) => {
    //             me.tipiVacciniOptions.push({ text: element.descrizione, value: element.descrizione });
    //         });
    //         me.tipiVacciniOptions.unshift({ text: "-Seleziona-", value: null });
    //     });
    // },
    loadProvince() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.provinceNascitaOptions.push({ value: element.sigla, text: element.provincia });
          me.provinceResidenzaOptions.push({ value: element.sigla, text: element.provincia });
          me.provinceDomicilioOptions.push({ value: element.sigla, text: element.provincia });
          me.provinceSintomiOptions.push({ value: element.sigla, text: element.provincia });
        });
        me.provinceNascitaOptions.unshift({ text: "-Seleziona-", value: null });
        me.provinceResidenzaOptions.unshift({ text: "-Seleziona-", value: null });
        me.provinceDomicilioOptions.unshift({ text: "-Seleziona-", value: null });
        me.provinceSintomiOptions.unshift({ text: "-Seleziona-", value: null });
      });
    },
    setDatiNascita(siglaProvincia, codiceIstatComuneNascita = null) {
      let me = this;
      me.jsonData.pazienteBean.comuneNascitaCodiceIstat = codiceIstatComuneNascita;
      me.provinceNascitaOptions.forEach((provincia) => {
        if (provincia.value === siglaProvincia) {
          me.jsonData.pazienteBean.provinciaNascita = provincia.value;
          me.loadComuneNascita(provincia.value);
          //   console.log();
          me.setCap(me.jsonData.pazienteBean.comuneNascitaCodiceIstat);
        }
      });
    },
    loadComuneNascita(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuniNascitaOptions = [];
        response.data.data.forEach((element) => {
          me.comuniNascitaOptions.push({ value: element.codiceIstat, text: element.comune });
        });
      });
    },
    setCap(value) {
      let me = this;
      me.comuniNascitaOptions.forEach((comune) => {
        if (comune.codiceIstat === value) {
          me.pazienteBean.capNascita = comune.cap;
        }
      });
    },
    setDatiResidenza(siglaProvincia, codiceIstatComuneResidenza = null) {
      let me = this;
      //   console.log(codiceIstatComuneResidenza);
      me.jsonData.comuneResidenzaCodiceIstat = codiceIstatComuneResidenza;
      me.provinceResidenzaOptions.forEach((provincia) => {
        if (provincia.value === siglaProvincia) {
          me.jsonData.provinciaResidenza = provincia.value;
          me.loadComuneResidenza(provincia.value);
        }
      });
    },
    loadComuneResidenza(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuniResidenzaOptions = response.data.data;
      });
    },
    onComuneResidenzaInput(codiceIstatComuneResidenza) {
      let me = this;
      me.comuniResidenzaOptions.forEach((comune) => {
        if (comune.codiceIstat === codiceIstatComuneResidenza) {
          me.jsonData.pazienteBean.capResidenza = comune.cap;
        }
      });
    },
    loadComuneDomicilio(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuniDomicilioOptions = [];
        me.comuniDomicilioOptions = response.data.data;
      });
    },
    onComuneDomicilioInput(codiceIstatComuneDomicilio) {
      let me = this;
      me.comuniDomicilioOptions.forEach((comune) => {
        if (comune.codiceIstat === codiceIstatComuneDomicilio) {
          me.jsonData.pazienteBean.capDomicilio = comune.cap;
        }
      });
    },
    loadComuneInizioSintomi(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuniSintomiOptions = [];
        response.data.data.forEach((element) => {
          me.comuniSintomiOptions.push({ value: element.codiceIstat, text: element.comune });
        });
      });
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.nomeMedicoSegnalante = me.utente.firstname;
      me.jsonData.cognomeMedicoSegnalante = me.utente.lastname;
    },
    onKeyPress(e) {
      let me = this;
      if (e.keyCode === 13) {
        me.onSearchAnagraficaClick();
      }
    },
    onSearchAnagraficaClick() {
      let me = this;
      me.showmolalloading = true;
      if (me.jsonData.pazienteBean.identificativo.trim().length === 0) {
        me.showmolalloading = false;
      } else {
        me.jsonData.pazienteBean.identificativo = me.jsonData.pazienteBean.identificativo.toUpperCase();
        let link = process.env.VUE_APP_PATH_API + "/utility";
        axios
          .get(link, { params: { codiceFiscale: me.jsonData.pazienteBean.identificativo } })
          .then((response) => {
            me.jsonData.pazienteBean.nome = response.data.data.nome;
            me.jsonData.pazienteBean.cognome = response.data.data.cognome;
            me.jsonData.pazienteBean.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY").toDate().getTime();
            me.jsonData.pazienteBean.sesso = response.data.data.sesso;
            me.jsonData.pazienteBean.provinciaNascita = response.data.data.provinciaNascita;
            me.setDatiNascita(response.data.data.provinciaNascita, response.data.data.codiceIstatComuneNascita);
            me.jsonData.provinciaResidenza = response.data.data.provinciaResidenza;
            me.setDatiResidenza(response.data.data.provinciaResidenza, response.data.data.codiceIstatComuneResidenza);
            me.jsonData.indirizzoResidenza = response.data.data.indirizzoResidenza;
            me.jsonData.pazienteBean.codiceFiscaleMedicoCurante = response.data.data.codiceFiscaleMedico;
            me.jsonData.pazienteBean.medicoCurante = response.data.data.medico;
            me.showmolalloading = false;
          })
          .catch(() => {
            me.showmolalloading = false;
          });
      }
    },
    onClickRicercaMalattiaMdl() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadMalattieInfettiveIcdIx();
    },
    onResetFiltroMdl() {
      let me = this;
      me.filtro.descrizione = "";
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadMalattieInfettiveIcdIx();
    },
    onHiddenMdlRicercaMalattia() {
      let me = this;
      me.filtro.descrizione = "";
    },
    onOkMdlRicercaMalattia() {
      let me = this;
      me.jsonData.malattiaSegnalata = me.malattiaSelezionata;
      me.jsonData.codiceIcdix = me.codiceIcdixSelezionato;
    },
    onRowSelectedMalattia(rows) {
      let me = this;
      if (rows[0]) {
        me.malattiaSelezionata = rows[0].descrizioneIcdix + " (" + rows[0].codiceIcdix + ")";
        me.codiceIcdixSelezionato = rows[0].codiceIcdix;
      }
    },
  },
};
</script>

<style></style>
