import MalattieInfettive from "../";
import MalattieInfettiveIndagineEpidemiologica from "../indagineepidemiologica";
import MalattieInfettiveHiv from "../hiv";

const routes = [
    { name: "MalattieInfettiveList", path: "/malattieinfettive/all", component: MalattieInfettive.MalattieInfettiveList, meta: { title: "Malattie Infettive" } },
    { name: "MalattieInfettiveView", path: "/malattieinfettive/view/:id", component: MalattieInfettive.MalattieInfettiveView, meta: { title: "Malattia Infettiva" } },
    { name: "MalattieInfettiveEdit", path: "/malattieinfettive/edit/:id", component: MalattieInfettive.MalattieInfettiveEdit, meta: { title: "Nuova Malattia Infettiva" } },
    { name: "MalattieInfettiveSegnalazioniList", path: "/malattieinfettive/segnalazioni", component: MalattieInfettive.MalattieInfettiveSegnalazioniList, meta: { title: "Malattie Infettive Segnalazioni" } },
    { name: "MalattieInfettiveArchivioList", path: "/malattieinfettive/archivio", component: MalattieInfettive.MalattieInfettiveArchivioList, meta: { title: "Malattie Infettive Segnalazioni" } },
    { name: "IndagineEpidemiologicaList", path: "/malattieinfettive/indagineepidemiologica", component: MalattieInfettiveIndagineEpidemiologica.IndagineEpidemiologicaList, meta: { title: "Indagine Epidemiologica" } },
    { name: "IndagineEpidemiologicaView", path: "/malattieinfettive/archivio/indagineepidemiologica/view/:id", component: MalattieInfettiveIndagineEpidemiologica.IndagineEpidemiologicaView, meta: { title: "Indagine Epidemiologica" } },
    { name: "IndagineEpidemiologicaEdit", path: "/malattieinfettive/archivio/indagineepidemiologica/edit/:id", component: MalattieInfettiveIndagineEpidemiologica.IndagineEpidemiologicaEdit, meta: { title: "Indagine Epidemiologica Nuova" } },
    { name: "IndagineEpidemiologicaBrucellosiEdit", path: "/malattieinfettive/indagineepidemiologica/brucellosi/edit/:id", component: MalattieInfettiveIndagineEpidemiologica.IndagineEpidemiologicaBrucellosiEdit, meta: { title: "Indagine Epidemiologica Brucellosi Nuova" } },
    { name: "IndagineEpidemiologicaSalmonellosiNonTifoideaEdit", path: "/malattieinfettive/indagineepidemiologica/salmonellosinontifoidea/edit/:id", component: MalattieInfettiveIndagineEpidemiologica.IndagineEpidemiologicaSalmonellosiNonTifoideaEdit, meta: { title: "Indagine Epidemiologica Salmonellosi Non Tifoidea Nuova" } },
    { name: "IndagineEpidemiologicaMalattiaJakobCreutzfeldtEdit", path: "/malattieinfettive/indagineepidemiologica/malattiajakobcreutzfeldt/edit/:id", component: MalattieInfettiveIndagineEpidemiologica.IndagineEpidemiologicaMalattiaJakobCreutzfeldtEdit, meta: { title: "Indagine Epidemiologica Malattia Jakob Creutzfeldt Nuova" } },

    { name: "MalattieInfettiveHivList", path: "/malattieinfettive/hiv", component: MalattieInfettiveHiv.MalattieInfettiveHivList, meta: { title: "Malattie Infettive" } },
    { name: "MalattieInfettiveHivView", path: "/malattieinfettive/hiv/view/:id", component: MalattieInfettiveHiv.MalattieInfettiveHivView, meta: { title: "Malattia Infettiva Hiv" } },
    { name: "MalattieInfettiveHivEdit", path: "/malattieinfettive/hiv/edit/:id", component: MalattieInfettiveHiv.MalattieInfettiveHivEdit, meta: { title: "Nuova Malattia Infettiva Hiv" } },
    { name: "MalattieInfettiveHivDashboard", path: "/malattieinfettive/hiv/dashboard", component: MalattieInfettiveHiv.MalattieInfettiveHivDashboard, meta: { title: "Nuova Malattia Infettiva Hiv" } },

    { name: "IndagineEpidemiologicaModelliList", path: "/malattieinfettive/indagineepidemiologica/modelli", component: MalattieInfettiveIndagineEpidemiologica.IndagineEpidemiologicaModelliList, meta: { title: "Malattie Infettive Indagine Epidemiologica Modelli" } },
];
export default routes;
