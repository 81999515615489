<template>
  <div class="sa-tab-scroll">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-button variant="outline-info  sa-margin-right float-sm-right" size="md" @click="onOpenSpirometriaModal">
          <!-- <b-button variant="btn-toolbar sa-margin-right float-sm-right" size="md" v-b-modal.mdlSpirometria> -->
          <i class="fas fa-lungs"></i>
          Spirometria
        </b-button>
      </b-col>
    </b-row>
    <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ this.getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="VALORI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Altezza(cm)</label>
          <b-form-input v-model="jsonData.altezza" placeholder="Altezza" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Peso(kg)</label>
          <b-form-input v-model="jsonData.peso" placeholder="Peso" :disabled="disableCampi"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">FVC (L/s)</label>
          <b-form-input type="number" v-model="jsonData.fvc" placeholder="FVC" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">FEV1 (L)</label>
          <b-form-input type="number" v-model="jsonData.fev1" placeholder="FEV1" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">FEV1/FVC% (%)</label>
          <b-form-input type="number" v-model="jsonData.fev1Fvc" placeholder="FEV/FVC" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">PEF(L/s)</label>
          <b-form-input type="number" v-model="jsonData.pef" placeholder="PEF" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">FEF-25-75% (L/s)</label>
          <b-form-input type="number" v-model="jsonData.fef2575" placeholder="FEF-25-75%" :disabled="disableCampi"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF25% (L/s)</label>
          <b-form-input type="number" v-model="jsonData.mef25" placeholder="MEF25%" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF50% (L/s)</label>
          <b-form-input type="number" v-model="jsonData.mef50" placeholder="MEF50%" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF75% (L/s)</label>
          <b-form-input type="number" v-model="jsonData.mef75" placeholder="MEF75" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MVV(L/m)</label>
          <b-form-input type="number" v-model="jsonData.mvv" placeholder="MVV" :disabled="disableCampi"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="VALORI NORMALI" header-tag="header" footer-tag="footer" title="" :disabled="disableCampi">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">FVC (L/s)</label>
          <b-form-input type="number" v-model="jsonData.fvcNormale" placeholder="FVC" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">FEV1 (L)</label>
          <b-form-input type="number" v-model="jsonData.fev1Normale" placeholder="FEV1" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">FEV1/FVC% (%)</label>
          <b-form-input type="number" v-model="jsonData.fev1FvcNormale" placeholder="FVC" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">PEF(L/s)</label>
          <b-form-input type="number" v-model="jsonData.pefNormale" placeholder="PEF" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">FEF-25-75% (L/s)</label>
          <b-form-input type="number" v-model="jsonData.fef2575Normale" placeholder="FEF-25-75%" :disabled="disableCampi"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF25% (L/s)</label>
          <b-form-input type="number" v-model="jsonData.mef25Normale" placeholder="MEF25%" :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF50% (L/s)</label>
          <b-form-input type="number" v-model="jsonData.mef50Normale" placeholder="MEF50% " :disabled="disableCampi"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MEF75% (L/s)</label>
          <b-form-input type="number" v-model="jsonData.mef75Normale" placeholder="MEF75%" :disabled="disableCampi"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">MVV(L/m)</label>
          <b-form-input type="number" v-model="jsonData.mvvNormale" placeholder="MVV" :disabled="disableCampi"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="CONCLUSIONI" header-tag="header" footer-tag="footer" title="" :disabled="disableCampi">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <!-- <label class="sa-label-data">Conclusioni</label> -->
          <b-form-textarea v-model="jsonData.conclusioni" type="text" rows="7" no-resize placeholder="Conclusioni" :disabled="disableCampi"></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <div style="height: 100%; display: inline-flex; flex-direction: column; width: 100%">
        <div class="sa-tab-scheda-row-fill-table-content">
          <mir-list-component :idRisorsa="id" @updateBase64="onUpdateBase64" :listaRichieste="listaRichieste" @update="onUpdateLista"></mir-list-component>
        </div>
      </div>
    </b-card>
    <b-modal b-modal ref="mdlSpirometria" id="mdlSpirometria" size="md" style="height: 100%" @ok="onSpirometria" title="Seleziona Stanza">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-select v-model="mirVisita.postazioneRichiesta" :options="stanzeOptions" :value="''" value-field="identificativo" text-field="nomeStanza"></b-form-select>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import MirListComponent from "../../../../../integrazioni/mir/components/MirListComponent.vue";
export default {
  components: { DatePicker, MirListComponent },
  mixins: [UtilityMixin],

  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return {
          fvc: null,
          fev1: null,
          fev1Fvc: null,
          pef: null,
          fef2575: null,
          mef25: null,
          mef50: null,
          mef75: null,
          mvv: null,
          conclusioni: "",
          firma: "",
          dataEvento: new Date().getTime(),
          /*Valori Nomali */

          fvcNormale: null,
          fev1Normale: null,
          fev1FvcNormale: null,
          pefNormale: null,
          fef2575Normale: null,
          mef25Normale: null,
          mef50Normale: null,
          mef75Normale: null,
          mvvNormale: null,
        };
      },
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.dataEvento = new Date().getTime();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  data() {
    return {
      pathResource: "/pazienteeventispirometrie",
      pathResourceStanze: "/mirstanze",
      pathMir: "/mirvisite",
      id: "-1",
      idRisorsa: null,
      disableCampi: false,
      stanzeOptions: [],
      listaRichieste: [],
      mirVisita: { idVisita: "", postazioneRichiesta: "" },
    };
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
    onOpenSpirometriaModal() {
      console.log("OpenModal");
      let me = this;
      me.loadStanze();
    },
    loadStanze() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceStanze;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.stanzeOptions = response.data.data.list;
          me.stanzeOptions.unshift({ identificativo: "", nomeStanza: "-Seleziona Stanza-" });
          me.$refs["mdlSpirometria"].show();
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSpirometria() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      if (me.id === "-1" || me.id === null || me.id === undefined) {
        axios
          .post(link, JSON.stringify(me.jsonData))
          .then((response) => {
            this.$emit("update", response.data.data);
            me.id = response.data.data.id;
            me.mirVisita.idVisita = response.data.data.id;
            me.mirVisita.idPaziente = response.data.data.idPaziente;
            me.showModalLoading = false;
            me.salvaMirVisita();
            me.$router.replace("/paziente/esami/spirometria/view/" + response.data.data.id + "?idPaziente=" + me.idPaziente).catch((err) => {
              err;
            });
            me.loadData();
          })
          .catch((error) => {
            me.showModalLoading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        link = link + "/" + me.jsonData.id;
        axios
          .put(link, JSON.stringify(me.jsonData))
          .then((response) => {
            // console.log(response);
            this.$emit("update", response.data.data);
            me.mirVisita.idVisita = response.data.data.id;
            me.mirVisita.idPaziente = response.data.data.idPaziente;
            me.showModalLoading = false;
            me.salvaMirVisita();
          })
          .catch((error) => {
            me.showModalLoading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                console.log(value);
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
    salvaMirVisita() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathMir;
      axios
        .post(link, JSON.stringify(me.mirVisita))
        .then((response) => {
          me.mirVisita = response.data.data;
          // me.saveSuccess(false, response.data.data.id);
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk("Richiesta spirografia effettuata", {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {
              me.disableCampi = true;
              // me.$refs.MirListComponent.loadData();
            })
            .catch((err) => {
              console.log(err);
            });
          me.mirVisita = { idVisita: "", postazioneRichiesta: "", idPaziente: "" };
        })
        .catch((error) => {
          console.log(error);
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onUpdateBase64() {},
    onUpdateLista(data) {
      let me = this;
      me.listaRichieste = data;
      if (me.listaRichieste.length > 0) {
        me.disableCampi = true;
        if (me.listaRichieste[0].statoAttuale !== "IN VISITA") {
          me.disableCampi = false;
        }
      }
    },
  },
};
</script>

<style></style>
