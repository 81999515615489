<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="false" :linkedit="linkedit" :linkback="linkback" :data="jsonData" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-form-section">
                    <h4>Anagrafica</h4>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Nome</label>
                            <span class="sa-data">{{ jsonData.nome }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Cognome</label>
                            <span class="sa-data">{{ jsonData.cognome }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Codice Fiscale</label>
                            <span class="sa-data">{{ jsonData.identificativo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Data Nascita</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataNascita) }}</span>
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sesso</label>
                            <span class="sa-data">{{ jsonData.sesso }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Provincia di nascita</label>
                            <span class="sa-data">{{ jsonData.provinciaNascita }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Comune Nascita</label>
                            <span class="sa-data">{{ jsonData.comuneNascita }}</span>
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="sa-form-section">
                    <h4>Residenza</h4>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Indirizzo</label>
                            <span class="sa-data">{{ jsonData.indirizzoResidenza }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Provincia Residenza</label>
                            <span class="sa-data">{{ jsonData.provinciaResidenza }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Comune Residenza</label>
                            <span class="sa-data">{{ jsonData.comuneResidenza }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">Cap Residenza</label>
                            <span class="sa-data">{{ jsonData.capResidenza }}</span>
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="sa-form-section">
                    <h4>Contatti</h4>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Telefono</label>
                            <span class="sa-data">{{ jsonData.telefono }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Email</label>
                            <span class="sa-data">{{ jsonData.email }}</span>
                        </b-col>
                    </b-row>
                </div>
                <hr />
                <div class="sa-form-section">
                    <h4>Note</h4>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <span class="sa-data">{{ jsonData.note }}</span>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/atleti/edit/" + me.id;
        me.loadData();
    },
    data() {
        return {
            showmolalloading: false,
            linkback: "/atleti",
            linkedit: null,
            pathResource: "/atleti",
            jsonData: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
            me.showmolalloading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
                return "---";
            }
        },
    },
};
</script>
