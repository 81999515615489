<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label
                    ><span class="sa-data"> {{ formatDateTime(jsonData.dataEvento) }}</span></b-col
                >
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblHeartAttack')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.tipo) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="3" lg="3" xl="3"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.sede) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="3" lg="3" xl="3"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblClinicPresentation") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.presentazioneClinica) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="3" lg="3" xl="3"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPainPattern") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.patternDelDolore) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStartOfSymptonsDate") }}</label
                    ><span class="sa-data"> {{ formatDateTime(jsonData.dataOraInizioSintomi) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStartOfTherapyDate") }}</label
                    ><span class="sa-data"> {{ formatDateTime(jsonData.dataOraInizioTerapia) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRiperfusionTherapy") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.terapiaDiRiperfusione) }}</span></b-col
                >
            </b-row>
            <hr />
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPrecoronaryTime") }}</label
                    ><span class="sa-data"> {{ jsonData.tempoPrecoronarico }}</span></b-col
                >
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblKillipClass") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.classeKillip) }}</span></b-col
                >
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCPK") }}</label
                    ><br />
                    <b-row style="margin-top: -5px">
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <span :class="setColor(jsonData.checkCpk)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkCpk)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            {{ jsonData.piccoCpk }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCKMBMass") }}</label
                    ><br />
                    <b-row style="margin-top: -5px">
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <span :class="setColor(jsonData.checkCkMbMassa)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkCkMbMassa)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            {{ jsonData.piccoCkMbMassa }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTroponineT") }}</label
                    ><br />
                    <b-row style="margin-top: -5px">
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <span :class="setColor(jsonData.checkTroponinaT)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkTroponinaT)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            {{ jsonData.piccoTroponinaT }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMioglobine") }}</label
                    ><br />
                    <b-row style="margin-top: -5px">
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <span :class="setColor(jsonData.checkMioglobina)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkMioglobina)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            {{ jsonData.piccoMioglobina }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCKMBActivity") }}</label
                    ><br />
                    <b-row style="margin-top: -5px">
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <span :class="setColor(jsonData.checkMioglobina)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkMioglobina)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            {{ jsonData.piccoCkMbAttivita }}
                        </b-col>
                    </b-row>
                </b-col>
                <b-col
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTroponineI") }}</label
                    ><br />
                    <b-row style="margin-top: -5px">
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            <span :class="setColor(jsonData.checkTroponinaI)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkTroponinaI)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                            {{ jsonData.piccoTroponinaI }}
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAntiplateletAgents") }}</label
                    ><br />
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkAntiaggrAsa)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkAntiaggrAsa)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblASA") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkAntiaggrTriclopidina)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkAntiaggrTriclopidina)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTriclopidina") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkAntiaggrIndobufene)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkAntiaggrIndobufene)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndobufene") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkAntiaggrGp)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkAntiaggrGp)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGpInib") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkAntiaggrAltro)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkAntiaggrAltro)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAceInibitors") }}</label>
                        </b-col>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkAceInibitori)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkAceInibitori)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAceInibitors") }}</label></b-col
                        >
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEparine") }}</label
                    ><br />
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkEparinaSodica)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkEparinaSodica)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSodic") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkEparinaCalcica)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkEparinaCalcica)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCalcic") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkEparinaLmwh)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkEparinaLmwh)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLMWH") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col
                            ><label style="margin-top: 10px" class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCaAntagonist") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkCaAntagonistiDiltiazem)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkCaAntagonistiDiltiazem)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiltiazem") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkCaAntagonistiVerapamil)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkCaAntagonistiVerapamil)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVerapamil") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkCaAntagonistiDiidropiridinici)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkCaAntagonistiDiidropiridinici)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiidropiridinici") }}</label></b-col
                        >
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblBetaBlockers") }}</label
                    ><br />
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkBetaBloccantiMetoprololo)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkBetaBloccantiMetoprololo)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMetoprololo") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkBetaBloccantiAtenololo)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkBetaBloccantiAtenololo)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtenololo") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkBetaBloccantiCarvedilolo)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkBetaBloccantiCarvedilolo)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCarvedilolo") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkBetaBloccantiAltro)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkBetaBloccantiAltro)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label></b-col
                        >
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblNitroderivatives") }}</label>
                        </b-col>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkNitroderivati)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkNitroderivati)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNitroderivatives") }}</label></b-col
                        >
                    </b-row>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHemorrhage") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.emorragie) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArrhythmias") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.aritmie) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBlocks") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.blocchi) }}</span>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkEdemaPolmonareAcuto)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkEdemaPolmonareAcuto)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAcutePulmonaryEdema") }}</label></b-col
                        >
                    </b-row>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkRotturaDisfPapillare)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkRotturaDisfPapillare)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPapillarBreakDisfunction") }}</label></b-col
                        >
                    </b-row>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkShock)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkShock)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblShock") }}</label></b-col
                        >
                    </b-row>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkDifettoInterventricolare)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkDifettoInterventricolare)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInterventricularDefection") }}</label></b-col
                        >
                    </b-row>
                </b-col>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="12" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.checkRianimazioneCardiopolmonare)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.checkRianimazioneCardiopolmonare)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="12" sm="10" md="10" lg="10" xl="10"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCardiopulmonaryResuscitation") }}</label></b-col
                        >
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblInstableAnginaClinicalInstrumentalData')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAppearanceModality") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.modalitaDiComparsa) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBraunwaldClassification") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.classificazioneDiBraunwald) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxDurationAngor") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.durataMaxAngor) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblReactiveCProtein") }}</label
                    ><span class="sa-data"> {{ jsonData.proteinaCReattiva }}</span></b-col
                >
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTroponineT") }}</label
                    ><span class="sa-data"> {{ jsonData.troponinaT }}</span></b-col
                >
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTroponineI") }}</label
                    ><span class="sa-data"> {{ jsonData.troponinaI }}</span></b-col
                >
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEcgAlterationDuringAngor") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.alterazioniEcgDuranteAngor) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTherapeuticInterventions") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.interventiTerapeutici) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDecorso24h") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.decorsoA24h) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            pathResource: "/cardiodaticlinicistrumentaliric",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
