import Profilo from "../";

const routes = [{
    name: "ProfiloView",
    path: "/profilo/view/:id",
    component: Profilo.ProfiloView
  },
  {
    name: "ProfiloEdit",
    path: "/profilo/edit/:id",
    component: Profilo.ProfiloEdit
  },
];

export default routes;