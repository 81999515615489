import ContabilitaFatturazioneList from "./pages/ContabilitaFatturazioneList.vue";
import ContabilitaFatturazioneView from "./pages/ContabilitaFatturazioneView.vue";
import ContabilitaFatturazioneEdit from "./pages/ContabilitaFatturazioneEdit.vue";
import TipiDocumentoList from "./components/TipiDocumentoList.vue";
import TipiDocumentoView from "./components/TipiDocumentoView.vue";
import TipiDocumentoEdit from "./components/TipiDocumentoEdit.vue";
import CausaliDocumentoList from "./components/CausaliDocumentoList.vue";
import CausaliDocumentoView from "./components/CausaliDocumentoView.vue";
import CausaliDocumentoEdit from "./components/CausaliDocumentoEdit.vue";
import FatturazioneNumeratoriList from "./components/FatturazioneNumeratoriList.vue";
import FatturazioneNumeratoriView from "./components/FatturazioneNumeratoriView.vue";
import FatturazioneNumeratoriEdit from "./components/FatturazioneNumeratoriEdit.vue";

export default {
    ContabilitaFatturazioneList,
    ContabilitaFatturazioneView,
    ContabilitaFatturazioneEdit,
    TipiDocumentoList,
    TipiDocumentoView,
    TipiDocumentoEdit,
    CausaliDocumentoList,
    CausaliDocumentoView,
    CausaliDocumentoEdit,
    FatturazioneNumeratoriList,
    FatturazioneNumeratoriView,
    FatturazioneNumeratoriEdit,
};
