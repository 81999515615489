<template>
    <div style="display: inline-flex; padding-bottom: 0px" icon="info-circle">
        <b-icon class="ml-1 sa-b-icon-1" style="cursor: pointer" icon="info-circle" aria-hidden="true" @click="onClickInfoAndamento()" v-b-modal.modalMisurazioni></b-icon>
        <b-modal modal-class="sa-modal-height" ref="mdlGraphicMeasurements" size="xl" style="height: 100%" ok-only>
            <template #modal-title>
                {{ misuraLabel }}
            </template>
            <chart-component :chartData="chartData"></chart-component>
        </b-modal>
    </div>
</template>

<script>
import axios from "axios";
// import moment from "moment";
import ChartComponent from "../../../../utility/components/ChartComponent.vue";
export default {
    props: {
        idPaziente: { type: String },
        misura: { type: String },
        tipoEvento: { type: String },
        gruppo: { type: String },
        misuraLabel: { type: String },
        unitaMisura: { type: String },
    },
    components: { ChartComponent },
    data() {
        return {
            fontSize: 12,
            pathRest: "/pazienteeventi/storicomisure",
            chartData: { titolo: null, xAxis: [], series: [], unitaMisura: "" },
        };
    },
    methods: {
        onClickInfoAndamento() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest;
            axios
                .get(link, { params: { idPaziente: me.idPaziente, misura: me.misura, tipoEvento: me.tipoEvento, gruppo: me.gruppo } })
                .then((response) => {
                    me.setChartData(response.data.data);
                })
                .catch(() => {});
        },
        setChartData(data) {
            let me = this;
            let values = [];
            me.chartData.xAxis = [];
            me.chartData.titolo = this.misuraLabel;
            me.chartData.unitaMisura = this.unitaMisura;
            data.forEach((element) => {
                // me.chartData.xAxis.push(moment(new Date(element.istant)).format("DD-MM-YYYY"));
                // values.push(Number(element.value));
                values.push([new Date(element.istant), Number(element.value)]);
            });
            console.log(me.chartData.xAxis);
            me.chartData.series.push({
                name: me.misuraLabel,
                type: "line",
                data: values,
                markPoint: {
                    data: [
                        { type: "max", name: "Max" },
                        { type: "min", name: "Min" },
                    ],
                },
                markLine: {
                    data: [{ type: "average", name: "Avg" }],
                },
            });
            me.$refs["mdlGraphicMeasurements"].show();
        },
    },
};
</script>

<style>
.sa-b-icon-1 {
    font-size: 1rem;
}

/**
 * The default size is 600px×400px, for responsive charts
 * you may need to set percentage values as follows (also
 * don't forget to provide a size for the container).
 */
/* .echarts {
    width: 100%;
    height: 100%;
} */
</style>
