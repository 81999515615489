<template>
    <sa-page-layout :btnBackVisible="false" :btnEditVisible="false" :btnRefreshVisible="true" :showModalLoading="showModalLoading" @refresh="onRefresh">
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(nome)="{ item }">
                        <router-link class="sa-edit-link" :to="'/gruppi/view/' + item.id">{{ item.nome }}</router-link>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-button size="sm" variant="outline-secondary" @click="onEditMenu(row.item, row.index, $event.target)" class="mr-1 no-text">
                            <b-icon icon="list"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            pathResourceGruppi: "/gruppi",
            showModalLoading: false,
            currentPage: 1,
            perPage: 100,
            items: [],
            filter: {},
            fields: [
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 3rem", tdClass: "text-center" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadGruppi();
    },
    methods: {
        loadGruppi() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGruppi;
            axios.get(link).then((response) => {
                me.items = response.data.data;
                me.showModalLoading = false;
            });
        },
        onRefresh() {},
        onEditMenu(item) {
            this.$router.push("/configurazioneeventimenu/edit/" + item.id);
        },
    },
};
</script>

<style></style>
