<template>
    <div class="sa-calendar-week">
        <div :class="getAlternateColor(i)" v-for="(giorni, i) in disponibilita" :key="i">
            <p style="font-weight: bold">{{ getDay(giorni.giorno) }}</p>
            <div class="sa-calendar-week-slot" v-for="(disponibilita, x) in giorni.disponibilita" :key="getIndexDisponibilita(disponibilita, x)">
                <div class="sa-calendar-week-slot-hour">
                    <span class="sa-calendar-week-slot-hour-start">{{ disponibilita.dalleOre }}</span>
                    <span class="sa-calendar-week-slot-hour-end">{{ disponibilita.alleOre }}</span>
                </div>
                <div class="sa-calendar-week-slot-text">
                    <agenda-slot :idAgenda="idAgenda" :costo="costo" :descrizionePrestazione="descrizionePrestazione" :codicePrestazione="codicePrestazione" :index="getIndexDisponibilita(disponibilita, x)" :slotDisponibilita="disponibilita" @update="updateData"></agenda-slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import AgendaSlot from "./AgendaSlot.vue";

export default {
    components: { AgendaSlot },
    props: {
        disponibilita: Array,
        codicePrestazione: String,
        descrizionePrestazione: String,
        costo: Number,
        idAgenda: String,
    },
    methods: {
        getAlternateColor(value) {
            if (value % 2 === 0) {
                return "sa-calendar-week-day alternate";
            } else {
                return "sa-calendar-week-day";
            }
        },
        getDay(value) {
            return moment(value).format("dddd DD ");
        },
        getIndex(index) {
            return "week_" + index;
        },
        getIndexDisponibilita(disponibilita, index) {
            return "week_" + disponibilita.dataRiferimento + "_" + index;
        },
        updateData() {
            let me = this;
            me.$emit("update");
        },
    },
};
</script>
<style scoped>
.sa-calendar-week {
    border-top: 1px solid #d0d0d0;
    display: flex;
    outline: none;
}
.sa-calendar-week-day {
    /*border: 2px solid red;*/
    height: 100%;
    flex: auto;
}
.sa-calendar-week-slot {
    height: 3rem;
}
.sa-calendar-week-slot-hour {
    width: 5rem;
    float: inline-start;
    height: 100%;
    border-bottom: 1px solid #d0d0d0;
}
.sa-calendar-week-slot-hour-start {
    display: inline-block;
    font-size: 0.8rem;
}
.sa-calendar-week-slot-hour-end {
    display: inline-block;
    font-size: 0.7rem;
    width: 100%;
    text-align: right;
    padding-right: 0.2rem;
}
.sa-calendar-week-slot-text {
    border-left: 1px solid #d0d0d0;
    border-bottom: 1px solid #d0d0d0;
    border-right: 1px solid #d0d0d0;
    margin-left: 5rem;
    padding-left: 0.2rem;
    height: 100%;
}

.sa-calendar-week-slot-text div {
    height: 100%;
    display: inline-flex;
    vertical-align: middle;
    align-items: center;
}
.sa-calendar-week-day.alternate {
    background-color: #f0f0f0;
}
</style>
