<template>
    <b-container fluid>
        <b-modal ref="mdlRicercaAnagrafica" id="mdlRicercaAnagrafica" size="lg" @ok="onOkMdlAnagrafica">
            <b-form @submit.prevent="onSubmitAnagrafica">
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="4">
                        <label class="sa-label-data"> {{ getLabelTraduora("adi.lblFiscalCode") }}</label>
                        <b-form-input
                            maxlength="16"
                            v-model="filtro.codiceFiscale"
                            :formatter="
                                (value) => {
                                    return new String(value).toUpperCase();
                                }
                            "
                        ></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="4">
                        <label class="sa-label-data"> {{ getLabelTraduora("adi.lblFirstName") }}</label>
                        <b-form-input v-model="filtro.nome"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="4">
                        <label class="sa-label-data"> {{ getLabelTraduora("adi.lblSecondName") }}</label>
                        <b-form-input v-model="filtro.cognome"></b-form-input>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onResetAnagrafica" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" select-mode="single" selectable @row-dblclicked="onDoubleClickRow" @row-selected="onRowSelected">
                    <template #cell(selezionato)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selezionato</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Non Selezionato</span>
                        </template>
                    </template>
                </b-table>
            </div>
        </b-modal>
    </b-container>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
import axios from "axios";
export default {
    props: {
        codiceFiscale: String,
    },
    mixins: [UtilityMixin],
    computed: {
        rows() {
            return this.items.length;
        },
    },
    mounted() {
        let me = this;
        me.loadAnagrafica();
        this.$root.$on("bv::modal::show", () => {
            me.filtro.codiceFiscale = this.codiceFiscale;
        });
    },
    data() {
        return {
            filtro: {
                nome: "",
                cognome: "",
                codiceFiscale: "",
            },
            selezionato: [],
            pathResourceAnagrafica: "/anagrafica",
            currentPage: 1,
            perPage: 10,
            fields: [
                {
                    label: "",
                    key: "selezionato",
                },
                {
                    label: "Codice Fiscale",
                    key: "identificativo",
                    sortable: true,
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    sortable: true,
                },
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                },
                {
                    label: "Data Nascita",
                    key: "dataNascita",
                    sortable: true,
                    formatter: function (value) {
                        return UtilityMixin.methods.formatDate(value);
                    },
                },
            ],
            items: [],
        };
    },
    methods: {
        loadAnagrafica() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAnagrafica + "?forPage=" + me.perPage + "&page=" + me.currentPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onOkMdlAnagrafica() {
            let me = this;
            this.$emit("update", me.selezionato);
        },
        onRowSelected(rows) {
            this.selezionato = rows;
        },
        onDoubleClickRow() {
            let me = this;
            me.onOkMdlAnagrafica();
            this.$root.$emit("bv::hide::modal", "mdlRicercaAnagrafica", "");
        },
        onResetAnagrafica() {
            let me = this;
            me.filtro = {
                cognome: "",
                nome: "",
                codiceFiscale: "",
            };
            me.loadAnagrafica();
        },
        onSubmitAnagrafica() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadAnagrafica();
        },
    },
};
</script>

<style></style>
