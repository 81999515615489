<template>
  <sa-page-layout :btnSaveNotBackVisible="btnSaveNotBackVisible" :linkNotBack="linkNotBack" @refresh="onRefresh" :btnPrintVisible="true" :backAfterSave="backAfterSave" :btnBackVisible="true" :pathResource="pathResource" :showModalLoading="showModalLoading" :data="jsonData" :linkback="linkback" :linkPrintData="linkPrintData" @aftersave="onAfterSave" class="sa-no-space">
    <template slot="toolbar-title">
      <adi-anagrafica-component :id="id"></adi-anagrafica-component>
    </template>
    <template slot="toolbar">
      <b-button v-if="btnAllegatiVisible && addAllegati" variant="outline-success btn-toolbar sa-margin-left float-sm-left" v-b-modal.mdlAllegati>
        <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
        {{ getLabelTraduora("patient.afa.btnNewAllegato") }}
      </b-button>
      <b-button v-if="jsonData.id !== '-1' && jsonData.id !== '' && cambiaStatoVisible" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(jsonData.id, 'INVIATA')"> Invia </b-button>
      <b-button v-if="jsonData.id !== '-1' && jsonData.id !== '' && cambiaStatoSpecialista" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(jsonData.id, 'INVIATA SPECIALISTA')"> Invia Specialista</b-button>
      <b-button v-if="jsonData.id !== '-1' && jsonData.id !== '' && (cambiaStatoVisible || cambiaStatiPuaInvioVisible)" size="sm" variant="btn btn-outline-danger waves-effect float-sm-left" @click="onCambiaStato(jsonData.id, 'ELIMINATA')"> Elimina </b-button>
      <b-button v-if="jsonData.id !== '-1' && jsonData.id !== '' && cambiaStatoPuaVisible" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(jsonData.id, 'ACCETTATA PUA')"> Accetta </b-button>
      <b-button v-if="jsonData.id !== '-1' && jsonData.id !== '' && cambiaStatoPuaVisible" size="sm" variant="btn btn-outline-danger waves-effect float-sm-left" @click="onCambiaStato(jsonData.id, 'RIFIUTATA PUA')"> Rifiuta </b-button>
      <b-button v-if="jsonData.id !== '-1' && jsonData.id !== '' && cambiaStatiPuaInvioVisible" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onInviaStruttura()"> Seleziona Struttura </b-button>
      <!-- <b-button v-if="jsonData.id !== '-1' && jsonData.id !== '' && cambiaStatiPuaInvioVisible" size="sm" variant="btn btn-outline-success waves-effect float-sm-left" @click="onCambiaStato(jsonData.id, 'ACCETTATA PUA')"> Invia A Struttura </b-button> -->
      <!-- <adi-proposta-accesso-stati-compnent v-if="jsonData.id !== '-1'" :row="jsonData" :pathCambiaStato="pathCambiaStato" @update="onUpdateStato" :isMenu="false"></adi-proposta-accesso-stati-compnent> -->
    </template>
    <template slot="table-body">
      <b-tabs content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%; padding-bottom: 25px" fill @input="onTabInput">
        <b-tab lazy :title="getLabelTraduora('adi.lblAccessProposal')" active @click="controlPermission('adiproposteaccesso')">
          <proposta-di-accesso-component :idPropostaAccesso="idPropostaAccesso" :utente="utente" :key="keyPropostaAccesso" ref="propostaAccesso" @update="onUpdatePropostaAccesso" @showmodalloaging="onShowModalLoading" :linkback="linkback" :linkPrintData="linkPrintData"></proposta-di-accesso-component>
        </b-tab>
        <b-tab lazy :title="getLabelTraduora('adi.lblSheetASVaMA')" :disabled="checkTabActive(schedaSvamaADisabilitata, tabSvamaA)" @click="controlPermission('adischedeasvama')">
          <adi-svama-scheda-a-component ref="schedaSvamaA" :id="idSvamaA" :utente="utente" :key="idSvamaA" @update="onUpdateJsonDataTabs" :linkPrintData="linkPrintData"></adi-svama-scheda-a-component>
        </b-tab>
        <b-tab lazy :title="getLabelTraduora('adi.lblSvamdiSheetA')" :disabled="checkTabActive(schedaSvamdiADisabilitata, tabSvamdiA)" @click="controlPermission('adisvamadischedea')">
          <adi-svamdi-scheda-a-component :id="idSvamdiSchedaA" :utente="utente" :key="idSvamdiSchedaA" @update="onUpdateJsonDataTabs"></adi-svamdi-scheda-a-component>
        </b-tab>
        <b-tab lazy :title="getLabelTraduora('adi.lblSheetBSVaMA')" :disabled="checkTabActive(schedaDisabilitata, tabSvamaB)" @click="controlPermission('adisvamaschedeb')">
          <adi-svama-scheda-b-component @update="onUpdateJsonDataTabs" :id="idSvamaSchedaB" :utente="utente"></adi-svama-scheda-b-component>
        </b-tab>
        <b-tab title="S.Va.M.A Scheda D" :disabled="checkTabActive(schedaSvamaDDisabilitata, tabSvamaD)" @click="controlPermission('adisvamaschedad')">
          <!-- <adi-svama-scheda-b-component @update="onUpdateJsonDataTabs"></adi-svama-scheda-b-component> -->
          <adi-svama-scheda-d-component @update="onUpdateJsonDataTabs" :id="idSvamaSchedaD" :key="idSvamaSchedaD" :utente="utente"></adi-svama-scheda-d-component>
        </b-tab>
        <b-tab title="S.Va.M.DI Scheda D" :disabled="checkTabActive(schedaSvamdiDDisabilitata, tabSvamdiD)" @click="controlPermission('adisvamdischedad')">
          <!-- <adi-svama-scheda-b-component @update="onUpdateJsonDataTabs"></adi-svama-scheda-b-component> -->
          <adi-svamdi-scheda-d-component @update="onUpdateJsonDataTabs" :id="idSvamdiSchedaD" :key="idSvamdiSchedaD" :utente="utente"></adi-svamdi-scheda-d-component>
        </b-tab>
        <b-tab lazy :title="getLabelTraduora('adi.lblPainTherapiesPalliativeCareAccessReportForm')" :disabled="checkTabActive(schedaDisabilitata, tabTerapieCure)" @click="controlPermission('adischedaterapiacure')">
          <terapie-dolore-cure-palliative-component ref="schedaTerapiaCure" :numeroProtocollo="numeroProtocollo" :utente="utente" :dataProtocollo="dataProtocollo" :id="idTerapiaCure" :key="idTerapiaCure" @update="onUpdateJsonDataTabs" :linkPrintData="linkPrintData"></terapie-dolore-cure-palliative-component>
        </b-tab>
        <b-tab lazy :title="getLabelTraduora('adi.lblNAD')" :disabled="checkTabActive(schedaDisabilitata, tabRichiestaAttivazioneNad)" @click="controlPermission('adirichiesteattivazionenad')">
          <richiesta-attivazione-nad-component ref="richiestaAttivazioneNad" :numeroProtocollo="numeroProtocollo" :utente="utente" :dataProtocollo="dataProtocollo" :id="idRichiestaAttivazioneNad" :key="idRichiestaAttivazioneNad" @update="onUpdateJsonDataTabs" :linkPrintData="linkPrintData"></richiesta-attivazione-nad-component>
        </b-tab>
        <b-tab lazy :title="getLabelTraduora('adi.lblSpecialistHomeVisit')" :disabled="checkTabActive(schedaDisabilitata, tabRichiestaVisitaDomiciliare)" @click="controlPermission('adirichiestevisitadomiciliare')">
          <richiesta-visita-specialistica-domiciliare-component :numeroProtocollo="numeroProtocollo" :utente="utente" :dataProtocollo="dataProtocollo" ref="richiestaVisitaDomiciliare" :id="idRichiestaVisitaDomiciliare" :key="idRichiestaVisitaDomiciliare" @update="onUpdateJsonDataTabs" :linkPrintData="linkPrintData"></richiesta-visita-specialistica-domiciliare-component>
        </b-tab>
        <b-tab title="Allegati" :disabled="checkTabActive(schedaDisabilitata, tabAllegati)" @click="activeAllegato()">
          <allegato-model-component :idRisorsa="id" @updateFiles="onUpdateFile()" :jsonData="jsonData" tipoDocumento="ADIPROPOSTAACCESSO"></allegato-model-component>
        </b-tab>
        <b-tab title="Prest. Res. e Semi Res." :disabled="checkTabActive(schedaDisabilitata, tabPaiPrestResSemiRess)" @click="controlPermission('adipaiprestazioniresidenzialisemiresidenziali')">
          <adi-pai-prestazioni-residenziali-semi-residenziali-list-component :idPropostaAccesso="id" :utente="utente"></adi-pai-prestazioni-residenziali-semi-residenziali-list-component>
        </b-tab>
        <b-tab title="Cure Domiciliari" :disabled="checkTabActive(schedaDisabilitata, tabPaiCureDomiciliari)" @click="controlPermission('adipaicuredomiciliari')">
          <!-- <adi-pai-prestazioni-residenziali-semi-residenziali-list-component :idPropostaAccesso="id" :utente="utente"></adi-pai-prestazioni-residenziali-semi-residenziali-list-component> -->
          <adi-pai-cure-domiciliari-list-component :idPropostaAccesso="id" :utente="utente"></adi-pai-cure-domiciliari-list-component>
        </b-tab>
      </b-tabs>

      <b-modal ref="mdlStrutturaSelezionata" id="mdlStrutturaSelezionata" size="md" style="height: 100%" @ok="onOkCambiaStatoSelezioneStruttura" title="Seleziona Struttura" :no-close-on-backdrop="true">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-label-data">Struttura</label>
            <b-form-select v-model="codiceStrutturaSelezionata" :options="struttureDestinazioneOptions" :value="''" value-field="codiceStruttura" text-field="descrizioneStruttura"></b-form-select>
          </b-col>
        </b-row>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
// import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
import axios from "axios";
import PropostaDiAccessoComponent from "../components/AdiPropostaDiAccessoComponent.vue";
import AdiSvamaSchedaAComponent from "../components/AdiSvamaSchedaAComponent.vue";
import AdiSvamdiSchedaAComponent from "../components/AdiSvamdiSchedaAComponent.vue";
import AdiSvamaSchedaBComponent from "../components/AdiSvamaSchedaBComponent.vue";
import AdiSvamaSchedaDComponent from "../components/AdiSvamaSchedaDComponent.vue";
import AdiSvamdiSchedaDComponent from "../components/AdiSvamdiSchedaDComponent.vue";
import TerapieDoloreCurePalliativeComponent from "../components/AdiTerapieDoloreCurePalliativeComponent.vue";
import RichiestaAttivazioneNadComponent from "../components/AdiRichiestaAttivazioneNadComponent.vue";
import RichiestaVisitaSpecialisticaDomiciliareComponent from "../components/AdiRichiestaVisitaSpecialisticaDomiciliareComponent.vue";
import AdiAnagraficaComponent from "../components/AdiAnagraficaComponent.vue";
import AllegatoModelComponent from "../../utility/components/AllegatoModelComponent.vue";
import AdiPaiPrestazioniResidenzialiSemiResidenzialiListComponent from "../components/AdiPaiPrestazioniResidenzialiSemiResidenzialiListComponent.vue";
import AdiPaiCureDomiciliariListComponent from "../components/AdiPaiCureDomiciliariListComponent.vue";
// import AdiPropostaAccessoStatiCompnent from "../components/AdiPropostaAccessoStatiCompnent.vue";
// import PrintComponent from "../../../utility/components/PrintComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  watch: {
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  mixins: [UtilityMixin],
  components: {
    SaPageLayout,
    AdiAnagraficaComponent,
    PropostaDiAccessoComponent,
    AdiSvamaSchedaAComponent,
    AdiSvamdiSchedaAComponent,
    AdiSvamaSchedaBComponent,
    TerapieDoloreCurePalliativeComponent,
    RichiestaAttivazioneNadComponent,
    RichiestaVisitaSpecialisticaDomiciliareComponent,
    AdiSvamaSchedaDComponent,
    AdiSvamdiSchedaDComponent,
    AllegatoModelComponent,
    AdiPaiPrestazioniResidenzialiSemiResidenzialiListComponent,
    AdiPaiCureDomiciliariListComponent,
    // AdiPropostaAccessoStatiCompnent,
  }, //PrintComponent
  // components: { SaPageLayout, PropostaDiAccesso, SchedaA, SchedaB, SchedaSegnalazioneAccessoTerapieDoloreCurePalliative, RichiestaAttivazioneNad, RichiestaVisitaSpecialisticaDomiciliare,  }, //PrintComponent
  data() {
    return {
      tabIndex: 0,
      pathResource: null,
      linkBackRadice: "/adipazienti",
      pathCambiaStato: "/adiproposteaccesso/aggiornaStato",
      pathResourceStruttureDestinazione: "/adistrutturadestinzazione",
      linkback: "",
      linkNotBack: null,
      linkPrintData: null,
      showModalLoading: false,
      backAfterSave: false,
      id: "-1",
      index: 0,
      idSvamaA: "-1",
      idTerapiaCure: "-1",
      idRichiestaAttivazioneNad: "-1",
      idSvamdiSchedaA: "-1",
      idRichiestaVisitaDomiciliare: "-1",
      idSvamaSchedaD: "-1",
      idSvamdiSchedaD: "-1",
      idSvamaSchedaB: "-1",
      codiceStrutturaSelezionata: "",
      struttureDestinazioneOptions: [],
      pathResourceStampa: null,
      key: 0,
      keyPropostaAccesso: 0,
      schedaDisabilitata: false,
      schedaSvamaADisabilitata: false,
      schedaSvamdiADisabilitata: false,
      schedaSvamaDDisabilitata: false,
      schedaSvamdiDDisabilitata: false,
      numeroProtocollo: null,
      dataProtocollo: 0,
      idPropostaAccesso: "-1",
      tabPropostaAccesso: false,
      tabSvamaA: false,
      tabSvamdiA: false,
      tabSvamaB: false,
      tabSvamdiD: false,
      tabSvamaD: false,
      tabTerapieCure: false,
      tabAllegati: false,
      tabPaiPrestResSemiRess: false,
      tabPaiCureDomiciliari: false,
      addAllegati: false,
      tabRichiestaAttivazioneNad: false,
      tabRichiestaVisitaDomiciliare: false,
      dataNascita: null,
      fonte: "",
      jsonData: { id: -1, paziente: {} },
      btnAllegatiVisible: false,
      btnSaveNotBackVisible: false,
      cambiaStatoVisible: false,
      cambiaStatoPuaVisible: false,
      cambiaStatoSpecialista: false,
      cambiaStatiPuaInvioVisible: false,
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.fonte = this.$route.query.fonte;
    // console.log(me.fonte);
    if (me.fonte === null || me.fonte === undefined || me.fonte === "undefined" || me.fonte === "") {
      me.fonte = "/richieste";
    }
    me.linkback = me.linkBackRadice + me.fonte;
    let path = this.$route.path.replace(/\/edit\/.*/, "");
    me.linkNotBack = path + "{editid}?";
    me.controlPermission("adiproposteaccesso");
    me.impostaPermessi();
  },
  beforeMount() {
    // console.log();
    // this.$refs["tabs"];
  },
  methods: {
    impostaPermessi() {
      let me = this;
      // console.log(me.jsonData);
      if (me.id === -1) {
        me.btnSaveNotBackVisible = UtilityMixin.methods.verificaPermessi(me.utente, "adiproposteaccesso", 2);
      } else {
        me.btnSaveNotBackVisible = UtilityMixin.methods.verificaPermessi(me.utente, "adiproposteaccesso", 4);
      }

      me.tabPropostaAccesso = UtilityMixin.methods.verificaPermessi(me.utente, "adiproposteaccesso", 1);
      me.tabSvamaA = UtilityMixin.methods.verificaPermessi(me.utente, "adischedeasvama", 1);
      me.tabSvamdiA = UtilityMixin.methods.verificaPermessi(me.utente, "adisvamadischedea", 1);
      me.tabSvamaB = UtilityMixin.methods.verificaPermessi(me.utente, "adisvamaschedeb", 1);
      me.tabSvamdiD = UtilityMixin.methods.verificaPermessi(me.utente, "adisvamdischedad", 1);
      me.tabSvamaD = UtilityMixin.methods.verificaPermessi(me.utente, "adisvamaschedad", 1);
      me.tabTerapieCure = UtilityMixin.methods.verificaPermessi(me.utente, "adischedaterapiacure", 1);
      me.tabRichiestaAttivazioneNad = UtilityMixin.methods.verificaPermessi(me.utente, "adirichiesteattivazionenad", 1);
      me.tabRichiestaVisitaDomiciliare = UtilityMixin.methods.verificaPermessi(me.utente, "adirichiestevisitadomiciliare", 1);
      me.tabAllegati = UtilityMixin.methods.verificaPermessi(me.utente, "allegati", 1);
      me.addAllegati = false;
      let fonte = this.$route.query.fonte;
      if (fonte === null || fonte === undefined || fonte === "undefined" || fonte === "") {
        fonte = "/richieste";
      }
      if (fonte === "/richieste") {
        me.addAllegati = UtilityMixin.methods.verificaPermessi(me.utente, "allegati", 2);
      }
      me.tabPaiPrestResSemiRess = UtilityMixin.methods.verificaPermessi(me.utente, "adipaiprestazioniresidenzialisemiresidenziali", 1);
      me.tabPaiCureDomiciliari = UtilityMixin.methods.verificaPermessi(me.utente, "adipaicuredomiciliari", 1);
    },
    onCambiaStato(riga, stato, strutturaAssegnataCodice = null, strutturaAssegnataDescrizinoe = null) {
      let messaggio = "";
      // console.log(stato);
      switch (stato) {
        case "INVIATA":
          messaggio = "inviare la richiesta?";
          break;
        case "INVIATA SPECIALISTA":
          messaggio = "inviare la richiesta allo specialista?";
          break;
        case "ACCETTATA PUA":
          messaggio = "accettare la richiesta?";
          break;
        case "RIFIUTATA PUA":
          messaggio = "rifiutare la richiesta?";
          break;
        case "INVIATA STRUTTURA":
          messaggio = "inviare alla struttura?";
          break;
      }
      this.$bvModal
        .msgBoxConfirm("Sicuro di voler " + messaggio, {
          title: "",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            let json = { idPropostaAccesso: riga, stato: stato, strutturaAssegnataCodice: strutturaAssegnataCodice, strutturaAssegnataDescrizinoe: strutturaAssegnataDescrizinoe };
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathCambiaStato;
            axios
              .post(link, json)
              .then((response) => {
                this.$bvToast.toast("Cambio Stato: " + response.data.data.stato, {
                  title: this.titleModal,
                  variant: "success",
                  autoHideDelay: 1000,
                });
                me.jsonData.statoAttuale = response.data.data.stato;
                me.jsonData.statoAttualeDataOra = response.data.data.statoDataOra;
                me.onUpdatePropostaAccesso(me.jsonData);
                // me.$refs.propostaAccesso.loadData();
              })
              .catch((error) => {
                // me.$refs["sa-modal-loading"].close();
                let message = "";
                error.response ? error.response.data.messaggio : "Errore non gestito";
                if (error.response) {
                  message = error.response.data.messaggio ? error.response.data.messaggio : "Codice Errore: " + error.response.status + ", non gestito";
                }
                if (error.response) {
                  this.$bvModal
                    .msgBoxOk(message, {
                      title: "ATTENZIONE",
                      size: "sm",
                      okVariant: "outline-danger",
                      headerClass: "sa-msg-header-danger",
                      footerClass: "p-2 border-top-0",
                      centered: true,
                    })
                    .then(() => {})
                    .catch((err) => {
                      console.log(err);
                    });
                }
              });
            axios;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onInviaStruttura() {
      let me = this;
      // console.log(me.jsonData.id);
      // console.log("sto assegnando alla struttura");
      me.loadStrutture();
    },
    loadStrutture() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceStruttureDestinazione;
      me.struttureDestinazioneOptions = [];
      me.codiceStrutturaSelezionata = "";
      axios
        .get(link)
        .then((response) => {
          me.struttureDestinazioneOptions = response.data.data.list;
          me.struttureDestinazioneOptions.unshift({ codiceStruttura: "", descrizioneStruttura: "-Seleziona Valore-" });
          this.$refs["mdlStrutturaSelezionata"].show();
        })
        .catch((error) => {
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onOkCambiaStatoSelezioneStruttura() {
      let me = this;
      me.struttureDestinazioneOptions.forEach((element) => {
        if (me.codiceStrutturaSelezionata === element.codiceStruttura) {
          me.onCambiaStato(me.jsonData.id, "INVIATA STRUTTURA", element.codiceStruttura, element.descrizioneStruttura);
        }
      });
    },
    onUpdateStato() {
      let me = this;
      me.loadData();
    },
    checkTabActive(tabDisable, perission) {
      let returnValue = false;
      if (tabDisable) {
        returnValue = true;
      } else if (!perission) {
        returnValue = true;
      }
      return returnValue;
    },
    activeAllegato() {
      let me = this;
      if (me.addAllegati) {
        me.btnAllegatiVisible = true;
        me.cambiaStatoVisible = false;
        me.cambiaStatoPuaVisible = false;
        me.cambiaStatoSpecialista = false;
        me.btnSaveNotBackVisible = false;
        me.cambiaStatiPuaInvioVisible = false;
      }
    },
    controlPermission(sezione) {
      // console.log("Eccomi ", sezione);
      let me = this;
      // console.log(me.jsonData);
      me.btnAllegatiVisible = false;
      me.cambiaStatoVisible = false;
      me.cambiaStatoPuaVisible = false;
      me.cambiaStatoSpecialista = false;
      me.cambiaStatiPuaInvioVisible = false;
      if (sezione !== "adipaiprestazioniresidenzialisemiresidenziali" && sezione !== "adipaicuredomiciliari" && me.fonte === "/richieste") {
        me.btnSaveNotBackVisible = UtilityMixin.methods.verificaPermessi(me.utente, sezione, 2) || UtilityMixin.methods.verificaPermessi(me.utente, sezione, 4);
      } else {
        me.btnSaveNotBackVisible = false;
      }
      if (sezione === "adiproposteaccesso") {
        if (me.jsonData.id !== null && me.jsonData.id !== "" && me.jsonData.id !== "-1" && me.jsonData.id !== undefined) {
          if ((me.jsonData.statoAttuale === "IN ATTESA DI VALUTAZIONE" || me.jsonData.statoAttuale === "PRESA IN CARICO") && me.fonte === "/richieste") {
            me.cambiaStatoVisible = true;
            me.cambiaStatoSpecialista = true;
          } else if (me.jsonData.statoAttuale === "INVIATA" && me.fonte === "/richieste") {
            me.cambiaStatoPuaVisible = true;
          } else if (me.jsonData.statoAttuale === "INVIATA SPECIALISTA" && me.fonte === "/richieste") {
            me.cambiaStatoVisible = true;
          } else if (me.jsonData.statoAttuale === "ACCETTATA PUA" && me.fonte === "/richieste") {
            me.cambiaStatiPuaInvioVisible = true;
          }
        }
      }
    },
    onShowModalLoading() {
      //value
      let me = this;
      me.showModalLoading = false;
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    onUpdatePropostaAccesso(data) {
      let me = this;
      me.updataPropostaAccesso(data);
      me.numeroProtocollo = data.numeroProtocollo;
      me.dataProtocollo = data.dataProtocollo;
      me.controlPermission("adiproposteaccesso");
      // console.log(data);
    },
    updataPropostaAccesso(data) {
      let me = this;
      me.jsonData = data;
      if (me.jsonData.id) {
        me.id = me.jsonData.id;
        me.tabSelection(0);
        // me.$refs.propostaAccesso.loadData();
      }
    },
    onTabInput(index) {
      let me = this;
      me.tabSelection(index);
    },
    tabSelection(index) {
      let me = this;
      if (me.id === "-1") {
        me.backAfterSave = true;
        me.schedaDisabilitata = true;
        me.schedaSvamaADisabilitata = true;
        me.schedaSvamdiADisabilitata = true;
        me.schedaSvamaDDisabilitata = true;
        me.schedaSvamdiDDisabilitata = true;
      } else {
        me.schedaDisabilitata = false;
        if (me.jsonData.dataNascitaAnagrafica !== undefined && me.jsonData.dataNascitaAnagrafica !== null) {
          me.dataNascita = me.jsonData.dataNascitaAnagrafica;
        }
        if (me.isOverAge(me.dataNascita, 65)) {
          me.schedaSvamaADisabilitata = false;
          me.schedaSvamdiADisabilitata = true;
          me.schedaSvamaDDisabilitata = false;
          me.schedaSvamdiDDisabilitata = true;
        } else {
          me.schedaSvamaADisabilitata = true;
          me.schedaSvamdiADisabilitata = false;
          me.schedaSvamaDDisabilitata = true;
          me.schedaSvamdiDDisabilitata = false;
        }
      }
      switch (index) {
        case 0:
          me.pathResource = "/adiproposteaccesso";
          me.pathResourceStampa = "";
          // me.jsonData = this.$refs.propostaAccesso.getJsonData();
          me.linkPrintData = me.pathResource + "/print/" + me.id;
          this.controlPermission("adiproposteaccesso");
          // me.tabIndex = 4;
          // this.$refs["tabs"].currentTab = 2;
          // console.log(this.$refs["tabs"].getTabs());
          break;
        case 1:
          me.pathResource = "/adischedeasvama";
          // me.pathResourceStampa =  "/adiproposteaccesso/print/" + me.id;
          // me.pathResourceStampa = "";
          me.idSvamaA = me.id;
          me.key = me.id;
          // me.linkPrintData = me.pathResource + "/print/" + me.id;
          me.linkPrintData = "/adiproposteaccesso/print/" + me.id;

          // me.jsonData = this.$refs.schedaSvamaA.getJsonData();
          break;
        case 2:
          me.pathResource = "/adisvamadischedea";
          // me.pathResourceStampa = "";
          me.idSvamdiSchedaA = me.id;
          me.key = me.id;
          // me.linkPrintData = me.pathResource + "/print/" + me.id;
          me.linkPrintData = "/adiproposteaccesso/print/" + me.id;
          break;
        case 3:
          me.pathResource = "/adisvamaschedeb";
          me.pathResourceStampa = "";
          me.idSvamaSchedaB = me.id;
          // console.log(this.$refs.adiSvamaSchedaBBean.getJsonData());
          me.key = me.id;
          // me.linkPrintData = me.pathResource + "/print/" + me.id;
          me.linkPrintData = "/adiproposteaccesso/print/" + me.id;
          break;
        case 4:
          me.pathResource = "/adisvamaschedad";
          me.pathResourceStampa = "";
          me.idSvamaSchedaD = me.id;
          me.key = me.id;
          // me.linkPrintData = me.pathResource + "/print/" + me.id;
          me.linkPrintData = "/adiproposteaccesso/print/" + me.id;
          break;
        case 5:
          me.pathResource = "/adisvamdischedad";
          me.pathResourceStampa = "";
          me.idSvamdiSchedaD = me.id;
          me.key = me.id;
          // me.linkPrintData = me.pathResource + "/print/" + me.id;
          me.linkPrintData = "/adiproposteaccesso/print/" + me.id;
          // me.jsonData = this.$refs.schedaTerapiaCure.getJsonData();
          break;
        case 6:
          me.pathResource = "/adischedaterapiacure";
          me.pathResourceStampa = "";
          me.idTerapiaCure = me.id;
          me.key = me.id;
          // me.linkPrintData = me.pathResource + "/print/" + me.id;
          me.linkPrintData = "/adiproposteaccesso/print/" + me.id;
          // me.jsonData = this.$refs.schedaTerapiaCure.getJsonData();
          break;
        case 7:
          me.pathResource = "/adirichiesteattivazionenad";
          me.pathResourceStampa = "";
          me.idRichiestaAttivazioneNad = me.id;
          me.key = me.id;
          // me.linkPrintData = me.pathResource + "/print/" + me.id;
          me.linkPrintData = "/adiproposteaccesso/print/" + me.id;
          break;
        case 8:
          me.pathResource = "/adirichiestevisitadomiciliare";
          me.pathResourceStampa = "";
          me.idRichiestaVisitaDomiciliare = me.id;
          me.key = me.id;
          // me.linkPrintData = me.pathResource + "/print/" + me.id;
          me.linkPrintData = "/adiproposteaccesso/print/" + me.id;
          break;
        case 10:
          // console.log("tab s", me.tabIndex);
          // sessionStorage.setItem("tab", 10);
          // console.log(this.$refs["tabs"]);
          break;
        case 11:
          // console.log("tab s", me.tabIndex);
          // sessionStorage.setItem("tab", 11);
          // console.log(this.$refs["tabs"]);
          break;
      }
    },
    onUpdateJsonDataTabs(jsonData) {
      let me = this;
      // console.log("Caricato Json");
      me.jsonData = jsonData;
    },
    onAfterSave(data) {
      let me = this;
      if (me.id == "-1") {
        me.updataPropostaAccesso(data);
      }
      if (me.id !== "-1") {
        // console.log(data);
        me.idPropostaAccesso = me.jsonData.id;
        me.keyPropostaAccesso++;
      }
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
