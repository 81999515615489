<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Sigla Federazione </strong><br />
                        {{ jsonData.siglaFederazione }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Numero Codice Federazione </strong><br />
                        {{ jsonData.numeroCodiceFederazione }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Denominazione Federazione </strong><br />
                        {{ jsonData.denominazioneFederazione }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Sport </strong><br />
                        {{ jsonData.sport }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Numero Codice Sport </strong><br />
                        {{ jsonData.numeroCodiceSport }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Specialita' </strong><br />
                        {{ jsonData.specialita }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Numero Codice Specialita' </strong><br />
                        {{ jsonData.numeroCodiceSpecialita }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Tipo Visita </strong><br />
                        {{ jsonData.tipoVisita }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>EEG Prima Visita </strong><br />
                        <span :class="getColorEegPrimaVisita()"><b-icon class="sa-size-icon-15" :icon="getIconEegPrimaVisita()"></b-icon></span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Scadenza </strong><br />
                        {{ jsonData.scadenza }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Eta' min. Maschi </strong><br />
                        {{ jsonData.etaMaschiMin }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <strong>Eta' max. Maschi</strong><br />
                        {{ jsonData.etaMaschiMax }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Eta' min. Femmine </strong><br />
                        {{ jsonData.etaFemmineMin }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Eta' max. Femmine</strong><br />
                        {{ jsonData.etaFemmineMax }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <strong>Tipologia Eta' </strong><br />
                        {{ jsonData.tipologiaEta }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>Periodo Stagione Da: </strong><br />
                        {{ formatDate(jsonData.periodoStagioneSportivaDa) }}
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>Periodo Stagione A:</strong><br />
                        {{ formatDate(jsonData.periodoStagioneSportivaA) }}
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
    </div>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            Type: Object,
            default: function() {
                return {
                    siglaFederazione: "",
                    numeroCodiceFederazione: "",
                    denominazioneFederazione: "",
                    sport: "",
                    numeroCodiceSport: "",
                    specialita: "",
                    numeroCodiceSpecialita: "",
                    tipoVisita: "",
                    eegPrimaVisita: false,
                    scadenza: "",
                    etaMaschiMin: null,
                    etaMaschiMax: null,
                    etaFemmineMin: null,
                    etaFemmineMax: null,
                    tipologiaEta: null,
                    periodoStagioneSportivaDa: null,
                    periodoStagioneSportivaA: null,
                };
            },
        },
    },
    watch: {},
    methods: {
        getColorEegPrimaVisita() {
            let me = this;
            let color = null;
            if (me.jsonData.eegPrimaVisita) {
                color = "text-sucess";
            } else {
                color = "text-danger";
            }
            return color;
        },
        getIconEegPrimaVisita() {
            let me = this;
            let icon = null;
            if (me.jsonData.eegPrimaVisita) {
                icon = "check2";
            } else {
                icon = "exclamation-triangle";
            }
            return icon;
        },
    },
};
</script>
