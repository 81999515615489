<template>
    <div>
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCardiomyopathyType") }}</label>
                    <b-form-select v-model="jsonData.tipoCardiomiopatia" :options="optionsTipo" :value="null" value-field="value" text-field="text"> </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDilatedEtiology") }}</label>
                    <b-form-select v-model="jsonData.eziologiaDilatativa" :options="optionsEziologia" :value="null" value-field="value" text-field="text"> </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblHypertrophicSineCausa") }}</label>
                    <b-form-select v-model="jsonData.ipertroficaSineCausa" :options="optionsIpertrofica" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblRestrictive") }}</label>
                    <b-form-select v-model="jsonData.restrittiva" :options="optionsRestrittiva" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    tipoCardiomiopatia: null,
                    eziologiaDilatativa: null,
                    ipertroficaSineCausa: null,
                    restrittiva: null,
                };
            },
        },
    },

    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            pathResource: "/cardiomiopatie",
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            optionsTipo: [
                { text: "Select di test", value: null },
                { text: "PROVA", value: "PROVA" },
            ],
            optionsEziologia: [{ text: "Select di test", value: null }],
            optionsIpertrofica: [{ text: "Select di test", value: null }],
            optionsRestrittiva: [{ text: "Select di test", value: null }],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
