<template>
  <div v-if="isEdit">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <!-- <b-button size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" v-b-modal.mdlDettagli>
        <b-icon icon="plus"></b-icon>
      </b-button> -->
        <b-table sticky-header ref="tblDettaglioAlimenti" stacked="xl" striped hover itemscope :items="listaDettaglioAlimenti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="listaDettaglioAlimenti">
            {{ listaDettaglioAlimenti.index + 1 }}
          </template>
          <template #cell(tipoPasto)="item">
            <b-form-select v-model="item.item.tipoPasto" :options="tipoPastoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
          </template>
          <template #cell(orePrecedenti)="item">
            <b-form-select v-model="item.item.orePrecedenti" :options="orePrecedentiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
          </template>
          <template #cell(dataOraPrecedenti)="item">
            <date-picker v-model="item.item.dataOraPrecedenti" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime"></date-picker>
          </template>
          <template #cell(luogo)="item">
            <b-form-input v-model="item.item.luogo"></b-form-input>
          </template>
          <template #cell(numeroCommensali)="item">
            <b-form-input v-model="item.item.numeroCommensali"></b-form-input>
          </template>
          <template #cell(listaAlimenti)="item">
            <!-- <b-form-input v-model="getListaAlimenti(item.item.listaAlimenti)" disabled></b-form-input> -->
            <span class="sa-data">{{ getListaAlimenti(item.item.listaAlimenti) }}</span>
          </template>
          <template #cell(actions)="row">
            <b-button v-b-modal.mdlIntestazione size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="omModificaRiga(row.item.listaAlimenti)">
              <!-- <b-icon icon="pencil"></b-icon> -->
              Agg. Alimenti
            </b-button>
            <b-button v-if="row.index == listaDettaglioAlimenti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAdd(listaDettaglioAlimenti, row)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== listaDettaglioAlimenti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(listaDettaglioAlimenti, row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-col>
      <b-modal b-modal ref="mdlDettagli" id="mdlDettagli" size="xl" style="height: 100%" @ok="onAddDettagli" @cancel="onCancelMdl" title="Aggiungi Dettaglio Pasto">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-table sticky-header stacked="xl" striped hover itemscope :items="rigaDettaglioAlimenti.listaAlimenti" :fields="fieldsAlimenti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
              <template #cell(alimento)="item">
                <b-form-input v-model="item.item.alimento"></b-form-input>
              </template>
              <template #cell(actions)="row">
                <b-button v-if="row.index == rigaDettaglioAlimenti.listaAlimenti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPasto(rigaDettaglioAlimenti.listaAlimenti, row.item)">
                  <b-icon icon="plus"></b-icon>
                </b-button>
                <b-button v-if="row.index !== rigaDettaglioAlimenti.listaAlimenti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteAlimenti(rigaDettaglioAlimenti.listaAlimenti, row)">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-modal>
    </b-row>
  </div>
  <div v-else>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <!-- <b-button size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" v-b-modal.mdlDettagli>
        <b-icon icon="plus"></b-icon>
      </b-button> -->
        <b-table sticky-header ref="tblDettaglioAlimenti" stacked="xl" striped hover itemscope :items="listaDettaglioAlimenti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="listaDettaglioAlimenti">
            {{ listaDettaglioAlimenti.index + 1 }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  mixins: [UtilityMixin],
  components: { DatePicker },
  props: {
    listaDettaglioAlimenti: {
      type: Array,
      default: function () {
        return null;
      },
    },
    isEdit: Boolean,
  },
  watch: {
    listaDettaglioAlimenti: {
      handler(listaDettaglioAlimenti) {
        this.$emit("update", listaDettaglioAlimenti);
      },
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      rigaDettaglioAlimenti: { tipoPasto: "", orePrecedenti: "", dataOraPrecedenti: null, luogo: "", numeroCommensali: "", listaAlimenti: [{ alimento: "" }] },
      fieldsContatti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo Pasto",
          key: "tipoPasto",
          thStyle: "width: 14rem",
          sortable: false,
        },
        {
          label: "Ore Precedenti",
          key: "orePrecedenti",
          thStyle: "width: 14rem",
          sortable: false,
        },
        {
          label: "Data Ora Precedente",
          key: "dataOraPrecedenti",
          thStyle: "width: 15rem",
          sortable: false,
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "Dove",
          key: "luogo",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "N. Commensali",
          key: "numeroCommensali",
          thStyle: "width: 8rem",
          sortable: false,
        },
        {
          label: "Alimenti",
          key: "listaAlimenti",
          sortable: false,
          formatter: function (value) {
            let returnValue = "";
            if (value !== null) {
              value.forEach((element) => {
                returnValue += element.alimento + ", ";
              });
              returnValue = returnValue.substring(0, returnValue.length - 2);
            }
            return returnValue;
          },
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 15rem",
          tdClass: "text-center",
        },
      ],
      tipoPastoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "COLAZIONE", text: "COLAZIONE" },
        { value: "PRANZO", text: "PRANZO" },
        { value: "CENA", text: "CENA" },
      ],
      orePrecedentiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "24 ORE", text: "24 ORE" },
        { value: "48 ORE", text: "48 ORE" },
        { value: "72 ORE", text: "72 ORE" },
      ],
      fieldsAlimenti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Alimento",
          key: "alimento",
          // thStyle: "width: 15rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
    };
  },
  // mounted() {
  //   let me = this;
  //   console.log(me.listaDettaglioAlimenti);
  //   me.listaDettaglioAlimenti.push({ tipoPasto: "", dove: "" });
  //   console.log(me.listaDettaglioAlimenti);
  // },
  methods: {
    onAddDettagli() {
      this.$refs.tblDettaglioAlimenti.refresh();
      // let me = this;
      // console.log(me.rigaDettaglioAlimenti);
      // if ((me.rigaDettaglioAlimenti.tipoPasto !== "" && me.rigaDettaglioAlimenti.orePrecedenti !== "", me.rigaDettaglioAlimenti.dataOraPrecedenti !== null, me.rigaDettaglioAlimenti.luogo !== "", me.rigaDettaglioAlimenti.numeroCommensali !== "")) {
      // me.rigaDettaglioAlimenti.listaAlimenti
      // } else {
      //   this.$bvModal
      //     .msgBoxOk("Dati non completi", {
      //       title: "ATTENZIONE",
      //       size: "sm",
      //       okVariant: "outline-danger",
      //       headerClass: "sa-msg-header-danger",
      //       footerClass: "p-2 border-top-0",
      //       centered: true,
      //     })
      //     .then((value) => {
      //       console.log(value);
      //     })
      //     .catch((err) => {
      //       console.log(err);
      //     });
      // }
      // me.rigaDettaglioAlimenti = { tipoPasto: "", orePrecedenti: "", dataOraPrecedenti: null, luogo: "", numeroCommensali: "", listaAlimenti: [{ alimento: "" }] };
    },
    onCancelMdl() {
      let me = this;
      me.rigaDettaglioAlimenti = { tipoPasto: "", orePrecedenti: "", dataOraPrecedenti: null, luogo: "", numeroCommensali: "", listaAlimenti: [{ alimento: "" }] };
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddPasto(array, value) {
      this.$refs.tblDettaglioAlimenti.refresh();
      if (value.alimento !== "") {
        array.push({ alimento: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Alimento obbligatorio", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAdd(array, value) {
      this.$refs.tblDettaglioAlimenti.refresh();
      console.log(value);
      if ((value.item.tipoPasto !== "" && value.item.orePrecedenti !== "", value.item.dataOraPrecedenti !== null, value.item.luogo !== "", value.item.numeroCommensali !== "")) {
        array.push({ tipoPasto: "", orePrecedenti: "", dataOraPrecedenti: null, luogo: "", numeroCommensali: "", listaAlimenti: [{ alimento: "" }] });
      } else {
        this.$bvModal
          .msgBoxOk("Campi obbligatori", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDeleteAlimenti(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
              this.rigaDettaglioAlimenti.listaAlimenti = array;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    omModificaRiga(value) {
      let me = this;
      // console.log(me.rigaDettaglioAlimenti.listaAlimenti[me.rigaDettaglioAlimenti.listaAlimenti.length - 1]);
      me.rigaDettaglioAlimenti.listaAlimenti = value;
      if (me.rigaDettaglioAlimenti.listaAlimenti.length === 0 || me.rigaDettaglioAlimenti.listaAlimenti[me.rigaDettaglioAlimenti.listaAlimenti.length - 1].alimento !== "") {
        me.rigaDettaglioAlimenti.listaAlimenti.push({ alimento: "" });
      }
      this.$bvModal.show("mdlDettagli");
    },
    getListaAlimenti(value) {
      // console.log(value);
      let returnValue = "";
      if (value !== null && value !== undefined && value !== "") {
        value.forEach((element) => {
          if (element.alimento !== "") {
            returnValue += element.alimento + ", ";
          }
        });
        returnValue = returnValue.substring(0, returnValue.length - 2);
      }
      return returnValue;
    },
  },
};
</script>

<style></style>
