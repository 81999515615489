<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Evento</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataEvento) }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECO RENALE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Eco Renale</label>
                            <span class="sa-data">{{ jsonData.ecorenale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.noteecorenale }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="SCINTIGRAFIA RENALE SEQUENZIALE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Scintigrafia Renale Sequenziale</label>
                            <span class="sa-data">{{ jsonData.scintigrafiarenale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.notescintigrafia }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="VFG" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tot. (ml/min)</label>
                            <span class="sa-data">{{ jsonData.vfgtot }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Dx (ml/min)</label>
                            <span class="sa-data">{{ jsonData.vfgdx }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sin (ml/min)</label>
                            <span class="sa-data">{{ jsonData.vfgsin }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Captopril" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tot. (ml/min)</label>
                            <span class="sa-data">{{ jsonData.captoprilTot }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Dx (ml/min)</label>
                            <span class="sa-data">{{ jsonData.captoprilDx }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sin (ml/min)</label>
                            <span class="sa-data">{{ jsonData.captoprilSin }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="ANGIOGRAFIA SELETTIVA" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Angiografia Selettiva</label>
                            <span class="sa-data">{{ jsonData.angiografia }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.noteangiografia }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="ECOGRAFIA TIROIDEA" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Ecografia Tiroidea</label>
                            <span class="sa-data">{{ jsonData.ecografiaTiroidea }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.ecografiaTiroideaNote }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="SCINTIGRAFIA TIROIDEA" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">Scintigrafia Tiroidea</label>
                            <span class="sa-data">{{ jsonData.scintigrafiaTiroidea }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.scintigrafiaTiroideaNote }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="TAC CRANIO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">TAC Cranio</label>
                            <span class="sa-data">{{ jsonData.tacCranio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.tacCranioNote }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="TAC TORACE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">TAC Torace</label>
                            <span class="sa-data">{{ jsonData.tacTorace }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.tacToraceNote }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="TAC ADDOME" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">TAC Addome</label>
                            <span class="sa-data">{{ jsonData.tacAddome }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.tacAddomeNote }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="RMN CRANIO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">RMN Cranio</label>
                            <span class="sa-data">{{ jsonData.rmnCranio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.rmnCranioNote }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="RMN TORACE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">RMN Torace</label>
                            <span class="sa-data">{{ jsonData.rmnTorace }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.rmnToraceNote }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="RMN ADDOME" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <label class="sa-label-data">RMN Addome</label>
                            <span class="sa-data">{{ jsonData.rmnAddome }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <span class="sa-data-justify">{{ jsonData.rmnAddomeNote }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECOCOLORDOPPLER ARTERIE RENALI - ORIGINE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV DX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.psvOrigineDx }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV DX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.edvOrigineDx }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR DX</label>
                            <span class="sa-data">{{ jsonData.irOrigineDx }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV SX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.psvOrigineSx }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV SX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.edvOrigineSx }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR SX</label>
                            <span class="sa-data">{{ jsonData.irOrigineSx }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECOCOLORDOPPLER ARTERIE RENALI - TRATTO MEDIO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV DX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.vpsDxTrattoMedio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV DX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.edvVpsDxTrattoMedio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR DX</label>
                            <span class="sa-data">{{ jsonData.irDxTrattoMedio }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV SX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.vpsSxTrattoMedio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV SX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.edvVpsSxTrattoMedio }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR SX</label>
                            <span class="sa-data">{{ jsonData.irSxTrattoMedio }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECOCOLORDOPPLER ARTERIE RENALI - ILO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV DX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.vpsDxIlo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV DX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.edvVpsDxIlo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR DX</label>
                            <span class="sa-data">{{ jsonData.irDxIlo }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV SX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.vpsSxIlo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV SX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.edvVpsSxIlo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR SX</label>
                            <span class="sa-data">{{ jsonData.irSxIlo }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECOCOLORDOPPLER ARTERIE RENALI - INTRAPARENCHIMALE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">PSV DX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.psvDxIntraparenchimale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">EDV DX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.edvDxIntraparenchimale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">IR DX</label>
                            <span class="sa-data">{{ jsonData.irDxIntraparenchimale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">AT DX</label>
                            <span class="sa-data">{{ jsonData.atDxIntraparenchimale }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">PSV SX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.psvSxIntraparenchimale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">EDV SX (cm/sec)</label>
                            <span class="sa-data">{{ jsonData.edvSxIntraparenchimale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">IR SX</label>
                            <span class="sa-data">{{ jsonData.irSxIntraparenchimale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">AT SX</label>
                            <span class="sa-data">{{ jsonData.atSxIntraparenchimale }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="CONCLUSIONI" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Conclusioni</label>
                            <span class="sa-data-justify">{{ jsonData.conclusioniEcodoppler }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="AORTA ADDOMINALE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Aorta Addominale</label>
                            <span class="sa-data-justify">{{ jsonData.aortaaddominale }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="ALTRO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Altro</label>
                            <span class="sa-data-justify">{{ jsonData.altroesame }}</span>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            pathResource: "/cardioesamistrumentali",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        tipoEvento() {
            return this.jsonData.tipoEvento;
        },
        gruppo() {
            return this.jsonData.gruppo;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
