<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(gestore)="{ item }">
                        <router-link class="sa-edit-link" :to="'/digitalsign/view/' + item.id">{{ item.gestore }}</router-link>
                        <br />
                        <span>{{ item.classModule }}</span>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            btnNewVisible: true,
            linkedit: "/digitalsign",
            pathResource: "/digitalsign",
            filter: null,
            showModalLoading: false,
            items: [],
            fields: [
                {
                    label: "Gestore",
                    key: "gestore",
                    sortable: true,
                },
                {
                    label: "Data",
                    key: "createDate",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
            ],
            filtro: {},
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        onRefresh() {
            let me = this;
            me.loadData();
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                    // me.$refs["SaPageList"].close();
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style></style>
