<template>
  <div>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="tblRigheDocumento" stacked="xl" striped hover itemscope :items="listaCauseMorte" :fields="fieldsRigheDocumento" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(cause)="item">
            <b-form-select :disabled="true" v-model="item.item.cause" :options="causeOptions">
              <template #first>
                <b-form-select-option disabled :value="null">--Seleziona Valore--</b-form-select-option>
              </template>
            </b-form-select>
          </template>
          <template #cell(descrizioneMalattia)="item">
            <b-form-input :state="item.item.state" v-model="item.item.descrizioneMalattia"></b-form-input>
          </template>
          <template #cell(tempoDescrizione)="item">
            <b-row>
              <b-col xs="12" sm="12" md="12" lg="6" xl="4">
                <b-form-input v-model="item.item.tempoAnni" placeholder="Anni" max="100"></b-form-input>
              </b-col>
              <b-col xs="12" sm="12" md="12" lg="6" xl="4">
                <b-form-input v-model="item.item.tempoMesi" placeholder="Mesi" max="12"></b-form-input>
              </b-col>
              <b-col xs="12" sm="12" md="12" lg="6" xl="4">
                <b-form-input v-model="item.item.tempoGiorni" placeholder="Giorni" max="31" no-wheel></b-form-input>
              </b-col>
            </b-row>
          </template>
          <template #cell(action)="row">
            &nbsp;
            <!-- <b-button :disabled="row.item.descrizioneMalattia === '' || !btnCalcola" v-if="row.item.cause == 'Causa Iniziale'" size="sm" variant="btn btn-primary waves-effect waves-light width-sm no-text" @click="onCalcolaCausaMorte"> -->
            <b-button :disabled="!isCompilata || row.item.descrizioneMalattia === '' || !btnCalcola" v-if="row.item.cause == 'Causa Iniziale'" size="sm" variant="btn btn-primary waves-effect waves-light width-sm no-text" @click="onCalcolaCausaMorte">
              <b-icon icon="calculator"></b-icon>
            </b-button>
            &nbsp;
            <b-button v-if="row.item.cause == 'Altri Stati Morbosi'" size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" :hidden="row.index === 0" @click="onDeleteRiga(row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
            &nbsp;
            <b-button v-if="row.index == listaCauseMorte.length - 1" size="sm" variant="btn btn-success waves-effect waves-light width-sm no-text" :hidden="getAddButtonHidden(row.index)" @click="onAddRigaDocumento(row)">
              <b-icon icon="plus"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
      <b-row v-if="jsonData.statoAttuale">
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Stato Attuale</label>
          <label class="sa-data">{{ jsonData.listaStati[0].stato }} {{ jsonData.listaStati[0].note }} </label>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Codifica Causa di Morte" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="2">
          <label>Codici ICD X Proposto</label>
          <b-form-select v-model="codiceIcdXProposto" :options="causaInizialeOptions" text-field="codice" value-field="codice" @change="onSelectCausaInizialeDescrizione"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="2">
          <label>Codici ICD X</label>
          <!-- <b-form-input v-model="jsonData.codiceIcdX"></b-form-input> -->
          <b-input-group class="mt-3">
            <b-form-input disabled v-model="jsonData.codiceIcdX"></b-form-input>
            <b-input-group-append>
              <b-button variant="outline-info" @click="onClickSearchIcdx"><b-icon icon="search"></b-icon></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="8">
          <label>Descrizione ICD X</label>
          <b-input-group class="mt-3">
            <b-form-input v-model="jsonData.descrizioneCodificataIcdX" readonly></b-form-input>
            <!-- <b-input-group-append>
              <b-button variant="outline-danger" @click="onClickDeleteIcdx"><b-icon icon="trash"></b-icon></b-button>
            </b-input-group-append> -->
          </b-input-group>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="2">
          <label>Codici ICD IX</label>
          <b-form-select v-model="jsonData.codiceIcdIX" text-field="codice" value-field="codice" disabled></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="10">
          <label>Descrizione ICD IX</label>
          <b-form-input v-model="jsonData.descrizioneCodificataIcdIX" readonly></b-form-input>
        </b-col>
      </b-row>
      <b-modal id="mdlCodiciIcd" scrollable ref="mdlCodiciIcd" @cancel="onCancelCodiciIcdX">
        <codici-icd-x-list-component @selected="onSelectedCodiciIcdX"></codici-icd-x-list-component>
      </b-modal>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import CodiciIcdXListComponent from "../../../utility/components/CodiciIcdXListComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  props: {
    jsonData: {
      type: Object,
      default: function () {
        return {
          codiceIcdX: "",
          descrizioneCodificataIcdX: "",
          listaCauseMorte: [
            {
              cause: "Causa Iniziale Uno",
              descrizioneMalattia: "",
              codiceIcd: "",
              descrizioneCodificataIcd: "",
              tempoGiorni: 0,
              tempoMesi: 0,
              tempoAnni: 0,
              state: true,
            },
          ],
        };
      },
    },
    codiciIcdxOptions: {
      type: Array[Object],
      default: function () {
        return {};
      },
    },
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { CodiciIcdXListComponent },
  computed: {},
  mounted() {
    let me = this;
    //me.listaCauseMorte = me.jsonData.listaCauseMorte;
    me.isCompilata = true;
    if (me.jsonData) {
      if (me.jsonData.listaCauseMorte) {
        me.jsonData.listaCauseMorte.forEach((element) => {
          me.listaCauseMorte.push({
            cause: element.cause,
            descrizioneMalattia: element.descrizioneMalattia,
            codiceIcd: element.codiceIcd,
            descrizioneCodificataIcd: element.codiceIcd,
            tempoGiorni: element.tempoGiorni,
            tempoMesi: element.tempoMesi,
            tempoAnni: element.tempoAnni,
            state: null,
          });
        });
      }

      me.codiceIcdXProposto = me.jsonData.codiceIcdX;
      // console.log(me.jsonData.statoAttuale);
      if (me.jsonData.statoAttuale !== null && me.jsonData.statoAttuale === "DA COMPILARE") {
        me.isCompilata = false;
      }
    }
    me.setCodiceIcdxCausaMorte();
    me.impostaPermessi();
  },
  data() {
    return {
      fieldProva: [],
      pathResource: "/rencam",
      causaInizialeOptions: [],
      isCompilata: true,
      currentPage: 1,
      perPage: null,
      btnCalcola: true,
      codiceIcdXProposto: null,
      listaCauseMorte: [],
      tempoOptions: [
        { text: "Giorni", value: "Giorni" },
        { text: "Mesi", value: "Mesi" },
        { text: "Anni", value: "Anni" },
      ],
      causeOptions: [
        { text: "Causa Iniziale", value: "Causa Iniziale" },
        { text: "Eventuali", value: "Eventuali" },
        { text: "Altri Stati Morbosi", value: "Altri Stati Morbosi" },
      ],
      fieldsRigheDocumento: [
        {
          label: "Cause",
          key: "cause",
          sortable: false,
          tdClass: "sa-row-form-element",
          thStyle: "width: 4em",
        },
        {
          label: "Descrizione Malattia",
          key: "descrizioneMalattia",
          sortable: false,
          tdClass: "sa-row-form-element",
          thStyle: "width: 8em",
        },
        {
          label: "Tempo - Anno | Mese | Giorno",
          key: "tempoDescrizione",
          sortable: false,
          tdClass: "sa-row-form-element",
          thStyle: "width: 5em",
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 1rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  watch: {
    // jsonData: {
    //   handler(jsonData) {
    //     // console.log(jsonData);
    //     this.$emit("update", jsonData);
    //   },
    //   deep: true,
    // },
    listaCauseMorte: {
      handler(listaCauseMorte) {
        let me = this;
        me.jsonData.listaCauseMorte = [];
        listaCauseMorte.forEach((element) => {
          me.jsonData.listaCauseMorte.push({
            cause: element.cause,
            descrizioneMalattia: element.descrizioneMalattia,
            codiceIcd: element.codiceIcd,
            descrizioneCodificataIcd: element.codiceIcd,
            tempoGiorni: element.tempoGiorni,
            tempoMesi: element.tempoMesi,
            tempoAnni: element.tempoAnni,
          });
        });
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      // console.log("sono qui 1");
      me.btnCalcola = UtilityMixin.methods.verificaPermessi(me.utente, "rencam", 2);
    },
    onAddRigaDocumento() {
      let me = this;
      // let numero = me.listaCauseMorte.length;
      // console.log(numero);
      // if (numero < 4) {
      //     me.listaCauseMorte.push({ cause: "Eventuali" });
      // } else {

      // }
      me.listaCauseMorte.push({ cause: "Altri Stati Morbosi" });
    },
    onDeleteRiga(row) {
      let me = this;
      if (row.index != 0) {
        me.listaCauseMorte.splice(row.index, 1);
      }
    },
    getAddButtonHidden(index) {
      let me = this;
      let returnValue = true;
      if (me.listaCauseMorte.length - 1 == index) {
        returnValue = false;
      }
      return returnValue;
    },
    onCalcolaCausaMorte() {
      let me = this;
      me.$emit("showmodalloading", true);
      let arrayCauseMorte = [];
      me.jsonData.listaCauseMorte.forEach((element) => {
        if (element.cause === "Altri Stati Morbosi") {
          // if (element.descrizioneMalattia.toLowerCase().includes("tumor") || element.descrizioneMalattia.toLowerCase().includes("carcinoma")) {
          arrayCauseMorte.push(element);
          // }
        } else {
          arrayCauseMorte.push(element);
        }
      });
      let link = process.env.VUE_APP_PATH_API + "/rencam/calcolacausainiziale";
      axios
        .post(link, arrayCauseMorte)
        .then((response) => {
          me.jsonData.descrizioneCodificataIcdX = "";
          me.causaInizialeOptions = response.data.data;
          if (me.causaInizialeOptions.length > 0) {
            me.codiceIcdXProposto = me.causaInizialeOptions[0].codice;
            me.jsonData.codiceIcdX = me.causaInizialeOptions[0].codice;
            me.jsonData.descrizioneCodificataIcdX = me.causaInizialeOptions[0].descrizione;
          }
          if (response.data.messaggio != null) {
            me.listaCauseMorte.forEach((element, index) => {
              if (response.data.messaggio.includes("#" + (index + 1) + "#")) {
                me.listaCauseMorte[index].state = false;
              } else {
                if (element.descrizioneMalattia) {
                  me.listaCauseMorte[index].state = true;
                }
              }
            });
          }
          me.$emit("showmodalloading", false);
        })
        .catch(() => {
          me.$emit("showmodalloading", false);
        });
    },
    onSelectCausaInizialeDescrizione(codice) {
      let me = this;
      me.setCausaInizialeIcdXDescrizione(codice);
    },
    setCausaInizialeIcdXDescrizione(codice) {
      let me = this;
      me.causaInizialeOptions.forEach((element) => {
        if (element.codice === codice) {
          me.jsonData.codiceIcdX = element.codice;
          me.jsonData.descrizioneCodificataIcdX = element.descrizione;
        }
      });
    },
    setCodiceIcdxCausaMorte() {
      let me = this;
      if (me.jsonData.codiceIcdX) {
        me.causaInizialeOptions = [];
        let link = process.env.VUE_APP_PATH_API + "/serviziicdx/dettaglio?codice=" + me.jsonData.codiceIcdX;
        axios
          .get(link)
          .then((response) => {
            me.causaInizialeOptions.push(response.data.data);
          })
          .catch();
      }
    },
    onClickSearchIcdx() {
      let me = this;
      me.$refs.mdlCodiciIcd.show();
    },
    onClickDeleteIcdx() {
      let me = this;
      me.codiceIcdXProposto = "";
      me.jsonData.codiceIcdX = "";
      me.jsonData.descrizioneCodificataIcdX = "";
    },
    onSelectedCodiciIcdX(value) {
      let me = this;
      me.jsonData.codiceIcdX = value.codice;
      me.jsonData.descrizioneCodificataIcdX = value.descrizione;
    },
    onCancelCodiciIcdX() {
      let me = this;
      me.codiceIcdXProposto = "";
      me.jsonData.codiceIcdX = "";
      me.jsonData.descrizioneCodificataIcdX = "";
    },
  },
};
</script>
<style scoped></style>
