<template>
    <div>
        <b-card class="sa-card" header="VALORI BASALI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
                    <span class="sa-data">{{ jsonData.freqCardiacaBasali }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pressione Sistolica</label>
                    <span class="sa-data">{{ jsonData.pressArtMaxBasali }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pressione Diastolica</label>
                    <span class="sa-data">{{ jsonData.pressArtMinBasali }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblECG") }}</label>
                    <span class="sa-data">{{ jsonData.ecg }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="PROTOCOLLO E TEST" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <span class="sa-data">{{ jsonData.protocolloETest }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="ACME" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMinimumTime") }}</label>
                    <span class="sa-data">{{ jsonData.tempoMin }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
                    <span class="sa-data">{{ jsonData.freqCardiacaAcme }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPredictedPercentage") }}</label>
                    <span class="sa-data">{{ jsonData.percPredettoBpm }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pressione Sistolica</label>
                    <span class="sa-data">{{ jsonData.pressArtMaxAcme }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Pressione Diastolica</label>
                    <span class="sa-data">{{ jsonData.pressArtMinAcme }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWattMinute") }}</label>
                    <span class="sa-data">{{ jsonData.wattMinAcme }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPredictedPercentage") }}</label>
                    <span class="sa-data">{{ jsonData.percPredettoWattalmin }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="RECUPERO" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMinimumTime") }}</label>
                    <span class="sa-data">{{ jsonData.tempoMinRecupero }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
                    <span class="sa-data">{{ jsonData.freqCardiacaRecupero }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPredictedPercentage") }}</label>
                    <span class="sa-data">{{ jsonData.percPredettoRecupero }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Pressione Sistolica</label>
                    <span class="sa-data">{{ jsonData.pressArtMaxRecupero }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Pressione Diastolica</label>
                    <span class="sa-data">{{ jsonData.pressArtMinRecupero }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    components: {},
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },

    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {},
};
</script>
