<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    {{ formatDateTime(jsonData.dataEvento) }}
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNextCheckDate") }}</label>
                    {{ formatDateTime(jsonData.prossimoControllo) }}
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblGeneralData')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
                    <span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
                    <span class="sa-data">{{ jsonData.bmi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWaistCircumference") }}</label>
                    <span class="sa-data">{{ jsonData.circvita }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHipsCircumference") }}</label>
                    <span class="sa-data">{{ jsonData.circfianchi }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCurrentTherapy')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.diuretici)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diuretici)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiuretics") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.alfabloccanti)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.alfabloccanti)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlphablockers") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.betabloccanti)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.betabloccanti)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBetablockers") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.alfabetabloccanti)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.alfabetabloccanti)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlphabetablockers") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aceinibitori)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aceinibitori)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAceInibitors") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.at1antagonisti)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.at1antagonisti)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAT1antagonists") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.diidropiridinici)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diidropiridinici)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDihydropyridines") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.fenilalchilamine)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fenilalchilamine)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPhenylalkylamines") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.benzotiazepine)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.benzotiazepine)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBenzothiazepines") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.antiaritmici)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antiaritmici)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAntiarrhythmics") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.digitale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.digitale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDigital") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.nitrati)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.nitrati)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNitrates") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.antiaggreganti)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antiaggreganti)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAntiplateletAgents") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.anticoagulanti)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.anticoagulanti)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnticoagulants") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.statine)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.statine)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStatins") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.fibrati)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fibrati)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFibrates") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.antidiabOrali)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antidiabOrali)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOralAntidiabetics") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.insulina)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.insulina)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsulin") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.altro)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.altro)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatientFollowedTherapy") }}</label>

                    <span class="sa-data">{{ getLabelTraduora(jsonData.segueterapia) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSideEffects") }}</label>

                    <span class="sa-data">{{ getLabelTraduora(jsonData.effetticollaterali) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEditTherapy") }}</label>

                    <span class="sa-data">{{ getLabelTraduora(jsonData.modificaterapia) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSideEffectsDescription") }}</label>
                    <span class="sa-data-justify">{{ jsonData.qualieffetti }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pressione Sistolica a Domicilio</label>
                    <span class="sa-data">{{ jsonData.pasdomicilio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pressione Diastolica a Domicilio</label>
                    <span class="sa-data">{{ jsonData.paddomicilio }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAnamnesys')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNextAnamnesys") }}</label>
                    <span class="sa-data">{{ jsonData.esameGenerale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGeneralCardiovascularAnamnesys") }}</label>
                    <span class="sa-data">{{ jsonData.esameCardiovascolare }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCardiovascularEvent") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tipoDiRicoveroCardio) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCause") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.causaRicoveroCardio) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExtraCardiovascularEvent") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tipoDiRicoveroExtra) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNYHA") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.nyha) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCause") }}</label>
                    <span class="sa-data">{{ jsonData.causaRicoveroExtra }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPaSitting')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pressione Sistolica Prima Misurazione</label>
                    <span class="sa-data">{{ jsonData.pasitmax1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pressione Diastolica Prima Misurazione</label>
                    <span class="sa-data">{{ jsonData.pasitmin1 }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pressione Sistolica Seconda Misurazione</label>
                    <span class="sa-data">{{ jsonData.pasitmax2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pressione Diastolica Seconda Misurazione</label>
                    <span class="sa-data">{{ jsonData.pasitmin2 }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pressione Sistolica Terza Misurazione</label>
                    <span class="sa-data">{{ jsonData.pasitmax3 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Pressione Diastolica Terza Misurazione</label>
                    <span class="sa-data">{{ jsonData.pasitmin3 }}</span>
                </b-col>
            </b-row>
            <!-- DA CONTROLLARE -->
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Media Pressione Sistolica </label>
                    <span class="sa-data">{{ paMediaMax }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Media Pressione Diastolica </label>
                    <span class="sa-data">{{ paMediaMin }}</span>
                </b-col>
                <!--  -->
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblFinalTherapy')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.diureticiFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diureticiFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiuretics") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.alfabloccantiFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.alfabloccantiFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlphablockers") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.betabloccantiFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.betabloccantiFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBetablockers") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.alfabetabloccantiFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.alfabetabloccantiFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlphabetablockers") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aceinibitoriFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aceinibitoriFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAceInibitors") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.at1antagonistiFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.at1antagonistiFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAT1antagonists") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.diidropiridiniciFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diidropiridiniciFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDihydropyridines") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.fenilalchilamineFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fenilalchilamineFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPhenylalkylamines") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.benzotiazepineFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.benzotiazepineFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBenzothiazepines") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.antiaritmiciFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antiaritmiciFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAntiarrhythmics") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.digitaleFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.digitaleFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDigital") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.nitratiFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.nitratiFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNitrates") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.antiaggregantiFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antiaggregantiFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAntiplateletAgents") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.anticoagulantiFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.anticoagulantiFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnticoagulants") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.statineFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.statineFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStatins") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.fibratiFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fibratiFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFibrates") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.antidiabOraliFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antidiabOraliFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOralAntidiabetics") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.insulinaFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.insulinaFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsulin") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.altroFinale)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.altroFinale)"> </b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoteComunicationsToSpecialistCenter") }}</label>
                    <span class="sa-data-justify">{{ jsonData.commento }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRoutineExams')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGlicemy") }}</label>
                    <span class="sa-data">{{ jsonData.glicemia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCreatininemia") }}</label>
                    <span class="sa-data">{{ jsonData.creatininemia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAzotemia") }}</label>
                    <span class="sa-data">{{ jsonData.azotemia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUricemia") }}</label>
                    <span class="sa-data">{{ jsonData.uricemia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSodiemia") }}</label>
                    <span class="sa-data">{{ jsonData.sodiemia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPotassiemia") }}</label>
                    <span class="sa-data">{{ jsonData.potassiemia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTrigliceridemia") }}</label>
                    <span class="sa-data">{{ jsonData.trigliceridi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColesterolemia") }}</label>
                    <span class="sa-data">{{ jsonData.colesterolo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHDL") }}</label>
                    <span class="sa-data">{{ jsonData.hdl }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteinuria") }}</label>
                    <span class="sa-data">{{ jsonData.proteinuria24 }}</span>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4"><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHB")
                    }}</label>
                        {{jsonData.hb}}
                    </b-col> -->
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblUrineExams')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGlucose") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.urineGlucosio) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBlood") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.urineSangue) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteins") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.urineProteine) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSediment") }}</label>
                    <span class="sa-data">{{ jsonData.sedimento }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.afa.lblHemocrome')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRedBloodCells") }}</label>
                    <span class="sa-data">{{ jsonData.globuliRossi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWhiteBloodCells") }}</label>
                    <span class="sa-data">{{ jsonData.globuliBianchi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPlatelets") }}</label>
                    <span class="sa-data">{{ jsonData.piastrine }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEmatocrito") }}</label>
                    <span class="sa-data">{{ jsonData.ematocrito }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFibrinogeno") }}</label>
                    <span class="sa-data">{{ jsonData.fibrinogeno }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHB") }}</label>
                    <span class="sa-data">{{ jsonData.emoglobina }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPT") }}</label>
                    <span class="sa-data">{{ jsonData.pt }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblINR") }}</label>
                    <span class="sa-data">{{ jsonData.inr }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPTT") }}</label>
                    <span class="sa-data">{{ jsonData.aptt }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            pathResource: "/cardioconsulenzetelematiche",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },

        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
