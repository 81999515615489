var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sa-page-layout',{attrs:{"btnNewVisible":_vm.btnNewVisible,"btnRefreshVisible":true,"showModalLoading":_vm.showModalLoading,"linkedit":_vm.linkedit},on:{"refresh":_vm.onRefresh}},[_c('template',{slot:"toolbar"}),_c('template',{slot:"table-filter"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('b-row',{staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"sa-data"},[_vm._v("Codice Fiscale")]),_c('b-input-group',{staticClass:"mb-3"},[_c('b-form-input',{attrs:{"maxlength":"16","type":"search","id":"codice","formatter":function (value) {
                  return new String(value).toUpperCase();
                }},model:{value:(_vm.filtro.codiceFiscale),callback:function ($$v) {_vm.$set(_vm.filtro, "codiceFiscale", $$v)},expression:"filtro.codiceFiscale"}})],1)],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"6","lg":"6","xl":"6"}},[_c('span',{staticClass:"sa-data"},[_vm._v("Malattia Segnalata")]),_c('b-form-input',{attrs:{"type":"search","id":"malattia"},model:{value:(_vm.filtro.malattiaSegnalata),callback:function ($$v) {_vm.$set(_vm.filtro, "malattiaSegnalata", $$v)},expression:"filtro.malattiaSegnalata"}})],1)],1),_c('b-row',[_c('b-col',{staticClass:"sa-padding-right text-right"},[_c('b-button',{attrs:{"type":"submit","variant":"info"}},[_vm._v(_vm._s(this.$i18n.t("global.lblFind")))]),_c('b-button',{attrs:{"type":"reset","variant":"danger"},on:{"click":_vm.onReset}},[_vm._v(_vm._s(this.$i18n.t("global.lblResetFilter")))])],1)],1)],1)],1),_c('template',{slot:"table-header"},[_c('b-row',{staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("global.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1),_c('template',{slot:"table-body"},[_c('div',{staticClass:"b-table-sticky-header"},[_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","filter":_vm.filter,"stacked":"xl","striped":"","hover":"","items":_vm.items,"fields":_vm.fields,"current-page":1,"per-page":_vm.perPage,"sort-icon-left":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(malattiaSegnalata)",fn:function(ref){
                var item = ref.item;
return [_c('router-link',{staticClass:"sa-edit-link",attrs:{"to":'/malattieinfettive/view/' + item.id}},[_vm._v(" "+_vm._s(item.malattiaSegnalata)+" ")])]}},{key:"cell(vaccino)",fn:function(ref){
                var item = ref.item;
return [_c('strong',{class:_vm.getClassColorVaccino(item.vaccino)},[_vm._v(_vm._s(item.vaccino))])]}},{key:"cell(anagrafica)",fn:function(ref){
                var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.identificativo))])]}},{key:"cell(actions)",fn:function(ref){
                var item = ref.item;
return [(!item.isIndagineEpidemiologica)?_c('div',[(_vm.createIndagineEpidemiologica)?_c('b-button',{staticClass:"no-text",attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return _vm.onAddIndagineEpidemiologica(item)}}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" Aggiungi Indagine ")],1):_vm._e(),_c('print-component',{attrs:{"jsonData":item,"linkPrintData":_vm.linkPrintData(item),"typeButton":"small","firmaGrafometrica":false}})],1):_c('div',[_c('b-input-group',[(_vm.editIndagineEpidemiologica)?_c('b-button',{staticClass:"no-text",attrs:{"size":"sm","variant":"outline-success"},on:{"click":function($event){return _vm.onEditIndagineEpidemiologica(item)}}},[_c('b-icon',{attrs:{"icon":"pencil"}}),_vm._v(" Modifica Indagine ")],1):_vm._e(),(_vm.showIndagineEpidemiologica)?_c('b-button',{staticClass:"mr-1 ml-1 no-text",attrs:{"size":"sm","variant":"outline-secondary"},on:{"click":function($event){return _vm.onClickBtnVisualizzaIndagine(item)}}},[_c('font-awesome-icon',{attrs:{"icon":"eye"}})],1):_vm._e()],1)],1)]}}])})],1)]),_c('template',{slot:"table-footer"},[_c('b-row',{staticClass:"sa-label-info",staticStyle:{"margin-left":"0.5rem","margin-right":"0.5rem"}},[_c('b-col',{attrs:{"lg":"3"}},[_c('p',[_vm._v(_vm._s(this.$i18n.t("ambulatori.lblTotalRecords"))+": "+_vm._s(_vm.rows))])]),_c('b-col',{attrs:{"lg":"9"}},[_c('b-pagination',{attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"align":"right","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),_c('b-modal',{ref:"mdlCambiaStato",attrs:{"id":"mdlCambiaStato"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(_vm._s(_vm.titleModal))]},proxy:true},{key:"modal-footer",fn:function(ref){
                var cancel = ref.cancel;
return [_c('b-button',{attrs:{"size":"sm","variant":"outline-danger"},on:{"click":function($event){return cancel()}}},[_vm._v("Cancel")]),_c('b-button',{attrs:{"size":"sm","variant":"outline-success"},on:{"click":function($event){return _vm.cambiaStato()}}},[_vm._v(_vm._s(_vm.titleModal))])]}}])},[_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('span',{staticClass:"sa-data"},[_vm._v(_vm._s(_vm.getLabelTraduora("adi.lblState")))]),_c('b-form-select',{attrs:{"options":_vm.statiOptions,"id":"stato","value-field":"value","text-field":"text"},model:{value:(_vm.nuovoStato.stato),callback:function ($$v) {_vm.$set(_vm.nuovoStato, "stato", $$v)},expression:"nuovoStato.stato"}})],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"12","xl":"12"}},[_c('span',{staticClass:"sa-data"},[_vm._v(_vm._s(_vm.getLabelTraduora("adi.lblNote")))]),_c('b-form-textarea',{attrs:{"no-resize":"","type":"search","id":"notestato","rows":"4"},model:{value:(_vm.nuovoStato.note),callback:function ($$v) {_vm.$set(_vm.nuovoStato, "note", $$v)},expression:"nuovoStato.note"}})],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }