<template>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Codice Applicativo:</label>
                <b-form-input v-model="jsonData.codiceApplicativo"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Descrizione</label>
                <b-form-textarea v-model="jsonData.descrizione" rows="7"></b-form-textarea>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Note</label>
                <b-form-textarea v-model="jsonData.note" rows="7"></b-form-textarea>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Url After Signed:</label>
                <b-form-input v-model="jsonData.urlAfterSigned"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Method After Signed:</label>
                <b-form-select v-model="jsonData.methodAfterSigned" :options="methodOptions" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Url After Signed Error:</label>
                <b-form-input v-model="jsonData.urlAfterSignedError"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">M;ethod After Signed Error:</label>
                <b-form-select v-model="jsonData.methodAfterSignedError" :options="methodOptions" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Token</label>
                <b-form-textarea v-model="jsonData.token" rows="7"></b-form-textarea>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
export default {
    data() {
        return {
            methodOptions: [
                { text: "-Selezionare il Metodo-", value: "" },
                { text: "GET", value: "GET" },
                { text: "DELETE", value: "DELETE" },
                { text: "POST", value: "POST" },
                { text: "PUT", value: "PUT" },
            ],
            jsonData: { codiceApplicativo: null, descrizione: "", note: "", urlAfterSigned: "", methodAfterSigned: "", urlAfterSignedError: "", methodAfterSignedError: "", token: "" },
        };
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>

<style></style>
