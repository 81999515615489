<template>
  <riepilogo-prestazioni-aggiuntive-component :utente="utente" :key="keyListaEventi" :titoloEvento="titoloEvento" :pathEventResource="pathEventResource" :linkEvent="linkEvent"></riepilogo-prestazioni-aggiuntive-component>
</template>

<script>
import RiepilogoPrestazioniAggiuntiveComponent from "../components/RiepilogoPrestazioniAggiuntiveComponent.vue";
import UtilityMixins from "../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: {
    RiepilogoPrestazioniAggiuntiveComponent,
  },

  watch: {
    utente: function () {
      this.keyListaEventi++;
    },
  },
  mounted() {},
  data() {
    return {
      titoloEvento: "Riepilogo Prestazioni Aggiuntive",
      pathEventResource: "/cardioanamnesi",
      keyListaEventi: 0,
      linkEvent: "/paziente/cardiologia/anamnesi",
    };
  },
};
</script>

<style></style>
