<template>
    <div class="sa-tab-scheda">
        <b-card class="sa-card" header="Urine" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Aspetto</label>
                    <b-form-input v-model="data.aspetto" no-resize placeholder="Aspetto" required></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Colore</label>
                    <b-form-input v-model="data.colore" no-resize placeholder="Colore"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Peso Specifico</label>
                    <b-form-input v-model="data.pesoSpecifico" no-resize placeholder="Peso Specifico" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Reazione</label>
                    <b-form-textarea v-model="data.reazione" no-resize placeholder="Reazione"></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Referto</label>
                    <b-form-textarea v-model="data.referto" no-resize rows="6" placeholder="Referto"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    aspetto: "",
                    colore: "",
                    pesoSpecifico: null,
                    reazione: "",
                    referto: "",
                    firma: "",
                };
            },
        },
    },
};
</script>
