<template>
    <div class="sa-tab-scheda">
        <b-row style="background-color: var(--bg-main-container)">
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Dati Generali" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-row>
                                <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                    <label class="sa-label-data">Prog. Anno</label>
                                    <span class="sa-data">{{ jsonData.progressivoAnno }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                    <label class="sa-label-data">Data Visita</label>
                                    <span class="sa-data">{{ formatDate(jsonData.dataVisita) }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                    <label class="sa-label-data">Tipo Atleta</label>
                                    <span class="sa-data">{{ jsonData.tipoAttleta }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                    <label class="sa-label-data">Num. Certificato</label>
                                    <span class="sa-data">{{ jsonData.numeroCertificato }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                    <label class="sa-label-data">Validita' Certificato</label>
                                    <span class="sa-data">{{ jsonData.validitaCertificato }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="6" md="4" lg="2" xl="2">
                                    <label class="sa-label-data">Data Scadenza</label>
                                    <span class="sa-data">{{ formatDate(jsonData.dataScadenza) }}</span>
                                </b-col>
                            </b-row>
                            <hr />
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Urine</label>
                                    <b-icon v-if="jsonData.visitaEsameUrine.firma !== null" class="sa-size-icon-15" icon="check2"></b-icon>
                                    <b-icon v-else icon="exclamation-triangle" variant="danger"></b-icon>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Spirografia</label>
                                    <b-icon v-if="jsonData.visitaSpirografia.firma !== null" class="sa-size-icon-15" icon="check2"></b-icon>
                                    <b-icon v-else icon="exclamation-triangle" variant="danger"></b-icon>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">E.C.G.</label>
                                    <b-icon v-if="jsonData.visitaEcg.firma !== null" class="sa-size-icon-15" icon="check2"></b-icon>
                                    <b-icon v-else icon="exclamation-triangle" variant="danger"></b-icon>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Sport</label>
                                    <span class="sa-data">{{ jsonData.sport }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Societa' Sportiva</label>
                                    <span class="sa-data">{{ jsonData.societaSportiva }}</span>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">Tipo Visita</label>
                                    <span class="sa-data">{{ jsonData.tipoVisita }}</span>
                                </b-col>
                            </b-row>
                            <hr />
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">Firma</label>
                                    <span class="sa-data">{{ jsonData.firma }}</span>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <div class="sa-tab-scheda-row-fill" header="" header-tag="header" footer-tag="footer" title="">
            <b-row style="height: 100%">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-card class="sa-card" header="Storico" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                        <div style="height: 100%; display: inline-flex; flex-direction: column; width: 100%">
                            <div class="sa-tab-scheda-row-fill-table-content">
                                <div class="sa-tab-scheda-row-fill-table-content-header">
                                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                                        <b-col lg="3">
                                            <p>Totale Visite: {{ rows }}</p>
                                        </b-col>
                                        <b-col lg="9">
                                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="sa-tab-scheda-row-fill-table-content-body">
                                    <div class="b-table-sticky-header">
                                        <b-table sticky-header stacked="xl" striped hover :items="storicoVisite" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                            <template v-slot:cell(nominativo)="{ item }">
                                                <span @click="onBoh(item.id)">{{ item.nome }} {{ item.cognome }}</span>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                                <div class="sa-tab-scheda-row-fill-table-content-footer">
                                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                                        <b-col lg="3">
                                            <p>Totale Visite: {{ rows }}</p>
                                        </b-col>
                                        <b-col lg="9">
                                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <!-- 
        <b-card class="sa-tab-scheda-row-fill" header="" header-tag="header" footer-tag="footer" title="">
            <b-row style="margin-top: 20px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <div>
                        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                            <b-col lg="3">
                                <p>Totale Visite: {{ rows }}</p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                    <div class="b-table-sticky-header">
                        <b-table sticky-header stacked="xl" striped hover :items="storicoVisite" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                            <template v-slot:cell(nominativo)="{ item }">
                                <span @click="onBoh(item.id)">{{ item.nome }} {{ item.cognome }}</span>
                            </template>
                        </b-table>
                    </div>
                    <div>
                        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                            <b-col lg="3">
                                <p>Totale Visite: {{ rows }}</p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                    </div>
                </b-col>
            </b-row>
        </b-card> -->
    </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            Type: Object,
            default: function () {
                return {
                    progressivoAnno: null,
                    dataVisita: null,
                    tipoAttleta: "",
                    numeroCertificato: "",
                    validitaCertificato: null,
                    dataScadenza: null,
                    provenienza: "",
                    sport: "",
                    tipoVisita: "",
                    idAttleta: null,
                };
            },
        },
    },
    computed: {
        rows() {
            return this.storicoVisite.length;
        },
    },
    data() {
        return {
            currentPage: 1,
            perPage: 100,
            pathResource: "/leonidavisite",
            storicoVisite: [],
            filtro: { statoAttuale: "" },
            fields: [
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Sesso",
                    key: "sesso",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Data di Nascita",
                    key: "dataNascita",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Data Accettazione",
                    key: "dataVisita",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Progressivo",
                    key: "progressivoAnno",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "Sport",
                    key: "sport",
                    thStyle: "width: 15rem",
                    sortable: true,
                },
                {
                    label: "Tipo Visita",
                    key: "tipoVisita",
                    thStyle: "width: 4rem",
                    sortable: true,
                },
                {
                    label: "Tipo Atleta",
                    key: "tipoAttleta",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Medico",
                    key: "proprietario",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
            ],
        };
    },
    created() {},
    mounted() {
        let me = this;
        me.loadDataVisite();
    },
    methods: {
        loadDataVisite() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            me.filtro.statoAttuale = "CHIUSA";
            me.filtro.idAtleta = me.jsonData.idAtleta ? me.jsonData.idAtleta : "-1";
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    console.log(response.data.data);
                    me.storicoVisite = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onBoh(id) {
            let me = this;
            console.log(id);
            // this.$router.push({ name: "VisitaMedicoSportiva", params: { id: id } }).then(() => {});
            this.$router.push({ path: this.$route.path, params: { id: id } });
            me.$emit("update", id);
        },
        // onRefresh(item) {
        //     this.$emit("update", item);
        // },
    },
};
</script>
