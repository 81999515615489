<template>
    <b-row>
        <b-col cols="12">
            <b-form-checkbox :checked="isChecked()" switch>
                <b-button size="sm" style="min-width: 135px" variant="btn btn-outline-secondary btn-toolbar sa-margin-right float-sm-right" @click="onBtnFarmacoClick()">
                    <font-awesome-icon icon="pills"></font-awesome-icon>
                    <span class="sa-data" style="padding-left: 5px">{{ tipoFarmaco }}</span>
                </b-button>
            </b-form-checkbox>
        </b-col>
        <b-col>
            <b-modal ref="mdlRicercaFarmaco" id="mdlRicercaFarmaco" size="lg" scrollable @ok="onOkMdlRicercaFarmaco" @hidden="onHiddenMdlRicercaFarmaco" @show="onShowMdlRicercaFarmaco">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Tipo Farmaco</label>
                        <b-form-input v-model="tipoFarmaco" disabled></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nome Farmaco</label>
                        <b-input-group>
                            <b-form-input v-model="nomeFarmaco"></b-form-input>
                            <b-button size="sm" variant="" @click="onClickRicercaFarmaco()"><b-icon icon="search"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="6">
                        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                    </b-col>
                    <b-col cols="6">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
                <b-table ref="tblRicercaFarmaco" sticky-header stacked="xl" striped hover itemscope :items="listaFarmaci" :fields="fieldsFarmaci" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selected</span>
                        </template>
                    </template>
                    <template #cell(index)="jsonData">
                        {{ jsonData.index + 1 }}
                    </template>
                    <template #cell(dosaggio)="item">
                        <b-form-select v-model="item.item.dosaggio" :options="dosaggiOptions" :value="null" value-field="value" text-field="text" @input="onInputDosaggio(item.item)"></b-form-select>
                    </template>
                </b-table>
            </b-modal>
        </b-col>
    </b-row>
</template>
<script>
import axios from "axios";
export default {
    props: {
        tipoFarmaco: {
            type: String,
            default: function () {
                return null;
            },
        },
        codiceAtc: {
            type: String,
            default: function () {
                return null;
            },
        },
        listaTerapia: {
            type: Array,
        },
        tipo: String,
    },
    data() {
        return {
            currentPage: 1,
            perPage: 30,
            farmaciSelezionati: [],
            nomeFarmaco: "",
            pathResourceFarmaci: "/farmaci",
            pathResourcePosologia: "/pazienteterapieposologia",
            listaFarmaciSelezionati: [],
            listaFarmaci: [],
            fieldsFarmaci: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Nome Farmaco",
                    key: "descrizione",
                    sortable: false,
                },
                {
                    label: "Somministrazione",
                    key: "dosaggio",
                    thStyle: "width: 16rem",
                    sortable: false,
                },
            ],

            dosaggiOptions: [],
        };
    },
    computed: {
        rows() {
            return this.listaFarmaci.length;
        },
    },
    // watch: {
    //     listaTerapia: function () {
    //         console.log("Modificata");
    //     },
    // },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;

            let linkPosologie = process.env.VUE_APP_PATH_API + me.pathResourcePosologia;
            axios.get(linkPosologie).then((response) => {
                response.data.data.forEach((element) => {
                    me.dosaggiOptions.push({ value: element.posologia, text: element.posologia });
                });
                me.dosaggiOptions.unshift({ value: "", text: "-Seleziona Somministrazione-" });
                me.showModalLoading = false;

                let link = process.env.VUE_APP_PATH_API + me.pathResourceFarmaci;
                axios
                    .get(link, { params: { atcGmp: me.codiceAtc, descrizione: me.nomeFarmaco } })
                    .then((response) => {
                        me.listaFarmaci = [];
                        me.farmaciSelezionati.forEach((element) => {
                            me.listaFarmaci.push(element);
                        });
                        response.data.data.forEach((element) => {
                            me.listaFarmaci.push({ descrizione: element.descrizione, dosaggio: "" });
                        });
                        me.showModalLoading = false;
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            });
        },
        onBtnFarmacoClick() {
            let me = this;
            me.loadData();
            me.$refs.mdlRicercaFarmaco.show();
        },
        onClickRicercaFarmaco() {
            let me = this;
            me.loadData();
        },
        onShowMdlRicercaFarmaco() {
            let me = this;
            me.farmaciSelezionati = [];
            me.dosaggiOptions = [];
        },
        onOkMdlRicercaFarmaco() {
            let me = this;
            me.listaFarmaciSelezionati = [];
            me.farmaciSelezionati.forEach((element) => {
                element.classe = me.tipoFarmaco;
                me.listaFarmaciSelezionati.push(element);
            });
            this.$emit("update", me.listaFarmaciSelezionati);
        },
        onHiddenMdlRicercaFarmaco() {
            let me = this;
            if (me.farmaciSelezionati.length === 0) {
                me.farmaciSelezionati = [];
            }
            me.dosaggiOptions = [];
        },
        onInputDosaggio(item) {
            let me = this;
            if (me.farmaciSelezionati.length > 0) {
                me.farmaciSelezionati.forEach((element, index) => {
                    if (element.descrizione === item.descrizione) {
                        if (item.dosaggio === "") {
                            me.farmaciSelezionati.splice(index, 1);
                            console.log(me.farmaciSelezionati);
                        }
                    }
                });
            }
            if (item.dosaggio !== null && item.dosaggio !== "" && item.dosaggio !== undefined) {
                me.farmaciSelezionati.push(item);
            }
        },
        onReset() {
            let me = this;
            me.nomeFarmaco = "";
            me.loadData();
        },
        isChecked() {
            let me = this;
            let returnValue = false;
            me.listaTerapia.forEach((element) => {
                if (element.classe === me.tipoFarmaco && element.tipo === me.tipo) {
                    returnValue = true;
                }
            });
            return returnValue;
        },
    },
};
</script>
