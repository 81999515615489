<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="8" lg="8" xl="8">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblOrigin") }}</label>
                    <b-form-input v-model="jsonData.provenienza"></b-form-input>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblInstrument") }}</label>
                    <b-form-select v-model="jsonData.strumento" :options="strumentiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>  Antonio è Strunz   -->
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblBiliaryTractExt") }}</label>
                    <b-form-input v-model="jsonData.vieBiliariExtraepatiche" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblLiver") }}</label>
                    <b-form-input v-model="jsonData.fegato" type="text"></b-form-input>
                </b-col> -->
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecoendoscopia.lblReasonExam") }}</label>
                    <b-form-textarea v-model="jsonData.motivoEsame" no-resize rows="8" type="text"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi</label>
                    <b-form-textarea v-model="jsonData.diagnosi" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Corpo Referto</label>
                    <b-form-textarea v-model="jsonData.corpoReferto" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Consigli Terapeutici</label>
                    <b-form-textarea v-model="jsonData.consigliTerapeutici" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblGallbladder") }}</label>
                    <b-form-input v-model="jsonData.colecisti" type="text"></b-form-input>
                </b-col> -->
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Premedicazione</label>
                    <b-form-textarea v-model="jsonData.premedicazione" type="text" no-resize rows="8"></b-form-textarea>
                </b-col> -->
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblPancreas") }}</label>
                    <b-form-textarea v-model="jsonData.pancreas" type="text" no-resize rows="8"></b-form-textarea>
                </b-col> -->
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblSpleen") }}</label>
                    <b-form-textarea v-model="jsonData.milza" type="text" no-resize rows="8"></b-form-textarea>
                </b-col> -->
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblLeftKidney") }}</label>
                    <b-form-textarea v-model="jsonData.reneSx" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblRightKidney") }}</label>
                    <b-form-textarea v-model="jsonData.reneDx" type="text" no-resize rows="8"></b-form-textarea>
                </b-col> -->
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblAxisSplenoPortal") }}</label>
                    <b-form-textarea v-model="jsonData.asseSplenoPortale" type="text" no-resize rows="8"></b-form-textarea>
                </b-col> -->
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecografia.lblVarious") }}</label>
                    <b-form-textarea v-model="jsonData.varie" type="text" no-resize rows="8"></b-form-textarea>
                </b-col> -->
            </b-row>
        </b-card>
        <eventi-operatori-component :operatori="jsonData.listaOperatori" @update="onUpdateListaOperatori" :isEdit="isEdit"></eventi-operatori-component>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
    components: { DatePicker, EventiOperatoriComponent },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    provenienza: null,
                    motivoEsame: null,
                    fegato: null,
                    colecisti: null,
                    vieBiliariExtraepatiche: null,
                    pancreas: null,
                    milza: null,
                    reneSx: null,
                    reneDx: null,
                    asseSplenoPortale: null,
                    varie: null,
                    diagnosi: null,
                    corpoReferto: null,
                    premedicazione: null,
                    consigliTerapeutici: null,
                    strumento: null,
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        me.loadStrumenti();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            isEdit: true,
            selectFirstElement: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            pathResource: "/gastroecografiaaddominale",
            pathResourceStrumenti: "/pazienteeventistrumenti",
            strumentiOptions: [{ value: null, text: "-Seleziona Strumento-" }],
            filtro: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        loadStrumenti() {
            let me = this;
            //  me.filtro.page = me.currentPage;
            //    me.filtro.forPage = me.perPage;
            me.filtro.gruppo = "ECO";
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrumenti;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.showModalLoading = false;
                    response.data.data.list.forEach((element) => {
                        me.strumentiOptions.push({ value: element.modello + " (MAT: " + element.codice + ")", text: element.modello + " (MAT: " + element.codice + ")" });
                    });
                })
                .catch();
        },
        onUpdateListaOperatori(listaOperatori) {
            let me = this;
            me.jsonData.listaOperatori = listaOperatori;
        },
    },
};
</script>

<style></style>
