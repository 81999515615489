<template>
  <div>
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="VALORI BASALI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
          <span class="sa-data">{{ jsonData.freqCardiacaBasali }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Sistolica</label>
          <span class="sa-data">{{ jsonData.pressArtMaxBasali }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Diastolica</label>
          <span class="sa-data">{{ jsonData.pressArtMinBasali }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblECG") }}</label>
          <span class="sa-data">{{ jsonData.ecg }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="PROTOCOLLO E TEST" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <span class="sa-data">{{ jsonData.protocolloETest }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="ACME" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMinimumTime") }}</label>
          <span class="sa-data">{{ jsonData.tempoMin }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
          <span class="sa-data">{{ jsonData.freqCardiacaAcme }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPredictedPercentage") }}</label>
          <span class="sa-data">{{ jsonData.percPredettoBpm }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Sistolica</label>
          <span class="sa-data">{{ jsonData.pressArtMaxAcme }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Diastolica</label>
          <span class="sa-data">{{ jsonData.pressArtMinAcme }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWattMinute") }}</label>
          <span class="sa-data">{{ jsonData.wattMinAcme }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPredictedPercentage") }}</label>
          <span class="sa-data">{{ jsonData.percPredettoWattalmin }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="RECUPERO" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMinimumTime") }}</label>
          <span class="sa-data">{{ jsonData.tempoMinRecupero }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
          <span class="sa-data">{{ jsonData.freqCardiacaRecupero }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPredictedPercentage") }}</label>
          <span class="sa-data">{{ jsonData.percPredettoRecupero }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Pressione Sistolica</label>
          <span class="sa-data">{{ jsonData.pressArtMaxRecupero }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Pressione Diastolica</label>
          <span class="sa-data">{{ jsonData.pressArtMinRecupero }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixins],
  props: {
    idEvento: { Type: String, require: true },
  },
  components: {},
  data() {
    return {
      pathResource: "/cardiotestprovocativi",
      jsonData: {},
      id: null,
      fontScale: 2,
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  computed: {
    isFirmato() {
      let me = this;
      let isFirmato = !me.jsonData.firma;
      console.log(isFirmato, "FIRMA");
      me.$emit("updateStatoFirma", isFirmato);
      return !me.jsonData.firma;
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
          me.showModalLoading = false;
        });
      }
    },
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
  },
};
</script>

<style></style>
