<template>
  <b-card class="sa-card" header="Prestazioni" header-tag="header" footer-tag="footer" title="">
    <template #header>
      <span>Prestazioni Aggiuntive</span>
      <div style="float: right" v-if="isEdit">
        <b-button variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPrestazione()" v-b-modal.mdlPrestazioni> <b-icon icon="plus"></b-icon>Aggiungi Prestazione</b-button>
      </div>
    </template>
    <b-row>
      <div style="float: right"></div>
      <!-- <div class="b-table-sticky-header"> -->
      <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="prestazioni" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(actions)="row">
          <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(row.item)" v-if="isEdit">
            <b-icon icon="trash"></b-icon>
          </b-button>
        </template>
      </b-table>
      <!-- </div> -->
    </b-row>
    <b-modal ref="mdlPrestazioni" id="mdlPrestazioni" size="xl" style="height: 100%" @ok="onOkMdlPrestazioni" @cancel="onCancelMdlPrestazioni" title="Aggiungi Prestazione">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Prestazione:</label>
          <b-form-select v-model="prestazioneNuova.codicePrestazione" :options="listaPrestazioniOptions" :value="null" value-field="codice" text-field="tipoPrestazione" @change="onSetNomePrestazione"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-textarea v-model="prestazioneNuova.note" no-resize rows="6" style="margin-top: 3px"></b-form-textarea>
        </b-col>
      </b-row>
    </b-modal>
  </b-card>
</template>

<script>
// import Vue from "vue";
import axios from "axios";
export default {
  props: {
    idRisorsa: {
      type: String,
      default: function () {
        return null;
      },
    },
    contesto: {
      type: String,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {},
    },
    isEdit: Boolean,
  },
  data() {
    return {
      pathResource: "/pazienteeventiprestazionipaziente",
      pathPrestazioni: "/pazienteeventiprestazioni",
      prestazioni: [],
      listaPrestazioniOptions: [{ codice: null, tipoPrestazione: "-Seleziona Valore-" }],
      filtro: { gruppo: null, page: null, forPage: null },
      prestazioneNuova: { idRisorsa: null, contesto: null, nome: null, note: null, codicePrestazione: null },
      fields: [
        {
          label: "",
          key: "index",
          thStyle: "width: 2rem",
        },
        {
          label: "Prestazione",
          key: "nome",
        },
        {
          label: "Codice",
          key: "codicePrestazione",
          thStyle: "width: 10rem",
        },
        {
          label: "Note",
          key: "note",
          // thStyle: "width: 5rem",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 8rem",
        },
      ],
    };
  },
  computed: {},
  watch: {
    idRisorsa() {
      let me = this;
      if (me.idRisorsa) {
        me.loadData();
      }
    },
  },
  methods: {
    // isViewNote(id) {
    //     let me = this;
    //     me.viewNote[id] = true;
    //     return me.viewNote[id];
    // },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource;
        axios.get(link, { params: { idRisorsa: me.idRisorsa } }).then((response) => {
          me.prestazioni = response.data.data.list;
          me.showModalLoading = false;
        });
      }
    },
    onAddPrestazione() {
      let me = this;
      me.loadListaPrestazioni();
    },
    loadListaPrestazioni() {
      let me = this;
      //   console.log(me.jsonData);
      me.showModalLoading = true;
      me.listaPrestazioniOptions = [];
      let link = process.env.VUE_APP_PATH_API + me.pathPrestazioni;
      me.filtro.gruppo = me.jsonData.gruppo;
      axios.get(link, { params: me.filtro }).then((response) => {
        // response.data.data.list.forEach((element) => {
        //   me.listaPrestazioniOptions.push({ text: element.tipoPrestazione, value: element.tipoPrestazione });
        // });
        me.listaPrestazioniOptions = response.data.data.list;
        me.listaPrestazioniOptions.unshift({ codice: null, tipoPrestazione: "-Seleziona Valore-" });
        me.showModalLoading = false;
      });
    },
    onDelete(jsonData) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
          title: "Conferma Cancellazione",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deletePrestazione(jsonData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deletePrestazione(jsonData) {
      let me = this;
      //   console.log(jsonData);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + jsonData.id;
      axios
        .delete(link)
        .then(() => {
          me.loadData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onOkMdlPrestazioni() {
      let me = this;
      me.prestazioneNuova.idRisorsa = me.idRisorsa;
      me.prestazioneNuova.contesto = me.contesto;
      me.salvaPrestazioni();
      me.prestazioneNuova = { idRisorsa: null, contesto: null, nome: null, note: null };
      //   me.prestazioni.push(me.prestazioneNuova);
    },
    onCancelMdlPrestazioni() {
      let me = this;
      me.prestazioneNuova = { idRisorsa: null, contesto: null, nome: null, note: null };
    },
    salvaPrestazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios
        .post(link, me.prestazioneNuova)
        .then((response) => {
          // console.log(response);
          this.$bvModal
            .msgBoxOk(response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
          me.loadData();
        })
        .catch((e) => {
          console.log(e.messaggio);
          this.$bvModal
            .msgBoxOk(e.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onSetNomePrestazione() {
      let me = this;
      me.listaPrestazioniOptions.forEach((element) => {
        if (element.codice === me.prestazioneNuova.codicePrestazione) {
          me.prestazioneNuova.nome = element.tipoPrestazione;
        }
      });
      // console.log(me.prestazioneNuova);
    },
  },
};
</script>

<style>
.hide-file-input {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}
.file-label {
  color: #fff;
  background-color: #3730a3;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
}
input[type="file"]:focus + .file-label {
  box-shadow: 0 0 0 4px #bae6fd;
}

.sa-drop-image {
  background-color: #f0f0f0;
  width: 100%;
  aspect-ratio: 1/ 1;
  border: 1px #666 dashed;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sa-image-upload-icon {
  display: flex;
  align-items: center;
  font-size: 5rem;
  margin: auto;
}
.sa-drop-image span {
  margin-top: 10px;
}

.sa-pei-btn-icon {
  padding-left: 5px;
  padding-top: 1px;
  font-size: 1.25rem;
}
.sa-pei-chk {
  width: 150px;
  padding-bottom: 0px !important;
}
.sa-pei-chk-label {
  font-size: 0.75rem;
  font-weight: 500;
  padding-left: 3px;
}
.sa-pei-input {
  width: 100%;
  font-size: 0.75rem;
  padding: 0px;
  height: 25px;
  margin-right: 3px;
  border: 1px solid rgb(201, 201, 201);
}
.sa-pei-chk .custom-control-label {
  padding-top: 3px;
}
/* .sa-pei-chk .custom-control-input {
    margin-left: 3px;
} */
</style>
