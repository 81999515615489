<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnFirmaVisible="btnFirmaVisible" :pathResourceFirma="pathResourceFirma" :idEvento="idEvento" :firmaFieldName="firmaFieldName" :btnPrintVisible="true" :linkPrintData="linkPrintData" :btnSaveVisible="false" :btnAnnullaVisible="false" :btnBackVisible="true" :btnEditVisible="!isFirmato" :linkedit="linkedit" :linkback="linkback" :pathResource="pathResource" :data="jsonData" @updateFirma="onFirmaUpdate" class="sa-no-space">
    <template slot="toolbar">
      <!-- <firma-component class="float-sm-right primary" :idEvento="jsonData.id" :pathRestFirma="pathResource" :pathRestValidation="pathRestValidation" :data="jsonData" :disabled="isFirmato" @update="onFirmaUpdate"></firma-component> -->
      <b-button v-if="btnAllegatiVisible" variant="outline-success btn-toolbar sa-margin-left float-sm-left" v-b-modal.mdlAllegati>
        <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
        {{ getLabelTraduora("patient.afa.btnNewAllegato") }}
      </b-button>
    </template>
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h3 class="sa-event-title">{{ titoloEvento }}</h3>
    </template>
    <template slot="table-body">
      <b-tabs v-model="tabIndex" content-class="mt-3" :class="getTabClass()">
        <b-tab active @click="onTabClick">
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("pazienti.egds.lblData") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <slot name="tab-content-event"></slot>
            <!-- SLOT PER IL COMPONENTE VIEW -->
            <!-- <br /> -->
            <b-row>
              <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Informazioni Scheda" header-tag="header" footer-tag="footer" title="">
                  <b-row style="align-items: baseline" v-if="this.id != '-1'">
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <label class="sa-label-data mr-2">{{ getLabelTraduora("repeatablePrescriptions.lblStatus") }} :</label>
                      <span class="sa-data" v-if="jsonData.firma">{{ jsonData.firma }}</span>
                      <span v-else>
                        <span class="sa-data">{{ getLabelTraduora("patient.anamnesis.lblFirma") }}</span>
                      </span>
                    </b-col>
                  </b-row>
                  <hr />
                  <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                      <div class="sa-view-form-data"><user-information :data="jsonData"></user-information></div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab v-if="isImmagini" @click="onTabClick">
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>Immagini</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <eventi-immagini-view-component :idRisorsa="idRisorsa" :contesto="contesto"></eventi-immagini-view-component>
          </div>
        </b-tab>
        <b-tab v-if="isAllegati" @click="onTabClick">
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("pazienti.egds.Allegati") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <paziente-allegati-list-component ref="allegatiListComponent" :jsonData="jsonData"></paziente-allegati-list-component>
          </div>
        </b-tab>
        <b-tab v-if="isPrestazioni" :disabled="tabDisabled" @click="onTabClick">
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>Prestazioni Aggiuntive</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <eventi-prestazioni-component :idRisorsa="idRisorsa" :contesto="contesto" :jsonData="jsonData" :isEdit="false"></eventi-prestazioni-component>
          </div>
        </b-tab>
        <!-- <b-tab>
                    <template #title class="sa-prova-uno">
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.cardiology.lblSuggestedTherapies") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <proposta-terapeutica-list-component></proposta-terapeutica-list-component>
                    </div>
                </b-tab> -->
      </b-tabs>
      <!-- <allegato-model-component @updateFiles="onUpdateFile()" :jsonData="jsonData"></allegato-model-component> -->
    </template>
  </sa-page-layout>
</template>

<script>
import EventiMixin from "../../../eventi/utility/mixins/EventiMixins";
import UtilityMixin from "../../../../utility/UtilityMixin";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UserInformation from "../../../../utenti/components/UserInformation.vue";
import PazienteAllegatiListComponent from "../../../../paziente/allegati/components/PazienteAllegatiListComponent.vue";
// import AllegatoModelComponent from "../../../../utility/components/AllegatoModelComponent.vue";
import EventiImmaginiViewComponent from "./EventiImmaginiViewComponent.vue";
import EventiPrestazioniComponent from "./EventiPrestazioniComponent.vue";
// import PropostaTerapeuticaListComponent from "../../../propostaterapeutica/components/PropostaTerapeuticaListComponent.vue";
// import FirmaComponent from "../../utility/components/FirmaComponent.vue";
export default {
  mixins: [UtilityMixin, EventiMixin],
  props: {
    isImmagini: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    btnFirmaVisible: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    isAllegati: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    isPrestazioni: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return {};
      },
    },
    titoloEvento: {
      type: String,
      default: function () {
        return null;
      },
    },
    pathResource: {
      type: String,
      default: function () {
        return null;
      },
    },
    linkPrintData: {
      type: String,
      default: function () {
        return "";
      },
    },
    // pathResourceFirma: {
    //     type: String,
    //     default: function () {
    //         return null;
    //     },
    // },
    isFirmato: {
      type: String,
      default: function () {
        return null;
      },
    },
  },
  created() {},
  components: { SaPageLayout, PazienteAnagrafica, UserInformation, PazienteAllegatiListComponent, EventiImmaginiViewComponent, EventiPrestazioniComponent }, // AllegatoModelComponent,
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    idRisorsa() {
      return this.jsonData.id;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
    tabDisabled() {
      return this.id === "-1";
    },
  },
  mounted() {
    this.linkback = "";
  },
  updated() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
    me.id = this.$route.params.id;
    me.idEvento = me.id;
    let path = this.$route.path.replace(/\/view\/.*/, "");
    me.linkNotBack = path + "{editid}?idPaziente=" + me.idPaziente;
    me.linkedit = path + "/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.linkback = path + "?idPaziente=" + me.idPaziente;
  },
  data() {
    return {
      contesto: "VISITA GASTROENTEROLOGICA",
      pathResourceFirma: "/pazienteeventi",
      firmaFieldName: "firma",
      showModalLoading: false,
      id: "-1",
      idEvento: null,
      pathRestValidation: "/validation",
      childKey: 0,
      tabIndex: 0,
      linkedit: null,
      linkback: null,
      linkNotBack: null,
      btnAllegatiVisible: false,
    };
  },
  methods: {
    getTabClass() {
      let me = this;
      // console.log(me.isImmagini + " " + me.isAllegati + " " + me.isPrestazioni);
      if (!me.isImmagini && !me.isAllegati && !me.isPrestazioni) {
        return "sa-tab sa-no-tabs";
      } else {
        return "sa-tab";
      }
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
      me.$refs["allegatiListComponent"].loadData();
    },
    onFirmaUpdate() {
      this.$emit("updateFirma");
    },
    onTabClick(e) {
      let me = this;
      if (e.originalTarget.firstChild.nodeValue === "Allegati") {
        me.btnAllegatiVisible = !me.tabDisabled;
      } else {
        me.btnAllegatiVisible = false;
      }
    },
  },
};
</script>

<style>
.sa-no-tabs ul.nav.nav-tabs {
  display: none;
}
</style>
