<template>
  <eventi-edit-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData" :isPrestazioni="true">
    <template slot="tab-content-event">
      <polipectomia-endoscopica-intestino-crasso-edit-component @update="onUpdateJsonData"></polipectomia-endoscopica-intestino-crasso-edit-component>
    </template>
  </eventi-edit-component>
</template>

<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import PolipectomiaEndoscopicaIntestinoCrassoEditComponent from "../components/PolipectomiaEndoscopicaIntestinoCrassoEditComponent.vue";
export default {
  components: { EventiEditComponent, PolipectomiaEndoscopicaIntestinoCrassoEditComponent },
  mixins: [UtilityMixin, EventiMixin],

  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      // titoloEvento: this.getLabelTraduora("patients.gastroenterology.egds"),
      titoloEvento: "Polipectomia Endoscopica Intestino Crasso",
      linkback: null,
      linkedit: null,
      jsonData: {},
      pathResourceFirma: "/gastropolipectomiaendoscopicaintestinocrasso",
      pathResource: "/gastropolipectomiaendoscopicaintestinocrasso",
      tabIndex: 0,
      id: null,
      pathRestValidation: "/validation",
      isThisFirmato: false,
      showModalLoading: false,
      linkPrintData: null,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.id = this.$route.params.id;
  },

  methods: {
    updateStatoFirma(firmato) {
      console.log(firmato);
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
      console.log(me.jsonData);
      me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
    },
  },
};
</script>
