<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Familiari" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblMother") }}</label>
                    <b-form-select v-model="jsonData.madre" :options="statoGenitoreOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblFather") }}</label>
                    <b-form-select v-model="jsonData.padre" :options="statoGenitoreOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
            <b-row
                ><b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblBrothers") }}</label>
                    <b-form-select v-model="jsonData.fratelli" :options="YesNoOptions" text-field="text" value-field="value">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblNumberBrothers") }}</label>
                    <b-form-input type="number" v-model="jsonData.numeroFratelli" :disabled="numeroFratelliDisabled"> </b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblSisters") }}</label>
                    <b-form-select v-model="jsonData.sorelle" :options="YesNoOptions" text-field="text" value-field="value">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblNumberSisters") }}</label>
                    <b-form-input type="number" v-model="jsonData.numeroSorelle" :disabled="numeroSorelleDisabled"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblSpouse") }}</label>
                    <b-form-select v-model="jsonData.coniuge" :options="YesNoOptions" text-field="text" value-field="value">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblSpouseStatus") }}</label>
                    <b-form-select v-model="jsonData.coniugeSino" :options="statoGenitoreOptions" :disabled="statoConiugeDisabled">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select> </b-col
                ><b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblSons") }}</label>
                    <b-form-select v-model="jsonData.figli" :options="YesNoOptions" text-field="text" value-field="value">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblNumberOfChildren") }}</label>
                    <b-form-input v-model="jsonData.numeroFigli" type="number" :disabled="numeroFigliDisabled"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblOther") }}</label>
                    <b-form-textarea type="text" v-model="jsonData.altro" rows="8" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="this.getLabelTraduora('patients.lblDiseasesPresent')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblCalculosisOfTheGallbladder") }}</label>
                    <b-form-select v-model="jsonData.calcolosiDellaColecisti" :options="malattieOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select> </b-col
                ><b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblGastricUlcer") }}</label>

                    <b-form-select v-model="jsonData.ulceraGastrica" :options="malattieOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select> </b-col
                ><b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblDuodenalUlcer") }}</label>

                    <b-form-select v-model="jsonData.ulceraDuodenale" :options="malattieOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select> </b-col
                ><b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblStomachCarcinoma") }}</label>

                    <b-form-select v-model="jsonData.carcinomaDelloStomaco" :options="malattieOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select> </b-col
                ><b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblGastricLymphoma") }}</label>

                    <b-form-select v-model="jsonData.linfomaGastrico" :options="malattieOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select> </b-col
                ><b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblRefluxEsophagitis") }}</label>

                    <b-form-select v-model="jsonData.esofagiteDaReflusso" :options="malattieOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select> </b-col
                ><b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblColonCancer") }}</label>

                    <b-form-select v-model="jsonData.carcinomaColon" :options="malattieOptions">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select> </b-col
                ><b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patients.lblOther") }}</label>
                    <b-form-textarea type="text" v-model="jsonData.altraMalattia" rows="8" no-resize></b-form-textarea> </b-col
            ></b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    components: { DatePicker },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    madre: null,
                    padre: null,
                    fratelli: null,
                    sorelle: null,
                    coniuge: null,
                    numeroFratelli: 0,
                    numeroSorelle: 0,
                    coniugeSino: null,
                    numeroFigli: 0,
                    calcolosiDellaColecisti: null,
                    ulceraGastrica: null,
                    ulceraDuodenale: null,
                    carcinomaDelloStomaco: null,
                    linfomaGastrico: null,
                    esofagiteDaReflusso: null,
                    carcinomaColon: null,
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.dataEvento = new Date().getTime();
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                jsonData.numeroSorelle = jsonData.sorelle == this.getLabelTraduora("patient.lblYes") ? jsonData.numeroSorelle : 0;
                jsonData.numeroFratelli = jsonData.fratelli == this.getLabelTraduora("patient.lblYes") ? jsonData.numeroFratelli : 0;
                jsonData.numeroFigli = jsonData.figli == this.getLabelTraduora("patient.lblYes") ? jsonData.numeroFigli : 0;
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    computed: {
        numeroFratelliDisabled() {
            let me = this;
            let returnValue = me.jsonData.fratelli == this.getLabelTraduora("patient.lblYes") ? false : true;
            return returnValue;
        },
        numeroSorelleDisabled() {
            let me = this;
            let returnValue = me.jsonData.sorelle == this.getLabelTraduora("patient.lblYes") ? false : true;
            return returnValue;
        },
        numeroFigliDisabled() {
            let me = this;
            let returnValue = me.jsonData.figli == this.getLabelTraduora("patient.lblYes") ? false : true;
            return returnValue;
        },
        statoConiugeDisabled() {
            let me = this;
            let returnValue = me.jsonData.coniuge == this.getLabelTraduora("patient.lblYes") ? false : true;
            if (returnValue) {
                me.jsonData.coniugeSino = null;
            }
            return returnValue;
        },
    },
    data() {
        return {
            pathResource: "/gastroanamnesifamiliare",
            selectFirstElement: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            statoGenitoreOptions: [
                { text: this.getLabelTraduora("patient.lblNotDetected"), value: this.getLabelTraduora("patient.lblNotDetected") },
                { text: this.getLabelTraduora("patient.lblLiving"), value: this.getLabelTraduora("patient.lblLiving") },
                { text: this.getLabelTraduora("patient.lblDeceased"), value: this.getLabelTraduora("patient.lblDeceased") },
            ],
            malattieOptions: [
                { text: this.getLabelTraduora("patient.lblNotDetected"), value: this.getLabelTraduora("patient.lblNotDetected") },
                { text: this.getLabelTraduora("patient.lblYes"), value: this.getLabelTraduora("patient.lblYes") },
                { text: this.getLabelTraduora("patient.lblNo"), value: this.getLabelTraduora("patient.lblNo") },
            ],
            YesNoOptions: [
                { text: this.getLabelTraduora("patient.lblYes"), value: this.getLabelTraduora("patient.lblYes") },
                { text: this.getLabelTraduora("patient.lblNo"), value: this.getLabelTraduora("patient.lblNo") },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>

<style></style>
