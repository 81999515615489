<template>
  <sa-page-layout :pathResource="pathResource" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <b-button variant="btn-toolbar btn btn-outline-primary" size="sm" @click="onClickExportCsv">
        <i class="bi bi-download sa-icon-i"></i>
        Export CSV
      </b-button>
    </template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Data Dal</label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dal" type="date"></date-picker>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Data Al</label>
            <date-picker :disabled-date="notBeforeDataDal" format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.al" type="date"></date-picker>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-label-data">Tipo Misura:</label>
            <!-- <b-form-tags input-id="tags-basic" v-model="misura"></b-form-tags> -->
            <b-input-group class="mb-2">
              <b-card v-for="tag in listaMisure" :key="tag" class="mt-1 mr-1" body-class="py-1 pr-2 text-nowrap">
                <strong>{{ getLabelTraduora(tag) }}</strong>
                <b-button @click="removeTag(tag)" variant="link" size="sm" :aria-controls="`my-custom-tags-tag_${tag.replace(/\s/g, '_')}_`">Rimuovi</b-button>
              </b-card>
            </b-input-group>
            <b-form-select v-model="value" :options="misureOptions" :value="null" value-field="value" text-field="text" @change="onAddMisura"></b-form-select>
          </b-col>
        </b-row>
        <b-row class="sa-form-btn-filter">
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(index)="row">
            {{ (currentPage - 1) * perPage + row.index + 1 }}
          </template>
          <template v-slot:cell(paziente)="item"> {{ item.item.nome }} {{ item.item.cognome }} </template>
          <template v-slot:cell(measurename)="item">
            {{ getLabelTraduora(item.item.measurename) }}
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { SaPageLayout, DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      rows: null,
      showModalLoading: false,
      pathResourceDownload: "/pazientemisure/downloadcsv",
      pathResource: "/pazientemisure/all",
      filtro: { al: null, dal: null, misura: null },
      items: [],
      value: null,
      listaMisure: [],
      misureOptions: [],
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 5rem",
        },
        {
          label: "Tipo Misurazione",
          key: "paziente",
          sortable: true,
        },
        {
          label: "Tipo Misurazione",
          key: "measurename",
          sortable: false,
        },
        {
          label: "Valore",
          key: "value",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "Data Rilevazione",
          key: "istant",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 15rem",
        },
        {
          label: "Dispositivo",
          key: "idDevice",
          sortable: false,
          thStyle: "width: 15rem",
        },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.loadListaMisurazioni();
    me.filtro.dataDal = new Date().getTime();
    me.filtro.dataAl = new Date().getTime();
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data;
          me.rows = response.data.data.length;
          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    loadListaMisurazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazientemisuretipi";
      axios.get(link).then((response) => {
        me.misureOptions = [];
        me.misureOptions.push({ value: null, text: "-Seleziona valore-" });
        response.data.data.forEach((element) => {
          if (element.tipoGrafico !== null && element.tipoGrafico !== "") {
            me.misureOptions.push({ value: element.descrizione, text: me.getLabelTraduora(element.descrizione) });
          }
        });
      });
    },
    onAddMisura() {
      let me = this;
      let present = false;
      me.listaMisure.forEach((element) => {
        if (element === me.value) {
          present = true;
        }
      });
      if (!present) {
        me.listaMisure.push(me.value);
      }
      me.value = null;
    },
    onClickExportCsv() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDownload;
      let fileName = "Export Misurazioni " + moment(new Date()).format("DD MM YYYY HH-mm") + ".csv";
      axios({
        url: link,
        params: { dal: me.filtro.dataDal, al: me.filtro.dataAl, idPaziente: me.idPaziente, misura: me.filtro.misura },
        method: "GET",
        responseType: "blob",
      })
        .then((response) => {
          // console.log(response.data);
          var blob = new Blob([response.data]);
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.href = href;
          downloadElement.download = fileName;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    notBeforeDataDal(date) {
      let me = this;
      return date < me.filtro.dal;
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      me.filtro.misura = me.convertToString(me.listaMisure);
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = { dataDal: null, dataAl: null, misura: null };
      me.listaMisure = [];
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
    },
    removeTag(value) {
      let me = this;
      me.listaMisure = me.listaMisure.filter((item) => item !== value);
    },
    convertToString(array) {
      let returnValue = "";
      array.forEach((element) => {
        if (returnValue === "") {
          returnValue = element;
        } else {
          returnValue += "#" + element;
        }
      });
      return returnValue;
    },
  },
};
</script>
