<template>
    <div class="sa-tab-scroll">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Data Evento</label>
                            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECO RENALE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Eco Renale</label>
                            <b-form-select v-model="jsonData.ecorenale" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.noteecorenale" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="SCINTIGRAFIA RENALE SEQUENZIALE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Scintigrafia Renale Sequenziale</label>
                            <b-form-select v-model="jsonData.scintigrafiarenale" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.notescintigrafia" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="VFG" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tot. (ml/min)</label>
                            <b-form-input v-model="jsonData.vfgtot"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Dx (ml/min)</label>
                            <b-form-input v-model="jsonData.vfgdx"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sin (ml/min)</label>
                            <b-form-input v-model="jsonData.vfgsin"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="Captopril" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Tot. (ml/min)</label>
                            <b-form-input v-model="jsonData.captoprilTot"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Dx (ml/min)</label>
                            <b-form-input v-model="jsonData.captoprilDx"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Sin (ml/min)</label>
                            <b-form-input v-model="jsonData.captoprilSin"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="ANGIOGRAFIA SELETTIVA" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Angiografia Selettiva</label>
                            <b-form-select v-model="jsonData.angiografia" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.noteangiografia" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="ECOGRAFIA TIROIDEA" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Ecografia Tiroidea</label>
                            <b-form-select v-model="jsonData.ecografiaTiroidea" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.ecografiaTiroideaNote" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="SCINTIGRAFIA TIROIDEA" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Scintigrafia Tiroidea</label>
                            <b-form-select v-model="jsonData.scintigrafiaTiroidea" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.scintigrafiaTiroideaNote" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="TAC CRANIO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">TAC Cranio</label>
                            <b-form-select v-model="jsonData.tacCranio" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.tacCranioNote" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="TAC TORACE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">TAC Torace</label>
                            <b-form-select v-model="jsonData.tacTorace" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.tacToraceNote" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="TAC ADDOME" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">TAC Addome</label>
                            <b-form-select v-model="jsonData.tacAddome" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.tacAddomeNote" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="RMN CRANIO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">RMN Cranio</label>
                            <b-form-select v-model="jsonData.rmnCranio" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.rmnCranioNote" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="RMN TORACE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">RMN Torace</label>
                            <b-form-select v-model="jsonData.rmnTorace" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.rmnToraceNote" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="RMN ADDOME" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">RMN Addome</label>
                            <b-form-select v-model="jsonData.rmnAddome" :options="esamiStrumentaliOptions" :value="''" text-field="text" value-field="value"></b-form-select>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Note</label>
                            <b-form-textarea v-model="jsonData.rmnAddomeNote" no-resize rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECOCOLORDOPPLER ARTERIE RENALI - ORIGINE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV DX (cm/sec)</label>
                            <b-form-input v-model="jsonData.psvOrigineDx" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV DX (cm/sec)</label>
                            <b-form-input v-model="jsonData.edvOrigineDx" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR DX</label>
                            <b-form-input v-model="jsonData.irOrigineDx" type="number" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV SX (cm/sec)</label>
                            <b-form-input v-model="jsonData.psvOrigineSx" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV SX (cm/sec)</label>
                            <b-form-input v-model="jsonData.edvOrigineSx" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR SX</label>
                            <b-form-input v-model="jsonData.irOrigineSx" type="number" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECOCOLORDOPPLER ARTERIE RENALI - TRATTO MEDIO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV DX (cm/sec)</label>
                            <b-form-input v-model="jsonData.vpsDxTrattoMedio" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV DX (cm/sec)</label>
                            <b-form-input v-model="jsonData.edvVpsDxTrattoMedio" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR DX</label>
                            <b-form-input v-model="jsonData.irDxTrattoMedio" type="number" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV SX (cm/sec)</label>
                            <b-form-input v-model="jsonData.vpsSxTrattoMedio" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV SX (cm/sec)</label>
                            <b-form-input v-model="jsonData.edvVpsSxTrattoMedio" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR SX</label>
                            <b-form-input v-model="jsonData.irSxTrattoMedio" type="number" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECOCOLORDOPPLER ARTERIE RENALI - ILO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV DX (cm/sec)</label>
                            <b-form-input v-model="jsonData.vpsDxIlo" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV DX (cm/sec)</label>
                            <b-form-input v-model="jsonData.edvVpsDxIlo" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR DX</label>
                            <b-form-input v-model="jsonData.irDxIlo" type="number" disabled></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">PSV SX (cm/sec)</label>
                            <b-form-input v-model="jsonData.vpsSxIlo" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">EDV SX (cm/sec)</label>
                            <b-form-input v-model="jsonData.edvVpsSxIlo" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">IR SX</label>
                            <b-form-input v-model="jsonData.irSxIlo" type="number" disabled></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <b-card class="sa-card" header="ECOCOLORDOPPLER ARTERIE RENALI - INTRAPARENCHIMALE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">PSV DX (cm/sec)</label>
                            <b-form-input v-model="jsonData.psvDxIntraparenchimale" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">EDV DX (cm/sec)</label>
                            <b-form-input v-model="jsonData.edvDxIntraparenchimale" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">IR DX</label>
                            <b-form-input v-model="jsonData.irDxIntraparenchimale" type="number" disabled></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">AT DX</label>
                            <b-form-input v-model="jsonData.atDxIntraparenchimale" type="number"></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">PSV SX (cm/sec)</label>
                            <b-form-input v-model="jsonData.psvSxIntraparenchimale" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">EDV SX (cm/sec)</label>
                            <b-form-input v-model="jsonData.edvSxIntraparenchimale" type="number" v-on:keydown.enter="onKeyPressEnterCalcoli"></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">IR SX</label>
                            <b-form-input v-model="jsonData.irSxIntraparenchimale" type="number" disabled></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">AT SX</label>
                            <b-form-input v-model="jsonData.atSxIntraparenchimale" type="number"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="CONCLUSIONI" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Conclusioni</label>
                            <b-form-textarea v-model="jsonData.conclusioniEcodoppler" rows="6" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="AORTA ADDOMINALE" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Aorta Addominale</label>
                            <b-form-textarea v-model="jsonData.aortaaddominale" rows="6" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="ALTRO" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">Altro</label>
                            <b-form-textarea v-model="jsonData.altroesame" rows="6" no-resize></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },

    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            pathResource: "/cardioesamistrumentali",
            esamiStrumentaliOptions: [
                { value: "", text: "-Seleziona Valore-" },
                { value: "DUBBIA", text: "DUBBIA" },
                { value: "NORMALE", text: "NORMALE" },
                { value: "PATOLOGICA", text: "PATOLOGICA" },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
                console.log(me.jsonData.dataEvento);
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        onKeyPressEnterCalcoli() {
            console.log("OK CALCOLI");
        },
    },
};
</script>
