<template>
    <div>
        <b-card class="sa-card" header="PRENOTAZIONE" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome</label>
                    <span class="sa-data">{{ jsonData.nome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome</label>
                    <span class="sa-data">{{ jsonData.cognome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Nascita</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataNascita) }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Atleta</label>
                    <span class="sa-data">{{ jsonData.tipoAtleta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sport</label>
                    <span class="sa-data">{{ jsonData.sport }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Visita</label>
                    <span class="sa-data">{{ jsonData.tipoVisita }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Prenotazione</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPrenotazione) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-if="jsonData.agendaAppuntamentoBean">
                    <label class="sa-label-data">Data Visita</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataVisita) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-if="jsonData.agendaAppuntamentoBean">
                    <label class="sa-label-data">Orario Visita</label>
                    <span class="sa-data">{{ formatTime(jsonData.agendaAppuntamentoBean.dataOraInizio) }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Scadenza Certificato</label>
                    <span class="sa-data">{{ formatDate(jsonData.scadenzaCertificato) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono</label>
                    <span class="sa-data">{{ jsonData.telefono }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Email</label>
                    <span class="sa-data">{{ jsonData.email }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <span class="sa-data">{{ jsonData.noteAggiuntive }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
// import moment from "moment";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            Type: Object,
            default: function () {
                return {
                    nome: "",
                    cognome: "",
                    dataNascita: null,
                    sport: "",
                    tipoVisita: "",
                    noteAggiuntive: "",
                    tipoAtleta: "",
                    dataVisita: null,
                    oraVisita: "",
                    telefono: "",
                    email: "",
                    scadenzaCertificato: null,
                    dataPrenotazione: null,
                    agendaAppuntamentoBean: {},
                };
            },
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
