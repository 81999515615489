import SchedaValutazioneEsigenzeImmediateList from "../svei/pages/SchedaValutazioneEsigenzeImmediateList";
import SchedaValutazioneEsigenzeImmediateEdit from "../svei/pages/SchedaValutazioneEsigenzeImmediateEdit";
import SchedaValutazioneEsigenzeImmediateView from "../svei/pages/SchedaValutazioneEsigenzeImmediateView";
import SveiMappa from "../svei/pages/SveiMappa";

export default {
    SchedaValutazioneEsigenzeImmediateList,
    SchedaValutazioneEsigenzeImmediateEdit,
    SchedaValutazioneEsigenzeImmediateView,
    SveiMappa,
};
