<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblGeneralData')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
          <b-form-input v-model="jsonData.peso" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
          <b-form-input v-model="jsonData.altezza" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblBMI") }}
          </label>
          <b-form-input v-model="jsonData.bmi" type="number" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblBSA") }}
          </label>
          <b-form-input v-model="jsonData.bsa" type="number" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAS") }}</label>
          <b-form-input v-model="jsonData.pas" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFC") }}</label>
          <b-form-input v-model="jsonData.fc" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightLowerLimbSystolic") }}</label>
          <b-form-input v-model="jsonData.pasArtoDx" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightIW") }}</label>
          <b-form-input v-model="jsonData.iwDx" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftLowerLimbSystolic") }}</label>
          <b-form-input v-model="jsonData.pasArtoSx" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftIW") }}</label>
          <b-form-input v-model="jsonData.iwSx" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLeftLowerLimbECD')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <ecd-arterioso-art-inf-sx-edit-component :jsonData="jsonData"></ecd-arterioso-art-inf-sx-edit-component>
      </b-row>
    </b-card>

    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightLowerLimbECD')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <ecd-arterioso-art-inf-dx-edit-component :jsonData="jsonData"></ecd-arterioso-art-inf-dx-edit-component>
      </b-row>
    </b-card>

    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblReportNotes')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProtocol") }}</label>
          <b-form-textarea v-model="jsonData.protocollo" rows="8" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVideotape") }}</label>
          <b-form-textarea v-model="jsonData.videotape" rows="8" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCardiovascularRiskFactors')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertension") }}</label>

          <b-form-checkbox v-model="jsonData.ipertensione" switch :checked="jsonData.ipertensione"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemia") }}</label>

          <b-form-checkbox v-model="jsonData.dislipidemia" switch :checked="jsonData.dislipidemia"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabetes") }}</label>

          <b-form-checkbox v-model="jsonData.diabete" switch :checked="jsonData.diabete"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSmoking") }}</label>

          <b-form-checkbox v-model="jsonData.fumo" switch :checked="jsonData.fumo"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</label>

          <b-form-checkbox v-model="jsonData.obesita" switch :checked="jsonData.obesita"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</label>

          <b-form-checkbox v-model="jsonData.familIpertensione" switch :checked="jsonData.familIpertensione"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIschemicHeartDiseaseFamiliarity") }}</label>

          <b-form-checkbox v-model="jsonData.familCardiopatiaIsch" switch :checked="jsonData.familCardiopatiaIsch"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCardiovascularAccidentsFamiliarity") }}</label>

          <b-form-checkbox v-model="jsonData.familCerebrovascolari" switch :checked="jsonData.familCerebrovascolari"></b-form-checkbox>
        </b-col>
      </b-row>

      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAiC75") }}</label>
          <b-form-input v-model="jsonData.aiC75" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPASC") }}</label>

          <b-form-input v-model="jsonData.pasc" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPADC") }}</label>

          <b-form-input v-model="jsonData.padc" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPPC") }}</label>

          <b-form-input v-model="jsonData.ppc" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>

          <b-form-textarea v-model="jsonData.noteLibere" rows="8" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComments") }}</label>

          <b-form-textarea v-model="jsonData.commento" rows="8" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExamInTheNorm") }}</label>
          <b-form-checkbox v-model="jsonData.esameNellaNorma" switch :checked="jsonData.esameNellaNorma"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSignificantStenosis") }}</label>

          <b-form-input v-model="jsonData.commento1" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>

          <b-form-checkbox v-model="jsonData.stenosiCeSx" switch :checked="jsonData.stenosiCeSx"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>

          <b-form-checkbox v-model="jsonData.stenosiCeDx" switch :checked="jsonData.stenosiCeDx"></b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMildAtherosclerosis") }}</label>

          <b-form-checkbox v-model="jsonData.aterosclerosiLieve" switch :checked="jsonData.aterosclerosiLieve"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSignificantStenosis") }}</label>

          <b-form-input v-model="jsonData.commento2" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>

          <b-form-checkbox v-model="jsonData.stenosiCiSx" switch :checked="jsonData.stenosiCiSx"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>

          <b-form-checkbox v-model="jsonData.stenosiCiDx" switch :checked="jsonData.stenosiCiDx"></b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModerateAtherosclerosis") }}</label>
          <b-form-checkbox v-model="jsonData.aterosclerosiModerata" switch :checked="jsonData.aterosclerosiModerata"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSignificantStenosis") }}</label>
          <b-form-input v-model="jsonData.commento3" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
          <b-form-checkbox v-model="jsonData.stenosiCcSx" switch :checked="jsonData.stenosiCcSx"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
          <b-form-checkbox v-model="jsonData.stenosiCcDx" switch :checked="jsonData.stenosiCcDx"></b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import EcdArteriosoArtInfSxEditComponent from "./EcdArteriosoArtInfSxEditComponent.vue";
import EcdArteriosoArtInfDxEditComponent from "./EcdArteriosoArtInfDxEditComponent";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker, EcdArteriosoArtInfDxEditComponent, EcdArteriosoArtInfSxEditComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },
  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardioecodopplerartiinferiori",
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onCalcoloBmiBsa(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
      me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
