<template>
  <div>
    <b-table sticky-header stacked="md" striped hover itemscope :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
      <template #cell(index)="row">
        {{ row.index + 1 }}
      </template>
      <template #cell(tipologiaPrestazione)="item">
        <b-form-input v-model="item.item.tipologiaPrestazione" class="text-upper" :disabled="edit"></b-form-input>
      </template>
      <template #cell(numeroAccessi)="item">
        <b-form-input v-model="item.item.numeroAccessi" class="text-upper" :disabled="edit"></b-form-input>
      </template>
      <template #cell(oreDiePerGiorniSettimane)="item">
        <b-form-input v-model="item.item.oreDiePerGiorniSettimane" class="text-upper" :disabled="edit"></b-form-input>
      </template>
      <template #cell(actions)="row">
        <b-button v-if="row.index == items.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddTabellaRiepilogativa(items, row.item)" :disabled="edit">
          <b-icon icon="plus"></b-icon>
        </b-button>
        <b-button v-if="row.index !== items.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(items, row)" :disabled="edit">
          <b-icon icon="trash"></b-icon>
        </b-button>
      </template>
    </b-table>
  </div>
</template>
<script>
export default {
  props: {
    edit: Boolean,
    items: {
      type: Array[Object],
      default: function () {
        return {
          tipologiaPrestazione: "",
          numeroAccessi: "",
          oreDiePerGiorniSettimane: "",
        };
      },
    },
  },
  mounted() {},
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipologia Prestazione",
          key: "tipologiaPrestazione",
          sortable: false,
        },
        {
          label: "Numero Accessi",
          key: "numeroAccessi",
          sortable: false,
        },
        {
          label: "N. Ore/Die x N. gg/Sett.",
          key: "oreDiePerGiorniSettimane",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
    };
  },

  methods: {
    onAddTabellaRiepilogativa(array, value) {
      if (value.tipologiaPrestazione !== "" && value.numeroAccessi !== "" && value.oreDiePerGiorniSettimane) {
        array.push({ tipologiaPrestazione: "", numeroAccessi: "", oreDiePerGiorniSettimane: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updateTabellaRiepilogativa() {
      let me = this;
      me.$emit("updateTabellaRiepilogativa", me.items);
    },
  },
};
</script>
