<template>
    <!-- <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="isThisFirmato" :btnAnnullaVisible="false"
        :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="jsonData"
        class="sa-no-space">
        <template slot="toolbar">
            <firma-component class="float-sm-right primary" :idEvento="jsonData.id" :pathRestFirma="pathResource" :pathRestValidation="pathRestValidation" :data="jsonData" :disabled="!isThisFirmato" @update="onFirmaUpdate"></firma-component> 
        </template>
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header">
            <h3 class="sa-event-title">Refertazione Ecocardio</h3>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <refertazione-ecocardio-view-component :idEvento="id"></refertazione-ecocardio-view-component>
            </div>
        </template>
    </sa-page-layout>-->
    <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
        <template slot="tab-content-event">
            <refertazione-ecocardio-view-component :idEvento="id" @update="onUpdateJsonData"></refertazione-ecocardio-view-component>
        </template>
    </eventi-view-component>
</template>
<script>
// import axios from "axios";
// import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixin from "../../../utility/mixins/EventiMixins";
// import FirmaComponent from "../../../../../paziente/eventi/components/FirmaComponent.vue";
// import UserInformation from "../../../../../utenti/components/UserInformation.vue";
import RefertazioneEcocardioViewComponent from "./RefertazioneEcocardioViewComponent.vue";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";

export default {
    mixins: [UtilityMixins, EventiMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { RefertazioneEcocardioViewComponent, EventiViewComponent }, //SaPageLayout,UserInformation,PazienteAnagrafica
    data() {
        return {
            titoloEvento: this.getLabelTraduora("patient.cardiology.lblEchocardiumReporting"),
            linkback: null,
            linkedit: null,
            pathResource: "/cardioecocardirefertazioni",
            pathResourceFirma: "/cardioecocardirefertazioni",
            jsonData: {},
            tabIndex: 0,
            id: null,
            pathRestValidation: "/validation",
            showModalLoading: false,
            isThisFirmato: false,
            linkPrintData: null,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.id = this.$route.params.id;
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
        },
    },
};
</script>
