<template>
  <sa-page-layout ref="pgaView" :data="jsonData" :pathResource="pathResource" :btnBackVisible="true" :btnSaveVisible="true" :signatureVisible="false" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <!-- <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template> -->
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section">
          <!-- <h5 class="sa-text-left">{{ this.$i18n.t("videocare.lblDoctorData") }}</h5> -->
          <b-card class="sa-card" header="Dati Medico" header-tag="header" footer-tag="footer" title="">
            <b-row>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblName") }}</label>
                <b-form-input v-model="jsonData.nomeMedicoPresente" :placeholder="this.$i18n.t('videocare.lblName')"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblSurname") }}</label>
                <b-form-input v-model="jsonData.cognomeMedico" :placeholder="this.$i18n.t('videocare.lblSurname')"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblDoctorCode") }}</label>
                <b-form-input v-model="jsonData.codiceMedicoPresente" :placeholder="this.$i18n.t('videocare.lblDoctorCode')"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblPhoneNumber") }}</label>
                <b-form-input v-model="jsonData.telefonoMedico" :placeholder="this.$i18n.t('videocare.lblPhoneNumber')"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("teleconsulto.lblEmail") }}</label>
                <b-form-input v-model="jsonData.emailMedico" :placeholder="this.$i18n.t('teleconsulto.lblEmail')"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4"> </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="sa-form-section">
          <b-card class="sa-card" header="Dati pazienti" header-tag="header" footer-tag="footer" title="">
            <!-- <h5 class="sa-text-left">{{ this.$i18n.t("videocare.lblPatientData") }}</h5> -->
            <b-row>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <label class="sa-label-data">Username</label>
                <b-form-input v-model="jsonData.usernameRichiedente" placeholder="Username"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblPhoneNumber") }}</label>
                <b-form-input v-model="jsonData.telefonoPaziente" :placeholder="this.$i18n.t('videocare.lblPhoneNumber')"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("teleconsulto.lblEmail") }}</label>
                <b-form-input v-model="jsonData.emailPaziente" :placeholder="this.$i18n.t('teleconsulto.lblEmail')"></b-form-input>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="sa-form-section">
          <b-card class="sa-card" header="Prenotazione" header-tag="header" footer-tag="footer" title="">
            <!-- <h5 class="sa-text-left">{{ this.$i18n.t("videocare.lblReservation") }}</h5> -->
            <b-row>
              <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblBookingDateAndTime") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraPrenotazione" type="datetime" :placeholder="this.$i18n.t('videocare.lblBookingDateAndTime')"></date-picker>
              </b-col>
            </b-row>
          </b-card>
        </div>
        <div class="sa-form-section">
          <b-card class="sa-card" header="Metodo invio" header-tag="header" footer-tag="footer" title="">
            <!-- <h5 class="sa-text-left">{{ this.$i18n.t("videocare.lblSendingMethod") }}</h5> -->
            <b-row>
              <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblSmsInvitation") }} </label>
                <input type="checkbox" id="flat" name="invitoSms" class="switch-input" v-model="jsonData.invitoSms" value="flat" />
              </b-col>
              <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ this.$i18n.t("videocare.lblEmailInvitation") }} </label>
                <input type="checkbox" id="flat" name="invitoEmail" class="switch-input" v-model="jsonData.invitoEmail" value="flat" />
              </b-col>
            </b-row>
          </b-card>
        </div>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import "vue2-datepicker/index.css";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteDashboardComponent from "./PazienteDashboardComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { DatePicker, PazienteAnagrafica, SaPageLayout }, //PazienteDashboardComponent
  data() {
    return {
      //pathRest: "/teleconsulti",
      pathResource: "/teleconsultoprenotazioni",
      linkback: "/paziente/teleconsulti",
      linkedit: null,
      showModalLoading: false,
      file1: null,
      id: "-1",
      profili: [],
      jsonData: { nomeMedicoPresente: null },
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function () {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  /*watch: {
        utente: {
            set: function(utente) {
                console.log(utente);
                this.jsonData.nomeMedicoPresente = utente.lastname;
            },
        },
    },*/
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.nomeMedicoPresente = me.utente.firstname;
    me.jsonData.cognomeMedico = me.utente.lastname;
    if (me.id !== "-1") {
      me.linkback = "/paziente/teleconsulti/view/" + me.id + "?idPaziente=" + me.idPaziente;
    }
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.loadDefaultData();
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    loadDefaultData() {
      let me = this;
      me.loadData();
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    onBack() {},
    onSave() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.jsonData.idPaziente = me.idPaziente;
      console.log(me.jsonData);
      let jsonData = JSON.stringify(me.jsonData);
      me.showModalLoading = true;
      if (me.id == "-1") {
        axios
          .post(link, jsonData)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
            me.$router.replace(me.routeLink).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            me.showModalLoading = false;
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      } else {
        axios
          .put(link + "/" + me.id, jsonData)
          .then((response) => {
            me.gruppo = response.data.data;
            me.$refs["sa-modal-loading"].close();
            me.$router.replace(me.routeLink).catch((err) => {
              err;
            });
          })
          .catch((error) => {
            me.$refs["sa-modal-loading"].close();
            this.$bvModal
              .msgBoxOk(error.response.data.messaggio, {
                title: "ATTENZIONE",
                size: "sm",
                okVariant: "outline-danger",
                headerClass: "sa-msg-header-danger",
                footerClass: "p-2 border-top-0",
                centered: true,
              })
              .then((value) => {
                this.boxTwo = value;
              })
              .catch((err) => {
                console.log(err);
              });
          });
      }
    },
  },
};
</script>
