<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label
                    ><span class="sa-data"> {{ formatDateTime(jsonData.dataEvento) }}</span></b-col
                >
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblDiagnosis')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStrokeTiaInsorgenceDate") }}</label
                    ><span class="sa-data"> {{ formatDateTime(jsonData.dataStroketia) }}</span></b-col
                >
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHours") }}</label
                    ><span class="sa-data"> {{ jsonData.ore }}</span></b-col
                >
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatientIsHospitalized") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.ospedsino) }}</span></b-col
                >
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRecoveryDate") }}</label
                    ><span class="sa-data"> {{ formatDateTime(jsonData.dataRicovero) }}</span></b-col
                >
                <b-col xs="12" sm="4" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDischargeDate") }}</label
                    ><span class="sa-data"> {{ formatDateTime(jsonData.dataDimissione) }}</span></b-col
                >
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSymptoms')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCognitiveAbilitiesChanges") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.sint1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConsciousnessLevelChanges") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.sint2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIpostenia") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.sint3) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSensoryClouding") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.sint4) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCommunicationDisturbances") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.sint5) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEquilibriumDisturbances") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.sint6) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                    <span class="sa-data"> {{ getLabelTraduora(jsonData.sint7) }}</span>
                    <br />
                    <span class="sa-data">{{ jsonData.altro }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOver24HoursSymptonsDuration") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.durata) }}</span></b-col
                >
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblActualState')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSymptonsCompleteRemission") }}</label>
                    <span :class="setColor(jsonData.statoattuale)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.statoattuale)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatientNeedsOtherPeopleForDailyActivities") }}</label>
                    <span :class="setColor(jsonData.statoattuale2)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.statoattuale2)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSymptonsPersistenceWithoutFunctionalLimitation") }}</label>
                    <span :class="setColor(jsonData.statoattuale3)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.statoattuale3)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatientIsIncapable") }}</label>
                    <span :class="setColor(jsonData.statoattuale4)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.statoattuale4)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatientWithSomeLimitations") }}</label>
                    <span :class="setColor(jsonData.statoattuale5)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.statoattuale5)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeceasedPatient") }}</label>
                    <span :class="setColor(jsonData.statoattuale6)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.statoattuale6)"></b-icon>
                    </span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.afa.lblFinalDiagnosis')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTacRmAutopsyConfirmedDiagnosis") }}</label
                    ><span class="sa-data"> {{ getLabelTraduora(jsonData.conferma) }}</span></b-col
                >
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCerebralIctus") }}</label>
                    <span :class="setColor(jsonData.inf)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.inf)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUncertainNatureStroke") }}</label>
                    <span :class="setColor(jsonData.inf2)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.inf2)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInBrainBloodLoss") }}</label>
                    <span :class="setColor(jsonData.inf3)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.inf3)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUnderSkullBloodLoss") }}</label>
                    <span :class="setColor(jsonData.inf4)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.inf4)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTIA") }}</label>
                    <span :class="setColor(jsonData.inf5)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.inf5)"></b-icon>
                    </span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            pathResource: "/cardiodaticlinicistrumentaliictus",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
