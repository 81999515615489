<template>
    <sa-page-layout :btnRefreshVisible="true" :btnBackVisible="true" :linkback="linkback" @refresh="onRefresh" :showModalLoading="showModalLoading" :pathResource="pathResource" :titolo="titolo">
        <!-- <template slot="toolbar">
            <b-button class="float-sm-left btn-toolbar sa-btn-icon-text" variant="outline-secondary sa-margin-left" size="sm" @click="onFirma">
                <b-icon icon="vector-pen"></b-icon>
                Firma
            </b-button>
        </template> -->
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="date" placeholder="Data Dal"></date-picker>
                    </b-col>
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="date" placeholder="Data Al"></date-picker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table ref="tbtEventi" sticky-header id="tbl" :filter="filtro" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(index)="row">
                        {{ (currentPage - 1) * perPage + row.index + 1 }}
                    </template>
                    <template v-slot:cell(cognomeNomeDatabase)="{ item }">{{ item.cognomeDatabase }} {{ item.nomeDatabase }}</template>
                    <template v-slot:cell(cognomeNomeReferto)="{ item }">{{ item.cognomeReferto }} {{ item.nomeReferto }}</template>
                    <template v-slot:cell(firmaWincare)="{ item }">
                        <b-icon v-if="item.firmaWincare" icon="check" font-scale="2.5" variant="success" />
                        <b-icon v-else icon="x" font-scale="2.5" variant="danger" />
                    </template>
                    <template v-slot:cell(firmaDigitale)="{ item }">
                        <b-icon v-if="item.firmaDigitale" icon="check" font-scale="2.5" variant="success" />
                        <b-icon v-else icon="x" font-scale="2.5" variant="danger" />
                    </template>
                    <template v-slot:cell(validoPerFirma)="{ item }">
                        <b-icon v-if="item.validoPerFirma" icon="check" font-scale="2.5" variant="success" />
                        <b-icon v-else icon="x" font-scale="2.5" variant="danger" />
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import FirmaRemotaArubaComponent from "../../utility/components/FirmaRemotaArubaComponent.vue";
export default {
    props: {
        utente: Object,
    },
    components: { DatePicker, SaPageLayout }, //FirmaRemotaArubaComponent
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showModalLoading: false,
            linkback: null,
            pathResource: "/wincareold",
            currentPage: 1,
            perPage: 100,
            titolo: null,
            filtro: { dataDal: null, dataAl: null, nomeTabella: "" },
            // filterOn: [],
            filesSelezionati: [],
            rowSelected: [],
            fields: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 5rem",
                },
                {
                    label: "Cod. Paz.",
                    key: "codPaz",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Data Evento",
                    key: "dataEvento",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
                {
                    label: "Esaminatore",
                    key: "esaminatore",
                    sortable: true,
                },
                {
                    label: "Cognome Nome Database",
                    key: "cognomeNomeDatabase",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patients.lblBirthDate"),
                    key: "dataNascita",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
                {
                    label: "Cognome Nome Referto",
                    key: "cognomeNomeReferto",
                    sortable: true,
                },
                {
                    label: "File PDF",
                    key: "filePdf",
                    sortable: true,
                    tdClass: "sa-td-wrap-text",
                },
                {
                    label: "Valido per Firma",
                    key: "validoPerFirma",
                    class: "text-center",
                    sortable: true,
                },
                {
                    label: "Firma Wincare",
                    key: "firmaWincare",
                    class: "text-center",
                    sortable: true,
                },
                {
                    label: "Firma Digitale",
                    key: "firmaDigitale",
                    class: "text-center",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                },
            ],

            items: [],
            // traduzione: "",
        };
    },
    mounted: function () {
        let me = this;
        me.linkback = "/wincareoldadmin";
        me.filtro.dataDal = new Date().getTime();
        me.filtro.dataAl = new Date().getTime();
        let wincareOldAdmin = sessionStorage.getItem("WincareOldAdmin");
        let wincareOldAdminData = {};
        if (wincareOldAdmin !== null && wincareOldAdmin !== "null") {
            wincareOldAdminData = JSON.parse(wincareOldAdmin);
            me.setDatiWincareOldAdmin(wincareOldAdminData);
        }
        // sessionStorage.setItem("WincareOldAdmin", null);
        this.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(function (error) {
                    me.showModalLoading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        setDatiWincareOldAdmin(data) {
            let me = this;
            me.filtro.dataDal = data.dataDal;
            me.filtro.dataAl = data.dataAl;
            me.filtro.nomeTabella = data.tipoEvento;
            me.titolo = data.tipoEvento.replaceAll("_", " ");
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit() {
            let me = this;
            me.loadData();
        },
        onReset() {
            let me = this;
            me.items = [];
            me.loadData();
        },
    },
};
</script>

<style>
.sa-td-wrap-text div {
    overflow-wrap: anywhere;
}
</style>
