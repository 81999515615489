<template>
  <eventi-list-component :titoloEvento="titoloEvento" :utente="utente" gruppo="MEDICINADELLOSPORT" :key="keyListaEventi" :pathEventResource="pathEventResource" :linkEvent="linkEvent" tipoEvento="MEDICINADELLOSPORTAFA"></eventi-list-component>
</template>

<script>
import EventiListComponent from "../../../base/components/EventiListComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: {
    EventiListComponent,
  },

  watch: {
    utente: function () {
      this.keyListaEventi++;
    },
  },
  mounted() {},
  data() {
    return {
      titoloEvento: this.$i18n.t("patient.afa.lblVisit"),
      pathEventResource: "/medicinadellosportafa",
      pathResourceMedicinaDelloSportAfa: "/medicinadellosportafa",
      keyListaEventi: 0,
      linkEvent: "/paziente/medicinadellosportafa",
    };
  },
};
</script>

<style></style>
