<template>
    <sa-page-layout ref="pgaView" :jsonData="jsonData" :pathResource="pathResource" :btnBackVisible="true" :btnEditVisible="nonTerminata" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <!-- <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template> -->
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblDrug") }} :</label>

                        <span class="sa-data">{{ jsonData.farmaco }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblQuantity") }} :</label>

                        <span class="sa-data">{{ jsonData.quantita }}</span>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblFrequency") }} :</label>

                        <div>
                            <b-card class="sc-card-max-height" style="height: 100%; width: 100%" no-body>
                                <div class="sa-calendar-day-grid-slot">
                                    <table class="sa-calendar-day-grid-table-slot">
                                        <tr v-for="(item1, i) in griglia" :key="i">
                                            <td v-for="(item2, j) in item1" :key="j">
                                                <div class="text-center">{{ item1 * i + j }} - {{ item1 * i + (j + 1) }}</div>
                                                <!-- {{ item1 * i + j }} -->
                                                <div v-if="isSelectOrarioSomministrazione(item1 * i + j)" class="sa-div-cell"><b-icon icon="check"></b-icon></div>
                                                <div v-else class="sa-div-cell"></div>
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </b-card>
                        </div>
                    </b-col>
                    <b-col>
                        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblPosology") }} :</label>

                        <span class="sa-data">{{ getLabelTraduora(jsonData.posologia) }} </span>
                        <hr />
                        <b-row>
                            <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data"> {{ this.$i18n.t("therapy.lblStartDate") }} :</label>

                                <span class="sa-data"> {{ formatDateTime(jsonData.dataInizio) }}</span>
                            </b-col>
                            <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ this.$i18n.t("therapy.lblEndDate") }} :</label>

                                <span class="sa-data"> {{ formatDateTime(jsonData.dataFine) }}</span>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label class="sa-label-data">{{ this.$i18n.t("therapy.lblStatus") }} :</label>

                                <span class="sa-data">{{ getLabelTraduora(jsonData.statoDescrizione) }} </span>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" md="12">
                        <label class="sa-label-data"> {{ this.$i18n.t("therapy.lblNote") }} :</label>

                        <span class="sa-data">{{ jsonData.note }}</span>
                    </b-col>
                </b-row>
                <!-- <b-row>
        <b-col cols="12" md="2" class="sa-text-right"></b-col>
        <b-col cols="12" md="10">
        <b-button variant="success" @click="onSave()">Inserisci</b-button>
        </b-col>
    </b-row>  -->
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../../utility/UtilityMixin";
//import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
// import PazienteDashboardComponent from "../../components/PazienteDashboardComponent.vue";
export default {
    components: { PazienteAnagrafica, SaPageLayout }, //PazienteDashboardComponent
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },

    data() {
        return {
            griglia: [6, 6, 6, 6],
            id: -1,
            i: 0,
            tabIndex: 0,
            currentPage: 1,
            perPage: 5,
            pathRest: "/pazientiterapia",
            pathResource: "/terapia",
            linkback: "/terapia",
            linkSomministrazione: "/pazienteterapiesomministrazioni",
            linkedit: null,
            jsonData: {
                dataInizio: null,
                dataFine: null,
                farmaco: null,
                quantita: null,
                frequenza: null,
                posologia: null,
                stato: null,
                note: null,
                orarioSomministrazione: [],
            },
            slotSomministrazioniItems: [],
            showModalLoading: false,
            slotSomministrazioniMattinaItems: [],
            slotSomministrazioniPomeriggioItems: [],
            slotSomministrazioniSeraItems: [],
            nonTerminata: true,
            //   items: [
            //     { name: "Stephen Hawking", id: 1, type1: false, type2a: true, type2b: false, type2c: false, type3: false },
            //     { name: "Johnny Appleseed", id: 2, type1: false, type2a: true, type2b: true, type2c: false, type3: false },
            //     { name: "George Washington", id: 3, type1: false, type2a: false, type2b: false, type2c: false, type3: true },
            //     { name: "Albert Einstein", id: 4, type1: true, type2a: false, type2b: false, type2c: true, type3: false },
            //     { name: "Isaac Newton", id: 5, type1: true, type2a: true, type2b: false, type2c: true, type3: false },
            //   ],
            //   fields: ["name", { key: "id", label: "ID" }, { key: "type1", label: "Type 1" }, { key: "type2a", label: "Type 2A" }, { key: "type2b", label: "Type 2B" }, { key: "type2c", label: "Type 2C" }, { key: "type3", label: "Type 3" }],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function () {
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                                return "/paziente/view/" + this.idPaziente;
                            } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "view/" + this.idPaziente;
                            } else {
                                return "homepaziente";
                            }
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            },
        },
        // rows() {
        //     return this.somministrazioneItems.length;
        // },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/terapia/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/terapia?idPaziente=" + me.idPaziente;
        this.loadData();
        me.loadSomministrazione();
    },
    methods: {
        isSelectOrarioSomministrazione(value) {
            let me = this;
            return me.jsonData.orarioSomministrazione.includes(value.toString());
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            me.showModalLoading = true;
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.showModalLoading = false;
                    me.jsonData = response.data.data;
                    me.slotSomministrazioniItems = response.data.data.slotSomministrazione;
                    if (me.jsonData.idStato === "3" || me.jsonData.idStato === "5") {
                        me.nonTerminata = false;
                    }
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadSomministrazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSomministrazione + "?idTerapia=" + me.id;
            me.isBusy = true;
            me.jsonData.idPaziente = me.idPaziente;
            axios
                .get(link, { params: me.jsonData })
                .then((response) => {
                    me.somministrazioneItems = [];
                    me.somministrazioneItems = response.data.data;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        formatTime(value) {
            if (value) {
                return moment(new Date(value)).format("HH:mm");
            } else {
                return "---";
            }
        },
        colorChange(value) {
            console.log("gg" + value);

            return "outline-success";
        },
    },
};
</script>
<style scoped>
.sa-div-cell {
    min-height: 38px;
    margin-left: auto;
    display: block;
    margin-right: auto;
    width: 100%;
    text-align: center;
    font-size: 28px;
    color: darkgreen;
}
</style>
