<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnNewVisible="true" @refresh="onRefresh" :linkedit="linkedit" :btnRefreshVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <div class="sa-tab-scroll">
          <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill>
            <b-tab active>
              <template #title>
                <span>Ultime Misurazioni</span>
              </template>
              <analisi-rischi-th-view-component :id="id"></analisi-rischi-th-view-component>
            </b-tab>
            <b-tab>
              <template #title>
                <span>Storico</span>
              </template>
              <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                  <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                  <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
              </b-row>
              <div>
                <div class="b-table-sticky-header" style="padding-top: 10px">
                  <b-table sticky-header ref="table" :filter="filter" responsive stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(createDate)="{ item }">
                      <router-link class="sa-edit-link" :to="'/paziente/analisirischith/view/' + item.id + '?idPaziente=' + idPaziente">{{ new Date(item.createDate).toLocaleString() }}</router-link>
                    </template>
                  </b-table>
                </div>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                  <b-col lg="3">
                    <p>
                      {{ this.$i18n.t("ambulatori.lblTotalRecords") }}:
                      {{ rows }}
                    </p>
                  </b-col>
                  <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                  </b-col>
                </b-row>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import AnalisiRischiThViewComponent from "../components/AnalisiRischiThViewComponent.vue";

export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { PazienteAnagrafica, SaPageLayout, AnalisiRischiThViewComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    linkPatient: {
      get: function () {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  data() {
    return {
      pathResource: "/pazienteanalisirischith",
      pagelink: "/paziente/analisirischith",
      linkedit: "/paziente/analisirischith",
      perPage: 10,
      currentPage: 1,
      filter: {},
      id: "-1",
      items: [],
      data: {},
      showModalLoading: false,
      fields: [
        {
          label: "Data Evento",
          key: "createDate",
          sortable: true,
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
        },
        {
          label: "SBP",
          key: "sbp",
          thStyle: "width: 3rem",
        },
        {
          label: "HR",
          key: "hr",
          thStyle: "width: 3rem",
        },
        {
          label: "SPO2",
          key: "spo2",
          thStyle: "width: 3rem",
        },
        {
          label: "Temperatura",
          key: "t",
          thStyle: "width: 3rem",
        },
        {
          label: "BS",
          key: "bs",
          thStyle: "width: 3rem",
        },
      ],
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.data.idPaziente = this.$route.query.idPaziente;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idPaziente=" + me.data.idPaziente;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.items = response.data.data;
          me.showModalLoading = false;
          me.id = me.items[0].id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
