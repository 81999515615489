<template>
  <div>
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label
          ><span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblOrmonalExams')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTSH") }}</label
          ><span class="sa-data">{{ jsonData.tsh }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFT3") }}</label
          ><span class="sa-data">{{ jsonData.ft3 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFT4") }}</label
          ><span class="sa-data">{{ jsonData.ft4 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anticorpi Anti-TG</label>
          <span class="sa-data">{{ jsonData.anticorpiAntiTg }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anticorpi Anti-TPO</label>
          <span class="sa-data">{{ jsonData.anticorpiAntiTpo }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMA") }}</label
          ><span class="sa-data">{{ jsonData.vma }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinaryMetanephrines") }}</label
          ><span class="sa-data">{{ jsonData.metanefrineUrinarie }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblACTH") }}</label
          ><span class="sa-data">{{ jsonData.acth }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoNoradrenaline") }}</label
          ><span class="sa-data">{{ jsonData.noradrenalinaorto }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblClinoNoradrenaline") }}</label
          ><span class="sa-data">{{ jsonData.noradrenalinaclino }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoAdrenaline") }}</label
          ><span class="sa-data">{{ jsonData.adrenalinaorto }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblClinoAdrenaline") }}</label
          ><span class="sa-data">{{ jsonData.adrenalinaclino }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinaryCatecolamine") }}</label
          ><span class="sa-data">{{ jsonData.catecolamine }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrynaryNormetanefrine") }}</label
          ><span class="sa-data">{{ jsonData.normetanefrine }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblOralGlucoseLoadCurve')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicGlycemia") }}</label
          ><span class="sa-data">{{ jsonData.glicemiaBase }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterOneHour") }}</label
          ><span class="sa-data">{{ jsonData.glicemia1h }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterTwoHours") }}</label
          ><span class="sa-data">{{ jsonData.glicemia2h }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicInsulinemia") }}</label
          ><span class="sa-data">{{ jsonData.insulinemiaBase }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterOneHour") }}</label
          ><span class="sa-data">{{ jsonData.insulinemia1h }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterTwoHours") }}</label
          ><span class="sa-data">{{ jsonData.insulinemia2h }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCaptorilTest')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicPRA") }}</label
          ><span class="sa-data">{{ jsonData.prabase }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoPRA") }}</label
          ><span class="sa-data">{{ jsonData.praorto }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterOneHour") }}</label
          ><span class="sa-data">{{ jsonData.pra1h }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicAldosterone") }}</label
          ><span class="sa-data">{{ jsonData.aldosteronebase }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoAldosterone") }}</label
          ><span class="sa-data">{{ jsonData.aldosteroneorto }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAfterOneHour") }}</label
          ><span class="sa-data">{{ jsonData.aldosterone1h }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicPlasmaticRenina") }}</label
          ><span class="sa-data">{{ jsonData.reninaPlasmaticaBase }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOrtoPlasmaticRenina") }}</label
          ><span class="sa-data">{{ jsonData.reninaPlasmaticaOrto }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasicBloodPressure") }}</label
          ><span class="sa-data">{{ jsonData.pabasemax }} / {{ jsonData.pabasemin }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBloodPressureOneHour") }}</label
          ><span class="sa-data">{{ jsonData.pa1hmax }} / {{ jsonData.pa1hmin }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl24HoursSodiura") }}</label
          ><span class="sa-data">{{ jsonData.sodiuria24h }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl24HoursPotassiuria") }}</label
          ><span class="sa-data">{{ jsonData.potassiuria24h }}</span>
        </b-col>

        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCaptorilTestResults") }}</label>
          <span class="sa-data">{{ jsonData.test }}</span>
        </b-col>

        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEightHoursCortisolemia") }}</label
          ><span class="sa-data">{{ jsonData.cortisolemiaore8 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTwentyHoursCortisolemia") }}</label
          ><span class="sa-data">{{ jsonData.cortisolemiaore20 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCortisoluria") }}</label
          ><span class="sa-data">{{ jsonData.cortisoluria }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAldosteronuria") }}</label
          ><span class="sa-data">{{ jsonData.aldosteronuria }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixins],
  props: {
    idEvento: { Type: String, require: true },
  },
  components: {},
  data() {
    return {
      pathResource: "/cardioesamispecialistici",
      jsonData: {},
      id: null,
      fontScale: 2,
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  computed: {
    isFirmato() {
      let me = this;
      let isFirmato = !me.jsonData.firma;
      console.log(isFirmato, "FIRMA");
      me.$emit("updateStatoFirma", isFirmato);
      return !me.jsonData.firma;
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
          me.showModalLoading = false;
        });
      }
    },
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
  },
};
</script>

<style></style>
