<template>
    <sa-page-layout :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" :titolo="titolo">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Refertazione Dal</label>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="timestamp"></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Refertazione Al</label>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="timestamp"></date-picker>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 5px">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-body">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-card class="card-dashboard">
                        <b-card-header style="max-height: 75px">
                            <h4 class="card-header-text-color">PRODOTTO NEL RANGE</h4>
                        </b-card-header>
                        <b-card-body class="text-center">
                            <h3 class="card-body-text-color">{{ jsonData.prodotto }}</h3>
                        </b-card-body>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <b-card class="card-dashboard">
                        <b-card-header style="max-height: 75px">
                            <h4 class="card-header-text-color">INVIATO FSE</h4>
                        </b-card-header>
                        <b-card-body class="text-center">
                            <h3 class="card-body-text-color">{{ jsonData.inviatoFse }}</h3>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout, DatePicker },
    mixins: [UtilityMixin],
    data() {
        return {
            titolo: "Riepilogo Documenti",
            pathResource: "/wincareold/contatori",
            showModalLoading: false,
            filtro: { dataDal: null, dataAl: null },
            jsonData: { prodotto: null, inviatoFse: null },
        };
    },
    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        me.filtro.dataDal = new Date().getTime();
        me.filtro.dataAl = new Date().getTime();
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = { dataDal: null, dataAl: null };
            me.filtro.dataDal = new Date().getTime();
            me.filtro.dataAl = new Date().getTime();
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>
<style scoped>
.card-dashboard {
    border: 2px solid !important;
    border-color: red !important;
    border-radius: 1rem;
}
.card-header-text-color {
    font-weight: bold;
    color: black;
}
.card-body-text-color {
    color: red;
}
</style>
