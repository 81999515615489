<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblHandicap") }}</label>
          <b-form-select v-model="jsonData.handicap" :options="handicapOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
          <b-row v-if="jsonData.handicap === 'SI'">
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblHandicapSituationGravity") }}</label>
              <b-form-select v-model="jsonData.situazioneGravita" :options="situazioniGravitaOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblCivilInvalidity") }}</label>
          <b-form-select v-model="jsonData.invaliditaCivile" :options="invaliditaCivileOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
          <b-row v-if="jsonData.invaliditaCivile === 'SI'">
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblCivilInvalidityPercentage") }}</label>
              <b-form-select v-model="jsonData.invaliditaCivilePercentuale" :options="invaliditaCivilePercentuale" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAccompaniment") }}</label>
          <b-form-select v-model="jsonData.accompagnamento" :options="accompagnamentoOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
      </b-row>

      <h4>{{ getLabelTraduora("adi.lblAnthropometricBioData") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDetectionDate") }}</label>
          <date-picker v-model="jsonData.dataRilevazione" format="DD-MM-YYYY" value-type="timestamp" type="date" :disabled="editSvamdiA"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblWeight") }}</label>
          <b-form-input v-model="jsonData.peso" class="text-upper" @input="onCalcoloBmiInput(jsonData.peso, jsonData.altezza)" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblHeight") }}</label>
          <b-form-input v-model="jsonData.altezza" class="text-upper" @input="onCalcoloBmiInput(jsonData.peso, jsonData.altezza)" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("adi.lblImc") }}</label>
          <b-form-input v-model="jsonData.imc" class="text-upper" readonly></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblSystolicBloodPressure") }}</label>
          <b-form-input v-model="jsonData.pressioneArteriosaSistolica" class="text-upper" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDiastolicBloodPressure") }}</label>
          <b-form-input v-model="jsonData.pressioneArteriosaDiastolica" class="text-upper" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblHeartRate") }}</label>
          <b-form-input v-model="jsonData.frequenzaCardiaca" class="text-upper" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblBreathFrequency") }}</label>
          <b-form-input v-model="jsonData.frequenzaRespiratoria" class="text-upper" :disabled="editSvamdiA"></b-form-input>
        </b-col>
      </b-row>

      <h4>{{ getLabelTraduora("adi.lblPathologicalConditions") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblMentalRetardation") }}</label>
          <b-form-select v-model="jsonData.ritardoMentale" :options="ritardoMentaleOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDementia") }}</label>
          <b-form-select v-model="jsonData.demenza" :options="demenzaOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblPsychiatricDisorder") }}</label>
          <b-form-select v-model="jsonData.disturboPsichiatrico" :options="disturbiPsichiatriciOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
      </b-row>

      <h4>{{ getLabelTraduora("adi.lblBodyFunctions") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblIntellectualFunctions") }}</label>
          <b-form-input v-model="jsonData.funzioniIntellettive" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblGlobalPsychosocialFunctions") }}</label>
          <b-form-input v-model="jsonData.funzioniPsicosociali" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblTransposeLanguage") }}</label>
          <b-form-input v-model="jsonData.recepireLinguaggio" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblExpressionLanguage") }}</label>
          <b-form-input v-model="jsonData.espressioneLinguaggio" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblViewFunctions") }}</label>
          <b-form-input v-model="jsonData.funzioniVista" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAuditoryFunctions") }}</label>
          <b-form-input v-model="jsonData.funzioniUditive" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblPainSensations") }}</label>
          <b-form-input v-model="jsonData.sensazioniDolore" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblExerciseTolerance") }}</label>
          <b-form-input v-model="jsonData.tolleranzaEsercizioFisico" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblChewing") }}</label>
          <b-form-input v-model="jsonData.masticazione" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDeglutition") }}</label>
          <b-form-input v-model="jsonData.deglutizione" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFecalContinence") }}</label>
          <b-form-input v-model="jsonData.continenzaFecale" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblUrinaryContinence") }}</label>
          <b-form-input v-model="jsonData.continenzaUrinaria" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFunctionsMobilizationArticular") }}</label>
          <b-form-input v-model="jsonData.funzioniMobilitaArticolare" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFunctionsMuscularStrength") }}</label>
          <b-form-input v-model="jsonData.funzioniForzaMuscolare" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFunctionsMuscularTone") }}</label>
          <b-form-input v-model="jsonData.funzioniTonoMuscolare" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFunctionsInvoluntaryMovement") }}</label>
          <b-form-input v-model="jsonData.funzioniMovimentoInvolontario" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFunctionsPacePattern") }}</label>
          <b-form-input v-model="jsonData.funzioniPatternAndatura" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFunctionsSkinProtective") }}</label>
          <b-form-input v-model="jsonData.funzioniProtettiveCute" class="text-upper" @input="onInputFunzioniCorporee" :disabled="editSvamdiA"></b-form-input>
        </b-col>
      </b-row>

      <h5>{{ getLabelTraduora("adi.lblEnvironmentalFactors") }}</h5>
      <h4>{{ getLabelTraduora("adi.lblTecnicalAids") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblCardiacAid") }}</label>
          <b-form-select v-model="jsonData.ausilioCardiaco" :options="ausiliTecniciOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDigestiveAid") }}</label>
          <b-form-select v-model="jsonData.ausilioDigerente" :options="ausiliTecniciOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblMotorAid") }}</label>
          <b-form-select v-model="jsonData.ausilioMotorio" :options="ausiliTecniciOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblRespiratoryAid") }}</label>
          <b-form-select v-model="jsonData.ausilioRespiratorio" :options="ausiliTecniciOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblRenalAid") }}</label>
          <b-form-select v-model="jsonData.ausilioRenale" :options="ausiliTecniciOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblCommunicationAid") }}</label>
          <b-form-select v-model="jsonData.ausilioComunicativo" :options="ausiliTecniciOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
      </b-row>
      <h4>{{ getLabelTraduora("adi.lblContinuousAssistance") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblFamiliar") }}</label>
          <b-form-select v-model="jsonData.familiare" :options="familiareOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblCaregiver") }}</label>
          <b-form-select v-model="jsonData.cargiver" :options="caregiverOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
      </b-row>
      <h4>{{ getLabelTraduora("adi.lblPharmacologicalTherapy") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAntiepileptics") }}</label>
          <b-form-select v-model="jsonData.antiepilettici" :options="terapieFarmacologicheOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAntipsychotics") }}</label>
          <b-form-select v-model="jsonData.antipsicotici" :options="terapieFarmacologicheOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAntidiabetics") }}</label>
          <b-form-select v-model="jsonData.antidiabetici" :options="terapieFarmacologicheOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblPainRelievers") }}</label>
          <b-form-select v-model="jsonData.antidolorifici" :options="terapieFarmacologicheOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAntineoplastics") }}</label>
          <b-form-select v-model="jsonData.antineoplastici" :options="terapieFarmacologicheOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblOther") }}</label>
          <b-form-select v-model="jsonData.altroTerapiaFarmacologica" :options="terapieFarmacologicheOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
      </b-row>
      <h4>{{ getLabelTraduora("adi.lblDependence") }}</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAlcool") }}</label>
          <b-form-select v-model="jsonData.alcool" :options="dipendenzeOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblOtherSubstances") }}</label>
          <b-form-select v-model="jsonData.altreSostanze" :options="dipendenzeOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamdiA"></b-form-select>
        </b-col>
      </b-row>
      <div v-if="!tblSchedaIsmDisabled">
        <h4>{{ getLabelTraduora("adi.lblDiseaseSeverityIndexCard") }}</h4>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblOrganicHeartDisease") }}</label>
            <b-form-input v-model="jsonData.cardiopatiaOrganica" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblIschemicHeartDisease") }}</label>
            <b-form-input v-model="jsonData.cardiopatiaIschemica" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblPrimitiveRhythmConductionDisturbances") }}</label>
            <b-form-input v-model="jsonData.disturbiPrimitiviRitmoConduzione" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblHeartFailure") }}</label>
            <b-form-input v-model="jsonData.insufficienzaCardiaca" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblHypertension") }}</label>
            <b-form-input v-model="jsonData.ipertensioneArteriosa" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblCerebralVascularAccidents") }}</label>
            <b-form-input v-model="jsonData.accidentiVascolariCerebrali" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblPeripheralVasculopathies") }}</label>
            <b-form-input v-model="jsonData.vasculopatiePeriferiche" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblDiabetesMellitus") }}</label>
            <b-form-input v-model="jsonData.diabeteMelito" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblEndocrineDiseases") }}</label>
            <b-form-input v-model="jsonData.malattieEndocrine" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblRespiratoryDiseases") }}</label>
            <b-form-input v-model="jsonData.malattieRespiratorie" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblMalignantNeoplasms") }}</label>
            <b-form-input v-model="jsonData.neoplasieMaligne" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblHepatobiliaryDiseases") }}</label>
            <b-form-input v-model="jsonData.malattieEpatobiliari" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblKidneyDisease") }}</label>
            <b-form-input v-model="jsonData.malattieRenali" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblOsteoarticularDiseases") }}</label>
            <b-form-input v-model="jsonData.malattieOsteoarticolari" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblGastrointestinalDiseases") }}</label>
            <b-form-input v-model="jsonData.malattieGastrointestinali" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblMalnutrition") }}</label>
            <b-form-input v-model="jsonData.malnutrizione" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblParkinsonisms") }}</label>
            <b-form-input v-model="jsonData.parkinsonismi" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblDepression") }}</label>
            <b-form-input v-model="jsonData.depressione" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblMentalIllness") }}</label>
            <b-form-input v-model="jsonData.malattieMentali" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblMentalDeterioration") }}</label>
            <b-form-input v-model="jsonData.deterioramentoMentale" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblAnemia") }}</label>
            <b-form-input v-model="jsonData.anemia" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblSensoryDeficits") }}</label>
            <b-form-input v-model="jsonData.deficitSensoriale" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblBedsores") }}</label>
            <b-form-input v-model="jsonData.piagheDecubito" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("adi.lblOther") }}</label>
            <b-form-input v-model="jsonData.altroIsm" class="text-upper" :disabled="editSvamdiA"></b-form-input>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    id: String,
    linkPrintData: String,
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      tblSchedaIsmDisabled: true,
      pathResource: "/adisvamadischedea",
      jsonData: {
        handicap: null,
        situazioneGravita: null,
        invaliditaCivile: null,
        invaliditaCivilePercentuale: null,
        accompagnamento: null,
        dataRilevazione: null,
        peso: 0,
        altezza: 0,
        imc: 0,
        pressioneArteriosaSistolica: 0,
        pressioneArteriosaDiastolica: 0,
        frequenzaCardiaca: 0,
        frequenzaRespiratoria: 0,
        ritardoMentale: null,
        demenza: null,
        disturboPsichiatrico: null,
        funzioniIntellettive: 0,
        funzioniPsicosociali: 0,
        recepireLinguaggio: 0,
        espressioneLinguaggio: 0,
        sensazioniDolore: 0,
        tolleranzaEsercizioFisico: 0,
        masticazione: 0,
        deglutizione: 0,
        continenzaFecale: 0,
        continenzaUrinaria: 0,
        funzioniMobilitaArticolare: 0,
        funzioniForzaMuscolare: 0,
        funzioniTonoMuscolare: 0,
        funzioniMovimentoInvolontario: 0,
        funzioniPatternAndatura: 0,
        funzioniProtettiveCute: 0,
        funzioniVista: 0,
        funzioniUditive: 0,
        ausilioCardiaco: null,
        ausilioDigerente: null,
        ausilioMotorio: null,
        ausilioRespiratorio: null,
        ausilioRenale: null,
        ausilioComunicativo: null,
        familiare: null,
        cargiver: null,
        antiepilettici: null,
        antipsicotici: null,
        antidiabetici: null,
        antidolorifici: null,
        antineoplastici: null,
        altroTerapiaFarmacologica: null,
        alcool: null,
        altreSostanze: null,
        cardiopatiaOrganica: 0,
        cardiopatiaIschemica: 0,
        disturbiPrimitiviRitmoConduzione: 0,
        insufficienzaCardiaca: 0,
        ipertensioneArteriosa: 0,
        accidentiVascolariCerebrali: 0,
        vasculopatiePeriferiche: 0,
        diabeteMelito: 0,
        malattieEndocrine: 0,
        malattieRespiratorie: 0,
        neoplasieMaligne: 0,
        malattieEpatobiliari: 0,
        malattieRenali: 0,
        malattieOsteoarticolari: 0,
        malattieGastrointestinali: 0,
        malnutrizione: 0,
        parkinsonismi: 0,
        depressione: 0,
        malattieMentali: 0,
        deterioramentoMentale: 0,
        anemia: 0,
        deficitSensoriale: 0,
        piagheDecubito: 0,
        altroIsm: 0,
      },
      handicapOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "IN CORSO", text: "IN CORSO" },
        { value: "NO", text: "NO" },
      ],
      situazioniGravitaOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      invaliditaCivileOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      invaliditaCivilePercentuale: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "≥33%", text: "≥33%" },
        { value: "≥46%", text: "≥46%" },
        { value: "≥66%", text: "≥66%" },
        { value: "100%", text: "100%" },
      ],
      accompagnamentoOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      ritardoMentaleOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      demenzaOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      disturbiPsichiatriciOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      ausiliTecniciOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON PERVENUTO", text: "NON PERVENUTO" },
      ],
      familiareOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON PERVENUTO", text: "NON PERVENUTO" },
      ],
      caregiverOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON PERVENUTO", text: "NON PERVENUTO" },
      ],
      terapieFarmacologicheOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON PERVENUTO", text: "NON PERVENUTO" },
      ],
      dipendenzeOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON PERVENUTO", text: "NON PERVENUTO" },
      ],
      editSvamdiA: false,
    };
  },
  mounted() {
    let me = this;
    me.jsonData.id = this.$route.params.id;
    if (me.id !== "-1") {
      me.loadData();
    }
    me.impostaPermessi();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      let fonte = this.$route.query.fonte;
      me.editSvamdiA = true;
      if (fonte === null || fonte === undefined || fonte === "undefined" || fonte === "") {
        fonte = "/richieste";
      }
      if (fonte === "/richieste") {
        me.editSvamdiA = !UtilityMixin.methods.verificaPermessi(me.utente, "adisvamadischedea", 2);
      }
    },
    loadData() {
      let me = this;
      if (me.id !== -1) {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        me.showmolalloading = true;
        axios
          .get(link)
          .then((response) => {
            // console.log("OK");
            me.jsonData = response.data.data;
            me.showmolalloading = false;
          })
          .catch(() => {
            me.showmolalloading = false;
          });
      }
      me.$emit("update", me.jsonData);
    },
    onCalcoloBmiInput(peso, altezza) {
      let me = this;
      me.jsonData.imc = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
    },
    onInputFunzioniCorporee() {
      let me = this;
      if (me.jsonData.funzioniIntellettive >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.funzioniPsicosociali >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.recepireLinguaggio >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.espressioneLinguaggio >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.sensazioniDolore >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.tolleranzaEsercizioFisico >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.masticazione >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.deglutizione >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.continenzaFecale >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.continenzaUrinaria >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.funzioniMobilitaArticolare >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.funzioniForzaMuscolare >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.funzioniTonoMuscolare >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.funzioniMovimentoInvolontario >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.funzioniPatternAndatura >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else if (me.jsonData.funzioniProtettiveCute >= 3) {
        me.tblSchedaIsmDisabled = false;
      } else {
        me.tblSchedaIsmDisabled = true;
      }
    },
  },
};
</script>
