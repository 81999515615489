<template>
  <div class="sa-tab-scroll">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Data Evento</label>
              <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="BASALE" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblBasal") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMedium") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblApex") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoanterioreBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoanterioreMedioBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceSettoBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorApex") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteanterioreBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteanterioreMediaBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceAntBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatAnterioreBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatAnterioreMediaBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"></b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioFrontSideWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatPosterioreBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatPosterioreMediaBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apicePostBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioRearWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteInferioreBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteInferioreMediaBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceInfBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoposterioreBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoposterioreMedioBas" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioBasale()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"></b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-alert :show="punteggioBasaleNotValid" variant="danger" style="margin-top: 10px">{{ messaggio }}</b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Punteggio Totale</label>
              <b-form-input v-model="jsonData.totaleBas" type="number" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Estensione Danno IMA</label>
              <b-form-input v-model="jsonData.estensionedannoBas" type="number" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAS (mmHg)</label>
              <b-form-input v-model="jsonData.pasBasale" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAD (mmHg)</label>
              <b-form-input v-model="jsonData.padBasale" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">FC (bpm)</label>
              <b-form-input v-model="jsonData.fcBasale" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Sintomi</label>
              <b-form-input v-model="jsonData.sintomiBasale" type="text"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Conclusioni</label>
              <b-form-textarea v-model="jsonData.conclusioniBasale" no-resize rows="6"></b-form-textarea>
              <b-input-group style="margin-top: 5px">
                <b-form-select v-model="jsonData.conclusioniBasale" :options="conclusioniOptions" value-field="conclusione" text-field="conclusione">
                  <template #first>
                    <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                  </template>
                </b-form-select>
                <b-button variant="outline-secondary" style="margin-right: 5px" size="sm" @click="onClickAggiungiConclusione(jsonData.conclusioniBasale)"><b-icon icon="plus"></b-icon></b-button>
                <b-button variant="outline-secondary" size="sm" @click="onDeleteConclusione(jsonData.conclusioniBasale, conclusioniOptions)"><b-icon icon="trash"></b-icon></b-button>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="VITALITA'" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblBasal") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMedium") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblApex") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoanterioreVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoanterioreMedioVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceSettoVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorApex") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteanterioreVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteanterioreMediaVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceAntVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatAnterioreVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatAnterioreMediaVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"></b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioFrontSideWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatPosterioreVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatPosterioreMediaVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apicePostVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioRearWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteInferioreVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteInferioreMediaVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceInfVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoposterioreVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoposterioreMedioVit" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioVitalita()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"></b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-alert :show="punteggioVitalitaNotValid" variant="danger" style="margin-top: 10px">{{ messaggio }}</b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Punteggio Totale</label>
              <b-form-input v-model="jsonData.totaleVit" type="number" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Estensione Danno IMA</label>
              <b-form-input v-model="jsonData.estensionedannoVit" type="number" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAS (mmHg)</label>
              <b-form-input v-model="jsonData.pasVit" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAD (mmHg)</label>
              <b-form-input v-model="jsonData.padVit" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">FC (bpm)</label>
              <b-form-input v-model="jsonData.fcVit" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Sintomi</label>
              <b-form-input v-model="jsonData.sintomiVit" type="text"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Conclusioni</label>
              <b-form-textarea v-model="jsonData.conclusioni" no-resize rows="6"></b-form-textarea>
              <b-input-group style="margin-top: 5px">
                <b-form-select v-model="jsonData.conclusioni" :options="conclusioniOptions" value-field="conclusione" text-field="conclusione">
                  <template #first>
                    <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                  </template>
                </b-form-select>
                <b-button variant="outline-secondary" style="margin-right: 5px" size="sm" @click="onClickAggiungiConclusione(jsonData.conclusioni)"><b-icon icon="plus"></b-icon></b-button>
                <b-button variant="outline-secondary" size="sm" @click="onDeleteConclusione(jsonData.conclusioni, conclusioniOptions)"><b-icon icon="trash"></b-icon></b-button>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="ISCHEMIA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblBasal") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMedium") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblApex") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoanterioreIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoanterioreMedioIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceSettoIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorApex") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteanterioreIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteanterioreMediaIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceAntIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatAnterioreIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatAnterioreMediaIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"></b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioFrontSideWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatPosterioreIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatPosterioreMediaIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apicePostIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioRearWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteInferioreIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteInferioreMediaIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceInfIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoposterioreIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoposterioreMedioIsc" :options="opzioniOptions" value-field="value" text-field="text" @change="onCalcoloPunteggioIschemia()">
                <template #first>
                  <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"></b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-alert :show="punteggioIschemiaNotValid" variant="danger" style="margin-top: 10px">{{ messaggio }}</b-alert>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Punteggio Totale</label>
              <b-form-input v-model="jsonData.totaleIsc" type="number" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Estensione Danno IMA</label>
              <b-form-input v-model="jsonData.estensionedannoIsc" type="number" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAS (mmHg)</label>
              <b-form-input v-model="jsonData.pasIsc" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAD (mmHg)</label>
              <b-form-input v-model="jsonData.padIsc" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">FC (bpm)</label>
              <b-form-input v-model="jsonData.fcIsc" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Sintomi</label>
              <b-form-input v-model="jsonData.sintomiIsc" type="text"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Conclusioni</label>
              <b-form-textarea v-model="jsonData.conclusioni2" no-resize rows="6"></b-form-textarea>
              <b-input-group style="margin-top: 5px">
                <b-form-select v-model="jsonData.conclusioni2" :options="conclusioniOptions" value-field="conclusione" text-field="conclusione">
                  <template #first>
                    <b-form-select-option disabled :value="''">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                  </template>
                </b-form-select>
                <b-button variant="outline-secondary" style="margin-right: 5px" size="sm" @click="onClickAggiungiConclusione(jsonData.conclusioni2)"><b-icon icon="plus"></b-icon></b-button>
                <b-button variant="outline-secondary" size="sm" @click="onDeleteConclusione(jsonData.conclusioni2, conclusioniOptions)"><b-icon icon="trash"></b-icon></b-button>
              </b-input-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="NOTE FINALI" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Note</label>
              <b-form-textarea v-model="jsonData.noteFinali" rows="8" no-resize></b-form-textarea>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardioecostress",
      pathResourceOpzioni: "/cardioecostressopzioni",
      pathResourceConclusioni: "/cardioecostressconclusioni",
      opzioniOptions: [],
      conclusioniOptions: [],
      opzioni: [],
      messaggio: "Uno dei valori inseriti e' INDETERMINABILE. Impossibile procedere al calcolo",
      punteggioBasaleNotValid: false,
      punteggioVitalitaNotValid: false,
      punteggioIschemiaNotValid: false,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadDefaultData();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.showModalLoading = true;
      me.loadOpzioni();
      me.loadConclusioni();
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
    loadOpzioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceOpzioni;
      me.opzioniOptions = [];
      axios.get(link).then((response) => {
        me.opzioni = response.data.data.list;
        response.data.data.list.forEach((element) => {
          me.opzioniOptions.push({ text: element.descrizione, value: element.descrizione });
        });
        me.showModalLoading = false;
      });
    },
    loadConclusioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceConclusioni;
      me.conclusioniOptions = [];
      axios.get(link).then((response) => {
        me.conclusioniOptions = response.data.data.list;
        me.showModalLoading = false;
      });
    },
    onKeyPressEnterCalcoli() {
      console.log("OK CALCOLI");
    },

    onClickAggiungiConclusione(conclusione) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + this.pathResourceConclusioni;
      let jsonData = { conclusione: conclusione };
      axios
        .post(link, JSON.stringify(jsonData))
        .then((response) => {
          this.$bvModal.msgBoxOk(response.data.messaggio, {
            title: "",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
          me.loadDefaultData();
        })
        .catch((e) => {
          console.log(e);
          this.$bvModal
            .msgBoxOk(e.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            });
        });
    },
    onCalcoloPunteggioBasale() {
      let me = this;
      let punteggioTotale = 0;
      let estensioneDannoIma = 0;
      let verificaValiditaPunteggio = true;
      me.opzioni.forEach((element) => {
        if (me.jsonData.settoanterioreBas === element.descrizione) {
          punteggioTotale = element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoanterioreMedioBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceSettoBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteanterioreBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteanterioreMediaBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceAntBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatAnterioreBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatAnterioreMediaBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatPosterioreBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatPosterioreMediaBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apicePostBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteInferioreBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteInferioreMediaBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceInfBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoposterioreBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoposterioreMedioBas === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
      });
      me.punteggioBasaleNotValid = !verificaValiditaPunteggio;
      if (verificaValiditaPunteggio) {
        me.jsonData.totaleBas = punteggioTotale;
        me.jsonData.estensionedannoBas = estensioneDannoIma;
      } else {
        me.jsonData.totaleBas = null;
        me.jsonData.estensionedannoBas = null;
      }
    },
    onCalcoloPunteggioVitalita() {
      let me = this;
      let punteggioTotale = 0;
      let estensioneDannoIma = 0;
      let verificaValiditaPunteggio = true;
      me.opzioni.forEach((element) => {
        if (me.jsonData.settoanterioreVit === element.descrizione) {
          punteggioTotale = element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoanterioreMedioVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceSettoVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteanterioreVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteanterioreMediaVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceAntVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatAnterioreVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatAnterioreMediaVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatPosterioreVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatPosterioreMediaVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apicePostVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteInferioreVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteInferioreMediaVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceInfVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoposterioreVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoposterioreMedioVit === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
      });
      me.punteggioVitalitaNotValid = !verificaValiditaPunteggio;
      if (verificaValiditaPunteggio) {
        me.jsonData.totaleVit = punteggioTotale;
        me.jsonData.estensionedannoVit = estensioneDannoIma;
      } else {
        me.jsonData.totaleVit = null;
        me.jsonData.estensionedannoVit = null;
      }
    },
    onCalcoloPunteggioIschemia() {
      let me = this;
      let punteggioTotale = 0;
      let estensioneDannoIma = 0;
      let verificaValiditaPunteggio = true;
      me.opzioni.forEach((element) => {
        if (me.jsonData.settoanterioreIsc === element.descrizione) {
          punteggioTotale = element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoanterioreMedioIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceSettoIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteanterioreIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteanterioreMediaIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceAntIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatAnterioreIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatAnterioreMediaIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatPosterioreIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatPosterioreMediaIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apicePostIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteInferioreIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteInferioreMediaIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceInfIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoposterioreIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoposterioreMedioIsc === element.descrizione) {
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
      });
      me.punteggioIschemiaNotValid = !verificaValiditaPunteggio;
      if (verificaValiditaPunteggio) {
        me.jsonData.totaleIsc = punteggioTotale;
        me.jsonData.estensionedannoIsc = estensioneDannoIma;
      } else {
        me.jsonData.totaleIsc = null;
        me.jsonData.estensionedannoIsc = null;
      }
    },
    onDeleteConclusione(conclusione, array) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
          title: "Conferma Cancellazione",
          size: "sm",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            console.log(value);
            let link = process.env.VUE_APP_PATH_API + this.pathResourceConclusioni;
            array.forEach((element) => {
              if (element.conclusione === conclusione) {
                axios.delete(link + "/" + element.id).then((response) => {
                  this.$bvModal.msgBoxOk(response.data.messaggio, {
                    title: "",
                    size: "sm",
                    okVariant: "outline-danger",
                    headerClass: "sa-msg-header-danger",
                    footerClass: "p-2 border-top-0",
                    centered: true,
                  });
                  me.loadDefaultData();
                });
              } else if (conclusione === null || conclusione === "") {
                this.$bvModal.msgBoxOk("Nessun elemento da cancellare.", {
                  title: "Attenzione!",
                  Text: "Nessun valore da cancellare!",
                  size: "sm",
                  okVariant: "outline-danger",
                  headerClass: "sa-msg-header-danger",
                  footerClass: "p-2 border-top-0",
                  centered: true,
                });
              }
            });
          }
        })
        .catch((e) => {
          this.$bvModal
            .msgBoxOk(e.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            });
        });
    },
  },
};
</script>
