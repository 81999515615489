<template>
    <sa-page-layout>
        <template slot="table-header">
            <h3>Codici IcdIX</h3>
        </template>

        <template slot="table-body">
            <div class="sa-tab-scroll">
                <codici-icd-ix-list-component></codici-icd-ix-list-component>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import CodiciIcdIxListComponent from "./CodiciIcdIxListComponent";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";

export default {
    components: { CodiciIcdIxListComponent, SaPageLayout },
};
</script>

<style></style>
