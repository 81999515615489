<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Caso</label>
                    <span class="sa-data">{{ jsonData.tipoCaso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Inizio Sintomi</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ricovero</label>
                    <span class="sa-data">{{ jsonData.ricovero }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Ricovero</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Diagnosi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dati Clinici</label>
                    <span class="sa-data">{{ jsonData.datiClinici }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Colturale</label>
                    <span class="sa-data">{{ jsonData.esameColturale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Diretto</label>
                    <span class="sa-data">{{ jsonData.esameDiretto }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Esame Sierologico</label>
                    <span class="sa-data">{{ jsonData.esameSierologico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Dettagli</label>
                    <span class="sa-data">{{ jsonData.dettagli }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Esantema" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo Esantema</label>
                    <span class="sa-data">{{ jsonData.tipoEsantema }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Localizzato a</label>
                    <span class="sa-data">{{ jsonData.localizzazioneEsantema }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Eventuale Forma Di Contagio</label>
                    <span class="sa-data">{{ jsonData.eventualeFormaContagio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <span class="sa-data-justify">{{ jsonData.note }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Vaccino" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Vaccino</label>
                    <span class="sa-data">{{ jsonData.vaccino }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Data Vaccino</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataVaccino) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Contatti" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Contatti</label>
                    <span class="sa-data">{{ jsonData.contatti }}</span>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Telefono Sanitario Notificante</label>
                    <span class="sa-data">{{ jsonData.telefonoSanitarioNotificante }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Data Notifica</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveierosoliagravidanza",
            currentPage: 1,
            perPage: 100,
            jsonData: {
                tipoCaso: "",
                dataInizioSintomi: null,
                ricovero: "",
                dataRicovero: null,
                datiClinici: "",
                esameColturale: "",
                esameDiretto: "",
                esameSierologico: "",
                dettagli: "",
                tipoEsantema: "",
                localizzazioneEsantema: "",
                eventualeFormaContagio: "",
                note: "",
                vaccino: "",
                dataVaccino: "",
                contatti: "",
                listaContatti: [],
                sanitarioNotificante: "",
                telefonoSanitarioNotificante: "",
                dataNotifica: null,
            },
            fieldsContatti: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Parentela",
                    key: "parentela",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: false,
                },
                {
                    label: "cognome",
                    key: "cognome",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Nome",
                    key: "nome",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Professione",
                    key: "professione",
                    thStyle: "width: 10rem",
                    sortable: false,
                },
                {
                    label: "Eta'",
                    key: "eta",
                    thStyle: "width: 5rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 4rem",
                },
            ],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.$emit("showmodalloading", true);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.$emit("showmodalloading", false);
                })
                .catch(() => {
                    me.$emit("showmodalloading", false);
                });
        },
    },
};
</script>
