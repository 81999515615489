<template>
    <b-card class="sa-card" header="Immagini" header-tag="header" footer-tag="footer" title="">
        <b-row>
            <b-col v-for="immagine in immagini" :key="immagine.id" xs="12" sm="12" md="6" lg="4" xl="3">
                <b-row style="padding-bottom: 0px">
                    <b-col>
                        <div style="padding-left: 0px; padding-right: 0px; padding-bottom: 0px; display: flex">
                            <input class="sa-pei-input" type="text" v-model="immagine.nome" @blur="onInputBlur(immagine)" />
                            <b-form-checkbox class="sa-pei-chk" :checked="immagine.stampaNote === 'SI'" @change="onChkStampaNoteChange(immagine, $event)">
                                <i class="bi bi-printer"></i>
                                <span class="sa-pei-chk-label">Note</span>
                            </b-form-checkbox>
                            <b-form-checkbox class="sa-pei-chk" :checked="immagine.stampaImmagine === 'SI'" @change="onChkStampaImmagineChange(immagine, $event)">
                                <i class="bi bi-printer"></i>
                                <span class="sa-pei-chk-label">Img.</span>
                            </b-form-checkbox>
                            <i :class="getColorByNote(immagine.note)" @click="onClickBtnNote(immagine)"></i>
                            <i class="sa-tab-title-danger text-danger bi bi-trash sa-pei-btn-icon" @click="onClickDeleteImmagine(immagine)"></i>
                            <!-- <b-form-input class="sa-pei-input" v-model="immagine.nome" style="padding-top: 0.5rem"></b-form-input> -->
                            <!-- <i class="bi bi-chat-right-text sa-pei-btn-icon" @click="onClickBtnNote(immagine)"></i> -->
                            <!-- <i class="bi bi-zoom-in sa-pei-btn-icon"></i> -->
                        </div>
                    </b-col>
                </b-row>
                <div class="sa-drop-image" v-if="!viewNote[immagine.id]">
                    <b-img thumbnail fluid :src="immagine.immagine" :alt="immagine.nome" @click="onClickImmagine(immagine)"></b-img>
                </div>
                <div v-else>
                    <b-form-textarea v-model="immagine.note" rows="15" no-resize @blur="onNoteBlur(immagine)"></b-form-textarea>
                </div>
                <!-- <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p> -->
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="4" xl="3">
                <div class="sa-drop-image" @dragover.prevent="onDragOver" @drop.prevent="onDrop($event)" @dragleave.prevent="onDragleave">
                    <i class="bi bi-cloud-plus sa-image-upload-icon"></i>
                    <span>Drag & Drop to Upload File</span>
                    <span>OR</span>
                    <!-- <b-button variant="out-line">Browse File</b-button> -->
                    <!-- <b-form-file v-model="file2" class="mt-3" placeholder=""></b-form-file> -->
                    <input id="file-input" class="hide-file-input" type="file" accept="image/*" @change="onFileChange($event)" />
                    <label class="file-label" for="file-input">Upload file</label>
                </div>
            </b-col>
            <b-col>
                <b-modal ref="mdlImmagineSelezionata" id="mdlImmagineSelezionata" :title="titoloMdlImmagineSelezionata" size="xl" hide-footer>
                    <b-img :src="immagineSelezionata" fluid alt="Responsive image"></b-img>
                </b-modal>
            </b-col>
        </b-row>
    </b-card>
    <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col> -->
    <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image">
                <div class="drag-area">
                    <div class="icon"><i class="bi bi-cloud-plus"></i></div>
                    <header>Drag & Drop to Upload File</header>
                    <span>OR</span>
                    <button>Browse File</button>
                    <input type="file" hidden />
                </div>
            </div>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image">
                <div>
                    <b-form-file accept="image/*"></b-form-file>
                </div>
            </div>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col> -->
    <!-- <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="2">
            <div class="sa-drop-image"></div>
            <p>Edit Immagini {{ idRisorsa }} {{ contesto }}</p>
        </b-col>
    </b-row>     -->
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default {
    props: {
        idRisorsa: {
            type: String,
            default: function () {
                return null;
            },
        },
        contesto: {
            type: String,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            file2: null,
            pathResource: "/pazienteeventiimmagini",
            abilitaNote: false,
            titoloMdlImmagineSelezionata: null,
            immagineSelezionata: null,
            immagini: [],
            viewNote: [],
        };
    },
    computed: {},
    watch: {
        idRisorsa() {
            let me = this;
            if (me.idRisorsa) {
                me.loadData();
            }
        },
    },
    methods: {
        // isViewNote(id) {
        //     let me = this;
        //     me.viewNote[id] = true;
        //     return me.viewNote[id];
        // },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource;
                axios.get(link, { params: { idRisorsa: me.idRisorsa } }).then((response) => {
                    me.immagini = response.data.data.list;
                    me.showModalLoading = false;
                });
            }
        },
        getColorByNote(note) {
            let colore = note ? "sa-tab-title-success text-success bi bi-chat-right-text sa-pei-btn-icon" : "sa-tab-title-danger text-danger bi bi-chat-right-text sa-pei-btn-icon";
            return colore;
        },
        onDragOver() {},
        onDrop(e) {
            let me = this;
            let files = e.dataTransfer.files;
            let file = files[0];
            let reader = new FileReader();
            reader.onload = (f) => {
                // f.target.result contains the base64 encoding of the image
                let src = f.target.result;
                let nome = "Immagine " + (me.immagini.length + 1);
                let link = process.env.VUE_APP_PATH_API + me.pathResource;
                let jsonData = { idRisorsa: me.idRisorsa, contesto: me.contesto, dimensioni: file.size, tipo: file.type, immagine: src, stampaImmagine: "SI", stampaNote: "NO", nome: nome, note: "" };
                axios
                    .post(link, JSON.stringify(jsonData))
                    .then(() => {
                        me.loadData();
                    })
                    .catch((e) => {
                        console.log(e.messaggio);
                        // this.$bvModal
                        //     .msgBoxOk(e, {
                        //         title: "ATTENZIONE",
                        //         size: "sm",
                        //         okVariant: "outline-danger",
                        //         headerClass: "sa-msg-header-danger",
                        //         footerClass: "p-2 border-top-0",
                        //         centered: true,
                        //     })
                        //     .then(() => {})
                        //     .catch((err) => {
                        //         console.log(err);
                        //     });
                    });
            };
            reader.readAsDataURL(file);
        },
        onFileChange($event) {
            let me = this;
            const file = $event.target.files[0];
            if (file) {
                me.onInputSelectedImg(file);
            }
        },
        onInputSelectedImg(img) {
            let me = this;
            let file = img;
            let reader = new FileReader();
            reader.onload = (f) => {
                // f.target.result contains the base64 encoding of the image
                let src = f.target.result;
                let nome = "Immagine " + (me.immagini.length + 1);
                let link = process.env.VUE_APP_PATH_API + me.pathResource;
                let jsonData = { idRisorsa: me.idRisorsa, contesto: me.contesto, dimensioni: file.size, tipo: file.type, immagine: src, stampaImmagine: "NO", stampaNote: "NO", nome: nome, note: "" };
                axios
                    .post(link, JSON.stringify(jsonData))
                    .then(() => {
                        me.loadData();
                    })
                    .catch((e) => {
                        console.log(e.messaggio);
                        // this.$bvModal
                        //     .msgBoxOk(e, {
                        //         title: "ATTENZIONE",
                        //         size: "sm",
                        //         okVariant: "outline-danger",
                        //         headerClass: "sa-msg-header-danger",
                        //         footerClass: "p-2 border-top-0",
                        //         centered: true,
                        //     })
                        //     .then(() => {})
                        //     .catch((err) => {
                        //         console.log(err);
                        //     });
                    });
            };
            reader.readAsDataURL(file);
        },
        onDragleave() {},
        onChkStampaNoteChange(jsonData, value) {
            let me = this;
            if (value) {
                jsonData.stampaNote = "SI";
            } else {
                jsonData.stampaNote = "NO";
            }
            me.aggiornaImmagine(jsonData);
        },
        onChkStampaImmagineChange(jsonData, value) {
            let me = this;
            if (value) {
                jsonData.stampaImmagine = "SI";
            } else {
                jsonData.stampaImmagine = "NO";
            }
            me.aggiornaImmagine(jsonData);
        },
        onClickBtnNote(immagine) {
            let me = this;
            Vue.set(me.viewNote, immagine.id, !me.viewNote[immagine.id]);
        },
        onInputBlur(immagine) {
            let me = this;
            me.aggiornaImmagine(immagine);
        },
        onNoteBlur(immagine) {
            let me = this;
            me.aggiornaImmagine(immagine);
        },
        onClickDeleteImmagine(jsonData) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteImmagine(jsonData);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteImmagine(jsonData) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + jsonData.id;
            axios
                .delete(link)
                .then(() => {
                    me.loadData();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        onClickImmagine(jsonData) {
            let me = this;
            me.titoloMdlImmagineSelezionata = jsonData.nome;
            me.immagineSelezionata = jsonData.immagine;
            me.$refs.mdlImmagineSelezionata.show();
        },
        aggiornaImmagine(jsonData) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + jsonData.id;
            axios
                .put(link, JSON.stringify(jsonData))
                .then(() => {
                    me.loadData();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            console.log(value);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>

<style>
.hide-file-input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
}
.file-label {
    color: #fff;
    background-color: #3730a3;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
}
input[type="file"]:focus + .file-label {
    box-shadow: 0 0 0 4px #bae6fd;
}

.sa-drop-image {
    background-color: #f0f0f0;
    width: 100%;
    aspect-ratio: 1/ 1;
    border: 1px #666 dashed;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.sa-image-upload-icon {
    display: flex;
    align-items: center;
    font-size: 5rem;
    margin: auto;
}
.sa-drop-image span {
    margin-top: 10px;
}

.sa-pei-btn-icon {
    padding-left: 5px;
    padding-top: 1px;
    font-size: 1.25rem;
}
.sa-pei-chk {
    width: 150px;
    padding-bottom: 0px !important;
}
.sa-pei-chk-label {
    font-size: 0.75rem;
    font-weight: 500;
    padding-left: 3px;
}
.sa-pei-input {
    width: 100%;
    font-size: 0.75rem;
    padding: 0px;
    height: 25px;
    margin-right: 3px;
    border: 1px solid rgb(201, 201, 201);
}
.sa-pei-chk .custom-control-label {
    padding-top: 3px;
}
/* .sa-pei-chk .custom-control-input {
    margin-left: 3px;
} */
</style>
