<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
        <template slot="tab-content-event">
            <dati-clinico-strum-ricovero-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></dati-clinico-strum-ricovero-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import DatiClinicoStrumRicoveroEditComponent from "./DatiClinicoStrumRicoveroEditComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { DatiClinicoStrumRicoveroEditComponent, EventiEditComponent },
    data() {
        return {
            linkback: null,
            titoloEvento: "Dati Clinico-Strumentali-S.C.A.",
            pathResource: "/cardiodaticlinicistrumentaliric",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            jsonData: {
                dataEvento: null,
                tipo: null,
                sede: null,
                presentazioneClinica: null,
                patternDelDolore: null,
                terapiaDiRiperfusione: null,
                classeKillip: null,
                emorragie: null,
                aritmie: null,
                blocchi: null,
                modalitaDiComparsa: null,
                classificazioneDiBraunwald: null,
                durataMaxAngor: null,
                alterazioniEcgDuranteAngor: null,
                interventiTerapeutici: null,
                decorsoA24h: null,
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
