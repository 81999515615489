<template>
  <sa-page-layout :data="jsonData" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="6" md="6" lg="4" xl="4">
              <label class="sa-label-data">Descrizione</label>
              <b-form-input v-model="jsonData.nome"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Note</label>
              <b-form-textarea v-model="jsonData.note" rows="8" no-resize></b-form-textarea>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Lista Messaggi" header-tag="header" footer-tag="footer" title="">
          <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaTestoMail" :fields="fieldsTestoMail" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
              <!-- <template #cell(index)="row">
                {{ row.index + 1 }}
              </template> -->
              <template #cell(idAction)="item">
                <b-form-select v-model="item.item.idAction" :options="actionOptions" :value="''" text-field="text" value-field="value" disabled-field="notEnabled"></b-form-select>
              </template>
              <template #cell(subject)="item">
                <b-form-input v-model="item.item.subject"></b-form-input>
              </template>
              <template #cell(testoMail)="item">
                <b-form-textarea v-model="item.item.testoMail" rows="2" no-resize></b-form-textarea>
              </template>
              <template #cell(actions)="row">
                <b-button v-if="row.index == jsonData.listaTestoMail.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAdd(jsonData.listaTestoMail, row.item)">
                  <b-icon icon="plus"></b-icon>
                </b-button>
                <b-button v-if="row.index !== jsonData.listaTestoMail.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaTestoMail, row)">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </template>
            </b-table>
          </div>
        </b-card>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { SaPageLayout },
  data() {
    return {
      //   pathRest: "/adipua",
      pathResource: "/emailnotificationgroups",
      pathAction: "/emailnotificationactions",
      linkback: "/emailnotificationgroups",
      linkedit: null,
      showModalLoading: false,
      id: "-1",
      perPage: 10,
      perPageUsers: 100000,
      currentPage: 1,
      currentPageUsers: 1,
      jsonData: { listaTestoMail: [] },
      actionOptions: [],
      fieldsTestoMail: [
        {
          label: "Azione Modulo",
          key: "idAction",
          sortable: true,
          thStyle: "width: 20rem",
        },
        {
          label: "Oggetto",
          key: "subject",
          sortable: true,
          thStyle: "width: 20rem",
        },
        {
          label: "Testo Mail",
          key: "testoMail",
          sortable: true,
          // thStyle: "width: 12rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
    };
  },
  computed: {},
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.loadDefaultData();
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadAction();
      me.loadData();
    },

    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.listaTestoMail.push({ idAction: "", subject: "", testoMail: "" });
      } else {
        me.showModalLoading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
          me.jsonData.listaTestoMail.push({ idAction: "", subject: "", testoMail: "" });
          me.checkDistrettiSelezionati();
        });
      }
    },
    checkDistrettiSelezionati() {
      let me = this;
      me.jsonData.listaTestoMail.forEach((value) => {
        // console.log(value);
        me.actionOptions.forEach((element) => {
          if (element.value === value.idDistretto) {
            element.notEnabled = true;
            // value.descrizioneDistretto = element.text;
          }
        });
      });
    },
    loadAction() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathAction;
      me.actionOptions = [];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          // console.log(element.id);
          me.actionOptions.push({ value: element.id, text: element.action + " " + element.modulo, notEnabled: false });
        });
        me.actionOptions.unshift({ value: "", text: "-Seleziona Action-", notEnabled: true });
        // console.log(this.actionOptions);
      });
    },
    onAdd(array, value) {
      console.log(value);
      let me = this;
      // console.log(value);
      if (value.idAction !== null && value.idAction !== "") {
        // console.log("Sono qua");
        me.actionOptions.forEach((element) => {
          if (element.value === value.idAction) {
            element.notEnabled = true;
            // value.descrizioneDistretto = element.text;
          }
        });
        array.push({ idAction: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Distretto non valido", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
              this.actionOptions.forEach((element) => {
                if (element.value === row.item.idDistretto) {
                  element.notEnabled = false;
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
