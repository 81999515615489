<template>
  <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData" :isPrestazioni="true">
    <template slot="tab-content-event">
      <gastro-inserzione-videocapusla-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></gastro-inserzione-videocapusla-edit-component>
    </template>
  </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import GastroInserzioneVideocapuslaEditComponent from "../components/GastroInserzioneVideocapuslaEditComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { EventiEditComponent, GastroInserzioneVideocapuslaEditComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    this.jsonData.dataEvento = new Date().getTime();
  },
  data() {
    return {
      linkback: null,
      titoloEvento: this.getLabelTraduora("patients.gastroenterology.egds"),
      pathResource: "/gastroinserzionevideocapusla",
      pathRestValidation: "/validation",
      id: "-1",
      isFirmato: false,
      childKey: 0,
      tabIndex: 0,
      showModalLoading: false,
      jsonData: { dataEvento: null, descrizioneIntervento: "" },
    };
  },
  methods: {
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
  },
};
</script>
