<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPaceMakerImplant')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNextCheckDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataProssimoControllo" type="datetime"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFirstImplantDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataPrimoImpianto" type="datetime"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSubstitutionDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataSostituzione" type="datetime"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                    <b-form-input v-model="jsonData.sostituzione" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInplantIndication") }}</label>
                    <b-form-input v-model="jsonData.sintomi" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblStimulationSystem") }}</label>
                    <b-form-select v-model="jsonData.sistemaDiStimolazione" :options="optionsSistema">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblBaseFrequency") }}</label>
                    <b-form-input v-model="jsonData.frequenzaBase" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblConstructor") }}</label>
                    <b-form-input v-model="jsonData.costruttore" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblModel") }}</label>
                    <b-form-input v-model="jsonData.tipoCostruttore" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSerialNumber") }}</label>
                    <b-form-input v-model="jsonData.serialeIcd" type="text"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAtrialCatheter')" header-tag="header" footer-tag="footer" title="">
            <b-row id="catetere-atriale-1">
                <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblImplantDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataImpiantoAtriale" type="datetime"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSerialNumber") }}</label>
                    <b-form-input v-model="jsonData.serialeCatetAtriale" type="text"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="catetere-atriale-2">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblModel") }}</label>
                    <b-form-input v-model="jsonData.modelloCatAtriale" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblConstructor") }}</label>
                    <b-form-input v-model="jsonData.costruttoreCatAtriale" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-select v-model="jsonData.tipoCostruttoreCatAtriale" :options="optionsTipoCostruttore">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightVentricularCatheter')" header-tag="header" footer-tag="footer" title="">
            <b-row id="catetere-ventr-destro-1">
                <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblImplantDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataImpiantoVentricolare" type="datetime"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSerialNumber") }}</label>
                    <b-form-input v-model="jsonData.serialeCatetVentr" type="text"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="catetere-ventr-destro-2">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblModel") }}</label>
                    <b-form-input v-model="jsonData.modelloCatVentricolare" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblConstructor") }}</label>
                    <b-form-input v-model="jsonData.costruttoreCatVentricolare" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-select v-model="jsonData.tipoCostruttoreCatVentricolare" :options="optionsTipoCostruttore">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLeftVentricularCatheter')" header-tag="header" footer-tag="footer" title="">
            <b-row id="catetere-ventr-sinistro-1">
                <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblImplantDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataImpiantoVentrSx" type="datetime"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSerialNumber") }}</label>
                    <b-form-input v-model="jsonData.serialeCatetVentrSx" type="text"></b-form-input>
                </b-col>
            </b-row>
            <b-row id="catetere-ventr-sinistro-2">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblModel") }}</label>
                    <b-form-input v-model="jsonData.modelloCatVentrSx" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblConstructor") }}</label>
                    <b-form-input v-model="jsonData.costruttoreCatVentrSx" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <b-form-select v-model="jsonData.tipoCostrutCatVentrSx" :options="optionsTipoCostruttore">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                    <b-form-textarea v-model="jsonData.commento1" rows="5" max-rows="5" no-resize> </b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAtrialElectrode')" header-tag="header" footer-tag="footer" title="">
            <b-row id="elettrodo-atriale">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblVein") }}</label>
                    <b-form-select v-model="jsonData.venaAtr" :options="optionsVena">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblThreshold") }}</label>
                    <b-form-input v-model="jsonData.sogliaAtriale" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCurrent") }}</label>
                    <b-form-input v-model="jsonData.correnteAtriale" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblImpedance") }}</label>
                    <b-form-input v-model="jsonData.resistenzeAtriale" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblWaveP") }}</label>
                    <b-form-input v-model="jsonData.ondaPAtriale" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightVentricularElectrode')" header-tag="header" footer-tag="footer" title="">
            <b-row id="elettrodo-ventricolare-destro">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblVein") }}</label>
                    <b-form-select v-model="jsonData.venaVentrDx" :options="optionsVena">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblThreshold") }}</label>
                    <b-form-input v-model="jsonData.sogliaVentricolareDx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCurrent") }}</label>
                    <b-form-input v-model="jsonData.correnteVentricolareDx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblImpedance") }}</label>
                    <b-form-input v-model="jsonData.resistenzeVentrDx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblWaveR") }}</label>
                    <b-form-input v-model="jsonData.ondaRVentricolareDx" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblleftVentricularElectrode')" header-tag="header" footer-tag="footer" title="">
            <b-row id="elettrodo-ventricolare-sinistro">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblVein") }}</label>
                    <b-form-select v-model="jsonData.venaVentrSx" :options="optionsVena">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblThreshold") }}</label>
                    <b-form-input v-model="jsonData.sogliaVentricolareSx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCurrent") }}</label>
                    <b-form-input v-model="jsonData.correnteVentricolareSx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblImpedance") }}</label>
                    <b-form-input v-model="jsonData.resistenzeVentrSx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblWaveR") }}</label>
                    <b-form-input v-model="jsonData.ondaRVentricolareSx" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblDefaultProgramming')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3"
                    ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataProssimoControllo" type="datetime"></date-picker>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPulseDurationAtrium") }}</label>
                    <b-form-input v-model="jsonData.durataImpulsoAtr1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPulseDurationRightVentricle") }}</label>
                    <b-form-input v-model="jsonData.durataImpulsoVentr1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPulseDurationLeftVentricle") }}</label>
                    <b-form-input v-model="jsonData.durataImpulsoVentr1Sx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblModality") }}</label>
                    <b-form-select v-model="jsonData.modalita1" :options="optionsSistema">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSpontaneousHeartFrequency") }}</label>
                    <b-form-input v-model="jsonData.fcSpontanea1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblMagneticHeartFrequency") }}</label>
                    <b-form-input v-model="jsonData.fcMagnetica1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblMaxHeartFrequency") }}</label>
                    <b-form-input v-model="jsonData.fcMax1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblMinHeartFrequency") }}</label>
                    <b-form-input v-model="jsonData.fcMin1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAtrialSensibility") }}</label>
                    <b-form-input v-model="jsonData.sensibilitaAtr1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblRightVetricleSensibility") }}</label>
                    <b-form-input v-model="jsonData.sensibilitaVentr1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLeftVetricleSensibility") }}</label>
                    <b-form-input v-model="jsonData.sensibilitaVentr1Sx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAvDelay") }}</label>
                    <b-form-input v-model="jsonData.av1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCapacitorChargingTime") }}</label>
                    <b-form-input v-model="jsonData.ricaricaCondens1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblBatteryCurrent") }}</label>
                    <b-form-input v-model="jsonData.correnteBatteria1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblBatteryImpedance") }}</label>
                    <b-form-input v-model="jsonData.impedenzaBatteria1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblBatteryConditions") }}</label>
                    <b-form-select v-model="jsonData.statoBatteria1" :options="optionsStatoBatt">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAtrialPulseWidth") }}</label>
                    <b-form-input v-model="jsonData.ampImpAtr1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblRightVentriclePulseWidth") }}</label>
                    <b-form-input v-model="jsonData.ampImpVentr1" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLeftVentriclePulseWidth") }}</label>
                    <b-form-input v-model="jsonData.ampImpVentr1Sx" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblShockImpedance") }}</label>
                    <b-form-input v-model="jsonData.impedenzaShock" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                    <b-form-textarea v-model="jsonData.altro1" rows="6" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return { dataEvento: null };
            },
        },
    },

    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            pathResource: "/cardiopacemakerimpianti",
            showModalLoading: false,
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            // paMediaMax: 0,
            // paMediaMin: 0,
            optionsSistema: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblDDDLV"), value: "patient.cardiology.Options.lblDDDLV" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblDDD"), value: "patient.cardiology.Options.lblDDD" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblVVIR"), value: "patient.cardiology.Options.lblVVIR" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblVVI"), value: "patient.cardiology.Options.lblVVI" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblDDDR"), value: "patient.cardiology.Options.lblDDDR" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblVDDR"), value: "patient.cardiology.Options.lblVDDR" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblDDI"), value: "patient.cardiology.Options.lblDDI" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblVDD"), value: "patient.cardiology.Options.lblVDD" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAIDDDR"), value: "patient.cardiology.Options.lblAIDDDR" },
            ],
            optionsTipoCostruttore: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblBipolar"), value: "patient.cardiology.Options.lblBipolar" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblUnipolar"), value: "patient.cardiology.Options.lblUnipolar" },
            ],
            optionsVena: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblCefalic"), value: "patient.cardiology.Options.lblCefalic" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblRightSucclavia"), value: "patient.cardiology.Options.lblRightSucclavia" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblLeftSucclavia"), value: "patient.cardiology.Options.lblLeftSucclavia" },
            ],
            optionsStatoBatt: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblOk"), value: "patient.cardiology.Options.lblOk" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblExhausted"), value: "patient.cardiology.Options.lblExhausted" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblStartingExhaust"), value: "patient.cardiology.Options.lblStartingExhaust" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
        // paMediaMax: function (max) {
        //     let me = this;
        //     me.jsonData.pasitmaxm = max;
        // },
        // paMediaMin: function (min) {
        //     let me = this;
        //     me.jsonData.pasitminm = min;
        // },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        // returnMediaPA() {
        //     let me = this;
        //     let misurazioniMax = 0;
        //     let misurazioniMin = 0;
        //     let divisore = 0;
        //     // console.log(me.jsonData.pasitmax2)
        //     if (me.jsonData.pasitmax1 && me.jsonData.pasitmin1) {
        //         let max = Number(me.jsonData.pasitmax1);
        //         let min = Number(me.jsonData.pasitmin1);
        //         misurazioniMax = misurazioniMax + max;
        //         misurazioniMin = misurazioniMin + min;
        //         divisore = divisore + 1;
        //     }
        //     if (me.jsonData.pasitmax2 && me.jsonData.pasitmin2) {
        //         let max = Number(me.jsonData.pasitmax2);
        //         let min = Number(me.jsonData.pasitmin2);
        //         misurazioniMax = misurazioniMax + max;
        //         misurazioniMin = misurazioniMin + min;
        //         divisore = divisore + 1;
        //     }
        //     if (me.jsonData.pasitmax3 && me.jsonData.pasitmin3) {
        //         misurazioniMax = misurazioniMax + Number(me.jsonData.pasitmax3);
        //         misurazioniMin = misurazioniMin + Number(me.jsonData.pasitmin3);
        //         divisore = divisore + 1;
        //     }
        //     // console.log(misurazioniMax+" / "+divisore+" = ")
        //     // console.log(misurazioniMax/divisore);
        //     me.paMediaMax = Math.floor(misurazioniMax / divisore);
        //     me.paMediaMin = Math.floor(misurazioniMin / divisore);
        //     // console.log("Media max: "+me.paMediaMax);
        // },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
