<template>
  <tableau-viz id="tableau-viz" src="http://172.18.253.41/views/HIV/10Dash" width="1000" height="840" hide-tabs toolbar="bottom"></tableau-viz>
  <!-- <iframe src="http://172.18.253.41/views/HIV/10Dash" frameborder="0" height="100%"></iframe> -->
</template>

<script>
export default {};
</script>

<style></style>
