<template>
    <sa-page-layout :btnBackVisible="false" :btnEditVisible="false" :btnRefreshVisible="true" :showModalLoading="showModalLoading" @refresh="onRefresh" :linkedit="linkedit" :linkback="linkback" :data="jsonData" :pathResource="pathResource">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.nome" type="search" id="nome" placeholder="Nome"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.cognome" type="search" id="cognome" placeholder="Cognome"></b-form-input>
                    </b-col>
                </b-row>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="">
                        <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button
                        >&nbsp;
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(first_name)="{ item }">
                        <router-link class="sa-edit-link" :to="'/worklist/visitamedicosportiva/view/' + item.id">{{ item.first_name }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            filtro: {},
            showModalLoading: false,
            linkback: "/atleti",
            linkedit: null,
            pathResource: "/atleti",
            jsonData: {},
            items: [
                { id: 1, first_name: "Cyndie" },
                { id: 2, first_name: "Winthrop" },
                { id: 3, first_name: "Odetta" },
                { id: 4, first_name: "Melany" },
                { id: 5, first_name: "Jenda" },
                { id: 6, first_name: "Tam" },
                { id: 7, first_name: "Ransom" },
                { id: 8, first_name: "Ollie" },
                { id: 9, first_name: "Samantha" },
                { id: 10, first_name: "Randie" },
                { id: 11, first_name: "Diannne" },
                { id: 12, first_name: "Thorin" },
                { id: 13, first_name: "Pepita" },
                { id: 14, first_name: "Donalt" },
                { id: 15, first_name: "Jordana" },
                { id: 16, first_name: "Nedi" },
                { id: 17, first_name: "Aili" },
                { id: 18, first_name: "Caspar" },
                { id: 19, first_name: "Ursuline" },
                { id: 20, first_name: "Farand" },
                { id: 21, first_name: "Felicio" },
                { id: 22, first_name: "Dorice" },
                { id: 23, first_name: "Caleb" },
                { id: 24, first_name: "Tana" },
                { id: 25, first_name: "Ches" },
                { id: 26, first_name: "Piotr" },
                { id: 27, first_name: "Mei" },
                { id: 28, first_name: "Vivie" },
                { id: 29, first_name: "Matthew" },
                { id: 30, first_name: "Hervey" },
                { id: 31, first_name: "Marinna" },
                { id: 32, first_name: "Garv" },
                { id: 33, first_name: "Leora" },
                { id: 34, first_name: "Larina" },
                { id: 35, first_name: "Barri" },
                { id: 36, first_name: "Toni" },
                { id: 37, first_name: "Marco" },
                { id: 38, first_name: "Montgomery" },
                { id: 39, first_name: "Shara" },
                { id: 40, first_name: "Fifi" },
                { id: 41, first_name: "Brit" },
                { id: 42, first_name: "Lyn" },
                { id: 43, first_name: "Alexandra" },
                { id: 44, first_name: "Tallie" },
                { id: 45, first_name: "Lenora" },
                { id: 46, first_name: "Keenan" },
                { id: 47, first_name: "Jock" },
                { id: 48, first_name: "Isak" },
                { id: 49, first_name: "Ermanno" },
                { id: 50, first_name: "Ester" },
                { id: 51, first_name: "Tulley" },
                { id: 52, first_name: "Jodee" },
                { id: 53, first_name: "Hurley" },
                { id: 54, first_name: "Rubin" },
                { id: 55, first_name: "Jayme" },
                { id: 56, first_name: "Sean" },
                { id: 57, first_name: "Wallace" },
                { id: 58, first_name: "Nestor" },
                { id: 59, first_name: "Fletcher" },
                { id: 60, first_name: "Loree" },
                { id: 61, first_name: "Winona" },
                { id: 62, first_name: "Marrilee" },
                { id: 63, first_name: "Faustina" },
                { id: 64, first_name: "Ariel" },
                { id: 65, first_name: "Zared" },
                { id: 66, first_name: "Jermaine" },
                { id: 67, first_name: "Demetri" },
                { id: 68, first_name: "Farand" },
                { id: 69, first_name: "Theresa" },
                { id: 70, first_name: "Leigh" },
                { id: 71, first_name: "Cos" },
                { id: 72, first_name: "Kaja" },
                { id: 73, first_name: "Lilian" },
                { id: 74, first_name: "Moselle" },
                { id: 75, first_name: "Frazier" },
                { id: 76, first_name: "Jennee" },
                { id: 77, first_name: "Patty" },
                { id: 78, first_name: "Merrily" },
                { id: 79, first_name: "Robers" },
                { id: 80, first_name: "Misti" },
                { id: 81, first_name: "Annecorinne" },
                { id: 82, first_name: "Horst" },
                { id: 83, first_name: "Eadmund" },
                { id: 84, first_name: "Giulio" },
                { id: 85, first_name: "Olga" },
                { id: 86, first_name: "Bertie" },
                { id: 87, first_name: "Adi" },
                { id: 88, first_name: "Phil" },
                { id: 89, first_name: "Ewan" },
                { id: 90, first_name: "Bonnie" },
                { id: 91, first_name: "Verla" },
                { id: 92, first_name: "Vincent" },
                { id: 93, first_name: "Whittaker" },
                { id: 94, first_name: "Joycelin" },
                { id: 95, first_name: "Helyn" },
                { id: 96, first_name: "Madeline" },
                { id: 97, first_name: "Randy" },
                { id: 98, first_name: "Concettina" },
                { id: 99, first_name: "Lynnell" },
                { id: 100, first_name: "Reinold" },
                { id: 101, first_name: "Maegan" },
                { id: 102, first_name: "Ailene" },
                { id: 103, first_name: "Nap" },
                { id: 104, first_name: "Joellen" },
                { id: 105, first_name: "Noland" },
                { id: 106, first_name: "Caddric" },
                { id: 107, first_name: "Freddie" },
                { id: 108, first_name: "Grazia" },
                { id: 109, first_name: "Cecil" },
                { id: 110, first_name: "Dev" },
                { id: 111, first_name: "Sybilla" },
                { id: 112, first_name: "Dulcia" },
                { id: 113, first_name: "Julina" },
                { id: 114, first_name: "Fayre" },
                { id: 115, first_name: "Haywood" },
                { id: 116, first_name: "Joanna" },
                { id: 117, first_name: "Vance" },
                { id: 118, first_name: "Verge" },
                { id: 119, first_name: "Candida" },
                { id: 120, first_name: "Chuck" },
                { id: 121, first_name: "Gale" },
                { id: 122, first_name: "Silas" },
                { id: 123, first_name: "Gregorio" },
                { id: 124, first_name: "Codi" },
                { id: 125, first_name: "Barr" },
                { id: 126, first_name: "Franklin" },
                { id: 127, first_name: "Ailee" },
                { id: 128, first_name: "Don" },
                { id: 129, first_name: "Garvin" },
                { id: 130, first_name: "Leesa" },
                { id: 131, first_name: "Shaylah" },
                { id: 132, first_name: "Myrah" },
                { id: 133, first_name: "Ferdy" },
                { id: 134, first_name: "Jeanne" },
                { id: 135, first_name: "Aline" },
                { id: 136, first_name: "Mabelle" },
                { id: 137, first_name: "Esme" },
                { id: 138, first_name: "Jorge" },
                { id: 139, first_name: "Fayre" },
                { id: 140, first_name: "Ikey" },
                { id: 141, first_name: "Daryn" },
                { id: 142, first_name: "Karmen" },
                { id: 143, first_name: "Ambur" },
                { id: 144, first_name: "Weylin" },
                { id: 145, first_name: "Brodie" },
                { id: 146, first_name: "Yettie" },
                { id: 147, first_name: "Laura" },
                { id: 148, first_name: "Brade" },
                { id: 149, first_name: "Jorie" },
                { id: 150, first_name: "Annabelle" },
                { id: 151, first_name: "Linnell" },
                { id: 152, first_name: "Wilhelm" },
                { id: 153, first_name: "Jacquie" },
                { id: 154, first_name: "Maddie" },
                { id: 155, first_name: "Tanya" },
                { id: 156, first_name: "Zack" },
                { id: 157, first_name: "Olva" },
                { id: 158, first_name: "Alvan" },
                { id: 159, first_name: "Allene" },
                { id: 160, first_name: "Petunia" },
                { id: 161, first_name: "Tadio" },
                { id: 162, first_name: "Lebbie" },
                { id: 163, first_name: "Tam" },
                { id: 164, first_name: "Malvin" },
                { id: 165, first_name: "Bevon" },
                { id: 166, first_name: "Cortney" },
                { id: 167, first_name: "Dinnie" },
                { id: 168, first_name: "Hermon" },
                { id: 169, first_name: "Lusa" },
                { id: 170, first_name: "Flinn" },
                { id: 171, first_name: "Emanuele" },
                { id: 172, first_name: "Gussi" },
                { id: 173, first_name: "Lola" },
                { id: 174, first_name: "Bertrand" },
                { id: 175, first_name: "Bridie" },
                { id: 176, first_name: "Maryanna" },
                { id: 177, first_name: "Loy" },
                { id: 178, first_name: "Brigida" },
                { id: 179, first_name: "Foster" },
                { id: 180, first_name: "Jobina" },
                { id: 181, first_name: "Dorthy" },
                { id: 182, first_name: "Maurise" },
                { id: 183, first_name: "Niven" },
                { id: 184, first_name: "Nealon" },
                { id: 185, first_name: "Vida" },
                { id: 186, first_name: "Fields" },
                { id: 187, first_name: "Nana" },
                { id: 188, first_name: "Yehudit" },
                { id: 189, first_name: "Raeann" },
                { id: 190, first_name: "Elga" },
                { id: 191, first_name: "Osborne" },
                { id: 192, first_name: "Bogart" },
                { id: 193, first_name: "Skipper" },
                { id: 194, first_name: "Rae" },
                { id: 195, first_name: "Hilary" },
                { id: 196, first_name: "Margareta" },
                { id: 197, first_name: "Fletcher" },
                { id: 198, first_name: "Rebbecca" },
                { id: 199, first_name: "Natty" },
                { id: 200, first_name: "Ulrika" },
                { id: 201, first_name: "Antonia" },
                { id: 202, first_name: "Lydia" },
                { id: 203, first_name: "Jess" },
                { id: 204, first_name: "Kizzee" },
                { id: 205, first_name: "Orsola" },
                { id: 206, first_name: "Harrietta" },
                { id: 207, first_name: "Concettina" },
                { id: 208, first_name: "Erika" },
                { id: 209, first_name: "Shelbi" },
                { id: 210, first_name: "Moina" },
                { id: 211, first_name: "Etheline" },
                { id: 212, first_name: "Louisa" },
                { id: 213, first_name: "Domenico" },
                { id: 214, first_name: "Garwood" },
                { id: 215, first_name: "Roarke" },
                { id: 216, first_name: "Rhody" },
                { id: 217, first_name: "Guthry" },
                { id: 218, first_name: "Axel" },
                { id: 219, first_name: "Rogers" },
                { id: 220, first_name: "Dani" },
                { id: 221, first_name: "Archibald" },
                { id: 222, first_name: "Charmine" },
                { id: 223, first_name: "Tann" },
                { id: 224, first_name: "Linnea" },
                { id: 225, first_name: "Brigham" },
                { id: 226, first_name: "Dyan" },
                { id: 227, first_name: "Rolando" },
                { id: 228, first_name: "Elvis" },
                { id: 229, first_name: "Towny" },
                { id: 230, first_name: "Rube" },
                { id: 231, first_name: "Tadd" },
                { id: 232, first_name: "Mela" },
                { id: 233, first_name: "Verla" },
                { id: 234, first_name: "Darnell" },
                { id: 235, first_name: "Dorothee" },
                { id: 236, first_name: "Jaquenetta" },
                { id: 237, first_name: "Bucky" },
                { id: 238, first_name: "Tamarra" },
                { id: 239, first_name: "Chauncey" },
                { id: 240, first_name: "Bertie" },
                { id: 241, first_name: "Franklin" },
                { id: 242, first_name: "Worden" },
                { id: 243, first_name: "Walt" },
                { id: 244, first_name: "Emmalee" },
                { id: 245, first_name: "Delcina" },
                { id: 246, first_name: "Lian" },
                { id: 247, first_name: "Devy" },
                { id: 248, first_name: "Mano" },
                { id: 249, first_name: "Brianne" },
                { id: 250, first_name: "Prentice" },
                { id: 251, first_name: "Vladimir" },
                { id: 252, first_name: "Halette" },
                { id: 253, first_name: "Ynes" },
                { id: 254, first_name: "Karla" },
                { id: 255, first_name: "Allene" },
                { id: 256, first_name: "Milly" },
                { id: 257, first_name: "Adrienne" },
                { id: 258, first_name: "Magdalena" },
                { id: 259, first_name: "Rafa" },
                { id: 260, first_name: "Bebe" },
                { id: 261, first_name: "Keriann" },
                { id: 262, first_name: "Jaclin" },
                { id: 263, first_name: "Pate" },
                { id: 264, first_name: "Giuditta" },
                { id: 265, first_name: "Jakie" },
                { id: 266, first_name: "Ellen" },
                { id: 267, first_name: "Ethyl" },
                { id: 268, first_name: "Kenyon" },
                { id: 269, first_name: "Jobi" },
                { id: 270, first_name: "Regan" },
                { id: 271, first_name: "Penelopa" },
                { id: 272, first_name: "Sarajane" },
                { id: 273, first_name: "Artus" },
                { id: 274, first_name: "Addison" },
                { id: 275, first_name: "Jody" },
                { id: 276, first_name: "Malinda" },
                { id: 277, first_name: "Tobe" },
                { id: 278, first_name: "Tine" },
                { id: 279, first_name: "Marquita" },
                { id: 280, first_name: "Carroll" },
                { id: 281, first_name: "Salome" },
                { id: 282, first_name: "Jaymee" },
                { id: 283, first_name: "Timmi" },
                { id: 284, first_name: "Sutherlan" },
                { id: 285, first_name: "Melinda" },
                { id: 286, first_name: "Basia" },
                { id: 287, first_name: "Elinor" },
                { id: 288, first_name: "Virgilio" },
                { id: 289, first_name: "Appolonia" },
                { id: 290, first_name: "Andris" },
                { id: 291, first_name: "Joline" },
                { id: 292, first_name: "Bearnard" },
                { id: 293, first_name: "Mavra" },
                { id: 294, first_name: "Kelcy" },
                { id: 295, first_name: "Calhoun" },
                { id: 296, first_name: "Brit" },
                { id: 297, first_name: "Iosep" },
                { id: 298, first_name: "Jamima" },
                { id: 299, first_name: "Wandie" },
                { id: 300, first_name: "Merline" },
                { id: 301, first_name: "Kariotta" },
                { id: 302, first_name: "Lamont" },
                { id: 303, first_name: "Yehudit" },
                { id: 304, first_name: "Orelle" },
                { id: 305, first_name: "Jo" },
                { id: 306, first_name: "Marcelia" },
                { id: 307, first_name: "Carolina" },
                { id: 308, first_name: "Marin" },
                { id: 309, first_name: "Rodger" },
                { id: 310, first_name: "Dotti" },
                { id: 311, first_name: "El" },
                { id: 312, first_name: "Eduino" },
                { id: 313, first_name: "Towny" },
                { id: 314, first_name: "Asia" },
                { id: 315, first_name: "Damiano" },
                { id: 316, first_name: "Brion" },
                { id: 317, first_name: "Glyn" },
                { id: 318, first_name: "Dredi" },
                { id: 319, first_name: "Rina" },
                { id: 320, first_name: "Kendal" },
                { id: 321, first_name: "Haleigh" },
                { id: 322, first_name: "Giulia" },
                { id: 323, first_name: "Susann" },
                { id: 324, first_name: "Camey" },
                { id: 325, first_name: "Dur" },
                { id: 326, first_name: "Katee" },
                { id: 327, first_name: "Phylys" },
                { id: 328, first_name: "Denny" },
                { id: 329, first_name: "Spence" },
                { id: 330, first_name: "Cacilie" },
                { id: 331, first_name: "Xenia" },
                { id: 332, first_name: "Clarabelle" },
                { id: 333, first_name: "Dyana" },
                { id: 334, first_name: "Westbrooke" },
                { id: 335, first_name: "Basilio" },
                { id: 336, first_name: "Norrie" },
                { id: 337, first_name: "Kimberli" },
                { id: 338, first_name: "Wilfred" },
                { id: 339, first_name: "Talbot" },
                { id: 340, first_name: "Anne-marie" },
                { id: 341, first_name: "Ambur" },
                { id: 342, first_name: "Lewie" },
                { id: 343, first_name: "Sascha" },
                { id: 344, first_name: "Audra" },
                { id: 345, first_name: "Carmine" },
                { id: 346, first_name: "Jacqui" },
                { id: 347, first_name: "Lazare" },
                { id: 348, first_name: "Angelika" },
                { id: 349, first_name: "Christel" },
                { id: 350, first_name: "Kati" },
                { id: 351, first_name: "Wiatt" },
                { id: 352, first_name: "Hieronymus" },
                { id: 353, first_name: "Vinnie" },
                { id: 354, first_name: "Fan" },
                { id: 355, first_name: "Mariquilla" },
                { id: 356, first_name: "Daisy" },
                { id: 357, first_name: "Twila" },
                { id: 358, first_name: "Rafi" },
                { id: 359, first_name: "Matilde" },
                { id: 360, first_name: "Clemmie" },
                { id: 361, first_name: "Sofie" },
                { id: 362, first_name: "Evy" },
                { id: 363, first_name: "Saree" },
                { id: 364, first_name: "Starla" },
                { id: 365, first_name: "Valida" },
                { id: 366, first_name: "Lucina" },
                { id: 367, first_name: "Charline" },
                { id: 368, first_name: "Carl" },
                { id: 369, first_name: "Clayton" },
                { id: 370, first_name: "Norrie" },
                { id: 371, first_name: "Wolf" },
                { id: 372, first_name: "Lorita" },
                { id: 373, first_name: "Leif" },
                { id: 374, first_name: "Gerhardt" },
                { id: 375, first_name: "Merralee" },
                { id: 376, first_name: "Serge" },
                { id: 377, first_name: "Pedro" },
                { id: 378, first_name: "Robena" },
                { id: 379, first_name: "Misha" },
                { id: 380, first_name: "Guthry" },
                { id: 381, first_name: "Pyotr" },
                { id: 382, first_name: "Alyce" },
                { id: 383, first_name: "Scarlett" },
                { id: 384, first_name: "Hugh" },
                { id: 385, first_name: "Jewel" },
                { id: 386, first_name: "Veronica" },
                { id: 387, first_name: "Talyah" },
                { id: 388, first_name: "Kelcey" },
                { id: 389, first_name: "Kittie" },
                { id: 390, first_name: "Alfi" },
                { id: 391, first_name: "Mariel" },
                { id: 392, first_name: "Geraldine" },
                { id: 393, first_name: "Benetta" },
                { id: 394, first_name: "Urbain" },
                { id: 395, first_name: "Cassandry" },
                { id: 396, first_name: "Maribelle" },
                { id: 397, first_name: "Gregorio" },
                { id: 398, first_name: "Waverly" },
                { id: 399, first_name: "Jana" },
                { id: 400, first_name: "Zelig" },
                { id: 401, first_name: "Carlo" },
                { id: 402, first_name: "Ardith" },
                { id: 403, first_name: "Tedi" },
                { id: 404, first_name: "Dyan" },
                { id: 405, first_name: "Siouxie" },
                { id: 406, first_name: "Nikola" },
                { id: 407, first_name: "Bord" },
                { id: 408, first_name: "Kippie" },
                { id: 409, first_name: "Lynett" },
                { id: 410, first_name: "Meggie" },
                { id: 411, first_name: "Brewer" },
                { id: 412, first_name: "Yolande" },
                { id: 413, first_name: "Scotti" },
                { id: 414, first_name: "Allyson" },
                { id: 415, first_name: "Reg" },
                { id: 416, first_name: "Shela" },
                { id: 417, first_name: "Addy" },
                { id: 418, first_name: "Cale" },
                { id: 419, first_name: "Marylou" },
                { id: 420, first_name: "Julian" },
                { id: 421, first_name: "Stefania" },
                { id: 422, first_name: "Florri" },
                { id: 423, first_name: "Zarla" },
                { id: 424, first_name: "Julie" },
                { id: 425, first_name: "El" },
                { id: 426, first_name: "Kent" },
                { id: 427, first_name: "Becka" },
                { id: 428, first_name: "Ellene" },
                { id: 429, first_name: "Brigitte" },
                { id: 430, first_name: "Swen" },
                { id: 431, first_name: "Hasheem" },
                { id: 432, first_name: "Somerset" },
                { id: 433, first_name: "Brear" },
                { id: 434, first_name: "Lory" },
                { id: 435, first_name: "Marquita" },
                { id: 436, first_name: "Atlanta" },
                { id: 437, first_name: "Rosella" },
                { id: 438, first_name: "Theresa" },
                { id: 439, first_name: "Bruis" },
                { id: 440, first_name: "Greta" },
                { id: 441, first_name: "Trude" },
                { id: 442, first_name: "Wildon" },
                { id: 443, first_name: "Falkner" },
                { id: 444, first_name: "Benoite" },
                { id: 445, first_name: "Alicea" },
                { id: 446, first_name: "Myra" },
                { id: 447, first_name: "Alberto" },
                { id: 448, first_name: "Leontyne" },
                { id: 449, first_name: "Rheba" },
                { id: 450, first_name: "Steffi" },
                { id: 451, first_name: "Minny" },
                { id: 452, first_name: "Johna" },
                { id: 453, first_name: "Benedetto" },
                { id: 454, first_name: "Franzen" },
                { id: 455, first_name: "Trenton" },
                { id: 456, first_name: "Sosanna" },
                { id: 457, first_name: "Chico" },
                { id: 458, first_name: "Donelle" },
                { id: 459, first_name: "Arnold" },
                { id: 460, first_name: "Jeanie" },
                { id: 461, first_name: "Danya" },
                { id: 462, first_name: "Whitney" },
                { id: 463, first_name: "Shelby" },
                { id: 464, first_name: "Shannen" },
                { id: 465, first_name: "Olvan" },
                { id: 466, first_name: "Marline" },
                { id: 467, first_name: "Vitoria" },
                { id: 468, first_name: "Gwendolyn" },
                { id: 469, first_name: "Rudd" },
                { id: 470, first_name: "Mathew" },
                { id: 471, first_name: "Fannie" },
                { id: 472, first_name: "Marleah" },
                { id: 473, first_name: "Titus" },
                { id: 474, first_name: "Shaylynn" },
                { id: 475, first_name: "Alfie" },
                { id: 476, first_name: "Darius" },
                { id: 477, first_name: "Nesta" },
                { id: 478, first_name: "Terrence" },
                { id: 479, first_name: "Waylin" },
                { id: 480, first_name: "Mariel" },
                { id: 481, first_name: "Codee" },
                { id: 482, first_name: "Joey" },
                { id: 483, first_name: "Carolann" },
                { id: 484, first_name: "Diana" },
                { id: 485, first_name: "Linnea" },
                { id: 486, first_name: "Bryant" },
                { id: 487, first_name: "Ardella" },
                { id: 488, first_name: "Elnar" },
                { id: 489, first_name: "Rhys" },
                { id: 490, first_name: "Nicole" },
                { id: 491, first_name: "Augy" },
                { id: 492, first_name: "Bartholomeus" },
                { id: 493, first_name: "Henryetta" },
                { id: 494, first_name: "Salvidor" },
                { id: 495, first_name: "Henrik" },
                { id: 496, first_name: "Tarah" },
                { id: 497, first_name: "Cazzie" },
                { id: 498, first_name: "Binni" },
                { id: 499, first_name: "Maje" },
                { id: 500, first_name: "Ashley" },
                { id: 501, first_name: "Willabella" },
                { id: 502, first_name: "Beverlee" },
                { id: 503, first_name: "Marthena" },
                { id: 504, first_name: "Izabel" },
                { id: 505, first_name: "Farah" },
                { id: 506, first_name: "Dennet" },
                { id: 507, first_name: "Cornie" },
                { id: 508, first_name: "Inga" },
                { id: 509, first_name: "Foss" },
                { id: 510, first_name: "Anallese" },
                { id: 511, first_name: "Dee" },
                { id: 512, first_name: "Bertie" },
                { id: 513, first_name: "Darren" },
                { id: 514, first_name: "Sinclair" },
                { id: 515, first_name: "Vasilis" },
                { id: 516, first_name: "Griffin" },
                { id: 517, first_name: "Alexandra" },
                { id: 518, first_name: "Alasdair" },
                { id: 519, first_name: "Stephannie" },
                { id: 520, first_name: "Silva" },
                { id: 521, first_name: "Frasco" },
                { id: 522, first_name: "Graham" },
                { id: 523, first_name: "Gratiana" },
                { id: 524, first_name: "Emilia" },
                { id: 525, first_name: "Lulita" },
                { id: 526, first_name: "Betsy" },
                { id: 527, first_name: "Karen" },
                { id: 528, first_name: "Elyn" },
                { id: 529, first_name: "Ellissa" },
                { id: 530, first_name: "Larina" },
                { id: 531, first_name: "Lindsay" },
                { id: 532, first_name: "Fifi" },
                { id: 533, first_name: "Ian" },
                { id: 534, first_name: "Meaghan" },
                { id: 535, first_name: "Boot" },
                { id: 536, first_name: "Lou" },
                { id: 537, first_name: "Frankie" },
                { id: 538, first_name: "Crin" },
                { id: 539, first_name: "Elenore" },
                { id: 540, first_name: "Ellen" },
                { id: 541, first_name: "Daisy" },
                { id: 542, first_name: "Ilsa" },
                { id: 543, first_name: "Aland" },
                { id: 544, first_name: "Rollie" },
                { id: 545, first_name: "Austine" },
                { id: 546, first_name: "Shoshanna" },
                { id: 547, first_name: "Gilbertina" },
                { id: 548, first_name: "Shoshanna" },
                { id: 549, first_name: "Ilsa" },
                { id: 550, first_name: "Win" },
                { id: 551, first_name: "Verena" },
                { id: 552, first_name: "Silvia" },
                { id: 553, first_name: "Frans" },
                { id: 554, first_name: "Charissa" },
                { id: 555, first_name: "Nonnah" },
                { id: 556, first_name: "Freddie" },
                { id: 557, first_name: "Philippa" },
                { id: 558, first_name: "Marilyn" },
                { id: 559, first_name: "Dionne" },
                { id: 560, first_name: "Silvain" },
                { id: 561, first_name: "Brietta" },
                { id: 562, first_name: "Nert" },
                { id: 563, first_name: "Rufe" },
                { id: 564, first_name: "Daryle" },
                { id: 565, first_name: "Basia" },
                { id: 566, first_name: "Glenda" },
                { id: 567, first_name: "Crysta" },
                { id: 568, first_name: "Issiah" },
                { id: 569, first_name: "Milty" },
                { id: 570, first_name: "Eleanore" },
                { id: 571, first_name: "Elnore" },
                { id: 572, first_name: "Lorene" },
                { id: 573, first_name: "Ado" },
                { id: 574, first_name: "Rhoda" },
                { id: 575, first_name: "Brunhilda" },
                { id: 576, first_name: "Peter" },
                { id: 577, first_name: "Bettine" },
                { id: 578, first_name: "Kissee" },
                { id: 579, first_name: "Aili" },
                { id: 580, first_name: "Dorita" },
                { id: 581, first_name: "Manya" },
                { id: 582, first_name: "Rachele" },
                { id: 583, first_name: "Nessi" },
                { id: 584, first_name: "Francisca" },
                { id: 585, first_name: "Candie" },
                { id: 586, first_name: "Clayson" },
                { id: 587, first_name: "Waiter" },
                { id: 588, first_name: "Felicity" },
                { id: 589, first_name: "Rubina" },
                { id: 590, first_name: "Elbertine" },
                { id: 591, first_name: "Shantee" },
                { id: 592, first_name: "Addie" },
                { id: 593, first_name: "Kathlin" },
                { id: 594, first_name: "Faydra" },
                { id: 595, first_name: "Alphonse" },
                { id: 596, first_name: "Alexandro" },
                { id: 597, first_name: "Albert" },
                { id: 598, first_name: "Quint" },
                { id: 599, first_name: "Kit" },
                { id: 600, first_name: "Cosimo" },
                { id: 601, first_name: "Megen" },
                { id: 602, first_name: "Stella" },
                { id: 603, first_name: "Gus" },
                { id: 604, first_name: "Lorri" },
                { id: 605, first_name: "Tess" },
                { id: 606, first_name: "Andriana" },
                { id: 607, first_name: "Rodrigo" },
                { id: 608, first_name: "Myra" },
                { id: 609, first_name: "Des" },
                { id: 610, first_name: "Issie" },
                { id: 611, first_name: "Delainey" },
                { id: 612, first_name: "Eldridge" },
                { id: 613, first_name: "Connie" },
                { id: 614, first_name: "Mychal" },
                { id: 615, first_name: "Kyle" },
                { id: 616, first_name: "Janina" },
                { id: 617, first_name: "Gloriana" },
                { id: 618, first_name: "Elysia" },
                { id: 619, first_name: "Ninnette" },
                { id: 620, first_name: "Gray" },
                { id: 621, first_name: "Lind" },
                { id: 622, first_name: "Margareta" },
                { id: 623, first_name: "Kaitlyn" },
                { id: 624, first_name: "Sonja" },
                { id: 625, first_name: "Gelya" },
                { id: 626, first_name: "Stevy" },
                { id: 627, first_name: "Walsh" },
                { id: 628, first_name: "Allegra" },
                { id: 629, first_name: "Cory" },
                { id: 630, first_name: "Udale" },
                { id: 631, first_name: "Mandel" },
                { id: 632, first_name: "Rickey" },
                { id: 633, first_name: "Anya" },
                { id: 634, first_name: "Mellisa" },
                { id: 635, first_name: "Riccardo" },
                { id: 636, first_name: "Eugenio" },
                { id: 637, first_name: "Reinald" },
                { id: 638, first_name: "Ellswerth" },
                { id: 639, first_name: "Jacobo" },
                { id: 640, first_name: "Brina" },
                { id: 641, first_name: "Corena" },
                { id: 642, first_name: "Edythe" },
                { id: 643, first_name: "Lynnet" },
                { id: 644, first_name: "Jodie" },
                { id: 645, first_name: "Packston" },
                { id: 646, first_name: "Rachel" },
                { id: 647, first_name: "Susanetta" },
                { id: 648, first_name: "Renault" },
                { id: 649, first_name: "Archibold" },
                { id: 650, first_name: "Germaine" },
                { id: 651, first_name: "Eleanore" },
                { id: 652, first_name: "Quill" },
                { id: 653, first_name: "Charmaine" },
                { id: 654, first_name: "Maurice" },
                { id: 655, first_name: "Fayth" },
                { id: 656, first_name: "Maureen" },
                { id: 657, first_name: "Kettie" },
                { id: 658, first_name: "Susie" },
                { id: 659, first_name: "Helaina" },
                { id: 660, first_name: "Francoise" },
                { id: 661, first_name: "Leandra" },
                { id: 662, first_name: "Debbi" },
                { id: 663, first_name: "Estevan" },
                { id: 664, first_name: "Catharine" },
                { id: 665, first_name: "Filmore" },
                { id: 666, first_name: "Warden" },
                { id: 667, first_name: "Ricca" },
                { id: 668, first_name: "Keary" },
                { id: 669, first_name: "Savina" },
                { id: 670, first_name: "Raviv" },
                { id: 671, first_name: "Lockwood" },
                { id: 672, first_name: "Anya" },
                { id: 673, first_name: "Henderson" },
                { id: 674, first_name: "Aileen" },
                { id: 675, first_name: "Dorry" },
                { id: 676, first_name: "Boyce" },
                { id: 677, first_name: "Trescha" },
                { id: 678, first_name: "Freddie" },
                { id: 679, first_name: "Zitella" },
                { id: 680, first_name: "Alla" },
                { id: 681, first_name: "Milton" },
                { id: 682, first_name: "Emmy" },
                { id: 683, first_name: "Elberta" },
                { id: 684, first_name: "Maisey" },
                { id: 685, first_name: "Honoria" },
                { id: 686, first_name: "Rodge" },
                { id: 687, first_name: "Sargent" },
                { id: 688, first_name: "April" },
                { id: 689, first_name: "Jacinthe" },
                { id: 690, first_name: "Spencer" },
                { id: 691, first_name: "Estella" },
                { id: 692, first_name: "Bennett" },
                { id: 693, first_name: "Ilka" },
                { id: 694, first_name: "Jeramey" },
                { id: 695, first_name: "Tarrah" },
                { id: 696, first_name: "Anabelle" },
                { id: 697, first_name: "Arden" },
                { id: 698, first_name: "Chet" },
                { id: 699, first_name: "Waylen" },
                { id: 700, first_name: "Aylmar" },
                { id: 701, first_name: "Adeline" },
                { id: 702, first_name: "Renata" },
                { id: 703, first_name: "Dalis" },
                { id: 704, first_name: "Cesaro" },
                { id: 705, first_name: "Morley" },
                { id: 706, first_name: "May" },
                { id: 707, first_name: "Carmine" },
                { id: 708, first_name: "Melina" },
                { id: 709, first_name: "Fabian" },
                { id: 710, first_name: "Kelli" },
                { id: 711, first_name: "Rufus" },
                { id: 712, first_name: "Cassey" },
                { id: 713, first_name: "Belle" },
                { id: 714, first_name: "Joanne" },
                { id: 715, first_name: "Denice" },
                { id: 716, first_name: "Orel" },
                { id: 717, first_name: "Willie" },
                { id: 718, first_name: "Fanya" },
                { id: 719, first_name: "Siusan" },
                { id: 720, first_name: "Lennie" },
                { id: 721, first_name: "Lin" },
                { id: 722, first_name: "Tybie" },
                { id: 723, first_name: "Lemuel" },
                { id: 724, first_name: "Adorne" },
                { id: 725, first_name: "Marten" },
                { id: 726, first_name: "Sigismondo" },
                { id: 727, first_name: "Brendin" },
                { id: 728, first_name: "Elfrieda" },
                { id: 729, first_name: "Talya" },
                { id: 730, first_name: "Phoebe" },
                { id: 731, first_name: "Niall" },
                { id: 732, first_name: "Milt" },
                { id: 733, first_name: "Ruddie" },
                { id: 734, first_name: "Arvy" },
                { id: 735, first_name: "Joseito" },
                { id: 736, first_name: "Olag" },
                { id: 737, first_name: "Jacenta" },
                { id: 738, first_name: "Adelaida" },
                { id: 739, first_name: "Mauricio" },
                { id: 740, first_name: "Lindon" },
                { id: 741, first_name: "Barnie" },
                { id: 742, first_name: "Shirley" },
                { id: 743, first_name: "Greta" },
                { id: 744, first_name: "Karlen" },
                { id: 745, first_name: "Ewen" },
                { id: 746, first_name: "Hodge" },
                { id: 747, first_name: "Dionysus" },
                { id: 748, first_name: "Bevan" },
                { id: 749, first_name: "Mame" },
                { id: 750, first_name: "Albina" },
                { id: 751, first_name: "Arlette" },
                { id: 752, first_name: "Curran" },
                { id: 753, first_name: "Oates" },
                { id: 754, first_name: "Bride" },
                { id: 755, first_name: "Larry" },
                { id: 756, first_name: "Aleda" },
                { id: 757, first_name: "Delinda" },
                { id: 758, first_name: "Ralph" },
                { id: 759, first_name: "Claudetta" },
                { id: 760, first_name: "Packston" },
                { id: 761, first_name: "Dyan" },
                { id: 762, first_name: "Giacobo" },
                { id: 763, first_name: "Holmes" },
                { id: 764, first_name: "Keely" },
                { id: 765, first_name: "Sly" },
                { id: 766, first_name: "Darbee" },
                { id: 767, first_name: "Hercule" },
                { id: 768, first_name: "Ozzy" },
                { id: 769, first_name: "Cornell" },
                { id: 770, first_name: "Shelia" },
                { id: 771, first_name: "Jada" },
                { id: 772, first_name: "Phaidra" },
                { id: 773, first_name: "Andras" },
                { id: 774, first_name: "Corabelle" },
                { id: 775, first_name: "Chrisse" },
                { id: 776, first_name: "Opal" },
                { id: 777, first_name: "Delaney" },
                { id: 778, first_name: "Delaney" },
                { id: 779, first_name: "Rose" },
                { id: 780, first_name: "Ivy" },
                { id: 781, first_name: "Tammy" },
                { id: 782, first_name: "Corrie" },
                { id: 783, first_name: "Osbert" },
                { id: 784, first_name: "Nydia" },
                { id: 785, first_name: "Daniella" },
                { id: 786, first_name: "Dorena" },
                { id: 787, first_name: "Diane" },
                { id: 788, first_name: "Harriot" },
                { id: 789, first_name: "Sisile" },
                { id: 790, first_name: "Jenilee" },
                { id: 791, first_name: "Allys" },
                { id: 792, first_name: "Galvan" },
                { id: 793, first_name: "Andrea" },
                { id: 794, first_name: "Ellissa" },
                { id: 795, first_name: "Rubina" },
                { id: 796, first_name: "Pia" },
                { id: 797, first_name: "Lynnette" },
                { id: 798, first_name: "Brigitta" },
                { id: 799, first_name: "Drucy" },
                { id: 800, first_name: "Kincaid" },
                { id: 801, first_name: "Farrell" },
                { id: 802, first_name: "Dore" },
                { id: 803, first_name: "Balduin" },
                { id: 804, first_name: "Homer" },
                { id: 805, first_name: "Duky" },
                { id: 806, first_name: "Zacharie" },
                { id: 807, first_name: "Margie" },
                { id: 808, first_name: "Carmelita" },
                { id: 809, first_name: "Daisie" },
                { id: 810, first_name: "Klara" },
                { id: 811, first_name: "Budd" },
                { id: 812, first_name: "Paco" },
                { id: 813, first_name: "Sophi" },
                { id: 814, first_name: "Ravid" },
                { id: 815, first_name: "Tristan" },
                { id: 816, first_name: "Franni" },
                { id: 817, first_name: "Sibel" },
                { id: 818, first_name: "Tobit" },
                { id: 819, first_name: "Kinna" },
                { id: 820, first_name: "Claudio" },
                { id: 821, first_name: "Augustina" },
                { id: 822, first_name: "Prinz" },
                { id: 823, first_name: "Free" },
                { id: 824, first_name: "Nannette" },
                { id: 825, first_name: "Somerset" },
                { id: 826, first_name: "Everard" },
                { id: 827, first_name: "Othello" },
                { id: 828, first_name: "Dorree" },
                { id: 829, first_name: "Gordon" },
                { id: 830, first_name: "Udall" },
                { id: 831, first_name: "Fanchon" },
                { id: 832, first_name: "Valery" },
                { id: 833, first_name: "Edith" },
                { id: 834, first_name: "Fenelia" },
                { id: 835, first_name: "Cathrine" },
                { id: 836, first_name: "Callida" },
                { id: 837, first_name: "Brittney" },
                { id: 838, first_name: "Prentiss" },
                { id: 839, first_name: "Pip" },
                { id: 840, first_name: "Vaclav" },
                { id: 841, first_name: "Melisent" },
                { id: 842, first_name: "Billi" },
                { id: 843, first_name: "Tiebout" },
                { id: 844, first_name: "Porty" },
                { id: 845, first_name: "Harman" },
                { id: 846, first_name: "Layla" },
                { id: 847, first_name: "Trenna" },
                { id: 848, first_name: "Quincey" },
                { id: 849, first_name: "Cammie" },
                { id: 850, first_name: "Zorah" },
                { id: 851, first_name: "Keen" },
                { id: 852, first_name: "Elsie" },
                { id: 853, first_name: "Vivianna" },
                { id: 854, first_name: "Clotilda" },
                { id: 855, first_name: "Meredith" },
                { id: 856, first_name: "Danyette" },
                { id: 857, first_name: "Joann" },
                { id: 858, first_name: "Claribel" },
                { id: 859, first_name: "Marco" },
                { id: 860, first_name: "Laura" },
                { id: 861, first_name: "Vilma" },
                { id: 862, first_name: "Merlina" },
                { id: 863, first_name: "Alane" },
                { id: 864, first_name: "Benedicto" },
                { id: 865, first_name: "Adan" },
                { id: 866, first_name: "Estevan" },
                { id: 867, first_name: "Dorthy" },
                { id: 868, first_name: "Kellby" },
                { id: 869, first_name: "Gonzalo" },
                { id: 870, first_name: "Gaven" },
                { id: 871, first_name: "Winne" },
                { id: 872, first_name: "Price" },
                { id: 873, first_name: "Chandler" },
                { id: 874, first_name: "Xymenes" },
                { id: 875, first_name: "Ephrem" },
                { id: 876, first_name: "Sigismond" },
                { id: 877, first_name: "Valina" },
                { id: 878, first_name: "Frederich" },
                { id: 879, first_name: "Gussy" },
                { id: 880, first_name: "Valentina" },
                { id: 881, first_name: "Tracie" },
                { id: 882, first_name: "Moishe" },
                { id: 883, first_name: "Aldis" },
                { id: 884, first_name: "Miles" },
                { id: 885, first_name: "Benito" },
                { id: 886, first_name: "Chuck" },
                { id: 887, first_name: "Philippine" },
                { id: 888, first_name: "Wally" },
                { id: 889, first_name: "Carmelia" },
                { id: 890, first_name: "Caryn" },
                { id: 891, first_name: "Shaylah" },
                { id: 892, first_name: "Thorpe" },
                { id: 893, first_name: "Waite" },
                { id: 894, first_name: "Benedict" },
                { id: 895, first_name: "Carmita" },
                { id: 896, first_name: "Elspeth" },
                { id: 897, first_name: "Massimiliano" },
                { id: 898, first_name: "Finlay" },
                { id: 899, first_name: "Wilmer" },
                { id: 900, first_name: "Berkly" },
                { id: 901, first_name: "Sharon" },
                { id: 902, first_name: "Lilia" },
                { id: 903, first_name: "Trueman" },
                { id: 904, first_name: "Pegeen" },
                { id: 905, first_name: "Trevar" },
                { id: 906, first_name: "Shanon" },
                { id: 907, first_name: "Nanni" },
                { id: 908, first_name: "Ingrid" },
                { id: 909, first_name: "Jo ann" },
                { id: 910, first_name: "Clarinda" },
                { id: 911, first_name: "Else" },
                { id: 912, first_name: "Wenona" },
                { id: 913, first_name: "Dorothee" },
                { id: 914, first_name: "Sherline" },
                { id: 915, first_name: "Adolpho" },
                { id: 916, first_name: "Loree" },
                { id: 917, first_name: "Cori" },
                { id: 918, first_name: "Grenville" },
                { id: 919, first_name: "Laurie" },
                { id: 920, first_name: "Denyse" },
                { id: 921, first_name: "Minne" },
                { id: 922, first_name: "Emalia" },
                { id: 923, first_name: "Clayson" },
                { id: 924, first_name: "Franz" },
                { id: 925, first_name: "Jonah" },
                { id: 926, first_name: "Vilma" },
                { id: 927, first_name: "Trueman" },
                { id: 928, first_name: "Natty" },
                { id: 929, first_name: "Nichols" },
                { id: 930, first_name: "Jewell" },
                { id: 931, first_name: "Yul" },
                { id: 932, first_name: "Jennica" },
                { id: 933, first_name: "Forster" },
                { id: 934, first_name: "Katina" },
                { id: 935, first_name: "Felice" },
                { id: 936, first_name: "Nettle" },
                { id: 937, first_name: "Marthena" },
                { id: 938, first_name: "Phaedra" },
                { id: 939, first_name: "Rosamund" },
                { id: 940, first_name: "Lynette" },
                { id: 941, first_name: "Kitti" },
                { id: 942, first_name: "Donni" },
                { id: 943, first_name: "Ellen" },
                { id: 944, first_name: "Jessie" },
                { id: 945, first_name: "Lynne" },
                { id: 946, first_name: "Craggy" },
                { id: 947, first_name: "Diane-marie" },
                { id: 948, first_name: "Ulberto" },
                { id: 949, first_name: "Clare" },
                { id: 950, first_name: "Westbrook" },
                { id: 951, first_name: "Helenka" },
                { id: 952, first_name: "Pennie" },
                { id: 953, first_name: "Shena" },
                { id: 954, first_name: "Dyane" },
                { id: 955, first_name: "Quinlan" },
                { id: 956, first_name: "Carmita" },
                { id: 957, first_name: "Eddy" },
                { id: 958, first_name: "Chevy" },
                { id: 959, first_name: "Beulah" },
                { id: 960, first_name: "Broderic" },
                { id: 961, first_name: "Pepito" },
                { id: 962, first_name: "Octavia" },
                { id: 963, first_name: "Raphaela" },
                { id: 964, first_name: "Dominica" },
                { id: 965, first_name: "Oralee" },
                { id: 966, first_name: "Marillin" },
                { id: 967, first_name: "Julietta" },
                { id: 968, first_name: "Eveleen" },
                { id: 969, first_name: "Tawsha" },
                { id: 970, first_name: "Valma" },
                { id: 971, first_name: "Carry" },
                { id: 972, first_name: "Padriac" },
                { id: 973, first_name: "Jonell" },
                { id: 974, first_name: "Lalo" },
                { id: 975, first_name: "Allianora" },
                { id: 976, first_name: "Tomasina" },
                { id: 977, first_name: "Otho" },
                { id: 978, first_name: "Maryann" },
                { id: 979, first_name: "Lacee" },
                { id: 980, first_name: "Dennis" },
                { id: 981, first_name: "Welsh" },
                { id: 982, first_name: "Prudence" },
                { id: 983, first_name: "Carly" },
                { id: 984, first_name: "Sergeant" },
                { id: 985, first_name: "Bridgette" },
                { id: 986, first_name: "Norene" },
                { id: 987, first_name: "Egon" },
                { id: 988, first_name: "Hugo" },
                { id: 989, first_name: "Bren" },
                { id: 990, first_name: "Brandy" },
                { id: 991, first_name: "Adrienne" },
                { id: 992, first_name: "Drusilla" },
                { id: 993, first_name: "Roxane" },
                { id: 994, first_name: "Darb" },
                { id: 995, first_name: "Gussie" },
                { id: 996, first_name: "Nelle" },
                { id: 997, first_name: "Bertie" },
                { id: 998, first_name: "Riva" },
                { id: 999, first_name: "Felicdad" },
                { id: 1000, first_name: "Gav" },
            ],
            fields: [
                {
                    label: "Nominativo",
                    key: "first_name",
                    sortable: true,
                },
            ],
            currentPage: 1,
            perPage: 100,
        };
    },
    methods: {
        onReset() {},
        onRefresh() {
            let me = this;
            me.showModalLoading = true;
        },
    },
};
</script>

<style></style>
