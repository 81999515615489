<template>
    <sa-page-layout :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnBackVisible="false" :linkback="linkback" :btnAnnullaVisible="true" :pathResource="pathResource" :data="jsonData" class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica @getEtaPaziente="getEtaPaziente" @getAnagraficaPaziente="getAnagraficaPaziente" :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="toolbar">
            <firma-component :idEvento="jsonData.id" :data="jsonData" :pathRestFirma="pathRestFirma" :fieldName="firmaFieldName" :pathRestValidation="pathRestValidation" :disabled="btnFirmaDisabled" @update="onFirmaUpdate"></firma-component>
        </template>
        <template slot="table-header">
            <h3 class="sa-event-title">{{ this.$i18n.t("patient.afa.lblVisit") }}</h3>
        </template>
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill @input="onTabInput">
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblSportsMedicalEvaluationForm") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <afa-scheda-valutazione-medico-sportiva :data="jsonData" :gruppo="gruppo" :tipoEvento="tipoEvento" :edit="disabilitaSchede()"></afa-scheda-valutazione-medico-sportiva>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblAnamnesis") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <afa-anamnesi :data="jsonData" :edit="disabilitaSchede()"></afa-anamnesi>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div :class="getColorByFirma(jsonData.firmaEo)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.firmaEo)"></b-icon>
                            </div>
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <afa-esame-obiettivo @loadMisure="loadMisure" :gruppo="gruppo" :tipoEvento="tipoEvento" :data="jsonData" :edit="disabilitaSchedaEsameObiettivo()"></afa-esame-obiettivo>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div :class="getColorByFirma(jsonData.firmaEcg)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.firmaEcg)"></b-icon>
                            </div>
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <afa-elettrocardiogramma @loadMisure="loadMisure" :gruppo="gruppo" :tipoEvento="tipoEvento" :data="jsonData" :edit="disabilitaSchedaEcg()"></afa-elettrocardiogramma>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblAnthropometrics") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <afa-antropometrici @loadMisure="loadMisure" :gruppo="gruppo" :tipoEvento="tipoEvento" :data="jsonData" :edit="disabilitaSchede()"></afa-antropometrici>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblFitness") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <afa-fitness @loadMisure="loadMisure" :gruppo="gruppo" :tipoEvento="tipoEvento" :data="jsonData" :edit="disabilitaSchede()"></afa-fitness>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>Terapia</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <paziente-terapia-edit-component></paziente-terapia-edit-component>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div :class="getColorByFirma(jsonData.firmaGf)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.firmaGf)"></b-icon>
                            </div>
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <afa-giudizio-finale :data="jsonData" :edit="disabilitaSchede()"></afa-giudizio-finale>
                    </div>
                </b-tab>
            </b-tabs>
            <!-- <b-modal ref="mdlModificaAnagraficaAccettazione" id="modalMisurazioni" size="xl" style="height: 100%" ok-only>
                <template #modal-title>
                    {{ lblRicercaPerMisura }}
                    {{ nomeModalMisura.toUpperCase() }}</template
                >
                <div>
                    <b-row>
                        <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ getLabelTraduora("agenda.lblDateFrom") }}</span>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="ricercaMisureRange.dataDal" @input="loadMisure(nomeModalMisura)" type="date" :placeholder="this.getLabelTraduora('agenda.lblDateFrom')"></date-picker>
                        </b-col>
                        <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6">
                            <span class="sa-form-label">{{ getLabelTraduora("agenda.lblDateEnd") }}</span>
                            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="ricercaMisureRange.dataAl" @input="loadMisure(nomeModalMisura)" type="date" placeholder="Data Al"></date-picker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-table sticky-header ref="tableActive" stacked="xl" striped hover :items="misure" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table mt-2"> </b-table>
                    </b-row>
                </div>
            </b-modal> -->
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
// import DatePicker from "vue2-datepicker";
// import "vue2-datepicker/index.css";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import PazienteTerapiaEditComponent from "../../../../terapia/components/PazienteTerapiaEditComponent.vue";
import AfaSchedaValutazioneMedicoSportiva from "./AfaSchedaValutazioneMedicoSportiva";
import AfaAnamnesi from "./AfaAnamnesi";
import AfaEsameObiettivo from "./AfaEsameObiettivo";
import AfaElettrocardiogramma from "./AfaElettrocardiogramma";
import AfaAntropometrici from "./AfaAntropometrici";
import AfaFitness from "./AfaFitness";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import AfaGiudizioFinale from "./AfaGiudizioFinale";
import FirmaComponent from "../../../utility/components/FirmaComponent.vue";
import moment from "moment";
import EventiMixins from "../../../utility/mixins/EventiMixins";
export default {
    mixins: [UtilityMixin, EventiMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: {
        // DatePicker,
        SaPageLayout,
        PazienteAnagrafica,
        PazienteTerapiaEditComponent,
        AfaSchedaValutazioneMedicoSportiva,
        AfaAnamnesi,
        AfaEsameObiettivo,
        AfaElettrocardiogramma,
        AfaAntropometrici,
        AfaFitness,
        AfaGiudizioFinale,
        FirmaComponent,
    },
    computed: {
        idPaziente: {
            get: function () {
                return this.$route.query.idPaziente;
            },
            set: function () {},
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    data() {
        return {
            id: null,
            tipoEvento: "MEDICINADELLOSPORTAFA",
            gruppo: "MEDICINADELLOSPORT",
            ricercaMisureRange: {
                dataDal: new Date().getTime(),
                dataAl: new Date().getTime(),
            },
            currentPage: 1,
            perPage: 10,
            titleGiudizioFinale: this.$i18n.t("patient.afa.lblFinalJudgement"),
            jsonData: {
                /*SchedaValutazioneSportiva*/
                anamnesiFamiliare: "",
                anamnesiFisiologica: "",
                anamnesiPatologica: "",
                anamnesiSportiva: "",
                infortuni: "",
                interventiChirurgici: "",
                numeroAnni: null,
                dataEvento: new Date().getTime(),
                antitetanica: null,
                fumo: null,
                alcool: null,
                caffe: null,
                sostenzeStupefacenti: null,
                sportPraticato: null,
                diabete: null,
                colesterolo: null,
                militare: null,
                documentoIdentitaTipo: null,
                allenamentiSettimana: 0,
                oreAllenamento: 0,
                /*----*/
                firmaEo: null,
                firmaEcg: null,
                firmaGf: null,
            },
            linkback: "",
            edit: true,
            etaPaziente: null,
            objAnagraficaPaziente: {},
            gruppoSanguignoPaziente: null,
            showModalLoading: false,
            pathResource: "/medicinadellosportafa",
            pathResourceMisurazioni: "/medicinadellosportafa/listamisure",
            pathRestFirma: null,
            pathRestValidation: "/validation",
            btnFirmaDisabled: false,
            firmaFieldName: null,
            misure: [],
            editEo: true,
            lblRicercaPerMisura: this.$i18n.t("patient.afa.lblMisurFor"),
            nomeModalMisura: "",
            editEcg: true,
            fields: [
                {
                    label: this.$i18n.t("patient.afa.lblMisureValue"),
                    key: "valoreMisura",
                    thStyle: "width: 15rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patient.events.lblEventDate"),
                    key: "dataOraEvento",
                    thStyle: "width: 15rem",
                    sortable: true,
                    formatter: (value) => {
                        return value ? moment(new Date(value)).format("DD-MM-YYYY hh:mm") : "";
                    },
                },
            ],
        };
    },
    created: function () {},
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.numeroAnni = me.etaPaziente;
        me.jsonData.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
        if (me.id !== "-1") {
            me.linkback = "/paziente/medicinadellosportafa/view/" + me.id;
        } else {
            me.linkback = "/paziente/medicinadellosportafa";
            if (sessionStorage.getItem("objVisita") !== null) {
                me.jsonData = JSON.parse(sessionStorage.getItem("objVisita"));
                delete me.jsonData.id;
            }
            me.loadLastEvent();
        }
        sessionStorage.removeItem("objVisita");
        me.loadData();
    },
    methods: {
        loadLastEvent() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + "/pazienteeventi/lastevent" + "?idPaziente=" + me.idPaziente + "&gruppo=MEDICINADELLOSPORT&tipoEvento=MEDICINADELLOSPORTAFA";
            axios
                .get(link)
                .then((response) => {
                    me.showmolalloading = false;

                    me.jsonData.anamnesiFamiliare = response.data.data.anamnesiFamiliare;
                    me.jsonData.anamnesiFisiologica = response.data.data.anamnesiFisiologica;
                    me.jsonData.anamnesiPatologica = response.data.data.anamnesiPatologica;
                    me.jsonData.anamnesiSportiva = response.data.data.anamnesiSportiva;
                    me.jsonData.infortuni = response.data.data.infortuni;
                    me.jsonData.interventiChirurgici = response.data.data.interventiChirurgici;
                    // console.log(response.data.data);
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        disabilitaSchede() {
            let me = this;
            let returnValue = false;
            if (!me.jsonData.firmaGf) {
                returnValue = true;
            }
            return returnValue;
        },
        disabilitaSchedaEsameObiettivo() {
            let me = this;
            let returnValue = false;
            if (me.disabilitaSchede()) {
                if (!me.jsonData.firmaEo) {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        disabilitaSchedaEcg() {
            let me = this;
            let returnValue = false;
            if (me.disabilitaSchede()) {
                if (!me.jsonData.firmaEcg) {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        getAnagraficaPaziente(value) {
            let me = this;
            me.gruppoSanguignoPaziente = value.gruppoSanguigno;
        },
        loadMisure(nomeMisura) {
            let me = this;
            me.nomeModalMisura = nomeMisura;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMisurazioni + "?nomeMisura=" + nomeMisura + "&idPaziente=" + me.idPaziente + "&dataDal=" + me.ricercaMisureRange.dataDal + "&dataAl=" + me.ricercaMisureRange.dataAl;
            axios
                .get(link)
                .then((response) => {
                    me.misure = response.data.data;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        getEtaPaziente(value) {
            let me = this;
            me.etaPaziente = value;
        },
        onSave() {
            console.log("save");
        },

        loadData() {
            let me = this;
            me.jsonData.idPaziente = me.idPaziente;
            if (me.id !== "-1") {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.idPaziente = me.jsonData.idPaziente;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
        onTabInput(index) {
            let me = this;
            // console.log("***");
            // console.log(me.jsonData.firmaGf);
            if (me.jsonData.firmaGf !== null) {
                me.btnFirmaDisabled = true;
                me.edit = false;
                me.editEo = false;
                me.editEcg = false;
            } else {
                console.log("OK Uno");
                switch (index) {
                    case 0:
                        me.btnFirmaDisabled = true;
                        me.firmaFieldName = null;
                        break;
                    case 1:
                        me.btnFirmaDisabled = true;
                        me.firmaFieldName = null;
                        break;
                    case 2:
                        me.firmaFieldName = "firma_eo";
                        me.pathRestFirma = "/medicinadellosportafa";
                        if (me.jsonData.firmaEo == null) {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.editEo = false;
                        }
                        break;
                    case 3:
                        me.firmaFieldName = "firma_ecg";
                        me.pathRestFirma = "/medicinadellosportafa";
                        if (me.jsonData.firmaEcg == null) {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.editEcg = false;
                        }
                        break;
                    case 4:
                        me.btnFirmaDisabled = true;
                        me.firmaFieldName = null;
                        break;
                    case 5:
                        me.btnFirmaDisabled = true;
                        me.firmaFieldName = null;
                        break;
                    case 6:
                        me.firmaFieldName = "firma_gf";
                        me.pathRestFirma = "/medicinadellosportafa";
                        if (me.jsonData.firmaGf == null) {
                            me.btnFirmaDisabled = false;
                        } else {
                            me.btnFirmaDisabled = true;
                            // me.edit = false;
                        }
                        break;
                }
            }
        },
        // onTabInput(index) {
        //   let me = this;
        //   if (me.jsonData.firmaGf !== null) {
        //     me.btnFirmaDisabled = true;
        //     me.edit = false;
        //     me.editEo = false;
        //     me.editEcg = false;
        //   } else {
        //     switch (index) {
        //       case 0:
        //         me.btnFirmaDisabled = true;
        //         break;
        //       case 1:
        //         me.btnFirmaDisabled = true;
        //         break;
        //       case 2:
        //         me.firmaFieldName = "firma_eo";
        //         me.pathRestFirma = "/medicinadellosportafa";
        //         if (me.jsonData.firmaEo == null) {
        //           me.btnFirmaDisabled = false;
        //         } else {
        //           me.btnFirmaDisabled = true;
        //           me.editEo = false;
        //         }
        //         break;
        //       case 3:
        //         me.firmaFieldName = "firma_ecg";
        //         console.log(me.firmaFieldName);
        //         me.pathRestFirma = "/medicinadellosportafa";
        //         if (me.jsonData.firmaEcg == null) {
        //           me.btnFirmaDisabled = false;
        //         } else {
        //           me.btnFirmaDisabled = true;
        //           me.editEcg = false;
        //         }
        //         break;
        //       case 4:
        //         me.btnFirmaDisabled = true;
        //         break;
        //       case 5:
        //         me.btnFirmaDisabled = true;
        //         break;
        //       case 6:
        //         me.btnFirmaDisabled = true;
        //         break;
        //       case 7:
        //         me.firmaFieldName = "firma_gf";
        //         me.pathRestFirma = "/medicinadellosportafa";
        //         if (me.jsonData.firmaGf == null) {
        //           me.btnFirmaDisabled = false;
        //         } else {
        //           me.btnFirmaDisabled = true;
        //           // me.edit = false;
        //         }
        //         break;
        //     }
        //   }
        // },

        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
    watch: {
        etaPaziente: function (eta) {
            let me = this;
            me.jsonData.numeroAnni = eta;
        },
        gruppoSanguignoPaziente: function (gruppoSanguigno) {
            let me = this;
            me.jsonData.gruppoSanguigno = gruppoSanguigno;
        },
    },
};
</script>
