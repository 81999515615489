<template>
    <sa-page-layout :showModalLoading="showModalLoading" class="sa-no-space" :navLeftVisible="true">
        <template slot="toolbar-left">
            <paziente-eventi-menu-component :idPaziente="idPaziente"></paziente-eventi-menu-component>
        </template>
        <template slot="table-body">
            <paziente-scheda :utente="utente" :messageDanger="collegamentoWt" :key="idPaziente" :idPaziente="idPaziente"></paziente-scheda>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import PazienteScheda from "../../utility/PazienteSchedaComponent.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteEventiMenuComponent from "../../eventi/base/components/PazienteEventiMenuComponent.vue";
export default {
    props: {
        utente: {
            required: true,
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { PazienteScheda, SaPageLayout, PazienteEventiMenuComponent },
    data() {
        return {
            showModalLoading: false,
            messageDanger: false,
            collegamentoWt: false,
            idPaziente: null, //"0d3d6418-1105-4085-b622-b5b0bee0aa3d",
            eventiMenu: [],
            menuKey: 1,
        };
    },
    created() {
        let me = this;
        me.idPaziente = me.$route.params.id;
    },
    mounted() {
        let me = this;
        // me.loadEventiMenu();
        me.loadUserData();
    },
    methods: {
        loadUserData() {
            let me = this;

            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + "/users/profile";
            axios
                .get(link)
                .then((response) => {
                    let utente = response.data.data;
                    this.$user = utente;
                    me.nominativoUtente = utente.lastname + " " + utente.firstname;
                    me.ruolo = utente.profilo;
                    me.menu = response.data.data.gruppo.menuGruppo;
                    me.showModalLoading = false;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },

        /*loadPatientMenu() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazientemenu";
            axios
                .get(link, { params: { tipo: "left" } })
                .then((response) => {
                    me.menu = response.data.data;
                })
                .catch((e) => {
                    this.errors.push(e);
                });
        },*/
    },
};
</script>
