<template>
    <div class="sa-tab-scheda">
        <b-card class="sa-card" header="Esame Obiettivo" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Trofismo</label>
                    <span class="sa-data">{{ data.trofismo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Altezza(cm)</label>
                    <span class="sa-data">{{ data.altezza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Peso(kg)</label>
                    <span class="sa-data">{{ data.peso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">B.M.I.</label>
                    <span class="sa-data">{{ data.bmi }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">Apparato Locomotore</label>
                    <span class="sa-data">{{ data.apparatoLocomotore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">Torace</label>
                    <span class="sa-data">{{ data.torace }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">Apparato Respiratorio</label>
                    <span class="sa-data">{{ data.apparatoRespiratorio }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">Apparato Cardiocircolatorio</label>
                    <span class="sa-data">{{ data.apparatoCardiocircolatorio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">P.A. Riposo</label>
                    <span class="sa-data">{{ data.pressioneArteriosaRiposoSistolica }} / {{ data.pressioneArteriosaRiposoDiastolica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">Addome</label>
                    <span class="sa-data">{{ data.addome }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">Organi Genitali</label>
                    <span class="sa-data">{{ data.organiGenitali }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">Arti</label>
                    <span class="sa-data">{{ data.arti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">Acuità visiva naturale</label>
                    <span class="sa-data">O.D. {{ data.acuitaVisivaNaturaleOcchioDestro }}/10 O.S. {{ data.acuitaVisivaNaturaleOcchioSinistro }}/10</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">Corretta</label>
                    <span class="sa-data">O.D. {{ data.correttaOcchioDestro }}/10 O.S. {{ data.correttaOcchioSinistro }}/10</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">Senso Cromatico</label>
                    <span class="sa-data">{{ data.sensoCromatico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">Obbligo Lenti Correttive</label>
                    <b-icon v-if="data.obbligoLentiCorrettive" class="sa-size-icon-15" icon="check2"></b-icon>
                    <b-icon v-else icon="exclamation-triangle" variant="danger"></b-icon>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">Udito</label>
                    <span class="sa-data">{{ data.udito }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">Conclusioni esame obiettivo</label>
                    <span class="sa-data">{{ data.conclusioniEsameObiettivo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">Note su scheda di valutazione</label>
                    <span class="sa-data">{{ data.noteSuSchedaDiValutazione }}</span>
                </b-col>
            </b-row>
        </b-card>
        <div class="sa-tab-scheda-row-fill" header="" header-tag="header" footer-tag="footer" title="">
            <b-card class="sa-card" header="FIRMA" header-tag="header" footer-tag="footer" title="">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <span class="sa-data">{{ data.firma }}</span>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    trofismo: "",
                    peso: null,
                    altezza: null,
                    apparatoLocomotore: "",
                    torace: "",
                    apparatoRespiratorio: "",
                    apparatoCardiocircolatorio: "",
                    pressioneArteriosaRiposoSistolica: null,
                    pressioneArteriosaRiposoDiastolica: null,
                    addome: "",
                    organiGenitali: "",
                    arti: "",
                    acuitaVisivaNaturaleOcchioDestro: null,
                    acuitaVisivaNaturaleOcchioSinistro: null,
                    correttaOcchioDestro: null,
                    correttaOcchioSinistro: null,
                    sensoCromatico: "",
                    obbligoLentiCorrettive: null,
                    udito: "",
                    conclusioniEsameObiettivo: "",
                    noteSuSchedaDiValutazione: "",
                    firma: "",
                    bmi: "",
                };
            },
        },
    },
};
</script>
