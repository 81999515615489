<template>
    <div>
        <b-alert variant="danger" :show="alertShow">{{ alertMessage }}</b-alert>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                <label class="sa-label-data">Tipo:</label>
                <b-form-select v-model="jsonData.typeJoin" :options="joinsOptions" value-field="value" text-field="text"></b-form-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                <label class="sa-label-data">Tabella di Sinistra:</label>
                <b-form-select v-model="mainTable" :options="tablesOptions" value-field="dbName" text-field="viewName" @change="onChangeTableLeft(jsonData.tableLeft)" disabled></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                <label class="sa-label-data">Campo di Sinistra:</label>
                <b-form-select v-model="jsonData.fieldLeft" :options="leftFieldsOptions" value-field="fieldName" text-field="fieldName"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                <label class="sa-label-data">Tabella di Sinistra:</label>
                <b-form-select v-model="jsonData.tableRight" :options="joinTablesOptions" value-field="dbName" text-field="viewName" @change="onChangeTableRight"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                <label class="sa-label-data">Campo di Destra:</label>
                <b-form-select v-model="jsonData.fieldRight" :options="rightFieldsOptions" value-field="fieldName" text-field="fieldName"></b-form-select>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Join:</label>
                <span class="sa-data-analysis-join-edit-join">{{ join }}</span>
            </b-col>
        </b-row>
    </div>
</template>

<script>
export default {
    props: {
        mainTable: { type: String, required: true },
        tablesOptions: { type: Array, required: true },
    },

    data() {
        return {
            alertShow: false,
            alertMessage: "",
            jsonData: { typeJoin: null, tableLeft: null, fieldLeft: null, tableRight: null, fieldRight: null },
            leftFieldsOptions: [],
            rightFieldsOptions: [],
            joinTablesOptions: [],
            joinsOptions: [
                { value: null, text: "- Seleziona Tipo -" },
                { value: "FULL JOIN", text: "FULL JOIN" },
                { value: "INNER JOIN", text: "INNER JOIN" },
                { value: "LEFT JOIN", text: "LEFT JOIN" },
                { value: "RIGHT JOIN", text: "RIGHT JOIN" },
            ],
        };
    },
    computed: {
        join: {
            get: function () {
                return this.jsonData.typeJoin + " " + this.jsonData.tableRight + " ON " + this.jsonData.tableLeft + "." + this.jsonData.fieldLeft + " = " + this.jsonData.tableRight + "." + this.jsonData.fieldRight;
            },
        },
    },
    mounted() {
        let me = this;
        this.tablesOptions.forEach((element) => {
            if (element.dbName !== this.mainTable) {
                me.joinTablesOptions.push(element);
            }
        });
        this.jsonData.tableLeft = this.mainTable;
        me.setLeftFieldsOptions(this.mainTable);
    },
    methods: {
        onChangeTableLeft() {
            let me = this;
            me.setLeftFieldsOptions();
        },
        onChangeTableRight() {
            let me = this;
            //me.setRightFieldsOptions();
            this.$store.dispatch("dataanalysisTablesStore/setTableFields", { tableName: me.jsonData.tableRight }, { root: true }).then(() => {
                me.setRightFieldsOptions();
            });
        },
        setLeftFieldsOptions(value) {
            let me = this;
            me.tablesOptions.forEach((element) => {
                if (element.dbName === value) {
                    me.leftFieldsOptions = element.tableFields;
                }
            });
        },
        setRightFieldsOptions() {
            let me = this;
            me.tablesOptions.forEach((element) => {
                if (element.dbName === me.jsonData.tableRight) {
                    me.rightFieldsOptions = element.tableFields;
                }
            });
        },
        getJoin() {
            return this.jsonData;
        },
        isValid() {
            //{ type: null, tableLeft: null, fieldLeft: null, tableRight: null, fieldRight: null },
            if (this.jsonData.typeJoin === null) {
                this.alertShow = true;
                this.alertMessage = "Selezionare il Tipo di Join";
            } else if (this.jsonData.tableLeft === null) {
                this.alertShow = true;
                this.alertMessage = "Selezionare la Tabella di Sinistra";
            } else if (this.jsonData.fieldLeft === null) {
                this.alertShow = true;
                this.alertMessage = "Selezionare il Campo di Sinistra";
            } else if (this.jsonData.tableRight === null) {
                this.alertShow = true;
                this.alertMessage = "Selezionare la Tabella di Destra";
            } else if (this.jsonData.fieldRight === null) {
                this.alertShow = true;
                this.alertMessage = "Selezionare il Campo di Destra";
            } else {
                this.alertShow = false;
                this.alertMessage = "";
            }
            return !this.alertShow;
        },
    },
};
</script>

<style></style>
