<template>
  <div>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Modalita':</label>
          <b-form-select v-model="jsonData.modalita" :options="modalitaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Firma:</label>
          <b-form-select v-model="jsonData.tipoFirma" :options="tipoFirmaOptions" :value="null" value-field="value" text-field="text" @change="onAbilitaRiquadroFirma"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Marca:</label>
          <b-form-select v-model="jsonData.marca" :options="marcaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Alias:</label>
          <b-form-input v-model="jsonData.alias"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">PIN:</label>
          <b-form-input v-model="jsonData.pin"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">OTP:</label>
          <b-form-input v-model="jsonData.otp"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">File da Firmare:</label>
          <b-form-file v-model="file1" :state="Boolean(file1)" :placeholder="this.$i18n.t('global.dragDrop')" ref="file-input" class="custom-input" type="file" accept=".pdf"></b-form-file>
          <div class="mt-3">{{ this.$i18n.t("patient.attachments.fileSelected") }} {{ file1 ? file1.name : "" }}</div>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Riquadro Firma" header-tag="header" footer-tag="footer" title="" v-if="!disableRiquadroFirma">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Pagina Riquadro Firma:</label>
          <b-form-input v-model="jsonData.paginaRiquadroFirma"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Formato Pagina:</label>
          <b-form-select v-model="jsonData.formatoPagina" :options="formatoPaginaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Pagina Riquadro Firma:</label>
          <b-form-input v-model="jsonData.paginaRiquadroFirma"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Larghezza Riquadro Firma (mm):</label>
          <b-form-input v-model="jsonData.larghezzaRiquadroFirma"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altezza Riquadro Firma (mm):</label>
          <b-form-input v-model="jsonData.altezzaRiquadroFirma"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Immagune Signature:</label>
          <b-form-file v-model="image" :state="Boolean(image)" :placeholder="this.$i18n.t('global.dragDrop')" ref="file-input" class="custom-input" type="file"></b-form-file>
          <div class="mt-3">{{ this.$i18n.t("patient.attachments.fileSelected") }} {{ image ? image.name : "" }}</div>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Reason:</label>
          <b-form-textarea v-model="jsonData.reason" no-resize max-rows="3"></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Marcatura" header-tag="header" footer-tag="footer" title="" v-if="jsonData.marca === 'SI'">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Nome:</label>
          <b-form-input v-model="jsonData.nomeMarca"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Cognome:</label>
          <b-form-input v-model="jsonData.cognomeMarca"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <!-- <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Alias:</label>
          <b-form-input v-model="jsonData.alias"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">PIN:</label>
          <b-form-input v-model="jsonData.pin"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">OTP:</label>
          <b-form-input v-model="jsonData.otp"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">File da Firmare:</label>
          <b-form-file v-model="file1" :state="Boolean(file1)" :placeholder="this.$i18n.t('global.dragDrop')" ref="file-input" class="custom-input" type="file" accept=".pdf"></b-form-file>
          <div class="mt-3">{{ this.$i18n.t("patient.attachments.fileSelected") }} {{ file1 ? file1.name : "" }}</div>
        </b-col>
      </b-row>
    </b-card> -->
  </div>
</template>

<script>
export default {
  props: {
    jsonData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    file1: function (e) {
      console.log(e);
      if (e !== null) {
        const selectedFile = e;
        this.createBase64(selectedFile);
      }
    },
    image: function (e) {
      console.log(e);
      if (e !== null) {
        const selectedFile = e;
        this.createBase64Img(selectedFile);
      }
    },
  },
  data() {
    return {
      showModalLoading: false,
      linkback: null,
      file1: null,
      image: null,
      disableRiquadroFirma: true,
      modalitaOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "REMOTA", text: "REMOTA" },
        { value: "AUTO", text: "AUTO" },
        { value: "SIGILLO", text: "SIGILLO" },
      ],
      tipoFirmaOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "P7M", text: "P7M" },
        { value: "PDF", text: "PDF" },
        { value: "XML", text: "XML" },
      ],
      marcaOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      formatoPaginaOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "A3 PORTRAIT", text: "A3 PORTRAIT" },
        { value: "A3 LANDSCAPE", text: "A3 LANDSCAPE" },
        { value: "A4 PORTRAIT", text: "A4 PORTRAIT" },
        { value: "A4 LANDSCAPE", text: "A4 LANDSCAPE" },
      ],
    };
  },
  methods: {
    createBase64(fileObject) {
      let me = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        me.pdf = e.target.result;
        me.jsonData.file = me.pdf;
      };
      reader.readAsDataURL(fileObject);
    },
    createBase64Img(fileObject) {
      let me = this;
      const reader = new FileReader();
      reader.onload = (e) => {
        me.pdf = e.target.result;
        me.jsonData.immagineSignature = me.pdf;
      };
      reader.readAsDataURL(fileObject);
    },
    onAbilitaRiquadroFirma() {
      let me = this;
      me.disableRiquadroFirma = true;
      if (me.jsonData.tipoFirma === "PDF") {
        me.disableRiquadroFirma = false;
      }
    },
  },
};
</script>

<style></style>
