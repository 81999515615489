<template>
  <div class="sa-page">
    <div class="sa-page-header">
      <div class="sa-toolbar-header"><slot name="toolbar-header"></slot></div>
      <!-- <div class="sa-page-header-left"><slot name="toolbar-title"> </slot></div>
            <div class="sa-page-header-center">Prova Due</div>
            <div class="sa-page-header-right">Prova Tre</div> -->
      <b-navbar v-if="toolbarVisible" :toggleable="toggleable" type="light" variant="faded sa-nav-page-header">
        <b-navbar-brand href="#">
          <span style="margin-left: 0.5rem; float: left">{{ titolo }}</span>
          <slot name="toolbar-title"></slot>
        </b-navbar-brand>

        <b-navbar-toggle target="page-nav-collapse"></b-navbar-toggle>

        <b-collapse id="page-nav-collapse" is-nav>
          <b-navbar-nav toggleable="xl" class="ml-auto sa-navbar-toggle" :class="getClass()">
            <div style="float: left; width: 100%">
              <slot name="toolbar-left"></slot>
            </div>
          </b-navbar-nav>
          <b-navbar-nav toggleable="xl" class="ml-auto sa-navbar-toggle">
            <div style="float: right; width: 100%">
              <slot name="toolbar"></slot>
              <b-button v-if="btnEditVisible" variant="primary btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onEdit">
                <b-icon icon="pencil"></b-icon>
                {{ getLabelTraduora("global.btnlblEdit") }}
              </b-button>
              <b-button v-if="btnNewVisible" class="float-sm-right" variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd">
                <b-icon icon="plus"></b-icon>
                {{ getLabelTraduora("global.lblBtnNew") }}
              </b-button>
              <b-button v-if="btnRefreshVisible" class="float-sm-right primary" variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center>
                <b-icon icon="arrow-clockwise" />
                {{ getLabelTraduora("global.lblBtnRefresh") }}
              </b-button>
              <b-button v-if="btnSaveVisible" variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onSaveClose" :disabled="btnSaveDisabled">
                <b-icon icon="check2"></b-icon>
                {{ getLabelTraduora(labelSaveAndClose) }}
              </b-button>
              <b-button v-if="btnSaveNotBackVisible" variant="success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onSave">
                <b-icon icon="check2"></b-icon>
                {{ getLabelTraduora("global.lblBtnSave") }}
              </b-button>
              <print-component v-if="btnPrintVisible" :data="data" :linkPrintData="linkPrintData"></print-component>
              <firma-component v-if="btnFirmaVisible" :fieldName="firmaFieldName" :idEvento="idEvento" :data="data" :pathRestFirma="pathResourceFirma" @update="onFirmaUpdate"></firma-component>
              <b-button v-if="btnBackVisible" variant="secondary btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onBack">
                <b-icon icon="chevron-left"></b-icon>
                {{ getLabelTraduora("global.lblBtnBack") }}
              </b-button>
              <b-button v-if="btnDownalodExcelVisible" variant="outline-success btn-toolbar float-sm-right sa-margin-right" size="sm" @click="onDownloadExcel">
                <b-icon icon="download"></b-icon>
                Download Excel
              </b-button>
              <b-button v-if="btnAnnullaVisible" variant="danger btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onBack">
                <b-icon icon="x"></b-icon>
                {{ getLabelTraduora("global.lblBtnCancel") }}
              </b-button>
            </div>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <slot name="toolbar-row"></slot>
    </div>

    <div class="sa-page-content">
      <!-- <print-component v-if="btnPrintVisible" :data="data" :linkPrintData="linkPrintData"></print-component> -->
      <div class="sa-page-content-body">
        <div class="sa-page-content-body-filter">
          <slot name="table-filter"></slot>
        </div>
        <hr class="sa-hr" />
        <div class="sa-page-content-body-header"><slot name="table-header"></slot></div>
        <div class="sa-page-content-body-content">
          <slot name="table-body" class="sa-sistoandolfi-uno"></slot>
        </div>
        <!--<div class="sa-table-footer" style="height: 30px"><slot name="table-footer"></slot></div>-->
        <div class="sa-page-content-body-footer"><slot name="table-footer"></slot></div>
      </div>
    </div>

    <sa-modal-loading ref="sa-modal-loading" />
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PrintComponent from "../../../../../app/utility/components/PrintComponent.vue";
import { SaModalLoading } from "../../../../components/modal";
import UtilityMixin from "../../../../../app/utility/UtilityMixin";
import FirmaComponent from "../../../../../app/paziente/eventi/utility/components/FirmaComponent.vue";
export default {
  mixins: [UtilityMixin],
  components: { SaModalLoading, PrintComponent, FirmaComponent },
  props: {
    lblFiltro: {
      type: String,
      default: function () {
        return "Filtri";
      },
    },
    titolo: String,
    linkedit: String,
    linkback: String,
    pathResource: String,
    pathResourceDownloadExcel: String,
    nomeFileExcel: String,
    fileName: String,
    pathResourceFirma: String,
    firmaFieldName: String,
    idEvento: String,
    btnBackVisible: { type: Boolean, default: false },
    btnPrintVisible: { type: Boolean, default: false },
    btnFirmaVisible: { type: Boolean, default: false },
    btnRefreshVisible: { type: Boolean, default: false },
    btnNewVisible: { type: Boolean, default: false },
    btnEditVisible: { type: Boolean, default: false },
    btnSaveVisible: { type: Boolean, default: false },
    btnSaveNotBackVisible: { type: Boolean, default: false },
    btnAnnullaVisible: { type: Boolean, default: false },
    showModalLoading: { type: Boolean, default: false },
    navLeftVisible: { type: Boolean, default: false },
    linkPrintData: { type: String, default: null },
    linkNotBack: { type: String, default: null },
    printData: { type: Object, default: null },
    linkSaveAndClose: { type: String, default: null },
    labelSaveAndClose: { type: String, default: "global.lblBtnSaveClose" },
    btnSaveDisabled: { type: Boolean, default: false },
    btnDownalodExcelVisible: { type: Boolean, default: false },
    toolbarVisible: { type: Boolean, default: true },
    toggleableDimension: {
      type: String,
      default: function () {
        return "xl";
      },
    },
    data: {
      type: Object,
      default: function () {
        return { id: "-1" };
      },
    },
    items: {
      type: Array[Object],
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      toggleable: false,
      newPatientId: "",
      jsonData: null,
    };
  },
  watch: {
    showModalLoading(value) {
      let me = this;
      if (value) {
        me.$refs["sa-modal-loading"].show();
      } else {
        setTimeout(me.$refs["sa-modal-loading"].close(), 200);
      }
    },
  },
  created() {
    let me = this;
    me.setToggleNavBar();
    window.addEventListener("resize", this.onResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      let me = this;
      me.setToggleNavBar();
    },
    getClass() {
      //sa-navbar-left
      return this.navLeftVisible ? "sa-navbar-left" : "sa-navbar-left-hidden";
    },
    setToggleNavBar() {
      let me = this;
      let standardDimension = ["sm", "md", "lg", "xl"];
      if (!standardDimension.includes(me.toggleableDimension)) {
        let dimension = parseInt(me.toggleableDimension.replace("px", ""));
        if (Number.isInteger(dimension)) {
          if (window.innerWidth < dimension) {
            me.toggleable = true;
          } else {
            me.toggleable = false;
          }
        } else {
          me.toggleable = "xl";
        }
      } else {
        me.toggleable = "xl";
      }
    },
    onBack() {
      let me = this;
      me.$router.replace(me.linkback).catch((err) => {
        err;
      });
    },
    onAdd() {
      let me = this;
      me.$router.replace(me.linkedit + "/edit/-1").catch((err) => {
        err;
      });
    },
    onRefresh() {
      let me = this;
      me.$emit("refresh");
    },
    onEdit() {
      let me = this;
      me.$router.replace(me.linkedit).catch((err) => {
        err;
      });
    },
    onFirmaUpdate() {
      this.$emit("updateFirma");
    },
    onDownloadExcel() {
      let me = this;
      me.$refs["sa-modal-loading"].show();
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDownloadExcel;
      axios
        .post(link, me.items, {
          responseType: "blob",
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let nomeFile = this.nomeFileExcel + moment(new Date()).format("DD-MM-YYYY");
          link.setAttribute("download", nomeFile + ".xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
          me.$refs["sa-modal-loading"].close();
        })
        .catch((err) => {
          console.log(err);
          me.$refs["sa-modal-loading"].close();
        });
    },
    onSaveClose() {
      let me = this;
      me.save(true);
    },
    onSave() {
      let me = this;
      me.save(false);
    },
    save(close) {
      let me = this;
      me.$refs["sa-modal-loading"].show();

      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      if (me.data.id === "-1" || me.data.id === null || me.data.id === undefined) {
        axios
          .post(link, JSON.stringify(me.data))
          .then((response) => {
            me.jsonData = response.data.data;
            me.saveSuccess(close, response.data.data.id);
          })
          .catch((error) => {
            me.saveError(error);
          });
      } else {
        link = link + "/" + me.data.id;
        axios
          .put(link, JSON.stringify(me.data))
          .then((response) => {
            me.jsonData = response.data.data;
            me.saveSuccess(close, me.data.id);
          })
          .catch((error) => {
            me.saveError(error);
          });
      }
    },
    saveSuccess(close, id) {
      let me = this;
      me.$refs["sa-modal-loading"].close();
      let linkback = me.linkSaveAndClose ? me.linkSaveAndClose : me.linkback;
      let link = close ? linkback.replace("{id}", id) : me.linkNotBack.replace("{editid}", "/edit/" + id);
      this.$bvToast.toast("Salvataggio avvenuto con sucesso", {
        title: "",
        variant: "success",
        solid: true,
      });
      me.$router.replace(link).catch((err) => {
        err;
      });
      this.$emit("aftersave", me.jsonData);
    },
    saveError(error) {
      let me = this;
      me.$refs["sa-modal-loading"].close();
      let message = "";
      error.response ? error.response.data.messaggio : "Errore non gestito";
      if (error.response) {
        message = error.response.data.messaggio ? error.response.data.messaggio : "Codice Errore: " + error.response.status + ", non gestito";
      }
      if (error.response) {
        this.$bvModal
          .msgBoxOk(message, {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then(() => {})
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
<style>
.navbar {
  background-color: white;
}
.sa-page-header {
  /* display: flex;
    width: 100%; */
}
.sa-page-header-left {
  background-color: blue;
  flex-grow: 1;
}
.sa-page-header-center {
  background-color: red;
  flex-grow: 1;
}
.sa-page-header-right {
  background-color: green;
  flex-grow: 1;
}
</style>
