<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <paziente-allegati-list-component ref="allegatiListC" :data="data"></paziente-allegati-list-component>
            </b-col>
        </b-row>

        <b-modal ref="mdlAllegati" id="mdlAllegati" size="xl" style="height: 100%" @show="onHideModal" @hidden="onHideModal">
            <paziente-allegati-edit-component :jsonData="data"></paziente-allegati-edit-component>
            <template #modal-footer="{ btnSalva }">
                <b-button :id="btnSalva" size="sm" variant="success" @click="onSalva">Salva</b-button>
            </template>
        </b-modal>
    </div>
</template>

<script>
import PazienteAllegatiEditComponent from "../../../../../paziente/allegati/components/PazienteAllegatiEditComponent.vue";
import PazienteAllegatiListComponent from "../../../../../paziente/allegati/components/PazienteAllegatiListComponent.vue";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import axios from "axios";
export default {
    mixins: [UtilityMixin],
    props: ["data", "edit"],
    components: { PazienteAllegatiListComponent, PazienteAllegatiEditComponent },
    data() {
        return {
            file: null,
            perPage: 10,
            pathResourceAllegati: "/allegati",
            currentPageFile: 1,
            rowList: 0,
        };
    },
    mounted() {
        let me = this;
        try {
            let pages = me.data.allegati.length;
            me.rowList = pages;
        } catch {
            me.rowList = 0;
        }
    },
    computed: {
        idPaziente: {
            get: function () {
                return this.$route.query.idPaziente;
            },
            set: function () {},
        },
    },
    methods: {
        gg(f) {
            console.log(f);
        },
        download() {},
        aggiungi() {
            let me = this.file;
            switch (me) {
                case me !== null:
                    console.log("ok");
                    break;
                default:
                    alert("Nessun file selezionato");
                    break;
            }
        },
        eliminaFile() {},
        fileList() {
            try {
                const items = this.data.allegati;
                // Return just page of items needed
                return items.slice((this.currentPageFile - 1) * this.perPage, this.currentPageFile * this.perPage);
            } catch {
                return [];
            }
        },
        onSalva() {
            let me = this;
            let obj = me.getDataPdf(me.data);
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegati;
            me.showModalLoading = true;
            axios.post(link, obj).then(() => {
                this.$refs["mdlAllegati"].hide();
                me.showModalLoading = false;
                this.$bvToast.toast(`${this.getLabelTraduora("patient.afa.lblToastSuccessUpload")} - ${this.data.nomeFile}`, {
                    title: "File Upload",
                    autoHideDelay: 2000,
                    variant: "success",
                });
            });
        },

        getDataPdf(obj) {
            let objPdf = {};
            objPdf.idRisorsa = obj.id;
            objPdf.idAnagrafica = obj.idPaziente;
            objPdf.nomeFile = obj.nomeFile;
            objPdf.size = obj.size;
            objPdf.file = obj.file;
            objPdf.tipo = obj.tipo;
            objPdf.dataCaricamento = obj.dataCaricamento;
            objPdf.descrizione = obj.descrizione;
            objPdf.tipoDocumento = obj.tipoDocumento;
            return objPdf;
        },
        onHideModal() {
            let me = this;
            console.log("ok");
            (me.data.file = null), (me.data.size = null), (me.data.tipo = null), (me.data.dataCaricamento = null), (me.data.descrizione = null), (me.data.tipoDocumento = null);
        },
    },
};
</script>
