<template>
  <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
        <template slot="tab-content-event">
            <gastro-anamnesi-fisiologica-view-component @update="onUpdateJsonData"></gastro-anamnesi-fisiologica-view-component>
        </template>
    </eventi-view-component>
</template>
<script>
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import GastroAnamnesiFisiologicaViewComponent from "./GastroAnamnesiFisiologicaViewComponent.vue"
export default {
  components: { GastroAnamnesiFisiologicaViewComponent, EventiViewComponent },
  mixins: [UtilityMixin, EventiMixin],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      linkback: null,
      titoloEvento: this.getLabelTraduora("patients.gastroenterology.physiologicalHistory"),
      pathResource: "/gastroanamnesifisiologica",
      pathResourceFirma: "/gastroanamnesifisiologica",
      jsonData: {},
      data: {},
      tabIndex: 0,
      id: null,
      linkPrintData: null,
      linkedit: null,
      pathRestValidation: "/validation",
      isThisFirmato: false,
      showModalLoading: false,
    };
  },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.id = this.$route.params.id;
    },

    methods: {
        updateStatoFirma(firmato) {
            console.log(firmato);
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            console.log(me.jsonData);
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
        },
    },
};
</script>
