<template>
  <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" :isImmagini="false" :isPrestazioni="true" @update="onUpdateJsonData" :isAllegati="false">
    <template slot="tab-content-event">
      <gastro-cartella-infermieristica-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></gastro-cartella-infermieristica-edit-component>
    </template>
  </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import GastroCartellaInfermieristicaEditComponent from "../components/GastroCartellaInfermieristicaEditComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { EventiEditComponent, GastroCartellaInfermieristicaEditComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    this.jsonData.dataEvento = new Date().getTime();
  },
  data() {
    return {
      linkback: null,
      titoloEvento: "CARTELLA INFERMIERISTICA",
      pathResource: "/gastroschedainfermieristica",
      pathRestValidation: "/validation",
      id: "-1",
      isFirmato: false,
      childKey: 0,
      tabIndex: 0,
      showModalLoading: false,
      jsonData: {
        dataEvento: null,
        peso: "",
        altezza: "",
        dataOraAccettazione: null,
        dataOraInizioEsame: null,
        dataOraFineEsame: null,
        tipoAccesso: "",
        esame: "",
        provenienza: "",
        mobilita: "",
        accompagnatore: "",
        comunicazioneAccompagnatore: "",
        cormobidita: "",
        noteCormobidita: "",
        interventi: "",
        allergie: "",
        antiaggreganti: "",
        anticoagulanti: "",
        terapiaDomiciliare: "",
        valutazionePreSedazioneAsa: "",
        eligibileNaap: "",
        preparazioneIntestinaleDigiuno: "",
        preparazioneIntestinaleProdotto: "",
        effettiPersonali: "",
        statoCoscienza: "",
        preOre: null,
        preInfermiere: "",
        preFcBm: "",
        prePressioneSistolica: "",
        prePressioneDiastolica: "",
        preSaturazione: "",
        preO2: "",
        preDolore: "",
        preFarmaci: "",
        intraOre: null,
        intraInfermiere: "",
        intraFcBm: "",
        intraPressioneSistolica: "",
        intraPressioneDiastolica: "",
        intraSaturazione: "",
        intraO2: "",
        intraDolore: "",
        intraFarmaci: "",
        postOre: null,
        postInfermiere: "",
        postFcBm: "",
        postPressioneSistolica: "",
        postPressioneDiastolica: "",
        postSaturazione: "",
        postO2: "",
        postDolore: "",
        postFarmaci: "",
        salaRisveglioDimissioniOre: null,
        salaRisveglioDimissioniInfermiere: "",
        salaRisveglioDimissioniFcBm: "",
        salaRisveglioDimissioniPressioneSistolica: "",
        salaRisveglioDimissioniPressioneDiastolica: "",
        salaRisveglioDimissioniSaturazione: "",
        salaRisveglioDimissioniO2: "",
        salaRisveglioDimissioniDolore: "",
        salaRisveglioDimissioniFarmaci: "",
        complicanze: "",
        dimissioniDataOra: null,
        infermiereSalaEndoscopica: "",
        infermiereSalaRisveglio: "",
        medico: "",
        richiestaIstologica: "",
        listaInterventiAddominali: [],
        listaOperatori: [],
      },
    };
  },
  methods: {
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
  },
};
</script>
