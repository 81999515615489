<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Alimentazione </label>
        <b-form-select v-model="jsonData.alimentazione" :options="alimentazioneOptions" :value="null" value-field="valore" text-field="descrizione" @change="onChangeCalcoloTotale()" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Bagno/Doccia</label>
        <b-form-select v-model="jsonData.bagnoDoccia" :options="bagnoDocciaOptions" :value="null" value-field="valore" text-field="descrizione" @change="onChangeCalcoloTotale()" :disabled="editSvamaB"></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Igiene Personale</label>
        <b-form-select v-model="jsonData.igienePersonale" :options="igienePersonaleOptions" :value="null" value-field="valore" text-field="descrizione" @change="onChangeCalcoloTotale()" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Abbigliamento</label>
        <b-form-select v-model="jsonData.abbigliamento" :options="abbigliamentoOptions" :value="null" value-field="valore" text-field="descrizione" @change="onChangeCalcoloTotale()" :disabled="editSvamaB"></b-form-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Continenza Intestinale</label>
        <b-form-select v-model="jsonData.continenzaIntestinale" :options="continenzaIntestinaleOptions" :value="null" value-field="valore" text-field="descrizione" @change="onChangeCalcoloTotale()" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Continenza Urinaria</label>
        <b-form-select v-model="jsonData.continenzaUrinaria" :options="continenzaUrinariaOptions" :value="null" value-field="valore" text-field="descrizione" @change="onChangeCalcoloTotale()" :disabled="editSvamaB"></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Uso del Gabinetto</label>
        <b-form-select v-model="jsonData.usoGabinetto" :options="usoGabinettoOptions" :value="null" value-field="valore" text-field="descrizione" @change="onChangeCalcoloTotale()" :disabled="editSvamaB"></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label>{{ getLabelTraduora("adi.lblTotalScore") }}</label>
        <b-form-input v-model="jsonData.punteggioTotaleSituazioneFunzionale" class="text-upper" disabled></b-form-input>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  props: {
    editSvamaB: Boolean,
    jsonData: {
      type: Object,
      return: {
        alimentazione: 0,
        bagnoDoccia: 0,
        igienePersonale: 0,
        abbigliamento: 0,
        continenzaIntestinale: 0,
        continenzaUrinaria: 0,
        usoGabinetto: 0,
        punteggioTotaleSituazioneFunzionale: 0,
      },
    },
  },
  mixins: [UtilityMixin],
  data() {
    return {
      linkValutazioni: "/adivalutazioni",
      alimentazioneOptions: [{ descrizione: "-Seleziona Valore-", valore: 0 }],
      bagnoDocciaOptions: [{ descrizione: "-Seleziona Valore-", valore: 0 }],
      igienePersonaleOptions: [{ descrizione: "-Seleziona Valore-", valore: 0 }],
      abbigliamentoOptions: [{ descrizione: "-Seleziona Valore-", valore: 0 }],
      continenzaIntestinaleOptions: [{ descrizione: "-Seleziona Valore-", valore: 0 }],
      continenzaUrinariaOptions: [{ descrizione: "-Seleziona Valore-", valore: 0 }],
      usoGabinettoOptions: [{ descrizione: "-Seleziona Valore-", valore: 0 }],
    };
  },
  mounted() {
    let me = this;
    me.loadValutazioniAlimentazione();
    me.loadValutazioniBagnoDoccia();
    me.loadValutazioniIgienePersonale();
    me.loadValutazioniAbbigliamento();
    me.loadValutazioniContinenzaIntestinale();
    me.loadValutazioniContinenzaUrinaria();
    me.loadValutazioniUsoGabinetto();
  },
  methods: {
    onChangeCalcoloTotale() {
      let me = this;
      // let somma = 0;
      me.jsonData.punteggioTotaleSituazioneFunzionale = me.jsonData.punteggioTotaleSituazioneFunzionale ? me.jsonData.punteggioTotaleSituazioneFunzionale : 0;
      me.jsonData.punteggioTotaleSituazioneFunzionale = me.jsonData.alimentazione + me.jsonData.bagnoDoccia + me.jsonData.igienePersonale + me.jsonData.abbigliamento + me.jsonData.continenzaIntestinale + me.jsonData.continenzaUrinaria + me.jsonData.usoGabinetto;
      // console.log(me.jsonData.punteggioTotaleSituazioneFunzionale);
      // if (me.jsonData.punteggioTotaleSituazioneFunzionale !== 0 || me.jsonData.punteggioTotaleSituazioneFunzionale !== null) {
      //     somma = parseInt(me.jsonData.alimentazione) + parseInt(me.jsonData.bagnoDoccia) + parseInt(me.jsonData.igienePersonale) + parseInt(me.jsonData.abbigliamento) + parseInt(me.jsonData.continenzaIntestinale) + parseInt(me.jsonData.continenzaUrinaria) + parseInt(me.jsonData.usoGabinetto);
      // } else {
      //     console.log("OK 2");
      //     somma = me.jsonData.alimentazione + me.jsonData.bagnoDoccia + me.jsonData.igienePersonale + me.jsonData.abbigliamento + me.jsonData.continenzaIntestinale + me.jsonData.continenzaUrinaria + me.jsonData.usoGabinetto;
      // }
      // me.jsonData.punteggioTotaleSituazioneFunzionale = somma;
    },
    loadValutazioniAlimentazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=ALIMENTAZIONE";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.alimentazioneOptions.push(element);
        });
      });
    },
    loadValutazioniBagnoDoccia() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=BAGNO/DOCCIA";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.bagnoDocciaOptions.push(element);
        });
      });
    },
    loadValutazioniIgienePersonale() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=IGIENE PERSONALE";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.igienePersonaleOptions.push(element);
        });
      });
    },
    loadValutazioniAbbigliamento() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=ABBIGLIAMENTO";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.abbigliamentoOptions.push(element);
        });
      });
    },
    loadValutazioniContinenzaIntestinale() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=CONTINENZA INTESTINALE";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.continenzaIntestinaleOptions.push(element);
        });
      });
    },
    loadValutazioniContinenzaUrinaria() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=CONTINENZA URINARIA";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.continenzaUrinariaOptions.push(element);
        });
      });
    },
    loadValutazioniUsoGabinetto() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=USO DEL GABINETTO";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.usoGabinettoOptions.push(element);
        });
      });
    },
  },
};
</script>
