<template>
    <sa-page-layout :pathResource="pathResource" :showmolalloading="showmolalloading">
        <template slot="toolbar"></template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <studio-medico-ambulatori-list-component></studio-medico-ambulatori-list-component>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import StudioMedicoAmbulatoriListComponent from "./StudioMedicoAmbulatoriListComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { StudioMedicoAmbulatoriListComponent, SaPageLayout },
    created() {},
    data() {
        return {
            showmolalloading: false,
            pathResource: "/ambulatoriutenti",
        };
    },
};
</script>
<style lang="css">
.sa-logo-home img {
    width: 100%;
    max-width: 35rem;
}
</style>
>
