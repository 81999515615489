<template>
  <b-card class="sa-card" header="Lista Stati" header-tag="header" footer-tag="footer" title="">
    <div class="b-table-sticky-header">
      <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData.listaStati" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
        <template #cell(index)="row">
          {{ row.index + 1 }}
        </template>
        <template v-slot:cell(statoDataOra)="{ item }">
          <span>{{ formatDateTime(item.statoDataOra) }}</span>
        </template>
      </b-table>
    </div>
  </b-card>
</template>
<script>
// import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      type: Object,
      require: true,
      default: function () {
        return {
          condizioneProfessionale: "",
          posizioneProfessionale: "",
          ramoAttivita: "",
          cittadinanza: "",
          cittadinanzaStraniera: "",
          gradoIstruzioneMadre: "",
          gradoIstruzionePadre: "",
          professioneMadre: "",
          professionePadre: "",
          condizioneProfessionaleMadre: "",
          condizioneProfessionalePadre: "",
          posizioneProfessionaleMadre: "",
          posizioneProfessionalePadre: "",
          ramoAttivitaMadre: "",
          ramoAttivitaPadre: "",
          cittadinanzaMadre: "",
          cittadinanzaStranieraMadre: "",
          cittadinanzaPadre: "",
          cittadinanzaStranieraPadre: "",
        };
      },
    },
  },
  data() {
    return {
      filtro: { page: 1, forPage: 100 },
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Stato",
          key: "stato",
          sortable: true,
          thStyle: "width: 20rem",
        },
        {
          label: "Data Ora",
          key: "statoDataOra",
          sortable: true,
          thStyle: "width: 20rem",
        },
        {
          label: "Note",
          key: "note",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    // let me = this;
    // me.loadCondizioneProfessionale();
    // me.loadPosizioneProfessione();
    // me.loadRamoAttivita();
    // me.loadCittadinanza();
    // me.loadGradiIstruzione();
  },
  watch: {
    jsonData: {
      handler() {},
      deep: true,
    },
  },
  methods: {},
};
</script>
