<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblTrofism") }}</label>
                    <span class="sa-data">{{ jsonData.trofismo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblFlanks") }}
                        <eventi-storico-misure-component misura="corpo" misuraLabel="Fianchi" unitaMisura="cm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.corpo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblWaist") }}
                        <eventi-storico-misure-component misura="vita" misuraLabel="Vita" unitaMisura="cm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.vita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblWeight") }}
                        <eventi-storico-misure-component misura="peso" misuraLabel="Peso" unitaMisura="Kg" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblHeight") }}
                        <eventi-storico-misure-component misura="altezza" misuraLabel="Altezza" unitaMisura="cm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblBMI") }}
                        <eventi-storico-misure-component misura="bmi" misuraLabel="BMI" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.bmi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblBSA") }}
                        <eventi-storico-misure-component misura="bsa" misuraLabel="BSA" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.bsa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblGlycemia") }}
                        <eventi-storico-misure-component misura="glicemia" misuraLabel="Glicemia" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.glicemia }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblDiabetes") }}
                        <eventi-storico-misure-component misura="diabete" misuraLabel="Diabete" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.diabete }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblPAMax") }}
                        <eventi-storico-misure-component misura="pa_max" misuraLabel="Pa Max" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.paMax }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblPAMin") }}
                        <eventi-storico-misure-component misura="pa_min" misuraLabel="Pa Min" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.paMin }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblCholesterol") }}
                        <eventi-storico-misure-component misura="colesterolo" misuraLabel="Colesterolo" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.colesterolo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblBloodGroup") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.gruppoSanguigno) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblCirculatorySystem") }}</label>
                    <span class="sa-data">{{ jsonData.apparatoCardiocirc }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblLocomotorSystem") }}</label>
                    <span class="sa-data">{{ jsonData.apparatoLocomotore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblRespiratorySystem") }}</label>
                    <span class="sa-data">{{ jsonData.apparatoRespiratorio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblAbdomenGeneralOrgans") }}</label>
                    <span class="sa-data">{{ jsonData.addomeOrgGen }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblNaturalSightRightEye") }}
                        <eventi-storico-misure-component misura="vista_n_od" misuraLabel="Vista Naturale Occhio Destro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.vistaNOd }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblNaturalSightLeftEye") }}
                        <eventi-storico-misure-component misura="vista_n_os" misuraLabel="Vista Naturale Occhio Sinistro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.vistaNOs }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblCurrentSightRightEye") }}
                        <eventi-storico-misure-component misura="vista_c_od" misuraLabel="Vista Corrente Occhio Destro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.vistaCOd }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblCurrentSightLeftEye") }}
                        <eventi-storico-misure-component misura="vista_c_os" misuraLabel="Vista Corrente Occhio Sinistro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.vistaCOs }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblCromaticSense") }}</label>
                    <span class="sa-data">{{ jsonData.sensoCromatico }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblLimbs") }}</label>
                    <span class="sa-data">{{ jsonData.arti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblHearingSense") }}</label>
                    <span class="sa-data">{{ jsonData.udito }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblExaminationConclusion") }}</label>
                    <span class="sa-data">{{ jsonData.conclusioniEsObiettivo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Stato</label>
                    <span class="sa-data">{{ jsonData.firmaEo }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    components: { EventiStoricoMisureComponent },
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.jsonData.idPaziente;
        },
    },
    data() {
        return {
            tipoEvento: "IDONEITA",
            gruppo: "MEDICINADELLOSPORT",
        };
    },
};
</script>
