<template>
  <sa-page-layout :pathResource="pathResource" :linkback="linkback" :data="jsonData" :btnAnnullaVisible="true" :btnSaveVisible="true" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Data Esordio Malattia</label>
              <date-picker v-model="jsonData.dataEsordioMalattia" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Sintomo di Esordio</label>
              <b-form-input v-model="jsonData.sintomoEsordio"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="SINTOMI" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Disturbi Psichiatrici Nelle Prime Fasi Della Malattia</label>
              <b-form-select v-model="jsonData.disturbiPsichiatriciPrimaFase" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Decadimento intellettivo-demenza</label>
              <b-form-select v-model="jsonData.decadimentoIntellettivoDemenza" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Mioclono</label>
              <b-form-select v-model="jsonData.mioclono" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Altri Movimenti Involontari</label>
              <b-form-select v-model="jsonData.altriMovimentiInvolontari" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Segni Piramidali</label>
              <b-form-select v-model="jsonData.segniPiramidali" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Segni Extrapiramidali</label>
              <b-form-select v-model="jsonData.segniExtrapiramidali" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Segni Cerebellari</label>
              <b-form-select v-model="jsonData.segniCerebellari" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Segni Visivi</label>
              <b-form-select v-model="jsonData.segniVisivi" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Mutismo acinetico</label>
              <b-form-select v-model="jsonData.mutismoAcinetico" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Parestesie</label>
              <b-form-select v-model="jsonData.parestesie" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Disentesie Dolorose</label>
              <b-form-select v-model="jsonData.disentesieDolorose" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">EEG Caratteristico</label>
              <b-form-select v-model="jsonData.eegCaratteristico" :options="esamiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Esame Liquorale (proteina 14-3-3)</label>
              <b-form-select v-model="jsonData.esameLiquorale" :options="esamiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="ALTRI ESAMI STRUMENTALI EFFETTUATI" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">TC Cerebrale</label>
              <b-form-select v-model="jsonData.tcCerebrale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">RMN Cerebrale</label>
              <b-form-select v-model="jsonData.rmnCerebrale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Sanitario Notificante</label>
              <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Telefono Sanitario Notificante</label>
              <b-form-input v-model="jsonData.sanitarioNotificanteContatto"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Data Notifica</label>
              <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../paziente/components/PazienteAnagrafica.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica, DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveiemalattiacreutzfeldtjakob",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      showModalLoading: false,
      jsonData: {
        dataEsordioMalattia: null,
        sintomoEsordio: "",
        disturbiPsichiatriciPrimaFase: "",
        decadimentoIntellettivoDemenza: "",
        mioclono: "",
        altriMovimentiInvolontari: "",
        segniPiramidali: "",
        segniExtrapiramidali: "",
        segniCerebellari: "",
        segniVisivi: "",
        mutismoAcinetico: "",
        parestesie: "",
        disentesieDolorose: "",
        eegCaratteristico: "",
        esameLiquorale: "",
        tcCerebrale: "",
        rmnCerebrale: "",
        sanitarioNotificante: "",
        sanitarioNotificanteContatto: "",
        dataNotifica: null,
      },
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      esamiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON ESEGUITO", text: "NON ESEGUITO" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    let malattiaInfettivaSegnalazione = sessionStorage.getItem("MalattiaInfettivaSegnalazione");
    let malattiaInfettiva = {};
    if (malattiaInfettivaSegnalazione !== null && malattiaInfettivaSegnalazione !== "null") {
      malattiaInfettiva = JSON.parse(malattiaInfettivaSegnalazione);
      me.setDatiMalattia(malattiaInfettiva);
    }
    sessionStorage.setItem("MalattiaInfettivaSegnalazione", null);
    if (me.id !== "-1") {
      me.linkback = "/malattieinfettive/indagineepidemiologica/malattiajakobcreutzfeldt/view/" + me.id + "?idPaziente=" + me.idPaziente;
    } else {
      me.linkback = "/malattieinfettive/all";
    }
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.setDatiMedico();
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        me.showModalLoading = true;
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = me.utente.firstname + " " + me.utente.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
  },
};
</script>
