<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblProtocolNumber") }}</label>
          <b-form-input v-model="jsonData.numeroProtocollo" @input="onInputData" readonly></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblProtocolDate") }}</label>
          <date-picker v-model="jsonData.dataProtocollo" format="DD-MM-YYYY" value-type="timestamp" type="date" @input="onInputData" disabled :editable="false"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDiagnosticQuestion") }} *</label>
          <b-form-textarea v-model="jsonData.quesitoDiagnostico" class="text-upper" no-resize @input="onInputData" :disabled="editichiestaVisitaDomiciliare"></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblReport") }} *</label>
          <b-form-textarea v-model="jsonData.relazione" class="text-upper" no-resize @input="onInputData" :disabled="editichiestaVisitaDomiciliare"></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    id: String,
    linkPrintData: String,
    numeroProtocollo: String,
    dataProtocollo: { type: Number },
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { DatePicker },
  data() {
    return {
      jsonData: {
        dataProtocollo: null,
        numeroProtocollo: "",
        quesitoDiagnostico: "",
        relazione: "",
      },
      pathResource: "/adirichiestevisitadomiciliare",
      editichiestaVisitaDomiciliare: false,
      showModalLoading: false,
      linkback: null,
    };
  },
  mounted() {
    let me = this;
    me.jsonData.id = this.$route.params.id;
    if (me.id !== "-1") {
      me.loadData();
    }
    me.impostaPermessi();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      let fonte = this.$route.query.fonte;
      me.editichiestaVisitaDomiciliare = true;
      if (fonte === null || fonte === undefined || fonte === "undefined" || fonte === "") {
        fonte = "/richieste";
      }
      if (fonte === "/richieste") {
        me.editichiestaVisitaDomiciliare = !UtilityMixin.methods.verificaPermessi(me.utente, "adirichiestevisitadomiciliare", 2);
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
      me.showmolalloading = true;
      axios
        .get(link)
        .then((response) => {
          me.jsonData = response.data.data;
          // console.log(me.jsonData);
          me.showmolalloading = false;
        })
        .catch(() => {
          me.showmolalloading = false;
          me.jsonData = {
            dataProtocollo: me.dataProtocollo,
            numeroProtocollo: me.numeroProtocollo,
            quesitoDiagnostico: "",
            relazione: "",
          };
        });
      me.$emit("update", me.jsonData);
    },
    getJsonData() {
      let me = this;
      return me.jsonData;
    },
    onInputData() {
      this.jsonData.id = this.$route.params.id;
      this.$emit("update", this.jsonData);
    },
  },
};
</script>
