<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblHandgripRight") }}
                        <eventi-storico-misure-component misura="handgrip_destra" misuraLabel="Handgrip Destra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.handgripDestra }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblHandgripLeft") }}
                        <eventi-storico-misure-component misura="handgrip_sinistra" misuraLabel="Handgrip Sinistra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.handgripSinistra }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblPerpendicularDeviation") }}
                        <eventi-storico-misure-component misura="deviazione_perpendicolare" misuraLabel="Deviazione Perpendicolare" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.deviazionePerpendicolare }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblStraightDeviation") }}
                        <eventi-storico-misure-component misura="deviazione_retta" misuraLabel="Deviazione Retta" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.deviazioneRetta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblFlexRight") }}
                        <eventi-storico-misure-component misura="flessibilita_destra" misuraLabel="Flessibilita Destra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.flessibilitaDestra }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblFlexLeft") }}
                        <eventi-storico-misure-component misura="flessibilita_sinistra" misuraLabel="Flessibilita Sinistra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.flessibilitaSinistra }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblChairTest") }}
                        <eventi-storico-misure-component misura="chair_test" misuraLabel="Chair Test" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.chairTest }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblMets") }}
                        <eventi-storico-misure-component misura="mets" misuraLabel="Mets" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.mets }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblStressDuration") }}
                        <eventi-storico-misure-component misura="durata_sforzo" misuraLabel="Durata Sforzo" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.durataSforzo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblRecoveryDuration") }}
                        <eventi-storico-misure-component misura="durata_recupero" misuraLabel="Durata Recupero" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.durataRecupero }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblQMCI") }}
                        <eventi-storico-misure-component misura="qmci" misuraLabel="Qmci" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.qmci }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { EventiStoricoMisureComponent },
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
        gruppo: {
            Type: String,
            require: true,
        },
        tipoEvento: {
            Type: String,
            require: true,
        },
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.jsonData.idPaziente;
        },
    },
    data() {
        return {};
    },
};
</script>
