<template>
    <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Ambulatorio</label>
                        <b-form-select id="inline-form-custom-select-pref" v-model="filtro.idAmbulatorio" class="mb-2 mr-sm-2 mb-sm-0" :options="ambulatoriOptions" :value="null" value-field="id" text-field="nome"></b-form-select>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Riferimento</label>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" type="date" v-model="filtro.dataRiferimento"></date-picker>
                    </b-col>
                </b-row>
                <b-row class="sa-form-btn-filter">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(nominativo)="{ item }">
                        <a>{{ item.cognome }} {{ item.nome }}</a>
                    </template>
                    <template v-slot:cell(prestazione)="{ item }">
                        <a>{{ item.codicePrestazione }} - {{ item.descrizionePrestazione }}</a>
                    </template>
                    <template #cell(actions)="row">
                        <b-row>
                            <b-button variant="btn btn-outline-info waves-effect waves-light width-sm no-text" @click="onPresaInCarico(row.item)" size="sm" :disabled="row.item.stato !== 'PRENOTATO'"><i class="fas fa-check"></i></b-button>
                        </b-row>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout, DatePicker },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            perPage: 100,
            currentPage: 1,
            filter: null,
            btnPresaInCaricoDisabled: false,
            pathResource: "/prenotazionicup",
            pathResourceAmbulatori: "/ambulatoriutenti/ambulatori",
            filtro: { dataRiferimento: Date.now() },
            filterOn: [],
            ambulatoriOptions: [],
            showModalLoading: false,
            fields: [
                {
                    label: this.$i18n.t("patients.lblNominative"),
                    key: "nominativo",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: this.$i18n.t("patients.lblTelephoneNumber"),
                    key: "telefono",
                    //thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patients.lblBirthDate"),
                    key: "nascitaData",
                    sortable: true,
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY");
                        } else {
                            return "-------";
                        }
                    },
                },
                {
                    label: "Prestazione",
                    key: "prestazione",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Provenienza",
                    key: "provenienza",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 4rem", tdClass: "text-center" },
            ],
            items: [],
        };
    },
    created: function () {},
    mounted: function () {
        this.loadDataAmbulatori();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?forPage=" + me.perPage + "&page=" + me.currentPage;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadDataAmbulatori() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAmbulatori + "?forPage=" + me.perPage + "&page=" + me.currentPage;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.ambulatoriOptions = [];
                    me.ambulatoriOptions = response.data.data;
                    if (me.ambulatoriOptions.length > 0) {
                        me.filtro.idAmbulatorio = me.ambulatoriOptions[0].id;
                        me.loadData();
                    }
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            console.log(me.filtro);
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathResource + "/edit/-1").catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onPresaInCarico(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/accetta/" + item.id;
            axios
                .put(link)
                .then((response) => {
                    console.log(response.data.data);
                    let sessionStorage = window.sessionStorage;
                    sessionStorage.setItem("idAmbulatorio", item.idAmbulatorio);
                    me.$router.replace("paziente/view/" + response.data.data.id).catch((err) => {
                        err;
                    });
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        // onDeleteItem(item) {
        //     let me = this;
        //     let domanda = this.$i18n.t("patient.event.lblConfirmDeleteQuestion");
        //     let titolo = this.$i18n.t("patient.event.lblConfirmDelete");
        //     let si = this.$i18n.t("patient.lblYes");
        //     let no = this.$i18n.t("patient.lblNo");
        //     this.$bvModal
        //         .msgBoxConfirm(domanda, {
        //             title: titolo,
        //             buttonSize: "sm",
        //             okVariant: "secondary",
        //             cancelVariant: "danger",
        //             okTitle: si,
        //             cancelTitle: no,
        //             footerClass: "p-2",
        //             hideHeaderClose: false,
        //             centered: true,
        //         })
        //         .then((value) => {
        //             if (value) {
        //                 me.deleteItem(item);
        //             }
        //         })
        //         .catch((error) => {
        //             console.log(error);
        //         });
        // },
        // deleteItem(item) {
        //     let me = this;
        //     me.showModalLoading = true;
        //     let link = process.env.VUE_APP_PATH_API + me.pathDeleteAnagrafica + "/" + item.id;
        //     axios
        //         .delete(link)
        //         .then((Response) => {
        //             console.log(Response);
        //             me.showModalLoading = false;
        //             me.loadData();
        //         })
        //         .catch((e) => {
        //             console.log(e);
        //         });
        // },
        // onShareItem(item) {
        //     console.log(item);
        // },
    },
};
</script>
