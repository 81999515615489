<template>
    <form ref="form" @submit.prevent="onFirmaSubmit">
        <b-form-group label="Utente" label-for="username-input" invalid-feedback="L'username è obbligatorio">
            <span>{{ utente.lastname }} {{ utente.firstname }}</span>
        </b-form-group>
        <b-form-group label="Username" label-for="username-input" invalid-feedback="L'username è obbligatorio">
            <b-form-input placeholder="Username" id="username-input" v-model="parametriFirma.username" required></b-form-input>
        </b-form-group>
        <b-form-group label="Password" label-for="password-input" invalid-feedback="La password è obbligatoria">
            <b-form-input placeholder="Password" type="password" id="password-input" v-model="parametriFirma.password" required></b-form-input>
        </b-form-group>
        <b-form-group label="O.T.P." label-for="otp-input" invalid-feedback="L'O.T.P. è obbligatorio">
            <b-form-input placeholder="O.T.P." type="password" id="otp-input" v-model="parametriFirma.otp" required></b-form-input>
        </b-form-group>
        <b-row>
            <b-col cols="12" sm="12" class="sa-text-right"><b-button size="sm" variant="outline-secondary" type="submit">Firma</b-button></b-col>
        </b-row>
    </form>
</template>
<script>
import axios from "axios";
export default {
    props: {
        utente: Object,
        filesName: { type: Array, require: true },
    },
    data() {
        return {
            pathResource: "/arubasign",
            parametriFirma: { username: "giovannirosiello", password: "Giogege5707", otp: null },
        };
    },
    methods: {
        onFirmaSubmit() {
            let me = this;
            this.$emit("signstart");
            me.parametriFirma.testo = me.utente.lastname + " " + me.utente.firstname;
            me.parametriFirma.filesName = me.filesName;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios.post(link, me.parametriFirma).then(() => {
                me.showModalLoading = false;
                this.$bvToast.toast("Processo di firma avviato", {
                    title: "File Upload",
                    autoHideDelay: 2000,
                    variant: "success",
                });
                this.$emit("signend");
            });
        },
    },
};
</script>

<style></style>
