<template>
  <sa-page-layout :pathResource="pathResource" :btnRefreshVisible="true" :btnNewVisible="true" :linkedit="linkedit" @refresh="onRefresh" :showModalLoading="showModalLoading">
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(index)="row">
            {{ (currentPage - 1) * perPage + row.index + 1 }}
          </template>
          <template v-slot:cell(nome)="{ item }">
            <router-link class="sa-edit-link" :to="'/emailnotificationgroups/view/' + item.id">{{ item.nome }}</router-link>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
// import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import DatePicker from "vue2-datepicker";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { SaPageLayout },
  mixins: [UtilityMixin],
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      rows: null,
      showModalLoading: false,
      pathResource: "/emailnotificationgroups",
      linkedit: "/emailnotificationgroups",
      filtro: {},
      items: [],
      value: null,
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 5rem",
        },
        {
          label: "Nome Gruppo",
          key: "nome",
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
  },
};
</script>
