<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
          <b-form-input v-model="jsonData.peso" type="number" placeholer="Kg" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
          <b-form-input v-model="jsonData.altezza" type="number" placeholer="cm" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
          <b-form-input v-model="jsonData.bmi" type="number" placeholer="Kg/m2" disabled></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBSA") }}</label>
          <b-form-input v-model="jsonData.bsa" type="number" placeholer="m2" disabled></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCardiovascularRiskFactors')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.ipertensione" switch :checked="jsonData.ipertensione">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblHypertension") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.dislipidemia" switch :checked="jsonData.dislipidemia">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemia") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.diabete" switch :checked="jsonData.diabete">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblDiabetes") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.obesita" switch :checked="jsonData.obesita">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.fumo" switch :checked="jsonData.fumo">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblSmoking") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.familCerebrovascolari" switch :checked="jsonData.familCerebrovascolari">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblCerebrovascularAccidentsFamiliarity") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.familIpertensione" switch :checked="jsonData.familIpertensione">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.familCardiopatiaIsch" switch :checked="jsonData.familCardiopatiaIsch">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblCardiovascularAccidentsFamiliarity") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArterialReport") }}</label>
          <b-form-textarea v-model="jsonData.refertoArterioso" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVenousReport") }}</label>
          <b-form-textarea v-model="jsonData.refertoVenoso" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-checkbox v-model="jsonData.esameNellaNorma" switch :checked="jsonData.esameNellaNorma">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblExamInTheNorm") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <b-form-checkbox v-model="jsonData.stenosiCcSx" switch :checked="jsonData.stenosiCcSx">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblStenosisOnLeft") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <b-form-checkbox v-model="jsonData.stenosiCcDx" switch :checked="jsonData.stenosiCcDx">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblStenosisOnRight") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
          <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblSignificativeStenosis") }}</span>
          <b-form-input v-model="jsonData.stenosiSignificativa" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.aterosclerosiLieve" switch :checked="jsonData.aterosclerosiLieve">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblLightAterosclerosys") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.aterosclerosiModerata" switch :checked="jsonData.aterosclerosiModerata">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblModerateAterosclerosys") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.aterosclerosiNonEmoSign" switch :checked="jsonData.aterosclerosiNonEmoSign">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblNotSignificativeEmodinamAterosclerosys") }}</span>
          </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-form-checkbox v-model="jsonData.aterosclerosiSevera" switch :checked="jsonData.aterosclerosiSevera">
            <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblSevereAterosclerosys") }}</span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },
  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardioecocolordopplerartisuperiori",
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onCalcoloBmiBsa(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
      me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
