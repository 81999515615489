<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAortoCoronaryBypass')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBloodVaseNumPathology") }}</label>
          <b-form-select v-model="jsonData.patologiaNumVasi" :options="optionsPatologia">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMammaryArteryUse") }}</label>
          <b-form-select v-model="jsonData.arteriaMammariaInterna" :options="optionsMammaria">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDistalAnastomosisNumber") }}</label>
          <b-form-input v-model="jsonData.numAnastomosiDistali" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGraftMammaryArteryNumber") }}</label>
          <b-form-input v-model="jsonData.numGraftArteriaMammariaInt" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGastroepiploicMammaryArteryGraftNumber") }}</label>
          <b-form-input v-model="jsonData.numGraftArteriaMammariaGastroepi" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProssimalAnastomosisNumber") }}</label>
          <b-form-input v-model="jsonData.numAnastomosiProssimali" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDistalAnastomosisMammaryArteryNumber") }}</label>
          <b-form-input v-model="jsonData.numAnastomosiDistArteriaMamInt" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDistalAnastomosisGastroepiploicaArteryNumber") }}</label>
          <b-form-input v-model="jsonData.numAnastomosiDistArteriaGastroepi" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCommonTrunk')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
          <b-form-select v-model="jsonData.percentualeDiStenosiTronco" :options="optionsPercentualeStenosi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
          <b-form-select v-model="jsonData.condottoTronco" :options="optionsCondotto">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
          <b-form-select v-model="jsonData.tecnicaTronco" :options="optionsTecnica">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightCoronary')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
          <b-form-select v-model="jsonData.percentualeDiStenosiCoronariaDx" :options="optionsPercentualeStenosi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
          <b-form-select v-model="jsonData.condottoCoronariaDx" :options="optionsCondotto">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
          <b-form-select v-model="jsonData.tecnicaCoronariaDx" :options="optionsTecnica">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblFrontalDescendant')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
          <b-form-select v-model="jsonData.percentualeDiStenosiDiscendenteAnt" :options="optionsPercentualeStenosi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
          <b-form-select v-model="jsonData.condottoDiscendenteAnt" :options="optionsCondotto">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
          <b-form-select v-model="jsonData.tecnicaDiscendenteAnt" :options="optionsTecnica">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblFirstDiagonal')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
          <b-form-select v-model="jsonData.percentualeDiStenosiPrimoDiagonale" :options="optionsPercentualeStenosi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
          <b-form-select v-model="jsonData.condottoPrimoDiagonale" :options="optionsCondotto">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
          <b-form-select v-model="jsonData.tecnicaPrimoDiagonale" :options="optionsTecnica">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSecondDiagonal')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStenosisPercentage") }}</label>
          <b-form-select v-model="jsonData.percentualeDiStenosiSecondoDiagonale" :options="optionsPercentualeStenosi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConduct") }}</label>
          <b-form-select v-model="jsonData.condottoSecondoDiagonale" :options="optionsCondotto">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTecnic") }}</label>
          <b-form-select v-model="jsonData.tecnicaSecondoDiagonale" :options="optionsTecnica">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },
  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardiochirurgia",
      componentKey: 0,
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      optionsConferma: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblYes"), value: "patient.cardiology.Options.lblYes" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
      ],
      optionsPatologia: [{ text: "Select di test", value: "Select di test" }],
      optionsMammaria: [{ text: "Select di test", value: "Select di test" }],
      optionsPercentualeStenosi: [{ text: "Select di test", value: "Select di test" }],
      optionsCondotto: [{ text: "Select di test", value: "Select di test" }],
      optionsTecnica: [{ text: "Select di test", value: "Select di test" }],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
