<template>
    <div>
        <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ this.$i18n.t("therapy.lblDrug") }} :</label>
                <span class="sa-data">{{ jsonData.farmaco }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ this.$i18n.t("therapy.lblQuantity") }} :</label>
                <span class="sa-data">{{ jsonData.quantita }}</span>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ this.$i18n.t("therapy.lblFrequency") }} :</label>
                <div>
                    <b-card class="sc-card-max-height" style="height: 100%; width: 100%" no-body>
                        <div class="sa-calendar-day-grid-slot">
                            <table class="sa-calendar-day-grid-table-slot">
                                <tr v-for="(item1, i) in griglia" :key="i">
                                    <td v-for="(item2, j) in item1" :key="j">
                                        <div class="text-center">{{ item1 * i + j }} - {{ item1 * i + (j + 1) }}</div>
                                        <!-- {{ item1 * i + j }} -->
                                        <div v-if="isSelectOrarioSomministrazione(item1 * i + j)" class="sa-div-cell sa-check-true"><b-icon icon="check"></b-icon></div>
                                        <div v-else class="sa-div-cell"></div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </b-card>
                </div>
            </b-col>
            <b-col>
                <label class="sa-label-data">{{ this.$i18n.t("therapy.lblPosology") }} :</label>
                <span class="sa-data">{{ getLabelTraduora(jsonData.posologia) }} </span>
                <hr />
                <b-row>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data"> {{ this.$i18n.t("therapy.lblStartDate") }} :</label>

                        <span class="sa-data"> {{ formatDateTime(jsonData.dataInizio) }}</span>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblEndDate") }} :</label>

                        <span class="sa-data"> {{ formatDateTime(jsonData.dataFine) }}</span>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblStatus") }} :</label>

                        <span class="sa-data">{{ getLabelTraduora(jsonData.statoDescrizione) }} </span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <hr />
        <b-row>
            <b-col cols="12" md="12">
                <label class="sa-label-data"> {{ this.$i18n.t("therapy.lblNote") }} :</label>

                <span class="sa-data">{{ jsonData.note }}</span>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
// import PazienteDashboardComponent from "../../components/PazienteDashboardComponent.vue";
export default {
    props: {
        idPaziente: String,
    },
    mixins: [UtilityMixin],
    data() {
        return {
            griglia: [6, 6, 6, 6],
            id: -1,
            i: 0,
            tabIndex: 0,
            currentPage: 1,
            perPage: 5,
            pathResource: "/pazienteterapie",
            linkedit: null,
            jsonData: {
                dataInizio: Date.now(),
                dataFine: Date.now(),
                farmaco: "Tachipirina",
                quantita: 1,
                frequenza: null,
                posologia: null,
                stato: null,
                note: null,
                orarioSomministrazione: [],
            },
            showModalLoading: false,
            slotSomministrazioniMattinaItems: [],
            slotSomministrazioniPomeriggioItems: [],
            slotSomministrazioniSeraItems: [],
            nonTerminata: true,
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        this.loadData();
        // me.loadSomministrazione();
    },
    methods: {
        isSelectOrarioSomministrazione(value) {
            let me = this;
            return me.jsonData.orarioSomministrazione.includes(value.toString());
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.showModalLoading = false;
                    me.jsonData = response.data.data;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        // loadSomministrazione() {
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + me.linkSomministrazione + "?idTerapia=" + me.id;
        //     me.isBusy = true;
        //     me.jsonData.idPaziente = me.idPaziente;
        //     axios
        //         .get(link, { params: me.jsonData })
        //         .then((response) => {
        //             me.somministrazioneItems = [];
        //             me.somministrazioneItems = response.data.data;
        //         })
        //         .catch(() => {
        //             me.showModalLoading = false;
        //         });
        // },
        colorChange(value) {
            console.log("gg" + value);

            return "outline-success";
        },
    },
};
</script>
