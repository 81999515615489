<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Data Ora Accettazione</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataOraAccettazione) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Data Ora Inizio Esame</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataOraInizioEsame) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Data Ora Fine Esame</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataOraFineEsame) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Peso</label>
                    <span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Altezza</label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Accesso</label>
                    <span class="sa-data">{{ jsonData.tipoAccesso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Esame</label>
                    <span class="sa-data">{{ jsonData.esame }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Provenienza</label>
                    <span class="sa-data">{{ jsonData.provenienza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Mobilità</label>
                    <span class="sa-data">{{ jsonData.mobilita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Accompagnatore</label>
                    <span class="sa-data">{{ jsonData.accompagnatore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Comunicazione Con Accompagnatore</label>
                    <span class="sa-data">{{ jsonData.comunicazioneAccompagnatore }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Comorbidità" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Comorbidità</label>
                    <span class="sa-data">{{ jsonData.cormobidita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note Comorbidità</label>
                    <span class="sa-data-justify">{{ jsonData.noteCormobidita }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="INTERVENTI ADDOMINALI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ha subito interventi</label>
                    <span class="sa-data">{{ jsonData.interventi }}</span>
                </b-col>
            </b-row>
            <b-row style="margin-top: 5px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaInterventiAddominali" :fields="fieldsInterventi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                        <template #cell(index)="row">
                            {{ row.index + 1 }}
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Allergie</label>
                    <span class="sa-data-justify">{{ jsonData.allergie }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Antiaggreganti</label>
                    <span class="sa-data-justify">{{ jsonData.antiaggreganti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Anticoagulanti</label>
                    <span class="sa-data-justify">{{ jsonData.anticoagulanti }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Terapia Domiciliare</label>
                    <span class="sa-data-justify">{{ jsonData.terapiaDomiciliare }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Valutazione Pre-Sedazione ASA</label>
                    <span class="sa-data">{{ jsonData.valutazionePreSedazioneAsa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Eligibile NAAP</label>
                    <span class="sa-data">{{ jsonData.eligibileNaap }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Preparazione Intestinale Digiuno</label>
                    <span class="sa-data">{{ jsonData.preparazioneIntestinaleDigiuno }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Preparazione Intestinale Prodotto</label>
                    <span class="sa-data">{{ jsonData.preparazioneIntestinaleProdotto }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Stato di Coscienza</label>
                    <span class="sa-data">{{ jsonData.statoCoscienza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Effetti Personali</label>
                    <span class="sa-data-justify">{{ jsonData.effettiPersonali }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Monitoraggio Paziente - PRE" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ore</label>
                    <span class="sa-data">{{ formatTime(jsonData.preOre) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere</label>
                    <span class="sa-data">{{ jsonData.preInfermiere }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">F.C. (b/m)</label>
                    <span class="sa-data">{{ jsonData.preFcBm }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Sistolica (mmHg)</label>
                    <span class="sa-data">{{ jsonData.prePressioneSistolica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Diastolica (mmHg)</label>
                    <span class="sa-data">{{ jsonData.prePressioneDiastolica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SatO2 (%)</label>
                    <span class="sa-data">{{ jsonData.preSaturazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">O2 (l/m)</label>
                    <span class="sa-data">{{ jsonData.preO2 }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dolore</label>
                    <span class="sa-data">{{ jsonData.preDolore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Farmaci e Dosi</label>
                    <span class="sa-data">{{ jsonData.preFarmaci }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Monitoraggio Paziente - INTRA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ore</label>
                    <span class="sa-data">{{ formatTime(jsonData.intraOre) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere</label>
                    <span class="sa-data">{{ jsonData.intraInfermiere }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">F.C. (b/m)</label>
                    <span class="sa-data">{{ jsonData.intraFcBm }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Sistolica (mmHg)</label>
                    <span class="sa-data">{{ jsonData.intraPressioneSistolica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Diastolica (mmHg)</label>
                    <span class="sa-data">{{ jsonData.intraPressioneDiastolica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SatO2 (%)</label>
                    <span class="sa-data">{{ jsonData.intraSaturazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">O2 (l/m)</label>
                    <span class="sa-data">{{ jsonData.intraO2 }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dolore</label>
                    <span class="sa-data">{{ jsonData.intraDolore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Farmaci e Dosi</label>
                    <span class="sa-data">{{ jsonData.intraFarmaci }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Monitoraggio Paziente - POST" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ore</label>
                    <span class="sa-data">{{ formatTime(jsonData.postOre) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere</label>
                    <span class="sa-data">{{ jsonData.postInfermiere }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">F.C. (b/m)</label>
                    <span class="sa-data">{{ jsonData.postFcBm }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Sistolica (mmHg)</label>
                    <span class="sa-data">{{ jsonData.postPressioneSistolica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Diastolica (mmHg)</label>
                    <span class="sa-data">{{ jsonData.postPressioneDiastolica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SatO2 (%)</label>
                    <span class="sa-data">{{ jsonData.postSaturazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">O2 (l/m)</label>
                    <span class="sa-data">{{ jsonData.postO2 }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dolore</label>
                    <span class="sa-data">{{ jsonData.postDolore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Farmaci e Dosi</label>
                    <span class="sa-data">{{ jsonData.postFarmaci }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Monitoraggio Paziente - SALA RISVEGLIO DIMISSIONE" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Ore</label>
                    <span class="sa-data">{{ formatTime(jsonData.salaRisveglioDimissioniOre) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere</label>
                    <span class="sa-data">{{ jsonData.salaRisveglioDimissioniInfermiere }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">F.C. (b/m)</label>
                    <span class="sa-data">{{ jsonData.salaRisveglioDimissioniFcBm }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Sistolica (mmHg)</label>
                    <span class="sa-data">{{ jsonData.salaRisveglioDimissioniPressioneSistolica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">P.A. Diastolica (mmHg)</label>
                    <span class="sa-data">{{ jsonData.salaRisveglioDimissioniPressioneDiastolica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SatO2 (%)</label>
                    <span class="sa-data">{{ jsonData.salaRisveglioDimissioniSaturazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">O2 (l/m)</label>
                    <span class="sa-data">{{ jsonData.salaRisveglioDimissioniO2 }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Dolore</label>
                    <span class="sa-data">{{ jsonData.salaRisveglioDimissioniDolore }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Farmaci e Dosi</label>
                    <span class="sa-data">{{ jsonData.salaRisveglioDimissioniFarmaci }}</span>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Richiesta Istologico</label>
                    <span class="sa-data">{{ jsonData.richiestaIstologica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Complicanze</label>
                    <span class="sa-data-justify">{{ jsonData.complicanze }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dimissione" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Ora Dimissioe</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dimissioniDataOra) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere Di Sala Endoscopica</label>
                    <span class="sa-data">{{ jsonData.infermiereSalaEndoscopica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Infermiere Di Sala Risveglio</label>
                    <span class="sa-data">{{ jsonData.infermiereSalaRisveglio }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Medico</label>
                    <span class="sa-data">{{ jsonData.medico }}</span>
                </b-col>
            </b-row>
        </b-card>
        <eventi-operatori-component :operatori="jsonData.listaOperatori" :isEdit="isEdit"></eventi-operatori-component>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import moment from "moment";
import axios from "axios";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
    components: { EventiOperatoriComponent },
    mixins: [UtilityMixin],
    props: {},
    data() {
        return {
            isEdit: false,
            pathResource: "/gastroschedainfermieristica",
            id: null,
            jsonData: {},
            linkPrintData: null,
            currentPage: 1,
            perPage: 10,
            fieldsInterventi: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 2rem",
                },
                {
                    label: "Tipo Intervento",
                    key: "tipoIntervento",
                    thStyle: "width: 15rem",
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                },
                {
                    label: "Data",
                    key: "data",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY");
                        } else {
                            return "-----";
                        }
                    },
                    thStyle: "width: 12rem",
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>

<style></style>
