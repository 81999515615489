<template>
  <sa-page-layout :pathResource="pathResource" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :btnSaveVisible="true" :btnAnnullaVisible="true" class="sa-no-space">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <div class="sa-form-section" v-if="this.$route.query.idPrenotazione">
          <h4>Prenotazione</h4>
          <leonida-prenotazione-view-component :jsonData="prenotazione"></leonida-prenotazione-view-component>
        </div>
        <b-card class="sa-card" header="Dati Struttura" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Struttura</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.codiceStruttura" class="mb-2 mr-sm-2 mb-sm-0" :options="struttureOptions" :value="null" value-field="codiceStruttura" text-field="denominazione"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Data Preotazione</label>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrenotazione" type="date" placeholder="Data Prenotazione"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Numero Cartella</label>
              <b-form-input v-model="jsonData.codiceCartella" placeholder="Numero Cartella" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Numero Certificato</label>
              <b-form-input v-model="jsonData.numeroCertificato" placeholder="Numero Certificato"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="ANAGRAFICA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="3">
              <label class="sa-label-data">Cognome</label>
              <b-form-input v-model="jsonData.accettazioneAnagrafica.cognome" placeholder="Cognome"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="3">
              <label class="sa-label-data">Nome</label>
              <b-form-input v-model="jsonData.accettazioneAnagrafica.nome" placeholder="Nome"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
              <label class="sa-label-data">Data di Nascita</label>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.accettazioneAnagrafica.dataNascita" type="date" placeholder="Data Nascita" @input="onInputDataNascita"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
              <label class="sa-label-data">Eta'</label>
              <span class="sa-data">{{ calcoloEta(jsonData.accettazioneAnagrafica.dataNascita) }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Sesso</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.sesso" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption" :value="null"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Tipo Identificativo</label>
              <b-form-select v-model="jsonData.accettazioneAnagrafica.tipoIdentificativo" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoIdentificativoOptions" :value="null" value-field="codice" text-field="descrizione"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Codice Fiscale</label>
              <b-input-group class="mb-3">
                <!-- <b-form-input maxlength="16" class="text-uppercase" @keypress="onKeyPress" v-model="jsonData.accettazioneAnagrafica.codiceFiscale" placeholder="Codice Fiscale"></b-form-input> -->
                <b-form-input maxlength="16" class="text-uppercase" @keypress="onKeyPress" v-model="jsonData.accettazioneAnagrafica.identificativo" placeholder="Codice Fiscale"></b-form-input>
                <b-button :disabled="disabledAccept(!jsonData.accettazioneAnagrafica.tipoIdentificativo)" size="sm" variant="" @click="onSearchAnagraficaVisitaClick"><b-icon icon="search"></b-icon></b-button>
              </b-input-group>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Provincia di Nascita</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceNascitaOptions" :value="null" value-field="sigla" text-field="provincia" @change="onProvinciaNascitaChange"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Comune di Nascita</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniNascitaOptions" :value="null" value-field="codiceIstat" text-field="comune"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Numero Tessera Sanitaria</label>
              <b-form-input v-model="jsonData.numeroTesseraSanitaria" placeholder="Numero Tessera Sanitaria"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
              <label class="sa-label-data">Documento d'identita'</label>
              <!-- <b-form-input v-model="jsonData.documentoIdentita" placeholder="Documento d'identita'"></b-form-input> -->
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.documentoIdentita" class="mb-2 mr-sm-2 mb-sm-0" :options="documentiIdentitaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Numero Documento</label>
              <b-form-input v-model="jsonData.numeroDocumento" placeholder="Numero Documento"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Gruppo Sanguigno</label>
              <b-form-select v-model="jsonData.gruppoSanguigno" class="mb-2 mr-sm-2 mb-sm-0" :options="gruppoSanguignoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Fattore RH</label>
              <b-form-select v-model="jsonData.fattoreRh" class="mb-2 mr-sm-2 mb-sm-0" :options="fattoreRhOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Tipo Atleta</label>
              <b-form-select v-model="jsonData.tipoAtleta" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiAtletaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Sport</label>
              <b-form-select v-model="jsonData.sport" class="mb-2 mr-sm-2 mb-sm-0" :options="sportOptions" :value="null" value-field="codiceSport" text-field="sport" @input="onInputSport($event)"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Tipo Visita</label>
              <b-form-input v-model="jsonData.tipoVisita" placeholder="Tipo Visita"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Validità Certificato</label>
              <b-form-input v-model="jsonData.validitaCertificato" placeholder="Validità Certificato"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Federazione/Ente</label>
              <b-form-input v-model="jsonData.federazioneEnte" placeholder="Federazione/Ente"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="2">
              <label class="sa-label-data">Societa' Sportiva</label>
              <b-form-input v-model="jsonData.societaSportiva" placeholder="Società Sportiva"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="RESIDENZA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ this.$i18n.t("patients.lblAddress") }}</label>
              <b-form-input v-model="jsonData.accettazioneAnagrafica.indirizzoResidenza" placeholder="Indirizzo"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Provincia di Residenza</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceResidenzaOptions" :value="null" value-field="sigla" text-field="provincia" @change="onProvinciaResidenzaChange"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Comune di Residenza</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniResidenzaOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onComuneResidenzaInput"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Cap Residenza</label>
              <b-form-input v-model="jsonData.accettazioneAnagrafica.capResidenza" placeholder="CAP Comune di residenza:"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="CONTATTI" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Numero di Telefono</label>
              <b-form-input v-model="jsonData.accettazioneAnagrafica.telefono" placeholder="Telefono"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Email</label>
              <b-form-input v-model="jsonData.accettazioneAnagrafica.email" placeholder="Email"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="MEDICO ESECUTORE" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
              <label class="sa-label-data">Nome e Cognome</label>
              <b-form-select v-model="jsonData.nomeMedicoEsecutore" :options="medicoEsecutoreOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="ALTRI DATI" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">Da riepilogare</label>
              <b-form-checkbox v-model="jsonData.daRiepilogare" switch></b-form-checkbox>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
              <label class="sa-label-data">Regime Erogazione</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.regimeErogazione" class="mb-2 mr-sm-2 mb-sm-0" :options="regimeErogazioneOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
              <label class="sa-label-data">Posizione Ticket</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.posizioneTicket" class="mb-2 mr-sm-2 mb-sm-0" :options="posizioneTicketOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Tariffa Base</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.tariffaBase" class="mb-2 mr-sm-2 mb-sm-0" :options="tariffaBaseOptions" :value="null" value-field="codiceTariffa" text-field="descrizioneTariffa"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Asl Riepilogo</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.aslRiepilogo" class="mb-2 mr-sm-2 mb-sm-0" :options="aslRiepilogoOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Asl Paziente</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.aslPaziente" class="mb-2 mr-sm-2 mb-sm-0" :options="aslPazienteOptions" :value="null" value-field="value" text-field="text"></b-form-select>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="ALTRI SPORT" header-tag="header" footer-tag="footer" title="">
          <b-row style="margin-bottom: 10px">
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-button variant="outline-primary sa-margin-right float-sm-right" size="sm" @click="onClickAggiungiSport">
                <b-icon icon="plus"></b-icon>
                Aggiungi
              </b-button>
            </b-col>
          </b-row>
          <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="jsonData.altriSport" :fields="fieldsAltriSport" sort-icon-left head-variant="light" class="sa-b-table">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
              <template v-slot:cell(sport)="{ item }">
                <a>{{ item.sport }}</a>
              </template>
              <template #cell(actions)="row">
                <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" style="margin-right: 4px" @click="onDeleteRiga(row.index)">
                  <b-icon icon="trash"></b-icon>
                </b-button>
              </template>
            </b-table>
          </div>
        </b-card>
        <b-card class="sa-card" header="NOTE" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-form-textarea v-model="jsonData.accettazioneAnagrafica.note" placeholder="Note" no-resize></b-form-textarea>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
    <template slot="table-footer">
      <b-modal ref="mdlAggiungiSport" id="mdlAggiungiSport" title="Aggiungi Sport" size="xl" @hidden="onHidden" ok-only ok-title="Chiudi">
        <div class="sa-form-section">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="2" xl="12">
              <b-button size="sm" style="margin-top: 2px" variant="btn btn-outline-info sa-margin-right float-sm-right" @click="onClickAggiungiRiga()">
                <b-icon icon="plus"></b-icon>
                Aggiungi
              </b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Sport</label>
              <b-form-select v-model="nuovaRiga.sport" :options="sportOptions" :value="null" value-field="codiceSport" text-field="sport" @input="onInputAltriSport($event)"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Tipo Visita</label>
              <b-form-input v-model="nuovaRiga.tipoVisita" placeholder="Tipo Visita"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Numero Certificato</label>
              <b-form-input v-model="nuovaRiga.numeroCertificato" placeholder="Numero Certificato"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Federazione/Ente</label>
              <b-form-input v-model="nuovaRiga.federazioneEnte" placeholder="Federazione/Ente"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Societa' Sportiva</label>
              <b-form-input v-model="nuovaRiga.societaSportiva" placeholder="Societa' Sportiva"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Validita' Certificato</label>
              <b-form-input v-model="nuovaRiga.validitaCertificato" placeholder="Validita' Certificato"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Data Scadenza Certificato</label>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="nuovaRiga.dataScadenzaCertificato" type="date" placeholder="Data Scadenza Certificato"></date-picker>
            </b-col>
          </b-row>
        </div>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import LeonidaPrenotazioneViewComponent from "../../prenotazione/components/LeonidaPrenotazioneViewComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  components: { SaPageLayout, DatePicker, LeonidaPrenotazioneViewComponent },
  mixins: [UtilityMixin],
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id === "-1") {
      me.linkback = "/leonidaaccettazioni";
    } else {
      me.linkback = "/leonidaaccettazioni/view/" + me.id;
    }
    if (this.$route.query.idPrenotazione) {
      me.idPrenotazione = this.$route.query.idPrenotazione;
      me.loadPrenotazione();
    }
    if (me.id !== "-1") {
      me.loadData();
      me.loadDefaultData();
    } else {
      me.loadDefaultData();
    }
  },
  data() {
    return {
      pathResource: "/leonidaaccettazioni",
      pathResourcePrenotazione: "/prenotazioni",
      pathResourceCodiceFiscale: "/leonidavisite/codicefiscale/",
      linkProvince: "/province",
      linkComune: "/comuni/provincia",
      linkStrutture: "/strutture",
      linkDocumentiIdentita: "/documentiidentita",
      linkTipoIdentificativo: "/anagraficaidentificativo",
      linkSport: "/tipisport",
      linkAsl: "/asl",
      linkLeonidaMedici: "/leonidamedici",
      linkAltriSport: "/accettazionileonidaaltrisport",
      linkRegimeErogazione: "/leonidaaccettazioneregimierogazione",
      linkPosizioneTicket: "/leonidaaccettazioneposizioneticket",
      linkTariffe: "/leonidaaccettazionetariffe",
      linkback: null,
      showModalLoading: false,
      jsonData: {
        codiceStruttura: null,
        accettazioneStruttura: {},
        accettazioneAnagrafica: {
          cognome: "",
          nome: "",
          provinciaNascita: null,
          comuneNascitaCodiceIstat: null,
          medicoCurante: "",
          codiceFiscaleMedicoCurante: "",
          provinciaResidenza: null,
          comuneResidenzaCodiceIstat: null,
          provinciaDomicilio: "",
          comuneDomicilioCodiceIstat: "",
          capDomicilio: "",
          capResidenza: "",
          dataNascita: null,
          sesso: null,
          tipoIdentificativo: null,
          identificativo: "",
          indirizzoResidenza: "",
          indirizzoDomicilio: "",
          telefono: "",
          email: "",
        },
        idPrenotazione: "",
        tipo: "",
        gruppoSanguigno: null,
        codiceCartella: "",
        numeroCertificato: "",
        societaSportiva: "",
        documentoIdentita: null,
        federazioneEnte: "",
        nomeMedicoEsecutore: null,
        numeroDocumento: "",
        numeroTesseraSanitaria: "",
        dataPrenotazione: null,
        sport: null,
        daRiepilogare: false,
        posizioneTicket: null,
        provenienzaMedico: null,
        classePriorita: null,
        aslRiepilogo: null,
        aslPaziente: null,
        regimeErogazione: null,
        tariffaBase: null,
        profiloTicket: null,
        tipoPrestazione: null,
        diagnosi: null,
        tipoAtleta: null,
        fattoreRh: null,
        validitaCertificato: null,
        tipoVisita: null,
        altriSport: [],
      },
      sessoOption: [
        { value: null, text: "-Sel. Sesso-" },
        { value: "M", text: "Maschio" },
        { value: "F", text: "Femmina" },
      ],
      gruppoSanguignoOptions: [
        { value: null, text: "-Sel. Gruppo Sang.-" },
        { value: "A", text: "A" },
        { value: "B", text: "B" },
        { value: "0", text: "0" },
        { value: "AB", text: "AB" },
      ],
      fattoreRhOptions: [
        { value: null, text: "-Sel. Fattore RH-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
      ],
      prenotazione: {},
      provinceNascitaOptions: [{ sigla: null, provincia: "-Seleziona Provincia-" }],
      provinceResidenzaOptions: [],
      provinceDomicilioOptions: [{ sigla: null, provincia: "-Seleziona Provincia-" }],
      comuniNascitaOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
      comuniResidenzaOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
      comuniDomicilioOptions: [{ codiceIstat: null, comune: "-Seleziona Comune-" }],
      struttureOptions: [{ value: null, text: "-Struttura-" }],
      tipoIdentificativoOptions: [{ value: null, text: "-Seleziona Identificativo-" }],
      documentiIdentitaOptions: [{ value: null, text: "-Seleziona Documento-" }],
      sportOptions: [],
      posizioneTicketOptions: [{ value: null, text: "-Seleziona Posizione Ticket-" }],
      provenienzaMedicoOptions: [{ value: null, text: "-Seleziona Provenienza Medico-" }],
      classePrioritaOptions: [{ value: null, text: "-Seleziona Classe di Priorita'-" }],
      aslRiepilogoOptions: [{ value: null, text: "-Seleziona Asl Riepilogo-" }],
      aslPazienteOptions: [{ value: null, text: "-Seleziona Asl Paziente-" }],
      regimeErogazioneOptions: [{ value: null, text: "-Seleziona Regime Erogazione-" }],
      tariffaBaseOptions: [{ value: null, text: "-Seleziona Tariffa-" }],
      profiloTicketOptions: [{ value: null, text: "-Seleziona Profilo Ticket-" }],
      tipoPrestazioneOptions: [{ value: null, text: "-Seleziona Tipo Prestazione-" }],
      diagnosiOptions: [{ value: null, text: "-Seleziona Diagnosi-" }],
      tipiAtletaOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "AGONISTA", text: "AGONISTA" },
        { value: "NON AGONISTA", text: "NON AGONISTA" },
        { value: "AGONISTA PRIVATO", text: "AGONISTA PRIVATO" },
        { value: "VOLO DA DIPORTO", text: "VOLO DA DIPORTO" },
      ],
      medicoEsecutoreOptions: [{ value: null, text: "-Seleziona Medico-" }],
      filtro: {},
      nuovaRiga: {},
      currentPage: 1,
      rows: 3,
      perPage: 50,
      fieldsAltriSport: [
        {
          label: "",
          key: "index",
          thStyle: "width: 2rem",
        },
        {
          label: "Sport",
          key: "sport",
        },
        {
          label: "Tipo Visita",
          key: "tipoVisita",
          thStyle: "width: 5rem",
        },
        {
          label: "Numero Certificato",
          key: "numeroCertificato",
          thStyle: "width: 6rem",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 3rem",
        },
      ],
    };
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadProvince();
      me.loadComuniNascita(me.jsonData.accettazioneAnagrafica.provinciaNascita);
      me.loadComuniResidenza(me.jsonData.accettazioneAnagrafica.provinciaResidenza, me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat);
      // me.loadComuniDomicilio(me.jsonData.accettazioneAnagrafica.provinciaDomicilio, me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat);
      me.loadStrutture();
      me.loadDocumentiIdentita();
      me.loadTipoIdentificativo();
      me.loadSport();
      me.loadMedicoEsecutore();
      me.loadPosizioneTicket();
      me.loadRegimiErogazione();
      me.loadTariffa();
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    disabledAccept() {
      let me = this;
      if (me.jsonData.accettazioneAnagrafica.tipoIdentificativo === "CF") {
        return false;
      } else {
        return true;
      }
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.setAltriDati(me.jsonData.accettazioneAnagrafica.dataNascita);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadPrenotazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourcePrenotazione + "/" + me.idPrenotazione;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.prenotazione = response.data.data;
          me.jsonData.accettazioneAnagrafica.nome = me.prenotazione.nome;
          me.jsonData.accettazioneAnagrafica.cognome = me.prenotazione.cognome;
          me.jsonData.accettazioneAnagrafica.dataNascita = me.prenotazione.dataNascita;
          me.jsonData.accettazioneAnagrafica.email = me.prenotazione.email;
          me.jsonData.accettazioneAnagrafica.telefono = me.prenotazione.telefono;
          me.jsonData.idPrenotazione = me.prenotazione.id;
          me.jsonData.dataPrenotazione = me.prenotazione.dataPrenotazione;
          me.jsonData.accettazioneAnagrafica.note = me.prenotazione.noteAggiuntive;
          me.jsonData.sport = me.prenotazione.sport;
          me.jsonData.tipoAtleta = me.prenotazione.tipoAtleta;
          me.setAltriDati(me.prenotazione.dataNascita);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadStrutture() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
      axios.get(link).then((response) => {
        me.struttureOptions = response.data.data;
        if (me.struttureOptions.length === 1) {
          me.jsonData.codiceStruttura = response.data.data[0].codiceStruttura;
        }
        me.struttureOptions.unshift({ codiceStruttura: null, denominazione: "-Seleziona Struttura-" });
      });
    },
    loadTipoIdentificativo() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkTipoIdentificativo;
      axios.get(link).then((response) => {
        me.tipoIdentificativoOptions = response.data.data;
        me.tipoIdentificativoOptions.unshift({ codice: null, descrizione: "-Seleziona Tipo Identificativo-" });
        // me.jsonData.tipoIdentificativo = "CF";
        me.jsonData.accettazioneAnagrafica.tipoIdentificativo = "CF";
      });
    },
    loadDocumentiIdentita() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkDocumentiIdentita;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.documentiIdentitaOptions.push({ value: element.tipoDocumento, text: element.tipoDocumento });
        });
      });
    },
    // onKeyPress(e) {
    //     let me = this;
    //     if (e.keyCode === 13) {
    //         me.onSearchAnagraficaClick();
    //     }
    // },
    onKeyPress(e) {
      let me = this;
      if (e.keyCode === 13) {
        me.onSearchAnagraficaVisitaClick();
      }
    },
    loadProvince() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProvince;
      axios.get(link).then((response) => {
        me.provinceNascitaOptions = response.data.data;
        me.provinceNascitaOptions.unshift({ sigla: null, provincia: "- Seleziona Provincia-" });
        response.data.data.forEach((element) => {
          me.provinceResidenzaOptions.push(element);
        });
      });
    },
    onProvinciaNascitaChange(value) {
      let me = this;
      me.provinceNascitaOptions.forEach((provincia) => {
        if (provincia.sigla === value) {
          me.setDatiNascita(provincia.sigla);
        }
      });
    },
    setDatiNascita(siglaProvincia, codiceIstatComuneNascita = null) {
      let me = this;
      me.jsonData.accettazioneAnagrafica.comuneNascitaCodiceIstat = codiceIstatComuneNascita;
      me.provinceNascitaOptions.forEach((provincia) => {
        if (provincia.sigla === siglaProvincia) {
          me.jsonData.accettazioneAnagrafica.provinciaNascita = provincia.sigla;
          me.loadComuniNascita(provincia.sigla);
        }
      });
    },
    loadComuniNascita(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuniNascitaOptions = [];
        me.comuniNascitaOptions = response.data.data;
        me.comuniNascitaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
      });
    },
    onProvinciaResidenzaChange(value) {
      let me = this;
      me.provinceResidenzaOptions.forEach((provincia) => {
        if (provincia.sigla === value) {
          console.log(provincia.sigla);
          // me.setDatiAsl(provincia.sigla);
          me.setDatiResidenza(provincia.sigla);
        }
      });
    },
    setDatiAsl(value) {
      let me = this;
      console.log(value);
      me.provinceResidenzaOptions.forEach((element) => {
        if (element.sigla === value) {
          me.loadAsl(element.regione);
        }
      });
    },
    setDatiResidenza(siglaProvincia, codiceIstatComuneResidenza = null) {
      let me = this;
      me.provinceResidenzaOptions.forEach((provincia) => {
        if (provincia.sigla === siglaProvincia) {
          me.jsonData.accettazioneAnagrafica.provinciaResidenza = provincia.sigla;
          me.loadComuniResidenza(provincia.sigla, codiceIstatComuneResidenza);
        }
      });
    },
    loadComuniResidenza(sigla, codiceIstatComuneResidenza = null) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link, { params: { provincia: sigla } }).then((response) => {
        me.comuniResidenzaOptions = response.data.data;
        me.comuniResidenzaOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
        me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat = codiceIstatComuneResidenza;
      });
    },
    onComuneResidenzaInput(value) {
      let me = this;
      me.comuniResidenzaOptions.forEach((comune) => {
        if (comune.codiceIstat === value) {
          me.jsonData.accettazioneAnagrafica.capResidenza = comune.cap;
        }
      });
    },
    loadAsl(value) {
      let me = this;
      me.aslRiepilogoOptions = [];
      me.aslPazienteOptions = [];
      let regione = value + "0";
      while (regione.charAt(0) === "0") {
        regione = regione.substring(1);
      }
      let link = process.env.VUE_APP_PATH_API + me.linkAsl + "/regione?codiceRegione=" + regione;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.aslRiepilogoOptions.push({ value: element.denominazione, text: element.denominazione });
          me.aslPazienteOptions.push({ value: element.denominazione, text: element.denominazione });
        });
      });
    },
    onProvinciaDomicilioChange(value) {
      let me = this;
      me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = null;
      me.provinceDomicilioOptions.forEach((provincia) => {
        if (provincia.sigla === value) {
          me.loadComuniDomicilio(provincia.sigla);
        }
      });
    },
    setDatiDomicilio(siglaProvincia, codiceIstatComuneDomicilio = null) {
      let me = this;
      me.provinceDomicilioOptions.forEach((provincia) => {
        if (provincia.sigla === siglaProvincia) {
          me.jsonData.accettazioneAnagrafica.provinciaDomicilio = provincia.sigla;
          me.loadComuniDomicilio(provincia.sigla, codiceIstatComuneDomicilio);
        }
      });
    },
    loadComuniDomicilio(sigla, codiceIstatComuneDomicilio = null) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuniDomicilioOptions = response.data.data;
        me.comuniDomicilioOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
        me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = codiceIstatComuneDomicilio;
      });
    },
    onComuneDomicilioInput(value) {
      let me = this;
      me.jsonData.accettazioneAnagrafica.capDomicilio = "";
      me.comuniDomicilioOptions.forEach((comune) => {
        if (comune.codiceIstat === value) {
          me.jsonData.accettazioneAnagrafica.capDomicilio = comune.cap;
        }
      });
    },
    loadSport() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkSport;
      axios.get(link).then((response) => {
        me.sportOptions = response.data.data;
        me.sportOptions.unshift({ codiceSport: null, sport: "-Seleziona Sport-" });
      });
    },
    loadRegimiErogazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkRegimeErogazione;
      axios.get(link, { params: { forPage: 10000, page: 1 } }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.regimeErogazioneOptions.push({ value: element.descrizione, text: "(" + element.codice + ") " + element.descrizione });
        });
      });
    },
    loadPosizioneTicket() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkPosizioneTicket;
      axios.get(link, { params: { forPage: 10000, page: 1 } }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.posizioneTicketOptions.push({ value: element.descrizione, text: "(" + element.codice + ") " + element.descrizione });
        });
      });
    },
    loadTariffa() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkTariffe;
      axios.get(link, { params: { forPage: 10000, page: 1 } }).then((response) => {
        me.tariffaBaseOptions = response.data.data.list;
        me.tariffaBaseOptions.unshift({ codiceTariffa: null, descrizioneTariffa: "-Seleziona Tariffa-" });
        if (me.tariffaBaseOptions.length === 2) {
          me.jsonData.tariffaBase = response.data.data.list[1].codiceTariffa;
        }
      });
    },
    loadMedicoEsecutore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkLeonidaMedici;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.medicoEsecutoreOptions.push({ value: element.username, text: element.firstname + " " + element.lastname });
        });
      });
    },
    onDeleteRiga(index) {
      let me = this;
      me.jsonData.altriSport.splice(index, 1);
    },
    onClickAggiungiSport() {
      let me = this;
      me.nuovaRiga = { sport: null, tipoVisita: null, numeroCertificato: "", dataScadenzaCertificato: null, validitaCertificato: null, federazioneEnte: null, societaSportiva: null };
      me.$refs.mdlAggiungiSport.show();
    },
    onHidden() {
      let me = this;
      me.nuovaRiga = { sport: null, tipoVisita: null, numeroCertificato: "", dataScadenzaCertificato: null, validitaCertificato: null, federazioneEnte: null, societaSportiva: null };
    },
    onClickAggiungiRiga() {
      let me = this;
      if (me.nuovaRiga.sport != null && me.nuovaRiga.tipoVisita != null && me.nuovaRiga.numeroCertificato != "" && me.nuovaRiga.dataScadenzaCertificato != null && me.nuovaRiga.validitaCertificato != null && me.nuovaRiga.federazioneEnte != null && me.nuovaRiga.societaSportiva) {
        me.jsonData.altriSport.push(me.nuovaRiga);
        me.nuovaRiga = { sport: null, tipoVisita: null, numeroCertificato: "", dataScadenzaCertificato: null, validitaCertificato: null, federazioneEnte: null, societaSportiva: null };
      }
    },
    onInputSport(value) {
      let me = this;
      me.sportOptions.forEach((element) => {
        if (element.codiceSport === value) {
          me.jsonData.tipoVisita = element.tipoTar;
          me.jsonData.validitaCertificato = element.validita;
          me.jsonData.federazioneEnte = element.federazione;
        }
      });
    },
    onInputAltriSport(value) {
      let me = this;
      me.sportOptions.forEach((sportSelezionato) => {
        if (sportSelezionato.codiceSport === value) {
          me.nuovaRiga.tipoVisita = sportSelezionato.tipoTar;
        }
      });
    },
    onInputDataNascita(value) {
      let me = this;
      me.setAltriDati(value);
    },
    setAltriDati(value) {
      let me = this;
      if (!me.isMaggiorenne(value) && me.jsonData.tipoAtleta === "AGONISTA") {
        me.jsonData.regimeErogazione = "SSN";
        me.jsonData.posizioneTicket = "ESENTE PER CATEGORIA";
      } else if (me.isMaggiorenne(value) && me.jsonData.tipoAtleta === "AGONISTA") {
        me.jsonData.regimeErogazione = "SOLVENTE";
        me.jsonData.posizioneTicket = "PRIVATO";
      } else if (me.jsonData.tipoAtleta != "AGONISTA") {
        me.jsonData.regimeErogazione = "SOLVENTE";
        me.jsonData.posizioneTicket = "PRIVATO";
      }
    },
    onSearchAnagraficaVisitaClick() {
      let me = this;
      me.showModalLoading = true;
      if (me.jsonData.accettazioneAnagrafica.identificativo.trim().length === 0) {
        me.showModalLoading = false;
      } else {
        me.jsonData.accettazioneAnagrafica.identificativo = me.jsonData.accettazioneAnagrafica.identificativo.toUpperCase();
        let link = process.env.VUE_APP_PATH_API + me.pathResourceCodiceFiscale + me.jsonData.accettazioneAnagrafica.identificativo;
        axios
          .get(link)
          .then((response) => {
            me.jsonData.accettazioneAnagrafica.nome = response.data.data.nome;
            me.jsonData.accettazioneAnagrafica.cognome = response.data.data.cognome;
            me.jsonData.accettazioneAnagrafica.dataNascita = moment(response.data.data.dataNascita).toDate().getTime();
            me.setDatiNascita(response.data.data.provinciaNascitaSigla, response.data.data.comuneNascitaCodiceIstat);
            me.jsonData.accettazioneAnagrafica.sesso = response.data.data.sesso;
            me.jsonData.accettazioneAnagrafica.indirizzoResidenza = response.data.data.indirizzoResidenza;
            me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = response.data.data.indirizzoResidenza;
            me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat = response.data.data.comuneResidenzaCodiceIstat;
            me.jsonData.accettazioneAnagrafica.capResidenza = response.data.data.atleta.capResidenza;
            me.setDatiResidenza(response.data.data.provinciaResidenzaSigla, response.data.data.comuneResidenzaCodiceIstat);
            me.jsonData.accettazioneAnagrafica.provinciaDomicilio = response.data.data.provinciaResidenza;
            me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = response.data.data.atleta.comuneResidenzaCodiceIstat;
            me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
            me.jsonData.accettazioneAnagrafica.medicoCurante = response.data.data.atleta.medicoCurante;
            me.jsonData.accettazioneAnagrafica.codiceFiscaleMedicoCurante = response.data.data.atleta.codiceFiscaleMedicoCurante;
            // me.jsonData.sport = response.data.data.sport;
            // me.jsonData.tipoAtleta = response.data.data.tipoAttleta;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },

    // onSearchAnagraficaClick() {
    //     let me = this;
    //     me.showModalLoading = true;
    //     if (me.jsonData.accettazioneAnagrafica.codiceFiscale.trim().length === 0) {
    //         me.showModalLoading = false;
    //     } else {
    //         me.jsonData.accettazioneAnagrafica.codiceFiscale = me.jsonData.accettazioneAnagrafica.codiceFiscale.toUpperCase();
    //         let link = process.env.VUE_APP_PATH_API + "/utility";
    //         axios
    //             .get(link, { params: { codiceFiscale: me.jsonData.accettazioneAnagrafica.codiceFiscale } })
    //             .then((response) => {
    //                 me.jsonData.accettazioneAnagrafica.nome = response.data.data.nome;
    //                 me.jsonData.accettazioneAnagrafica.cognome = response.data.data.cognome;
    //                 me.jsonData.accettazioneAnagrafica.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY")
    //                     .toDate()
    //                     .getTime();
    //                 me.setDatiNascita(response.data.data.provinciaNascita, response.data.data.codiceIstatComuneNascita);
    //                 me.jsonData.accettazioneAnagrafica.sesso = response.data.data.sesso;

    //                 me.jsonData.accettazioneAnagrafica.indirizzoResidenza = response.data.data.indirizzoResidenza;
    //                 me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = response.data.data.indirizzoResidenza;
    //                 me.setDatiResidenza(response.data.data.provinciaResidenza, response.data.data.codiceIstatComuneResidenza);
    //                 // me.setDatiDomicilio(response.data.data.provinciaResidenza, response.data.data.codiceIstatComuneResidenza);
    //                 me.jsonData.accettazioneAnagrafica.provinciaDomicilio = response.data.data.provinciaResidenza;
    //                 me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = response.data.data.codiceIstatComuneResidenza;
    //                 me.jsonData.accettazioneAnagrafica.capDomicilio = me.jsonData.accettazioneAnagrafica.capResidenza;
    //                 me.jsonData.accettazioneAnagrafica.codiceFiscaleMedicoCurante = response.data.data.codiceFiscaleMedico;
    //                 me.jsonData.accettazioneAnagrafica.medicoCurante = response.data.data.medico;
    //                 me.showModalLoading = false;
    //             })
    //             .catch(() => {
    //                 me.showModalLoading = false;
    //             });
    //     }
    // },
  },
  watch: {
    "jsonData.accettazioneAnagrafica.capResidenza": {
      immediate: true,
      handler(value) {
        let me = this;
        if (value) {
          me.jsonData.accettazioneAnagrafica.capDomicilio = value;
        }
      },
    },
    "jsonData.accettazioneAnagrafica.provinciaNascita": {
      immediate: true,
      handler(value) {
        let me = this;
        if (value) {
          me.loadComuniNascita(value);
        }
      },
    },
    "jsonData.accettazioneAnagrafica.provinciaResidenza": {
      immediate: true,
      handler(value) {
        let me = this;
        if (value) {
          me.jsonData.accettazioneAnagrafica.provinciaDomicilio = value;
          me.loadComuniResidenza(value, me.jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat);
          me.setDatiAsl(value);
        }
      },
    },
    "jsonData.accettazioneAnagrafica.comuneResidenzaCodiceIstat": {
      immediate: true,
      handler(value) {
        let me = this;
        if (value) {
          me.jsonData.accettazioneAnagrafica.comuneDomicilioCodiceIstat = value;
        }
      },
    },
    "jsonData.accettazioneAnagrafica.indirizzoResidenza": {
      immediate: true,
      handler(value) {
        let me = this;
        if (value) {
          me.jsonData.accettazioneAnagrafica.indirizzoDomicilio = value;
        }
      },
    },
    "jsonData.sport": {
      immediate: true,
      handler(value) {
        let me = this;
        if (me.loadSport()) {
          me.onInputSport(value);
        }
      },
    },
  },
};
</script>
