<template>
    <div>
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Familiari" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblMother") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.madre) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblFather") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.padre) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblBrothers") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.fratelli) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblNumberBrothers") }}</label>
                    <span class="sa-data">{{ jsonData.numeroFratelli }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblSisters") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.sorelle) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblSisters") }}</label>
                    <span class="sa-data">{{ jsonData.numeroSorelle }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblSpouse") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.coniuge) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblSpouseStatus") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.coniugeSino) }}</span></b-col
                >
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblChildren") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.figli) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblNumberOfChildren") }}</label>
                    <span class="sa-data">{{ jsonData.numeroFigli }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblOther") }}</label>
                    <span class="sa-data">{{ jsonData.altro }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="this.getLabelTraduora('patients.lblDiseasesPresent')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblCalculosisOfTheGallbladder") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.calcolosiDellaColecisti) }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblGastricUlcer") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.ulceraGastrica) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblDuodenalUlcer") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.ulceraDuodenale) }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblStomachCarcinoma") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.carcinomaDelloStomaco) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblGastricLymphoma") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.linfomaGastrico) }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblRefluxEsophagitis") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.esofagiteDaReflusso) }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblColonCancer") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.carcinomaColon) }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblOther") }}</label>
                    <span class="sa-data">{{ jsonData.altraMalattia }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import axios from "axios";
export default {
    mixins: [UtilityMixin],
    props: {},
    data() {
        return { pathResource: "/gastroanamnesifamiliare", id: null, jsonData: {} };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>

<style></style>
