<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAssessmentVenue") }}</label>
          <b-form-input v-model="jsonData.sedeValutazione" class="text-upper" @input="onInputData" :disabled="!editSvamaA"></b-form-input>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDate") }}</label>
          <date-picker v-model="jsonData.dataValutazione" format="DD-MM-YYYY" value-type="timestamp" type="date" @input="onInputData" :disabled="!editSvamaA"></date-picker>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblAnamnesticNotes") }}</label>
          <b-form-textarea v-model="jsonData.cenniAnamnestici" class="text-upper" no-resize @input="onInputData" :disabled="!editSvamaA"></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblTreatmentsInPlace") }}</label>
          <b-form-textarea v-model="jsonData.trattamentiInAtto" class="text-upper" no-resize @input="onInputData" :disabled="!editSvamaA"></b-form-textarea>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <h5>{{ getLabelTraduora("adi.lblNursing") }}</h5>
      </b-row>
      <b-row>
        <b-col>
          <b-table sticky-header ref="tblValutazioni" id="tblValutazioni" responsive stacked="lg" striped hover :items="quesiti" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
            <template v-slot:cell(actions)="row">
              <b-form-group>
                <b-form-checkbox :checked="onRisposteChecked(row)" v-model="row.item.rispostaSelezionata" @change="onChangeCheckbox(row, $event)" :disabled="!editSvamaA"></b-form-checkbox>
              </b-form-group>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <label class="sa-label-data">{{ getLabelTraduora("adi.lblTotal") }} </label>
        <b-form-input v-model="jsonData.totaleAssistenzaInfermieristica" class="text-upper" disabled @input="onInputData"></b-form-input>
      </b-row>
      <hr />
      <b-row>
        <h5>{{ getLabelTraduora("adi.lblSensoryComunication") }}</h5>
      </b-row>
      <b-row>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblUnderstandingLanguage") }}</label>
          <b-form-select v-model="jsonData.linguaggioComprensione" :options="linguaggioComprensioneOptions" :value="null" value-field="value" text-field="text" @input="onInputData" :disabled="!editSvamaA"></b-form-select>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblProductionLanguage") }}</label>
          <b-form-select v-model="jsonData.linguaggioProduzione" :options="linguaggioProduzioneOptions" :value="null" value-field="value" text-field="text" @input="onInputData" :disabled="!editSvamaA"></b-form-select>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblHearing") }}</label>
          <b-form-select v-model="jsonData.udito" :options="uditoOptions" :value="null" value-field="value" text-field="text" @input="onInputData" :disabled="!editSvamaA"></b-form-select>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblView") }}</label>
          <b-form-select v-model="jsonData.vista" :options="vistaOptions" :value="null" value-field="value" text-field="text" @input="onInputData" :disabled="!editSvamaA"></b-form-select>
        </b-col>
      </b-row>
      <!-- <b-row>
            <h5>{{ getLabelTraduora("adi.lblPathologyCoding") }}</h5>
        </b-row>
         <b-row> DA CONTROLLARE
            <b-col>
                <table-codifica-patologie></table-codifica-patologie>
            </b-col>
        </b-row> -->
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
// import AdiAnagraficaComponent from "./AdiAnagraficaComponent.vue";
// import tableCodificaPatologie from "./tableCodificaPatologie.vue";
export default {
  props: {
    id: String,
    linkPrintData: String,
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { DatePicker }, //tableCodificaPatologie, AdiAnagraficaComponent
  data() {
    return {
      jsonData: {
        sedeValutazione: "",
        dataValutazione: null,
        cenniAnamnestici: "",
        trattamentiInAtto: "",
        totaleAssistenzaInfermieristica: null,
        linguaggioComprensione: null,
        linguaggioProduzione: null,
        udito: null,
        vista: null,
        adiValutazioneRispostaBean: [],
      },
      pathResource: "/adischedeasvama",
      linkLinguaggioComprensione: "/adischedeasvamalinguaggiocomprensione",
      linkLinguaggioProduzione: "/adischedeasvamalinguaggioproduzione",
      linkUdito: "/adischedeasvamaudito",
      linkVista: "/adischedeasvamavista",
      linkValutazioni: "/adivalutazioni",
      showModalLoading: false,
      linkback: null,
      quesiti: [],
      fields: [
        {
          label: "Quesito",
          key: "descrizione",
        },
        {
          label: "Risposta",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],
      linguaggioComprensioneOptions: [{ value: null, text: "-Seleziona Valore-", disabled: true }],
      linguaggioProduzioneOptions: [{ value: null, text: "--Seleziona Valore--", disabled: true }],
      uditoOptions: [{ value: null, text: "--Seleziona Valore--", disabled: true }],
      vistaOptions: [{ value: null, text: "--Seleziona Valore--", disabled: true }],
      editSvamaA: false,
    };
  },
  mounted() {
    let me = this;
    me.jsonData.id = this.$route.params.id;
    me.loadDefaultData();
    me.impostaPermessi();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      let fonte = this.$route.query.fonte;
      me.editSvamaA = false;
      if (fonte === null || fonte === undefined || fonte === "undefined" || fonte === "") {
        fonte = "/richieste";
      }
      if (fonte === "/richieste") {
        me.editSvamaA = UtilityMixin.methods.verificaPermessi(me.utente, "adischedeasvama", 2);
      }
    },
    onRisposteChecked(row) {
      let me = this;
      me.jsonData.adiValutazioneRispostaBean.forEach((element) => {
        if (element.risposta === row.item.descrizione) {
          row.item.rispostaSelezionata = true;
        }
      });
    },
    loadDefaultData() {
      let me = this;
      if (me.id !== "-1") {
        me.loadLinguaggioComprensione();
        me.loadLinguaggioProduzione();
        me.loadUdito();
        me.loadVista();
        me.loadValutazioni();
        me.loadData();
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
      me.showmolalloading = true;
      axios
        .get(link)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showmolalloading = false;
        })
        .catch(() => {
          me.showmolalloading = false;
        });
      me.$emit("update", me.jsonData);
    },
    loadLinguaggioComprensione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkLinguaggioComprensione + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.linguaggioComprensioneOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadLinguaggioProduzione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkLinguaggioProduzione + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.linguaggioProduzioneOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadUdito() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkUdito + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.uditoOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadVista() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkVista + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.vistaOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadValutazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=ASSISTENZA INFERMIERISTICA";
      axios.get(link).then((response) => {
        me.quesiti = [];
        response.data.data.list.forEach((element) => {
          element.rispostaSelezionata = false;
          me.quesiti.push(element);
        });
      });
    },
    onChangeCheckbox(row, value) {
      let me = this;
      row.item.rispostaSelezionata = value;
      if (value) {
        me.jsonData.adiValutazioneRispostaBean.push({ risposta: row.item.descrizione, valore: row.item.valore });
      } else {
        me.jsonData.adiValutazioneRispostaBean.forEach((element, index) => {
          if (element.risposta === row.item.descrizione) {
            me.jsonData.adiValutazioneRispostaBean.splice(index, 1);
          }
        });
      }
      let sum = 0;
      me.jsonData.adiValutazioneRispostaBean.forEach((element) => {
        sum = sum + element.valore;
      });
      me.jsonData.totaleAssistenzaInfermieristica = sum;
    },
    getJsonData() {
      let me = this;
      return me.jsonData;
    },
    onInputData() {
      this.jsonData.id = this.$route.params.id;
      this.$emit("update", this.jsonData);
    },
  },
};
</script>
