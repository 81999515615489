<template>
  <pazienti-list-component :pathResource="pathResource" :utente="utente" :fonte="fonte"></pazienti-list-component>
</template>

<script>
import PazientiListComponent from "../components/PazientiListComponent.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  props: {
    utente: { type: Object },
  },
  components: { PazientiListComponent },
  mixins: [UtilityMixin],
  data() {
    return {
      // showModalLoading: false,
      // btnNewVisible: false,
      pathResource: "/adiproposteaccesso",
      linkedit: "/adipazienti/richieste/scheda",
      fonte: "/all",
    };
  },
};
</script>
