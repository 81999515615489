<template>
  <sa-page-layout :btnSaveVisible="true" :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
    <template slot="table-body">
      <graffidi-device-edit-component :showModalLoading="showModalLoading" @update="onUpdateJsonData" @beforeLoadData="onBeforeLoadData" @afterLoadData="onAfterLoadData"></graffidi-device-edit-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import GraffidiDeviceEditComponent from "../components/GraffidiDeviceEditComponent.vue";
export default {
  components: { SaPageLayout, GraffidiDeviceEditComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      linkback: "/graffididevice",
      jsonData: {},
      showModalLoading: false,
      pathResource: "/digitalsigndevice",
    };
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onBeforeLoadData() {
      let me = this;
      me.showModalLoading = true;
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>

<style></style>
