import VisitaControlloEcg from "../visitacontrolloecg";
import EcocardioDati from "../ecocardiodati";
import VisitaAngiologica from "../visitaangiologica"
import WincareOldAdmin from "../wincareoldadmin"

const routes = [
    { name: "VisitaControlloEcgList", path: "/visitacontrolloecg", component: VisitaControlloEcg.VisitaControlloEcgList, meta: { title: "Visitta Controllo E.C.G." } },
    { name: "EcocardioDatiList", path: "/ecocardiodati", component: EcocardioDati.EcocardioDatiList, meta: { title: "Ecocardio Dati" } },
    { name: "VisitaAngiologicaList", path: "/visitaangiologica", component: VisitaAngiologica.VisitaAngiologicaList, meta: { title: "Visita Angiologica" } },
    { name: "WincareOldAdminList", path: "/wincareoldadmin", component: WincareOldAdmin.WincareOldAdminList, meta: { title: "Wincare Old Admin List" } },
    { name: "WincareOldAdminEventiList", path: "/wincareoldadmineventi", component: WincareOldAdmin.WincareOldAdminEventiList, meta: { title: "Wincare Old Admin Eventi List" } },
    // { name: "PazienteEdit", path: "/pazienti/edit/:id", component: Pazienti.PazientiEdit, meta: { title: "pazienti.lblTitle" } },
];

export default routes;
