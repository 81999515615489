<template>
  <div class="sa-agenda-container">
    <div class="sa-mounth-view">
      <div class="sa-panel">
        <div class="sa-agenda-mese-days-week">
          <div class="sa-agenda-mese-day-week">Lun.</div>
          <div class="sa-agenda-mese-day-week">Mar.</div>
          <div class="sa-agenda-mese-day-week">Mer.</div>
          <div class="sa-agenda-mese-day-week">Gio.</div>
          <div class="sa-agenda-mese-day-week">Ven.</div>
          <div class="sa-agenda-mese-day-week">Sab.</div>
          <div class="sa-agenda-mese-day-week">Dom.</div>
        </div>
        <div class="sa-agenda-mese">
          <div class="sa-week" v-for="settimana in calendarioMese" :key="settimana.settimana">
            <div class="sa-days" v-for="giorno in settimana.giorni" :key="giorno.giorno">
              <div :class="setCalssDayWeek(giorno)">
                <div class="sa-day-header">
                  <div class="sa-day-header-spacer"></div>
                  <div :class="setCalssDay(giorno)" @click="onDayClick(giorno)">{{ giorno.giorno }}</div>
                </div>
                <div class="sa-day-body"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
require("moment/locale/it");

// Choose Locale
moment.locale("it");
export default {
  data() {
    return {
      calendarioMese: [],
      dataSelezionata: null,
    };
  },
  mounted: function() {
    let me = this;
    me.setCalendarioMese(new Date());
  },
  methods: {
    onDayClick(giorno) {
      this.$emit("onDayClick", giorno);
    },
    setCalssDayWeek(giorno) {
      if (giorno.date.getDay() === 6) {
        return "sa-day sa-day-saturday";
      } else if (giorno.date.getDay() === 0) {
        return "sa-day sa-day-sunday";
      } else {
        return "sa-day";
      }
    },
    setCalssDay(giorno) {
      let className = giorno.today ? "sa-day-header-number sa-day-header-number-today" : "sa-day-header-number";

      if (giorno.anotherMonth) {
        className += " sa-day-header-number-another-month";
      }
      return className;
    },
    setCalendarioMese(date) {
      let me = this;
      let today = new Date();
      let firstDayMonthDate = new Date(date.getFullYear(), date.getMonth(), 1);
      let firstDayWeek = firstDayMonthDate.getDay();
      let startDay = 1 - firstDayWeek;
      let numberDay = 0;
      for (let y = 0; y < 6; y++) {
        let settimana = { settimana: y, giorni: [] };

        for (let x = 0; x < 7; x++) {
          let isToday = false;
          let isAnotherMonth = false;
          var previewDate = new Date(date.getFullYear(), date.getMonth(), numberDay + x + startDay + 1);
          if (previewDate.getDate() === today.getDate() && previewDate.getMonth() === today.getMonth() && previewDate.getFullYear() === today.getFullYear()) {
            isToday = true;
          }

          if (previewDate.getMonth() !== date.getMonth()) {
            isAnotherMonth = true;
          }

          settimana.giorni.push({ giorno: previewDate.getDate(), date: previewDate, today: isToday, anotherMonth: isAnotherMonth });
        }

        me.calendarioMese.push(settimana);

        numberDay += 7;
      }
    },
  },
};
</script>
<style lang="css">
.sa-mounth-view {
  display: inline-flex;
  flex: 1 0 100%;
  align-items: stretch;
  max-width: 100%;
}
.sa-agenda-mese {
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 0 0%;
  border: 1px solid #ddd;
}
.sa-week {
  display: flex;
  flex: 1 0 0%;
  border-bottom: 1px solid #ddd;
}
.sa-days {
  flex: 1 1 auto;
  display: flex;
}
.sa-day {
  flex: 1;
  color: #606060;
  border-right: 1px solid #ddd;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.sa-day-header {
  display: flex;
  color: #3c4043;
  padding: 1px 0.2em 0.1em 0.2em;
  align-items: center;
  justify-content: center;
}
.sa-day-body {
  font-size: 0.9em;
  flex: 1;
  flex-grow: 1;
}
.sa-day-header-spacer {
  flex: 1;
}
.sa-day-header-number {
  height: 1.5rem;
  width: 1.5rem;
}
.sa-day-header-number:hover {
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 50%;
}

.sa-day-header-number-today {
  background-color: #8fb8c7;
  color: white;
  border-radius: 50%;
}
.sa-day-header-number-another-month {
  color: #979797;
}
.sa-agenda-mese-days-week {
  display: flex;
}
.sa-agenda-mese-day-week {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  color: #606060;
  padding-right: 0.5em;
  padding-bottom: 0.25em;
}

.sa-day.sa-day-saturday {
  background-color: #f7f7f7;
}

.sa-day.sa-day-sunday {
  background-color: #eaeaea;
}
</style>
