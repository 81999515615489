<template>
    <sa-page-layout toggleableDimension="1366px" :showModalLoading="showModalLoading" :data="jsonData" :pathResource="pathResource" class="sa-no-space" :key="keyPageLayout">
        <template slot="toolbar-title">
            <paziente-anagrafica-leonida :data="jsonData"></paziente-anagrafica-leonida>
        </template>
        <template slot="toolbar">
            <b-button v-if="btnEditSpirometria" variant="btn-toolbar sa-margin-right float-sm-right" size="sm" v-b-modal.mdlSpirometria>
                <i class="bi bi-activity sa-icon-i"></i>
                Spirometria
            </b-button>
            <b-button v-if="btnEditEcgDopoSforzo" variant="cardioline btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onClickEcgRequest('DOPO SFORZO')">
                <i class="bi bi-activity sa-icon-i"></i>
                E.C.G. Dopo Sforzo
            </b-button>
            <b-button v-if="btnEditEcgRiposo" variant="cardioline btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onClickEcgRequest('RIPOSO')">
                <i class="bi bi-activity sa-icon-i"></i>
                E.C.G. Riposo
            </b-button>
            <b-button :hidden="hidden" variant="primary btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onEdit">
                <b-icon icon="pencil"></b-icon>
                Modifica
            </b-button>
            <b-button v-if="!edit" variant="secondary float-sm-right btn-toolbar" class="my-2 my-sm-0" size="sm" @click="onBack">
                <b-icon icon="chevron-left"></b-icon>
                Indietro
            </b-button>
            <b-button v-if="edit" variant="success btn-toolbar sa-margin-right float-sm-right" size="sm" :disabled="btnSalvaDisabled" @click="onSave">
                <b-icon icon="check2"></b-icon>
                Salva
            </b-button>
            <b-button v-if="edit" :hidden="btnAnnullaHidden" variant="danger btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onAnnullaModifiche">
                <b-icon icon="x"></b-icon>
                Annulla
            </b-button>
            <firma-component class="float-sm-right primary" :hidden="hiddenBtnFirma" :idEvento="jsonData.id" :pathRestFirma="pathRestFirma" :pathRestValidation="pathRestValidation" :data="jsonDataValidation" :disabled="btnFirmaDisabled" @update="onFirmaUpdate"></firma-component>
            <firma-multipla-component class="float-sm-right primary" :hidden="hiddenBtnFirmaMultipla" :idEvento="idEventoAccertamentiSupplementari" :pathRestFirma="pathRestFirma" :pathRestValidation="pathRestValidation" :data="jsonDataValidation" :disabled="btnFirmaMultiplaDisabled" @update="onFirmaUpdate"></firma-multipla-component>
        </template>
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab sa-tab-schede" fill @input="onTabInput">
                <b-tab active>
                    <template #title>
                        <div :class="getColorByFirma(jsonData.firma)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.firma)"></b-icon>
                            </div>
                            <div style="float: left"><span>Dati Gen.</span></div>
                        </div>
                    </template>
                    <!-- <div class="sa-tab-scroll"> -->
                    <dati-generali-edit-component v-if="editDg" :data="jsonData"></dati-generali-edit-component>
                    <dati-generali-view-component v-else :jsonData="jsonData" @update="onRefresh" :key="keyStoricoVisite"></dati-generali-view-component>
                    <!-- </div> -->
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <div :class="getColorByFirma(jsonData.visitaAnamnesi.firma)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.visitaAnamnesi.firma)"></b-icon>
                            </div>
                            <div style="float: left"><span>Anamnesi</span></div>
                        </div>
                        <!-- <span :class="getColorAnamnesi()"><b-icon :icon="getIconAnamnesi()"></b-icon>&nbsp;Anamnesi</span> -->
                    </template>
                    <!-- <div class="sa-tab-scroll"> -->
                    <anamnesi-edit v-if="editAnamnesi" :data="jsonData.visitaAnamnesi"></anamnesi-edit>
                    <anamnesi-view-component v-else :data="jsonData.visitaAnamnesi"></anamnesi-view-component>
                    <!-- </div> -->
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <div :class="getColorByFirma(jsonData.visitaEsameObbiettivo.firma)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.visitaEsameObbiettivo.firma)"></b-icon>
                            </div>
                            <div style="float: left"><span>Es. Obiettivo</span></div>
                        </div>
                        <!-- <span :class="getColorEsameObiettivo()"><b-icon :icon="getIconEsameObiettivo()"></b-icon>&nbsp;Esame Obiettivo</span> -->
                    </template>
                    <!-- <div class="sa-tab-scroll"> -->
                    <esame-obiettivo-edit v-if="editEo" :data="jsonData.visitaEsameObbiettivo"></esame-obiettivo-edit>
                    <esame-obiettivo-view-component v-else :data="jsonData.visitaEsameObbiettivo"></esame-obiettivo-view-component>
                    <!-- </div> -->
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <div :class="getColorByFirma(jsonData.visitaEsameUrine.firma)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.visitaEsameUrine.firma)"></b-icon>
                            </div>
                            <div style="float: left"><span>Urine</span></div>
                        </div>
                    </template>

                    <urine-edit-component v-if="editUrine" :data="jsonData.visitaEsameUrine"></urine-edit-component>
                    <urine-view-component v-else :data="jsonData.visitaEsameUrine"></urine-view-component>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <div :class="getColorByFirma(jsonData.visitaSpirografia.firma)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.visitaSpirografia.firma)"></b-icon>
                            </div>
                            <div style="float: left"><span>Spirografia</span></div>
                        </div>
                    </template>
                    <spirografia-edit-component v-if="editSpirografia" :data="jsonData.visitaSpirografia" :esameObiettivo="jsonData.visitaEsameObbiettivo"></spirografia-edit-component>
                    <spirografia-view-component v-else :data="jsonData.visitaSpirografia" :esameObiettivo="jsonData.visitaEsameObbiettivo"></spirografia-view-component>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <div :class="getColorByFirma(jsonData.visitaEcg.firma)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.visitaEcg.firma)"></b-icon>
                            </div>
                            <div style="float: left"><span>E.C.G.</span></div>
                        </div>
                    </template>
                    <ecg-edit v-if="editEcg" :jsonData="jsonData.visitaEcg" :idRisorsa="idRisorsa" :key="keyEcgEditComponent"></ecg-edit>
                    <ecg-view-component v-else :data="jsonData.visitaEcg" :idRisorsa="idRisorsa" :key="keyEcgViewComponent"></ecg-view-component>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <div :class="getColorByFirma(jsonData.visitaEsamiSpecialisticiIntegrativi.firma)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.visitaEsamiSpecialisticiIntegrativi.firma)"></b-icon>
                            </div>
                            <div style="float: left"><span>Es. Spec. Int.</span></div>
                        </div>
                    </template>
                    <!-- <div class="sa-tab-scroll"> -->
                    <esami-specialistici-integrativi-edit-component v-if="editEsi" :data="jsonData.visitaEsamiSpecialisticiIntegrativi"></esami-specialistici-integrativi-edit-component>
                    <esami-specialistici-integrativi-view-component v-else :data="jsonData.visitaEsamiSpecialisticiIntegrativi"></esami-specialistici-integrativi-view-component>
                    <!-- </div> -->
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <!-- <div :class="getColorByFirma(jsonData.visitaEsamiSpecialisticiIntegrativi.firma)"> -->
                        <div class="sa-tab-title-danger">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.visitaEsamiSpecialisticiIntegrativi.firma)"></b-icon>
                            </div>
                            <div style="float: left"><span>Acc. Sup.</span></div>
                        </div>
                    </template>
                    <accertamenti-supplementari-edit-component ref="accertamentiSupplementari" v-if="editAs" @update="onUpdateAccertamentiSupplementariPerFirma"></accertamenti-supplementari-edit-component>
                    <accertamenti-supplementari-view-component ref="accertamentiSupplementari" v-else @update="onUpdateAccertamentiSupplementariPerFirma"></accertamenti-supplementari-view-component>
                </b-tab>
                <b-tab :disabled="tabellaDisabilitata">
                    <template #title>
                        <div :class="getColorByFirma(jsonData.firma)">
                            <div style="float: left">
                                <b-icon :icon="getIconByFirma(jsonData.firma)"></b-icon>
                            </div>
                            <div style="float: left"><span>Giu. Concl.</span></div>
                        </div>
                    </template>
                    <giudizio-conclusivo-edit-component v-if="editGf" :data="jsonData"></giudizio-conclusivo-edit-component>
                    <giudizio-cocnlusivo-view-component v-else :data="jsonData"></giudizio-cocnlusivo-view-component>
                </b-tab>
            </b-tabs>
            <b-modal b-modal ref="mdlSpirometria" id="mdlSpirometria" size="md" style="height: 100%" @ok="onSpirometria" title="Seleziona Stanza">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-form-select v-model="mirVisita.postazioneRichiesta" :options="stanzeOptions" :value="''" value-field="identificativo" text-field="nomeStanza"></b-form-select>
                    </b-col>
                </b-row>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagraficaLeonida from "../components/PazienteAnagraficaLeonida.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";
import FirmaComponent from "../../paziente/eventi/utility/components/FirmaComponent.vue";
import FirmaMultiplaComponent from "../../paziente/eventi/utility/components/FirmaMultiplaComponent.vue";
import DatiGeneraliViewComponent from "../components/DatiGeneraliViewComponent.vue";
import DatiGeneraliEditComponent from "../components/DatiGeneraliEditComponent.vue";
import AnamnesiViewComponent from "../components/AnamnesiViewComponent.vue";
import EsameObiettivoViewComponent from "../components/EsameObiettivoViewComponent.vue";
import UrineViewComponent from "../components/UrineViewComponent.vue";
import SpirografiaViewComponent from "../components/SpirografiaViewComponent.vue";
import EcgViewComponent from "../components/EcgViewComponent.vue";
import AnamnesiEdit from "../components/AnamnesiEditComponent.vue";
import EsameObiettivoEdit from "../components/EsameObiettivoEditComponent.vue";
import UrineEditComponent from "../components/UrineEditComponent.vue";
import SpirografiaEditComponent from "../components/SpirografiaEditComponent.vue";
import EcgEdit from "../components/EcgEditComponent.vue";
import GiudizioCocnlusivoViewComponent from "../components/GiudizioConlusivoViewComponent.vue";
import GiudizioConclusivoEditComponent from "../components/GiudizioConclusivoEditComponent.vue";
import EsamiSpecialisticiIntegrativiEditComponent from "../components/EsamiSpecialisticiIntegrativiEditComponent.vue";
import EsamiSpecialisticiIntegrativiViewComponent from "../components/EsamiSpecialisticiIntegrativiViewComponent.vue";
import AccertamentiSupplementariEditComponent from "../components/AccertamentiSupplementariEditComponent.vue";
import AccertamentiSupplementariViewComponent from "../components/AccertamentiSupplementariViewComponent.vue";

export default {
    mixins: [UtilityMixin],
    components: {
        SaPageLayout,
        PazienteAnagraficaLeonida,
        FirmaComponent,
        FirmaMultiplaComponent,
        DatiGeneraliViewComponent,
        DatiGeneraliEditComponent,
        AnamnesiViewComponent,
        EsameObiettivoViewComponent,
        UrineViewComponent,
        SpirografiaViewComponent,
        EcgViewComponent,
        AnamnesiEdit,
        EsameObiettivoEdit,
        UrineEditComponent,
        SpirografiaEditComponent,
        EcgEdit,
        GiudizioCocnlusivoViewComponent,
        GiudizioConclusivoEditComponent,
        EsamiSpecialisticiIntegrativiEditComponent,
        EsamiSpecialisticiIntegrativiViewComponent,
        AccertamentiSupplementariEditComponent,
        AccertamentiSupplementariViewComponent,
    },
    data() {
        return {
            btnEditEcgRiposo: false,
            btnEditEcgDopoSforzo: false,
            btnEditSpirometria: false,
            idRisorsa: "-1",
            keyEcgEditComponent: 0,
            keyEcgViewComponent: 0,
            keyPageLayout: 0,
            keyStoricoVisite: 0,
            id: null,
            idEventoAccertamentiSupplementari: null,
            showModalLoading: false,
            linkback: null,
            edit: false,
            editDg: false,
            editAnamnesi: false,
            editEo: false,
            editUrine: false,
            editSpirografia: false,
            editEcg: false,
            editAs: false,
            editEsi: false,
            editGf: false,
            hidden: false,
            btnAnnullaHidden: false,
            hiddenBtnFirma: false,
            hiddenBtnFirmaMultipla: false,
            tabellaDisabilitata: false,
            pathResource: "/leonidavisite",
            pathMir: "/mirvisite",
            pathResourceSalvataggio: "",
            pathResourceAgenda: "/agende",
            pathResourceAgendaDisponibilita: "/agendadisponibilita",
            pathResourceCardioline: "/cardioline/send",
            pathResourceStanze: "/mirstanze",
            pathRestFirma: null,
            indexTabAttiva: 0,
            pathRestValidation: "/validation",
            btnFirmaDisabled: false,
            btnFirmaMultiplaDisabled: false,
            btnSalvaDisabled: false,
            jsonDataValidation: {},
            jsonData: {
                idAttleta: null,
                atleta: {
                    tipo: "PATIENT",
                    identificativo: null,
                    tipoIdentificativo: null,
                    cognome: null,
                    nome: null,
                    dataNascita: null,
                    comuneNascitaCodiceIstat: null,
                    comuneResidenzaCodiceIstat: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoResidenza: null,
                    indirizzoDomicilio: null,
                    note: null,
                    medicoCurante: null,
                    codiceFiscaleMedicoCurante: null,
                    capDomicilio: null,
                    capResidenza: null,
                    telefono: null,
                    email: null,
                    sesso: null,
                    codiceStruttura: null,
                    comuneResidenza: null,
                    comuneNascita: null,
                    provinciaResidenza: null,
                    provinciaNascita: null,
                },
                tipoAttleta: null,
                statoAttuale: "",
                visitaEsameUrine: { id: null, firma: null },
                visitaAnamnesi: { id: null },
                visitaEsameObbiettivo: { id: null },
                visitaSpirografia: { id: null, firma: null },
                visitaEcg: { id: null, firma: null },
                visitaEsamiSpecialisticiIntegrativi: { id: null, firma: null },
            },
            dataAgenda: {},
            stanzeOptions: [],
            agendeOptions: [{ id: null, titolo: "-Seleziona Agenda-" }],
            disponibilitaOptions: [{ id: null, dalleOre: "-Seleziona Orario-" }],
            mirVisita: { idVisita: "", postazioneRichiesta: "" },
        };
    },
    created() {},
    updated() {
        let me = this;
        me.idRisorsa = me.jsonData.id;
    },
    mounted() {
        let me = this;
        me.init(this.$route.params.id);
    },
    methods: {
        init(id) {
            let me = this;
            me.id = id;
            console.log(me.id);
            me.linkback = "/worklist";
            me.loadData();
        },
        getColorByFirma(firma) {
            // let me = this;
            let colore = firma ? "sa-tab-title-success text-success" : "sa-tab-title-danger text-danger";

            return colore;
        },
        getIconByFirma(firma) {
            let icon = firma ? "check2" : "exclamation-triangle";
            return icon;
        },
        editFirma() {
            let me = this;
            if (me.edit) {
                me.editAnamnesi = me.jsonData.visitaAnamnesi.firma !== null ? false : true;
                me.editEo = me.jsonData.visitaEsameObbiettivo.firma !== null ? false : true;
                me.editUrine = me.jsonData.visitaEsameUrine.firma !== null ? false : true;
                me.editSpirografia = me.jsonData.visitaSpirografia.firma !== null ? false : true;
                me.editEcg = me.jsonData.visitaEcg.firma !== null ? false : true;
                me.editAs = me.jsonData.visitaEsamiSpecialisticiIntegrativi.firma !== null ? false : true;
            }
        },
        onEdit() {
            let me = this;
            me.loadData();
            me.edit = true;
            me.btnEditEcgRiposo = false;
            me.btnEditEcgDopoSforzo = false;
            me.btnEditSpirometria = false;
            me.hidden = true;
            me.editDg = true;
            me.editFirma();
            me.editEsi = true;
            me.editAs = true;
            me.editGf = true;
            if (me.jsonData.firma !== null) {
                me.editDg = false;
                me.editAnamnesi = false;
                me.editEo = false;
                me.editUrine = false;
                me.editSpirografia = false;
                me.editEcg = false;
                me.editEsi = false;
                me.editAs = false;
                me.editGf = false;
                me.edit = false;
                me.hidden = false;
                this.$bvModal
                    .msgBoxOk("Impossibile effettuare modifiche, documento già validato", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        this.boxOne = value;
                    });
            }
        },
        verificaStatoAttuale(value) {
            let me = this;
            if (value === "CHIUSA") {
                me.hidden = true;
                me.hiddenBtnFirma = true;
                me.hiddenBtnFirmaMultipla = true;
                me.linkback = "/archiviovisite";
            } else {
                // me.hidden = false;
                me.hiddenBtnFirma = false;
                me.hiddenBtnFirmaMultipla = false;
                me.linkback = "/worklist";
            }
        },
        onAnnullaModifiche() {
            let me = this;
            me.edit = false;
            me.btnEditEcgRiposo = false;
            me.btnEditEcgDopoSforzo = false;
            me.btnEditSpirometria = false;
            me.hidden = false;
            me.editDg = false;
            me.editAnamnesi = false;
            me.editEo = false;
            me.editUrine = false;
            me.editSpirografia = false;
            me.editEcg = false;
            me.editEsi = false;
            me.editAs = false;
            me.editGf = false;
            me.loadData();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.jsonData.statoAttuale = "";
                me.loadAgende();
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                    me.loadStanze();
                    me.loadAgende();
                    // me.onTabInput(me.indexTabAttiva);
                    me.editFirma();
                    me.verificaStatoAttuale(me.jsonData.statoAttuale);
                    me.disableButton();
                    me.keyStoricoVisite = me.keyStoricoVisite + 1;
                    // me.setFormatStato();
                });
            }
        },
        loadAgende() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAgenda;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.dataAgenda = response.data.data;
                    me.agendeOptions = me.dataAgenda;
                    me.agendeOptions.unshift({ id: null, titolo: "-Seleziona Agenda-" });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadStanze() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStanze;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.stanzeOptions = response.data.data.list;
                    me.stanzeOptions.unshift({ identificativo: "", nomeStanza: "-Seleziona Stanza-" });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onInputVisita(value) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAgendaDisponibilita;
            axios
                .get(link, {
                    params: {
                        idAgenda: value,
                        periodo: "G",
                        riferimento: moment(me.jsonData.dataVisita).format("YYYY-MM-DD"),
                        codicePrestazione: me.jsonData.tipoVisita,
                    },
                })
                .then((response) => {
                    if (response.data.data.length > 0) {
                        me.disponibilitaOptions = response.data.data[0].disponibilita;
                        me.disponibilitaOptions.unshift({ id: null, dalleOre: "-Seleziona Orario-" });
                    }
                });
        },
        onTabInput(index) {
            let me = this;
            me.indexTabAttiva = index;
            me.disableButton(index);
        },
        disableButton() {
            let me = this;
            me.btnEditEcgRiposo = false;
            me.btnEditEcgDopoSforzo = false;
            me.btnEditSpirometria = false;
            if (me.id === "-1") {
                let me = this;
                me.edit = true;
                me.hidden = true;
                me.btnFirmaDisabled = true;
                me.hiddenBtnFirma = false;
                me.hiddenBtnFirmaMultipla = true;
                me.btnFirmaMultiplaDisabled = true;
                me.editDg = true;
                me.btnAnnullaHidden = true;
                me.tabellaDisabilitata = true;
            } else {
                switch (me.indexTabAttiva) {
                    case 0:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = null;
                        me.btnFirmaDisabled = true;
                        me.btnFirmaMultiplaDisabled = true;
                        me.hiddenBtnFirma = true;
                        me.hiddenBtnFirmaMultipla = true;
                        me.pathResourceSalvataggio = me.pathResource;
                        me.jsonDataSalvataggio = me.jsonData;
                        break;
                    case 1:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/certificatianamnesi";
                        me.jsonDataValidation = me.jsonData.visitaAnamnesi;
                        me.pathResourceSalvataggio = "/certificatianamnesi";
                        me.jsonDataSalvataggio = me.jsonData.visitaAnamnesi;
                        me.hiddenBtnFirma = false;
                        me.hiddenBtnFirmaMultipla = true;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                            me.btnFirmaMultiplaDisabled = true;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.btnFirmaMultiplaDisabled = true;
                        }
                        if (me.jsonData.statoAttuale === "CHIUSA") {
                            me.hiddenBtnFirma = true;
                        } else {
                            me.hiddenBtnFirma = false;
                        }

                        break;
                    case 2:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/certificatiesameobbiettivo";
                        me.jsonDataValidation = me.jsonData.visitaEsameObbiettivo;
                        me.pathResourceSalvataggio = "/certificatiesameobbiettivo";
                        me.jsonDataSalvataggio = me.jsonData.visitaEsameObbiettivo;
                        me.hiddenBtnFirma = false;
                        me.hiddenBtnFirmaMultipla = true;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                            me.btnFirmaMultiplaDisabled = true;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.btnFirmaMultiplaDisabled = true;
                        }
                        if (me.jsonData.statoAttuale === "CHIUSA") {
                            me.hiddenBtnFirma = true;
                        } else {
                            me.hiddenBtnFirma = false;
                        }
                        break;
                    case 3:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/visiteesamiurine";
                        me.jsonDataValidation = me.jsonData.visitaEsameUrine;
                        me.pathResourceSalvataggio = "/visiteesamiurine";
                        me.jsonDataSalvataggio = me.jsonData.visitaEsameUrine;
                        me.hiddenBtnFirma = false;
                        me.hiddenBtnFirmaMultipla = true;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                            me.btnFirmaMultiplaDisabled = true;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.btnFirmaMultiplaDisabled = true;
                        }
                        if (me.jsonData.statoAttuale === "CHIUSA") {
                            me.hiddenBtnFirma = true;
                        } else {
                            me.hiddenBtnFirma = false;
                        }
                        break;
                    case 4:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/visitespirografie";
                        me.jsonDataValidation = me.jsonData.visitaSpirografia;
                        me.pathResourceSalvataggio = "/visitespirografie";
                        me.jsonDataSalvataggio = me.jsonData.visitaSpirografia;
                        me.hiddenBtnFirma = false;
                        me.hiddenBtnFirmaMultipla = true;
                        me.btnEditSpirometria = true;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                            me.btnFirmaMultiplaDisabled = true;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.btnFirmaMultiplaDisabled = true;
                        }
                        if (me.jsonData.statoAttuale === "CHIUSA") {
                            me.hiddenBtnFirma = true;
                        } else {
                            me.hiddenBtnFirma = false;
                        }
                        break;
                    case 5:
                        me.keyEcgViewComponent++;
                        me.btnSalvaDisabled = false;
                        if (me.editEcg) {
                            me.btnEditEcgRiposo = true;
                            me.btnEditEcgDopoSforzo = true;
                        }
                        me.pathRestFirma = "/visiteecg";
                        me.jsonDataValidation = me.jsonData.visitaEcg;
                        me.pathResourceSalvataggio = "/visiteecg";
                        me.jsonDataSalvataggio = me.jsonData.visitaEcg;
                        me.hiddenBtnFirma = false;
                        me.hiddenBtnFirmaMultipla = true;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                            me.btnFirmaMultiplaDisabled = true;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.btnFirmaMultiplaDisabled = true;
                        }
                        if (me.jsonData.statoAttuale === "CHIUSA") {
                            me.hiddenBtnFirma = true;
                        } else {
                            me.hiddenBtnFirma = false;
                        }
                        break;
                    case 6:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = "/visitaesamispecialisticiintegrativi";
                        me.jsonDataValidation = me.jsonData.visitaEsamiSpecialisticiIntegrativi;
                        me.pathResourceSalvataggio = "/visitaesamispecialisticiintegrativi";
                        me.jsonDataSalvataggio = me.jsonData.visitaEsamiSpecialisticiIntegrativi;
                        me.hiddenBtnFirma = false;
                        me.hiddenBtnFirmaMultipla = true;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                            me.btnFirmaMultiplaDisabled = true;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.btnFirmaMultiplaDisabled = true;
                        }
                        if (me.jsonData.statoAttuale === "CHIUSA") {
                            me.hiddenBtnFirma = true;
                        } else {
                            me.hiddenBtnFirma = false;
                        }
                        break;
                    case 7:
                        me.pathRestFirma = "/visiterichiestaesamisupplementari";
                        me.btnSalvaDisabled = true;
                        me.btnFirmaDisabled = true;
                        me.hiddenBtnFirma = true;
                        me.btnFirmaMultiplaDisabled = false;
                        me.hiddenBtnFirmaMultipla = false;
                        me.$refs.accertamentiSupplementari.loadData();
                        if (me.jsonData.statoAttuale === "CHIUSA") {
                            me.hiddenBtnFirmaMultipla = true;
                        } else {
                            me.hiddenBtnFirmaMultipla = false;
                        }
                        break;
                    case 8:
                        me.btnSalvaDisabled = false;
                        me.pathRestFirma = me.pathResource;
                        me.jsonDataValidation = me.jsonData;
                        me.pathResourceSalvataggio = me.pathResource;
                        me.jsonDataSalvataggio = me.jsonData;
                        me.hiddenBtnFirma = false;
                        me.hiddenBtnFirmaMultipla = true;
                        if (me.jsonDataValidation.firma === null || me.jsonDataValidation.firma === "") {
                            me.btnFirmaDisabled = false;
                            me.btnFirmaMultiplaDisabled = true;
                        } else {
                            me.btnFirmaDisabled = true;
                            me.btnFirmaMultiplaDisabled = true;
                        }
                        if (me.jsonData.statoAttuale === "CHIUSA") {
                            me.hiddenBtnFirma = true;
                        } else {
                            me.hiddenBtnFirma = false;
                        }
                        break;
                }
            }
        },
        onBack() {
            let me = this;
            me.$router.replace(me.linkback).catch((err) => {
                err;
            });
        },
        onSave() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSalvataggio;
            if (me.jsonDataSalvataggio.id === "-1" || me.jsonDataSalvataggio.id === null || me.jsonDataSalvataggio.id === undefined) {
                me.jsonDataSalvataggio.id = me.jsonData.id;
                axios
                    .post(link, JSON.stringify(me.jsonDataSalvataggio))
                    .then((response) => {
                        me.id = response.data.data.id;
                        me.showModalLoading = false;
                        me.onAnnullaModifiche();
                    })
                    .catch((error) => {
                        me.jsonDataSalvataggio.id = null;
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                link = link + "/" + me.jsonDataSalvataggio.id;
                axios
                    .put(link, JSON.stringify(me.jsonDataSalvataggio))
                    .then(() => {
                        me.showModalLoading = false;
                        me.onAnnullaModifiche();
                    })
                    .catch((error) => {
                        console.log(error);
                        me.showModalLoading = false;
                        this.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                console.log(value);
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            }
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateAccertamentiSupplementariPerFirma(rows) {
            let me = this;
            let array = [];
            rows.forEach((element) => {
                if (element.statoAttuale === "DA FIRMARE") {
                    delete element._cellVariants;
                    me.idEventoAccertamentiSupplementari = element.id;
                    array.push(element);
                }
            });
            me.jsonDataValidation = array;
        },
        onRefresh(id) {
            let me = this;
            me.init(id);
            me.keyPageLayout++;
        },
        onClickEcgRequest(tipo) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let examType = tipo === "DOPO SFORZO" ? "STRESS" : "ECG";
            let cardiolineRequest = { tipoEsame: tipo, examType: examType, unitId: 1, visitNumber: 1, firstName: me.jsonData.nome, lastName: me.jsonData.cognome, patientId: me.jsonData.codiceFiscale, gender: me.jsonData.sesso, birthdate: moment(new Date(me.jsonData.dataNascita)).format("YYYYMMDD"), action: "NEW", idRisorsa: me.jsonData.id };
            // console.log(cardiolineRequest);
            me.showModalLoading = true;
            axios
                .get(link, { params: { idAtleta: me.jsonData.idAtleta } })
                .then((response) => {
                    cardiolineRequest.visitNumber = response.data.data.length;
                    me.showModalLoading = false;
                    link = process.env.VUE_APP_PATH_API + me.pathResourceCardioline;
                    axios
                        .post(link, cardiolineRequest)
                        .then(() => {
                            me.showModalLoading = false;
                            me.keyEcgEditComponent++;
                            me.keyEcgViewComponent++;
                        })
                        .catch((error) => {
                            me.showModalLoading = false;
                            this.$bvModal
                                .msgBoxOk(error.response.data.messaggio, {
                                    title: "ATTENZIONE",
                                    size: "sm",
                                    okVariant: "outline-danger",
                                    headerClass: "sa-msg-header-danger",
                                    footerClass: "p-2 border-top-0",
                                    centered: true,
                                })
                                .then((value) => {
                                    console.log(value);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        });
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSpirometria() {
            let me = this;
            // console.log("dati inviati correttamente");
            me.mirVisita.idVisita = me.jsonData.id;
            me.mirVisita.idPaziente = me.jsonData.idAtleta;
            me.salvaMirVisita();
        },
        salvaMirVisita() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathMir;
            axios
                .post(link, JSON.stringify(me.mirVisita))
                .then((response) => {
                    me.mirVisita = response.data.data;
                    // me.saveSuccess(false, response.data.data.id);
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk("Richiesta spirografia effettuata", {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                    me.mirVisita = { idVisita: "", postazioneRichiesta: "" };
                })
                .catch((error) => {
                    console.log(error);
                    me.showModalLoading = false;
                    this.$bvModal
                        .msgBoxOk("Spirografia gia' richiesta", {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then(() => {})
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>
<style scoped>
.btn-cardioline {
    color: #fff;
    background-color: #ee5b00;
    border-color: #ee5b00;
}

.btn-cardioline:hover {
    color: #ee5b00;
    background-color: #fff;
    border-color: #ee5b00;
}
.sa-icon-i {
    margin-right: 10px;
    height: 21px;
    width: 21px;
    font-size: 1.4rem;
    font-size: 1.4rem;
    margin-top: -5px;
}
</style>
