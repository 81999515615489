<template>
  <sa-page-layout :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <digital-sign-log-view-component @showmodalloading="onShowModalLoading" @update="onUpdateJsonData"></digital-sign-log-view-component>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DigitalSignLogViewComponent from "../components/DigitalSignLogViewComponent.vue";
export default {
  components: { SaPageLayout, DigitalSignLogViewComponent },
  data() {
    return {
      linkback: "/graffidilog",
      pathResource: "/digitalsignlog",
      showModalLoading: false,
      id: null,
      jsonData: {},
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
  },
  methods: {
    onShowModalLoading(value) {
      let me = this;
      me.showModalLoading = value;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
  },
};
</script>

<style></style>
