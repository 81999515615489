<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblOrigin") }}</label>
                    <b-form-input v-model="jsonData.provenienza"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Strumento</label>
                    <b-form-select v-model="jsonData.strumento" :options="strumentiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblPremedication") }}</label>
                    <b-form-input v-model="jsonData.premedicazione"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblReasonExam") }}</label>
                    <b-form-textarea v-model="jsonData.motivoEsame" rows="4" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Esame" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblEndoscopy") }}</label>
                    <b-form-textarea v-model="jsonData.endoscopia" rows="8" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblBiliaryRadiology") }}</label>
                    <b-form-textarea v-model="jsonData.radiologiaViaBiliare" rows="8" no-resize></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblRadiologyPancreas") }}</label>
                    <b-form-textarea v-model="jsonData.radiologiaPancreas" rows="8" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblDiagnosis") }}</label>
                    <b-form-textarea v-model="jsonData.diagnosi" rows="8" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Consigli Terapeutici</label>
                    <b-form-textarea v-model="jsonData.consigliTerapeutici" rows="8" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <eventi-operatori-component :operatori="jsonData.listaOperatori" @update="onUpdateListaOperatori" :isEdit="isEdit"></eventi-operatori-component>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
    components: { DatePicker, EventiOperatoriComponent },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    provenienza: "",
                    motivoEsame: "",
                    premedicazione: "",
                    endoscopia: "",
                    radiologiaViaBiliare: "",
                    radiologiaPancreas: "",
                    diagnosi: "",
                    consigliTerapeutici: "",
                    strumento: "",
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        me.loadStrumenti();
    },

    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            isEdit: true,
            selectFirstElement: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            pathResource: "/gastroercp",
            pathResourceStrumenti: "/pazienteeventistrumenti",
            showModalLoading: false,
            strumentiOptions: [{ value: "", text: "-Seleziona Strumento-" }],
            filtro: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        loadStrumenti() {
            let me = this;
            //  me.filtro.page = me.currentPage;
            //    me.filtro.forPage = me.perPage;
            me.filtro.gruppo = "ERCP";
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrumenti;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.showModalLoading = false;
                    response.data.data.list.forEach((element) => {
                        me.strumentiOptions.push({ value: element.modello + " (MAT: " + element.codice + ")", text: element.modello + " (MAT: " + element.codice + ")" });
                    });
                })
                .catch();
        },
        onUpdateListaOperatori(listaOperatori) {
            let me = this;
            me.jsonData.listaOperatori = listaOperatori;
        },
    },
};
</script>
