<template>
  <sa-page-layout :btnSaveVisible="true" :data="jsonData" :btnAnnullaVisible="true" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" class="sa-no-space">
    <template slot="table-body">
      <digital-sign-edit-component :jsonData="jsonData"></digital-sign-edit-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DigitalSignEditComponent from "../components/DigitalSignEditComponent.vue";
export default {
  components: { SaPageLayout, DigitalSignEditComponent },
  data() {
    return {
      pathResource: "",
      linkback: "/digitalsign",
      showModalLoading: false,
      jsonData: { modalita: null, tipoFirma: null, marca: null },
    };
  },
};
</script>

<style></style>
