<template>
  <div>
    <b-card class="sa-card" header="GIUDIZIO CONCLUSIVO" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Giudizio </label>
          <b-form-select v-model="data.giudizio" id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0" :options="giudizioOptions" :value="null"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Validita' Mesi </label>
          <b-form-input v-model="data.validitaCertificato"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Scadenza </label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataScadenza" type="datetime" placeholder="Data Scadenza"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Giudizio conclusivo di non idoneità o sospensione </label>
          <b-form-textarea :disabled="disabilitato" v-model="data.giudizioConclusivo" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin.js";
import DatePicker from "vue2-datepicker";
export default {
  mixins: [UtilityMixin],
  components: { DatePicker },
  props: {
    data: {
      Type: Object,
      default: function () {
        return {
          giudizio: "",
          giudizioConclusivo: "",
          validita: null,
          dataScadenza: null,
        };
      },
    },
  },
  data() {
    return {
      pathRestFirma: "/certificatianamnesi",
      giudizioOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "IDONEO", text: "IDONEO" },
        { value: "NON IDONEO", text: "NON IDONEO" },
        { value: "SOSPESO", text: "SOSPESO" },
        { value: "SOSPESO MANCANO URINE", text: "SOSPESO MANCANO URINE" },
        { value: "SOSPESO IN ATTESA DI ACCERTAMENTI DIAGNOSTICI", text: "SOSPESO IN ATTESA DI ACCERTAMENTI DIAGNOSTICI" },
      ],
      disabilitato: false,
    };
  },
  mounted() {},
  methods: {},
  watch: {
    data: {
      handler(jsonData) {
        console.log(jsonData);
      },
      deep: true,
    },
    "data.giudizio": {
      immediate: true,
      handler(value) {
        let me = this;
        if (value === "IDONEO" || value === null) {
          me.disabilitato = true;
        } else {
          me.disabilitato = false;
        }
      },
    },
  },
};
</script>
