<template>
  <div v-if="isEdit">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Sanitario Notificante</label>
        <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Telefono Sanitario Notificante</label>
        <b-form-input v-model="jsonData.sanitarioNotificanteContatto"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data Notifica</label>
        <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Sanitario Notificante</label>
        <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Telefono Sanitario Notificante</label>
        <span class="sa-data">{{ jsonData.sanitarioNotificanteContatto }}</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Data Notifica</label>
        <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  mixins: [UtilityMixin],
  components: { DatePicker },
  props: {
    jsonData: {
      type: Object,
      default: function () {
        return null;
      },
    },
    isEdit: Boolean,
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
};
</script>

<style></style>
