<template>
  <sa-page-layout :pathResource="pathResource" :btnEditVisible="true" :btnAnnullaVisible="true" :btnFirmaVisible="btnFirmaVisible" :idEvento="idEvento" :pathResourceFirma="pathResourceFirma" :linkback="linkback" :linkedit="linkedit" class="sa-no-space">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <visita-component :idEvento="idEvento"></visita-component>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import VisitaComponent from "./VisitaComponent.vue";
import Vue from "vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { SaPageLayout, PazienteAnagrafica, VisitaComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/visitehh",
      pathResourceFirma: "/pazienteeventi",
      linkedit: "/paziente/eventi/visite/edit/",
      linkback: "/paziente/eventi/visite/",
      btnFirmaVisible: false,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    idEvento() {
      return this.$route.params.id;
    },
    linkPatient: {
      get: function () {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/eventi/visita/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.linkback = "/paziente/eventi/visite?idPaziente=" + me.idPaziente;
    me.impostaPermessi();
  },
  methods: {
    loadData() {},
    impostaPermessi() {
      let me = this;
      me.btnFirmaVisible = false;
      me.btnFirmaVisible = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "pazienteeventi", 64);
    },
  },
};
</script>
