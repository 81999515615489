<template>
  <div>
    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
              <span class="sa-data">{{ formatDate(jsonData.dataEvento) }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Data Prescrizione</label>
              <span class="sa-data">{{ formatDate(jsonData.dataPrescrizione) }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Codice Prescrizione</label>
              <span class="sa-data">{{ jsonData.codicePrescrizione }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">
                {{ getLabelTraduora("patient.cardiology.lblWeight") }}
                <eventi-storico-misure-component misura="peso" misuraLabel="Peso" unitaMisura="kg" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
              </label>
              <span class="sa-data">{{ jsonData.peso }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
              <span class="sa-data">{{ jsonData.altezza }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data ft-color-calculated-fields">
                <i class="fas fa-calculator"></i>
                {{ getLabelTraduora("patient.cardiology.lblBMI") }}
                <eventi-storico-misure-component misura="bmi" misuraLabel="BMI" unitaMisura="(kg/m2)" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
              </label>
              <span class="sa-data">{{ jsonData.bmi }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWaistCircumference") }}</label>
              <span class="sa-data">{{ jsonData.circvita }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHipsCircumference") }}</label>
              <span class="sa-data">{{ jsonData.circfianchi }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSmoking") }}</label>
              <span class="sa-data">{{ jsonData.fumo }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblOnGoingTherapies')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-table ref="tblTerapiaInCorso" sticky-header stacked="xl" striped hover itemscope :items="listaFarmaciTerapiaInCorso" :fields="fieldsListaFarmaciTerapiaInCorso" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
              <template #cell(index)="row">
                {{ row.index + 1 }}
              </template>
            </b-table>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="Dati Terapia" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFollowsTherapy") }}</label>
              <span class="sa-data">{{ jsonData.segueterapia }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSideEffects") }}</label>
              <span class="sa-data">{{ jsonData.effetticollaterali }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInfoSideEffects") }}</label>
              <span class="sa-data">{{ jsonData.qualieffetti }}</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRecoveryType") }}</label>
              <span class="sa-data">{{ jsonData.tipoDiRicoveroCardio }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRecoveryCause") }}</label>
              <span class="sa-data">{{ jsonData.causaRicoveroCardio }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExtraRecoveryType") }}</label>
              <span class="sa-data">{{ jsonData.tipoDiRicoveroExtra }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExtraRecoveryCause") }}</label>
              <span class="sa-data">{{ jsonData.causaRicoveroExtra }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="Valori P.A. a Domicilio" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">P.A.S.</label>
              <span class="sa-data">{{ jsonData.pasdomicilio }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">P.A.D.</label>
              <span class="sa-data">{{ jsonData.paddomicilio }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7">
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAnamnesys')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNextAnamnesys") }}</label>
              <span class="sa-data-justify">{{ jsonData.anamnesiProssima }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Esame Obiettivo Generale e Cardiovascolare</label>
              <span class="sa-data-justify">{{ jsonData.anamnesiGeneraleCardiovascolare }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Relazione Elettrocardiogramma</label>
              <span class="sa-data-justify">{{ jsonData.relazioneElettrocardiogramma }}</span>
            </b-col> -->
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
        <b-card class="sa-card" header="Relazione Elettrocardiogramma" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Ritmo</label>
              <span class="sa-data-justify">{{ jsonData.relazioneElettrocardiogrammaRitmo }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Frequenza Cardiaca (b/min)</label>
              <span class="sa-data-justify">{{ jsonData.relazioneElettrocardiogrammaFrequenzaCardiaca }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Onda P (msee)</label>
              <span class="sa-data-justify">{{ jsonData.relazioneElettrocardiogrammaOndaP }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">P-R (msee)</label>
              <span class="sa-data-justify">{{ jsonData.relazioneElettrocardiogrammaPr }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">QRS (msee)</label>
              <span class="sa-data-justify">{{ jsonData.relazioneElettrocardiogrammaQrs }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Tratto ST</label>
              <span class="sa-data-justify">{{ jsonData.relazioneElettrocardiogrammaTrattoSt }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Conclusioni</label>
              <span class="sa-data-justify">{{ jsonData.relazioneElettrocardiogrammaConclusioni }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="Dati Clinici" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">Classe NYHA</label>
              <span class="sa-data">{{ jsonData.nyha }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">Stadio Scompenso</label>
              <span class="sa-data">{{ jsonData.stadioScompenso }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">Polsi Arteriosi</label>
              <span class="sa-data">{{ jsonData.polsi }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Elettrocardiogramma</label>
              <span class="sa-data">{{ jsonData.ecgIvs }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Attivita Fisica</label>
              <span class="sa-data">{{ jsonData.attFisica }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
        <b-card class="sa-card" header="P.A. IN CLINOSTATISMO" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <pressione-sanguigna-component :items="jsonData.listaPressioneClino" :isEdit="false"></pressione-sanguigna-component>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Media P.A. Sistolica</label>
              <span class="sa-data">{{ jsonData.paclinomaxm }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Media P.A. Diastolica</label>
              <span class="sa-data">{{ jsonData.paclinominm }}</span>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">P.A. ARTO INF. SN</label>
              <span class="sa-data">{{ jsonData.pasaisn }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">P.A. ARTO INF. DX</label>
              <span class="sa-data">{{ jsonData.pasaidx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">ABI SN</label>
              <span class="sa-data">{{ jsonData.abisn }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">ABI DX</label>
              <span class="sa-data">{{ jsonData.abidx }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
        <b-card class="sa-card" header="P.A. IN ORTOSTATISMO" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <pressione-sanguigna-component :items="jsonData.listaPressioneOrto" :isEdit="false"></pressione-sanguigna-component>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Media P.A. Sistolica</label>
              <span class="sa-data">{{ jsonData.paortomaxm }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Media P.A. Diastolica</label>
              <span class="sa-data">{{ jsonData.paortominm }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
        <b-card class="sa-card" header="P.A. IN POSIZIONE SEDUTA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <pressione-sanguigna-component :items="jsonData.listaPressioneSitting" :isEdit="false"></pressione-sanguigna-component>
            </b-col>
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Media P.A. Sistolica</label>
              <span class="sa-data">{{ jsonData.pasitmaxm }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Media P.A. Diastolica</label>
              <span class="sa-data">{{ jsonData.pasitminm }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="SCORE2-OP (Paesi a rischio moderato) – Valutazione del rischio di eventi cardiovascolari fatali e non fatali a 10 anni" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="9" lg="9" xl="9">
              <b-row>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                  <label class="sa-label-data">Sesso</label>
                  <span class="sa-data">{{ jsonData.sesso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <label class="sa-label-data">P.A. Sistolica</label>
                  <span class="sa-data">{{ jsonData.pressartsist }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                  <label class="sa-label-data">Col. non HDL</label>
                  <span class="sa-data">{{ jsonData.colesterolemia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                  <label class="sa-label-data">Età</label>
                  <span class="sa-data">{{ jsonData.eta }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                  <label class="sa-label-data">Fumo</label>
                  <span class="sa-data">{{ jsonData.fumostrat1 }}</span>
                </b-col>
              </b-row>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                  <label class="sa-label-data">Rischio Cardiovascolare</label>
                  <div style="width: 100%">
                    <!-- <div :class="rischioCardiovascolareColorClass">{{ jsonData.rischioCardiovascolare }}</div> -->
                    <span :class="rischioCardiovascolareColorClass">{{ jsonData.rischioCardiovascolare }}</span>
                  </div>
                </b-col>
                <b-col v-if="isUnderQuaranta" xs="12" sm="12" md="12" lg="12" xl="12">
                  <label class="sa-label-data"></label>
                  <span class="sa-data">IL CALCOLO DELLO SCORE NON E' APPLICABILE PER I SOGETTI INFERIORI AI 40 ANNI DI ETA'</span>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-card class="sa-card" header="TERAPIA PROPOSTA" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-table ref="tblTerapiaInCorso" sticky-header stacked="xl" striped hover itemscope :items="listaFarmaciTerapiaProposta" :fields="fieldsListaFarmaciTerapiaProposta" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
        </b-table>
      </b-row>
    </b-card>

    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="Diagnosi, note e Comunicazioni al MMG" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-table ref="tblDiagnosi" sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaDiagnosi" :fields="fieldsDiagnosi" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Note</label>
              <span class="sa-data-justify">{{ jsonData.noteDiagnosi }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card class="sa-card" header="PDTA" header-tag="header" footer-tag="footer" title="">
      <!-- <b-row> -->
      <b-form-group>
        <b-row>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-radio v-model="pdta" name="some-radios" value="1" disabled>Nessun PDTA</b-form-radio>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-radio v-model="pdta" name="some-radios" value="2" disabled>PDTA Ipertensione Arteriosa</b-form-radio>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-radio v-model="pdta" name="some-radios" value="3" disabled>PDTA Scompenso Cardiaco</b-form-radio>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-radio v-model="pdta" name="some-radios" value="4" disabled>PDTA Cardiopatia Ischemica</b-form-radio>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <b-form-radio v-model="pdta" name="some-radios" value="5" disabled>PDTA Prevenzione Malattie Cardiovascolari</b-form-radio>
          </b-col>
        </b-row>
      </b-form-group>
      <!-- </b-row> -->
    </b-card>
    <b-card class="sa-card" header="ESAMI DI LABORATORIO - DA PRATICARE PRIMA DEL PROSSIMO CONTROLLO" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Glicemia</label>
          <span class="sa-data">{{ jsonData.glicemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">HbA1c</label>
          <span class="sa-data">{{ jsonData.hba1c }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Creatininemia</label>
          <span class="sa-data">{{ jsonData.creatinemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Azotemia</label>
          <span class="sa-data">{{ jsonData.azotemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Uricemia</label>
          <span class="sa-data">{{ jsonData.uricemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Sodiemia</label>
          <span class="sa-data">{{ jsonData.sodiemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Potassioemia</label>
          <span class="sa-data">{{ jsonData.potassioemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Calcemia</label>
          <span class="sa-data">{{ jsonData.calcemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Cloremia</label>
          <span class="sa-data">{{ jsonData.cloremia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Magnesemia</label>
          <span class="sa-data">{{ jsonData.magnesemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTotalCholesterolHdlTriglycerides") }}</label>
          <span class="sa-data">{{ jsonData.colesteroloTrigliceridi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCpkLdh") }}</label>
          <span class="sa-data">{{ jsonData.cpkLdh }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">GOT</label>
          <span class="sa-data">{{ jsonData.got }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">GPT</label>
          <span class="sa-data">{{ jsonData.gpt }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Quadro Prot. Elettrof.</label>
          <span class="sa-data">{{ jsonData.quadroProtElettroforetico }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Amilasi</label>
          <span class="sa-data">{{ jsonData.amilasi }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Y-GT</label>
          <span class="sa-data">{{ jsonData.yGt }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBloodCountPlatelets") }}</label>
          <span class="sa-data">{{ jsonData.emocromoPiastrine }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrine") }}</label>
          <span class="sa-data">{{ jsonData.esameUrine }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMicroalbuminuria") }}</label>
          <span class="sa-data">{{ jsonData.microalbuminuria }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dosaggio degli Elettroliti Urinari (Na+ e K+) su campione delle 24h</label>
          <span class="sa-data">{{ jsonData.dosaggioElettrolitiUrinari }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteinuria") }}</label>
          <span class="sa-data">{{ jsonData.proteinuria }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nt-proBNP</label>
          <span class="sa-data">{{ jsonData.ntProBnp }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Sideremia</label>
          <span class="sa-data">{{ jsonData.sideremia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ferritinemia</label>
          <span class="sa-data">{{ jsonData.ferritinemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Transferrinemia</label>
          <span class="sa-data">{{ jsonData.transferrinemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Vit.D-25OH</label>
          <span class="sa-data">{{ jsonData.vitD25Oh }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPtApttInr") }}</label>
          <span class="sa-data">{{ jsonData.ptApttInr }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPCR") }}</label>
          <span class="sa-data">{{ jsonData.pcr }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHomocysteinemiaDosage") }}</label>
          <span class="sa-data">{{ jsonData.dosaggioOmocisteinemia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Troponinemia T</label>
          <span class="sa-data">{{ jsonData.troponinemiaT }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Troponinemia I</label>
          <span class="sa-data">{{ jsonData.troponinemiaI }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">TSH</label>
          <span class="sa-data">{{ jsonData.tsh }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">FT3</label>
          <span class="sa-data">{{ jsonData.ft3 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">FT4</label>
          <span class="sa-data">{{ jsonData.ft4 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Anticorpi Anti-TG</label>
          <span class="sa-data">{{ jsonData.anticorpiAntiTg }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Anticorpi Anti-TPO</label>
          <span class="sa-data">{{ jsonData.anticorpiAntiTpo }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPraAndBasalAldosteronemia1hAfterCaptopril") }}</label>
          <span class="sa-data">{{ jsonData.dosaggioDopoCaptopril }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPraAndBasalAldosteronemiaOnClinic") }}</label>
          <span class="sa-data">{{ jsonData.dosaggioClinoOrto }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCortisol") }}</label>
          <span class="sa-data">{{ jsonData.cortisolemia820 }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUrinaryCatecholaminesMetanephrinesDosage24hSample") }}</label>
          <span class="sa-data">{{ jsonData.dosaggioCatecolamine }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">ACTH</label>
          <span class="sa-data">{{ jsonData.acth }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="ESAMI STRUMENTALI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartUltrasonographyColordoppler") }}</label>
          <span class="sa-data">{{ jsonData.ecocardiografia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUltrasoundTsaColordoppler") }}</label>
          <span class="sa-data">{{ jsonData.ecotsa }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecocolordoppler Aorta Addominale</label>
          <span class="sa-data">{{ jsonData.ecocolordopplerAortaAddominale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHolterECG") }}</label>
          <span class="sa-data">{{ jsonData.holterEcg }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAmbulatoryMonitoringBloodPressure") }}</label>
          <span class="sa-data">{{ jsonData.monitoraggioPressione }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecocolordoppler Arterioso Arti Inferiori</label>
          <span class="sa-data">{{ jsonData.ecocolordopplerArteriosoArtiInferiori }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecocolordoppler Arterie Renali</label>
          <span class="sa-data">{{ jsonData.ecocolordopplerArterieRenali }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblErgonometricTest") }}</label>
          <span class="sa-data">{{ jsonData.testErgometrico }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecostress</label>
          <span class="sa-data">{{ jsonData.ecostress }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecostress Farmacologico</label>
          <span class="sa-data">{{ jsonData.ecostressFarmacologico }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">T.C. Coronarica</label>
          <span class="sa-data">{{ jsonData.tcCoronarica }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMyocardialPerfusionSpectRestStimulation") }}</label>
          <span class="sa-data">{{ jsonData.scintigrafiaMiocardica }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">P.E.T.</label>
          <span class="sa-data">{{ jsonData.pet }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">RMN Cardiaca</label>
          <span class="sa-data">{{ jsonData.rmnCardiaca }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Angio-TC TSA</label>
          <span class="sa-data">{{ jsonData.angioTcTsa }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Angio-TC Torace</label>
          <span class="sa-data">{{ jsonData.angioTcTorace }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Angio-TC Addome</label>
          <span class="sa-data">{{ jsonData.angioTcAddome }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">RMN Cranio</label>
          <span class="sa-data">{{ jsonData.rmnCranio }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">RMN Torace</label>
          <span class="sa-data">{{ jsonData.rmnTorace }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">RMN Addome</label>
          <span class="sa-data">{{ jsonData.rmnAddome }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBrainTAC") }}</label>
          <span class="sa-data">{{ jsonData.tacCerebrale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">TAC Torace</label>
          <span class="sa-data">{{ jsonData.tacTorace }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAbdomenTAC") }}</label>
          <span class="sa-data">{{ jsonData.tacAddome }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecografia Renale</label>
          <span class="sa-data">{{ jsonData.ecografiaRenale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecografia Addominale</label>
          <span class="sa-data">{{ jsonData.ecografiaAddominale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecografia Tiroidea</label>
          <span class="sa-data">{{ jsonData.ecografiaTiroidea }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBasalSequentialRenalScintigraphy1hAfterCaptopril") }}</label>
          <span class="sa-data">{{ jsonData.scintigrafiaRenale }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecodoppler Arti Inferiori</label>
          <span class="sa-data">{{ jsonData.ecodopplerArtiInferiori }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ecodoppler Trans Cranico con Buble Test</label>
          <span class="sa-data">{{ jsonData.ecodopplerTransCranico }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
          <span class="sa-data-justyfy">{{ jsonData.altroEsame }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="Prossimo Controllo" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCheckNextDate") }}</label>
              <span class="sa-data">{{ formatDate(jsonData.prossimoControllo) }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Ora Prossimo Controllo</label>
              <span class="sa-data">{{ formatTime(jsonData.oraProssimoControllo) }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import PressioneSanguignaComponent from "../../../utility/components/PressioneSanguignaComponent.vue";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
  mixins: [UtilityMixins],
  props: {},
  components: { PressioneSanguignaComponent, EventiStoricoMisureComponent },
  data() {
    return {
      pathResource: "/cardiovisitecontrolliecg",
      pathResourceCategorieFarmaci: "/farmacicategoriefarmaci",
      rischioCardiovascolareColorClass: "",
      id: null,
      isUnderQuaranta: false,
      gruppo: "CARDIOLOGIA",
      tipoEvento: "VISITA CONTROLLO ECG",
      jsonData: {
        listaPressioneClino: [],
        listaPressioneOrto: [],
        listaPressioneSitting: [],
      },
      fontScale: 2,
      filtro: { page: 1, forPage: 30 },
      currentPage: 1,
      perPage: 30,
      listaCategorieFarmaci: [],
      listaFarmaciTerapiaInCorso: [],
      fieldsListaFarmaciTerapiaInCorso: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Nome Farmaco",
          key: "nomeFarmaco",
          sortable: false,
        },
        {
          label: "Somministrazione",
          key: "dosaggio",
          //   thStyle: "width: 13rem",
          sortable: false,
        },
        {
          label: "Classe Farmaco",
          key: "classe",
          thStyle: "width: 13rem",
          sortable: false,
        },
        // {
        //     label: "",
        //     key: "actions",
        //     thStyle: "width: 3rem",
        // },
      ],
      listaFarmaciTerapiaProposta: [],
      fieldsListaFarmaciTerapiaProposta: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Nome Farmaco",
          key: "nomeFarmaco",
          sortable: false,
        },
        {
          label: "Sommninistrazione",
          key: "dosaggio",
          //   thStyle: "width: 13rem",
          sortable: false,
        },
        {
          label: "Classe Farmaco",
          key: "classe",
          thStyle: "width: 13rem",
          sortable: false,
        },
        // {
        //     label: "",
        //     key: "actions",
        //     thStyle: "width: 3rem",
        // },
      ],
      fieldsDiagnosi: [
        {
          label: "",
          key: "index",
          thStyle: "width: 0.2rem",
          tdClass: "text-center",
        },
        {
          label: "Diagnosi",
          key: "descrizioneIcdix",
          sortable: false,
        },
        {
          label: "Codice Icd-IX",
          key: "codiceIcdix",
          thStyle: "width: 16rem",
          sortable: false,
        },
        // {
        //     label: "",
        //     key: "actions",
        //     thStyle: "width: 4rem",
        //     tdClass: "text-center",
        // },
      ],
      pdta: 1,
      listaEsamiPdtaUno: [
        { descrizione: "", campo: "glicemia" },
        { descrizione: "", campo: "hba1c" },
        { descrizione: "", campo: "creatinemia" },
        { descrizione: "", campo: "uricemia" },
        { descrizione: "", campo: "sodiemia" },
        { descrizione: "", campo: "potassioemia" },
        { descrizione: "", campo: "calcemia" },
        { descrizione: "", campo: "colesteroloTrigliceridi" },
        { descrizione: "", campo: "emocromoPiastrine" },
        { descrizione: "", campo: "esameUrine" },
        { descrizione: "", campo: "microalbuminuria" },
      ],
      listaEsamiPdtaDue: [
        { descrizione: "", campo: "ntProBnp" },
        { descrizione: "", campo: "glicemia" },
        { descrizione: "", campo: "hba1c" },
        { descrizione: "", campo: "creatinemia" },
        { descrizione: "", campo: "uricemia" },
        { descrizione: "", campo: "sodiemia" },
        { descrizione: "", campo: "potassioemia" },
        { descrizione: "", campo: "colesteroloTrigliceridi" },
        { descrizione: "", campo: "got" },
        { descrizione: "", campo: "quadroProtElettroforetico" },
        { descrizione: "", campo: "emocromoPiastrine" },
        { descrizione: "", campo: "esameUrine" },
        { descrizione: "", campo: "microalbuminuria" },
        { descrizione: "", campo: "ecocardiografia" },
      ],
      listaEsamiPdtaTre: [
        { descrizione: "", campo: "glicemia" },
        { descrizione: "", campo: "hba1c" },
        { descrizione: "", campo: "creatinemia" },
        { descrizione: "", campo: "uricemia" },
        { descrizione: "", campo: "sodiemia" },
        { descrizione: "", campo: "potassioemia" },
        { descrizione: "", campo: "colesteroloTrigliceridi" },
        { descrizione: "", campo: "got" },
        { descrizione: "", campo: "quadroProtElettroforetico" },
        { descrizione: "", campo: "emocromoPiastrine" },
        { descrizione: "", campo: "esameUrine" },
        { descrizione: "", campo: "microalbuminuria" },
        { descrizione: "", campo: "ecocardiografia" },
      ],
      listaEsamiPdtaQuattro: [
        { descrizione: "", campo: "glicemia" },
        { descrizione: "", campo: "hba1c" },
        { descrizione: "", campo: "creatinemia" },
        { descrizione: "", campo: "uricemia" },
        { descrizione: "", campo: "sodiemia" },
        { descrizione: "", campo: "potassioemia" },
        { descrizione: "", campo: "colesteroloTrigliceridi" },
        { descrizione: "", campo: "got" },
        { descrizione: "", campo: "quadroProtElettroforetico" },
        { descrizione: "", campo: "emocromoPiastrine" },
        { descrizione: "", campo: "tsh" },
        { descrizione: "", campo: "esameUrine" },
        { descrizione: "", campo: "microalbuminuria" },
      ],
      listaEsamiSelezionati: [],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
    me.loadCategorieFarmaci();
  },
  computed: {
    isFirmato() {
      let me = this;
      let isFirmato = !me.jsonData.firma;
      console.log(isFirmato, "FIRMA");
      me.$emit("updateStatoFirma", isFirmato);
      return !me.jsonData.firma;
    },
    idPaziente() {
      let me = this;
      return me.jsonData.idPaziente;
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        if (jsonData.eta < 40) {
          this.isUnderQuaranta = true;
        } else {
          this.isUnderQuaranta = false;
        }
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    listaFarmaciTerapiaInCorso: function () {
      let me = this;
      let tmp = [];
      me.jsonData.listaTerapia.forEach((element) => {
        if (element.tipo !== "TERAPIA IN CORSO") {
          tmp.push(element);
        }
      });
      tmp = tmp.concat(me.listaFarmaciTerapiaInCorso);

      me.jsonData.listaTerapia = tmp;
    },
    listaFarmaciTerapiaProposta: function () {
      let me = this;
      let tmp = [];
      me.jsonData.listaTerapia.forEach((element) => {
        if (element.tipo !== "TERAPIA PROPOSTA") {
          tmp.push(element);
        }
      });
      tmp = tmp.concat(me.listaFarmaciTerapiaProposta);

      me.jsonData.listaTerapia = tmp;
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            me.verificaPdta(response.data.data);
            me.jsonData.listaTerapia.forEach((element) => {
              if (element.tipo === "TERAPIA IN CORSO") {
                me.listaFarmaciTerapiaInCorso.push(element);
              } else {
                me.listaFarmaciTerapiaProposta.push(element);
              }
            });
            me.setColorByScoreColor();
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
    setColorByScoreColor() {
      let me = this;
      if (me.jsonData.rischioCardiovascolareColore === "V") {
        // me.rischioCardiovascolareColorClass = "sa-data text-success sa-risk-alert-success";
        me.rischioCardiovascolareColorClass = "sa-data sa-risk-alert-success";
      } else if (me.jsonData.rischioCardiovascolareColore === "A") {
        // me.rischioCardiovascolareColorClass = "sa-data text-warning sa-risk-alert-warning";
        me.rischioCardiovascolareColorClass = "sa-data sa-risk-alert-warning";
      } else if (me.jsonData.rischioCardiovascolareColore === "R") {
        // me.rischioCardiovascolareColorClass = "sa-data text-danger sa-risk-alert-danger";
        me.rischioCardiovascolareColorClass = "sa-data sa-risk-alert-danger";
      } else {
        me.rischioCardiovascolareColorClass = "sa-data text-normal sa-risk-alert";
      }
    },
    loadCategorieFarmaci() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceCategorieFarmaci;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.listaCategorieFarmaci = response.data.data.list;
        me.showModalLoading = false;
      });
    },
    setColor(value) {
      let color = value == "true" ? "text-success" : "text-danger";
      return color;
    },
    setIcon(value) {
      let icon = value === "true" ? "check" : "x";
      return icon;
    },
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    getBooleanTraduzione(bool) {
      if (bool == "true" || bool == true) {
        return this.getLabelTraduora("patient.lblYes");
      } else {
        return this.getLabelTraduora("patient.lblNo");
      }
    },
    verificaPdta(data) {
      let me = this;
      me.creaListaEsamiSelezionati(data);
      if (me.listaEsamiSelezionati.length > 0) {
        if (me.controlloSceltaPdtaDaEsamiSelezionati(me.listaEsamiPdtaQuattro)) {
          me.pdta = 5;
        } else if (me.controlloSceltaPdtaDaEsamiSelezionati(me.listaEsamiPdtaDue)) {
          me.pdta = 3;
        } else if (me.controlloSceltaPdtaDaEsamiSelezionati(me.listaEsamiPdtaTre)) {
          me.pdta = 4;
        } else if (me.controlloSceltaPdtaDaEsamiSelezionati(me.listaEsamiPdtaUno)) {
          me.pdta = 2;
        }
      }
    },
    controlloSceltaPdtaDaEsamiSelezionati(listaPdta) {
      let me = this;
      let returnValue = true;
      listaPdta.forEach((element) => {
        let presente = false;
        me.listaEsamiSelezionati.forEach((elementoSelezionato) => {
          if (elementoSelezionato.campo === element.campo) {
            presente = true;
          }
        });
        if (!presente) {
          returnValue = false;
        }
      });
      return returnValue;
    },
    creaListaEsamiSelezionati(data) {
      let me = this;
      // console.log(data.glicemia);
      if (data.monitoraggioPressione === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "monitoraggioPressione" });
      }
      if (data.tacAddome === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "tacAddome" });
      }
      if (data.cpkLdh === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "cpkLdh" });
      }
      if (data.ecoRenale === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecoRenale" });
      }
      if (data.microalbuminuria === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "microalbuminuria" });
      }
      if (data.proteinuria === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "proteinuria" });
      }
      if (data.dosaggioCatecolamine === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioCatecolamine" });
      }
      if (data.hba1c === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "hba1c" });
      }
      if (data.digossinemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "digossinemia" });
      }
      if (data.pcr === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "pcr" });
      }
      if (data.dosaggioOmocisteinemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioOmocisteinemia" });
      }
      if (data.acth === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "acth" });
      }
      if (data.tacCerebrale === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "tacCerebrale" });
      }
      if (data.ecografiaTiroidea === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecografiaTiroidea" });
      }
      if (data.scintigrafiaTiroidea === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "scintigrafiaTiroidea" });
      }
      if (data.dosaggioElettrolitiUrinari === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioElettrolitiUrinari" });
      }
      if (data.glicemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "glicemia" });
      }
      if (data.azotemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "azotemia" });
      }
      if (data.creatinemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "creatinemia" });
      }
      if (data.uricemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "uricemia" });
      }
      if (data.scintigrafiaMiocardica === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "scintigrafiaMiocardica" });
      }
      if (data.colesteroloTrigliceridi === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "colesteroloTrigliceridi" });
      }
      if (data.holterEcg === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "holterEcg" });
      }
      if (data.testErgometrico === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "testErgometrico" });
      }
      if (data.angiocardiopneumoscint === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "angiocardiopneumoscint" });
      }
      if (data.fondooculare === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "fondooculare" });
      }
      if (data.cortisolemia820 === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "cortisolemia820" });
      }
      if (data.dosaggioClinoOrto === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioClinoOrto" });
      }
      if (data.esameUrine === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "esameUrine" });
      }
      if (data.dosaggioDopoCaptopril === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "dosaggioDopoCaptopril" });
      }
      if (data.sodiemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "sodiemia" });
      }
      if (data.potassioemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "potassioemia" });
      }
      if (data.calcemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "calcemia" });
      }
      if (data.cloremia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "cloremia" });
      }
      if (data.ptApttInr === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ptApttInr" });
      }
      if (data.ecocardiografia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecocardiografia" });
      }
      if (data.ecotsa === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecotsa" });
      }
      if (data.tsh === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "tsh" });
      }
      if (data.ft4 === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ft4" });
      }
      if (data.ft3 === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ft3" });
      }
      if (data.markersEpatite === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "markersEpatite" });
      }
      if (data.ecografiaEpatobiliare === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecografiaEpatobiliare" });
      }
      if (data.emocromoPiastrine === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "emocromoPiastrine" });
      }
      if (data.scintigrafiaRenale === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "scintigrafiaRenale" });
      }
      if (data.ecodopplerArtiInferiori === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecodopplerArtiInferiori" });
      }
      if (data.ecodopplerTransCranico === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecodopplerTransCranico" });
      }
      if (data.pet === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "pet" });
      }
      if (data.tcCoronarica === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "tcCoronarica" });
      }
      if (data.ecostress === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecostress" });
      }
      if (data.magnesemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "magnesemia" });
      }
      if (data.got === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "got" });
      }
      if (data.gpt === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "gpt" });
      }
      if (data.quadroProtElettroforetico === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "quadroProtElettroforetico" });
      }
      if (data.amilasi === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "amilasi" });
      }
      if (data.yGt === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "yGt" });
      }
      if (data.ntProBnp === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ntProBnp" });
      }
      if (data.sideremia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "sideremia" });
      }
      if (data.ferritinemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ferritinemia" });
      }
      if (data.transferrinemia === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "transferrinemia" });
      }
      if (data.vitD25Oh === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "vitD25Oh" });
      }
      if (data.troponinemiaT === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "troponinemiaT" });
      }
      if (data.troponinemiaI === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "troponinemiaI" });
      }
      if (data.anticorpiAntiTg === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "anticorpiAntiTg" });
      }
      if (data.anticorpiAntiTpo === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "anticorpiAntiTpo" });
      }
      if (data.ecocolordopplerAortaAddominale === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecocolordopplerAortaAddominale" });
      }
      if (data.ecocolordopplerArteriosoArtiInferiori === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecocolordopplerArteriosoArtiInferiori" });
      }
      if (data.ecocolordopplerArterieRenali === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecocolordopplerArterieRenali" });
      }
      if (data.ecostressFarmacologico === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecostressFarmacologico" });
      }
      if (data.rmnCardiaca === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "rmnCardiaca" });
      }
      if (data.angioTcTsa === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "angioTcTsa" });
      }
      if (data.angioTcTorace === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "angioTcTorace" });
      }
      if (data.angioTcAddome === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "angioTcAddome" });
      }
      if (data.rmnCranio === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "rmnCranio" });
      }
      if (data.rmnTorace === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "rmnTorace" });
      }
      if (data.rmnAddome === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "rmnAddome" });
      }
      if (data.tacTorace === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "tacTorace" });
      }
      if (data.ecografiaRenale === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecografiaRenale" });
      }
      if (data.ecografiaAddominale === "SI") {
        me.listaEsamiSelezionati.push({ descrizione: "", campo: "ecografiaAddominale" });
      }
      // console.log(me.listaEsamiSelezionati.length);
    },
  },
};
</script>

<style>
.sa-risk-alert-danger,
.sa-risk-alert-success,
.sa-risk-alert-warning {
  height: 50px;
  width: 50px;
  border-radius: 25px;
  display: block;
  align-content: center;
  font-size: 1.6rem !important;
  text-align: center;
  padding-top: 8px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
}
.sa-risk-alert-danger {
  border: 2px solid red;
}

.sa-risk-alert-success {
  border: 2px solid #28a745;
}

.sa-risk-alert-warning {
  border: 2px solid #ffc107;
}
</style>
