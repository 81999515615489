<template :showmolalloading="showmolalloading">
    <sa-page-layout>
        <template slot="toolbar">
            <b-button class="float-sm-right" variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center>
                <b-icon icon="arrow-clockwise" variant="secondary" />
                Refresh
            </b-button>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" :filter="filter" fixed responsive stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table"></b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import moment from "moment";
import axios from "axios";
export default {
    components: { SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showmolalloading: false,
            pagelink: "/session",
            pathResource: "/session",
            currentPage: 1,
            perPage: 20,
            filter: null,
            filterOn: [],
            fields: [
                {
                    label: "Username", //this.$i18n.t("agende.lblDiary"),
                    key: "username",
                    sortable: true,
                    thStyle: "width: 8rem",
                },
                {
                    label: "Expires In", //this.$i18n.t("agende.lblDiary"),
                    key: "expiresIn",
                    formatter: (value) => {
                        return moment(new Date(value)).format("mm");
                    },
                    sortable: true,
                    thStyle: "width: 6rem",
                },
                {
                    label: "Create Token", // this.$i18n.t("agende.lblDescription"),
                    key: "createToken",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
                    },
                    thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Expires Token", // this.$i18n.t("agende.lblDescription"),
                    key: "expiresToken",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
                    },
                    thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Token Type", //this.$i18n.t("agende.lblDiary"),
                    key: "tokenType",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Session", //this.$i18n.t("agende.lblDiary"),
                    key: "session",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Type", //this.$i18n.t("agende.lblDiary"),
                    key: "sessionType",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Access Token", //this.$i18n.t("agende.lblDiary"),
                    key: "accessToken",
                    sortable: true,
                    thStyle: "max-width: 10rem; overflow-wrap: break-word;",
                    tdClass: "sa-wrap-break-word",
                },
            ],
            items: [],
        };
    },
    created: function () {
        this.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showmolalloading = true;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showmolalloading = false;
                })
                .catch(function (error) {
                    me.showmolalloading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm");
            } else {
                return "---";
            }
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
