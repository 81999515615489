<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.fisiologica.lblConcomitantDiseases") }}</label>
                    <b-form-textarea v-model="jsonData.malattieConcomitanti" rows="8" no-resize></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.fisiologica.lblPreviousSurgicalInterventions") }}</label>
                    <b-form-textarea v-model="jsonData.pregressiInterventi" rows="8" no-resize></b-form-textarea>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.fisiologica.lblSmoke") }}</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.fumo" :options="fumoOptions" :value="null" value-field="value" text-field="text" @input="onInputFumo($event)">
                        <template #first>
                            <b-form-select-option :value="null" value-field="value" text-field="text" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.fisiologica.lblSmokeQuantity") }}</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.quantitaFumo" :options="quantitaFumoOptions" :value="null" value-field="value" text-field="text" :disabled="quantitaFumoDisabled">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.fisiologica.lblAlcoholicBeverages") }}</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bevandeAlcoliche" :options="bevandeAlcolicheOptions" :value="null" value-field="value" text-field="text" @input="onInputBevandeAlcoliche($event)">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.fisiologica.lblAlcoholicBeveragesQuantity") }}</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.quantitaBevandeAlcoliche" :options="quantitaBevandeAlcolicheOptions" :value="null" value-field="value" text-field="text" :disabled="quantitaBevandeAlcolicheDisabled">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.fisiologica.lblCoffee") }}</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.caffe" :options="caffeOptions" :value="null" value-field="value" text-field="text" @input="onInputCaffe($event)">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.getLabelTraduora("patient.fisiologica.lblCoffeeQuantity") }}</label>
                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.quantitaCaffe" :options="quantitaCaffeOptions" :value="null" value-field="value" text-field="text" :disabled="quantitaCaffeDisabled">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    components: { DatePicker },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    fumo: null,
                    quantitaFumo: null,
                    bevandeAlcoliche: null,
                    quantitaBevandeAlcoliche: null,
                    caffe: null,
                    quantitaCaffe: null,
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            pathResource: "/gastroanamnesifisiologica",
            quantitaFumoDisabled: false,
            quantitaBevandeAlcolicheDisabled: false,
            quantitaCaffeDisabled: false,
            selectFirstElement: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            fumoOptions: [
                { value: this.getLabelTraduora("patient.gastro.Options.Yes"), text: this.getLabelTraduora("patient.gastro.Options.Yes") },
                { value: this.getLabelTraduora("patient.gastro.Options.No"), text: this.getLabelTraduora("patient.gastro.Options.No") },
            ],
            quantitaFumoOptions: [
                { text: "0 - 10/gg", value: "0 - 10/gg" },
                { text: "11 - 20/gg", value: "11 - 20/gg" },
                { text: "21 - 40/gg", value: "21 - 40/gg" },
                { text: ">40/gg", value: ">40/gg" },
            ],
            bevandeAlcolicheOptions: [
                { value: this.getLabelTraduora("patient.gastro.Options.Yes"), text: this.getLabelTraduora("patient.gastro.Options.Yes") },
                { value: this.getLabelTraduora("patient.gastro.Options.No"), text: this.getLabelTraduora("patient.gastro.Options.No") },
            ],
            quantitaBevandeAlcolicheOptions: [
                { text: "<1/2 Lt", value: "<1/2 Lt" },
                { text: "1/2 Lt", value: "1/2 Lt" },
                { text: "1-2 Lt", value: "1-2 Lt" },
                { text: ">2 Lt", value: ">2 Lt" },
            ],
            caffeOptions: [
                { value: this.getLabelTraduora("patient.gastro.Options.Yes"), text: this.getLabelTraduora("patient.gastro.Options.Yes") },
                { value: this.getLabelTraduora("patient.gastro.Options.No"), text: this.getLabelTraduora("patient.gastro.Options.No") },
            ],
            quantitaCaffeOptions: [
                { text: "1", value: "1" },
                { text: "2", value: "2" },
                { text: "3", value: "3" },
                { text: ">3", value: ">3" },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        onInputFumo(value) {
            let me = this;
            me.quantitaFumoDisabled = false;
            if (value === null || value === "NO") {
                me.jsonData.quantitaFumo = null;
                me.quantitaFumoDisabled = true;
            }
        },
        onInputBevandeAlcoliche(value) {
            let me = this;
            me.quantitaBevandeAlcolicheDisabled = false;
            if (value === null || value === "NO") {
                me.jsonData.quantitaBevandeAlcoliche = null;
                me.quantitaBevandeAlcolicheDisabled = true;
            }
        },
        onInputCaffe(value) {
            let me = this;
            me.quantitaCaffeDisabled = false;
            if (value === null || value === "NO") {
                me.jsonData.quantitaCaffe = null;
                me.quantitaCaffeDisabled = true;
            }
        },
    },
};
</script>

<style></style>
