<template>
    <div>
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Intervento" header-tag="header" footer-tag="footer" title="">
            <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblInterventionDescription") }}</label>
            <span class="sa-data-justify">{{ jsonData.descrizioneIntervento }}</span>
        </b-card>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import axios from "axios";
export default {
    mixins: [UtilityMixin],
    props: {},
    data() {
        return { pathResource: "/gastroposizionamentobib", id: null, jsonData: {}, linkPrintData: null };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>

<style></style>
