<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Indagine Epidemiologica" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Caso</label>
          <span class="sa-data">{{ jsonData.tipoCaso }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malattia</label>
          <span class="sa-data">{{ jsonData.malattia }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Fonte di Contagio</label>
          <span class="sa-data">{{ jsonData.fonteContagio }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Note Fonte di Contagio</label>
          <span class="sa-data">{{ jsonData.fonteContagioNote }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricovero Ospedaliero</label>
          <span class="sa-data">{{ jsonData.ricoveroOspedaliero }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Ricovero</label>
          <span class="sa-data">{{ formatDate(jsonData.ricoveroOspedalieroData) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nome Ospedale</label>
          <span class="sa-data">{{ jsonData.ricoveroOspedalieroNomeOspedale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Reparto</label>
          <span class="sa-data">{{ jsonData.ricoveroOspedalieroReparto }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Sinitomi" header-tag="header" footer-tag="footer" title="">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData.listaSintomatologia" :fields="fieldsSintomi" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template v-slot:cell(luogo)="{ item }">
            <a>{{ item.luogo }}</a>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-card class="sa-card" header="Permanenza in Stati Esteri nei 2 mesi precedenti alla comparsa dei sintomi" header-tag="header" footer-tag="footer" title="">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData.listaStatiEsteri" :fields="fieldsStatiEsteri" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template v-slot:cell(luogo)="{ item }">
            <a>{{ item.luogo }}</a>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-card class="sa-card" header="Ricerche diagnostiche eseguite o in corso" header-tag="header" footer-tag="footer" title="">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData.lisitaRicercheDiagnostiche" :fields="fieldsRicercheDiagnostiche" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template v-slot:cell(tipoRicerca)="{ item }">
            <a>{{ item.tipoRicerca }}</a>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-card class="sa-card" header="Contatti" header-tag="header" footer-tag="footer" title="">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template v-slot:cell(codiceFiscale)="{ item }">
            <a>{{ item.codiceFiscale }}</a>
          </template>
        </b-table>
      </div>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.nomeSanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cognome Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.cognomeSanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.contattoTelefonicoSanitarioNotificante }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import UtilityMixin from "../../../utility/UtilityMixin";
import axios from "axios";
export default {
  // props: {
  //   jsonData: {
  //     Type: Object,
  //     default: function () {
  //       return {
  //         tipoCaso: "",
  //         fonteContagio: "",
  //         fonteContagioNote: "",
  //         listaStatiEsteri: [{ luogo: null, giornoDal: null, giornoAl: null }],
  //         lisitaRicercheDiagnostiche: [{ tipoRicerca: "", data: null, luogo: "", risultati: "" }],
  //         listaContatti: [{ codiceFiscale: "", cognome: "", nome: "", eta: "", professione: "", parentela: null }],
  //         listaSintomatologia: [{ sintomo: null }],
  //       };
  //     },
  //   },
  // },
  mixins: [UtilityMixin],
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      linkedit: null,
      pathResource: "/malattieinfettiveie",
      jsonData: {
        tipoCaso: "",
        fonteContagio: "",
        fonteContagioNote: "",
        listaStatiEsteri: [{ luogo: null, giornoDal: null, giornoAl: null }],
        lisitaRicercheDiagnostiche: [{ tipoRicerca: "", data: null, luogo: "", risultati: "" }],
        listaContatti: [{ codiceFiscale: "", cognome: "", nome: "", eta: "", professione: "", parentela: null }],
        listaSintomatologia: [{ sintomo: null }],
      },
      fieldsStatiEsteri: [
        {
          label: "N.",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Luogo",
          key: "luogo",
          sortable: false,
        },
        {
          label: "Giorno Dal",
          key: "giornoDal",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "Giorno Al",
          key: "giornoAl",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsRicercheDiagnostiche: [
        {
          label: "N.",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo Ricerca",
          key: "tipoRicerca",
          sortable: false,
        },
        {
          label: "Luogo",
          key: "luogo",
          thStyle: "width: 18rem",
          sortable: false,
        },
        {
          label: "Risultati",
          key: "risultati",
          thStyle: "width: 20rem",
          sortable: false,
        },
        {
          label: "Data",
          key: "data",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsContatti: [
        {
          label: "N.",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Codice Fiscale",
          key: "codiceFiscale",
          sortable: false,
        },
        {
          label: "Nome",
          key: "nome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Cognome",
          key: "cognome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Eta'",
          key: "eta",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Professione",
          key: "professione",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Parentela",
          key: "parentela",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsSintomi: [
        {
          label: "N.",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Sintomo",
          key: "sintomo",
          sortable: false,
        },
        {
          label: "Data Dal",
          key: "dataDal",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "Data Al",
          key: "dataAl",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/malattieinfettive/indagineepidemiologica/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.loadData();
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
  },
};
</script>
