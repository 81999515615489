<template>
    <b-row>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCommonIliacArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artIliacaComuneSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artIliacaComuneVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artIliacaComuneDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
            <b-form-select v-model="jsonData.ccscoresn" :options="scoreOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
            <b-form-select v-model="jsonData.cccolsn" :options="colorOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
            <b-form-input v-model="jsonData.maximtnearccsx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
            <b-form-input v-model="jsonData.maximtfarccsx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblExternalIliacArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artIliacaEsternaSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artIliacaEsternaVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artIliacaEsternaDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>

        <!---->
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInternalIliacArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artIliacaInternaSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artIliacaInternaVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artIliacaInternaDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCommonFemoralArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artFemoraleComSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artFemoraleComVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artFemoraleComDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
            <b-form-select v-model="jsonData.bscoresx" :options="scoreOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
            <b-form-select v-model="jsonData.bcolsn" :options="colorOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
            <b-form-input v-model="jsonData.maximtnearbsx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
            <b-form-input v-model="jsonData.maximtfarbsx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblFemoralBifurcation") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.biforcazioneFemoraleSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.biforcazioneFemoraleVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.biforcazioneFemoraleDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDeepFemoralArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaFemoraleProfSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleProfVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleProfDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSuperiorFemoralArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaFemoraleSupProxSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupProxVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupProxDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
            <b-form-select v-model="jsonData.ciscoresn" :options="scoreOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
            <b-form-select v-model="jsonData.cecolsn" :options="colorOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
            <b-form-input v-model="jsonData.maximtnearcisx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
            <b-form-input v-model="jsonData.maximtfarcisx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblFemoralArteryMed") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaFemoraleSupMedSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupMedVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupMedDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblFemoralArteryDist") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaFemoraleSupDistSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupDistVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupDistDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPoplitealArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaPopliteaSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaPopliteaVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaPopliteaDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
            <b-form-select v-model="jsonData.cescoresn" :options="scoreOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
            <b-form-select v-model="jsonData.cicolsn" :options="colorOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
            <b-form-input v-model="jsonData.maximtnearcesx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
            <b-form-input v-model="jsonData.maximtfarcesx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <!------->
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorTibialArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaTibialeAntSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaTibialeAntVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaTibialeAntDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblTibioPeronieroTrunk") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.troncoTibioPeronieroSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.troncoTibioPeronieroVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.troncoTibioPeronieroDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorTibialAtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artTibialePostSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artTibialePostVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artTibialePostDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPeronieraArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaPeronieraSx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaPeronieraVpsSx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaPeronieraDescSx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
    </b-row>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            type: Object,
            default: function () {
                return {
                    ccscoresn: null,
                };
            },
        },
    },
    data() {
        return {
            stenosiOptions: [
                { text: "0 - 29 %", value: "0-29%" },
                { text: "30 - 49 %", value: "30-49%" },
                { text: "50 - 69 %", value: "50-69%" },
                { text: "70 - 89 %", value: "70-89%" },
                { text: "90 - 99 %", value: "90-99%" },
                { text: this.getLabelTraduora("patient.cardiology.slcStenosisOcclusion"), value: "patient.cardiology.slcStenosisOcclusion" },
                { text: "NA", value: "NA" },
                { text: "NES", value: "NES" },
            ],
            colorOptions: [
                { text: "L", value: "patient.cardiology.slcColorValueLaminare" },
                { text: "T", value: "patient.cardiology.slcColorValueTurbolento" },
            ],
            scoreOptions: [
                { text: "0", value: "0" },
                { text: "1", value: "1" },
                { text: "2", value: "2" },
                { text: "3", value: "3" },
                { text: "4", value: "4" },
                { text: "5", value: "5" },
            ],
        };
    },
};
</script>

<style></style>
