<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblOrigin") }}</label>
                    <b-form-input v-model="jsonData.provenienza"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblIndications") }}</label>
                    <b-form-input v-model="jsonData.indicazione"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblInstrument") }}</label>
                    <b-form-select v-model="jsonData.strumento" :options="strumentiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblTherapiesInPlace") }}</label>
                    <b-form-textarea v-model="jsonData.terapieInAtto" rows="8" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <!--<b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblWashingDisinfection") }}</label>
                    <b-form-select v-model="jsonData.lavaggioDisinfezione" :options="lavaggiDisinfezioneOptions" :value="null" value-field="descrizione" text-field="descrizione">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card> -->
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblHpTest") }}</label>
                    <b-form-select v-model="jsonData.helicobacterPilori" :options="hpOptions" :value="null">
                        <template #first>
                            <b-form-select-option :value="null" disabled>{{ selectFirstElement }}</b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblEsophagus") }}</label>
                    <b-form-textarea v-model="jsonData.esofago" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblStomach") }}</label>
                    <b-form-textarea v-model="jsonData.stomaco" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblDuodenumFasting") }}</label>
                    <b-form-textarea v-model="jsonData.duodenoDigiuno" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Premedicazione</label>
                    <b-form-textarea v-model="jsonData.premedicazione" no-resize rows="8"></b-form-textarea>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi</label>
                    <b-form-textarea v-model="jsonData.diagnosi" no-resize rows="8"></b-form-textarea>
                </b-col> -->

                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Corpo Referto</label>
                    <b-form-textarea v-model="jsonData.corpoReferto" no-resize rows="8"></b-form-textarea>
                </b-col> -->
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Consigli Terapeutici</label>
                    <b-form-textarea v-model="jsonData.consigliTerapeutici" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblConclusions") }}</label>
                    <b-form-textarea v-model="jsonData.conclusioni" no-resize rows="8"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Diagnosi" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi Duodeno</label>
                    <b-form-textarea v-model="jsonData.diagnosiDuodeno" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi Stomaco</label>
                    <b-form-textarea v-model="jsonData.diagnosiStomaco" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi Helibacter Pylori</label>
                    <b-form-textarea v-model="jsonData.diagnosiHelico" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi Esofago</label>
                    <b-form-textarea v-model="jsonData.diagnosiEsofago" no-resize rows="8"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <eventi-operatori-component :operatori="jsonData.listaOperatori" @update="onUpdateListaOperatori" :isEdit="isEdit"></eventi-operatori-component>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
    components: { DatePicker, EventiOperatoriComponent },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    provenienza: null,
                    helicobacterPilori: null,
                    strumento: null,
                    // lavaggioDisinfezione: null,
                    indicazione: null,
                    diagnosiDuodeno: null,
                    diagnosiStomaco: null,
                    diagnosiHelico: null,
                    diagnosiEsofago: null,
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        me.loadDefaultData();
    },

    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            isEdit: true,
            selectFirstElement: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            pathResource: "/gastroegds",
            pathResourceStrumenti: "/pazienteeventistrumenti",
            perPage: 10000,
            currentPage: 1,
            filtro: {},
            showModalLoading: false,
            strumentiOptions: [{ value: null, text: "-Seleziona Strumento-" }],
            hpOptions: [
                { text: this.getLabelTraduora("patient.egds.SelectNotCarriedOut"), value: this.getLabelTraduora("patient.egds.SelectNotCarriedOut") },
                { text: this.getLabelTraduora("patient.afa.lblPositive"), value: this.getLabelTraduora("patient.afa.lblPositive") },
                { text: this.getLabelTraduora("patient.afa.lblNegative"), value: this.getLabelTraduora("patient.afa.lblNegative") },
            ],
            indicazioneOptions: [],
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        loadDefaultData() {
            let me = this;
            // me.loadLavaggiDisinfezione();
            // me.loadIndicazioni();
            me.loadStrumenti();
        },
        loadStrumenti() {
            let me = this;
            //  me.filtro.page = me.currentPage;
            //    me.filtro.forPage = me.perPage;
            me.filtro.gruppo = "GASTRO";
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStrumenti;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.showModalLoading = false;
                    response.data.data.list.forEach((element) => {
                        me.strumentiOptions.push({ value: element.modello + " (MAT: " + element.codice + ")", text: element.modello + " (MAT: " + element.codice + ")" });
                    });
                })
                .catch();
        },
        onUpdateListaOperatori(listaOperatori) {
            let me = this;
            me.jsonData.listaOperatori = listaOperatori;
        },
        // loadIndicazioni() {
        //     let me = this;
        //     me.showModalLoading = true;
        //     let link = process.env.VUE_APP_PATH_API + me.pathResourceIndicazioni;
        //     axios
        //         .get(link)
        //         .then((response) => {
        //             me.showModalLoading = false;
        //             me.indicazioniOptions = response.data.data;
        //         })
        //         .catch();
        // },
        // loadLavaggiDisinfezione() {
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + me.pathResourceLavaggiDisinfezione;
        //     axios
        //         .get(link)
        //         .then((response) => {
        //             me.showModalLoading = false;
        //             me.lavaggiDisinfezioneOptions = response.data.data;
        //         })
        //         .catch();
        // },
    },
};
</script>

<style></style>
