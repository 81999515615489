<template>
    <sa-page-layout :showmolalloading="showmolalloading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <h5 class="sa-text-left">Dati Prenotazione</h5>
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataRiferimento" type="datetime" placeholder="Data"></date-picker>
                    </b-col>
                    <b-col sm="6">
                        <b-form-select v-model="filtro.descrizionePrestazione" class="mb-2 mr-sm-2 mb-sm-0" :options="prestazioniOption" :value="null" value-field="value" text-field="text" v-on:change="setSelectedPrestazione($event)"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <br />
                        <b-button type="button" variant="info" @click="onConferma">{{ this.$i18n.t("global.lblBtnConferma") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblBtnResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="data" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" @row-clicked="onRowClickHandler">
                    <template v-slot:cell(dalleOre)="{ item }">
                        <a>
                            {{ item.dalleOre }}
                        </a>
                    </template>
                    <template v-slot:cell(descrizionePrestazione)="{ item }">
                        <span>{{ getDescrizionePrestazione(item) }}</span>
                    </template>
                </b-table>
            </div>
            <b-modal id="mdlDettaglioAppuntamento" ref="mdlDettaglioAppuntamento" title="Prenotazione" size="lg" @ok="onOk">
                <div>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <label for="">Cognome e Nome</label>
                            <b-form-input v-model="appuntamento.titolo" readonly></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <label for="">Codice Fiscale</label>
                            <b-form-input v-model="appuntamento.codiceFiscaleAssistito" readonly></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <label for="">Ora Inizio</label>
                            <date-picker :editable="false" :popup-style="{ visibility: 'hidden' }" aria-readonly="readonly" format="HH:mm" value-type="timestamp" v-model="appuntamento.dataOraInizio" type="datetime" placeholder="Data"></date-picker>
                        </b-col>
                        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                            <label for="">Ora Fine</label>
                            <date-picker :editable="false" :popup-style="{ visibility: 'hidden' }" format="HH:mm" value-type="timestamp" v-model="appuntamento.dataOraFine" type="datetime" placeholder="Data"></date-picker>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label for="">Note</label>
                            <b-form-textarea v-model="appuntamento.note" placeholder="" rows="3" max-rows="6"></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import Utility from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";

export default {
    mixins: { Utility },
    components: { DatePicker, SaPageLayout },
    computed: {
        rows() {
            return this.data.length;
        },
    },
    created() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        me.appuntamento = sessionStorage.getItem("prenotazione") == null ? {} : JSON.parse(sessionStorage.getItem("prenotazione"));
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: null,
            pathRest: "/prenotazioniagende",
            linkPrestazioni: "/prestazioni",
            rigaSelezionata: null,
            filtro: { dataRiferimento: null, descrizionePrestazione: null },
            filterOn: [],
            appuntamento: {
                titolo: "",
                dataOraInizio: null,
                dataOraFine: null,
                idAgenda: "",
                codiceFiscaleAssistito: "",
                codiceFiscaleMedico: "",
                codicePrestazione: "",
                descrizionePrestazione: "",
                costo: 0,
                note: "",
            },
            /*appuntamento: {
                titolo: "",
                dataOraInizio: 1630931040000,
                dataOraFine: 1630931280000,
                idAgenda: "eaf6068b-ef62-4fb9-81ce-8a20911cc50a",
                codiceFiscaleAssistito: "NDLSST75B09B781P",
                codiceFiscaleMedico: "NDLSST75B09B781P",
                codicePrestazione: "INF09",
                descrizionePrestazione: "2300 somministrazione di farmaci",
                costo: 0,
                note: "",
            },*/
            showmolalloading: false,
            fields: [
                {
                    label: "",
                    key: "dalleOre",
                    formatter: (value) => {
                        return moment(value).format("HH:mm");
                    },
                    sortable: false,
                    thStyle: "width: 7rem",
                },
                {
                    label: "Prestazioni",
                    key: "descrizionePrestazione",
                    sortable: false,
                },
            ],
            fieldsStoricoPrenotazioni: [],
            data: [],
            storicoPrenotazioni: [],
            prestazioniOption: [{ value: null, text: "--Seleziona Prestazione--" }],
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.appuntamento.idAgenda = this.$route.params.id;
        me.loadPrestazioni();
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("YYYY-MM-DD");
            } else {
                return "---";
            }
        },
        formatTime(value) {
            if (value) {
                return moment(new Date(value)).format("HH:mm");
            } else {
                return "---";
            }
        },
        getDescrizionePrestazione(item) {
            let descrizionePrestazione = "";
            if (item.object) {
                descrizionePrestazione = item.titolo; //+ " (" + item.codicePrestazione + " - " + item.descrizionePrestazione + ") ";
                console.log(descrizionePrestazione);
            }

            return descrizionePrestazione;
        },
        onConferma() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/disponibilita?idAgenda=" + me.id + "&riferimento=" + me.formatDate(me.filtro.dataRiferimento) + "&periodo=" + "G";
            me.isBusy = true;
            axios
                .get(link)
                .then((response) => {
                    me.data = [];
                    me.data = response.data.data;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        loadPrestazioni() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + me.linkPrestazioni + "?idAgenda=" + me.id;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.prestazioniOption.push({ value: element.codicePrestazione, text: element.descrizionePrestazione });
                });
            });
        },
        onActiveTabs(newTabIndex) {
            // let me = this;
            console.log(newTabIndex);
            /*if (newTabIndex === 1) {
                console.log(newTabIndex);
            }*/
        },
        onReset() {},
        onRowClickHandler(record) {
            let me = this;
            me.appuntamento.dataOraInizio = moment(record.dataRiferimento + " " + record.dalleOre, "YYYY-MM-DD HH:mm")
                .toDate()
                .getTime();
            me.appuntamento.dataOraFine = moment(record.dataRiferimento + " " + record.alleOre, "YYYY-MM-DD HH:mm")
                .toDate()
                .getTime();

            me.$refs["mdlDettaglioAppuntamento"].show();
        },
        onOk() {
            let me = this;
            console.log(me.appuntamento);
            let jsonData = JSON.stringify(me.appuntamento);
            let link = process.env.VUE_APP_PATH_API + "/prenotazioniagende/appuntamento";
            me.showmolalloading = true;
            axios
                .post(link, jsonData)
                .then((response) => {
                    me.showmolalloading = false;
                    console.log(response);
                    // me.data = response.data.data;
                    // me.showmolalloading = false;
                    // me.$router.replace(me.routeLink).catch((err) => {
                    //     err;
                    // });
                })
                .catch((error) => {
                    me.showmolalloading = false;
                    this.$bvModal
                        .msgBoxOk(error.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
        setSelectedPrestazione(newPrestazione) {
            let me = this;
            me.prestazioniOption.forEach((element) => {
                if (element.value === newPrestazione) {
                    me.appuntamento.codicePrestazione = element.value;
                    me.appuntamento.descrizionePrestazione = element.text;
                }
            });
        },
    },
};
</script>
