import Vue from 'vue';
<template>
    <sa-page-layout :btnSaveVisible="false" :data="jsonData" :btnAnnullaVisible="false" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <svei-mappa></svei-mappa>
        </template>
    </sa-page-layout>
</template>

<script>
import SveiMappa from "../components/SveiMappaComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout, SveiMappa },
    data() {
        return {
            linkback: "",
            pathResource: "",
            showModalLoading: false,
            jsonData: {},
        };
    },
};
</script>

<style></style>
