<template>
    <malattie-infettive-list-component :pathResource="pathResource" :utente="utente" :fonte="'/all'"></malattie-infettive-list-component>
</template>

<script>
import MalattieInfettiveListComponent from "../components/MalattieInfettiveListComponent.vue";
export default {
    props: {
        utente: { type: Object },
    },
    components: { MalattieInfettiveListComponent },
    data() {
        return {
            pathResource: "/malattieinfettivesegnalazioni",
        };
    },
};
</script>
