<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Peso Nascita:</label>
          <b-form-input v-model="jsonData.pesoNascita"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Eta' gestazionale (settimane):</label>
          <b-form-input v-model="jsonData.etaGestazionaleNascita"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Bambino in Vita" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Bambino in Vita:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVita" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="!chekCondition(jsonData.bambinoVita, 'NO')">
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Decesso:</label>
          <date-picker v-model="jsonData.bambinoVitaDataDecesso" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Autopsia Eseguita:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaAutopsiaEseguita" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Referto Anatomopatologico Finale:</label>
          <b-form-input v-model="jsonData.bambinoVitaRefertoAnatomopatologicoFinale"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Causa Morte Iniziale:</label>
          <b-form-input v-model="jsonData.bambinoVitaCausaMorteIniziale"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Causa Morte Intermedia:</label>
          <b-form-input v-model="jsonData.bambinoVitaCausaMorteIntermedia"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Causa Morte Finale:</label>
          <b-form-input v-model="jsonData.bambinoVitaCausaMorteFinale"></b-form-input>
        </b-col>
      </b-row>
      <b-row v-if="!chekCondition(jsonData.bambinoVita, 'SI')">
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Ultima Valutazione:</label>
          <date-picker v-model="jsonData.bambinoVitaDataUltimaValutazione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ipoacussia/Sordita':</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaIpoacusiaSordita" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cataratta:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaCataratta" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Glaucoma Congenito:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaGlaucomaCongenito" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Retinopatia Pigmentosa:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaRetinopatiaPigmentata" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cardiopatia Congenita:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaCardiopatiaCongenita" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cardiopatia Congenita Dettaglio:</label>
          <!--Mettere  watche e resettare campi-->
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaCardiopatiaCongenitaDettaglio" :options="cardiopatiaCongenitaOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.bambinoVitaCardiopatiaCongenita, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altra Cardiopatia:</label>
          <b-form-input v-model="jsonData.cardiopatiaCongenitaDettaglioAltro" :disabled="chekCondition(jsonData.bambinoVitaCardiopatiaCongenitaDettaglio, 'ALTRA')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altri Sintomi:</label>
          <b-form-input v-model="jsonData.bambinoVitaAltriSegniSintomi"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Porpora:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bmbinoVitaPorpora" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Splenomegalia:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaSplenomegaglia" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Microcefalia:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaMicroencefalia" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ritardo Sviluppo Psicomotorio:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaRitardoSviluppoPsicomotorio" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Meningoencefalite:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaMeningoencefalite" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Radiolucenza Ossa Lunghe:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaRadiolicenzaOssea" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ittero Entro 24 Ore Nascita:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaItteroVentiquattroOreNascita" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccino Rosolia:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.bambinoVitaVaccinoRosolia" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Vaccino:</label>
          <date-picker v-model="jsonData.bambinoVitaVaccinoRosoliaData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.bambinoVitaVaccinoRosolia, 'SI')"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Storia Materna" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Codice Fiscale/STP/ENI:</label>
          <b-form-input v-model="jsonData.madreIdentificativo"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cognome:</label>
          <b-form-input v-model="jsonData.madreCognome"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome:</label>
          <b-form-input v-model="jsonData.madreNome"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Nascita:</label>
          <date-picker v-model="jsonData.madreDataNascita" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Ultima Mestruazione:</label>
          <date-picker v-model="jsonData.madreDataUltimaMestruazione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Rubeotest Eseguito Prima Gravidanza :</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreRobeotestPrimaGravidanza" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anno Esecuzione:</label>
          <b-form-input v-model="jsonData.madreRobeotestPrimaGravidanzaAnno" maxlength="4" :disabled="chekCondition(jsonData.madreRobeotestPrimaGravidanza, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risultato:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreRobeotestPrimaGravidanzaRisultato" :options="risultatoRubeotestOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.madreRobeotestPrimaGravidanza, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Rubeotest Eseguito Durante Questa Gravidanza :</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreRobeotestDuranteGravidanza" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anno Esecuzione:</label>
          <b-form-input v-model="jsonData.madreRobeotestDuranteGravidanzaData" maxlength="4" :disabled="chekCondition(jsonData.madreRobeotestDuranteGravidanza, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risultato:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreRobeotestDuranteGravidanzaRisultato" :options="risultatoRubeotestOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.madreRobeotestDuranteGravidanza, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Occupazione Momento Concepimento:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreOccupazioneMomentoConcepimento" :options="occupazioneMomentoConcepimentoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Gravidanze Inclusa Presente:</label>
          <b-form-input v-model="jsonData.madreNumeroGravidanze"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Parti:</label>
          <b-form-input v-model="jsonData.madreNumeroParti"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccino Contro Rosolia:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreVaccinoRosolia" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Vaccino:</label>
          <date-picker v-model="jsonData.madreVaccinoRosoliaData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.madreVaccinoRosolia, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malattia Simile Rosolia in Gravidanza:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreMalattiaSimileRosoliaGravidanza" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Settimana Gestazione:</label>
          <b-form-input v-model="jsonData.madreRosoliaGravidanzaSettimana" :disabled="chekCondition(jsonData.madreMalattiaSimileRosoliaGravidanza, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Esantema Maculopapulare:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreEsantemaMaculopapulare" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Comparsa:</label>
          <date-picker v-model="jsonData.madreEsantemaMaculopapulareDataComparsa" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.madreEsantemaMaculopapulare, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreFebbre" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Adenopatia Cervicale:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreAdenopatiaCervicale" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Adenopatia Sub Occipitale:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreAdenopatiaSubOccipitale" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Adenopatia Retro Auricolare:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreAdenopatiaRetroAuricolare" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Artralgia/Artrite:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreArtralgiaArtrite" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreAltro" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diagnosi Confermata in Laboratorio:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreDiagnosiRosoliaConfermata" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Settimana di Gestaizone:</label>
          <b-form-input v-model="jsonData.madreDiagnosiRosoliaConfermataSettimanaGestazione" :disabled="chekCondition(jsonData.madreDiagnosiRosoliaConfermata, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Test:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreDiagnosiRosoliaConfermataTipoTest" :options="tipoTestOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.madreDiagnosiRosoliaConfermata, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Esposizione Caso Rosolia/Esantema Maculopapulare Gravidanza:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreEsposizioneRosoliaEsantemaMaculopatia" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Caso Confermato Laboratorio:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.madreEsposizioneRosoliaEsantemaMaculopatiaConfermato" :options="siNoNonNotoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.madreEsposizioneRosoliaEsantemaMaculopatia, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Settimane Gestazione:</label>
          <b-form-input v-model="jsonData.madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoSetti" :disabled="chekCondition(jsonData.madreEsposizioneRosoliaEsantemaMaculopatia, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Luogo Esposizione:</label>
          <b-form-input v-model="jsonData.madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoLuogo" :disabled="chekCondition(jsonData.madreEsposizioneRosoliaEsantemaMaculopatia, 'SI')"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Viaggio Estero Periodo Incubazione:</label>
          <b-form-select v-model="jsonData.madreFonteInfezioneNonNotaViaggi" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="!chekCondition(jsonData.madreFonteInfezioneNonNotaViaggi, 'SI')">
        <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaViaggi" :fields="fieldsViaggi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(dove)="item">
            <b-form-input v-model="item.item.dove" placeholder="Luogo"></b-form-input>
          </template>
          <template #cell(giornoDal)="item">
            <date-picker v-model="item.item.giornoDal" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
          </template>
          <template #cell(giornoAl)="item">
            <date-picker v-model="item.item.giornoAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="row.index == jsonData.listaViaggi.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddViaggi(jsonData.listaViaggi, row.item)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== jsonData.listaViaggi.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaViaggi, row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Esami Laboratorio Bambino" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricerca Operativa Anticorpi IgM:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricercaAnticorpiIgmRosolia" :options="effettuatoNonOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricercaAnticorpiIgmRosoliaTipoCampione" :options="tipoCampioneOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.ricercaAnticorpiIgmRosolia, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Laboratorio:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricercaAnticorpiIgmRosoliaLaboratorio" :options="laboratorioOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.ricercaAnticorpiIgmRosolia, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <b-form-input v-model="jsonData.ricercaAnticorpiIgmRosoliaLaboratorioAltro" :disabled="chekCondition(jsonData.ricercaAnticorpiIgmRosoliaLaboratorio, 'ALTRO')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Metodo:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricercaAnticorpiIgmRosoliaMetodo" :options="metodoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.ricercaAnticorpiIgmRosolia, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risultato:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricercaAnticorpiIgmRosoliaRisultato" :options="risultatoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.ricercaAnticorpiIgmRosolia, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricerca Operativa Anticorpi IgG:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricercaAnticorpiIggRosoliaSpecifici" :options="effettuatoNonOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricercaAnticorpiIggRosoliaSpecificiTipoCampione" :options="tipoCampioneOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.ricercaAnticorpiIggRosoliaSpecifici, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Laboratorio:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricercaAnticorpiIggRosoliaSpecificiLaboratorio" :options="laboratorioOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.ricercaAnticorpiIggRosoliaSpecifici, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <b-form-input v-model="jsonData.ricercaAnticorpiIggRosoliaSpecificiLaboratorioAltro" :disabled="chekCondition(jsonData.ricercaAnticorpiIggRosoliaSpecificiLaboratorio, 'ALTRO')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Metodo:</label>
          <b-form-input v-model="jsonData.ricercaAnticorpiIggRosoliaSpecificiMetodoValoriRiferimen" :disabled="chekCondition(jsonData.ricercaAnticorpiIggRosoliaSpecifici, 'EFFETTUATO')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risultato:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricercaAnticorpiIggRosoliaSpecificiRisultato" :options="risultatoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.ricercaAnticorpiIggRosoliaSpecifici, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <!---->
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Isolamento Virale Liquidi Biologici:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.isolamentoViraleLiquidiBiologici" :options="effettuatoNonOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.isolamentoViraleLiquidiBiologiciTipoCampione" :options="tipoCampioneOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.isolamentoViraleLiquidiBiologici, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione Altro:</label>
          <b-form-input v-model="jsonData.isolamentoViraleLiquidiBiologiciTipoCampioneAltro" :disabled="chekCondition(jsonData.isolamentoViraleLiquidiBiologiciTipoCampione, 'ALTRO')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Prelievo:</label>
          <date-picker v-model="jsonData.isolamentoViraleLiquidiBiologiciDataPrelievo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.isolamentoViraleLiquidiBiologici, 'EFFETTUATO')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Laboratorio:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.isolamentoViraleLiquidiBiologiciLaboratorio" :options="laboratorioOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.isolamentoViraleLiquidiBiologici, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altro:</label>
          <b-form-input v-model="jsonData.isolamentoViraleLiquidiBiologiciLaboratorioAltro" :disabled="chekCondition(jsonData.isolamentoViraleLiquidiBiologiciLaboratorio, 'ALTRO')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.isolamentoViraleLiquidiBiologiciRisultato" :options="risultatoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.isolamentoViraleLiquidiBiologici, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <!---->
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Identificazione Genoma Virale RT-PCR:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.identificazioneGenomaViraleRtPcr" :options="effettuatoNonOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.identificazioneGenomaViraleRtPcrTipoCampione" :options="tipoCampioneOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.identificazioneGenomaViraleRtPcr, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione Altro:</label>
          <b-form-input v-model="jsonData.identificazioneGenomaViraleRtPcrTipoCampioneAltro" :disabled="chekCondition(jsonData.identificazioneGenomaViraleRtPcrTipoCampione, 'ALTRO')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Prelievo:</label>
          <date-picker v-model="jsonData.identificazioneGenomaViraleRtPcrDataPrelievo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.identificazioneGenomaViraleRtPcr, 'EFFETTUATO')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Laboratorio:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.identificazioneGenomaViraleRtPcrLaboratorio" :options="laboratorioOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.identificazioneGenomaViraleRtPcr, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altro:</label>
          <b-form-input v-model="jsonData.identificazioneGenomaViraleRtPcrLaboratorioAltro" :disabled="chekCondition(jsonData.identificazioneGenomaViraleRtPcrLaboratorio, 'ALTRO')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.identificazioneGenomaViraleRtPcrRisultato" :options="risultatoOptions" value="" value-field="value" text-field="text" :disabled="chekCondition(jsonData.identificazioneGenomaViraleRtPcr, 'EFFETTUATO')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Genotipizzazione:</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.genotipizzazione" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ceppo:</label>
          <b-form-input v-model="jsonData.genotipizzazioneCeppo" :disabled="chekCondition(jsonData.genotipizzazione, 'SI')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import Vue from "vue";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { IndagineEpidemiologicaDatiNotificaComponent, DatePicker }, //
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveierosoliacongenita",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      showModalLoading: false,
      linkNazioni: "/nazioni",
      disableSintomi: true,
      jsonData: {
        pesoNascita: "",
        etaGestazionaleNascita: "",
        bambinoVita: "",
        bambinoVitaDataDecesso: null,
        bambinoVitaAutopsiaEseguita: "",
        bambinoVitaRefertoAnatomopatologicoFinale: "",
        bambinoVitaCausaMorteIniziale: "",
        bambinoVitaCausaMorteIntermedia: "",
        bambinoVitaCausaMorteFinale: "",
        bambinoVitaDataUltimaValutazione: null,
        bambinoVitaIpoacusiaSordita: "",
        bambinoVitaCataratta: "",
        bambinoVitaGlaucomaCongenito: "",
        bambinoVitaRetinopatiaPigmentata: "",
        bambinoVitaCardiopatiaCongenita: "",
        bambinoVitaCardiopatiaCongenitaDettaglio: "",
        bambinoVitaAltriSegniSintomi: "",
        bmbinoVitaPorpora: "",
        bambinoVitaSplenomegaglia: "",
        bambinoVitaMicroencefalia: "",
        bambinoVitaRitardoSviluppoPsicomotorio: "",
        bambinoVitaMeningoencefalite: "",
        bambinoVitaRadiolicenzaOssea: "",
        bambinoVitaItteroVentiquattroOreNascita: "",
        bambinoVitaVaccinoRosolia: "",
        bambinoVitaVaccinoRosoliaData: null,
        madreIdentificativo: "",
        madreCognome: "",
        madreNome: "",
        madreDataNascita: null,
        madreRobeotestPrimaGravidanza: "",
        madreRobeotestPrimaGravidanzaAnno: "",
        madreRobeotestPrimaGravidanzaRisultato: "",
        madreRobeotestDuranteGravidanza: "",
        madreRobeotestDuranteGravidanzaData: null,
        madreRobeotestDuranteGravidanzaRisultato: "",
        madreOccupazioneMomentoConcepimento: "",
        madreNumeroGravidanze: "",
        madreNumeroParti: "",
        madreVaccinoRosolia: "",
        madreVaccinoRosoliaData: null,
        madreMalattiaSimileRosoliaGravidanza: "",
        madreRosoliaGravidanzaSettimana: "",
        madreEsantemaMaculopapulare: "",
        madreEsantemaMaculopapulareDataComparsa: null,
        madreFebbre: "",
        madreAdenopatiaCervicale: "",
        madreAdenopatiaSubOccipitale: "",
        madreAdenopatiaRetroAuricolare: "",
        madreArtralgiaArtrite: "",
        madreAltro: "",
        madreDiagnosiRosoliaConfermata: "",
        madreDiagnosiRosoliaConfermataSettimanaGestazione: "",
        madreDiagnosiRosoliaConfermataTipoTest: "",
        madreEsposizioneRosoliaEsantemaMaculopatia: "",
        madreEsposizioneRosoliaEsantemaMaculopatiaConfermato: "",
        madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoSetti: "",
        madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoLuogo: "",
        madreFonteInfezioneNonNotaViaggi: "",
        ricercaAnticorpiIgmRosolia: "",
        ricercaAnticorpiIgmRosoliaTipoCampione: "",
        ricercaAnticorpiIgmRosoliaLaboratorio: "",
        ricercaAnticorpiIgmRosoliaLaboratorioAltro: "",
        ricercaAnticorpiIgmRosoliaMetodo: "",
        ricercaAnticorpiIgmRosoliaRisultato: "",
        ricercaAnticorpiIggRosoliaSpecifici: "",
        ricercaAnticorpiIggRosoliaSpecificiTipoCampione: "",
        ricercaAnticorpiIggRosoliaSpecificiLaboratorio: "",
        ricercaAnticorpiIggRosoliaSpecificiLaboratorioAltro: "",
        ricercaAnticorpiIggRosoliaSpecificiMetodoValoriRiferimen: "",
        ricercaAnticorpiIggRosoliaSpecificiRisultato: "",
        isolamentoViraleLiquidiBiologici: "",
        isolamentoViraleLiquidiBiologiciTipoCampione: "",
        isolamentoViraleLiquidiBiologiciTipoCampioneAltro: "",
        isolamentoViraleLiquidiBiologiciDataPrelievo: null,
        isolamentoViraleLiquidiBiologiciLaboratorio: "",
        isolamentoViraleLiquidiBiologiciLaboratorioAltro: "",
        isolamentoViraleLiquidiBiologiciRisultato: "",
        identificazioneGenomaViraleRtPcr: "",
        identificazioneGenomaViraleRtPcrTipoCampione: "",
        identificazioneGenomaViraleRtPcrTipoCampioneAltro: "",
        identificazioneGenomaViraleRtPcrDataPrelievo: null,
        identificazioneGenomaViraleRtPcrLaboratorio: "",
        identificazioneGenomaViraleRtPcrLaboratorioAltro: "",
        identificazioneGenomaViraleRtPcrRisultato: "",
        genotipizzazione: "",
        genotipizzazioneCeppo: "",
        sanitarioNotificante: "",
        sanitarioNotificanteContatto: "",
        dataNotifica: null,
        idAnagrafica: "",
        cardiopatiaCongenitaDettaglioAltro: "",
        madreDataUltimaMestruazione: null,
        listaViaggi: [],
      },
      fieldsViaggi: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Luogo",
          key: "dove",
          sortable: false,
        },
        {
          label: "Dal",
          key: "giornoDal",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Al",
          key: "giornoAl",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      effettuatoNonOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "EFFETTUATO", text: "EFFETTUATO" },
        { value: "NON EFFETTUATO", text: "NON EFFETTUATO" },
      ],
      metodoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "ELISA", text: "ELISA" },
        { value: "ALTRO", text: "ALTRO" },
      ],
      risultatoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
        { value: "DUBBIO", text: "DUBBIO" },
      ],
      tipoCampioneOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SIERO O SANGUE", text: "SIERO O SANGUE" },
        { value: "SALIVA", text: "SALIVA" },
        { value: "ALTRO", text: "ALTRO" },
      ],
      siNoNonNotoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      laboratorioOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "LAB RIF. NAZIONALE", text: "LAB RIF. NAZIONALE" },
        { value: "LAB RIF. REGIONALE", text: "LAB RIF. REGIONALE" },
        { value: "ALTRO", text: "ALTRO" },
      ],
      cardiopatiaCongenitaOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "PERVIETÀ DOTTO ARTERIOSO", text: "PERVIETÀ DOTTO ARTERIOSO" },
        { value: "STENOSI POLMONARE PERIFER.", text: "STENOSI POLMONARE PERIFER." },
        { value: "CARD. CONGENITA NON NOTA", text: "CARD. CONGENITA NON NOTA" },
        { value: "ALTRA", text: "ALTRA" },
      ],
      risultatoRubeotestOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "IMMUNE", text: "IMMUNE" },
        { value: "SUSCETTIBILE", text: "SUSCETTIBILE" },
        { value: "DUBBIO", text: "DUBBIO" },
      ],
      occupazioneMomentoConcepimentoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "OPERATORE SANITARIO", text: "OPERATORE SANITARIO" },
        { value: "OPERATORE SCOLASTICO", text: "OPERATORE SCOLASTICO" },
        { value: "ALTRO", text: "ALTRO" },
      ],
      tipoTestOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "ISOLAMENTO VIRALE", text: "ISOLAMENTO VIRALE" },
        { value: "IDENTIFICAZIONE ACIDO NUCLEICO DEL VIRUS DELLA ROSOLIA RT-PCR", text: "IDENTIFICAZIONE ACIDO NUCLEICO DEL VIRUS DELLA ROSOLIA RT-PCR" },
        { value: "PRESENZA IGM SPECIFICHE", text: "PRESENZA IGM SPECIFICHE" },
        { value: "IGG SPECIFICHE CON SIEROCONVERSIONE O AUMENTO DEL TITOLO DI ALMENO 4 VOLTE", text: "IGG SPECIFICHE CON SIEROCONVERSIONE O AUMENTO DEL TITOLO DI ALMENO 4 VOLTE" },
        { value: "TEST AVIDITÀ IGG", text: "TEST AVIDITÀ IGG" },
        { value: "IMMINOBLOTTING", text: "IMMINOBLOTTING" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.madreFonteInfezioneNonNotaViaggi": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaViaggi.push({ dove: "", giornoDal: null, giornoAl: null });
      } else {
        me.jsonData.listaViaggi = [];
      }
    },
    "jsonData.bambinoVitaCardiopatiaCongenita": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.bambinoVitaCardiopatiaCongenitaDettaglio = "";
      }
    },
    "jsonData.bambinoVitaCardiopatiaCongenitaDettaglio": function (value) {
      let me = this;
      if (value !== "ALTRA") {
        me.jsonData.cardiopatiaCongenitaDettaglioAltro = "";
      }
    },
    "jsonData.bambinoVitaVaccinoRosolia": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.bambinoVitaVaccinoRosoliaData = null;
      }
    },
    "jsonData.madreRobeotestPrimaGravidanza": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.madreRobeotestPrimaGravidanzaAnno = "";
        me.jsonData.madreRobeotestPrimaGravidanzaRisultato = "";
      }
    },
    "jsonData.madreRobeotestDuranteGravidanza": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.madreRobeotestDuranteGravidanzaData = "";
        me.jsonData.madreRobeotestDuranteGravidanzaRisultato = "";
      }
    },
    "jsonData.madreVaccinoRosolia": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.madreVaccinoRosoliaData = null;
      }
    },
    "jsonData.madreMalattiaSimileRosoliaGravidanza": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.madreRosoliaGravidanzaSettimana = "";
      }
    },
    "jsonData.madreEsantemaMaculopapulare": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.madreEsantemaMaculopapulareDataComparsa = null;
      }
    },
    "jsonData.madreDiagnosiRosoliaConfermata": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.madreDiagnosiRosoliaConfermataSettimanaGestazione = "";
        me.jsonData.madreDiagnosiRosoliaConfermataTipoTest = "";
      }
    },
    "jsonData.madreEsposizioneRosoliaEsantemaMaculopatia": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.madreEsposizioneRosoliaEsantemaMaculopatiaConfermato = "";
        me.jsonData.madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoSetti = "";
        me.jsonData.madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoLuogo = "";
      }
    },
    "jsonData.ricercaAnticorpiIgmRosolia": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.ricercaAnticorpiIgmRosoliaTipoCampione = "";
        me.jsonData.ricercaAnticorpiIgmRosoliaLaboratorio = "";
        me.jsonData.ricercaAnticorpiIgmRosoliaLaboratorioAltro = "";
        me.jsonData.ricercaAnticorpiIgmRosoliaMetodo = "";
        me.jsonData.ricercaAnticorpiIgmRosoliaRisultato = "";
      }
    },
    "jsonData.ricercaAnticorpiIgmRosoliaLaboratorio": function (value) {
      let me = this;
      if (value !== "ALTRO") {
        me.jsonData.ricercaAnticorpiIgmRosoliaLaboratorioAltro = "";
      }
    },
    "jsonData.ricercaAnticorpiIggRosoliaSpecifici": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.ricercaAnticorpiIggRosoliaSpecificiTipoCampione = "";
        me.jsonData.ricercaAnticorpiIggRosoliaSpecificiLaboratorio = "";
        me.jsonData.ricercaAnticorpiIggRosoliaSpecificiLaboratorioAltro = "";
        me.jsonData.ricercaAnticorpiIggRosoliaSpecificiMetodoValoriRiferimen = "";
        me.jsonData.ricercaAnticorpiIggRosoliaSpecificiRisultato = "";
      }
    },
    "jsonData.ricercaAnticorpiIggRosoliaSpecificiLaboratorio": function (value) {
      let me = this;
      if (value !== "ALTRO") {
        me.jsonData.ricercaAnticorpiIggRosoliaSpecificiLaboratorioAltro = "";
      }
    },
    "jsonData.isolamentoViraleLiquidiBiologici": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.isolamentoViraleLiquidiBiologiciTipoCampione = "";
        me.jsonData.isolamentoViraleLiquidiBiologiciTipoCampioneAltro = "";
        me.jsonData.isolamentoViraleLiquidiBiologiciDataPrelievo = null;
        me.jsonData.isolamentoViraleLiquidiBiologiciLaboratorio = "";
        me.jsonData.isolamentoViraleLiquidiBiologiciLaboratorioAltro = "";
        me.jsonData.isolamentoViraleLiquidiBiologiciRisultato = "";
      }
    },
    "jsonData.isolamentoViraleLiquidiBiologiciTipoCampione": function (value) {
      let me = this;
      if (value !== "ALTRP") {
        me.jsonData.isolamentoViraleLiquidiBiologiciTipoCampioneAltro = "";
      }
    },
    "jsonData.isolamentoViraleLiquidiBiologiciLaboratorio": function (value) {
      let me = this;
      if (value !== "ALTRP") {
        me.jsonData.isolamentoViraleLiquidiBiologiciLaboratorioAltro = "";
      }
    },
    "jsonData.identificazioneGenomaViraleRtPcr": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.identificazioneGenomaViraleRtPcrTipoCampione = "";
        me.jsonData.identificazioneGenomaViraleRtPcrTipoCampioneAltro = "";
        me.jsonData.identificazioneGenomaViraleRtPcrDataPrelievo = null;
        me.jsonData.identificazioneGenomaViraleRtPcrLaboratorio = "";
        me.jsonData.identificazioneGenomaViraleRtPcrLaboratorioAltro = "";
        me.jsonData.identificazioneGenomaViraleRtPcrRisultato = "";
      }
    },
    "jsonData.identificazioneGenomaViraleRtPcrTipoCampione": function (value) {
      let me = this;
      if (value !== "ALTRO") {
        me.jsonData.identificazioneGenomaViraleRtPcrTipoCampioneAltro = "";
      }
    },
    "jsonData.identificazioneGenomaViraleRtPcrLaboratorio": function (value) {
      let me = this;
      if (value !== "ALTRO") {
        me.jsonData.identificazioneGenomaViraleRtPcrLaboratorioAltro = "";
      }
    },
    "jsonData.genotipizzazione": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.genotipizzazioneCeppo = "";
      }
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.id = me.id;
    me.loadMalattiaInfettivaSegnalazione();
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
        });
    },
    loadMalattiaInfettivaSegnalazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.setDatiMalattia(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onAttivaTabella() {
      let me = this;
      me.jsonData.listaFarmaci = [];
      if (me.jsonData.seiSettimanePrimaAssunzioneFarmaci === "SI") {
        me.jsonData.listaFarmaci.push({ dove: "", giornoDal: null, giornoAl: null });
      }
    },
    onAddViaggi(array, value) {
      if (value.dove !== "" && value.giornoDal !== null && value.giornoAl !== null) {
        array.push({ dove: "", giornoDal: null, giornoAl: null });
      } else {
        this.$bvModal
          .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
