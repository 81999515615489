<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNextCheckDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataProssimoControllo) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPaceMakerImplant')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                    ><span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblFirstImplantDate") }}</span>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataPrimoImpianto) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                    ><span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblSubstitutionDate") }}</span>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataSostituzione) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                    <span class="sa-data">{{ jsonData.sostituzione }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInplantIndication") }}</label>
                    <span class="sa-data">{{ jsonData.sintomi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="5" lg="5" xl="5">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStimulationSystem") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.sistemaDiStimolazione) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBaseFrequency") }}</label>
                    <span class="sa-data">{{ jsonData.frequenzaBase }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConstructor") }}</label>
                    <span class="sa-data">{{ jsonData.costruttore }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModel") }}</label>
                    <span class="sa-data">{{ jsonData.tipoCostruttore }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSerialNumber") }}</label>
                    <span class="sa-data">{{ jsonData.serialeIcd }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAtrialCatheter')" header-tag="header" footer-tag="footer" title="">
            <b-row id="catetere-atriale-1">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblImplantDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataImpiantoAtriale) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSerialNumber") }}</label>
                    <span class="sa-data">{{ jsonData.serialeCatetAtriale }}</span>
                </b-col>
            </b-row>
            <b-row id="catetere-atriale-2">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModel") }}</label>
                    <span class="sa-data">{{ jsonData.modelloCatAtriale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConstructor") }}</label>
                    <span class="sa-data">{{ jsonData.costruttoreCatAtriale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tipoCostruttoreCatAtriale) }}</span>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightVentricularCatheter')" header-tag="header" footer-tag="footer" title="">
            <b-row id="catetere-ventr-destro-1">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblImplantDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataImpiantoVentricolare) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSerialNumber") }}</label>
                    <span class="sa-data">{{ jsonData.serialeCatetVentr }}</span>
                </b-col>
            </b-row>
            <b-row id="catetere-ventr-destro-2">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModel") }}</label>
                    <span class="sa-data">{{ jsonData.modelloCatVentricolare }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConstructor") }}</label>
                    <span class="sa-data">{{ jsonData.costruttoreCatVentricolare }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tipoCostruttoreCatVentricolare) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLeftVentricularCatheter')" header-tag="header" footer-tag="footer" title="">
            <b-row id="catetere-ventr-sinistro-1">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblImplantDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataImpiantoVentrSx) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSerialNumber") }}</label>
                    <span class="sa-data">{{ jsonData.serialeCatetVentrSx }}</span>
                </b-col>
            </b-row>
            <b-row id="catetere-ventr-sinistro-2">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModel") }}</label>
                    <span class="sa-data">{{ jsonData.modelloCatVentrSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConstructor") }}</label>
                    <span class="sa-data">{{ jsonData.costruttoreCatVentrSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tipoCostrutCatVentrSx) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                    <span class="sa-data">{{ jsonData.commento1 }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAtrialElectrode')" header-tag="header" footer-tag="footer" title="">
            <b-row id="elettrodo-atriale">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVein") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.venaAtr) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblThreshold") }}</label>
                    <span class="sa-data">{{ jsonData.sogliaAtriale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCurrent") }}</label>
                    <span class="sa-data">{{ jsonData.correnteAtriale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblImpedance") }}</label>
                    <span class="sa-data">{{ jsonData.resistenzeAtriale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWaveP") }}</label>
                    <span class="sa-data">{{ jsonData.ondaPAtriale }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightVentricularElectrode')" header-tag="header" footer-tag="footer" title="">
            <b-row id="elettrodo-ventricolare-destro">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVein") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.venaVentrDx) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblThreshold") }}</label>
                    <span class="sa-data">{{ jsonData.sogliaVentricolareDx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCurrent") }}</label>
                    <span class="sa-data">{{ jsonData.correnteVentricolareDx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblImpedance") }}</label>
                    <span class="sa-data">{{ jsonData.resistenzeVentrDx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWaveP") }}</label>
                    <span class="sa-data">{{ jsonData.ondaRVentricolareDx }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblleftVentricularElectrode')" header-tag="header" footer-tag="footer" title="">
            <b-row id="elettrodo-ventricolare-sinistro">
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVein") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.venaVentrSx) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblThreshold") }}</label>
                    <span class="sa-data">{{ jsonData.sogliaVentricolareSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCurrent") }}</label>
                    <span class="sa-data">{{ jsonData.correnteVentricolareSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblImpedance") }}</label>
                    <span class="sa-data">{{ jsonData.resistenzeVentrSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWaveP") }}</label>
                    <span class="sa-data">{{ jsonData.ondaRVentricolareSx }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblDefaultProgramming')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataProssimoControllo) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPulseDurationAtrium") }}</label>
                    <span class="sa-data">{{ jsonData.durataImpulsoAtr1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPulseDurationRightVentricle") }}</label>
                    <span class="sa-data">{{ jsonData.durataImpulsoVentr1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPulseDurationLeftVentricle") }}</label>
                    <span class="sa-data">{{ jsonData.durataImpulsoVentr1Sx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModality") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.modalita1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSpontaneousHeartFrequency") }}</label>
                    <span class="sa-data">{{ jsonData.fcSpontanea1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMagneticHeartFrequency") }}</label>
                    <span class="sa-data">{{ jsonData.fcMagnetica1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxHeartFrequency") }}</label>
                    <span class="sa-data">{{ jsonData.fcMax1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMinHeartFrequency") }}</label>
                    <span class="sa-data">{{ jsonData.fcMin1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialSensibility") }}</label>
                    <span class="sa-data">{{ jsonData.sensibilitaAtr1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVetricleSensibility") }}</label>
                    <span class="sa-data">{{ jsonData.sensibilitaVentr1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftVetricleSensibility") }}</label>
                    <span class="sa-data">{{ jsonData.sensibilitaVentr1Sx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAvDelay") }}</label>
                    <span class="sa-data">{{ jsonData.av1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCapacitorChargingTime") }}</label>
                    <span class="sa-data">{{ jsonData.ricaricaCondens1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBatteryCurrent") }}</label>
                    <span class="sa-data">{{ jsonData.correnteBatteria1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBatteryImpedance") }}</label>
                    <span class="sa-data">{{ jsonData.impedenzaBatteria1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBatteryConditions") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.statoBatteria1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrialPulseWidth") }}</label>
                    <span class="sa-data">{{ jsonData.ampImpAtr1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightVentriclePulseWidth") }}</label>
                    <span class="sa-data">{{ jsonData.ampImpVentr1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftVentriclePulseWidth") }}</label>
                    <span class="sa-data">{{ jsonData.ampImpVentr1Sx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblShockImpedance") }}</label>
                    <span class="sa-data">{{ jsonData.impedenzaShock }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                    <span class="sa-data">{{ jsonData.altro1 }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {},
    components: {},
    data() {
        return {
            pathResource: "/cardiopacemakerimpianti",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
