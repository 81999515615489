<template>
  <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="true" :btnPrintVisible="true" :linkedit="linkEdit" :btnBackVisible="true" :linkback="linkback" class="sa-no-space" :linkPrintData="linkPrintData">
    <template slot="toolbar">
      <print-component :data="jsonData" :linkPrintData="getLinkPrintData(jsonData)" typeButton="normal" nomeCampo="Stampa Giudizio Finale"></print-component>
      <b-button v-if="tabIndex === 6 && jsonData.id != '-1'" variant="outline-success btn-toolbar sa-margin-right float-sm-right" v-b-modal.mdlAllegati>
        <b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>
        {{ getLabelTraduora("patient.afa.btnNewAllegato") }}
      </b-button>
      <b-button v-if="tabIndex === 6 && jsonData.id != '-1'" class="float-sm-right primary" variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center>
        <b-icon icon="arrow-clockwise" />
        {{ getLabelTraduora("global.lblBtnRefresh") }}
      </b-button>
    </template>
    <template slot="toolbar-title">
      <paziente-anagrafica @getEtaPaziente="getEtaPaziente" @getAnagraficaPaziente="getAnagraficaPaziente" :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h3 class="sa-event-title">{{ getLabelTraduora("patient.afa.lblVisit") }}</h3>
    </template>
    <template slot="table-body">
      <b-tabs v-model="tabIndex" content-class="mt-3" class="sa-tab">
        <b-tab class="sa-prova-uno">
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblSportsMedicalEvaluationForm") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <afa-scheda-valutazione-medico-sportiva-view-component :etaPaziente="etaPaziente" :jsonData="jsonData" :tipoEvento="tipoEvento" :gruppo="gruppo"></afa-scheda-valutazione-medico-sportiva-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblAnamnesis") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <afa-anamnesi-view-component :jsonData="jsonData"></afa-anamnesi-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="getColorByFirma(jsonData.firmaEo)">
              <div style="float: left">
                <b-icon :icon="getIconByFirma(jsonData.firmaEo)"></b-icon>
              </div>
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <afa-esame-obiettivo-view-component :gruppoSanguignoPaziente="gruppoSanguignoPaziente" :jsonData="jsonData" :tipoEvento="tipoEvento" :gruppo="gruppo"></afa-esame-obiettivo-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="getColorByFirma(jsonData.firmaEcg)">
              <div style="float: left">
                <b-icon :icon="getIconByFirma(jsonData.firmaEcg)"></b-icon>
              </div>
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <afa-elettrocardiogramma-view-component :jsonData="jsonData" :tipoEvento="tipoEvento" :gruppo="gruppo"></afa-elettrocardiogramma-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblAnthropometrics") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <afa-antropometrici-view-component :jsonData="jsonData" :tipoEvento="tipoEvento" :gruppo="gruppo"></afa-antropometrici-view-component>
          </div>
        </b-tab>
        <b-tab :edit="edit">
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblFitness") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <afa-fitness-view-component :jsonData="jsonData" :tipoEvento="tipoEvento" :gruppo="gruppo"></afa-fitness-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblOtherExams") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <afa-altri-esami-component ref="altriEsami" :data="jsonData" :edit="edit"></afa-altri-esami-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>Terapia</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <paziente-terapia-list-component :isEdit="false"></paziente-terapia-list-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="getColorByFirma(jsonData.firmaGf)">
              <div style="float: left">
                <b-icon :icon="getIconByFirma(jsonData.firmaGf)"></b-icon>
              </div>
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <afa-giudizio-finale-view-component :jsonData="jsonData"></afa-giudizio-finale-view-component>
          </div>
        </b-tab>
      </b-tabs>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import AfaAltriEsamiComponent from "./AfaAltriEsamiComponent";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import AfaSchedaValutazioneMedicoSportivaViewComponent from "./AfaSchedaValutazioneMedicoSportivaViewComponent.vue";
import AfaAnamnesiViewComponent from "./AfaAnamnesiViewComponent.vue";
import AfaEsameObiettivoViewComponent from "./AfaEsameObiettivoViewComponent.vue";
import AfaElettrocardiogrammaViewComponent from "./AfaElettrocardiogrammaViewComponent.vue";
import AfaAntropometriciViewComponent from "./AfaAntropometriciViewComponent.vue";
import AfaFitnessViewComponent from "./AfaFitnessViewComponent.vue";
import AfaGiudizioFinaleViewComponent from "./AfaGiudizioFinaleViewComponent.vue";
import PazienteTerapiaListComponent from "../../../../terapia/components/PazienteTerapiaListComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import PrintComponent from "../../../../../../app/utility/components/PrintComponent.vue";
export default {
  mixins: [UtilityMixin, EventiMixin],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: {
    SaPageLayout,
    PazienteAnagrafica,
    AfaAltriEsamiComponent,
    AfaSchedaValutazioneMedicoSportivaViewComponent,
    AfaAnamnesiViewComponent,
    AfaEsameObiettivoViewComponent,
    AfaElettrocardiogrammaViewComponent,
    AfaAntropometriciViewComponent,
    AfaFitnessViewComponent,
    AfaGiudizioFinaleViewComponent,
    PazienteTerapiaListComponent,
    PrintComponent,
  },
  data() {
    return {
      id: null,
      tabIndex: null,
      jsonData: { idPaziente: null },
      pathRest: "/medicinadellosportafa",
      linkEdit: null,
      etaPaziente: null,
      linkback: null,
      edit: false,
      showModalLoading: false,
      gruppoSanguignoPaziente: "",
      linkPrintData: null,
      tipoEvento: "MEDICINADELLOSPORTAFA",
      gruppo: "MEDICINADELLOSPORT",
    };
  },
  computed: {
    idPaziente: {
      get: function () {
        return this.$route.query.idPaziente;
      },
      set: function (newValue) {
        this.jsonData.idPaziente = newValue;
        this.linkEdit = "/paziente/medicinadellosportafa/edit/" + this.id + "?idPaziente=" + newValue;
        this.linkback = "/paziente/medicinadellosportafa?idPaziente=" + newValue;
      },
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  created: function () {},
  mounted() {
    let me = this;
    me.id = this.$route.params.id;

    me.linkback = "/paziente/medicinadellosportafa?idPaziente=" + me.idPaziente;
    me.loadData();
  },
  methods: {
    getAnagraficaPaziente(value) {
      let me = this;
      me.gruppoSanguignoPaziente = value.gruppoSanguigno;
    },
    onRefresh() {
      let me = this;
      me.$refs["altriEsami"].$refs["allegatiListC"].loadData();
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
          me.idPaziente = me.jsonData.idPaziente;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    getEtaPaziente(value) {
      this.etaPaziente = value;
    },
    getLinkPrintData(item) {
      let returnValue = "/medicinadellosportprint/" + item.id;
      return returnValue;
      // console.log("Print" + item.id);
    },
  },
};
</script>
