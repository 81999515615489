<template>
    <div class="sa-calendar-day">
        <div class="sa-calendar-day-content">
            <!-- <b-row>
                <b-col class="text-right">
                    <b-button v-b-toggle :pressed.sync="switchGrigliaList" class="no-text btn-outline-primary sa-base-color" variant="outline-light"><b-icon :icon="togleIcon"></b-icon></b-button>
                </b-col>
            </b-row> -->
            <!-- <b-row v-if="switchGrigliaList" id="agenda-day-grid"> -->
            <b-row id="agenda-day-grid" style="margin-top: 10px">
                <agenda-day-griglia :idAgenda="idAgenda" :costo="costo" :dataRiferimento="dataRiferimento" :descrizionePrestazione="descrizionePrestazione" :codicePrestazione="codicePrestazione" :griglia="gridData" @update="updateData"></agenda-day-griglia>
            </b-row>
            <!-- <b-row v-else id="agenda-day-list" style="height: 100%; overflow: auto; margin-bottom: 100px">
                <agenda-day-list :idAgenda="idAgenda" :costo="costo" :dataRiferimento="dataRiferimento" :descrizionePrestazione="descrizionePrestazione" :codicePrestazione="codicePrestazione" :disponibilita="disponibilita" @update="updateData"></agenda-day-list>
            </b-row> -->
        </div>
    </div>
</template>
<script>
import AgendaDayGriglia from "./AgendaDayGriglia.vue";
// import AgendaDayList from "./AgendaDayList.vue";

export default {
    components: { AgendaDayGriglia }, //AgendaDayList
    computed: {
        togleIcon: function () {
            return !this.switchGrigliaList ? "grid-3x3" : "list-ul";
        },
    },
    props: {
        disponibilita: Array,
        codicePrestazione: String,
        descrizionePrestazione: String,
        costo: Number,
        idAgenda: String,
        dataRiferimento: String,
    },
    data() {
        return {
            gridData: [],
            switchGrigliaList: true,
        };
    },
    mounted: function () {
        let me = this;
        me.setRiferimento();
    },
    watch: {
        disponibilita: function () {
            let column = 7;
            let griglia = [];
            let count = 0;
            let row = [];
            let numeroRighe = 0;
            if (this.disponibilita.length > 0) {
                // numeroRighe = Math.trunc(this.disponibilita[0].disponibilita.length / column);
                numeroRighe = this.disponibilita[0].disponibilita.length % column > 0 ? numeroRighe + 1 : numeroRighe;

                this.disponibilita[0].disponibilita.forEach((element) => {
                    if (count < column) {
                        row.push(element);
                        count++;
                    }

                    let numeroElementi = griglia.length * column + row.length;
                    if (count === column || (griglia.length === numeroRighe - 1 && this.disponibilita[0].disponibilita.length === numeroElementi)) {
                        griglia.push(row);
                        row = [];
                        count = 0;
                    }
                });
            }

            this.gridData = griglia;
        },
    },
    methods: {
        setRiferimento(dataSelezionata = null) {
            if (dataSelezionata === null) {
                dataSelezionata = new Date();
            }
        },
        onHourClick(giorno) {
            let me = this;
            me.setRiferimento(giorno.date);
        },
        updateData() {
            let me = this;
            me.$emit("update");
        },
    },
};
</script>
<style scoped>
.sa-calendar-day {
    height: 100%;
    padding-bottom: 1.25rem;
    /* overflow-y: auto; */
}
.sa-calendar-day-content {
    height: 100%;
}
#agenda-day-grid {
    height: 100%;
    padding-bottom: 45px;
}
</style>
