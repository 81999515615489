<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Luogo Probabile Contagio: <b-icon icon="info-circle" v-b-popover.hover.top="messaggioLuogoContagio"></b-icon></label>
          <b-form-select v-model="jsonData.luogoProbabileEsposizione" :options="nazioniOptions" :value="''" value-field="comune" text-field="comune"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Indirizzo:</label>
          <b-form-input v-model="jsonData.italiaIndirizzo" :disabled="chekCondition(jsonData.luogoProbabileEsposizione, 'Italia')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Comune:</label>
          <b-form-input v-model="jsonData.comune"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipo Domicilio:</label>
          <b-form-select v-model="jsonData.tipoDomicilio" :options="tipoDomicilioOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <!-- <b-col>
              <label class="sa-label-data">Via Trasmissione:</label>
              <b-form-select v-model="jsonData.viaTrasmissiome" :options="viaTrasmissioneOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Specificare Altra Via Trasmissione:</label>
              <b-form-input v-model="jsonData.altro" :disabled="chekCondition(jsonData.viaTrasmissiome, 'ALTRO')"></b-form-input>
            </b-col> -->
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Informazioni Generali" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Donatore:</label>
          <b-form-select v-model="jsonData.donatore" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Donatore:</label>
          <b-form-select v-model="jsonData.tipoDonatore" :options="tipoDonatoreOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.donatore, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Donazione Ultimi 28 Giorni:</label>
          <b-form-select v-model="jsonData.donazioneVentottoGiorni" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.donatore, 'SI')"></b-form-select>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Anamnesi Trasfusione:</label>
              <b-form-select v-model="jsonData.anamnesiTrasfusioneVentottoGiorni" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Anamnesi Trapianto:</label>
              <b-form-select v-model="jsonData.anamnesiTrapiantoVentottoGiorni" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Gravidanza:</label>
              <b-form-select v-model="jsonData.gravidanza" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Numero settimane:</label>
              <b-form-input v-model="jsonData.gravidanzaSettimane" :disabled="chekCondition(jsonData.gravidanza, 'SI')"></b-form-input>
            </b-col> -->
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Stato Vaccinale" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tick Borne Encephalitis:</label>
          <b-form-select v-model="jsonData.vaccinoTickBorneEncephalitis" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre Gialla:</label>
          <b-form-select v-model="jsonData.vaccinoFebbreGialla" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Encefalite Giapponese:</label>
          <b-form-select v-model="jsonData.vaccinoEncefaliteGiapponese" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Presenza Sintomi:</label>
          <b-form-select v-model="jsonData.presenzaSintomi" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onAttivaSintomi()"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Inizio Sintomi:</label>
          <date-picker v-model="jsonData.dataInizioSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disableSintomi"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre:</label>
          <b-form-select v-model="jsonData.febbre" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disableSintomi"></b-form-select>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Astenia/Cefalea:</label>
              <b-form-select v-model="jsonData.asteniaCefalea" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disableSintomi"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Artralgia:</label>
              <b-form-select v-model="jsonData.artralgia" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disableSintomi"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Rush:</label>
              <b-form-select v-model="jsonData.rush" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disableSintomi"></b-form-select>
            </b-col> -->
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Encefalite:</label>
          <b-form-select v-model="jsonData.encefalite" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disableSintomi"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Meningite:</label>
          <b-form-select v-model="jsonData.meningite" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disableSintomi"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Poliradiculoneurite:</label>
          <b-form-select v-model="jsonData.poliradiculoneurite" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disableSintomi"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Paralisi Flaccida Acuta:</label>
          <b-form-select v-model="jsonData.paralisiFlaccidaAcuta" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disableSintomi"></b-form-select>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Febbre Emorragica:</label>
              <b-form-select v-model="jsonData.febbreEmorragica" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disableSintomi"></b-form-select>
            </b-col> -->
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altri Sintomi:</label>
          <b-form-input v-model="jsonData.altriSintomi" :disabled="disableSintomi"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Condizioni Rischio Persistente:</label>
          <b-form-select v-model="jsonData.condizioniRischioPersistenti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Specificare:</label>
          <b-form-input v-model="jsonData.condizioniRischioPersistentiDettagli" :disabled="chekCondition(jsonData.condizioniRischioPersistenti, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricovero Ospedaliero:</label>
          <b-form-select v-model="jsonData.ricoveroOspedale" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ospedale:</label>
          <b-form-input v-model="jsonData.ricoveroOspedaleDenominazione" :disabled="chekCondition(jsonData.ricoveroOspedale, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Ricovero:</label>
          <date-picker v-model="jsonData.ricoveroOspedaleDataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.ricoveroOspedale, 'SI')"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Laboratorio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Liquor:</label>
          <b-form-select v-model="jsonData.liquor" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Prelievo Campione Liquor:</label>
          <date-picker v-model="jsonData.liquorDataPrelievoCampione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.liquor, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">IgM Liquor:</label>
          <b-form-select v-model="jsonData.liquorIgm" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.liquor, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">IgG Liquor:</label>
          <b-form-select v-model="jsonData.liquorIgg" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.liquor, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">PCR Liquor:</label>
          <b-form-select v-model="jsonData.liquorPcr" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.liquor, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Isolamento Virale Liquor:</label>
          <b-form-select v-model="jsonData.liquorIsolamentoVirale" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.liquor, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Siero/Sangue:</label>
          <b-form-select v-model="jsonData.sieroSangue" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Prelievo Campione Siero/Sangue:</label>
          <date-picker v-model="jsonData.sieroSangueDataPrelievoCampione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.sieroSangue, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">IgM Siero/Sangue:</label>
          <b-form-select v-model="jsonData.sieroSangueIgm" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.sieroSangue, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">IgG Siero/Sangue:</label>
          <b-form-select v-model="jsonData.sieroSangueIgg" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.sieroSangue, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">PCR Siero/Sangue:</label>
          <b-form-select v-model="jsonData.sieroSanguePcr" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.sieroSangue, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Neutralizzazione Siero/Sangue:</label>
          <b-form-select v-model="jsonData.sieroSangueNeutralizzazione" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.sieroSangue, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Isolamento VIrale Siero/Sangue:</label>
          <b-form-select v-model="jsonData.sieroSangueIsolamentoVirale" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.sieroSangue, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Test NAT Siero/Sangue:</label>
          <b-form-select v-model="jsonData.sieroSangueTestNat" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.sieroSangue, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Urine:</label>
          <b-form-select v-model="jsonData.urine" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Prelievo Campione Urine:</label>
          <date-picker v-model="jsonData.urineDataPrelievo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.urine, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">PCR Urine:</label>
          <b-form-select v-model="jsonData.urinePcr" :options="positivoNegativoDubbioOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.urine, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Invio Campione Presso ISS:</label>
          <b-form-select v-model="jsonData.invioCampionePressoIss" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Invio:</label>
          <date-picker v-model="jsonData.invioCampionePressoIssData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.invioCampionePressoIss, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Effettuato Sequenziamento:</label>
          <b-form-select v-model="jsonData.effettuatoSequenziamento" :options="sequenziamentoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Specificare Altro:</label>
          <b-form-input v-model="jsonData.effettuatoSequenziamentoAltro" :disabled="chekCondition(jsonData.effettuatoSequenziamento, 'ALTRO')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Esito e Follow-Up:</label>
          <b-form-select v-model="jsonData.esitoFolowUp" :options="esitoFillowUpOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Esito:</label>
          <date-picker v-model="jsonData.esitoFolowUpData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Classificazione Caso Neuroinvasivo:</label>
          <b-form-select v-model="jsonData.classificazioneCasoNeuroinvasivo" :options="classificazioneCasoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Classificazione Caso Solo Febbre:</label>
          <b-form-select v-model="jsonData.classificazioneCasoSoloFebbre" :options="classificazioneCasoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Classificazione Caso Sintomatico Altro:</label>
          <b-form-select v-model="jsonData.classificazioneCasoSintomaticoAltro" :options="classificazioneCasoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Classificazione Caso Donatore:</label>
          <b-form-select v-model="jsonData.classificazioneCasoDonatore" :options="classificazioneCasoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Classificazione Caso Asintomatico:</label>
          <b-form-select v-model="jsonData.classificazioneCasoAsintomatico" :options="classificazioneCasoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipo Caso:</label>
          <b-form-select v-model="jsonData.tipoCaso" :options="tipoCasoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Note:</label>
          <b-form-textarea v-model="jsonData.note" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { IndagineEpidemiologicaDatiNotificaComponent, DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveiealtricasiparticolari",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      showModalLoading: false,
      linkNazioni: "/nazioni",
      disableSintomi: true,
      messaggioLuogoContagio: "Presenza nel luogo {periodo} precedenti l’inizio della sintomatologia",
      jsonData: {
        luogoProbabileEsposizione: "",
        italiaIndirizzo: "",
        comune: "",
        tipoDomicilio: "",
        viaTrasmissiome: "",
        donatore: "",
        tipoDonatore: "",
        donazioneVentottoGiorni: "",
        anamnesiTrasfusioneVentottoGiorni: "",
        anamnesiTrapiantoVentottoGiorni: "",
        gravidanza: "",
        gravidanzaSettimane: "",
        vaccinoTickBorneEncephalitis: "",
        vaccinoFebbreGialla: "",
        vaccinoEncefaliteGiapponese: "",
        presenzaSintomi: "",
        dataInizioSintomi: null,
        febbre: "",
        asteniaCefalea: "",
        artralgia: "",
        rush: "",
        encefalite: "",
        meningite: "",
        poliradiculoneurite: "",
        paralisiFlaccidaAcuta: "",
        febbreEmorragica: "",
        altro: "",
        condizioniRischioPersistenti: "",
        condizioniRischioPersistentiDettagli: "",
        ricoveroOspedale: "",
        ricoveroOspedaleDenominazione: "",
        ricoveroOspedaleDataRicovero: null,
        sanitarioNotificante: "",
        sanitarioNotificanteContatto: "",
        dataNotifica: null,
        esitoFolowUp: "",
        esitoFolowUpData: null,
        classificazioneCaso: "",
        tipoCaso: "",
        altriSintomi: "",

        liquor: "",
        liquorDataPrelievoCampione: null,
        liquorIgm: "",
        liquorIgg: "",
        liquorPcr: "",
        liquorIsolamentoVirale: "",
        sieroSangue: "",
        sieroSangueDataPrelievoCampione: null,
        sieroSangueIgm: "",
        sieroSangueIgg: "",
        sieroSangueNeutralizzazione: "",
        sieroSanguePcr: "",
        sieroSangueIsolamentoVirale: "",
        urine: "",
        urineDataPrelievo: null,
        urinePcr: "",
        invioCampionePressoIss: "",
        invioCampionePressoIssData: null,
        note: "",

        classificazioneCasoNeuroinvasivo: "",
        classificazioneCasoSoloFebbre: "",
        classificazioneCasoSintomaticoAltro: "",
        classificazioneCasoDonatore: "",
        classificazioneCasoAsintomatico: "",

        sieroSangueTestNat: "",
        effettuatoSequenziamento: "",
        effettuatoSequenziamentoAltro: "",
      },
      nazioniOptions: [],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      siNoNonNotoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      positivoNegativoDubbioOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
        { value: "DUBBIO", text: "DUBBIO" },
      ],
      tipoDomicilioOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "DOMICILIO ABITUALE", text: "DOMICILIO ABITUALE" },
        { value: "ALTRO DOMICILIO", text: "ALTRO DOMICILIO" },
      ],
      viaTrasmissioneOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "VETTORE", text: "VETTORE" },
        { value: "SESSUALE", text: "SESSUALE" },
        { value: "TRASFUZIONE O TRAPIANTO", text: "TRASFUZIONE O TRAPIANTO" },
        { value: "ALIMENTARE (PRODOTTI NON PASTORIZZATI)", text: "ALIMENTARE (PRODOTTI NON PASTORIZZATI)" },
        { value: "ALTRO", text: "ALTRO" },
      ],
      tipoDonatoreOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SANGUE", text: "SANGUE" },
        { value: "CELLULE", text: "CELLULE" },
        { value: "TESSUTI", text: "TESSUTI" },
        { value: "ORGANI", text: "ORGANI" },
      ],
      esitoFillowUpOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "GUARITO", text: "GUARITO" },
        { value: "IN MIGLIORAMENTO", text: "IN MIGLIORAMENTO" },
        { value: "GRAVE", text: "GRAVE" },
        { value: "DECEDUTO", text: "DECEDUTO" },
        { value: "NON NOTO", text: "NON NOTO" },
        { value: "NON APPLICABILE (ES. DONATORE ASINTOMATICO)", text: "NON APPLICABILE (ES. DONATORE ASINTOMATICO)" },
      ],
      classificazioneCasoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "CONFERMATO", text: "CONFERMATO" },
        { value: "PROBABILE", text: "PROBABILE" },
      ],
      sequenziamentoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "LINEAGE1", text: "LINEAGE1" },
        { value: "LINEAGE2", text: "LINEAGE2" },
        { value: "ALTRO", text: "ALTRO" },
        { value: "NO", text: "NO" },
      ],
      tipoCasoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "AUTOCTONO, STESSA REGIONE", text: "AUTOCTONO, STESSA REGIONE" },
        { value: "AUTOCTONO, PROVENIENTE DA ALTRA REGIONE", text: "AUTOCTONO, PROVENIENTE DA ALTRA REGIONE" },
        { value: "IMPORTATO, DA STATO ESTERO", text: "IMPORTATO, DA STATO ESTERO" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.id = me.id;
    me.loadMalattiaInfettivaSegnalazione();
    me.loadNazioni();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.luogoProbabileEsposizione": function (value) {
      let me = this;
      if (value !== "Italia") {
        me.jsonData.italiaIndirizzo = "";
      }
    },
    "jsonData.viaTrasmissiome": function (value) {
      let me = this;
      if (value !== "ALTRO") {
        me.jsonData.altro = "";
      }
    },
    "jsonData.donatore": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.tipoDonatore = "";
        me.jsonData.donazioneVentottoGiorni = "";
      }
    },
    "jsonData.gravidanza": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.gravidanzaSettimane = "";
      }
    },
    "jsonData.presenzaSintomi": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.dataInizioSintomi = null;
        me.jsonData.febbre = "";
        me.jsonData.asteniaCefalea = "";
        me.jsonData.artralgia = "";
        me.jsonData.rush = "";
        me.jsonData.encefalite = "";
        me.jsonData.meningite = "";
        me.jsonData.poliradiculoneurite = "";
        me.jsonData.paralisiFlaccidaAcuta = "";
        me.jsonData.febbreEmorragica = "";
        me.jsonData.altriSintomi = "";
      }
    },
    "jsonData.condizioniRischioPersistenti": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.condizioniRischioPersistentiDettagli = "";
      }
    },
    "jsonData.ricoveroOspedale": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.ricoveroOspedaleDenominazione = "";
        me.jsonData.ricoveroOspedaleDataRicovero = null;
      }
    },
    "jsonData.liquor": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.liquorIgm = "";
        me.jsonData.liquorDataPrelievoCampione = null;
        me.jsonData.liquorIgg = "";
        me.jsonData.liquorPcr = "";
        me.jsonData.liquorIsolamentoVirale = "";
      }
    },
    "jsonData.sieroSangue": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.sieroSangueIgm = "";
        me.jsonData.sieroSangueDataPrelievoCampione = null;
        me.jsonData.sieroSangueIgg = "";
        me.jsonData.sieroSanguePcr = "";
        me.jsonData.sieroSangueNeutralizzazione = "";
        me.jsonData.sieroSangueIsolamentoVirale = "";
      }
    },
    "jsonData.urine": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.urinePcr = "";
        me.jsonData.urineDataPrelievo = null;
      }
    },
    "jsonData.invioCampionePressoIss": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.invioCampionePressoIssData = null;
      }
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;

      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
        });
    },
    loadMalattiaInfettivaSegnalazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.setDatiMalattia(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
      let malattia = data.malattiaSegnalata;
      // console.log(me.messaggioLuogoContagio);
      if (malattia.toUpperCase().includes("WEST NILE")) {
        me.messaggioLuogoContagio = me.messaggioLuogoContagio.replace("{periodo}", "nelle 3 settimane");
      } else {
        me.messaggioLuogoContagio = me.messaggioLuogoContagio.replace("{periodo}", "nei 15 giorni");
      }
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    chekCondition(value, condizione) {
      let returnValue = true;
      if (value === condizione) {
        returnValue = false;
      }
      return returnValue;
    },
    loadNazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkNazioni;
      axios.get(link).then((response) => {
        me.nazioniOptions = response.data.data;
      });
    },
    onAttivaSintomi() {
      let me = this;
      me.disableSintomi = me.chekCondition(this.jsonData.presenzaSintomi, "SI");
    },
  },
};
</script>
