<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Informazioni relative all'erogazione dei servizi" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Preso in carico presso:</label>
          <b-form-input v-model="jsonData.caricoPresso" class="text-upper" :disabled="!editSvamaD"></b-form-input>
        </b-col>
      </b-row>
      <!-- </b-card>
    <b-card class="sa-card" header="Attualmente fruisce dei seguenti servizi/prestazioni" header-tag="header" footer-tag="footer" title=""> -->
      <h4 class="sa-h4">Attualmente fruisce dei seguenti servizi/prestazioni:</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-lable-data">Tipo sociale:</label>
          <!-- <b-form-input v-model="jsonData.fruisceServiziPrestazioniTipoSociale"></b-form-input> -->
          <b-form-select v-model="jsonData.fruisceServiziPrestazioniTipoSociale" :options="serviziPrestazioniOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-lable-data">Tipo sanitario:</label>
          <!-- <b-form-input v-model="jsonData.fruisceServiziPrestazioniTipoSanitario"></b-form-input> -->
          <b-form-select v-model="jsonData.fruisceServiziPrestazioniTipoSanitario" :options="serviziPrestazioniOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-lable-data">Tipo sociosanitario:</label>
          <!-- <b-form-input v-model="jsonData.fruisceServiziPrestazioniTipoSociosanitario"></b-form-input>-->
          <b-form-select v-model="jsonData.fruisceServiziPrestazioniTipoSociosanitario" :options="serviziPrestazioniOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-lable-data">Altro:</label>
          <b-form-input v-model="jsonData.fruisceServiziPrestazioniAltro" class="text-upper" :disabled="!editSvamaD"></b-form-input>
        </b-col>
      </b-row>
      <h4 class="sa-h4">In caso di presenza in struttura residenziale/semiresidenziale, specificare:</h4>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipologia, denominazione e luogo:</label>
          <b-form-input v-model="jsonData.strutturaResidenzialeSemiDescrizione" class="text-upper" :disabled="!editSvamaD"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipolo valutazione:</label>
          <b-form-select v-model="jsonData.tipoValutazione" :options="tipoValutazioneOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Motivo rivalutazione:</label>
          <b-form-select v-model="jsonData.motivoRivalutazione" :options="motivoRivalutazioneOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Diagnosi delle principali condizioni di salute" header-tag="header" footer-tag="footer" title="">
      <adi-diagnosi-principali-condizioni-salute-component :items="jsonData.listaPatologie" @updatePatologie="onUpdatePatologie" :editSvamaD="editSvamaD"></adi-diagnosi-principali-condizioni-salute-component>
    </b-card>
    <b-card class="sa-card" header="Valutazione in sede U.V.I." header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Valutazione Sociale-VSOC:</label>
          <b-form-select v-model="jsonData.valutazioneSocialeVsoc" :options="valutazioneSocialeVsocOption" :value="null" value-field="value" text-field="text" @change="getPunteggioValutazioneSociale(jsonData.valutazioneSocialeVsoc)" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
          <label class="sa-label-data">Necessita' di assistenza sanitaria:</label>
          <b-form-select v-model="jsonData.necessitaAssistenzaSanitaria" :options="necessitaAssistenzaSanitariaOption" :value="null" value-field="value" text-field="text" @change="getPunteggioNecessitaAssistenzaSanitaria(jsonData.necessitaAssistenzaSanitaria)" :disabled="!editSvamaD"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Profilo dell'autonomia" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Profilo autonomia assistenza sanitaria:</label>
          <b-form-select v-model="jsonData.profiloAutonomiaAssistenzaSanitaria" :options="profiloAutonomiaAssistenzaSanitariaOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Situazione cognitiva:</label>
          <b-form-select v-model="jsonData.situazioneCognitiva" :options="situazioneCognitivaOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Area cognitiva flussi Dom/Res/SemiRes:</label>
          <b-form-select v-model="jsonData.areaCognitivaFlussiDomiciliariResidenzialiSemi" :options="areaCognitivaFlussiDomiciliariResidenzialiSemiOption" :value="null" value-field="value" text-field="text" @change="getPunteggioAreaCognitivaFlussiDomiciliariResidenzialiSemi(jsonData.areaCognitivaFlussiDomiciliariResidenzialiSemi)" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Area cmportamentale domiciliare:</label>
          <b-form-select v-model="jsonData.areaComportamentaleDomiciliare" :options="areaComportamentaleDomiciliareOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Area comportamentale Residenziale/Semi:</label>
          <b-form-select v-model="jsonData.areaComportamentaleResidenzialeSemi" :options="areaComportamentaleResidenzialeSemiOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Situazine funzionale:</label>
          <b-form-select v-model="jsonData.situazioneFunzionale" :options="situazioneFunzionaleOption" :value="null" value-field="value" text-field="text" @change="getPunteggioSituazioneFunzionale(jsonData.situazioneFunzionale)" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Situazine mobilita':</label>
          <b-form-select v-model="jsonData.situazioneMobilita" :options="situazioneMobilitaOption" :value="null" value-field="value" text-field="text" @change="getPunteggioSituazioneMobilita(jsonData.situazioneMobilita)" :disabled="!editSvamaD"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Punteggio Sociale:</label>
          <b-form-select v-model="jsonData.punteggioSocialeDescrizione" :options="valutazioneSocialeVsocOption" :value="null" value-field="value" text-field="text" @change="getPunteggioSociale(jsonData.punteggioSocialeDescrizione)" :disabled="!editSvamaD"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Verbale U.V.I." header-tag="header" footer-tag="footer" title="">
      <adi-verbale-uvi-component :items="jsonData" :editSvamaD="editSvamaD"></adi-verbale-uvi-component>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Codifica progetto assistenziale:</label>
          <b-form-select v-model="jsonData.codificaProgettoAssistenziale" :options="codificaProgettoAssistenzialeOption" :value="null" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Durata progetto assistenziale:</label>
          <b-form-input v-model="jsonData.progettoAssistenzialeDurata" class="text-upper" :disabled="!editSvamaD"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data rivalutazione prevista:</label>
          <date-picker v-model="jsonData.progettoAssistenzialeDataRivalutazionePrevista" format="DD-MM-YYYY" value-type="timestamp" type="date" :disabled="!editSvamaD"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tariffa applicata:</label>
          <b-form-input v-model="jsonData.progettoAssistenzialeTariffaApplicata" class="text-upper" :disabled="!editSvamaD"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Percentuale quota SSR:</label>
          <b-form-input v-model="jsonData.progettoAssistenzialePercentualeQuotaSsr" class="text-upper" :disabled="!editSvamaD"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Percentuale quota ente locale:</label>
          <b-form-input v-model="jsonData.progettoAssistenzialePercentualeQuotaEnteLocale" class="text-upper" :disabled="!editSvamaD"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Percentuale quota utente:</label>
          <b-form-input v-model="jsonData.progettoAssistenzialePercentualeQuotaUtente" class="text-upper" :disabled="!editSvamaD"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <adi-personale-presente-component :items="jsonData.listaPersonale" @updatePersonale="onUpdatePersonale" :editSvamaD="editSvamaD"></adi-personale-presente-component>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Responsabile del caso:</label>
          <b-form-input v-model="jsonData.responsabileCaso" class="text-upper" :disabled="!editSvamaD"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data valutazione:</label>
          <date-picker v-model="jsonData.dataValutazione" format="DD-MM-YYYY" value-type="timestamp" type="date" :disabled="!editSvamaD"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <!-- <b-card></b-card> -->
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import AdiDiagnosiPrincipaliCondizioniSaluteComponent from "./AdiDiagnosiPrincipaliCondizioniSaluteComponent.vue";
import AdiVerbaleUviComponent from "./AdiVerbaleUviComponent.vue";
import AdiPersonalePresenteComponent from "./AdiPersonalePresenteComponent.vue";

export default {
  props: {
    id: String,
    linkPrintData: String,
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { AdiDiagnosiPrincipaliCondizioniSaluteComponent, AdiVerbaleUviComponent, DatePicker, AdiPersonalePresenteComponent },
  data() {
    return {
      pathResource: "/adisvamaschedad",
      linkback: "/adipazienti",
      pathValutazioneSociale: "/adivalutazionesedeuvi",
      pathNecessitaAssistenzaSanitaria: "/adinecessitaassistenzasanitaria",
      pathAreaCognitivaFlussiDomiciliariResidenzialiSemi: "/adiareacognitivaflussidomiciliariresidenzialisemi",
      pathAreaComportamentale: "/adiareacomportamentale",
      pathSituazioneFunzionale: "/adisituazionefunzionale",
      pathSituazioneMobilita: "/adisituazionemobilita",
      jsonData: {
        caricoPresso: null,
        fruisceServiziPrestazioniTipoSociale: null,
        fruisceServiziPrestazioniTipoSanitario: null,
        fruisceServiziPrestazioniTipoSociosanitario: null,
        fruisceServiziPrestazioniAltro: null,
        strutturaResidenzialeSemiDescrizione: null,
        tipoValutazione: null,
        motivoRivalutazione: null,
        valutazioneSocialeVsoc: null,
        punteggioValutazioneSocialeVsoc: null,
        necessitaAssistenzaSanitaria: null,
        punteggioNecessitaAssistenzaSanitaria: null,
        profiloAutonomiaAssistenzaSanitaria: null,
        situazioneCognitiva: null,
        areaCognitivaFlussiDomiciliariResidenzialiSemi: null,
        punteggioAreaCognitivaFlussiDomiciliariResidenzialiSemi: null,
        areaComportamentaleDomiciliare: null,
        areaComportamentaleResidenzialeSemi: null,
        situazioneFunzionale: null,
        punteggioSituazioneFunzionale: null,
        situazioneMobilita: null,
        punteggioSituazioneMobilita: null,
        punteggioSociale: null,
        punteggioSocialeDescrizione: null,
        sintesiProblemiAttualiRilevati: null,
        sintesiBaseDocumentazione: null,
        codificaProgettoAssistenziale: null,
        responsabileCaso: null,
        dataValutazione: null,
        listaPatologie: [{ patologia: "", codiceIcdIx: "" }],
        listaPersonale: [{ tipo: "", tipoPartecipante: "", ente: "", nome: "" }],
      },
      editSvamaD: false,
      valutazioneSocialeVsocOption: [],
      necessitaAssistenzaSanitariaOption: [],
      areaCognitivaFlussiDomiciliariResidenzialiSemiOption: [],
      areaComportamentaleDomiciliareOption: [],
      areaComportamentaleResidenzialeSemiOption: [],
      situazioneFunzionaleOption: [],
      situazioneMobilitaOption: [],
      tipoValutazioneOption: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "VALUTAZIONE ALL'AMMISSIONE", text: "VALUTAZIONE ALL'AMMISSIONE" },
        { value: "RIVALUTAZIONE PERIODICA", text: "RIVALUTAZIONE PERIODICA" },
        { value: "RIVALUTAZIONE STRAORDINARIA", text: "RIVALUTAZIONE STRAORDINARIA" },
      ],
      motivoRivalutazioneOption: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SCADENZA DEL PERIODO PREVISTO", text: "SCADENZA DEL PERIODO PREVISTO" },
        { value: "VARIAZIONE NELLE CONDIZIONI DEL PAZIENTE", text: "VARIAZIONE NELLE CONDIZIONI DEL PAZIENTE" },
      ],
      profiloAutonomiaAssistenzaSanitariaOption: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "BASSA", text: "BASSA" },
        { value: "INTERMEDIA", text: "INTERMEDIA" },
        { value: "ELEVATA", text: "ELEVATA" },
      ],
      situazioneCognitivaOption: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "LUCIDO", text: "LUCIDO" },
        { value: "CONFUSO", text: "CONFUSO" },
        { value: "MOLTO CONFUSO O STUPOROSO", text: "MOLTO CONFUSO O STUPOROSO" },
        { value: "PROBLEMI COMPORTAMENTALI PREVALENTI", text: "PROBLEMI COMPORTAMENTALI PREVALENTI" },
      ],
      codificaProgettoAssistenzialeOption: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "ACCOGLIENZA IN RESIDENZA (RSA)", text: "ACCOGLIENZA IN RESIDENZA (RSA)" },
        { value: "OSPITALITA' TEMPORANEA RIABILITATIVA", text: "OSPITALITA' TEMPORANEA RIABILITATIVA" },
        { value: "OSPITALITA' TEMPORANEA SOCIALE 08 STRUTTURE RESIDENZIALI SOCIALI", text: "OSPITALITA' TEMPORANEA SOCIALE 08 STRUTTURE RESIDENZIALI SOCIALI" },
        { value: "CENTRO DIURNO (RSA A CICLO DIURNO)", text: "CENTRO DIURNO (RSA A CICLO DIURNO)" },
        { value: "ASSISTENZA DOMICILIARE SOCIALE-SAD", text: "ASSISTENZA DOMICILIARE SOCIALE-SAD)" },
        { value: "ASSISTENZA DOMICILIARE INTEGRATA", text: "ASSISTENZA DOMICILIARE INTEGRATA" },
        { value: "LA RETE ATTUALE RISPONDE AI BISOGNI / CONFERMA P.A.I.", text: "LA RETE ATTUALE RISPONDE AI BISOGNI / CONFERMA P.A.I." },
        { value: "STRUTTURE RESIDENZIALI SOCIALI", text: "STRUTTURE RESIDENZIALI SOCIALI" },
        { value: "CENTRO SOCIALE POLIFUNZIONALE", text: "CENTRO SOCIALE POLIFUNZIONALE" },
        { value: "ALTRE FORME DI ASSISTENZA", text: "ALTRE FORME DI ASSISTENZA" },
      ],
      serviziPrestazioniOption: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.jsonData.id = me.id;
    me.loadDefaultData();
    me.loadData();
    me.impostaPermessi();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      let fonte = this.$route.query.fonte;
      me.editSvamaD = true;
      if (fonte === null || fonte === undefined || fonte === "undefined" || fonte === "") {
        fonte = "/richieste";
      }
      if (fonte === "/richieste" || fonte === "/pua") {
        me.editSvamaD = UtilityMixin.methods.verificaPermessi(me.utente, "adisvamaschedad", 2);
      }
    },
    onUpdatePatologie(array) {
      let me = this;
      me.jsonData.listaPatologie = null;
      me.jsonData.listaPatologie = array;
    },
    onUpdatePersonale(array) {
      let me = this;
      me.jsonData.listaPersonale = null;
      me.jsonData.listaPersonale = array;
    },
    loadDefaultData() {
      let me = this;
      if (me.id !== "-1") {
        me.loadValutazioneSociale();
        me.loadNecessitaAssistenzaSanitaria();
        me.loadAreaCognitivaFlussiDomiciliariResidenzialiSemi();
        me.loadAreaComportamentale();
        me.loadSituazioneFunzionale();
        me.loadSituazioneMobilita();
      }
    },

    loadData() {
      let me = this;
      me.$emit("showmodalloaging", true);
      if (me.id === "-1") {
        me.$emit("update", me.jsonData);
        me.$emit("showmodalloaging", false);
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            me.jsonData.listaPatologie.push({ patologia: "", codiceIcdIx: "" });
            me.jsonData.listaPersonale.push({ tipo: "", tipoPartecipante: "", ente: "", nome: "" });
            me.$emit("update", me.jsonData);
            me.$emit("showmodalloaging", false);
          })
          .catch(() => {
            me.$emit("update", me.jsonData);
            me.$emit("showmodalloaging", false);
          });
      }
      me.$emit("update", me.jsonData);
    },
    loadValutazioneSociale() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathValutazioneSociale;
      (me.valutazioneSocialeVsocOption = []),
        axios.get(link).then((response) => {
          // me.valutazioneSocialeVsocOption = response.data.data.list;
          response.data.data.list.forEach((element) => {
            me.valutazioneSocialeVsocOption.push({ value: element.descrizione, text: element.descrizione });
          });
          me.valutazioneSocialeVsocOption.unshift({ value: null, text: "-Seleziona Valore-" });
        });
    },
    getPunteggioValutazioneSociale(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathValutazioneSociale + "/descrizione/" + value;
      axios.get(link).then((response) => {
        me.jsonData.punteggioValutazioneSocialeVsoc = response.data.data.punteggio;
      });
    },
    loadNecessitaAssistenzaSanitaria() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathNecessitaAssistenzaSanitaria;
      (me.necessitaAssistenzaSanitariaOption = []),
        axios.get(link).then((response) => {
          // me.necessitaAssistenzaSanitariaOption = response.data.data.list;
          response.data.data.list.forEach((element) => {
            me.necessitaAssistenzaSanitariaOption.push({ value: element.descrizione, text: element.descrizione });
          });
          me.necessitaAssistenzaSanitariaOption.unshift({ value: null, text: "-Seleziona Valore-" });
        });
    },
    getPunteggioNecessitaAssistenzaSanitaria(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathNecessitaAssistenzaSanitaria + "/descrizione/" + value;
      axios.get(link).then((response) => {
        me.jsonData.punteggioNecessitaAssistenzaSanitaria = response.data.data.punteggio;
      });
    },
    loadAreaCognitivaFlussiDomiciliariResidenzialiSemi() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathAreaCognitivaFlussiDomiciliariResidenzialiSemi;
      (me.areaCognitivaFlussiDomiciliariResidenzialiSemiOption = []),
        axios.get(link).then((response) => {
          // me.necessitaAssistenzaSanitariaOption = response.data.data.list;
          response.data.data.list.forEach((element) => {
            me.areaCognitivaFlussiDomiciliariResidenzialiSemiOption.push({ value: element.descrizione, text: element.descrizione });
          });
          me.areaCognitivaFlussiDomiciliariResidenzialiSemiOption.unshift({ value: null, text: "-Seleziona Valore-" });
        });
    },
    getPunteggioAreaCognitivaFlussiDomiciliariResidenzialiSemi(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathAreaCognitivaFlussiDomiciliariResidenzialiSemi + "/descrizione/" + value;
      axios.get(link).then((response) => {
        me.jsonData.punteggioAreaCognitivaFlussiDomiciliariResidenzialiSemi = response.data.data.punteggio;
      });
    },
    loadAreaComportamentale() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathAreaComportamentale;
      (me.necessitaAssistenzaSanitariaOption = []),
        axios.get(link).then((response) => {
          response.data.data.list.forEach((element) => {
            switch (element.tipoFlusso) {
              case "DOMICILIARI":
                me.areaComportamentaleDomiciliareOption.push({ value: element.descrizione, text: element.descrizione });
                break;
              case "RESIDENZIALE/SEMI":
                me.areaComportamentaleResidenzialeSemiOption.push({ value: element.descrizione, text: element.descrizione });
                break;
            }
          });
          me.areaComportamentaleDomiciliareOption.unshift({ value: null, text: "-Seleziona Valore-" });
          me.areaComportamentaleResidenzialeSemiOption.unshift({ value: null, text: "-Seleziona Valore-" });
        });
    },
    loadSituazioneFunzionale() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathSituazioneFunzionale;
      (me.situazioneFunzionameOption = []),
        axios.get(link).then((response) => {
          response.data.data.list.forEach((element) => {
            me.situazioneFunzionaleOption.push({ value: element.descrizione, text: element.descrizione });
          });
          me.situazioneFunzionaleOption.unshift({ value: null, text: "-Seleziona Valore-" });
        });
    },
    getPunteggioSituazioneFunzionale(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathSituazioneFunzionale + "/descrizione/" + value;
      axios.get(link).then((response) => {
        me.jsonData.punteggioSituazioneFunzionale = response.data.data.punteggio;
      });
    },
    loadSituazioneMobilita() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathSituazioneMobilita + "?scheda=SVAMA";
      (me.situazioneFunzionameOption = []),
        axios.get(link).then((response) => {
          response.data.data.list.forEach((element) => {
            me.situazioneMobilitaOption.push({ value: element.descrizione, text: element.descrizione });
          });
          me.situazioneMobilitaOption.unshift({ value: null, text: "-Seleziona Valore-" });
        });
    },
    getPunteggioSituazioneMobilita(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathSituazioneMobilita + "/descrizione/" + value;
      axios.get(link).then((response) => {
        me.jsonData.punteggioSituazioneMobilita = response.data.data.punteggio;
      });
    },
    getPunteggioSociale(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathValutazioneSociale + "/descrizione/" + value;
      axios.get(link).then((response) => {
        me.jsonData.punteggioSociale = response.data.data.punteggio;
      });
    },
  },
};
</script>

<style lang="css">
.sa-h4 {
  margin-top: 5px;
  margin-bottom: 5px;
}
</style>
