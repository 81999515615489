<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblHandgripRight") }}
                        <eventi-storico-misure-component misura="handgrip_destra" misuraLabel="Handgrip Destra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.handgripDestra" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblHandgripLeft") }}
                        <eventi-storico-misure-component misura="handgrip_sinistra" misuraLabel="Handgrip Sinistra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" step="0.01" v-model="data.handgripSinistra" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblPerpendicularDeviation") }}
                        <eventi-storico-misure-component misura="deviazione_perpendicolare" misuraLabel="Deviazione Perpendicolare" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.deviazionePerpendicolare" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblStraightDeviation") }}
                        <eventi-storico-misure-component misura="deviazione_retta" misuraLabel="Deviazione Retta" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" step="0.01" v-model="data.deviazioneRetta" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblFlexRight") }}
                        <eventi-storico-misure-component misura="flessibilita_destra" misuraLabel="Flessibilita Destra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" step="0.01" v-model="data.flessibilitaDestra" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblFlexLeft") }}
                        <eventi-storico-misure-component misura="flessibilita_sinistra" misuraLabel="Flessibilita Sinistra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.flessibilitaSinistra" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblChairTest") }}
                        <eventi-storico-misure-component misura="chair_test" misuraLabel="Chair Test" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.chairTest" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblMets") }}
                        <eventi-storico-misure-component misura="mets" misuraLabel="Mets" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.mets" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblStressDuration") }}
                        <eventi-storico-misure-component misura="durata_sforzo" misuraLabel="Durata Sforzo" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.durataSforzo" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblRecoveryDuration") }}
                        <eventi-storico-misure-component misura="durata_recupero" misuraLabel="Durata Recupero" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.durataRecupero" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblQMCI") }}
                        <eventi-storico-misure-component misura="qmci" misuraLabel="QMCI" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.qmci" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"></b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { EventiStoricoMisureComponent },
    props: {
        edit: {
            Type: Boolean,
        },
        data: {
            Type: Object,
            require: true,
        },
        gruppo: {
            Type: String,
            require: true,
        },
        tipoEvento: {
            Type: String,
            require: true,
        },
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.data.idPaziente;
        },
    },
    data() {
        return {};
    },
    methods: {},
};
</script>
