<template>
    <!-- <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title=""> -->
    <paziente-terapia-list-component :key="keyTerapiaAttiva" :elements="elements" :dataRiferimento="dataRiferimento" @update="onRefresh"></paziente-terapia-list-component>
    <!-- </b-card> -->
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin.js";
import PazienteTerapiaListComponent from "./PazienteTerapiaListComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { PazienteTerapiaListComponent },
    data() {
        return {
            dataRiferimento: new Date(),
            keyTerapiaAttiva: 0,
            elements: [],
            storicoTerapie: [],
            perPage: 100,
            currentPage: 1,
            filter: [],
            fieldsStorico: [],
        };
    },
    computed: {
        rows() {
            return this.storicoTerapie.length;
        },
    },
    methods: {
        onTabTerapiaInput() {},
        onRefresh() {},
    },
};
</script>

<style></style>
