<template>
  <div v-if="!isEta">
    <b-card class="sa-card" header="Informazioni Professione" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">9. Condizione Professionale o non professionale</label>
          <b-form-select v-model="jsonData.condizioneProfessionale" :options="condizioniProfessionaliOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">10. Posizione nella professione</label>
          <b-form-select v-model="jsonData.posizioneProfessionale" :options="posizioneProfessioneOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">11. Ramo di attività economica</label>
          <b-form-select v-model="jsonData.ramoAttivita" :options="ramoAttivitaEconomicaOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="CITTADINANZA" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">12. Cittadinanza</label>
          <b-form-select v-model="jsonData.cittadinanza" :options="cittadinanzaOptions" :value="''" value-field="id" text-field="descrizione" @input="onInputCittadinanza"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Stato Estero</label>
          <b-form-select v-model="jsonData.cittadinanzaStraniera" :options="statiEsteriOptions" :value="''" value-field="codiceIstat" text-field="comune" :disabled="statoEsteroDisabled"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
  </div>
  <div v-else>
    <b-card class="sa-card" header="DATI GENITORI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">9. Grado Istruzione Madre</label>
          <b-form-select v-model="jsonData.gradoIstruzioneMadre" :options="gradiIstruzioneOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">9. Grado Istruzione Padre</label>
          <b-form-select v-model="jsonData.gradoIstruzionePadre" :options="gradiIstruzioneOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">10. Professione Madre</label>
          <b-form-input v-model="jsonData.professioneMadre"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">10. Professione Padre</label>
          <b-form-input v-model="jsonData.professionePadre"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">11. Condizione Professionale Madre</label>
          <b-form-select v-model="jsonData.condizioneProfessionaleMadre" :options="condizioniProfessionaliOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">11. Condizione Professionale Padre</label>
          <b-form-select v-model="jsonData.condizioneProfessionalePadre" :options="condizioniProfessionaliOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">12. Posizione nella professione Madre</label>
          <b-form-select v-model="jsonData.posizioneProfessionaleMadre" :options="posizioneProfessioneOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">12. Posizione nella professione Padre</label>
          <b-form-select v-model="jsonData.posizioneProfessionalePadre" :options="posizioneProfessioneOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">13. Ramo di attività economica Madre</label>
          <b-form-select v-model="jsonData.ramoAttivitaMadre" :options="ramoAttivitaEconomicaOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">13. Ramo di attività economica Padre</label>
          <b-form-select v-model="jsonData.ramoAttivitaPadre" :options="ramoAttivitaEconomicaOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="CITTADINANZA GENITORI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">14. Cittadinanza Madre</label>
          <b-form-select v-model="jsonData.cittadinanzaMadre" :options="cittadinanzaOptions" :value="''" value-field="id" text-field="descrizione" @input="onInputCittadinanzaMadre"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Stato Estero</label>
          <b-form-select v-model="jsonData.cittadinanzaStranieraMadre" :options="statiEsteriOptions" :value="''" value-field="codiceIstat" text-field="comune" :disabled="statoEsteroMadreDisabled"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">14. Cittadinanza Padre</label>
          <b-form-select v-model="jsonData.cittadinanzaPadre" :options="cittadinanzaOptions" :value="''" value-field="id" text-field="descrizione" @input="onInputCittadinanzaPadre"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Stato Estero</label>
          <b-form-select v-model="jsonData.cittadinanzaStranieraPadre" :options="statiEsteriOptions" :value="''" value-field="codiceIstat" text-field="comune" :disabled="statoEsteroPadreDisabled"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
export default {
  props: {
    jsonData: {
      type: Object,
      require: true,
      default: function () {
        return {
          condizioneProfessionale: "",
          posizioneProfessionale: "",
          ramoAttivita: "",
          cittadinanza: "",
          cittadinanzaStraniera: "",
          /* DA AGGIUNGERE AL BACKEND*/
          gradoIstruzioneMadre: "",
          gradoIstruzionePadre: "",
          professioneMadre: "",
          professionePadre: "",
          condizioneProfessionaleMadre: "",
          condizioneProfessionalePadre: "",
          posizioneProfessionaleMadre: "",
          posizioneProfessionalePadre: "",
          ramoAttivitaMadre: "",
          ramoAttivitaPadre: "",
          cittadinanzaMadre: "",
          cittadinanzaStranieraMadre: "",
          cittadinanzaPadre: "",
          cittadinanzaStranieraPadre: "",
        };
      },
    },
  },
  data() {
    return {
      pathResourceCondizioneProfessionale: "/rencamschedemortecondizioneprofessionale",
      pathResourcePosizioneProfessione: "/rencamschedemorteposizioneprofessione",
      pathResourceRamoAttivitaEconomica: "/rencamschedemorteramoattivita",
      pathResourceCittadinanza: "/rencamschedemortecittadinanza",
      pathResourceStatiEsteri: "/comuni/provincia",
      pathResourceGradoIstruzione: "/rencamschedemortegradoistruzione",
      statoEsteroDisabled: true,
      statoEsteroMadreDisabled: true,
      statoEsteroPadreDisabled: true,
      isEta: false,
      gradiIstruzioneOptions: [{ id: "", descirione: "-Seleziona Valore-" }],
      condizioniProfessionaliOptions: [{ id: "", descrizione: "-Seleziona Condizione Professionale-" }],
      posizioneProfessioneOptions: [{ id: "", descrizione: "-Seleziona Posizione Professione-" }],
      ramoAttivitaEconomicaOptions: [{ id: "", descrizione: "-Seleziona Ramo Attività-" }],
      cittadinanzaOptions: [{ id: "", descrizione: "-Seleziona Tipo Cittadinanza-" }],
      statiEsteriOptions: [{ codiceIstat: "", comune: "-Seleziona Stato Estero-" }],
      filtro: { page: 1, forPage: 100 },
    };
  },
  mounted() {
    let me = this;
    me.loadCondizioneProfessionale();
    me.loadPosizioneProfessione();
    me.loadRamoAttivita();
    me.loadCittadinanza();
    me.loadGradiIstruzione();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        let me = this;
        if (jsonData.eta < 1) {
          me.isEta = true;
        } else {
          me.isEta = false;
          me.jsonData.gradoIstruzioneMadre = "";
          me.jsonData.gradoIstruzionePadre = "";
          me.jsonData.professioneMadre = "";
          me.jsonData.professionePadre = "";
          me.jsonData.condizioneProfessionaleMadre = "";
          me.jsonData.condizioneProfessionalePadre = "";
          me.jsonData.posizioneProfessionaleMadre = "";
          me.jsonData.posizioneProfessionalePadre = "";
          me.jsonData.ramoAttivitaMadre = "";
          me.jsonData.ramoAttivitaPadre = "";
          me.jsonData.cittadinanzaMadre = "";
          me.jsonData.cittadinanzaStranieraMadre = "";
          me.jsonData.cittadinanzaPadre = "";
          me.jsonData.cittadinanzaStranieraPadre = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    loadGradiIstruzione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceGradoIstruzione;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.gradiIstruzioneOptions = response.data.data.list;
        me.gradiIstruzioneOptions.unshift({ id: "", descrizione: "-Seleziona Grado Istruzione-" });
      });
    },
    loadCondizioneProfessionale() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceCondizioneProfessionale;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.condizioniProfessionaliOptions = response.data.data.list;
        me.condizioniProfessionaliOptions.unshift({ id: "", descrizione: "-Seleziona Condizione Professionale-" });
      });
    },
    loadPosizioneProfessione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourcePosizioneProfessione;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.posizioneProfessioneOptions = response.data.data.list;
        me.posizioneProfessioneOptions.unshift({ id: "", descrizione: "-Seleziona Posizione Professione-" });
      });
    },
    loadRamoAttivita() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceRamoAttivitaEconomica;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.ramoAttivitaEconomicaOptions = response.data.data.list;
        me.ramoAttivitaEconomicaOptions.unshift({ id: "", descrizione: "-Seleziona Ramo Attività-" });
      });
    },
    loadCittadinanza() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceCittadinanza;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.cittadinanzaOptions = response.data.data.list;
        me.cittadinanzaOptions.unshift({ id: "", descrizione: "-Seleziona Tipo Cittadinanza-" });
      });
    },
    onInputCittadinanza(cittadinanza) {
      let me = this;
      if (cittadinanza === "3") {
        me.statoEsteroDisabled = false;
        me.loadStatiEsteri();
      } else {
        me.statoEsteroDisabled = true;
        me.jsonData.cittadinanzaStraniera = "";
      }
    },
    onInputCittadinanzaMadre(cittadinanza) {
      let me = this;
      if (cittadinanza === "3") {
        me.statoEsteroMadreDisabled = false;
        me.loadStatiEsteri();
      } else {
        me.statoEsteroMadreDisabled = true;
        me.jsonData.cittadinanzaStranieraMadre = "";
      }
    },
    onInputCittadinanzaPadre(cittadinanza) {
      let me = this;
      if (cittadinanza === "3") {
        me.statoEsteroPadreDisabled = false;
        me.loadStatiEsteri();
      } else {
        me.statoEsteroPadreDisabled = true;
        me.jsonData.cittadinanzaStranieraPadre = "";
      }
    },
    loadStatiEsteri() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceStatiEsteri + "/EE";
      axios.get(link).then((response) => {
        me.statiEsteriOptions = response.data.data;
        me.statiEsteriOptions.unshift({ codiceIstat: "", comune: "-Seleziona Stato Estero-" });
      });
    },
  },
};
</script>
