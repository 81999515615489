import ModuliList from "./components/ModuliList.vue";
import ModuliEdit from "./components/ModuliEdit.vue";
import ParamsList from "./components/ParamsList.vue";
import ParamsEdit from "./components/ParamsEdit.vue";
import PDFViewer from "./components/PdfViewer.vue";
import CodiciIcdIxList from "./components/CodiciIcdIxList.vue";

export default {
    ParamsList,
    ParamsEdit,
    ModuliList,
    ModuliEdit,
    PDFViewer,
    CodiciIcdIxList,
};
