<template>
  <div class="modal-body">
    <b-row style="padding: 10px">
      <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
        <div class="custom-control custom-radio">
          <input type="radio" id="customRadio1" name="customRadio" class="custom-control-input" />
          <label class="custom-control-label" for="customRadio1">{{ this.$i18n.t("agenda.lblData") }}</label>
        </div>
      </b-col>
      <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="setGiorno" type="date" :placeholder="this.$i18n.t('agenda.lblDateFrom')"></date-picker>
      </b-col>
    </b-row>
    <b-row style="margin-top: 25px; padding: 10px; background: #F2F2F2;">
      <div class="col-12" style="margin-bottom: 15px;">
        <div class="custom-control custom-radio">
          <input type="radio" id="customRadio2" name="customRadio" class="custom-control-input" />
          <label class="custom-control-label" for="customRadio2">{{ this.$i18n.t("patient.agenda.lblDay") }}</label>
        </div>
      </div>
      <div class="col-6">
        <div class="custom-control custom-checkbox">
          <br />
          <input type="checkbox" class="custom-control-input" v-model="setEsclusivo" id="customCheck2" switch />
          <label class="custom-control-label" for="customCheck2">{{ this.$i18n.t("patient.agenda.lblExclusive") }}</label>
        </div>
      </div>
      <div class="col-6">
        <label for="" class="col-form-label">{{ this.$i18n.t("patient.agenda.lblDay") }}</label
        ><br />
        <b-form-select id="inline-form-custom-select-pref" :options="giorniOptions" v-model="setGiorno" :value="null" value-field="value" text-field="giorno"></b-form-select>
      </div>
    </b-row>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";

export default {
  components: { DatePicker },
  props: {
    jsonData: {
      type: Object,
      default: function() {
        return { giorno: null, esclusivo: null };
      },
    },
  },
  data() {
    return {
      giorniOptions: [
        { value: null, giorno: "---- Seleziona un giorno ----" },
        { value: "1", giorno: "Domenica" },
        { value: "2", giorno: "Lunedì" },
        { value: "3", giorno: "Martedì" },
        { value: "4", giorno: "Mercoledì" },
        { value: "5", giorno: "Giovedì" },
        { value: "6", giorno: "Venerdì" },
        { value: "7", giorno: "Sabato" },
      ],
    };
  },
  computed: {
    setGiorno: {
      get: function() {
        return this.jsonData.giorno;
      },
      set: function(value) {
        this.jsonData.giorno = value;
      },
    },
    setEsclusivo: {
      get: function() {
        return this.jsonData.esclusivo;
      },
      set: function(value) {
        this.jsonData.esclusivo = value;
      },
    },
  },
};
</script>
