export default {
    components() {
        let returnValue = {};
        const eventiComponents = require.context("../../", true, /^.*ViewComponent.vue$/);
        eventiComponents.keys().forEach((element) => {
            let pathSplit = element.split("/");
            let folder = pathSplit[3];
            let regex = new RegExp(folder + "ViewComponent.vue", "gi");
            let nomeFile = folder.charAt(0).toUpperCase() + folder.slice(1);
            if (element.match(regex)) {
                returnValue[folder] = () => import(`../${pathSplit[2]}/${pathSplit[3]}/components/${nomeFile}ViewComponent.vue`);
            }
        });
        return returnValue;
    },
};
