<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="4">
                        <b-form-input v-model="filtro.numero" type="search" id="filterInput" placeholder="Numero Accettazione"></b-form-input>
                    </b-col>
                    <b-col sm="4">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="date" placeholder="Data Dal"></date-picker>
                    </b-col>
                    <b-col sm="4">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="date" placeholder="Data Al"></date-picker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" :filter="filter" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(numero)="{ item }">
                        <router-link class="sa-edit-link" :to="'/leonidaaccettazioni/view/' + item.id">{{ item.numero }}</router-link>
                    </template>
                    <template #cell(etaPaziente)="row">
                        <div :class="getColorByEta(row.item.accettazioneAnagrafica.dataNascita)">
                            <div style="sa-maggiorenne-true">
                                <span>+18</span>
                            </div>
                        </div>
                    </template>
                    <template v-slot:cell(nominativo)="{ item }">
                        <a>{{ item.accettazioneAnagrafica.nome }} {{ item.accettazioneAnagrafica.cognome }}</a>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <!-- <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onStampa(row.item)"></b-button> -->
                        <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onStampaAtleta(row.item)"><b-icon icon="printer"></b-icon></b-button>
                        <b-button variant="outline-info no-text" size="sm" @click="onFatturazione(row.item)"><i class="bi bi-currency-euro"></i></b-button>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <b-modal ref="mdlStampaAtleta" size="xl" style="height: 100%">
                <leonida-stampe-component :accettazione="accettazioneSelezionata"></leonida-stampe-component>
            </b-modal>
            <!-- <b-modal ref="mdlStampa" size="xl" style="height: 100%">
                <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
            </b-modal> -->
        </template>
    </sa-page-layout>
</template>
<script>
import moment from "moment";
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import LeonidaStampeComponent from "../../components/LeonidaStampeComponent.vue";
export default {
    components: { DatePicker, SaPageLayout, LeonidaStampeComponent },
    mixins: [UtilityMixin],
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showModalLoading: false,
            linkedit: "/leonidaaccettazioni",
            pathResource: "/leonidaaccettazioni",
            pathResourceStampa: "/print/createreport",
            reportName: "CertificatoIdoneitaCatoMaior",
            stampaReport: null,
            currentPage: 1,
            perPage: 10,
            filter: null,
            filterOn: [],
            accettazioneSelezionata: null,
            fields: [
                {
                    label: "Numero",
                    key: "numero",
                    sortable: true,
                    thStyle: "width: 9rem",
                },
                {
                    label: "",
                    key: "etaPaziente",
                    thStyle: "width: 3rem",
                },
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Data Accettazione",
                    key: "createDate",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY");
                        } else {
                            return "-------";
                        }
                    },
                    thStyle: "width: 6rem",
                    sortable: true,
                },
                {
                    class: "sa-table-column-action-single",
                    key: "actions",
                    thStyle: "width: 10rem",
                    tdClass: "text-center",
                    label: "",
                },
            ],

            filtro: { numero: "", dataDal: null, dataAl: null },
            items: [],
            data: [],
        };
    },
    mounted: function () {
        this.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(function (error) {
                    me.showModalLoading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Sorveglianza Covid Admin",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onFatturazione(row) {
            let me = this;
            sessionStorage.setItem("AnagraficaPaziente", JSON.stringify(row));
            me.$router.replace("/contabilita/fatturazione/edit/-1").catch((err) => {
                err;
            });
        },
        // getColorByEta(item) {
        //     let colore = "sa-maggiorenne-true";
        //     if (!this.isMaggiorenne(item.accettazioneAnagrafica.dataNascita)) {
        //         colore = "sa-maggiorenne-false";
        //     } else {
        //         colore = "sa-maggiorenne-true";
        //     }
        //     return colore;
        // },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit: function () {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        // onStampa(row) {
        //     let me = this;
        //     // console.log(row);
        //     let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
        //     axios.get(link).then((response) => {
        //         let printData = response.data.data;
        //         let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
        //         axios.post(linkReport, { reportName: me.reportName, data: printData }).then((response) => {
        //             me.$refs.mdlStampa.show();
        //             me.stampaReport = "data:application/pdf;base64," + response.data.base64;
        //         });
        //     });
        // },
        onStampaAtleta(item) {
            let me = this;
            me.accettazioneSelezionata = item;
            me.$refs.mdlStampaAtleta.show();
            console.log(item);
        },
        onSchedaAtleta(item) {
            console.log(item);
        },
    },
};
</script>
