<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Caso</label>
          <b-form-select v-model="jsonData.tipoCaso" :options="tipiCasiOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option :value="null" disabled>-Seleziona Valore-</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malattia</label>
          <b-form-input v-model="jsonData.malattia" disabled></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Fonte di Contagio</label>
          <b-form-input v-model="jsonData.fonteContagio"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Note fonte di contagio</label>
          <b-form-textarea v-model="jsonData.fonteContagioNote" no-resize rows="6"></b-form-textarea>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricovero Ospedaliero</label>
          <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="jsonData.ricoveroOspedaliero" :options="siNoOptions" value="" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Ricovero</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.ricoveroOspedalieroData" type="timestamp" :disabled="chekCondition(jsonData.ricoveroOspedaliero, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nome Ospedale</label>
          <b-form-input v-model="jsonData.ricoveroOspedalieroNomeOspedale" :disabled="chekCondition(jsonData.ricoveroOspedaliero, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Reparto</label>
          <b-form-input v-model="jsonData.ricoveroOspedalieroReparto" :disabled="chekCondition(jsonData.ricoveroOspedaliero, 'SI')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Sintomi" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-table sticky-header ref="tblSintomi" stacked="xl" striped hover itemscope :items="jsonData.listaSintomatologia" :fields="fieldsSintomatologia" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(sintomo)="item">
            <b-input-group>
              <b-form-input v-model="item.item.sintomo" placeholder="Sintomo" disabled></b-form-input>
              <b-button size="sm" v-b-modal.mdlRicercaSintomo @click="onClickRicercaSintomo(item.index)"> <b-icon icon="search"> </b-icon></b-button>
            </b-input-group>
          </template>
          <template #cell(dataDal)="item">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.dataDal" type="timestamp" placeholder="Data Dal"></date-picker>
          </template>
          <template #cell(dataAl)="item">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.dataAl" type="timestamp" placeholder="Data Dal"></date-picker>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="row.index == jsonData.listaSintomatologia.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddSintomi(jsonData.listaSintomatologia, row.item)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== jsonData.listaSintomatologia.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaSintomatologia, row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row class="mt-2 mb-2">
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-checkbox v-model="isStatoEstero" switch> Permanenza in Stati Esteri nei 2 mesi precedenti alla comparsa dei sintomi? </b-form-checkbox>
        </b-col>
      </b-row>
      <template v-if="isStatoEstero">
        <b-table sticky-header ref="tblStatoEstero" stacked="xl" striped hover itemscope :items="jsonData.listaStatiEsteri" :fields="fieldsStatiEsteri" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(luogo)="item">
            <b-form-select v-model="item.item.luogo" :options="statiEsteriOptions">
              <template #first>
                <b-form-select-option :value="null" disabled>-Seleziona Valore-</b-form-select-option>
              </template>
            </b-form-select>
          </template>
          <template #cell(giornoDal)="item">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.giornoDal" type="timestamp" placeholder="Giorno Dal"></date-picker>
          </template>
          <template #cell(giornoAl)="item">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.giornoAl" type="timestamp" placeholder="Giorno Dal"></date-picker>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="row.index == jsonData.listaStatiEsteri.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddStatiEsteri(jsonData.listaStatiEsteri, row.item)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== jsonData.listaStatiEsteri.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaStatiEsteri, row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </template>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row class="mt-2 mb-2">
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-checkbox v-model="isRicercheDiagnostiche" switch> Ricerche diagnostiche eseguite o in corso? </b-form-checkbox>
        </b-col>
      </b-row>
      <template v-if="isRicercheDiagnostiche">
        <b-table sticky-header ref="tblRicercheDiagnostiche" stacked="xl" striped hover itemscope :items="jsonData.lisitaRicercheDiagnostiche" :fields="fieldsRicercheDiagnostiche" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(tipoRicerca)="item">
            <b-form-input v-model="item.item.tipoRicerca" placeholder="Tipo Ricerca"></b-form-input>
          </template>
          <template #cell(luogo)="item">
            <b-form-input v-model="item.item.luogo" placeholder="Luogo"></b-form-input>
          </template>
          <template #cell(data)="item">
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="item.item.data" type="timestamp" placeholder="Data"></date-picker>
          </template>
          <template #cell(risultati)="item">
            <b-form-input v-model="item.item.risultati" placeholder="Risultati"></b-form-input>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="row.index == jsonData.lisitaRicercheDiagnostiche.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddRicercheDiagnostiche(jsonData.lisitaRicercheDiagnostiche, row.item)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== jsonData.lisitaRicercheDiagnostiche.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.lisitaRicercheDiagnostiche, row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </template>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row class="mt-2 mb-2">
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-checkbox v-model="isContatti" switch> Aggiungere Contatti </b-form-checkbox>
        </b-col>
      </b-row>
      <template v-if="isContatti">
        <b-table sticky-header ref="tblContatti" stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(codiceFiscale)="item">
            <b-form-input v-model="item.item.codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
          </template>
          <template #cell(nome)="item">
            <b-form-input v-model="item.item.nome" placeholder="Nome"></b-form-input>
          </template>
          <template #cell(cognome)="item">
            <b-form-input v-model="item.item.cognome" placeholder="Cognome"></b-form-input>
          </template>
          <template #cell(eta)="item">
            <b-form-input v-model="item.item.eta" placeholder="Eta"></b-form-input>
          </template>
          <template #cell(professione)="item">
            <!-- <b-form-select v-model="item.item.professione" :options="professioniOptions" :value="null" value-field="value" text-field="text">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-Seleziona Valore-</b-form-select-option>
                  </template>
                </b-form-select> -->
            <b-form-input v-model="item.item.professione" placeholder="Professione"></b-form-input>
          </template>
          <template #cell(parentela)="item">
            <b-form-select v-model="item.item.parentela" :options="parenteleOptions">
              <template #first>
                <b-form-select-option :value="null" disabled>-Seleziona Valore-</b-form-select-option>
              </template>
            </b-form-select>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="row.index == jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddContatti(jsonData.listaContatti, row.item)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaContatti, row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </template>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome Sanitario Notificante</label>
          <b-form-input v-model="jsonData.nomeSanitarioNotificante"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cognome Sanitario Notificante</label>
          <b-form-input v-model="jsonData.cognomeSanitarioNotificante"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
          <b-form-input v-model="jsonData.contattoTelefonicoSanitarioNotificante"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-modal ref="mdlRicercaSintomo" id="mdlRicercaSintomo" title="Ricerca Sintomo" size="xl" @hidden="onHiddenMdlRicercaSintomo" @ok="onOkMdlRicercaSintomo">
      <div class="sa-form-section">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-label-data">Sintomo</label>
            <b-form @submit.prevent="onClickRicercaSintomoMdl">
              <b-input-group>
                <b-form-input v-model="filtro.descrizione"></b-form-input>
                <b-button size="sm" @click="onClickRicercaSintomoMdl"> <b-icon icon="search"> </b-icon></b-button>
              </b-input-group>
            </b-form>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="reset" v-on:click="onResetFiltroMdl" variant="danger">Resetta Filtro</b-button>
          </b-col>
        </b-row>
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
        <div class="b-table-sticky-header">
          <b-table sticky-header ref="tblSintomi" stacked="xl" striped hover itemscope :items="sintomiOptions" :fields="fieldsSintomo" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" selectable select-mode="single" @row-selected="onRowSelectedSintomi">
            <template #cell(selezionato)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selezionato</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Non Selezionato</span>
              </template>
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  components: { DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveie",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      showModalLoading: false,
      linkback: "/malattieinfettive/segnalazioni",
      isStatoEstero: false,
      isRicercheDiagnostiche: false,
      isContatti: false,
      currentPage: 1,
      perPage: 100,
      linkStatoEstero: "/comuni/provincia",
      linkTipiCasi: "/malattieinfettiveietipicasi",
      linkFontiContagio: "/malattieinfettiveiefonticontagi",
      linkParentele: "/malattieinfettiveieparentele",
      linkSintomi: "/malattieinfettiveiesintomi",
      linkProfessioni: "/malattieinfettiveprofessioni",
      jsonData: {
        tipoCaso: null,
        fonteContagio: null,
        malattia: "",
        fonteContagioNote: "",
        ricoveroOspedaliero: "",
        ricoveroOspedalieroNomeOspedale: "",
        ricoveroOspedalieroReparto: "",
        ricoveroOspedalieroData: null,
        listaStatiEsteri: [], //{ luogo: null, giornoDal: null, giornoAl: null }
        lisitaRicercheDiagnostiche: [],
        listaContatti: [],
        listaSintomatologia: [{ sintomo: null, dataDal: null, dataAl: null }],
      },
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      filtro: { descrizione: "" },
      sintomoSelezionato: "",
      indexSelezionato: null,
      fieldsStatiEsteri: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Luogo",
          key: "luogo",
          sortable: false,
        },
        {
          label: "Giorno Dal",
          key: "giornoDal",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "Giorno Al",
          key: "giornoAl",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsRicercheDiagnostiche: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo Ricerca",
          key: "tipoRicerca",
          sortable: false,
        },
        {
          label: "Luogo",
          key: "luogo",
          thStyle: "width: 18rem",
          sortable: false,
        },
        {
          label: "Risultati",
          key: "risultati",
          thStyle: "width: 20rem",
          sortable: false,
        },
        {
          label: "Data",
          key: "data",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsContatti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Codice Fiscale",
          key: "codiceFiscale",
          sortable: false,
        },
        {
          label: "Nome",
          key: "nome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Cognome",
          key: "cognome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Eta'",
          key: "eta",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Professione",
          key: "professione",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Parentela",
          key: "parentela",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsSintomatologia: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Sintomo",
          key: "sintomo",
          sortable: false,
        },
        {
          label: "Data Dal",
          key: "dataDal",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "Data Al",
          key: "dataAl",
          sortable: false,
          thStyle: "width: 10rem",
          formatter: function (value) {
            return UtilityMixin.methods.formatDate(value);
          },
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
      fieldsSintomo: [
        {
          label: "",
          key: "selezionato",
          thStyle: "width: 1rem",
        },
        {
          label: "Sintomo",
          key: "descrizione",
          sortable: false,
        },
      ],
      malattieOptions: [{ value: "PROVA", text: "PROVA" }],
      statiEsteriOptions: [],
      tipiCasiOptions: [],
      fontiContagioOptions: [],
      parenteleOptions: [],
      sintomiOptions: [],
      professioniOptions: [],
    };
  },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
    rows() {
      return this.sintomiOptions.length;
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.id = me.id;
    me.loadMalattiaInfettivaSegnalazione();
    me.loadData();
    me.loadDefaultData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    isStatoEstero: function (value) {
      let me = this;
      if (value) {
        me.jsonData.listaStatiEsteri.push({ luogo: null, giornoDal: null, giornoAl: null });
      } else {
        me.jsonData.listaStatiEsteri = [];
      }
    },
    isRicercheDiagnostiche: function (value) {
      let me = this;
      if (value) {
        me.jsonData.lisitaRicercheDiagnostiche.push({ tipoRicerca: "", data: null, luogo: "", risultati: "" });
      } else {
        me.jsonData.lisitaRicercheDiagnostiche = [];
      }
    },
    isContatti: function (value) {
      let me = this;
      if (value) {
        me.jsonData.listaContatti.push({ codiceFiscale: "", cognome: "", nome: "", eta: "", professione: null, parentela: null });
      } else {
        me.jsonData.listaContatti = [];
      }
    },
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadTipiCasi();
      me.loadFontiContagio();
      me.loadStatoEstero();
      me.loadParentele();
      me.loadSintomi();
      me.loadProfessioni();
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          if (me.jsonData.listaStatiEsteri.length > 0) {
            me.isStatoEstero = true;
          }
          if (me.jsonData.lisitaRicercheDiagnostiche.length > 0) {
            me.isRicercheDiagnostiche = true;
          }
          if (me.jsonData.listaContatti.length > 0) {
            me.isContatti = true;
          }
          me.showModalLoading = false;
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
        });
      // }
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
      me.jsonData.malattia = data.malattiaSegnalata;
    },
    loadMalattiaInfettivaSegnalazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.setDatiMalattia(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    loadTipiCasi() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkTipiCasi + "?page=1&forPage=1000";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipiCasiOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadFontiContagio() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkFontiContagio + "?page=1&forPage=1000";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.fontiContagioOptions.push({ value: element.descirizione, text: element.descirizione });
          me.fontiContagioOptions.unshift({ value: null, text: "-Seleziona Valore-" });
        });
      });
    },
    loadStatoEstero() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkStatoEstero + "/EE";
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.statiEsteriOptions.push({ value: element.comune, text: element.comune });
        });
      });
    },
    loadParentele() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkParentele + "?page=1&forPage=1000";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.parenteleOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadSintomi() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkSintomi + "?page=1&forPage=10";
      axios.get(link, { params: me.filtro }).then((response) => {
        // response.data.data.list.forEach((element) => {
        //     me.sintomiOptions.push({ value: element.descrizione, text: element.descrizione });
        // });
        me.sintomiOptions = response.data.data.list;
      });
    },
    loadProfessioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkProfessioni + "?page=1&forPage=1000";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.professioniOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    onAddStatiEsteri(array, value) {
      if (value.luogo !== null && value.giornoDal !== null && value.giornoAl !== null) {
        array.push({ luogo: null, giornoDal: null, giornoAl: null });
      } else {
        this.$bvModal
          .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddRicercheDiagnostiche(array, value) {
      if (value.tipoRicerca !== "" && value.luogo !== "" && value.risultati !== "" && value.data !== null) {
        array.push({ tipoRicerca: "", luogo: null, risultati: null, data: null });
      } else {
        this.$bvModal
          .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },

    onAddContatti(array, value) {
      if (value.codiceFiscale !== "" && value.nome !== "" && value.cognome !== "" && value.eta !== "" && value.professione !== null && value.parentela !== null) {
        array.push({ codiceFiscale: "", cognome: "", nome: "", eta: "", professione: null, parentela: null });
      } else {
        this.$bvModal
          .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddSintomi(array, value) {
      if (value.sintomo) {
        array.push({ sintomo: "", dataDal: null, dataAl: null });
      } else {
        this.$bvModal
          .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onOkMdlRicercaSintomo() {
      let me = this;
      me.jsonData.listaSintomatologia[me.indexSelezionato].sintomo = me.sintomoSelezionato;
    },
    onHiddenMdlRicercaSintomo() {
      let me = this;
      me.filtro.descrizione = "";
    },
    onClickRicercaSintomoMdl() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadSintomi();
    },
    onResetFiltroMdl() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadSintomi();
    },
    onRowSelectedSintomi(rows) {
      let me = this;
      if (rows[0]) {
        me.sintomoSelezionato = rows[0].descrizione;
      }
    },
    onClickRicercaSintomo(value) {
      let me = this;
      me.indexSelezionato = value;
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.nomeSanitarioNotificante = Vue.prototype.user.firstname;
      me.jsonData.cognomeSanitarioNotificante = Vue.prototype.user.lastname;
    },
  },
};
</script>
