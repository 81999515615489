<template>
  <div v-if="!isEta">
    <b-card class="sa-card" header="5. Stato Civile" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Stato Civile</label>
          <b-form-select v-model="jsonData.statoCivile" :options="statoCivileOptions" :value="''" value-field="id" text-field="descrizione" @input="onInputStatoCivile"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anno di nascita del coniuge superstite</label>
          <b-form-input v-model="jsonData.annoNascitaConiuge" :disabled="statoCivileDisabled" maxlength="4"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anno di matrimonio</label>
          <b-form-input v-model="jsonData.annoMatrimonio" :disabled="statoCivileDisabled" maxlength="4"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="6. Residenza" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Provincia</label>
          <b-form-select v-model="jsonData.provinciaResidenzaSigla" :options="provinceResidenzaOptions" :value="''" value-field="sigla" text-field="provincia" @input="onInputProvinciaResidenza"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Comune</label>
          <b-form-select v-model="jsonData.comuneResidenzaCodiceIstat" :options="comuniResidenzaOptions" :value="''" value-field="codiceIstat" text-field="comune" @input="onInputComuneResidenza"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">CAP</label>
          <b-form-input v-model="jsonData.capResidenza"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Quartiere di Residenza</label>
          <b-form-select v-model="jsonData.quartiereResidenza" :options="quartieriResidenzaOptions" :value="''" value-field="id" text-field="zonaQuartiere" :disabled="disableQuartiere"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Distretto di Residenza</label>
          <!-- <b-form-input v-model="jsonData.distrettoResidenza"></b-form-input> -->
          <b-form-select v-model="jsonData.distrettoResidenza" :options="distrettiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
          <!-- <b-form-select v-model="jsonData.distrettoResidenza" :options="quartieriResidenzaOptions" :value="''" value-field="id" text-field="zonaQuartiere"></b-form-select> -->
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="8" lg="8" xl="8">
          <label class="sa-label-data">Indirizzo</label>
          <b-form-input v-model="jsonData.indirizzoResidenza"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="ISTRUZIONE E PROFESSIONE" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">7. Grado di Istruzione</label>
          <b-form-select v-model="jsonData.gradoIstruzione" :options="gradiIstruzioneOptions" :value="''" value-field="id" text-field="descrizione"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">8. Professione</label>
          <b-form-input v-model="jsonData.professione"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
  </div>
  <div v-else>
    <b-card class="sa-card" header="Dati della Madre" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">5. Genere Del Parto</label>
          <b-form-select v-model="jsonData.genereParto" :options="generePartoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">6. Filiazione</label>
          <b-form-select v-model="jsonData.filiazione" :options="filiazioneOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">7. Data Di Nascita Della Madre</label>
          <date-picker v-model="jsonData.dataNascitaMadre" format="DD-MM-YYYY" value-type="timestamp" type="datetime"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">8. Residenza Della Madre</label>
          <b-form-select v-model="jsonData.residenzaMadre" :options="residenzaOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Provincia</label>
          <b-form-select v-model="jsonData.provinciaResidenzaMadre" :options="provinceResidenzaOptions" :value="''" value-field="sigla" text-field="provincia" @input="onInputProvinciaResidenza"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Comune</label>
          <b-form-select v-model="jsonData.comuneResidenzaMadre" :options="comuniResidenzaOptions" :value="''" value-field="codiceIstat" text-field="comune"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Indirizzo</label>
          <b-form-input v-model="jsonData.indirizzoMadre"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    jsonData: {
      type: Object,
      require: true,
      default: function () {
        return {
          statoCivile: "",
          annoNascitaConiuge: "",
          annoMatrimonio: "",
          provinciaResidenzaSigla: "",
          comuneResidenzaCodiceIstat: "",
          capResidenza: "",
          quartiereResidenza: "",
          indirizzoResidenza: "",
          distrettoResidenza: "",
          gradoIstruzione: "",
          professione: "",
          /*CAMPI DA AGGIUNGERE*/
          genereParto: "",
          filiazione: "",
          dataNascitaMadre: null,
          residenzaMadre: "",
          provinciaResidenzaMadre: "",
          comuneResidenzaMadre: "",
          indirizzoMadre: "",
        };
      },
    },
  },
  components: { DatePicker },
  data() {
    return {
      pathResourceProvinceResidenza: "/province",
      pathResourceComuniResidenza: "/comuni/provincia",
      pathResourceQuartieriResidenza: "/quartieri",
      pathResourceGradoIstruzione: "/rencamschedemortegradoistruzione",
      pathResourceStatoCivile: "/rencamschedemortestatocivile",
      pathResourceDistretti: "/rencamschedamortedistretti",
      statoCivileDisabled: true,
      disableQuartiere: true,
      isEta: false,
      generePartoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "PARTO SEMPLICE", text: "PARTO SEMPLICE" },
        { value: "PARTO PLURIMO", text: "PARTO PLURIMO" },
      ],
      filiazioneOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "LEGITTIMA", text: "1 - LEGITTIMA" },
        { value: "NATURALE RICONOSCIUTA SOLO DALLA MADRE", text: "2 - NATURALE RICONOSCIUTA SOLO DALLA MADRE" },
        { value: "SOLO DAL PADRE", text: "3 - SOLO DAL PADRE" },
        { value: "DA ENTRAMBI I GENITORI", text: "4 - DA ENTRAMBI I GENITORI" },
        { value: "NATURALE NON RICONOSCIUTA E DI FILIAZIONE IGNOTA", text: "5 - NATURALE NON RICONOSCIUTA E DI FILIAZIONE IGNOTA" },
      ],
      residenzaOptions: [
        { value: "", text: "-Seleziona Localita di Nascita-" },
        { value: "STESSO COMUNE DI MORTE", text: "STESSO COMUNE DI MORTE" },
        { value: "ALTRO COMUNE", text: "ALTRO COMUNE" },
        { value: "STATO ESTERO", text: "STATO ESTERO" },
      ],
      statoCivileOptions: [{ id: "", descrizione: "-Seleziona Stato Civile-" }],
      provinceResidenzaOptions: [{ sigla: "", provincia: "- Seleziona Provincia-" }],
      comuniResidenzaOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
      quartieriResidenzaOptions: [{ id: "", zonaQuartiere: "-Seleziona Quartiere-" }],
      gradiIstruzioneOptions: [{ id: "", descrizione: "-Seleziona Grado di Istruzione-" }],
      distrettiOptions: [{ value: "", text: "-Seleziona Distretti-" }],
      filtro: { page: 1, forPage: 100 },
    };
  },
  mounted() {
    let me = this;
    me.loadProvinceResidenza();
    console.log(me.jsonData.provinciaResidenzaSigla);
    if (me.jsonData.provinciaResidenzaSigla !== "" && me.jsonData.provinciaResidenzaSigla !== null) {
      console.log("Chiamo i Comuni");
      me.loadComuniResidenza(me.jsonData.provinciaResidenzaSigla, true);
    }
    me.loadGradiIstruzione();
    me.loadStatoCivile();
    me.loadDistretti();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        let me = this;
        if (jsonData.eta < 1) {
          me.isEta = true;
        } else {
          me.isEta = false;
          me.jsonData.genereParto = "";
          me.jsonData.filiazione = "";
          me.jsonData.dataNascitaMadre = null;
          me.jsonData.residenzaMadre = "";
          me.jsonData.provinciaResidenzaMadre = "";
          me.jsonData.comuneResidenzaMadre = "";
          me.jsonData.indirizzoMadre = "";
        }
        // if (me.jsonData.provinciaResidenzaSigla !== "" && me.jsonData.provinciaResidenzaSigla !== null) {
        //   me.loadComuniResidenza(me.jsonData.provinciaResidenzaSigla);
        //   me.loadQuartieriResidenza("Napoli");
        // }
      },
      deep: true,
    },
    "jsonData.provinciaResidenzaSigla": function () {
      let me = this;
      // console.log("Verificato");
      if (me.jsonData.provinciaResidenzaSigla !== "" && me.jsonData.provinciaResidenzaSigla !== null) {
        me.loadComuniResidenza(me.jsonData.provinciaResidenzaSigla);
        // me.loadQuartieriResidenza("Napoli");
      }
    },
  },
  methods: {
    loadStatoCivile() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceStatoCivile;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.statoCivileOptions = response.data.data.list;
        me.statoCivileOptions.unshift({ id: "", descrizione: "-Seleziona Stato Civile-" });
      });
    },
    onInputStatoCivile(statoCivile) {
      let me = this;
      if (statoCivile === "2" || statoCivile === "5") {
        me.statoCivileDisabled = false;
      } else {
        me.statoCivileDisabled = true;
        me.jsonData.annoNascitaConiuge = "";
        me.jsonData.annoMatrimonio = "";
      }
    },

    loadProvinceResidenza() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvinceResidenza;
      axios.get(link).then((response) => {
        me.provinceResidenzaOptions = response.data.data;
        me.provinceResidenzaOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
      });
    },
    onInputProvinciaResidenza(provinciaResidenza) {
      let me = this;
      if (provinciaResidenza !== "") {
        // me.jsonData.comuneResidenzaCodiceIstat = "";
        me.loadComuniResidenza(provinciaResidenza);
      } else {
        me.jsonData.comuneResidenzaCodiceIstat = "";
        me.jsonData.capResidenza = "";
        me.comuniResidenzaOptions = [{ codiceIstat: "", comune: "-Seleziona Comune-" }];
      }
    },
    loadComuniResidenza(provinciaResidenza, loadQuartieri = false) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceComuniResidenza + "/" + provinciaResidenza;
      axios.get(link).then((response) => {
        me.comuniResidenzaOptions = response.data.data;
        me.comuniResidenzaOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
        // me.loadQuartieriResidenza("Napoli");
        console.log(loadQuartieri);
        if (loadQuartieri) {
          me.comuniResidenzaOptions.forEach((element) => {
            if (element.codiceIstat === me.jsonData.comuneDecessoCodiceIstat) {
              me.jsonData.capResidenza = element.cap;
              me.loadQuartieriResidenza(element.comune);
            }
          });
        }
      });
    },
    onInputComuneResidenza(comuneResidenza) {
      let me = this;
      let comuneResidenzaDenominazione = "";
      me.comuniResidenzaOptions.forEach((element) => {
        if (element.codiceIstat === comuneResidenza) {
          comuneResidenzaDenominazione = element.comune;
          me.jsonData.capResidenza = element.cap;
          me.jsonData.quartiereResidenza = "";
          me.loadQuartieriResidenza(comuneResidenzaDenominazione);
        }
      });
      // if (comuneResidenzaDenominazione === "Napoli") {
      // me.loadQuartieriResidenza(comuneResidenzaDenominazione);
      // } else {
      //   me.jsonData.quartiereResidenza = "";
      //   me.quartieriResidenzaOptions = [{ id: "", zonaQuartiere: "-Seleziona Quartiere-" }];
      // }
    },
    loadQuartieriResidenza(comuneResidenzaDenominazione) {
      let me = this;
      me.disableQuartiere = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceQuartieriResidenza;
      axios.get(link, { params: { comune: comuneResidenzaDenominazione } }).then((response) => {
        me.quartieriResidenzaOptions = response.data.data;
        me.quartieriResidenzaOptions.unshift({ id: "", zonaQuartiere: "-Seleziona Quartiere-" });
        if (me.quartieriResidenzaOptions.length > 1) {
          me.disableQuartiere = false;
        }
      });
    },
    loadGradiIstruzione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceGradoIstruzione;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.gradiIstruzioneOptions = response.data.data.list;
        me.gradiIstruzioneOptions.unshift({ id: "", descrizione: "-Seleziona Grado Istruzione-" });
      });
    },
    loadDistretti() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDistretti;
      me.distrettiOptions = [];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.distrettiOptions.push({ value: element.id, text: element.asl + " (" + element.distretto + ")" });
        });
        me.distrettiOptions.unshift({ value: "", text: "-Seleziona Distretti-" });
      });
    },
  },
};
</script>
