<template>
    <!-- <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="isThisFirmato" :btnAnnullaVisible="false"
        :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="jsonData"
        class="sa-no-space">
        <template slot="toolbar">           
        </template>
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header">
            <h3 class="sa-event-title">Esami Strumentali</h3>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <esami-strumentali-view-component :idEvento="id"></esami-strumentali-view-component>
            </div>
        </template>
    </sa-page-layout> -->
    <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
        <template slot="tab-content-event">
            <spect-miocardica-view-component :idEvento="id" @update="onUpdateJsonData"></spect-miocardica-view-component>
        </template>
    </eventi-view-component>
</template>
<script>
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixins from "../../../utility/mixins/EventiMixins";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import SpectMiocardicaViewComponent from "./SpectMiocardicaViewComponent.vue";

export default {
    mixins: [UtilityMixins, EventiMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SpectMiocardicaViewComponent, EventiViewComponent },
    data() {
        return {
            linkback: null,
            titoloEvento: "S.P.E.C.T. MIOCARDICA",
            pathResource: "/cardiospectmiocardica",
            pathResourceFirma: "",
            id: null,
            linkPrintData: null,
            jsonData: {},
            tabIndex: 0,
            showModalLoading: false,
            fontScale: 2,
            linkedit: null,
            pathRestValidation: "/validation",
            isThisFirmato: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.id = this.$route.params.id;
    },
    methods: {
        updateStatoFirma(firmato) {
            console.log(firmato);
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
        },
    },
};
</script>
