<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento">
        <template slot="tab-content-event">
            <spect-miocardica-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></spect-miocardica-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import SpectMiocardicaEditComponent from "./SpectMiocardicaEditComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SpectMiocardicaEditComponent, EventiEditComponent },
    data() {
        return {
            linkback: null,
            titoloEvento: "S.P.E.C.T. MIOCARDICA",
            pathResource: "/cardiospectmiocardica",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            jsonData: {
                dataEvento: null,
                normoperfusione: "",
                deficRev1: "",
                deficRev2: "",
                tessutoIpoper1: "",
                tessutoIpoper2: "",
                deficIrr1: "",
                deficIrr2: "",
                conclusione2: "",
                noteSpetMiocardica: "",
                /*NUOVI CAMPI*/
                freqCardiacaBasali: "",
                pressArtMaxBasali: "",
                pressArtMinBasali: "",
                ecg: "",
                protocolloETest: "",
                tempoMin: "",
                freqCardiacaAcme: "",
                percPredettoBpm: "",
                pressArtMaxAcme: "",
                pressArtMinAcme: "",
                wattMinAcme: "",
                percPredettoWattalmin: "",
                tempoMinRecupero: "",
                freqCardiacaRecupero: "",
                percPredettoRecupero: "",
                pressArtMaxRecupero: "",
                pressArtMinRecupero: "",
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
