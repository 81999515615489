<template>
    <div class="sa-tab-scheda">
        <div class="sa-tab-scheda-row-fill">
            <b-row style="background-color: var(--bg-main-container); height: 100%">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-card class="sa-card" header="GIUDIZIO CONCLUSIVO" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Giudizio</label>
                                <span class="sa-data">{{ data.giudizio }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Validita' Mesi</label>
                                <span class="sa-data">{{ data.validitaCertificato }}</span>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Data Scadenza</label>
                                <span class="sa-data">{{ formatDate(data.dataScadenza) }}</span>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label class="sa-label-data">Giudizio conclusivo di non idoneità o sospensione</label>
                                <span class="sa-data">{{ data.giudizioConclusivo }}</span>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <b-card class="sa-card" header="FIRMA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-data">{{ data.firma }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin.js";
export default {
    mixins: [UtilityMixin],
    components: {},
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    giudizio: "",
                    giudizioConclusivo: "",
                    validitaCertificato: null,
                    dataScadenza: null,
                };
            },
        },
    },
    data() {
        return {
            pathRestFirma: "/certificatianamnesi",
        };
    },
};
</script>
