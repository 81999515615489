<template>
    <b-row class="sa-patient-toolbar">
        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <b-dropdown v-for="menu in eventiMenu" :key="menu.id" text="" variant="outline-primary sa-patient-toolbar-btn-primary" class="m-md-2 sa-patient-toolbar-btn sa-patient-toolbar-event-menu">
                <template #button-content>
                    <span><b-img :src="getIconMenu(menu)" fluid-grow :alt="menu.text" class="sa-event-group-icon"></b-img></span>
                </template>
                <b-dropdown-item v-for="itemMenu in menu.children" :key="itemMenu.routeId" :href="getItemMenuHref(itemMenu.viewId)">
                    <b-icon icon="clipboard-plus" class="sa-icon-menu-color"></b-icon>
                    {{ getMenuLabel(itemMenu) }}
                </b-dropdown-item>
            </b-dropdown>
        </b-col>
    </b-row>
</template>

<script>
import axios from "axios";
export default {
    props: {
        idPaziente: {
            type: String,
            default: function () {
                return "";
            },
        },
    },
    data() {
        return {
            pathResourceEventiMenu: "/pazienteeventimenu",
            eventiMenu: [],
        };
    },
    mounted() {
        this.loadEventiMenu();
    },
    methods: {
        loadEventiMenu() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceEventiMenu + "/";
            axios
                .get(link)
                .then((response) => {
                    me.eventiMenu = response.data.data;
                })
                .catch((e) => {
                    console.log("Error: " + e.response.status + " " + link);
                });
        },
        getItemMenuHref(value) {
            let me = this;
            let href = "#/paziente" + value + "/?idPaziente=" + me.idPaziente;
            return href;
        },
        getIconMenu(menu) {
            let returnValue = require("@/assets/images/iconevent/" + menu.iconCls);
            return returnValue;
        },
        getMenuLabel(menu) {
            return this.$i18n.t(menu.text);
        },
    },
};
</script>

<style></style>
