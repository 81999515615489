<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="PUA" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblProtocolNumber") }}</label>
          <b-form-input v-model="jsonData.numeroProtocollo" class="text-upper" @input="onInputData" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblDate") }}</label>
          <date-picker v-model="jsonData.dataProtocollo" format="DD-MM-YYYY" value-type="timestamp" type="date" @input="onInputData" disabled :editable="false"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="CRITERI GENERALI DI ELEGGIBILITA'" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblPrimaryPathology") }}</label>
          <b-form-checkbox v-model="jsonData.patologiaPrimaria" size="lg" switch @input="onInputData" :disabled="editTerapieCure"></b-form-checkbox>
          <b-row v-if="jsonData.patologiaPrimaria === true">
            <b-col>
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblTypology") }}</label>
              <b-form-input v-model="jsonData.tipologiaPatologiaPrimaria" class="text-upper" @input="onInputData" :disabled="editTerapieCure"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblLiverMetastases") }}</label>
          <b-form-checkbox v-model="jsonData.metastasiEpatiche" size="lg" switch @input="onInputData" :disabled="editTerapieCure"></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblPulmonaryBrain") }}</label>
          <b-form-checkbox v-model="jsonData.polmonsriEncefaliche" size="lg" switch @input="onInputData" :disabled="editTerapieCure"></b-form-checkbox>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblOther") }}</label>
          <b-form-textarea no-resize v-model="jsonData.altro" @input="onInputData" :disabled="editTerapieCure"></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblTreatmentsCarriedOut") }}</label>
          <b-form-checkbox v-model="jsonData.trattamentiEffettuati" size="lg" switch @input="onInputData" :disabled="editTerapieCure"></b-form-checkbox>
          <b-row v-if="jsonData.trattamentiEffettuati === true">
            <b-col>
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblTypology") }}</label>
              <b-form-input v-model="jsonData.tipologiaTrattamentiEffettuati" class="text-upper" no-resize @input="onInputData" :disabled="editTerapieCure"></b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblIntervention") }}</label>
          <b-form-checkbox v-model="jsonData.intervento" size="lg" switch @input="onInputData" :disabled="editTerapieCure"></b-form-checkbox>
          <b-row v-if="jsonData.intervento === true">
            <b-col>
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblDate") }}</label>
              <date-picker v-model="jsonData.dataIntervento" format="DD-MM-YYYY" value-type="timestamp" type="date" @input="onInputData" :disabled="editTerapieCure"></date-picker>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblChemotherapy") }}</label>
          <b-form-checkbox v-model="jsonData.chemioterapia" size="lg" switch @input="onInputData" :disabled="editTerapieCure"></b-form-checkbox>
          <b-row v-if="jsonData.chemioterapia === true">
            <b-col>
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblSuspensionDate") }}</label>
              <date-picker v-model="jsonData.dataSospensioneChemioterapia" format="DD-MM-YYYY" value-type="timestamp" type="date" @input="onInputData" :disabled="editTerapieCure"></date-picker>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblRadiotherapy") }}</label>
          <b-form-checkbox v-model="jsonData.radioterapia" size="lg" switch @input="onInputData" :disabled="editTerapieCure"></b-form-checkbox>
          <b-row v-if="jsonData.radioterapia === true">
            <b-col>
              <label class="sa-label-data">{{ getLabelTraduora("adi.lblSuspensionDate") }}</label>
              <date-picker v-model="jsonData.dataSospensioneRadioterapia" format="DD-MM-YYYY" value-type="timestamp" type="date" @input="onInputData" :disabled="editTerapieCure"></date-picker>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblEstimatedSurvival") }} *</label>
          <b-form-input v-model="jsonData.sopravvivenzaStimata" class="text-upper" @input="onInputData" :disabled="editTerapieCure"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblKarnofskyIndex") }} *</label>
          <b-form-select v-model="jsonData.riduzionePerformance" :options="riduzionePerformanceOptions" :value="null" value-field="value" text-field="text" @input="onInputData" :disabled="editTerapieCure"></b-form-select>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <b-table sticky-header ref="tblScalaFlacc" :items="scalaFlaccOptions" :fields="fieldsScalaFlacc" stacked="lg" striped hover sort-icon-left head-variant="light" class="sa-b-table" :key="keyScalaFlac">
            <template v-slot:cell(zero)="{ item }">
              <b-form-radio :name="item.id" v-model="scalaFlaccSelected[item.id]" :value="item.zero" @change="onRadioChangeCellScalaFlacc(item.zero)" :disabled="editTerapieCure" :checked="verificaChecked(item)">
                <span class="sa-data">{{ item.zero }} </span>
              </b-form-radio>
            </template>
            <template v-slot:cell(uno)="{ item }">
              <b-form-radio :name="item.id" v-model="scalaFlaccSelected[item.id]" :value="item.uno" @change="onRadioChangeCellScalaFlacc(item.uno)" :disabled="editTerapieCure">
                <span class="sa-data">{{ item.uno }}</span>
              </b-form-radio>
            </template>
            <template v-slot:cell(due)="{ item }">
              <b-form-radio :name="item.id" v-model="scalaFlaccSelected[item.id]" :value="item.due" @change="onRadioChangeCellScalaFlacc(item.due)" :disabled="editTerapieCure">
                <span class="sa-data">{{ item.due }}</span>
              </b-form-radio>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col>
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblPainLevel") }} *</label>
          <b-form-select v-model="jsonData.livelloDolore" :value="null" :options="livelloDoloreOptions" value-field="value" text-field="text" @input="onInputData" disabled></b-form-select>
          <!-- <b-form-select v-model="jsonData.livelloDolore" :value="null" :options="livelloDoloreOptions" value-field="value" text-field="text" @input="onInputData" :disabled="editTerapieCure"></b-form-select> -->
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    id: String,
    linkPrintData: String,
    numeroProtocollo: String,
    dataProtocollo: { type: Number },
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { DatePicker },
  computed: {},
  data() {
    return {
      jsonData: {
        numeroProtocollo: "",
        dataProtocollo: new Date(),
        patologiaPrimaria: false,
        tipologiaPatologiaPrimaria: "",
        metastasiEpatiche: false,
        polmonsriEncefaliche: false,
        altro: "",
        trattamentiEffettuati: false,
        tipologiaTrattamentiEffettuati: "",
        intervento: false,
        dataIntervento: null,
        chemioterapia: false,
        dataSospensioneChemioterapia: null,
        radioterapia: false,
        dataSospensioneRadioterapia: null,
        sopravvivenzaStimata: "",
        riduzionePerformance: null,
        livelloDolore: null,
        scalaFlacc: [],
      },
      pathResource: "/adischedaterapiacure",
      linkRiduzionePerformance: "/adischedaterapiacureriduzioneperformance",
      linkLivelloDolore: "/adischedaterapiacurelivellodolore",
      linkScalaFlacc: "/adischedaterapiacureschedeflacc",
      riduzionePerformanceOptions: [{ value: null, text: "-Seleziona Valore-" }],
      scalaFlaccSelected: [],
      scalaFlaccOptions: [],
      editTerapieCure: false,
      keyScalaFlac: 0,
      // scalaFlaccOptions: [
      //     {
      //         id: "Viso",
      //         zero: "Sorride o non presenta espressioni particolari",
      //         uno: "Smorfie occasionali, espressione disinteressata",
      //         due: "Aggrottamento di ciglie da costante a frequente, tremore al mento",
      //     },
      //     {
      //         id: "Pianto",
      //         zero: "Non piange",
      //         uno: "Geme e piagnucola. Si lamenta occasionalmente",
      //         due: "Piange in modo continuo, urla e singhiozza. Si lamenta frequentemente",
      //     },
      //     {
      //         id: "Consolabilita'",
      //         zero: "E' rilassato. Soddisfatto",
      //         uno: "Si rassicura parlandogli, abbracciandolo si lascia distrarre",
      //         due: "E' difficile da consolare e confortare",
      //     },
      //     {
      //         id: "Gambe",
      //         zero: "Posizione normale e rilassata",
      //         uno: "Muove le gambe a scatti. La muscolatura è tesa",
      //         due: "Scalcia e ritrae le gambe in modo piu' frequente",
      //     },
      //     {
      //         id: "Attivita'",
      //         zero: "Ha una posizione normale e tranquilla. Si muove naturalmente",
      //         uno: "Si agita, si dondola avanri e indietro. E' teso",
      //         due: "Si inarca in modo prolungato e a scatti",
      //     },
      // ],
      fieldsScalaFlacc: [
        {
          label: "Scala Flacc",
          key: "id",
          thStyle: "width: 3rem",
        },
        {
          label: "0",
          key: "zero",
          thStyle: "width: 10rem",
        },
        {
          label: "1",
          key: "uno",
          thStyle: "width: 10rem",
        },
        {
          label: "2",
          key: "due",
          thStyle: "width: 10rem",
        },
      ],
      livelloDoloreOptions: [{ value: null, text: "--Seleziona il Livello Dolore--" }],
    };
  },
  mounted() {
    let me = this;
    me.jsonData.id = this.$route.params.id;
    me.loadDefaultData();
    me.impostaPermessi();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      let fonte = this.$route.query.fonte;
      me.editTerapieCure = true;
      if (fonte === null || fonte === undefined || fonte === "undefined" || fonte === "") {
        fonte = "/richieste";
      }
      if (fonte === "/richieste") {
        me.editTerapieCure = !UtilityMixin.methods.verificaPermessi(me.utente, "adischedaterapiacure", 2);
      }
    },
    loadDefaultData() {
      let me = this;
      if (me.id !== "-1") {
        me.loadRiduzionePerformance();
        me.loadLivelloDolore();
        me.loadScalaFlacc();
        // me.loadData();
      }
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
      me.showmolalloading = true;
      axios
        .get(link)
        .then((response) => {
          me.jsonData = response.data.data;
          me.jsonData.scalaFlacc.forEach((element) => {
            me.scalaFlaccSelected[element.idScala] = element.valore;
          });
          me.keyScalaFlac++;
          // console.log(me.scalaFlaccSelected);
          // console.log(me.scalaFlaccSelected);
          me.showmolalloading = false;
        })
        .catch(() => {
          me.showmolalloading = false;
          me.jsonData = {
            numeroProtocollo: me.numeroProtocollo,
            dataProtocollo: me.dataProtocollo,
            patologiaPrimaria: false,
            tipologiaPatologiaPrimaria: "",
            metastasiEpatiche: false,
            polmonsriEncefaliche: false,
            altro: "",
            trattamentiEffettuati: false,
            tipologiaTrattamentiEffettuati: "",
            intervento: false,
            dataIntervento: null,
            chemioterapia: false,
            dataSospensioneChemioterapia: null,
            radioterapia: false,
            dataSospensioneRadioterapia: null,
            sopravvivenzaStimata: "",
            riduzionePerformance: null,
            livelloDolore: null,
            scalaFlacc: [],
          };
        });
      me.$emit("update", me.jsonData);
    },
    loadRiduzionePerformance() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkRiduzionePerformance + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.riduzionePerformanceOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadLivelloDolore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkLivelloDolore + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.livelloDoloreOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadScalaFlacc() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkScalaFlacc + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        me.scalaFlaccOptions = response.data.data.list;
        me.loadData();
      });
    },
    getJsonData() {
      let me = this;
      return me.jsonData;
    },
    onInputData() {
      this.jsonData.id = this.$route.params.id;
      this.$emit("update", this.jsonData);
    },
    onRadioChangeCellScalaFlacc() {
      let me = this;
      me.jsonData.livelloDolore = null;
      let totale = 0;
      me.jsonData.scalaFlacc = [];
      for (var key in me.scalaFlaccSelected) {
        let obj = { idScala: key, valore: me.scalaFlaccSelected[key] };
        me.jsonData.scalaFlacc.push(obj);
        me.scalaFlaccOptions.forEach((element) => {
          if (element.id === key) {
            // console.log(me.scalaFlaccSelected[key]);
            if (element.zero === me.scalaFlaccSelected[key]) {
              totale = totale + 0;
            } else if (element.uno === me.scalaFlaccSelected[key]) {
              totale = totale + 1;
            } else if (element.due === me.scalaFlaccSelected[key]) {
              totale = totale + 2;
            }
          }
        });
      }
      // console.log(totale);
      if (totale > 7) {
        me.jsonData.livelloDolore = "8-10: Dolore Grave";
      } else if (totale > 4 && totale < 8) {
        me.jsonData.livelloDolore = "5-7: Dolore Moderato";
      } else if (totale > 0 && totale < 5) {
        me.jsonData.livelloDolore = "1-4: Dolore Lieve";
      } else {
        me.jsonData.livelloDolore = "";
      }
    },
    verificaChecked(value) {
      value;
      // console.log(value);
      return "selected";
    },
  },
};
</script>
