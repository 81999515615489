<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblWeight") }}
                        <eventi-storico-misure-component misura="peso" misuraLabel="Peso" unitaMisura="Kg" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblHeight") }}
                        <eventi-storico-misure-component misura="altezza" misuraLabel="Altezza" unitaMisura="cm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblBMI") }}
                        <eventi-storico-misure-component misura="bmi" misuraLabel="Bmi" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.bmi }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblBSA") }}
                        <eventi-storico-misure-component misura="bsa" misuraLabel="Bsa" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.bsa }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblTBW") }}
                        <eventi-storico-misure-component misura="tbw" misuraLabel="TBW" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.tbw }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblLeanMass") }}
                        <eventi-storico-misure-component misura="massa_magra" misuraLabel="Massa Magra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.massaMagra }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblECW") }}
                        <eventi-storico-misure-component misura="ecw" misuraLabel="ECW" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.ecw }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblFatMass") }}
                        <eventi-storico-misure-component misura="massa_grassa" misuraLabel="Massa Grassa" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.massaGrassa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblBasalMetabolism") }}
                        <eventi-storico-misure-component misura="metabolismo_basale" misuraLabel="Metabolismo Basale" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.metabolismoBasale }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblMuscleMass") }}
                        <eventi-storico-misure-component misura="massa_muscolare" misuraLabel="Massa Muscolare" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.massaMuscolare }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    components: { EventiStoricoMisureComponent },
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
        gruppo: {
            Type: String,
            require: true,
        },
        tipoEvento: {
            Type: String,
            require: true,
        },
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.jsonData.idPaziente;
        },
    },
    data() {
        return {};
    },
};
</script>
