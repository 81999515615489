<template>
  <sa-page-layout :titolo="titolo" :showModalLoading="showModalLoading" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData">
    <template slot="table-filter">
      <div v-if="isStrutture">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Struttura</label>
            <b-form-select v-model="jsonData.codiceStruttura" :options="struttureOptions" :value="''" value-field="codiceStruttura" text-field="denominazione"></b-form-select>
          </b-col>
        </b-row>
      </div>
    </template>
    <template slot="table-header">
      <div style="max-height: 18rem; overflow: auto">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="5">
            <fieldset class="sa-fieldset-intestazione">
              <legend>Intestazione:</legend>
              <p>{{ jsonData.cognomeRagioneSoggetto }} {{ jsonData.nomeSoggetto }}</p>
              <p>
                <label>Codice Destinatario</label>
                {{ jsonData.codiceDestinatario }}
              </p>
              <p>{{ jsonData.indirizzoSoggetto }}</p>
              <p>{{ jsonData.capSoggetto }} {{ jsonData.localitaSoggetto }} {{ jsonData.provinciaSoggetto }}</p>
              <p>
                <label>P. I.V.A.:</label>
                {{ jsonData.partitaIvaSoggetto }}
              </p>
              <p>
                <label>Cod. Fisc.:</label>
                {{ jsonData.codiceFiscaleSoggetto }}
              </p>
            </fieldset>
          </b-col>

          <b-col lg="1">
            <b-button v-b-modal.mdlIntestazione size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onModificaIntestazione">
              <b-icon icon="pencil"></b-icon>
            </b-button>
          </b-col>

          <b-col lg="5">
            <fieldset class="sa-fieldset-intestazione">
              <legend>Dati Documento:</legend>
              <p>
                <label>Numero:</label>
                <span class="sa-data-text">{{ jsonData.numeroDocumento }}</span>
              </p>
              <p>
                <label>Data:</label>
                {{ formatDate(jsonData.dataDocumento) }}
              </p>
              <p>
                <label>Tipo:</label>
                {{ getTipoDocumentoDescrizione(jsonData.idTipoDocumento) }}
              </p>
              <p>
                <label>Causale:</label>
                {{ getDescriptionCasuali(jsonData.idCausaleDocumento) }}
              </p>
            </fieldset>
          </b-col>
          <b-col lg="1">
            <b-button v-b-modal.mdlDatiDocumento size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onModificaDatiDocumento">
              <b-icon icon="pencil"></b-icon>
            </b-button>
          </b-col>
        </b-row>
      </div>
    </template>
    <template slot="table-body">
      <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill>
        <b-tab title="Righe Documento" active>
          <div class="b-table-sticky-header">
            <b-table sticky-header ref="tblRigheDocumento" :filtro="filtro" stacked="xl" striped hover :items="jsonData.righeDocumento" :fields="fieldsRigheDocumento" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
              <template #cell(index)="jsonData">
                {{ jsonData.index + 1 }}
              </template>
              <template #cell(action)="row">
                <b-button v-if="row.item.descrizione.trim() !== ''" size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onModificaRiga(row.index)">
                  <b-icon icon="pencil">{{ row.item }}</b-icon>
                </b-button>
                &nbsp;
                <b-button v-if="row.item.descrizione.trim() !== ''" size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" @click="onDeleteRiga(row.index)">
                  <b-icon icon="trash"></b-icon>
                </b-button>
                &nbsp;
                <b-button v-if="row.item.descrizione.trim() === ''" size="sm" variant="btn btn-success waves-effect waves-light width-sm no-text" @click="onAddRigaDocumento()">
                  <b-icon icon="plus"></b-icon>
                </b-button>
              </template>
            </b-table>
          </div>
        </b-tab>
        <b-tab title="Riepilogo"></b-tab>
      </b-tabs>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info">
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">Metodo Pagamento</label>
          <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.idMetodoPagamento" class="mb-2 mr-sm-2 mb-sm-0" :options="metodiPagamentoOptions" value-field="id" text-field="descrizioneMetodoPagamento" :value="null"></b-form-select>
        </b-col>
      </b-row>
      <b-row class="sa-label-info" v-if="jsonData.marcaBolloImporto > 0">
        <b-col cols="4">
          <p>
            <span class="sa-data">Totale Marca da Bollo:</span>
            <span class="sa-data">{{ jsonData.marcaBolloImporto | toCurrency }}</span>
          </p>
        </b-col>
        <b-col cols="4" class="text-center">
          <span class="sa-data">Marca da Bollo Virtuale:</span>
          <b-form-checkbox v-model="jsonData.marcaBolloVirtuale" switch></b-form-checkbox>
        </b-col>
        <b-col cols="4">
          <span class="sa-data">Marca da Bollo Numero:</span>
          <!-- <b-form-checkbox v-model="jsonData.marcaBolloNumero" switch></b-form-checkbox> -->
          <b-form-input v-model="jsonData.marcaBolloNumero" :disabled="jsonData.marcaBolloVirtuale" placeholder="Marca da Bollo Numero"></b-form-input>
        </b-col>
      </b-row>
      <b-row class="sa-label-info">
        <b-col cols="4">
          <p>
            <span class="sa-data">Totale Imponibile:</span>
            <span class="sa-data">{{ jsonData.totaleImponibileDocumento | toCurrency }}</span>
          </p>
        </b-col>
        <b-col cols="4" class="text-center">
          <p>
            <span class="sa-data">Totale Iva:</span>
            <span class="sa-data">{{ jsonData.totaleImpostaDocumento | toCurrency }}</span>
          </p>
        </b-col>
        <b-col cols="4" class="text-right">
          <p>
            <span class="sa-data">Totale Documento:</span>
            <span class="sa-data">{{ jsonData.totaleDocumento | toCurrency }}</span>
          </p>
        </b-col>
      </b-row>
      <div>
        <b-modal id="mdlIntestazione" ref="mdlIntestazione" title="Intestazione" size="xl" @show="onShowRicercaDatiSoggetto" @ok="onOkModificaIntestazione">
          <b-form @submit.prevent="onClick">
            <b-row>
              <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ this.$i18n.t("billing.lblCompanyName") }}</label>
                <b-input-group class="mb-3">
                  <b-form-input v-model="intestazioneDocumento.cognomeRagioneSoggetto" placeholder="Ragione Sociale"></b-form-input>
                  <b-button size="sm" variant="" @click="onClickRicercaDatiSoggetto">
                    <b-icon icon="search"></b-icon>
                  </b-button>
                </b-input-group>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Nome</label>
                <b-form-input v-model="intestazioneDocumento.nomeSoggetto" placeholder="Nome"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("billing.lblFiscalCode") }}</label>
                <b-form-input v-model="intestazioneDocumento.identificativo" placeholder="Codice Fiscale"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ this.$i18n.t("billing.lblIva") }}</label>
                <b-form-input v-model="intestazioneDocumento.partitaIvaSoggetto" placeholder="Partita Iva"></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Codice Destinatorio</label>
                <b-form-input v-model="intestazioneDocumento.codiceDestinatario" placeholder="Codice Destinatario"></b-form-input>
              </b-col>

              <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ this.$i18n.t("billing.lblProvince") }}</label>
                <!--<b-form-input list="my-list-id"></b-form-input>

                                <datalist id="my-list-id">
                                    <option v-for="provincia in provinceOptions" :key="provincia.sigla">{{ provincia.provincia }}</option>
                                </datalist>-->
                <b-form-select id="inline-form-custom-select-pref" v-model="intestazioneDocumento.provinciaSoggetto" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceOptions" value-field="sigla" text-field="provincia" :value="null" @input="loadComuni($event)"></b-form-select>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ this.$i18n.t("billing.lblCity") }}</label>
                <b-form-select id="inline-form-custom-select-pref" v-model="intestazioneDocumento.localitaSoggettoCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniOptions" value-field="codiceIstat" text-field="comune" @input="setCap($event)"></b-form-select>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ this.$i18n.t("billing.lblPostalCode") }}</label>
                <b-form-input v-model="intestazioneDocumento.capSoggetto" placeholder="C.A.P."></b-form-input>
              </b-col>
              <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ this.$i18n.t("billing.lblAddress") }}</label>
                <b-form-input v-model="intestazioneDocumento.indirizzoSoggetto" placeholder="Indirizzo"></b-form-input>
              </b-col>
            </b-row>
          </b-form>
          <br />
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <div class="b-table-sticky-header">
                <b-table id="tabellaIntestatario" :items="tabellaDatiSoggetto" :fields="clientiFields" selectable select-mode="single" @row-selected="onRowSelected">
                  <template #cell(index)="data">
                    {{ data.index + 1 }}
                  </template>
                  <template #cell(nominativo)="data">{{ data.item.cognome + " " + data.item.nome }}</template>
                </b-table>
              </div>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal id="mdlDatiDocumento" title="Dati Documento" size="xl" @ok="onOkModificaDatiDocumento">
          <b-row>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="6">
              <label class="sa-label-data">{{ this.$i18n.t("billing.lblDocumentNumber") }}</label>
              <b-form-input v-model="datiDocumento.numeroDocumento" placeholder="Numero Documento"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="6">
              <label class="sa-label-data">{{ this.$i18n.t("billing.lblDate") }}</label>
              <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="datiDocumento.dataDocumento" type="timestamp" placeholder="Data Documento"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="6">
              <label class="sa-label-data">{{ this.$i18n.t("billing.lblDocumentType") }}</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="datiDocumento.idTipoDocumento" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiDocumentoOptions" text-field="descrizioneTipoDocumento" value-field="id" @input="loadCausaleDocumentoCodice($event)" :value="null"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <label class="sa-label-data">{{ this.$i18n.t("billing.lblCausaleDocumentoCodice") }}</label>
              <b-form-select id="inline-form-custom-select-pref" v-model="datiDocumento.idCausaleDocumento" class="mb-2 mr-sm-2 mb-sm-0" :options="causaliDocumentoOptions" text-field="descrizioneCausale" value-field="id" :value="null"></b-form-select>
            </b-col>
          </b-row>
        </b-modal>

        <b-modal id="modalRiga" ref="modalRiga" title="Riga Documento" size="xl" @show="onShowRigaDocumento" hide-footer>
          <div class="sa-form-section">
            <form ref="form" @submit.stop.prevent="handleSubmit">
              <b-row>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblProductCode") }}</label>
                  <b-input-group class="mb-3">
                    <b-form-input type="text" v-model="rigaDocumentoSelezionata.codiceArticolo" placeholder="Codice" required :state="formRigaDocumento.codiceArticoloState"></b-form-input>
                    <!-- <b-button size="sm" variant="" v-b-modal.ricercaArticolo>
                                            <b-icon icon="search"></b-icon>
                                        </b-button> -->
                  </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblDescription") }}</label>
                  <b-form-input type="text" v-model="rigaDocumentoSelezionata.descrizione" placeholder="Descrizione" required :state="formRigaDocumento.descrizioneState"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblUnitOfMeasure") }}</label>
                  <b-form-select id="inline-form-custom-select-pref" v-model="rigaDocumentoSelezionata.idUnitaMisura" class="mb-2 mr-sm-2 mb-sm-0" :options="unitaMisureOptions" :value="null" text-field="descrizione" value-field="id" required :state="formRigaDocumento.idUnitaMisuraState"></b-form-select>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblQuantity") }}</label>
                  <b-form-input type="number" v-model="rigaDocumentoSelezionata.quantita" placeholder="Quantità" required name="quantita" :state="formRigaDocumento.quantitaState" @change="onCalcolo"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblMultiplier") }}</label>
                  <b-form-select id="inline-form-custom-select-pref" v-model="rigaDocumentoSelezionata.idMoltiplicatore" class="mb-2 mr-sm-2 mb-sm-0" :options="moltiplicatoriOptions" :value="null" text-field="descrizioneMoltiplicatore" value-field="id" required :state="formRigaDocumento.idMoltiplicatoreState"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblUnitPrice") }}</label>
                  <b-input-group size="md" prepend="€">
                    <b-form-input step="0.01" type="number" v-model="rigaDocumentoSelezionata.prezzoUnitario" placeholder="Prezzo Unitario" required name="prezzoUnitario" @change="onCalcolo" :state="formRigaDocumento.prezzoUnitarioState"></b-form-input>
                  </b-input-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblDiscountPercentage") }}</label>
                  <b-input-group size="md" append="%">
                    <b-form-input step="0.01" type="number" v-model="rigaDocumentoSelezionata.sconto" class="form-control" @change="onCalcolo" required :state="formRigaDocumento.scontoState"></b-form-input>
                  </b-input-group>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblTotalLineAmount") }}</label>
                  <b-form-input step="0.001" type="number" v-model="rigaDocumentoSelezionata.totaleRiga" placeholder="Totale Riga" readonly></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblIva") }}</label>
                  <b-form-select id="inline-form-custom-select-pref" v-model="rigaDocumentoSelezionata.idIva" class="mb-2 mr-sm-2 mb-sm-0" :options="ivaOptions" :value="null" text-field="descrizioneIva" value-field="id" required :state="formRigaDocumento.idIvaState"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                  <label class="sa-label-data">{{ this.$i18n.t("billing.lblTipoSpesa") }}</label>
                  <b-form-select id="inline-form-custom-select-pref" v-model="rigaDocumentoSelezionata.idTipoSpesa" class="mb-2 mr-sm-2 mb-sm-0" :options="tipiSpesaOptions" :value="null" text-field="descrizioneTipoSpesa" value-field="id" required :state="formRigaDocumento.idTipoSpesaState"></b-form-select>
                </b-col>
              </b-row>
            </form>
          </div>
          <hr />
          <b-row>
            <div class="sa-padding-right text-right col">
              <b-button type="button" variant="secondary" @click="onCloseRigaDocumento">Cancel</b-button>
              <b-button type="button" variant="info" @click="onOkRigaDocumento">OK</b-button>
            </div>
          </b-row>
        </b-modal>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
// import moment from "moment";
// require("moment/locale/it");
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin.js";
import moment from "moment";
export default {
  components: { SaPageLayout, DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      id: null,
      titolo: "Fatturazione",
      nameState: null,
      showModalLoading: false,
      pathResource: "/documenti",
      pathResourceStrutture: "/strutture",
      linkback: null,
      linkDatiSoggetto: "/clienti",
      linkCalcoloTotaleRiga: "/calcola",
      currentPage: 1,
      perPage: null,
      isStrutture: false,
      filtro: "",
      value: 0,
      item: {},
      rigaSelezionata: { descrizione: "" },
      rigaDocumentoSelezionata: { codiceArticolo: "", descrizione: "", idUnitaMisura: null, quantita: null, descrizioneMoltiplicatore: null, moltiplicatore: null, prezzoUnitario: null, sconto: null, totaleRiga: null, descrizioneIva: null, codiceTipoSpesa: null, idMoltiplicatore: null, idIva: null, idTipoSpesa: null },
      intestazioneDocumento: { idSoggetto: null, cognomeRagioneSoggetto: null, nomeSoggetto: null, codiceFiscaleSoggetto: null, partitaIvaSoggetto: null, codiceDestinatario: "", indirizzoSoggetto: null, provinciaSoggetto: null, localitaSoggetto: null, capSoggetto: null },
      datiDocumento: { numeroDocumento: null, dataDocumento: null, idTipoDocumento: null, idCausaleDocumento: null },
      comuniOptions: [],
      provinceOptions: [],
      tipiDocumentoOptions: [{ id: null, descrizioneTipoDocumento: "-Selezionare Tipo Documento-" }],
      causaliDocumentoOptions: [{ id: null, descrizioneCausale: "-Selezionare Causale-" }],
      metodiPagamentoOptions: [],
      unitaMisureOptions: [],
      moltiplicatoriOptions: [],
      ivaOptions: [],
      tipiSpesaOptions: [],
      struttureOptions: [],
      indexRigaDocumentoSelezionata: null,
      formRigaDocumento: {
        codiceArticoloState: null,
        descrizioneState: null,
        idUnitaMisuraState: null,
        quantitaState: null,
        descrizioneMoltiplicatoreState: null,
        prezzoUnitarioState: null,
        scontoState: null,
        totaleRigaState: null,
        codiceDescrizioneIvaState: null,
        codiceTipoSpesaState: null,
        idMoltiplicatoreState: null,
        idIvaState: null,
        idTipoSpesaState: null,
      },
      clientiFields: [
        {
          label: "N.",
          key: "index",
          sortable: false,
          thStyle: "width: 2em",
        },
        {
          label: "Cognome Nome/Ragione Sociale",
          key: "nominativo",
          sortable: true,
        },
        {
          label: "Codice Fiscale",
          key: "identificativo",
          sortable: true,
        },
        {
          label: "Partita I.V.A",
          key: "partitaIva",
          sortable: true,
        },
      ],
      fieldsRigheDocumento: [
        {
          label: "N.",
          key: "index",
          sortable: false,
          thStyle: "width: 1em",
        },
        {
          label: this.$i18n.t("billing.lblDescription"),
          key: "descrizione",
          sortable: true,
        },
        {
          label: this.$i18n.t("billing.lblQuantity"),
          key: "quantita",
          sortable: true,
          thStyle: "width: 4em",
        },
        {
          label: this.$i18n.t("billing.lblUnitOfMeasure"),
          key: "unitaMisura",
          sortable: true,
          thStyle: "width: 4em",
        },
        {
          label: this.$i18n.t("billing.lblAmount"),
          key: "prezzoUnitario",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return value.toLocaleString("it-IT", { style: "currency", currency: "EUR" });
            }
          },
          thStyle: "width: 7em",
        },
        {
          label: this.$i18n.t("billing.lblDiscountPercentage"),
          key: "sconto",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return value + "%";
              // return value.toLocaleString("it-IT", { style: "percent" });
            }
          },
          thStyle: "width: 5em",
        },
        {
          label: this.$i18n.t("billing.lblTotalLineAmount"),
          key: "totaleRiga",
          sortable: true,
          formatter: (value) => {
            if (value) {
              return value.toLocaleString("it-IT", { style: "currency", currency: "EUR" });
            }
          },
          thStyle: "width: 8rem",
        },
        {
          label: this.$i18n.t("billing.lblIva"),
          key: "descrizioneIva",
          sortable: true,
        },
        {
          label: this.$i18n.t("billing.lblTipoSpesa"),
          key: "codiceTipoSpesa",
          sortable: true,
          thStyle: "width: 6.5rem",
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 7rem",
          tdClass: "text-center",
        },
      ],
      items: [],
      tabellaDatiSoggetto: [],
      jsonData: {
        idSoggetto: null,
        idMetodoPagamento: null,
        numeroDocumento: null,
        dataDocumento: new Date().getTime(),
        cognomeRagioneSoggetto: null,
        nomeSoggetto: null,
        codiceFiscaleSoggetto: null,
        partitaIvaSoggetto: null,
        indirizzoSoggetto: null,
        provinciaSoggetto: null,
        localitaSoggetto: null,
        localitaSoggettoCodiceIstat: null,
        capSoggetto: null,
        causaleDocumentoDescrizione: null,
        idTipoDocumento: null,
        tipoDocumentoDescrizione: null,
        idCausaleDocumento: null,
        indirizzoDestinazione: "IDEM",
        totaleImponibileDocumento: 0.0,
        totaleImpostaDocumento: 0.0,
        totaleDocumento: 0.0,
        idMagazzino: "1",
        magazzinoDescrizione: "GG",
        codiceStruttura: "",
        causaleDocumento: { descrizioneCausale: null },
        righeDocumento: [],
      },
    };
  },
  watch: {
    "jsonData.idTipoDocumento": function (newValue) {
      if (newValue !== null) {
        this.loadCausaleDocumentoCodice(newValue);
      }
    },
    "jsonData.provinciaSoggetto": function (newValue) {
      if (newValue !== null) {
        this.loadComuni(newValue);
      }
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    if (me.id !== "-1") {
      me.linkback = "/contabilita/fatturazione/view/" + me.id;
    } else {
      me.linkback = "/contabilita/fatturazione";
    }
    me.loadData();
    me.loadDefaultData();
    // me.loadProvince();
    // me.loadTipiDocumento();
    // me.loadMetodiPagamento();
    // me.loadUnitaMisura();
    // me.loadMoltiplicatori();
    // me.loadIva();
    // me.loadTipoSpesa();
    let anagraficaStringa = sessionStorage.getItem("AnagraficaPaziente");
    let anagraficaJson = {};
    if (anagraficaStringa !== null && anagraficaStringa !== "null") {
      anagraficaJson = JSON.parse(anagraficaStringa);
      me.setDatiDocumento(anagraficaJson);
    }
    sessionStorage.setItem("AnagraficaPaziente", null);
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadProvince();
      me.loadTipiDocumento();
      me.loadMetodiPagamento();
      me.loadUnitaMisura();
      me.loadMoltiplicatori();
      me.loadIva();
      me.loadTipoSpesa();
      me.loadStrutture();
    },
    formatDate(value) {
      if (value) {
        //return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    validateStateRigaDocumento() {
      let me = this;
      me.formRigaDocumento.codiceArticoloState = me.rigaDocumentoSelezionata.codiceArticolo.trim() == "" ? false : true;
      me.formRigaDocumento.descrizioneState = me.rigaDocumentoSelezionata.descrizione.trim() == "" ? false : true;
      me.formRigaDocumento.quantitaState = me.rigaDocumentoSelezionata.quantita === null ? false : !isNaN(me.rigaDocumentoSelezionata.quantita);
      me.formRigaDocumento.scontoState = me.rigaDocumentoSelezionata.sconto === null || me.rigaDocumentoSelezionata.sconto === "" ? false : !isNaN(me.rigaDocumentoSelezionata.sconto);
      me.formRigaDocumento.totaleRigaState = me.rigaDocumentoSelezionata.totaleRiga <= 0 ? false : true;
      me.formRigaDocumento.idMoltiplicatoreState = !me.rigaDocumentoSelezionata.idMoltiplicatore ? false : true;
      me.formRigaDocumento.idIvaState = me.rigaDocumentoSelezionata.idIva <= 0 ? false : true;
      me.formRigaDocumento.idTipoSpesaState = me.rigaDocumentoSelezionata.idTipoSpesa < 0 ? false : true;
      me.formRigaDocumento.idUnitaMisuraState = !me.rigaDocumentoSelezionata.idUnitaMisura ? false : true;
      me.formRigaDocumento.prezzoUnitarioState = me.rigaDocumentoSelezionata.prezzoUnitario === null ? false : !isNaN(me.rigaDocumentoSelezionata.prezzoUnitario);
    },
    loadData() {
      let me = this;

      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.righeDocumento.push(me.rigaSelezionata);
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        me.showModalLoading = true;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            if (me.jsonData.righeDocumento) {
              me.jsonData.righeDocumento.push(me.rigaSelezionata);
            } else {
              me.jsonData.righeDocumento = [];
              me.jsonData.righeDocumento.push(me.rigaSelezionata);
            }
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
    loadStrutture() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutture;
      axios
        .get(link)
        .then((response) => {
          me.struttureOptions = response.data.data;
          if (me.struttureOptions.length > 1) {
            me.isStrutture = true;
            me.struttureOptions.unshift({ codiceStruttura: "", denominazione: "-Seleziona Struttura-" });
          } else {
            me.isStrutture = false;
            me.jsonData.codiceStruttura = me.struttureOptions[0].codiceStruttura;
          }
        })
        .catch(() => {});
    },
    loadMetodiPagamento() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/metodipagamento";
      axios.get(link).then((response) => {
        me.metodiPagamentoOptions = response.data.data;
        me.metodiPagamentoOptions.unshift({ id: null, descrizioneMetodoPagamento: "- Seleziona Met. Pagamento -" });
      });
    },
    loadTipiDocumento() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/tipidocumento";
      axios.get(link).then((response) => {
        me.tipiDocumentoOptions = response.data.data;
        if (me.tipiDocumentoOptions.length === 1) {
          me.jsonData.idTipoDocumento = me.tipiDocumentoOptions[0].id;
          me.jsonData.tipoDocumentoDescrizione = me.tipiDocumentoOptions[0].descrizioneTipoDocumento;
          me.loadCausaleDocumentoCodice(me.jsonData.idTipoDocumento);
        }
        me.tipiDocumentoOptions.unshift({ id: null, descrizioneTipoDocumento: "-Selezionare Tipo Documento-" });
      });
    },
    loadCausaleDocumentoCodice(idTipoDocumento) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/causalidocumento";
      axios.get(link, { params: { idTipoDocumento: idTipoDocumento } }).then((response) => {
        me.causaliDocumentoOptions = response.data.data;
        if (me.causaliDocumentoOptions.length === 1) {
          me.jsonData.idCausaleDocumento = me.causaliDocumentoOptions[0].id;
          me.jsonData.causaleDocumentoDescrizione = me.causaliDocumentoOptions[0].descrizioneCausale;
        }
        me.causaliDocumentoOptions.unshift({ id: null, descrizioneCausale: "-Selezionare Causale-" });
      });
    },
    loadProvince() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/province";
      axios.get(link).then((response) => {
        me.provinceOptions = response.data.data;
        me.provinceOptions.unshift({ codiceIstat: null, provincia: "- Seleziona Provincia-" });
      });
    },
    loadComuni(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/comuni/provincia/" + sigla;
      axios.get(link).then((response) => {
        me.comuniOptions = response.data.data;
        me.comuniOptions.unshift({ codiceIstat: null, comune: "-Seleziona Comune-" });
        if (me.intestazioneDocumento.capSoggetto === null) {
          me.setCap(me.intestazioneDocumento.localitaSoggettoCodiceIstat);
        }
      });
    },
    onModificaIntestazione() {
      let me = this;
      if (me.id !== -1) {
        me.intestazioneDocumento.cognomeRagioneSoggetto = me.jsonData.cognomeRagioneSoggetto;
        me.intestazioneDocumento.nomeSoggetto = me.jsonData.nomeSoggetto;
        me.intestazioneDocumento.identificativo = me.jsonData.codiceFiscaleSoggetto;
        me.intestazioneDocumento.partitaIvaSoggetto = me.jsonData.partitaIvaSoggetto;
        me.intestazioneDocumento.indirizzoSoggetto = me.jsonData.indirizzoSoggetto;
        me.intestazioneDocumento.provinciaSoggetto = me.jsonData.provinciaSoggetto;
        me.intestazioneDocumento.localitaSoggettoCodiceIstat = me.jsonData.localitaSoggettoCodiceIstat;
        me.intestazioneDocumento.capSoggetto = me.jsonData.capSoggetto;
        me.intestazioneDocumento.codiceDestinatario = me.jsonData.codiceDestinatario;
      }
      //me.$refs("mdlIntestazione").show();
    },
    onModificaDatiDocumento() {
      let me = this;
      if (me.id !== -1) {
        // Object.assign(me.datiDocumento, me.jsonData);
        me.datiDocumento.numeroDocumento = me.jsonData.numeroDocumento;
        me.datiDocumento.dataDocumento = me.jsonData.dataDocumento;
        me.datiDocumento.idTipoDocumento = me.jsonData.idTipoDocumento;
        me.datiDocumento.idCausaleDocumento = me.jsonData.idCausaleDocumento;
      }
      // me.datiDocumento.indirizzoSoggetto = me.data.indirizzoSoggetto;
    },
    onShowRicercaDatiSoggetto() {},
    onOkModificaIntestazione() {
      let me = this;
      me.jsonData.cognomeRagioneSoggetto = me.intestazioneDocumento.cognomeRagioneSoggetto;
      me.jsonData.nomeSoggetto = me.intestazioneDocumento.nomeSoggetto;
      me.jsonData.codiceFiscaleSoggetto = me.intestazioneDocumento.identificativo;
      me.jsonData.partitaIvaSoggetto = me.intestazioneDocumento.partitaIvaSoggetto;
      me.jsonData.indirizzoSoggetto = me.intestazioneDocumento.indirizzoSoggetto;
      me.jsonData.provinciaSoggetto = me.intestazioneDocumento.provinciaSoggetto;
      me.jsonData.localitaSoggettoCodiceIstat = me.intestazioneDocumento.localitaSoggettoCodiceIstat;
      me.jsonData.capSoggetto = me.intestazioneDocumento.capSoggetto;
      me.jsonData.localitaSoggetto = me.intestazioneDocumento.localitaSoggetto;
      me.jsonData.idSoggetto = me.intestazioneDocumento.idSoggetto;
      me.jsonData.codiceDestinatario = me.intestazioneDocumento.codiceDestinatario;
    },
    onClickRicercaDatiSoggetto() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.linkDatiSoggetto + "/";

      axios
        .get(link, { params: { ragioneSoggetto: me.descrizioneRagioneSoggetto } })
        .then((response) => {
          me.tabellaDatiSoggetto = response.data.data;
          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          me.$bvModal
            .msgBoxOk(error.response.data.messaggio, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then((value) => {
              this.boxTwo = value;
            })
            .catch((err) => {
              console.log(err);
            });
        });
    },
    setCap(value) {
      let me = this;
      me.comuniOptions.forEach((comune) => {
        if (comune.codiceIstat === value) {
          me.intestazioneDocumento.capSoggetto = comune.cap;
          me.intestazioneDocumento.localitaSoggetto = comune.comune;
        }
      });
    },
    onRowSelected(item) {
      let me = this;
      if (item.length > 0) {
        me.intestazioneDocumento.idSoggetto = item[0].id;
        me.intestazioneDocumento.cognomeRagioneSoggetto = item[0].cognome;
        me.intestazioneDocumento.nomeSoggetto = item[0].nome;
        me.intestazioneDocumento.identificativo = item[0].identificativo;
        me.intestazioneDocumento.partitaIvaSoggetto = item[0].partitaIva;
        me.intestazioneDocumento.indirizzoSoggetto = item[0].indirizzoResidenza;
        me.intestazioneDocumento.provinciaSoggetto = item[0].provinciaResidenza;
        me.intestazioneDocumento.localitaSoggettoCodiceIstat = item[0].comuneResidenzaCodiceIstat;
        me.intestazioneDocumento.codiceDestinatario = item[0].codiceDestinatario;

        //me.setCap(me.intestazioneDocumento.localitaSoggetto);
      }
    },
    onOkModificaDatiDocumento() {
      let me = this;
      me.tipiDocumentoOptions.forEach((tipoDocumento) => {
        if (tipoDocumento.id === me.datiDocumento.idTipoDocumento) {
          me.jsonData.tipoDocumentoDescrizione = tipoDocumento.descrizioneTipoDocumento;
        }
      });
      me.jsonData.numeroDocumento = me.datiDocumento.numeroDocumento;
      me.jsonData.dataDocumento = me.datiDocumento.dataDocumento;
      me.jsonData.idTipoDocumento = me.datiDocumento.idTipoDocumento;
      me.jsonData.idCausaleDocumento = me.datiDocumento.idCausaleDocumento;
    },
    onModificaRiga(index) {
      let me = this;
      me.indexRigaDocumentoSelezionata = index;
      Object.assign(me.rigaDocumentoSelezionata, me.jsonData.righeDocumento[index]);
      me.$bvModal.show("modalRiga");
    },
    onAddRigaDocumento() {
      let me = this;
      let index = me.jsonData.righeDocumento.length - 1;

      me.indexRigaDocumentoSelezionata = index;
      Object.assign(me.rigaDocumentoSelezionata, me.jsonData.righeDocumento[index]);
      if (me.moltiplicatoriOptions.length === 2) {
        me.rigaDocumentoSelezionata.idMoltiplicatore = me.moltiplicatoriOptions[1].id;
        me.rigaDocumentoSelezionata.descrizioneMoltiplicatore = me.moltiplicatoriOptions[1].descrizioneMoltiplicatore;
      }
      me.$bvModal.show("modalRiga");
    },
    onOkRigaDocumento(bvModalEvt) {
      let me = this;
      bvModalEvt.preventDefault();
      const valid = this.$refs.form.checkValidity();

      me.nameState = valid;
      if (!valid) {
        me.validateStateRigaDocumento();
        return;
      }
      me.rigaDocumentoSelezionata.codiceTipoSpesa = me.getCodiceTipoSpesa(me.rigaDocumentoSelezionata.idTipoSpesa);
      me.rigaDocumentoSelezionata = me.setIva(me.rigaDocumentoSelezionata);
      me.rigaDocumentoSelezionata.unitaMisura = me.getUnitaMisura(me.rigaDocumentoSelezionata.idUnitaMisura);
      me.jsonData.righeDocumento[me.indexRigaDocumentoSelezionata] = me.rigaDocumentoSelezionata;
      this.$refs.tblRigheDocumento.refresh();
      if (me.jsonData.righeDocumento[me.jsonData.righeDocumento.length - 1].descrizione !== "") {
        me.jsonData.righeDocumento.push({ codiceArticolo: "", descrizione: "", unitaMisura: null, quantita: null, descrizioneMoltiplicatore: null, prezzoUnitario: null, sconto: null, totaleRiga: null, descrizioneIva: null, codiceTipoSpesa: null, idMoltiplicatore: null, idIva: null, idTipoSpesa: null });
      }
      me.calcolaDocumento();

      this.$nextTick(() => {
        this.$bvModal.hide("modalRiga");
      });
    },
    calcolaDocumento() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + me.linkCalcoloTotaleRiga;
      let jsonData = JSON.stringify(me.jsonData);
      axios.post(link, jsonData).then((response) => {
        me.jsonData = response.data.data;
        me.showModalLoading = false;
      });
    },
    onCloseRigaDocumento() {
      let me = this;
      me.formRigaDocumento.codiceArticoloState = null;
      me.formRigaDocumento.descrizioneState = null;
      me.formRigaDocumento.quantitaState = null;
      me.formRigaDocumento.scontoState = null;
      me.formRigaDocumento.totaleRigaState = null;
      me.formRigaDocumento.idMoltiplicatoreState = null;
      me.formRigaDocumento.idIvaState = null;
      me.formRigaDocumento.idTipoSpesaState = null;
      me.formRigaDocumento.idUnitaMisuraState = null;
      me.formRigaDocumento.prezzoUnitarioState = null;

      this.$bvModal.hide("modalRiga");
    },
    handleSubmit() {
      console.log("Handle Submit");
    },
    loadUnitaMisura() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/unitamisura";
      axios.get(link).then((response) => {
        me.unitaMisureOptions = response.data.data;
        me.unitaMisureOptions.unshift({ id: null, descrizione: "- Seleziona U.M. -" });
      });
    },
    loadMoltiplicatori() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/moltiplicatori";
      axios.get(link).then((response) => {
        me.moltiplicatoriOptions = response.data.data;
        me.moltiplicatoriOptions.unshift({ id: null, descrizioneMoltiplicatore: "- Seleziona Moltiplo -" });
      });
    },
    loadIva() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/tabellaiva";
      axios.get(link).then((response) => {
        me.ivaOptions = response.data.data;
        me.ivaOptions.unshift({ id: null, descrizioneIva: "- Seleziona Tipo Iva -" });
      });
    },
    loadTipoSpesa() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/tipispesa";
      axios.get(link).then((response) => {
        me.tipiSpesaOptions = response.data.data;
        me.tipiSpesaOptions.unshift({ id: null, descrizioneTipoSpesa: "- Seleziona Tipo Spesa -" });
      });
    },
    onShowRigaDocumento() {},
    onDeleteRiga(index) {
      let me = this;
      me.jsonData.righeDocumento.splice(index, 1);
    },
    onCalcolo() {
      let me = this;
      let totaleRiga = 0;
      me.setMoltiplicatore(me.rigaDocumentoSelezionata);
      totaleRiga = me.rigaDocumentoSelezionata.quantita * me.rigaDocumentoSelezionata.moltiplicatore * me.rigaDocumentoSelezionata.prezzoUnitario;
      totaleRiga = totaleRiga - (totaleRiga * me.rigaDocumentoSelezionata.sconto) / 100;
      me.rigaDocumentoSelezionata.totaleRiga = totaleRiga;
    },
    getTipoDocumentoDescrizione(idTipoDocumento) {
      let me = this;
      let descrizione = " ";
      me.tipiDocumentoOptions.forEach((element) => {
        if (element.id === idTipoDocumento) {
          descrizione = element.descrizioneTipoDocumento;
        }
      });
      return descrizione;
    },
    getDescriptionCasuali(id) {
      let me = this;
      let descrizione = " ";
      me.causaliDocumentoOptions.forEach((element) => {
        if (element.id === id) {
          descrizione = element.descrizioneCausale;
        }
      });
      return descrizione;
    },
    getCodiceTipoSpesa(id) {
      let me = this;
      let codiceSpesa = "";
      me.tipiSpesaOptions.forEach((element) => {
        if (element.id === id) {
          codiceSpesa = element.codiceTipoSpesa;
        }
      });
      return codiceSpesa;
    },
    setIva(riga) {
      let me = this;
      me.ivaOptions.forEach((element) => {
        if (element.id === riga.idIva) {
          riga.descrizioneIva = element.descrizioneIva;
          riga.aliquota = element.aliquotaIva;
        }
      });
      return riga;
    },
    setMoltiplicatore(riga) {
      let me = this;
      me.moltiplicatoriOptions.forEach((element) => {
        if (element.id === riga.idMoltiplicatore) {
          riga.moltiplicatore = element.multiplo;
        }
      });
      return riga;
    },
    getUnitaMisura(id) {
      let me = this;
      let description = "";
      me.unitaMisureOptions.forEach((element) => {
        if (element.id === id) {
          description = element.descrizione;
        }
      });
      return description;
    },
    setDatiDocumento(anagraficaPaziente) {
      let me = this;
      console.log(anagraficaPaziente);
      me.jsonData.cognomeRagioneSoggetto = anagraficaPaziente.accettazioneAnagrafica.cognome;
      me.jsonData.nomeSoggetto = anagraficaPaziente.accettazioneAnagrafica.nome;
      me.jsonData.partitaIvaSoggetto = anagraficaPaziente.accettazioneAnagrafica.identificativo;
      me.jsonData.codiceFiscaleSoggetto = anagraficaPaziente.accettazioneAnagrafica.identificativo;
      me.jsonData.indirizzoSoggetto = anagraficaPaziente.accettazioneAnagrafica.indirizzoResidenza;
      me.jsonData.capSoggetto = anagraficaPaziente.accettazioneAnagrafica.capResidenza;
      me.jsonData.provinciaSoggetto = anagraficaPaziente.accettazioneAnagrafica.provinciaResidenza;
      me.jsonData.localitaSoggetto = anagraficaPaziente.accettazioneAnagrafica.comuneResidenza;
      me.jsonData.localitaSoggettoCodiceIstat = anagraficaPaziente.accettazioneAnagrafica.comuneResidenzaCodiceIstat;
      me.jsonData.codiceStruttura = anagraficaPaziente.accettazioneStruttura.codiceStruttura;
      me.jsonData.idTipoDocumento = "1";
      if (me.jsonData.idTipoDocumento != null) {
        me.jsonData.idCausaleDocumento = "1";
      }
      me.rigaDocumentoSelezionata.codiceArticolo = anagraficaPaziente.tipoVisita;
      me.rigaSelezionata.descrizione = anagraficaPaziente.tipoVisita;
      if (anagraficaPaziente.tipoAtleta) {
        if (anagraficaPaziente.tipoAtleta === "AGONISTA") {
          me.rigaSelezionata.descrizione = "VISITA MEDICO SPORTIVA AGONISTICA";
        } else if (anagraficaPaziente.tipoAtleta === "NON AGONISTA") {
          me.rigaSelezionata.descrizione = "VISITA MEDICO SPORTIVA NON AGONISTICA";
        } else if (anagraficaPaziente.tipoAtleta === "AGONISTA PRIVATO") {
          me.rigaSelezionata.descrizione = "VISITA MEDICO SPORTIVA AGONISTICA";
        } else if (anagraficaPaziente.tipoAtleta === "VOLO DA DIPORTO") {
          me.rigaSelezionata.descrizione = "VISITA MEDICO SPORTIVA PER VOLO DA DIPORTO";
        }
      }
      me.rigaDocumentoSelezionata.idUnitaMisura = "2";
      me.rigaDocumentoSelezionata.quantita = "1";
      // me.rigaDocumentoSelezionata.idIva = "1";
      me.rigaDocumentoSelezionata.idMoltiplicatore = "1";
      me.rigaDocumentoSelezionata.idTipoSpesa = "0";
      me.rigaDocumentoSelezionata.sconto = 0;
      me.loadTariffa(anagraficaPaziente.tariffaBase);
    },
    loadTariffa(tariffaBase) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/leonidaaccettazionetariffe";
      axios.get(link, { params: { forPage: 10000, page: 1 } }).then((response) => {
        me.tariffaBaseOptions = response.data.data.list;
        me.tariffaBaseOptions.forEach((element) => {
          if (element.codiceTariffa === tariffaBase) {
            me.rigaDocumentoSelezionata.prezzoUnitario = element.importo;
            me.setDescrizioneIva(element.iva);
          }
        });
      });
    },
    setDescrizioneIva(iva) {
      let me = this;
      me.ivaOptions.forEach((element) => {
        if (element.descrizioneIva === iva) {
          me.rigaDocumentoSelezionata.idIva = element.id;
        }
      });
    },
  },
};
</script>
