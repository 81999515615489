<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                    <span class="sa-data"> {{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("lbl.measure.weight") }}</label>
                    <span class="sa-data"> {{ jsonData.peso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("lbl.measure.height") }}</label>
                    <span class="sa-data"> {{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblBMI") }}</label>
                    <span class="sa-data"> {{ jsonData.bmi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblBSA") }}</label>
                    <span class="sa-data"> {{ jsonData.bsa }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.card.lblRiskCalculationResultFactors')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHypertension") }}</label>
                    <span :class="setColor(jsonData.ipertensione) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.ipertensione)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.Dyslipidemia") }}</label>
                    <span :class="setColor(jsonData.dislipidemia) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.dislipidemia)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblDiabetes") }}</label>
                    <span :class="setColor(jsonData.diabete) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diabete)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</label>
                    <span :class="setColor(jsonData.obesita) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.obesita)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSmoking") }}</label>
                    <span :class="setColor(jsonData.fumo) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fumo)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCardiovascularAccidentsFamiliarity") }}</label>
                    <span :class="setColor(jsonData.familCerebrovascolari) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCerebrovascolari)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</label>
                    <span :class="setColor(jsonData.familIpertensione) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familIpertensione)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIDiseaseFamiliarity") }}</label>
                    <span :class="setColor(jsonData.familCardiopatiaIsch) + ' sa-text-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCardiopatiaIsch)"></b-icon>
                    </span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Origine" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">DX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">PSV</label>
                    <span class="sa-data"> {{ jsonData.vpsDx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">EDV</label>
                    <span class="sa-data"> {{ jsonData.edvVpsDx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data" style="color: red">IR</label>
                    <span class="sa-data"> {{ jsonData.irDx }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">PSV</label>
                    <span class="sa-data"> {{ jsonData.vpsSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">EDV</label>
                    <span class="sa-data"> {{ jsonData.edvVpsSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data" style="color: red">IR</label>
                    <span class="sa-data"> {{ jsonData.irSx }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Tratto Medio" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">DX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">PSV</label>
                    <span class="sa-data"> {{ jsonData.vpsDxTrattoMedio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">EDV</label>
                    <span class="sa-data"> {{ jsonData.edvVpsDxTrattoMedio }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data" style="color: red">IR</label>
                    <span class="sa-data"> {{ jsonData.irDxTrattoMedio }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">PSV</label>
                    <span class="sa-data"> {{ jsonData.vpsSxTrattoMedio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">EDV</label>
                    <span class="sa-data"> {{ jsonData.edvVpsSxTrattoMedio }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data" style="color: red">IR</label>
                    <span class="sa-data"> {{ jsonData.irSxTrattoMedio }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - llo" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">DX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">PSV</label>
                    <span class="sa-data"> {{ jsonData.vpsDxIlo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">EDV</label>
                    <span class="sa-data"> {{ jsonData.edvVpsDxIlo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data" style="color: red">IR</label>
                    <span class="sa-data"> {{ jsonData.irDxIlo }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">SX</label>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">PSV</label>
                    <span class="sa-data"> {{ jsonData.vpsSxIlo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">EDV</label>
                    <span class="sa-data"> {{ jsonData.edvVpsSxIlo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data" style="color: red">IR</label>
                    <span class="sa-data"> {{ jsonData.irSxIlo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Intraparenchimale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">DX</label>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">PSV</label>
                    <span class="sa-data"> {{ jsonData.vpsAtDx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">EDV</label>
                    <span class="sa-data"> {{ jsonData.edvAtDx }}</span>
                </b-col>

                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data" style="color: red">IR</label>
                    <span class="sa-data"> {{ jsonData.irDxIntrapar }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">AT</label>
                    <span class="sa-data"> {{ jsonData.atDx }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">SX</label>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">PSV</label>
                    <span class="sa-data"> {{ jsonData.vpsAtSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">EDV</label>
                    <span class="sa-data"> {{ jsonData.edvAtSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data" style="color: red">IR</label>
                    <span class="sa-data"> {{ jsonData.irSxIntrapar }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">AT</label>
                    <span class="sa-data"> {{ jsonData.atSx }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Soprarenale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diametro AP</label>
                    <span class="sa-data"> {{ jsonData.vpsAtSx2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diametro LL</label>
                    <span class="sa-data"> {{ jsonData.vps2 }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Ecocolordoppler Arterie Renali - Sottorenale" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diametro AP</label>
                    <span class="sa-data"> {{ jsonData.vps3 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diametro LL</label>
                    <span class="sa-data"> {{ jsonData.vps4 }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Referto" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Referto</label>
                    {{ jsonData.referto }}
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Conclusioni" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.esameNellaNorma)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.esameNellaNorma)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <span>{{ getLabelTraduora("patient.cardiology.lblExamInTheNorm") }}</span>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.stenosiCcSx)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCcSx)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblStenosisOnLeft") }}</label>
                        </b-col>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.stenosiCcDx)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCcDx)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="4" sm="4" md="4" lg="4" xl="4">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblStenosisOnRight") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aterosclerosiLieve)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiLieve)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLightAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSignificativeStenosis") }}</label
                    ><br />
                    {{ jsonData.stenosiSignificativa }}
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aterosclerosiModerata)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiModerata)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblModerateAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aterosclerosiNonEmoSign)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiNonEmoSign)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblNotSignificativeEmodinamAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <b-row>
                        <b-col xs="2" sm="2" md="2" lg="2" xl="2">
                            <span :class="setColor(jsonData.aterosclerosiSevera)">
                                <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiSevera)"></b-icon>
                            </span>
                        </b-col>
                        <b-col xs="10" sm="10" md="10" lg="10" xl="10">
                            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSevereAterosclerosys") }}</label>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            linkback: null,
            pathResource: "/cardioecocolordoppleraortaaddominale",
            jsonData: { createDate: 0, createUser: "prova" },
            data: {},
            tabIndex: 0,
            id: null,
            showModalLoading: false,
            fontScale: 2,
            linkedit: null,
            pathRestValidation: "/validation",
            isThisFirmato: false,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
