<template>
    <sa-page-layout :pathResource="pathResource" :data="jsonData" :btnEditVisible="true" :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" class="sa-no-space">
        <template slot="toolbar">
            <!-- <print-component class="float-sm-right primary" :data="jsonData" :reportName="reportName"></print-component> -->
            <b-button v-b-modal.stampaReferto style="display: inline-block" variant="btn--md btn-light waves-effect waves-light btn-toolbar sa-margin-right" size="sm" @click="onStampa(jsonData)">
                <b-icon icon="printer" />
                Stampa
            </b-button>
            <!-- <b-button size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onStampa(jsonData)"></b-button> -->
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <leonida-accettazione-paziente-view-component :jsonData="jsonData"></leonida-accettazione-paziente-view-component>
            </div>
            <b-modal ref="mdlStampa" size="xl" style="height: 100%">
                <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import LeonidaAccettazionePazienteViewComponent from "../../components/LeonidaAccettazionePazienteViewComponent.vue";
// import PrintComponent from "../../../utility/components/PrintComponent.vue";
// import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    components: { SaPageLayout, LeonidaAccettazionePazienteViewComponent }, //, PrintComponent
    mounted() {
        let me = this;
        me.id = me.$route.params.id;
        me.linkedit = "/leonidaaccettazioni/edit/" + me.id;
        me.loadData();
    },
    data() {
        return {
            id: "",
            linkback: "/leonidaaccettazioni",
            stampaReport: null,
            linkedit: null,
            pathResource: "/leonidaaccettazioni",
            pathResourceAnagrafica: "/anagrafica",
            pathResourceUltimaVisita: "/leonidavisite/ultimavisita",
            pathResourceStampa: "/print/createreport",
            reportName: "CertificatoIdoneitaCatoMaior",
            jsonDataVisita: {},
            jsonData: {
                accettazioneStruttura: { codiceStruttura: null },
                accettazioneAnagrafica: {
                    codiceFiscale: "",
                    provinciaNascita: null,
                    comuneNascitaCodiceIstat: null,
                    medicoCurante: "",
                    codiceFiscaleMedicoCurante: "",
                    provinciaResidenza: null,
                    comuneResidenzaCodiceIstat: null,
                    provinciaDomicilio: null,
                    comuneDomicilioCodiceIstat: null,
                    capDomicilio: null,
                    capResidenza: null,
                    dataNascita: null,
                    sesso: null,
                    tipoIdentificativo: null,
                    nome: "",
                    cognome: "",
                },
                idPrenotazione: "",
                tipo: "",
            },
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.showModalLoading = false;
                    me.jsonData = response.data.data;
                    // me.loadDataVisita();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        // loadDataVisita() {
        //     let me = this;
        //     let link = process.env.VUE_APP_PATH_API + me.pathResourceAnagrafica;
        //     axios
        //         .get(link)
        //         .then((response) => {
        //             response.data.data.forEach((element) => {
        //                 if (element.identificativo === me.jsonData.accettazioneAnagrafica.identificativo) {
        //                     let link = process.env.VUE_APP_PATH_API + me.pathResourceUltimaVisita;
        //                     axios
        //                         .get(link, { params: { idAtleta: element.id } })
        //                         .then((response) => {
        //                             me.jsonDataVisita = response.data.data;
        //                             me.jsonData.giudizio = me.jsonDataVisita.giudizio;
        //                             me.jsonData.dataScadenzaCertificato = UtilityMixin.methods.formatDate(me.jsonDataVisita.dataScadenza);
        //                             me.jsonData.giudizioConclusivo = me.jsonDataVisita.giudizioConclusivo;
        //                             me.jsonData.obbligoLentiCorrettive = me.jsonDataVisita.visitaEsameObbiettivo.obbligoLentiCorrettive;
        //                         })
        //                         .catch(() => {});
        //                 }
        //             });
        //         })
        //         .catch(() => {});
        // },
        onStampa(row) {
            let me = this;
            // console.log(row);
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
            axios.get(link).then((response) => {
                let printData = response.data.data;
                let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
                axios.post(linkReport, { reportName: me.reportName, data: printData }).then((response) => {
                    me.$refs.mdlStampa.show();
                    me.stampaReport = "data:application/pdf;base64," + response.data.base64;
                });
            });
        },
    },
};
</script>
