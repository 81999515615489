<template>
    <b-card class="sa-card" header="Immagini" header-tag="header" footer-tag="footer" title="">
        <b-row>
            <b-col v-for="immagine in immagini" :key="immagine.id" xs="12" sm="12" md="6" lg="4" xl="3">
                <b-row style="padding-bottom: 0px">
                    <b-col>
                        <div style="padding-left: 0px; padding-right: 0px; padding-bottom: 0px; display: flex">
                            <span class="sa-pei-label">{{ immagine.nome }}</span>
                            <!-- <input class="sa-pei-input" type="text" v-model="immagine.nome" disabled /> -->
                            <b-form-checkbox class="sa-pei-chk" :checked="immagine.stampaNote === 'SI'" disabled>
                                <i class="bi bi-printer"></i>
                                <span class="sa-pei-chk-label">Note</span>
                            </b-form-checkbox>
                            <b-form-checkbox class="sa-pei-chk" :checked="immagine.stampaImmagine === 'SI'" disabled>
                                <i class="bi bi-printer"></i>
                                <span class="sa-pei-chk-label">Img.</span>
                            </b-form-checkbox>
                            <i :class="getColorByNote(immagine.note)" @click="onClickBtnNote(immagine)" :disabled="isNoteDisabled"></i>
                        </div>
                    </b-col>
                </b-row>
                <div class="sa-drop-image" @dragover.prevent="onDragOver" @drop.prevent="onDrop($event)" @dragleave.prevent="onDragleave" v-if="!viewNote[immagine.id]">
                    <b-img thumbnail fluid :src="immagine.immagine" alt="Image 1"></b-img>
                </div>
                <div v-else>
                    <span class="sa-data">{{ immagine.note.slice(0, 356) }}</span>
                    <a class="" style="font-size: 0.75rem" v-if="readMoreActivated && immagine.note.length > 350" href="#" @click="onLeggiTuttoClick(immagine)">Leggi tutto...</a>
                </div>
                <div></div>
            </b-col>
            <b-modal ref="mdlNoteImmagine" id="mdlNoteImmagine" :title="titoloNotaSelezionata" hide-footer>
                <span class="sa-data">{{ noteSelezionate }}</span>
            </b-modal>
        </b-row>
    </b-card>
</template>

<script>
import Vue from "vue";
import axios from "axios";
export default {
    props: {
        idRisorsa: {
            type: String,
            default: function () {
                return null;
            },
        },
        contesto: {
            type: String,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            immagini: [],
            viewNote: [],
            isNoteDisabled: false,
            showModalLoading: false,
            readMoreActivated: true,
            pathResource: "/pazienteeventiimmagini",
            noteSelezionate: "",
            titoloNotaSelezionata: "",
        };
    },
    watch: {
        idRisorsa() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                if (me.idRisorsa) {
                    let link = process.env.VUE_APP_PATH_API + me.pathResource;
                    axios.get(link, { params: { idRisorsa: me.idRisorsa } }).then((response) => {
                        me.immagini = response.data.data.list;
                        // this.$emit("update", response.data.data);
                        me.showModalLoading = false;
                    });
                }
            }
        },
        getColorByNote(note) {
            let colore = note ? "sa-tab-title-success text-success bi bi-chat-right-text sa-pei-btn-icon" : "sa-tab-title-danger text-danger bi bi-chat-right-text sa-pei-btn-icon";
            return colore;
        },
        onClickBtnNote(immagine) {
            let me = this;
            if (immagine.note != "") {
                me.isNoteDisabled = false;
                Vue.set(me.viewNote, immagine.id, !me.viewNote[immagine.id]);
            } else {
                me.isNoteDisabled = true;
            }
        },
        onLeggiTuttoClick(jsonData) {
            let me = this;
            me.titoloNotaSelezionata = "Note " + jsonData.nome;
            me.noteSelezionate = jsonData.note;
            me.$refs.mdlNoteImmagine.show();
        },
    },
};
</script>

<style>
.sa-pei-label {
    font-size: 0.75rem;
    width: 270px;
    padding-top: 4px;
}
</style>
