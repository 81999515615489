<template>
    <sa-page-layout :btnRefreshVisible="true" :btnNewVisible="btnNewVisible" :linkedit="linkedit" @refresh="onRefresh" :showModalLoading="showModalLoading" :pathResource="pathResource">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.titolo" type="search" id="filterInput" :placeholder="this.$i18n.t('agenda.lblTitletoSearch')"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.descrizione" type="search" id="testo" :placeholder="this.$i18n.t('agenda.lblDescriptiontoSearch')"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" :filter="filtro" responsive stacked="lg" striped hover :items="data" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(titolo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/agende/view/' + item.id">{{ item.titolo }}</router-link>
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-input-group>
                            <b-button size="sm" v-if="btnEditAgendaVisible" class="no-text" variant="outline-info" style="margin-right: 3px" @click="onEdit(row.item, row.index, $event.target)">
                                <b-icon icon="pencil" variant="outline-primary" />
                            </b-button>
                            <b-button size="sm" v-if="btnDeleteAgendaVisible" class="no-text" variant="outline-danger" style="margin-right: 3px" @click="onDelete(row.item, row.index, $event.target)"><b-icon icon="trash" variant="outline-danger"></b-icon></b-button>
                            <b-button size="sm" class="no-text" variant="outline-info" @click="onView(row.item, row.index, $event.target)">
                                <b-icon icon="calendar-event" variant="outline-primary" />
                            </b-button>
                            <condivisione-modal :idRisorsa="row.item.id" idSezione="agende"></condivisione-modal>
                        </b-input-group>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import CondivisioneModal from "../../utility/components/CondivisioneModal.vue";
export default {
    components: { SaPageLayout, CondivisioneModal },
    mixins: [UtilityMixin],
    props: {
        utente: { type: Object },
    },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            showModalLoading: false,
            linkedit: "/agende",
            pathResource: "/agende",
            currentPage: 1,
            perPage: 20,
            btnNewVisible: false,
            btnEditAgendaVisible: false,
            btnDeleteAgendaVisible: false,
            filtro: { descrizione: "", titolo: "" },
            filterOn: [],
            fields: [
                {
                    label: this.$i18n.t("agende.lblDiary"),
                    key: "titolo",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("agende.lblDescription"),
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 12.5rem",
                },
            ],

            items: [],
            data: [],
            traduzione: "",
        };
    },
    created: function () {
        this.loadData();
        this.impostaPermessi();
    },
    watch: {
        utente() {
            let me = this;
            me.impostaPermessi();
        },
    },
    methods: {
        impostaPermessi() {
            let me = this;
            me.btnNewVisible = UtilityMixin.methods.verificaPermessi(me.utente, "agende", 2);
            me.btnEditAgendaVisible = UtilityMixin.methods.verificaPermessi(me.utente, "agende", 4);
            me.btnDeleteAgendaVisible = UtilityMixin.methods.verificaPermessi(me.utente, "agende", 8);
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.data = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(function (error) {
                    me.showModalLoading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Agende",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onEdit(item) {
            let me = this;
            me.$router.replace(me.linkedit + "/edit/" + item.id).catch((err) => {
                err;
            });
        },
        onView(item) {
            let me = this;
            me.$router.replace(me.linkedit + "/view/" + item.id).catch((err) => {
                err;
            });
        },
        onAdd() {
            let me = this;
            me.$router.replace(me.pathResource + "/edit/-1").catch((error) => {
                error;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onDelete(row) {
            let me = this;

            this.$bvModal
                .msgBoxConfirm([this.$i18n.t("agenda.lblMsgDelete")] + " " + row.titolo + " ?")
                .then((item) => {
                    if (item) {
                        let link = process.env.VUE_APP_PATH_API + me.pathResource;
                        axios.delete(link + "/" + row.id).then(() => {
                            me.$bvToast.toast([this.$i18n.t("agenda.lblConfirmDelete")], {
                                title: "Agenda",
                                variant: "info",
                                solid: true,
                                noCloseButton: true,
                                autoHideDelay: 2000,
                            });
                            me.loadData();
                        });
                    }
                })
                .catch((err) => {
                    this.$bvModal.msgBoxOk(err.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblEditTitle")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },
        onSubmit() {
            let me = this;
            me.data = [];
            me.data = me.items.filter((element) => element.titolo.toUpperCase().includes(me.filtro.titolo.toUpperCase()) && element.descrizione.toUpperCase().includes(me.filtro.descrizione.toUpperCase()));
        },
        onReset() {
            let me = this;
            me.data = [];
            me.loadData();
        },
    },
};
</script>
