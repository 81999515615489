<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Caso</label>
          <b-form-select v-model="jsonData.tipoCaso" :options="tipiCasiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sintomi</label>
          <b-form-input v-model="jsonData.sintomi"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Inizio Sintomi</label>
          <date-picker v-model="jsonData.dataInizioSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricovero</label>
          <b-form-select v-model="jsonData.ricovero" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputRicovero"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Ricovero</label>
          <date-picker v-model="jsonData.dataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="dataRicoveroDisabled"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nome Ospedale</label>
          <b-form-input v-model="jsonData.ricoveroNomeOspedale"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Reparto</label>
          <b-form-input v-model="jsonData.ricoveroReparto"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="DIAGNOSI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dati Clinici</label>
          <b-form-select v-model="jsonData.diagnosiBasataDatiClinici" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Esame Colturale</label>
          <b-form-select v-model="jsonData.diagnosiBasataEsameColturale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Esame Diretto</label>
          <b-form-select v-model="jsonData.diagnosiBasataEsameDiretto" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Esame Sierologico</label>
          <b-form-select v-model="jsonData.diagnosiBasataEsameSierologico" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputDiagnosiEsameSierologico"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Specificare Esami Sierologici</label>
          <b-form-textarea v-model="jsonData.diagnosiBasataEsameSierologicoTipo" rows="6" no-resize :disabled="diagnosiEsameSierologicoTipoDisabled"></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo e Sede Lesione</label>
          <b-form-textarea v-model="jsonData.tipoSedeLesione" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Fonte di Contagio</label>
          <b-form-textarea v-model="jsonData.fonteContagio" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tempistica</label>
          <b-form-textarea v-model="jsonData.tempisticaContagio" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Note</label>
          <b-form-textarea v-model="jsonData.note" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="DATI VACCINO" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Stato Vaccino</label>
          <b-form-select v-model="jsonData.statoVaccino" :options="statiVaccinoOptions" :value="''" value-field="value" text-field="text" @input="onInputStatoVaccino"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Vaccino</label>
          <date-picker v-model="jsonData.dataVaccinazione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="dataVaccinazioneDisabled"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="CONTATTI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Contatti</label>
          <b-form-select v-model="jsonData.contatti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row style="margin-top: 5px">
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(parentela)="item">
              <b-form-select v-model="item.item.parentela" :options="parenteleOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </template>
            <template #cell(codiceFiscale)="item">
              <b-form-input v-model="item.item.codiceFiscale"></b-form-input>
            </template>
            <template #cell(cognome)="item">
              <b-form-input v-model="item.item.cognome"></b-form-input>
            </template>
            <template #cell(nome)="item">
              <b-form-input v-model="item.item.nome"></b-form-input>
            </template>
            <template #cell(professione)="item">
              <!-- <b-form-select v-model="item.item.professione" :options="professioniOptions" :value="''" value-field="value" text-field="text"></b-form-select> -->
              <b-form-input v-model="item.item.professione"></b-form-input>
            </template>
            <template #cell(eta)="item">
              <b-form-input v-model="item.item.eta" maxlength="3"></b-form-input>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddContatti(jsonData.listaContatti, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.listaContatti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaContatti, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sanitario Notificante</label>
          <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Telefono Sanitario Notificante</label>
          <b-form-input v-model="jsonData.sanitarioNotificanteContatto"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Notifica</label>
          <date-picker v-model="jsonData.dataSegnalazione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import Vue from "vue";
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveiemalattietrasmissionesessuale",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      pathResourceProfessioni: "/malattieinfettiveprofessioni",
      pathResourceParentele: "/malattieinfettiveieparentele",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      showModalLoading: false,
      dataRicoveroDisabled: true,
      diagnosiEsameSierologicoTipoDisabled: true,
      dataVaccinazioneDisabled: true,
      jsonData: {
        tipoCaso: "",
        dataInizioSintomi: null,
        ricovero: "",
        dataRicovero: null,
        diagnosiBasataDatiClinici: "",
        diagnosiBasataEsameColturale: "",
        diagnosiBasataEsameDiretto: "",
        diagnosiBasataEsameSierologico: "",
        diagnosiBasataEsameSierologicoTipo: "",
        tipoSedeLesione: "",
        fonteContagio: "",
        statoVaccino: "",
        dataVaccinazione: null,
        contatti: "",
        listaContatti: [],
        sanitarioNotificante: "",
        sanitarioNotificanteContatto: "",
        dataSegnalazione: null,
        sintomi: "",
        ricoveroNomeOspedale: "",
        ricoveroReparto: "",
      },
      fieldsContatti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Parentela",
          key: "parentela",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "Codice Fiscale",
          key: "codiceFiscale",
          thStyle: "width: 15rem",
          sortable: false,
        },
        {
          label: "cognome",
          key: "cognome",
          thStyle: "width: 20rem",
          sortable: false,
        },
        {
          label: "Nome",
          key: "nome",
          thStyle: "width: 20rem",
          sortable: false,
        },
        {
          label: "Professione",
          key: "professione",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "Eta'",
          key: "eta",
          thStyle: "width: 5rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      tipiCasiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "PROBABILE", text: "PROBABILE" },
        { value: "ACCERTATO", text: "ACCERTATO" },
      ],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      statiVaccinoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "VACCINATO", text: "VACCINATO" },
        { value: "NON VACCINATO", text: "NON VACCINATO" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      parenteleOptions: [],
      professioniOptions: [],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.id = me.id;
    me.loadMalattiaInfettivaSegnalazione();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.contatti": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaContatti.push({ parentela: "", codiceFiscale: "", cognome: "", nome: "", professione: "", eta: "" });
        me.loadParentele();
        me.loadProfessioni();
      } else {
        me.jsonData.listaContatti = [];
      }
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
        });
    },
    loadParentele() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceParentele + "?page=1&forPage=1000";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.parenteleOptions.push({ value: element.descrizione, text: element.descrizione });
        });
        me.parenteleOptions.unshift({ value: "", text: "-Seleziona-" });
      });
    },
    loadProfessioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProfessioni + "?page=1&forPage=1000";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.professioniOptions.push({ value: element.descrizione, text: element.descrizione });
        });
        me.professioniOptions.unshift({ value: "", text: "-Seleziona-" });
      });
    },
    onInputRicovero(value) {
      let me = this;
      if (value === "SI") {
        me.dataRicoveroDisabled = false;
      } else {
        me.dataRicoveroDisabled = true;
        me.jsonData.dataRicovero = null;
      }
    },
    onInputDiagnosiEsameSierologico(value) {
      let me = this;
      if (value === "SI") {
        me.diagnosiEsameSierologicoTipoDisabled = false;
      } else {
        me.diagnosiEsameSierologicoTipoDisabled = true;
        me.jsonData.diagnosiBasataEsameSierologicoTipo = "";
      }
    },
    onInputStatoVaccino(value) {
      let me = this;
      if (value === "VACCINATO") {
        me.dataVaccinazioneDisabled = false;
      } else {
        me.dataVaccinazioneDisabled = true;
        me.jsonData.dataVaccinazione = null;
      }
    },
    onAddContatti(array, value) {
      if (value.parentela !== "" && value.codiceFiscale !== "" && value.cognome !== "" && value.nome !== "" && value.professione !== "" && value.eta !== "") {
        array.push({ parentela: "", codiceFiscale: "", cognome: "", nome: "", professione: "", eta: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
    loadMalattiaInfettivaSegnalazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.setDatiMalattia(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
  },
};
</script>
