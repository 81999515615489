<template>
  <div>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Cognome</label>
          <b-form-input v-model="jsonData.cognome" class="text-uppercase"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nome</label>
          <b-form-input v-model="jsonData.nome" class="text-uppercase"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Nascita</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataNascita" type="date" placeholder="Data Nascita" :disabled-date="notAfter" @input="onInputCalcoloEta"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Sesso</label>
          <b-form-select v-model="jsonData.sesso" :options="sessoOptions" :value="''" class="mb-3" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Codice Fiscale</label>
          <b-form-input v-model="jsonData.codiceFiscale" class="text-uppercase"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Morte</label>
          <!-- <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataMorte" type="date" placeholder="Data Morte" @input="onInputCalcoloEta"></date-picker> -->
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataMorte" type="date" :disabled-date="notAfter" placeholder="Data Morte" @input="onInputCalcoloEta"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Eta</label>
          <b-form-input v-model="jsonData.eta" type="number" disabled></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipo Scheda di morte</label>
          <span v-if="isEta">{{ jsonData.tipoSchedaMorte }}</span>
          <span v-else>{{ jsonData.tipoSchedaMorte }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Localita Nascita</label>
          <b-form-select v-model="jsonData.localitaNascita" :options="localitaNascitaOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Provincia/Stato Estero Di Nascita</label>
          <b-form-select v-model="jsonData.provinciaNascitaSigla" :options="provinceNascitaOptions" :value="''" value-field="sigla" text-field="provincia" @input="onInputProvinciaNascita"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Comune/Stato Estero Di Nascita</label>
          <b-form-select v-model="jsonData.comuneNascitaCodiceIstat" :options="comuniNascitaOptions" :value="''" value-field="codiceIstat" text-field="comune"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  props: {
    jsonData: {
      type: Object,
      require: true,
      default: function () {
        return {
          cognome: "",
          nome: "",
          codiceFiscale: "",
          sesso: "",
          dataNascita: null,
          dataMorte: null,
          tipoSchedaMorte: "", //DA AGGIUNGERE AL BACKEND
          provinciaNascitaSigla: "",
          comuneNascitaCodiceIstat: "",
          localitaNascita: "",
          eta: "",
        };
      },
    },
  },
  components: { DatePicker },
  data() {
    return {
      isEta: false,
      provinceNascitaOptions: [{ sigla: "", provincia: "- Seleziona Provincia-" }],
      comuniNascitaOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
      pathResourceProvince: "/province",
      linkComune: "/comuni/provincia",
      sessoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "M", text: "Maschio" },
        { value: "F", text: "Femmina" },
      ],
      localitaNascitaOptions: [
        { value: "", text: "-Seleziona Localita di Nascita-" },
        { value: "Stesso Comune di Morte", text: "Stesso Comune di Morte" },
        { value: "Altro Comune", text: "Altro Comune" },
        { value: "Stato Estero", text: "Stato Estero" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadProvince();
  },

  watch: {
    jsonData: {
      handler(jsonData) {
        let me = this;
        if (jsonData.eta < 1) {
          me.isEta = true;
          me.jsonData.tipoSchedaMorte = "Morte nel 1° anno di vita";
        } else {
          me.isEta = false;
          me.jsonData.tipoSchedaMorte = "Morte oltre il 1° anno di vita";
        }
      },
      deep: true,
    },
    "jsonData.sesso": function (value) {
      let me = this;
      if (value === "M") {
        me.jsonData.statoGravidanza = "";
      }
    },
  },
  methods: {
    onInputCalcoloEta() {
      let me = this;
      me.jsonData.eta = UtilityMixin.methods.calcoloEtaYearOnly(me.jsonData.dataNascita, new Date(me.jsonData.dataMorte));
    },
    loadProvince() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
      axios.get(link).then((response) => {
        me.provinceNascitaOptions = response.data.data;
        me.provinceNascitaOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
        if (me.jsonData.provinciaNascitaSigla !== "" && me.jsonData.provinciaNascitaSigla !== null) {
          me.loadComuni(me.jsonData.provinciaNascitaSigla);
        }
      });
    },
    notAfter: function (date) {
      return date > Date.now();
    },
    onInputProvinciaNascita(provinciaNascitaSigla) {
      let me = this;
      if (provinciaNascitaSigla !== "") {
        me.jsonData.comuneNascitaCodiceIstat = "";
        me.loadComuni(provinciaNascitaSigla);
      } else {
        me.jsonData.comuneNascitaCodiceIstat = "";
        me.comuniNascitaOptions = [{ codiceIstat: "", comune: "-Seleziona Comune-" }];
      }
    },
    loadComuni(provinciaNascitaSigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + provinciaNascitaSigla;
      axios.get(link).then((response) => {
        me.comuniNascitaOptions = response.data.data;
        me.comuniNascitaOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
      });
    },
  },
};
</script>
