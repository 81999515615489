var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"b-table-sticky-header",staticStyle:{"padding-top":"10px"}},[_c('b-table',{ref:"table",staticClass:"sa-b-table",attrs:{"sticky-header":"","stacked":"xl","striped":"","hover":"","items":_vm.jsonData,"fields":_vm.fields,"current-page":_vm.currentPage,"per-page":_vm.perPage,"sort-icon-left":"","head-variant":"light"},scopedSlots:_vm._u([{key:"cell(farmaco)",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"sa-edit-link",attrs:{"to":'/paziente/propostaterapeutica/view/' + item.id + '?idPaziente=' + _vm.idPaziente}},[_vm._v(_vm._s(item.farmaco))])]}},{key:"cell(descrizioneFrequenza)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getLabelTraduora(item.descrizioneFrequenza))+" ")]}},{key:"cell(posologia)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getLabelTraduora(item.posologia)))])]}},{key:"cell(statoDescrizione)",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getLabelTraduora(item.statoDescrizione)))])]}},{key:"cell(actions)",fn:function(row){return [_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.modalSomministrazione",modifiers:{"modalSomministrazione":true}}],attrs:{"disabled":row.item.statoDescrizione !== 'paziente.terapia.lblATTIVA',"size":"sm","variant":"btn btn-purple waves-effect waves-light width-sm no-text"},on:{"click":function($event){return _vm.onOpenModal(row.item, row.index, $event.target)}}},[_c('b-icon',{attrs:{"icon":"pencil"}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }