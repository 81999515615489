<template>
  <div>
    <b-row>
      <b-col>
        <b-table :items="jsonData.adiSvamaSchedaBTipologiaSintomiBeans" :fields="fields" striped hover responsive sticky-header head-variant="light">
          <template v-slot:cell(nonValutabile)="row">
            <b-form-select v-model="row.item.nonValutabile" :options="valutabilitaOptions" value-field="value" text-field="text" :disabled="editSvamaB"></b-form-select>
          </template>
          <template v-slot:cell(frequenza)="row">
            <b-form-select v-model="row.item.frequenza" :options="frequenzeOptions" :value="null" value-field="value" text-field="text" @input="onInputCalcoloFrequenzaGravita(row.item)" :disabled="rowDisabled(row.item) || editSvamaB"></b-form-select>
          </template>
          <template v-slot:cell(gravita)="row">
            <b-form-select v-model="row.item.gravita" :options="gravitaOptions" :value="null" @input="onInputCalcoloFrequenzaGravita(row.item)" :disabled="rowDisabled(row.item) || editSvamaB"></b-form-select>
          </template>
          <template v-slot:cell(frequenzaGravita)="row">
            <b-form-input v-model="row.item.frequenzaGravita" class="text-upper" disabled></b-form-input>
          </template>
          <template v-slot:cell(stressCargiver)="row">
            <b-form-select v-model="row.item.stressCargiver" :options="stressCargiverOptions" :value="null" :disabled="rowDisabled(row.item) || editSvamaB"></b-form-select>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <!-- <b-row>
            <b-col>
                <label>{{ getLabelTraduora("adi.lblTotalScore") }}</label>
                <b-form-input v-model="jsonData.punteggioTotale" disabled></b-form-input>
            </b-col>
        </b-row> -->
  </div>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  props: {
    editSvamaB: Boolean,
    jsonData: {
      type: Object,
      // return: {
      //     adiSvamaSchedaBTipologiaSintomiBeans: [
      //         { tipologia: "Deliri", nonValutabile: "", frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Allucinazioni", nonValutabile: "", frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Agitazione", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Depressione/disforia", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Ansia", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Euforia/esaltazione", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Apatia/Indifferenza", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Disibinizione", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Irritabilità/Labilità", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Attività motoria aberrante", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Sonno", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //         { tipologia: "Appetito", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //     ],
      // },
    },
  },
  mixins: [UtilityMixin],
  data() {
    return {
      // adiSvamaSchedaBTipologiaSintomiBeans: [
      //     { tipologia: "Deliri", nonValutabile: null, frequenza: 0, gravita: 0, frequenzaGravita: 0, stressCargiver: 0 },
      //     {
      //         tipologia: "Allucinazioni",
      //     },
      // ],
      valutabilitaOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "NO", text: "NO" },
        { value: "SI", text: "SI" },
      ],
      frequenzeOptions: [
        { value: null, text: "-Seleziona la frequenza-" },
        { value: 0, text: "0" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
      ],
      gravitaOptions: [
        { value: null, text: "-Seleziona la gravita'-" },
        { value: 0, text: "0" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
      ],
      stressCargiverOptions: [
        { value: null, text: "-Seleziona stress del caregiver-" },
        { value: 0, text: "0" },
        { value: 1, text: "1" },
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
      ],
      fields: [
        {
          label: "Tipologia Sintomo",
          key: "tipologia",
        },
        {
          label: "Non Valutabile",
          key: "nonValutabile",
        },
        {
          label: "Frequenza",
          key: "frequenza",
        },
        {
          label: "Gravita'",
          key: "gravita",
        },
        {
          label: "Frequenza x Gravita'",
          key: "frequenzaGravita",
        },
        {
          label: "Stress del caregiver",
          key: "stressCargiver",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    onInputCalcoloFrequenzaGravita(row) {
      row.frequenzaGravita = row.frequenza * row.gravita;
      if (row.frequenza === null && row.gravita === null) {
        row.frequenzaGravita = null;
      }
    },
    rowDisabled(value) {
      let returnValue = false;
      if (value.nonValutabile === "NO") {
        value.frequenza = null;
        value.gravita = null;
        value.frequenzaGravita = null;
        value.stressCargiver = null;
        returnValue = true;
      }

      return returnValue;
    },
  },
};
</script>
