<template>
    <GmapMap :center="{ lat: 40.8738669, lng: 14.2253539 }" :zoom="12" map-type-id="terrain" style="height: 100%" :options="getOptions()">
        <gmap-info-window content="This is my info window content" :options="infoOptions" :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen = false" />
        <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position" :clickable="true" :draggable="false" @click="onGmapMarkerClick(m, index)" :icon="getMarkerIcon(m, index)" />
    </GmapMap>
</template>

<script>
import axios from "axios";
import googlePointA from "../../../assets/images/icongoogle/google_point_a.png";
import googlePointS from "../../../assets/images/icongoogle/google_point_s.png";
import googlePointG from "../../../assets/images/icongoogle/google_point_g.png";
import googlePointGT from "../../../assets/images/icongoogle/google_point_gt.png";
import googlePointR from "../../../assets/images/icongoogle/google_point_r.png";
import googlePointV from "../../../assets/images/icongoogle/google_point_v.png";
export default {
    components: {},
    data() {
        return {
            pathResource: "/schedavalutazioneesigenzeimmediate",
            infoWinOpen: false,
            infoWindowPos: null,
            infoOptions: {
                content: "",
                //optional: offset infowindow so it visually sits nicely on top of our marker
                pixelOffset: {
                    width: 0,
                    height: -35,
                },
            },
            markers: [
                {
                    position: { lat: 40.8738669, lng: 14.2253539 },
                    codiceValutazione: "ASL",
                    assistitoCognome: "A.S.L. Napoli 1 Centro",
                    assistitoNome: "",
                },
                // {
                //     position: { lat: 40.8769669, lng: 14.2253539 },
                //     codiceValutazione: "R",
                //     assistitoNome: "Nome",
                //     assistitoCognome: "Cognome",
                //     assistitoDataNascita: 946681200000,
                // },
                // {
                //     position: { lat: 40.8779669, lng: 14.2353539 },
                //     codiceValutazione: "G",
                // },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        getOptions() {
            return {
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                clickableIcons: false,
                styles: [
                    {
                        featureType: "poi",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "road",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "landscape.natural",
                        elementType: "labels",
                        stylers: [{ visibility: "off" }],
                    },
                    {
                        featureType: "transit",
                        elementType: "labels.icon",
                        stylers: [{ visibility: "off" }],
                    },
                ],
            };
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.markers = [];
                    me.markers.push({
                        position: { lat: 40.8738669, lng: 14.2253539 },
                        codiceValutazione: "A",
                        assistitoCognome: "A.S.L. Napoli 1 Centro",
                        assistitoNome: "",
                    });
                    me.markers.push({
                        position: { lat: 40.8668784, lng: 14.224242 },
                        codiceValutazione: "S",
                        assistitoCognome: "AORN Antonio Cardarelli Chirurgia ",
                        assistitoNome: "",
                    });
                    me.markers.push({
                        position: { lat: 40.8292138, lng: 14.1861202 },
                        codiceValutazione: "S",
                        assistitoCognome: "Presidio Ospedaliero San Paolo",
                        assistitoNome: "",
                    });
                    response.data.data.list.forEach((element) => {
                        let lat = parseFloat(element.latitudine);
                        let lng = parseFloat(element.longitudine);
                        if (lat && lng) {
                            me.markers.push({
                                position: { lat: lat, lng: lng },
                                codiceValutazione: element.codiceValutazione,
                                assistitoCognome: element.assistitoCognome,
                                assistitoNome: element.assistitoNome,
                            });
                        }
                    });
                    me.markers.push({
                        position: { lat: 40.8769669, lng: 14.2253539 },
                        codiceValutazione: "R",
                        assistitoNome: "Nome",
                        assistitoCognome: "Cognome",
                        assistitoDataNascita: 946681200000,
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        getMarkerIcon(element) {
            let returnValue = null;
            switch (element.codiceValutazione) {
                case "A":
                    returnValue = googlePointA;
                    break;
                case "S":
                    returnValue = googlePointS;
                    break;
                case "R":
                    returnValue = googlePointR;
                    break;
                case "G":
                    returnValue = googlePointG;
                    break;
                case "GT":
                    returnValue = googlePointGT;
                    break;
                case "V":
                    returnValue = googlePointV;
                    break;
            }
            return returnValue; //"http://maps.google.com/mapfiles/ms/icons/yellow-dot.png";
        },
        onGmapMarkerClick(marker, idx) {
            // console.log(idx);
            this.infoWindowPos = marker.position;
            this.infoOptions.content = marker.assistitoCognome + " " + marker.assistitoNome; //marker.infoText;

            if (this.currentMidx == idx) {
                this.infoWinOpen = !this.infoWinOpen;
            } else {
                this.infoWinOpen = true;
                this.currentMidx = idx;
            }
        },
    },
};
</script>

<style>
.gm-style-iw-d {
    color: black;
    font-weight: bold;
}
</style>
