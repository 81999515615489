<template>
  <b-card class="sa-card" header="Attività" header-tag="header" footer-tag="footer" title="">
    <template #header>
      <h5 class="mt-0">
        <span>
          <i class="fas fa-comment-medical"></i>
          {{ titolo }}
        </span>
      </h5>
    </template>
    <!-- <h5 class="mt-0">
            <span class="text-dark">
                <i class="fas fa-comment-medical"></i>
                {{ this.$i18n.t("patient.activities.lblActivities") }}
            </span>
        </h5> -->
  </b-card>
</template>
<script>
export default {
  data() {
    return {};
  },
  computed: {
    titolo() {
      return "Attività";
    },
  },
  mounted() {
    // let me = this;
    // me.id = this.$route.params.id;
  },
  methods: {},
};
</script>
