<template>
    <p>Componente delle stampaReport</p>
</template>

<script>
export default {
    props: {
        accettazione: {
            type: Object,
        },
    },
    mounted() {
        let me = this;
        console.log(me.accettazione);
    },
};
</script>

<style></style>
