<template>
  <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="toolbar">
      <b-button class="float-sm-right" variant="outline-success btn-toolbar sa-margin-right" size="sm" v-b-modal.modalAssociaUtente>
        <b-icon icon="people-fill"></b-icon>
        Associa Utente
      </b-button>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="6" md="6" lg="4" xl="4">
              <label class="sa-label-data">Descrizione</label>
              <span class="sa-data">{{ jsonData.nome }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Note</label>
              <span class="sa-data">{{ jsonData.note }}</span>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Lista Messaggi" header-tag="header" footer-tag="footer" title="">
          <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaTestoMail" :fields="fieldsTestoMail" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table"> </b-table>
          </div>
        </b-card>
        <b-card class="sa-card" header="Utenti Associati" header-tag="header" footer-tag="footer" title="">
          <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData.listaDestinatari" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
              <template #cell(actions)="row">
                <b-button variant="outline-danger" @click="onDeleteAssociazione(row.item)">
                  <b-icon icon="person-x-fill" aria-hidden="true"></b-icon>
                  Dissocia
                </b-button>
              </template>
            </b-table>
          </div>
        </b-card>
      </div>

      <b-modal size="lg" ref="modalAssocia" id="modalAssociaUtente" title="Associa Utente" @show="onShow()" scrollable>
        <b-form @submit.prevent="onSubmitUsers">
          <b-row>
            <b-col xs="6" sm="6" md="6" lg="6" xl="6">
              <b-form-input v-model="mdlFiltroUsers.nome" type="search" placeholder="Nome"></b-form-input>
            </b-col>
            <b-col xs="6" sm="6" md="6" lg="6" xl="6">
              <b-form-input v-model="mdlFiltroUsers.cognome" type="search" placeholder="Cognome"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="sa-padding-right text-right">
              <b-button type="submit" variant="info">Cerca</b-button>
            </b-col>
          </b-row>
        </b-form>
        <div class="b-table-sticky-header">
          <b-table sticky-header :busy="isBusy" ref="table" selectable stacked="lg" striped hover :items="itemsUtenti" :fields="fieldsModal" sort-icon-left head-variant="light" class="sa-b-table" current-page="1" :per-page="perPageUsers" @row-selected="onRowSelected">
            <template #table-busy>
              <div class="text-center text-info my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Caricamento...</strong>
              </div>
            </template>
            <template #cell(selected)="{ rowSelected }">
              <template v-if="rowSelected">
                <span aria-hidden="true">&check;</span>
                <span class="sr-only">Selected</span>
              </template>
              <template v-else>
                <span aria-hidden="true">&nbsp;</span>
                <span class="sr-only">Not selected</span>
              </template>
            </template>
            <template #cell(firstname)="row">
              <p>{{ row.item.lastname }} {{ row.item.firstname }}</p>
            </template>
          </b-table>
        </div>

        <template #modal-footer="{ ok }">
          <b-button size="sm" variant="outline-danger" @click="ok()">
            <b-icon icon="x"></b-icon>
            Annulla
          </b-button>
          <b-button size="sm" variant="outline-success" @click="onAssocia()">
            <b-icon icon="person-check-fill"></b-icon>
            Associa
          </b-button>
        </template>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
//import moment from "moment";
//import { SaPageViewEdit } from "../../../template/sa/components/layout/contents";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  components: { SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  mixins: [UtilityMixin],
  data() {
    return {
      isBusy: false,
      linkback: null,
      tabIndex: 0,
      showModalLoading: false,
      perPage: 10,
      perPageUsers: 100000,
      currentPage: 1,
      currentPageUsers: 1,
      filter: null,
      pathResource: "/emailnotificationreceivergroups",
      pathRest: "/emailnotificationgroups",
      pathResourceUtenti: "/emailnotificationreceivers",
      // pathResourceGruppiUtenti: "/gruppi",
      linkedit: null,
      mdlFiltroUsers: {
        nome: "",
        cognome: "",
      },
      selected: [],
      profiliModalOptions: [],
      filterOn: [],
      jsonData: {},
      items: [],
      itemsUtenti: [],
      filtroUsers: { forPage: 0, page: 0, user: "", profilo: "" },
      fields: [
        // {
        //   label: "",
        //   key: "index",
        //   sortable: false,
        //   thStyle: "width: 5rem",
        // },
        {
          label: "Email",
          key: "email",
          sortable: true,
        },
        {
          label: "Nome",
          key: "nome",
          sortable: true,
        },
        {
          label: "Cognome",
          key: "cognome",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          tdClass: "text-right",
          thStyle: "width: 15rem",
        },
      ],
      fieldsTestoMail: [
        {
          label: "Modulo",
          key: "modulo",
          sortable: true,
          thStyle: "width: 12rem",
        },
        {
          label: "Azione",
          key: "action",
          sortable: true,
          thStyle: "width: 12rem",
        },
        {
          label: "Oggetto",
          key: "subject",
          sortable: true,
          thStyle: "width: 20rem",
        },
        {
          label: "Testo Mail",
          key: "testoMail",
          sortable: true,
          // thStyle: "width: 12rem",
        },
      ],
      fieldsModal: [
        {
          label: "",
          key: "selected",
          thStyle: "width: 2.5rem",
          tdClass: "text-center",
        },
        {
          label: "Nome",
          key: "nome",
          sortable: true,
          thStyle: "width: 8rem",
        },
        {
          label: "Cognome",
          key: "cognome",
          sortable: true,
          thStyle: "width: 8rem",
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
          thStyle: "width: 8rem",
        },
      ],
    };
  },
  created: function () {},
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/emailnotificationgroups/edit/" + me.id;
    me.linkback = "/emailnotificationgroups";
    me.loadData();
    // me.loadDefaultData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
      axios.get(link + me.id).then((response) => {
        me.jsonData = response.data.data;
        me.showModalLoading = false;
        me.loadDataTableAssociati();
      });
    },
    loadDataTableAssociati() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios.get(link, { params: { idGruppo: me.jsonData.id } }).then((response) => {
        me.showModalLoading = false;
        me.items = response.data.data.list;
      });
    },
    loadDataUtenze() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceUtenti;
      me.showModalLoading = true;
      // me.filtroUsers.forPage = me.perPageUsers;
      // me.filtroUsers.page = me.currentPageUsers;
      me.filtroUsers.idGruppoUtenti = me.jsonData.id;
      me.filtroUsers.nome = me.mdlFiltroUsers.nome;
      me.filtroUsers.cognome = me.mdlFiltroUsers.cognome;
      axios.get(link, { params: me.filtroUsers }).then((response) => {
        me.isBusy = false;
        me.showModalLoading = false;
        me.itemsUtenti = response.data.data.list;
      });
    },
    loadDefaultData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceGruppiUtenti;
      axios
        .get(link)
        .then((response) => {
          response.data.data.forEach((element) => {
            me.profiliModalOptions.push({ text: element.nome, value: element.nome });
          });
        })
        .finally(() => {
          me.profiliModalOptions.unshift({ text: this.getLabelTraduora("patient.ecoreferting.lblSelected"), value: "" });
        });
    },

    onRowSelected(items) {
      this.selected = items;
    },
    onAssocia() {
      let me = this;
      for (let i = 0; i < me.selected.length; i++) {
        let obj = { id: null, idGruppo: me.jsonData.id, idEmailNotificationReceiver: me.selected[i].id };
        me.associaUtente(obj);
      }
    },
    associaUtente(obj) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .post(link, obj)
        .then((response) => {
          me.$refs["modalAssocia"].hide();
          response.data.data;
          me.showModalLoading = false;
          me.loadData();
          //   console.log(me.jsonData);
          this.$bvToast.toast(`Utente associato`, {
            title: this.getLabelTraduora("ambulatory.toastAssociateUserSuccess"),
            solid: true,
            variant: "success",
            autoHideDelay: 1000,
            appendToast: true,
          });
        })
        .catch((e) => {
          console.log(e);
          me.showModalLoading = false;
        });
      //   console.log(obj);
    },
    ok() {},
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onDeleteAssociazione(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm(this.getLabelTraduora("ambulatory.modelMessageConfirmDeleteAssociate"), {
          title: this.getLabelTraduora("ambulatory.modelMessageDeleteAssociate"),
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteAssociazione(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteAssociazione(item) {
      let me = this;
      console.log(item);
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .delete(link + item.idAssociazione)
        .then(() => {
          //   console.log(response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((error) => {
          console.log(error);
          me.showModalLoading = false;
        });
    },
    onShow() {
      let me = this;
      me.loadDataUtenze();
    },
    onResetFiltro() {
      let me = this;
      me.filtroModal.profilo = "";
      me.filtroModal.username = "";
      me.loadDataUtenze();
    },
    onFiltra() {
      let me = this;
      me.loadDataUtenze();
    },
    onSubmitUsers() {
      let me = this;
      me.loadDataUtenze();
    },
  },
};
</script>
