<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <h4>Questionario Situazione Cognitiva</h4>
          <adi-questionario-situazione-cognitiva-component :editSvamaB="editSvamaB" :jsonData="jsonData"></adi-questionario-situazione-cognitiva-component>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <h4>Neuropsychiatric Inventory'</h4>
          <adi-neuropsychiatric-inventory-component :editSvamaB="editSvamaB" :jsonData="jsonData"></adi-neuropsychiatric-inventory-component>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <h4>Situazione Funzionale Indice Barthel</h4>
          <adi-situazione-funzionale-indice-barthel-component :editSvamaB="editSvamaB" :jsonData="jsonData"></adi-situazione-funzionale-indice-barthel-component>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <h4>Mobilita Indice Barthel</h4>
          <adi-mobilita-indice-barthel-component :editSvamaB="editSvamaB" :jsonData="jsonData"></adi-mobilita-indice-barthel-component>
        </b-col>
      </b-row>
      <!-- <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <header header-tag="header" class="p-1" role="tab">
                    <h4 v-b-toggle.prevenzioneTrattamentoDecubiti>Prevenzione - Trattamento Decubiti</h4>
                </header>
                <b-collapse id="prevenzioneTrattamentoDecubiti" visible>
                    <prevenzione-trattamento-decubiti></prevenzione-trattamento-decubiti>
                </b-collapse>
            </b-col>
        </b-row> -->
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import AdiQuestionarioSituazioneCognitivaComponent from "./AdiQuestionarioSituazioneCognitivaComponent.vue";
import AdiNeuropsychiatricInventoryComponent from "./AdiNeuropsychiatricInventoryComponent.vue";
import AdiSituazioneFunzionaleIndiceBarthelComponent from "./AdiSituazioneFunzionaleIndiceBarthelComponent.vue";
import AdiMobilitaIndiceBarthelComponent from "./AdiMobilitaIndiceBarthelComponent.vue";
// import PrevenzioneTrattamentoDecubiti from "./PrevenzioneTrattamentoDecubiti.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { AdiQuestionarioSituazioneCognitivaComponent, AdiNeuropsychiatricInventoryComponent, AdiSituazioneFunzionaleIndiceBarthelComponent, AdiMobilitaIndiceBarthelComponent }, //PrevenzioneTrattamentoDecubiti
  data() {
    return {
      pathResource: "/adisvamaschedeb",
      linkback: null,
      editSvamaB: false,
      showModalLoading: false,
      jsonData: {
        totaleValutazioneCognitiva: null,
        adiSvamaSchedaBSituazioneCognitivaBeans: [],
        questionarioNonSomministrabile: false,
        motivoTestNonSomministrabile: "",
        provvedimentiProblemiComportamentali: "",
        provvedimentiPazientiPsicogeriatrici: "",
        alimentazione: 0,
        bagnoDoccia: 0,
        igienePersonale: 0,
        abbigliamento: 0,
        continenzaIntestinale: 0,
        continenzaUrinaria: 0,
        usoGabinetto: 0,
        punteggioTotaleSituazioneFunzionale: 0,
        adiSvamaSchedaBTipologiaSintomiBeans: [
          { tipologia: "Deliri", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Allucinazioni", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Agitazione", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Depressione/disforia", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Ansia", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Euforia/esaltazione", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Apatia/Indifferenza", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Disibinizione", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Irritabilità/Labilità", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Attività motoria aberrante", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Sonno", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
          { tipologia: "Appetito", nonValutabile: "", frequenza: null, gravita: null, frequenzaGravita: "", stressCargiver: null },
        ],
        trasferimentoLetto: null,
        deambulazione: 0,
        usoCarrozzina: 0,
        scale: 0,
        punteggioTotaleValutazioneMobilita: null,
        rischioDecubiti: null,
        presenzaDecubiti: 0,
        condizioniGenerali: 0,
        statoMentale: 0,
        attivita: 0,
        mobilita: 0,
        incontinenza: 0,
        totaleRiscioDecubiti: 0,
        note: "",
      },
      // questionariOptions: [
      //     { value: null, text: "--Seleziona Questionario--", disabled: true },
      //     { value: "questionario situazione cognitiva", text: "Questionario sulla Situazione Cognitiva" },
      //     { value: "neuropsychiatric inventory", text: "Neuropsychiatric Inventory (NPI)" },
      //     { value: "situazione funzionale indice barthel", text: "Situazione Funzionale (Indice di Barthel)" },
      //     { value: "mobilita indice barthel", text: "Mobilita' (Indice di Barthel)" },
      //     { value: "prevenzione - trattamento decubiti", text: "Prevenzione - Trattamento Decubiti" },
      // ],
    };
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.id = this.$route.params.id;
    me.loadData();
    me.impostaPermessi();
  },
  methods: {
    impostaPermessi() {
      let me = this;
      let fonte = this.$route.query.fonte;
      me.editSvamaB = true;
      if (fonte === null || fonte === undefined || fonte === "undefined" || fonte === "") {
        fonte = "/richieste";
      }
      if (fonte === "/richieste") {
        me.editSvamaB = !UtilityMixin.methods.verificaPermessi(me.utente, "adisvamaschedeb", 2);
      }
    },
    loadData() {
      let me = this;
      if (me.id !== -1) {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        me.showmolalloading = true;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showmolalloading = false;
          })
          .catch(() => {
            me.showmolalloading = false;
          });
      }
      me.$emit("update", me.jsonData);
    },
  },
};
</script>
