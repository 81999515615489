<template>
  <sa-page-layout class="sa-no-space" :showModalLoading="showModalLoading" :btnEditVisible="true" :linkedit="linkEdit" :linkback="linkback" :btnBackVisible="true">
    <template slot="toolbar">
      <print-component :data="jsonData" :linkPrintData="getLinkPrintData(jsonData)" typeButton="normal" nomeCampo="Stampa Giudizio Finale"></print-component>
      <b-button v-if="tabIndex === 6 && jsonData.id != '-1'" variant="outline-success btn-toolbar sa-margin-right float-sm-right" v-b-modal.mdlAllegati><b-icon icon="file-earmark-plus" aria-hidden="true"></b-icon>{{ getLabelTraduora("patient.afa.btnNewAllegato") }} </b-button>
      <b-button v-if="tabIndex === 6 && jsonData.id != '-1'" class="float-sm-right primary" variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" />{{ getLabelTraduora("global.lblBtnRefresh") }}</b-button>
    </template>
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h3 class="sa-event-title">{{ getLabelTraduora("patient.afa.lblEligibility") }}</h3>
    </template>
    <template slot="table-body">
      <b-tabs v-model="tabIndex" content-class="mt-3" nav-wrapper-class="sticky-top" class="sa-tab">
        <b-tab>
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblSportsMedicalEvaluationForm") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <idoneita-scheda-valutazione-medico-sportiva-view-component :gruppo="gruppo" :tipoEvento="tipoEvento" :jsonData="jsonData"></idoneita-scheda-valutazione-medico-sportiva-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblAnamnesis") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <idoneita-anamnesi-view-component :jsonData="jsonData"></idoneita-anamnesi-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="getColorByFirma(jsonData.firmaEo)">
              <div style="float: left">
                <b-icon :icon="getIconByFirma(jsonData.firmaEo)"></b-icon>
              </div>
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <idoneita-esame-obiettivo-view-component :gruppo="gruppo" :tipoEvento="tipoEvento" :jsonData="jsonData"></idoneita-esame-obiettivo-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="getColorByFirma(jsonData.firmaEo)">
              <div style="float: left">
                <b-icon :icon="getIconByFirma(jsonData.firmaEo)"></b-icon>
              </div>
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <idoneita-elettrocardiogramma-view-component :gruppo="gruppo" :tipoEvento="tipoEvento" :jsonData="jsonData"></idoneita-elettrocardiogramma-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblUrines") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <idoneita-urine-view-component :gruppo="gruppo" :tipoEvento="tipoEvento" :jsonData="jsonData"></idoneita-urine-view-component>
          </div>
        </b-tab>
        <b-tab :edit="edit">
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblSpirography") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <idoneita-spirografia-view-component :gruppo="gruppo" :tipoEvento="tipoEvento" :jsonData="jsonData"></idoneita-spirografia-view-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblOtherExams") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <idoneita-altri-esami ref="altriEsami" @onRefresh="onRefresh" :data="jsonData" :edit="edit"></idoneita-altri-esami>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="sa-tab-title-normal">
              <div style="float: left">
                <span>Terapia</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <paziente-terapia-list-component :isEdit="false"></paziente-terapia-list-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <div :class="getColorByFirma(jsonData.firmaGf)">
              <div style="float: left">
                <b-icon :icon="getIconByFirma(jsonData.firmaGf)"></b-icon>
              </div>
              <div style="float: left">
                <span>{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}</span>
              </div>
            </div>
          </template>
          <div class="sa-tab-scroll">
            <idoneita-gudizio-finale-view-component :jsonData="jsonData"></idoneita-gudizio-finale-view-component>
          </div>
        </b-tab>
      </b-tabs>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import IdoneitaAltriEsami from "./IdoneitaAltriEsami.vue";
import IdoneitaSchedaValutazioneMedicoSportivaViewComponent from "./IdoneitaSchedaValutazioneMedicoSportivaViewComponent.vue";
import IdoneitaAnamnesiViewComponent from "./IdoneitaAnamnesiViewComponent.vue";
import IdoneitaEsameObiettivoViewComponent from "./IdoneitaEsameObiettivoViewComponent.vue";
import IdoneitaElettrocardiogrammaViewComponent from "./IdoneitaElettrocardiogrammaViewComponent.vue";
import IdoneitaUrineViewComponent from "./IdoneitaUrineViewComponent.vue";
import IdoneitaSpirografiaViewComponent from "./IdoneitaSpirografiaViewComponent.vue";
import IdoneitaGudizioFinaleViewComponent from "./IdoneitaGiudizioFinaleViewComponent.vue";
import PazienteTerapiaListComponent from "../../../../terapia/components/PazienteTerapiaListComponent.vue";
import EventiMixins from "../../../utility/mixins/EventiMixins";
import PrintComponent from "../../../../../../app/utility/components/PrintComponent.vue";
export default {
  mixins: [UtilityMixin, EventiMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: {
    SaPageLayout,
    PazienteAnagrafica,
    IdoneitaAltriEsami,
    IdoneitaSchedaValutazioneMedicoSportivaViewComponent,
    IdoneitaAnamnesiViewComponent,
    IdoneitaEsameObiettivoViewComponent,
    IdoneitaElettrocardiogrammaViewComponent,
    IdoneitaUrineViewComponent,
    IdoneitaSpirografiaViewComponent,
    IdoneitaGudizioFinaleViewComponent,
    PazienteTerapiaListComponent,
    PrintComponent,
  },
  data() {
    return {
      id: null,
      tipoEvento: "IDONEITA",
      gruppo: "MEDICINADELLOSPORT",
      jsonData: { idPaziente: null, dataCaricamento: new Date().getTime() },
      pathRest: "/medicinadellosportidoneita",
      linkEdit: null,
      tabIndex: null,
      linkback: null,
      edit: false,
      showModalLoading: false,
    };
  },
  computed: {
    idPaziente: {
      get: function () {
        return this.jsonData.idPaziente;
      },
      set: function (newValue) {
        this.jsonData.idPaziente = newValue;
        this.linkEdit = "/paziente/medicinadellosportidoneita/edit/" + this.id + "?idPaziente=" + newValue;
        this.linkback = "/paziente/medicinadellosportidoneita?idPaziente=" + newValue;
      },
    },
    linkPatient() {
      return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  created: function () {},
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkback = "/paziente/medicinadellosportidoneita?idPaziente=" + me.idPaziente;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.idPaziente = me.jsonData.idPaziente;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onRefresh() {
      let me = this;
      me.$refs["altriEsami"].$refs["allegatiListC"].loadData();
    },
    getColorEsameObiettivo() {
      let me = this;
      let colore = me.jsonData.firmaEo != null ? "text-success" : "text-danger";
      return colore;
    },
    getColorElettrocardiogramma() {
      let me = this;
      let colore = me.jsonData.firmaEcg != null ? "text-success" : "text-danger";
      return colore;
    },
    getColorGiudizioFinale() {
      let me = this;
      let colore = me.jsonData.firmaGf != null ? "text-success" : "text-danger";
      return colore;
    },

    getIconEsameObiettivo() {
      let me = this;
      let icon = me.jsonData.firmaEo != null ? "check2" : "exclamation-triangle";
      return icon;
    },
    getIconElettrocardiogramma() {
      let me = this;
      let icon = me.jsonData.firmaEcg != null ? "check2" : "exclamation-triangle";
      return icon;
    },
    getIconGiudizioFinale() {
      let me = this;
      let icon = me.jsonData.firmaGf != null ? "check2" : "exclamation-triangle";
      return icon;
    },
    getLinkPrintData(item) {
      let returnValue = "/medicinadellosportprint/" + item.id;
      return returnValue;
      // console.log("Print" + item.id);
    },
  },
};
</script>
