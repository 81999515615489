import CaduceoLapManagementRegistroInvii from "./pages/RegistroInvii.vue";
import CaduceoLapManagementLapMediciList from "./pages/LapMediciList.vue";
import CaduceoLapManagementLapMediciView from "./pages/LapMediciView.vue";
import CaduceoLapManagementIndicatoriView from "./pages/IndicatoriView.vue";
import CaduceoLapManagementMediciList from "./pages/CaduceoLapManagementMediciList.vue";
import CaduceoLapManagementMedicoView from "./pages/CaduceoLapManagementMedicoView.vue";
import CaduceoLapManagementMedicoEdit from "./pages/CaduceoLapManagementMedicoEdit.vue";

export default {
    CaduceoLapManagementRegistroInvii,
    CaduceoLapManagementLapMediciList,
    CaduceoLapManagementLapMediciView,
    CaduceoLapManagementIndicatoriView,
    CaduceoLapManagementMediciList,
    CaduceoLapManagementMedicoView,
    CaduceoLapManagementMedicoEdit,
};
