import axios from "axios";
export default {
    namespaced: true,
    state: {
        tablesInfo: [],
    },
    getters: {
        getTablesInfo: (state) => state.tablesInfo,
    },
    actions: {
        async init({ commit }) {
            try {
                let link = process.env.VUE_APP_PATH_API + "/dataanalysistables";
                await axios.get(link).then((response) => {
                    let tablesInfo = [];
                    response.data.data.list.forEach((element) => {
                        element.tableFields = [];
                        tablesInfo.push(element);
                    });
                    commit("setTablesInfo", tablesInfo);
                });
            } catch (error) {
                alert(error);
                console.log(error);
            }
        },
        async setTableFields({ commit }, data) {
            let link = process.env.VUE_APP_PATH_API + "/dataanalysisdatabaseinfo/tableinfo" + "?idDataSource=1&tableName=" + data.tableName;
            await axios.get(link).then((response) => {
                commit("setTableFields", { tableName: data.tableName, tableFields: response.data.data });
            });
        },
    },
    mutations: {
        setTablesInfo(state, tablesInfo) {
            state.tablesInfo = tablesInfo;
        },
        setTableFields(state, data) {
            state.tablesInfo.forEach((element) => {
                if (element.dbName === data.tableName) {
                    element.tableFields = data.tableFields;
                }
            });
        },
    },
};

// export default DataanalysisTablesStore;

// export default DataanalysisTablesStore;
// export default DataanalysisTablesStore({
//     state() {
//         return {
//             count: 0,
//         };
//     },
//     getters: {},
//     mutations: {
//         increment(state) {
//             state.count++;
//         },
//     },
//     actions: {},
// });

// import { createStore } from "vuex";

// // Create a new store instance.
// const store = createStore({
//     state() {
//         return {
//             count: 0,
//         };
//     },
//     mutations: {
//         increment(state) {
//             state.count++;
//         },
//     },
// });
