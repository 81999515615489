<template>
  <div class="sa-list-component">
    <div class="sa-list-component-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Gestore:</label>
            <b-form-select v-model="filtro.gestore" :options="gestoreOptions" value-field="value" text-field="text" @change="onChangeGestore"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Tipo Firma:</label>
            <b-form-select v-model="filtro.tipoFirma" :options="tipoFirmaOptions" :value="''" value-field="value" text-field="text" :disabled="tipoFirmaDisable"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice Struttura:</label>
            <b-form-input v-model="filtro.codiceStruttura"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice Unita Operativa:</label>
            <b-form-input v-model="filtro.codiceUnitaOperativa"></b-form-input>
          </b-col>
          <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">Codice Dispositivo:</label>
            <b-form-input v-model="filtro.dispositivo"></b-form-input>
          </b-col> -->
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">Cerca</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">Reset</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="sa-list-component-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(dispositivo)="{ item }">
            <router-link class="sa-edit-link" :to="'/graffididevice/edit/' + item.id">{{ item.descrizioneDispositivo }}</router-link>
            <span class="sa-label-data-secondary">{{ item.codiceDispositivo }}</span>
          </template>
          <template v-slot:cell(gestore)="{ item }">
            {{ item.gestore }}
            <span class="sa-label-data-secondary">{{ item.tipoFirma }}</span>
          </template>
          <template v-slot:cell(struttura)="{ item }">
            <span>{{ item.descrizioneStruttura }} </span>
            <span v-if="item.codiceUnitaOperativa !== null && item.codiceUnitaOperativa !== ''">({{ item.codiceStruttura }})</span>
            <span class="sa-label-data-secondary">
              <span>{{ item.descrizioneUnitaOperativa }} </span>
              <span v-if="item.codiceUnitaOperativa !== null && item.codiceUnitaOperativa !== ''">({{ item.codiceUnitaOperativa }})</span>
            </span>
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      pathResource: "/digitalsigndevice",
      filtro: { gestore: "", tipoFirma: "", codiceStruttura: "", codiceUnitaOperativa: "", dispositivo: "" },
      items: [],
      listKey: 0,
      fields: [
        {
          label: "Dispositivo",
          key: "dispositivo",
          sortable: true,
        },
        {
          label: "Gestore",
          key: "gestore",
          sortable: true,
        },
        {
          label: "Struttura",
          key: "struttura",
          sortable: true,
        },
      ],
      tipoFirmaDisable: true,
      gestoreOptions: [
        { text: "-Selezionare il Gestore-", value: "" },
        { text: "KEFIRMA", value: "KEFIRMA" },
        { text: "ARUBA", value: "ARUBA" },
        { text: "INFOCERT", value: "INFOCERT" },
      ],
      infocertOptions: [
        { text: "-Selezionare la Firma-", value: "" },
        { text: "REMOTA", value: "REMOTA" },
        { text: "AUTOMATICA", value: "AUTOMATICA" },
      ],
      arubaOptions: [
        { text: "-Selezionare la Firma-", value: "" },
        { text: "REMOTA", value: "REMOTA" },
        { text: "AUTOMATICA", value: "AUTOMATICA" },
      ],
      kefirmaOptions: [{ text: "GRAFOMETRICA", value: "GRAFOMETRICA" }],
      tipoFirmaOptions: [{ text: "-Selezionare la Firma-", value: "" }],
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
      if (me.filtro.gestore === undefined) {
        // me.filtro = { gestore: "", tipoFirma: "", codiceStruttura: "", codiceUnitaOperativa: "", dispositivo: "" };
      }
    }
    // me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.listKey++;
          me.changeGestore();
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      me.tipoFirmaOptions = { text: "-Selezionare la Firma-", value: "" };
      me.tipoFirmaDisable = true;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onChangeGestore() {
      let me = this;
      me.filtro.tipoFirma = "";
      me.changeGestore();
    },
    changeGestore() {
      let me = this;
      me.tipoFirmaDisable = false;
      me.tipoFirmaOptions = [];
      switch (me.filtro.gestore) {
        case "ARUBA":
          me.tipoFirmaOptions = me.arubaOptions;
          break;
        case "INFOCERT":
          me.tipoFirmaOptions = me.infocertOptions;
          break;
        case "KEFIRMA":
          me.tipoFirmaOptions = me.kefirmaOptions;
          break;
      }
      if (me.tipoFirmaOptions.length === 1) {
        me.tipoFirmaDisable = true;
        me.filtro.tipoFirma = me.tipoFirmaOptions[0].value;
      }
    },
  },
};
</script>

<style></style>
