<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"> </date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblHeartAttack')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
          <b-form-select v-model="jsonData.tipo" :options="optionsTipo">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
          <b-form-select v-model="jsonData.sede" :options="optionsSede">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblClinicPresentation") }}</label>
          <b-form-select v-model="jsonData.presentazioneClinica" :options="optionsPresentazione">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPainPattern") }}</label>
          <b-form-select v-model="jsonData.patternDelDolore" :options="optionsDolore">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStartOfSymptonsDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraInizioSintomi" type="datetime"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStartOfTherapyDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataOraInizioTerapia" type="datetime"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRiperfusionTherapy") }}</label>
          <b-form-select v-model="jsonData.terapiaDiRiperfusione" :options="optionsTerapia">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPrecoronaryTime") }}</label>
          <b-form-input v-model="jsonData.tempoPrecoronarico" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblKillipClass") }}</label>
          <b-form-select v-model="jsonData.classeKillip" :options="optionsKillip">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCPK") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <b-form-checkbox v-model="jsonData.checkCpk" switch :checked="jsonData.checkCpk"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <b-form-input v-model="jsonData.piccoCpk" type="number" :placeholder="getLabelTraduora('patient.cardiology.lblPeak')" :readonly="!jsonData.checkCpk"> </b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCKMBMass") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <b-form-checkbox v-model="jsonData.checkCkMbMassa" switch :checked="jsonData.checkCkMbMassa"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <b-form-input v-model="jsonData.piccoCkMbMassa" type="number" :placeholder="getLabelTraduora('patient.cardiology.lblPeak')" :readonly="!jsonData.checkCkMbMassa"> </b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTroponineTWithMeasure") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <b-form-checkbox v-model="jsonData.checkTroponinaT" switch :checked="jsonData.checkTroponinaT"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-input v-model="jsonData.piccoTroponinaT" type="number" :placeholder="getLabelTraduora('patient.cardiology.lblPeak')" :readonly="!jsonData.checkTroponinaT"> </b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMioglobine") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-checkbox v-model="jsonData.checkMioglobina" switch :checked="jsonData.checkMioglobina"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
              <b-form-input v-model="jsonData.piccoMioglobina" type="number" :placeholder="getLabelTraduora('patient.cardiology.lblPeak')" :readonly="!jsonData.checkMioglobina"> </b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCKMBActivity") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <b-form-checkbox v-model="jsonData.checkCkMbAttivita" switch :checked="jsonData.checkCkMbAttivita"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <b-form-input v-model="jsonData.piccoCkMbAttivita" type="number" :placeholder="getLabelTraduora('patient.cardiology.lblPeak')" :readonly="!jsonData.checkCkMbAttivita"> </b-form-input>
            </b-col>
          </b-row>
        </b-col>
        <b-col
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTroponineIWithMeasure") }}</label>
          <b-row style="margin-top: -5px">
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <b-form-checkbox v-model="jsonData.checkTroponinaI" switch :checked="jsonData.checkTroponinaI"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="6" xl="6">
              <b-form-input v-model="jsonData.piccoTroponinaI" type="number" :placeholder="getLabelTraduora('patient.cardiology.lblPeak')" :readonly="!jsonData.checkTroponinaI"> </b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4"
          ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAntiplateletAgents") }}</label>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkAntiaggrAsa" switch :checked="jsonData.checkAntiaggrAsa"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblASA") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkAntiaggrTriclopidina" switch :checked="jsonData.checkAntiaggrTriclopidina"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTriclopidina") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkAntiaggrIndobufene" switch :checked="jsonData.checkAntiaggrIndobufene"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndobufene") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkAntiaggrGp" switch :checked="jsonData.checkAntiaggrGp"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGpInib") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkAntiaggrAltro" switch :checked="jsonData.checkAntiaggrAltro"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAceInibitors") }}</label>
            </b-col>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkAceInibitori" switch :checked="jsonData.checkAceInibitori"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAceInibitors") }}</label></b-col
            >
          </b-row>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4"
          ><label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEparine") }}</label>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkEparinaSodica" switch :checked="jsonData.checkEparinaSodica"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSodic") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkEparinaCalcica" switch :checked="jsonData.checkEparinaCalcica"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCalcic") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkEparinaLmwh" switch :checked="jsonData.checkEparinaLmwh"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLMWH") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col
              ><label style="margin-top: 10px" class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCaAntagonist") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkCaAntagonistiDiltiazem" switch :checked="jsonData.checkCaAntagonistiDiltiazem"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiltiazem") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkCaAntagonistiVerapamil" switch :checked="jsonData.checkCaAntagonistiVerapamil"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVerapamil") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkCaAntagonistiDiidropiridinici" switch :checked="jsonData.checkCaAntagonistiDiidropiridinici"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiidropiridinici") }}</label></b-col
            >
          </b-row>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblBetaBlockers") }}</label>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkBetaBloccantiMetoprololo" switch :checked="jsonData.checkBetaBloccantiMetoprololo"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMetoprololo") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkBetaBloccantiAtenololo" switch :checked="jsonData.checkBetaBloccantiAtenololo"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtenololo") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkBetaBloccantiCarvedilolo" switch :checked="jsonData.checkBetaBloccantiCarvedilolo"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCarvedilolo") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkBetaBloccantiAltro" switch :checked="jsonData.checkBetaBloccantiAltro"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label></b-col
            >
          </b-row>
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblNitroderivatives") }}</label>
            </b-col>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkNitroderivati" switch :checked="jsonData.checkNitroderivati"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNitroderivatives") }}</label></b-col
            >
          </b-row>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHemorrhage") }}</label>
          <b-form-select v-model="jsonData.emorragie" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArrhythmias") }}</label>
          <b-form-select v-model="jsonData.aritmie" :options="optionsAritmie">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBlocks") }}</label>
          <b-form-select v-model="jsonData.blocchi" :options="optionsBlocchi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkEdemaPolmonareAcuto" switch :checked="jsonData.checkEdemaPolmonareAcuto"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAcutePulmonaryEdema") }}</label></b-col
            >
          </b-row>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkRotturaDisfPapillare" switch :checked="jsonData.checkRotturaDisfPapillare"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPapillarBreakDisfunction") }}</label></b-col
            >
          </b-row>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkShock" switch :checked="jsonData.checkShock"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblShock") }}</label></b-col
            >
          </b-row>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkDifettoInterventricolare" switch :checked="jsonData.checkDifettoInterventricolare"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInterventricularDefection") }}</label></b-col
            >
          </b-row>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="12" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.checkRianimazioneCardiopolmonare" switch :checked="jsonData.checkRianimazioneCardiopolmonare"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="10" lg="10" xl="10"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCardiopulmonaryResuscitation") }}</label></b-col
            >
          </b-row>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblInstableAnginaClinicalInstrumentalData')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAppearanceModality") }}</label>
          <b-form-select v-model="jsonData.modalitaDiComparsa" :options="optionsModalita">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBraunwaldClassification") }}</label>
          <b-form-select v-model="jsonData.classificazioneDiBraunwald" :options="optionsBraunwald">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxDurationAngor") }}</label>
          <b-form-select v-model="jsonData.durataMaxAngor" :options="optionsAngor">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblReactiveCProtein") }}</label>
          <b-form-input v-model="jsonData.proteinaCReattiva" type="number" :placeholder="getLabelTraduora('patient.cardiology.lblPeak')"></b-form-input>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTroponineT") }}</label>
          <b-form-input v-model="jsonData.troponinaT" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTroponineI") }}</label>
          <b-form-input v-model="jsonData.troponinaI" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEcgAlterationDuringAngor") }}</label>
          <b-form-select v-model="jsonData.alterazioniEcgDuranteAngor" :options="optionsAlterazioniEcg">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTherapeuticInterventions") }}</label>
          <b-form-select v-model="jsonData.interventiTerapeutici" :options="optionsInterventi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDecorso24h") }}</label>
          <b-form-select v-model="jsonData.decorsoA24h" :options="optionsDecorso">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return {
          dataEvento: null,
          tipo: null,
          sede: null,
          presentazioneClinica: null,
          patternDelDolore: null,
          terapiaDiRiperfusione: null,
          classeKillip: null,
          emorragie: null,
          aritmie: null,
          blocchi: null,
          modalitaDiComparsa: null,
          classificazioneDiBraunwald: null,
          durataMaxAngor: null,
          alterazioniEcgDuranteAngor: null,
          interventiTerapeutici: null,
          decorsoA24h: null,
        };
      },
    },
  },
  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardiodaticlinicistrumentaliric",
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      optionsConferma: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblYes"), value: "patient.cardiology.Options.lblYes" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
      ],
      optionsDecorso: [{ text: "Select di test", value: "Select di test" }],
      optionsTipo: [{ text: "Select di test", value: "Select di test" }],
      optionsSede: [{ text: "Select di test", value: "Select di test" }],
      optionsBlocchi: [{ text: "Select di test", value: "Select di test" }],
      optionsAritmie: [{ text: "Select di test", value: "Select di test" }],
      optionsDolore: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblStableAngina"), value: "patient.cardiology.Options.lblStableAngina" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblInstableAngina"), value: "patient.cardiology.Options.lblInstableAngina" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblAnginaOfRecentInsurgence"), value: "patient.cardiology.Options.lblAnginaOfRecentInsurgence" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPainAtRest"), value: "patient.cardiology.Options.lblPainAtRest" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNightPain"), value: "patient.cardiology.Options.lblNightPain" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblStrainPain"), value: "patient.cardiology.Options.lblStrainPain" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblDispnea"), value: "patient.cardiology.Options.lblDispnea" },
      ],
      optionsTerapia: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblAnticoagulantsTherapy"), value: "patient.cardiology.Options.lblAnticoagulantsTherapy" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPTCA"), value: "patient.cardiology.Options.lblPTCA" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPtcaStent"), value: "patient.cardiology.Options.lblPtcaStent" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblArterialSurgicalReperfusion"), value: "patient.cardiology.Options.lblArterialSurgicalReperfusion" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblVenousArterialSurgicalReperfusion"), value: "patient.cardiology.Options.lblVenousArterialSurgicalReperfusion" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblMedicalTherapy"), value: "patient.cardiology.Options.lblMedicalTherapy" },
      ],
      optionsPresentazione: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblAngina"), value: "patient.cardiology.Options.lblAngina" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblHeartAttack"), value: "patient.cardiology.Options.lblHeartAttack" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblDispnea"), value: "patient.cardiology.Options.lblDispnea" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblHeartAsma"), value: "patient.cardiology.Options.lblHeartAsma" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblShock"), value: "patient.cardiology.Options.lblShock" },
      ],
      optionsKillip: [{ text: "Select di test", value: "Select di test" }],
      optionsModalita: [{ text: "Select di test", value: "Select di test" }],
      optionsBraunwald: [{ text: "Select di test", value: "Select di test" }],
      optionsAngor: [{ text: "Select di test", value: "Select di test" }],
      optionsAlterazioniEcg: [{ text: "Select di test", value: "Select di test" }],
      optionsInterventi: [{ text: "Select di test", value: "Select di test" }],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
