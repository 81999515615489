<template>
  <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
    <template v-slot:cell(nome)="{ item }">
      <router-link class="sa-edit-link" :to="'/adipazienti/pua'" @click.native="onClick(item)">{{ item.descrizione }} </router-link>
      <br />
      <small> {{ item.branca }}</small>
    </template>
    <template v-slot:cell(actions)="row">
      <b-button size="xs" variant="outline-primary" @click="onInviaDati(row.item, row.index, $event.target)">
        <font-awesome-icon size="lg" icon="book-medical" tabindex="-1" />
      </b-button>
    </template>
  </b-table>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      pathResource: "/adipuautenti",
      items: [],
      fields: [
        {
          label: "Nome Pua",
          key: "nome",
          sortable: true,
          thStyle: "width: 12rem",
        },
        {
          label: "Telefono",
          key: "telefono",
          sortable: true,
          thStyle: "width: 12rem",
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
          thStyle: "width: 12rem",
        },
        //{ key: "actions", label: "", thStyle: "width: 5rem" },
      ],
    };
  },

  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showmolalloading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios.get(link).then((response) => {
        me.items = response.data.data.list;
        if (me.items.length === 1) {
          me.saveIdPua(me.items[0].id);
          me.$router.replace("adipazienti/richieste").catch((err) => {
            err;
          });
        }
      });
    },
    onClick(item) {
      let me = this;
      me.saveIdPua(item.id);
    },
    saveIdPua(idPua) {
      let sessionStorage = window.sessionStorage;
      sessionStorage.setItem("idPua", idPua);
    },
  },
};
</script>

<style></style>
