<template>
    <sa-page-layout :data="jsonData" :btnBackVisible="true" :btnEditVisible="true" :pathResource="pathResource" :linkback="linkback" :linkedit="linkedit" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <div class="sa-component-content">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="9" lg="9" xl="9">
                            <h4>{{ this.$i18n.t("structure.lblClinicRegistry") }}</h4>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <img :src="jsonData.logo" style="width: 10rem" />
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblName") }}</label>
                            <span class="sa-data">{{ jsonData.denominazione }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblRegionCode") }}</label>
                            <span class="sa-data">{{ jsonData.codiceRegione }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblCompanyCode") }}</label>
                            <span class="sa-data">{{ jsonData.codiceStruttura }}</span>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblVatNumber") }}</label>
                            <span class="sa-data">{{ jsonData.partitaIva }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblFiscalCode") }}</label>
                            <span class="sa-data">{{ jsonData.codiceFiscale }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblIban") }}</label>
                            <span class="sa-data">{{ jsonData.iban }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblTaxRegime") }}</label>
                            <span class="sa-data">{{ jsonData.codiceRegimeFiscale }}</span>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblAddress") }}</label>
                            <span class="sa-data">{{ jsonData.indirizzo }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblProvince") }}</label>
                            <span class="sa-data">{{ jsonData.provincia }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblCity") }}</label>
                            <span class="sa-data">{{ jsonData.localita }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblPostalCode") }}</label>
                            <span class="sa-data">{{ jsonData.cap }}</span>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblTelephoneNumber") }}</label>
                            <span class="sa-data">{{ jsonData.telefono }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblEmail") }}</label>
                            <span class="sa-data">{{ jsonData.email }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblFax") }}</label>
                            <span class="sa-data">{{ jsonData.fax }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblUrl") }}</label>
                            <span class="sa-data">{{ jsonData.url }}</span>
                        </b-col>
                    </b-row>
                    <hr />
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ this.$i18n.t("structure.lblNote") }}</label>
                            <span class="sa-data">{{ jsonData.note }}</span>
                        </b-col>
                    </b-row>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            linkback: "/strutture",
            pathResource: "/strutture",
            linkedit: null,
            jsonData: {},
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/struttura/edit/" + me.id;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showmolalloading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.jsonData = response.data.data;
                me.showmolalloading = false;
            });
        },
    },
};
</script>
