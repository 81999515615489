import EmailNotificationGroupsList from "../emailnotification/pages/EmailNotificationGroupsList"
import EmailNotificationGroupsEdit from "../emailnotification/pages/EmailNotificationGroupsEdit"
import EmailNotificationGroupsView from "../emailnotification/pages/EmailNotificationGroupsView"
import EmailNotificationUtentiList from "../emailnotification/pages/EmailNotificationUtentiList"
import EmailNotificationUtentiEdit from "../emailnotification/pages/EmailNotificationUtentiEdit"
import EmailNotificationUtentiView from "../emailnotification/pages/EmailNotificationUtentiView"

export default {
    EmailNotificationGroupsList,
    EmailNotificationGroupsEdit,
    EmailNotificationGroupsView,
    EmailNotificationUtentiList,
    EmailNotificationUtentiEdit,
    EmailNotificationUtentiView,
};