<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipo Caso</label>
          <b-form-select v-model="jsonData.casoSospetto" :options="casoSospettoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nazione Residenza Diversa dall'italia</label>
          <b-form-select v-model="jsonData.nazioneResidenza" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputNazioneResidenza"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Si trova in Italia da più di 21 giorni</label>
          <b-form-select v-model="jsonData.soggiornoItaliaVentunoGiorniItalia" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.soggiornoItaliaDisabled"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Vive in un campo nomadi</label>
          <b-form-select v-model="jsonData.viveCampoNomadi" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Vive in un centro di accoglienza per migranti</label>
          <b-form-select v-model="jsonData.viveCentroAccogglienzaMigranti" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">E' un operatore sanitario</label>
          <b-form-select v-model="jsonData.operatoreSanitario" :options="defaultOptions" :value="''" value-field="value" text-field="text" @input="onInputOperatoreSanitario"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipo Operatore</label>
          <b-form-select v-model="jsonData.operatoreSanitarioTipo" :options="operatoreSanitarioTipiOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.operatoreSanitarioTipoDisabled"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">E' un operatore scolastico</label>
          <b-form-select v-model="jsonData.operatoreScolastico" :options="defaultOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="STATO VACCINALE" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Precedente vaccinazione contro il morbillo</label>
          <b-form-select v-model="jsonData.precedenteVaccinazioneMorbillio" :options="defaultOptions" :value="''" value-field="value" text-field="text" @input="onInputVaccinazioneMorbillo"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Numero Dosi</label>
          <b-form-select v-model="jsonData.numeroDosiVaccinoMorbillo" :options="numeroDosiOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.vaccinazioneMorbilloDisabled"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data ultima dose</label>
          <date-picker v-model="jsonData.dataUltimaDoseMorbillo" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.vaccinazioneMorbilloDisabled"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Vaccinazione contro il morbillo verificata da certificato vaccinale</label>
          <b-form-select v-model="jsonData.vaccinazioneMorbilloCertificata" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Precedente vaccinazione contro la rosolia</label>
          <b-form-select v-model="jsonData.precedenteVaccinazioneRosolia" :options="defaultOptions" :value="''" value-field="value" text-field="text" @input="onInputVaccinazioneRosolia"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data ultima dose</label>
          <date-picker v-model="jsonData.dataUltimaDoseRosolia" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.vaccinazioneRosoliaDisabled"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccinazione contro la rosolia verificata da certificato vaccinale</label>
          <b-form-select v-model="jsonData.vaccinazioneRosoliaCertificata" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccinazione post esposizione</label>
          <b-form-select v-model="jsonData.vaccunazuinePostEsposizione" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputVaccinazionePostEsposizione"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Entro 72 ore dall'esposizione</label>
          <b-form-select v-model="jsonData.vaccunazuinePostEsposizioneSettantadueOre" :options="siNoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.vaccunazuinePostEsposizioneSettantadueOreDisabled"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Somministrazione immunoglobuine post-esposizione</label>
          <b-form-select v-model="jsonData.somministrazioneImmuniglobulinePostEsposizione" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="DATI CLINICI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nazione Primi Sintomi</label>
          <b-form-select v-model="jsonData.nazionePrimiSintomi" :options="nazioniOptions" :value="''" value-field="codiceIstat" text-field="comune"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia Primi Sintomi</label>
          <b-form-select v-model="jsonData.provinciaPrimiSintomi" :options="provinceOptions" :value="''" value-field="sigla" text-field="provincia" @input="onInputProvince"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Comune Primi Sintomi</label>
          <b-form-select v-model="jsonData.comunePrimiSintomi" :options="comuniOptions" :value="''" value-field="codiceIstat" text-field="comune"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Esantema maculo papulare</label>
          <b-form-select v-model="jsonData.esantemaMaculoPapulare" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Comparsa Esantema</label>
          <date-picker v-model="jsonData.dataComparsaEsantema" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Febbre</label>
          <b-form-select v-model="jsonData.febbre" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Comparsa Febbre</label>
          <date-picker v-model="jsonData.febbreDataComparsa" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Rinite</label>
          <b-form-select v-model="jsonData.rinite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Congiuntivite</label>
          <b-form-select v-model="jsonData.congiuntivite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tosse</label>
          <b-form-select v-model="jsonData.tosse" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Adenopatia</label>
          <b-form-select v-model="jsonData.adenopatia" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Artralgia</label>
          <b-form-select v-model="jsonData.artralgia" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Artrite</label>
          <b-form-select v-model="jsonData.artrite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Specificare altri sintomi</label>
          <b-form-textarea v-model="jsonData.altriSintomi" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Patologie di base o immunodepressive</label>
          <b-form-select v-model="jsonData.patologieBaseImmunodepressive" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12" style="margin-top: 5px">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaPatologie" :fields="fieldsPatologie" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(patologia)="item">
              <b-form-input v-model="item.item.patologia"></b-form-input>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.listaPatologie.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPatologie(jsonData.listaPatologie, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.listaPatologie.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaPatologie, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">In stato di gravidanza</label>
          <b-form-select v-model="jsonData.gravidanza" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputGravidanza"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data ultima mestruazione</label>
          <date-picker v-model="jsonData.gravidanzaDataUltimeMestruazioni" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.dataUltimeMestruazioniDisabled"></date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Sono stati effettuati esami di laboratorio per la diagnosi di morbillo</label>
          <b-form-select v-model="jsonData.esamiLaboratorioDiagnosiMorbillo" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputEsamiLaboratorioMorbillo"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Sono stati effettuati esami di laboratorio per la diagnosi di rosolia</label>
          <b-form-select v-model="jsonData.esamiLaboratorioDiagnosiRosolia" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputEsamiLaboratorioRosolia"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="!esamiLaboratorioMorbilloDisabled" class="sa-card" header="CONFERMA DI LABORATORIO - MORBILLO" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-conferme-laboratorio-morbillo-rosolia-edit-component :confermaLaboratorio="jsonData.listaConfermeLaboratorio[0]" @update="updateListaConfermeLaboratorio" :isEdit="true"></indagine-epidemiologica-conferme-laboratorio-morbillo-rosolia-edit-component>
    </b-card>
    <b-card v-if="!esamiLaboratorioRosoliaDisabled" class="sa-card" header="CONFERMA DI LABORATORIO - ROSOLIA" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-conferme-laboratorio-morbillo-rosolia-edit-component :confermaLaboratorio="jsonData.listaConfermeLaboratorio[1]" @update="updateListaConfermeLaboratorio" :isEdit="true"></indagine-epidemiologica-conferme-laboratorio-morbillo-rosolia-edit-component>
    </b-card>
    <b-card class="sa-card" header="INVIO CAMPIONE AL LABORATORIO" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Invio campione al laboratorio Periferico</label>
          <b-form-select v-model="jsonData.invioCampioniLaboratorioPeriferico" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data invio laboratorio Periferico</label>
          <date-picker v-model="jsonData.invioCampioniLaboratorioPerifericoData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Invio campione al laboratorio Regionale</label>
          <b-form-select v-model="jsonData.invioCampioniLaboratorioRegionale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data invio laboratorio Regionale</label>
          <date-picker v-model="jsonData.invioCampioniLaboratorioRegionaleData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Invio campione al laboratorio Nazionale</label>
          <b-form-select v-model="jsonData.invioCampioniLaboratorioNazionale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data invio laboratorio Nazionale</label>
          <date-picker v-model="jsonData.invioCampioniLaboratorioNazionaleData" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="COMPLICANZE" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Complicanze</label>
          <b-form-select v-model="jsonData.complicanze" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Otite</label>
          <b-form-select v-model="jsonData.otite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Polmonite</label>
          <b-form-select v-model="jsonData.polmonite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Insufficienza Respiratoria</label>
          <b-form-select v-model="jsonData.insufficienzaRespiratioria" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Diarrea</label>
          <b-form-select v-model="jsonData.diarrea" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Disidratazione</label>
          <b-form-select v-model="jsonData.disidratazione" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Stomatite</label>
          <b-form-select v-model="jsonData.stomatite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Trombocitopenia</label>
          <b-form-select v-model="jsonData.trombocitopenia" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Cherato-congiuntivite </label>
          <b-form-select v-model="jsonData.cheratoCongiuntivite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Laringotracheobronchite (croup)</label>
          <b-form-select v-model="jsonData.laringotracheobronchite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Encefalite Acuta</label>
          <b-form-select v-model="jsonData.encefaliteAcuta" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Convulsioni</label>
          <b-form-select v-model="jsonData.convulsioni" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Epatite/Aumento Transaminasi</label>
          <b-form-select v-model="jsonData.epatiteAumentoTransaminasi" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Bronchite</label>
          <b-form-select v-model="jsonData.bronchite" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Specificare Altri Sintomi</label>
          <b-form-textarea v-model="jsonData.complicanzeAltriSintomi" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="ESITO" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Esito</label>
          <b-form-select v-model="jsonData.esito" :options="esitiOptions" :value="''" value-field="value" text-field="text" @input="onInputEsito"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Decesso</label>
          <date-picker v-model="jsonData.dataDecesso" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="disabledValue.decessoDisabled"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Causa Decesso</label>
          <b-form-input v-model="jsonData.causaDecesso" :disabled="disabledValue.decessoDisabled"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Reliquati</label>
          <b-form-input v-model="jsonData.reliquati" :disabled="disabledValue.reliquatiDisabled"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="RICOVERO PER MORBILLO/ROSOLIA" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricovero</label>
          <b-form-select v-model="jsonData.ricovero" :options="ricoveroOptions" :value="''" value-field="value" text-field="text" @input="onInputEsito"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ospedale</label>
          <b-form-input v-model="jsonData.ospedale"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Ricovero</label>
          <date-picker v-model="jsonData.dataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Dimissione</label>
          <date-picker v-model="jsonData.dataDimissioni" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="VIAGGI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ha effettuato viaggi nei 7-23 giorni precedenti l'inizio dell'esantema</label>
          <b-form-select v-model="jsonData.viaggiGiorniPrecedentiInizioEsantema" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row style="margin-top: 5px">
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.listaViaggi" :fields="fieldsViaggi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
            <template #cell(dove)="item">
              <b-form-select v-model="item.item.dove" :options="localitaViaggioOptions" :value="''" value-field="value" text-field="text" @input="onInputLocalitaViaggio"></b-form-select>
            </template>
            <template #cell(regioneNazione)="item">
              <b-form-select v-model="item.item.regioneNazione" :options="regioneNazioneViaggioOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </template>
            <template #cell(partenza)="item">
              <date-picker v-model="item.item.partenza" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </template>
            <template #cell(rientro)="item">
              <date-picker v-model="item.item.rientro" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </template>
            <template #cell(actions)="row">
              <b-button v-if="row.index == jsonData.listaViaggi.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddViaggi(jsonData.listaViaggi, row.item)">
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button v-if="row.index !== jsonData.listaViaggi.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaViaggi, row)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <!-- ORIGINE DEL CONTAGIO DA CONTROLLARE -->
    <b-card class="sa-card" header="ORIGINE DEL CONTAGIO (Fornire una sola risposta) " header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Asilo nido/Scuola</label>
          <b-form-select v-model="jsonData.origineContagioAsiloNidoScuola" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ambulatorio medico/Sala d’attesa</label>
          <b-form-select v-model="jsonData.origineContagioAmbulatorioSalaAttesa" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ambulatorio in ospedale/Sala d’attesa</label>
          <b-form-select v-model="jsonData.origineContagioOspedaleSalaAttesa" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Pronto Soccorso</label>
          <b-form-select v-model="jsonData.origineContagioProntoSoccorso" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Lavoro</label>
          <b-form-select v-model="jsonData.origineContagioLavoro" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Università</label>
          <b-form-select v-model="jsonData.origineContagioUniversita" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Carcere</label>
          <b-form-select v-model="jsonData.origineContagioCarcere" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Chiesa</label>
          <b-form-select v-model="jsonData.origineContagioChiesa" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Casa/Famiglia</label>
          <b-form-select v-model="jsonData.origineContagioCasaFamiglia" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Struttura Recettiva</label>
          <b-form-select v-model="jsonData.origineContagioStrutturaRecettiva" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ambiente Militare</label>
          <b-form-select v-model="jsonData.origineContagioAmbienteMilitare" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Campo Nomadi</label>
          <b-form-select v-model="jsonData.origineContagioCampoNomadi" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Viaggio Internazionale</label>
          <b-form-select v-model="jsonData.origineContagioViaggioInternazionale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Non Noto</label>
          <b-form-select v-model="jsonData.origineContagioNonNoto" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Reparto Ospedale</label>
          <b-form-select v-model="jsonData.origineContagioRepartoOspedale" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputRepartoOspedale"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Motivo</label>
          <b-form-select v-model="jsonData.origineContagioRepartoOspedaleMotivazione" :options="motivazioniContagioRepartoOspedaleOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.motivoRepartoOspedaleDisabled"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altro</label>
          <b-form-textarea v-model="jsonData.origineContagioAltro" rows="4" no-resize></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">In quale località è avvenuto il contagio</label>
          <b-form-select v-model="jsonData.localitaContagio" :options="localitaContagioOptions" :value="''" value-field="value" text-field="text" @input="onInputLocalitaContagio"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Specificare altra regione o nazione</label>
          <b-form-select v-model="jsonData.altraRegioneNazione" :options="altraRegioneNazioneOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Il caso fa parte di un focolaio</label>
          <b-form-select v-model="jsonData.parteFocolaio" :options="defaultOptions" :value="''" value-field="value" text-field="text" @input="onInputFocolaio"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Nome focolaio</label>
          <b-form-input v-model="jsonData.nomeFocolaio" :disabled="disabledValue.focolaioDisabled"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Descrizione Focolaio <b-icon icon="info-circle" v-b-popover.hover.top="popoverText"></b-icon></label>
          <b-form-textarea v-model="jsonData.descrizioneFocolaio" rows="6" no-resize :disabled="disabledValue.focolaioDisabled"></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Il paziente è stato a contatto con un altro caso confermato di morbillo (entro 7-18 giorni) o di rosolia (entro 12-23 giorni) prima dell’esordio dell’esantema?</label>
          <b-form-select v-model="jsonData.contattoCasoConfermatoMorbilloRosolia" :options="tipoCasoConfermatoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Il contatto in questione è un caso importato</label>
          <b-form-select v-model="jsonData.contattoCasoConfermatoMorbilloRosoliaImportato" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputCasoConfermatoImportato"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Specificare nazione</label>
          <b-form-select v-model="jsonData.contattoCasoConfermatoMorbilloRosoliaImportatoNazione" :options="nazioniOptions" :value="''" value-field="codiceIstat" text-field="comune" :disabled="disabledValue.contattoCasoConfermatoNazioneDisabled"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nel periodo di contagiosità il paziente è stato a contatto ocn una donna in gravidanza</label>
          <b-form-select v-model="jsonData.contattoDonnaGravidanza" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="CLASSIFICAZIONE DEL CASO" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Caso di</label>
          <b-form-select v-model="jsonData.classificazioneCasoDi" :options="classificazioneCasoTipo" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Classificazione finale del caso</label>
          <b-form-select v-model="jsonData.classificazioneFinale" :options="classificazioneFinaleOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Caso</label>
          <b-form-select v-model="jsonData.classificazioneCaso" :options="classificazioneCasoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="INFORMAZIONI SULLA RACCOLTA DATI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sanitario Notificante</label>
          <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Telefono Sanitario Notificante</label>
          <b-form-input v-model="jsonData.sanitarioNotificanteContatto"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Notifica</label>
          <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import IndagineEpidemiologicaConfermeLaboratorioMorbilloRosoliaEditComponent from "./IndagineEpidemiologicaConfermeLaboratorioMorbilloRosoliaEditComponent.vue";
export default {
  mixins: [UtilityMixin],
  components: { DatePicker, IndagineEpidemiologicaConfermeLaboratorioMorbilloRosoliaEditComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      popoverText: "Misure intraprese per prevenire/controllare la trasmissione dell’infezione. Includere n° contatti identificati, n° contatti vaccinati in post-esposizione, n° contatti a cui sono state offerte IG",
      pathResource: "/malattieinfettiveiemorbillorosolia",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      pathResourceNazioni: "/nazioni",
      pathResourceProvince: "/province",
      pathResourceComuni: "/comuni/provincia",
      pathResourceRegioni: "/regioni",
      linkback: null,
      showModalLoading: false,
      id: "-1",
      currentPage: 1,
      perPage: 100,
      tipoMalattiaMorbillo: "MORBILLO",
      esamiLaboratorioMorbilloDisabled: false,
      esamiLaboratorioRosoliaDisabled: false,
      disabledValue: {
        soggiornoItaliaDisabled: true,
        operatoreSanitarioTipoDisabled: true,
        vaccinazioneMorbilloDisabled: true,
        vaccinazioneRosoliaDisabled: true,
        vaccunazuinePostEsposizioneSettantadueOreDisabled: true,
        dataUltimeMestruazioniDisabled: true,
        decessoDisabled: true,
        reliquatiDisabled: true,
        motivoRepartoOspedaleDisabled: true,
        focolaioDisabled: true,
        contattoCasoConfermatoNazioneDisabled: true,
      },
      filtro: {},
      jsonData: {
        casoSospetto: "",
        nazioneResidenza: "",
        soggiornoItaliaVentunoGiorniItalia: "",
        viveCentroAccogglienzaMigranti: "",
        viveCampoNomadi: "",
        operatoreSanitario: "",
        operatoreSanitarioTipo: "",
        operatoreScolastico: "",
        precedenteVaccinazioneMorbillio: "",
        numeroDosiVaccinoMorbillo: "",
        dataUltimaDoseMorbillo: null,
        vaccinazioneMorbilloCertificata: "",
        precedenteVaccinazioneRosolia: "",
        dataUltimaDoseRosolia: null,
        vaccinazioneRosoliaCertificata: "",
        vaccunazuinePostEsposizione: "",
        vaccunazuinePostEsposizioneSettantadueOre: "",
        somministrazioneImmuniglobulinePostEsposizione: "",
        nazionePrimiSintomi: "",
        provinciaPrimiSintomi: "",
        comunePrimiSintomi: "",
        esantemaMaculoPapulare: "",
        dataComparsaEsantema: null,
        febbre: "",
        febbreDataComparsa: null,
        rinite: "",
        congiuntivite: "",
        tosse: "",
        adenopatia: "",
        artralgia: "",
        artrite: "",
        altriSintomi: "",
        patologieBaseImmunodepressive: "",
        listaPatologie: [],
        gravidanza: "",
        gravidanzaDataUltimeMestruazioni: null,
        esamiLaboratorioDiagnosiMorbillo: "",
        esamiLaboratorioDiagnosiRosolia: "",
        listaConfermeLaboratorio: [
          {
            malattia: "MORBILLO",
            ricercaIgm: "",
            metodo: "",
            dataRaccoltaCampione: null,
            esitoIgm: "",
            tipoCampioneIgm: "",
            campioneAltro: "",
            ricercaIgg: "",
            metod: "",
            dataRaccoltaCampioneFaseAcuta: null,
            dataRaccoltaCampioneFaseConvalescente: null,
            esitoIgg: "",
            tipoCampioneIgg: "",
            tipoCampioneIggAltro: "",
            pcr: "",
            dataRaccoltaCampionePcr: null,
            esitoPcr: "",
            tipoCampionePcr: "",
            tipoCampionePcrAltro: "",
            genotipizzazione: "",
            genotipo: "",
            metodoAltro: "",
            metodAltro: "",
            specificareAltroTest: "",
            altroTestDataRaccoltaCampione: "",
            altroTestEsito: "",
            altroTestTipoCampione: "",
            altroTestSpecificareAltreo: "",
          },
          {
            malattia: "ROSOLIA",
            ricercaIgm: "",
            metodo: "",
            dataRaccoltaCampione: null,
            esitoIgm: "",
            tipoCampioneIgm: "",
            campioneAltro: "",
            ricercaIgg: "",
            metod: "",
            dataRaccoltaCampioneFaseAcuta: null,
            dataRaccoltaCampioneFaseConvalescente: null,
            esitoIgg: "",
            tipoCampioneIgg: "",
            tipoCampioneIggAltro: "",
            pcr: "",
            dataRaccoltaCampionePcr: null,
            esitoPcr: "",
            tipoCampionePcr: "",
            tipoCampionePcrAltro: "",
            genotipizzazione: "",
            genotipo: "",
            metodoAltro: "",
            metodAltro: "",
            specificareAltroTest: "",
            altroTestDataRaccoltaCampione: "",
            altroTestEsito: "",
            altroTestTipoCampione: "",
            altroTestSpecificareAltreo: "",
          },
        ],
        invioCampioniLaboratorioPeriferico: "",
        invioCampioniLaboratorioPerifericoData: null,
        invioCampioniLaboratorioRegionale: "",
        invioCampioniLaboratorioRegionaleData: null,
        invioCampioniLaboratorioNazionale: "",
        invioCampioniLaboratorioNazionaleData: null,
        complicanze: "",
        otite: "",
        polmonite: "",
        insufficienzaRespiratioria: "",
        diarrea: "",
        disidratazione: "",
        stomatite: "",
        trombocitopenia: "",
        cheratoCongiuntivite: "",
        laringotracheobronchite: "",
        encefaliteAcuta: "",
        convulsioni: "",
        epatiteAumentoTransaminasi: "",
        bronchite: "",
        complicanzeAltriSintomi: "",
        esito: "",
        dataDecesso: null,
        causaDecesso: "",
        reliquati: "",
        ricovero: "",
        ospedale: "",
        dataRicovero: null,
        dataDimissioni: null,
        viaggiGiorniPrecedentiInizioEsantema: "",
        listaViaggi: [],
        origineContagioAsiloNidoScuola: "",
        origineContagioAmbulatorioSalaAttesa: "",
        origineContagioRepartoOspedale: "",
        origineContagioRepartoOspedaleMotivazione: "",
        origineContagioProntoSoccorso: "",
        origineContagioLavoro: "",
        origineContagioUniversita: "",
        origineContagioCarcere: "",
        origineContagioChiesa: "",
        origineContagioCasaFamiglia: "",
        origineContagioStrutturaRecettiva: "",
        origineContagioAmbienteMilitare: "",
        origineContagioCampoNomadi: "",
        origineContagioViaggioInternazionale: "",
        origineContagioNonNoto: "",
        origineContagioOspedaleSalaAttesa: "",
        origineContagioAltro: "",
        localitaContagio: "",
        altraRegioneNazione: "",
        parteFocolaio: "",
        nomeFocolaio: "",
        descrizioneFocolaio: "",
        contattoCasoConfermatoMorbilloRosolia: "",
        contattoCasoConfermatoMorbilloRosoliaImportato: "",
        contattoCasoConfermatoMorbilloRosoliaImportatoNazione: "",
        contattoDonnaGravidanza: "",
        classificazioneCasoDi: "",
        classificazioneFinale: "",
        classificazioneCaso: "",
        sanitarioNotificante: "",
        sanitarioNotificanteContatto: "",
        dataNotifica: null,
      },
      fieldsPatologie: [
        {
          label: "",
          key: "index",
          thStyle: "width: 2rem",
        },
        {
          label: "Patologia",
          key: "patologia",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
        },
      ],
      fieldsViaggi: [
        {
          label: "",
          key: "index",
          thStyle: "width: 2rem",
        },
        {
          label: "Dove",
          key: "dove",
          thStyle: "width: 15rem",
        },
        {
          label: "Specificare Nazione/Regione",
          key: "regioneNazione",
          // thStyle: "width: 8rem",
        },
        {
          label: "Data Partenza",
          key: "partenza",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          thStyle: "width: 12rem",
        },
        {
          label: "Data Rientro",
          key: "rientro",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          thStyle: "width: 12rem",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
        },
      ],
      casoSospettoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "MORBILLO", text: "MORBILLO" },
        { value: "ROSOLIA", text: "ROSOLIA" },
      ],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      defaultOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      operatoreSanitarioTipiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "MEDICO", text: "MEDICO" },
        { value: "INDERMIERE/A", text: "INDERMIERE/A" },
        { value: "ALTRO PERSONALE A CONTATTO CON I PAZIENTI", text: "ALTRO PERSONALE A CONTATTO CON I PAZIENTI" },
        { value: "PERSONALE IN FORMAZIONE", text: "PERSONALE IN FORMAZIONE" },
      ],
      numeroDosiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "1", text: "1" },
        { value: "2", text: "2" },
        { value: "NON RICORDO", text: "NON RICORDO" },
      ],
      esitiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "GUARIGIONE", text: "GUARIGIONE" },
        { value: "DECESSO", text: "DECESSO" },
        { value: "RELIQUATI", text: "RELIQUATI" },
        { value: "PERSO AL FOLLOW-UP", text: "PERSO AL FOLLOW-UP" },
      ],
      ricoveroOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "SOLO VISITA AL PRONTO SOCCORSO", text: "SOLO VISITA AL PRONTO SOCCORSO" },
      ],
      motivazioniContagioRepartoOspedaleOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "RICOVERATO PER ALTRA DIAGNOSI", text: "RICOVERATO PER ALTRA DIAGNOSI" },
        { value: "VISITATORE", text: "VISITATORE" },
      ],
      localitaContagioOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "REGIONE DI RESIDENZA/DOMICILIO", text: "REGIONE DI RESIDENZA/DOMICILIO" },
        { value: "ALTRA REGIONE", text: "ALTRA REGIONE" },
        { value: "ESTERO", text: "ESTERO" },
        { value: "NON SPECIFICATO", text: "NON SPECIFICATO" },
      ],
      tipoCasoConfermatoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI, MORBILLO", text: "SI, MORBILLO" },
        { value: "SI, ROSOLIA", text: "SI, ROSOLIA" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      classificazioneCasoTipo: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "MORBILLO", text: "MORBILLO" },
        { value: "ROSOLIA", text: "ROSOLIA" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      classificazioneFinaleOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSSIBILE", text: "POSSIBILE" },
        { value: "PROBABILE", text: "PROBABILE" },
        { value: "CONFERMATO", text: "CONFERMATO" },
      ],
      classificazioneCasoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "IMPORTATO", text: "IMPORTATO" },
        { value: "AUTOCTONO CORRELATO AD UN CASO IMPORTATO", text: "AUTOCTONO: CORRELATO AD UN CASO IMPORTATO" },
        { value: "AUTOCTONO CORRELATO AD UN CASO AUTOCTONO", text: "AUTOCTONO: CORRELATO AD UN CASO AUTOCTONO" },
        { value: "AUTOCTONO FONTE NON NOTA", text: "AUTOCTONO: FONTE NON NOTA" },
      ],
      localitaViaggioOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "ITALIA", text: "ITALIA" },
        { value: "ESTERO", text: "ESTERO" },
      ],
      altraRegioneNazioneOptions: [{ value: "", text: "-Seleziona Valore-" }],
      nazioniOptions: [],
      regioniOptions: [{ value: "", text: "-Seleziona Valore-" }],
      provinceOptions: [],
      comuniOptions: [{ codiceIstat: "", comune: "-Seleziona Valore-" }],
      regioneNazioneViaggioOptions: [{ value: "", text: "-Seleziona Valore-" }],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.id = this.$route.params.id;
    me.jsonData.id = this.$route.params.id;
    me.jsonData.id = me.id;
    me.loadMalattiaInfettivaSegnalazione();
    me.loadData();
    me.loadDefaultData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.esamiLaboratorioDiagnosiRosolia": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaConfermeLaboratorio.push(
          { malattia: "MORBILLO", ricercaIgm: "", metodo: "", dataRaccoltaCampione: null, esitoIgm: "", tipoCampioneIgm: "", campioneAltro: "", ricercaIgg: "", metod: "", dataRaccoltaCampioneFaseAcuta: null, dataRaccoltaCampioneFaseConvalescente: null, esitoIgg: "", tipoCampioneIgg: "", tipoCampioneIggAltro: "", pcr: "", dataRaccoltaCampionePcr: null, esitoPcr: "", tipoCampionePcr: "", tipoCampionePcrAltro: "", genotipizzazione: "", genotipo: "" },
          { malattia: "ROSOLIA", ricercaIgm: "", metodo: "", dataRaccoltaCampione: null, esitoIgm: "", tipoCampioneIgm: "", campioneAltro: "", ricercaIgg: "", metod: "", dataRaccoltaCampioneFaseAcuta: null, dataRaccoltaCampioneFaseConvalescente: null, esitoIgg: "", tipoCampioneIgg: "", tipoCampioneIggAltro: "", pcr: "", dataRaccoltaCampionePcr: null, esitoPcr: "", tipoCampionePcr: "", tipoCampionePcrAltro: "", genotipizzazione: "", genotipo: "" }
        );
      } else {
        me.jsonData.listaConfermeLaboratorio = [];
      }
    },
    "jsonData.listaConfermeLaboratorio": function (array) {
      if (array.length === 0) {
        array.push(
          { malattia: "MORBILLO", ricercaIgm: "", metodo: "", dataRaccoltaCampione: null, esitoIgm: "", tipoCampioneIgm: "", campioneAltro: "", ricercaIgg: "", metod: "", dataRaccoltaCampioneFaseAcuta: null, dataRaccoltaCampioneFaseConvalescente: null, esitoIgg: "", tipoCampioneIgg: "", tipoCampioneIggAltro: "", pcr: "", dataRaccoltaCampionePcr: null, esitoPcr: "", tipoCampionePcr: "", tipoCampionePcrAltro: "", genotipizzazione: "", genotipo: "" },
          { malattia: "ROSOLIA", ricercaIgm: "", metodo: "", dataRaccoltaCampione: null, esitoIgm: "", tipoCampioneIgm: "", campioneAltro: "", ricercaIgg: "", metod: "", dataRaccoltaCampioneFaseAcuta: null, dataRaccoltaCampioneFaseConvalescente: null, esitoIgg: "", tipoCampioneIgg: "", tipoCampioneIggAltro: "", pcr: "", dataRaccoltaCampionePcr: null, esitoPcr: "", tipoCampionePcr: "", tipoCampionePcrAltro: "", genotipizzazione: "", genotipo: "" }
        );
      }
    },
    "jsonData.patologieBaseImmunodepressive": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaPatologie.push({ patologia: "" });
      } else {
        me.jsonData.listaPatologie = [];
      }
    },
    "jsonData.viaggiGiorniPrecedentiInizioEsantema": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaViaggi.push({ dove: "", regioneNazione: "", partenza: null, rientro: null });
      } else {
        me.jsonData.listaViaggi = [];
      }
    },
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadNazioni();
      me.loadRegioni();
      me.loadProvince();
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          if (me.jsonData.provinciaPrimiSintomi !== "" && me.jsonData.provinciaPrimiSintomi !== null) {
            me.loadComune(me.jsonData.provinciaPrimiSintomi);
          }
          me.showModalLoading = false;
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
        });
    },
    loadMalattiaInfettivaSegnalazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.setDatiMalattia(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onInputNazioneResidenza(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.soggiornoItaliaDisabled = false;
      } else {
        me.disabledValue.soggiornoItaliaDisabled = true;
        me.jsonData.soggiornoItaliaVentunoGiorniItalia = "";
      }
    },
    onInputOperatoreSanitario(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.operatoreSanitarioTipoDisabled = false;
      } else {
        me.disabledValue.operatoreSanitarioTipoDisabled = true;
        me.jsonData.operatoreSanitarioTipo = "";
      }
    },
    onInputVaccinazioneMorbillo(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.vaccinazioneMorbilloDisabled = false;
      } else {
        me.disabledValue.vaccinazioneMorbilloDisabled = true;
        me.jsonData.numeroDosiVaccinoMorbillo = "";
        me.jsonData.dataUltimaDoseMorbillo = null;
      }
    },
    onInputVaccinazioneRosolia(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.vaccinazioneRosoliaDisabled = false;
      } else {
        me.disabledValue.vaccinazioneRosoliaDisabled = true;
        me.jsonData.dataUltimaDoseRosolia = null;
      }
    },
    onInputVaccinazionePostEsposizione(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.vaccunazuinePostEsposizioneSettantadueOreDisabled = false;
      } else {
        me.disabledValue.vaccunazuinePostEsposizioneSettantadueOreDisabled = true;
        me.jsonData.vaccunazuinePostEsposizioneSettantadueOre = "";
      }
    },
    onInputEsamiLaboratorioMorbillo(value) {
      let me = this;
      if (value === "SI") {
        me.esamiLaboratorioMorbilloDisabled = false;
      } else {
        me.esamiLaboratorioMorbilloDisabled = true;
      }
    },
    onInputEsamiLaboratorioRosolia(value) {
      let me = this;
      if (value === "SI") {
        me.esamiLaboratorioRosoliaDisabled = false;
      } else {
        me.esamiLaboratorioRosoliaDisabled = true;
      }
    },
    onInputGravidanza(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.dataUltimeMestruazioniDisabled = false;
      } else {
        me.disabledValue.dataUltimeMestruazioniDisabled = true;
        me.jsonData.gravidanzaDataUltimeMestruazioni = null;
      }
    },
    onInputRepartoOspedale(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.motivoRepartoOspedaleDisabled = false;
      } else {
        me.disabledValue.motivoRepartoOspedaleDisabled = true;
        me.jsonData.origineContagioRepartoOspedaleMotivazione = "";
      }
    },
    onInputEsito(value) {
      let me = this;
      if (value === "DECESSO") {
        me.disabledValue.decessoDisabled = false;
        me.jsonData.reliquati = "";
        me.disabledValue.reliquatiDisabled = true;
      } else if (value === "RELIQUATI") {
        me.disabledValue.reliquatiDisabled = false;
        me.disabledValue.decessoDisabled = true;
        me.jsonData.causaDecesso = "";
        me.jsonData.dataDecesso = null;
      } else {
        me.disabledValue.decessoDisabled = true;
        me.disabledValue.reliquatiDisabled = true;
        me.jsonData.dataDecesso = null;
        me.jsonData.causaDecesso = "";
        me.jsonData.reliquati = "";
      }
    },
    onInputLocalitaContagio(value) {
      let me = this;
      if (value === "ALTRA REGIONE") {
        me.altraRegioneNazione = "";
        me.altraRegioneNazioneOptions = [{ value: "", text: "-Seleziona Valore-" }];
        me.altraRegioneNazioneOptions = me.regioniOptions;
        console.log(me.altraRegioneNazioneOptions);

        // me.loadRegioni(me.altraRegioneNazioneOptions);
      } else if (value === "ESTERO") {
        me.altraRegioneNazione = "";
        me.altraRegioneNazioneOptions = [];
        me.nazioniOptions.forEach((element) => {
          me.altraRegioneNazioneOptions.push({ value: element.codiceIstat, text: element.comune });
        });
      } else {
        me.altraRegioneNazione = "";
        me.altraRegioneNazioneOptions = [{ value: "", text: "-Seleziona Valore-" }];
      }
    },
    onInputLocalitaViaggio(value) {
      let me = this;
      if (value === "ITALIA") {
        me.regioneNazioneViaggioOptions = [{ value: "", text: "-Seleziona Valore-" }];
        me.regioneNazioneViaggioOptions = me.regioniOptions;
        console.log(me.regioneNazioneViaggioOptions);
        // me.loadRegioni(me.regioneNazioneViaggioOptions);
      }
      if (value === "ESTERO") {
        me.regioneNazioneViaggioOptions = [];
        me.nazioniOptions.forEach((element) => {
          me.regioneNazioneViaggioOptions.push({ value: element.codiceIstat, text: element.comune });
        });
      }
    },
    onInputFocolaio(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.focolaioDisabled = false;
      } else {
        me.disabledValue.focolaioDisabled = true;
        me.jsonData.nomeFocolaio = "";
        me.jsonData.descrizioneFocolaio = "";
      }
    },
    onInputCasoConfermatoImportato(value) {
      let me = this;
      if (value === "SI") {
        me.disabledValue.contattoCasoConfermatoNazioneDisabled = false;
      } else {
        me.disabledValue.contattoCasoConfermatoNazioneDisabled = true;
        me.jsonData.contattoCasoConfermatoMorbilloRosoliaImportatoNazione = "";
      }
    },
    loadRegioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceRegioni;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          me.regioniOptions.push({ value: element.codiceIstat, text: element.regione });
        });
      });
    },
    loadNazioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceNazioni;
      axios.get(link).then((response) => {
        me.nazioniOptions = response.data.data;
        me.nazioniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Paese-" });
      });
    },
    loadProvince() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvince;
      axios.get(link).then((response) => {
        me.provinceOptions = response.data.data;
        me.provinceOptions.unshift({ sigla: "", provincia: "-Seleziona Provincia-" });
      });
    },
    onInputProvince(provincia) {
      let me = this;
      me.loadComune(provincia);
    },
    loadComune(sigla) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceComuni + "/" + sigla;
      axios.get(link).then((response) => {
        me.comuniOptions = response.data.data;
        me.comuniOptions.unshift({ codiceIstat: "", comune: "-Seleziona Valore-" });
      });
    },
    onAddPatologie(array, value) {
      if (value.patologia !== "") {
        array.push({ patologia: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddViaggi(array, value) {
      if (value.dove !== "" && value.regioneNazione !== "" && value.partenza !== null && value.rientro !== null) {
        array.push({ dove: "", regioneNazione: "", partenza: null, rientro: null });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updateListaConfermeLaboratorio(data) {
      let me = this;
      if (data.malattia === "MORBILLO") {
        me.jsonData.listaConfermeLaboratorio[0] = data;
      }
      if (data.malattia === "ROSOLIA") {
        me.jsonData.listaConfermeLaboratorio[1] = data;
      }
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
  },
};
</script>
