<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Peso Nascita:</label>
          <span class="sa-data">{{ jsonData.pesoNascita }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Eta' gestazionale (settimane):</label>
          <span class="sa-data">{{ jsonData.etaGestazionaleNascita }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Bambino in Vita" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Bambino in Vita:</label>
          <span class="sa-data">{{ jsonData.bambinoVita }}</span>
        </b-col>
      </b-row>
      <b-row v-if="!chekCondition(jsonData.bambinoVita, 'NO')">
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Decesso:</label>
          <span class="sa-data">{{ formatDate(jsonData.bambinoVitaDataDecesso) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Autopsia Eseguita:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaAutopsiaEseguita }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Referto Anatomopatologico Finale:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaRefertoAnatomopatologicoFinale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Causa Morte Iniziale:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaCausaMorteIniziale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Causa Morte Intermedia:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaCausaMorteIntermedia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Causa Morte Finale:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaCausaMorteFinale }}</span>
        </b-col>
      </b-row>
      <b-row v-if="!chekCondition(jsonData.bambinoVita, 'SI')">
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Ultima Valutazione:</label>
          <span class="sa-data">{{ formatDate(jsonData.bambinoVitaDataUltimaValutazione) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ipoacussia/Sordita':</label>
          <span class="sa-data">{{ jsonData.bambinoVitaIpoacusiaSordita }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cataratta:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaCataratta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Glaucoma Congenito:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaGlaucomaCongenito }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Retinopatia Pigmentosa:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaRetinopatiaPigmentata }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cardiopatia Congenita:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaCardiopatiaCongenita }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cardiopatia Congenita Dettaglio:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaCardiopatiaCongenitaDettaglio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altra Cardiopatia:</label>
          <span class="sa-data">{{ jsonData.cardiopatiaCongenitaDettaglioAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altri Sintomi:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaAltriSegniSintomi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Porpora:</label>
          <span class="sa-data">{{ jsonData.bmbinoVitaPorpora }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Splenomegalia:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaSplenomegaglia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Microcefalia:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaMicroencefalia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ritardo Sviluppo Psicomotorio:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaRitardoSviluppoPsicomotorio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Meningoencefalite:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaMeningoencefalite }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Radiolucenza Ossa Lunghe:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaRadiolicenzaOssea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ittero Entro 24 Ore Nascita:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaItteroVentiquattroOreNascita }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccino Rosolia:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaVaccinoRosolia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Vaccino:</label>
          <span class="sa-data">{{ jsonData.bambinoVitaVaccinoRosoliaData }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Storia Materna" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Codice Fiscale/STP/ENI:</label>
          <span class="sa-data">{{ jsonData.madreIdentificativo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cognome:</label>
          <span class="sa-data">{{ jsonData.madreCognome }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nome:</label>
          <span class="sa-data">{{ jsonData.madreNome }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Nascita:</label>
          <span class="sa-data">{{ formatDate(jsonData.madreDataNascita) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Ultima Mestruazione:</label>
          <span class="sa-data">{{ formatDate(jsonData.madreDataUltimaMestruazione) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Rubeotest Eseguito Prima Gravidanza :</label>
          <span class="sa-data">{{ jsonData.madreRobeotestPrimaGravidanza }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anno Esecuzione:</label>
          <span class="sa-data">{{ jsonData.madreRobeotestPrimaGravidanzaAnno }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risultato:</label>
          <span class="sa-data">{{ jsonData.madreRobeotestPrimaGravidanzaRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Rubeotest Eseguito Durante Questa Gravidanza :</label>
          <span class="sa-data">{{ jsonData.madreRobeotestDuranteGravidanza }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anno Esecuzione:</label>
          <span class="sa-data">{{ jsonData.madreRobeotestDuranteGravidanzaData }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risultato:</label>
          <span class="sa-data">{{ jsonData.madreRobeotestDuranteGravidanzaRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Occupazione Momento Concepimento:</label>
          <span class="sa-data">{{ jsonData.madreOccupazioneMomentoConcepimento }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Gravidanze Inclusa Presente:</label>
          <span class="sa-data">{{ jsonData.madreNumeroGravidanze }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Parti:</label>
          <span class="sa-data">{{ jsonData.madreNumeroParti }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vaccino Contro Rosolia:</label>
          <span class="sa-data">{{ jsonData.madreVaccinoRosolia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Vaccino:</label>
          <span class="sa-data">{{ formatDate(jsonData.madreVaccinoRosoliaData) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malattia Simile Rosolia in Gravidanza:</label>
          <span class="sa-data">{{ jsonData.madreMalattiaSimileRosoliaGravidanza }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Settimana Gestazione:</label>
          <span class="sa-data">{{ jsonData.madreRosoliaGravidanzaSettimana }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Esantema Maculopapulare:</label>
          <span class="sa-data">{{ jsonData.madreEsantemaMaculopapulare }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Comparsa:</label>
          <span class="sa-data">{{ formatDate(jsonData.madreEsantemaMaculopapulareDataComparsa) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre:</label>
          <span class="sa-data">{{ jsonData.madreFebbre }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Adenopatia Cervicale:</label>
          <span class="sa-data">{{ jsonData.madreAdenopatiaCervicale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Adenopatia Sub Occipitale:</label>
          <span class="sa-data">{{ jsonData.madreAdenopatiaSubOccipitale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Adenopatia Retro Auricolare:</label>
          <span class="sa-data">{{ jsonData.madreAdenopatiaRetroAuricolare }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Artralgia/Artrite:</label>
          <span class="sa-data">{{ jsonData.madreArtralgiaArtrite }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <span class="sa-data">{{ jsonData.madreAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diagnosi Confermata in Laboratorio:</label>
          <span class="sa-data">{{ jsonData.madreDiagnosiRosoliaConfermata }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Settimana di Gestaizone:</label>
          <span class="sa-data">{{ jsonData.madreDiagnosiRosoliaConfermataSettimanaGestazione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Test:</label>
          <span class="sa-data">{{ jsonData.madreDiagnosiRosoliaConfermataTipoTest }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Esposizione Caso Rosolia/Esantema Maculopapulare Gravidanza:</label>
          <span class="sa-data">{{ jsonData.madreEsposizioneRosoliaEsantemaMaculopatia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Caso Confermato Laboratorio:</label>
          <span class="sa-data">{{ jsonData.madreEsposizioneRosoliaEsantemaMaculopatiaConfermato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Settimane Gestazione:</label>
          <span class="sa-data">{{ jsonData.madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoSetti }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Luogo Esposizione:</label>
          <span class="sa-data">{{ jsonData.madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoLuogo }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Viaggio Estero Periodo Incubazione:</label>
          <span class="sa-data">{{ jsonData.madreFonteInfezioneNonNotaViaggi }}</span>
        </b-col>
      </b-row>
      <b-row v-if="!chekCondition(jsonData.madreFonteInfezioneNonNotaViaggi, 'SI')">
        <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaViaggi" :fields="fieldsViaggi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(dove)="item">
            <span class="sa-data">{{ item.item.dove }}</span>
          </template>
          <template #cell(giornoDal)="item">
            <span class="sa-data">{{ formatDate(item.item.giornoDal) }}</span>
          </template>
          <template #cell(giornoAl)="item">
            <span class="sa-data">{{ formatDate(item.item.giornoAl) }}</span>
          </template>
        </b-table>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Esami Laboratorio Bambino" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricerca Operativa Anticorpi IgM:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIgmRosolia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIgmRosoliaTipoCampione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Laboratorio:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIgmRosoliaLaboratorio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIgmRosoliaLaboratorioAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Metodo:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIgmRosoliaMetodo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risultato:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIgmRosoliaRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricerca Operativa Anticorpi IgG:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIggRosoliaSpecifici }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIggRosoliaSpecificiTipoCampione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Laboratorio:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIggRosoliaSpecificiLaboratorio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIggRosoliaSpecificiLaboratorioAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Metodo:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIggRosoliaSpecificiMetodoValoriRiferimen }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Risultato:</label>
          <span class="sa-data">{{ jsonData.ricercaAnticorpiIggRosoliaSpecificiRisultato }}</span>
        </b-col>
        <!---->
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Isolamento Virale Liquidi Biologici:</label>
          <span class="sa-data">{{ jsonData.isolamentoViraleLiquidiBiologici }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione:</label>
          <span class="sa-data">{{ jsonData.isolamentoViraleLiquidiBiologiciTipoCampione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione Altro:</label>
          <span class="sa-data">{{ jsonData.isolamentoViraleLiquidiBiologiciTipoCampioneAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Prelievo:</label>
          <span class="sa-data">{{ jsonData.isolamentoViraleLiquidiBiologiciDataPrelievo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Laboratorio:</label>
          <span class="sa-data">{{ jsonData.isolamentoViraleLiquidiBiologiciLaboratorio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altro:</label>
          <span class="sa-data">{{ jsonData.isolamentoViraleLiquidiBiologiciLaboratorioAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato:</label>
          <span class="sa-data">{{ jsonData.isolamentoViraleLiquidiBiologiciRisultato }}</span>
        </b-col>
        <!---->
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Identificazione Genoma Virale RT-PCR:</label>
          <span class="sa-data">{{ jsonData.identificazioneGenomaViraleRtPcr }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione:</label>
          <span class="sa-data">{{ jsonData.identificazioneGenomaViraleRtPcrTipoCampione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Campione Altro:</label>
          <span class="sa-data">{{ jsonData.identificazioneGenomaViraleRtPcrTipoCampione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Prelievo:</label>
          <span class="sa-data">{{ formatDate(jsonData.identificazioneGenomaViraleRtPcrDataPrelievo) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Laboratorio:</label>
          <span class="sa-data">{{ jsonData.identificazioneGenomaViraleRtPcrLaboratorio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altro:</label>
          <span class="sa-data">{{ jsonData.identificazioneGenomaViraleRtPcrLaboratorioAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato:</label>
          <span class="sa-data">{{ jsonData.identificazioneGenomaViraleRtPcrRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Genotipizzazione:</label>
          <span class="sa-data">{{ jsonData.genotipizzazione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ceppo:</label>
          <span class="sa-data">{{ jsonData.genotipizzazioneCeppo }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaDatiNotificaComponent from "../components/IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { IndagineEpidemiologicaDatiNotificaComponent }, //
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveierosoliacongenita",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      showModalLoading: false,
      disableSintomi: true,
      jsonData: {
        pesoNascita: "",
        etaGestazionaleNascita: "",
        bambinoVita: "",
        bambinoVitaDataDecesso: null,
        bambinoVitaAutopsiaEseguita: "",
        bambinoVitaRefertoAnatomopatologicoFinale: "",
        bambinoVitaCausaMorteIniziale: "",
        bambinoVitaCausaMorteIntermedia: "",
        bambinoVitaCausaMorteFinale: "",
        bambinoVitaDataUltimaValutazione: null,
        bambinoVitaIpoacusiaSordita: "",
        bambinoVitaCataratta: "",
        bambinoVitaGlaucomaCongenito: "",
        bambinoVitaRetinopatiaPigmentata: "",
        bambinoVitaCardiopatiaCongenita: "",
        bambinoVitaCardiopatiaCongenitaDettaglio: "",
        bambinoVitaAltriSegniSintomi: "",
        bmbinoVitaPorpora: "",
        bambinoVitaSplenomegaglia: "",
        bambinoVitaMicroencefalia: "",
        bambinoVitaRitardoSviluppoPsicomotorio: "",
        bambinoVitaMeningoencefalite: "",
        bambinoVitaRadiolicenzaOssea: "",
        bambinoVitaItteroVentiquattroOreNascita: "",
        bambinoVitaVaccinoRosolia: "",
        bambinoVitaVaccinoRosoliaData: null,
        madreIdentificativo: "",
        madreCognome: "",
        madreNome: "",
        madreDataNascita: null,
        madreRobeotestPrimaGravidanza: "",
        madreRobeotestPrimaGravidanzaAnno: "",
        madreRobeotestPrimaGravidanzaRisultato: "",
        madreRobeotestDuranteGravidanza: "",
        madreRobeotestDuranteGravidanzaData: null,
        madreRobeotestDuranteGravidanzaRisultato: "",
        madreOccupazioneMomentoConcepimento: "",
        madreNumeroGravidanze: "",
        madreNumeroParti: "",
        madreVaccinoRosolia: "",
        madreVaccinoRosoliaData: null,
        madreMalattiaSimileRosoliaGravidanza: "",
        madreRosoliaGravidanzaSettimana: "",
        madreEsantemaMaculopapulare: "",
        madreEsantemaMaculopapulareDataComparsa: null,
        madreFebbre: "",
        madreAdenopatiaCervicale: "",
        madreAdenopatiaSubOccipitale: "",
        madreAdenopatiaRetroAuricolare: "",
        madreArtralgiaArtrite: "",
        madreAltro: "",
        madreDiagnosiRosoliaConfermata: "",
        madreDiagnosiRosoliaConfermataSettimanaGestazione: "",
        madreDiagnosiRosoliaConfermataTipoTest: "",
        madreEsposizioneRosoliaEsantemaMaculopatia: "",
        madreEsposizioneRosoliaEsantemaMaculopatiaConfermato: "",
        madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoSetti: "",
        madreEsposizioneRosoliaEsantemaMaculopatiaConfermatoLuogo: "",
        madreFonteInfezioneNonNotaViaggi: "",
        ricercaAnticorpiIgmRosolia: "",
        ricercaAnticorpiIgmRosoliaTipoCampione: "",
        ricercaAnticorpiIgmRosoliaLaboratorio: "",
        ricercaAnticorpiIgmRosoliaLaboratorioAltro: "",
        ricercaAnticorpiIgmRosoliaMetodo: "",
        ricercaAnticorpiIgmRosoliaRisultato: "",
        ricercaAnticorpiIggRosoliaSpecifici: "",
        ricercaAnticorpiIggRosoliaSpecificiTipoCampione: "",
        ricercaAnticorpiIggRosoliaSpecificiLaboratorio: "",
        ricercaAnticorpiIggRosoliaSpecificiLaboratorioAltro: "",
        ricercaAnticorpiIggRosoliaSpecificiMetodoValoriRiferimen: "",
        ricercaAnticorpiIggRosoliaSpecificiRisultato: "",
        isolamentoViraleLiquidiBiologici: "",
        isolamentoViraleLiquidiBiologiciTipoCampione: "",
        isolamentoViraleLiquidiBiologiciTipoCampioneAltro: "",
        isolamentoViraleLiquidiBiologiciDataPrelievo: null,
        isolamentoViraleLiquidiBiologiciLaboratorio: "",
        isolamentoViraleLiquidiBiologiciLaboratorioAltro: "",
        isolamentoViraleLiquidiBiologiciRisultato: "",
        identificazioneGenomaViraleRtPcr: "",
        identificazioneGenomaViraleRtPcrTipoCampione: "",
        identificazioneGenomaViraleRtPcrTipoCampioneAltro: "",
        identificazioneGenomaViraleRtPcrDataPrelievo: null,
        identificazioneGenomaViraleRtPcrLaboratorio: "",
        identificazioneGenomaViraleRtPcrLaboratorioAltro: "",
        identificazioneGenomaViraleRtPcrRisultato: "",
        genotipizzazione: "",
        genotipizzazioneCeppo: "",
        sanitarioNotificante: "",
        sanitarioNotificanteContatto: "",
        dataNotifica: null,
        idAnagrafica: "",
        cardiopatiaCongenitaDettaglioAltro: "",
        madreDataUltimaMestruazione: null,
        listaViaggi: [],
      },
      fieldsViaggi: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Luogo",
          key: "dove",
          sortable: false,
        },
        {
          label: "Dal",
          key: "giornoDal",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Al",
          key: "giornoAl",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkback = "/malattieinfettive/all";
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
  },
};
</script>
