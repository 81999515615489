import Home from "../components/Home.vue";
import DashboardAslNa1 from "../components/DashboardAslNa1.vue";
import TestTableau from "../components/TestTableau.vue";

const routes = [
    { path: "/", redirect: { name: "Home" } },
    { name: "Home", path: "/home", component: Home, meta: { title: "Home" } },
    { name: "DashboardAslNa1", path: "/aslna1dashboard", component: DashboardAslNa1, meta: { title: "Dashboard AslNa1" } },
    { name: "TestTableau", path: "/testtableau", component: TestTableau, meta: { title: "Dashboard AslNa1" } },
];

export default routes;
