<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblRestingHR") }}
                        <eventi-storico-misure-component misura="hr1" misuraLabel="HR a Riposo" unitaMisura="bpm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.hr1 }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblAfterStressHR") }}
                        <eventi-storico-misure-component misura="hr2" misuraLabel="HR Dopo Sforzo" unitaMisura="bpm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.hr2 }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblRestingPR") }}
                        <eventi-storico-misure-component misura="pr1" misuraLabel="PR a Riposo" unitaMisura="bpm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.pr1 }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblAfterStressPR") }}
                        <eventi-storico-misure-component misura="pr2" misuraLabel="PR Dopo Sforzo" unitaMisura="bpm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.pr2 }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblRestingQT") }}
                        <eventi-storico-misure-component misura="qt1" misuraLabel="QT a Riposo" unitaMisura="bpm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.qt1 }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblAfterStressQT") }}
                        <eventi-storico-misure-component misura="qt2" misuraLabel="QT Dopo Sforzo" unitaMisura="bpm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.qt2 }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblRestingQTC") }}
                        <eventi-storico-misure-component misura="qtc_riposo" misuraLabel="QTC a Riposo" unitaMisura="bpm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.qtcRiposo }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblPulsation") }}
                        <eventi-storico-misure-component misura="hr_3" misuraLabel="Pulsazioni" unitaMisura="bpm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.hr3 }}</span>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblIri") }}
                        <eventi-storico-misure-component misura="iri" misuraLabel="IRI" unitaMisura="bpm" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.iri }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblIriJudgement") }}</label>
                    <span class="sa-data">{{ jsonData.giudizioIri }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblReport") }}</label>
                    <span class="sa-data">{{ jsonData.referto }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("repeatablePrescriptions.lblStatus") }}</label>
                    {{ jsonData.firmaEcg }}
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { EventiStoricoMisureComponent },
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
        gruppo: {
            Type: String,
            require: true,
        },
        tipoEvento: {
            Type: String,
            require: true,
        },
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.jsonData.idPaziente;
        },
    },
    data() {
        return {};
    },
};
</script>
