import SchedaValutazioneEsigenzeImmediate from "../";
import SveiMappa from "../";

const routes = [
    { name: "SchedaValutazioneEsigenzeImmediateList", path: "/schedavalutazioneesigenzeimmediate", component: SchedaValutazioneEsigenzeImmediate.SchedaValutazioneEsigenzeImmediateList, meta: { title: "Scheda Valutazione Esigenze Immediate" } },
    { name: "SchedaValutazioneEsigenzeImmediateEdit", path: "/schedavalutazioneesigenzeimmediate/edit/:id", component: SchedaValutazioneEsigenzeImmediate.SchedaValutazioneEsigenzeImmediateEdit, meta: { title: "Scheda Valutazione Esigenze Immediate" } },
    { name: "SchedaValutazioneEsigenzeImmediateView", path: "/schedavalutazioneesigenzeimmediate/view/:id", component: SchedaValutazioneEsigenzeImmediate.SchedaValutazioneEsigenzeImmediateView, meta: { title: "Scheda Valutazione Esigenze Immediate" } },
    { name: "SveiMappa", path: "/sveimappa", component: SveiMappa.SveiMappa, meta: { title: "Posizionamento Geografico" } },
];

export default routes;
