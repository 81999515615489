<template>
  <div>
    <b-card class="sa-card" header="VALORI BASALI" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
          <b-form-input v-model="jsonData.freqCardiacaBasali" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Sistolica</label>
          <b-form-input v-model="jsonData.pressArtMaxBasali" type="number"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Diastolica</label>
          <b-form-input v-model="jsonData.pressArtMinBasali" type="number"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblECG") }}</label>
          <b-form-select v-model="jsonData.ecg" :options="defaultOptions"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="PROTOCOLLO E TEST" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <b-form-select v-model="jsonData.protocolloETest" :options="defaultOptions"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="ACME" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMinimumTime") }}</label>
          <b-form-input v-model="jsonData.tempoMin" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
          <b-form-input v-model="jsonData.freqCardiacaAcme" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPredictedPercentage") }}</label>
          <b-form-input v-model="jsonData.percPredettoBpm" step="1" max="100" min="0" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Sistolica</label>
          <b-form-input v-model="jsonData.pressArtMaxAcme" type="number"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pressione Diastolica</label>
          <b-form-input v-model="jsonData.pressArtMinAcme" type="number"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWattMinute") }}</label>
          <b-form-input v-model="jsonData.wattMinAcme" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPredictedPercentage") }}</label>
          <b-form-input v-model="jsonData.percPredettoWattalmin" step="1" max="100" min="0" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="RECUPERO" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMinimumTime") }}</label>
          <b-form-input v-model="jsonData.tempoMinRecupero" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
          <b-form-input v-model="jsonData.freqCardiacaRecupero" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPredictedPercentage") }}</label>
          <b-form-input v-model="jsonData.percPredettoRecupero" step="1" max="100" min="0" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Pressione Sistolica</label>
          <b-form-input v-model="jsonData.pressArtMaxRecupero" type="number"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Pressione Diastolica</label>
          <b-form-input v-model="jsonData.pressArtMinRecupero" type="number"> </b-form-input>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: {},
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      defaultOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "NORMALE", text: "NORMALE" },
        { value: "ANORMALE", text: "ANORMALE" },
        { value: "TEST MASSIMALE", text: "TEST MASSIMALE" },
        { value: "TEST SOTTOMASSIMALE", text: "TEST SOTTOMASSIMALE" },
        { value: "STOP PER ANGINA", text: "STOP PER ANGINA" },
        { value: "STOP PER ESAURIMENTO MUSCOLARE", text: "STOP PER ESAURIMENTO MUSCOLARE" },
        { value: "ALTRO", text: "ALTRO" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {},
};
</script>
