<template>
  <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
    <template slot="tab-content-event">
      <ecocardio-dati-edit-component :jsonData="jsonData" :tipoEvento="titoloEvento" :gruppo="gruppoEvento" @update="onUpdateJsonData"></ecocardio-dati-edit-component>
    </template>
  </eventi-edit-component>
</template>
<script>
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixins from "../../../utility/mixins/EventiMixins";
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EcocardioDatiEditComponent from "./EcocardioDatiEditComponent.vue";

export default {
  mixins: [UtilityMixins, EventiMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { EventiEditComponent, EcocardioDatiEditComponent },
  data() {
    return {
      pathResource: "/cardioecocardiodati",
      titoloEvento: "ECOCARDIO DATI",
      gruppoEvento: "CARDIOLOGIA",
      id: "-1",
      linkback: null,
      defaultSegmenti: "NORMOCINETICO",
      jsonData: {
        dataEvento: null,
        ea: null,
        aint: null,
        eint: null,
        pfva: null,
        pfve: null,
        ess: null,
        csa: null,
        rwt: null,
        sft: null,
        pgmaxAorta: null,
        ia: null,
        im: null,
        dt: null,
        peso: null,
        altezza: null,
        impedenzaAcustica: null,
        co: null,
        ef: null,
        sv: null,
        tpr: null,
        lvvs: null,
        lvvd: null,
        irt: null,
        eiai: null,
        eft: null,
        lvmisp: null,
        lvmidp: null,
        tprt: null,
        cot: null,
        bsa: null,
        bmi: null,
        vdtd: null,
        atrio: null,
        aorta: null,
        rr: null,
        ppts: null,
        pptd: null,
        sivts: null,
        sivtd: null,
        vsts: null,
        vstd: null,
        videotape: null,
        fc: null,
        pad: null,
        pas: null,
        impressioniDiagnostiche: null,
        aneurismaticiCalc: null,
        aneurismatici: null,
        apicePost: "NORMOCINETICO",
        apiceInf: "NORMOCINETICO",
        apiceAnt: "NORMOCINETICO",
        pareteInferioreMedia: "NORMOCINETICO",
        pareteInferiore: "NORMOCINETICO",
        apiceSetto: "NORMOCINETICO",
        normocineticiCalc: null,
        ipocineticiCalc: null,
        acineticiCalc: null,
        discineticiCalc: null,
        settoposterioreMedio: "NORMOCINETICO",
        settoanterioreMedio: "NORMOCINETICO",
        pareteanteriore: "NORMOCINETICO",
        pareteanterioreMedia: "NORMOCINETICO",
        pareteLatAnteriore: "NORMOCINETICO",
        pareteLatAnterioreMedia: "NORMOCINETICO",
        pareteLatPosteriore: "NORMOCINETICO",
        pareteLatPosterioreMedia: "NORMOCINETICO",
        settoposteriore: "NORMOCINETICO",
        settoanteriore: "NORMOCINETICO",
        estensioneDanno: null,
        totale: null,
        discinetici: null,
        acinetici: null,
        ipocinetici: null,
        normocinetici: null,
        img4: null,
        img2: null,
        img3: null,
        img1: null,
        commento4: null,
        commento3: null,
        commento2: null,
        commento1: null,
        filmati: null,
        codicePrescrizione: null,
        s: null,
        d: null,
        apv: null,
        apd: null,
        studyuid: null,
        lvmha: null,
        vciI: null,
        vciE: null,
        tapse: null,
        aortaAscendente: null,
        aortaAddominale: null,
        lav: null,
        lavI: null,
        lvvDi: null,
        stdi: null,
        etdi: null,
        aiTdi: null,
        ee1Tdi: null,
        adur: null,
        anulus: null,
        giunzSt: null,
        aortaArco: null,
        aortaTor: null,
        lvot: null,
        lvotv1: null,
        cwaov1: null,
        areaDoppler: null,
        area2d: null,
        rvwt: null,
        rvaed: null,
        rvaes: null,
        fac: null,
        insTric: null,
        prSistPolm: null,
        jetRigTric: null,
        areaDueDMitrale: null,
        areaDopplerMitrale: null,
        gradMedio: null,
        stdiSettale: null,
        etdiSettale: null,
        aiTdiSettale: null,
        mobilita: null,
        ispessimentoLembi: null,
        ispessimentoAppSottvalv: null,
        calcificazioni: null,
        wilkinsScore: null,
        conclusioni: null,
        esaminatore: null,
        storicoReferti: null,
        checkFi: null,
        duraDurret: null,
        dti: null,
        sd: null,
        seniValsalva: null,
        specializzandoDue: null,
        diagnosiClinica: null,
        provenienza: null,
        tipoManovra: null,
        specializzandoUno: null,
        pfveDue: null,
        pfvaDue: null,
        eaDue: null,
        arApd: null,
        areaDueDIndex: null,
        areaDopplerI: null,
        phtMitrale: null,
        venaContracataAorta: null,
        eroaAorta: null,
        volRigurgitanteAorta: null,
        frazRigurgitanteAorta: null,
        noteVentricoloSinistro: null,
        noteAtrioSinistro: null,
        dueChLav: null,
        lvvdQuattroCh: null,
        lvvsQuattroCh: null,
        lvvdDueCh: null,
        lvvsDueCh: null,
        iaDueDsb: null,
        saDueDsb: null,
        imDueDsb: null,
        smDueDsb: null,
        indicazioneClinica: null,
        dueDRvbasal: null,
        dueDRvmedio: null,
        dueDPvlongit: null,
        noteVentricoloDestro: null,
        noteAtrioDestro: null,
        adArea: null,
        adDiametro: null,
        ecg: null,
        stdiTricuspidalico: null,
        etdiTricuspidalico: null,
        aiTdiTricuspidalico: null,
        pgMedio: null,
        pht: null,
        descrizioneAorta: null,
        protesiValvolariAorta: null,
        venaContracataMitrale: null,
        eroaMitrale: null,
        volRigurgitanteMitrale: null,
        sm: null,
        wilkinsScoreMitrale: null,
        descrizioneMitrale: null,
        vmaxPolmonare: null,
        ip: null,
        sp: null,
        protesiValvolariMitrale: null,
        protesiValvolariPolmonare: null,
        descrizionePolmonare: null,
        descrizioneTricuspide: null,
        protesiValvolariTricuspide: null,
        vmaxTricuspide: null,
        st: null,
        pericardio: null,
        glsDx: null,
        glsSn: null,
        it: null,
        venaContracataPolmonare: null,
        sa: null,
      },
      showModalLoading: false,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    this.jsonData.dataEvento = new Date().getTime();
  },

  methods: {
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
