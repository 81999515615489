<template>
    <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnBackVisible="true" :btnEditVisible="true" :linkedit="linkedit" :linkback="linkback" :data="profilo">
        <template slot="toolbar-title">Profilo </template>
        <template slot="table-body">
            <div class="sa-scroll-view-bar">
                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="profilo-celullare"> Username</label>
                        <br />
                        <span id="profilo-celullare">{{ profilo.username }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="profilo-celullare"> Cellulare</label>
                        <br />
                        <span id="profilo-celullare">{{ profilo.cellulare }}</span>
                    </b-col>

                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-form-label" for="profilo-email">Email</label>
                        <br />
                        <span id="profilo-email"> {{ profilo.email }}</span>
                    </b-col>
                </b-row>
                <br />
                <h5>Dettaglio Anagrafica</h5>

                <b-row>
                    <b-col xs="12" sm="12" md="4" lg="6" xl="6">
                        <label class="sa-form-label" for="profilo-cognome">Cognome</label>
                        <br />
                        <span id="profilo-cognome">{{ profilo.cognome }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="6" xl="6">
                        <label class="sa-form-label" for="profilo-nome">Nome</label>
                        <br />
                        <span id="profilo-nome">{{ profilo.nome }}</span>
                    </b-col>
                </b-row>
                <br />
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-form-label" for="profilo-tipo-identificativo">Tipo Identificativo</label>
                        <br />
                        <span id="profilo-tipoIdentificativo">{{ profilo.tipoIdentificativo }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-form-label" for="profilo-identificativo">Identificativo</label>
                        <br />
                        <span id="profilo-identificativo">{{ profilo.identificativo }}</span>
                    </b-col>
                </b-row>
                <br />
                <h5>Domicilio</h5>
                <hr />
                <b-row>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="profilo-indirizzo">Indirizzo </label>
                        <br />
                        <span id="profilo-indirizzo">{{ profilo.indirizzoDomicilio }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="profilo-civico">Civico </label>
                        <br />
                        <span id="profilo-civico">{{ profilo.civicoDomicilio }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label for="profilo-provincia">Provincia </label>
                        <br />
                        <span id="profilo-provincia">{{ profilo.provinciaDomicilio }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="profilo-comune">Comune </label>
                        <br />
                        <span id="profilo-comune">{{ profilo.comuneDomicilio }}</span>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="sa-element-data">
                        <label class="sa-form-label" for="profilo-frazione">Frazione </label>
                        <br />
                        <span id="profilo-frazione">{{ profilo.frazioneDomicilio }}</span>
                    </b-col>

                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="profilo-cap">C.A.P. </label>
                        <br />
                        <span id="profilo-cap">{{ profilo.capDomicilio }}</span>
                    </b-col>
                </b-row>
                <br />
                <h5>Residenza</h5>
                <hr />
                <b-row>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="profilo-indirizzo">Indirizzo </label>
                        <br />
                        <span id="profilo-indirizzo">{{ profilo.indirizzoResidenza }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="profilo-civico">Civico </label>
                        <br />
                        <span id="profilo-civico">{{ profilo.civicoResidenza }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label for="profilo-provincia">Provincia </label>
                        <br />
                        <span id="profilo-provincia">{{ profilo.provinciaResidenza }}</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="profilo-comune">Comune </label>
                        <br />
                        <span id="profilo-comune">{{ profilo.comuneResidenza }}</span>
                    </b-col>
                </b-row>
                <br />
                <b-row>
                    <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="sa-element-data">
                        <label class="sa-form-label" for="profilo-frazione">Frazione </label>
                        <br />
                        <span id="profilo-frazione">{{ profilo.frazioneResidenza }}</span>
                    </b-col>

                    <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                        <label class="sa-form-label" for="profilo-cap">C.A.P. </label>
                        <br />
                        <span id="profilo-cap">{{ profilo.capResidenza }}</span>
                    </b-col>
                </b-row>
                <br />
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-form-label" for="profilo-latitudine">Latitudine</label>
                        <br />
                        <span v-if="profilo.latitudine" id="profilo-latitudine">{{ profilo.latitudine }}</span>
                        <span v-else id="profilo-latitudine">-</span>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-form-label" for="profilo-longitudine">Longitudine</label>
                        <br />
                        <span v-if="profilo.longitudine" id="profilo-longitudine">{{ profilo.longitudine }}</span>
                        <span v-else id="profilo-latitudine">-</span>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
// import axios from "axios";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
import axios from "axios";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return { idUtente: null };
            },
        },
    },
    components: { SaPageLayout },
    data() {
        return {
            id: null,
            pathResource: "/profilo",
            linkback: "/lemieprenotazioni",
            linkedit: null,
            profilo: {},
            showModalLoading: false,
        };
    },
    mixins: [UtilityMixins],
    mounted() {
        let me = this;
        me.loadData();
        me.linkedit = me.pathResource + "/edit" + "/" + me.utente.id;
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/profilo";
            axios.get(link).then((response) => {
                console.log(response.data.data);
                console.log(JSON.stringify(response.data.data));
                me.profilo = response.data.data;
            });
        },
    },
};
</script>

<style></style>
