import AfaList from "./afa/components/AfaList.vue";
import AfaEdit from "./afa/components/AfaEdit.vue";
import AfaView from "./afa/components/AfaView.vue";
import IdoneitaList from "./idoneita/components/IdoneitaList.vue";
import IdoneitaEdit from "./idoneita/components/IdoneitaEdit.vue";
import IdoneitaView from "./idoneita/components/IdoneitaView.vue";

export default {
    AfaList,
    AfaEdit,
    AfaView,
    IdoneitaList,
    IdoneitaEdit,
    IdoneitaView,
};
