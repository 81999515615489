<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("lbl.measure.weight") }}</label>
                    <span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("lbl.measure.height") }}</label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
                    <span class="sa-data"> {{ jsonData.bmi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBSA") }}</label>
                    <span class="sa-data"> {{ jsonData.bsa }}</span>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" :header="getLabelTraduora('patient.card.lblRiskCalculationResultFactors')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHypertension") }}</label>
                    <span :class="setColor(jsonData.ipertensione) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.ipertensione)"></b-icon></span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.Dyslipidemia") }}</label>
                    <span :class="setColor(jsonData.displidemia) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.displidemia)"></b-icon></span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblDiabetes") }}</label>
                    <span :class="setColor(jsonData.diabete) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diabete)"></b-icon></span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</label>
                    <span :class="setColor(jsonData.obesita) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.obesita)"></b-icon></span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSmoking") }}</label>
                    <span :class="setColor(jsonData.fumo) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fumo)"></b-icon></span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCardiovascularAccidentsFamiliarity") }}</label>
                    <span :class="setColor(jsonData.familCerebrovascolari) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCerebrovascolari)"></b-icon></span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</label>
                    <span :class="setColor(jsonData.familIpertensione) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familIpertensione)"></b-icon></span>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIDiseaseFamiliarity") }}</label>
                    <span :class="setColor(jsonData.familCardiopatiaIsch) + ' sa-text-data'"> <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCardiopatiaIsch)"></b-icon></span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.afa.lblReport')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblReport") }}</label>
                    <span class="sa-data">{{ jsonData.referto }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    props: {},
    components: {},
    data() {
        return {
            pathResource: "/cardiovisiteangiologiche",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
