export default {
    viewComponents() {
        let returnValue = {};
        const indaginiComponents = require.context("", true, /^.*ViewComponent.vue$/);
        indaginiComponents.keys().forEach((element) => {
            let pathSplit = element.split("/");
            let folder = pathSplit[1];
            let regex = new RegExp(folder + "", "gi");
            let nomeFile = folder.charAt(0).toUpperCase() + folder.slice(1);
            if (element.match(regex)) {
                returnValue[folder] = () => import(`./${nomeFile}`);
            }
        });
        return returnValue;
    },
    editComponents() {
        let returnValue = {};
        const indaginiComponents = require.context("", true, /^.*EditComponent.vue$/);
        indaginiComponents.keys().forEach((element) => {
            let pathSplit = element.split("/");
            let folder = pathSplit[1];
            let regex = new RegExp(folder + "", "gi");
            let nomeFile = folder.charAt(0).toUpperCase() + folder.slice(1);
            if (element.match(regex)) {
                returnValue[folder] = () => import(`./${nomeFile}`);
            }
        });
        return returnValue;
    },
};
