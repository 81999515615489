<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY hh:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"> </date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
          <b-form-input v-model="jsonData.peso" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
          <b-form-input v-model="jsonData.altezza" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
          <b-form-input v-model="jsonData.bmi" disabled></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBSA") }}</label>
          <b-form-input v-model="jsonData.bsa" disabled></b-form-input>
        </b-col>

        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.card.lblSystolicBloodPressure") }}</label>
          <b-form-input v-model="jsonData.pas" type="number" placeholder="Sys"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.card.lblDiastolicBloodPressure") }}</label>
          <b-form-input v-model="jsonData.pad" type="number" placeholder="Dias"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFC") }}</label>
          <b-form-input v-model="jsonData.fc" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>

    <!-- <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <b-card class="sa-card" header="Dati Paziente" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                            <date-picker format="DD-MM-YYYY hh:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"> </date-picker>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
                            <b-form-input v-model="jsonData.peso" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
                            <b-form-input v-model="jsonData.altezza" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
                            <b-form-input v-model="jsonData.bmi" disabled></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                            <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBSA") }}</label>
                            <b-form-input v-model="jsonData.bsa" disabled></b-form-input>
                        </b-col>

                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.card.lblSystolicBloodPressure") }}</label>
                            <b-form-input v-model="jsonData.pas" type="number" placeholder="Sys"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">{{ getLabelTraduora("patient.card.lblDiastolicBloodPressure") }}</label>
                            <b-form-input v-model="jsonData.pad" type="number" placeholder="Dias"></b-form-input>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFC") }}</label>
                            <b-form-input v-model="jsonData.fc" type="number"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row> -->

    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row align="center">
            <b-col xs="2" sm="2" md="2" lg="2" xl="2"> </b-col>
            <b-col class="ft-color-section-title">
              <h5>{{ getLabelTraduora("patient.cardiology.lblCommonCarotid") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title">
              <h5>{{ getLabelTraduora("patient.cardiology.lblBulbCarotid") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title">
              <h5>{{ getLabelTraduora("patient.cardiology.lblInternalCarotid") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title">
              <h5>{{ getLabelTraduora("patient.cardiology.lblExternalCarotid") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>Score</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.ccscoresn" :options="scoreOptions" type="number" placeholder="Sx"> </b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.ccscoredx" :options="scoreOptions" type="number" placeholder="Dx"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.bscoresx" :options="scoreOptions" type="number" placeholder="Sx"> </b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.bscoredx" :options="scoreOptions" type="number" placeholder="Dx"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.ciscoresn" :options="scoreOptions" type="number" placeholder="Sx"> </b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.ciscoredx" :options="scoreOptions" type="number" placeholder="Dx"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.cescoresn" :options="scoreOptions" type="number" placeholder="Sx"> </b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.cescoredx" :options="scoreOptions" type="number" placeholder="Dx"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>Color</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.cccolsn" :options="colorOptions" placeholder="Sx"> </b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.cccoldx" :options="colorOptions" placeholder="Dx"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.bcolsn" :options="colorOptions" placeholder="Sx"> </b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.bcoldx" :options="colorOptions" placeholder="Dx"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.cicolsn" :options="colorOptions" placeholder="Sx"> </b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.cicoldx" :options="colorOptions" placeholder="Dx"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.cecolsn" :options="colorOptions" placeholder="Sx"> </b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.cecoldx" :options="colorOptions" placeholder="Dx"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.maximtnearccsx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.maximtnearccdx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.maximtnearbsx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.maximtnearbdx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.maximtnearcisx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.maximtnearcidx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.maximtnearcesx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.maximtnearcedx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.maximtfarccsx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.maximtfarccdx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.maximtfarbsx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.maximtfarbdx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.maximtfarcisx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.maximtfarcidx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.maximtfarcesx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.maximtfarcedx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMeanIMTNear") }}</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.meanimtnearccsx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.meanimtnearccdx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.meanimtnearbsx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.meanimtnearbdx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.meanimtnearcisx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.meanimtnearcidx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.meanimtnearcesx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.meanimtnearcedx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMeanIMTFar") }}</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.meanimtfarccsx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.meanimtfarccdx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.meanimtfarbsx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.meanimtfarbdx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.meanimtfarcisx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.meanimtfarcidx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-input v-model="jsonData.meanimtfarcesx" step="0.1" min="0.1" max="8" type="number" placeholder="Sx"></b-form-input>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-input v-model="jsonData.meanimtfarcedx" step="0.1" min="0.1" max="8" type="number" placeholder="Dx"> </b-form-input>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row align="center">
            <b-col xs="2" sm="2" md="2" lg="2" xl="2"> </b-col>
            <b-col class="ft-color-section-title">
              <h5>{{ getLabelTraduora("patient.cardiology.lblCommonCarotid") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title">
              <h5>{{ getLabelTraduora("patient.cardiology.lblBulbCarotid") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title">
              <h5>{{ getLabelTraduora("patient.cardiology.lblInternalCarotid") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title">
              <h5>{{ getLabelTraduora("patient.cardiology.lblExternalCarotid") }}</h5>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>{{ getLabelTraduora("patient.cardiology.lblStenosysRange") }}</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.rangeStenosiCcSn" :options="stenosiOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.rangeStenosiCcDx" :options="stenosiOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.rangeStenosiBSn" :options="stenosiOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.rangeStenosiBDx" :options="stenosiOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.rangeStenosiCiSn" :options="stenosiOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.rangeStenosiCiDx" :options="stenosiOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.rangeStenosiCeSn" :options="stenosiOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.rangeStenosiCeDx" :options="stenosiOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>{{ getLabelTraduora("patient.cardiology.lblEcogenGW") }}</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.ecogenCcSn" :options="ecogenOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.ecogenCcDx" :options="ecogenOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.ecogenBSn" :options="ecogenOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.ecogenBDx" :options="ecogenOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.ecogenCiSn" :options="ecogenOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.ecogenCiDx" :options="ecogenOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.ecogenCeSn" :options="ecogenOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.ecogenCeDx" :options="ecogenOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>{{ getLabelTraduora("patient.cardiology.lblStent") }}</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.stentCcSn" :options="yesNoOptions">
                    <template #first>
                      <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }} </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.stentCcDx" :options="yesNoOptions">
                    <template #first>
                      <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }} </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.stentBSn" :options="yesNoOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.stentBDx" :options="yesNoOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.stentCiSn" :options="yesNoOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.stentCiDx" :options="yesNoOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.stentCeSn" :options="yesNoOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.stentCeDx" :options="yesNoOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="ft-color-section-title" xs="2" sm="2" md="2" lg="2" xl="2">
              <h5>{{ getLabelTraduora("patient.cardiology.lblEndoarterect") }}</h5>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.endoartCcSn" :options="yesNoOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.endoartCcDx" :options="yesNoOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.endoartBSn" :options="yesNoOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.endoartBDx" :options="yesNoOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.endoartCiSn" :options="yesNoOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.endoartCiDx" :options="yesNoOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
            <b-col class="ft-color-section">
              <b-row>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>
                  <b-form-select v-model="jsonData.endoartCeSn" :options="yesNoOptions"></b-form-select>
                </b-col>
                <b-col style="margin-top: -8px" xs="12" sm="12" md="6" lg="6" xl="6">
                  <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>
                  <b-form-select v-model="jsonData.endoartCeDx" :options="yesNoOptions"> </b-form-select>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblReportNotes')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
              <b-form-textarea v-model="jsonData.noteLibere" rows="4" no-resize></b-form-textarea>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComments") }}</label>
              <b-form-textarea v-model="jsonData.commento" rows="6" no-resize></b-form-textarea>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCardiovascularRiskFactors')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertension") }}</label>
              <b-form-checkbox v-model="jsonData.ipertensione" switch :checked="jsonData.ipertensione"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemia") }}</label>
              <b-form-checkbox v-model="jsonData.dislipidemia" switch :checked="jsonData.dislipidemia"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabetes") }}</label>
              <b-form-checkbox v-model="jsonData.diabete" switch :checked="jsonData.diabete"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</label>
              <b-form-checkbox v-model="jsonData.obesita" switch :checked="jsonData.obesita"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSmoking") }}</label>
              <b-form-checkbox v-model="jsonData.fumo" switch :checked="jsonData.fumo"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCerebrovascularAccidentsFamiliarity") }}</label>
              <b-form-checkbox v-model="jsonData.familCerebrovascolari" switch :checked="jsonData.familCerebrovascolari"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIDiseaseFamiliarity") }}</label>
              <b-form-checkbox v-model="jsonData.familCardiopatiaIsch" switch :checked="jsonData.familCardiopatiaIsch"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</label>
              <b-form-checkbox v-model="jsonData.familIpertensione" switch :checked="jsonData.familIpertensione"> </b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNormExamination") }}</label>
              <b-form-checkbox v-model="jsonData.esameNellaNorma" switch :checked="jsonData.esameNellaNorma"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMildAtherosclerosis") }}</label>
              <b-form-checkbox v-model="jsonData.aterosclerosiLieve" switch :checked="jsonData.aterosclerosiLieve"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModerateAtherosclerosis") }}</label>
              <b-form-checkbox v-model="jsonData.aterosclerosiModerata" switch :checked="jsonData.aterosclerosiModerata"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSevereAtherosclerosis") }}</label>
              <!-- <b-form-checkbox v-model="jsonData.aterosclerosiSevera" switch :checked="jsonData.aterosclerosiSevera"></b-form-checkbox> -->
              <b-form-checkbox v-model="aterosclerosiSevera" switch :checked="aterosclerosiSevera" :onclick="checkSevera()"></b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCommonCarotidStenosisRight") }}</label>
              <b-form-checkbox v-model="jsonData.stenosiCcDx" switch :checked="jsonData.stenosiCcDx"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCommonCarotidStenosisLeft") }}</label>
              <b-form-checkbox v-model="jsonData.stenosiCcSx" switch :checked="jsonData.stenosiCcSx"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBulbCarotidStenosisRight") }}</label>
              <b-form-checkbox v-model="jsonData.stenosiCeDx" switch :checked="jsonData.stenosiCeDx"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBulbCarotidStenosisLeft") }}</label>
              <b-form-checkbox v-model="jsonData.stenosiCeSx" switch :checked="jsonData.stenosiCeSx"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2"
              ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInternalCarotidStenosisRight") }}</label>
              <b-form-checkbox v-model="jsonData.stenosiCiDx" switch :checked="jsonData.stenosiCiDx"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInternalCarotidStenosisLeft") }}</label>
              <b-form-checkbox v-model="jsonData.stenosiCiSx" switch :checked="jsonData.stenosiCiSx"> </b-form-checkbox>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSignificantNon-hemodynamicAtherosclerosis ") }}</label>
              <b-form-checkbox v-model="aterosclerosiNonEmoSign" switch :checked="aterosclerosiNonEmoSign" :onclick="checkNonEmoSign()"></b-form-checkbox>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return {
          pas: 0,
          cbmmax: 0,
          cicoldx: "",
          bsa: 0,
          bmi: 0,
          maximtnearcedx: 0,
          meanimtfarbsx: 0,
          meanimtfarbdx: 0,
          cescoresn: 0,
          ciscoredx: 0,
          ciscoresn: 0,
          bscoredx: 0,
          bscoresx: 0,
          ccscoredx: 0,
          maximtnearcidx: 0,
          meanimtfarcisx: 0,
          meanimtfarcidx: 0,
          cicolsn: "",
          cecoldx: "",
          cecolsn: "",
          bcoldx: "",
          bcolsn: "",
          cccoldx: "",
          meanimtfarcesx: 0,
          meanimtfarcedx: 0,
          maximtfarccsx: 0,
          maximtfarccdx: 0,
          maximtfarbsx: 0,
          maximtfarbdx: 0,
          maximtfarcisx: 0,
          maximtfarcidx: 0,
          maximtfarcesx: 0,
          maximtfarcedx: 0,
          meanimtnearccsx: 0,
          meanimtnearccdx: 0,
          meanimtnearbsx: 0,
          meanimtnearbdx: 0,
          meanimtnearcisx: 0,
          meanimtnearcidx: 0,
          meanimtnearcesx: 0,
          meanimtnearcedx: 0,
          maximtnearcesx: 0,
          maximtnearcisx: 0,
          maximtnearbdx: 0,
          maximtnearbsx: 0,
          maximtnearccdx: 0,
          maximtnearccsx: 0,
          peso: 0,
          cccolsn: "",
          altezza: 0,
          pad: 0,
          fc: 0,
          imtmax: 0,
          meanmax: 0,
          cbmmean: 0,
          meanimtfarccsx: 0,
          meanimtfarccdx: 0,
          ccscoresn: 0,
          cescoredx: 0,
          noteLibere: "",
          familIpertensione: "",
          familCardiopatiaIsch: "",
          familCerebrovascolari: "",
          fumo: "",
          obesita: "",
          diabete: "",
          img4: "",
          img2: "",
          img3: "",
          commento1: "",
          commento2: "",
          commento3: "",
          commento4: "",
          img1: "",
          dislipidemia: "",
          ipertensione: "",
          stenosiCeDx: "",
          stenosiCeSx: "",
          stenosiCiDx: "",
          stenosiCiSx: "",
          stenosiCcSx: "",
          aterosclerosiLieve: "",
          stenosiCcDx: "",
          aterosclerosiModerata: "",
          esameNellaNorma: "",
          videotape: "",
          commento: "",
          protocollo: "",
          codicePrescrizione: "",
          dataPrescrizione: null,
          patientid: "",
          studyuid: "",
          vosOr: 0,
          vosCf: 0,
          checkFi: "",
          filmati: "",
          rangeStenosiCcSn: "",
          rangeStenosiCcDx: "",
          rangeStenosiBSn: "",
          rangeStenosiBDx: "",
          rangeStenosiCiSn: "",
          rangeStenosiCiDx: "",
          rangeStenosiCeSn: "",
          rangeStenosiCeDx: "",
          ecogenCcSn: "",
          ecogenCcDx: "",
          ecogenBSn: "",
          ecogenBDx: "",
          ecogenCiSn: "",
          ecogenCiDx: "",
          ecogenCeSn: "",
          ecogenCeDx: "",
          stentCcSn: "",
          stentCcDx: "",
          stentBSn: "",
          stentBDx: "",
          stentCiSn: "",
          stentCiDx: "",
          stentCeSn: "",
          stentCeDx: "",
          endoartCcSn: "",
          endoartCcDx: "",
          endoartBSn: "",
          endoartBDx: "",
          endoartCiSn: "",
          endoartCiDx: "",
          endoartCeSn: "",
          endoartCeDx: "",
          aterosclerosiSevera: "",
          aterosclerosiNonEmoSign: "",
          psvIca: 0,
          psvCca: 0,
          psvRatio: 0,
        };
      },
    },
  },
  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardioecocarotidi",
      aterosclerosiNonEmoSign: false,
      aterosclerosiSevera: false,
      yesNoOptions: [
        { text: this.getLabelTraduora("patient.lblYes"), value: "patient.lblYes" },
        { text: this.getLabelTraduora("patient.lblNo"), value: "patient.lblNo" },
      ],
      colorOptions: [
        { text: "L", value: "L" },
        { text: "T", value: "T" },
      ],
      stenosiOptions: [
        { text: "0 - 29 %", value: "0-29%" },
        { text: "30 - 49 %", value: "30-49%" },
        { text: "50 - 69 %", value: "50-69%" },
        { text: "70 - 89 %", value: "70-89%" },
        { text: "90 - 99 %", value: "90-99%" },
        { text: this.getLabelTraduora("patient.cardiology.slcStenosisOcclusion"), value: "patient.cardiology.slcStenosisOcclusion" },
        { text: "NA", value: "NA" },
        { text: "NES", value: "NES" },
      ],
      scoreOptions: [
        { text: "0", value: 0 },
        { text: "1", value: 1 },
        { text: "2", value: 2 },
        { text: "3", value: 3 },
        { text: "4", value: 4 },
        { text: "5", value: 5 },
      ],
      ecogenOptions: [
        { text: "I", value: "I" },
        { text: "II", value: "II" },
        { text: "III", value: "III" },
        { text: "IV", value: "IV" },
        { text: "V", value: "V" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },

  methods: {
    onCalcoloBmiBsa(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
      me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
    checkNonEmoSign() {
      let me = this;
      if (me.aterosclerosiNonEmoSign === true) {
        me.jsonData.aterosclerosiNonEmoSign = "S";
      } else {
        me.jsonData.aterosclerosiNonEmoSign = "N";
      }
    },
    checkSevera() {
      let me = this;
      if (me.aterosclerosiSevera === true) {
        me.jsonData.aterosclerosiSevera = "S";
      } else {
        me.jsonData.aterosclerosiSevera = "N";
      }
    },
    checkNonEmoSignOnLoad() {
      let me = this;
      if (me.jsonData.aterosclerosiNonEmoSign === "S") {
        me.aterosclerosiNonEmoSign = true;
      } else {
        me.aterosclerosiNonEmoSign = false;
      }
    },
    checkSeveraOnLoad() {
      let me = this;
      if (me.jsonData.aterosclerosiSevera === "S") {
        me.aterosclerosiSevera = true;
      } else {
        me.aterosclerosiSevera = false;
      }
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
