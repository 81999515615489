<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
        <template slot="tab-content-event">
            <gastro-anamnesi-fisiologica-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></gastro-anamnesi-fisiologica-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import GastroAnamnesiFisiologicaEditComponent from "./GastroAnamnesiFisiologicaEditComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    mixins: [UtilityMixin],
    components: { EventiEditComponent, GastroAnamnesiFisiologicaEditComponent },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    data() {
        return {
            linkback: null,
            titoloEvento: this.getLabelTraduora("patients.gastroenterology.physiologicalHistory"),
            pathResource: "/gastroanamnesifisiologica",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            jsonData: {
                dataEvento: null,
                fumo: null,
                quantitaFumo: null,
                bevandeAlcoliche: null,
                quantitaBevandeAlcoliche: null,
                caffe: null,
                quantitaCaffe: null,
            },
        };
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
