<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Clinici" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAS") }}</label>
          <b-form-input v-model="jsonData.pas" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAD") }}</label>
          <b-form-input v-model="jsonData.pad" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="1" lg="1" xl="1">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblFC") }}
          </label>
          <b-form-input v-model="jsonData.fc" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="1" lg="1" xl="1">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
          <b-form-input v-model="jsonData.peso" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
          <b-form-input v-model="jsonData.altezza" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndicazioneClinica") }}</label>
          <b-form-select v-model="jsonData.indicazioneClinica" :options="indicazioniClinicheOptions">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblECG") }}</label>
          <b-form-select v-model="jsonData.ecg" :options="ecgOptions">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSezioniSinistre')" header-tag="header" footer-tag="footer" title="">
      <template #header>
        <b-row>
          <b-col cols="12" xs="10" sm="10" md="10" lg="10" xl="10">
            <span>Sezioni Sinistre</span>
          </b-col>
          <b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
            <b-button variant="outline-secondary outline-secondary-noborder pull-right" size="sm" @click="onClickCalcolaValori">
              <i style="margin-top: 2px" class="fas fa-calculator"></i>
              <span style="padding-left: 5px">Calcola</span>
            </b-button>
          </b-col>
        </b-row>
      </template>

      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVStd") }}</label>
          <b-form-input v-model="jsonData.vstd" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVSts") }}</label>
          <b-form-input v-model="jsonData.vsts" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSIVtd") }}</label>
          <b-form-input v-model="jsonData.sivtd" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSIVts") }}</label>
          <b-form-input v-model="jsonData.sivts" @keyup.enter="calcolaValori()" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPPtd") }}</label>
          <b-form-input v-model="jsonData.pptd" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPPts") }}</label>
          <b-form-input v-model="jsonData.ppts" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRR") }}</label>
          <b-form-input v-model="jsonData.rr" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAorta") }}</label>
          <b-form-input v-model="jsonData.aorta" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAtrio") }}</label>
          <b-form-input v-model="jsonData.atrio" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblBMI") }}
          </label>
          <b-form-input v-model="jsonData.bmi" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblBSA") }}
          </label>
          <b-form-input v-model="jsonData.bsa" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblCOT") }}
          </label>
          <b-form-input v-model="jsonData.cot" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblTPRT") }}
          </label>
          <b-form-input v-model="jsonData.tprt" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblLVMid") }}
          </label>
          <b-form-input v-model="jsonData.lvmidp" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblLVMis") }}
          </label>
          <b-form-input v-model="jsonData.lvmisp" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lbleft") }}
          </label>
          <b-form-input v-model="jsonData.eft" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="1" lg="1" xl="1">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblsft") }}
          </label>
          <b-form-input v-model="jsonData.sft" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="1" lg="1" xl="1">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblRWT") }}
          </label>
          <b-form-input v-model="jsonData.rwt" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="1" lg="1" xl="1">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblLVMha") }}
          </label>
          <b-form-input v-model="jsonData.lvmha" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="1" lg="1" xl="1">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblESS") }}
          </label>
          <b-form-input v-model="jsonData.ess" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoteVentricoloSinistro") }}</label>
          <b-form-textarea v-model="jsonData.noteVentricoloSinistro" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoteAtrioSinistro") }}</label>
          <b-form-textarea v-model="jsonData.noteAtrioSinistro" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSezioniDestre')" header-tag="header" footer-tag="footer" title="">
      <template #header>
        <b-row>
          <b-col cols="12" xs="10" sm="10" md="10" lg="10" xl="10">
            <span>Sezioni Destre</span>
          </b-col>
          <b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
            <b-button variant="outline-secondary outline-secondary-noborder pull-right" size="sm" @click="onClickCalcolaValori">
              <i style="margin-top: 2px" class="fas fa-calculator"></i>
              <span style="padding-left: 5px">Calcola</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col xs="12" sm="3" md="3" lg="3" xl="1">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVCIi") }}</label>
          <b-form-input v-model="jsonData.vciI" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="1">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVCIe") }}</label>
          <b-form-input v-model="jsonData.vciE" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="1">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTAPSE") }}</label>
          <b-form-input v-model="jsonData.tapse" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="1">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVDtd") }}</label>
          <b-form-input v-model="jsonData.vdtd" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="1">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRVwt") }}</label>
          <b-form-input v-model="jsonData.rvwt" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="1">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2DRVbasal") }}</label>
          <b-form-input v-model="jsonData.dueDRvbasal" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2DRVmedio") }}</label>
          <b-form-input v-model="jsonData.dueDRvmedio" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2DRVlongit") }}</label>
          <b-form-input v-model="jsonData.dueDPvlongit" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRVAED") }}</label>
          <b-form-input v-model="jsonData.rvaed" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRVAES") }}</label>
          <b-form-input v-model="jsonData.rvaes" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblFAC") }}
          </label>
          <b-form-input v-model="jsonData.fac" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPrsistpolm") }}</label>
          <b-form-input v-model="jsonData.prSistPolm" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblADarea") }}</label>
          <b-form-input v-model="jsonData.adArea" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblADdiametro") }}</label>
          <b-form-input v-model="jsonData.adDiametro" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoteVentricoloDestro") }}</label>
          <b-form-textarea v-model="jsonData.noteVentricoloDestro" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNoteAtrioDestro") }}</label>
          <b-form-textarea v-model="jsonData.noteAtrioDestro" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lbl2Dsimpson')" header-tag="header" footer-tag="footer" title="">
      <template #header>
        <b-row>
          <b-col cols="12" xs="10" sm="10" md="10" lg="10" xl="10">
            <span>2D Simpson</span>
          </b-col>
          <b-col cols="12" xs="2" sm="2" md="2" lg="2" xl="2">
            <b-button variant="outline-secondary outline-secondary-noborder pull-right" size="sm" @click="onClickCalcolaValori">
              <i style="margin-top: 2px" class="fas fa-calculator"></i>
              <span style="padding-left: 5px">Calcola</span>
            </b-button>
          </b-col>
        </b-row>
      </template>
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVVD") }}</label>
          <b-form-input v-model="jsonData.lvvdQuattroCh" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVVS") }}</label>
          <b-form-input v-model="jsonData.lvvsQuattroCh" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLAV") }}</label>
          <b-form-input v-model="jsonData.lav" type="number" @keyup.enter="calcolaValori()"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblTPR") }}
          </label>
          <b-form-input v-model="jsonData.tpr" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lbl2DSV") }}
          </label>
          <b-form-input v-model="jsonData.sv" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lbl2DEF") }}
          </label>
          <b-form-input v-model="jsonData.ef" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="4" xl="4">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lbl2DCO") }}
          </label>
          <b-form-input v-model="jsonData.co" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="4" xl="4">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblLAVi") }}
          </label>
          <b-form-input v-model="jsonData.lavI" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="4" xl="4">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblLVVdi") }}
          </label>
          <b-form-input v-model="jsonData.lvvd" readonly></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lbl2DsimpsonBiplana')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVVd2ch") }}</label>
          <b-form-input v-model="jsonData.lvvdDueCh" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVVs2ch") }}</label>
          <b-form-input v-model="jsonData.lvvsDueCh" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLAV2ch") }}</label>
          <b-form-input v-model="jsonData.dueChLav" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSpeckleTracking')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGLSdx") }}</label>
          <b-form-input v-model="jsonData.glsDx" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGLSsx") }}</label>
          <b-form-input v-model="jsonData.glsSn" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblDiastole')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="2" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVE") }}</label>
          <b-form-input v-model="jsonData.pfve" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVA") }}</label>
          <b-form-input v-model="jsonData.pfva" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="3" xl="3">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblEA") }}
          </label>
          <b-form-input v-model="jsonData.ea" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDT") }}</label>
          <b-form-input v-model="jsonData.dt" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIRT") }}</label>
          <b-form-input v-model="jsonData.irt" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVE") }}</label>
          <b-form-input v-model="jsonData.pfveDue" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVA") }}</label>
          <b-form-input v-model="jsonData.pfvaDue" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEA") }}</label>
          <b-form-input v-model="jsonData.eaDue" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblADur") }}</label>
          <b-form-input v-model="jsonData.adur" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="2" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTipoManovra") }}</label>
          <b-form-select v-model="jsonData.tipoManovra" :options="tipiManovraOptions">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblContrattilitaSegmentaria')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="8" lg="8" xl="8">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblBasal") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMedium") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblApex") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoanteriore" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoanterioreMedio" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceSetto" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorApex") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteanteriore" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteanterioreMedia" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceAnt" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatAnteriore" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatAnterioreMedia" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"></b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioFrontSideWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatPosteriore" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteLatPosterioreMedia" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apicePost" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioRearWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteInferiore" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.pareteInferioreMedia" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.apiceInf" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoposteriore" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <b-form-select v-model="jsonData.settoposterioreMedio" :options="segmentiOptions" value-field="value" text-field="text" @change="onCalcolaPunteggio()">
                <template #first>
                  <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
              </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"></b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
              <label class="sa-label-data">Normocinetici</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.normocinetici" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
              <label class="sa-label-data">(*1=)</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.normocineticiCalc" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
              <label class="sa-label-data">Ipocinetici</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.ipocinetici" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
              <label class="sa-label-data">(*2=)</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.ipocineticiCalc" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
              <label class="sa-label-data">Acinetici</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.acinetici" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
              <label class="sa-label-data">(*3=)</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.acineticiCalc" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
              <label class="sa-label-data">Discinetici</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.discinetici" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
              <label class="sa-label-data">(*4=)</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.discineticiCalc" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
              <label class="sa-label-data">Aneurismatici</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.aneurismatici" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
              <label class="sa-label-data">(*5=)</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
              <b-form-input v-model="jsonData.aneurismaticiCalc" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-alert :show="punteggioNotValid" variant="danger" style="margin-top: 10px">{{ messaggio }}</b-alert>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
              <label class="sa-label-data">P. Totale</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
              <b-form-input v-model="jsonData.totale" disabled></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="4" xl="4">
              <label class="sa-label-data">Est. Danno IMA</label>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
              <b-form-input v-model="jsonData.estensioneDanno" disabled></b-form-input>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblH5TdiAnulus')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <h5>{{ getLabelTraduora("patient.cardiology.lblh5Lateral") }}</h5>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">S (cm/sec)</label>
          <b-form-input v-model="jsonData.sTdi" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">E' (cm/sec)</label>
          <b-form-input v-model="jsonData.eTdi" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">A' (sec)</label>
          <b-form-input v-model="jsonData.aiTdi" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">E/E'</label>
          <b-form-input v-model="jsonData.eE1Tdi" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <h5>{{ getLabelTraduora("patient.cardiology.lblh5Septal") }}</h5>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">S (cm/sec)</label>
          <b-form-input v-model="jsonData.sTdiSettale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">E' (cm/sec)</label>
          <b-form-input v-model="jsonData.eTdiSettale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">A' (sec)</label>
          <b-form-input v-model="jsonData.aiTdiSettale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <h5>{{ getLabelTraduora("patient.cardiology.lblh5Tricuspid") }}</h5>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">S (cm/sec)</label>
          <b-form-input v-model="jsonData.sTdiTricuspidalico" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">E' (cm/sec)</label>
          <b-form-input v-model="jsonData.eTdiTricuspidalico" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">A' (sec)</label>
          <b-form-input v-model="jsonData.aiTdiTricuspidalico" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAorta')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnulus") }}</label>
          <b-form-input v-model="jsonData.anulus" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValsalva") }}</label>
          <b-form-input v-model="jsonData.seniValsalva" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGiunzSt") }}</label>
          <b-form-input v-model="jsonData.giunzSt" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAortaAsc") }}</label>
          <b-form-input v-model="jsonData.aortaAscendente" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAortaArch") }}</label>
          <b-form-input v-model="jsonData.aortaArco" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAortaAdd") }}</label>
          <b-form-input v-model="jsonData.aortaAddominale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArea2d") }}</label>
          <b-form-input v-model="jsonData.area2d" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblArea2dIndex") }}
          </label>
          <b-form-input v-model="jsonData.areaDueDIndex" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVOT") }}</label>
          <b-form-input v-model="jsonData.lvot" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblContractedVein") }}</label>
          <b-form-input v-model="jsonData.venaContracataAorta" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVOTVTI") }}</label>
          <b-form-input v-model="jsonData.lvotv1" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCWAoVTI") }}</label>
          <b-form-input v-model="jsonData.cwaov1" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMax") }}</label>
          <b-form-input v-model="jsonData.pgmaxAorta" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMEDIUM") }}</label>
          <b-form-input v-model="jsonData.pgMedio" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPHT") }}</label>
          <b-form-input v-model="jsonData.pht" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAreaDoppler") }}</label>
          <b-form-input v-model="jsonData.areaDoppler" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data ft-color-calculated-fields">
            <i class="fas fa-calculator"></i>
            {{ getLabelTraduora("patient.cardiology.lblAreaDopplerI") }}
          </label>
          <b-form-input v-model="jsonData.areaDopplerI" readonly></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMitralHero") }}</label>
          <b-form-input v-model="jsonData.eroaAorta" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSA") }}</label>
          <b-form-select v-model="jsonData.sa" :options="plus4Options">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIA") }}</label>
          <b-form-select v-model="jsonData.ia" :options="plus4Options">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("agendas.lblDescription") }}</label>
          <b-form-textarea max-rows="5" no-resize v-model="jsonData.descrizioneAorta"></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValveProstheses") }}</label>
          <!-- <b-form-select v-model="jsonData.protesiValvolariAorta">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select> -->
          <b-form-input v-model="jsonData.protesiValvolariAorta" type="text"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblMitral')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblArea2d") }}</label>
          <b-form-input v-model="jsonData.areaDueDMitrale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAreaDoppler") }}</label>
          <b-form-input v-model="jsonData.areaDopplerMitrale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMediumGrad") }}</label>
          <b-form-input v-model="jsonData.gradMedio" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPHT") }}</label>
          <b-form-input v-model="jsonData.phtMitrale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblContractedVein") }}</label>
          <b-form-input v-model="jsonData.venaContracataMitrale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="2">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMitralHero") }}</label>
          <b-form-input v-model="jsonData.eroaMitrale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRegurgitating") }}</label>
          <b-form-input v-model="jsonData.volRigurgitanteMitrale" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIM") }}</label>
          <b-form-select v-model="jsonData.im" :options="plus4Options">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="2" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSM") }}</label>
          <b-form-select v-model="jsonData.sm" :options="plus4Options">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("agendas.lblDescription") }}</label>
          <b-form-textarea max-rows="5" no-resize v-model="jsonData.descrizioneMitrale"></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValveProstheses") }}</label>
          <!-- <b-form-select v-model="jsonData.protesiValvolariMitrale">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select> -->
          <b-form-input v-model="jsonData.protesiValvolariMitrale" type="text"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Wilkins Score" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMobility") }}</label>
          <b-form-select v-model="jsonData.mobilita" @input="calcoloWilkinsScore(jsonData.ispessimentoLembi, jsonData.calcificazioni, jsonData.ispessimentoAppSottvalv, jsonData.mobilita)" :options="wcMobilitaOptions" value-field="value" text-field="descrizione">
            <template #first>
              <b-form-select-option disabled :value="0">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblThickeningFlaps") }}</label>
          <b-form-select v-model="jsonData.ispessimentoLembi" @input="calcoloWilkinsScore(jsonData.ispessimentoLembi, jsonData.calcificazioni, jsonData.ispessimentoAppSottvalv, jsonData.mobilita)" :options="wcLembiOptions" value-field="value" text-field="descrizione">
            <template #first>
              <b-form-select-option disabled :value="0">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblThickeningSubvalvularApparatus") }}</label>
          <b-form-select v-model="jsonData.ispessimentoAppSottvalv" @input="calcoloWilkinsScore(jsonData.ispessimentoLembi, jsonData.calcificazioni, jsonData.ispessimentoAppSottvalv, jsonData.mobilita)" :options="wcSottovalvolareOptions" value-field="value" text-field="descrizione">
            <template #first>
              <b-form-select-option disabled :value="0">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCalcification") }}</label>
          <b-form-select v-model="jsonData.calcificazioni" @input="calcoloWilkinsScore(jsonData.ispessimentoLembi, jsonData.calcificazioni, jsonData.ispessimentoAppSottvalv, jsonData.mobilita)" :options="wcCalcificazioniOptions" value-field="value" text-field="descrizione">
            <template #first>
              <b-form-select-option disabled :value="0">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="1" xl="1">
          <label class="sa-label-data">Score Tot.</label>
          <b-form-input class="text-center" v-model="jsonData.wilkinsScoreMitrale" readonly></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPulmonary')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMax") }}</label>
              <b-form-input v-model="jsonData.vmaxPolmonare" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIP") }}</label>
              <b-form-select v-model="jsonData.ip" :options="plus4Options"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSP") }}</label>
              <b-form-select v-model="jsonData.sp" :options="plus4Options"></b-form-select>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("agendas.lblDescription") }}</label>
              <b-form-textarea max-rows="5" no-resize v-model="jsonData.descrizionePolmonare"></b-form-textarea>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValveProstheses") }}</label>
              <!-- <b-form-select v-model="jsonData.protesiValvolariPolmonare">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select> -->
              <b-form-input v-model="jsonData.protesiValvolariPolmonare" type="text"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPulmonaryDoppler')" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">S (cm/sec)</label>
              <b-form-input v-model="jsonData.s" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">D (cm/sec)</label>
              <b-form-input v-model="jsonData.d" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPV") }}</label>
              <b-form-input v-model="jsonData.apv" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPD") }}</label>
              <b-form-input v-model="jsonData.apd" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data ft-color-calculated-fields">
                <i class="fas fa-calculator"></i>
                {{ getLabelTraduora("patient.cardiology.lblARAPD") }}
              </label>
              <b-form-input v-model="jsonData.arApd" readonly></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblTricuspid')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMax") }}</label>
          <b-form-input v-model="jsonData.vmaxTricuspide" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIT") }}</label>
          <b-form-select v-model="jsonData.it" :options="plus4Options"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblST") }}</label>
          <b-form-input v-model="jsonData.st" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblJetRigTric") }}</label>
          <b-form-input v-model="jsonData.jetRigTric" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("agendas.lblDescription") }}</label>
          <b-form-textarea max-rows="5" no-resize v-model="jsonData.protesiValvolariTricuspide"></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblValveProstheses") }}</label>
          <!-- <b-form-select v-model="jsonData.protesiValvolariTricuspide">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select> -->
          <b-form-input v-model="jsonData.protesiValvolariTricuspide" type="text"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPericardium") }}</label>
          <b-form-input v-model="jsonData.pericardio" type="text"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
      <template #header>
        <span>{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</span>
        <eventi-dizionari-component :tipoEvento="tipoEvento" :gruppo="gruppo" tipoDizionario="CONCLUSIONI" @update="onUpdateConclusioni"></eventi-dizionari-component>
      </template>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-form-textarea v-model="jsonData.conclusioni" no-resize rows="6" style="margin-top: 3px"></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblDiagnosticImpression')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Impressioni Diagnostiche</label>
          <b-form-select v-model="jsonData.impressioniDiagnostiche" :options="impressioniDiagnosticheOptions">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <!-- <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblDiagnosticImpression')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Impressioni Diagnostiche</label>
          <b-form-select v-model="jsonData.impressioniDiagnostiche">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card> -->
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiDizionariComponent from "../../../base/components/EventiDizionariComponent.vue";
export default {
  mixins: [UtilityMixin],
  components: { DatePicker, EventiDizionariComponent },
  props: {
    tipoEvento: String,
    gruppo: String,
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },
  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      pathResource: "/cardioecocardiodati",
      pathResourceSegmenti: "/cardioecostressopzioni",
      pathResourceSegmentiWilkinsScore: "/wilkinsscore",
      pathResourceCalcolaValori: "/cardioecocardiodati/calcola",
      pathResourceImpressioniDiagnostiche: "/cardioecocardiodatiimpressionidiagnostiche",
      showModalLoading: false,
      segmentiOptions: [],
      opzioni: [],
      // opzioniImpressioniDiagnostiche: [],
      wcMobilitaOptions: [],
      wcLembiOptions: [],
      wcSottovalvolareOptions: [],
      wcCalcificazioniOptions: [],
      indicazioniClinicheOptions: [],
      ecgOptions: [
        { value: null, text: "-Seleziona Valore-" },
        { value: "NORMALE", text: "NORMALE" },
        { value: "ANORMALE", text: "ANORMALE" },
        { value: "IVS", text: "IVS" },
        { value: "BBdx", text: "BBdx" },
        { value: "EAS", text: "EAS" },
        { value: "ISCHEMICO", text: "ISCHEMICO" },
        { value: "BAV I", text: "BAV I" },
        { value: "BAV II", text: "BAV II" },
        { value: "BAV III", text: "BAV III" },
        { value: "EIDDD", text: "EIDDD" },
        { value: "EIVVI", text: "EIVVI" },
        { value: "BESV", text: "BESV" },
        { value: "BEV", text: "BEV" },
        { value: "FIB. ATRIALE", text: "FIB. ATRIALE" },
        { value: "FLUTTER ATRIALE", text: "FLUTTER ATRIALE" },
        { value: "GIUNAZIONALE", text: "GIUNAZIONALE" },
        { value: "NECROSI ANTERIORE", text: "NECROSI ANTERIORE" },
        { value: "NECROSI INFERIORE", text: "NECROSI INFERIORE" },
        { value: "NECROSI LATERALE", text: "NECROSI LATERALE" },
        { value: "NECROSI SETTALE", text: "NECROSI SETTALE" },
      ],
      punteggioNotValid: false,
      messaggio: "Uno dei valori inseriti e' INDETERMINABILE. Impossibile procedere al calcolo",
      plus4Options: [
        { text: "++++/4", value: "++++/4" },
        { text: "+++/4", value: "+++/4" },
        { text: "++/4", value: "++/4" },
        { text: "+/4", value: "+/4" },
      ],
      tipiManovraOptions: [
        { text: this.getLabelTraduora("patient.cardiology.slcManeuverValsalva"), value: "patient.cardiology.slcManeuverValsalva" },
        { text: this.getLabelTraduora("patient.cardiology.slcManeuverLegsUp"), value: "patient.cardiology.slcManeuverLegsUp" },
        { text: this.getLabelTraduora("patient.cardiology.slcManeuverNoManeuver"), value: "patient.cardiology.slcManeuverNoManeuver" },
      ],
      paternRiempimentoOptions: [],
      settoAnterioreOptions: [],
      settoAnterioreMedioOptions: [],
      apiceSettoOptions: [],
      settoPosterioreOptions: [],
      settoPosterioreMedioOptions: [],
      pareteLateraleAnterioreOptions: [],
      pareteLateraleAnterioreMediaOptions: [],
      impressioniDiagnosticheOptions: [],
      normocinetici: 0,
      ipocinetici: 0,
      acinetici: 0,
      discinetici: 0,
      aneurismatici: 0,
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadDefaultData();
    // me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadSegmenti();
      me.loadSegmentiWilkinsScore();
      me.loadImpressioniDiagnostiche();
    },
    onCalcoloBmiBsa(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
      me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.onCalcolaPunteggio();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
          me.onCalcolaPunteggio();
        });
      }
    },
    loadImpressioniDiagnostiche() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceImpressioniDiagnostiche;
      me.impressioniDiagnosticheOptions = [];
      axios.get(link).then((response) => {
        // me.opzioniImpressioniDiagnostiche = response.data.data.list;
        response.data.data.list.forEach((element) => {
          me.impressioniDiagnosticheOptions.push({ text: element.descrizione, value: element.descrizione });
        });
        me.showModalLoading = false;
      });
    },
    loadSegmenti() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceSegmenti;
      axios.get(link).then((response) => {
        me.opzioni = response.data.data.list;
        response.data.data.list.forEach((element) => {
          me.segmentiOptions.push({ text: element.descrizione, value: element.descrizione });
          me.loadData();
        });
        me.showModalLoading = false;
      });
    },
    onCalcolaPunteggio() {
      let me = this;
      me.normocinetici = 0;
      me.ipocinetici = 0;
      me.acinetici = 0;
      me.discinetici = 0;
      me.aneurismatici = 0;
      let punteggioTotale = 0;
      let estensioneDannoIma = 0;
      let verificaValiditaPunteggio = true;
      me.opzioni.forEach((element) => {
        if (me.jsonData.settoanteriore === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale = element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoanterioreMedio === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceSetto === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteanteriore === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteanterioreMedia === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceAnt === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatAnteriore === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatAnterioreMedia === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatPosteriore === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteLatPosterioreMedia === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apicePost === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteInferiore === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.pareteInferioreMedia === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.apiceInf === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoposteriore === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
        if (me.jsonData.settoposterioreMedio === element.descrizione) {
          me.contaValori(element.descrizione);
          punteggioTotale += element.valorePunteggioTotale;
          estensioneDannoIma += element.valoreIma;
          verificaValiditaPunteggio = element.valorePunteggioTotale !== -1 && verificaValiditaPunteggio === true;
        }
      });
      me.punteggioNotValid = !verificaValiditaPunteggio;
      if (verificaValiditaPunteggio) {
        me.jsonData.totale = punteggioTotale;
        me.jsonData.estensioneDanno = estensioneDannoIma;
        me.jsonData.normocinetici = me.normocinetici;
        me.jsonData.normocineticiCalc = me.normocinetici;
        me.jsonData.ipocinetici = me.ipocinetici;
        me.jsonData.ipocineticiCalc = me.ipocinetici * 2;
        me.jsonData.acinetici = me.acinetici;
        me.jsonData.acineticiCalc = me.acinetici * 3;
        me.jsonData.discinetici = me.discinetici;
        me.jsonData.discineticiCalc = me.discinetici * 4;
        me.jsonData.aneurismatici = me.aneurismatici;
        me.jsonData.aneurismaticiCalc = me.aneurismatici * 5;
      } else {
        me.jsonData.totale = null;
        me.jsonData.estensioneDanno = null;
        me.jsonData.normocinetici = null;
        me.jsonData.normocineticiCalc = null;
        me.jsonData.ipocinetici = null;
        me.jsonData.ipocineticiCalc = null;
        me.jsonData.acinetici = null;
        me.jsonData.acineticiCalc = null;
        me.jsonData.discinetici = null;
        me.jsonData.discineticiCalc = null;
        me.jsonData.aneurismatici = null;
        me.jsonData.aneurismaticiCalc = null;
      }
    },
    contaValori(value) {
      let me = this;
      switch (value) {
        case "NORMOCINETICO":
          me.normocinetici++;
          break;
        case "IPOCINETICO":
          me.ipocinetici++;
          break;
        case "ACINETICO":
          me.acinetici++;
          break;
        case "DISCINETICO":
          me.discinetici++;
          break;
        case "ANEURISMATICO":
          me.aneurismatici++;
          break;

        default:
          break;
      }
    },
    loadSegmentiWilkinsScore() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceSegmentiWilkinsScore + "?forPage=100&page=1";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          switch (element.tipoSelect) {
            case "MOBILITA":
              me.wcMobilitaOptions.push({ descrizione: this.getLabelTraduora(element.descrizione), value: element.valueSelect });
              break;
            case "LEMBI":
              me.wcLembiOptions.push({ descrizione: this.getLabelTraduora(element.descrizione), value: element.valueSelect });
              break;
            case "SOTTOVALVOLARE":
              me.wcSottovalvolareOptions.push({ descrizione: this.getLabelTraduora(element.descrizione), value: element.valueSelect });
              break;
            case "CALCIFICAZIONI":
              me.wcCalcificazioniOptions.push({ descrizione: this.getLabelTraduora(element.descrizione), value: element.valueSelect });
              break;

            default:
              break;
          }
        });
        me.showModalLoading = false;
      });
    },
    calcoloWilkinsScore(ispessimentoLembi, calcificazioni, ispessimentoAppSottvalv, mobilita) {
      let me = this;
      let valueMobilita = parseInt(mobilita);
      let valueCalcificazioni = parseInt(calcificazioni);
      let valueIspessimentoAppSottvalv = parseInt(ispessimentoAppSottvalv);
      let valueIspessimentoLembi = parseInt(ispessimentoLembi);
      me.jsonData.wilkinsScoreMitrale = valueMobilita + valueCalcificazioni + valueIspessimentoAppSottvalv + valueIspessimentoLembi;
    },
    onCalcoloLVMidEF() {},
    onClickCalcolaValori() {
      let me = this;
      me.calcolaValori();
    },
    calcolaValori() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceCalcolaValori;
      let jsonData = JSON.stringify(me.jsonData);
      axios.post(link, jsonData).then((response) => {
        me.jsonData.bsa = response.data.data.bsa;
        me.jsonData.lvmidp = response.data.data.lvmidp;
        me.jsonData.eft = response.data.data.eft;
        me.jsonData.sft = response.data.data.sft;
        me.jsonData.rwt = response.data.data.rwt;
        me.jsonData.lvmha = response.data.data.lvmha;
        me.jsonData.fc = response.data.data.fc;
        me.jsonData.cot = response.data.data.cot;
        me.jsonData.tprt = response.data.data.tprt;
        me.jsonData.lvmisp = response.data.data.lvmisp;
        me.jsonData.ess = response.data.data.ess;
        me.jsonData.ea = response.data.data.ea;
        me.jsonData.fac = response.data.data.fac;
        me.jsonData.sv = response.data.data.sv;
        me.jsonData.ef = response.data.data.ef;
        me.jsonData.co = response.data.data.co;
        me.jsonData.tpr = response.data.data.tpr;
        me.jsonData.lvvd = response.data.data.lvvd;
        me.showModalLoading = false;
      });
    },
    onUpdateJsonData(data) {
      console.log(data);
    },
    onUpdateConclusioni(data) {
      let me = this;
      me.jsonData.conclusioni = data;
      this.$emit("update", me.jsonData);
    },
  },
};
</script>
