<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblOrigin") }}</label>
                    <b-form-input v-model="jsonData.provenienza"></b-form-input>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("pazienti.egds.lblInstrument") }}</label>
                    <b-form-select v-model="jsonData.strumenti" :options="strumentiOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                </b-col> -->
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Premedicazione</label>
                    <b-form-textarea v-model="jsonData.premedicazione" type="text" rows="12" no-resize></b-form-textarea>
                </b-col> -->
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi</label>
                    <b-form-textarea v-model="jsonData.diagnosi" rows="12" type="text" no-resize></b-form-textarea>
                </b-col>
                <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Corpo Referto</label>
                    <b-form-textarea v-model="jsonData.corpoReferto" type="text" rows="12" no-resize></b-form-textarea>
                </b-col> -->
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Consigli Terapeutici</label>
                    <b-form-textarea v-model="jsonData.consigliTerapeutici" type="text" rows="12" no-resize></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblReport") }}</label>
                    <b-form-textarea v-model="jsonData.referto" rows="12" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <eventi-operatori-component :operatori="jsonData.listaOperatori" @update="onUpdateListaOperatori" :isEdit="isEdit"></eventi-operatori-component>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
    components: { DatePicker, EventiOperatoriComponent },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    provenienza: null,
                    referto: "",
                    diagnosi: null,
                    corpoReferto: null,
                    // premedicazione: null,
                    consigliTerapeutici: null,
                    // strumenti: null,
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        // me.loadStrumenti();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            isEdit: true,
            pathResource: "/gastrovisitagastroenterologica",
            // pathResourceStrumenti: "/pazienteeventistrumenti",
            selectFirstElement: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            // strumentiOptions: [{ value: null, text: "-Seleziona Strumento-" }],
            filtro: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        onUpdateListaOperatori(listaOperatori) {
            let me = this;
            me.jsonData.listaOperatori = listaOperatori;
        },
        // loadStrumenti() {
        //     let me = this;
        //     //  me.filtro.page = me.currentPage;
        //     //    me.filtro.forPage = me.perPage;
        //     me.filtro.gruppo = "GASTRO";
        //     let link = process.env.VUE_APP_PATH_API + me.pathResourceStrumenti;
        //     axios
        //         .get(link, { params: me.filtro })
        //         .then((response) => {
        //             me.showModalLoading = false;
        //             response.data.data.list.forEach((element) => {
        //                 me.strumentiOptions.push({ value: element.modello + " (MAT: " + element.codice + ")", text: element.modello + " (MAT: " + element.codice + ")" });
        //             });
        //         })
        //         .catch();
        // },
    },
};
</script>

<style></style>
