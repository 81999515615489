<template>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
        <div>
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6"></b-col>
                <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6" class="text-right">
                    <b-button size="sm" variant="success" @click="onRefresh">Refresh</b-button>
                </b-col>
            </b-row>
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="allegati" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(statoFirma)="{ item }">
                        <b-button v-if="item.statoFirma == 'FIRMATO'" v-b-tooltip.hover.right="onHoverStatoFirma(item)" variant="outline">
                            <font-awesome-icon icon="signature" class="text-success" />
                        </b-button>
                        <b-button v-else v-b-tooltip.hover.right="onHoverStatoFirma(item)" variant="outline">
                            <font-awesome-icon icon="signature" class="text-danger" />
                        </b-button>
                    </template>
                    <template #cell(actions)="row">
                        <b-button-group>
                            <b-button size="sm" variant="btn btn-outline-purple waves-effect waves-light width-sm no-text" style="margin-right: 5px" @click="onVisualizzaAllegato(row.item)">
                                <i class="bi bi-file-pdf"></i>
                            </b-button>
                        </b-button-group>
                    </template>
                </b-table>
            </div>
            <b-modal ref="mdlAllegati" id="mdlAllegati" size="xl" style="height: 100%">
                <paziente-allegati-edit-component :jsonData="data" :tipoRisorsa="tipoRisorsa"></paziente-allegati-edit-component>
                <template #modal-footer="{ btnSalva }">
                    <b-button :id="btnSalva" size="sm" variant="success" @click="onSalva">Salva</b-button>
                </template>
            </b-modal>
            <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%">
                <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
            </b-modal>
        </div>
    </b-card>
</template>
<script>
import moment from "moment";
import PazienteAllegatiEditComponent from "../../paziente/allegati/components/PazienteAllegatiEditComponent.vue";
import axios from "axios";
import UtilityMixin from "../UtilityMixin";
export default {
    props: {
        jsonData: {
            type: Object,
            default: function () {},
        },
        idRisorsa: { type: String, default: null },
        tipoRisorsa: { type: String, default: null },
    },
    components: { PazienteAllegatiEditComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            file: null,
            stampaReport: null,
            perPage: 10,
            pathResourceAllegati: "/allegati",
            currentPageFile: 1,
            rowList: 0,
            data: { dataCaricamento: new Date().getTime() },
            allegati: [],
            fields: [
                {
                    label: "Tipo Documento",
                    key: "tipoDocumento",
                    thStyle: "width: 10rem",
                    sortable: true,
                },
                {
                    label: "Data Produzione",
                    key: "dataDocumento",
                    thStyle: "width: 8rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                    sortable: true,
                },
                {
                    label: "Data Caricamento",
                    key: "dataCaricamento",
                    thStyle: "width: 5rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "-----";
                        }
                    },
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    thStyle: "width: 15rem",
                    sortable: true,
                },
                {
                    label: "Firma",
                    key: "statoFirma",
                    sortable: true,
                    tdClass: "text-center",
                    thStyle: "width: 6rem",
                },
                {
                    label: "",
                    key: "actions",
                    tdClass: "text-center",
                    thStyle: "width: 2rem",
                },
            ],
        };
    },
    watch: {
        idRisorsa() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegati;
            axios.get(link, { params: { idRisorsa: me.idRisorsa } }).then((response) => {
                me.allegati = response.data.data;
            });
        },
        onSalva() {
            let me = this;
            let obj = me.getDataPdf(me.data);
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegati;
            me.showModalLoading = true;
            axios
                .post(link, obj)
                .then(() => {
                    this.$refs["mdlAllegati"].hide();
                    me.showModalLoading = false;
                    me.loadData();
                    me.$emit("updateFiles");
                    this.$bvToast.toast(`${this.getLabelTraduora("patient.afa.lblToastSuccessUpload")} - ${this.data.nomeFile}`, {
                        title: "File Upload",
                        autoHideDelay: 2000,
                        variant: "success",
                    });
                })
                .catch((error) => {
                    me.$emit("closeModal");
                    let message = "";
                    error.response ? error.response.data.messaggio : "Errore non gestito";
                    if (error.response) {
                        message = error.response.data.messaggio ? error.response.data.messaggio : "Codice Errore: " + error.response.status + ", non gestito";
                    }
                    if (error.response) {
                        this.$bvModal
                            .msgBoxOk(message, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then(() => {})
                            .catch((err) => {
                                console.log(err);
                            });
                    }
                });
        },
        getDataPdf(obj) {
            let me = this;
            let objPdf = {};
            objPdf.idRisorsa = me.jsonData.id;
            objPdf.idAnagrafica = me.jsonData.idPaziente;
            objPdf.nomeFile = obj.nomeFile;
            objPdf.size = obj.size;
            objPdf.file = obj.file;
            objPdf.tipo = obj.tipo;
            objPdf.dataCaricamento = obj.dataCaricamento;
            objPdf.descrizione = obj.descrizione;
            objPdf.tipoDocumento = obj.tipoDocumento;
            return objPdf;
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onVisualizzaAllegato(value) {
            // console.log(item);
            let me = this;
            me.$refs.mdlStampaReferto.show();
            me.stampaReport = value.file.includes("data:application/pdf;base64,") ? value.file : "data:application/pdf;base64," + value.file;
        },
        onHoverStatoFirma(item) {
            let firma = "";
            if (item.statoFirma == "FIRMATO") {
                firma = "IL DOCUMENTO E' FIRMATO";
            } else {
                firma = "IL DOCUMENTO NON E' FIRMARO";
            }
            return firma;
        },
    },
};
</script>

<style></style>
