<template>
    <div class="sa-components-patient-search">
        <b-form @submit.prevent="onSubmit">
            <h5 class="sa-text-left">Dati del Paziente</h5>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col sm="6">
                    <b-input-group>
                        <template #append>
                            <b-input-group-text @click="onSubmit"><b-icon icon="search"/></b-input-group-text>
                        </template>
                        <b-form-input v-model="filtro.codiceFiscalePaziente" @keypress="onKeyPress" type="search" placeholder="Codice Fiscale Paziente"></b-form-input>
                    </b-input-group>
                    <!--<b-form-input v-model="filtro.codiceFiscalePaziente" type="search" placeholder="Codice Fiscale Paziente"></b-form-input>-->
                </b-col>
                <b-col sm="6">
                    <b-form-input v-model="filtro.nominativoPaziente" type="search" placeholder="Nome del Paziente"></b-form-input>
                </b-col>
            </b-row>
            <h5 class="sa-text-left">Dati del Medico</h5>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col sm="6">
                    <b-form-input v-model="filtro.codiceFiscaleMedico" type="search" placeholder="Codice Fiscale Medico"></b-form-input>
                </b-col>
                <b-col sm="6">
                    <b-form-input v-model="filtro.nominativoMedico" type="search" placeholder="Nome del Medico"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="sa-padding-right text-right">
                    <br />
                    <b-button type="button" variant="info" @click="onConferma">{{ this.$i18n.t("global.lblBtnConferma") }}</b-button>
                    <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblBtnResetFilter") }}</b-button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>
<script>
import axios from "axios";
export default {
    name: "RicercaAnagraficaPaziente",
    components: {},
    props: {
        link: String,
    },
    data() {
        return {
            filtro: { codiceFiscalePaziente: "", codiceFiscaleMedico: "", nominativoMedico: "", nominativoPaziente: "" },
            data: [],
        };
    },
    methods: {
        onKeyPress(e) {
            let me = this;
            if (e.keyCode === 13) {
                me.loadPatientData();
            }
        },
        onSubmit() {
            let me = this;
            me.loadPatientData();
        },
        loadPatientData() {
            let me = this;
            me.showmolalloading = true;
            if (me.filtro.codiceFiscalePaziente.trim().length === 0) {
                me.showmolalloading = false;
            } else {
                me.filtro.codiceFiscalePaziente = me.filtro.codiceFiscalePaziente.toUpperCase();
                let link = process.env.VUE_APP_PATH_API + "/utility";
                axios
                    .get(link, { params: { codiceFiscale: me.filtro.codiceFiscalePaziente } })
                    .then((response) => {
                        me.filtro.codiceFiscaleMedico = response.data.data.codiceFiscaleMedico;
                        me.filtro.nominativoMedico = response.data.data.medico;
                        me.filtro.nominativoPaziente = response.data.data.cognome + " " + response.data.data.nome;
                        me.showmolalloading = false;
                    })
                    .catch(() => {
                        me.showmolalloading = false;
                    });
            }
        },
        onConferma() {
            let me = this;
            me.showmolalloading = true;
            axios
                .get(me.link, { params: { codiceFiscalePaziente: me.filtro.codiceFiscalePaziente, codiceFiscaleMedico: me.filtro.codiceFiscaleMedico } })
                .then((response) => {
                    me.data = [];
                    me.data = response.data.data;
                    me.$emit("update", me.data, me.filtro);
                    me.showmolalloading = false;
                })
                .catch(() => {
                    me.data = [];
                    me.$emit("update", me.data);
                    me.showmolalloading = false;
                });
        },
        onReset() {},
    },
};
</script>
