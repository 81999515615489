<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNextCheckDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.prossimoControllo" type="datetime"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblGeneralData')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
          <b-form-input v-model="jsonData.peso" type="number" placeholer="Kg" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
          <b-form-input v-model="jsonData.altezza" type="number" placeholer="cm" @input="onCalcoloBmi(jsonData.altezza, jsonData.peso)"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
          <b-form-input v-model="jsonData.bmi" type="number" placeholer="Kg/m2" disabled></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblWaistCircumference") }}</label>
          <b-form-input v-model="jsonData.circvita" type="number" placeholer="cm"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblHipsCircumference") }}</label>
          <b-form-input v-model="jsonData.circfianchi" type="number" placeholer="cm"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCurrentTherapy')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.diuretici" switch :checked="jsonData.diuretici"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiuretics") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.alfabloccanti" switch :checked="jsonData.alfabloccanti"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlphablockers") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.betabloccanti" switch :checked="jsonData.betabloccanti"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBetablockers") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.alfabetabloccanti" switch :checked="jsonData.alfabetabloccanti"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlphabetablockers") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.aceinibitori" switch :checked="jsonData.aceinibitori"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAceInibitors") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.at1antagonisti" switch :checked="jsonData.at1antagonisti"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAT1antagonists") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.diidropiridinici" switch :checked="jsonData.diidropiridinici"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDihydropyridines") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.fenilalchilamine" switch :checked="jsonData.fenilalchilamine"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPhenylalkylamines") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.benzotiazepine" switch :checked="jsonData.benzotiazepine"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBenzothiazepines") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.antiaritmici" switch :checked="jsonData.antiaritmici"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAntiarrhythmics") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.digitale" switch :checked="jsonData.digitale"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDigital") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.nitrati" switch :checked="jsonData.nitrati"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNitrates") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.antiaggreganti" switch :checked="jsonData.antiaggreganti"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAntiplateletAgents") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.anticoagulanti" switch :checked="jsonData.anticoagulanti"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnticoagulants") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.statine" switch :checked="jsonData.statine"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStatins") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.fibrati" switch :checked="jsonData.fibrati"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFibrates") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.antidiabOrali" switch :checked="jsonData.antidiabOrali"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOralAntidiabetics") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.insulina" switch :checked="jsonData.insulina"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsulin") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.altro" switch :checked="jsonData.altro"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPatientFollowedTherapy") }}</label>
          <b-form-select v-model="jsonData.segueterapia" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSideEffects") }}</label>
          <b-form-select v-model="jsonData.effetticollaterali" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEditTherapy") }}</label>
          <b-form-select v-model="jsonData.modificaterapia" :options="optionsModifica">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSideEffectsDescription") }}</label>
          <b-form-textarea v-model="jsonData.qualieffetti" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-form-label">Pressione Sistolica a Domicilio</label>
          <b-form-input v-model="jsonData.pasdomicilio" type="number" placeholder="max"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Pressione Diastolica a Domicilio</label>
          <b-form-input v-model="jsonData.paddomicilio" type="number" placeholder="min"> </b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblAnamnesys')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblNextAnamnesys") }}</label>
          <b-form-textarea v-model="jsonData.esameGenerale" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblGeneralCardiovascularAnamnesys") }}</label>
          <b-form-textarea v-model="jsonData.esameCardiovascolare" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCardiovascularEvent") }}</label>
          <b-form-select v-model="jsonData.tipoDiRicoveroCardio" :options="optionsRicovero">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCause") }}</label>
          <b-form-select v-model="jsonData.causaRicoveroCardio" :options="optionsCausa">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblExtraCardiovascularEvent") }}</label>
          <b-form-select v-model="jsonData.tipoDiRicoveroExtra" :options="optionsRicovero">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>

        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblNYHA") }}</label>
          <b-form-select v-model="jsonData.nyha" :options="optionsNyha">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCause") }}</label>
          <b-form-input type="text" v-model="jsonData.causaRicoveroExtra"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPaSitting')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Pressione Sistolica Prima Misurazione</label>
          <b-form-input v-model="jsonData.pasitmax1" type="number" placeholder="max"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Pressione Diastolica Prima Misurazione</label>
          <b-form-input v-model="jsonData.pasitmin1" type="number" placeholder="min"> </b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Pressione Sistolica Seconda Misurazione</label>
          <b-form-input v-model="jsonData.pasitmax2" type="number" placeholder="max"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Pressione Diastolica Seconda Misurazione</label>
          <b-form-input v-model="jsonData.pasitmin2" type="number" placeholder="min"> </b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Pressione Sistolica Terza Misurazione</label>
          <b-form-input v-model="jsonData.pasitmax3" type="number" placeholder="max"> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Pressione Diastolica Terza Misurazione</label>
          <b-form-input v-model="jsonData.pasitmin3" type="number" placeholder="min"> </b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Media Pressione Sistolica </label>
          <b-form-input v-model="paMediaMax" type="number" placeholder="max" :key="componentKey" disabled> </b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Media Pressione Diastolica </label>
          <b-form-input v-model="paMediaMin" type="number" placeholder="min" :key="componentKey" disabled> </b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <b-button type="reset" v-on:click="returnMediaPA()" style="float: right" variant="info">{{ this.$i18n.t("patient.cardiology.lblReturnAvarage") }}</b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblFinalTherapy')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.diureticiFinale" switch :checked="jsonData.diureticiFinale"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiuretics") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.alfabloccantiFinale" switch :checked="jsonData.alfabloccantiFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlphablockers") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.betabloccantiFinale" switch :checked="jsonData.betabloccantiFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBetablockers") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.alfabetabloccantiFinale" switch :checked="jsonData.alfabetabloccantiFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAlphabetablockers") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.aceinibitoriFinale" switch :checked="jsonData.aceinibitoriFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAceInibitors") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.at1antagonistiFinale" switch :checked="jsonData.at1antagonistiFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAT1antagonists") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.diidropiridiniciFinale" switch :checked="jsonData.diidropiridiniciFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDihydropyridines") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.fenilalchilamineFinale" switch :checked="jsonData.fenilalchilamineFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPhenylalkylamines") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.benzotiazepineFinale" switch :checked="jsonData.benzotiazepineFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBenzothiazepines") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.antiaritmiciFinale" switch :checked="jsonData.antiaritmiciFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAntiarrhythmics") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.digitaleFinale" switch :checked="jsonData.digitaleFinale"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDigital") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.nitratiFinale" switch :checked="jsonData.nitratiFinale"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNitrates") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.antiaggregantiFinale" switch :checked="jsonData.antiaggregantiFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAntiplateletAgents") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.anticoagulantiFinale" switch :checked="jsonData.anticoagulantiFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnticoagulants") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.statineFinale" switch :checked="jsonData.statineFinale"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStatins") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.fibratiFinale" switch :checked="jsonData.fibratiFinale"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFibrates") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.antidiabOraliFinale" switch :checked="jsonData.antidiabOraliFinale"></b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOralAntidiabetics") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.insulinaFinale" switch :checked="jsonData.insulinaFinale"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInsulin") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <b-row>
            <b-col xs="2" sm="2" md="2" lg="2" xl="2">
              <b-form-checkbox v-model="jsonData.altroFinale" switch :checked="jsonData.altroFinale"> </b-form-checkbox>
            </b-col>
            <b-col xs="10" sm="10" md="10" lg="10" xl="10">
              <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
            </b-col>
          </b-row>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblNoteComunicationsToSpecialistCenter") }}</label>
          <b-form-textarea v-model="jsonData.commento" rows="5" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRoutineExams')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGlicemy") }}</label>
          <b-form-input v-model="jsonData.glicemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCreatininemia") }}</label>
          <b-form-input v-model="jsonData.creatininemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAzotemia") }}</label>
          <b-form-input v-model="jsonData.azotemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUricemia") }}</label>
          <b-form-input v-model="jsonData.uricemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSodiemia") }}</label>
          <b-form-input v-model="jsonData.sodiemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPotassiemia") }}</label>
          <b-form-input v-model="jsonData.potassiemia" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTrigliceridemia") }}</label>
          <b-form-input v-model="jsonData.trigliceridi" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColesterolemia") }}</label>
          <b-form-input v-model="jsonData.colesterolo" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHDL") }}</label>
          <b-form-input v-model="jsonData.hdl" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteinuria") }}</label>
          <b-form-input v-model="jsonData.proteinuria24" type="number"></b-form-input>
        </b-col>
        <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4"
            ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHB") }}</span
            >
            <b-form-input v-model="jsonData.hb" type="number"></b-form-input>
          </b-col> -->
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblUrineExams')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGlucose") }}</label>
          <b-form-select v-model="jsonData.urineGlucosio" :options="optionsPresente">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBlood") }}</label>
          <b-form-select v-model="jsonData.urineSangue" :options="optionsPresente">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProteins") }}</label>
          <b-form-select v-model="jsonData.urineProteine" :options="optionsPresente">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSediment") }}</label>
          <b-form-input v-model="jsonData.sedimento" type="text"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.afa.lblHemocrome')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRedBloodCells") }}</label>
          <b-form-input v-model="jsonData.globuliRossi" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWhiteBloodCells") }}</label>
          <b-form-input v-model="jsonData.globuliBianchi" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPlatelets") }}</label>
          <b-form-input v-model="jsonData.piastrine" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEmatocrito") }}</label>
          <b-form-input v-model="jsonData.ematocrito" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFibrinogeno") }}</label>
          <b-form-input v-model="jsonData.fibrinogeno" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHB") }}</label>
          <b-form-input v-model="jsonData.emoglobina" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPT") }}</label>
          <b-form-input v-model="jsonData.pt" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblINR") }}</label>
          <b-form-input v-model="jsonData.inr" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPTT") }}</label>
          <b-form-input v-model="jsonData.aptt" type="number"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },
  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      componentKey: 0,
      showModalLoading: false,
      pathResource: "/cardioconsulenzetelematiche",
      paMediaMax: 0,
      paMediaMin: 0,
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      optionsConferma: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblYes"), value: "patient.cardiology.Options.lblYes" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
      ],
      optionsRicovero: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblElection"), value: "patient.cardiology.Options.lblElection" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblUrgent"), value: "patient.cardiology.Options.lblUrgent" },
      ],
      optionsPresente: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPresent"), value: "patient.cardiology.Options.lblPresent" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblAbsent"), value: "patient.cardiology.Options.lblAbsent" },
      ],
      optionsNyha: [
        { text: "I", value: "I" },
        { text: "II", value: "II" },
        { text: "III", value: "III" },
        { text: "IV", value: "IV" },
      ],
      optionsModifica: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblYesForIntollerance"), value: "patient.cardiology.Options.lblYesForIntollerance" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblYesForSideEffects"), value: "patient.cardiology.Options.lblYesForSideEffects" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblYesForPoorControl"), value: "patient.cardiology.Options.lblYesForPoorControl" },
      ],
      optionsCausa: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblTIA"), value: "patient.cardiology.Options.lblTIA" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblAngina"), value: "patient.cardiology.Options.lblAngina" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblArrhytmia"), value: "patient.cardiology.Options.lblArrhytmia" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblBAV"), value: "patient.cardiology.Options.lblBAV" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblHypertensiveCrisis"), value: "patient.cardiology.Options.lblHypertensiveCrisis" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblICTUS"), value: "patient.cardiology.Options.lblICTUS" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblIMA"), value: "patient.cardiology.Options.lblIMA" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblCoronarography"), value: "patient.cardiology.Options.lblCoronarography" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblSCA"), value: "patient.cardiology.Options.lblSCA" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onCalcoloBmi(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
    returnMediaPA() {
      let me = this;
      let misurazioniMax = 0;
      let misurazioniMin = 0;
      let divisore = 0;
      // console.log(me.jsonData.pasitmax2)
      if (me.jsonData.pasitmax1 && me.jsonData.pasitmin1) {
        let max = Number(me.jsonData.pasitmax1);
        let min = Number(me.jsonData.pasitmin1);
        misurazioniMax = misurazioniMax + max;
        misurazioniMin = misurazioniMin + min;
        divisore = divisore + 1;
      }
      if (me.jsonData.pasitmax2 && me.jsonData.pasitmin2) {
        let max = Number(me.jsonData.pasitmax2);
        let min = Number(me.jsonData.pasitmin2);
        misurazioniMax = misurazioniMax + max;
        misurazioniMin = misurazioniMin + min;
        divisore = divisore + 1;
      }
      if (me.jsonData.pasitmax3 && me.jsonData.pasitmin3) {
        misurazioniMax = misurazioniMax + Number(me.jsonData.pasitmax3);
        misurazioniMin = misurazioniMin + Number(me.jsonData.pasitmin3);
        divisore = divisore + 1;
      }
      // console.log(misurazioniMax+" / "+divisore+" = ")
      // console.log(misurazioniMax/divisore);
      me.paMediaMax = Math.floor(misurazioniMax / divisore);
      me.paMediaMin = Math.floor(misurazioniMin / divisore);
      // console.log("Media max: "+me.paMediaMax);
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
