<template>
    <sa-page-layout :pathResource="pathResource" :data="jsonData" :linkback="linkback" :btnAnnullaVisible="true" :btnSaveVisible="true" :showModalLoading="showModalLoading" :titolo="titolo" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Tariffa</label>
                        <b-form-input v-model="jsonData.codiceTariffa"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Descrizione Tariffa</label>
                        <b-form-input v-model="jsonData.descrizioneTariffa"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Importo</label>
                        <b-input-group size="md" prepend="€">
                            <b-form-input step="0.50" type="number" v-model="jsonData.importo" placeholder="Importo"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">IVA</label>
                        <b-form-select v-model="jsonData.iva" :options="ivaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id !== "-1") {
            me.linkback = "/tariffe/view/" + me.id;
        } else {
            me.linkback = "/tariffe";
        }
        me.loadData();
        me.loadIva();
    },
    data() {
        return {
            titolo: "Edit Tariffe",
            linkback: null,
            showModalLoading: false,
            pathResource: "/leonidaaccettazionetariffe",
            jsonData: { iva: null },
            ivaOptions: [{ value: null, text: "-Seleziona Iva-" }],
        };
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                me.showModalLoading = true;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
        loadIva() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/tabellaiva";
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.ivaOptions.push({ value: element.descrizioneIva, text: element.descrizioneIva });
                });
            });
        },
    },
};
</script>
