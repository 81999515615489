<template>
  <sa-page-layout :btnSaveVisible="true" :data="jsonData" :btnAnnullaVisible="true" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" class="sa-no-space">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <h3 class="sa-event-title">Malattia Segnalata: {{ malattiaInfettivaSegnalata }}</h3>
    </template>
    <template slot="table-body">
      <component ref="itemRefs" :is="currentComponentEdit" v-bind="currentProperties" @showmodalloading="onShowModalLoading" @update="onUpdateJsonData"></component>
    </template>
  </sa-page-layout>
</template>

<script>
// import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import indagini from "../components";
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../paziente/components/PazienteAnagrafica.vue";
import IndagineEpidemiologicaEditComponent from "../components/IndagineEpidemiologicaEditComponent.vue";
// import EventiMixins from "../../../paziente/eventi/utility/mixins/EventiMixins";
export default {
  components: { SaPageLayout, PazienteAnagrafica, IndagineEpidemiologicaEditComponent },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveie",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      showModalLoading: false,
      linkback: "/malattieinfettive/segnalazioni",
      isStatoEstero: false,
      isRicercheDiagnostiche: false,
      isContatti: false,
      currentPage: 1,
      perPage: 100,
      malattiaInfettivaSegnalata: "",
      indagineEpidemiologicaComponent: null,
      jsonData: {},
      currentComponentEdit: null,
      currentProperties: {},
    };
  },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
    rows() {
      return this.sintomiOptions.length;
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.indagineEpidemiologicaComponent = this.$route.query.indagineEpidemiologicaComponent;
    me.setPathResource();
    me.setMalattiaSegnalata();
    me.currentProperties = { showmodalloading: me.onShowModalLoading, update: me.onUpdateJsonData, utente: me.utente };
    me.currentComponentEdit = indagini.editComponents()[me.indagineEpidemiologicaComponent + "EditComponent.vue"];
  },

  methods: {
    onShowModalLoading(value) {
      let me = this;
      me.showModalLoading = value;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    setMalattiaSegnalata() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.malattiaInfettivaSegnalata = response.data.data.malattiaSegnalata;
          // console.log(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    setPathResource() {
      let me = this;
      switch (me.indagineEpidemiologicaComponent) {
        case "IndagineEpidemiologica":
          me.pathResource = "/malattieinfettiveie";
          break;
        case "IndagineEpidemiologicaListeriosi":
          me.pathResource = "/malattieinfettiveielisteriosi";
          break;
        case "IndagineEpidemiologicaInfezioniAlimentari":
          me.pathResource = "/malattieinfettiveieinfezionialimentari";
          break;
        case "IndagineEpidemiologicaLegionellosi":
          me.pathResource = "/malattieinfettiveielegionellosi";
          break;
        case "IndagineEpidemiologicaEpatiti":
          me.pathResource = "/malattieinfettiveieepatiti";
          break;
        case "IndagineEpidemiologicaLeishmaniosiViscerale":
          me.pathResource = "/malattieinfettiveieleishmaniosiviscerale";
          break;
        case "IndagineEpidemiologicaCovid":
          me.pathResource = "/malattieinfettiveiecovid";
          break;
        case "IndagineEpidemiologicaEpatiteE":
          me.pathResource = "/malattieinfettiveieepatitee";
          break;
        case "IndagineEpidemiologicaRosoliaCongenita":
          me.pathResource = "/malattieinfettiveierosoliacongenita";
          break;
        case "IndagineEpidemioogicaTubercolosiMicobatteriosiNonTubercolari":
          me.pathResource = "/malattieinfettiveietubercolosimicrobatterinontubercolari";
          break;
        case "IndagineEpidemiologicaEncefaliteMeningiteSMeningococcia":
          me.pathResource = "/malattieinfettiveieencefalitemeningitesmeningococcica";
          break;
        case "IndagineEpidemiologicaTossinfezioneAlimentare":
          me.pathResource = "/malattieinfettiveietossinfezionealimentare";
          break;
        case "IndagineEpidemiologicaMalaria":
          me.pathResource = "/malattieinfettiveiemalaria";
          break;
        case "IndagineEpidemiologicaMorbilloRosolia":
          me.pathResource = "/malattieinfettiveiemorbillorosolia";
          break;
        case "IndagineEpidemiologicaInfluenza":
          me.pathResource = "/malattieinfettiveieinfluenza";
          break;
        case "IndagineEpidemiologicaTetano":
          me.pathResource = "/malattieinfettiveietetano";
          break;
        case "IndagineEpidemiologicaMalattieTrasmissioneSessuale":
          me.pathResource = "/malattieinfettiveiemalattietrasmissionesessuale";
          break;
        case "IndagineEpidemiologicaRosoliaGravidanza":
          me.pathResource = "/malattieinfettiveierosoliagravidanza";
          break;
        case "IndagineEpidemiologicaAltriCasiParticolari":
          me.pathResource = "/malattieinfettiveiealtricasiparticolari";
          break;
      }
    },
  },
};
</script>
