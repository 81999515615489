<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ this.$i18n.t("patient.afa.lblFamilyAnamnesis") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiFamiliare" :disabled="!edit"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ this.$i18n.t("patient.afa.lblPathologicalAnamnesis") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiPatologica" :disabled="!edit"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ this.$i18n.t("patient.afa.lblInjuries") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.infortuni" :disabled="!edit"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-form-label">{{ this.$i18n.t("patient.afa.lblPhysiologicalAnamnesis") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiFisiologica" :disabled="!edit"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-form-label">{{ this.$i18n.t("patient.afa.lblSurgicalInterventions") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.interventiChirurgici" :disabled="!edit"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-form-label">{{ this.$i18n.t("patient.afa.lblSportAnamnesis") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.anamnesiSportiva" :disabled="!edit"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
export default {
    props: ["data", "edit"],
    data() {
        return {};
    },
};
</script>
