<template>
    <sa-page-layout :pathResource="pathResource" :linkedit="linkedit" :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <span class="sa-data">{{ getLabelTraduora("adi.lblFiscalCode") }}</span>
                        <b-input-group class="mb-3">
                            <b-form-input
                                maxlength="16"
                                v-model="filtro.codiceFiscale"
                                type="search"
                                id="codice"
                                :formatter="
                                    (value) => {
                                        return new String(value).toUpperCase();
                                    }
                                "
                                :disabled="true"
                            ></b-form-input>
                            <b-button size="sm" v-b-modal.mdlRicercaAnagrafica>
                                <b-icon icon="search"></b-icon>
                            </b-button>
                        </b-input-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <span class="sa-data">{{ getLabelTraduora("patients.lblName") }}</span>
                        <b-form-input v-model="filtro.nome" type="search" id="malattia"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <span class="sa-data">{{ getLabelTraduora("patients.lblLastname") }}</span>
                        <b-form-input v-model="filtro.malattiaSegnalata" type="search" id="malattia"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(malattia)="{ item }">
                        <router-link class="sa-edit-link" :to="'/indagineepidemiologica/view/' + item.id">
                            {{ item.malattia }}
                        </router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <anagrafica-modal-component @update="getCodiceFiscale" :codiceFiscale="filtro.codiceFiscale"></anagrafica-modal-component>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import AnagraficaModalComponent from "../../components/AnagraficaModalComponent.vue";
export default {
    components: { SaPageLayout, AnagraficaModalComponent },
    mixins: [UtilityMixin],
    data() {
        return {
            pathResource: "/malattieinfettiveie",
            linkedit: "/indagineepidemiologica",
            showModalLoading: false,
            perPage: 10,
            currentPage: 1,
            filter: {},
            items: [],
            fields: [
                {
                    label: "Tipo Caso",
                    key: "tipoCaso",
                    sortable: true,
                },
                {
                    label: "Malattia",
                    key: "malattia",
                    sortable: true,
                },
            ],
            filtro: {
                codiceFiscale: "",
                nome: "",
                cognome: "",
            },
        };
    },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?forPage=" + me.perPage + "&page=" + me.currentPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {
                cognome: "",
                nome: "",
                codiceFiscale: "",
                malattia: "",
            };
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        getCodiceFiscale(row) {
            let me = this;
            me.filtro.codiceFiscale = row[0].identificativo;
            me.loadData();
        },
    },
};
</script>
