<template>
  <sa-page-layout ref="pgaView" :items="items" :pathResource="pathResource" :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <b-row cols="12">
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          Data Evento:
          <span class="sa-text-data">{{ formatDate(items.createDate) }}</span
          ><span> </span>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-row>
          <b-col>
            <div :class="rangeSBP(items.sbp)">
              <strong>Pressione Sistolica</strong>
              <br />
              <span>{{ items.sbp }}</span>
            </div>
          </b-col>
          <b-col>
            <div :class="rangeHR(items.hr)">
              <strong>Frequenza Cardiaca</strong>
              <br />
              <span>{{ items.hr }}</span>
            </div>
          </b-col>
          <b-col>
            <div :class="rangeSPO2(items.spo2)">
              <strong>Saturazione</strong>
              <br />
              <span>{{ items.spo2 }}</span>
            </div>
          </b-col>
          <b-col>
            <div :class="rangeBS(items.bs)">
              <strong>Glicemia</strong>
              <br />
              <span>{{ items.bs }}</span>
            </div>
          </b-col>
          <b-col>
            <div :class="rangeTemperatura(items.t)">
              <strong>Temperatura</strong>
              <br />
              <span>{{ items.t }}</span>
            </div>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <div v-if="responseOK">
            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
              <strong>
                <h4>Risposta Analisi</h4>
              </strong>
              <br />
              {{ items.rispostaMessaggio }}
            </b-col>
          </div>
          <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
            <strong>
              <h4>Risultato Analisi</h4>
            </strong>
            <br />
            {{ items.risultato }}
          </b-col>
        </b-row>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { PazienteAnagrafica, SaPageLayout },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      id: -1,
      pathResource: "/pazienteanalisirischith",
      linkback: "/analisirischith",
      linkedit: null,
      items: [],
      iterator: 0,
      patientGoals: [],
      resultRecomendation: [],
      resultData: {},
      noData: null,
      coloreRischio: "#000000",
      resultRoutine: [],
      misure: [],
      showModalLoading: false,
      responseOK: false,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function () {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },

  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/analisirischith/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.linkback = "/paziente/analisirischith?idPaziente=" + me.idPaziente;

    this.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.items = response.data.data;
          me.showModalLoading = false;
          if (me.items.rispostaMessaggio === "OK") {
            me.responseOK = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    rangeSBP(value) {
      let returnValue = "sa-text-data sa-secondary";
      if (value >= 160) {
        returnValue = "sa-text-data sa-danger";
      } else if (value > 135 && value < 160) {
        returnValue = "sa-text-data sa-warning";
      } else if (value < 100 && value > 90) {
        returnValue = "sa-text-data sa-warning";
      } else if (value <= 90) {
        returnValue = "sa-text-data sa-danger";
      } else {
        returnValue = "sa-text-data sa-success";
      }
      return returnValue;
    },
    rangeHR(value) {
      let returnValue = "sa-text-data sa-secondary";
      if (value >= 160) {
        returnValue = "sa-text-data sa-danger";
      } else if (value > 135 && value < 160) {
        returnValue = "sa-text-data sa-warning";
      } else if (value < 100 && value > 90) {
        returnValue = "sa-text-data sa-warning";
      } else if (value <= 90) {
        returnValue = "sa-text-data sa-danger";
      } else {
        returnValue = "sa-text-data sa-success";
      }
      return returnValue;
    },
    rangeSPO2(value) {
      let returnValue = "sa-text-data sa-secondary";
      if (value >= 160) {
        returnValue = "sa-text-data sa-danger";
      } else if (value > 135 && value < 160) {
        returnValue = "sa-text-data sa-warning";
      } else if (value < 100 && value > 90) {
        returnValue = "sa-text-data sa-warning";
      } else if (value <= 90) {
        returnValue = "sa-text-data sa-danger";
      } else {
        returnValue = "sa-text-data sa-success";
      }
      return returnValue;
    },
    rangeTemperatura(value) {
      let returnValue = "sa-text-data sa-secondary";
      if (value >= 160) {
        returnValue = "sa-text-data sa-danger";
      } else if (value > 135 && value < 160) {
        returnValue = "sa-text-data sa-warning";
      } else if (value < 100 && value > 90) {
        returnValue = "sa-text-data sa-warning";
      } else if (value <= 90) {
        returnValue = "sa-text-data sa-danger";
      } else {
        returnValue = "sa-text-data sa-success";
      }
      return returnValue;
    },
    rangeBS(value) {
      let returnValue = "sa-text-data sa-secondary";
      if (value >= 160) {
        returnValue = "sa-text-data sa-danger";
      } else if (value > 135 && value < 160) {
        returnValue = "sa-text-data sa-warning";
      } else if (value < 100 && value > 90) {
        returnValue = "sa-text-data sa-warning";
      } else if (value <= 90) {
        returnValue = "sa-text-data sa-danger";
      } else {
        returnValue = "sa-text-data sa-success";
      }
      return returnValue;
    },
  },
};
</script>

<style scoped>
.sa-danger {
  color: #830f00 !important;
}
.sa-warning {
  color: #ff7707 !important;
}
.sa-success {
  color: #88b902 !important;
}
.sa-secondary {
  color: #6c757d !important;
}
</style>
