<template>
    <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
            <b-card class="sa-card sa-card-toolbar" header="Medici" header-tag="header" footer-tag="footer" title="">
                <template #header>
                    Medici
                    <b-button v-if="isEdit" type="button" variant="outline-secondary" class="float-right" @click="onClickAggiungiOperatoreMedico">+ Aggiungi</b-button>
                </template>
                <div v-for="operatore in operatori" :key="operatore.codiceFiscale">
                    <p v-if="operatore.tipo == 'MEDICO'" class="sa-operatori">{{ operatore.cognome }} {{ operatore.nome }}</p>
                </div>
            </b-card>
        </b-col>
        <b-col>
            <b-card class="sa-card sa-card-toolbar" header="Infermieri" header-tag="header" footer-tag="footer" title="">
                <template #header>
                    Infermieri
                    <b-button v-if="isEdit" type="button" variant="outline-secondary" class="float-right" @click="onClickAggiungiOperatoreInfermiere">+ Aggiungi</b-button>
                </template>
                <div v-for="operatore in operatori" :key="operatore.codiceFiscale">
                    <p v-if="operatore.tipo == 'INFERMIERE'" class="sa-operatori">{{ operatore.cognome }} {{ operatore.nome }}</p>
                </div>
            </b-card>
        </b-col>
        <b-modal ref="mdlOperatoriSanitari" id="mdlOperatoriSanitari" scrollable @ok="onOkMdlOperatoriSanitari" @hidden="onHiddenMdlOperatoriSanitari" @show="onShowMdlOperatoriSanitari">
            <b-row>
                <b-col class="sa-padding-right text-right">
                    <b-button variant="outline-success" @click="onClickBtnNuovoOperatore">
                        <b-icon icon="plus"></b-icon>
                        Nuovo Operatore
                    </b-button>
                </b-col>
            </b-row>
            <b-table ref="tblOperatoriSanitari" selectable sticky-header stacked="xl" striped hover itemscope :items="listaOperatori" :fields="fieldsOperatori" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" @row-selected="onRowSelected">
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                    </template>
                </template>
                <template #cell(index)="row">
                    {{ row.index + 1 }}
                </template>
                <template #cell(nominativo)="row">{{ row.item.cognome }} {{ row.item.nome }}</template>
                <template #cell(actions)="row">
                    <b-button size="sm" variant="btn btn-outline-primary waves-effect waves-light width-sm no-text" @click="onClickModificaOperatore(row.item)">
                        <b-icon icon="pencil"></b-icon>
                    </b-button>
                </template>
            </b-table>
        </b-modal>
        <b-modal ref="mdlNuovoOperatore" id="mdlNuovoOperatore" title="Nuovo Operatore" size="lg" scrollable @ok="onOkMdlNuovoOperatore" @hidden="onHiddenMdlNuovoOperatore" @show="onShowMdlNuovoOperatore">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tipo</label>
                    <b-form-select v-model="nuovoOperatore.tipo" :options="tipiOptions" value-field="value" text-field="text"></b-form-select>
                    <!-- <b-form-input v-model="nuovoOperatore.tipo" disabled></b-form-input> -->
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Cognome</label>
                    <b-form-input v-model="nuovoOperatore.cognome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Nome</label>
                    <b-form-input v-model="nuovoOperatore.nome"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Codice Fiscale</label>
                    <b-form-input v-model="nuovoOperatore.codiceFiscale" maxlength="16"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Numero Ordine</label>
                    <b-form-input v-model="nuovoOperatore.numeroOrdine"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Username</label>
                    <b-form-input v-model="nuovoOperatore.username"></b-form-input>
                </b-col>
            </b-row>
        </b-modal>
    </b-row>
</template>

<script>
import axios from "axios";
export default {
    props: {
        operatori: {
            type: Array,
        },
        isEdit: Boolean,
    },
    data() {
        return {
            currentPage: 1,
            perPage: 100000,
            operatoriSelezionati: [],
            tipoOperatore: null,
            pathResource: "/operatorisanitari",
            nuovoOperatore: { id: -1, tipo: "INFERMIERE", nome: "", cognome: "", codiceFiscale: "", numeroOrdine: "", username: "" },
            listaOperatori: [
                { cognome: "Cognome Uno", nome: "Nome Uno", codiceFiscale: "CF1", tipo: "MEDICO" },
                { cognome: "Cognome Due", nome: "Nome Due", codiceFiscale: "CF2", tipo: "MEDICO" },
                { cognome: "Cognome Tre", nome: "Nome Tre", codiceFiscale: "CF3", tipo: "MEDICO" },
            ],
            fieldsOperatori: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 0.2rem",
                    tdClass: "text-center",
                },
                {
                    label: "Cognome e Nome",
                    key: "nominativo",
                    sortable: false,
                },
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    thStyle: "width: 16rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                    sortable: false,
                },
            ],
            tipiOptions: [
                { value: null, text: "-Seleziona Tipo-" },
                { value: "MEDICO", text: "MEDICO" },
                { value: "INFERMIERE", text: "INFERMIERE" },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource;
                axios.get(link, { params: { tipo: me.tipoOperatore } }).then((response) => {
                    me.listaOperatori = response.data.data.list;
                    me.showModalLoading = false;
                    me.$refs.mdlOperatoriSanitari.show();
                });
            }
        },
        onClickAggiungiOperatoreMedico() {
            let me = this;
            me.tipoOperatore = "MEDICO";
            me.loadData();
        },
        onClickAggiungiOperatoreInfermiere() {
            let me = this;
            me.tipoOperatore = "INFERMIERE";
            me.loadData();
        },
        onOkMdlOperatoriSanitari() {
            let me = this;
            let nuovaListaOperatori = [];

            me.operatori.forEach((element) => {
                if (element.tipo !== me.tipoOperatore) {
                    nuovaListaOperatori.push(element);
                }
                console.log(nuovaListaOperatori);
            });
            nuovaListaOperatori = nuovaListaOperatori.concat(me.operatoriSelezionati);
            me.$emit("update", nuovaListaOperatori);
        },
        onHiddenMdlOperatoriSanitari() {},
        onShowMdlOperatoriSanitari() {},
        onRowSelected(item) {
            let me = this;
            me.operatoriSelezionati = item;
        },
        onClickModificaOperatore(operatore) {
            let me = this;
            me.nuovoOperatore = operatore;
            me.$refs.mdlNuovoOperatore.show();
        },
        onClickBtnNuovoOperatore() {
            let me = this;
            me.nuovoOperatore = { id: -1, tipo: me.tipoOperatore, nome: "", cognome: "", codiceFiscale: "", numeroOrdine: "", username: "" };
            me.$refs.mdlNuovoOperatore.show();
        },
        onShowMdlNuovoOperatore() {},
        onHiddenMdlNuovoOperatore() {
            // let me = this;
        },
        onOkMdlNuovoOperatore() {
            let me = this;
            let codiceFiscaleValido = false;
            let regexCodiceFiscale = /^(?:(?:[B-DF-HJ-NP-TV-Z]|[AEIOU])[AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[1256LMRS][\dLMNP-V])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/;
            if (regexCodiceFiscale.test(me.nuovoOperatore.codiceFiscale.toUpperCase())) {
                codiceFiscaleValido = true;
            } else {
                codiceFiscaleValido = false;
            }
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            if (codiceFiscaleValido) {
                if (me.nuovoOperatore.id == -1 || me.nuovoOperatore.id === undefined) {
                    axios.post(link, me.nuovoOperatore).then(() => {
                        me.loadData();
                        me.$refs.mdlNuovoOperatore.hide();
                    });
                } else {
                    link = link + "/" + me.nuovoOperatore.id;
                    axios.put(link, me.nuovoOperatore).then(() => {
                        me.loadData();
                        me.$refs.mdlNuovoOperatore.hide();
                    });
                }
            } else {
                this.$bvModal
                    .msgBoxOk("Codice Fiscale MANCANTE o ERRATO", {
                        title: "ATTENZIONE",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    })
                    .then((value) => {
                        console.log(value);
                        me.$refs.mdlNuovoOperatore.show();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
    },
};
</script>

<style>
.sa-operatori {
    font-size: 0.8rem;
    margin-bottom: 0.3rem;
}
</style>
