import IndagineEpidemiologicaList from "./pages/IndagineEpidemiologicaList.vue";
import IndagineEpidemiologicaView from "./pages/IndagineEpidemiologicaView.vue";
import IndagineEpidemiologicaEdit from "./pages/IndagineEpidemiologicaEdit.vue";

import IndagineEpidemiologicaBrucellosiEdit from "./pages/IndagineEpidemiologicaBrucellosiEdit.vue";
import IndagineEpidemiologicaSalmonellosiNonTifoideaEdit from "./pages/IndagineEpidemiologicaSalmonellosiNonTifoideaEdit.vue";
import IndagineEpidemiologicaMalattiaJakobCreutzfeldtEdit from "./pages/IndagineEpidemiologicaMalattiaJakobCreutzfeldtEdit.vue";
import IndagineEpidemiologicaModelliList from "./pages/IndagineEpidemiologicaModelliList.vue";

export default {
    IndagineEpidemiologicaList,
    IndagineEpidemiologicaEdit,
    IndagineEpidemiologicaView,
    
    IndagineEpidemiologicaBrucellosiEdit,
    IndagineEpidemiologicaSalmonellosiNonTifoideaEdit,
    IndagineEpidemiologicaMalattiaJakobCreutzfeldtEdit,
    IndagineEpidemiologicaModelliList,
};
