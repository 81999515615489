<template>
  <eventi-list-component :titoloEvento="titoloEvento" :utente="utente" :key="keyListaEventi"
    :pathEventResource="pathEventResource" :linkEvent="linkEvent" :gruppo="gruppo" :tipoEvento="tipoEvento">
  </eventi-list-component>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import EventiListComponent from "../../../base/components/EventiListComponent.vue";
export default {
  components: { EventiListComponent },
  mixins: [UtilityMixin, EventiMixin],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
  },
  data() {
    return {
      showModalLoading: false,
      titoloEvento: this.$i18n.t("patients.gastroenterology.anusRectumColonoscopy"),
      linkEvent: "/paziente/gastroenterologia/anorettocolonscopia",
      pathEventResource: "/gastroanorettocolonscopia",
      keyListaEventi: 0,
      gruppo: "GASTROENTEROLOGIA",
      tipoEvento: "ANO RETTO COLONSCOPIA",
    };
  },

  methods: {},
};
</script>
