<template>
    <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-filter">
            <!-- <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Codice Fiscale Medico</label>
                        <b-form-input v-model="filtro.codiceFiscaleMedico" type="search" placeholder="Codice Fiscale Medico"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Cognome</label>
                        <b-form-input v-model="filtro.cognome" type="search" placeholder="Cognome"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Nome</label>
                        <b-form-input v-model="filtro.nome" type="search" placeholder="Nome"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="sa-form-btn-filter">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form> -->
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="end" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template #cell(actions)="row">
                        <b-row>
                            <b-button variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" size="sm" @click="onClickImport(row.item)"><i class="bi bi-upload"></i></b-button>
                        </b-row>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import moment from "moment";
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
export default {
    props: {
        utente: { type: Object },
    },
    components: { SaPageLayout, PazienteAnagrafica },
    mixins: [UtilityMixin],
    created() {
        let me = this;
        me.idPaziente = me.$route.query.idPaziente;
        me.linkPatient = UtilityMixin.getLinkPatient(this.utente, this.idPaziente);
    },
    updated() {
        let me = this;
        me.idAmbulatorio = sessionStorage.getItem("idAmbulatorio");
    },
    data() {
        return {
            idAmbulatorio: null,
            idPaziente: null,
            linkPatient: null,
            showModalLoading: false,
            pathResource: "/wincareoldlistaeventi",
            pathResourceImport: "/wincareoldeventi",
            linkedit: "",
            currentPage: 1,
            perPage: 50,
            rows: null,
            filtro: { idPaziente: "" },
            items: [],
            // items: [
            //     { codiceFsicale: "CF_SSSSSSSSSSS", dataEvento: 1255488420, tipoEvento: "ANAMNESI", sezione: 1, codpaz: 1, numProgressivo: 0 },
            //     { codiceFsicale: "CF_SSSSSSSSSSS", dataEvento: 1255488420, tipoEvento: "ANAMNESI", sezione: 1, codpaz: 1, numProgressivo: 0 },
            // ],
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Data Evento",
                    key: "dataEvento",
                    sortable: true,
                    thStyle: "width: 12rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        } else {
                            return "-------";
                        }
                    },
                },
                {
                    label: "Tipo Evento",
                    key: "tipoEvento",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 40px" },
            ],
        };
    },
    mounted() {
        let me = this;

        me.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            console.log(link);
            me.filtro.idPaziente = me.idPaziente;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    console.log(response);
                    me.items = [];
                    me.rows = response.data.data.lenght;
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onClickImport(item) {
            let me = this;
            me.showModalLoading = true;
            let requestData = { idAmbulatorio: me.idAmbulatorio, sezione: item.sezione, codpaz: item.codpaz, tipoEvento: item.tipoEvento, numProgressivo: item.numProgressivo };

            let link = process.env.VUE_APP_PATH_API + me.pathResourceImport;
            axios
                .get(link, { params: requestData })
                .then((response) => {
                    console.log(response);

                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style></style>
