<template>
    <sa-page-layout :titolo="titolo" :pathResource="pathResource" :linkback="linkback" :data="jsonData" :btnAnnullaVisible="true" :btnSaveVisible="true" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Report Name</label>
                        <b-form-input v-model="jsonData.reportName"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Nome File</label>
                        <b-form-select v-model="jsonData.nomeFile" :options="nomiFileOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                        <!-- <b-form-input v-model="jsonData.nomeFile"></b-form-input> -->
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Descrizione</label>
                        <b-form-input v-model="jsonData.descrizione"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Versione</label>
                        <b-form-input v-model="jsonData.versione"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Stato</label>
                        <b-form-select v-model="jsonData.stato" :options="statiReportOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Test Data (JSON)</label>
                        <b-form-textarea v-model="jsonData.testData" no-resize rows="30"></b-form-textarea>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-button variant="info btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onStampa()"><b-icon icon="printer"></b-icon>Test</b-button>
                    </b-col>
                </b-row>
            </div>
        </template>
        <template slot="table-footer">
            <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%">
                <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
            </b-modal>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        me.loadNomiFile();
        if (me.id !== "-1") {
            me.linkback = "/printserver/view/" + me.id;
        } else {
            me.linkback = "/printserver";
        }
    },
    data() {
        return {
            titolo: "Print Server Edit",
            pathResource: "/printserver",
            pathResourceStampa: "/print/createreport",
            stampaReport: null,
            showModalLoading: false,
            linkback: null,
            id: "",
            linkNomiFile: "/printserver/nomifile",
            jsonData: { nomeFile: null, stato: null },
            statiReportOptions: [
                { value: null, text: "- Seleziona Stato -" },
                { value: "ATTIVO", text: "ATTIVO" },
                { value: "SOSPESO", text: "SOSPESO" },
                { value: "OBSOLETO", text: "OBSOLETO" },
            ],
            nomiFileOptions: [{ value: null, text: "- Seleziona Nome File -" }],
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                });
            }
        },
        onStampa() {
            let me = this;
            let printData = JSON.parse(me.jsonData.testData);
            let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
            axios.post(linkReport, { reportName: me.jsonData.reportName, data: printData }).then((response) => {
                me.$refs.mdlStampaReferto.show();
                me.stampaReport = "data:application/pdf;base64," + response.data.base64;
            });
        },
        loadNomiFile() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkNomiFile;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.nomiFileOptions.push({ value: element.nomeFile, text: element.nomeFile });
                });
            });
        },
    },
};
</script>
