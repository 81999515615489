<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblDiagnosis')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStrokeTiaInsorgenceDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataStroketia" type="datetime"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHours") }}</label>
          <b-form-input v-model="jsonData.ore" type="number"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatientIsHospitalized") }}</label>
          <b-form-select v-model="jsonData.ospedsino" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRecoveryDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataRicovero" type="datetime"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDischargeDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataDimissione" type="datetime"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSymptoms')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCognitiveAbilitiesChanges") }}</label>

          <b-form-select v-model="jsonData.sint1" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConsciousnessLevelChanges") }}</label>

          <b-form-select v-model="jsonData.sint2" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIpostenia") }}</label>

          <b-form-select v-model="jsonData.sint3" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSensoryClouding") }}</label>
          <b-form-select v-model="jsonData.sint4" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCommunicationDisturbances") }}</label>
          <b-form-select v-model="jsonData.sint5" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEquilibriumDisturbances") }}</label>
          <b-form-select v-model="jsonData.sint6" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>

          <b-form-select v-model="jsonData.sint7" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col v-if="jsonData.sint7 === 'patient.cardiology.Options.lblYes'">
          <b-form-textarea v-model="jsonData.altro" rows="12" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOver24HoursSymptonsDuration") }}</label>
          <b-form-select v-model="jsonData.durata" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblActualState')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblActualStateNotes") }}</label>
        </b-col>

        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSymptonsCompleteRemission") }}</label>
          <b-form-checkbox v-model="jsonData.statoattuale" switch :checked="jsonData.statoattuale"> </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatientNeedsOtherPeopleForDailyActivities") }}</label>
          <b-form-checkbox v-model="jsonData.statoattuale2" switch :checked="jsonData.statoattuale2"> </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSymptonsPersistenceWithoutFunctionalLimitation") }}</label>
          <b-form-checkbox v-model="jsonData.statoattuale3" switch :checked="jsonData.statoattuale3"> </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatientIsIncapable") }}</label>
          <b-form-checkbox v-model="jsonData.statoattuale4" switch :checked="jsonData.statoattuale4"> </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPatientWithSomeLimitations") }}</label>
          <b-form-checkbox v-model="jsonData.statoattuale5" switch :checked="jsonData.statoattuale5"> </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeceasedPatient") }}</label>
          <b-form-checkbox v-model="jsonData.statoattuale6" switch :checked="jsonData.statoattuale6"> </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.afa.lblFinalDiagnosis')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTacRmAutopsyConfirmedDiagnosis") }}</label>
          <b-form-select v-model="jsonData.conferma" :options="optionsConferma">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCerebralIctus") }}</label>
          <b-form-checkbox v-model="jsonData.inf" switch :checked="jsonData.inf"> </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUncertainNatureStroke") }}</label>
          <b-form-checkbox v-model="jsonData.inf2" switch :checked="jsonData.inf2"> </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInBrainBloodLoss") }}</label>
          <b-form-checkbox v-model="jsonData.inf3" switch :checked="jsonData.inf4"> </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblUnderSkullBloodLoss") }}</label>
          <b-form-checkbox v-model="jsonData.inf4" switch :checked="jsonData.inf4"> </b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTIA") }}</label>
          <b-form-checkbox v-model="jsonData.inf5" switch :checked="jsonData.inf5"> </b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return {
          dataEvento: null,
          ospedsino: null,
          sint1: null,
          sint2: null,
          sint3: null,
          sint4: null,
          sint5: null,
          sint6: null,
          sint7: null,
          durata: null,
          conferma: null,
        };
      },
    },
  },

  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardiodaticlinicistrumentaliictus",
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      optionsConferma: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblYes"), value: "patient.cardiology.Options.lblYes" },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
