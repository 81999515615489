<template>
  <!-- <sa-page-layout :btnSaveVisible="true" :data="jsonData" :btnAnnullaVisible="true" :showModalLoading="showModalLoading" :linkback="linkback" :pathResource="pathResource" class="sa-no-space"> -->
  <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :btnRefreshVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="toolbar">
      <print-component v-if="btnPrintVisible" :data="jsonData" :linkPrintData="linkPrintData(jsonData)" typeButton="normal"></print-component>
    </template>
    <template slot="table-body">
      <b-tabs content-class="mt-3" ref="tabs" class="sa-tab" style="height: 100%" fill>
        <b-tab title="Scheda Valutazione Esigenze Immediate" active>
          <scheda-valutazione-esigenze-immediate-view-component :jsonData="jsonData" @update="onUpdateJsonData"></scheda-valutazione-esigenze-immediate-view-component>
        </b-tab>
        <b-tab title="Allegati">
          <allegato-model-component :idRisorsa="id" @updateFiles="onUpdateFile()" :jsonData="jsonData" tipoDocumento="SCHEDAVALUTAZIONEESIGENZEIMMEDIATE"></allegato-model-component>
        </b-tab>
      </b-tabs>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import AllegatoModelComponent from "../../utility/components/AllegatoModelComponent.vue";
import PrintComponent from "../../../app/utility/components/PrintComponent.vue";
import SchedaValutazioneEsigenzeImmediateViewComponent from "../components/SchedaValutazioneEsigenzeImmediateViewComponent.vue";
export default {
  components: { SaPageLayout, SchedaValutazioneEsigenzeImmediateViewComponent, AllegatoModelComponent, PrintComponent },
  data() {
    return {
      id: "-1",
      pathResource: "/schedavalutazioneesigenzeimmediate",
      linkback: "/schedavalutazioneesigenzeimmediate",
      linkedit: "",
      showModalLoading: false,
      btnPrintVisible: true,
      jsonData: {},
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/schedavalutazioneesigenzeimmediate/edit/" + me.id;
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    linkPrintData(item) {
      let returnValue = "/sveiprinter/" + item.id;
      return returnValue;
    },
  },
};
</script>

<style></style>
