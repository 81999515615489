<template>
    <div>
        <code>SELECT * FROM 'gesan_utility'</code>
    </div>
</template>

<script>
export default {};
</script>

<style></style>
