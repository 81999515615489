<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :pathResource="pathResource" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Sigla Federazione</span>
                        <b-form-input v-model="jsonData.siglaFederazione" placeholder="Sigla Federazione"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Numero Codice Federazione</span>
                        <b-form-input v-model="jsonData.numeroCodiceFederazione" placeholder="Numero Codice Federazione"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Denominazione Federazione</span>
                        <b-form-input v-model="jsonData.denominazioneFederazione" placeholder="Denominazione Federazione"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Sport</span>
                        <b-form-input v-model="jsonData.sport" placeholder="Sport"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Numero Codice Sport</span>
                        <b-form-input v-model="jsonData.numeroCodiceSport" placeholder="Numero Codice Sport"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Specialita'</span>
                        <b-form-input v-model="jsonData.specialita" placeholder="Specialita'"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Numero Codice Specialita'</span>
                        <b-form-input v-model="jsonData.numeroCodiceSpecialita" placeholder="Numero Codice Specialita'"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Tipo Visita</span>
                        <b-form-input v-model="jsonData.tipoVisita" placeholder="Tipo Visita"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">EEG Prima Visita</span>
                        <b-form-checkbox v-model="jsonData.eegPrimaVisita"></b-form-checkbox>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Scadenza</span>
                        <b-form-input v-model="jsonData.scadenza" type="number" placeholder="Scadenza"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Eta' min. Maschi</span>
                        <b-form-input v-model="jsonData.etaMaschiMin" type="number" placeholder="Eta' min. Maschi"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="3">
                        <span class="sa-form-label">Eta' max. Maschi</span>
                        <b-form-input v-model="jsonData.etaMaschiMax" type="number" placeholder="Eta' max. Maschi"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <span class="sa-form-label">Eta' min. Femmine</span>
                        <b-form-input v-model="jsonData.etaFemmineMin" type="number" placeholder="Eta' min. Femmine"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <span class="sa-form-label">Eta' max. Femmine</span>
                        <b-form-input v-model="jsonData.etaFemmineMax" type="number" placeholder="Eta' max. Femmine"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <span class="sa-form-label">Tipologia Eta'</span>
                        <b-form-select v-model="jsonData.tipologiaEta" :options="tipologieEtaOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <span class="sa-form-label">Periodo Stagione Da:</span>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.periodoStagioneSportivaDa" type="timestamp" placeholder="Periodo Stagione Da"></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <span class="sa-form-label">Periodo Stagione A:</span>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.periodoStagioneSportivaA" type="timestamp" placeholder="Periodo Stagione A"></date-picker>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DatePicker from "vue2-datepicker";
export default {
    components: { SaPageLayout, DatePicker },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        if (me.id !== "-1") {
            me.linkback = "/federazionisportivenazionali/view/" + me.id;
        } else {
            me.linkback = "/federazionisportivenazionali";
        }
    },
    data() {
        return {
            pathResource: "/leonidafederazionisportivenazionali",
            showModalLoading: false,
            linkback: null,
            jsonData: {
                siglaFederazione: "",
                numeroCodiceFederazione: "",
                denominazioneFederazione: "",
                sport: "",
                numeroCodiceSport: "",
                specialita: "",
                numeroCodiceSpecialita: "",
                tipoVisita: "",
                eegPrimaVisita: false,
                scadenza: "",
                etaMaschiMin: null,
                etaMaschiMax: null,
                etaFemmineMin: null,
                etaFemmineMax: null,
                tipologiaEta: null,
                periodoStagioneSportivaDa: null,
                periodoStagioneSportivaA: null,
            },
            tipologieEtaOptions: [
                { value: null, text: "-Seleziona Tipologia Eta'-" },
                { value: "ANAGRAFICA", text: "ANAGRAFICA" },
                { value: "SOLARE", text: "SOLARE" },
                { value: "SPORTIVA", text: "SPORTIVA" },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = false;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.showmolalloading = false;
                });
            }
        },
    },
};
</script>
