<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCoronargraphy')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTC") }}</label>
          <b-form-textarea v-model="jsonData.tc" rows="5" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
          <b-form-select v-model="jsonData.lesioniTc" :options="optionsLesioni" value="null">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIVA") }}</label>
          <b-form-textarea v-model="jsonData.iva" rows="5" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
          <b-form-select v-model="jsonData.lesioniIva" :options="optionsLesioni">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCx") }}</label>
          <b-form-textarea v-model="jsonData.cx" rows="5" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
          <b-form-select v-model="jsonData.lesioniCx" :options="optionsLesioni">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDx") }}</label>
          <b-form-textarea v-model="jsonData.dx" rows="5" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
          <b-form-select v-model="jsonData.lesioniDx" :options="optionsLesioni">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDominance") }}</label>
          <b-form-select v-model="jsonData.dominanza" :options="optionsDominanza">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAccess") }}</label>
          <b-form-select v-model="jsonData.accesso" :options="optionsAccesso">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAccessComplication") }}</label>
          <b-form-select v-model="jsonData.complicanzaAccesso" :options="optionsAccesso">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFrench") }}</label>
          <b-form-select v-model="jsonData.french" :options="optionsFrench">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblVentriculography')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="9" lg="9" xl="9">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
          <b-form-textarea v-model="jsonData.ventricolografia" rows="5" no-resize></b-form-textarea>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
          <b-form-select v-model="jsonData.lesioniVentricolografia" :options="optionsLesioniVentri">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
          <b-form-select v-model="jsonData.lesioniVentricolografia2" :options="optionsLesioni">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPercutaneosCoronaryIntervention')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataIndicazionePci" type="datetime"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPciIndication") }}</label>
          <b-form-select v-model="jsonData.indicazionePci" :options="optionsPci">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEcgInAcs" type="datetime"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEcgInAcs") }}</label>
          <b-form-select v-model="jsonData.ecgInAcs" :options="optionsAcs">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblProceduralCharatteristics')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAccess") }}</label>
          <b-form-select v-model="jsonData.accesso2" :options="optionsAccesso">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAccessComplication") }}</label>
          <b-form-select v-model="jsonData.complicanzaAccesso2" :options="optionsAccesso">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFrench") }}</label>
          <b-form-select v-model="jsonData.french2" :options="optionsFrench">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblComplications')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedBloodVesselsNumber") }}</label>
          <b-form-select v-model="jsonData.numeroVasiTrattati" :options="optionsVasi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedInjuriesNumber") }}</label>
          <b-form-select v-model="jsonData.numeroLesioniTrattate" :options="optionsVasi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblInterventionalHemodynamicProcedures')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.data1" type="datetime"> </date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
          <b-form-select v-model="jsonData.sede1" :options="optionsSede">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOperation") }}</label>
          <b-form-select v-model="jsonData.intervento1" :options="optionsIntervento">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblResult") }}</label>
          <b-form-select v-model="jsonData.esito1" :options="optionsEsito">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
          <b-form-select v-model="jsonData.complicanze1" :options="optionsComplicanze">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.data2" type="datetime"> </date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
          <b-form-select v-model="jsonData.sede2" :options="optionsSede">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOperation") }}</label>
          <b-form-select v-model="jsonData.intervento2" :options="optionsIntervento">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblResult") }}</label>
          <b-form-select v-model="jsonData.esito2" :options="optionsEsito">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
          <b-form-select v-model="jsonData.complicanze2" :options="optionsComplicanze">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12"
          ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.data3" type="datetime"> </date-picker>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
          <b-form-select v-model="jsonData.sede3" :options="optionsSede">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOperation") }}</label>
          <b-form-select v-model="jsonData.intervento3" :options="optionsIntervento">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblResult") }}</label>
          <b-form-select v-model="jsonData.esito3" :options="optionsEsito">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
          <b-form-select v-model="jsonData.complicanze3" :options="optionsComplicanze">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPTCA')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSessionNumber") }}</label>
          <b-form-input v-model="jsonData.nsessione" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPtcaNumber") }}</label>
          <b-form-input v-model="jsonData.nptca" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProcedureNumber") }}</label>
          <b-form-input v-model="jsonData.nprocedura" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntroducers") }}</label>
          <b-form-input v-model="jsonData.introduttori" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiseasedBloodVessels") }}</label>
          <b-form-input v-model="jsonData.text9" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedBloodVessels") }}</label>
          <b-form-input v-model="jsonData.vasiTrattati" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGuidingCatheter") }}</label>
          <b-form-input v-model="jsonData.catetereGuida" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedBranches") }}</label>
          <b-form-input v-model="jsonData.ramiTrattati" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedInjuries") }}</label>
          <b-form-input v-model="jsonData.lesioniTrattate" type="text"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblFirstMeasurements')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBall") }}</label>
          <b-form-input v-model="jsonData.palloneEmodinamica1" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGuide") }}</label>
          <b-form-select v-model="jsonData.guidaEmodinamica1" :options="optionsGuida">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblator") }}</label>
          <b-form-input v-model="jsonData.rotoblatorEmodinamica1" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorProbe") }}</label>
          <b-form-input v-model="jsonData.sondaRotoblatorEmodin1" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorIvusProbe") }}</label>
          <b-form-input v-model="jsonData.sondaIvusRotoblatorEmodin1" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAterectomia") }}</label>
          <b-form-select v-model="jsonData.aterectomiaEmodinamica1" :options="optionsAterectomia">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorAterectomiaProbe") }}</label>
          <b-form-input v-model="jsonData.sondaAterectomiaEmodin1" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
          <b-form-select v-model="jsonData.sedeAterectomiaEmodin1" :options="optionsSedeAterectomia">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
          <b-form-select v-model="jsonData.tipoEmodinamica1" :options="optionsTipo">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPRE") }}</label>
          <b-form-input v-model="jsonData.preEmodinamica1" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPOST") }}</label>
          <b-form-input v-model="jsonData.postEmodinamica1" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTIMI") }}</label>
          <b-form-select v-model="jsonData.timiEmodinamica1" :options="optionsTimi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
          <b-form-select v-model="jsonData.complicazioniEmodinamica1" :options="optionsComplEmodin">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStentNumber") }}</label>
          <b-form-input v-model="jsonData.nstentEmodinamica1" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStent") }}</label>
          <b-form-select v-model="jsonData.stentEmodinamica1" :options="optionsStentEmodin">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSecondMeasurements')" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBall") }}</label>
          <b-form-input v-model="jsonData.palloneEmodinamica2" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGuide") }}</label>
          <b-form-select v-model="jsonData.guidaEmodinamica2" :options="optionsGuida">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblator") }}</label>
          <b-form-input v-model="jsonData.rotoblatorEmodinamica2" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorProbe") }}</label>
          <b-form-input v-model="jsonData.sondaRotoblatorEmodin2" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorIvusProbe") }}</label>
          <b-form-input v-model="jsonData.sondaIvusRotoblatorEmodin2" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAterectomia") }}</label>
          <b-form-select v-model="jsonData.aterectomiaEmodinamica2" :options="optionsAterectomia">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorAterectomiaProbe") }}</label>
          <b-form-input v-model="jsonData.sondaAterectomiaEmodin2" type="text"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
          <b-form-select v-model="jsonData.sedeAterectomiaEmodin2" :options="optionsSedeAterectomia">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
          <b-form-select v-model="jsonData.tipoEmodinamica2" :options="optionsTipo">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPRE") }}</label>
          <b-form-input v-model="jsonData.preEmodinamica2" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPOST") }}</label>
          <b-form-input v-model="jsonData.postEmodinamica2" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTIMI") }}</label>
          <b-form-select v-model="jsonData.timiEmodinamica2" :options="optionsTimi">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
          <b-form-select v-model="jsonData.complicazioniEmodinamica2" :options="optionsComplEmodin">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStentNumber") }}</label>
          <b-form-input v-model="jsonData.nstentEmodinamica2" type="number"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStent") }}</label>
          <b-form-select v-model="jsonData.stentEmodinamica2" :options="optionsStentEmodin">
            <template #first>
              <b-form-select-option :value="null" disabled>
                {{ firstElementSelect }}
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },
  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardiocoronarografieptca",
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      optionsPci: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblUaNstemiStagedPci"), value: this.getLabelTraduora("patient.cardiology.Options.lblUaNstemiStagedPci") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblUaNstemi"), value: this.getLabelTraduora("patient.cardiology.Options.lblUaNstemi") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblStableCad"), value: this.getLabelTraduora("patient.cardiology.Options.lblStableCad") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblStemiPrimaryPci"), value: this.getLabelTraduora("patient.cardiology.Options.lblStemiPrimaryPci") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblStemiRescuePci"), value: this.getLabelTraduora("patient.cardiology.Options.lblStemiRescuePci") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblStemiAfterFibrinolysis"), value: this.getLabelTraduora("patient.cardiology.Options.lblStemiAfterFibrinolysis") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblLateAccessStemi"), value: this.getLabelTraduora("patient.cardiology.Options.lblLateAccessStemi") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblStemiStagedPci"), value: this.getLabelTraduora("patient.cardiology.Options.lblStemiStagedPci") },
      ],
      optionsFrench: ["4", "5", "6", "7", "8", ">8"],
      optionsAccesso: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblFemoral"), value: 1 },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblRadial"), value: 2 },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblBrachialis"), value: 3 },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblUlnar"), value: 4 },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblOther"), value: 5 },
      ],
      optionsDominanza: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblMixed"), value: 0 },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblLeft"), value: 1 },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblRight"), value: 2 },
      ],
      optionsLesioni: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblFiftyPercentageStenosis"), value: this.getLabelTraduora("patient.cardiology.Options.lblFiftyPercentageStenosis") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblDiffuseAtheromasia"), value: this.getLabelTraduora("patient.cardiology.Options.lblDiffuseAtheromasia") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblOcclused"), value: this.getLabelTraduora("patient.cardiology.Options.lblOcclused") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblTightStenosis"), value: this.getLabelTraduora("patient.cardiology.Options.lblTightStenosis") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblStenosisUnder50"), value: this.getLabelTraduora("patient.cardiology.Options.lblStenosisUnder50") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblStenosisOver50"), value: this.getLabelTraduora("patient.cardiology.Options.lblStenosisOver50") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblUnharmed"), value: this.getLabelTraduora("patient.cardiology.Options.lblUnharmed") },
      ],
      optionsLesioniVentri: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblLateralHypokinesia"), value: this.getLabelTraduora("patient.cardiology.Options.lblLateralHypokinesia") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblLowerHypokinesia"), value: this.getLabelTraduora("patient.cardiology.Options.lblLowerHypokinesia") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblFrontalHypokinesia"), value: this.getLabelTraduora("patient.cardiology.Options.lblFrontalHypokinesia") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblGlobalHypokinesia"), value: this.getLabelTraduora("patient.cardiology.Options.lblGlobalHypokinesia") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblLateralAkinesia"), value: this.getLabelTraduora("patient.cardiology.Options.lblLateralAkinesia") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblLowerAkinesia"), value: this.getLabelTraduora("patient.cardiology.Options.lblLowerAkinesia") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblFrontalAkinesia"), value: this.getLabelTraduora("patient.cardiology.Options.lblFrontalAkinesia") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPreserverdKinesis"), value: this.getLabelTraduora("patient.cardiology.Options.lblPreserverdKinesis") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblDilated"), value: this.getLabelTraduora("patient.cardiology.Options.lblDilated") },
      ],
      optionsComplicanze: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblYes"), value: this.getLabelTraduora("patient.cardiology.Options.lblYes") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: this.getLabelTraduora("patient.cardiology.Options.lblNo") },
      ],
      optionsEsito: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblSatisfying"), value: this.getLabelTraduora("patient.cardiology.Options.lblSatisfying") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNotSatisfying"), value: this.getLabelTraduora("patient.cardiology.Options.lblNotSatisfying") },
      ],
      optionsIntervento: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPtcaStent"), value: this.getLabelTraduora("patient.cardiology.Options.lblPtcaStent") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPtcaStentMedicated"), value: this.getLabelTraduora("patient.cardiology.Options.lblPtcaStentMedicated") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblPtca"), value: this.getLabelTraduora("patient.cardiology.Options.lblPtca") },
      ],
      optionsAcs: [
        { text: this.getLabelTraduora("patient.cardiology.Options.lblNoChanges"), value: this.getLabelTraduora("patient.cardiology.Options.lblNoChanges") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblBbsNewInsurgence"), value: this.getLabelTraduora("patient.cardiology.Options.lblBbsNewInsurgence") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblOverLevellingAndChange"), value: this.getLabelTraduora("patient.cardiology.Options.lblOverLevellingAndChange") },
        { text: this.getLabelTraduora("patient.cardiology.Options.lblOverLevelling"), value: this.getLabelTraduora("patient.cardiology.Options.lblOverLevelling") },
      ],
      optionsVasi: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
      optionsStentEmodin: [],
      optionsGuida: [],
      optionsSede: [],
      optionsAterectomia: [],
      optionsSedeAterectomia: [],
      optionsTipo: [],
      optionsTimi: [],
      optionsComplEmodin: [],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onCalcoloBmiBsa(altezza, peso) {
      let me = this;
      me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
      me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
    },
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
    returnMediaPA() {
      let me = this;
      let misurazioniMax = 0;
      let misurazioniMin = 0;
      let divisore = 0;
      // console.log(me.jsonData.pasitmax2)
      if (me.jsonData.pasitmax1 && me.jsonData.pasitmin1) {
        let max = Number(me.jsonData.pasitmax1);
        let min = Number(me.jsonData.pasitmin1);
        misurazioniMax = misurazioniMax + max;
        misurazioniMin = misurazioniMin + min;
        divisore = divisore + 1;
      }
      if (me.jsonData.pasitmax2 && me.jsonData.pasitmin2) {
        let max = Number(me.jsonData.pasitmax2);
        let min = Number(me.jsonData.pasitmin2);
        misurazioniMax = misurazioniMax + max;
        misurazioniMin = misurazioniMin + min;
        divisore = divisore + 1;
      }
      if (me.jsonData.pasitmax3 && me.jsonData.pasitmin3) {
        misurazioniMax = misurazioniMax + Number(me.jsonData.pasitmax3);
        misurazioniMin = misurazioniMin + Number(me.jsonData.pasitmin3);
        divisore = divisore + 1;
      }
      // console.log(misurazioniMax+" / "+divisore+" = ")
      // console.log(misurazioniMax/divisore);
      me.paMediaMax = Math.floor(misurazioniMax / divisore);
      me.paMediaMin = Math.floor(misurazioniMin / divisore);
      // console.log("Media max: "+me.paMediaMax);
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
