<template>
    <sa-page-layout :titolo="titolo" :pathResource="pathResource" :data="jsonData" :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Tariffa</label>
                        <span class="sa-data">{{ jsonData.codiceTariffa }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Descrizione Tariffa</label>
                        <span class="sa-data">{{ jsonData.descrizioneTariffa }}</span>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Importo</label>
                        <span class="sa-data">{{ jsonData.importo }} €</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">IVA</label>
                        <span class="sa-data">{{ jsonData.iva }}</span>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/tariffe/edit/" + me.id;
        me.loadData();
    },
    data() {
        return {
            titolo: "Tariffa View",
            pathResource: "/leonidaaccettazionetariffe",
            jsonData: {},
            linkback: "/tariffe",
            linkedit: null,
            showModalLoading: false,
        };
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                me.showModalLoading = true;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>
