<template>
    <div>
        <p>Test Tableau</p>
        <!-- <b-embed type="iframe" :src="link"></b-embed> -->
        <iframe width="1000" height="600" :src="link"></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            //link: "https://tableau.gesan.it/#/site/Demo/views/HealthEnv_17029993359380/Home?:showAppBanner=false&:origin=viz_share_link&:display_count=n&:showVizHome=n:embed=yes",
            // link: "http://10.10.13.28/t/Demo/views/HealthEnv_17029993359380/Home?:showAppBanner=false&:origin=viz_share_link&:display_count=n&:showVizHome=n:embed=yes",
            link: null,
        };
    },
    created() {
        let me = this;
        console.log(me.$route.query.authToken);
        me.link = "proxy?authToken=" + me.$route.query.authToken;
    },
};
</script>

<style></style>
