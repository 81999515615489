<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblDateVisit") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataAppuntamento) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblSportPracticed") }}</label>
                    <span class="sa-data">{{ jsonData.sportPraticato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblFollowingVisit") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.visitaSuccessiva) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">
                                {{ this.$i18n.t("patient.afa.lblWeeklyWorkouts") }}
                                <eventi-storico-misure-component misura="allenamenti_settimana" misuraLabel="Allenamenti Settimanali" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                            </label>
                            <span class="sa-data">{{ jsonData.allenamentiSettimana }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblSpeciality") }}</label>
                            <span class="sa-data">{{ jsonData.specialita }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblFederation") }}</label>
                            <span class="sa-data">{{ jsonData.federazione }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblOtherSports") }}</label>
                            <span class="sa-data">{{ jsonData.altriSport }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblAntitetanus") }}</label>
                            <span class="sa-data">{{ jsonData.antitetanica }}</span>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                            <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblAlcohol") }}</label>
                            <span class="sa-data">{{ jsonData.alcool }}</span>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblNarcoticSubstances") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.sostenzeStupefacenti) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patients.lblFirstExamination") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.primaVisita) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblHourVisit") }}</label>
                    <span class="sa-data">{{ formatTime(jsonData.oraAppuntamento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblYearsNumber") }}</label>
                    <span class="sa-data">{{ jsonData.numeroAnni }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ this.$i18n.t("patient.afa.lblHoursWorkout") }}
                        <eventi-storico-misure-component misura="ore_allenamento" misuraLabel="Ore Allenanemnto" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.oreAllenamento }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblCorporation") }}</label>
                    <span class="sa-data">{{ jsonData.societa }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblLHB") }}</label>
                    <span class="sa-data">{{ jsonData.aslNew }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblMenarche") }}</label>
                    <span class="sa-data">{{ jsonData.menarca }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblSmoking") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.fumo) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblCoffee") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.caffe) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblMilitary") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.militare) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblMilitaryMotif") }}</label>
                    <span class="sa-data">{{ jsonData.militareMotivo }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblIdentityDocument") }}</label>
                    <span class="sa-data">{{ this.$i18n.t(jsonData.documentoIdentitaTipo) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblIdentityDocumentNumber") }}</label>
                    <span class="sa-data">{{ jsonData.documentoIdentitaNumero }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblIdentityDocumentReleaser") }}</label>
                    <span class="sa-data">{{ jsonData.documentoIdentitaRilasciatoDa }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblIdentityDocumentDateRelease") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.documentoIdentitaScadenzaIl) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";

export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: { Type: Object, require: true },
        tipoEvento: { Type: String, require: true },
        gruppo: { Type: String, require: true },
    },
    components: { EventiStoricoMisureComponent },
    computed: {
        idPaziente() {
            let me = this;
            return me.jsonData.idPaziente;
        },
    },

    methods: {
        convertData(label, data) {
            let returnValue = "error translation";
            switch (label) {
                case "alcool":
                    if (data == 0) {
                        returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
                    } else if (data == 1) {
                        returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
                    } else if (data == 2) {
                        returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
                    } else if (data == 3) {
                        returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
                    } else if (data == 4) {
                        returnValue = this.getLabelTraduora("patient.afa.lblAbstinent");
                    }
                    break;
                case "fumo":
                    if (data == 0) {
                        returnValue = this.getLabelTraduora("patient.afa.lblNotSmoker");
                    } else if (data == 1) {
                        returnValue = this.getLabelTraduora("patient.afa.lblUpTo10Cigarettes");
                    } else if (data == 2) {
                        returnValue = this.getLabelTraduora("patient.afa.lblUpTo20Cigarettes");
                    } else if (data == 3) {
                        returnValue = this.getLabelTraduora("patient.afa.lblmore20Cigarettes");
                    }
                    break;
                case "caffe":
                    if (data == 0) {
                        returnValue = this.getLabelTraduora("patient.afa.lbl0Cups");
                    } else if (data == 1) {
                        returnValue = this.getLabelTraduora("patient.afa.lbl1Cup");
                    } else if (data == 2) {
                        returnValue = this.getLabelTraduora("patient.afa.lbl2Cups");
                    } else if (data == 3) {
                        returnValue = this.getLabelTraduora("patient.afa.lbl3Cups");
                    } else if (data == 4) {
                        returnValue = this.getLabelTraduora("patient.afa.lblMore3Cups");
                    }
                    break;
                case "cartaID":
                    if (data == "CI") {
                        returnValue = this.getLabelTraduora("patient.afa.lblIdentityCard");
                    } else if (data == "PASS") {
                        returnValue = this.getLabelTraduora("patient.afa.lblPassport");
                    }
                    break;
                case "default":
                    if (data == 0) {
                        returnValue = this.getLabelTraduora("patient.lblNo");
                    }
                    if (data == 1) {
                        returnValue = this.getLabelTraduora("patient.lblYes");
                    }
            }
            return returnValue;
        },
    },
};
</script>
