<template>
  <sa-page-layout :pathResource="pathResource" :showmolalloading="showmolalloading">
    <template slot="toolbar"></template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <adi-home-pua-list-component></adi-home-pua-list-component>
      </div>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import AdiHomePuaListComponent from "../components/AdiHomePuaListComponent.vue";
export default {
  components: { SaPageLayout, AdiHomePuaListComponent },
  created() {},
  data() {
    return {
      showmolalloading: false,
      pathResource: "/adipuautenti",
    };
  },
};
</script>
<style lang="css">
.sa-logo-home img {
  width: 100%;
  max-width: 35rem;
}
</style>
>
