<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataEvento" type="date"> </date-picker>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Visita" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPrescriptionDate") }}</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataPrescrizione" type="date"> </date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCodePrescription") }}</label>
          <b-form-select v-model="jsonData.codicePrescrizione" :options="codiciPrescrizioneOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblResult") }}</label>
          <b-form-select v-model="jsonData.esito" :options="esitiOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMinFrequency") }}</label>
          <b-form-input v-model="jsonData.frequenzaMin" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMedFrequency") }}</label>
          <b-form-input v-model="jsonData.frequenza" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxFrequency") }}</label>
          <b-form-input v-model="jsonData.frequenzaMax" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.cardiology.lblTherapy") }}</label>
          <b-form-textarea v-model="jsonData.terapia" rows="6" no-resize></b-form-textarea>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSaConduction") }}</label>
          <b-form-select v-model="jsonData.conduzionesa" :options="conduzioniSaOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAvConduction") }}</label>
          <b-form-select v-model="jsonData.conduzioneav" :options="conduzioniAvOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInterventricolarConduction") }}</label>
          <b-form-select v-model="jsonData.conduzioneinterv" :options="conduzioniIntervOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLownClass") }}</label>
          <b-form-select v-model="jsonData.classelown" :options="classiLownOptions" :value="null" value-field="value" text-field="text">
            <template #first>
              <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIndications") }}</label>
          <b-form-input v-model="jsonData.indicazione" type="text"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBpsv") }}</label>
          <b-form-input v-model="jsonData.bpsv" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBpsvCopule") }}</label>
          <b-form-input v-model="jsonData.bpsv24h" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBpsvRun") }}</label>
          <b-form-input v-model="jsonData.bpsv24h2" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBpv") }}</label>
          <b-form-input v-model="jsonData.bpv" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBpvCopule") }}</label>
          <b-form-input v-model="jsonData.bpv24h" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBpvRun") }}</label>
          <b-form-input v-model="jsonData.bpv24h2" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPr") }}</label>
          <b-form-input v-model="jsonData.pr" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPause") }}</label>
          <b-form-input v-model="jsonData.pause" type="number"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
          <b-form-textarea v-model="jsonData.commento" rows="6" no-resize></b-form-textarea>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixins],
  components: { DatePicker },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
    jsonData: {
      type: Object,
      default: function () {
        return { dataEvento: null };
      },
    },
  },

  data() {
    return {
      linkback: null,
      tabIndex: 0,
      childKey: 0,
      showModalLoading: false,
      pathResource: "/cardiomonitoraggiecg",
      firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
      codiciPrescrizioneOptions: [{ text: "89.50 MONITORAGGIO ECG", value: "89.50 MONITORAGGIO ECG" }],
      esitiOptions: [
        { value: "NORMALE", text: "NORMALE" },
        { value: "PATOLOGICO", text: "PATOLOGICO" },
      ],
      conduzioniSaOptions: [
        { value: "NORMALE", text: "NORMALE" },
        { value: "PATOLOGICO", text: "PATOLOGICO" },
      ],
      conduzioniAvOptions: [
        { value: "NORMALE", text: "NORMALE" },
        { value: "I GRADO", text: "I GRADO" },
        { value: "II GRADO", text: "II GRADO" },
        { value: "III GRADO", text: "III GRADO" },
      ],
      conduzioniIntervOptions: [
        { value: "NORMALE", text: "NORMALE" },
        { value: "PATOLOGICO", text: "PATOLOGICO" },
      ],
      classiLownOptions: [
        { value: "0", text: "0" },
        { value: "1A", text: "1A" },
        { value: "1B", text: "1B" },
        { value: "2", text: "2" },
        { value: "3", text: "3" },
        { value: "4A", text: "4A" },
        { value: "4B", text: "4B" },
        { value: "5", text: "5" },
      ],
      // indicazioniOptions: [],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
        me.jsonData.dataEvento = new Date().getTime();
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          this.$emit("update", response.data.data);
          me.showModalLoading = false;
        });
      }
    },
  },
};
</script>

<style></style>
