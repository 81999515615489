<template>
  <sa-page-layout :btnRefreshVisible="btnRefreshVisible" :btnNewVisible="btnNewVisible" @refresh="onRefresh" :linkedit="linkedit">
    <!--<template slot="toolbar">
            <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
            <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" />Refresh</b-button>
        </template>-->
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Struttura</label>
            <b-form-select v-model="filtro.codiceStruttura" :options="struttureOptions" :value="''" value-field="codiceStruttura" text-field="denominazione" :disabled="!isStrutture"></b-form-select>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Data Dal</label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="datetime" placeholder="Data Dal"></date-picker>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Data Al</label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="datetime" placeholder="Data Al"></date-picker>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Numero Documento</label>
            <b-form-input v-model="filtro.numeroDocumento" type="search" id="numeroDocumento" placeholder="Numero Documento"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Tipo Documento</label>
            <b-form-input v-model="filtro.tipoDocumentoDescrizione" type="search" id="tipoDocumentoDescrizione" placeholder="Tipo Documento"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Ragione Sociale</label>
            <b-form-input v-model="filtro.cognomeRagioneSoggetto" type="search" id="ragioneSoggetto" placeholder="Ragione Sociale"></b-form-input>
          </b-col>
        </b-row>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem"> </b-row>
        <b-row class="sa-form-btn-filter">
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">Cerca</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">Resetta Filtro</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6" xs="6" sm="6" md="6" lg="3" xl="3">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col cols="6" xs="6" sm="6" md="6" lg="9" xl="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(tipoDocumentoDescrizione)="{ item }">
            <router-link class="sa-edit-link" :to="'/contabilita/fatturazione/view/' + item.id">{{ item.tipoDocumentoDescrizione }}</router-link>
          </template>
          <template v-slot:cell(cognomeRagioneSoggetto)="{ item }">{{ item.cognomeRagioneSoggetto }} {{ item.nomeSoggetto }}</template>
          <template #cell(actions)="row">
            <b-button-group>
              <b-button size="sm" variant="btn btn-outline-purple waves-effect waves-light width-sm no-text" style="margin-right: 5px" @click="onStampa(row.item)">
                <b-icon icon="printer"></b-icon>
              </b-button>
              <b-button size="sm" variant="btn btn-outline-info no-text" @click="onDownload(row.item)">
                <b-icon icon="download"></b-icon>
              </b-button>
            </b-button-group>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6" xs="6" sm="6" md="6" lg="3" xl="3">
          <p>Totale Record: {{ rows }}</p>
        </b-col>
        <b-col cols="6" xs="6" sm="6" md="6" lg="9" xl="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <b-modal ref="mdlStampaReferto" size="xl" style="height: 100%">
        <b-embed type="iframe" :src="stampaReport" allowfullscreen></b-embed>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PaginationComponent from "../../utility/components/PaginationComponent.vue";
export default {
  components: { DatePicker, SaPageLayout },
  // computed: {
  //     rows() {
  //         return this.items.length;
  //     },
  // },
  data() {
    return {
      perPage: 100,
      currentPage: 1,
      filter: null,
      rows: null,
      btnRefreshVisible: true,
      btnNewVisible: true,
      isStrutture: false,
      linkedit: "/contabilita/fatturazione",
      pathResource: "/documenti",
      pathResourceStrutture: "/strutture",
      pathResourceStampa: "/print/createreport",
      stampaReport: null,
      reportName: "documenti",
      linkFatturaElettronica: "/documenti/fatturaelettronica",
      filtro: { codiceStruttura: "", dataDal: null, dataAl: null, numeroDocumento: "", tipoDocumentoDescrizione: "", cognomeRagioneSoggetto: "" },
      filterOn: [],
      struttureOptions: [],
      fields: [
        {
          label: this.$i18n.t("billing.lblDate"),
          key: "dataDocumento",
          sortable: true,
          thStyle: "width: 7rem",
          formatter: (value) => {
            return moment(new Date(value)).format("DD-MM-YYYY");
          },
        },
        {
          //label: this.$i18n.t("billing.lblDocumentNumber"), <- Correggere su Traduora
          label: "Num.",
          key: "numeroDocumento",
          thStyle: "width: 5rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("billing.lblDocumentType"),
          key: "tipoDocumentoDescrizione",
          thStyle: "width: 14rem",
          sortable: true,
        },
        {
          label: this.$i18n.t("billing.lblCompanyName"),
          key: "cognomeRagioneSoggetto",

          sortable: true,
        },
        {
          //label: this.$i18n.t("billing.lblTotaleDocumento"), <- Correggere su Traduora
          label: "Totale",
          key: "totaleDocumento",
          sortable: true,
          formatter: (value) => value.toLocaleString("it-IT", { style: "currency", currency: "EUR" }),
          thStyle: "width: 7rem",
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 6rem",
        },
        //{ class: "sa-table-column-action-single", key: "actions", label: "" },
      ],
      items: [],
    };
  },
  created: function () {},
  mounted: function () {
    let me = this;
    //me.$refs["SaPageList"].show();
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
    me.loadData();
    me.loadStrutture();
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      me.isBusy = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          //me.$refs["SaPageList"].close();
        })
        .catch(() => {
          me.showmolalloading = false;
        });
    },
    loadStrutture() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceStrutture;
      axios
        .get(link)
        .then((response) => {
          me.struttureOptions = response.data.data;
          if (me.struttureOptions.length > 1) {
            me.isStrutture = true;
            me.struttureOptions.unshift({ codiceStruttura: "", denominazione: "-Seleziona Struttura-" });
          } else {
            me.isStrutture = false;
            me.filtro.codiceStruttura = me.struttureOptions[0].codiceStruttura;
            me.loadData();
          }
        })
        .catch(() => {});
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = { codiceStruttura: "", dataDal: null, dataAl: null, numeroDocumento: "", tipoDocumentoDescrizione: "", cognomeRagioneSoggetto: "" };
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },

    onStampa(row) {
      let me = this;
      // console.log(row);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
      axios.get(link).then((response) => {
        let printData = response.data.data;
        let linkReport = process.env.VUE_APP_PATH_API + me.pathResourceStampa;
        axios.post(linkReport, { reportName: me.reportName, data: printData }).then((response) => {
          me.$refs.mdlStampaReferto.show();
          me.stampaReport = "data:application/pdf;base64," + response.data.base64;
        });
      });
    },
    onDownload(row) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkFatturaElettronica + "/" + row.id;
      axios({
        url: link, //your url
        method: "GET",
        responseType: "blob", // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        let nomeFile = row.numeroDocumento + "_" + moment(row.dataDocumento).format("DD-MM-YYYY");
        link.setAttribute("download", nomeFile + ".xml"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
