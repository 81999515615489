<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="toolbar">
            <b-button variant="outline-secondary btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onClickElaboraTutto">
                <b-icon icon="gear"></b-icon>
                Elabora Tutto
            </b-button>

        </template>        
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Codice Fiscale Medico</label>
                        <b-form-input v-model="filtro.codiceFiscaleMedico" type="search" id="filterInput" placeholder="Codice Fiscale Medico"></b-form-input>
                    </b-col>
                    <b-col cols="4" xs="4" sm="4" md="4" lg="2" xl="2">
                        <label class="sa-label-data">Anno</label>
                        <b-form-input v-model="filtro.anno" type="search" id="filterInput" placeholder="Anno"></b-form-input>
                    </b-col>
                    <b-col cols="4" xs="4" sm="4" md="4" lg="2" xl="2">
                        <label class="sa-label-data">Stato</label>
                        <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="filtro.stato" :options="statiOptions" value-field="codice" text-field="descrizione" ></b-form-select>
                    </b-col>
                    <b-col cols="6" xs="6" sm="6" md="6" lg="2" xl="2">
                        <label class="sa-label-data">Dal</label>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="datetime" placeholder="Data Dal"></date-picker>
                    </b-col>
                    <b-col cols="6" xs="6" sm="6" md="6" lg="2" xl="2">
                        <label class="sa-label-data">Al</label>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="datetime" placeholder="Data Al"></date-picker>
                    </b-col>
                </b-row>
                <b-row class="sa-form-btn-filter">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
            <div style="margin-top: 5px">
                <b-alert v-if="infoElaborazione.elaborazioneInCorso != null" show variant="danger">
                    <a @click="onClickElaborazioneIncorso">ATENZIONE: Ci sono già dei dati in elaborazione: {{ infoElaborazione.elaborazioneInCorso }}</a>
                </b-alert>
            </div>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(index)="row">
                        {{ (currentPage - 1) * perPage + row.index + 1 }}
                    </template>
                    <template v-slot:cell(codiceFiscaleMedico)="{ item }">
                        <router-link class="sa-edit-link" :to="'/caduceolapmanagement/medici/view/' + item.codiceFiscaleMedico">{{ item.codiceFiscaleMedico }}</router-link>
                    </template>
                    <template v-slot:cell(stato)="{ item }">
                        <a :class="getClassStato(item)">{{ item.stato }}</a>
                    </template>
                    <template #cell(actions)="row">
                        <b-row>
                            <!--:disabled="infoElaborazione.elaborazioneInCorso != null"-->
                            <b-button  variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" size="sm" @click="onElabora(row.item)"><i class="bi bi-gear"></i></b-button>
                        </b-row>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <b-modal id="mdlElaborazioneInCorsoLog" ref="mdlElaborazioneInCorsoLog" title="Log Elaborazione Attuale" size="lg" @ok="onOk">
                <p class="my-4"><pre>{{ infoElaborazione.elaborazioneInCorsoLog }}</pre></p>
            </b-modal>
        </template>
    </sa-page-layout>
</template>

<script>
import moment from "moment";
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { DatePicker, SaPageLayout },
    mixins: [UtilityMixin],
    data() {
        return {
            disabledElaborazione: false,
            showModalLoading: false,
            pathResource: "/registroinvii",
            pathResourceInfoElaborazione: "/elaborazioni",
            pathResourceElaborazione: "/elaborazioni",
            linkedit: "",
            currentPage: 1,
            perPage: 50,
            rows: null,
            interval: null,
            filtro: { codiceFiscaleMedico: "", dataDal: null, dataAl: null, stato: null },
            infoElaborazione: { elaborazioneInCorso: null },
            statiOptions:[
                {codice:null, descrizione: "TUTTI"},
                {codice:"ERROR", descrizione:"ERROR"},
                {codice:"ELABORATO", descrizione:"ELABORATO"},
                {codice:"NON ELABORATO", descrizione:"NON ELABORATO"},
                {codice:"DA ELABORARE", descrizione:"DA ELABORARE"}
            ],
            items: [],
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Codice Fiscale Medico",
                    key: "codiceFiscaleMedico",
                    sortable: true,
                    thStyle: "width: 15rem",
                },
                {
                    label: "Data Ora",
                    key: "dataOra",
                    sortable: true,
                    thStyle: "width: 12rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        } else {
                            return "-------";
                        }
                    },
                },
                {
                    label: "Anno",
                    key: "anno",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Log Elaborazione",
                    key: "logElaborazione",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 40px" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.loadInfoElaborazione();

        let sessionStorage = window.sessionStorage;
        me.filtro = JSON.parse(sessionStorage["filtro"]);
        me.filtro.page = me.currentPage;
        me.filtro.forPage = me.perPage;
        me.loadData();
        if (!this.interval) {
            this.interval = setInterval(() => me.loadInfoElaborazione(), 5000);
        }
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    beforeDestroy() {
        if (this.interval) {
            clearTimeout(this.interval);
        }
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadInfoElaborazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceInfoElaborazione;
            me.showModalLoading = false;
            axios
                .get(link)
                .then((response) => {
                    me.infoElaborazione = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onElabora(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceElaborazione;
            me.showModalLoading = true;
            axios
                .post(link, JSON.stringify(item))
                .then(() => {
                    
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        getClassStato(item) {
            // let me = this;
            if (item.stato === "ERROR") {
                return "text-danger";
            } else {
                return "";
            }
        },
        onClickElaborazioneIncorso() {
            let me = this;
            me.$refs["mdlElaborazioneInCorsoLog"].show();
        },
        onOk() {},
        onClickElaboraTutto(){
            console.log("Elabora");
        }
    },
};
</script>

<style></style>
