<template>
    <div style="padding-top: 10px">
        <b-form @submit.prevent="onSubmit" @reset="onReset">
            <b-row>
                <b-col cols="6" class="my-1">
                    <label for="input-1" class="sa-form-label">Codice Icd-10</label>
                    <b-form-input id="input-1" v-model="form.codice" type="text" placeholder="Codice Icd-10"></b-form-input>
                </b-col>
                <b-col cols="6" class="my-1">
                    <label for="input-2" class="sa-form-label">Descrizione</label>
                    <b-form-input id="input-2" v-model="form.descrizione" type="text" placeholder="Descrizione"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="sa-padding-right text-right">
                    <b-button type="submit" variant="primary">Cerca</b-button>
                </b-col>
            </b-row>
        </b-form>
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
            <b-col cols="12">
                <p>Totale Record: {{ rows }}</p>
            </b-col>
        </b-row>
        <div class="b-table-sticky-header">
            <b-table ref="table" selectable select-mode="single" sticky-header stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" @row-selected="onRowSelectedTblIcdX"></b-table>
        </div>
    </div>
</template>

<script>
import UtilityMixin from "../UtilityMixin";
import axios from "axios";
export default {
    mixins: [UtilityMixin],
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            currentPage: 1,
            perPage: 100,
            fields: [
                {
                    label: "Codice",
                    key: "codice",
                    thStyle: "width: 15rem text-center",
                    tdStyle: "text-center",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    thStyle: "width: 15rem text-center",
                    tdStyle: "text-center",
                    sortable: true,
                },
            ],

            pathResource: "/serviziicdx",
            items: [],
            showModalLoading: false,
            form: {},
        };
    },
    mounted() {
        // let me = this;
        // me.loadData();
    },
    methods: {
        onSubmit() {
            let me = this;
            me.loadData();
            return false;
        },
        onReset() {
            return 0;
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?forPage=" + me.perPage + "&page=" + me.currentPage;
            axios
                .get(link, { params: this.form })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRowSelectedTblIcdX(value) {
            let selected = { codice: null, descrizione: null };
            if (value.length > 0) {
                selected = value[0];
            }
            this.$emit("selected", selected);
        },
    },
};
</script>

<style></style>
