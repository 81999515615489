<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
        <template slot="tab-content-event">
            <anamnesi-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></anamnesi-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import AnamnesiEditComponent from "./AnamnesiEditComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    mixins: [UtilityMixins, EventiMixin],
    components: { EventiEditComponent, AnamnesiEditComponent },
    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            pathResource: "/cardioanamnesi",
            jsonData: {
                dataEvento: null,
                parentiCardiopatiaIsch: null,
                parentiCerebrovasc: null,
                dislipidemia: null,
                sedePregIma: null,
                sedePregPtca: null,
                sedePregCabg: null,
                consumoDiAlcool: null,
                attivitaLavorativa: null,
                problemiLavoro: null,
                problemiCasa: null,
                titoloDiStudio: null,
            },
            showModalLoading: false,
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            titoloEvento: this.getLabelTraduora("patient.menu.cardiologyAnamnesis"),
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
