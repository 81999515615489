<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Data Evento</label>
              <span class="sa-data"> {{ formatDateTime(jsonData.dataEvento) }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="BASALE" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblBasal") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMedium") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblApex") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoanterioreBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoanterioreMedioBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apiceSettoBas }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorApex") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteanterioreBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteanterioreMediaBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apiceAntBas }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatAnterioreBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatAnterioreMediaBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioFrontSideWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatPosterioreBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatPosterioreMediaBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apicePostBas }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioRearWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteInferioreBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteInferioreMediaBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apiceInfBas }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoposterioreBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoposterioreMedioBas }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Punteggio Totale</label>
              <span class="sa-data">{{ jsonData.totaleBas }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Estensione Danno IMA</label>
              <span class="sa-data">{{ jsonData.estensionedannoBas }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAS (mmHg)</label>
              <span class="sa-data">{{ jsonData.pasBasale }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAD (mmHg)</label>
              <span class="sa-data">{{ jsonData.padBasale }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">FC (bpm)</label>
              <span class="sa-data">{{ jsonData.fcBasale }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Sintomi</label>
              <span class="sa-data">{{ jsonData.sintomiBasale }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Conclusioni</label>
              <span class="sa-data">{{ jsonData.conclusioniBasale }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="VITALITA'" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblBasal") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMedium") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblApex") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoanterioreVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoanterioreMedioVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apiceSettoVit }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorApex") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteanterioreVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteanterioreMediaVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apiceAntVit }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatAnterioreVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatAnterioreMediaVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioFrontSideWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatPosterioreVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatPosterioreMediaVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apicePostVit }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioRearWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteInferioreVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteInferioreMediaVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apiceInfVit }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoposterioreVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoposterioreMedioVit }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Punteggio Totale</label>
              <span class="sa-data">{{ jsonData.totaleVit }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Estensione Danno IMA</label>
              <span class="sa-data">{{ jsonData.estensionedannoVit }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAS (mmHg)</label>
              <span class="sa-data">{{ jsonData.pasVit }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAD (mmHg)</label>
              <span class="sa-data">{{ jsonData.padVit }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">FC (bpm)</label>
              <span class="sa-data">{{ jsonData.fcVit }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Sintomi</label>
              <span class="sa-data">{{ jsonData.sintomiVit }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Conclusioni</label>
              <span class="sa-data">{{ jsonData.conclusioni }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="ISCHEMIA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblBasal") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblMedium") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <h5>{{ getLabelTraduora("patient.cardiology.lblApex") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoanterioreIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoanterioreMedioIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apiceSettoIsc }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorApex") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteanterioreIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteanterioreMediaIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apiceAntIsc }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatAnterioreIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatAnterioreMediaIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioFrontSideWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatPosterioreIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteLatPosterioreMediaIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apicePostIsc }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioRearWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteInferioreIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.pareteInferioreMediaIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.apiceInfIsc }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoposterioreIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
              <span class="sa-data">{{ jsonData.settoposterioreMedioIsc }}</span>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Punteggio Totale</label>
              <span class="sa-data">{{ jsonData.totaleIsc }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Estensione Danno IMA</label>
              <span class="sa-data">{{ jsonData.estensionedannoIsc }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAS (mmHg)</label>
              <span class="sa-data">{{ jsonData.pasIsc }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">PAD (mmHg)</label>
              <span class="sa-data">{{ jsonData.padIsc }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">FC (bpm)</label>
              <span class="sa-data">{{ jsonData.fcIsc }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Sintomi</label>
              <span class="sa-data">{{ jsonData.sintomiIsc }}</span>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Conclusioni</label>
              <span class="sa-data">{{ jsonData.conclusioni2 }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="NOTE" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Note</label>
              <span class="sa-data">{{ jsonData.noteFinali }}</span>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixins],
  props: {
    idEvento: { Type: String, require: true },
  },
  components: {},
  data() {
    return {
      linkback: null,
      pathResource: "/cardioecostress",
      jsonData: {},
      id: null,
      showModalLoading: false,
      aterosclerosiNonEmoSign: false,
      aterosclerosiSevera: false,
      fontScale: 2,
      linkedit: null,
      pathRestValidation: "/validation",
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  computed: {
    isFirmato() {
      let me = this;
      let isFirmato = !me.jsonData.firma;
      console.log(isFirmato, "FIRMA");
      me.$emit("updateStatoFirma", isFirmato);
      return !me.jsonData.firma;
    },
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        axios.get(link).then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        });
      }
    },
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
  },
};
</script>

<style></style>
