<template>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
        <div style="margin-top: 10px">
            <b-row style="margin-bottom: 10px">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-button variant="outline-primary sa-margin-right float-sm-right" size="sm" @click="onClickAggiungiEsame">
                        <b-icon icon="plus"></b-icon>
                        Aggiungi
                    </b-button>
                </b-col>
            </b-row>
            <div>
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col lg="3">
                        <p>Richieste Totali: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fieldsRichiesta" sort-icon-left head-variant="light" class="sa-b-table" select-mode="multi" selectable @row-selected="onRowSelected">
                    <template v-slot:cell(dataRichiesta)="{ item }">
                        <router-link class="sa-edit-link" :to="'/accertamentisupplementaridettagli/edit/' + item.id + '?idPaziente=' + idPaziente">{{ formatDateTime(item.dataRichiesta) }}</router-link>
                    </template>
                    <template #cell(action)="row">
                        <b-button size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" style="margin-right: 4px" @click="onDeleteRiga(row.item)">
                            <b-icon icon="trash"></b-icon>
                        </b-button>
                    </template>
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>
                </b-table>
            </div>
            <div>
                <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem; margin-bottom: 0.5rem">
                    <b-col lg="3">
                        <p>Richieste Totali: {{ rows }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </div>
            <div>
                <b-modal ref="mdlAggiungiEsame" id="mdlAggiungiEsame" title="Aggiungi Esame" size="xl" @hidden="onHidden" @ok="onOkMdlAggiungiEsame">
                    <div class="sa-form-section">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <strong>Diagnosi</strong>
                                <br />
                                <b-form-textarea v-model="data.motivazione" no-resize></b-form-textarea>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <strong>Data/Ora richiesta</strong>
                                <br />
                                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="data.dataRichiesta" type="timestamp" placeholder="Data Richiesta"></date-picker>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <strong>Esame Richiesto</strong>
                                <br />
                                <b-input-group>
                                    <b-form-select class="mb-2 mr-sm-2 mb-sm-0" v-model="nuovaRiga.tipoEsame" :options="tipoEsameOptions" :value="null" value-field="value" text-field="text"></b-form-select>
                                    <b-button size="sm" variant="btn btn-outline-info no-text" @click="onClickAggiungiRiga()">
                                        <b-icon icon="plus"></b-icon>
                                    </b-button>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <div class="b-table-sticky-header" style="margin-top: 10px">
                            <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="data.visitaEsameSupplementare" :fields="fieldsRichiestaEsami" sort-icon-left head-variant="light" class="sa-b-table">
                                <template v-slot:cell(tipoEsame)="{ item }">
                                    <a v-b-modal.mdlAggiungiEsame href="#" onclick="return false">{{ item.tipoEsame }}</a>
                                </template>
                            </b-table>
                        </div>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <strong>Conclusioni</strong>
                                <br />
                                <b-form-textarea v-model="data.conclusioni" no-resize></b-form-textarea>
                            </b-col>
                        </b-row>
                    </div>
                </b-modal>
            </div>
        </div>
    </b-card>
</template>
<script>
import moment from "moment";
import axios from "axios";
import DatePicker from "vue2-datepicker";
export default {
    components: { DatePicker },
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    motivazione: "",
                    dataRichiesta: Date.now(),
                    conclusioni: "",
                    idVisita: "",
                    visitaEsameSupplementare: [],
                };
            },
        },
    },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    mounted() {
        let me = this;
        me.data.idVisita = this.$route.params.id;
        me.idPaziente = this.$route.query.idPaziente;
        me.loadTipoEsame();
    },
    data() {
        return {
            pathResource: "/visiterichiestaesamisupplementari",
            linkTipoEsame: "/visiteesamisupplementaritipoesame",
            currentPage: 1,
            perPage: 10,
            nuovaRiga: { tipoEsame: null, pdf: null, updateDate: null, referto: "", statoAttuale: "", statoAttualeDataOra: Date.now(), note: "" },
            items: [],
            selectedRow: [],
            fieldsRichiesta: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 0.2rem",
                    tdClass: "text-center",
                },
                {
                    label: "Richiesta",
                    key: "dataRichiesta",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    sortable: true,
                },
                {
                    label: "Stato Attuale",
                    key: "statoAttuale",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Stato Attuale Data/Ora",
                    key: "statoAttualeDataOra",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "",
                    key: "action",
                    thStyle: "width: 3rem",
                    tdClass: "text-center",
                },
            ],
            fieldsRichiestaEsami: [
                {
                    label: "Esame Richiesto",
                    key: "tipoEsame",
                    sortable: true,
                },
                {
                    label: "Data/Ora richiesta",
                    key: "updateDate",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
            ],
            tipoEsameOptions: [{ value: null, text: "-Seleziona Esame-" }],
        };
    },
    methods: {
        onClickAggiungiEsame() {
            let me = this;
            me.data.motivazione;
            me.nuovaRiga = { tipoEsame: null, pdf: null, updateDate: null, referto: "", statoAttuale: "", statoAttualeDataOra: Date.now(), note: "" };
            me.$refs.mdlAggiungiEsame.show();
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idVisita=" + me.data.idVisita;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    for (let element in me.items) {
                        me.items[element]["_cellVariants"] = {};
                        if (me.items[element]["statoAttuale"] === "INCOMPLETA") {
                            me.items[element]["_cellVariants"].statoAttuale = "danger";
                        } else if (me.items[element]["statoAttuale"] === "DA FIRMARE") {
                            me.items[element]["_cellVariants"].statoAttuale = "warning";
                        } else {
                            me.items[element]["_cellVariants"].statoAttuale = "success";
                        }
                    }
                })
                .catch(() => {});
        },
        loadTipoEsame() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTipoEsame + "?page=1&forPage=50";
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.tipoEsameOptions.push({ value: element.descrizione, text: element.descrizione });
                });
            });
        },
        onOkMdlAggiungiEsame() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            let jsonData = JSON.stringify(me.data);
            axios
                .post(link, jsonData)
                .then(() => {
                    // me.data = response.data.data;
                    me.loadData();
                })
                .catch((error) => {
                    me.$refs["sa-modal-loading"].close();
                    this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblEditTitle")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                })
                .then((value) => {
                    this.botTwo = value;
                })
                .catch(() => {});
        },
        onHidden() {
            let me = this;
            me.data.visitaEsameSupplementare = [];
            me.data.dataRichiesta = Date.now();
            me.data.motivazione = "";
            me.data.conclusioni = "";
            me.nuovaRiga = { tipoEsame: null, pdf: null, updateDate: null, referto: "", statoAttuale: "", statoAttualeDataOra: Date.now(), note: "" };
        },
        formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        onDeleteRiga(row) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + row.id;
            axios
                .delete(link)
                .then(() => {
                    me.loadData();
                })
                .catch((e) => {
                    alert(e);
                });
        },
        onClickAggiungiRiga() {
            let me = this;
            me.data.visitaEsameSupplementare.push(me.nuovaRiga);
            me.nuovaRiga = { tipoEsame: null, pdf: null, updateDate: null, referto: "", statoAttuale: "", statoAttualeDataOra: Date.now(), note: "" };
        },
        onRowSelected(rows) {
            this.$emit("update", rows);
        },
        // getJsonData() {
        //     let me = this;
        //     return me.selectedRow;
        // },
    },
};
</script>
