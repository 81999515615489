<template>
    <b-row>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCommonIliacArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artIliacaComuneDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artIliacaComuneVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artIliacaComuneDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
            <b-form-select v-model="jsonData.ccscoredx" :options="scoreOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
            <b-form-select v-model="jsonData.cccoldx" :options="colorOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
            <b-form-input v-model="jsonData.maximtnearccdx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
            <b-form-input v-model="jsonData.maximtfarccdx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblExternalIliacArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artIliacaEsternaDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artIliacaEsternaVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artIliacaEsternaDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <!---->
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblInternalIliacArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artIliacaInternaDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artIliacaInternaVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artIliacaInternaDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCommonFemoralArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artFemoraleComDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artFemoraleComVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artFemoraleComDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
            <b-form-select v-model="jsonData.bscoredx" :options="scoreOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
            <b-form-select v-model="jsonData.bcoldx" :options="colorOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
            <b-form-input v-model="jsonData.maximtnearbdx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
            <b-form-input v-model="jsonData.maximtfarbdx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblFemoralBifurcation") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.biforcazioneFemoraleDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.biforcazioneFemoraleVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.biforcazioneFemoraleDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblDeepFemoralArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaFemoraleProfDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleProfVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleProfDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblSuperiorFemoralArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaFemoraleSupProxDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupProxVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupProxDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
            <b-form-select v-model="jsonData.ciscoredx" :options="scoreOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
            <b-form-select v-model="jsonData.cecoldx" :options="colorOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
            <b-form-input v-model="jsonData.maximtnearcidx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
            <b-form-input v-model="jsonData.maximtfarcidx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblFemoralArteryMed") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaFemoraleSupMedDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupMedVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupMedDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblFemoralArteryDist") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaFemoraleSupDistDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupDistVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaFemoraleSupDistDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPoplitealArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaPopliteaDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaPopliteaVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaPopliteaDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
            <b-form-select v-model="jsonData.cescoredx" :options="scoreOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
            <b-form-select v-model="jsonData.cicoldx" :options="colorOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
            <b-form-input v-model="jsonData.maximtnearcedx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
            <b-form-input v-model="jsonData.maximtfarcedx" step="0.1" min="0.1" max="8" type="number"></b-form-input>
        </b-col>
        <!------->
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorTibialArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaTibialeAntDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaTibialeAntVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaTibialeAntDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblTibioPeronieroTrunk") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.troncoTibioPeronieroDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.troncoTibioPeronieroVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.troncoTibioPeronieroDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorTibialAtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.artTibialePostDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.artTibialePostVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.artTibialePostDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
        <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPeronieraArtery") }}</label>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="2" lg="2" xl="2">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
            <b-form-select v-model="jsonData.arteriaPeronieraDx" :options="stenosiOptions">
                <template #first>
                    <b-form-select-option :value="null" disabled>{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                </template>
            </b-form-select>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="1" lg="1" xl="1">
            <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
            <b-form-input v-model="jsonData.arteriaPeronieraVpsDx" type="number"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
            <b-form-input v-model="jsonData.arteriaPeronieraDescDx"></b-form-input>
        </b-col>
        <b-col class="ft-color-section" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data"></label>
        </b-col>
    </b-row>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            type: Object,
            default: function () {
                return {
                    ccscoredx: null,
                };
            },
        },
    },
    data() {
        return {
            stenosiOptions: [
                { text: "0 - 29 %", value: "0-29%" },
                { text: "30 - 49 %", value: "30-49%" },
                { text: "50 - 69 %", value: "50-69%" },
                { text: "70 - 89 %", value: "70-89%" },
                { text: "90 - 99 %", value: "90-99%" },
                { text: this.getLabelTraduora("patient.cardiology.slcStenosisOcclusion"), value: "patient.cardiology.slcStenosisOcclusion" },
                { text: "NA", value: "NA" },
                { text: "NES", value: "NES" },
            ],
            colorOptions: [
                { text: "L", value: "patient.cardiology.slcColorValueLaminare" },
                { text: "T", value: "patient.cardiology.slcColorValueTurbolento" },
            ],
            scoreOptions: [
                { text: "0", value: "0" },
                { text: "1", value: "1" },
                { text: "2", value: "2" },
                { text: "3", value: "3" },
                { text: "4", value: "4" },
                { text: "5", value: "5" },
            ],
        };
    },
};
</script>

<style></style>
