<template>
    <div style="width: 100%; padding: 1px">
        <div class="sa-form-section">
            <h5 class="sa-text-left">Dati Struttura</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblStructure") }}</span>
                    <b-form-select id="inline-form-custom-select-pref" v-model="data.codiceStruttura" class="mb-2 mr-sm-2 mb-sm-0" :options="struttureOptions" :value="null" value-field="codiceStruttura" text-field="denominazione" @change="loadAmbulatorio" :disabled="!edit"></b-form-select>
                </b-col>
            </b-row>
            <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblPatientRegistry") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblLastname") }}</span>
                    <b-form-input v-model="data.cognome" placeholder="Cognome" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblName") }}</span>
                    <b-form-input v-model="data.nome" placeholder="Nome" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblIdentificationType") }}</span>
                    <b-form-select v-model="data.tipoIdentificativo" class="mb-2 mr-sm-2 mb-sm-0" :options="tipoIdentificativoOptions" :value="null" :disabled="!edit"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblFiscalCode") }}</span>
                    <b-input-group class="mb-3">
                        <b-form-input maxlength="16" class="text-uppercase" @keypress="onKeyPress" v-model="data.identificativo" placeholder="Codice Fiscale" :disabled="!edit"></b-form-input>
                        <b-button :disabled="disabledAccept(!data.tipoIdentificativo)" size="sm" variant="" @click="onSearchAnagraficaClick"> <b-icon icon="search"></b-icon> </b-button>
                    </b-input-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblBirthDate") }}</span>
                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="data.dataNascita" type="date" placeholder="Data Nascita" :disabled="!edit"></date-picker>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span>
                    <b-form-select :disabled="!edit" id="inline-form-custom-select-pref" v-model="data.provinciaNascita" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceNascitaOptions" :value="null" value-field="codiceIstat" text-field="provincia" @change="onProvinciaNascitaChange"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span>
                    <b-form-select id="inline-form-custom-select-pref" :disabled="!edit" v-model="data.comuneNascitaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniNascitaOptions" :value="null" value-field="codiceIstat" text-field="comune"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblGender") }}</span>
                    <b-form-select id="inline-form-custom-select-pref" :disabled="!edit" v-model="data.sesso" class="mb-2 mr-sm-2 mb-sm-0" :options="sessoOption" :value="null"></b-form-select>
                </b-col>
            </b-row>
        </div>
        <div class="sa-form-section">
            <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblResidence") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblAddress") }}</span>
                    <b-form-input v-model="data.indirizzoResidenza" placeholder="Indirizzo" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span>
                    <b-form-select id="inline-form-custom-select-pref" :disabled="!edit" v-model="data.provinciaResidenza" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceResidenzaOptions" :value="null" value-field="codiceIstat" text-field="provincia" @change="onProvinciaResidenzaChange"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span>
                    <b-form-select id="inline-form-custom-select-pref" :disabled="!edit" v-model="data.comuneResidenzaCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniResidenzaOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onComuneResidenzaInput"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblPostalCode") }}</span>
                    <b-form-input v-model="data.capResidenza" placeholder="C.A.P." :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
        </div>
        <div class="sa-form-section">
            <h6 class="sa-text-left">{{ this.$i18n.t("patients.lblDomicile") }}</h6>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblAddress") }}</span>
                    <b-form-input v-model="data.indirizzoDomicilio" placeholder="Indirizzo" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblProvince") }}</span>
                    <b-form-select id="inline-form-custom-select-pref" :disabled="!edit" v-model="data.provinciaDomicilio" class="mb-2 mr-sm-2 mb-sm-0" :options="provinceDomicilioOptions" :value="null" value-field="codiceIstat" text-field="provincia" @change="onProvinciaDomicilioChange"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblCity") }}</span>
                    <b-form-select id="inline-form-custom-select-pref" :disabled="!edit" v-model="data.comuneDomicilioCodiceIstat" class="mb-2 mr-sm-2 mb-sm-0" :options="comuniDomicilioOptions" :value="null" value-field="codiceIstat" text-field="comune" @input="onComuneDomicilioInput"></b-form-select>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblPostalCode") }}</span>
                    <b-form-input v-model="data.capDomicilio" placeholder="C.A.P." :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
        </div>
        <div class="sa-form-section">
            <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblContacts") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblTelephoneNumber") }}</span>
                    <b-form-input v-model="data.telefono" placeholder="Telefono" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblEmail") }}</span>
                    <b-form-input v-model="data.email" placeholder="Email" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
        </div>
        <div class="sa-form-section">
            <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblDoctor") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblLastname") }} {{ this.$i18n.t("patients.lblName") }} </span>
                    <b-form-input v-model="data.medicoCurante" placeholder="Medico Curante" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                    <span class="sa-form-label">{{ this.$i18n.t("patients.lblFiscalCode") }}</span>
                    <b-form-input v-model="data.codiceFiscaleMedicoCurante" class="text-uppercase" placeholder="Codice Fiscale medico curante" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
        </div>
        <div class="sa-form-section">
            <h5 class="sa-text-left">{{ this.$i18n.t("patients.lblNote") }}</h5>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-form-textarea v-model="data.note" placeholder="Note" :disabled="!edit" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
    components: { DatePicker },
    props: {
        link: String,
        data: {
            type: Object,
            default: function() {
                return {
                    codiceStruttura: null,
                    cognome: null,
                    nome: null,
                    tipoIdentificativo: null,
                    identificativo: null,
                    dataNascita: null,
                    provinciaNascita: null,
                    sesso: null,
                    comuneNascitaCodiceIstat: null,
                    comuneResidenzaCodiceIstat: null,
                    indirizzoResidenza: null,
                    provinciaResidenza: null,
                    capResidenza: null,
                    indirizzoDomicilio: null,
                    provinciaDomicilio: null,
                    comuneDomicilioCodiceIstat: null,
                    capDomicilio: null,
                    telefono: null,
                    email: null,
                    medicoCurante: null,
                    codiceFiscaleMedicoCurante: null,
                    note: null,
                };
            },
        },
        edit: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            // pathResource: "/accettazioni",
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkComuneByIstat: "/comuni/istat",
            linkStrutture: "/strutture",
            linkAmbulatori: "/ambulatori",
            linkTipoIdentificativo: "/anagraficaidentificativo",
            sessoOption: [
                { value: null, text: "- Sel. Sesso -" },
                { value: "M", text: "Maschio" },
                { value: "F", text: "Femmina" },
            ],
            provinceNascitaOptions: [{ codiceIstat: null, provincia: "-Seleziona Provincia-" }],
            provinceDomicilioOptions: [],
            provinceResidenzaOptions: [],
            comuniNascitaOptions: [{ codiceIstat: null, comune: "- Seleziona Comune -" }],
            comuniDomicilioOptions: [{ codiceIstat: null, comune: "- Seleziona Comune -" }],
            comuniResidenzaOptions: [{ codiceIstat: null, comune: "- Seleziona Comune -" }],
            struttureOptions: [{ value: null, text: "-Struttura-" }],
            ambulatoriOptions: [{ value: null, text: "-Selezionare una Struttura-" }],
            tipoIdentificativoOptions: [{ value: null, text: "-Seleziona Identificativo-" }],
        };
    },
    mounted: function() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadProvince();
            me.loadStrutture();
            me.loadTipoIdentificativo();
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        disabledAccept() {
            let me = this;
            if (me.data.tipoIdentificativo === "CODICE_FISCALE") {
                return false;
            } else {
                return true;
            }
        },
        loadStrutture() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
            axios.get(link).then((response) => {
                me.struttureOptions = response.data.data;
                me.struttureOptions.unshift({ codiceStruttura: null, denominazione: "- Seleziona Struttura -" });
            });
        },
        loadAmbulatorio(id) {
            let me = this;
            me.ambulatoriOptions = [];
            let link = process.env.VUE_APP_PATH_API + me.linkAmbulatori;
            axios.get(link, { params: { idStruttura: id } }).then((response) => {
                response.data.data.forEach((element) => {
                    console.log(element);
                    me.ambulatoriOptions.push({ value: element.id, text: element.nome });
                });
                me.$emit("update", me.data, me.ambulatoriOptions);
            });
        },
        loadTipoIdentificativo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkTipoIdentificativo;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.tipoIdentificativoOptions.push({ value: element.codice, text: element.descrizione });
                });
            });
        },
        onKeyPress(e) {
            let me = this;
            if (e.keyCode === 13) {
                me.onSearchAnagraficaClick();
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                me.provinceNascitaOptions = response.data.data;
                me.provinceNascitaOptions.unshift({ codiceIstat: null, provincia: "- Seleziona Provincia -" });
                response.data.data.forEach((element) => {
                    me.provinceDomicilioOptions.push(element);
                    me.provinceResidenzaOptions.push(element);
                });
            });
        },
        onProvinciaNascitaChange(value) {
            let me = this;
            me.provinceNascitaOptions.forEach((provincia) => {
                if (provincia.codiceIstat === value) {
                    me.setDatiNascita(provincia.sigla);
                }
            });
        },
        setDatiNascita(siglaProvincia, codiceIstatComuneNascita = null) {
            let me = this;
            me.data.comuneNascitaCodiceIstat = codiceIstatComuneNascita;
            me.provinceNascitaOptions.forEach((provincia) => {
                if (provincia.sigla === siglaProvincia) {
                    me.data.provinciaNascita = provincia.codiceIstat;
                    me.loadComuniNascita(provincia.sigla);
                }
            });
        },
        loadComuniNascita(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniNascitaOptions = [];
                me.comuniNascitaOptions = response.data.data;
                me.comuniNascitaOptions.unshift({ codiceIstat: null, comune: "- Seleziona Comune -" });
            });
        },
        onProvinciaResidenzaChange(value) {
            let me = this;
            me.provinceResidenzaOptions.forEach((provincia) => {
                if (provincia.codiceIstat === value) {
                    me.setDatiResidenza(provincia.sigla);
                }
            });
        },
        setDatiResidenza(siglaProvincia, codiceIstatComuneResidenza = null) {
            let me = this;

            me.provinceResidenzaOptions.forEach((provincia) => {
                if (provincia.sigla === siglaProvincia) {
                    me.data.provinciaResidenza = provincia.codiceIstat;
                    me.loadComuniResidenza(provincia.sigla, codiceIstatComuneResidenza);
                }
            });
        },
        loadComuniResidenza(sigla, codiceIstatComuneResidenza = null) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link, { params: { provincia: sigla } }).then((response) => {
                me.comuniResidenzaOptions = response.data.data;
                me.comuniResidenzaOptions.unshift({ codiceIstat: null, comune: "- Seleziona Comune -" });
                me.data.comuneResidenzaCodiceIstat = codiceIstatComuneResidenza;
                //me.onCapResidenzaChange(me.data.comuneResidenzaCodiceIstat);
            });
        },
        onComuneResidenzaInput(value) {
            let me = this;
            me.comuniResidenzaOptions.forEach((comune) => {
                if (comune.codiceIstat === value) {
                    me.data.capResidenza = comune.cap;
                }
            });
        },
        onProvinciaDomicilioChange(value) {
            let me = this;
            me.data.comuneDomicilioCodiceIstat = null;
            me.provinceDomicilioOptions.forEach((provincia) => {
                if (provincia.codiceIstat === value) {
                    me.loadComuniDomicilio(provincia.sigla);
                }
            });
        },
        setDatiDomicilio(siglaProvincia, codiceIstatComuneDomicilio = null) {
            let me = this;

            me.provinceDomicilioOptions.forEach((provincia) => {
                if (provincia.sigla === siglaProvincia) {
                    me.data.provinciaDomicilio = provincia.codiceIstat;
                    me.loadComuniDomicilio(provincia.sigla, codiceIstatComuneDomicilio);
                }
            });
        },
        loadComuniDomicilio(sigla, codiceIstatComuneDomicilio = null) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuniDomicilioOptions = response.data.data;
                me.comuniDomicilioOptions.unshift({ codiceIstat: null, comune: "- Seleziona Comune -" });
                me.data.comuneDomicilioCodiceIstat = codiceIstatComuneDomicilio;
            });
        },
        onComuneDomicilioInput(value) {
            let me = this;
            me.data.capDomicilio = "";
            me.comuniDomicilioOptions.forEach((comune) => {
                if (comune.codiceIstat === value) {
                    me.data.capDomicilio = comune.cap;
                }
            });
        },
        onSearchAnagraficaClick() {
            let me = this;
            me.showmolalloading = true;
            if (me.data.identificativo.trim().length === 0) {
                me.showmolalloading = false;
            } else {
                me.data.identificativo = me.data.identificativo.toUpperCase();
                let link = process.env.VUE_APP_PATH_API + "/utility";
                axios
                    .get(link, { params: { codiceFiscale: me.data.identificativo } })
                    .then((response) => {
                        me.data.nome = response.data.data.nome;
                        me.data.cognome = response.data.data.cognome;
                        me.data.dataNascita = moment(response.data.data.dataNascita, "DD/MM/YYYY")
                            .toDate()
                            .getTime();
                        me.setDatiNascita(response.data.data.provinciaNascita, response.data.data.codiceIstatComuneNascita);
                        me.data.sesso = response.data.data.sesso;

                        me.data.indirizzoResidenza = response.data.data.indirizzoResidenza;
                        me.data.indirizzoDomicilio = response.data.data.indirizzoResidenza;
                        me.setDatiResidenza(response.data.data.provinciaResidenza, response.data.data.codiceIstatComuneResidenza);
                        me.setDatiDomicilio(response.data.data.provinciaResidenza, response.data.data.codiceIstatComuneResidenza);
                        me.data.codiceFiscaleMedicoCurante = response.data.data.codiceFiscaleMedico;
                        me.data.medicoCurante = response.data.data.medico;
                        me.showmolalloading = false;
                    })
                    .catch(() => {
                        me.showmolalloading = false;
                    });
            }
        },
    },
};
</script>
