<template>
  <div class="sa-list-component">
    <div class="sa-list-component-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice Rencam:</label>
            <b-form-input v-model="filtro.codiceIcdx"></b-form-input>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Codice Gemo:</label>
            <b-form-input v-model="filtro.codiceGemo"></b-form-input>
          </b-col>
          <!-- <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Codice Dispositivo:</label>
              <b-form-input v-model="filtro.dispositivo"></b-form-input>
            </b-col> -->
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">Cerca</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">Reset</b-button>
          </b-col>
        </b-row>
      </b-form>
    </div>
    <div class="sa-list-component-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <div class="sa-list-component-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="jsonData" :fields="fields" :current-page="1" :per-page="perPage" :key="listKey" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="row">
            {{ row.index + 1 + (currentPage - 1) * perPage }}
          </template>
          <template v-slot:cell(rencam)="{ item }">
            <span>{{ item.codiceIcdx }} </span>
            <span class="sa-label-data-secondary">
              <span>{{ item.descrizioneIcdx }} </span>
            </span>
          </template>
          <template v-slot:cell(gemo)="{ item }">
            <span>{{ item.codiceGemo }} </span>
            <span class="sa-label-data-secondary">
              <span>{{ item.descrizioneGemo }} </span>
            </span>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="row.item.codiceGemo === null" size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onOpenModal(row.item)">
              <b-icon icon="pencil"></b-icon>
            </b-button>
            <b-button v-else size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDeleteAssociazione(row.item)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </div>
    <div class="sa-list-component-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </div>
    <b-modal size="xl" ref="modalCodifica" id="modalCodifica" title="Seleziona La Codifica Gemo" :scrollable:="false" no-close-on-backdrop @ok="onOk">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <span class="sa-label-data">Codice Rencam:</span>
          <span class="sa-data">{{ recordDaModificare.codiceIcdx }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <span class="sa-label-data">Descrizione Rencam:</span>
          <span class="sa-data">{{ recordDaModificare.descrizioneIcdx }}</span>
        </b-col>
      </b-row>
      <div class="sa-list-component-filter">
        <b-form @submit.prevent="onSubmitModal">
          <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Codice</label>
              <b-form-input v-model="filtroGemo.codice"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Descrizione:</label>
              <b-form-input v-model="filtroGemo.descrizione"></b-form-input>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="sa-padding-right text-right">
              <b-button type="submit" variant="info">Cerca</b-button>
              <b-button type="reset" v-on:click="onResetModal" variant="danger">Reset</b-button>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <div class="sa-list-component-header">
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rowsModal }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPageGemo" :total-rows="rowsModal" :per-page="perPageGemo" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
      <div class="sa-list-component-body">
        <div class="b-table-sticky-header">
          <b-table sticky-header ref="table" stacked="lg" striped hover :items="icdxGemo" :fields="fieldsGemo" :current-page="1" :per-page="perPageGemo" :key="listKey" selectable select-mode="single" @row-selected="onRowSelected" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 + (currentPageGemo - 1) * perPageGemo }}
            </template>
          </b-table>
        </div>
      </div>
      <div class="sa-list-component-footer">
        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col lg="3">
            <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rowsModal }}</p>
          </b-col>
          <b-col lg="9">
            <b-pagination v-model="currentPageGemo" :total-rows="rowsModal" :per-page="perPageGemo" align="right" size="sm"></b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      rows: 0,
      rowsModal: 0,
      perPage: 50,
      perPageGemo: 10,
      currentPage: 1,
      currentPageGemo: 1,
      pathResource: "/rencamserviziicdxconversionegemo",
      pathResourceIcdxGemo: "/rencamserviziicdxgemo",
      filtro: { codiceIcdx: "", codiceGemo: "" },
      filtroGemo: { codice: "", descrizione: "" },
      recordDaModificare: {},
      jsonData: [],
      icdxGemo: [],
      listKey: 0,
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Rencam",
          key: "rencam",
          sortable: true,
        },
        {
          label: "Gemo",
          key: "gemo",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsGemo: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Codice",
          key: "codice",
          sortable: true,
        },
        {
          label: "Descrizione",
          key: "descrizione",
          sortable: true,
        },
        // {
        //   label: "",
        //   key: "actions",
        //   sortable: false,
        //   thStyle: "width: 4rem",
        // },
      ],
    };
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
    currentPageGemo() {
      let me = this;
      console.log("Change Gemp");
      me.loadIcdxGemo();
    },
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.jsonData = [];
          me.jsonData = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.listKey++;
          me.changeGestore();
          this.$emit("afterLoadData");
        })
        .catch(() => {
          this.$emit("afterLoadData");
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onSubmitModal() {
      let me = this;
      me.loadIcdxGemo();
    },
    onResetModal() {
      let me = this;
      me.filtroGemo = {};
      me.loadIcdxGemo();
    },
    onOpenModal(value) {
      let me = this;
      me.recordDaModificare = value;
      me.loadIcdxGemo();
      me.$refs["modalCodifica"].show();
    },
    onOk() {
      let me = this;
      me.aggiornaRiga(me.recordDaModificare);
    },
    loadIcdxGemo() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceIcdxGemo;
      me.filtroGemo.page = me.currentPageGemo;
      me.filtroGemo.forPage = me.perPageGemo;
      axios
        .get(link, { params: me.filtroGemo })
        .then((response) => {
          me.icdxGemo = [];
          me.icdxGemo = response.data.data.list;
          me.rowsModal = response.data.data.recordsNumber;
        })
        .catch(() => {});
    },
    onRowSelected(value) {
      let me = this;
      me.recordDaModificare.codiceGemo = value[0].codice;
      me.recordDaModificare.descrizioneGemo = value[0].descrizione;
    },
    aggiornaRiga(value) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + value.id;
      let data = JSON.stringify(value);
      axios
        .put(link, data)
        .then((response) => {
          me.jsonData.push(response.data.data);
          me.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onDeleteAssociazione(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sicuro di voler procedere alla disassociazione?", {
          title: "Titolo",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            item.codiceGemo = null;
            item.descrizioneGemo = null;
            me.aggiornaRiga(item);
          }
        });
    },
  },
};
</script>

<style></style>
