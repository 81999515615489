import GestioneAttivitaAttivita from "../attivita";
// import GestioneAttivitaBacehca from "../bacheca";
const routes = [
    { name: "GestioneAttivitaAttivitaList", path: "/gestioneattivita/attivita", component: GestioneAttivitaAttivita.GestioneAttivitaAttivitaListPage, meta: { title: "" } },
    { name: "GestioneAttivitaAttivitaEditPage", path: "/gestioneattivita/attivita/edit/:id", component: GestioneAttivitaAttivita.GestioneAttivitaAttivitaEditPage, meta: { title: "" } },
    // { name: "GestioneAttivitaBachecaList", path: "/gestioneattivita/bacheca", component: GestioneAttivitaBacehca.AttivitaBachecaList, meta: { title: "" } },
    // { name: "GestioneAttivitaBachecaView", path: "/gestioneattivita/bacheca/view/:id", component: GestioneAttivitaBacehca.AttivitaBachecaView, meta: { title: "" } },
    // { name: "GestioneAttivitaBachecaEdit", path: "/gestioneattivita/bacheca/edit/:id", component: GestioneAttivitaBacehca.AttivitaBachecaEdit, meta: { title: "" } },
];

export default routes;
