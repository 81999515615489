<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading" :data="jsonData" class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <proposta-terapeutica-edit-component @update="onUpdateJsonData"></proposta-terapeutica-edit-component>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import PropostaTerapeuticaEditComponent from "../components/PropostaTerapeuticaEditComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SaPageLayout, PazienteAnagrafica, PropostaTerapeuticaEditComponent },
    data() {
        return {
            pathResource: "/pazienteterapie",
            showModalLoading: false,
            linkback: null,
            jsonData: {},
        };
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/paziente/propostaterapeutica";
        } else {
            me.linkback = "/paziente/propostaterapeutica/view/" + me.id;
        }
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
