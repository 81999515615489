import CaduceoLapManagement from "../";

const routes = [
    { name: "Registro Invii", path: "/caduceolapmanagement/registroinvii", component: CaduceoLapManagement.CaduceoLapManagementRegistroInvii, meta: { title: "" } },
    { name: "Lap Medici", path: "/caduceolapmanagement/lapmedici", component: CaduceoLapManagement.CaduceoLapManagementLapMediciList, meta: { title: "" } },
    { name: "Lap Medici View", path: "/caduceolapmanagement/lapmedici/view/:id", component: CaduceoLapManagement.CaduceoLapManagementLapMediciView, meta: { title: "" } },
    { name: "Indicatori", path: "/caduceolapmanagement/lapmedici/indicatore/:indicatore", component: CaduceoLapManagement.CaduceoLapManagementIndicatoriView, meta: { title: "" } },
    { name: "Scheda Medico", path: "/caduceolapmanagement/medici", component: CaduceoLapManagement.CaduceoLapManagementMediciList, meta: { title: "" } },
    { name: "Scheda Medico", path: "/caduceolapmanagement/medici/edit/:id", component: CaduceoLapManagement.CaduceoLapManagementMedicoEdit, meta: { title: "" } },
    { name: "Scheda Medico", path: "/caduceolapmanagement/medici/view/:id", component: CaduceoLapManagement.CaduceoLapManagementMedicoView, meta: { title: "" } },
];

export default routes;
