<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Codice Fiscale Medico</label>
                        <b-form-input v-model="filtro.codiceFiscaleMedico" type="search" placeholder="Codice Fiscale Medico"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Cognome</label>
                        <b-form-input v-model="filtro.cognome" type="search" placeholder="Cognome"></b-form-input>
                    </b-col>
                    <b-col cols="8" xs="8" sm="8" md="8" lg="4" xl="4">
                        <label class="sa-label-data">Nome</label>
                        <b-form-input v-model="filtro.nome" type="search" placeholder="Nome"></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="sa-form-btn-filter">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="end" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template v-slot:cell(codiceFiscaleMedico)="{ item }">
                        <router-link class="sa-edit-link" :to="'/caduceolapmanagement/medici/view/' + item.codiceFiscaleMedico">{{ item.codiceFiscaleMedico }}</router-link>
                    </template>
                    <template v-slot:cell(nominativo)="{ item }">
                        <span>{{ item.cognome }} {{ item.nome }}</span>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import moment from "moment";
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    mixins: [UtilityMixin],
    data() {
        return {
            showModalLoading: false,
            pathResource: "/medici",
            linkedit: "",
            currentPage: 1,
            perPage: 50,
            rows: null,
            filtro: { codiceFiscaleMedico: "", cognome: "", nome: "" },
            items: [],
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Codice Fiscale Medico",
                    key: "codiceFiscaleMedico",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Cognome e Nome",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Distretto",
                    key: "distretto",
                    sortable: true,
                },
                {
                    label: "Data Inizio Rapporto",
                    key: "dataInizioRapporto",
                    sortable: true,
                    thStyle: "width: 12rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        } else {
                            return "-------";
                        }
                    },
                },
                {
                    label: "Distretto",
                    key: "Data Fine Rapporto",
                    sortable: true,
                    thStyle: "width: 12rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        } else {
                            return "-------";
                        }
                    },
                },
            ],
        };
    },
    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
        }
        me.linkedit = "/caduceolapmanagement/medici";
        me.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            console.log(me.filtro);
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>

<style></style>
