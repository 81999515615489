<template>
  <div>
    <b-row>
      <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblDrug") }}</label>
        <span class="sa-data">{{ jsonData.farmaco }}</span>
      </b-col>
      <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblQuantity") }}</label>
        <template>
          <span class="sa-data">{{ jsonData.quantita }}</span>
        </template>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblFrequency") }} :</label>

        <div>
          <b-card class="sc-card-max-height" style="height: 100%; width: 100%" no-body>
            <div class="sa-calendar-day-grid-slot">
              <table class="sa-calendar-day-grid-table-slot">
                <tr v-for="(item1, i) in griglia" :key="i">
                  <td v-for="(item2, j) in item1" :key="j">
                    <div class="text-center">{{ item1 * i + j }} - {{ item1 * i + (j + 1) }}</div>
                    <!-- {{ item1 * i + j }} -->
                    <div v-if="isSelectOrarioSomministrazione(item1 * i + j)" class="sa-div-cell"><b-icon icon="check"></b-icon></div>
                    <div v-else class="sa-div-cell"></div>
                  </td>
                </tr>
              </table>
            </div>
          </b-card>
        </div>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblPosology") }}</label>
        <span class="sa-data">{{ jsonData.posologia }}</span>
        <b-row>
          <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">{{ this.$i18n.t("therapy.lblStartDate") }}</label>
            <span class="sa-data">{{ formatDate(jsonData.dataInizio) }}</span>
          </b-col>
          <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">{{ this.$i18n.t("therapy.lblEndDate") }}</label>
            <span class="sa-data">{{ formatDate(jsonData.dataFine) }}</span>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <label class="sa-label-data">{{ this.$i18n.t("therapy.lblStatus") }} :</label>
            <span class="sa-data">{{ this.$i18n.t(jsonData.statoDescrizione) }}</span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">{{ this.$i18n.t("therapy.lblNote") }}</label>
        <span class="sa-data">{{ jsonData.note }}</span>
      </b-col>
    </b-row>
    <!-- <hr />
    <b-row>
      <b-col class="sa-padding-right text-right">
        <b-button size="sm" variant="default" @click="onChiudi">Chiudi</b-button>
      </b-col>
    </b-row> -->
  </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin.js";
export default {
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/pazienteterapie",
      griglia: [6, 6, 6, 6],
    };
  },
  props: {
    jsonData: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  methods: {
    isSelectOrarioSomministrazione(value) {
      let me = this;
      return me.jsonData.orarioSomministrazione.includes(value.toString());
    },
  },
};
</script>

<style scoped>
.sa-div-cell {
  min-height: 38px;
  margin-left: auto;
  display: block;
  margin-right: auto;
  width: 100%;
  text-align: center;
  font-size: 28px;
  color: darkgreen;
}
</style>
