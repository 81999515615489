<template>
    <div class="sa-form-section">
        <div>
            <h3>{{ getLabelTraduora("patient.afa.lblEligibility") }}</h3>
        </div>
        <b-tabs v-model="tabIndex" content-class="mt-3" nav-wrapper-class="sticky-top" class="sa-tab" fill>
            <b-tab>
                <template #title>
                    <div>
                        <div style="float: left">
                            <span>{{ getLabelTraduora("patient.afa.lblSportsMedicalEvaluationForm") }}</span>
                        </div>
                    </div>
                </template>
                <div class="sa-tab-scroll">
                    <idoneita-scheda-valutazione-medico-sportiva-view-component :jsonData="jsonData"></idoneita-scheda-valutazione-medico-sportiva-view-component>
                </div>
            </b-tab>
            <b-tab>
                <template #title>
                    <div>
                        <div style="float: left">
                            <span>{{ getLabelTraduora("patient.afa.lblAnamnesis") }}</span>
                        </div>
                    </div>
                </template>
                <div class="sa-tab-scroll">
                    <idoneita-anamnesi-view-component :jsonData="jsonData"></idoneita-anamnesi-view-component>
                </div>
            </b-tab>
            <b-tab>
                <template #title>
                    <div :class="getColorByFirma(jsonData.firmaEo)">
                        <div style="float: left">
                            <b-icon :icon="getIconByFirma(jsonData.firmaEo)"></b-icon>
                        </div>
                        <div style="float: left"><span>Es. Obiettivo</span></div>
                    </div>
                </template>
                <div class="sa-tab-scroll">
                    <idoneita-esame-obiettivo-view-component :jsonData="jsonData"></idoneita-esame-obiettivo-view-component>
                </div>
            </b-tab>
            <b-tab>
                <template #title>
                    <div :class="getColorByFirma(jsonData.firmaEcg)">
                        <div style="float: left">
                            <b-icon :icon="getIconByFirma(jsonData.firmaEcg)"></b-icon>
                        </div>
                        <div style="float: left">
                            <span>{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}</span>
                        </div>
                    </div>
                </template>
                <div class="sa-tab-scroll">
                    <idoneita-elettrocardiogramma-view-component :jsonData="jsonData"></idoneita-elettrocardiogramma-view-component>
                </div>
            </b-tab>
            <b-tab :title="getLabelTraduora('patient.afa.lblUrines')">
                <div class="sa-tab-scroll">
                    <idoneita-urine-view-component :jsonData="jsonData"></idoneita-urine-view-component>
                </div>
            </b-tab>
            <b-tab :title="getLabelTraduora('patient.afa.lblSpirography')" :edit="edit">
                <div class="sa-tab-scroll">
                    <idoneita-spirografia-view-component :jsonData="jsonData"></idoneita-spirografia-view-component>
                </div>
            </b-tab>
            <b-tab :title="getLabelTraduora('patient.afa.lblOtherExams')">
                <div class="sa-tab-scroll">
                    <idoneita-altri-esami ref="altriEsami" @onRefresh="onRefresh" :data="jsonData" :edit="edit"></idoneita-altri-esami>
                </div>
            </b-tab>
            <b-tab>
                <template #title>
                    <span :class="getColorGiudizioFinale()"><b-icon :icon="getIconGiudizioFinale()"></b-icon>&nbsp;{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}</span>
                </template>
                <div class="sa-tab-scroll">
                    <idoneita-gudizio-finale-view-component :jsonData="jsonData"></idoneita-gudizio-finale-view-component>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import IdoneitaAltriEsami from "./IdoneitaAltriEsami.vue";
import IdoneitaSchedaValutazioneMedicoSportivaViewComponent from "./IdoneitaSchedaValutazioneMedicoSportivaViewComponent.vue";
import IdoneitaAnamnesiViewComponent from "./IdoneitaAnamnesiViewComponent.vue";
import IdoneitaEsameObiettivoViewComponent from "./IdoneitaEsameObiettivoViewComponent.vue";
import IdoneitaElettrocardiogrammaViewComponent from "./IdoneitaElettrocardiogrammaViewComponent.vue";
import IdoneitaUrineViewComponent from "./IdoneitaUrineViewComponent.vue";
import IdoneitaSpirografiaViewComponent from "./IdoneitaSpirografiaViewComponent.vue";
import IdoneitaGudizioFinaleViewComponent from "./IdoneitaGiudizioFinaleViewComponent.vue";

export default {
    mixins: [UtilityMixin],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {
        IdoneitaAltriEsami,
        IdoneitaSchedaValutazioneMedicoSportivaViewComponent,
        IdoneitaAnamnesiViewComponent,
        IdoneitaEsameObiettivoViewComponent,
        IdoneitaElettrocardiogrammaViewComponent,
        IdoneitaUrineViewComponent,
        IdoneitaSpirografiaViewComponent,
        IdoneitaGudizioFinaleViewComponent,
    },
    data() {
        return {
            id: null,
            jsonData: { idPaziente: null, dataCaricamento: new Date().getTime() },
            pathRest: "/medicinadellosportidoneita",
            linkEdit: null,
            tabIndex: null,
            linkback: null,
            edit: false,
            showModalLoading: false,
        };
    },
    computed: {
        // idPaziente: {
        //     get: function () {
        //         return this.jsonData.idPaziente;
        //     },
        //     set: function (newValue) {
        //         this.jsonData.idPaziente = newValue;
        //         this.linkEdit = "/paziente/medicinadellosportidoneita/edit/" + this.id + "?idPaziente=" + newValue;
        //     },
        // },
    },
    mounted() {
        let me = this;
        console.log(me.idEvento);
        me.id = this.$route.params.id;
        me.linkback = "/paziente/medicinadellosportidoneita?idPaziente=" + me.idPaziente;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            axios
                .get(link + me.idEvento)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.idPaziente = me.jsonData.idPaziente;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        getColorByFirma(firma) {
            // let me = this;
            let colore = firma ? "sa-tab-title-success text-success" : "sa-tab-title-danger text-danger";

            return colore;
        },
        getIconByFirma(firma) {
            let icon = firma ? "check2" : "exclamation-triangle";
            return icon;
        },
        onRefresh() {
            let me = this;
            me.$refs["altriEsami"].$refs["allegatiListC"].loadData();
        },
        getColorEsameObiettivo() {
            let me = this;
            let colore = me.jsonData.firmaEo != null ? "text-success" : "text-danger";
            return colore;
        },
        getColorElettrocardiogramma() {
            let me = this;
            let colore = me.jsonData.firmaEcg != null ? "text-success" : "text-danger";
            return colore;
        },
        getColorGiudizioFinale() {
            let me = this;
            let colore = me.jsonData.firmaGf != null ? "text-success" : "text-danger";
            return colore;
        },

        getIconEsameObiettivo() {
            let me = this;
            let icon = me.jsonData.firmaEo != null ? "check2" : "exclamation-triangle";
            return icon;
        },
        getIconElettrocardiogramma() {
            let me = this;
            let icon = me.jsonData.firmaEcg != null ? "check2" : "exclamation-triangle";
            return icon;
        },
        getIconGiudizioFinale() {
            let me = this;
            let icon = me.jsonData.firmaGf != null ? "check2" : "exclamation-triangle";
            return icon;
        },
    },
};
</script>
