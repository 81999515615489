<template>
    <div>
        <b-card class="sa-card" header="Dati Struttura" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Struttura</label>
                    <span class="sa-data">{{ jsonData.accettazioneStruttura.denominazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Data Prenotazione</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataPrenotazione) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Numero Cartella</label>
                    <span class="sa-data">{{ jsonData.codiceCartella }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Numero Certificato</label>
                    <span class="sa-data">{{ jsonData.numeroCertificato }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="ANAGRAFICA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="3" xl="3">
                    <label class="sa-label-data">Cognome</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.cognome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">Nome</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.nome }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">Data di Nascita</label>
                    <span class="sa-data">{{ formatDate(jsonData.accettazioneAnagrafica.dataNascita) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">Eta'</label>
                    <span class="sa-data">{{ calcoloEta(jsonData.accettazioneAnagrafica.dataNascita) }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="2" xl="2">
                    <label class="sa-label-data">Sesso</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.sesso }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Tipo Identificativo</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.tipoIdentificativo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Codice Fiscale</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.identificativo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Provincia</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.provinciaNascita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Comune</label>
                    <span class="sa-data">{{ jsonData.comuneNascita }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Numero Tessera Sanitaria</label>
                    <span class="sa-data">{{ jsonData.numeroTesseraSanitaria }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Documento D'Identità</label>
                    <span class="sa-data">{{ jsonData.documentoIdentita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Numero Documento</label>
                    <span class="sa-data">{{ jsonData.numeroDocumento }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Gruppo Sanguigno</label>
                    <span class="sa-data">{{ jsonData.gruppoSanguigno }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Fattore RH</label>
                    <span class="sa-data">{{ jsonData.fattoreRh }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Tipo Atleta</label>
                    <span class="sa-data">{{ jsonData.tipoAtleta }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Sport</label>
                    <span class="sa-data">{{ jsonData.sport }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Tipo Visita</label>
                    <span class="sa-data">{{ jsonData.tipoVisita }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Validità Certificato</label>
                    <span class="sa-data">{{ jsonData.validitaCertificato }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Federazione/Ente</label>
                    <span class="sa-data">{{ jsonData.federazioneEnte }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">Società Sportiva</label>
                    <span class="sa-data">{{ jsonData.societaSportiva }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="RESIDENZA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Indirizzo Residenza</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.indirizzoResidenza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Provincia Residenza</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.provinciaResidenza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Comune Residenza</label>
                    <span class="sa-data">{{ jsonData.comuneResidenza }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">Cap Residenza</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.capResidenza }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="CONTATTI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Telefono</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.telefono }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Email</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.email }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="MEDICO ESECUTORE" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Medico Esecutore</label>
                    <span class="sa-data">{{ jsonData.nomeMedicoEsecutore }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="ALTRI DATI" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Da riepilogare</label>
                    <b-form-checkbox disabled v-model="jsonData.daRiepilogare" switch></b-form-checkbox>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Regime Erogazione</label>
                    <span class="sa-data">{{ jsonData.regimeErogazione }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Posizione Ticket</label>
                    <span class="sa-data">{{ jsonData.posizioneTicket }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Tariffa Base</label>
                    <span class="sa-data">{{ jsonData.tariffaBase }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Asl Riepilogo</label>
                    <span class="sa-data">{{ jsonData.aslRiepilogo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Asl Paziente</label>
                    <span class="sa-data">{{ jsonData.aslPaziente }}</span>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="ALTRI SPORT" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col>
                    <b-table sticky-header responsive stacked="lg" striped hover :items="jsonData.altriSport" :fields="fieldsAltriSport" sort-icon-left head-variant="light" class="sa-b-table"></b-table>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" header="NOTE" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Note</label>
                    <span class="sa-data">{{ jsonData.accettazioneAnagrafica.note }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    props: {
        jsonData: {
            Type: Object,
            default: function () {
                return {
                    accettazioneStruttura: { codiceStruttura: null },
                    accettazioneAnagrafica: {
                        codiceFiscale: "",
                        provinciaNascita: null,
                        comuneNascitaCodiceIstat: null,
                        medicoCurante: "",
                        codiceFiscaleMedicoCurante: "",
                        provinciaResidenza: null,
                        comuneResidenzaCodiceIstat: null,
                        provinciaDomicilio: null,
                        comuneDomicilioCodiceIstat: null,
                        capDomicilio: null,
                        capResidenza: null,
                        dataNascita: null,
                        sesso: null,
                        tipoIdentificativo: null,
                        nome: "",
                        cognome: "",
                    },
                    idPrenotazione: "",
                    tipo: "",
                    altriSport: [],
                };
            },
        },
    },
    mixins: [UtilityMixin],
    data() {
        return {
            fieldsAltriSport: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 2rem",
                },
                {
                    label: "Sport",
                    key: "sport",
                },
                {
                    label: "Tipo Visita",
                    key: "tipoVisita",
                    thStyle: "width: 5rem",
                },
                {
                    label: "Numero Certificato",
                    key: "numeroCertificato",
                    thStyle: "width: 6rem",
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
            ],
        };
    },
    methods: {
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
    },
};
</script>
