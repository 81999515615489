<template>
    <sa-page-layout :showModalLoading="showModalLoading" :btnNewVisible="true" @refresh="onRefresh" :linkedit="linkedit" :btnRefreshVisible="true" class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <!-- <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template> -->
        <!-- <template slot="toolbar">
      <b-button class="float-sm-right " variant="success btn-toolbar sa-margin-right" size="sm" @click="onAdd"><b-icon icon="plus" variant="secondary"></b-icon>{{ this.$i18n.t("global.lblBtnNew") }}</b-button>
      <b-button class="float-sm-right " variant="info btn-toolbar" size="sm" @click="onRefresh" v-b-modal.modal-center><b-icon icon="arrow-clockwise" variant="secondary" />Refresh</b-button>
    </template> -->

        <template slot="table-header"> </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill v-model="tabIndex">
                    <b-tab active>
                        <template #title>
                            <div class="sa-tab-title-normal">
                                <div style="float: left">
                                    <span>{{ getTraduzione("patient.riskAnalysis.lblLastMeasurement") }}</span>
                                </div>
                            </div>
                        </template>
                        <div class="sa-tab-scroll">
                            <analisi-rischi-view-component :id="id"></analisi-rischi-view-component>
                        </div>
                    </b-tab>
                    <b-tab>
                        <template #title>
                            <div class="sa-tab-title-normal">
                                <div style="float: left">
                                    <span>{{ getTraduzione("patient.riskAnalysis.lblMeasurementsHistory") }}</span>
                                </div>
                            </div>
                        </template>
                        <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem; margin-top: 0.5rem">
                            <b-col lg="3">
                                <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                            </b-col>
                            <b-col lg="9">
                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                            </b-col>
                        </b-row>
                        <div>
                            <div class="b-table-sticky-header" style="padding-top: 10px">
                                <div class="sa-tab-scroll">
                                    <b-table sticky-header ref="table" :filter="filter" responsive stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                        <template v-slot:cell(createDate)="{ item }">
                                            <router-link class="sa-edit-link" :to="'/paziente/analisirischi/view/' + item.id + '?idPaziente=' + idPaziente">{{ new Date(item.createDate).toLocaleString() }}</router-link>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
            </div>
        </template>
        <template slot="table-footer" v-if="tabIndex === 1">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../utility/UtilityMixin.js";
// import PazienteDashboardComponent from "../PazienteDashboardComponent.vue";
import AnalisiRischiViewComponent from "./AnalisiRischiViewComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SaPageLayout, PazienteAnagrafica, AnalisiRischiViewComponent }, //PazienteDashboardComponent
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        rows() {
            if (this.items) {
                return this.items.length;
            } else {
                return 0;
            }
        },
        btnNewVisible: {
            get: function () {
                let me = this;
                return UtilityMixin.methods.verificaPermessi(me.utente, "analisirischi", 2);
            },
        },
        linkPatient: {
            get: function () {
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                                return "/paziente/view/" + this.idPaziente;
                            } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "view/" + this.idPaziente;
                            } else {
                                return "homepaziente";
                            }
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            },
        },
    },
    data() {
        return {
            pathResource: "/pazienteanalisirischi",
            pagelink: "/paziente/analisirischi",
            linkedit: "/paziente/analisirischi",
            linktipodieta: "",
            perPage: 10,
            currentPage: 1,
            tabIndex: 0,
            note: "",
            filter: null,
            showModalLoading: false,
            id: "-1",
            filtro: {},
            filterOn: [],
            fields: [
                {
                    label: this.$i18n.t("billing.lblDate"),
                    key: "createDate",
                    formatter: (value) => {
                        if (!value) {
                            return "";
                        } else {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        }
                    },
                    thStyle: "width: 3rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("riskAnalysis.lblResultMessage"),
                    key: "resultmessage",
                    sortable: true,
                    thStyle: "width: 3rem",
                },
                {
                    label: this.$i18n.t("riskAnalysis.lblResultCode"),
                    key: "resultcode",
                    sortable: true,
                    thStyle: "width: 3rem",
                },
                {
                    label: this.$i18n.t("riskAnalysis.lblRiskResult"),
                    key: "risultatoRischio",
                    thStyle: "width:3rem",
                },
            ],
            items: [],
            data: {},
        };
    },
    created: function () {},
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.data.idPaziente = this.$route.query.idPaziente;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idAnagrafica=" + me.data.idPaziente;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    // console.log(response.data.data);
                    if (me.items[0]) {
                        me.id = me.items[0].id;
                    }
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        getTraduzione(label) {
            return this.$i18n.t(label);
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro.codiceSacca);
            me.loadData();
        },
        // onAdd() {
        //   let me = this;
        //   me.$router.replace(me.pagelink + "/edit/-1?idPaziente=" + me.idPaziente).catch((err) => {
        //     err;
        //   });
        // },
        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
<style scoped>
.col-lg-12 {
    height: 35px;
}
</style>
