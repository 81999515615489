<template>
    <gestione-attivita-attivita-list-component :utente="utente" :fonte="'/all'"></gestione-attivita-attivita-list-component>
</template>

<script>
import GestioneAttivitaAttivitaListComponent from "../components/GestioneAttivitaAttivitaListComponent.vue";
export default {
    props: {
        utente: { type: Object },
    },
    components: { GestioneAttivitaAttivitaListComponent },
    data() {
        return {};
    },
};
</script>
