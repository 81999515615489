<template>
    <div class="sa-tab-scroll">
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"> </date-picker>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.EcoReporting.lblHeight") }}(cm)</label>
                <b-form-input v-model="jsonData.altezza" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.EcoReporting.lblWeight") }}(Kg)</label>
                <b-form-input v-model="jsonData.peso" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)" type="number"> </b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBSA") }}(m2)</label>
                <b-form-input v-model="jsonData.bsa" readonly></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}(Kg/m2)</label>
                <b-form-input v-model="jsonData.bmi" readonly></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAS") }} (mmHg)</label>
                <b-form-input v-model="jsonData.pas" type="number"> </b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAD") }} (mmHg)</label>
                <b-form-input v-model="jsonData.pad" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFC") }} (opm)</label>
                <b-form-input v-model="jsonData.fc" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.EcoReporting.lblAcousticImpedance") }}</label>
                <b-form-select v-model="jsonData.impedenzaAcustica" :options="optionsImpedenzaAcustica">
                    <template #first>
                        <b-form-select-option :value="null" disabled>{{ firstElementSelect }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVideotape") }}</label>
                <b-form-input v-model="jsonData.videotape"></b-form-input>
            </b-col>

            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblLeftVentricle") }}</h5>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVStd") }} (mm)</label>
                <b-form-input v-model="jsonData.vstd" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPPtd") }} (mm)</label>
                <b-form-input v-model="jsonData.pptd" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVMha") }} (g/m2,7) </label>
                <b-form-input v-model="jsonData.lvmha" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVSts") }} (mm)</label>
                <b-form-input v-model="jsonData.vsts" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbleft") }}[%]</label>
                <b-form-input v-model="jsonData.eft" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRWT") }}</label>
                <b-form-input v-model="jsonData.rwt" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSIVtd") }} (mm)</label>
                <b-form-input v-model="jsonData.sivtd" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblsft") }} [%]</label>
                <b-form-input v-model="jsonData.sft" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblESS") }}(cm/d)</label>
                <b-form-input v-model="jsonData.ess" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVMid") }}(P)[g/m2]</label>
                <b-form-input v-model="jsonData.lvmidp" type="number"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVV") }}</label>
                <b-form-input v-model="jsonData.lvv" type="number"></b-form-input>
            </b-col>

            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblSegments") }}</h5>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
                <h5>{{ getLabelTraduora("patient.cardiology.lblBasal") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
                <h5>{{ getLabelTraduora("patient.cardiology.lblMedium") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
                <h5>{{ getLabelTraduora("patient.cardiology.lblApex") }}</h5>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="3" md="3" lg="3" xl="3">
                <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.settoanteriore" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.settoanterioreMedio" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.apiceSetto" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblAnteriorApex") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.pareteanteriore" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.pareteanterioreMedia" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.apiceAnt" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblLateralAnteriorWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.pareteLatAnteriore" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.pareteLatAnterioreMedia" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioFrontSideWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.pareteLatPosteriore" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.pareteLatPosterioreMedia" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.apicePost" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblEcocardioRearWall") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.pareteInferiore" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.pareteInferioreMedia" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.apiceInf" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblPosteriorSeptum") }}</label>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.settoposteriore" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3">
                <b-form-select v-model="jsonData.settoposterioreMedio" :options="segmentiOptions" value-field="descrizione" text-field="descrizione">
                    <template #first>
                        <b-form-select-option disabled :value="null">{{ getLabelTraduora("global.lblSelectValue") }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col class="ft-color-section" xs="12" sm="12" md="3" lg="3" xl="3"> </b-col>

            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTotal") }}</label>
                <b-form-input v-model="jsonData.totale" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIMA") }}</label>
                <b-form-input v-model="jsonData.estensioneDanno" type="number"> </b-form-input>
            </b-col>

            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                <b-form-textarea v-model="jsonData.notevs" rows="4" max-rows="10"></b-form-textarea>
            </b-col>

            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblRightVentricle") }}</h5>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVDtd") }}</label>
                <b-form-input v-model="jsonData.vdtd" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblSystolicPressure") }}</label>
                <b-form-input v-model="jsonData.pressSist" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTAPSE") }}</label>
                <b-form-input v-model="jsonData.tapse" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                <b-form-textarea v-model="jsonData.notevd" rows="4" max-rows="10"></b-form-textarea>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblRightAtrium") }}</h5>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVCIi") }}</label>
                <b-form-input v-model="jsonData.vcii" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVCIe") }}</label>
                <b-form-input v-model="jsonData.vcie" type="number"></b-form-input>
            </b-col>
            <b-col></b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDesription") }}</label>
                <b-form-textarea v-model="jsonData.descrAtriodestro" rows="4" max-rows="10"></b-form-textarea>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblLeftAtrium") }}</h5>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftAtrium") }}</label>
                <b-form-input v-model="jsonData.atrioSx" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLavi") }}</label>
                <b-form-input v-model="jsonData.lavi" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDesription") }}</label>
                <b-form-textarea v-model="jsonData.descrAtriosinistro" rows="4" max-rows="10"></b-form-textarea>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblAorta") }}</h5>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAorta") }}</label>
                <b-form-input v-model="jsonData.aorta" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLVOT") }}</label>
                <b-form-input v-model="jsonData.lvot" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEscVal") }}</label>
                <b-form-input v-model="jsonData.escval" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2D") }}</label>
                <b-form-input v-model="jsonData.duedAorta"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIA") }}</label>
                <b-form-select v-model="jsonData.ia" :options="optionsIaImItIp">
                    <template #first>
                        <b-form-select-option :value="null" disabled>{{ firstElementSelect }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDoppler") }}</label>
                <b-form-textarea v-model="jsonData.doppleraorta" rows="4" max-rows="10"></b-form-textarea>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAortaArea") }}</label>
                <b-form-input v-model="jsonData.area" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMax") }}</label>
                <b-form-input v-model="jsonData.pgmaxAorta" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMean") }}</label>
                <b-form-input v-model="jsonData.pgmeanAorta" type="number"></b-form-input>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblMitral") }}</h5>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2D") }}</label>
                <b-form-input v-model="jsonData.mitrale2d"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIM") }}</label>
                <b-form-select v-model="jsonData.im" :options="optionsIaImItIp">
                    <template #first>
                        <b-form-select-option :value="null" disabled>{{ firstElementSelect }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEE") }}</label>
                <b-form-input v-model="jsonData.ee"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDoppler") }}</label>
                <b-form-textarea v-model="jsonData.dopplemitrale" rows="4" max-rows="10"></b-form-textarea>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVE") }}</label>
                <b-form-input v-model="jsonData.pfve" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPFVA") }}</label>
                <b-form-input v-model="jsonData.pfva" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDT") }}</label>
                <b-form-input v-model="jsonData.dt" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIRT") }}</label>
                <b-form-input v-model="jsonData.irt" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMean") }}</label>
                <b-form-input v-model="jsonData.pgmeanMitrale" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMVA") }}</label>
                <b-form-input v-model="jsonData.mva" type="number"></b-form-input>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblPulmonary") }}</h5>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2D") }}</label>
                <b-form-input v-model="jsonData.polmonare2d"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIM") }}</label>
                <b-form-select v-model="jsonData.ip" :options="optionsIaImItIp">
                    <template #first>
                        <b-form-select-option :value="null" disabled>{{ firstElementSelect }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDoppler") }}</label>
                <b-form-textarea v-model="jsonData.dopplerpolmo" rows="4" max-rows="10"></b-form-textarea>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMax") }}</label>
                <b-form-input v-model="jsonData.vmaxPolmonare" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPGMax") }}</label>
                <b-form-input v-model="jsonData.pgmaxPolmonare" type="number"></b-form-input>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblPulmonaryVeinsDoppler") }}</h5>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblS") }}</label>
                <b-form-input v-model="jsonData.s" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblD") }}</label>
                <b-form-input v-model="jsonData.d" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPV") }}</label>
                <b-form-input v-model="jsonData.apv" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAPD") }}</label>
                <b-form-input v-model="jsonData.apd" type="number"></b-form-input>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblTricuspid") }}</h5>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbl2D") }}</label>
                <b-form-input v-model="jsonData.tricuspide2d"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIT") }}</label>
                <b-form-select v-model="jsonData.it" :options="optionsIaImItIp">
                    <template #first>
                        <b-form-select-option :value="null" disabled>{{ firstElementSelect }}</b-form-select-option>
                    </template>
                </b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDoppler") }}</label>
                <b-form-textarea v-model="jsonData.dopplertricuspide" rows="4" max-rows="10"></b-form-textarea>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMax") }}</label>
                <b-form-input v-model="jsonData.vmaxTricuspide" type="number"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVMaxIT") }}</label>
                <b-form-input v-model="jsonData.vmaxitTricuspide" type="number"></b-form-input>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <hr />
                <h5>{{ getLabelTraduora("patient.cardiology.lblPericardium") }}</h5>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.EcoReporting.lblPericardiumValue") }} </label>
                <b-form-input v-model="jsonData.pericardio"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12"
                ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
                <b-form-textarea v-model="jsonData.conlusioni" rows="4" max-rows="10"></b-form-textarea>
            </b-col>
        </b-row>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    impedenzaAcustica: null,
                    /**/
                    vstd: null,
                    vsts: null,
                    sivtd: null,
                    pptd: null,
                    eft: null,
                    sft: null,
                    lvmha: null,
                    rwt: null,
                    ess: null,
                    lvmidp: null,
                    lvv: null,
                    /**/
                    settoanteriore: null,
                    settoanterioreMedio: null,
                    pareteanteriore: null,
                    pareteanterioreMedia: null,
                    apiceSetto: null,
                    apiceAnt: null,
                    apiceInf: null,
                    apicePost: null,
                    pareteLatAnteriore: null,
                    pareteLatAnterioreMedia: null,
                    pareteLatPosteriore: null,
                    pareteLatPosterioreMedia: null,
                    pareteInferiore: null,
                    pareteInferioreMedia: null,
                    settoposterioreMedio: null,
                    settoposteriore: null,
                    /**/
                    it: null,
                    ip: null,
                    im: null,
                    ia: null,
                };
            },
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            pathResource: "/cardioecocardirefertazioni",
            pathResourceSegmenti: "/cardiosegmenti",
            segmentiOptions: [],
            optionsImpedenzaAcustica: [
                { text: this.getLabelTraduora("patient.ecoreporting.slcValueImpendaAcusticaNormale"), value: this.getLabelTraduora("patient.ecoreporting.slcValueImpendaAcusticaNormale") },
                { text: this.getLabelTraduora("patient.ecoreporting.slcValueImpendaAcusticaAlta"), value: this.getLabelTraduora("patient.ecoreporting.slcValueImpendaAcusticaAlta") },
            ],
            optionsIaImItIp: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPlus4Four"), value: this.getLabelTraduora("patient.cardiology.Options.lblPlus4Four") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPlus4Three"), value: this.getLabelTraduora("patient.cardiology.Options.lblPlus4Three") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPlus4Two"), value: this.getLabelTraduora("patient.cardiology.Options.lblPlus4Two") },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPlus4One"), value: this.getLabelTraduora("patient.cardiology.Options.lblPlus4One") },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        me.loadDefaultData();
    },

    methods: {
        onCalcoloBmiBsa(altezza, peso) {
            let me = this;
            me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
            me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
        },
        loadDefaultData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSegmenti;
            axios
                .get(link)
                .then((response) => {
                    me.showModalLoading = false;
                    response.data.data.forEach((element) => {
                        let descrizione = this.getLabelTraduora(element.descrizione);
                        me.segmentiOptions.push({ id: element.id, descrizione: descrizione });
                    });
                })
                .catch((e) => {
                    me.showModalLoading = false;
                    console.log(e);
                });
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>

<style></style>
