import HassistoIntegrator from "../";

const routes = [
  { name: "HassitoKitList", path: "/hassistokit", component: HassistoIntegrator.HassistoKitList, meta: { title: "patient.menu.lblKits" } },
  { name: "HassistoKitEdit", path: "/hassistokit/edit/:id", component: HassistoIntegrator.HassistoKitEdit, meta: { title: "patient.lblKits" } },
  { name: "HassistoHubList", path: "/hassistohub", component: HassistoIntegrator.HassistoHubList, meta: { title: "patient.menu.lblHub" } },
  { name: "HassitoDeviceList", path: "/hassistodevice", component: HassistoIntegrator.HassistoDeviceList, meta: { title: "patient.menu.lblDevices" } },
  { name: "HassitoDeviceEdit", path: "/hassistodevice/edit/:id", component: HassistoIntegrator.HassistoDeviceEdit, meta: { title: "patient.lblDevice" } },
];

export default routes;
