<template>
    <eventi-list-component :utente="utente" :key="keyListaEventi" :titoloEvento="titoloEvento"
        :pathEventResource="pathEventResource" :linkEvent="linkEvent" :gruppo="gruppo" :tipoEvento="tipoEvento"></eventi-list-component>
</template>

<script>
import EventiListComponent from "../../../base/components/EventiListComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: {
        EventiListComponent,
    },

    watch: {
        utente: function () {
            this.keyListaEventi++;
        },
    },
    mounted() { },
    data() {
        return {
            titoloEvento: "Ecocarotidi",
            pathEventResource: "/cardioecocarotidi",
            keyListaEventi: 0,
            linkEvent: "/paziente/cardiologia/ecocarotidi",
            gruppo:"CARDIOLOGIA",
            tipoEvento:"ECOCAROTIDI"
        };
    },
};
</script>

<style>

</style>
