<template>
  <eventi-list-component :titoloEvento="titoloEvento" :utente="utente" :key="keyListaEventi" :pathEventResource="pathEventResource" :linkEvent="linkEvent" :gruppo="gruppo" :tipoEvento="tipoEvento"></eventi-list-component>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiListComponent from "../../../base/components/EventiListComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin, EventiMixin],
  components: { EventiListComponent },
  data() {
    return {
      titoloEvento: this.$i18n.t("patients.gastroenterology.gastroenterologicalVisit"),
      linkEvent: "/paziente/gastroenterologia/visitagastroenterologica",
      pathEventResource: "/gastrovisitagastroenterologica",
      keyListaEventi: 0,
      gruppo: "GASTROENTEROLOGIA",
      tipoEvento: "VISITA GASTROENTEROLOGICA",
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
  },
  watch: {
    utente: function () {
      this.keyListaEventi++;
    },
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    rows() {
      return this.items.length;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  methods: {},
};
</script>
