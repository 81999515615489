<template>
    <sa-page-layout :data="data" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente" @getEtaPaziente="getEtaPaziente" @getAnagraficaPaziente="getAnagraficaPaziente"></paziente-anagrafica>
        </template>
        <!-- <template slot="toolbar-row">
            <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
        </template> -->
        <!-- <template slot="table-header">
      <b-row>
        <b-col cols="2">
          {{ this.$i18n.t("riskAnalysis.lblResultMessage") }}: <span class="sa-data">{{ data.resultmessage }}</span
          ><span> </span>
        </b-col>
        <b-col
          >{{ this.$i18n.t("riskAnalysis.lblResultCode") }}: <span class="sa-data">{{ data.resultcode }}</span></b-col
        >
      </b-row>
    </template> -->
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <!-- <b-row> -->
                <!-- <b-col cols="9"> -->
                <b-row>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patients.lblBirthDate") }} : </label>
                        <span class="sa-data">{{ formatDate(data.birthdate) }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patients.lblPatientAge") }} : </label>
                        <span class="sa-data">{{ data.age }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patients.lblGender") }} : </label>
                        <span class="sa-data">{{ data.sex }}</span>
                    </b-col>
                </b-row>
                <!-- <div style="height: 20px"></div> -->
                <!-- <br/> -->
                <hr />
                <!-- <b-card bg-variant="light" style="width: 100%; height: auto"> -->
                <b-row>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("riskAnalysis.lblCigarette") }}</label>
                        <b-form-checkbox v-model="data.cigaretteSmoker"></b-form-checkbox>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblAlcohol") }}</label>
                        <b-form-checkbox v-model="data.alcoholConsuption"></b-form-checkbox>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblStrokeTransientIschemia") }}</label
                        ><b-form-checkbox v-model="data.ictusIschemiaTransitoria"></b-form-checkbox>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblHypertension") }}</label
                        ><b-form-checkbox v-model="data.ipertensione"></b-form-checkbox>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblChronicKidneyDisease") }}</label
                        ><b-form-checkbox v-model="data.malattiaRenaleCronica"></b-form-checkbox>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblHeartDiseaseChronic") }}</label
                        ><b-form-checkbox v-model="data.cardiopatiaCronica"></b-form-checkbox>
                    </b-col>
                    <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                        <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblDiabetes") }}</label>
                        <b-form-checkbox v-model="data.diabete"></b-form-checkbox>
                    </b-col>
                    <!-- <b-col>
                <span>{{ this.$i18n.t("riskAnalysis.lblChronicDiseases") }}</span> <b-form-input v-model="data.chronicaldisases" type="number" max="150" min="0"></b-form-input>
            </b-col> -->
                </b-row>
                <!-- </b-card> -->

                <hr />

                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                        ><label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblCholesterol") }}</label> <b-form-input v-model="data.hdlCholesterol" type="number" step="0.01"></b-form-input
                    ></b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4"
                        ><label class="sa-label-data">{{ this.$i18n.t("patients.lblTotalCholesterol") }}</label> <b-form-input v-model="data.totalCholesterol" type="number" step="0.01"></b-form-input
                    ></b-col>
                    <!-- <b-col cols="5">
                <span> {{ this.$i18n.t("patients.lblMeanSystolicPressure") }}</span
                ><b-form-input v-model="data.pressioneSistolicaMedia" type="number"></b-form-input>
              </b-col>
              <b-col cols="2"></b-col> -->
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data"> {{ this.$i18n.t("patients.lblBloodPressureTreatment") }}</label
                        ><b-form-input v-model="data.trattamentoPressioneSanguigna" type="number" step="0.01"></b-form-input>
                    </b-col>
                </b-row>
                <!-- <div style="height: 20px"></div> -->
                <!-- <hr /> -->
                <!-- <b-row style="height:30px;"
              ><b-col
                ><h4>{{ this.$i18n.t("riskAnalysis.lblGoals") }}</h4></b-col
              >
              <b-col
                ><h4>{{ this.$i18n.t("riskAnalysis.lblResults") }}</h4></b-col
              >
            </b-row>
            <b-row>
              <b-col>
                <div v-for="tott in patientGoals" :key="tott.id">
                  {{ tott.id }}: <span class="sa-data">{{ tott.value }}</span>
                </div>
              </b-col>
              <b-col>
                <div v-for="tott in resultCalculation" :key="tott.id">
                  {{ tott.id }}: <span class="sa-data">{{ tott.value }}</span>
                </div>
              </b-col>
            </b-row> -->
                <!-- </b-col> -->
                <!-- <b-col cols="3"
            ><h4>{{ this.$i18n.t("riskAnalysis.lblMeasurements") }}</h4>
            <b-row
              ><b-col
                ><div v-for="tott in misure" :key="tott.id_measure + tott.measurement_id">
                  {{ checkName(tott.measurement_name) }}: <span class="sa-data">{{ tott.measurement_value }}</span>
                </div></b-col
              ></b-row
            ></b-col
          > -->
                <!-- </b-row> -->
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
// import DatePicker from "vue2-datepicker";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
// import PazienteDashboardComponent from "../PazienteDashboardComponent.vue";
export default {
    components: { PazienteAnagrafica, SaPageLayout }, //PazienteDashboardComponent
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            id: -1,
            pathRest: "/pazienteanalisirischi",
            pathResource: "/pazienteanalisirischi",
            pathVisita: "/visitehh/lastvisitahh",
            linkback: "/analisirischi",
            linkedit: null,
            componentKey: 0,
            data: {
                sex: "",
                age: "",
                birthdate: "",
                language: this.language,
            },
            iterator: 0,
            patientGoals: [],
            resultCalculation: [],
            misure: [],
            showModalLoading: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        language() {
            return this.$route.query.language;
        },
        linkPatient: {
            get: function () {
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                                return "/paziente/view/" + this.idPaziente;
                            } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "view/" + this.idPaziente;
                            } else {
                                return "homepaziente";
                            }
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            },
        },
        // rows() {
        //     return this.somministrazioneItems.length;
        // },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/paziente/analisirischi/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/analisirischi?idPaziente=" + me.idPaziente;

        me.loadData();
        me.getFumatoreAlcool();
        // me.checkVisita();
        me.data.language = this.language;
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRest + "/";
            me.isBusy = true;
            if (me.id !== "-1" && me.id !== null && me.id !== undefined) {
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.data = [];
                        me.data = response.data.data;
                        console.log(me.data);
                        try {
                            let json = JSON.parse(me.data.patientGoalBpResult);
                            for (var prop in json) {
                                let obj = {
                                    id: prop,
                                    value: json[prop],
                                };
                                me.patientGoals.push(obj);
                            }
                            let json2 = JSON.parse(me.items.riskCalculationResult);
                            for (var prop2 in json2) {
                                let obj = {
                                    id: prop2,
                                    value: json2[prop2],
                                };
                                me.resultCalculation.push(obj);
                            }
                        } catch {
                            me.resultCalculation = [];
                            me.patientGoals = [];
                        }
                        try {
                            let json = JSON.parse(me.data.measurements);
                            me.misure = json;
                        } catch {
                            me.misure = [];
                        }
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
            if (!me.data.patientId) {
                me.data.patientId = me.idPaziente;
            }
        },
        getEtaPaziente(value) {
            let me = this;
            me.data.age = value;
        },
        getAnagraficaPaziente(value) {
            let me = this;
            me.data.birthdate = value.dataNascita;
            me.data.sex = value.sesso;
        },
        checkVisita() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathVisita + "?idPaziente=";
            me.isBusy = true;
            axios
                .get(link + me.idPaziente)
                .then((res) => {
                    let visita = res.data.data;
                    if (visita !== null && visita !== undefined) {
                        console.log(visita);
                        me.data.hdlCholesterol = visita.colesteroloHdl;
                        me.data.totalCholesterol = visita.colesteroloTotale;
                        //me.data.chronicaldisases = Number(visita.malattieCroniche);
                        // if (isNaN(me.data.chronicaldisases)) {
                        //   me.data.chronicaldisases = 0;
                        // }
                    } else {
                        console.log(res);
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        getFumatoreAlcool() {
            let me = this;
            let dataAl = moment(new Date()).valueOf();
            let dataDal = dataAl - 5184000000;
            let al = moment(dataAl).format("yyyy-MM-DD HH:mm:ss");
            let dal = moment(dataDal).format("yyyy-MM-DD HH:mm:ss");
            // console.log(al);
            // console.log(dal);
            let link = process.env.VUE_APP_PATH_API + "/hyperhealth?forPage=20&page=1&idPaziente=" + me.idPaziente + "&dal=" + dal + "&idMisura=habits&al=" + al + "&order=desc";
            if (me.id === "-1") {
                axios.get(link).then((res) => {
                    if (res.data.data.list[0]) {
                        let lista = res.data.data.list;
                        let sigarette = 0;
                        let alcool = 0;
                        lista.forEach((element) => {
                            if (element.measurename === "Sigarette") {
                                sigarette = sigarette + Number(element.value);
                            }
                            if (element.measurename === "Alcool") {
                                alcool = alcool + Number(element.value);
                            }
                        });
                        // console.log("sigarette: " + sigarette);
                        // console.log("alcool: " + alcool);
                        if (sigarette > 0) {
                            me.data.cigaretteSmoker = true;
                        }
                        if (alcool > 0) {
                            me.data.alcoholConsuption = true;
                        }
                    } else {
                        me.data.cigaretteSmoker = false;
                        me.data.alcoholConsuption = false;
                    }
                });
            }
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
        check(bool) {
            if (bool === true) {
                return "check";
            } else {
                return "times";
            }
        },
        checkName(name) {
            let label = "";
            switch (name) {
                case "hr_average":
                    label = this.$i18n.t("patient.card.lblHrAvarage");
                    break;
                case "totalcalories":
                    label = this.$i18n.t("patient.card.lblTotalCalories");
                    break;
                case "calories":
                    label = this.$i18n.t("patient.card.lblCalories");
                    break;
                case "active":
                    label = this.$i18n.t("patient.card.lblActive");
                    break;
                case "total_sleep_time":
                    label = this.$i18n.t("patient.card.lblTotalSleepTime");
                    break;
                case "sleep_efficiency":
                    label = this.$i18n.t("patient.card.lblSleepEfficiency");
                    break;
                case "hr_min":
                    label = this.$i18n.t("patient.card.lblHrMin");
                    break;
                case "hr_max":
                    label = this.$i18n.t("patient.card.lblHrMax");
                    break;
                case "elevation":
                    label = this.$i18n.t("patient.card.lblElevation");
                    break;
                case "out_of_bed_count":
                    label = this.$i18n.t("patient.card.lblOutOfBedTime");
                    break;
                case "Skin Temperature (celsius)":
                    label = this.$i18n.t("patient.card.lblSkinTemperature");
                    break;
                case "Bone Mass (kg)":
                    label = this.$i18n.t("patient.card.lblBoneMass");
                    break;
                case "Body Temperature (celsius)":
                    label = this.$i18n.t("patient.card.lblBodyTemperature");
                    break;
                case "Temperature (celsius)":
                    label = this.$i18n.t("patient.card.lblTemperature");
                    break;
                case "Pulse Wave Velocity (m/s)":
                    label = this.$i18n.t("patient.card.lblPulseWaveVelocity");
                    break;
                case "SP02 (%)":
                    label = this.$i18n.t("patient.card.lblSPO2");
                    break;
                case "Heart Pulse (bpm) - only for BPM devices":
                    label = this.$i18n.t("patient.card.lblHeartPulseBPM");
                    break;
                case "Systolic Blood Pressure (mmHg)":
                    label = this.$i18n.t("patient.card.lblSystolicBloodPressure");
                    break;
                case "Diastolic Blood Pressure (mmHg)":
                    label = this.$i18n.t("patient.card.lblDiastolicBloodPressure");
                    break;
                case "delete-unlink-update":
                    label = this.$i18n.t("patient.card.lblDeleted");
                    break;
                case "sleep_score":
                    label = this.$i18n.t("patient.card.lblSleepScore");
                    break;
                case "Weight (kg)":
                    label = this.$i18n.t("patient.card.lblWeight");
                    break;
                case "Fat Free Mass (kg)":
                    label = this.$i18n.t("patient.card.lblFatFreeMass");
                    break;
                case "Height (meter)":
                    label = this.$i18n.t("patient.card.lblHeight");
                    break;
                case "Fat Mass Weight (kg)":
                    label = this.$i18n.t("patient.card.lblFatMassWeight");
                    break;
                case "distance":
                    label = this.$i18n.t("patient.card.lblDistance");
                    break;
                case "Fat Ratio (%)":
                    label = this.$i18n.t("patient.card.lblFatRatio");
                    break;
                case "steps":
                    label = this.$i18n.t("patient.card.lblSteps");
                    break;
                case "Hydration (kg)":
                    label = this.$i18n.t("patient.card.lblHydration");
                    break;
                case "Muscle Mass (kg)":
                    label = this.$i18n.t("patient.card.lblMuscleMass");
                    break;
                default:
                    label = "unknown";
                    break;
            }
            return label;
        },
    },
};
</script>
