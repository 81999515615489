<template>
    <div class="sa-tab-scroll">
        <b-row>
            <b-col cols="12" md="12">
                <b-alert :show="farmacoAvverso" variant="danger">
                    <b>{{ traduciConValue("paziente.terapia.lblAdverseDrugAlert", nomeFarmacoAvverso) }}</b>
                </b-alert>
            </b-col>
            <b-col cols="12" md="12">
                <b-alert :show="atcAvverso" variant="warning">
                    <b>
                        {{ traduciConValue("paziente.terapia.lblAdverseATCAlert", codiceAtcAvverso) }}
                        <br />
                        {{ farmaciAtcAvversi }}
                    </b>
                </b-alert>
            </b-col>
            <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                <label>{{ this.$i18n.t("therapy.lblDrug") }}</label>
                <b-input-group class="mb-3">
                    <b-form-input maxlength="16" class="text-uppercase" v-model="jsonData.farmaco" :placeholder="this.$i18n.t('therapy.lblDrug')" disabled></b-form-input>
                    <b-button size="sm" variant="" v-b-modal.ricercaFarmaco>
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
                <span v-if="showCodici">
                    <b>ATC:</b>
                    {{ jsonData.atcGmp }}
                </span>
            </b-col>
            <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                <label>{{ this.$i18n.t("therapy.lblQuantity") }}</label>
                <template>
                    <b-input-group class="mb-3"><b-form-spinbutton id="sb-step" v-model="jsonData.quantita" min="0" max="1000000000" step="0.50"></b-form-spinbutton></b-input-group>
                </template>
                <span v-if="showCodici">
                    <b>{{ this.$i18n.t("therapy.lblProductCode") }}:</b>
                    {{ jsonData.codiceProdotto }}
                </span>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label>{{ this.$i18n.t("therapy.lblFrequency") }}</label>
                <div>
                    <b-card class="sc-card-max-height" style="height: 100%; width: 100%" no-body>
                        <div class="sa-calendar-day-grid-slot">
                            <table class="sa-calendar-day-grid-table-slot">
                                <tr v-for="(item1, i) in griglia" :key="i">
                                    <td v-for="(item2, j) in item1" :key="j">
                                        <div class="text-center">{{ item1 * i + j }} - {{ item1 * i + (j + 1) }}</div>
                                        <b-form-checkbox class="text-center" :value="item1 * i + j" v-model="frequenzeSelezionate" @change="onSelectedFrequenza"></b-form-checkbox>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </b-card>
                </div>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label>{{ this.$i18n.t("therapy.lblPosology") }}</label>
                <b-form-select id="inline-form-custom-select-pref" v-model="jsonData.idPosologia" class="mb-2 mr-sm-2 mb-sm-0" :value="null" :options="posologiaItems" value-field="value" text-field="text"></b-form-select>
                <b-row>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label>{{ this.$i18n.t("therapy.lblStartDate") }}</label>
                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataInizio" type="datetime"></date-picker>
                    </b-col>
                    <b-col cols="6" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label>{{ this.$i18n.t("therapy.lblEndDate") }}</label>
                        <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataFine" type="datetime"></date-picker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label>{{ this.$i18n.t("therapy.lblStatus") }} :</label>
                        <br />
                        <span class="sa-data">{{ this.$i18n.t(jsonData.statoDescrizione) }}</span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row>
            <b-col cols="12" md="12">
                <label>{{ this.$i18n.t("therapy.lblNote") }}</label>
                <b-form-textarea id="textarea" v-model="jsonData.note" no-resize></b-form-textarea>
            </b-col>
        </b-row>
        <b-modal id="terminaAdesso" scrollable @show="onShow" @ok="onOk"></b-modal>
        <b-modal id="ricercaFarmaco" scrollable :title="this.$i18n.t('therapy.lblDrugSearch')" @show="onShow" @ok="onOk">
            <b-form @submit.prevent="onClick">
                <b-input-group class="mb-3">
                    <b-form-input maxlength="16" class="text-uppercase" v-model="descrizioneFarmaco" :placeholder="this.$i18n.t('therapy.lblDrug')"></b-form-input>
                    <b-button size="sm" variant="" v-b-modal.ricercaFarmaco @click="onClick">
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
                <!-- Ricerca per ATC -->
                <b-input-group class="mb-3">
                    <b-form-input maxlength="16" class="text-uppercase" v-model="atcGmpFarmaco" placeholder="ATC"></b-form-input>
                    <b-button size="sm" variant="" v-b-modal.ricercaFarmaco @click="onClick">
                        <b-icon icon="search"></b-icon>
                    </b-button>
                </b-input-group>
            </b-form>
            <b-table id="tabellaFarmaci" :items="tabellaFarmaci" :fields="farmaciFields" selectable select-mode="single" @row-selected="onRowSelected"></b-table>
        </b-modal>
        <b-modal id="interazioneTrovata" title="ALERT" centered>
            <b-row class="text-center">
                <b-col>
                    <span>{{ this.$i18n.t("therapy.lblInteractionsTrue") }}</span>
                </b-col>
            </b-row>
        </b-modal>
        <b-modal id="termina-terapia" :title="getLabelTraduora('patient.event.lblConfirmDelete')" centered>
            <span>{{ getLabelTraduora("therapy.lblConfermaTerminaTerapia") }}</span>
            <br />
            <b-form-checkbox v-model="terapiaAvversa" :checked="terapiaAvversa">
                <span>{{ getLabelTraduora("therapy.lblDrugIsAdverse") }}</span>
            </b-form-checkbox>
            <template #modal-footer="{ cancel }">
                <b-button size="sm" variant="danger" @click="cancel()">
                    {{ getLabelTraduora("patient.lblNo") }}
                </b-button>
                <b-button size="sm" variant="secondary" @click="terminaAdesso()">
                    {{ getLabelTraduora("patient.lblYes") }}
                </b-button>
            </template>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin.js";
export default {
    mixins: [UtilityMixin],
    components: { DatePicker }, //PazienteDashboardComponent
    data() {
        return {
            griglia: [6, 6, 6, 6],
            tabellaFarmaci: [],
            rigaSelezionata: null,
            descrizioneFarmaco: "",
            atcGmpFarmaco: "",
            id: { type: String },
            pathRestFarmaci: "/farmaci",
            pathResource: "/pazienteterapie",
            linkback: "/terapia",
            linkedit: null,
            showModalLoading: false,
            showCodici: false,
            componentKey: 0,
            terapiaAvversa: false,
            farmacoAvverso: false,
            nomeFarmacoAvverso: "",
            atcAvverso: false,
            codiceAtcAvverso: "",
            farmaciAtcAvversi: "",
            farmaciFields: [
                {
                    label: this.$i18n.t("agendas.lblDescription"),
                    key: "descrizione",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
                {
                    label: "ATC",
                    key: "atcGmp",
                    sortable: true,
                    thStyle: "width: 7rem",
                },
            ],
            jsonData: { quantita: 0.5, farmaco: "", atcGmp: "", codiceProdotto: "", iterazioneFarmaco: "", dataInizio: new Date().getTime(), dataFine: new Date().getTime(), idStato: null, idPosologia: null },
            frequenzaItems: [],
            posologiaItems: [],
            frequenzeSelezionate: [],
            statoOption: [{ value: null, text: "--Seleziona Stato Terapia--" }],
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                console.log("ok");
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.jsonData.idPaziente = me.idPaziente;
        if (me.id === "-1") {
            me.linkback = "/paziente/terapia?idPaziente=" + me.idPaziente;
        } else {
            me.linkback = "/paziente/terapia/view/" + me.id + "?idPaziente=" + me.idPaziente;
        }
        this.loadPosologiaData();
        this.loadFrequenzaData();
        me.loadStatiSomministrazione();
        me.updateParent();
    },
    methods: {
        closeModal() {
            let me = this;
            me.showModalLoading = false;
        },
        terapieAvverse() {
            let me = this;
            me.farmacoAvverso = false;
            me.atcAvverso = false;

            let link = process.env.VUE_APP_PATH_API + "/pazienteterapie/controllostoricoavversita?idPaziente=" + me.idPaziente;
            axios.get(link).then((response) => {
                let avversi = response.data.data;
                me.codiceAtcAvverso = "";
                avversi.forEach((element) => {
                    if (element.farmaco === me.data.farmaco && element.atcGmp === me.data.atcGmp) {
                        me.nomeFarmacoAvverso = me.data.farmaco;
                        me.farmacoAvverso = true;
                    } else if (element.farmaco !== me.data.farmaco && element.atcGmp === me.data.atcGmp) {
                        me.codiceAtcAvverso = element.atcGmp;
                        me.farmaciAtcAvversi += element.farmaco += "\n";
                    }
                });
                if (me.farmacoAvverso === false && me.farmaciAtcAvversi !== "") {
                    me.atcAvverso = true;
                }
                // console.log("Avversità:");
                // console.log(avversi);
                // console.log("data:");
                // console.log(me.data);
            });
        },
        // notBefore: function(date) {
        //     // return date < new Date(2022, 4, 5); // || date > new Date(2019, 10, 6);
        //     return date < new Date(); // || date > new Date(2019, 10, 6);
        // },

        onTerminaTerapia() {
            let me = this;
            me.$bvModal.show("termina-terapia");
            // this.$bvModal
            //   .msgBoxConfirm(this.$i18n.t("therapy.lblConfermaTerminaTerapia") + "\n<b-form-checkbox v-model='terapiaAvversa' checked='terapiaAvversa'></b-form-checkbox>", {
            //     title: "Conferma Cancellazione",
            //     buttonSize: "sm",
            //     okVariant: "secondary",
            //     cancelVariant: "danger",
            //     okTitle: "SI",
            //     cancelTitle: "NO",
            //     footerClass: "p-2",
            //     hideHeaderClose: false,
            //     centered: true,
            //   })
            //   .then((value) => {
            //     if (value) {
            //       me.terminaAdesso();
            //     }
            //   })
            //   .catch((error) => {
            //     console.log(error);
            //   });
        },
        terminaAdesso() {
            // console.log("Prima");
            // console.log(this.data);
            this.data.dataFine = new Date().getTime();
            if (this.terapiaAvversa === true) {
                this.data.stato = "5";
                this.data.idStato = "5";
                this.data.stato = "paziente.terapia.lblAVVERSA";
            }
            // console.log("Dopo");
            // console.log(this.data);
            this.$bvModal.hide("termina-terapia");
            this.$refs.SaPageLayout.save();

            // SaPageLayout.methods.save();
        },
        onSelectedFrequenza(value) {
            let me = this;
            me.frequenzeSelezionate = value;
            me.jsonData.orarioSomministrazione = me.frequenzeSelezionate;
        },
        updateParent() {
            let me = this;
            document.addEventListener("interazioniTrue", function (e) {
                let check = e.detail;
                // console.log(check);
                if (check === true) {
                    me.$bvModal.show("interazioneTrovata");
                }
            });
            document.addEventListener("takeInterazioni", function (e) {
                // console.log(e); // Prints "Example of an event"
                let interactions = e.detail;
                me.jsonData.iterazioneFarmaco = JSON.stringify(interactions);
                me.showModalLoading = false;
            });
        },
        loadStatiSomministrazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazienteterapiestatidescrizione";
            me.showModalLoading = true;
            axios(link)
                .then((response) => {
                    let obj = {};
                    me.showModalLoading = false;
                    response.data.data.forEach((element) => {
                        obj = { text: this.$i18n.t(element.descrizione), value: element.id };
                        me.statoOption.push(obj);
                    });
                })
                .catch((e) => {
                    me.showModalLoading = false;
                    console.log(e);
                });
        },
        loadFrequenzaData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazienteterapiefrequenza";
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.frequenzaItems = [];
                    response.data.data.forEach((element) => {
                        let option = { text: this.$i18n.t(element.descrizione), value: element.id };
                        me.frequenzaItems.push(option);
                    });
                    if (me.id !== "-1") {
                        me.loadData();
                    }
                })
                .catch(() => {});
        },
        loadPosologiaData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + "/pazienteterapieposologia";
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.posologiaItems = [];
                    response.data.data.forEach((element) => {
                        let option = { text: this.getLabelTraduora(element.posologia), value: element.id };
                        me.posologiaItems.push(option);
                    });
                    me.posologiaItems.unshift({ value: null, text: this.$i18n.t("therapy.lblSelectPosology") });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.isBusy = true;
            me.showCodici = false;
            axios
                .get(link + me.id)
                .then((response) => {
                    me.data = response.data.data;
                    me.frequenzeSelezionate = me.data.orarioSomministrazione;
                    //me.$refs["SaPageList"].close();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onShow() {
            let me = this;
            me.tabellaFarmaci = [];
        },
        onOk() {
            let me = this;
            me.showCodici = false;
            me.showModalLoading = true;
            me.jsonData.farmaco = me.rigaSelezionata.descrizione;
            me.jsonData.atcGmp = me.rigaSelezionata.atcGmp;
            me.jsonData.codiceProdotto = me.rigaSelezionata.codiceProdotto;
            me.componentKey += 1;
            me.showCodici = true;
            me.terapieAvverse();
        },
        onRowSelected(items) {
            if (items.length > 0) {
                this.rigaSelezionata = items[0];
            }
        },
        checkAttiva(id) {
            if (id === "-1") {
                return false;
            } else {
                return true;
            }
        },

        onClick() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathRestFarmaci + "/";
            if (me.descrizioneFarmaco !== "") {
                axios
                    .get(link, { params: { descrizione: me.descrizioneFarmaco, atcGmp: me.atcGmpFarmaco } })
                    .then((response) => {
                        me.tabellaFarmaci = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        me.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((err) => {
                                console.log(err);
                            });
                    });
            } else {
                // console.log(me.atcGmpFarmaco);
                axios
                    .get(link, { params: { atcGmp: me.atcGmpFarmaco } })
                    .then((response) => {
                        me.tabellaFarmaci = response.data.data;
                        me.showModalLoading = false;
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        me.$bvModal
                            .msgBoxOk(error.response.data.messaggio, {
                                title: "ATTENZIONE",
                                size: "sm",
                                okVariant: "outline-danger",
                                headerClass: "sa-msg-header-danger",
                                footerClass: "p-2 border-top-0",
                                centered: true,
                            })
                            .then((value) => {
                                this.boxTwo = value;
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    });
            }
        },
    },
};
</script>
