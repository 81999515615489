<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="Informazioni Cliniche" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data insorgenza primi sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.dataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricovero Ospedaliero</label>
          <span class="sa-data">{{ jsonData.ricoveroOspedaliero }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Ricovero</label>
          <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nome Ospedale</label>
          <span class="sa-data">{{ jsonData.nomeOspedale }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Sintomatologia Clinica" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Febbre</label>
          <span class="sa-data">{{ jsonData.febbre }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Brividi</label>
          <span class="sa-data">{{ jsonData.brividi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Cefalea</label>
          <span class="sa-data">{{ jsonData.cefalea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tosse</label>
          <span class="sa-data">{{ jsonData.tosse }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Espettorazione</label>
          <span class="sa-data">{{ jsonData.espettorazione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dispnea</label>
          <span class="sa-data">{{ jsonData.dispnea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Emoftoe</label>
          <span class="sa-data">{{ jsonData.emoftoe }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Toracologie</label>
          <span class="sa-data">{{ jsonData.toracologie }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Versamento Pleurico</label>
          <span class="sa-data">{{ jsonData.versamentoPleurico }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ipossia</label>
          <span class="sa-data">{{ jsonData.ipossia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Opacita Polmonare</label>
          <span class="sa-data">{{ jsonData.opacitaPolmonare }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nausea/Vomito</label>
          <span class="sa-data">{{ jsonData.nauseaVomito }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dolori Addominali</label>
          <span class="sa-data">{{ jsonData.doloriAddominali }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Diarrea</label>
          <span class="sa-data">{{ jsonData.diarrea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Peritonite</label>
          <span class="sa-data">{{ jsonData.peritonite }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Confusione Mentale</label>
          <span class="sa-data">{{ jsonData.confusioneMentale }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Atassia</label>
          <span class="sa-data">{{ jsonData.atassia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Afasia</label>
          <span class="sa-data">{{ jsonData.afasia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Extrapiramidale</label>
          <span class="sa-data">{{ jsonData.extrapiramidale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Aritmia Cardiaca</label>
          <span class="sa-data">{{ jsonData.aritmiaCardiaca }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Pericardite</label>
          <span class="sa-data">{{ jsonData.pericardite }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Osteoartromialgia</label>
          <span class="sa-data">{{ jsonData.osteoartromialgia }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Altre Manifestazioni Cliniche Rilevanti</label>
          <span class="sa-data">{{ jsonData.altreManifestazioni }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altre Manifestazioni Cliniche Dettaglio</label>
          <span class="sa-data-justify">{{ jsonData.altreManifestazioniDettaglio }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Malattie concomitanti</label>
          <span class="sa-data">{{ jsonData.malattieConcomitati }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiMalattieConcomitantiBeans" :fields="fieldsMalattieConcomitanti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">In terapia sistemica con corticosteroidi o immunosoppressori</label>
          <span class="sa-data">{{ jsonData.terapiaCorticosteroidiImmunosoppressori }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Antibiotici (e dosi) assunti durante il ricovero</label>
          <span class="sa-data">{{ jsonData.antibioticiAssuntiDuranteRicovero }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiAntibioticiAssuntiBeans" :fields="fieldsAntibioticiAssunti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Cure odontoiatriche nelle due settimane precedenti l’esordio</label>
          <span class="sa-data">{{ jsonData.cureOdontoiatricheDueSettimanePrecedenti }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Ricovero ospedaliero nelle due settimane precedenti l’esordio</label>
          <span class="sa-data">{{ jsonData.ricoveroOspedalieroDueSettimanePrecendenti }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiRicoveroOspedalieroBeans" :fields="fieldsRicoveroOspedaliero" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Soggiorno, nelle due settimane precedenti l’esordio, in luoghi diversi dalla propria abitazione</label>
          <span class="sa-data">{{ jsonData.soggiornoDueSettimanePrecedentiDiversoDomicilio }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiSoggiornoBeans" :fields="fieldsSoggiorno" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Frequentazione di piscine nelle due settimane precedenti</label>
          <span class="sa-data">{{ jsonData.frequentazionePiscineDueSettimanePrecedenti }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Abitudine al fumo di sigaretta</label>
          <span class="sa-data">{{ jsonData.fumatore }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Fumatore da (Anni)</label>
          <span class="sa-data">{{ jsonData.anniFumatore }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Quantità Sigarette Fumate</label>
          <span class="sa-data">{{ jsonData.fumoQuantita }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Abitudine all’alcool</label>
          <span class="sa-data">{{ jsonData.alcool }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Quantità Assunta</label>
          <span class="sa-data">{{ jsonData.alcoolQuantita }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Diagnosi di Legionellosi" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Isolamento del germe specificare da quale materiale patologico</label>
          <span class="sa-data">{{ jsonData.isolamentoGerme }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Quantità Assunta</label>
          <span class="sa-data">{{ jsonData.isolamentoGermeMaterialePatologico }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Rilevazione Antigene Urinario</label>
          <span class="sa-data">{{ jsonData.rilevazioneAntigeneUrinario }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Sirerologia</label>
          <span class="sa-data">{{ jsonData.sierologia }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiSierologiaBeans" :fields="fieldsSierologia" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Indagine Ambientale</label>
          <span class="sa-data">{{ jsonData.indagineAmbientale }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeLegionellosiIndagineAmbientaleBeans" :fields="fieldsIndagineAmbientale" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.contattoSanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Notifica</label>
          <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveielegionellosi",
      linkback: null,
      id: "-1",
      showModalLoading: false,
      currentPage: 1,
      perPage: 100,
      jsonData: {},
      fieldsMalattieConcomitanti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Malattia",
          key: "malattia",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsAntibioticiAssunti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Antibiotico",
          key: "antibiotico",
          sortable: false,
        },
        {
          label: "Dose",
          key: "dose",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsRicoveroOspedaliero: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Ospedale",
          key: "ospedale",
          sortable: false,
        },
        {
          label: "Reparto",
          key: "reparto",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Dal",
          key: "dal",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Al",
          key: "al",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsSierologia: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo Sierologia",
          key: "tipoSierologia",
          sortable: false,
        },
        {
          label: "Giorni Inizio Sintomi",
          key: "giorniInizioSintomi",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Specie",
          key: "specie",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Sierogruppo",
          key: "sierogruppo",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsSoggiorno: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Tipo Struttura",
          key: "tipoStruttura",
          sortable: false,
        },
        {
          label: "Localita'",
          key: "localita",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Operatore Turistico",
          key: "operatoreTuristico",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Gruppo/Individuale",
          key: "gruppoIndividuale",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Dal",
          key: "dal",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Al",
          key: "al",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      fieldsIndagineAmbientale: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Materiale Analizzato",
          key: "materialeAnalizzato",
          sortable: false,
        },
        {
          label: "Indagine",
          key: "indagine",
          sortable: false,
          thStyle: "width: 12rem",
        },
        {
          label: "Specie",
          key: "specie",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "Sierogruppo",
          key: "sierogruppo",
          sortable: false,
          thStyle: "width: 10rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.$emit("showmodalloading", true);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.$emit("showmodalloading", false);
        })
        .catch(() => {
          me.$emit("showmodalloading", false);
        });
    },
  },
};
</script>
