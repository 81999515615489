<template>
  <sa-page-layout :data="data" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-header">
      <b-row> </b-row>
    </template>
    <template slot="table-body">
      <div style="height: 20px"></div>
      <b-row>
        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4"
          ><strong>Pressione Sistolica</strong>
          <br />
          <div>
            <b-input-group class="mb-3">
              <b-form-input v-model="data.sbp"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalSbp><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4"
          ><strong>Frequenza Cardiaca</strong>
          <br />
          <div>
            <b-input-group class="mb-3">
              <b-form-input v-model="data.hr"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalHr><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col xs="12" sm="6" md="6" lg="4" xl="4">
          <strong>Saturazione</strong>
          <br />
          <div>
            <b-input-group class="mb-3">
              <b-form-input v-model="data.spo2"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalSpo2><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <strong>Glicemia </strong>
          <div>
            <b-input-group class="mb-3">
              <b-form-input v-model="data.bs"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalBs><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
          <strong> Temperatura </strong>
          <div>
            <b-input-group class="mb-3">
              <b-form-input v-model="data.t"></b-form-input>
              <b-input-group-append>
                <b-button size="sm" text="Button" variant="secondary" v-b-modal.modalTemperatura><b-icon icon="search"></b-icon></b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
      </b-row>
      <b-modal id="modalSpo2" title="SPO2" size="md" hide-footer v-model="showmodalSpo2">
        <b-table :select-mode="'single'" selectable responsive @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneSpo2" :fields="fieldsSPO2" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <hr />
        <b-row>
          <div class="sa-padding-right text-right col">
            <b-button size="sm" variant="danger" @click="showmodalSpo2 = false"> Cancel </b-button>
            <b-button size="sm" variant="secondary" @click="onAggiungiSpo"> OK </b-button>
          </div>
        </b-row>
      </b-modal>
      <b-modal id="modalSbp" title="SBP" size="md" hide-footer v-model="showmodalSbp">
        <b-table :select-mode="'single'" selectable responsive @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneSbp" :fields="fieldsSBP" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <hr />
        <b-row>
          <div class="sa-padding-right text-right col">
            <b-button size="sm" variant="danger" @click="showmodalSbp = false"> Cancel </b-button>
            <b-button size="sm" variant="secondary" @click="onAggiungiSbp"> OK </b-button>
          </div>
        </b-row>
      </b-modal>
      <b-modal id="modalHr" title="HR" size="md" hide-footer v-model="showmodalHr">
        <b-table :select-mode="'single'" selectable responsive @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneHr" :fields="fieldsHr" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <hr />
        <b-row>
          <div class="sa-padding-right text-right col">
            <b-button size="sm" variant="danger" @click="showmodalHr = false"> Cancel </b-button>
            <b-button size="sm" variant="secondary" @click="onAggiungiHr"> OK </b-button>
          </div>
        </b-row>
      </b-modal>
      <b-modal id="modalBs" title="BS" size="md" hide-footer v-model="showmodalBs">
        <b-table :select-mode="'single'" selectable responsive @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneBs" :fields="fieldsBs" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <hr />
        <b-row>
          <div class="sa-padding-right text-right col">
            <b-button size="sm" variant="danger" @click="showmodalBs = false"> Cancel </b-button>
            <b-button size="sm" variant="secondary" @click="onAggiungiBs"> OK </b-button>
          </div>
        </b-row>
      </b-modal>
      <b-modal id="modalTemperatura" title="Temperatura" size="md" hide-footer v-model="showmodalTemperatura">
        <b-table :select-mode="'single'" selectable responsive @row-selected="onRowSelected" sticky-header ref="table" stacked="l" striped hover :items="misurazioneTemperatura" :fields="fieldsTemperatura" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
        </b-table>
        <hr />
        <b-row>
          <div class="sa-padding-right text-right col">
            <b-button size="sm" variant="danger" @click="showmodalTemperatura = false"> Cancel </b-button>
            <b-button size="sm" variant="secondary" @click="onAggiungiTemperatura"> OK </b-button>
          </div>
        </b-row>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";

export default {
  components: { PazienteAnagrafica, SaPageLayout },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      id: -1,
      pathResource: "/pazienteanalisirischith",
      pathRest: "/pazientemisure/ultimemisure",
      linkback: "/analisirischi",
      linkedit: null,
      componentKey: 0,
      data: {},
      iterator: 0,
      patientGoals: [],
      resultCalculation: [],
      misure: [],
      showModalLoading: false,
      misurazioneSpo2: [],
      misurazioneSbp: [],
      misurazioneBs: [],
      misurazioneTemperatura: [],
      misurazioneHr: [],
      showmodalSpo2: false,
      showmodalSbp: false,
      showmodalHr: false,
      showmodalBs: false,
      showmodalTemperatura: false,
      selected: {},
      dataInizio: moment(new Date()).format("DD-MM-YYYY HH:mm:ss"),
      //dataFine: "2022-01-14 23:00:00",
      fieldsSPO2: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Data Evento",
          key: "createDate",
          sortable: true,
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
        },
        {
          label: "SPO2",
          key: "value",
          thStyle: "width: 3rem",
        },
      ],
      fieldsSBP: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Data Evento",
          key: "createDate",
          sortable: true,
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
        },
        {
          label: "SBP",
          key: "value",
          thStyle: "width: 3rem",
        },
      ],
      fieldsHr: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Data Evento",
          key: "createDate",
          sortable: true,
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
        },
        {
          label: "HR",
          key: "value",
          thStyle: "width: 3rem",
        },
      ],
      fieldsBs: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Data Evento",
          key: "createDate",
          sortable: true,
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
        },
        {
          label: "BS",
          key: "value",
          thStyle: "width: 3rem",
        },
      ],
      fieldsTemperatura: [
        {
          label: "",
          key: "selected",
          selection: true,
          thStyle: "width: 1rem",
        },
        {
          label: "Data Evento",
          key: "createDate",
          sortable: true,
          formatter: (value) => {
            if (!value) {
              return "";
            } else {
              return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            }
          },
        },
        {
          label: "Temperatura",
          key: "value",
          thStyle: "width: 3rem",
        },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function () {
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length == 1) {
              if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                return "/paziente/view/" + this.idPaziente;
              } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                return "view/" + this.idPaziente;
              } else {
                return "homepaziente";
              }
            } else {
              return "";
            }
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
    },
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/paziente/analisirischith/edit/" + me.id + "?idPaziente=" + me.idPaziente;
    me.linkback = "/paziente/analisirischith?idPaziente=" + me.idPaziente;
    me.data.idPaziente = this.$route.query.idPaziente;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathRest + "/" + me.data.idPaziente;
      axios.get(link).then((response) => {
        response.data.data.forEach((element) => {
          if (element.measurename == "pressureMax") {
            me.misurazioneSbp.push(element);
          } else if (element.measurename == "pulse") {
            me.misurazioneHr.push(element);
          } else if (element.measurename == "spo2") {
            me.misurazioneSpo2.push(element);
          } else if (element.measurename == "glucose") {
            me.misurazioneBs.push(element);
          } else if (element.measurename == "temperature") {
            me.misurazioneTemperatura.push(element);
          }
        });
        me.showModalLoading = false;
      });
    },
    onRowSelected(items) {
      let me = this;
      me.selected = items;
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    forceRerender() {
      this.componentKey += 1;
    },
    onAggiungiSpo() {
      let me = this;
      me.data.spo2 = me.selected[0].value;
      me.showmodalSpo2 = false;
    },
    onAggiungiSbp() {
      let me = this;
      me.data.sbp = me.selected[0].value;
      me.showmodalSbp = false;
    },
    onAggiungiHr() {
      let me = this;
      me.data.hr = me.selected[0].value;
      me.showmodalHr = false;
    },
    onAggiungiBs() {
      let me = this;
      me.data.bs = me.selected[0].value;
      me.showmodalBs = false;
    },
    onAggiungiTemperatura() {
      let me = this;
      me.data.t = me.selected[0].value;
      me.showmodalTemperatura = false;
    },
  },
};
</script>
