<template>
  <sa-page-layout :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <template slot="toolbar">
      <b-button variant="btn-toolbar btn btn-outline-primary" size="sm" @click="onClickExportMdb">
        <i class="bi bi-download sa-icon-i"></i>
        Export MDB
      </b-button>
    </template>
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Data Dal</label>
            <date-picker v-model="filtro.dataDal" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
            <label class="sa-label-data">Data Al</label>
            <date-picker v-model="filtro.dataAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6" lg="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6" lg="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="xl" striped hover :items="items" :fields="fields" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="item">
            {{ item.index + 1 }}
          </template>
          <template v-slot:cell(nomeFile)="{ item }">
            <router-link class="sa-edit-link" :to="'/rencamcaricamentocsv/view/' + item.id">{{ item.nomeFile }}</router-link>
          </template>
          <template #cell(actions)="row">
            <!-- <b-button size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onDownloadFile(row.item.filename)">
              <i class="far fa-arrow-alt-circle-down"></i>
            </b-button> -->
            <b-button size="sm" variant="btn btn-outline-info no-text" @click="onDownloadFile(row.item.filename)">
              <b-icon icon="download"></b-icon>
            </b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout, DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      showModalLoading: false,
      pathResource: "/rencamschedamorteexportmdb",
      pathResourceDownload: "/rencamschedamorteexportmdb/download",
      pathResourceDownloadFile: "/rencamschedamorteexportmdb/downloadlocale",
      currentPage: 1,
      perPage: 100,
      rows: null,
      fields: [
        {
          label: "",
          key: "index",
          sortable: true,
          thStyle: "width: 3rem",
          tdClass: "text-center",
        },
        {
          label: "Nome File",
          key: "filename",
          sortable: true,
        },
        {
          label: "Username",
          key: "createUser",
          sortable: true,
          thStyle: "width: 10rem",
        },
        {
          label: "Data Download",
          key: "createDate",
          formatter: function (value) {
            if (value) {
              return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
              return "----";
            }
          },
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: "Data Dal",
          key: "dateDal",
          formatter: function (value) {
            if (value) {
              return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
              return "----";
            }
          },
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: "Data Al",
          key: "dateAl",
          formatter: function (value) {
            if (value) {
              return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
              return "----";
            }
          },
          sortable: true,
          thStyle: "width: 7rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      filter: {},
      filtro: { dataDal: Date.now(), dataAl: Date.now() },
      items: [],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  watch: {
    currentPage() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onReset() {
      let me = this;
      me.filtro = { page: 1, forPage: 100, nomeFile: "" };
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onClickExportMdb() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDownload;
      let fileName = "ASL - NAPOLI 1 Centro(A.S.L. NAPOLI 1 CENTRO) - " + moment(new Date()).format("DD MM YYYY HH-mm") + ".mdb";
      axios({
        url: link, // File URL Goes Here
        params: me.filtro,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        console.log(response.data);
        var blob = new Blob([response.data]);
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); //create the download url
        downloadElement.href = href;
        downloadElement.download = fileName; //the name of the downloaded file
        document.body.appendChild(downloadElement);
        downloadElement.click(); //click to file
        document.body.removeChild(downloadElement); //remove the element
        window.URL.revokeObjectURL(href); //release the object  of the blob
      });
    },
    onDownloadFile(filename) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDownloadFile + "/" + filename;
      let fileName = filename;
      axios({
        url: link, // File URL Goes Here
        // params: me.filtro,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        console.log(response.data);
        var blob = new Blob([response.data]);
        var downloadElement = document.createElement("a");
        var href = window.URL.createObjectURL(blob); //create the download url
        downloadElement.href = href;
        downloadElement.download = fileName; //the name of the downloaded file
        document.body.appendChild(downloadElement);
        downloadElement.click(); //click to file
        document.body.removeChild(downloadElement); //remove the element
        window.URL.revokeObjectURL(href); //release the object  of the blob
      });
    },
  },
};
</script>
