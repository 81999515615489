<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Caso</label>
          <span class="sa-data">{{ jsonData.tipoCaso }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Fonte di Contagio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Alimento sospetto</label>
          <span class="sa-data">{{ jsonData.alimentoSospetto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Tipo Alimento</label>
          <span class="sa-data">{{ jsonData.tipoAlimento }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Alimento Consumato Abitualmente</label>
          <span class="sa-data">{{ jsonData.consumoAbituale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Conserve Cotte</label>
          <span class="sa-data">{{ jsonData.cotturaConserve }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ingerito da (Ore)</label>
          <span class="sa-data">{{ jsonData.tempoIngerimento }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altro Alimento Sospetto</label>
          <span class="sa-data">{{ jsonData.altroAlimentoSospetto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Numero Consumatori Alimento</label>
          <span class="sa-data">{{ jsonData.numeroConsumatoriAlimento }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Numero Consumatori Con Sintomi</label>
          <span class="sa-data">{{ jsonData.numeroConsumatoriSintomatici }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Sintomatologia Clinica" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Diplopia</label>
          <span class="sa-data">{{ jsonData.diplopia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Diplopia Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.diplopiaDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nausea</label>
          <span class="sa-data">{{ jsonData.nausea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Nausea Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.nauseaDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Disfagia</label>
          <span class="sa-data">{{ jsonData.disfagia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Disfagia Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.disfagiaDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Vomito</label>
          <span class="sa-data">{{ jsonData.vomito }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Vomito Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.vomitoDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Secchezza Delle Fauci</label>
          <span class="sa-data">{{ jsonData.secchezzaFauci }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Secchezza Delle Fauci Data Insorgenza Sintomi </label>
          <span class="sa-data">{{ formatDate(jsonData.secchezzaFauciDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Diarrea</label>
          <span class="sa-data">{{ jsonData.diarrea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Diarrea Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.diarreaDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Stipsi</label>
          <span class="sa-data">{{ jsonData.stipsi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Stipsi Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.stipsiDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Insufficienza Respiratoria</label>
          <span class="sa-data">{{ jsonData.insufficienzaRespiratoria }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Insufficienza Respiratoria Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.insufficienzaRespiratoriaDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Midriasi</label>
          <span class="sa-data">{{ jsonData.midriasi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Midriasi Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.midriasiDataInsorgenzaSintomi) }}</span>
        </b-col>
        <!--DA COMPLETARE-->
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Coma</label>
          <span class="sa-data">{{ jsonData.coma }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Coma Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.comaDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ritenzione Urinaria</label>
          <span class="sa-data">{{ jsonData.ritenzioneUrinaria }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ritenzione Urinaria Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.ritenzioneUrinariaDataInsorgenzaSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altra Sintomatologia</label>
          <span class="sa-data">{{ jsonData.altraSintomatologia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Altra Sintomatologia Data Insorgenza Sintomi</label>
          <span class="sa-data">{{ formatDate(jsonData.altraSintomatologiaDataInsorgenzaSintomi) }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Diagnosi Strumentale" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Elettromiografia</label>
          <span class="sa-data">{{ jsonData.elettromiografia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Data Elettromiografia</label>
          <span class="sa-data">{{ formatDate(jsonData.elettromiografiaDataEsecuzione) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Referto Elettromiografia</label>
          <span class="sa-data-justify">{{ jsonData.referto }}</span>
        </b-col>
        <!-- <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data">Altre Diagnosi Strumentali</label>
                <b-form-select v-model="jsonData.altraDiagnosiStrumentale" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">Altre Diagnosi Strumentali Data Esecuzione</label>
                <date-picker v-model="jsonData.altraDiagnosiStrumentaleDataEsecuzione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="jsonData.elettromiografia !== 'SI'"></date-picker>                        
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Altre Diagnosi Strumentali Referto</label>
                <b-form-textarea v-model="jsonData.altraDiagnosiStrumentaleReferto"></b-form-textarea>
            </b-col> -->
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Decorso" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data di Ricovero</label>
          <span class="sa-data">{{ formatDate(jsonData.dataRicovero) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Antibotulinico Somministrato</label>
          <span class="sa-data">{{ jsonData.antibotulinico }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Dopo quanto tempo dall'esordio (indicare Ore)</label>
          <span class="sa-data">{{ jsonData.antibotulinicoOreSomministrazione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Unita' di antibotulinico somministrate</label>
          <span class="sa-data">{{ jsonData.antibotulinicoQuantita }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altre Terapie</label>
          <span class="sa-data-justify">{{ jsonData.altreTerapie }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Indagini di Laboratorio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricerca delle tossine botuliniche eseguita su Siero</label>
          <span class="sa-data">{{ jsonData.ricercaTossineBotulinicheSiero }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato</label>
          <span class="sa-data">{{ jsonData.ricercaTossineBotulinicheSieroRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricerca delle tossine botuliniche eseguita su Feci</label>
          <span class="sa-data">{{ jsonData.ricercaTossineBotulinicheFeci }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato</label>
          <span class="sa-data">{{ jsonData.ricercaTossineBotulinicheFeciRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricerca tossine botuliniche eseguita su Reperti Autoptici</label>
          <span class="sa-data">{{ jsonData.ricercaTossineBotulinicheRepertiAutoptici }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato</label>
          <span class="sa-data">{{ jsonData.ricercaTossineBotulinicheRepertiAutopticiRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricerca delle tossine botuliniche eseguita su Alimenti</label>
          <span class="sa-data">{{ jsonData.ricercaTossineBotulinicheAlimenti }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato</label>
          <span class="sa-data">{{ jsonData.ricercaTossineBotulinicheAlimentiRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricerca dei clostridi produttori di tossine Feci</label>
          <span class="sa-data">{{ jsonData.ricercaClostridiTossineFeci }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato</label>
          <span class="sa-data">{{ jsonData.ricercaClostridiTossineFeciRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricerca dei clostridi produttori di tossine Reperti Autoptici</label>
          <span class="sa-data">{{ jsonData.ricercaClostridiTossineRepertiAutoptici }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato</label>
          <span class="sa-data">{{ jsonData.ricercaClostridiTossineRepertiAutopticiRisultato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Ricerca dei clostridi produttori di tossine Alimenti</label>
          <span class="sa-data">{{ jsonData.ricercaClostridiTossineAlimenti }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Risultato</label>
          <span class="sa-data">{{ jsonData.ricercaClostridiTossineAlimentiRisultato }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Laboratorio Analisi</label>
          <span class="sa-data">{{ jsonData.laboratorioAnalisi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Indirizzo Laboratorio</label>
          <span class="sa-data">{{ jsonData.indirizzoLaboratorio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Telefono Laboratorio</label>
          <span class="sa-data">{{ jsonData.telefonoLaboratorio }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.telefonoSanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Notifica</label>
          <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveiebotulismo",
      id: "-1",
      jsonData: {},
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.$emit("showmodalloading", true);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.$emit("showmodalloading", false);
        })
        .catch(() => {
          me.$emit("showmodalloading", false);
        });
    },
  },
};
</script>
