<template>
  <!-- <sa-page-layout :showModalLoading="showModalLoading" :btnEditVisible="isThisFirmato" :btnAnnullaVisible="false"
        :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="jsonData"
        class="sa-no-space">
        <template slot="toolbar">
        </template>
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header">
            <h3 class="sa-event-title">Cardiomiopatie</h3>
        </template>
        <template slot="table-body">
           <cardiomiopatie-view-component :idEvento="id"></cardiomiopatie-view-component>
        </template>
    </sa-page-layout> -->
  <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
    <template slot="tab-content-event">
      <cardiomiopatie-view-component :idEvento="id" @update="onUpdateJsonData"></cardiomiopatie-view-component>
    </template>
  </eventi-view-component>
</template>
<script>
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixins from "../../../utility/mixins/EventiMixins";
import CardiomiopatieViewComponent from "./CardiomiopatieViewComponent.vue";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
export default {
  mixins: [UtilityMixins, EventiMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { CardiomiopatieViewComponent, EventiViewComponent },
  data() {
    return {
      linkback: null,
      titoloEvento: "Cardiomiopatie",
      pathResource: "/cardiocardiomiopatie",
      pathResourceFirma: "/cardiocardiomiopatie",
      jsonData: {},
      id: null,
      linkPrintData: null,
      showModalLoading: false,
      aterosclerosiNonEmoSign: false,
      aterosclerosiSevera: false,
      fontScale: 2,
      linkedit: null,
      pathRestValidation: "/validation",
      isThisFirmato: false,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idPaziente = this.$route.query.idPaziente;
    me.id = this.$route.params.id;
  },
  methods: {
    updateStatoFirma(firmato) {
      console.log(firmato);
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
      me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
    },
  },
};
</script>
