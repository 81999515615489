import AdiPazientiList from "./page/PazientiList.vue";
import PazientiListArchivio from "./page/PazientiListArchivio.vue";
import PazientiListRichieste from "./page/PazientiListRichieste.vue";
// import PazientiListRichiestePua from "./page/PazientiListRichiestePua.vue";
import AdiPazienteSchedaEdit from "./page/PazienteSchedaEdit.vue";
import DimissioniProtettePazientiList from "./page/DimissioniProtettePazientiList.vue";
import DimissioniProtetteEdit from "./page/DimissioniProtetteEdit.vue";
import DimissioniProtetteSezioneClinicaEdit from "./page/DimissioniProtetteSezioneClinicaEdit.vue";
import AdiPaiPrestazioniResidenzialiSemiResidenzialiList from "./page/AdiPaiPrestazioniResidenzialiSemiResidenzialiList.vue";
import AdiPaiPrestazioniResidenzialiSemiResidenzialiEdit from "./page/AdiPaiPrestazioniResidenzialiSemiResidenzialiEdit.vue";
import AdiPaiCureDomiciliariList from "./page/AdiPaiCureDomiciliariList.vue";
import AdiPaiCureDomiciliariEdit from "./page/AdiPaiCureDomiciliariEdit.vue";
import AdiPuaList from "./page/AdiPuaList.vue";
import AdiPuaView from "./page/AdiPuaView.vue";
import AdiPuaEdit from "./page/AdiPuaEdit.vue";
import AdiHomePuaList from "./page/AdiHomePuaList.vue";

// import DimissioniProtette from "./components/accessoUo/DimissioniProtette.vue";
// import SezioneClinica from "./components/accessoUo/SezioneClinica.vue";

// import AccessoMmgList from "./components/accessoMmg/AccessoMmgList.vue";

export default {
    AdiPazientiList,
    PazientiListArchivio,
    PazientiListRichieste,
    // PazientiListRichiestePua,
    AdiPazienteSchedaEdit,
    DimissioniProtettePazientiList,
    DimissioniProtetteEdit,
    DimissioniProtetteSezioneClinicaEdit,
    AdiPaiPrestazioniResidenzialiSemiResidenzialiList,
    AdiPaiPrestazioniResidenzialiSemiResidenzialiEdit,
    AdiPaiCureDomiciliariList,
    AdiPaiCureDomiciliariEdit,
    AdiPuaList,
    AdiPuaView,
    AdiPuaEdit,
    AdiHomePuaList,
    //   DimissioniProtette,
    //   SezioneClinica,
    //   AccessoMmgList
};
