<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Tipo Caso</label>
          <span class="sa-data">{{ jsonData.tipoCaso }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Procurata Lesione/Ferita contaminata con terreno o polvere negli ultimi 10/14 giorni</label>
          <span class="sa-data">{{ jsonData.lesioneFeritaContaminata }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Luogo Lesione</label>
          <span class="sa-data">{{ jsonData.lesioneFeritaContaminataLuogo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Fa uso di droghe da strada?</label>
          <span class="sa-data">{{ jsonData.utilizzoDrogheStrada }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Interventi Chirurgici negli ultimi 10/14 giorni</label>
          <span class="sa-data">{{ jsonData.interventiChirurgici }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <b-table sticky-header ref="tblStatoEstero" stacked="xl" striped hover itemscope :items="jsonData.malattieInfettiveIeTetanoInterventoChirurgicoBeans" :fields="fieldsInterventiChirurgici" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
              {{ row.index + 1 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Altri eventuali fattori di rischio</label>
          <span class="sa-data-justify">{{ jsonData.altriFattoriRischio }}</span>
        </b-col>
      </b-row>
      <hr />
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.sanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
          <span class="sa-data">{{ jsonData.telefonoSanitarioNotificante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Notifica</label>
          <span class="sa-data">{{ formatDate(jsonData.dataNotifica) }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveietetano",
      jsonData: {},
      perPage: 100,
      currentPage: 1,
      fieldsInterventiChirurgici: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Intervento",
          key: "intervento",
          sortable: false,
        },
        {
          label: "Data Intervento",
          key: "interventoData",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: false,
          thStyle: "width: 12rem",
        },
        {
          label: "Sito Intervento",
          key: "interventoSito",
          sortable: false,
          thStyle: "width: 12rem",
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.$emit("showmodalloading", true);
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.$emit("showmodalloading", false);
        })
        .catch(() => {
          me.$emit("showmodalloading", false);
        });
    },
  },
};
</script>
