<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblFamilyAnamnesis") }}</label>
                    <span class="sa-data">{{ jsonData.anamnesiFamiliare }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblPathologicalAnamnesis") }}</label>
                    <span class="sa-data">{{ jsonData.anamnesiPatologica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblInjuries") }}</label>
                    <span class="sa-data">{{ jsonData.infortuni }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblPhysiologicalAnamnesis") }}</label>
                    <span class="sa-data">{{ jsonData.anamnesiFisiologica }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSurgicalInterventions") }}</label>
                    <span class="sa-data">{{ jsonData.interventiChirurgici }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSportAnamnesis") }}</label>
                    <span class="sa-data">{{ jsonData.anamnesiSportiva }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
    },
};
</script>
