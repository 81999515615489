<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <leonida-federazioni-sportive-nazionali-view-component :jsonData="jsonData"></leonida-federazioni-sportive-nazionali-view-component>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import LeonidaFederazioniSportiveNazionaliViewComponent from "./LeonidaFederazioniSportiveNazionaliViewComponent.vue";
export default {
    components: { SaPageLayout, LeonidaFederazioniSportiveNazionaliViewComponent },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/federazionisportivenazionali/edit/" + me.id;
        me.loadData();
    },
    data() {
        return {
            pathResource: "/leonidafederazionisportivenazionali",
            linkedit: null,
            linkback: "/federazionisportivenazionali",
            showModalLoading: false,
            jsonData: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            me.showModalLoading = false;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.jsonData = response.data.data;
                me.showmolalloading = false;
            });
        },
    },
};
</script>
