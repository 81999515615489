<template>
    <sa-page-layout :btnRefreshVisible="true" @refresh="onRefresh()" class="sa-no-space">
        <template slot="toolbar"></template>
        <template slot="toolbar-title"></template>
        <template slot="table-header">
            <h3 class="sa-event-title">{{ titolo }}</h3>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="6" sm="6" md="6" lg="3" xl="3">
                        <b-card class="sa-card sa-header-nowrap sa-info-card-dashboard" header="O.S. INFO" header-tag="header" footer-tag="footer" title="">
                            <b-card-body>
                                <b-card-text class="text-right sa-dashboard-data-text">{{ jsonData.operationSystemInfo }}</b-card-text>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col cols="12" xs="6" sm="6" md="6" lg="3" xl="3">
                        <b-card class="sa-card sa-header-nowrap sa-info-card-dashboard" header="JAVA INFO" header-tag="header" footer-tag="footer" title="">
                            <b-card-body>
                                <b-card-text class="text-right sa-dashboard-data-text">{{ jsonData.javaVendor }} {{ jsonData.javaVersion }}</b-card-text>
                            </b-card-body>
                        </b-card>
                    </b-col>
                    <b-col cols="4" xs="6" sm="4" md="4" lg="2" xl="2">
                        <b-card class="sa-card sa-header-nowrap sa-info-card-dashboard" header="C.P.U. Number" header-tag="header" footer-tag="footer" title="">
                            <b-card-text class="text-right sa-dashboard-data-number">{{ jsonData.cpuCoresNumber }}</b-card-text>
                        </b-card>
                    </b-col>
                    <b-col cols="4" xs="6" sm="4" md="4" lg="2" xl="2">
                        <b-card class="sa-card sa-header-nowrap sa-info-card-dashboard" header="Con. Attive JDBCManager" header-tag="header" footer-tag="footer" title="">
                            <b-card-text class="text-right sa-dashboard-data-number">{{ jsonData.activeConnectionsJDBCManager }}</b-card-text>
                        </b-card>
                    </b-col>
                    <b-col cols="4" xs="6" sm="4" md="4" lg="2" xl="2">
                        <b-card class="sa-card sa-header-nowrap sa-info-card-dashboard" header="RAM di sistema" header-tag="header" footer-tag="footer" title="">
                            <b-card-text class="text-right sa-dashboard-data-number">{{ jsonData.systemMemory }}</b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                        <b-card class="sa-card sa-header-nowrap" header="O.S. INFO" header-tag="header" footer-tag="footer" title="">
                            <b-card-body>
                                <div style="height: 300px">
                                    <div style="height: 100%">
                                        <div style="width: 100%; height: 100%"><v-chart :option="pie" autoresize @rendered="chartEvents" /></div>
                                    </div>
                                </div>
                            </b-card-body>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import "echarts";
import ECharts from "vue-echarts";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout, "v-chart": ECharts },
    data() {
        return {
            titolo: "System Info",
            pathResource: "/systeminfo",
            jsonData: { connessioneAttivaJdbc: "---" },
            // dataSystemMemory: [[0,0], [5,0], [10,0], [0,0]],
            dataSystemMemory: [],
            dataSystemMemoryUsed: [],
            dataJavaMemory: [],
            dataJavaMemoryUsed: [],
            pie: null,
            interval: null,
        };
    },
    mounted() {
        let me = this;
        me.loadData();
        if (!this.interval) {
            this.interval = setInterval(() => me.loadData(), 2000);
        }
    },
    beforeDestroy() {
        if (this.interval) {
            clearTimeout(this.interval);
        }
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.jsonData = response.data;
                    me.pie = me.getChart();
                })
                .catch(() => {});
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        chartEvents() {},
        getChart() {
            let me = this;
            let dateTime = new Date();
            if (me.dataSystemMemory.length > 6) {
                me.dataSystemMemory.shift();
                me.dataSystemMemoryUsed.shift();
                me.dataJavaMemory.shift();
                me.dataJavaMemoryUsed.shift();
            }

            me.dataSystemMemory.push([dateTime, me.jsonData.systemMemory]);
            me.dataSystemMemoryUsed.push([dateTime, me.jsonData.systemMemoryUsed]);
            me.dataJavaMemory.push([dateTime, me.jsonData.javaMemory]);
            me.dataJavaMemoryUsed.push([dateTime, me.jsonData.javaMemoryUsed]);
            let option = {
                title: {
                    text: "",
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "cross",
                        label: {
                            backgroundColor: "#6a7985",
                        },
                    },
                },
                legend: {
                    data: ["Totale R.A.M.", "R.A.M. Usata", "Totale RAM Java", "R.A.M Usata Java"],
                },
                toolbox: {
                    feature: {
                        saveAsImage: {},
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: [
                    {
                        type: "time",
                        boundaryGap: false,
                        axisLabel: {
                            formatter: "{mm}:{ss}",
                            show: false,
                        },
                    },
                ],
                yAxis: [
                    {
                        type: "value",
                    },
                ],
                series: [
                    {
                        name: "Totale R.A.M.",
                        type: "line",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: me.dataSystemMemory,
                    },
                    {
                        name: "R.A.M. Usata",
                        type: "line",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: me.dataSystemMemoryUsed,
                    },
                    {
                        name: "Totale RAM Java",
                        type: "line",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: me.dataJavaMemory,
                    },
                    {
                        name: "R.A.M Usata Java",
                        type: "line",
                        areaStyle: {},
                        emphasis: {
                            focus: "series",
                        },
                        data: me.dataJavaMemoryUsed,
                    },
                ],
            };

            return option;
        },
    },
};
</script>

<style>
.sa-info-card-dashboard {
    height: 90px;
    max-height: 90px;
}
</style>
