<template>
  <div>
    <b-table sticky-header stacked="md" striped hover itemscope :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
      <template #cell(index)="row">
        {{ row.index + 1 }}
      </template>
      <template #cell(tipo)="item">
        <b-form-select v-model="item.item.tipo" :options="tipoOption" :value="''" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
      </template>
      <template #cell(tipoPartecipante)="item">
        <b-form-select v-model="item.item.tipoPartecipante" :options="tipoPartecipanteOption" :value="''" value-field="value" text-field="text" :disabled="!editSvamaD"></b-form-select>
      </template>
      <template #cell(ente)="item">
        <b-form-input v-model="item.item.ente" class="text-upper" :disabled="!editSvamaD"></b-form-input>
      </template>
      <template #cell(nome)="item">
        <b-form-input v-model="item.item.nome" class="text-upper" :disabled="!editSvamaD"></b-form-input>
      </template>
      <template #cell(actions)="row">
        <b-button v-if="row.index == items.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPersonale(items, row.item)" :disabled="!editSvamaD">
          <b-icon icon="plus"></b-icon>
        </b-button>
        <b-button v-if="row.index !== items.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(items, row)" :disabled="!editSvamaD">
          <b-icon icon="trash"></b-icon>
        </b-button>
      </template>
    </b-table>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array[Object],
      default: function () {
        return {
          tipo: "",
          tipoPartecipante: "",
          ente: "",
          nome: "",
        };
      },
    },
    editSvamaD: Boolean,
  },
  mounted() {},
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Convocat/Presenti",
          key: "tipo",
          sortable: false,
        },
        {
          label: "Titolo Partecipante",
          key: "tipoPartecipante",
          sortable: false,
        },
        {
          label: "Ente",
          key: "ente",
          sortable: false,
        },
        {
          label: "Nome",
          key: "nome",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      tipoPartecipanteOption: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "Resp. U.O. di competenza", text: "Resp. U.O. di competenza" },
        { value: "MMG/PLS", text: "MMG/PLS" },
        { value: "Ass. Sociale", text: "Ass. Sociale" },
        { value: "Medico Specialista", text: "Medico Specialista" },
        { value: "Altro", text: "Altro" },
      ],
      tipoOption: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "Convocato", text: "Convocato" },
        { value: "Presente", text: "Presente" },
      ],
    };
  },
  methods: {
    onAddPersonale(array, value) {
      if (value.tipo && value.tipoPartecipante && value.ente && value.nome) {
        array.push({ tipo: "", tipoPartecipante: "", ente: "", nome: "" });
      } else {
        this.$bvModal
          .msgBoxOk("Campi non validi", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    updatePianoTerapeutico() {
      let me = this;
      me.$emit("updatePatologie", me.items);
    },
  },
};
</script>

<style></style>
