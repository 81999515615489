import AnamnesiFamiliare from "../gastroenterologia/anamensifamiliare/index"
import AnamnesiFisiologica from "./anamnesifisiologica/index"
import AnamensiPatologica from "./anamnesipatologica/index"
import AnoRettoColonScopia from "./anorettocolonscopia/index"
import EcoEndoscopia from "./ecoendoscopia/index"
import EcografiaAddominale from "./ecografiaaddominale/index"
import Egds from "./egds/index"
import VisitaGastroenterologica from "./visitagastroenterologica/index"
import Ercp from "./ercp/index"
import InserzioneVideocapusla from "./inserzionevideocapusla/index"
import PolipectomiaEndoscopicaIntestinoCrasso from "./polipectomiaendoscopicaintestinocrasso/index"
import PosizionamentoBib from "./posizionamentobib/index"
import Enteroscopia from "./enteroscopia/index"
import Pancolonscopia from "./pancolonscopia/index"
import VisitaProctologica from "./visitaproctologica/index"
import CartellaInfermieristica from "./cartellainfermieristica/index"

import ConfigSelectEgdsStrumentoList from "./components/ConfigSelectEgdsStrumentoList.vue";
import ConfigSelectEgdsStrumentoEdit from "./components/ConfigSelectEgdsStrumentoEdit.vue";
import ConfigSelectEgdsStrumentoView from "./components/ConfigSelectEgdsStrumentoView.vue";
import ConfigSelectEgdsLavaggioDisinfezioneList from "./components/ConfigSelectEgdsLavaggioDisinfezioneList.vue";
import ConfigSelectEgdsLavaggioDisinfezioneEdit from "./components/ConfigSelectEgdsLavaggioDisinfezioneEdit.vue";
import ConfigSelectEgdsLavaggioDisinfezioneView from "./components/ConfigSelectEgdsLavaggioDisinfezioneView.vue";
import ConfigSelectEgdsIndicazioneList from "./components/ConfigSelectEgdsIndicazioneList.vue";
import ConfigSelectEgdsIndicazioneEdit from "./components/ConfigSelectEgdsIndicazioneEdit.vue";
import ConfigSelectEgdsIndicazioneView from "./components/ConfigSelectEgdsIndicazioneView.vue";

export default {
  AnamnesiFamiliare,
  AnamnesiFisiologica,
  AnamensiPatologica,
  AnoRettoColonScopia,
  EcoEndoscopia,
  EcografiaAddominale,
  Egds,
  VisitaGastroenterologica,
  Ercp,
  InserzioneVideocapusla,
  PolipectomiaEndoscopicaIntestinoCrasso,
  PosizionamentoBib,
  Enteroscopia,
  Pancolonscopia,
  VisitaProctologica,
  CartellaInfermieristica,
  ConfigSelectEgdsStrumentoList,
  ConfigSelectEgdsStrumentoEdit,
  ConfigSelectEgdsStrumentoView,
  ConfigSelectEgdsLavaggioDisinfezioneList,
  ConfigSelectEgdsLavaggioDisinfezioneEdit,
  ConfigSelectEgdsLavaggioDisinfezioneView,
  ConfigSelectEgdsIndicazioneList,
  ConfigSelectEgdsIndicazioneEdit,
  ConfigSelectEgdsIndicazioneView,
};
