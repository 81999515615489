<template>
  <sa-page-layout :btnNewVisible="true" :showModalLoading="showModalLoading" :btnRefreshVisible="true" :linkedit="linkedit" @refresh="onRefresh">
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="device" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(deviceName)="row">
            <span style="cursor:pointer" v-b-tooltip.hover title="Change Device Name" class="sa-edit-link" @click="onModificaNomeDevice(row.item)" v-b-modal.modalCambioNomeDevice>{{ row.item.deviceName }}</span>
          </template>
          <template #cell(action)="row">
            <b-button variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <b-modal id="modalCambioNomeDevice" ref="modal" :title="this.$i18n.t('patient.device.tooltipChangeNameDevice')" @show="resetModal" @hidden="resetModal" @ok="modificaNomeDevice">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <b-form-group label="Name" label-for="name-input" invalid-feedback="Name is required">
            <b-form-input id="name-input" v-model="nomeModel" required></b-form-input>
          </b-form-group>
        </form>
      </b-modal>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import axios from "axios";
export default {
  computed: {
    rows() {
      return this.device.length;
    },
  },
  data() {
    return {
      nomeModel: null,
      showModalLoading: false,
      perPage: 10,
      currentPage: 1,
      pathResourceCambioNomeDevice: "/hassistodevices/aggiornanomedevice",
      linkedit: "/hassistodevice",
      pathResource: "/hassistodevices",
      device: [],
      deviceObject: {},
      filter: {},
      fields: [
        {
          label: "",
          key: "index",
          thStyle: "width: 1rem",
          tdClass: "text-center",
        },
        {
          label: "Device Name",
          key: "deviceName",
          sortable: true,
          thStyle: "width: 6rem",
          tdClass: "text-left",
        },
        {
          label: "Model",
          key: "deviceModel",
          sortable: true,
          thStyle: "width: 6rem",
          tdClass: "text-left",
        },
        {
          label: "Mac Address",
          key: "deviceMAC",
          sortable: true,
          thStyle: "width: 6rem",
          tdClass: "text-left",
        },

        {
          label: "Type",
          key: "deviceType",
          sortable: true,
          thStyle: "width: 6rem",
          tdClass: "text-left",
        },
        {
          label: "",
          key: "action",
          thStyle: "width: 1rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  components: { SaPageLayout },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.showModalLoading = false;
          me.device = response.data.data;
          console.log(me.device);
        })
        .catch((e) => {
          me.showModalLoading = false;
          console.log(e);
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    resetModal() {
      let me = this;
      me.nomeModel = "";
    },
    onModificaNomeDevice(item) {
      let me = this;
      me.deviceObject = item;
    },

    modificaNomeDevice() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceCambioNomeDevice;
      me.showModalLoading = true;

      axios
        .post(link, { deviceMAC: me.deviceObject.deviceMAC, newDeviceName: me.nomeModel })
        .then((Response) => {
          console.log(Response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((e) => {
          me.showModalLoading = false;
          console.log(e);
        });
    },

    onDeleteItem(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
          title: "Conferma Cancellazione",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios
        .delete(link, { data: { deviceMAC: item.deviceMAC } })
        .then((Response) => {
          console.log(Response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
