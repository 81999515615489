<template>
    <b-modal id="mdlSinonimi" ref="mdlSinonimi" ok-only scrollable size="lg" @show="onShow()" @hidden="onClose">
        <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill v-model="tabIndex">
            <b-tab title="Sinonimi" active>
                <b-form @submit="loadSinonimi()" @reset="onResetFiltroSinonimi">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                            <label class="sa-label-data">Descrizione</label>
                            <b-form-input v-model="filtroSinonimi.descrizione" required></b-form-input>
                        </b-col>

                        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                            <label class="sa-label-data">Codice ICDX</label>
                            <b-form-input
                                v-model="filtroSinonimi.codiceIcdx"
                                :formatter="
                                    (value) => {
                                        return value.toUpperCase();
                                    }
                                "
                            ></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row align-content="center" style="padding-top: 5px; padding-bottom: 5px">
                        <b-col class="sa-padding-right text-right" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-button type="reset" v-on:click="onResetFiltroSinonimi" variant="outline-danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                            <b-button type="submit" v-on:click="loadSinonimi" variant="outline-success">Cerca</b-button>
                        </b-col>
                    </b-row>
                </b-form>

                <b-row class="sa-label-info">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rowsSinonimi }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPageSinonimi" :total-rows="rowsSinonimi" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                        <div class="b-table-sticky-header">
                            <b-table sticky-header ref="table" :filter="filter" stacked="xl" striped hover :items="itemsSinonimi" :fields="fieldsSinonimi" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                <!-- <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table"></b-table>                             -->
                                <template #cell(action)="row">
                                    <b-button size="sm" variant="btn btn-danger waves-effect waves-light width-sm no-text" @click="onDeleteRigaSinonimi(row.item)">
                                        <b-icon icon="trash"></b-icon>
                                    </b-button>
                                </template>
                            </b-table>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="sa-label-info" style="padding-bottom: 30px">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rowsSinonimi }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPageSinonimi" :total-rows="rowsSinonimi" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Aggiungi Sinonimo">
                <b-row>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nuovo Sinonimo</label>
                        <b-form-input v-model="jsonDataSinonimi.descrizione"></b-form-input>
                    </b-col>

                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice ICDX</label>
                        <b-input-group>
                            <b-form-input v-model="jsonDataSinonimi.codiceIcdx" readonly></b-form-input>
                            <b-input-group-append>
                                <b-button variant="btn btn-outline-success" @click="onAggiungiNuovoSinonimo()" v-b-tooltip.hover title="Aggiungi Sinonimo"><b-icon icon="check" v-b-tooltip.hover title="Aggiungi Sinonimo"></b-icon></b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                    <!-- <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                            <b-icon icon="check-square" style="cursor: pointer; margin-left: 12px; margin-top: 18px" scale="2.5" variant="success" @click="onAggiungiNuovoSinonimo()" v-b-tooltip.hover title="Aggiungi Sinonimo"></b-icon>
                        </b-col> -->
                </b-row>
                <b-form @submit="loadCodiciIcdX()" @reset="onResetFiltroCodiciIcdx">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                            <label>Descrizione</label>
                            <b-form-input v-model="filtroCodiciIcdx.descrizione"></b-form-input>
                        </b-col>

                        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                            <label>Codice ICDX</label>
                            <b-form-input
                                v-model="filtroCodiciIcdx.codice"
                                :formatter="
                                    (value) => {
                                        return value.toUpperCase();
                                    }
                                "
                            ></b-form-input>
                        </b-col>
                    </b-row>
                    <b-row align-content="center" style="padding-top: 5px; padding-bottom: 5px">
                        <b-col class="sa-padding-right text-right" xs="12" sm="12" md="12" lg="12" xl="12">
                            <b-button type="reset" v-on:click="onResetFiltroCodiciIcdx" variant="outline-danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                            <b-button type="submit" variant="outline-success" @click="loadCodiciIcdX()">Cerca</b-button>
                        </b-col>
                    </b-row>
                </b-form>

                <b-row class="sa-label-info">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rowsIcdx }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPageCodiciIcdx" :total-rows="rowsIcdx" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>

                <b-table sticky-header ref="table" :filter="filter" @row-selected="onSelezionaCodiceIcdx" select-mode="single" selectable fixed stacked="xl" striped hover :items="itemsCodiciIcdx" :fields="fieldsCodiciIcdx" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(codiceIcdX)="{ item }">
                        <b style="cursor: pointer" @click="onClickCodiceIcdx(item.codiceIcdX)">{{ item.codice }}</b>
                    </template>
                </b-table>

                <b-row class="sa-label-info" style="padding-bottom: 30px">
                    <b-col lg="3">
                        <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rowsIcdx }}</p>
                    </b-col>
                    <b-col lg="9">
                        <b-pagination v-model="currentPageCodiciIcdx" :total-rows="rowsIcdx" :per-page="perPage" align="right" size="sm"></b-pagination>
                    </b-col>
                </b-row>
            </b-tab>
        </b-tabs>
    </b-modal>
</template>

<script>
import axios from "axios";
export default {
    mounted: function () {},
    components: {},
    data() {
        return {
            itemsSinonimi: [],
            itemsCodiciIcdx: [],
            jsonDataSinonimi: {
                codiceIcdx: "",
            },
            tabIndex: 0,
            showInputReadOnlineCodiceIcdx: true,
            filtroCodiciIcdx: {
                codice: "",
                descrizione: "",
            },
            filtroSinonimi: {
                codiceIcdx: "",
                descrizione: "",
            },
            pathResourceSinonimi: "/rencamsinonimiicdx",
            pathResourceCodiciIcdx: "/serviziicdx",
            fieldsSinonimi: [
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "Codice Icdx",
                    key: "codiceIcdx",
                    sortable: true,
                    thStyle: "width: 2.5rem",
                },
                {
                    label: "",
                    key: "action",
                    thStyle: "width: 2rem",
                    tdClass: "text-center",
                },
            ],
            fieldsCodiciIcdx: [
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Codice Icdx",
                    key: "codiceIcdX",
                    sortable: true,
                    thStyle: "width: 3rem",
                },
            ],
            rowsIcdx: 0,
            rowsSinonimi: 0,
            perPage: 10,
            currentPageCodiciIcdx: 1,
            currentPageSinonimi: 1,
            filter: {},
            pathResource: "/sinonimiicdx",
        };
    },
    methods: {
        onShowModalSinonimi() {
            let me = this;
            me.loadSinonimi();
        },
        onResetFiltroSinonimi() {
            let me = this;
            me.filtroSinonimi.codiceIcdx = "";
            me.filtroSinonimi.descrizione = "";
            me.loadSinonimi();
        },
        onResetJsonDataSinonimo() {
            let me = this;
            me.jsonDataSinonimi.codiceIcdx = "";
            me.jsonDataSinonimi.descrizione = "";
            me.loadSinonimi();
        },
        onClickCodiceIcdx(value) {
            let me = this;
            me.jsonDataSinonimi.codiceIcdx = value;
            // this.showInputReadOnlineCodiceIcdx = false;
            // this.$nextTick(() => {
            //   this.showInputReadOnlineCodiceIcdx = true;
            // });
        },
        onSelezionaCodiceIcdx(item) {
            let me = this;
            if (item) {
                me.jsonDataSinonimi.codiceIcdx = item[0].codice;
            }
        },
        onResetFiltroCodiciIcdx() {
            let me = this;
            me.filtroCodiciIcdx.codiceCategoria = "";
            me.filtroCodiciIcdx.descrizione = "";
            me.loadCodiciIcdX();
        },
        onAggiungiNuovoSinonimo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSinonimi;
            let jsonData = JSON.stringify(me.jsonDataSinonimi);
            axios
                .post(link, jsonData)
                .then((response) => {
                    me.$refs["mdlSinonimi"].hide();
                    this.$bvToast.toast(`Sinonimo : ${response.data.data.descrizione}`, {
                        title: `Creazione Sinonimo per Codice : ${response.data.data.codiceIcdx} `,
                        solid: true,
                        variant: "success",
                        autoHideDelay: 5000,
                    });
                    setTimeout(me.$refs["mdlSinonimi"].show(), 2000);
                    me.onResetJsonDataSinonimo();
                })
                .catch((e) => {
                    e;
                });
        },
        onShow() {
            let me = this;
            me.loadSinonimi();
        },
        onClose() {
            let me = this;
            me.filtroSinonimi.descrizione = "";
            me.filtroSinonimi.codiceIcdx = "";
            me.jsonDataSinonimi.codiceIcdx = "";
            me.jsonDataSinonimi.descrizione = "";
            me.filtroCodiciIcdx.descrizione = "";
            me.filtroCodiciIcdx.codice = "";
        },
        loadSinonimi() {
            let me = this;
            event.preventDefault();

            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSinonimi + "?forPage=" + me.perPage + "&page=" + me.currentPageSinonimi;
            axios
                .get(link, { params: me.filtroSinonimi })
                .then((response) => {
                    me.itemsSinonimi = response.data.data.list;
                    me.rowsSinonimi = response.data.data.recordsNumber;
                    me.showModalLoading = false;
                })
                .catch((e) => {
                    e;
                    me.showModalLoading = false;
                });
        },
        loadCodiciIcdX() {
            let me = this;
            event.preventDefault();
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceCodiciIcdx + "?forPage=" + me.perPage + "&page=" + me.currentPageCodiciIcdx;
            axios
                .get(link, { params: me.filtroCodiciIcdx })
                .then((response) => {
                    me.itemsCodiciIcdx = response.data.data.list;
                    me.rowsIcdx = response.data.data.recordsNumber;
                    me.showModalLoading = false;
                })
                .catch((e) => {
                    e;
                    me.showModalLoading = false;
                });
        },
        onDeleteRigaSinonimi(item) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        me.deleteSinonimo(item);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        },
        deleteSinonimo(item) {
            console.log(item);
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceSinonimi + "/" + item.id;
            axios
                .delete(link)
                .then((response) => {
                    me.$refs["mdlSinonimi"].hide();
                    this.$bvToast.toast(`Sinonimo : ${response.data.data.descrizione}`, {
                        title: "Cancellazione Sinonimo",
                        solid: true,
                        variant: "success",
                        autoHideDelay: 5000,
                    });
                    setTimeout(me.$refs["mdlSinonimi"].show(), 2000);
                    me.onResetJsonDataSinonimo();
                })
                .catch((e) => {
                    e;
                });
        },
    },
    watch: {
        currentPageSinonimi: function () {
            let me = this;
            me.loadSinonimi();
        },
        currentPageCodiciIcdx: function () {
            let me = this;
            me.loadCodiciIcdX();
        },

        // tabIndex: function(value) {
        //   let me = this;
        //   if (value == 1) {
        //     if (me.itemsCodiciIcdx.length <= 0) {
        //       me.loadCodiciIcdX();
        //     }
        //   }
        // },
    },
};
</script>

<style>
.ft-modal {
    height: 10px;
}
</style>
