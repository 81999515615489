<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.events.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY hh:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("lbl.measure.weight") }}</label>
                    <b-form-input v-model="jsonData.peso" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("lbl.measure.height") }}</label>
                    <b-form-input v-model="jsonData.altezza" type="number" @input="onCalcoloBmiBsa(jsonData.altezza, jsonData.peso)"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
                    <b-form-input v-model="jsonData.bmi" disabled></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBSA") }}</label>
                    <b-form-input v-model="jsonData.bsa" disabled></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.card.lblRiskCalculationResultFactors')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHypertension") }}</label>
                    <b-form-checkbox v-model="jsonData.ipertensione" switch :checked="jsonData.ipertensione"> </b-form-checkbox>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.Dyslipidemia") }}</label>
                    <b-form-checkbox v-model="jsonData.displidemia" switch :checked="jsonData.displidemia"> </b-form-checkbox>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblDiabetes") }}</label>
                    <b-form-checkbox v-model="jsonData.diabete" switch :checked="jsonData.diabete"></b-form-checkbox>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</label>
                    <b-form-checkbox v-model="jsonData.obesita" switch :checked="jsonData.obesita"></b-form-checkbox>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblSmoking") }}</label>
                    <b-form-checkbox v-model="jsonData.fumo" switch :checked="jsonData.fumo"></b-form-checkbox>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCardiovascularAccidentsFamiliarity") }}</label>
                    <b-form-checkbox v-model="jsonData.familCerebrovascolari" switch :checked="jsonData.familCerebrovascolari"></b-form-checkbox>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</label>
                    <b-form-checkbox v-model="jsonData.familIpertensione" switch :checked="jsonData.familIpertensione"> </b-form-checkbox>
                </b-col>
                <b-col class="ft-color-section" xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIDiseaseFamiliarity") }}</label>
                    <b-form-checkbox v-model="jsonData.familCardiopatiaIsch" switch :checked="jsonData.familCardiopatiaIsch"></b-form-checkbox>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.afa.lblReport')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblReport") }}</label>
                    <b-form-textarea id="textarea" v-model="jsonData.referto" rows="8" no-resize></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    familIpertensione: "",
                    familCardiopatiaIsch: "",
                    familCerebrovascolari: "",
                    fumo: "",
                    obesita: "",
                    esaminatore: "",
                    codMedicoFirmante: "",
                    checkFi: "",
                    diabete: "",
                    displidemia: "",
                    ipertensione: "",
                    bsa: 0,
                    bmi: 0,
                    altezza: 0,
                    peso: 0,
                    referto: "",
                };
            },
        },
    },

    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            pathResource: "/cardiovisiteangiologiche",
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        onCalcoloBmiBsa(altezza, peso) {
            let me = this;
            me.jsonData.bmi = (peso / ((altezza / 100) * (altezza / 100))).toFixed(2);
            me.jsonData.bsa = (0.007184 * (Math.pow(peso, 0.425) * Math.pow(altezza, 0.725))).toFixed(2);
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>

<style></style>
