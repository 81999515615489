<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCoronargraphy')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTC") }}</label>
                    <span class="sa-data">{{ jsonData.tc }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.lesioniTc) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIVA") }}</label>
                    <span class="sa-data">{{ jsonData.iva }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.lesioniIva) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCx") }}</label>
                    <span class="sa-data">{{ jsonData.cx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.lesioniCx) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDx") }}</label>
                    <span class="sa-data">{{ jsonData.dx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.lesioniDx) }}</span>
                </b-col>
            </b-row>

            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDominance") }}</label>
                    <span class="sa-data">{{ getDominanza(jsonData.dominanza) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAccess") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.accesso) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAccessComplication") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.complicanzaAccesso) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFrench") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.french) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblVentriculography')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="9" lg="9" xl="9">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                    <span class="sa-data">{{ jsonData.ventricolografia }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInjuries") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.lesioniVentricolografia) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.lesioniVentricolografia2) }}</span>
                </b-col>
            </b-row>
        </b-card>

        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPercutaneosCoronaryIntervention')" header-tag="header" footer-tag="footer" title="">
            <h5 class="sa-text-left">{{ getLabelTraduora("patient.cardiology.lblPercutaneosCoronaryIntervention") }}</h5>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataIndicazionePci) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPciIndication") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.indicazionePci) }}</span>
                </b-col>
            </b-row>

            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEcgInAcs) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEcgInAcs") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.ecgInAcs) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblProceduralCharatteristics')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAccess") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.accesso2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAccessComplication") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.complicanzaAccesso2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFrench") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.french2) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblComplications')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedBloodVesselsNumber") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.numeroVasiTrattati) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedInjuriesNumber") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.numeroLesioniTrattate) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblInterventionalHemodynamicProcedures')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.data1) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.sede1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOperation") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.intervento1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblResult") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.esito1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.complicanze1) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.data2) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.sede2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOperation") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.intervento2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblResult") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.esito2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.complicanze2) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.data3) }}</span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.sede3) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOperation") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.intervento3) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblResult") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.esito3) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.complicanze3) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPTCA')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSessionNumber") }}</label>
                    <span class="sa-data">{{ jsonData.nSessione }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPtcaNumber") }}</label>
                    <span class="sa-data">{{ jsonData.nPtca }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProcedureNumber") }}</label>
                    <span class="sa-data">{{ jsonData.nProcedura }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIntroducers") }}</label>
                    <span class="sa-data">{{ jsonData.introduttori }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiseasedBloodVessels") }}</label>
                    <span class="sa-data">{{ jsonData.text9 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedBloodVessels") }}</label>
                    <span class="sa-data">{{ jsonData.vasiTrattati }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGuidingCatheter") }}</label>
                    <span class="sa-data">{{ jsonData.catetereGuida }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedBranches") }}</label>
                    <span class="sa-data">{{ jsonData.ramiTrattati }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTreatedInjuries") }}</label>
                    <span class="sa-data">{{ jsonData.lesioniTrattate }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblFirstMeasurements')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBall") }}</label>
                    <span class="sa-data">{{ jsonData.palloneEmodinamica1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGuide") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.guidaEmodinamica1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblator") }}</label>
                    <span class="sa-data">{{ jsonData.rotoblatorEmodinamica1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorProbe") }}</label>
                    <span class="sa-data">{{ jsonData.sondaRotoblatorEmodin1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorIvusProbe") }}</label>
                    <span class="sa-data">{{ jsonData.sondaIvusRotoblatorEmodin1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAterectomia") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.aterectomiaEmodinamica1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorAterectomiaProbe") }}</label>
                    <span class="sa-data">{{ jsonData.sondaAterectomiaEmodin1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.sedeAterectomiaEmodin1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tipoEmodinamica1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPRE") }}</label>
                    <span class="sa-data">{{ jsonData.preEmodinamica1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPOST") }}</label>
                    <span class="sa-data">{{ jsonData.postEmodinamica1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTIMI") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.timiEmodinamica1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.complicazioniEmodinamica1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStentNumber") }}</label>
                    <span class="sa-data">{{ jsonData.nStentEmodinamica1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStent") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.stentEmodinamica1) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblSecondMeasurements')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblBall") }}</label>
                    <span class="sa-data">{{ jsonData.palloneEmodinamica2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblGuide") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.guidaEmodinamica2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblator") }}</label>
                    <span class="sa-data">{{ jsonData.rotoblatorEmodinamica2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorProbe") }}</label>
                    <span class="sa-data">{{ jsonData.sondaRotoblatorEmodin2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorIvusProbe") }}</label>
                    <span class="sa-data">{{ jsonData.sondaIvusRotoblatorEmodin2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAterectomia") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.aterectomiaEmodinamica2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRotoblatorAterectomiaProbe") }}</label>
                    <span class="sa-data">{{ jsonData.sondaAterectomiaEmodin2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSite") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.sedeAterectomiaEmodin2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tipoEmodinamica2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPRE") }}</label>
                    <span class="sa-data">{{ jsonData.preEmodinamica2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPOST") }}</label>
                    <span class="sa-data">{{ jsonData.postEmodinamica2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTIMI") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.timiEmodinamica2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComplications") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.complicazioniEmodinamica2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStentNumber") }}</label>
                    <span class="sa-data">{{ jsonData.nStentEmodinamica2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStent") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.stentEmodinamica2) }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    props: {},
    components: {},
    data() {
        return {
            pathResource: "/cardiocoronarografieptca",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        getDominanza(value) {
            let returnValue = "";
            if (value == 0) {
                returnValue = this.getLabelTraduora("patient.cardiology.Options.lblMixed");
            }
            if (value == 1) {
                returnValue = this.getLabelTraduora("patient.cardiology.Options.lblLeft");
            }
            if (value == 2) {
                returnValue = this.getLabelTraduora("patient.cardiology.Options.lblRight");
            }
            return returnValue;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
