<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="Dati Visita" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRhythm") }}</label>
                    <b-form-select v-model="jsonData.ritmo" :options="optionsRitmo">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartRate") }}</label>
                    <b-form-input v-model="jsonData.frequenzaCardiaca" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAvConduction") }}</label>

                    <b-form-select v-model="jsonData.conduzioneAv" :options="optionsRitmo">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInterventricolarConduction") }}</label>

                    <b-form-select v-model="jsonData.conduzioneIntraventricolare" :options="optionsVentricolare">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVsIpertrophy") }}</label>
                    <b-form-select v-model="jsonData.ipertrofiaVs" :options="optionsIpertrofia">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartIschemia") }}</label>

                    <b-form-select v-model="jsonData.ischemiaCardiaca" :options="optionsIpertrofia">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQWave") }}</label>
                    <b-form-select v-model="jsonData.ondaQ" :options="optionsOndaQ">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStElevation") }}</label>

                    <b-form-select v-model="jsonData.sopraslivellamentoSt" :options="optionsSopraST">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStSubLeveling") }}</label>

                    <b-form-select v-model="jsonData.sottoslivellamentoSt" :options="optionsSottoST">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTWaveInversion") }}</label>

                    <b-form-select v-model="jsonData.inversioneOndaT" :options="optionsOndaT">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    frequenzaCardiaca: null,
                    ritmo: null,
                    conduzioneAv: null,
                    conduzioneIntraventricolare: null,
                    ipertrofiaVs: null,
                    ischemiaCardiaca: null,
                    ondaQ: null,
                    sopraslivellamentoSt: null,
                    sottoslivellamentoSt: null,
                    inversioneOndaT: null,
                };
            },
        },
    },

    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            pathResource: "/cardioesamiecg",
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            optionsRitmo: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblSinusal"), value: "patient.cardiology.Options.lblSinusal" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAtrialFlutter"), value: "patient.cardiology.Options.lblAtrialFlutter" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAtrialFibrillation"), value: "patient.cardiology.Options.lblAtrialFibrillation" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblJunctional"), value: "patient.cardiology.Options.lblJunctional" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblEleVVI"), value: "patient.cardiology.Options.lblEleVVI" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblEleDDD"), value: "patient.cardiology.Options.lblEleDDD" },
            ],
            optionsVentricolare: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNormal"), value: "patient.cardiology.Options.lblNormal" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblBBCompleteLeft"), value: "patient.cardiology.Options.lblBBCompleteLeft" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblEAS"), value: "patient.cardiology.Options.lblEAS" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblBBIncompleteRight"), value: "patient.cardiology.Options.lblSBBIncompleteRight" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblBBRight"), value: "patient.cardiology.Options.lblBBRight" },
            ],
            optionsIpertrofia: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblYes"), value: "patient.cardiology.Options.lblYes" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblNo"), value: "patient.cardiology.Options.lblNo" },
            ],
            optionsOndaQ: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAbsent"), value: "patient.cardiology.Options.lblAbsent" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPresent"), value: "patient.cardiology.Options.lblPresent" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPresentLower"), value: "patient.cardiology.Options.lblPresentlower" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPresentFrontal"), value: "patient.cardiology.Options.lblPresentFrontal" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPresentLateral"), value: "patient.cardiology.Options.lblPresentLateral" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPresentSeptal"), value: "patient.cardiology.Options.lblPresentSeptal" },
            ],
            optionsSottoST: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAbsent"), value: "patient.cardiology.Options.lblAbsent" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblLow2mm"), value: "patient.cardiology.Options.lblLow2mm" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblUp2mm"), value: "patient.cardiology.Options.lblUp2mm" },
            ],
            optionsSopraST: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAbsent"), value: "patient.cardiology.Options.lblAbsent" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblLow2mm"), value: "patient.cardiology.Options.lblLow2mm" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblUp2mm"), value: "patient.cardiology.Options.lblUp2mm" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblUp2mmFrontal"), value: "patient.cardiology.Options.lblUp2mmFrontal" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblUp2mmLateral"), value: "patient.cardiology.Options.lblUp2mmlateral" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblUp2mmLower"), value: "patient.cardiology.Options.lblUp2mmLower" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblUp2mmSeptal"), value: "patient.cardiology.Options.lblUp2mmSeptal" },
            ],
            optionsOndaT: [
                { text: this.getLabelTraduora("patient.cardiology.Options.lblAbsent"), value: "patient.cardiology.Options.lblAbsent" },
                { text: this.getLabelTraduora("patient.cardiology.Options.lblPresent"), value: "patient.cardiology.Options.lblPresent" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
