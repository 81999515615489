import PazienteTeleconsulto from "../";

const routes = [
    { name: "PazienteTeleconsultiList", path: "/paziente/teleconsulti", component: PazienteTeleconsulto.PazienteTeleconsultiList, meta: { title: "teleconsulto.lblTitle" } },
    { name: "PazienteTeleconsultiView", path: "/paziente/teleconsulti/view/:id", component: PazienteTeleconsulto.PazienteTeleconsultoView, meta: { title: "teleconsulto.lblTitle" } },
    { name: "PazienteTeleconsultoEdit", path: "/paziente/teleconsulti/edit/:id", component: PazienteTeleconsulto.PazienteTeleconsultoEdit, meta: { title: "teleconsulto.lblTitle" } },

    { name: "PazienteTeleconsultoHassisto", path: "/paziente/teleconsultoassisto/:id", component: PazienteTeleconsulto.PazienteTeleconsulto, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteTeleconsultoHassisto", path: "/paziente/teleconsultohassisto/:id", component: PazienteTeleconsulto.PazienteTeleconsultoHassisto, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteTeleconsultoHassisto", path: "/paziente/teleconsulti/:id", component: PazienteTeleconsulto.PazienteTeleconsulto, meta: { title: "pazienti.lblTitle" } },
    { name: "PazienteTeleconsultoGoogleMeet", path: "/paziente/teleconsultogooglemeet/:id", component: PazienteTeleconsulto.PazienteTeleconsultoGoogleMeet, meta: { title: "pazienti.lblTitle" } },
];

export default routes;
