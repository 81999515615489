import EmailNotification from "../";

const routes = [
    { name: "EmailNotificationGroupsList", path: "/emailnotificationgroups", component: EmailNotification.EmailNotificationGroupsList, meta: { title: "Lista Gruppi" } },
    { name: "EmailNotificationGroupsEdit", path: "/emailnotificationgroups/edit/:id", component: EmailNotification.EmailNotificationGroupsEdit, meta: { title: "Gruppo" } },
    { name: "EmailNotificationGroupsView", path: "/emailnotificationgroups/view/:id", component: EmailNotification.EmailNotificationGroupsView, meta: { title: "Gruppo" } },
    { name: "EmailNotificationUtentiList", path: "/emailnotificationutenti", component: EmailNotification.EmailNotificationUtentiList, meta: { title: "Lista Utenti" } },
    { name: "EmailNotificationUtentiEdit", path: "/emailnotificationutenti/edit/:id", component: EmailNotification.EmailNotificationUtentiEdit, meta: { title: "Utenti" } },
    { name: "EmailNotificationUtentiView", path: "/emailnotificationutenti/view/:id", component: EmailNotification.EmailNotificationUtentiView, meta: { title: "Utenti" } },
];

export default routes;