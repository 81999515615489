<template>
    <eventi-list-component :titoloEvento="titoloEvento" :utente="utente" :key="keyListaEventi" :pathEventResource="pathEventResource" :linkEvent="linkEvent" :gruppo="gruppo" :tipoEvento="tipoEvento"></eventi-list-component>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiListComponent from "../../../base/components/EventiListComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    mixins: [UtilityMixin, EventiMixin],
    components: { EventiListComponent },
    data() {
        return {
            titoloEvento: "PANCOLONSCOPIA",
            linkEvent: "/paziente/gastroenterologia/pancolonscopia",
            pathEventResource: "/gastropancolonscopia",
            keyListaEventi: 0,
            gruppo: "GASTROENTEROLOGIA",
            tipoEvento: "PANCOLONSCOPIA",
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
    },
    watch: {
        utente: function () {
            this.keyListaEventi++;
        },
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        rows() {
            return this.items.length;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    methods: {},
};
</script>
