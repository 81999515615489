import HassistoDeviceList from "./components/HassistoDeviceList.vue";
import HassistoKitList from "./components/HassistoKitList";
import HassistoHubList from "./components/HassistoHubList";
import HassistoDeviceEdit from "./components/HassistoDeviceEdit";
import HassistoKitEdit from "./components/HassistoKitEdit";

export default {
    HassistoDeviceList,
    HassistoKitList,
    HassistoHubList,
    HassistoDeviceEdit,
    HassistoKitEdit
};
