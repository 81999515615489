<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblReferenceType") }} *</label>
          <b-form-select v-model="jsonData.tipoReferente" :options="tipoReferenteOptions" @input="onInputData" :disabled="editRichiestaAttivazioneNad"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblCureType") }} *</label>
          <b-form-select v-model="jsonData.tipoCura" :options="tipoCuraOptions" @input="onInputData" :disabled="editRichiestaAttivazioneNad"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblProtocolNumber") }}</label>
          <b-form-input v-model="jsonData.numeroProtocollo" class="text-upper" @input="onInputData" readonly></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("adi.lblProtocolDate") }}</label>
          <date-picker v-model="jsonData.dataProtocollo" format="DD-MM-YYYY" value-type="timestamp" type="date" @input="onInputData" disabled :editable="false"></date-picker>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  props: {
    id: String,
    linkPrintData: String,
    numeroProtocollo: String,
    dataProtocollo: { type: Number },
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  mixins: [UtilityMixin],
  components: { DatePicker },
  data() {
    return {
      jsonData: {
        dataProtocollo: new Date(),
        tipoReferente: null,
        tipoCura: null,
        numeroProtocollo: "",
      },
      pathResource: "/adirichiesteattivazionenad",
      linkTipoReferente: "/adirichiesteattivazionenadtipireferenti",
      linkTipoCura: "/adirichiesteattivazionenadtipicura",
      editRichiestaAttivazioneNad: false,
      tipoReferenteOptions: [{ value: null, text: "-Seleziona Valore-", disabled: true }],
      tipoCuraOptions: [{ value: null, text: "-Seleziona Valore-", disabled: true }],
    };
  },
  mounted() {
    let me = this;
    me.jsonData.id = this.$route.params.id;
    if (me.id !== "-1") {
      me.loadData();
    }
    // me.loadData();
    me.loadDefaultData();
    me.impostaPermessi();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
        // this.jsonData.numeroProtocollo = this.numeroProtocollo;
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
    // numeroProtocollo(value) {
    //     console.log(value);
    //     this.jsonData.numeroProtocollo = value;
    // },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      let fonte = this.$route.query.fonte;
      me.editRichiestaAttivazioneNad = true;
      if (fonte === null || fonte === undefined || fonte === "undefined" || fonte === "") {
        fonte = "/richieste";
      }
      if (fonte === "/richieste") {
        me.editRichiestaAttivazioneNad = !UtilityMixin.methods.verificaPermessi(me.utente, "adirichiesteattivazionenad", 2);
      }
    },
    loadDefaultData() {
      let me = this;
      me.loadTipoReferente();
      me.loadTipoCura();
    },
    loadData() {
      let me = this;
      if (me.id !== -1) {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
        me.showmolalloading = true;
        axios
          .get(link)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showmolalloading = false;
          })
          .catch(() => {
            me.showmolalloading = false;
            me.jsonData = {
              dataProtocollo: this.dataProtocollo,
              tipoReferente: null,
              tipoCura: null,
              numeroProtocollo: this.numeroProtocollo,
            };
          });
        me.$emit("update", me.jsonData);
      }
    },
    loadTipoReferente() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkTipoReferente + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipoReferenteOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    loadTipoCura() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkTipoCura + "?page=1&forPage=50";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipoCuraOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
    getJsonData() {
      let me = this;
      return me.jsonData;
    },
    onInputData() {
      this.jsonData.id = this.$route.params.id;
      this.$emit("update", this.jsonData);
    },
  },
};
</script>
