import Agenda from "./components/Agenda.vue";
import AgendeList from "./components/AgendeList.vue";
import AgendaView from "./components/AgendaView.vue";
import AgendaEdit from "./components/AgendaEdit.vue";
//import Month from "./components/Month.vue";
// import GruppoEdit from "./components/GruppoEdit.vue";
// import GruppoView from "./components/GruppoView.vue";

export default {
    Agenda,
    AgendeList,
    AgendaView,
    AgendaEdit,
    //   GruppoEdit,
    //   GruppoView,
    //   UserList,
    //   UserEdit,
};
