<template>
  <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :btnBackVisible="true" :btnNewVisible="true" :linkback="linkback" :data="profilo">
    <template slot="toolbar-title">Profilo </template>
    <template slot="table-body">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label" for="profilo-username"> Username</label>
          <b-form-input id="profilo-username" v-model="profilo.username"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label" for="profilo-celullare"> Cellulare</label>
          <b-form-input id="profilo-cellulare" v-model="profilo.cellulare"></b-form-input>
        </b-col>

        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-form-label" for="profilo-email">Email</label>
          <b-form-input id="profilo-email" v-model="profilo.email"></b-form-input>
        </b-col>
      </b-row>
      <br />
      <h5>Dettaglio Anagrafica</h5>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="6" xl="6">
          <label class="sa-form-label" for="profilo-cognome">Cognome</label>
          <br />
          <b-form-input id="profilo-cognome" v-model="profilo.cognome"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="6" xl="6">
          <label class="sa-form-label" for="profilo-nome">Nome</label>
          <br />
          <b-form-input id="profilo-nome" v-model="profilo.nome"></b-form-input>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-form-label" for="profilo-tipo-identificativo">Tipo Identificativo</label>
          <br />
          <b-form-select id="profilo-tipo-identificativo" v-model="profilo.tipoIdentificativo" :options="tipoIdentificativoOptions" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-form-label" for="profilo-identificativo">Identificativo</label>
          <br />
          <b-form-input id="profilo-identificativo" v-model="profilo.identificativo"></b-form-input>
        </b-col>
      </b-row>
      <br />
      <h5>Domicilio</h5>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-form-label" for="profilo-indirizzo-domicilio">Indirizzo </label>
          <br />
          <b-form-input id="profilo-indirizzo-domicilio" v-model="profilo.indirizzoDomicilio"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-form-label" for="profilo-civico-domicilio">Civico</label>
          <br />
          <b-form-input id="profilo-civico-domicilio" v-model="profilo.civicoDomicilio"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label for="profilo-provincia-domicilio">Provincia </label>
          <br />
          <b-form-input id="profilo-provincia-domicilio" v-model="profilo.provinciaDomicilio"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-form-label" for="profilo-comune-domicilio">Comune</label>
          <br />
          <b-form-input id="profilo-comune-domicilio" v-model="profilo.comuneDomicilio"></b-form-input>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="sa-element-data">
          <label class="sa-form-label" for="profilo-frazione-domicilio">Frazione</label>
          <br />
          <b-form-input id="profilo-frazione-domicilio" v-model="profilo.frazioneDomicilio"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-form-label" for="profilo-cap-domicilio">C.A.P.</label>
          <br />
          <b-form-input id="profilo-cap-domicilio" v-model="profilo.capDomicilio"></b-form-input>
        </b-col>
      </b-row>
      <br />
      <h5>Residenza</h5>
      <hr />
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-form-label" for="profilo-indirizzo-residenza">Indirizzo</label>
          <br />
          <b-form-input id="profilo-indirizzo-residenza" v-model="profilo.indirizzoResidenza"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-form-label" for="profilo-civico-residenza">Civico</label>
          <br />
          <b-form-input id="profilo-civico-residenza" v-model="profilo.civicoResidenza"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label for="profilo-provincia">Provincia</label>
          <br />
          <b-form-input id="profilo-provincia-residenza" v-model="profilo.provinciaResidenza"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-form-label" for="profilo-comune-residenza">Comune </label>
          <br />
          <b-form-input id="profilo-comune-residenza" v-model="profilo.comuneResidenza"></b-form-input>
        </b-col>
      </b-row>
      <br />
      <b-row>
        <b-col xs="12" sm="12" md="3" lg="3" xl="3" class="sa-element-data">
          <label class="sa-form-label" for="profilo-frazione-residenza">Frazione </label>
          <br />
          <b-form-input id="profilo-frazione-residenza" v-model="profilo.frazioneResidenza"></b-form-input>
        </b-col>

        <b-col xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-form-label" for="profilo-cap-residenza">C.A.P.</label>
          <br />
          <b-form-input id="profilo-cap-residenza" v-model="profilo.capResidenza"></b-form-input>
        </b-col>
      </b-row>
      <br />
    </template>
  </sa-page-layout>
</template>

<script>
// import axios from "axios";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
import UtilityMixins from "../../utility/UtilityMixin";
import axios from "axios";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return { idUtente: null };
      },
    },
  },
  components: { SaPageLayout },
  data() {
    return {
      pathResource: "/profilo",
      linkback: "/profilo",

      profilo: {},
      showModalLoading: false,

      tipoIdentificativoOptions: [
        { text: "Carta D'Identita'", value: "CI" },
        { text: "Codice Fiscale", value: "CF" },
        { text: "Passaporto", value: "PS" },
      ],
    };
  },
  mixins: [UtilityMixins],
  mounted() {
    let me = this;
    me.loadData();
    me.linkback = this.linkback + "/view/" + me.utente.id;
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/profilo";
      axios.get(link).then((response) => {
        me.profilo = response.data.data;
      });
    },
  },
};
</script>

<style></style>
