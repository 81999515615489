<template>
    <!-- <b-embed type="iframe" :src="file" allowfullscreen></b-embed> -->

    <div class="TN bzz aHS-YH" style="margin-left: 0px">
        <div class="qj qr"></div>
        <div class="aio UKr6le">
            <span class="nU false"><a href="https://meet.google.com/zzq-rxuh-eut" target="_blank" class="J-Ke n0" title="Start a meeting" aria-label="Start a meeting" draggable="false">Start a meeting</a></span>
        </div>
        <div class="nL aif"></div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            file: "https://meet.google.com/zzq-rxuh-eut",
        };
    },
};
</script>

<style></style>
