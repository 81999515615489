<template>
    <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
        <template slot="tab-content-event">
            <monitoraggio-ecg-view-component @update="onUpdateJsonData"></monitoraggio-ecg-view-component>
        </template>
    </eventi-view-component>
</template>
<script>
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixins from "../../../utility/mixins/EventiMixins";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import MonitoraggioEcgViewComponent from "./MonitoraggioEcgViewComponent.vue";
export default {
    mixins: [UtilityMixins, EventiMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { EventiViewComponent, MonitoraggioEcgViewComponent },
    data() {
        return {
            titoloEvento: "Monitoraggio ECG",
            linkback: null,
            pathResource: "/cardiomonitoraggiecg",
            pathResourceFirma: "/cardiomonitoraggiecg",
            jsonData: {},
            tabIndex: 0,
            id: null,
            linkPrintData: null,
            showModalLoading: false,
            fontScale: 2,
            linkedit: null,
            pathRestValidation: "/validation",
            isThisFirmato: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.id = this.$route.params.id;
    },
    methods: {
        updateStatoFirma(firmato) {
            console.log(firmato);
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
        },
    },
};
</script>
