<template>
  <sa-page-layout :btnNewVisible="false" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col sm="6">
            <b-form-input v-model="filtro.paziente" type="search" id="nome" :placeholder="this.$i18n.t('adi.lblFirstNameESecondName')"></b-form-input>
          </b-col>
          <b-col sm="6">
            <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" :placeholder="this.$i18n.t('adi.lblFiscalCode')"></b-form-input>
          </b-col>
        </b-row>
        <b-row class="sa-form-btn-filter">
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row class="sa-label-info">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="items" :filter="filtro" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(codiceFiscale)="{ item }">
            <router-link class="sa-edit-link" :to="'/adipazienti/richieste/adipai/prestazioniresidenziali/edit/' + item.id"> {{ item.codiceFiscale }} </router-link>
          </template>
          <template v-slot:cell(paziente)="{ item }">
            <a> {{ item.nome }} {{ item.cognome }} </a>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import UtilityMixin from "../../utility/UtilityMixin";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  mixins: [UtilityMixin],
  data() {
    return {
      filtro: {},
      currentPage: 1,
      perPage: 50,
      rows: null,
      showModalLoading: false,
      linkedit: "/adipazienti/richieste/adipai/prestazioniresidenziali",
      pathResource: "/adiproposteaccesso",
      // pathResource: "/adianagrafiche",
      items: [],
      fields: [
        {
          label: "Codice Fiscale",
          key: "codiceFiscale",
          thStyle: "width: 10rem",
          sortable: true,
        },
        {
          label: "Paziente",
          key: "paziente",
          sortable: true,
        },
        {
          label: "Data",
          key: "dataCreazione",
          thStyle: "width: 8rem",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.filtro.forPage = me.perPage;
      me.filtro.page = me.currentPage;
      me.showModalLoading = true;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.showModalLoading = false;
        })
        .catch((error) => {
          me.showModalLoading = false;
          this.$bvModal
            .msgBoxOk(link + " \n" + error.message, {
              title: "ATTENZIONE",
              size: "sm",
              okVariant: "outline-danger",
              headerClass: "sa-msg-header-danger",
              footerClass: "p-2 border-top-0",
              centered: true,
            })
            .then(() => {})
            .catch((err) => {
              console.log(err);
            });
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
  },
};
</script>
