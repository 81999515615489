<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblHeight") }}</label>
          <span class="sa-data">{{ jsonData.altezza }}</span>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblWeight") }}</label>
          <span class="sa-data">{{ jsonData.peso }}</span>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">B.M.I</label>
          <span class="sa-data">{{ jsonData.bmi }}</span>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblAbdominalCircumference") }}</label>
          <span class="sa-data">{{ jsonData.circonferenzaAddominale }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data"> Spo2 </label>
          <span class="sa-data">{{ jsonData.spo2 }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.diabetology.lblSystolicPressure") }}</label>
          <span class="sa-data">{{ jsonData.pressioneSistolica }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblDiastolicBloodPressure") }} </label>
          <span class="sa-data">{{ jsonData.pressioneDiastolica }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.diabetology.lblHeartRate") }}</label>
          <span class="sa-data">{{ jsonData.frequenzaCardiaca }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.diabetology.lblECG") }}</label>
          <span class="sa-data">{{ jsonData.ecg }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.diabetology.lblGlucose") }}</label>
          <span class="sa-data">{{ jsonData.glucosio }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.diabetology.lblSkinAndAnnexes") }}</label>
          <span class="sa-data">{{ jsonData.cuteEdAnnessi }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblRespiratorySystem") }}</label>
          <span class="sa-data">{{ jsonData.apparatoRespiratorio }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.lblDigestiveSystem") }}</label>
          <span class="sa-data">{{ jsonData.apparatoDigerente }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patients.lblUrogenitalApparatus") }}</label>
          <span class="sa-data">{{ jsonData.apparatoUrogenitale }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data"> {{ getLabelTraduora("patient.diabetology.lblArticularMuscleSystem") }}</label>
          <span class="sa-data">{{ jsonData.apparatoMuscoloArticolare }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.diabetology.lblCardiovascularSystem") }}</label>
          <span class="sa-data">{{ jsonData.sistemaCardiovascolare }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data"> {{ getLabelTraduora("patients.lblNervousSystem") }}</label>
          <span class="sa-data">{{ jsonData.sistemaNervoso }}</span>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data">{{ getLabelTraduora("patients.lblFoot") }}</label>
          <span class="sa-data">{{ jsonData.piede }}</span>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data"> {{ getLabelTraduora("patients.lblSubcutaneous") }}</label>
          <span class="sa-data">{{ jsonData.sottocutaneo }}</span>
        </b-col>
        <b-col xs="12" sm="3" md="3" lg="3" xl="3">
          <label class="sa-label-data"> {{ getLabelTraduora("patients.lblMouthTeeth") }}</label>
          <span class="sa-data">{{ jsonData.boccaDenti }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patients.lblPatientAge") }}</label>
          <span class="sa-data">{{ jsonData.eta }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.lblCigarette") }}</label>
          <!-- <span class="sa-data">{{ jsonData.fumatore }} -->
          <b-form-checkbox v-model="jsonData.fumatore" name="check-button" size="md" disabled></b-form-checkbox>
        </b-col>
        <b-col xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.lblAlcohol") }}</label>
          <b-form-checkbox v-model="jsonData.alcool" name="check-button" size="md" disabled></b-form-checkbox>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblStrokeTransientIschemia") }}</label>
          <b-form-checkbox v-model="jsonData.ictusIschemiaTransitoria" name="check-button" size="md" disabled></b-form-checkbox>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHypertension") }}</label>
          <b-form-checkbox v-model="jsonData.ipertensione" name="check-button" size="md" disabled></b-form-checkbox>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblChronicKidneyDisease") }}</label>
          <b-form-checkbox v-model="jsonData.malattiaRenaleCronica" name="check-button" size="md" disabled></b-form-checkbox>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHeartDiseaseChronic") }}</label>
          <b-form-checkbox v-model="jsonData.cardiopatiaCronica" name="check-button" size="md" disabled></b-form-checkbox>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblDiabetes") }}</label>
          <b-form-checkbox v-model="jsonData.diabete" name="check-button" size="md" disabled></b-form-checkbox>
        </b-col>
        <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
          <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblHypertensionTreatment") }}</label>
          <b-form-checkbox v-model="jsonData.trattamentoIpertensione" name="check-button" size="md" disabled></b-form-checkbox>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="4" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patients.lblCholesterol") }}</label>
          <span class="sa-data">{{ jsonData.colesteroloHdl }}</span>
        </b-col>
        <b-col xs="12" sm="4" md="6" lg="6" xl="6">
          <label class="sa-label-data">{{ getLabelTraduora("patients.lblTotalCholesterol") }}</label>
          <span class="sa-data">{{ jsonData.colesteroloTotale }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ getLabelTraduora("pazienti.egds.lblConclusions") }}</label>
          <span class="sa-data">{{ jsonData.conclusioni }}</span>
        </b-col></b-row
      >
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">Prossimo Consulto</label>
          <span class="sa-data">{{ jsonData.prossimoConsulto }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <div class="sa-view-form-data"><user-information :data="jsonData"></user-information></div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../utility/UtilityMixin";
import UserInformation from "../../../../utenti/components/UserInformation.vue";
export default {
  mixins: [UtilityMixin],
  components: { UserInformation },
  props: {
    idEvento: { type: String, require: true },
  },
  data() {
    return {
      // pathResource: "/visitehh",
      pathResource: "/pazienteeventivisite",
      linkback: null,
      linkedit: null,
      showmolalloading: false,
      id: "-1",
      profili: [],
      jsonData: {},
      provenienzaOption: [{ value: null, text: null }],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    dataEventoPlaceholder() {
      return this.getLabelTraduora("patient.events.lblEventDate");
    },
  },
  mounted: function () {
    let me = this;
    me.id = me.idEvento;

    me.linkback = "" + me.idPaziente;
    me.loadData();
  },
  methods: {
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
      } else {
        return "---";
      }
    },
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showmolalloading = false;
      } else {
        me.showmolalloading = true;
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";

        axios.get(link + me.idEvento).then((response) => {
          me.jsonData = response.data.data;
        });
      }
    },
  },
};
</script>
