<template>
    <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :data="jsonData" :btnEditVisible="false" :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit">
        <template slot="table-filter">
            <p style="padding-left: 10px; border-bottom: 1px solid #e3e3e3; font-weight: 500; font-variant: all-small-caps; font-size: 1.2rem; color: black">{{ titoloCard }}</p>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div v-if="punto !== '1.1'" class="b-table-sticky-header">
                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="jsonData.listaIndicatori" :fields="fieldsLapDettagli" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template v-slot:cell(punto)="{ item }">
                        <router-link class="sa-edit-link" :to="'/caduceolapmanagement/indicatore/' + item.punto">{{ item.punto }}</router-link>
                    </template>
                </b-table>
            </div>
            <div v-else class="b-table-sticky-header">
                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="jsonData.listaAssistiti" :fields="fieldsAssistiti" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template v-slot:cell(punto)="{ item }">
                        <router-link class="sa-edit-link" :to="'/caduceolapmanagement/indicatore/' + item.punto">{{ item.punto }}</router-link>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>

        <!-- <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-card class="sa-card" :header="titoloCard" header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col v-if="punto !== '1.1'" xs="12" sm="12" md="12" lg="12" xl="12">
                            <div class="b-table-sticky-header">
                                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="jsonData.listaIndicatori" :fields="fieldsLapDettagli" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                    <template v-slot:cell(index)="row">
                                        {{ row.index + 1 }}
                                    </template>
                                    <template v-slot:cell(punto)="{ item }">
                                        <router-link class="sa-edit-link" :to="'/caduceolapmanagement/indicatore/' + item.punto">{{ item.punto }}</router-link>
                                    </template>
                                </b-table>
                            </div>
                        </b-col>
                        <b-col v-else xs="12" sm="12" md="12" lg="12" xl="12">
                            <div class="b-table-sticky-header">
                                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="jsonData.listaAssistiti" :fields="fieldsAssistiti" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                                    <template v-slot:cell(index)="row">
                                        {{ row.index + 1 }}
                                    </template>
                                    <template v-slot:cell(punto)="{ item }">
                                        <router-link class="sa-edit-link" :to="'/caduceolapmanagement/indicatore/' + item.punto">{{ item.punto }}</router-link>
                                    </template>
                                </b-table>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </template> -->
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            showModalLoading: false,
            rows: null,
            indicatore: "",
            idLap: null,
            punto: null,
            linkedit: null,
            linkback: null,
            currentPage: 1,
            perPage: 300,
            pathResource: "/lapdettagli",
            pathResourceAssistiti: "/assistiti",
            filtro: { codiceFiscaleMedico: "", annoRiferimento: "", idLap: "", punto: "" },
            jsonData: {
                id: "-1",
                codiceFiscaleMedico: null,
                annoRiferimento: null,
                listaIndicatori: [],
                listaAssistiti: [],
            },
            fieldsLapDettagli: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 1rem",
                },
                {
                    label: "Cognome",
                    key: "cognome",
                    sortable: true,
                },
                {
                    label: "Nome",
                    key: "nome",
                    sortable: true,
                },
                {
                    label: "Codice Fiscale",
                    key: "cfPatient",
                    sortable: true,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Numero",
                    key: "numero",
                    sortable: true,
                    thStyle: "width: 2rem",
                },
            ],
            fieldsAssistiti: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 1rem",
                },
                {
                    label: "Cognome",
                    key: "surname",
                    sortable: true,
                },
                {
                    label: "Nome",
                    key: "name",
                    sortable: true,
                },
                {
                    label: "Codice Fiscale",
                    key: "cfPatient",
                    sortable: true,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Data Arruolamento",
                    key: "dataArruolamento",
                    sortable: true,
                    thStyle: "width: 12rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        } else {
                            return "-------";
                        }
                    },
                },
                {
                    label: "Data Fine Arr.",
                    key: "dataFineArruolamento",
                    sortable: true,
                    thStyle: "width: 12rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        } else {
                            return "-------";
                        }
                    },
                },
            ],
        };
    },
    computed: {
        titoloCard() {
            let me = this;
            return "Indicatore: " + me.punto + " " + me.jsonData.codiceFiscaleMedico + " Anno: " + me.jsonData.annoRiferimento;
        },
    },
    mounted() {
        let me = this;
        me.indicatore = this.$route.params.indicatore;
        me.id = this.$route.query.id;
        me.linkback = "/caduceolapmanagement/lapmedici/view/" + me.id;
        console.log(me.id);
        if (me.indicatore !== "-1") {
            let data = me.indicatore.split("_");
            me.jsonData.codiceFiscaleMedico = data[0];
            me.jsonData.annoRiferimento = data[1];
            me.idLap = data[0] + "_" + data[1];
            me.punto = data[2];
            if (me.punto === "1.1") {
                me.loadAssistiti();
            } else {
                me.loadDataIndicatore();
            }
        }
    },
    methods: {
        loadAssistiti() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAssistiti + "/";
            me.filtro.codiceFiscaleMedico = me.jsonData.codiceFiscaleMedico;
            me.filtro.anno = me.jsonData.annoRiferimento;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.rows = response.data.data.length;
                    me.jsonData.listaAssistiti = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadDataIndicatore() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            me.filtro.idLap = me.idLap;
            me.filtro.punto = me.punto;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.rows = response.data.data.recordsNumber;
                    me.jsonData.listaIndicatori = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style></style>
