<template>
    <sa-page-layout :data="jsonData" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                        <b-card class="sa-card" header="Anagrafia Medico" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="12" xl="12">
                                    <label class="sa-label-data">Codice Fiscale</label>
                                    <b-form-input v-model="jsonData.codiceFiscaleMedico"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Cognome</label>
                                    <b-form-input v-model="jsonData.cognome"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Nome</label>
                                    <b-form-input v-model="jsonData.nome"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Codice Regionale</label>
                                    <b-form-input v-model="jsonData.codiceRegionale"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Categoria</label>
                                    <b-form-input v-model="jsonData.categoria"></b-form-input>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                        <b-card class="sa-card" header="Dati Appartenenza" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Distretto</label>
                                    <b-form-select v-model="jsonData.codiceDistretto" :options="distrettiOptions" :value="null" value-field="value" text-field="text" @input="onInputDistretto"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Ambito</label>
                                    <b-form-select v-model="jsonData.codAmbito" :options="ambitiOptions" :value="null" value-field="value" text-field="text" @input="onInputAmbito"></b-form-select>
                                </b-col>
                                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                                    <label class="sa-label-data">Num. Pazienti</label>
                                    <b-form-input v-model="jsonData.numeroPazienti"></b-form-input>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Data Inizio Rapporto</label>
                                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataInizioRapporto" type="date" placeholder="Data Inizio Rapporto"></date-picker>
                                </b-col>
                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">Data Fine Rapporto</label>
                                    <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataFineRapporto" type="date" placeholder="Data Fine Rapporto"></date-picker>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { DatePicker, SaPageLayout },
    data() {
        return {
            pathResource: "/medici",
            linkback: null,
            pathResourceDistretti: "/distretti",
            pathResourceAmbiti: "/ambiti",
            showModalLoading: false,
            file1: null,
            id: null,
            distrettiOptions: [{ value: null, text: "-Seleziona Distretto-" }],
            ambitiOptions: [{ value: null, text: "-Seleziona Ambito-" }],
            comuneOption: [{ value: null, text: "-Seleziona Comune-" }],
            jsonData: { codiceFiscaleMedico: null, cognome: null, nome: null, codiceRegionale: null, categoria: "MAP", codiceDistretto: null, distretto: null, codAmbito: null, ambito: null, dataInizioRapporto: null, dataFineRapporto: null, numeroPazienti: 0 },
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        if (me.id === "-1") {
            me.linkback = "/caduceolapmanagement/medici";
        } else {
            me.linkback = "/caduceolapmanagement/medici/view/" + me.id;
        }
        me.loadDefaultData();
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadDistretti();
            me.loadAmbiti();
            me.loadData();
        },
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    me.loadComune(me.jsonData.provincia);
                    me.loadCap(me.jsonData.localita);
                    me.showModalLoading = false;
                });
            }
        },
        loadDistretti() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceDistretti;
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.distrettiOptions.push({ value: element.codiceDistretto, text: element.distretto });
                });
            });
        },
        loadAmbiti() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAmbiti;
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.ambitiOptions.push({ value: element.codice, text: element.descrizione });
                });
            });
        },
        onInputDistretto(value) {
            let me = this;
            me.jsonData.distretto = "";
            me.distrettiOptions.forEach((element) => {
                if (element.value === value) {
                    me.jsonData.distretto = element.text;
                }
            });
        },
        onInputAmbito(value) {
            let me = this;
            me.jsonData.ambito = "";
            me.ambitiOptions.forEach((element) => {
                if (element.value === value) {
                    me.jsonData.ambito = element.text;
                }
            });
        },
    },
};
</script>

<style></style>
