<template>
  <div v-if="isEdit">
    <b-table sticky-header ref="tblContatti" stacked="xl" striped hover itemscope :items="listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
      <template #cell(index)="listaContatti">
        {{ listaContatti.index + 1 }}
      </template>
      <template #cell(codiceFiscale)="item">
        <b-form-input v-model="item.item.codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
      </template>
      <template #cell(nome)="item">
        <b-form-input v-model="item.item.nome" placeholder="Nome"></b-form-input>
      </template>
      <template #cell(cognome)="item">
        <b-form-input v-model="item.item.cognome" placeholder="Cognome"></b-form-input>
      </template>
      <template #cell(eta)="item">
        <b-form-input v-model="item.item.eta" placeholder="Eta"></b-form-input>
      </template>
      <template #cell(professione)="item">
        <!-- <b-form-select v-model="item.item.professione" :options="professioniOptions" :value="null" value-field="value" text-field="text">
                  <template #first>
                    <b-form-select-option :value="null" disabled>-Seleziona Valore-</b-form-select-option>
                  </template>
                </b-form-select> -->
        <b-form-input v-model="item.item.professione" placeholder="Professione"></b-form-input>
      </template>
      <template #cell(parentela)="item">
        <b-form-select v-model="item.item.parentela" :options="parenteleOptions">
          <template #first>
            <b-form-select-option :value="null" disabled>-Seleziona Valore-</b-form-select-option>
          </template>
        </b-form-select>
      </template>
      <template #cell(actions)="row">
        <b-button v-if="row.index == listaContatti.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddContatti(listaContatti, row.item)">
          <b-icon icon="plus"></b-icon>
        </b-button>
        <b-button v-if="row.index !== listaContatti.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(listaContatti, row)">
          <b-icon icon="trash"></b-icon>
        </b-button>
      </template>
    </b-table>
  </div>
  <div v-else>
    <b-table sticky-header ref="table" stacked="xl" striped hover :items="listaContatti" :fields="fieldsContatti" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
      <template #cell(index)="listaContatti">
        {{ listaContatti.index + 1 }}
      </template>
      <template v-slot:cell(codiceFiscale)="{ item }">
        <a>{{ item.codiceFiscale }}</a>
      </template>
    </b-table>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    listaContatti: {
      type: Array,
      default: function () {
        return null;
      },
    },
    isEdit: Boolean,
  },
  watch: {
    listaContatti: {
      handler(listaContatti) {
        this.$emit("update", listaContatti);
      },
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      linkParentele: "/malattieinfettiveieparentele",
      parenteleOptions: [],
      fieldsContatti: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Codice Fiscale",
          key: "codiceFiscale",
          sortable: false,
        },
        {
          label: "Nome",
          key: "nome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Cognome",
          key: "cognome",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Eta'",
          key: "eta",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Professione",
          key: "professione",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Parentela",
          key: "parentela",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadParentele();
  },
  methods: {
    onAddContatti(array, value) {
      if (value.codiceFiscale !== "" && value.nome !== "" && value.cognome !== "" && value.eta !== "" && value.professione !== null && value.parentela !== null) {
        array.push({ codiceFiscale: "", cognome: "", nome: "", eta: "", professione: null, parentela: null });
      } else {
        this.$bvModal
          .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    loadParentele() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkParentele + "?page=1&forPage=1000";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.parenteleOptions.push({ value: element.descrizione, text: element.descrizione });
        });
      });
    },
  },
};
</script>

<style></style>
