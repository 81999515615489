<template>
    <eventi-list-component :titoloEvento="titoloEvento" :utente="utente" :key="keyListaEventi" :pathEventResource="pathEventResource" :linkEvent="linkEvent" :gruppo="gruppo" :tipoEvento="tipoEvento"></eventi-list-component>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiListComponent from "../../../base/components/EventiListComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
export default {
    mixins: [UtilityMixin, EventiMixin],
    components: { EventiListComponent },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    data() {
        return {
            showModalLoading: false,
            titoloEvento: this.$i18n.t("patients.gastroenterology.ecoendoscopy"),
            linkEvent: "/paziente/gastroenterologia/ecoendoscopia",
            pathEventResource: "/gastroecoendoscopie",
            keyListaEventi: 0,
            gruppo: "GASTROENTEROLOGIA",
            tipoEvento: "ECOENDOSCOPIA",
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        rows() {
            return this.items.length;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    methods: {},
};
</script>
