<template>
  <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Tipo Caso</label>
              <b-form-select v-model="jsonData.tipoCaso" :options="tipiCasiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Data Inizio Sintomi</label>
              <date-picker v-model="jsonData.dataInizioSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Ricovero Ospedaliero</label>
              <b-form-select v-model="jsonData.ricoveroOspedaliero" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputRicoveroOspedaliero"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Nome Ospedale</label>
              <b-form-input v-model="jsonData.nomeOspedale" :disabled="ricoveroOspedalieroDisabled"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Data Ricovero</label>
              <date-picker v-model="jsonData.dataRicovero" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="ricoveroOspedalieroDisabled"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Data Dimissione</label>
              <date-picker v-model="jsonData.dataDimissione" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="ricoveroOspedalieroDisabled"></date-picker>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Protocollo Diagnostico" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Diagnosi Clinica</label>
              <b-form-select v-model="jsonData.diagnosiClinica" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Reazione di Wright</label>
              <b-form-select v-model="jsonData.reazioneWright" :options="siNoOptions" :value="''" value-field="value" text-field="text" @input="onInputReazioneWright"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Data Reazione</label>
              <date-picker v-model="jsonData.dataReazioneWright" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="reazioneWrightDisabled"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Titolo</label>
              <b-form-input v-model="jsonData.titolo" :disabled="reazioneWrightDisabled"></b-form-input>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Ha una stalla o zona dedicata agli animali?</label>
              <b-form-select v-model="jsonData.proprietaStalle" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.animaliProprietaStalle" :fields="fieldsAnimali" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(animale)="item">
                  <b-form-input v-model="item.item.animale"></b-form-input>
                </template>
                <template #cell(actions)="row">
                  <b-button v-if="row.index == jsonData.animaliProprietaStalle.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddProprietaAnimali(jsonData.animaliProprietaStalle, row.item)">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                  <b-button v-if="row.index !== jsonData.animaliProprietaStalle.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.animaliProprietaStalle, row)">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Sono transitati greggi di pecore negli ultimi 20 giorni?</label>
              <b-form-select v-model="jsonData.transitoGreggi" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Esistono Stalle?</label>
              <b-form-select v-model="jsonData.presenzaStalle" :options="siNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <b-table sticky-header stacked="xl" striped hover itemscope :items="jsonData.animaliPresenzaStalle" :fields="fieldsAnimali" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                <template #cell(index)="row">
                  {{ row.index + 1 }}
                </template>
                <template #cell(animale)="item">
                  <b-form-input v-model="item.item.animale"></b-form-input>
                </template>
                <template #cell(actions)="row">
                  <b-button v-if="row.index == jsonData.animaliPresenzaStalle.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPresenzaAnimali(jsonData.animaliPresenzaStalle, row.item)">
                    <b-icon icon="plus"></b-icon>
                  </b-button>
                  <b-button v-if="row.index !== jsonData.animaliPresenzaStalle.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.animaliPresenzaStalle, row)">
                    <b-icon icon="trash"></b-icon>
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="Nei 20 giorni precedenti la comparsa della sintomatologia ha consumato uno dei seguenti alimenti?" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <h4>Nei 20 giorni precedenti la comparsa della sintomatologia ha consumato uno dei seguenti alimenti?</h4>
            </b-col> -->
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Latte Appena Munto</label>
              <b-form-select v-model="jsonData.latte" :options="consumazioniOptions" :value="''" value-field="value" text-field="text" @input="onInputAlimenti(jsonData.latte, 'latteLuogoAcquisto', 'latteLuogoAcquistoDisabled')"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Luogo Acquisto</label>
              <b-form-select v-model="jsonData.latteLuogoAcquisto" :options="luogoAcquistoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.latteLuogoAcquistoDisabled"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Mozzarella</label>
              <b-form-select v-model="jsonData.mozzarella" :options="consumazioniOptions" :value="''" value-field="value" text-field="text" @input="onInputAlimenti(jsonData.mozzarella, 'mozzarellaLuogoAcquisto', 'mozzarellaLuogoAcquistoDisabled')"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Luogo Acquisto</label>
              <b-form-select v-model="jsonData.mozzarellaLuogoAcquisto" :options="luogoAcquistoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.mozzarellaLuogoAcquistoDisabled"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Ricotta di Pecora/Mucca</label>
              <b-form-select v-model="jsonData.ricottaPecoraMucca" :options="consumazioniOptions" :value="''" value-field="value" text-field="text" @input="onInputAlimenti(jsonData.ricottaPecoraMucca, 'ricottaPecoraMuccaLuogoAcquisto', 'ricottaPecoraMuccaLuogoAcquistoDisabled')"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Luogo Acquisto</label>
              <b-form-select v-model="jsonData.ricottaPecoraMuccaLuogoAcquisto" :options="luogoAcquistoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.ricottaPecoraMuccaLuogoAcquistoDisabled"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Formaggi Freschi di Pecora/Mucca</label>
              <b-form-select v-model="jsonData.formaggiFreschi" :options="consumazioniOptions" :value="''" value-field="value" text-field="text" @input="onInputAlimenti(jsonData.formaggiFreschi, 'formaggiFreschiLuogoAcquisto', 'formaggiFreschiLuogoAcquistoDisabled')"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
              <label class="sa-label-data">Luogo Acquisto</label>
              <b-form-select v-model="jsonData.formaggiFreschiLuogoAcquisto" :options="luogoAcquistoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.formaggiFreschiLuogoAcquistoDisabled"></b-form-select>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Carne Cruda/Al Sangue</label>
              <b-form-select v-model="jsonData.carneCruda" :options="consumazioniOptions" :value="''" value-field="value" text-field="text" @input="onInputAlimenti(jsonData.carneCruda, 'carneCrudaLuogoAcquisto', 'carneCrudaLuogoAcquistoDisabled')"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Luogo Acquisto</label>
              <b-form-select v-model="jsonData.carneCrudaLuogoAcquisto" :options="luogoAcquistoOptions" :value="''" value-field="value" text-field="text" :disabled="disabledValue.carneCrudaLuogoAcquistoDisabled"></b-form-select>
            </b-col>
          </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Sanitario Notificante</label>
              <b-form-input v-model="jsonData.sanitarioNotificante"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Numero Cellulare Sanitario Notificante</label>
              <b-form-input v-model="jsonData.contattoSanitarioNotificante"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Data Notifica</label>
              <date-picker v-model="jsonData.dataNotifica" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../paziente/components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
export default {
  components: { SaPageLayout, PazienteAnagrafica, DatePicker },
  mixins: [UtilityMixin],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/malattieinfettiveiebrucellosi",
      pathResourceTipiCasi: "/malattieinfettiveietipicasi",
      pathResourceAnimali: "/malattieinfettiveiebrucellosianimali",
      linkback: "",
      currentPage: 1,
      id: "-1",
      perPage: 100,
      showModalLoading: false,
      ricoveroOspedalieroDisabled: true,
      reazioneWrightDisabled: true,
      filtro: { page: 1, forPage: 100 },
      disabledValue: {
        latteLuogoAcquistoDisabled: true,
        mozzarellaLuogoAcquistoDisabled: true,
        ricottaPecoraMuccaLuogoAcquistoDisabled: true,
        formaggiFreschiLuogoAcquistoDisabled: true,
        carneCrudaLuogoAcquistoDisabled: true,
      },
      jsonData: {
        tipoCaso: "",
        dataInizioSintomi: null,
        ricoveroOspedaliero: "",
        nomeOspedale: "",
        dataRicovero: null,
        dataDimissione: null,
        diagnosiClinica: "",
        reazioneWright: "",
        dataReazioneWright: null,
        proprietaStalle: "",
        animaliProprietaStalle: [],
        animaliPresenzaStalle: [],
        transitoGreggi: "",
        presenzaStalle: "",
        latte: "",
        latteLuogoAcquisto: "",
        mozzarella: "",
        mozzarellaLuogoAcquisto: "",
        ricottaPecoraMucca: "",
        ricottaPecoraMuccaLuogoAcquisto: "",
        formaggiFreschi: "",
        formaggiFreschiLuogoAcquisto: "",
        carneCruda: "",
        carneCrudaLuogoAcquisto: "",
        sanitarioNotificante: "",
        contattoSanitarioNotificante: "",
        dataNotifica: null,
      },
      fieldsAnimali: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Animali",
          key: "animale",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          sortable: false,
          thStyle: "width: 4rem",
        },
      ],
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      tipiCasiOptions: [],
      consumazioniOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "3 VOLTE A SETTIMANA", text: "3 VOLTE A SETTIMANA" },
        { value: "1 VOLTA A SETTIMANA", text: "1 VOLTA A SETTIMANA" },
        { value: "<1 VOLTA A SETTIMANA", text: "<1 VOLTA A SETTIMANA" },
        { value: "MAI", text: "MAI" },
        { value: "NON SO", text: "NON SO" },
      ],
      luogoAcquistoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "PROVA", text: "PROVA" },
        { value: "PROVA 2", text: "PROVA 2" },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.id = this.$route.params.id;
    let malattiaInfettivaSegnalazione = sessionStorage.getItem("MalattiaInfettivaSegnalazione");
    let malattiaInfettiva = {};
    if (malattiaInfettivaSegnalazione !== null && malattiaInfettivaSegnalazione !== "null") {
      malattiaInfettiva = JSON.parse(malattiaInfettivaSegnalazione);
      me.setDatiMalattia(malattiaInfettiva);
    }
    sessionStorage.setItem("MalattiaInfettivaSegnalazione", null);
    if (me.id !== "-1") {
      me.linkback = "/malattieinfettive/indagineepidemiologica/brucellosi/view/" + me.id + "?idPaziente=" + me.idPaziente;
    } else {
      me.linkback = "/malattieinfettive/all";
    }
    me.loadData();
    me.loadTipiCasi();
  },
  watch: {
    "jsonData.proprietaStalle": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.animaliProprietaStalle.push({ animale: "", tipoStalla: "PROPRIETARIO STALLA" });
      } else {
        me.jsonData.animaliProprietaStalle = [];
      }
    },
    "jsonData.presenzaStalle": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.animaliPresenzaStalle.push({ animale: "", tipoStalla: "PRESENZA STALLA NELLE VICINANZE" });
      } else {
        me.jsonData.animaliPresenzaStalle = [];
      }
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.setDatiMedico();
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        me.showModalLoading = true;
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
    loadTipiCasi() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceTipiCasi;
      axios.get(link, { params: me.filtro }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipiCasiOptions.push({ value: element.descrizione, text: element.descrizione });
        });
        me.tipiCasiOptions.unshift({ value: "", text: "-Seleziona Tipo Caso-" });
        me.showModalLoading = false;
      });
    },
    onInputRicoveroOspedaliero(value) {
      let me = this;
      if (value === "SI") {
        me.ricoveroOspedalieroDisabled = false;
      } else {
        me.ricoveroOspedalieroDisabled = true;
        me.jsonData.nomeOspedale = "";
        me.jsonData.dataRicovero = null;
        me.jsonData.dataDimissione = null;
      }
    },
    onInputReazioneWright(value) {
      let me = this;
      if (value === "SI") {
        me.reazioneWrightDisabled = false;
      } else {
        me.reazioneWrightDisabled = true;
        me.jsonData.dataReazioneWright = null;
        me.jsonData.titolo = "";
      }
    },
    onInputAlimenti(alimento, luogoAcquisto, luogoAcquistoDisabled) {
      let me = this;
      if (alimento !== "" && alimento !== "MAI" && alimento !== "NON SO") {
        me.disabledValue[luogoAcquistoDisabled] = false;
      } else {
        me.jsonData[luogoAcquisto] = "";
        me.disabledValue[luogoAcquistoDisabled] = true;
      }
      // me.jsonData[luogoAcquisto] = alimento === "SI" ? "" : "";
      // me.disabledValue[luogoAcquistoDisabled] = alimento === "SI" ? false : true;
    },
    onAddProprietaAnimali(array, value) {
      if (value.animale !== "") {
        array.push({ animale: "", tipoStalla: "PROPRIETARIO STALLA" });
      } else {
        this.$bvModal
          .msgBoxOk("Il Campo Animali è OBBLIGATORIO", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onAddPresenzaAnimali(array, value) {
      if (value.animale !== "") {
        array.push({ animale: "", tipoStalla: "PRESENZA STALLA NELLE VICINANZE" });
      } else {
        this.$bvModal
          .msgBoxOk("Il Campo Animali è OBBLIGATORIO", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = me.utente.firstname + " " + me.utente.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
  },
};
</script>
