<template>
    <div>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblGeneralData')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblWeight") }}</label>
                    <span class="sa-data">{{ jsonData.peso }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeight") }}</label>
                    <span class="sa-data">{{ jsonData.altezza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"> <i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBMI") }}</label>
                    <span class="sa-data">{{ jsonData.bmi }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data ft-color-calculated-fields"><i class="fas fa-calculator"></i> {{ getLabelTraduora("patient.cardiology.lblBSA") }}</label>
                    <span class="sa-data">{{ jsonData.bsa }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPAS") }}</label>
                    <span class="sa-data">{{ jsonData.pas }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFC") }}</label>
                    <span class="sa-data">{{ jsonData.fc }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightLowerLimbSystolic") }}</label>
                    <span class="sa-data">{{ jsonData.pasArtoDx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRightIW") }}</label>
                    <span class="sa-data">{{ jsonData.iwDx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftLowerLimbSystolic") }}</label>

                    <span class="sa-data">{{ jsonData.pasArtoSx }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeftIW") }}</label>
                    <span class="sa-data">{{ jsonData.iwSx }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblLeftLowerLimbECD')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <ecd-arterioso-art-inf-sx-view-component :jsonData="jsonData"></ecd-arterioso-art-inf-sx-view-component>
            </b-row>
        </b-card>

        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblRightLowerLimbECD')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <ecd-arterioso-art-inf-dx-view-component :jsonData="jsonData"></ecd-arterioso-art-inf-dx-view-component>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblReportNotes')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProtocol") }}</label>

                    <span class="sa-data">{{ jsonData.protocollo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVideotape") }}</label>

                    <span class="sa-data">{{ jsonData.videotape }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblCardiovascularRiskFactors')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertension") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.ipertensione)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.ipertensione)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDyslipidemia") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.dislipidemia)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.dislipidemia)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabetes") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.diabete)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diabete)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSmoking") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.fumo)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fumo)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblObesity") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.obesita)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.obesita)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHypertensionFamiliarity") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.familIpertensione)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familIpertensione)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIschemicHeartDiseaseFamiliarity") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.familCardiopatiaIsch)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCardiopatiaIsch)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCardiovascularAccidentsFamiliarity") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.familCerebrovascolari)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.familCerebrovascolari)"></b-icon>
                    </span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAiC75") }}</label>

                    <span class="sa-data">{{ jsonData.aiC75 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPASC") }}</label>

                    <span class="sa-data">{{ jsonData.pasc }}</span>
                </b-col>
                <b-col class="sa-label-data" xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPADC") }}</label>

                    <span class="sa-data">{{ jsonData.padc }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPPC") }}</label>

                    <span class="sa-data">{{ jsonData.ppc }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>

                    <span class="sa-data">{{ jsonData.noteLibere }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblComments") }}</label>

                    <span class="sa-data">{{ jsonData.commento }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExamInTheNorm") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.esameNellaNoma)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.esameNellaNoma)"> </b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSignificantStenosis") }}</label>

                    <span class="sa-data">{{ jsonData.commento1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.stenosiCeSx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCeSx)"> </b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.stenosiCeDx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCeDx)"></b-icon>
                    </span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMildAtherosclerosis") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.aterosclerosiLieve)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiLieve)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSignificantStenosis") }}</label>

                    <span class="sa-data">{{ jsonData.commento2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.stenosiCiSx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCiSx)"> </b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.stenosiCiDx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCiDx)"></b-icon>
                    </span>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblModerateAtherosclerosis") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.aterosclerosiModerata)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aterosclerosiModerata)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSignificantStenosis") }}</label>

                    <span class="sa-data">{{ jsonData.commento3 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblLeft") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.stenosiCcSx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCcSx)"></b-icon>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRight") }}</label>

                    <span class="sa-data" :class="setColor(jsonData.stenosiCcDx)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.stenosiCcDx)"></b-icon>
                    </span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EcdArteriosoArtInfDxViewComponent from "./EcdArteriosoArtInfDxViewComponent.vue";
import EcdArteriosoArtInfSxViewComponent from "./EcdArteriosoArtInfSxViewComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {},
    components: { EcdArteriosoArtInfDxViewComponent, EcdArteriosoArtInfSxViewComponent },
    data() {
        return {
            linkback: null,
            pathResource: "/cardioecodopplerartiinferiori",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            console.log(me.id);
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.checkNonEmoSignOnLoad();
                    me.checkSeveraOnLoad();
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        checkNonEmoSignOnLoad() {
            let me = this;
            if (me.jsonData.aterosclerosiNonEmoSign === "S") {
                me.aterosclerosiNonEmoSign = "true";
            } else {
                me.aterosclerosiNonEmoSign = "false";
            }
        },
        checkSeveraOnLoad() {
            let me = this;
            if (me.jsonData.aterosclerosiSevera === "S") {
                me.aterosclerosiSevera = "true";
            } else {
                me.aterosclerosiSevera = "false";
            }
        },
    },
};
</script>

<style></style>
