<template>
  <sa-page-layout :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <template slot="table-body">
      <scheda-morte-codifica-gemo-component ref="SchedaMorteCodificaGemoComponent" @afterLoadData="onAfterLoadData"></scheda-morte-codifica-gemo-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import SchedaMorteCodificaGemoComponent from "../components/SchedaMorteCodificaGemoComponent.vue";
export default {
  components: { SaPageLayout, SchedaMorteCodificaGemoComponent },
  data() {
    return {
      pathResource: "/rencamserviziicdxconversionegemo",
      showModalLoading: false,
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.$refs.SchedaMorteCodificaGemoComponent.loadData();
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAfterLoadData() {
      let me = this;
      me.showModalLoading = false;
    },
  },
};
</script>

<style></style>
