import { render, staticRenderFns } from "./EsamiLaboratorioEdit.vue?vue&type=template&id=131eed6a&scoped=true"
import script from "./EsamiLaboratorioEdit.vue?vue&type=script&lang=js"
export * from "./EsamiLaboratorioEdit.vue?vue&type=script&lang=js"
import style0 from "./EsamiLaboratorioEdit.vue?vue&type=style&index=0&id=131eed6a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131eed6a",
  null
  
)

export default component.exports