<template>
    <eventi-list-component :utente="utente" :key="keyListaEventi" :titoloEvento="titoloEvento"
        :pathEventResource="pathEventResource" :linkEvent="linkEvent" :tipoEvento="tipoEvento" :gruppo="gruppo">
    </eventi-list-component>
</template>

<script>
import EventiListComponent from "../../../base/components/EventiListComponent.vue";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: {
        EventiListComponent,
    },

    watch: {
        utente: function () {
            this.keyListaEventi++;
        },
    },
    mounted() { },
    data() {
        return {
            titoloEvento: "Ecocolordoppler Arti Superiori",
            pathEventResource: "/cardioecocolordopplerartisuperiori",
            keyListaEventi: 0,
            linkEvent: "/paziente/cardiologia/ecocolordopplerartisuperiori",
            gruppo:"CARDIOLOGIA",
            tipoEvento:"ECOCOLORDOPPLER ARTI SUPERIORI"
        };
    },
};
</script>

<style>

</style>
