<template>
  <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
    <div>
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6"> </b-col>
        <b-col cols="6" xs="6" sm="6" md="6" lg="6" xl="6" class="text-right">
          <b-button v-if="btnNewVisible" size="sm" variant="success" @click="onAdd">Nuovo</b-button>
          <b-button size="sm" variant="info" @click="onRefresh">Refresh</b-button>
        </b-col>
      </b-row>
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(dataOra)="{ item }">
            <router-link class="sa-edit-link" :to="'/adipazienti/richieste/adipai/curedomiciliari/edit/' + item.id + '?idPropostaAccesso=' + idPropostaAccesso + '&fonte=' + fonte"> {{ formatDateTime(item.dataOra) }}</router-link>
          </template>
        </b-table>
      </div>
    </div>
  </b-card>
</template>

<script>
import UtilityMixin from "../../utility/UtilityMixin";
import Vue from "vue";
import axios from "axios";
import moment from "moment";
export default {
  components: {},
  mixins: [UtilityMixin],
  props: {
    idPropostaAccesso: String,
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      filtro: {},
      pathResource: "/adipaicuredomiciliari",
      linkback: "/adipazienti",
      linkedit: "/adipazienti/richieste/adipai/curedomiciliari",
      fonte: "",
      currentPage: 1,
      perPage: 50,
      rows: null,
      showModalLoading: false,
      btnNewVisible: false,
      items: [],
      fields: [
        {
          label: "Data",
          key: "dataOra",
          thStyle: "width: 8rem",
          formatter: (value) => {
            if (value) {
              return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
              return "-----";
            }
          },
          sortable: true,
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.fonte = this.$route.query.fonte;
    me.loadData();
    me.impostaPermessi();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
    idPropostaAccesso() {
      let me = this;
      me.loadData();
    },
  },
  methods: {
    loadData() {
      let me = this;
      if (me.idPropostaAccesso !== "-1") {
        let link = process.env.VUE_APP_PATH_API + me.pathResource;
        me.filtro.forPage = me.perPage;
        me.filtro.page = me.currentPage;
        me.filtro.idPropostaAccesso = me.idPropostaAccesso;
        me.showModalLoading = true;
        axios
          .get(link, { params: me.filtro })
          .then((response) => {
            me.items = [];
            me.rows = response.data.data.recordsNumber;
            me.items = response.data.data.list;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
    impostaPermessi() {
      let me = this;
      me.btnNewVisible = false;
      if (me.fonte === null || me.fonte === undefined || me.fonte === "undefined" || me.fonte === "") {
        me.fonte = "/richieste";
      }
      if (me.fonte === "/richieste" || me.fonte === "/pua") {
        me.btnNewVisible = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "adipaicuredomiciliari", 2);
      }
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onAdd() {
      let me = this;
      me.$router.replace("/adipazienti/richieste/adipai/curedomiciliari/edit/-1?idPropostaAccesso=" + me.idPropostaAccesso + "&fonte=" + me.fonte).catch((err) => {
        err;
      });
    },
  },
};
</script>

<style></style>
