<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">
                        {{ getLabelTraduora("patient.afa.lblRestingHR") }}
                        <eventi-storico-misure-component misura="hr1" misuraLabel="Hr1" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.hr1" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">
                        {{ getLabelTraduora("patient.afa.lblAfterStressHR") }}
                        <eventi-storico-misure-component misura="hr2" misuraLabel="Hr2" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.hr2" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">
                        {{ getLabelTraduora("patient.afa.lblRestingPR") }}
                        <eventi-storico-misure-component misura="pr1" misuraLabel="Pr1" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.pr1" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">
                        {{ getLabelTraduora("patient.afa.lblAfterStressPR") }}
                        <eventi-storico-misure-component misura="pr2" misuraLabel="Pr2" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" v-model="data.pr2" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">
                        {{ getLabelTraduora("patient.afa.lblRestingQT") }}
                        <eventi-storico-misure-component misura="qt1" misuraLabel="Qt1" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.qt1" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">
                        {{ getLabelTraduora("patient.afa.lblAfterStressQT") }}
                        <eventi-storico-misure-component misura="qt2" misuraLabel="Qt2" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.qt2" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">
                        {{ getLabelTraduora("patient.afa.lblRestingQTC") }}
                        <eventi-storico-misure-component misura="qtc_riposo" misuraLabel="qtc Riposo" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.qtcRiposo" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">
                        {{ getLabelTraduora("patient.afa.lblPulsation") }}
                        <eventi-storico-misure-component misura="hr_3" misuraLabel="hr3" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.hr3" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">
                        {{ getLabelTraduora("patient.afa.lblIri") }}
                        <eventi-storico-misure-component misura="iri" misuraLabel="iri" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" v-model="data.iri" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-form-label">{{ getLabelTraduora("patient.afa.lblIriJudgement") }}</label>
                    <b-form-input v-model="data.giudizioIri" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>

            <b-row>
                <b-col>
                    <label class="sa-form-label">{{ getLabelTraduora("patient.afa.lblReport") }}</label>
                    <b-form-textarea rows="4" max-rows="9" v-model="data.referto" :disabled="!edit"></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <label class="sa-form-label">{{ getLabelTraduora("repeatablePrescriptions.lblStatus") }}</label>
                    <br />
                    {{ data.firmaEcg }}
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";

export default {
    mixins: [UtilityMixin],
    components: { EventiStoricoMisureComponent },
    props: {
        tipoEvento: { type: String, require: true },
        gruppo: { type: String, require: true },
        edit: { type: Boolean, require: true },
        data: { type: Object, require: true },
    },
    data() {
        return {};
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.data.idPaziente;
        },
    },
    methods: {
        onClickInfoAndamento(nomeMisura) {
            let me = this;
            me.$emit("loadMisure", nomeMisura);
        },
    },
};
</script>
