<template>
  <sa-page-layout :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :btnSaveVisible="true">
    <template slot="toolbar-title">
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <!-- <template slot="toolbar-row">
      <paziente-dashboard-component :idPaziente="idPaziente"></paziente-dashboard-component>
    </template> -->
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <paziente-allegati-edit-component :jsonData="jsonData" :tipoRisorsa="tipoRisorsa"></paziente-allegati-edit-component>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
// import PazienteDashboardComponent from "../../components/PazienteDashboardComponent.vue";
import PazienteAllegatiEditComponent from "./PazienteAllegatiEditComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { SaPageLayout, PazienteAnagrafica, PazienteAllegatiEditComponent },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient: {
      get: function () {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idRisorsa = me.idPaziente;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.linkback = "/paziente/allegati?idPaziente=" + me.idPaziente;
  },
  data() {
    return {
      pathResource: "/allegati",
      linkback: null,
      tipoRisorsa: "ALLEGATOGENERICO",
      // file1: null,
      jsonData: { file: null, idRisorsa: "", nomeFile: "", size: null, tipo: "", tipoDocumento: "", dataCaricamento: null, descrizione: "", idAnagrafica: "" },
    };
  },
  methods: {
    onDelete() {
      this.$refs["file-input"].reset();
    },
    // handleFile(e) {
    //     let me = this;
    //     console.log(e);
    //     const selectedFile = e.target.files[0];
    //     console.log(selectedFile);
    //     me.jsonData.nomeFile = e.target.files[0].name;
    //     me.jsonData.size = e.target.files[0].size;
    //     me.jsonData.tipo = e.target.files[0].type;
    //     this.createBase64(selectedFile);
    // },
    // createBase64(fileObject) {
    //   let me = this;
    //   const reader = new FileReader();
    //   reader.onload = (e) => {
    //     me.pdf = e.target.result;
    //     me.jsonData.file = me.pdf;
    //   };
    //   reader.readAsDataURL(fileObject);
    // },
  },
  // watch: {
  //   file1: function(e) {
  //     let me = this;
  //     console.log(e);
  //     const selectedFile = e;
  //     me.jsonData.nomeFile = e.name;
  //     me.jsonData.size = e.size;
  //     me.jsonData.tipo = e.type;
  //     this.createBase64(selectedFile);
  //   },
  // },
};
</script>
