<template>
    <div>
        <b-row>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCommonIliacArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.artIliacaComuneDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.artIliacaComuneVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.artIliacaComuneDescDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
                <span class="sa-data">{{ jsonData.ccscoredx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
                <span class="sa-data">{{ jsonData.cccoldx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
                <span class="sa-data">{{ jsonData.maximtnearccdx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
                <span class="sa-data">{{ jsonData.maximtfarccdx }}</span>
            </b-col>
            <!--  -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExternalIliacArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.artIliacaEsternaDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.artIliacaEsternaVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.artIliacaEsternaDescDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col>

            <!---->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInternalIliacArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.artIliacaInternaDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.artIliacaInternaVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.artIliacaInternaDescDx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCommonFemoralArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.artFemoraleComDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.artFemoraleComVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.artFemoraleComDescDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>

                <span class="sa-data">{{ jsonData.bscoredx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>

                <span class="sa-data">{{ jsonData.bcoldx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>

                <span class="sa-data">{{ jsonData.maximtnearbdx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>

                <span class="sa-data">{{ jsonData.maximtfarbdx }}</span>
            </b-col>
            <!--  -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFemoralBifurcation") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.biforcazioneFemoraleDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.biforcazioneFemoraleVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.biforcazioneFemoraleDescDx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeepFemoralArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleProfDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleProfVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleProfDescDx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSuperiorFemoralArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleSupProxDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleSupProxVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleSupProxDescDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>

                <span class="sa-data">{{ jsonData.ciscoredx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>

                <span class="sa-data">{{ jsonData.cecoldx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>

                <span class="sa-data">{{ jsonData.maximtnearcidx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>

                <span class="sa-data">{{ jsonData.maximtfarcidx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFemoralArteryMed") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleSupMedDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleSupMedVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleSupProxDescDx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFemoralArteryDist") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleSupDistDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleSupDistVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleSupMedDescDx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPoplitealArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.arteriaPopliteaDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.arteriaPopliteaVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.arteriaPopliteaDescDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>

                <span class="sa-data">{{ jsonData.cescoredx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>

                <span class="sa-data">{{ jsonData.cccoldx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>

                <span class="sa-data">{{ jsonData.maximtnearcidx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>

                <span class="sa-data">{{ jsonData.maximtfarcidx }}</span>
            </b-col>
            <!------->
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnteriorTibialArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.arteriaTibialeAntDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.arteriaTibialeAntVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.arteriaTibialeAntDescDx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTibioPeronieroTrunk") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.troncoTibioPeronieroDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.troncoTibioPeronieroVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.troncoTibioPeronieroDescDx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPosteriorTibialAtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.artTibialePostDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.artTibialePostVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.artTibialePostDescDx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPeronieraArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.arteriaPeronieraDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.arteriaPeronieraVpsDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.arteriaPeronieraDescDx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data"></label>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            type: Object,
            default: function () {
                return {
                    ccscoredx: null,
                };
            },
        },
    },
    data() {
        return {};
    },
    methods: {
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
    },
};
</script>

<style></style>
