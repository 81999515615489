<template>
  <sa-page-layout :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :showModalLoading="showModalLoading">
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Gestore:</label>
            <b-form-select v-model="filtro.gestore" :options="gestoreOptions" value-field="value" text-field="text" @change="onChangeGestore"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Tipo Firma:</label>
            <b-form-select v-model="filtro.tipoFirma" :options="tipoFirmaOptions" :value="''" value-field="value" text-field="text" :disabled="tipoFirmaDisable"></b-form-select>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Nome File</label>
            <b-form-input v-model="filtro.nomeFile" type="search" id="tipo" placeholder="Nome File"></b-form-input>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Data Dal</label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="date" placeholder="Data Dal"></date-picker>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Data Al</label>
            <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="date" placeholder="Data Al"></date-picker>
          </b-col>
          <b-col xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Stato</label>
            <b-form-select v-model="filtro.stato" :options="statoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-header">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(tipo)="{ item }">
            <router-link class="sa-edit-link" :to="'/graffidilog/view/' + item.id">{{ item.tipo }}</router-link>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout, DatePicker },
  data() {
    return {
      rows: 0,
      perPage: 50,
      currentPage: 1,
      pathResource: "/digitalsignlog",
      filter: null,
      showModalLoading: false,
      items: [],
      fields: [
        {
          label: "Gestore",
          key: "gestore",
          sortable: true,
        },
        {
          label: "Tipo",
          key: "tipo",
          sortable: true,
        },
        {
          label: "Id Riferimento",
          key: "idChiamante",
        },
        {
          label: "Nome File",
          key: "nomeFile",
        },
        {
          label: "Data",
          key: "createDate",
          sortable: true,
          formatter: (value) => {
            return moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
          },
          thStyle: "width: 15rem",
        },
        {
          label: "Stato",
          key: "stato",
          sortable: true,
          thStyle: "width: 25rem",
        },
      ],
      tipoFirmaDisable: true,
      statoOptions: [
        { text: "-Selezionare lo Stato-", value: "" },
        { text: "FIRMATO", value: "FIRMATO" },
        { text: "SIGNED", value: "SIGNED" },
        { text: "ERROR", value: "ERROR" },
        { text: "ERROR POST SIGNED", value: "ERROR POST SIGNED" },
        { text: "SUCCESS  POST SIGNED", value: "SUCCESS  POST SIGNED" },
      ],
      gestoreOptions: [
        { text: "-Selezionare il Gestore-", value: "" },
        { text: "KEFIRMA", value: "KEFIRMA" },
        { text: "ARUBA", value: "ARUBA" },
        { text: "INFOCERT", value: "INFOCERT" },
      ],
      infocertOptions: [
        { text: "-Selezionare la Firma-", value: "" },
        { text: "REMOTA", value: "REMOTA" },
        { text: "AUTOMATICA", value: "AUTOMATICA" },
      ],
      arubaOptions: [
        { text: "-Selezionare la Firma-", value: "" },
        { text: "REMOTA", value: "REMOTA" },
        { text: "AUTOMATICA", value: "AUTOMATICA" },
      ],
      kefirmaOptions: [{ text: "GRAFOMETRICA", value: "GRAFOMETRICA" }],
      tipoFirmaOptions: [{ text: "-Selezionare la Firma-", value: "" }],
      filtro: { stato: "", gestore: "", tipoFirma: "", nomeFile: "", dataDal: null, dataAl: null },
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
    me.loadData();
  },
  methods: {
    onRefresh() {
      let me = this;
      me.loadData();
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      me.showModalLoading = true;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.items = response.data.data.list;
          me.rows = response.data.data.recordsNumber;
          me.changeGestore();
          // me.$refs["SaPageList"].close();
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = {};
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.tipoFirmaOptions = { text: "-Selezionare la Firma-", value: "" };
      me.tipoFirmaDisable = true;
      me.loadData();
    },
    onChangeGestore() {
      let me = this;
      me.filtro.tipoFirma = "";
      me.changeGestore();
    },
    changeGestore() {
      let me = this;
      me.tipoFirmaDisable = false;
      me.tipoFirmaOptions = [];
      switch (me.filtro.gestore) {
        case "ARUBA":
          me.tipoFirmaOptions = me.arubaOptions;
          break;
        case "INFOCERT":
          me.tipoFirmaOptions = me.infocertOptions;
          break;
        case "KEFIRMA":
          me.tipoFirmaOptions = me.kefirmaOptions;
          break;
      }
      if (me.tipoFirmaOptions.length === 1) {
        me.tipoFirmaDisable = true;
        me.filtro.tipoFirma = me.tipoFirmaOptions[0].value;
      }
    },
  },
};
</script>

<style></style>
