<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento">
        <template slot="tab-content-event">
            <esami-strumentali-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></esami-strumentali-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EsamiStrumentaliEditComponent from "../components/EsamiStrumentaliEditComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { EventiEditComponent, EsamiStrumentaliEditComponent },
    data() {
        return {
            linkback: null,
            titoloEvento: "ESAMI STRUMENTALI",
            pathResource: "/cardioesamistrumentali",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            jsonData: {
                dataEvento: null,
                vpsSxIlo: "",
                captoprilDx: "",
                captoprilTot: "",
                vfgsin: "",
                vfgdx: "",
                vfgtot: "",
                noteangiografia: "",
                notescintigrafia: "",
                ecografiaTiroidea: "",
                captoprilSin: "",
                ecorenale: "",
                vpsDxIlo: "",
                irDxIlo: "",
                noteecorenale: "",
                angiografia: "",
                scintigrafiarenale: "",
                scintigrafiaTiroidea: "",
                tacAddome: "",
                tacCranio: "",
                irSxIlo: "",
                irSx: "",
                centroUltimaModifica: "",
                altroesame: "",
                vpsDx: "",
                irDx: "",
                vpsSx: "",
                aortaaddominale: "",
                tacTorace: "",
                altroesame2: "",
                rmnAddome: "",
                rmnCranio: "",
                rmnTorace: "",
                atDx: "",
                irDxIntrapar: "",
                atSx: "",
                irSxIntrapar: "",
                conclusioniEcodoppler: "",
                edvVpsDx: "",
                edvVpsSx: "",
                vpsDxTrattoMedio: "",
                edvVpsDxTrattoMedio: "",
                irDxTrattoMedio: "",
                vpsSxTrattoMedio: "",
                edvVpsSxTrattoMedio: "",
                irSxTrattoMedio: "",
                edvVpsDxIlo: "",
                edvVpsSxIlo: "",
                edvAtDx: "",
                edvAtSx: "",
                /* DA AGGIUNGERE */
                ecografiaTiroideaNote: "",
                scintigrafiaTiroideaNote: "",
                tacCranioNote: "",
                tacToraceNote: "",
                tacAddomeNote: "",
                rmnCranioNote: "",
                rmnToraceNote: "",
                rmnAddomeNote: "",

                psvOrigineDx: "",
                edvOrigineDx: "",
                irOrigineDx: "",
                psvOrigineSx: "",
                edvOrigineSx: "",
                irOrigineSx: "",

                psvDxIntraparenchimale: "",
                edvDxIntraparenchimale: "",
                irDxIntraparenchimale: "",
                atDxIntraparenchimale: "",
                psvSxIntraparenchimale: "",
                edvSxIntraparenchimale: "",
                irSxIntraparenchimale: "",
                atSxIntraparenchimale: "",
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
