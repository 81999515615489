<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime" disabled></date-picker>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.gastro.lblOrigin") }}</label>
                    <b-form-input v-model="jsonData.provenienza"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.ecoendoscopia.lblReasonExam") }}</label>
                    <b-form-textarea v-model="jsonData.motivoEsame" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Descrizione</label>
                    <b-form-input v-model="jsonData.descrizione"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Duodeno</label>
                    <b-form-textarea v-model="jsonData.duodeno" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Digiuno</label>
                    <b-form-textarea v-model="jsonData.digiuno" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
            </b-row>
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Ileo</label>
                    <b-form-textarea v-model="jsonData.ileo" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Varie</label>
                    <b-form-textarea v-model="jsonData.varie" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Diagnosi</label>
                    <b-form-textarea v-model="jsonData.diagnosi" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">Proposta Terapeutica</label>
                    <b-form-textarea v-model="jsonData.propostaTerapeutica" type="text" no-resize rows="8"></b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <eventi-operatori-component :operatori="jsonData.listaOperatori" @update="onUpdateListaOperatori" :isEdit="isEdit"></eventi-operatori-component>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
    components: { DatePicker, EventiOperatoriComponent },
    mixins: [UtilityMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return {
                    dataEvento: null,
                    provenienza: "",
                    motivoEsame: "",
                    descrizione: "",
                    duodeno: "",
                    digiuno: "",
                    ileo: "",
                    varie: "",
                    diagnosi: "",
                    propostaTerapeutica: "",
                };
            },
        },
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
        // me.loadStrumenti();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    data() {
        return {
            isEdit: true,
            pathResource: "/gastroenteroscopia",
            filtro: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
        onUpdateListaOperatori(listaOperatori) {
            let me = this;
            me.jsonData.listaOperatori = listaOperatori;
        },
    },
};
</script>

<style></style>
