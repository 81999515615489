<template>
    <sa-page-layout :btnNewVisible="true" :btnRefreshVisible="true" :showModalLoading="showModalLoading" :linkedit="linkedit" @refresh="onRefresh">
        <template slot="toolbar"></template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Progetto</label>
                        <b-form-select v-model="filtro.progetto" :options="progettiOptions" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Attività</label>
                        <b-form-select v-model="filtro.attivita" :options="tipiOptions" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Stato</label>
                        <b-form-select v-model="filtro.stato" :options="statiOptions" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                    <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Descrizione</label>
                        <b-input-group class="mb-3">
                            <b-form-input v-model="filtro.descrizione" type="search"></b-form-input>
                        </b-input-group>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <span class="sa-data">Tipo</span>
                        <b-form-input v-model="filtro.tipo" type="search"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(malattiaSegnalata)="{ item }">
                        <router-link class="sa-edit-link" :to="'/malattieinfettive/view/' + item.id">
                            {{ item.malattiaSegnalata }}
                        </router-link>
                    </template>

                    <template v-slot:cell(statoAttuale)="{ item }">
                        <strong :class="getClassColorStatoAttuale(item.statoAttuale)">{{ item.statoAttuale }}</strong>
                    </template>
                    <template v-slot:cell(actions)="{ item }">
                        <b-button size="sm" class="no-text" variant="outline-success" @click="onClickView(item)">
                            <b-icon icon="eye"></b-icon>
                        </b-button>
                        <b-button size="sm" class="no-text" variant="outline-secondary" @click="onClickEdit(item)">
                            <b-icon icon="pencil"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("ambulatori.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import SaPageLayout from "../../../../template/layout/components/SaPageLayout.vue";

export default {
    props: {
        utente: { type: Object },
        fonte: String,
    },
    mixins: [UtilityMixin],
    components: { SaPageLayout },
    mounted() {
        let me = this;
        me.linkedit = "/gestioneattivita/attivita";
        me.id = this.$route.params.id;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        me.loadData();
        me.loadProgetti();
        me.loadTipi();
        me.loadStati();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
        utente() {
            // let me = this;
            // me.impostaPermessi();
        },
    },
    data() {
        return {
            id: "-1",
            linkedit: null,
            pathResource: "/gestioneattivita",
            pathResourceProgetti: "/gestioneattivitaprogetti",
            pathResourceTipi: "/gestioneattivitatipi",
            pathResourceStati: "/gestioneattivitastati",
            items: [],
            statiOptions: [],
            progettiOptions: [],
            tipiOptions: [],
            fields: [
                {
                    label: "Prodotto",
                    key: "prodotto",
                    thStyle: "width: 12rem",
                    sortable: true,
                },
                {
                    label: "Progetto",
                    key: "progetto",
                    thStyle: "width: 12rem",
                    sortable: true,
                },
                {
                    label: "Attività",
                    key: "attivita",
                    sortable: true,
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "Data Inizio",
                    key: "dataOraInizio",
                    sortable: true,
                    thStyle: "width: 15rem",
                    formatter: function (value) {
                        return UtilityMixin.methods.formatDateTime(value);
                    },
                },
                {
                    label: "Consegna Prev.",
                    key: "dataOraFine",
                    sortable: true,
                    thStyle: "width: 10rem",
                    formatter: function (value) {
                        return UtilityMixin.methods.formatDate(value);
                    },
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
                {
                    label: "",
                    tdClass: "text-center",
                    thStyle: "width: 8rem",
                    key: "actions",
                },
            ],
            filter: {},
            perPage: 50,
            rows: 0,
            currentPage: 1,
            filtro: {
                progetto: "",
                attivita: "",
                stato: "",
            },

            showModalLoading: false,
        };
    },
    computed: {
        // rows() {
        //     return this.items.length;
        // },
    },
    methods: {
        loadProgetti() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceProgetti + "?forPage=" + me.perPage + "&page=" + me.currentPage;
            me.progettiOptions.push({ text: "Tutti", value: "" });
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.progettiOptions.push({ text: element.nome, value: element.nome });
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadTipi() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceTipi + "?forPage=" + me.perPage + "&page=" + me.currentPage;
            me.tipiOptions.push({ text: "Tutti", value: "" });
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.tipiOptions.push({ text: element.descrizione, value: element.descrizione });
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadStati() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceStati;
            me.statiOptions.push({ text: "Tutti", value: "" });
            axios
                .get(link)
                .then((response) => {
                    response.data.data.list.forEach((element) => {
                        me.statiOptions.push({ text: element.stato, value: element.stato });
                    });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?forPage=" + me.perPage + "&page=" + me.currentPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onClickEdit() {},
        onReset() {},
        onSubmit() {
            let me = this;
            me.loadData();
        },
        getClassColorStatoAttuale(value) {
            let variant = "primary";
            switch (value) {
                case "CHIUSA":
                    variant = "text-success";
                    break;
                case "NON VACCINATO":
                    variant = "text-danger";
                    break;
                case "APERTA":
                    variant = "text-warning";
                    break;
                case "NON ESISTE VACCINO":
                    variant = "text-primary";
                    break;
                default:
                    break;
            }

            return variant;
        },
    },
};
</script>

<style></style>
