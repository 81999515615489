<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDateTime(jsonData.dataEvento) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblTherapiesComplications')" header-tag="header" footer-tag="footer" title="">
            <b-row id="diuretici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.diuretici)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diuretici)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblDiuretics") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoDiuretici }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzadiuretici) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciadiuretici) }}</span>
                </b-col>
            </b-row>
            <b-row id="alfabloccanti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.alfabloccanti)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.alfabloccanti)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblAlphablockers") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAlfabloccanti }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaalfa) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaalfa) }}</span>
                </b-col>
            </b-row>
            <b-row id="betabloccanti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.betabloccanti)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.betabloccanti)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblBetablockers") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoBetabloccanti }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzabeta) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciabeta) }}</span>
                </b-col>
            </b-row>
            <b-row id="alfabetabloccanti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.alfabetabloccanti)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.alfabetabloccanti)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblAlphabetablockers") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAlfabetabloccanti }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaalfabeta) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaalfabeta) }}</span>
                </b-col>
            </b-row>
            <b-row id="aceinibitori">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.aceinibitori)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.aceinibitori)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblACEinhibitors") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAceinibitori }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaace) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaace) }}</span>
                </b-col>
            </b-row>
            <b-row id="at1antagonisti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.at1antagonisti)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.at1antagonisti)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblAT1antagonists") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAt1 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaat1) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaat1) }}</span>
                </b-col>
            </b-row>
            <b-row id="diidropiridinici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.diidropiridinici)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.diidropiridinici)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblDihydropyridines") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoDiidropiridinici }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzadiidropiridinici) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciadiidropiridinici) }}</span>
                </b-col>
            </b-row>
            <b-row id="fenilalchilamine">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.fenilalchilamine)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.fenilalchilamine)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblPhenylalkylamines") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoFenilalchilamine }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzafenilalchilamine) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciafenilalchilamine) }}</span>
                </b-col>
            </b-row>
            <b-row id="benzotiazepine">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.benzotiazepine)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.benzotiazepine)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblBenzothiazepines") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoBenzotiazepine }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzabenzotiazepine) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciabenzotiazepine) }}</span>
                </b-col>
            </b-row>
            <b-row id="nitroderivati">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.nitroderivati)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.nitroderivati)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblNitroderivatives") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoNitroderivati }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzanitroderivati) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficacianitroderivati) }}</span>
                </b-col>
            </b-row>
            <b-row id="antiaggreganti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.antiaggreganti)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antiaggreganti)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblAntiplateletAgents") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoNitroderivati }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzanitroderivati) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficacianitroderivati) }}</span>
                </b-col>
            </b-row>
            <b-row id="digitale">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.digitale)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.digitale)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblDigitalis") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoDigitale }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzadigitale) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciadigitale) }}</span>
                </b-col>
            </b-row>
            <b-row id="antiaritmici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.antiaritmici)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antiaritmici)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblAntiarrhythmics") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAntiaritmici }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaantiaritmici) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaantiaritmici) }}</span>
                </b-col>
            </b-row>
            <b-row id="anticoagulanti">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.anticoagulanti)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.anticoagulanti)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblAnticoagulants") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAnticoagulanti }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaanticoagulanti) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaanticoagulanti) }}</span>
                </b-col>
            </b-row>
            <b-row id="antidiabetici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.antidiabetici)">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antidiabetici)"></b-icon><span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblOralAntidiabetics") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAntidiabetici }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaantidiabetici) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaantidiabetici) }}</span>
                </b-col>
            </b-row>
            <b-row id="Antidislipidemici">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <span :class="setColor(jsonData.antidislipidemici) + 'sa-data'">
                        <b-icon :font-scale="fontScale" :icon="setIcon(jsonData.antidislipidemici)"></b-icon> <span class="sa-data">{{ getLabelTraduora("patient.cardiology.lblAntidyslipidemics") }}</span>
                    </span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAntidislipidemici }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaAntidislipidemici) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaAntidislipidemici) }}</span>
                </b-col>
            </b-row>
            <b-row id="altro">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                    <span class="sa-data">{{ jsonData.altrofarmaco }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAltro }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaaltro) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaaltro) }}</span>
                </b-col>
            </b-row>
            <b-row id="altro2">
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOther") }}</label>
                    <span class="sa-data">{{ jsonData.altrofarmaco2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblType") }}</label>
                    <span class="sa-data">{{ jsonData.tipoAltro2 }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTollerance") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.tolleranzaaltro2) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEfficency") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.efficaciaaltro2) }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblOrganComplications')" header-tag="header" footer-tag="footer" title="">
            <b-row id="vasculo-cerebrale">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCerebralVasculopathy") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.vasculocerebrale) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <span class="sa-data">{{ jsonData.anniVasculocereb }}</span>
                </b-col>
            </b-row>
            <b-row id="vasculo-periferica">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPeripheralVasculopathy") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.vasculoperiferica) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <span class="sa-data">{{ jsonData.anniVasculoperi }}</span>
                </b-col>
            </b-row>
            <b-row id="neuropatia-diabetica">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDiabeticNeuropathy") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.neuropatiaDiabetica) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <span class="sa-data">{{ jsonData.anniNeurodiab }}</span>
                </b-col>
            </b-row>
            <b-row id="retinopatia">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblRetinopathy") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.retinopatia) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <span class="sa-data">{{ jsonData.anniRetinopatia }}</span>
                </b-col>
            </b-row>
            <b-row id="nefropatia">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNefropathy") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.nefropatia) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <span class="sa-data">{{ jsonData.anniNefropatia }}</span>
                </b-col>
            </b-row>
            <b-row id="ipertrofia-ventricolare">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVetricularIpertrophy") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.ipertrofiaVentricolare) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <span class="sa-data">{{ jsonData.anniIpertrofia }}</span>
                </b-col>
            </b-row>
            <b-row id="coronaropatia">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCoronaropathy") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.coronaropatia) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <span class="sa-data">{{ jsonData.anniCoronaro }}</span>
                </b-col>
            </b-row>
            <b-row id="scompenso-cardiaco">
                <b-col xs="12" sm="12" md="10" lg="10" xl="10">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHeartFailure") }}</label>
                    <span class="sa-data">{{ getLabelTraduora(jsonData.scompensoCardiaco) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblHowMuchYears") }}</label>
                    <span class="sa-data">{{ jsonData.anniScompenso }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import axios from "axios";
import UtilityMixins from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixins],
    props: {
        idEvento: { Type: String, require: true },
    },
    components: {},
    data() {
        return {
            pathResource: "/cardioterapiecomplicanze",
            jsonData: {},
            id: null,
            fontScale: 2,
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    me.jsonData = response.data.data;
                    me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                    me.showModalLoading = false;
                });
            }
        },
        setColor(value) {
            let color = value == "true" ? "text-success" : "text-danger";
            return color;
        },
        setIcon(value) {
            let icon = value === "true" ? "check" : "x";
            return icon;
        },
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
    },
};
</script>

<style></style>
