<template>
    <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :data="jsonData" :btnEditVisible="false" :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-card class="sa-card" header="Scheda L.A.P." header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="2" xl="2">
                            <label class="sa-label-data">Codice Fiscale Medico</label>
                            <span class="sa-data">{{ jsonData.codiceFiscaleMedico }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Medico</label>
                            <span class="sa-data">{{ jsonData.cognome }} {{ jsonData.nome }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="2" xl="2">
                            <label class="sa-label-data">Categoria</label>
                            <span class="sa-data">{{ jsonData.categoria }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="2" xl="2">
                            <label class="sa-label-data">Data Inizio Rap.</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataInizioRapporto) }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="2" xl="2">
                            <label class="sa-label-data">Data Fine Rap.</label>
                            <span class="sa-data">{{ formatDate(jsonData.dataFineRapporto) }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Cod. Regionale</label>
                            <span class="sa-data">{{ jsonData.codiceRegionale }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Distretto</label>
                            <span class="sa-data">{{ jsonData.codiceDistretto }}-{{ jsonData.distretto }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Ambito</label>
                            <span class="sa-data">{{ jsonData.codAmbito }}-{{ jsonData.ambito }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Dettaglio" header-tag="header" footer-tag="footer" title="">
                    <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill @input="onTabInput">
                        <b-tab active>
                            <template #title>
                                <div class="sa-tab-title-normal">
                                    <div style="float: left">
                                        <span>L.A.P.</span>
                                    </div>
                                </div>
                            </template>

                            <div class="sa-tab-scroll">
                                <div class="b-table-sticky-header">
                                    <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="lapMedico" :fields="fieldsLapMedico" current-page="1" per-page="50" sort-icon-left head-variant="light" class="sa-b-table">
                                        <template v-slot:cell(index)="row">
                                            {{ row.index + 1 }}
                                        </template>
                                        <template v-slot:cell(codiceFiscaleMedico)="{ item }">
                                            <router-link class="sa-edit-link" :to="'/caduceolapmanagement/lapmedici/view/' + item.id">{{ item.codiceFiscaleMedico }}</router-link>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title>
                                <div class="sa-tab-title-normal">
                                    <div style="float: left">
                                        <span>Registro Invio</span>
                                    </div>
                                </div>
                            </template>
                            <div class="b-table-sticky-header">
                                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="registroInvio" :fields="fieldsRegistroInvio" current-page="1" per-page="10000" sort-icon-left head-variant="light" class="sa-b-table">
                                    <template v-slot:cell(index)="row">
                                        {{ row.index + 1 }}
                                    </template>
                                    <template v-slot:cell(codiceFiscaleMedico)="{ item }">
                                        <router-link class="sa-edit-link" :to="'/caduceolapmanagement/schedamedico/' + item.codiceFiscaleMedico">{{ item.codiceFiscaleMedico }}</router-link>
                                    </template>
                                    <template v-slot:cell(stato)="{ item }">
                                        <a :class="getClassStato(item)">{{ item.stato }}</a>
                                    </template>
                                    <template #cell(actions)="row">
                                        <b-row>
                                            <b-button variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" size="sm" @click="onElabora(row.item)"><i class="bi bi-gear"></i></b-button>
                                        </b-row>
                                    </template>
                                </b-table>
                            </div>
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import moment from "moment";
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    components: { SaPageLayout },
    mixins: [UtilityMixin],
    data() {
        return {
            id: "",
            linkedit: null,
            linkback: null,
            pathResource: "/medici",
            pathResourceLapMedico: "/lap",
            pathResourceRegistroInvioMedico: "/registroinvii",
            showModalLoading: false,
            lapMedico: [],
            registroInvio: [],
            filtroLap: { codiceFiscaleMedico: "", annoRiferimento: "" },
            filtroRegistroInvioMedico: { codiceFiscaleMedico: "" },
            jsonData: {
                id: "-1",
            },
            fieldsRegistroInvio: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Codice Fiscale Medico",
                    key: "codiceFiscaleMedico",
                    sortable: true,
                    thStyle: "width: 15rem",
                },
                {
                    label: "Data Ora",
                    key: "dataOra",
                    sortable: true,
                    thStyle: "width: 12rem",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
                        } else {
                            return "-------";
                        }
                    },
                },
                {
                    label: "Anno",
                    key: "anno",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Stato",
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Log Elaborazione",
                    key: "logElaborazione",
                    sortable: true,
                },
                { key: "actions", label: "", thStyle: "width: 40px" },
            ],
            fieldsLapMedico: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Cognome e Nome",
                    key: "nominativoMedico",
                    sortable: true,
                },
                {
                    label: "Codice Fiscale Medico",
                    key: "codiceFiscaleMedico",
                    sortable: true,
                },
                {
                    label: "Anno",
                    key: "annoRiferimento",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Num. Pazienti",
                    key: "numeroPazienti",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Num. Arruolati",
                    key: "pazientiDiabetici",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Tot. L.A.P.",
                    key: "totaleLap",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkback = "/caduceolapmanagement/medici";
        if (me.id !== "-1") {
            me.loadData();
        }
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadDataLapMedico() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceLapMedico;
            me.filtroLap.page = 1;
            me.filtroLap.forPage = 50;
            me.filtroLap.codiceFiscaleMedico = me.id;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtroLap })
                .then((response) => {
                    me.lapMedico = [];
                    me.lapMedico = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadRegistroInvioMedico() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceRegistroInvioMedico;
            me.filtroRegistroInvioMedico.page = 1;
            me.filtroRegistroInvioMedico.forPage = 1000;
            me.filtroRegistroInvioMedico.codiceFiscaleMedico = me.id;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtroRegistroInvioMedico })
                .then((response) => {
                    me.registroInvio = [];
                    me.registroInvio = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onTabInput(index) {
            let me = this;
            switch (index) {
                case 0:
                    me.loadDataLapMedico();
                    break;
                case 1:
                    me.loadRegistroInvioMedico();
                    break;
            }
        },
        getClassStato(item) {
            // let me = this;
            if (item.stato === "ERROR") {
                return "text-danger";
            } else {
                return "";
            }
        },
    },
};
</script>

<style></style>
