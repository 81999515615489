<template>
    <sa-page-layout :btnNewVisible="btnNewVisible" :btnRefreshVisible="true" @refresh="onRefresh" :pathResource="pathResource" :linkedit="linkedit" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Cognome</label>
                        <b-form-input v-model="filtro.cognome" type="search" id="cognome" placeholder="Cognome"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Nome</label>
                        <b-form-input v-model="filtro.nome" type="search" id="nome" placeholder="Nome"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Fiscale</label>
                        <b-form-input v-model="filtro.codiceFiscale" type="search" id="codiceFiscale" placeholder="Codice Fiscale"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Valutazione</label>
                        <b-form-select v-model="filtro.codiceValutazione" :options="valutazioniOption" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("patients.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" stacked="lg" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(nominativo)="{ item }">
                        <router-link class="sa-edit-link" :to="'/schedavalutazioneesigenzeimmediate/view/' + item.id">{{ item.assistitoCognome }} {{ item.assistitoNome }}</router-link>
                    </template>
                    <template v-slot:cell(statoFirma)="{ item }">
                        <b-button v-if="item.statoFirma == 'FIRMATA'" v-b-tooltip.hover.right="onHoverStatoFirma(item)" variant="outline">
                            <font-awesome-icon icon="signature" class="text-success" />
                        </b-button>
                        <b-button v-else v-b-tooltip.hover.right="onHoverStatoFirma(item)" variant="outline">
                            <font-awesome-icon icon="signature" class="text-danger" />
                        </b-button>
                    </template>
                    <template v-slot:cell(codiceValutazione)="{ item }">
                        <span :class="getCodiceValutazionecolor(item.codiceValutazione)">{{ item.codiceValutazione }}</span>
                    </template>
                    <template #cell(actions)="row">
                        <!-- <b-button variant="outline-secondary no-text" v-if="row.item.statoFirma !== 'FIRMATA'">
                            <b-icon icon="pencil-square"></b-icon>
                        </b-button> -->
                        <b-button v-if="row.item.statoFirma === 'FIRMATA'" size="sm" variant="btn btn-purple waves-effect  waves-light width-sm no-text" style="margin-right: 3px" @click="onPrintPreview(row.item)">
                            <b-icon icon="printer"></b-icon>
                        </b-button>
                        <print-component v-if="btnPrintVisible && row.item.statoFirma !== 'FIRMATA'" :jsonData="row.item" :linkPrintData="linkPrintData(row.item)" typeButton="small" :firmaGrafometrica="true"></print-component>
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("patients.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <pdf-print-view-embedded ref="pdfPrintViewEmbedded" :pdf="signedPdfViewer"></pdf-print-view-embedded>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
// import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PrintComponent from "../../../app/utility/components/PrintComponent.vue";
import PdfPrintViewEmbedded from "../../../app/utility/components/PdfPrintViewEmbedded.vue";
export default {
    components: { SaPageLayout, PrintComponent, PdfPrintViewEmbedded },
    data() {
        return {
            rows: 0,
            perPage: 50,
            currentPage: 1,
            btnNewVisible: true,
            btnPrintVisible: true,
            pathResourceAllegato: "/sveiprinter/preview/",
            linkedit: "/schedavalutazioneesigenzeimmediate",
            pathResource: "/schedavalutazioneesigenzeimmediate",
            pathResourceCodiceValutazione: "/schedavalutazioneesigenzeimmediatecodicevalutazione",
            filter: null,
            signedPdfViewer: null,
            showModalLoading: false,
            valutazioniOption: [],
            items: [],
            fields: [
                {
                    label: "Cod. Scheda",
                    key: "idScheda",
                    sortable: true,
                    thStyle: "width: 6rem",
                },
                {
                    label: "Nominativo",
                    key: "nominativo",
                    sortable: true,
                },
                {
                    label: "Codice Fiscale",
                    key: "assistitoCodiceFiscale",
                    sortable: true,
                },
                {
                    label: "Ultima Modifica",
                    key: "updateDate",
                    sortable: true,
                    thStyle: "width: 15rem",
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY HH:mm:ss");
                    },
                },
                {
                    label: "Data Nascita",
                    key: "assistitoDataNascita",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
                {
                    label: "Cod. Val.",
                    key: "codiceValutazione",
                    sortable: true,
                    tdClass: "text-center",
                    thStyle: "width: 6rem",
                },
                {
                    label: "Firma",
                    key: "statoFirma",
                    sortable: true,
                    tdClass: "text-center",
                    thStyle: "width: 6rem",
                },
                {
                    label: "",
                    key: "actions",
                    tdClass: "text-center",
                    thStyle: "width: 7rem",
                },
            ],
            filtro: { nome: "", cognome: "", codiceFiscale: "", codiceValutazione: "" },
        };
    },
    mounted() {
        let me = this;
        me.loadCodiceValutazione();
        me.loadData();
    },
    methods: {
        linkPrintData(item) {
            let returnValue = "/sveiprinter/" + item.id;
            return returnValue;
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                    // me.$refs["SaPageList"].close();
                    // me.items.forEach((element) => {
                    //           if(element.codiceValutazione === "R"){

                    //           }else if(element.codiceValutazione === "G" || element.codiceValutazione === "GR"){

                    // }
                    // });
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        loadCodiceValutazione() {
            let me = this;
            me.valutazioniOption = [];
            let link = process.env.VUE_APP_PATH_API + this.pathResourceCodiceValutazione;
            axios.get(link).then((response) => {
                response.data.data.list.forEach((element) => {
                    me.valutazioniOption.push({ text: element.descrizione, value: element.codice });
                });
                me.valutazioniOption.unshift({ text: "-Seleziona-", value: "" });
            });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = { nome: "", cognome: "", codiceFiscale: "", codiceValutazione: "" };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        getCodiceValutazionecolor(value) {
            let returnValue = "";
            switch (value) {
                case "R":
                    returnValue = "sa-codice-valurazione-red";
                    break;
                case "V":
                    returnValue = "sa-codice-valurazione-green";
                    break;
                case "G":
                    returnValue = "sa-codice-valurazione-yellow";
                    break;
                case "GT":
                    returnValue = "sa-codice-valurazione-yellow";
                    break;
            }
            return returnValue;
        },
        onHoverStatoFirma(item) {
            let firma = "";
            if (item.statoFirma == "FIRMATA") {
                firma = "SCHEDA FIRMATA";
            } else {
                firma = "SCHEDA NON FIRMATA";
            }
            return firma;
        },
        onPrintPreview(item) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAllegato + item.id;
            me.showModalLoading = true;
            axios
                .get(link)
                .then((response) => {
                    me.signedPdfViewer = response.data.data.file.includes("data:application/pdf;base64,") ? response.data.data.file : "data:application/pdf;base64," + response.data.data.file;
                    //me.$refs.mdlSigendPdfViewer.show();
                    me.$refs.pdfPrintViewEmbedded.show();
                    me.showModalLoading = false;
                })
                .catch((error) => {
                    // me.$refs.mdlGenerazioneReportInCorso.hide();
                    console.log(error);
                    me.showModalLoading = false;
                    let messaggio = error.response.data.messaggio ? error.response.data.messaggio : "Errore non gestito, Codice: " + error.response.status;
                    this.$bvModal
                        .msgBoxOk(messaggio, {
                            title: this.$i18n.t("ERROR!"),
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                });
        },
    },
};
</script>

<style>
.sa-codice-valurazione-red,
.sa-codice-valurazione-green,
.sa-codice-valurazione-yellow {
    height: 24px;
    width: 24px;
    display: inline-block;

    padding-top: 3px;
    border-radius: 13px;
    font-weight: bold;
}
.sa-codice-valurazione-red {
    background-color: red;
    color: white;
}
.sa-codice-valurazione-green {
    background-color: rgb(0, 255, 42);
    color: white;
}
.sa-codice-valurazione-yellow {
    background-color: rgb(255, 230, 0);
    color: black;
}

.vue-pdf-embed > div {
    margin-bottom: 8px;
    box-shadow: 0 2px 8px 4px rgba(0, 0, 0, 0.1);
}
</style>
