<template>
    <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica-leonida :data="jsonDataVisita"></paziente-anagrafica-leonida>
        </template>
        <template slot="table-body">
            <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                <div class="b-table-sticky-header">
                    <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                        <template v-slot:cell(actions)="row">
                            <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-success" v-b-modal.mdlCompletaRichiesta :disabled="isDisabledBtnCompleta(row.item)" @click="completaEsame(row.item, row.index, $event.target)">
                                <b-icon icon="check2" />
                            </b-button>
                            <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-secondary" :disabled="idDisabledBtnPdf(row.item)" @click="onClickPdf(row.item, row.index, $event)" v-b-modal.pdfmodel>
                                <font-awesome-icon size="lg" icon="file-pdf" />
                            </b-button>
                            <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-secondary" v-b-modal.mdlVisualizzaReferto @click="onClickVisualizzaReferto(row.item, row.index, $event)">
                                <font-awesome-icon icon="eye" />
                            </b-button>
                        </template>
                    </b-table>
                </div>
            </b-card>
        </template>
        <template slot="table-footer">
            <b-modal ref="mdlVisualizzaReferto" id="mdlVisualizzaReferto" title="Referto" size="xl">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <strong>Esame </strong><br />
                        {{ rigaRichiesta.tipoEsame }}
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Referto </strong><br />
                        {{ rigaRichiesta.referto }}
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <strong>Note </strong><br />
                        {{ rigaRichiesta.note }}
                    </b-col>
                </b-row>
            </b-modal>
            <b-modal ref="mdlCompletaRichiesta" id="mdlCompletaRichiesta" title="Aggiungi Esame" size="xl" @ok="onOkMdlCompletaRichiesta">
                <div class="sa-form-section">
                    <b-row>
                        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                            <strong>Esame </strong><br />
                            <b-form-input v-model="rigaRichiesta.tipoEsame" readonly></b-form-input>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Referto </strong><br />
                            <b-form-textarea no-resize v-model="rigaRichiesta.referto"></b-form-textarea>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Pdf </strong><br />
                            <b-form-file v-model="file1" :state="Boolean(file1)" placeholder="Trascina un file qui" ref="file-input" class="custom-input" type="file" accept=".pdf"></b-form-file>
                            <div class="mt-3">File Selezionato: {{ file1 ? file1.name : "" }}</div>
                        </b-col>
                        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                            <strong>Note </strong><br />
                            <b-form-textarea no-resize v-model="rigaRichiesta.note"></b-form-textarea>
                        </b-col>
                    </b-row>
                </div>
            </b-modal>
            <pdf-viewer :pdf="pdf"></pdf-viewer>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PdfViewer from "../../utility/components/PdfViewer.vue";
import PazienteAnagraficaLeonida from "./PazienteAnagraficaLeonida.vue";
export default {
    components: { SaPageLayout, PdfViewer, PazienteAnagraficaLeonida },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.idPaziente = this.$route.query.idPaziente;
        me.loadData();
    },
    data() {
        return {
            showModalLoading: false,
            linkback: null,
            id: "",
            pdf: null,
            pathResource: "/visiterichiestaesamisupplementari",
            pathResourceAccertamentiSupplementariDettaglio: "/certificatiesamisupplementari",
            pathResourceVisita: "/visite",
            file1: null,
            btnCompletaDisabled: false,
            jsonData: {
                motivazione: "",
                dataRichiesta: null,
                conclusioni: "",
                idVisita: "",
                visitaEsameSupplementare: [{ id: "", note: "", pdf: null, referto: "", tipoEsame: "", statoAttuale: "", statoAttualeDataOra: null, idRichiesta: "" }],
            },
            jsonDataVisita: {},
            rigaRichiesta: { id: "", note: "", pdf: null, referto: "", tipoEsame: "", statoAttuale: "", statoAttualeDataOra: Date.now(), idRichiesta: "" },
            items: [],
            fields: [
                {
                    label: "Esame",
                    key: "tipoEsame",
                    sortable: true,
                },
                {
                    label: "Stato Attuale",
                    key: "statoAttuale",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Data/Ora richiesta",
                    key: "createDate",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 10rem",
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.jsonData = response.data.data;
                    me.items = response.data.data.visitaEsameSupplementare;
                    me.loadVisita(me.jsonData.idVisita);
                })
                .catch(() => {});
        },
        loadVisita(idVisita) {
            let me = this;
            me.showModalLoading = false;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceVisita + "/" + idVisita;
            axios.get(link).then((response) => {
                me.jsonDataVisita = response.data.data;
                me.showModalLoading = false;
            });
        },
        isDisabledBtnCompleta(row) {
            let returnValue = false;
            if (row.statoAttuale === "COMPLETA") {
                returnValue = true;
            }
            return returnValue;
        },
        idDisabledBtnPdf(row) {
            let returnValue = false;
            if (row.pdf === null) {
                returnValue = true;
            }
            return returnValue;
        },
        onClickVisualizzaReferto(row) {
            let me = this;
            me.rigaRichiesta.tipoEsame = row.tipoEsame;
            me.rigaRichiesta.referto = row.referto;
            me.rigaRichiesta.note = row.note;
        },
        createBase64(fileObject) {
            let me = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                me.pdf = e.target.result;
                me.rigaRichiesta.pdf = me.pdf;
            };
            reader.readAsDataURL(fileObject);
        },
        completaEsame(row) {
            let me = this;
            me.rigaRichiesta.id = row.id;
            me.rigaRichiesta.idRichiesta = row.idRichiesta;
            me.rigaRichiesta.tipoEsame = row.tipoEsame;
            me.rigaRichiesta.referto = row.referto;
            me.rigaRichiesta.pdf = row.pdf;
            me.rigaRichiesta.note = row.note;
        },
        onOkMdlCompletaRichiesta() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceAccertamentiSupplementariDettaglio + "/" + me.rigaRichiesta.id;
            let data = JSON.stringify(me.rigaRichiesta);
            axios
                .put(link, data)
                .then((response) => {
                    me.items.push(response.data.data);
                    me.loadData();
                })
                .catch((error) => {
                    me.$refs["sa-modal-loading"].close();
                    this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblEditTitle")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                });
        },
        onClickPdf(row) {
            let me = this;
            me.pdf = row.pdf;
        },
    },
    watch: {
        file1: function (e) {
            const selectedFile = e;
            this.createBase64(selectedFile);
        },
        jsonData: function (value) {
            let me = this;
            me.jsonData.idVisita = value.idVisita;
            me.linkback = "/worklist/visitamedicosportiva/view/" + me.jsonData.idVisita + "?idPaziente=" + me.idPaziente;
        },
    },
};
</script>
