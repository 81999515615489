<template>
  <sa-page-layout :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="table-body">
      <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Nome</label>
              <b-form-input v-model="jsonData.nome"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Cognome</label>
              <b-form-input v-model="jsonData.cognome"></b-form-input>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
              <label class="sa-label-data">Email</label>
              <b-form-input v-model="jsonData.email"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { SaPageLayout },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/malattieinfettivesegnalazioneemaildestinatari",
      linkback: null,
      showModalLoading: false,
      id: "-1",
      jsonData: {
        nome: "",
        cognome: "",
        email: "",
      },
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkback = "/malattieinfettive/emaildestinatari";

    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        me.showModalLoading = true;
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
  },
};
</script>
