<template>
    <p>Menu Uno</p>
</template>

<script>
export default {
    data() {
        return {};
    },
    created() {
        console.log("Menu Uno");
    },
};
</script>

<style></style>
