<template>
    <sa-page-layout :data="data" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveVisible="true" :linkback="linkback" :linkedit="linkedit" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-header">
            <h3 class="sa-module-title">{{ this.$i18n.t("clinic.lblClinicRegistry") }}</h3>
        </template>
        <template slot="table-body">
            <!-- active-nav-item-class="font-weight-bold text-primary"  -->
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%" fill v-model="tabIndex">
                <b-tab :title="this.getLabelTraduora('patient.ambulatory.DataAmbulatory')" active>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.ambulatory.DataAmbulatory") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <b-row>
                            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblStructure") }}</label>
                                <b-form-select v-model="data.codiceStruttura" class="mb-2 mr-sm-2 mb-sm-0" :options="strutturaOption" :value="null" value-field="codiceStruttura" text-field="denominazione" @input="onInputStruttura"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblBranca") }}</label>
                                <b-form-select v-model="data.codiceBranca" class="mb-2 mr-sm-2 mb-sm-0" :options="brancheOption" :value="null" @change="loadSpecializzazioni(data.codiceBranca)"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblSpecializations") }}</label>
                                <b-form-select v-model="data.specializzazione" class="mb-2 mr-sm-2 mb-sm-0" :options="specializzazioniOption" :value="null"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <label class="sa-label-data">Codice Ambulatorio</label>
                                <b-form-input v-model="data.codiceAmbulatorio" placeholder="Codice Ambulatorio"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblName") }}</label>
                                <b-form-input v-model="data.nome" placeholder="Ambulatorio"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblClinicManager") }}</label>
                                <b-form-input v-model="data.responsabile" placeholder="Responsabile Struttura"></b-form-input>
                            </b-col>
                        </b-row>

                        <h5 class="sa-text-left">{{ getLabelTraduora("clinic.lblClinicInformation") }}</h5>
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblAddress") }}</label>
                                <b-form-input v-model="data.indirizzo" placeholder="Indirizzo"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblStreetNumber") }}</label>
                                <b-form-input v-model="data.numeroCivico" placeholder="Numero Civico"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblProvince") }}</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="data.provincia" class="mb-2 mr-sm-2 mb-sm-0" :options="provinciaOption" :value="null" @change="loadComune(data.provincia)"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblCity") }}</label>
                                <b-form-select id="inline-form-custom-select-pref" v-model="data.comune" class="mb-2 mr-sm-2 mb-sm-0" :options="comuneOption" :value="null" @change="loadCap(data.comune)"></b-form-select>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="6" lg="4" xl="4">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblPostalCode") }}</label>
                                <b-form-input v-model="data.cap" placeholder="CAP"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblAsl") }}</label>
                                <b-form-input v-model="data.asl" placeholder="ASL"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblAslCode") }}</label>
                                <b-form-input v-model="data.codiceAsl" placeholder="Codice ASL"></b-form-input>
                            </b-col>
                        </b-row>

                        <h5 class="sa-text-left">{{ getLabelTraduora("clinic.lblContacts") }}</h5>
                        <b-row>
                            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblTelephoneNumber") }}</label>
                                <b-form-input v-model="data.telefono" placeholder="Telefono"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ getLabelTraduora("clinic.lblEmail") }}</label>
                                <b-form-input v-model="data.email" placeholder="Email"></b-form-input>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
                <b-tab>
                    <template #title>
                        <div class="sa-tab-title-normal">
                            <div style="float: left">
                                <span>{{ getLabelTraduora("patient.ambulatory.Heading") }}</span>
                            </div>
                        </div>
                    </template>
                    <div class="sa-tab-scroll">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ getLabelTraduora("patient.ambulatory.Heading1") }}</label>
                                <b-form-input v-model="data.intestazioneRigaUno"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ getLabelTraduora("patient.ambulatory.Heading2") }}</label>
                                <b-form-input v-model="data.intestazioneRigaDue"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ getLabelTraduora("patient.ambulatory.Heading3") }}</label>
                                <b-form-input v-model="data.intestazioneRigaTre"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                <label class="sa-label-data">{{ getLabelTraduora("patient.ambulatory.Heading4") }}</label>
                                <b-form-input v-model="data.intestazioneRigaQuattro"></b-form-input>
                            </b-col>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label class="sa-label-data">{{ getLabelTraduora("patient.ambulatory.Heading5") }}</label>
                                <b-form-input v-model="data.intestazioneRigaCinque"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col v-for="(element, index) in loghi" :key="index" cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                                <label class="sa-label-data">{{ getLabelTraduora("patient.ambulatory.LogoLeft") }}</label>
                                <b-img :src="getLogoByIndex(index)" fluid alt="Logo non Presente" style="max-heig"></b-img>
                                <div style="display: flex">
                                    <b-form-file ref="file-input" class="mb-2" accept="image/jpeg, image/png, image/gif" @input="onInputFile($event, index)"></b-form-file>
                                    <b-button variant="outline-danger" v-if="getLogoByIndex(index)" style="margin-bottom: 0.5rem" @click="onClkSvuota(index)">Svuota</b-button>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
//import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    components: { SaPageLayout },
    data() {
        return {
            tabIndex: 0,
            file1: null,
            file2: null,
            file3: null,
            pathRest: "/ambulatori",
            pathResource: "/ambulatori",
            linkback: "/ambulatori",
            linkProvince: "/province",
            linkComune: "/comuni/provincia",
            linkComuneByIstat: "/comuni/istat",
            linkStrutture: "/strutture",
            linkBranche: "/branche",
            linkSpecializzazioni: "/branchespecializzazione",
            linkedit: null,
            showModalLoading: false,
            id: "-1",
            profili: [],
            loghi: [0, 1, 2],
            strutturaOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectStructure") }],
            specializzazioniOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectSpecialization") }],
            brancheOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectBranca") }],
            provinciaOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectProvince") }],
            comuneOption: [{ value: null, text: this.$i18n.t("clinic.lblSelectTown") }],
            data: { idStruttura: null, codiceStruttura: null, codiceBranca: null, specializzazione: null, comune: null, provincia: null, cap: null },
        };
    },
    computed: {},
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    methods: {
        onInputFile(e, index) {
            let me = this;
            const reader = new FileReader();
            reader.onload = (e) => {
                switch (index) {
                    case 0:
                        me.data.logoLeft = e.target.result;
                        break;
                    case 1:
                        me.data.logoCenter = e.target.result;
                        break;
                    case 2:
                        me.data.logoRight = e.target.result;
                        break;
                }
            };
            reader.readAsDataURL(e);
        },
        getLogoByIndex(index) {
            let me = this;
            let returnValue = null;
            switch (index) {
                case 0:
                    returnValue = me.data.logoLeft;
                    break;
                case 1:
                    returnValue = me.data.logoCenter;
                    break;
                case 2:
                    returnValue = me.data.logoRight;
                    break;
            }
            return returnValue;
        },
        loadDefaultData() {
            let me = this;
            me.loadProvince();
            me.loadStrutture();
            me.loadBranche();
            me.loadData();
            me.loadSpecializzazioni(me.data.codiceBranca);
        },

        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                me.showModalLoading = true;
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.data = response.data.data;
                    me.loadComune(me.data.provincia);
                    me.loadCap(me.data.comune);
                    me.loadSpecializzazioni(me.data.codiceBranca);
                    me.showModalLoading = false;
                    me.loadComune(me.data.provincia);
                });
            }
        },
        loadProvince() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkProvince;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.provinciaOption.push({ value: element.sigla, text: element.provincia });
                });
            });
        },
        loadComune(sigla) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComune + "/" + sigla;
            axios.get(link).then((response) => {
                me.comuneOption = [];
                response.data.data.forEach((element) => {
                    me.comuneOption.push({ value: element.codiceIstat, text: element.comune });
                });
            });
        },
        loadCap(comune) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkComuneByIstat + "/" + comune;
            axios.get(link, { params: {} }).then((response) => {
                me.data.cap = response.data.data.cap;
            });
        },
        loadStrutture() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkStrutture;
            axios.get(link).then((response) => {
                me.strutturaOption = response.data.data;
                me.strutturaOption.unshift({ codiceStruttura: null, denominazione: this.$i18n.t("clinic.lblSelectStructure") });
                // response.data.data.forEach((element) => {
                //     me.strutturaOption.push({ value: element.codiceStruttura, text: element.denominazione });

                // });
            });
        },
        onInputStruttura(value) {
            let me = this;
            me.strutturaOption.forEach((element) => {
                if (element.codiceStruttura === value) {
                    me.data.idStruttura = element.id;
                }
            });
        },
        loadBranche() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkBranche;
            axios.get(link).then((response) => {
                response.data.data.forEach((element) => {
                    me.brancheOption.push({ value: element.codice, text: element.branca });
                });
            });
        },
        loadSpecializzazioni(codiceBranca) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSpecializzazioni;
            axios.get(link, { params: { codiceStruttura: me.data.codiceStruttura, codiceBranca: codiceBranca } }).then((response) => {
                me.specializzazioniOption = [];
                response.data.data.forEach((element) => {
                    me.specializzazioniOption.push({ value: element.specializzazione, text: element.specializzazione });
                });
            });
        },
        // createBase64(fileObject, logo) {
        //     let me = this;
        //     const reader = new FileReader();
        //     reader.onload = (e) => {
        //         switch (logo) {
        //             case "logoLeft":
        //                 me.data.logoLeft = e.target.result;
        //                 break;
        //             case "logoCenter":
        //                 me.data.logoCenter = e.target.result;
        //                 break;
        //             case "logoRight":
        //                 me.data.logoRight = e.target.result;
        //                 break;

        //             default:
        //                 break;
        //         }
        //     };
        //     reader.readAsDataURL(fileObject);
        // },
        onClkSvuota(index) {
            let me = this;
            switch (index) {
                case 0:
                    me.data.logoLeft = null;
                    break;
                case 1:
                    me.data.logoCenter = null;
                    break;
                case 2:
                    me.data.logoRight = null;
                    break;
            }
        },
    },
};
</script>
