<template>
    <div class="sa-tab-scheda">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="E.C.G A RIPOSO" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-row>
                            <b-col>
                                <label class="sa-label-data">Freq. media</label>
                                <b-form-input placeholder="Frequenza Media" v-model="jsonData.frequenzaMediaRiposo"></b-form-input>
                            </b-col>

                            <b-col>
                                <label class="sa-label-data">PR</label>
                                <b-form-input placeholder="Tratto PR" v-model="jsonData.prRiposo"></b-form-input>
                            </b-col>
                            <b-col>
                                <label class="sa-label-data">QT</label>
                                <b-input-group>
                                    <b-form-input placeholder="Tratto QT" v-model="jsonData.qtRiposo"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="outline-danger" v-b-toggle.collapse-riposo :disabled="btnPdfRiposoDisabled">
                                            <i class="bi bi-file-earmark-pdf"></i>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row class="sa-row-collapse">
                            <b-col>
                                <div>
                                    <b-collapse id="collapse-riposo" class="mt-2">
                                        <b-embed type="iframe" :src="pdfRiposo" allowfullscreen></b-embed>
                                    </b-collapse>
                                </div>
                            </b-col>
                        </b-row>
                        <br />
                        <label class="sa-label-data">Referto</label>
                        <b-form-textarea v-model="jsonData.refertoRiposo" placeholder="Referto Medico" no-resize rows="9"></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="refertoEcgARiposoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onEcgARiposoInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" style="margin-right: 5px" size="sm" @click="onClickAggiungiDescrizione(jsonData.refertoRiposo, linkRefertoEcgARiposo)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(jsonData.refertoRiposo, linkRefertoEcgARiposo, refertoEcgARiposoOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="E.C.G DOPO SFORZO" header-tag="header" footer-tag="footer" title="">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-row>
                            <b-col>
                                <label class="sa-label-data">Freq. media</label>
                                <b-form-input placeholder="Frequenza Media" v-model="jsonData.frequenzaMediaDopoSforzo"></b-form-input>
                            </b-col>

                            <b-col>
                                <label class="sa-label-data">PR</label>
                                <b-form-input v-model="jsonData.prDopoSforzo" placeholder="Tratto PR"></b-form-input>
                            </b-col>
                            <!-- <b-col>
                        <label class="sa-label-data">QT</label>
                        <b-form-input v-model="jsonData.qtDopoSforzo" placeholder="Tratto QT"></b-form-input>
                    </b-col> -->
                            <b-col>
                                <label class="sa-label-data">QT</label>
                                <b-input-group>
                                    <b-form-input placeholder="Tratto QT" v-model="jsonData.qtDopoSforzo"></b-form-input>
                                    <b-input-group-append>
                                        <b-button variant="outline-danger" v-b-toggle.collapse-doposforzo :disabled="btnPdfDopoSforzoDisabled">
                                            <i class="bi bi-file-earmark-pdf"></i>
                                        </b-button>
                                    </b-input-group-append>
                                </b-input-group>
                            </b-col>
                        </b-row>
                        <b-row class="sa-row-collapse">
                            <b-col>
                                <div>
                                    <b-collapse id="collapse-doposforzo" class="mt-2">
                                        <b-embed type="iframe" :src="pdfDopoSforzo" allowfullscreen></b-embed>
                                    </b-collapse>
                                </div>
                            </b-col>
                        </b-row>
                        <br />
                        <label class="sa-label-data">Referto</label>
                        <b-form-textarea placeholder="Referto Medico" v-model="jsonData.refertoDopoSforzo" no-resize rows="9"></b-form-textarea>
                        <b-input-group style="margin-top: 5px">
                            <b-form-select class="mb-2 mr-sm-2 mb-sm-0" :options="refertoEcgDopoSforzoOptions" :value="null" value-field="descrizione" text-field="descrizione" @input="onEcgDopoSforzoInput($event)"></b-form-select>
                            <b-button variant="outline-secondary" style="margin-right: 5px" size="sm" @click="onClickAggiungiDescrizione(jsonData.refertoDopoSforzo, linkRefertoEcgDopoSforzo)"><b-icon icon="plus"></b-icon></b-button>
                            <b-button variant="outline-secondary" size="sm" @click="onDeleteDescrizione(jsonData.refertoDopoSforzo, linkRefertoEcgDopoSforzo, refertoEcgDopoSforzoOptions)"><b-icon icon="trash"></b-icon></b-button>
                        </b-input-group>
                    </b-col>
                </b-card>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                <b-card class="sa-card" header="INDICE RAPIDO IDONEITA'" header-tag="header" footer-tag="footer" title="">
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <b-row>
                            <b-col>
                                <label class="sa-label-data">Dur. eser.(Sec.)</label>
                                <b-form-input placeholder="In secondi" v-model="jsonData.durataEsercizio" @change="onCalcoloIRI(jsonData.durataEsercizio, jsonData.numeroBattiti)"></b-form-input>
                            </b-col>
                            <b-col>
                                <label class="sa-label-data">Num. battiti</label>
                                <b-form-input type="number" placeholder='da 60\" a 90\"' v-model="jsonData.numeroBattiti" @change="onCalcoloIRI(jsonData.durataEsercizio, jsonData.numeroBattiti)"></b-form-input>
                            </b-col>
                            <b-col>
                                <label class="sa-label-data">I.R.I.</label>
                                <b-form-input v-model="jsonData.iri" disabled></b-form-input>
                            </b-col>
                        </b-row>
                        <br />
                        <label class="sa-form-label">N.B.: I parametri "durata esercizio" e "numero battiti" consentono di calcolare automaticamente il valore relativo all'indice.</label>
                    </b-col>
                </b-card>
            </b-col>
        </b-row>
        <div class="sa-tab-scheda-row-fill" header="" header-tag="header" footer-tag="footer" title="">
            <b-row style="height: 100%">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                        <div style="height: 100%; display: inline-flex; flex-direction: column; width: 100%">
                            <div class="sa-tab-scheda-row-fill-table-content">
                                <cardioline-richieste-list-component :idRisorsa="idRisorsa" @updateBase64="onUpdateBase64"></cardioline-richieste-list-component>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <!-- <div>
            <b-table striped hover :items="items"></b-table>
        </div> -->
    </div>
</template>
<script>
import axios from "axios";
import CardiolineRichiesteListComponent from "../../integrazioni/cardioline/components/CardiolineRichiesteListComponent.vue";
export default {
    props: {
        idRisorsa: {
            type: String,
            require: true,
        },
        jsonData: {
            Type: Object,
            default: function () {
                return {
                    frequenzaMediaRiposo: null,
                    prRiposo: null,
                    qtRiposo: null,
                    refertoRiposo: "",
                    frequenzaMediaDopoSforzo: null,
                    prDopoSforzo: null,
                    qtDopoSforzo: null,
                    refertoDopoSforzo: "",
                    durataEsercizio: null,
                    numeroBattiti: null,
                    iri: null,
                };
            },
        },
    },
    components: { CardiolineRichiesteListComponent },
    data() {
        return {
            linkRefertoEcgARiposo: "/visiteecgariposo",
            linkRefertoEcgDopoSforzo: "/visiteecgdoposforzo",
            refertoEcgARiposoOptions: [],
            refertoEcgDopoSforzoOptions: [],
            btnPdfRiposoDisabled: true,
            btnPdfDopoSforzoDisabled: true,
            pdfRiposo: null,
            pdfDopoSforzo: null,
            items: [
                {
                    Riposo: " ",
                    DuranteEsercizio: "",
                    DopoSforzo: " ",
                    "I.R.I": " ",
                },
            ],
        };
    },
    computed: {},
    updated() {},
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadDefaultData();
    },
    watch: {
        pdfRiposo() {
            let me = this;
            if (me.pdfRiposo) {
                me.btnPdfRiposoDisabled = false;
            }
        },
        pdfDopoSforzo() {
            let me = this;
            if (me.pdfRiposo) {
                me.btnPdfDopoSforzoDisabled = false;
            }
        },
    },
    methods: {
        loadDefaultData() {
            let me = this;
            me.loadEcgARiposo();
            me.loadEcgDopoSforzo();
        },
        onCalcoloIRI(durata, battiti) {
            let calcolo = (durata * 100) / (5.5 * battiti);
            this.jsonData.iri = Math.round(calcolo);
        },
        loadEcgARiposo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRefertoEcgARiposo;
            axios.get(link).then((response) => {
                me.refertoEcgARiposoOptions = response.data.data;
            });
        },
        loadEcgDopoSforzo() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkRefertoEcgDopoSforzo;
            axios.get(link).then((response) => {
                me.refertoEcgDopoSforzoOptions = response.data.data;
            });
        },
        onEcgARiposoInput(value) {
            let me = this;
            me.jsonData.refertoRiposo = value;
        },
        onEcgDopoSforzoInput(value) {
            let me = this;
            me.jsonData.refertoDopoSforzo = value;
        },
        onClickAggiungiDescrizione(descrizione, path) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + path;
            let jsonData = { descrizione: descrizione };
            axios
                .post(link, JSON.stringify(jsonData))
                .then((response) => {
                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                        title: "",
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                    me.loadDefaultData();
                })
                .catch((e) => {
                    // console.log(e);
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },
        onDeleteDescrizione(descrizione, path, array) {
            let me = this;
            this.$bvModal
                .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
                    title: "Conferma Cancellazione",
                    size: "sm",
                    buttonSize: "sm",
                    okVariant: "secondary",
                    cancelVariant: "danger",
                    okTitle: "SI",
                    cancelTitle: "NO",
                    footerClass: "p-2",
                    hideHeaderClose: false,
                    centered: true,
                })
                .then((value) => {
                    if (value) {
                        let link = process.env.VUE_APP_PATH_API + path;
                        array.forEach((element) => {
                            if (element.descrizione === descrizione) {
                                axios.delete(link + "/" + element.id).then((response) => {
                                    this.$bvModal.msgBoxOk(response.data.messaggio, {
                                        title: "",
                                        size: "sm",
                                        okVariant: "outline-danger",
                                        headerClass: "sa-msg-header-danger",
                                        footerClass: "p-2 border-top-0",
                                        centered: true,
                                    });
                                    me.loadDefaultData();
                                });
                            } else if (descrizione === null || descrizione === "") {
                                this.$bvModal.msgBoxOk("Nessun elemento da cancellare.", {
                                    title: "Attenzione!",
                                    Text: "Nessun valore da cancellare!",
                                    size: "sm",
                                    okVariant: "outline-danger",
                                    headerClass: "sa-msg-header-danger",
                                    footerClass: "p-2 border-top-0",
                                    centered: true,
                                });
                            }
                        });
                    }
                })
                .catch((e) => {
                    this.$bvModal
                        .msgBoxOk(e.response.data.messaggio, {
                            title: "ATTENZIONE",
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        })
                        .then((value) => {
                            this.boxTwo = value;
                        });
                });
        },
        onUpdateBase64(data) {
            if (data.tipoEsame === "RIPOSO") {
                this.pdfRiposo = data.base64 ? "data:application/pdf;base64," + data.base64 : null;
            }
            if (data.tipoEsame === "DOPO SFORZO") {
                this.pdfDopoSforzo = data.base64 ? "data:application/pdf;base64," + data.base64 : null;
            }
            // console.log(data);
        },
    },
};
</script>
