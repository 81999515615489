<template>
    <div v-if="isEdit">
        <b-table sticky-header stacked="md" striped hover itemscope :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
                {{ row.index + 1 }}
            </template>
            <template #cell(pressioneSistolica)="item">
                <b-form-input v-model="item.item.pressioneSistolica" maxlength="3" @focusout="onFocusoutPressione" v-on:keydown.enter="onKeyPressEnter"></b-form-input>
            </template>
            <template #cell(pressioneDiastolica)="item">
                <b-form-input v-model="item.item.pressioneDiastolica" maxlength="3" @focusout="onFocusoutPressione" v-on:keydown.enter="onKeyPressEnter"></b-form-input>
            </template>
            <template #cell(frequenzaCardiaca)="item">
                <b-form-input v-model="item.item.frequenzaCardiaca" @focusout="onFocusoutPressione" v-on:keydown.enter="onKeyPressEnter"></b-form-input>
            </template>
            <!-- <template #cell(tipoMisurazione)="item">
                <b-form-input v-model="item.item.tipoMisurazione" disabled></b-form-input>
            </template> -->
            <!-- <template #cell(actions)="row">
                <b-button v-if="row.index == items.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddPressione(items, row.item)">
                    <b-icon icon="plus"></b-icon>
                </b-button>
                <b-button v-if="row.index !== items.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(items, row)">
                    <b-icon icon="trash"></b-icon>
                </b-button>
            </template> -->
        </b-table>
    </div>
    <div v-else>
        <b-table sticky-header stacked="xl" striped hover itemscope :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
            <template #cell(index)="row">
                {{ row.index + 1 }}
            </template>
        </b-table>
    </div>
</template>
<script>
export default {
    props: {
        isEdit: Boolean,
        tipoMisurazione: String,
        items: {
            type: Array[Object],
            default: function () {
                return {
                    pressioneSistolica: "",
                    pressioneDiastolica: "",
                    frequenzaCardiaca: "",
                    tipoMisurazione: this.tipoMisurazione,
                };
            },
        },
    },
    mounted() {
        let me = this;
        while (me.items.length < 3) {
            me.items.push({ pressioneSistolica: "0", pressioneDiastolica: "0", frequenzaCardiaca: "0", tipoMisurazione: this.tipoMisurazione });
        }
    },
    data() {
        return {
            currentPage: 1,
            perPage: 100,
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    // thStyle: "width: 2rem",
                },
                {
                    label: "Sistolica",
                    key: "pressioneSistolica",
                    sortable: false,
                },
                {
                    label: "Diastolica",
                    key: "pressioneDiastolica",
                    sortable: false,
                },
                {
                    label: "Frequenza Cardiaca",
                    key: "frequenzaCardiaca",
                    sortable: false,
                },
                // {
                //     label: "",
                //     key: "actions",
                //     sortable: false,
                //     // thStyle: "width: 4rem",
                // },
            ],
        };
    },

    methods: {
        // onAddPressione(array, value) {
        //     let me = this;
        //     if (value.pressioneSistolica !== "" && value.pressioneDiastolica !== "") {
        //         if (array.length < 4) {
        //             array.push({ pressioneSistolica: "", pressioneDiastolica: "", tipoMisurazione: me.tipoMisurazione });
        //         }
        //     } else {
        //         this.$bvModal
        //             .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
        //                 title: "ATTENZIONE",
        //                 size: "sm",
        //                 okVariant: "outline-danger",
        //                 headerClass: "sa-msg-header-danger",
        //                 footerClass: "p-2 border-top-0",
        //                 centered: true,
        //             })
        //             .then((value) => {
        //                 console.log(value);
        //             })
        //             .catch((err) => {
        //                 console.log(err);
        //             });
        //     }
        // },
        // onDelete(array, row) {
        //     if (row.index != array.length) {
        //         this.$bvModal
        //             .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
        //                 title: "Titolo",
        //                 buttonSize: "sm",
        //                 okVariant: "secondary",
        //                 cancelVariant: "danger",
        //                 okTitle: "SI",
        //                 cancelTitle: "NO",
        //                 footerClass: "p-2",
        //                 hideHeaderClose: false,
        //                 centered: true,
        //             })
        //             .then((value) => {
        //                 if (value) {
        //                     array.splice(row.index, 1);
        //                 }
        //             })
        //             .catch((err) => {
        //                 console.log(err);
        //             });
        //     }
        // },
        onKeyPressEnter() {
            let me = this;
            me.updateData();
        },
        onFocusoutPressione() {
            let me = this;
            me.updateData();
        },
        updateData() {
            let me = this;
            me.$emit("update", me.items);
        },
    },
};
</script>
