<template>
    <sa-page-layout :btnAnnullaVisible="true" :linkback="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
        <template slot="toolbar">
            <b-button variant="success btn-toolbar sa-margin-right float-sm-right" size="sm" @click="onSave"><b-icon icon="check2"></b-icon>{{ this.$i18n.t("global.lblBtnSave") }}</b-button>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">{{ this.$i18n.t("agendas.lblTitle") }}</label>
                                    <b-form-input v-model="jsonData.titolo" :placeholder="this.$i18n.t('agenda.lblEnterTitle')"></b-form-input>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                                    <label class="sa-label-data">{{ this.$i18n.t("agenda.lblAgendaIdentifier") }}</label>
                                    <b-form-input v-model="jsonData.identificativo" :placeholder="this.$i18n.t('agenda.lblEnterIdentifier')"></b-form-input>
                                </b-col>
                            </b-row>
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                                    <label class="sa-label-data">{{ this.$i18n.t("agenda.lblDurationAppointment") }}</label>
                                    <b-form-input v-model="jsonData.durataAppuntamento"></b-form-input>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5">
                                    <label class="sa-label-data">{{ this.$i18n.t("patient.agenda.lblProprietario") }}</label>
                                    <b-form-input v-model="jsonData.proprietario" :placeholder="this.$i18n.t('agenda.lblEnterOwner')"></b-form-input>
                                </b-col>
                                <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
                                    <label class="sa-label-data">{{ this.$i18n.t("agenda.lblExtraTime") }}</label>
                                    <b-form-checkbox v-model="jsonData.extraTime" name="check-button" switch></b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-card class="sa-card" header="DESCRIZIONE AGENDA" header-tag="header" footer-tag="footer" title="">
                            <b-row>
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <label class="sa-label-data">Descrizione</label>
                                    <b-form-textarea class="form-control" v-model="jsonData.descrizione" rows="5" no-resize :placeholder="this.$i18n.t('agenda.lblEnterDescription')"></b-form-textarea>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-card class="sa-card" header="INTERVALLI" header-tag="header" footer-tag="footer" title="">
                            <b-row :hidden="this.id === '-1'">
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <b-table sticky-header ref="table" id="tab" responsive selectable select-mode="single" @row-selected="onRowSelected" stacked="lg" striped hover :items="jsonData.parametri" :fields="fieldsDay" sort-icon-left head-variant="light" class="sa-b-table">
                                        <template #cell(selected)="{ rowSelected }">
                                            <template v-if="rowSelected">
                                                <span aria-hidden="true">&check;</span>
                                                <span class="sr-only">Selected</span>
                                            </template>
                                            <template v-else>
                                                <span aria-hidden="true">&nbsp;</span>
                                                <span class="sr-only">Not selected</span>
                                            </template>
                                        </template>
                                        <template v-slot:cell(actions)="row">
                                            <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-danger" :disabled="isDefault" @click="onDeleteIntervallo(row.item, row.index, $event.target)"><b-icon icon="trash" variant="outline-primary" /></b-button>
                                        </template>
                                    </b-table>
                                    <div class="button-list">
                                        <button class="btn btn-primary sa-base-color waves-effect waves-light" data-toggle="modal" data-target=".bs-example-modal-center" v-b-modal.aggiungiData><i class="fas fa-calendar-plus mr-1"></i> {{ this.$i18n.t("global.lblAdd") }}</button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-card class="sa-card" header="RIPARTIZIONE INTERVALLI" header-tag="header" footer-tag="footer" title="">
                            <b-row :hidden="this.id === '-1'">
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <b-table sticky-header ref="table" select-mode="single" id="tab" responsive stacked="lg" striped hover :items="intervalli" :fields="fieldsRipartizioni" sort-icon-left head-variant="light" class="sa-b-table">
                                        <template #cell(selected)="{ rowSelected }">
                                            <template v-if="rowSelected">
                                                <span aria-hidden="true">&check;</span>
                                                <span class="sr-only">Selected</span>
                                            </template>
                                            <template v-else>
                                                <span aria-hidden="true">&nbsp;</span>
                                                <span class="sr-only">Not selected</span>
                                            </template>
                                        </template>
                                        <template v-slot:cell(actions)="row">
                                            <b-button size="sm" class="no-text" variant="outline-danger" @click="onDeleteRipartizioneIntervallo(row.item, row.index, $event.target)"><b-icon icon="trash" variant="outline-primary" /></b-button>
                                        </template>
                                    </b-table>
                                    <div class="button-list">
                                        <button class="btn btn-primary sa-base-color waves-effect waves-light" data-toggle="modal" data-target=".bs-example-modal-center" v-b-modal.ripartizioniData><i class="fas fa-calendar-plus mr-1"></i> {{ this.$i18n.t("global.lblAdd") }}</button>
                                    </div>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-card class="sa-card" header="ARCHIVIO PRESTAZIONI" header-tag="header" footer-tag="footer" title="">
                            <b-row :hidden="this.id === '-1'">
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <b-form @submit.prevent="onSubmit">
                                        <b-input-group>
                                            <b-form-input v-model="filtro.codicePrestazione" :placeholder="this.$i18n.t('patient.agenda.lblPerformanceCode')"></b-form-input>
                                            <b-input-group-append>
                                                <b-button type="submit" class="btn-primary sa-base-color" @click="onSubmit" variant="info"><b-icon icon="search"></b-icon></b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </b-form>
                                    <div style="height: 10px" />
                                    <div class="table-responsive">
                                        <b-table sticky-header :per-page="perPage" :current-page="currentPage" ref="tablePerformance" id="tbl" responsive stacked="lg" striped hover :items="allPrestazioni" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
                                            <template v-slot:cell(actions)="row">
                                                <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-info" @click="onAssociaPrestazione(row.item, row.index, $event.target)" :disabled="exist">
                                                    <b-icon icon="box-arrow-right" variant="outline-primary" />
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </div>
                                    <b-col>
                                        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                                            <b-col lg="3">
                                                <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                                            </b-col>
                                            <b-col lg="9">
                                                <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <b-card class="sa-card" header="PRESTAZIONI DEL MEDICO" header-tag="header" footer-tag="footer" title="">
                            <b-row :hidden="this.id === '-1'">
                                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                    <b-button block variant="info" class="btn-primary sa-base-color" @click="showModalNuovaPrestazione = true">{{ this.$i18n.t("agenda.lblNewPerformance") }} <b-icon icon="plus"></b-icon></b-button>
                                    <div style="height: 10px" />
                                    <div class="table-responsive">
                                        <b-table sticky-header ref="tablePerformanceDoctor" id="tbl" :per-page="perPagePM" :current-page="currentPagePM" responsive stacked="lg" striped hover :items="jsonData.prestazioni" :fields="fieldsPrestazioniMedico" sort-icon-left head-variant="light" class="sa-b-table">
                                            <template v-slot:cell(actions)="row">
                                                <b-button size="sm" class="mr-1 ml-1 no-text" variant="outline-danger" @click="onDissociaPrestazione(row.item, row.index, $event.target)">
                                                    <b-icon icon="trash" variant="outline-danger" />
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </div>
                                    <b-col>
                                        <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                                            <b-col lg="3">
                                                <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rowsPM }}</p>
                                            </b-col>
                                            <b-col lg="9">
                                                <b-pagination v-model="currentPagePM" :total-rows="rowsPM" :per-page="perPagePM" align="right" size="sm"></b-pagination>
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                </b-col>
                            </b-row>
                        </b-card>
                    </b-col>
                </b-row>
                <template slot="table-footer">
                    <!-- MODAL AGGIUNGI DATA -->
                    <b-modal class="modal-content" id="aggiungiData" v-model="modalShow" :title="this.$i18n.t('patient.agenda.lblIntervalli')" hide-footer no-close-on-backdrop no-close-on-select>
                        <agenda-modal-nuova-data :jsonData="editParametri"></agenda-modal-nuova-data>
                        <hr />
                        <b-row>
                            <div class="sa-padding-right text-right col">
                                <b-button type="button" variant="secondary" @click="onCancel">{{ this.$i18n.t("global.lblBtnCancel") }}</b-button>
                                <b-button type="button" variant="primary" class="btn-primary sa-base-color" @click="onAddParametro">{{ this.$i18n.t("global.lblAdd") }}</b-button>
                            </div>
                        </b-row>
                    </b-modal>
                    <!-- MODAL RIPARTIZIONE AGENDA -->
                    <b-modal hide-footer class="modal-content" id="ripartizioniData" v-model="modalRipartizioniShow" :title="this.$i18n.t('patient.agenda.lblBreakdownofIntervals')" no-close-on-backdrop no-close-on-select>
                        <agenda-modal-nuova-ripartizione :jsonData="objectIntervalli"></agenda-modal-nuova-ripartizione>
                        <hr />
                        <b-row>
                            <div class="sa-padding-right text-right col">
                                <b-button type="button" variant="secondary" @click="onCancel">{{ this.$i18n.t("global.lblBtnCancel") }}</b-button>
                                <b-button type="button" variant="primary" class="btn-primary sa-base-color" @click="onAddRipartizioni">{{ this.$i18n.t("global.lblAdd") }}</b-button>
                            </div>
                        </b-row>
                    </b-modal>
                    <!-- MODAL NUOVA PRESTAZIONE -->
                    <b-modal ref="modal-new-prestazione" v-model="showModalNuovaPrestazione" no-close-on-backdrop no-close-on-select title="Nuova Prestazione" hide-footer>
                        <agenda-modal-nuova-prestazione :jsonData="editPrestazione"></agenda-modal-nuova-prestazione>
                        <hr />
                        <b-row>
                            <div class="sa-padding-right text-right col">
                                <b-button type="button" variant="secondary" @click="onCancel">{{ this.$i18n.t("global.lblBtnCancel") }}</b-button>
                                <b-button type="button" variant="primary" class="btn-primary sa-base-color" @click="onAggiungiNewPrestazione">{{ this.$i18n.t("global.lblAdd") }}</b-button>
                            </div>
                        </b-row>
                    </b-modal>
                </template>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import moment from "moment";
import AgendaModalNuovaPrestazione from "../components/AgendaModalNuovaPrestazione.vue";
import AgendaModalNuovaRipartizione from "../components/AgendaModalNuovaRipartizione.vue";
import AgendaModalNuovaData from "../components/AgendaModalNuovaData.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";

export default {
    mixins: [UtilityMixin],
    components: { SaPageLayout, AgendaModalNuovaPrestazione, AgendaModalNuovaRipartizione, AgendaModalNuovaData },
    data() {
        return {
            linkedit: "",
            showModalLoading: false,
            isDefault: false,
            pathRestPrestazioni: "/agendaprestazioni",
            pathResource: "/agende",
            pathNomenclatore: "/nomenclatore",
            linkback: "/agende",
            jsonData: {},
            editPrestazione: { codicePrestazione: null, descrizionePrestazione: null, costo: null, durata: null },
            prestazioni: [],
            perPage: 5,
            perPagePM: 5,
            currentPage: 1,
            currentPagePM: 1,
            exist: false,
            allPrestazioni: [],
            intervalli: [],
            parametri: [],
            showModalNuovaPrestazione: false,
            filtro: {
                descrizionePrestazione: "",
                codicePrestazione: "",
                codicePrestazioneAgg: null,
            },
            fields: [
                {
                    label: this.$i18n.t("agenda.lblCode"),
                    key: "codicePrestazione",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("agendas.lblDescription"),
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 6rem",
                },
            ],
            fieldsPrestazioniMedico: [
                { label: this.$i18n.t("agenda.lblCode"), key: "codicePrestazione", sortable: true },
                {
                    label: this.$i18n.t("agendas.lblDescription"),
                    key: "descrizionePrestazione",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patient.agenda.lblCost"),
                    key: "costo",
                    sortable: false,
                },
                {
                    label: this.$i18n.t("agenda.lblDuration"),
                    key: "durata",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 6rem",
                },
            ],
            fieldsDay: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 2.3rem",
                },
                {
                    label: this.$i18n.t("agenda.lblInterval"),
                    key: "giorno",
                    sortable: false,
                    size: "sm",
                    formatter: (value) => {
                        if (value === "default" || value.length === 1) {
                            return value === "1"
                                ? this.$i18n.t("agenda.lblSunday")
                                : value === "2"
                                ? this.$i18n.t("agenda.lblMonday")
                                : value === "3"
                                ? this.$i18n.t("agenda.lblTuesday")
                                : value === "4"
                                ? this.$i18n.t("agenda.lblWednesday")
                                : value === "5"
                                ? this.$i18n.t("agenda.lblThursday")
                                : value === "6"
                                ? this.$i18n.t("agenda.lblFriday")
                                : value === "7"
                                ? this.$i18n.t("agenda.lblSaturday")
                                : "default";
                        } else {
                            return value ? moment(new Date(value)).format("DD-MM-YYYY") : "";
                        }
                    },
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 6rem",
                },
            ],
            fieldsRipartizioni: [
                {
                    label: this.$i18n.t("agenda.lblFrom"),
                    key: "dalleOre",
                },
                {
                    label: this.$i18n.t("agenda.lblTo"),
                    key: "alleOre",
                },
                {
                    label: this.$i18n.t("patient.agenda.lblColor"),
                    key: "clsColor",
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 7rem",
                },
            ],
            data: {},
            objectAgenda: { titolo: "", descrizione: "", identificativo: "", tipo: "", extraTime: false, durataAppuntamento: 0, gruppo: "", parametri: [{ giorno: "", esclusivo: false, intervalli: [{}] }], prestazioni: [{}] },
            modalShow: false,
            modalRipartizioniShow: false,
            modalModificaRipartizioniShow: false,
            selectedParametro: [],
            dalleOre: "",
            alleOre: "",
            clsColor: "",
            objectParametri: { esclusivo: false, giorno: "", intervalli: [{ dalleOre: "00:00", alleOre: "00:00" }], idAgenda: "" },
            editParametri: { esclusivo: false, giorno: "", intervalli: [{ dalleOre: "00:00", alleOre: "00:00" }], idAgenda: "" },
            objectIntervalli: { dalleOre: "", alleOre: "", clsColor: "" },
            objectPrestazioni: { descrizionePrestazione: "", codicePrestazione: "", costo: "", durata: "", idAgenda: "" },
            id: null,
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/agende/edit/" + me.id;
        me.loadDefaultData();
        me.loadData();
        me.loadNomenclatore();
    },
    computed: {
        rows() {
            return this.allPrestazioni.length;
        },
        rowsPM() {
            return this.prestazioni.length;
        },
    },
    methods: {
        loadDefaultData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathRestPrestazioni;
            me.showModalLoading = true;
            axios
                .get(link, {
                    params: {
                        idAgenda: me.id,
                    },
                })
                .then((response) => {
                    me.prestazioni = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {});
        },
        loadNomenclatore() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathNomenclatore;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.allPrestazioni = response.data.data;
                })
                .catch(() => {});
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.showModalLoading = true;
            if (me.id !== "-1") {
                axios
                    .get(link + me.id)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.parametri = response.data.data.parametri;
                        me.intervalli = response.data.data.parametri[0].intervalli;
                        console.log(me.intervalli);
                        me.showModalLoading = false;
                    })
                    .catch(() => {});
            }
        },
        onRowSelected(item) {
            let me = this;
            if (item.length > 0) {
                me.selectedParametro = item;
                me.intervalli = me.selectedParametro[0].intervalli;
            } else {
                me.intervalli = [];
            }
        },
        onSave() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.objectAgenda = me.jsonData;
                let jsonData = JSON.stringify(me.objectAgenda);
                axios
                    .post(link, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showModalLoading = false;
                        this.$bvToast.toast([this.$i18n.t("agenda.lblConfirmEdit")], {
                            title: [this.$i18n.t("agenda.lblEditTitle")],
                            variant: "info",
                            solid: true,
                            noCloseButton: true,
                            autoHideDelay: 2000,
                        });
                        me.$router.replace(me.linkback).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                            title: [this.$i18n.t("agenda.lblEditTitle")],
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        });
                    })
                    .then((value) => {
                        this.botTwo = value;
                    })
                    .catch(() => {});
            } else {
                me.objectAgenda = me.jsonData;
                let jsonData = JSON.stringify(me.objectAgenda);
                axios
                    .put(link + "/" + me.id, jsonData)
                    .then((response) => {
                        me.data = response.data.data;
                        me.showModalLoading = false;
                        this.$bvToast.toast([this.$i18n.t("agenda.lblConfirmEdit")], {
                            title: [this.$i18n.t("agenda.lblEditTitle")],
                            variant: "info",
                            solid: true,
                            noCloseButton: true,
                            autoHideDelay: 2000,
                        });
                        me.$router.replace(me.linkback).catch((err) => {
                            err;
                        });
                    })
                    .catch((error) => {
                        me.showModalLoading = false;
                        this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                            title: [this.$i18n.t("agenda.lblEditTitle")],
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        });
                    })
                    .then((value) => {
                        this.botTwo = value;
                    })
                    .catch(() => {});
            }
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadNomenclatore();
        },
        onAddParametro() {
            let me = this;
            me.objectAgenda = me.jsonData;

            // JSON INTERVALLO
            me.objectParametri.idAgenda = me.id;
            //let regexGiorno = /^([^0-9]*)$/;
            // if (regexGiorno.test(me.editParametri.giorno) === false) {
            if (me.editParametri.giorno.length !== 1) {
                me.objectParametri.giorno = moment(me.editParametri.giorno).format("YYYY-MM-DD");
            } else {
                console.log("parametro", me.editParametri.giorno);
                me.objectParametri.giorno = me.editParametri.giorno;
            }
            me.objectParametri.esclusivo = me.editParametri.esclusivo;
            me.objectAgenda.parametri.push(me.objectParametri);
            me.modalShow = false;
        },
        onAddRipartizioni() {
            let me = this;

            me.objectIntervalli.dalleOre = moment(me.objectIntervalli.dalleOre).format("HH:mm");
            me.objectIntervalli.alleOre = moment(me.objectIntervalli.alleOre).format("HH:mm");

            me.selectedParametro[0].intervalli.push(me.objectIntervalli);

            me.modalRipartizioniShow = false;
        },
        onDeleteRipartizioneIntervallo(row) {
            let me = this;
            me.objectAgenda = me.jsonData;
            let eliminaIndex = -1;
            me.selectedParametro[0].intervalli.forEach((element, index) => {
                if (element.id === row.id) {
                    eliminaIndex = index;
                }
            });

            this.$bvModal
                .msgBoxConfirm([this.$i18n.t("agenda.lblConfirmDeleteIntervall")], {
                    title: [this.$i18n.t("agenda.lblDeleteIntervall")],
                    footerClass: "p-2",
                    centered: true,
                })
                .then((item) => {
                    if (item) {
                        me.selectedParametro[0].intervalli.splice(eliminaIndex, 1);
                        let jsonData = JSON.stringify(me.objectAgenda);
                        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                        axios.put(link + me.id, jsonData).then(() => {
                            me.$bvToast.toast([this.$i18n.t("agenda.lblSuccDeleteParam")], {
                                title: [this.$i18n.t("agenda.lblEditTitle")],
                                variant: "info",
                                solid: true,
                                noCloseButton: true,
                                autoHideDelay: 2000,
                            });
                            me.loadData();
                            me.loadDefaultData();
                        });
                    }
                })
                .catch((err) => {
                    this.$bvModal.msgBoxOk(err.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblEditTitle")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                })
                .then((value) => {
                    this.botTwo = value;
                })
                .catch(() => {});
        },
        onDeleteIntervallo(row) {
            let me = this;

            me.objectAgenda.parametri = me.jsonData.parametri;
            me.objectAgenda.parametri.intervalli = me.jsonData.parametri[0].intervalli;

            me.objectAgenda = me.jsonData;

            let eliminaIndex = -1;
            me.objectAgenda.parametri.forEach((element, index) => {
                if (element.id === row.id) {
                    eliminaIndex = index;
                }
            });

            if (row.giorno !== "default") {
                this.$bvModal
                    .msgBoxConfirm([this.$i18n.t("agenda.lblDeleteParam")], {
                        title: [this.$i18n.t("agenda.lblDeleteParamAgenda")],
                        footerClass: "p-2",
                        centered: true,
                    })
                    .then((item) => {
                        if (item) {
                            me.objectAgenda.parametri.splice(eliminaIndex, 1);
                            let jsonData = JSON.stringify(me.objectAgenda);
                            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                            axios.put(link + me.id, jsonData).then(() => {
                                me.$bvToast.toast([this.$i18n.t("agenda.lblSuccDeleteParam")], {
                                    title: [this.$i18n.t("agenda.lblEditTitle")],
                                    variant: "info",
                                    solid: true,
                                    noCloseButton: true,
                                    autoHideDelay: 2000,
                                });
                                me.loadData();
                                me.loadDefaultData();
                            });
                        }
                    })
                    .catch((err) => {
                        this.$bvModal.msgBoxOk(err.response.data.messaggio, {
                            title: [this.$i18n.t("agenda.lblAttenction")],
                            size: "sm",
                            okVariant: "outline-danger",
                            headerClass: "sa-msg-header-danger",
                            footerClass: "p-2 border-top-0",
                            centered: true,
                        });
                    })
                    .then((value) => {
                        this.botTwo = value;
                    })
                    .catch(() => {});
            } else {
                this.$bvModal.msgBoxConfirm([this.$i18n.t("agenda.lblDefaultDelete")], {
                    title: [this.$i18n.t("agenda.lblDeleteParamAgenda")],
                    footerClass: "p-2",
                    centered: true,
                });
            }
        },
        onAssociaPrestazione(row) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;

            me.objectAgenda = me.jsonData;

            //OBJECT PRESTAZIONI
            me.objectPrestazioni.codicePrestazione = row.codicePrestazione;
            me.objectPrestazioni.descrizionePrestazione = row.descrizione;
            me.objectPrestazioni.costo = row.importoPrestazione;
            me.objectAgenda.prestazioni.push(me.objectPrestazioni);

            let jsonData = JSON.stringify(me.objectAgenda);
            axios
                .put(link + "/" + me.id, jsonData)
                .then((response) => {
                    me.data = response.data.data;
                    me.showModalLoading = false;
                    this.$bvToast.toast([this.$i18n.t("agenda.lblAssociatePerformance")], {
                        title: [this.$i18n.t("agenda.lblEditTitle")],
                        variant: "info",
                        solid: true,
                        noCloseButton: true,
                        autoHideDelay: 2000,
                    });
                    me.loadDefaultData();
                    me.loadData();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblAttenction")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                })
                .then((value) => {
                    this.botTwo = value;
                })
                .catch(() => {});
        },
        onDissociaPrestazione(row) {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.objectAgenda = me.jsonData;
            let eliminaIndex = -1;
            me.objectAgenda.prestazioni.forEach((element, index) => {
                if (element.codicePrestazione === row.codicePrestazione) {
                    eliminaIndex = index;
                }
            });
            me.objectAgenda.prestazioni.splice(eliminaIndex, 1);

            let jsonData = JSON.stringify(me.objectAgenda);
            axios
                .put(link + "/" + me.id, jsonData)
                .then((response) => {
                    me.data = response.data.data;
                    me.showModalLoading = false;
                    this.$bvToast.toast([this.$i18n.t("agenda.DissociatedPerformance")], {
                        title: [this.$i18n.t("agenda.lblEditTitle")],
                        variant: "info",
                        solid: true,
                        noCloseButton: true,
                        autoHideDelay: 2000,
                    });
                    me.loadDefaultData();
                })
                .catch((error) => {
                    me.showModalLoading = false;
                    this.$bvModal.msgBoxOk(error.response.data.messaggio, {
                        title: [this.$i18n.t("agenda.lblAttenction")],
                        size: "sm",
                        okVariant: "outline-danger",
                        headerClass: "sa-msg-header-danger",
                        footerClass: "p-2 border-top-0",
                        centered: true,
                    });
                })
                .then((value) => {
                    this.botTwo = value;
                })
                .catch(() => {});
        },
        onAnnullaModifiche() {
            let me = this;
            me.loadData();
            me.$router.replace(me.linkback).catch((err) => {
                err;
            });
        },
        controlloPrestazioni() {
            let me = this;
            me.objectAgenda.prestazioni.forEach((element) => {
                if (element.descrizionePrestazione === me.objectPrestazioni.descrizionePrestazione) {
                    me.exist = true;
                } else {
                    me.exist = false;
                }
            });
        },
        onAggiungiNewPrestazione() {
            let me = this;
            me.jsonData.prestazioni.push(me.editPrestazione);
            me.showModalNuovaPrestazione = false;
        },
        onCancel() {
            let me = this;
            me.modalRipartizioniShow = false;
            me.showModalNuovaPrestazione = false;
            me.modalShow = false;
            me.editParametri.giorno = null;
            me.editParametri.esclusivo = null;
            me.objectIntervalli.clsColor = null;
            me.objectIntervalli.alleOre = null;
            me.objectIntervalli.dalleOre = null;
            me.editPrestazione.codicePrestazione = null;
            me.editPrestazione.descrizionePrestazione = null;
            me.editPrestazione.durata = null;
            me.editPrestazione.costo = null;
        },
    },
};
</script>
<style>
.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}
.button-list {
    float: right;
    padding: inherit;
}
</style>
