<template>
  <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
    <template slot="tab-content-event">
      <visita-controllo-ecg-edit-component :jsonData="jsonData" :tipoEvento="titoloEvento" :gruppo="gruppoEvento" @update="onUpdateJsonData"></visita-controllo-ecg-edit-component>
    </template>
  </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import VisitaControlloEcgEditComponent from "./VisitaControlloEcgEditComponent.vue";
export default {
  mixins: [UtilityMixins],
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { VisitaControlloEcgEditComponent, EventiEditComponent },
  created() {
    console.log("Created");
  },
  data() {
    return {
      linkback: null,
      titoloEvento: "VISITA CONTROLLO ECG",
      // titoloEvento: this.getLabelTraduora("patient.menu.cardiologyEcg"),
      gruppoEvento: "CARDIOLOGIA",
      pathResource: "/cardiovisitecontrolliecg",
      pathRestValidation: "/validation",
      id: "-1",
      isFirmato: false,
      childKey: 0,
      tabIndex: 0,
      showModalLoading: false,
      jsonData: {
        dataEvento: null,
        monitoraggioPressione: "NO",
        tacAddome: "NO",
        altro: null,
        betabloccantiFinale: null,
        diidropiridiniciFinale: null,
        diureticiFinale: null,
        aceinibitoriFinale: null,
        causaRicoveroExtra: null,
        tipoDiRicoveroExtra: null,
        causaRicoveroCardio: null,
        tipoDiRicoveroCardio: null,
        esameCardiovascolare: null,
        esameGenerale: null,
        farmaco10: null,
        freqsit: 0.0,
        pasitminm: 0.0,
        pasitmaxm: 0.0,
        paortominm: 0.0,
        paortomaxm: 0.0,
        paclinominm: 0.0,
        paclinomaxm: 0.0,
        freqorto: 0.0,
        freqclino: 0.0,
        pasitmin3: 0.0,
        doseFarmaco10: null,
        pasitmax3: 0.0,
        paortomin3: 0.0,
        paortomax3: 0.0,
        paclinomin3: 0.0,
        paclinomax3: 0.0,
        nyha: null,
        polsi: null,
        ecgIvs: null,
        fundusoculi: null,
        pasitmin2: 0.0,
        doseFarmaco9: null,
        pasitmax2: 0.0,
        paortomin2: 0.0,
        paortomax2: 0.0,
        paclinomin2: 0.0,
        paclinomax2: 0.0,
        pasitmin1: 0.0,
        pasitmax1: 0.0,
        paortomin1: 0.0,
        paortomax1: 0.0,
        paclinomin1: 0.0,
        doseFarmaco8: null,
        paclinomax1: 0.0,
        commento: null,
        codicePrescrizione: null,
        dataPrescrizione: null,
        pasdomicilio: 0.0,
        qualieffetti: null,
        paddomicilio: 0.0,
        digitale: null,
        nitrati: null,
        alfabetabloccanti: null,
        antiaggreganti: null,
        anticoagulanti: null,
        statine: null,
        fibrati: null,
        antidiabOrali: null,
        insulina: null,
        aceinibitori: null,
        antiaritmici: null,
        anamnesiProssima: "",
        anamnesiGeneraleCardiovascolare: "",
        digitaleFinale: null,
        nitratiFinale: null,
        farmaco6: null,
        antiaggregantiFinale: null,
        anticoagulantiFinale: null,
        statineFinale: null,
        fibratiFinale: null,
        antidiabOraliFinale: null,
        insulinaFinale: null,
        antiaritmiciFinale: null,
        cpkLdh: "NO",
        dataUltimaModifica: null,
        email: null,
        ecoRenale: "NO",
        microalbuminuria: "NO",
        proteinuria: "NO",
        dosaggioCatecolamine: "NO",
        hba1c: "NO",
        digossinemia: "NO",
        pcr: "NO",
        dosaggioOmocisteinemia: "NO",
        diabetestrat1: null,
        sesso: null,
        eta: 0.0,
        doseFarmaco5: null,
        fumostrat1: null,
        coloreRischio: null,
        rischioCardiovascolare: null,
        pressartsist: null,
        colesterolemia: null,
        fumo: null,
        acth: "NO",
        circvita: 0.0,
        circfianchi: 0.0,
        interpretation: null,
        doseFarmaco3: null,
        fanalysis: 0.0,
        defdia3: null,
        defdia1: null,
        coddia1: null,
        coddia3: null,
        defdia2: null,
        coddia2: null,
        defdia4: null,
        coddia4: null,
        tacCerebrale: "NO",
        doseFarmaco2: null,
        versioneEsame: 0.0,
        esaminatore: null,
        storicoReferti: null,
        checkFi: null,
        segueterapia: null,
        effetticollaterali: null,
        attFisica: null,
        pasaisn: 0.0,
        pasaidx: 0.0,
        doseFarmaco1: null,
        abisn: 0.0,
        abidx: 0.0,
        stadioScompenso: null,
        paroxysmalNocturnalDyspnea: null,
        neckVeinDistention: null,
        cracklesRales: null,
        acutePulmonaryEdema: null,
        s3Gallop: null,
        weightLossMayorFramingham: null,
        centralVenousPressure16: null,
        farmaco2: null,
        leftVentricularDysfunction: null,
        bilateralAnkleEdema: null,
        pleuralEffusion: null,
        nightCough: null,
        dyspneaOnExertion: null,
        hepatomegaly: null,
        tachycardia120: null,
        weightLossMinorFramingham: null,
        ecografiaTiroidea: "NO",
        scintigrafiaTiroidea: "NO",
        dosaggioElettrolitiUrinari: "NO",
        doseFarmaco7: null,
        glicemia: "NO",
        azotemia: "NO",
        creatinemia: "NO",
        uricemia: "NO",
        farmaco9: null,
        farmaco8: null,
        scintigrafiaMiocardica: "NO",
        farmaco7: null,
        peso: 0.0,
        altezza: 0.0,
        bmi: 0.0,
        doseFarmaco6: null,
        farmaco3: null,
        farmaco5: null,
        farmaco4: null,
        farmaco1: null,
        colesteroloTrigliceridi: "NO",
        benzotiazepine: null,
        imgecg: null,
        holterEcg: "NO",
        testErgometrico: "NO",
        angiocardiopneumoscint: "NO",
        doseFarmaco4: null,
        diuretici: null,
        fondooculare: "NO",
        cortisolemia820: "NO",
        dosaggioClinoOrto: "NO",
        esameUrine: "NO",
        fenilalchilamine: null,
        dosaggioDopoCaptopril: "NO",
        sodiemia: "NO",
        potassioemia: "NO",
        calcemia: "NO",
        cloremia: "NO",
        ptApttInr: "NO",
        ecocardiografia: "NO",
        ecotsa: "NO",
        tsh: "NO",
        ft4: "NO",
        ft3: "NO",
        markersEpatite: "NO",
        ecografiaEpatobiliare: "NO",
        at1antagonisti: null,
        gotGptQpeAp: null,
        alfabloccanti: null,
        // routineSangueCompleta: null,
        emocromoPiastrine: "NO",
        centroUltimaModifica: null,
        oraPrenotazioneMonitoraggio: null,
        altroEsame: null,
        dataPrenotazioneMonitoraggio: null,
        scintigrafiaRenale: "NO",
        ecodopplerArtiInferiori: "NO",
        ecodopplerTransCranico: "NO",
        prossimoControllo: null,
        oraProssimoControllo: null,
        alfabloccantiFinale: null,
        alfabetabloccantiFinale: null,
        at1antagonistiFinale: null,
        fenilalchilamineFinale: null,
        benzotiazepineFinale: null,
        farmaco1Finale: null,
        diidropiridinici: null,
        farmaco4Finale: null,
        farmaco5Finale: null,
        farmaco3Finale: null,
        farmaco6Finale: null,
        farmaco2Finale: null,
        doseFarmaco1Finale: null,
        doseFarmaco2Finale: null,
        doseFarmaco3Finale: null,
        doseFarmaco4Finale: null,
        doseFarmaco5Finale: null,
        betabloccanti: null,
        doseFarmaco6Finale: null,
        altroFinale: null,
        farmaco10Finale: null,
        doseFarmaco10Finale: null,
        doseFarmaco9Finale: null,
        doseFarmaco8Finale: null,
        doseFarmaco7Finale: null,
        farmaco9Finale: null,
        farmaco8Finale: null,
        farmaco7Finale: null,
        consulenzaEsterna: "",
        pet: "NO",
        tcCoronarica: "NO",
        ecostress: "NO",
        relazioneElettrocardiogramma: null, //DA AGGIUNGERE NEL DB E NEL BACKEND
        listaPressioneClino: [{ pressioneSistolica: "", pressioneDiastolica: "", frequenzaCardiaca: "", tipoMisurazione: "CLINO" }],
        listaPressioneOrto: [{ pressioneSistolica: "", pressioneDiastolica: "", frequenzaCardiaca: "", tipoMisurazione: "ORTO" }],
        listaPressioneSitting: [{ pressioneSistolica: "", pressioneDiastolica: "", frequenzaCardiaca: "", tipoMisurazione: "SITTING" }],
        listaTerapia: [],
        listaDiagnosi: [],
        noteDiagnosi: "",
        /**/
        magnesemia: "NO",
        got: "NO",
        gpt: "NO",
        quadroProtElettroforetico: "NO",
        amilasi: "NO",
        yGt: "NO",
        ntProBnp: "NO",
        sideremia: "NO",
        ferritinemia: "NO",
        transferrinemia: "NO",
        vitD25Oh: "NO",
        troponinemiaT: "NO",
        troponinemiaI: "NO",
        anticorpiAntiTg: "NO",
        anticorpiAntiTpo: "NO",
        ecocolordopplerAortaAddominale: "NO",
        ecocolordopplerArteriosoArtiInferiori: "NO",
        ecocolordopplerArterieRenali: "NO",
        ecostressFarmacologico: "NO",
        rmnCardiaca: "NO",
        angioTcTsa: "NO",
        angioTcTorace: "NO",
        angioTcAddome: "NO",
        rmnCranio: "NO",
        rmnTorace: "NO",
        rmnAddome: "NO",
        tacTorace: "NO",
        ecografiaRenale: "NO",
        ecografiaAddominale: "NO",
        mediaFrequenzaCardiacaClino: "",
        mediaFrequenzaCardiacaOrto: "",
        mediaFrequenzaCardiacaSitting: "",
        relazioneElettrocardiogrammaRitmo: "",
        relazioneElettrocardiogrammaFrequenzaCardiaca: "",
        relazioneElettrocardiogrammaOndaP: "",
        relazioneElettrocardiogrammaPr: "",
        relazioneElettrocardiogrammaQrs: "",
        relazioneElettrocardiogrammaTrattoSt: "",
        relazioneElettrocardiogrammaConclusioni: "",
      },
      key: 0,
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    this.jsonData.dataEvento = new Date().getTime();
  },
  methods: {
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    onUpdateFile() {
      let me = this;
      me.childKey++;
    },
    onUpdateJsonData(data) {
      let me = this;
      data.sesso = data.sessoAnagrafica;
      data.eta = UtilityMixins.methods.calcoloEtaYearOnly(data.dataNascitaAnagrafica, new Date(data.dataEvento)).toString();
      me.jsonData = data;
    },
  },
};
</script>
<style scoped>
.ft-space {
  margin-top: 10px;
}
</style>
