<template>
    <b-row>
        <b-col xs="12" sm="12" md="12" lg="12" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("global.lblCreateDate") }} </label>
            <span class="sa-data"> {{ formatDateTime(data.createDate) }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("global.lblCreateUser") }}</label>
            <span class="sa-data"
                ><b-link id="userInformationCreateUser" @click="getUserInfo(data.createUser, $event)">{{ data.createUser }}</b-link></span
            >
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("global.lblUpdateDate") }}</label>
            <span class="sa-data"> {{ formatDateTime(data.createDate) }}</span>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="12" xl="3">
            <label class="sa-label-data">{{ getLabelTraduora("global.lblUpdateUser") }}</label>
            <span class="sa-data">
                <b-link id="userInformationUpdateUser" @click="getUserInfo(data.updateUser, $event)">{{ data.updateUser }}</b-link>
            </span>
        </b-col>
        <b-popover ref="popover" target="userInformationCreateUser" title="User Information" triggers="focus" class="sa-popover">
            <p>
                {{ this.$i18n.t("anagrafica.lblLastName") }}: <strong style="white-space: nowrap">{{ item.lastname }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("anagrafica.lblFirstName") }}: <strong style="white-space: nowrap">{{ item.firstname }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("anagrafica.lblEmail") }}: <strong style="white-space: nowrap">{{ item.email }}</strong>
            </p>
        </b-popover>
        <b-popover ref="popover" target="userInformationUpdateUser" title="User Information" triggers="focus">
            <p>
                {{ this.$i18n.t("anagrafica.lblLastName") }}: <strong style="white-space: nowrap">{{ item.lastname }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("anagrafica.lblFirstName") }}: <strong style="white-space: nowrap">{{ item.firstname }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("utenti.lblGroup") }}: <strong style="white-space: nowrap">{{ item.profilo }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("anagrafica.lblEmail") }}: <strong style="white-space: nowrap">{{ item.email }}</strong>
            </p>
        </b-popover>
    </b-row>
    <!-- <div class="row">
        <div class="col">
            <strong> {{ this.$i18n.t("global.lblCreateDate") }} </strong><br />
            <span class="sa-form-label">{{ formatDateTime(data.createDate) }}</span>
        </div>
        <div class="col">
            <label> {{ this.$i18n.t("global.lblCreateUser") }} </label>
            <span class="sa-form-label">
                <b-link id="userInformationCreateUser" @click="getUserInfo(data.createUser, $event)">{{ data.createUser }}</b-link>
            </span>
        </div>
        <div class="col">
            <strong> {{ this.$i18n.t("global.lblUpdateDate") }} </strong><br />
            <span class="sa-form-label">{{ formatDateTime(data.updateDate) }}</span>
        </div>
        <div class="col">
            <strong> {{ this.$i18n.t("global.lblUpdateUser") }} </strong><br />
            <span class="sa-form-label">
                <b-link id="userInformationUpdateUser" @click="getUserInfo(data.updateUser, $event)">{{ data.updateUser }}</b-link>
            </span>
        </div>
        <b-popover ref="popover" target="userInformationCreateUser" title="User Information" triggers="focus" class="sa-popover">
            <p>
                {{ this.$i18n.t("anagrafica.lblLastName") }}: <strong style="white-space: nowrap">{{ item.lastname }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("anagrafica.lblFirstName") }}: <strong style="white-space: nowrap">{{ item.firstname }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("anagrafica.lblEmail") }}: <strong style="white-space: nowrap">{{ item.email }}</strong>
            </p>
        </b-popover>
        <b-popover ref="popover" target="userInformationUpdateUser" title="User Information" triggers="focus">
            <p>
                {{ this.$i18n.t("anagrafica.lblLastName") }}: <strong style="white-space: nowrap">{{ item.lastname }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("anagrafica.lblFirstName") }}: <strong style="white-space: nowrap">{{ item.firstname }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("utenti.lblGroup") }}: <strong style="white-space: nowrap">{{ item.profilo }}</strong>
            </p>
            <p>
                {{ this.$i18n.t("anagrafica.lblEmail") }}: <strong style="white-space: nowrap">{{ item.email }}</strong>
            </p></b-popover
        >
    </div> -->
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin";
import axios from "axios";
export default {
    mixins: [UtilityMixin],
    props: {
        data: {},
    },
    data() {
        return {
            pathResource: "/users/infouser",
            show: false,
            target: "userInformationUpdateUser",
            item: {},
        };
    },
    created() {},
    methods: {
        getUserInfo(data) {
            console.log(data);
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + data;
            axios
                .get(link)
                .then((response) => {
                    me.item = response.data.data;
                })
                .catch(() => {
                    // me.showmolalloading = false;
                });
        },
    },
};
</script>
