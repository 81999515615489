<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Presenza Sintomi:</label>
          <span class="sa-data">{{ jsonData.presenzaSintomi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Inizio Sintomi:</label>
          <span class="sa-data">{{ formatDate(jsonData.dataInizioSintomi) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ittero:</label>
          <span class="sa-data">{{ jsonData.ittero }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre:</label>
          <span class="sa-data">{{ jsonData.febbre }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Dolore Addominale:</label>
          <span class="sa-data">{{ jsonData.doloreAddominale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Perdita Appetito/Peso:</label>
          <span class="sa-data">{{ jsonData.perditaAppetitoPeso }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nausea:</label>
          <span class="sa-data">{{ jsonData.nausea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vomito:</label>
          <span class="sa-data">{{ jsonData.vomito }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diarrea:</label>
          <span class="sa-data">{{ jsonData.diarrea }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Urine Scure:</label>
          <span class="sa-data">{{ jsonData.urineScure }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Feci Chiare:</label>
          <span class="sa-data">{{ jsonData.feciChiare }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Mal di Testa:</label>
          <span class="sa-data">{{ jsonData.malTesta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Dolori Articolari:</label>
          <span class="sa-data">{{ jsonData.doloriArticolari }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Astenia:</label>
          <span class="sa-data">{{ jsonData.astenia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Quadro Clinico Neurologico:</label>
          <span class="sa-data">{{ jsonData.quadroClinicoNeurologico }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Quadro Clinico Neurologico" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Paralisi di Ball:</label>
          <span class="sa-data">{{ jsonData.paralisiBell }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Mielite Traversa Acuta:</label>
          <span class="sa-data">{{ jsonData.mieliteTraversaAcuta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sindrome di Guillain-Barre':</label>
          <span class="sa-data">{{ jsonData.sindromeGuillainBarre }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Amiotrofia Nevralgica:</label>
          <span class="sa-data">{{ jsonData.amiotrofiaNevralgica }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Meningo-Encefalite:</label>
          <span class="sa-data">{{ jsonData.meningoEncefalite }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricovero:</label>
          <span class="sa-data">{{ jsonData.ricoveroOspedaliero }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Durata degenza:</label>
          <span class="sa-data">{{ jsonData.durataDegenza }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Epatite Fuminante:</label>
          <span class="sa-data">{{ jsonData.epatiteFuminante }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Anamnesi" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Gravidanza in Corso:</label>
          <span class="sa-data">{{ jsonData.gravidanzaCorso }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Settimana di Gestazione:</label>
          <span class="sa-data">{{ jsonData.settimanaGestazione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Malattia Epatica Cronica:</label>
          <span class="sa-data">{{ jsonData.malattiaEptaicaCronica }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Immunodepressione:</label>
          <span class="sa-data">{{ jsonData.immunodepressione }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Causa Immunodepressione" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Terapia Immunosoppressiva:</label>
          <span class="sa-data">{{ jsonData.immunodepressioneCausaTerapiaImmunosoppressiva }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Asplenia:</label>
          <span class="sa-data">{{ jsonData.immunodepressioneCausaAsplenia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malnutrizione:</label>
          <span class="sa-data">{{ jsonData.immunodepressioneCausaMalnutrizione }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trapianto Organo/Midollo Osseo:</label>
          <span class="sa-data">{{ jsonData.immunodepressioneCausaTrapaintoOrganoMidollo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anno Trapianto:</label>
          <span class="sa-data">{{ jsonData.immunodepressioneCausaTrapaintoOrganoMidolloAnno }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Hiv:</label>
          <span class="sa-data">{{ jsonData.immunodepressioneCausaHiv }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diabete:</label>
          <span class="sa-data">{{ jsonData.immunodepressioneCausaDiabete }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tumore:</label>
          <span class="sa-data">{{ jsonData.immunodepressioneCausaTumore }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <span class="sa-data">{{ jsonData.immunodepressioneCausaAltro }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Complicanze" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Encefalopatia:</label>
          <span class="sa-data">{{ jsonData.encefalite }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Fulminante:</label>
          <span class="sa-data">{{ jsonData.fulminante }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trapianto di Fegato:</label>
          <span class="sa-data">{{ jsonData.trapiantoFegato }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Decesso:</label>
          <span class="sa-data">{{ jsonData.decesso }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Decesso:</label>
          <span class="sa-data">{{ formatDate(jsonData.dataDecesso) }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Causa Decesso:</label>
          <span class="sa-data">{{ jsonData.decessoCausaIniziale }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Fattore di Rischio Alimentare (2 mesi precedenti)" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Acqua non Controllata:</label>
          <span class="sa-data">{{ jsonData.acquaNonControllata }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Frutti di Mare Crudi/Poco Cotti:</label>
          <span class="sa-data">{{ jsonData.fruttiMareCrudiPocoCotti }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Frutti di Mare:</label>
          <span class="sa-data">{{ jsonData.fruttiMareCrudiPocoCottiDettagli }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altri Frutti Di Mare:</label>
          <span class="sa-data">{{ jsonData.fruttiMareCrudiPocoCottiAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Frutta Fresca non Lavata:</label>
          <span class="sa-data">{{ jsonData.fruttaFrescaNonLavata }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Frutta Fresca:</label>
          <span class="sa-data">{{ jsonData.fruttaFrescaNonLavataDettagli }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altra Frutta Fresca:</label>
          <span class="sa-data">{{ jsonData.fruttaFrescaNonLavataAltro }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Verdura Cruda non Lavata:</label>
          <span class="sa-data">{{ jsonData.verduraCrudaNonLavata }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Verdura:</label>
          <span class="sa-data">{{ jsonData.verduraCrudaNonLavataDettagli }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Carne Maiale Cruda/Poco Cotta:</label>
          <span class="sa-data">{{ jsonData.carneMaialeCrudaPocoCotta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Insaccati/Salsicce Maiale non Stagionati:</label>
          <span class="sa-data">{{ jsonData.insaccatiSalsicceMaialeNonStagionati }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Salsicce di Fegato Maiale non Stagionate:</label>
          <span class="sa-data">{{ jsonData.salsicceFegatoMaialeNonStagionate }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Carne Cinghiale Cruda/Poco Cotta:</label>
          <span class="sa-data">{{ jsonData.carneCinghialecrudaPocoCotta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Salsicce Cinghiale non Stagionate:</label>
          <span class="sa-data">{{ jsonData.salsicceCinghialeNonStagionate }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Carni Altra Selvaggina Cruda/Poco Cotta:</label>
          <span class="sa-data">{{ jsonData.carneAltraSelvvagginaCrudaPocoCotta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Specificare Animale:</label>
          <span class="sa-data">{{ jsonData.carneAltraSelvvagginaCrudaPocoCottaAnimale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Carne Cruda/Poco Cotta di Altro Animale:</label>
          <span class="sa-data">{{ jsonData.carneCrudaPocoCottaAltroAnimale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Animale:</label>
          <span class="sa-data">{{ jsonData.carneCrudaPocoCottaAltroAnimaleDettagli }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Frattaglie:</label>
          <span class="sa-data">{{ jsonData.frattaglie }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Altri Fattori Di Rischio (2 mesi precedenti)" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Viaggio (1 o piu' notto fuori citta di residenza):</label>
          <span class="sa-data">{{ jsonData.viaggi }}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaViaggi" :fields="fieldsViaggi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(dove)="item">
            <span class="sa-data">{{ item.item.dove }}</span>
          </template>
          <template #cell(giornoDal)="item">
            <span class="sa-data">{{ formatDate(item.item.giornoDal) }}</span>
          </template>
          <template #cell(giornoAl)="item">
            <span class="sa-data">{{ formatDate(item.item.giornoAl) }}</span>
          </template>
          <!-- <template #cell(actions)="row">
            <b-button v-if="row.index == jsonData.listaViaggi.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddViaggi(jsonData.listaViaggi, row.item)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== jsonData.listaViaggi.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaViaggi, row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template> -->
        </b-table>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vita/Lavoro in Fattoria/Allevamento:</label>
          <span class="sa-data">{{ jsonData.viveLavoraFattoriaAllevamento }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Pratica Giardinaggio:</label>
          <span class="sa-data">{{ jsonData.praticaGiardinaggio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Possesso Orto:</label>
          <span class="sa-data">{{ jsonData.orto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Utilizzo Letame come Concime:</label>
          <span class="sa-data">{{ jsonData.letameConcime }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Pratica Caccia:</label>
          <span class="sa-data">{{ jsonData.caccia }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Possesso Animali Domestici:</label>
          <span class="sa-data">{{ jsonData.animaliDomestici }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Animale:</label>
          <span class="sa-data">{{ jsonData.animaliDomesticiDettaglio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Possesso Animali Allevamento:</label>
          <span class="sa-data">{{ jsonData.animaliAllevamento }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Animale:</label>
          <span class="sa-data">{{ jsonData.animaliAllevamentoDettaglio }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Contatto Soggetti con Stessa Diagnosi:</label>
          <span class="sa-data">{{ jsonData.contattiSoggettiStessaDiagnosi }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Rapporto:</label>
          <span class="sa-data">{{ jsonData.contattiSoggettiStessaDiagnosiTipoRapporto }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trasfusione Sangue/Emoderivati:</label>
          <span class="sa-data">{{ jsonData.trasfusioneSangueEmoderivati }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati di Laboratorio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Igm Anti-HEV:</label>
          <span class="sa-data">{{ jsonData.igmAntiHev }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">IgG Anti-HEV:</label>
          <span class="sa-data">{{ jsonData.iggAntiHev }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">HEV-RNA Su Siero:</label>
          <span class="sa-data">{{ jsonData.hevRnaSiero }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">HEV-RNA Su Feci:</label>
          <span class="sa-data">{{ jsonData.hevRnaFeci }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Genotipo:</label>
          <span class="sa-data">{{ jsonData.rnaPositivoGenotipo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Valore Massimo AST/GPT (UI/L):</label>
          <span class="sa-data">{{ jsonData.valoreMassimoAstGpt }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Valore Massimo ALT/GPT (UI/L):</label>
          <span class="sa-data">{{ jsonData.valoreMassimoAltGot }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Bilirubina Totale (MG/DL):</label>
          <span class="sa-data">{{ jsonData.bilirubinaTotale }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Bilirubina Diretta (MG/DL):</label>
          <span class="sa-data">{{ jsonData.bilirubinaDiretta }}</span>
          <span class="sa-data">{{ jsonData.bilirubinaDiretta }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anti-HBc:</label>
          <span class="sa-data">{{ jsonData.antiHbc }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">IgM Anti-HAV:</label>
          <span class="sa-data">{{ jsonData.igmAntiHav }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">IgM Anti-HBc:</label>
          <span class="sa-data">{{ jsonData.igmAntiHbc }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anti-HCV:</label>
          <span class="sa-data">{{ jsonData.antiHcv }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">HbsAg:</label>
          <span class="sa-data">{{ jsonData.hbsag }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">HCV-RNA:</label>
          <span class="sa-data">{{ jsonData.hcvRna }}</span>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="false"></indagine-epidemiologica-dati-notifica-component>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../../utility/UtilityMixin";
import IndagineEpidemiologicaDatiNotificaComponent from "../components/IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { IndagineEpidemiologicaDatiNotificaComponent },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveieepatitee",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      jsonData: {},
      fieldsViaggi: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Luogo",
          key: "dove",
          sortable: false,
        },
        {
          label: "Dal",
          key: "giornoDal",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Al",
          key: "giornoAl",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      if (me.id === "-1") {
        me.setDatiMedico();
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        me.showModalLoading = true;
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.showModalLoading = false;
          })
          .catch(() => {
            me.showModalLoading = false;
          });
      }
    },
  },
};
</script>
