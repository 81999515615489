<template>
    <div class="sa-tab-scheda">
        <div class="sa-tab-scheda-row-fill">
            <b-row style="background-color: var(--bg-main-container); height: 100%">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-card class="sa-card" header="ACCERTAMENTI SUPPLEMENTARI" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                        <div style="height: 100%; display: inline-flex; flex-direction: column; width: 100%">
                            <div class="sa-tab-scheda-row-fill-table-content">
                                <div class="sa-tab-scheda-row-fill-table-content-header">
                                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                                        <b-col lg="3">
                                            <p>Totale Visite: {{ rows }}</p>
                                        </b-col>
                                        <b-col lg="9">
                                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                                        </b-col>
                                    </b-row>
                                </div>
                                <div class="sa-tab-scheda-row-fill-table-content-body">
                                    <div class="b-table-sticky-header">
                                        <b-table sticky-header ref="table" stacked="xl" striped hover :items="items" :fields="fieldsRichiesta" sort-icon-left head-variant="light" class="sa-b-table" select-mode="multi" selectable @row-selected="onRowSelected">
                                            <template v-slot:cell(dataRichiesta)="{ item }">
                                                <!-- <router-link class="sa-edit-link" :to="'/worklist/accertamentisupplementaridettagli/view/' + item.id + '?idPaziente=' + idPaziente">{{ formatDateTime(item.dataRichiesta) }}</router-link> -->
                                                <span>{{ formatDateTime(item.dataRichiesta) }}</span>
                                            </template>
                                            <template #cell(selected)="{ rowSelected }">
                                                <template v-if="rowSelected">
                                                    <span aria-hidden="true">&check;</span>
                                                    <span class="sr-only">Selected</span>
                                                </template>
                                                <template v-else>
                                                    <span aria-hidden="true">&nbsp;</span>
                                                    <span class="sr-only">Not selected</span>
                                                </template>
                                            </template>
                                            <template #cell(actions)="row">
                                                <b-button size="sm" variant="btn btn-outline-secondary waves-effect waves-light width-sm no-text" style="margin-right: 3px" @click="onVisualizzaDettagli(row.item)">
                                                    <b-icon icon="info"></b-icon>
                                                </b-button>
                                            </template>
                                        </b-table>
                                    </div>
                                </div>
                                <div class="sa-tab-scheda-row-fill-table-content-footer">
                                    <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                                        <b-col lg="3">
                                            <p>Totale Visite: {{ rows }}</p>
                                        </b-col>
                                        <b-col lg="9">
                                            <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                                        </b-col>
                                    </b-row>
                                </div>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>
            <b-modal ref="mdlAccertamentiSupplementariDettaglio" id="mdlAccertamentiSupplementariDettaglio" title="Accertamenti Supplementari" size="xl" style="height: 100%">
                <accertamenti-supplementari-dettagli-view :idRichiesta="idRichiesta"></accertamenti-supplementari-dettagli-view>
                <template #modal-footer="{ btnSalva }">
                    <b-button :id="btnSalva" size="sm" variant="success" @click="onChiudi">Chiudi</b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
import AccertamentiSupplementariDettagliView from "./AccertamentiSupplementariDettagliView.vue";
export default {
    components: { AccertamentiSupplementariDettagliView },
    mixins: [UtilityMixin],
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    motivazione: "",
                    dataRichiesta: Date.now(),
                    conclusioni: "",
                    idVisita: "",
                    visitaEsameSupplementare: [],
                };
            },
        },
    },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    mounted() {
        let me = this;
        me.data.idVisita = this.$route.params.id;
        me.idPaziente = this.$route.query.idPaziente;
    },
    data() {
        return {
            idRichiesta: null,
            currentPage: 1,
            perPage: 10,
            pathResource: "/visiterichiestaesamisupplementari",
            items: [],
            fieldsRichiesta: [
                {
                    label: "Esame Richiesti",
                    key: "dataRichiesta",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Diagnosi",
                    key: "motivazione",
                    sortable: true,
                },
                {
                    label: "Conclusioni",
                    key: "conclusioni",
                    sortable: true,
                },
                {
                    label: "Stato Attuale",
                    key: "statoAttuale",
                    thStyle: "width: 8rem",
                    sortable: true,
                },
                {
                    label: "Stato Attuale Data/Ora",
                    key: "statoAttualeDataOra",
                    formatter: (value) => {
                        if (value) {
                            return moment(new Date(value)).format("DD/MM/YYYY HH:mm");
                        } else {
                            return "";
                        }
                    },
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    tdClass: "text-center",
                    thStyle: "width: 7rem",
                },
            ],
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "?idVisita=" + me.data.idVisita;
            axios
                .get(link)
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    for (let element in me.items) {
                        me.items[element]["_cellVariants"] = {};
                        if (me.items[element]["statoAttuale"] === "INCOMPLETA") {
                            me.items[element]["_cellVariants"].statoAttuale = "danger";
                        } else if (me.items[element]["statoAttuale"] === "DA FIRMARE") {
                            me.items[element]["_cellVariants"].statoAttuale = "warning";
                        } else {
                            me.items[element]["_cellVariants"].statoAttuale = "success";
                        }
                    }
                })
                .catch(() => {});
        },
        onRowSelected(rows) {
            this.$emit("update", rows);
        },
        onVisualizzaDettagli(item) {
            console.log(item);

            let me = this;
            me.idRichiesta = item.id;
            me.$refs.mdlAccertamentiSupplementariDettaglio.show();
        },
        onChiudi() {
            let me = this;
            me.$refs.mdlAccertamentiSupplementariDettaglio.hide();
        },
    },
};
</script>
