<template>
    <div>
        <b-card class="sa-card" header="Dati Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEventDate") }}</label>
                    <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="jsonData.dataEvento" type="datetime"></date-picker>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblInducedArrhythmias')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSustained") }}</label>

                    <b-form-select v-model="jsonData.sostenuta" :options="optionsSostenuta">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="8" xl="8">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQrsType") }}</label>
                    <b-form-select v-model="jsonData.qrsTipologia" :options="optionsTipologia">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQrsDurata") }}</label>
                    <b-form-input v-model="jsonData.qrsDurata" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="8" xl="8">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblQrsMorphology") }}</label>

                    <b-form-select v-model="jsonData.qrsMorfologia" :options="optionsMorfologia">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCycle") }}</label>
                    <b-form-input v-model="jsonData.ciclo" type="number"></b-form-input>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblInductionMode')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblStimulationSite") }}</label>
                    <b-form-select v-model="jsonData.sedeStimolazione" :options="optionsSedeStimolazione">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExtrastimuliNumber") }}</label>
                    <b-form-input v-model="jsonData.nextrastimol" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblOnDriveCycle") }}</label>
                    <b-form-input v-model="jsonData.cicloDrive" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblS1S2") }}</label>
                    <b-form-input v-model="jsonData.s1S2" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblS2S3") }}</label>
                    <b-form-input v-model="jsonData.s2S3" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblS3S4") }}</label>
                    <b-form-input v-model="jsonData.s3S4" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblIncreasingFrequencyStimulation") }}</label>
                    <b-form-select v-model="jsonData.stimolazioneFrequenzaCrescente" :options="stimolazioneFrequenzaOptions"> </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCycleStimulationBurst") }}</label>
                    <b-form-input v-model="jsonData.stimolazioneConBurst" type="number"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPharmacology") }}</label>
                    <b-form-select v-model="jsonData.farmacologia1" :options="optionsFarmacologia">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="6" xl="6">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInterruptionMode") }}</label>
                    <b-form-select v-model="jsonData.modalitaDiInterruzione" :options="optionsInterruzione">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="8" xl="8"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPharmacology") }}</label>
                    <b-form-select v-model="jsonData.farmacologia2" :options="optionsFarmacologia">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDcShock") }}</label>
                    <b-form-select v-model="jsonData.dcShock" :options="dcShockOptions"> </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblNotes") }}</label>
                    <b-form-textarea v-model="jsonData.aritmieIndotteNote" type="text" rows="6" no-resize> </b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblPharmacologyTest')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="4" xl="4"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExecuted") }}</label>
                    <b-form-select v-model="jsonData.eseguito" :options="optionsEseguito">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="8" xl="8"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExecutedWith") }}</label>
                    <b-form-input v-model="jsonData.eseguitoCon" type="text"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="10" xl="10"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDescription") }}</label>
                    <b-form-input v-model="jsonData.descrizioneTestFarmacologico" type="text"> </b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="2"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblEffectiveness") }}</label>
                    <b-form-select v-model="jsonData.efficace" :options="optionsEfficace">
                        <template #first>
                            <b-form-select-option :value="null" disabled>
                                {{ firstElementSelect }}
                            </b-form-select-option>
                        </template>
                    </b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12"
                    ><label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblProceduralComplications") }}</label>
                    <b-form-textarea v-model="jsonData.complicanzeProcedurali" type="text" rows="6" no-resize> </b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" :header="getLabelTraduora('patient.cardiology.lblConclusions')" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblConclusions") }}</label>
                    <b-form-textarea v-model="jsonData.conclusioni" type="text" rows="6" no-resize> </b-form-textarea>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import UtilityMixins from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixins],
    components: { DatePicker },
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
        jsonData: {
            type: Object,
            default: function () {
                return { dataEvento: null };
            },
        },
    },
    data() {
        return {
            linkback: null,
            tabIndex: 0,
            childKey: 0,
            showModalLoading: false,
            pathResource: "/cardiostudielettrofisiologici",
            stimolazioneFrequenzaOptions: [],
            dcShockOptions: [],
            firstElementSelect: this.getLabelTraduora("patient.ecoreferting.lblSelected"),
            optionsConclusioni: [],
            optionsSostenuta: [],
            optionsTipologia: [],
            optionsMorfologia: [],
            optionsSedeStimolazione: [],
            optionsFarmacologia: [],
            optionsInterruzione: [],
            optionsEseguito: [],
            optionsEfficace: [],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
                me.jsonData.dataEvento = new Date().getTime();
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios.get(link).then((response) => {
                    this.$emit("update", response.data.data);
                    me.showModalLoading = false;
                });
            }
        },
    },
};
</script>
