<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data">{{ this.$i18n.t("patient.afa.lblFinalJudgement") }}</label> <b-form-textarea rows="4" max-rows="9" v-model="data.giudizioFinale" :disabled="!edit"></b-form-textarea>
        </b-col>
      </b-row>
      <b-row>
        <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-form-label">{{ getLabelTraduora("therapy.lblStatus") }}:</span>
      </b-col> -->
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data-no-new-line">{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}: </label>
          <span class="sa-data"> {{ data.firmaEo }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data-no-new-line">{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}: </label>
          <span class="sa-data"> {{ data.firmaEcg }}</span>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
          <label class="sa-label-data-no-new-line">{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}: </label>
          <span class="sa-data"> {{ data.firmaGf }}</span>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
  mixins: [UtilityMixin],
  props: ["data", "edit"],
  data() {
    return {};
  },
};
</script>
