<template>
    <div>
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <b-table ref="tblDiagnosi" sticky-header stacked="xl" striped hover itemscope :items="listaDiagnosi" :fields="fieldsDiagnosi" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template #cell(descrizioneIcdix)="item">
                        <b-form-input v-model="item.item.descrizioneIcdix" disabled></b-form-input>
                    </template>
                    <template #cell(codiceIcdix)="item">
                        <b-form-input v-model="item.item.codiceIcdix" disabled></b-form-input>
                    </template>
                    <template #cell(actions)="row">
                        <b-button v-if="row.index == listaDiagnosi.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddCodiceIcdIx(row.item)">
                            <b-icon icon="plus"></b-icon>
                        </b-button>
                        <b-button v-if="row.index !== listaDiagnosi.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(listaDiagnosi, row)">
                            <b-icon icon="trash"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-modal ref="mdlCodiciIcdIx" id="mdlCodiciIcdIx" size="lg" scrollable @ok="onOkMlCodiciIcdIx" @hidden="onHiddenMlCodiciIcdIx" @show="onShowMlCodiciIcdIx">
            <b-form @submit.prevent="onSubmit" @reset="onReset">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice ICD-IX</label>
                        <b-form-input v-model="filtro.codice"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Descrizione ICD-IX</label>
                        <b-form-input v-model="filtro.descrizione"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="primary">Cerca</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("patients.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>Record Totali: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
            <b-table ref="tblDiagnosi" sticky-header stacked="xl" striped hover itemscope :items="listaCodiciIcdIx" :fields="fieldsCodiciIcdIx" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table" selectable @row-selected="onRowSelected">
                <template #cell(selected)="{ rowSelected }">
                    <template v-if="rowSelected">
                        <span aria-hidden="true">&check;</span>
                        <span class="sr-only">Selected</span>
                    </template>
                    <template v-else>
                        <span aria-hidden="true">&nbsp;</span>
                        <span class="sr-only">Not selected</span>
                    </template>
                </template>
                <template #cell(index)="row">
                    {{ row.index + 1 }}
                </template>
            </b-table>
        </b-modal>
    </div>
</template>
<script>
import axios from "axios";
export default {
    props: {
        listaDiagnosi: {
            type: Array[Object],
            default: function () {
                return {
                    descrizioneIcdix: "",
                    codiceIcdix: "",
                };
            },
        },
    },
    data() {
        return {
            pathResource: "/serviziicdix",
            currentPage: 1,
            perPage: 30,
            rows: null,
            listaDiagnosiSelezionate: [{ descrizione: "", codice: "" }],
            listaCodiciIcdIx: [],
            righeSelezionate: [],
            filtro: { descrizione: "", codice: "" },
            fieldsDiagnosi: [
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 0.2rem",
                    tdClass: "text-center",
                },
                {
                    label: "Diagnosi",
                    key: "descrizioneIcdix",
                    sortable: false,
                },
                {
                    label: "Codice Icd-IX",
                    key: "codiceIcdix",
                    thStyle: "width: 16rem",
                    sortable: false,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                    tdClass: "text-center",
                },
            ],
            fieldsCodiciIcdIx: [
                {
                    label: "",
                    key: "selected",
                    thStyle: "width: 0.2rem",
                    tdClass: "text-center",
                },
                {
                    label: "",
                    key: "index",
                    thStyle: "width: 0.2rem",
                    tdClass: "text-center",
                },
                {
                    label: "Diagnosi",
                    key: "descrizioneIcdix",
                    sortable: false,
                },
                {
                    label: "Codice Icd-IX",
                    key: "codiceIcdix",
                    thStyle: "width: 16rem",
                    sortable: false,
                },
                // {
                //     label: "",
                //     key: "actions",
                //     // thStyle: "width: 9rem",
                //     tdClass: "text-center",
                // },
            ],
        };
    },
    // computed: {
    //     rows() {
    //         return this.listaCodiciIcdIx.length;
    //     },
    // },
    // mounted() {
    //     let me = this;
    //     me.loadCodiciIcdIx();
    // },
    watch: {
        currentPage() {
            let me = this;
            me.loadCodiciIcdIx();
        },
    },
    methods: {
        loadCodiciIcdIx() {
            let me = this;
            me.showModalLoading = true;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.listaCodiciIcdIx = response.data.data.list;
                    me.rows = response.data.data.recordsNumber;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        onAddCodiceIcdIx() {
            let me = this;
            me.$refs.mdlCodiciIcdIx.show();
        },
        onDelete(array, row) {
            if (row.index != array.length) {
                this.$bvModal
                    .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
                        title: "Titolo",
                        buttonSize: "sm",
                        okVariant: "secondary",
                        cancelVariant: "danger",
                        okTitle: "SI",
                        cancelTitle: "NO",
                        footerClass: "p-2",
                        hideHeaderClose: false,
                        centered: true,
                    })
                    .then((value) => {
                        if (value) {
                            array.splice(row.index, 1);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
        },
        onRowSelected(rows) {
            let me = this;
            me.righeSelezionate = rows;
        },
        onReset() {
            let me = this;
            me.filtro = { descrizione: "", codice: "" };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadCodiciIcdIx();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadCodiciIcdIx();
        },
        onShowMlCodiciIcdIx() {
            let me = this;
            me.loadCodiciIcdIx();
        },
        onHiddenMlCodiciIcdIx() {},
        onOkMlCodiciIcdIx() {
            let me = this;
            // me.listaDiagnosi = me.righeSelezionate;
            me.$emit("update", me.righeSelezionate);
        },
    },
};
</script>
