import SchedeMorte from "../schedemorte";
import CaricamentoCsv from "../caricamentocsv";
import ExportMdb from "../exportmdb";
import ExportExcel from "../exportexcel";

const routes = [
    { name: "SchedaMorteList", path: "/rencamschedemorte", component: SchedeMorte.SchedaMorteList, meta: { title: "Schede di Morte" } },
    { name: "SchedaMorteEdit", path: "/rencamschedemorte/edit/:id", component: SchedeMorte.SchedaMorteEdit, meta: { title: "Scheda di Morte Edit" } },
    { name: "RencamSchedeMorteCaricamentoCsvList", path: "/rencamcaricamentocsv", component: CaricamentoCsv.RencamSchedeMorteCaricamentoCsvList, meta: { title: "Rencam Caricamento CSV" } },
    { name: "RencamSchedeMorteCaricamentoCsvDetailsList", path: "/rencamcaricamentocsv/view/:id", component: CaricamentoCsv.RencamSchedeMorteCaricamentoCsvDetailsList, meta: { title: "Rencam Caricamento CSV Details" } },
    { name: "RencamSchedeMorteExportMdbList", path: "/rencamexportmdb", component: ExportMdb.RencamSchedeMorteExportMdbList, meta: { title: "Rencam Export MDB" } },
    { name: "RencamSchedeMorteExportExcelList", path: "/rencamexportexcel", component: ExportExcel.RencamSchedeMorteExportExcelList, meta: { title: "Rencam Export CSV" } },
    { name: "SchedaMorteCodificaGemo", path: "/rencam/schedemortecodificagemo", component: SchedeMorte.SchedaMorteCodificaGemo, meta: { title: "Scheda di Morte Codifica Gemo" } },
];
export default routes;
