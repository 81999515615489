<template>
  <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Gestore:</label>
        <b-form-select v-model="jsonData.gestore" :options="gestoreOptions" value-field="value" text-field="text" @change="onChangeGestore"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Tipo Firma:</label>
        <b-form-select v-model="jsonData.tipoFirma" :options="tipoFirmaOptions" :value="''" value-field="value" text-field="text" :disabled="tipoFirmaDisable"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Codice Dispositivo:</label>
        <b-form-input v-model="jsonData.codiceDispositivo"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Descrizione Dispositivo:</label>
        <b-form-input v-model="jsonData.descrizioneDispositivo"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Codice Struttura:</label>
        <b-form-input v-model="jsonData.codiceStruttura"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Descrizione Struttura:</label>
        <b-form-input v-model="jsonData.descrizioneStruttura"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Codice Unita Operativa:</label>
        <b-form-input v-model="jsonData.codiceUnitaOperativa"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
        <label class="sa-label-data">Descrizione Unita Operativa:</label>
        <b-form-input v-model="jsonData.descrizioneUnitaOperativa"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Username Account:</label>
        <b-form-input v-model="jsonData.usernameAccount"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Password Account:</label>
        <b-form-input v-model="jsonData.passwordAccount"></b-form-input>
      </b-col>
      <!--<b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Note</label>
        <b-form-textarea v-model="jsonData.note" rows="7"></b-form-textarea>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Url After Signed:</label>
        <b-form-input v-model="jsonData.urlAfterSigned"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Method After Signed:</label>
        <b-form-select v-model="jsonData.methodAfterSigned" :options="methodOptions" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Url After Signed Error:</label>
        <b-form-input v-model="jsonData.urlAfterSignedError"></b-form-input>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">M;ethod After Signed Error:</label>
        <b-form-select v-model="jsonData.methodAfterSignedError" :options="methodOptions" value-field="value" text-field="text"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Token</label>
        <b-form-textarea v-model="jsonData.token" rows="7"></b-form-textarea>
      </b-col> -->
    </b-row>
  </b-card>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      id: "-1",
      pathResource: "/digitalsigndevice",
      tipoFirmaDisable: true,
      gestoreOptions: [
        { text: "-Selezionare il Gestore-", value: "" },
        { text: "KEFIRMA", value: "KEFIRMA" },
        { text: "ARUBA", value: "ARUBA" },
        { text: "INFOCERT", value: "INFOCERT" },
      ],
      infocertOptions: [
        { text: "-Selezionare la Firma-", value: "" },
        { text: "REMOTA", value: "REMOTA" },
        { text: "AUTOMATICA", value: "AUTOMATICA" },
      ],
      arubaOptions: [
        { text: "-Selezionare la Firma-", value: "" },
        { text: "REMOTA", value: "REMOTA" },
        { text: "AUTOMATICA", value: "AUTOMATICA" },
      ],
      kefirmaOptions: [{ text: "GRAFOMETRICA", value: "GRAFOMETRICA" }],
      tipoFirmaOptions: [{ text: "-Selezionare la Firma-", value: "" }],
      jsonData: {
        gestore: "",
        tipoFirma: "",
        codiceDispositivo: "",
        descrizioneDispositivo: "",
        usernameAccount: "",
        passwordAccount: "",
        codiceStruttura: "",
        descrizioneStruttura: "",
        codiceUnitaOperativa: "",
        descrizioneUnitaOperativa: "",
      },
    };
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    loadData() {
      let me = this;
      this.$emit("beforeLoadData");
      if (me.id !== "-1") {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        axios.get(link + me.id).then((response) => {
          me.jsonData = response.data.data;
          me.changeGestore();
          this.$emit("afterLoadData");
        });
      } else {
        this.$emit("afterLoadData");
      }
    },
    onChangeGestore() {
      let me = this;
      me.jsonData.tipoFirma = "";
      me.changeGestore();
    },
    changeGestore() {
      let me = this;
      me.tipoFirmaDisable = false;
      me.tipoFirmaOptions = [];
      switch (me.jsonData.gestore) {
        case "ARUBA":
          me.tipoFirmaOptions = me.arubaOptions;
          break;
        case "INFOCERT":
          me.tipoFirmaOptions = me.infocertOptions;
          break;
        case "KEFIRMA":
          me.tipoFirmaOptions = me.kefirmaOptions;
          break;
      }
      if (me.tipoFirmaOptions.length === 1) {
        me.tipoFirmaDisable = true;
        me.jsonData.tipoFirma = me.tipoFirmaOptions[0].value;
      }
    },
  },
};
</script>

<style></style>
