import LeonidaWorkList from "./pages/LeonidaWorkList.vue";
import LeonidaVisitaMedicoSportivaView from "./pages/LeonidaVisitaMedicoSportivaView.vue";

import AtletiList from "./components/AtletiList.vue";
import AtletaView from "./components/AtletaView.vue";
import AtletaEdit from "./components/AtletaEdit.vue";
import PendingList from "./components/PendingList.vue";
import VisiteList from "./components/VisiteList.vue";
import AccertamentiSupplementatriDettagliEdit from "./components/AccertamentiSupplementariDettagliEdit.vue";
import AccertamentiSupplementatriDettagliView from "./components/AccertamentiSupplementariDettagliView.vue";
import AccertamentiSupplementatriDettagliViewOld from "./components/AccertamentiSupplementariDettagliViewOLD.vue";
import LeonidaFederazioniSportiveNazionaliList from "./components/LeonidaFederazioniSportiveNazionaliList.vue";
import LeonidaFederazioniSportiveNazionaliView from "./components/LeonidaFederazioniSportiveNazionaliView.vue";
import LeonidaFederazioniSportiveNazionaliEdit from "./components/LeonidaFederazioniSportiveNazionaliEdit.vue";
import LeonidaArchivioVisiteList from "./components/LeonidaArchivioVisiteList.vue";
import LeonidaAccettazioneTariffeList from "./components/LeonidaAccettazioneTariffeList.vue";
import LeonidaAccettazioneTariffeView from "./components/LeonidaAccettazioneTariffeView.vue";
import LeonidaAccettazioneTariffeEdit from "./components/LeonidaAccettazioneTariffeEdit.vue";

export default {
    LeonidaWorkList,
    LeonidaVisitaMedicoSportivaView,
    AccertamentiSupplementatriDettagliView,
    AccertamentiSupplementatriDettagliViewOld,
    AtletiList,
    AtletaView,
    AtletaEdit,
    PendingList,
    VisiteList,
    AccertamentiSupplementatriDettagliEdit,
    LeonidaFederazioniSportiveNazionaliList,
    LeonidaFederazioniSportiveNazionaliView,
    LeonidaFederazioniSportiveNazionaliEdit,
    LeonidaArchivioVisiteList,
    LeonidaAccettazioneTariffeList,
    LeonidaAccettazioneTariffeView,
    LeonidaAccettazioneTariffeEdit,
};
