<template>
    <div>
        <b-row>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-form-label">{{ getLabelTraduora("patient.cardiology.lblCommonIliacArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.artIliacaComuneSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.artIliacaComuneVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.artIliacaComuneDescSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>

                <span class="sa-data">{{ jsonData.ccscoresn }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>

                <span class="sa-data">{{ jsonData.cccolsn }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>

                <span class="sa-data">{{ jsonData.maximtnearccsx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>

                <span class="sa-data">{{ jsonData.maximtfarccsx }}</span>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblExternalIliacArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.artIliacaEsternaSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.artIliacaEsternaVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.artIliacaEsternaDescSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col>

            <!---->
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblInternalIliacArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.artIliacaInternaSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.artIliacaInternaVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.artIliacaInternaDescSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblCommonFemoralArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.artFemoraleComSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.artFemoraleComVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.artFemoraleComDescSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>

                <span class="sa-data">{{ jsonData.bscoresx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>

                <span class="sa-data">{{ jsonData.bcolsn }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>

                <span class="sa-data">{{ jsonData.maximtnearbsx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>

                <span class="sa-data">{{ jsonData.maximtfarbsx }}</span>
            </b-col>
            <!--  -->
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFemoralBifurcation") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.biforcazioneFemoraleSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.biforcazioneFemoraleVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.biforcazioneFemoraleDescSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col>
            <b-col class="ft-color-section-title" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblDeepFemoralArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleProfSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleProfVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.arteriaFemoraleProfDescSx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblSuperiorFemoralArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.arteriaFemoraleSupProxSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.arteriaFemoraleSupProxVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.arteriaFemoraleSupProxDescSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
                <span class="sa-data">{{ jsonData.ciscoresn }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
                <span class="sa-data">{{ jsonData.cecolsn }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
                <span class="sa-data">{{ jsonData.maximtnearcisx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
                <span class="sa-data">{{ jsonData.maximtfarcisx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFemoralArteryMed") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.arteriaFemoraleSupMedSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.arteriaFemoraleSupMedVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.arteriaFemoraleSupProxDescSx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblFemoralArteryDist") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.arteriaFemoraleSupDistSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.arteriaFemoraleSupDistVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.arteriaFemoraleSupMedDescSx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPoplitealArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.arteriaPopliteaSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.arteriaPopliteaVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.arteriaPopliteaDescSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblScore") }}</label>
                <span class="sa-data">{{ jsonData.cescoresn }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblColor") }}</label>
                <span class="sa-data">{{ jsonData.cccolsn }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTNear") }}</label>
                <span class="sa-data">{{ jsonData.maximtnearcisx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblMaxIMTFar") }}</label>
                <span class="sa-data">{{ jsonData.maximtfarcisx }}</span>
            </b-col>
            <!------->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblAnteriorTibialArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.arteriaTibialeAntSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.arteriaTibialeAntVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.arteriaTibialeAntDescSx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblTibioPeronieroTrunk") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>
                <span class="sa-data">{{ jsonData.troncoTibioPeronieroSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>
                <span class="sa-data">{{ jsonData.troncoTibioPeronieroVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>
                <span class="sa-data">{{ jsonData.troncoTibioPeronieroDescSx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPosteriorTibialAtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.artTibialePostSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.artTibialePostVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.artTibialePostDescSx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                <label class="sa-label-data"></label>
            </b-col> -->
            <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblPeronieraArtery") }}</label>
            </b-col>
            <b-col xs="12" sm="12" md="2" lg="2" xl="2">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lbStenosis") }}</label>

                <span class="sa-data">{{ jsonData.arteriaPeronieraSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="1" lg="1" xl="1">
                <label class="sa-label-data">{{ getLabelTraduora("patient.cardiology.lblVPS") }}</label>

                <span class="sa-data">{{ jsonData.arteriaPeronieraVpsSx }}</span>
            </b-col>
            <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                <label class="sa-label-data">{{ getLabelTraduora("agende.lblDescription") }}</label>

                <span class="sa-data">{{ jsonData.arteriaPeronieraDescSx }}</span>
            </b-col>
            <!-- <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data"></label>
            </b-col> -->
        </b-row>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            type: Object,
            default: function () {
                return {
                    ccscoresn: null,
                };
            },
        },
    },
    data() {
        return {
            stenosiOptions: [
                { text: "0 - 29 %", value: "0-29%" },
                { text: "30 - 49 %", value: "30-49%" },
                { text: "50 - 69 %", value: "50-69%" },
                { text: "70 - 89 %", value: "70-89%" },
                { text: "90 - 99 %", value: "90-99%" },
                { text: this.getLabelTraduora("patient.cardiology.slcStenosisOcclusion"), value: "patient.cardiology.slcStenosisOcclusion" },
                { text: "NA", value: "NA" },
                { text: "NES", value: "NES" },
            ],
            colorOptions: [
                { text: "L", value: "patient.cardiology.slcColorValueLaminare" },
                { text: "T", value: "patient.cardiology.slcColorValueTurbolento" },
            ],
            scoreOptions: [
                { text: "0", value: "0" },
                { text: "1", value: "1" },
                { text: "2", value: "2" },
                { text: "3", value: "3" },
                { text: "4", value: "4" },
                { text: "5", value: "5" },
            ],
        };
    },
};
</script>

<style></style>
