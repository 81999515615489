<template>
  <div>
    <b-row>
      <b-col>
        <b-table sticky-header ref="tblSituazioneCognitiva" id="tblSituazioneCognitiva" responsive stacked="lg" striped hover :items="questionario" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(actions)="row">
            <b-form-group>
              <b-form-checkbox :checked="onRisposteChecked(row)" v-model="row.item.rispostaSelezionata" @change="onChangeCheckbox(row, $event)" :disabled="jsonData.questionarioNonSomministrabile || editSvamaB"></b-form-checkbox>
            </b-form-group>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12">
        <label class="sa-label-data">{{ getLabelTraduora("adi.lblTotalCognitiveAssessment") }}</label>
        <b-form-input v-model="jsonData.totaleValutazioneCognitiva" class="text-upper" disabled></b-form-input>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <b-col>
        <b-form-checkbox v-model="jsonData.questionarioNonSomministrabile" switch :disabled="editSvamaB">Questionario Situazione Cognitiva non somministrabile</b-form-checkbox>
      </b-col>
    </b-row>
    <div v-if="jsonData.questionarioNonSomministrabile">
      <b-row>
        <b-col cols="12">
          <label>{{ getLabelTraduora("adi.lblTestNotAdiministration") }}</label>
          <b-form-textarea v-model="jsonData.motivoTestNonSomministrabile" no-resize :disabled="editSvamaB"></b-form-textarea>
        </b-col>
        <b-col cols="12">
          <label>{{ getLabelTraduora("adi.lblBehavioralProblems") }}</label>
          <b-form-textarea v-model="jsonData.provvedimentiProblemiComportamentali" no-resize :disabled="editSvamaB"></b-form-textarea>
        </b-col>
        <b-col cols="12">
          <label>{{ getLabelTraduora("adi.lblPsychogeriatricSubjects") }}</label>
          <b-form-textarea v-model="jsonData.provvedimentiPazientiPsicogeriatrici" no-resize :disabled="editSvamaB"></b-form-textarea>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  props: {
    editSvamaB: Boolean,
    jsonData: {
      type: Object,
      return: {
        totaleValutazioneCognitiva: null,
        adiSvamaSchedaBSituazioneCognitivaBeans: [{ valore: null, risposta: "" }],
        questionarioNonSomministrabile: false,
        motivoTestNonSomministrabile: "",
        provvedimentiProblemiComportamentali: "",
        provvedimentiPazientiPsicogeriatrici: "",
      },
    },
  },
  mixins: [UtilityMixin],
  data() {
    return {
      questionario: [
        { descrizione: "Che giorno è oggi?", valore: 1 },
        { descrizione: "Che giorno è della settimana?", valore: 1 },
        { descrizione: "Come si chiama questo posto?", valore: 1 },
        { descrizione: "Qual è il suo indirizzo?", valore: 1 },
        { descrizione: "Quanti anni ha?", valore: 1 },
        { descrizione: "Qunado è nato?", valore: 1 },
        { descrizione: "Chi è il presidente della repubblica? (o il Papa?)", valore: 1 },
        { descrizione: "Chi era il presidente precedente? (o il Papa?)", valore: 1 },
        { descrizione: "Qual è il cognome da ragazza di sua madre?", valore: 1 },
        { descrizione: "Sottragga da 20 tre e poi ancora fino in fondo", valore: 1 },
      ],

      fields: [
        {
          label: "Domanda",
          key: "descrizione",
        },
        {
          label: "Risposta",
          key: "actions",
          thStyle: "width: 6rem",
        },
      ],
    };
  },
  mounted() {},
  watch: {
    "jsonData.questionarioNonSomministrabile": function (value) {
      let me = this;
      if (value) {
        me.jsonData.adiSvamaSchedaBSituazioneCognitivaBeans = [];
        me.jsonData.totaleValutazioneCognitiva = 0;
      }
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    onRisposteChecked(row) {
      let me = this;
      if (me.jsonData.questionarioNonSomministrabile) {
        row.item.rispostaSelezionata = false;
      }
      me.jsonData.adiSvamaSchedaBSituazioneCognitivaBeans.forEach((element) => {
        if (element.risposta === row.item.descrizione) {
          row.item.rispostaSelezionata = true;
        }
      });
    },
    onChangeCheckbox(row, value) {
      let me = this;
      row.item.rispostaSelezionata = value;
      if (value) {
        me.jsonData.adiSvamaSchedaBSituazioneCognitivaBeans.push({ risposta: row.item.descrizione, valore: row.item.valore });
      } else {
        me.jsonData.adiSvamaSchedaBSituazioneCognitivaBeans.forEach((element, index) => {
          if (element.risposta === row.item.descrizione) {
            me.jsonData.adiSvamaSchedaBSituazioneCognitivaBeans.splice(index, 1);
          }
        });
      }
      let sum = 0;
      me.jsonData.adiSvamaSchedaBSituazioneCognitivaBeans.forEach((element) => {
        sum = sum + element.valore;
      });
      me.jsonData.totaleValutazioneCognitiva = sum;
    },
  },
};
</script>
