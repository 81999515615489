<template>
  <div v-if="isEdit">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-label-data"> A e B Numero che hanno consumato l'alimento specifico</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-label-data"> C e D Numero che non hanno consumato l'alimento specifico</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <!-- <b-button size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" v-b-modal.mdlDettagli>
        <b-icon icon="plus"></b-icon>
      </b-button> -->
        <b-table sticky-header ref="tblTassoAttaccoAlimentoSpecifici" stacked="xl" striped hover itemscope :items="listaTassiAttaccoAlimentari" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="listaTassiAttaccoAlimentari">
            {{ listaTassiAttaccoAlimentari.index + 1 }}
          </template>
          <template #cell(alimento)="item">
            <b-form-input v-model="item.item.alimento"></b-form-input>
          </template>
          <template #cell(personeConsumatoAlimentiMalati)="item">
            <b-form-input v-model="item.item.personeConsumatoAlimentiMalati" type="number" @change="calcolaTotali(item.item)"></b-form-input>
          </template>
          <template #cell(personeConsumatoAlimentiSani)="item">
            <b-form-input v-model="item.item.personeConsumatoAlimentiSani" type="number" @change="calcolaTotali(item.item)"></b-form-input>
          </template>
          <template #cell(personeConsumatoAlimentiTotale)="item">
            <b-form-input v-model="item.item.personeConsumatoAlimentiTotale" type="number" disabled></b-form-input>
          </template>
          <template #cell(personeConsumatoAlimentiPercentualeMalati)="item">
            <b-form-input v-model="item.item.personeConsumatoAlimentiPercentualeMalati" type="number" disabled></b-form-input>
          </template>
          <template #cell(personeNonConsumatoAlimentiMalati)="item">
            <b-form-input v-model="item.item.personeNonConsumatoAlimentiMalati" type="number" @change="calcolaTotali(item.item)"></b-form-input>
          </template>
          <template #cell(personeNonConsumatoAlimentiSani)="item">
            <b-form-input v-model="item.item.personeNonConsumatoAlimentiSani" type="number" @change="calcolaTotali(item.item)"></b-form-input>
          </template>
          <template #cell(personeNonConsumatoAlimentiTotale)="item">
            <b-form-input v-model="item.item.personeNonConsumatoAlimentiTotale" type="number" disabled></b-form-input>
          </template>
          <template #cell(personeNonConsumatoAlimentiPercentualeMalati)="item">
            <b-form-input v-model="item.item.personeNonConsumatoAlimentiPercentualeMalati" type="number" disabled></b-form-input>
          </template>
          <template #cell(differenzaPercentuali)="item">
            <b-form-input v-model="item.item.differenzaPercentuali" type="number" disabled></b-form-input>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="row.index == listaTassiAttaccoAlimentari.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAdd(listaTassiAttaccoAlimentari, row.item)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== listaTassiAttaccoAlimentari.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(listaTassiAttaccoAlimentari, row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-label-data"> A e B Numero che hanno consumato l'alimento specifico</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-label-data"> C e D Numero che non hanno consumato l'alimento specifico</span>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <!-- <b-button size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" v-b-modal.mdlDettagli>
        <b-icon icon="plus"></b-icon>
      </b-button> -->
        <b-table sticky-header ref="tblTassoAttaccoAlimentoSpecifici" stacked="xl" striped hover itemscope :items="listaTassiAttaccoAlimentari" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="listaTassiAttaccoAlimentari">
            {{ listaTassiAttaccoAlimentari.index + 1 }}
          </template>
        </b-table>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import UtilityMixin from "../../../utility/UtilityMixin";
// import DatePicker from "vue2-datepicker";
export default {
  mixins: [UtilityMixin],
  // components: { DatePicker },
  props: {
    listaTassiAttaccoAlimentari: {
      type: Array,
      default: function () {
        return null;
      },
    },
    isEdit: Boolean,
  },
  watch: {
    listaTassiAttaccoAlimentari: {
      handler(listaTassiAttaccoAlimentari) {
        this.$emit("update", listaTassiAttaccoAlimentari);
      },
      deep: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 100,
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Alimento",
          key: "alimento",
          // thStyle: "width: 14rem",
          sortable: false,
        },
        {
          label: "(A) Malati",
          key: "personeConsumatoAlimentiMalati",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "(B) Sani",
          key: "personeConsumatoAlimentiSani",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "(A + B) Totale",
          key: "personeConsumatoAlimentiTotale",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "(%) Percentuale",
          key: "personeConsumatoAlimentiPercentualeMalati",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "(C) Malati",
          key: "personeNonConsumatoAlimentiMalati",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "(D) Sani",
          key: "personeNonConsumatoAlimentiSani",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "(C + D) Totale",
          key: "personeConsumatoAlimentiTotale",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "(%) Percentuale",
          key: "personeNonConsumatoAlimentiPercentualeMalati",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "Dif. %",
          key: "differenzaPercentuali",
          thStyle: "width: 10rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 5rem",
          tdClass: "text-center",
        },
      ],
    };
  },

  methods: {
    calcolaTotali(item) {
      item.personeConsumatoAlimentiTotale = Number(item.personeConsumatoAlimentiMalati) + Number(item.personeConsumatoAlimentiSani);
      item.personeNonConsumatoAlimentiTotale = Number(item.personeNonConsumatoAlimentiMalati) + Number(item.personeNonConsumatoAlimentiSani);
      item.personeConsumatoAlimentiPercentualeMalati = ((Number(item.personeConsumatoAlimentiMalati) / item.personeConsumatoAlimentiTotale) * 100).toFixed(2);
      item.personeNonConsumatoAlimentiPercentualeMalati = ((Number(item.personeNonConsumatoAlimentiMalati) / item.personeNonConsumatoAlimentiTotale) * 100).toFixed(2);
      item.differenzaPercentuali = (item.personeConsumatoAlimentiPercentualeMalati - item.personeNonConsumatoAlimentiPercentualeMalati).toFixed(2);
    },
    onAdd(array, value) {
      //   console.log(value);
      if (value.alimento !== "") {
        array.push({ alimento: "", personeConsumatoAlimentiMalati: 0, personeConsumatoAlimentiSani: 0, personeConsumatoAlimentiTotale: 0, personeConsumatoAlimentiPercentualeMalati: 0, personeNonConsumatoAlimentiMalati: 0, personeNonConsumatoAlimentiSani: 0, personeNonConsumatoAlimentiTotale: 0, personeNonConsumatoAlimentiPercentualeMalati: 0, differenzaPercentuali: 0 });
      } else {
        this.$bvModal
          .msgBoxOk("Tutti i campi sono OBBLIGATORI", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>

<style></style>
