<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}</label>
                    <span class="sa-data">{{ jsonData.giudizioFinale }}</span>
                </b-col>
            </b-row>
            <hr />
            <b-row>
                <!-- <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <span class="sa-form-label">{{ getLabelTraduora("therapy.lblStatus") }}:</span>
      </b-col> -->
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data-no-new-line">{{ getLabelTraduora("patient.afa.lblPhysicalExamination") }}:</label>
                    <span class="sa-data">{{ jsonData.firmaEo }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data-no-new-line">{{ getLabelTraduora("patient.afa.lblElectrocardiogram") }}:</label>
                    <span class="sa-data">{{ jsonData.firmaEcg }}</span>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data-no-new-line">{{ getLabelTraduora("patient.afa.lblFinalJudgement") }}:</label>
                    <span class="sa-data">{{ jsonData.firmaGf }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";

export default {
    mixins: [UtilityMixin],
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
    },
};
</script>
