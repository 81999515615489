import Anamnesi from "../anamnesi";
import CardioChirurgia from "../cardiochirurgia";
import Cardiomiopatie from "../cardiomiopatie";
import ConsulenzaTelematica from "../consulenzatelematica";
import CoronarografiaPtca from "../coronarografiaptca";
import DatiClinicoStrumRicovero from "../daticlinicostrumentaliricovero";
import DatiClinicoStrumentaliIctus from "../daticlinicostrumentaliictus";
import Ecocardiodati from "../ecocardiodati";
import Ecocarotidi from "../ecocarotidi";
import Ecostress from "../ecostress";
import EcocolordopplerAortaAddominale from "../ecocolordoppleraortaaddominale";
import EcocolordopplerArtiSuperiori from "../ecocolordopplerartisuperiori";
import EcoDopplerArtiInferiori from "../ecodopplerartiinferiori";
import EcoTransesofagea from "../ecotransesofagea";
import Emergenza from "../emergenza";
import Elettrostimolatore from "../elettrostimolatore";
import EsameEcg from "../esameecg";
import EsamiLaboratorio from "../esamilaboratorio";
import EsamiSpecialistici from "../esamispecialistici";
import EsamiStrumentali from "../esamistrumentali";
import SpectMiocardica from "../spectmiocardica";
import MonitoraggioEcg from "../monitoraggioecg";
import MonitoraggioPressione from "../monitoraggiopressione";
import PaceMakerImpianto from "../pacemakerimpianto";
import RefertazioneEcocardio from "../refertazioneecocardio";
import StudioElettrofisiologico from "../studioelettrofisiologico";
import TerapieComplicanze from "../terapiecomplicanze";
import TestProvocativi from "../testprovocativi";
import VisitaAngiologica from "../visitaangiologica";
import VisitaControlloEcg from "../visitacontrolloecg";

const routes = [
    { name: "AnamnesiList", path: "/paziente/cardiologia/anamnesi/", component: Anamnesi.AnamnesiList, meta: { title: "Anamnesi Cardiologia" } },
    { name: "AnamnesiView", path: "/paziente/cardiologia/anamnesi/view/:id", component: Anamnesi.AnamnesiView, meta: { title: "Anamnesi Cardiologia" } },
    { name: "AnamnesiEdit", path: "/paziente/cardiologia/anamnesi/edit/:id", component: Anamnesi.AnamnesiEdit, meta: { title: "Anamnesi Cardiologia" } },

      { name: "CoronarografiaPtcaList", path: "/paziente/cardiologia/coronarografiaptca/", component: CoronarografiaPtca.CoronarografiaPtcaList, meta: { title: "Coronarografia Ptca" } },
      { name: "CoronarografiaPtcaView", path: "/paziente/cardiologia/coronarografiaptca/view/:id", component: CoronarografiaPtca.CoronarografiaPtcaView, meta: { title: "Coronarografia Ptca" } },
      { name: "CoronarografiaPtcaEdit", path: "/paziente/cardiologia/coronarografiaptca/edit/:id", component: CoronarografiaPtca.CoronarografiaPtcaEdit, meta: { title: "Coronarografia Ptca" } },

      { name: "CardiomiopatieList", path: "/paziente/cardiologia/cardiomiopatie/", component: Cardiomiopatie.CardiomiopatieList, meta: { title: "Cardiomiopatie" } },
      { name: "CardiomiopatieView", path: "/paziente/cardiologia/cardiomiopatie/view/:id", component: Cardiomiopatie.CardiomiopatieView, meta: { title: "Cardiomiopatie" } },
      { name: "CardiomiopatieEdit", path: "/paziente/cardiologia/cardiomiopatie/edit/:id", component: Cardiomiopatie.CardiomiopatieEdit, meta: { title: "Cardiomiopatie" } },

      { name: "ChirurgiaList", path: "/paziente/cardiologia/cardiochirurgia/", component: CardioChirurgia.CardioChirurgiaList, meta: { title: "Chirurgia Cardiologia" } },
      { name: "ChirurgiaView", path: "/paziente/cardiologia/cardiochirurgia/view/:id", component: CardioChirurgia.CardioChirurgiaView, meta: { title: "Chirurgia Cardiologia" } },
      { name: "ChirurgiaEdit", path: "/paziente/cardiologia/cardiochirurgia/edit/:id", component: CardioChirurgia.CardioChirurgiaEdit, meta: { title: "Chirurgia Cardiologia" } },

    //   { name: "RefertazioneList", path: "/paziente/cardiologia/refertazione/", component: Cardiologia.CardioRefertazione.CardioRefertazioneList, meta: { title: "Refertazione Cardiologia" } },
    //   { name: "RefertazioneView", path: "/paziente/cardiologia/refertazione/view/:id", component: Cardiologia.CardioRefertazione.CardioRefertazioneView, meta: { title: "Refertazione Cardiologia" } },
    //   { name: "RefertazioneEdit", path: "/paziente/cardiologia/refertazione/edit/:id", component: Cardiologia.CardioRefertazione.CardioRefertazioneEdit, meta: { title: "Refertazione Cardiologia" } },

    //   { name: "EcostressList", path: "/paziente/cardiologia/ecostress/", component: Cardiologia.EcoStress.CardioEcostressList, meta: { title: "Ecostress" } },
    //   { name: "EcostressView", path: "/paziente/cardiologia/ecostress/view/:id", component: Cardiologia.EcoStress.CardioEcostressView, meta: { title: "Ecostress" } },
    //   { name: "EcostressEdit", path: "/paziente/cardiologia/ecostress/edit/:id", component: Cardiologia.EcoStress.CardioEcostressEdit, meta: { title: "Ecostress" } },

      { name: "EcodopplerArtiInferioriList", path: "/paziente/cardiologia/dopplerartiinferiori/", component: EcoDopplerArtiInferiori.EcodopplerArtiInferioriList, meta: { title: "Ecodoppler Arti Inferiori" } },
      { name: "EcodopplerArtiInferioriView", path: "/paziente/cardiologia/dopplerartiinferiori/view/:id", component: EcoDopplerArtiInferiori.EcodopplerArtiInferioriView, meta: { title: "Ecodoppler Arti Inferiori" } },
      { name: "EcodopplerArtiInferioriEdit", path: "/paziente/cardiologia/dopplerartiinferiori/edit/:id", component: EcoDopplerArtiInferiori.EcodopplerArtiInferioriEdit, meta: { title: "Ecodoppler Arti Inferiori" } },

      { name: "VisitaControlloEcgList", path: "/paziente/cardiologia/visitacontrolloecg/", component: VisitaControlloEcg.VisitaControlloEcgList, meta: { title: "Visita Controllo Ecg" } },
      { name: "VisitaControlloEcgView", path: "/paziente/cardiologia/visitacontrolloecg/view/:id", component: VisitaControlloEcg.VisitaControlloEcgView, meta: { title: "Visita Controllo Ecg" } },
      { name: "VisitaControlloEcgEdit", path: "/paziente/cardiologia/visitacontrolloecg/edit/:id", component: VisitaControlloEcg.VisitaControlloEcgEdit, meta: { title: "Visita Controllo Ecg" } },

      { name: "EcocarotidiList", path: "/paziente/cardiologia/ecocarotidi/", component: Ecocarotidi.EcocarotidiList, meta: { title: "Ecocarotidi" } },
      { name: "EcocarotidiView", path: "/paziente/cardiologia/ecocarotidi/view/:id", component: Ecocarotidi.EcocarotidiView, meta: { title: "Ecocarotidi" } },
      { name: "EcocarotidiEdit", path: "/paziente/cardiologia/ecocarotidi/edit/:id", component: Ecocarotidi.EcocarotidiEdit, meta: { title: "Ecocarotidi" } },

      { name: "ElettrostimolatoreList", path: "/paziente/cardiologia/elettrostimolatore/", component: Elettrostimolatore.ElettrostimolatoreList, meta: { title: "Elettrostimolatore" } },
      { name: "ElettrostimolatoreView", path: "/paziente/cardiologia/elettrostimolatore/view/:id", component: Elettrostimolatore.ElettrostimolatoreView, meta: { title: "Elettrostimolatore" } },
      { name: "ElettrostimolatoreEdit", path: "/paziente/cardiologia/elettrostimolatore/edit/:id", component: Elettrostimolatore.ElettrostimolatoreEdit, meta: { title: "Elettrostimolatore" } },

      { name: "CardioVisitaAngiologicaList", path: "/paziente/cardiologia/visitaangiologica/", component: VisitaAngiologica.VisitaAngiologicaList, meta: { title: "Visita Angiologica" } },
      { name: "CardioVisitaAngiologicaView", path: "/paziente/cardiologia/visitaangiologica/view/:id", component: VisitaAngiologica.VisitaAngiologicaView, meta: { title: "Visita Angiologica" } },
      { name: "CardioVisitaAngiologicaEdit", path: "/paziente/cardiologia/visitaangiologica/edit/:id", component: VisitaAngiologica.VisitaAngiologicaEdit, meta: { title: "Visita Angiologica" } },

      { name: "ConsulenzaTelematicaList", path: "/paziente/cardiologia/consulenzatelematica", component: ConsulenzaTelematica.ConsulenzaTelematicaList, meta: { title: "Consulenza Telematica" } },
      { name: "ConsulenzaTelematicaView", path: "/paziente/cardiologia/consulenzatelematica/view/:id", component: ConsulenzaTelematica.ConsulenzaTelematicaView, meta: { title: "Consulenza Telematica" } },
      { name: "ConsulenzaTelematicaEdit", path: "/paziente/cardiologia/consulenzatelematica/edit/:id", component: ConsulenzaTelematica.ConsulenzaTelematicaEdit, meta: { title: "Consulenza Telematica" } },

    //   { name: "ConsulenzaEsternaList", path: "/paziente/cardiologia/consulenzaesterna", component: Cardiologia.ConsulenzaEsterna.ConsulenzaEsternaList, meta: { title: "Consulenza Esterna" } },
    //   { name: "ConsulenzaEsternaView", path: "/paziente/cardiologia/consulenzaesterna/view/:id", component: Cardiologia.ConsulenzaEsterna.ConsulenzaEsternaView, meta: { title: "Consulenza Esterna" } },
    //   { name: "ConsulenzaEsternaEdit", path: "/paziente/cardiologia/consulenzaesterna/edit/:id", component: Cardiologia.ConsulenzaEsterna.ConsulenzaEsternaEdit, meta: { title: "Consulenza Esterna" } },

      { name: "TerapieComplicanzeList", path: "/paziente/cardiologia/terapiecomplicanze", component: TerapieComplicanze.TerapieComplicanzeList, meta: { title: "Terapie Complicanze" } },
      { name: "TerapieComplicanzeView", path: "/paziente/cardiologia/terapiecomplicanze/view/:id", component: TerapieComplicanze.TerapieComplicanzeView, meta: { title: "Terapie Complicanze" } },
      { name: "TerapieComplicanzeEdit", path: "/paziente/cardiologia/terapiecomplicanze/edit/:id", component: TerapieComplicanze.TerapieComplicanzeEdit, meta: { title: "Terapie Complicanze" } },

      { name: "EcocardioDatiList", path: "/paziente/cardiologia/ecocardiodati/", component: Ecocardiodati.EcocardioDatiList, meta: { title: "Ecocardio Dati"}},
      { name: "EcocardioDatiView", path: "/paziente/cardiologia/ecocardiodati/view/:id", component: Ecocardiodati.EcocardioDatiView, meta: {title: "Ecocardio Dati"}},
      { name: "EcocardioDatiEdit", path: "/paziente/cardiologia/ecocardiodati/edit/:id", component: Ecocardiodati.EcocardioDatiEdit, meta: {title: "Ecocardio Dati"}},

      { name: "RefertazioneEcocardioList", path: "/paziente/cardiologia/refertazioneecocardio", component: RefertazioneEcocardio.RefertazioneEcocardioList, meta: { title: "Refertazione Ecocardio" } },
      { name: "RefertazioneEcocardioView", path: "/paziente/cardiologia/refertazioneecocardio/view/:id", component: RefertazioneEcocardio.RefertazioneEcocardioView, meta: { title: "Refertazione Ecocardio" } },
      { name: "RefertazioneEcocardioEdit", path: "/paziente/cardiologia/refertazioneecocardio/edit/:id", component: RefertazioneEcocardio.RefertazioneEcocardioEdit, meta: { title: "Refertazione Ecocardio" } },

      { name: "EcoColorDopplerAortaAddominaleList", path: "/paziente/cardiologia/ecocolordoppleraortaaddominale", component: EcocolordopplerAortaAddominale.EcocolordopplerAortaAddominaleList, meta: { title: "patient.menu.cardiology.menuAbdominalAortaEcocolordoppler" } },
      { name: "EcoColorDopplerAortaAddominaleView", path: "/paziente/cardiologia/ecocolordoppleraortaaddominale/view/:id", component: EcocolordopplerAortaAddominale.EcocolordopplerAortaAddominaleView, meta: { title: "patient.menu.cardiology.menuAbdominalAortaEcocolordoppler" } },
      { name: "EcoColorDopplerAortaAddominaleEdit", path: "/paziente/cardiologia/ecocolordoppleraortaaddominale/edit/:id", component: EcocolordopplerAortaAddominale.EcocolordopplerAortaAddominaleEdit, meta: { title: "patient.menu.cardiology.menuAbdominalAortaEcocolordoppler" } },

      { name: "EcocolordopplerArtiSuperioriList", path: "/paziente/cardiologia/ecocolordopplerartisuperiori", component: EcocolordopplerArtiSuperiori.EcocolordopplerArtiSuperioriList, meta: { title: "patient.menu.cardiology.menuUpperArmsEcocolordoppler" } },
      { name: "EcocolordopplerArtiSuperioriView", path: "/paziente/cardiologia/ecocolordopplerartisuperiori/view/:id", component: EcocolordopplerArtiSuperiori.EcocolordopplerArtiSuperioriView, meta: { title: "patient.menu.cardiology.menuUpperArmsEcocolordoppler" } },
      { name: "EcocolordopplerArtiSuperioriEdit", path: "/paziente/cardiologia/ecocolordopplerartisuperiori/edit/:id", component: EcocolordopplerArtiSuperiori.EcocolordopplerArtiSuperioriEdit, meta: { title: "patient.menu.cardiology.menuUpperArmsEcocolordoppler" } },

      { name: "TestProvocativiList", path: "/paziente/cardiologia/testprovocativi", component: TestProvocativi.TestProvocativiList, meta: { title: "patient.menu.cardiology.menuProvocativeTest" } },
      { name: "TestProvocativiView", path: "/paziente/cardiologia/testprovocativi/view/:id", component: TestProvocativi.TestProvocativiView, meta: { title: "patient.menu.cardiology.menuProvocativeTest" } },
      { name: "TestProvocativiEdit", path: "/paziente/cardiologia/testprovocativi/edit/:id", component: TestProvocativi.TestProvocativiEdit, meta: { title: "patient.menu.cardiology.menuProvocativeTest" } },

      { name: "StudioElettrofisiologicoList", path: "/paziente/cardiologia/studioelettrofiologico", component: StudioElettrofisiologico.StudioElettrofisiologicoList, meta: { title: "patient.menu.cardiology.menuElectrofiologicStudy" } },
      { name: "StudioElettrofisiologicoView", path: "/paziente/cardiologia/studioelettrofiologico/view/:id", component: StudioElettrofisiologico.StudioElettrofisiologicoView, meta: { title: "patient.menu.cardiology.menuElectrofiologicStudy" } },
      { name: "StudioElettrofisiologicoEdit", path: "/paziente/cardiologia/studioelettrofiologico/edit/:id", component: StudioElettrofisiologico.StudioElettrofisiologicoEdit, meta: { title: "patient.menu.cardiology.menuElectrofiologicStudy" } },

      { name: "EsamiLaboratorioList", path: "/paziente/cardiologia/esamilaboratorio", component: EsamiLaboratorio.EsamiLaboratorioList, meta: { title: "patient.menu.cardiology.menuLaboratoryExams" } },
      { name: "EsamiLaboratorioView", path: "/paziente/cardiologia/esamilaboratorio/view/:id", component: EsamiLaboratorio.EsamiLaboratorioView, meta: { title: "patient.menu.cardiology.menuLaboratoryExams" } },
      { name: "EsamiLaboratorioEdit", path: "/paziente/cardiologia/esamilaboratorio/edit/:id", component: EsamiLaboratorio.EsamiLaboratorioEdit, meta: { title: "patient.menu.cardiology.menuLaboratoryExams" } },

      { name: "EsameEcgList", path: "/paziente/cardiologia/esameecg", component: EsameEcg.EsameEcgList, meta: { title: "patient.menu.cardiology.menuEcgExam" } },
      { name: "EsameEcgView", path: "/paziente/cardiologia/esameecg/view/:id", component: EsameEcg.EsameEcgView, meta: { title: "patient.menu.cardiology.menuEcgExam" } },
      { name: "EsameEcgEdit", path: "/paziente/cardiologia/esameecg/edit/:id", component: EsameEcg.EsameEcgEdit, meta: { title: "patient.menu.cardiology.menuEcgExam" } },

      { name: "EmergenzaList", path: "/paziente/cardiologia/emergenza", component: Emergenza.EmergenzaList, meta: { title: "patient.menu.cardiology.menuEmergency" } },
      { name: "EmergenzaView", path: "/paziente/cardiologia/emergenza/view/:id", component: Emergenza.EmergenzaView, meta: { title: "patient.menu.cardiology.menuEmergency" } },
      { name: "EmergenzaEdit", path: "/paziente/cardiologia/emergenza/edit/:id", component: Emergenza.EmergenzaEdit, meta: { title: "patient.menu.cardiology.menuEmergency" } },

      { name: "SpectMiocardicaList", path: "/paziente/cardiologia/spectmiocardica", component: SpectMiocardica.SpectMiocardicaList, meta: { title: "Spect Miocardica" } },
      { name: "SpectMiocardicaView", path: "/paziente/cardiologia/spectmiocardica/view/:id", component: SpectMiocardica.SpectMiocardicaView, meta: { title: "Spect Miocardica" } },
      { name: "SpectMiocardicaEdit", path: "/paziente/cardiologia/spectmiocardica/edit/:id", component: SpectMiocardica.SpectMiocardicaEdit, meta: { title: "Spect Miocardica Nuova" } },

      { name: "PaceMakerImpiantoList", path: "/paziente/cardiologia/pacemakerimpianto", component: PaceMakerImpianto.PaceMakerImpiantoList, meta: { title: "patient.menu.cardiology.menuPaceMakerInplant" } },
      { name: "PaceMakerImpiantoView", path: "/paziente/cardiologia/pacemakerimpianto/view/:id", component: PaceMakerImpianto.PaceMakerImpiantoView, meta: { title: "patient.menu.cardiology.menuPaceMakerInplant" } },
      { name: "PaceMakerImpiantoEdit", path: "/paziente/cardiologia/pacemakerimpianto/edit/:id", component: PaceMakerImpianto.PaceMakerImpiantoEdit, meta: { title: "patient.menu.cardiology.menuPaceMakerInplant" } },

      { name: "EcoTransesofageaList", path: "/paziente/cardiologia/ecotransesofagea", component: EcoTransesofagea.EcoTransesofageaList, meta: { title: "patient.menu.cardiologyEcoTransesofagea" } },
      { name: "EcoTransesofageaView", path: "/paziente/cardiologia/ecotransesofagea/view/:id", component: EcoTransesofagea.EcoTransesofageaView, meta: { title: "patient.menu.cardiologyEcoTransesofagea" } },
      { name: "EcoTransesofageaEdit", path: "/paziente/cardiologia/ecotransesofagea/edit/:id", component: EcoTransesofagea.EcoTransesofageaEdit, meta: { title: "patient.menu.cardiologyEcoTransesofagea" } },

      { name: "EsamiSpecialisticiList", path: "/paziente/cardiologia/esamispecialistici", component: EsamiSpecialistici.EsamiSpecialisticiList, meta: { title: "patient.menu.cardiology.menuSpecialisticExams" } },
      { name: "EsamiSpecialisticiView", path: "/paziente/cardiologia/esamispecialistici/view/:id", component: EsamiSpecialistici.EsamiSpecialisticiView, meta: { title: "patient.menu.cardiology.menuSpecialisticExams" } },
      { name: "EsamiSpecialisticiEdit", path: "/paziente/cardiologia/esamispecialistici/edit/:id", component: EsamiSpecialistici.EsamiSpecialisticiEdit, meta: { title: "patient.menu.cardiology.menuSpecialisticExams" } },

      { name: "DatiClinicoStrumentaliIctusList", path: "/paziente/cardiologia/daticlinicostrumentaliictus", component: DatiClinicoStrumentaliIctus.DatiClinicoStrumentaliIctusList, meta: { title: "patient.menu.cardiology.menuClinicalInstrumentalStrokeData" } },
      { name: "DatiClinicoStrumentaliIctusView", path: "/paziente/cardiologia/daticlinicostrumentaliictus/view/:id", component: DatiClinicoStrumentaliIctus.DatiClinicoStrumentaliIctusView, meta: { title: "patient.menu.cardiology.menuClinicalInstrumentalStrokeData" } },
      { name: "DatiClinicoStrumentaliIctusEdit", path: "/paziente/cardiologia/daticlinicostrumentaliictus/edit/:id", component: DatiClinicoStrumentaliIctus.DatiClinicoStrumentaliIctusEdit, meta: { title: "patient.menu.cardiology.menuClinicalInstrumentalStrokeData" } },

      { name: "DatiClinicoStrumRicoveroList", path: "/paziente/cardiologia/daticlinicostrumentaliricovero", component: DatiClinicoStrumRicovero.DatiClinicoStrumRicoveroList, meta: { title: "patient.menu.cardiology.menuClinicalInstrumentalRecoveryData" } },
      { name: "DatiClinicoStrumRicoveroView", path: "/paziente/cardiologia/daticlinicostrumentaliricovero/view/:id", component: DatiClinicoStrumRicovero.DatiClinicoStrumRicoveroView, meta: { title: "patient.menu.cardiology.menuClinicalInstrumentalRecoveryData" } },
      { name: "DatiClinicoStrumRicoveroEdit", path: "/paziente/cardiologia/daticlinicostrumentaliricovero/edit/:id", component: DatiClinicoStrumRicovero.DatiClinicoStrumRicoveroEdit, meta: { title: "patient.menu.cardiology.menuClinicalInstrumentalRecoveryData" } },

      { name: "MonitoraggioPressioneList", path: "/paziente/cardiologia/monitoraggiopressione", component: MonitoraggioPressione.MonitoraggioPressioneList, meta: { title: "patient.menu.cardiology.menuPressureMonitoring" } },
      { name: "MonitoraggioPressioneView", path: "/paziente/cardiologia/monitoraggiopressione/view/:id", component: MonitoraggioPressione.MonitoraggioPressioneView, meta: { title: "patient.menu.cardiology.menuPressureMonitoring" } },
      { name: "MonitoraggioPressioneEdit", path: "/paziente/cardiologia/monitoraggiopressione/edit/:id", component: MonitoraggioPressione.MonitoraggioPressioneEdit, meta: { title: "patient.menu.cardiology.menuPressureMonitoring" }},

      { name: "MonitoraggioEcgList", path: "/paziente/cardiologia/monitoraggioecg", component: MonitoraggioEcg.MonitoraggioEcgList, meta: { title: "patient.menu.cardiology.menuEcgMonitoring" } },
      { name: "MonitoraggioEcgView", path: "/paziente/cardiologia/monitoraggioecg/view/:id", component: MonitoraggioEcg.MonitoraggioEcgView, meta: { title: "patient.menu.cardiology.menuEcgMonitor"}},
      { name: "MonitoraggioEcgEdit", path: "/paziente/cardiologia/monitoraggioecg/edit/:id", component: MonitoraggioEcg.MonitoraggioEcgEdit, meta: { title: "patient.menu.cardiology.menuEcgMonitoring" } },
      
      { name: "EsamiStrumentaliList", path: "/paziente/cardiologia/esamistrumentali", component: EsamiStrumentali.EsamiStrumentaliList, meta: { title: "Esami Strumentali" } },
      { name: "EsamiStrumentaliView", path: "/paziente/cardiologia/esamistrumentali/view/:id", component: EsamiStrumentali.EsamiStrumentaliView, meta: { title: "Esami Strumentali"}},
      { name: "EsamiStrumentaliEdit", path: "/paziente/cardiologia/esamistrumentali/edit/:id", component: EsamiStrumentali.EsamiStrumentaliEdit, meta: { title: "Esami Strumentali Nuovo" } },
      
      { name: "CardioEcostressList", path: "/paziente/cardiologia/ecostress", component: Ecostress.CardioEcostressList, meta: { title: "Ecostress" } },
      { name: "CardioEcostressView", path: "/paziente/cardiologia/ecostress/view/:id", component: Ecostress.CardioEcostressView, meta: { title: "Ecostress"}},
      { name: "CardioEcostressEdit", path: "/paziente/cardiologia/ecostress/edit/:id", component: Ecostress.CardioEcostressEdit, meta: { title: "Ecostress Nuovo" } },
];
export default routes;
