export default {
    getLinkPatient(utente, idPaziente) {
        if (utente.gruppo) {
            if (utente.gruppo.menuGruppo) {
                if (utente.gruppo.menuGruppo.length == 1) {
                    if (utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                        return "/paziente/view/" + idPaziente;
                    } else if (utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                        return "view/" + idPaziente;
                    } else {
                        return "homepaziente";
                    }
                } else {
                    return "";
                }
            } else {
                return "";
            }
        } else {
            return "";
        }
    },
};
