<template>
  <sa-page-layout :btnNewVisible="true" :showModalLoading="showModalLoading" :btnRefreshVisible="true" @refresh="onRefresh" :linkedit="linkedit">
    <template slot="table-header">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" stacked="lg" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="item">
            {{ item.index + 1 }}
          </template>
          <template #cell(hubUUID)="row"> {{ row.item.hubName }} ({{ row.item.hubUUID }}) </template>

          <template #cell(show_details)="row">
            <b-button size="sm" @click="row.toggleDetails" class="mr-2" variant="outline-info"> {{ row.detailsShowing ? "Hide" : "Show" }} Details</b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <div class="container-fluid">
                <b-row class="mb-4">
                  <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <b>Temperature : </b>
                    <template v-if="row.item.temperatureName">
                      <span>{{ row.item.temperatureName.toUpperCase() }}</span></template
                    >
                    <template v-else><span> N/A</span></template>
                  </b-col>

                  <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <b>Glucose : </b>
                    <template v-if="row.item.glucoseName">
                      <span>{{ row.item.glucoseName.toUpperCase() }}</span></template
                    >
                    <template v-else><span> N/A</span></template>
                  </b-col>

                  <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <b>Ecg : </b>
                    <template v-if="row.item.ecgName">
                      <span>{{ row.item.ecgName.toUpperCase() }}</span></template
                    >
                    <template v-else><span> N/A</span></template>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <b>Spo2 : </b>
                    <template v-if="row.item.spo2Name">
                      <span>{{ row.item.spo2Name.toUpperCase() }}</span></template
                    >
                    <template v-else><span> N/A</span></template>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <b>Pressure : </b>
                    <template v-if="row.item.pressureName">
                      <span>{{ row.item.pressureName.toUpperCase() }}</span></template
                    >
                    <template v-else><span> N/A</span></template>
                  </b-col>

                  <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <b>Bracelet : </b>
                    <template v-if="row.item.braceletName">
                      <span>{{ row.item.braceletName.toUpperCase() }}</span></template
                    >
                    <template v-else><span> N/A</span></template>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <b>Scale : </b>
                    <template v-if="row.item.scaleName">
                      <span>{{ row.item.scaleName.toUpperCase() }}</span></template
                    >
                    <template v-else><span> N/A</span></template>
                  </b-col>
                  <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                    <b>Speed Sensor : </b>
                    <template v-if="row.item.speedSensorName">
                      <span>{{ row.item.speedSensorName.toUpperCase() }}</span></template
                    >
                    <template v-else><span> N/A</span></template>
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </template>
          <template v-slot:cell(kitId)="{ item }">
            <router-link class="sa-edit-link" :to="'hassistokit/edit/' + item.kitId"> {{ item.kitId }} </router-link>
          </template>
          <template #cell(delete)="row">
            <b-button variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" size="sm" @click="onDeleteItem(row.item)"><b-icon icon="trash"></b-icon></b-button>
          </template>
        </b-table>
      </div>
    </template>
    <template slot="table-footer">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col lg="3">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
        </b-col>
        <b-col lg="9">
          <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  props: {
    disable: { type: Boolean, default: false },
  },
  components: { SaPageLayout },
  computed: {
    rows() {
      return this.items.length;
    },
  },
  data() {
    return {
      default: "Nessun dispositivo Associato",
      showModalLoading: false,
      perPage: 10,
      currentPage: 1,
      pathResource: "hassistokits",
      linkedit: "/hassistokit",
      items: [],
      fields: [
        {
          label: "",
          key: "index",
          thStyle: "width: 3rem",
          tdClass: "text-center",
        },
        {
          label: "Id",
          key: "kitId",
          sortable: true,
          thStyle: "width: 5rem",
          tdClass: "text-left",
        },
        {
          label: "Paziente",
          key: "patientId",
          sortable: true,
          thStyle: "width: 7rem",
          tdClass: "text-left",
        },
        {
          label: "Hub Uuid",
          key: "hubUUID",
          sortable: true,
          thStyle: "width: 12rem",
          tdClass: "text-left",
        },

        {
          label: "Url",
          key: "url",
          sortable: true,
          thStyle: "width: 10rem",
          tdClass: "text-left",
        },
        {
          label: "",
          key: "show_details",
          tdClass: "text-center",
        },
        {
          label: "",
          key: "delete",
          thStyle: "width: 8rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/" + me.pathResource;
      me.showModalLoading = true;
      axios
        .get(link)
        .then((response) => {
          me.showModalLoading = false;
          if (response.data.data !== null) {
            me.items = response.data.data;
          }
        })
        .catch((e) => {
          me.showModalLoading = false;
          console.log(e);
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onDeleteItem(item) {
      let me = this;
      this.$bvModal
        .msgBoxConfirm("Sei sicuro di procedere alla cancellazione?", {
          title: "Conferma Cancellazione",
          buttonSize: "sm",
          okVariant: "secondary",
          cancelVariant: "danger",
          okTitle: "SI",
          cancelTitle: "NO",
          footerClass: "p-2",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            me.deleteItem(item);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteItem(item) {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + "/" + me.pathResource;
      axios
        .delete(link, { data: { kitId: item.kitId } })
        .then((response) => {
          console.log(response);
          me.showModalLoading = false;
          me.loadData();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
