<template>
    <sa-page-layout :data="jsonData" :btnAnnullaVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading" :btnSaveVisible="true">
        <template slot="table-body">
            <b-tabs content-class="mt-3" class="sa-tab" style="height: 100%;" fill>
                <b-tab active title="Anagrafica">
                    <div class="sa-tab-scroll">
                        <anagrafica-component :data="jsonData" :edit="true"></anagrafica-component>
                    </div>
                </b-tab>
            </b-tabs>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import AnagraficaComponent from "../../anagrafica/components/AnagraficaComponent.vue";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: {
        AnagraficaComponent,
        SaPageLayout,
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkback = "/atleti/view/" + me.id;
        me.loadData();
    },
    data() {
        return {
            showModalLoading: false,
            linkback: null,
            pathResource: "/atleti",
            jsonData: {
                atleta: {
                    tipo: "PATIENT",
                    identificativo: null,
                    tipoIdentificativo: null,
                    cognome: null,
                    nome: null,
                    dataNascita: null,
                    comuneNascitaCodiceIstat: null,
                    comuneResidenzaCodiceIstat: null,
                    comuneDomicilioCodiceIstat: null,
                    indirizzoResidenza: null,
                    indirizzoDomicilio: null,
                    note: null,
                    medicoCurante: null,
                    codiceFiscaleMedicoCurante: null,
                    capDomicilio: null,
                    capResidenza: null,
                    telefono: null,
                    email: null,
                    codiceFiscale: null,
                    sesso: null,
                    codiceStruttura: null,
                    comuneResidenza: null,
                    comuneNascita: null,
                    provinciaResidenza: null,
                    provinciaNascita: null,
                },
            },
        };
    },
    methods: {
        loadData() {
            let me = this;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                me.showModalLoading = true;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        console.log(me.jsonData);
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>
