<template>
  <div class="sa-tab-scroll">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="UTENTE" header-tag="header" footer-tag="footer" title="">
          <adi-pai-risultati-obiettivi-component :items="jsonData.listaObiettiviUtente" :personaObiettivo="'UTENTE'" @updateData="onUpdateObiettiviUtente" :edit="!editPaiPrestResSemiRess"></adi-pai-risultati-obiettivi-component>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="FAMIGLIA" header-tag="header" footer-tag="footer" title="">
          <adi-pai-risultati-obiettivi-component :items="jsonData.listaObiettiviFamiglia" :personaObiettivo="'FAMIGLIA'" @updateData="onUpdateObiettiviFamiglia" :edit="!editPaiPrestResSemiRess"></adi-pai-risultati-obiettivi-component>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="ASSISTENZA RESIDENZIALE E SEMI-RESIDENZIALE PROGRAMMATA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Assistenza Residenziale e Semi-Residenziale Programmata</label>
              <b-form-select v-model="jsonData.assistenzaProgrammata" :options="tipiAssistenzaProgrammataOptions" :value="''" value-field="value" text-field="text" :disabled="!editPaiPrestResSemiRess"></b-form-select>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <b-card class="sa-card" header="TIPOLOGIA IN RAPPORTO ALLA DURATA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Tipologia in Rapporto alla Durata</label>
              <b-form-select v-model="jsonData.tipologiaRapportoDurata" :options="tipologieRapportiOptions" :value="''" value-field="value" text-field="text" :disabled="!editPaiPrestResSemiRess"></b-form-select>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <label class="sa-label-data">Eventuali Segnalazioni Relative al Piano Esecutivo</label>
              <b-form-textarea v-model="jsonData.eventualiSegnalazioniRelativePianoEsecutivo" class="text-upper" rows="6" no-resize :disabled="!editPaiPrestResSemiRess"></b-form-textarea>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="COMPONENTI DELL' UNITA' DI VALUTAZIONE INTEGRATA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <adi-pai-unita-valutazione-integrata-component :items="jsonData.listaPaiUvi" @updatePaiUvi="onUpdatePaiUvi" :edit="!editPaiPrestResSemiRess"></adi-pai-unita-valutazione-integrata-component>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="COMPONENTI DELL' UNITA' DI VALUTAZIONE INTEGRATA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Data</label>
              <date-picker v-model="jsonData.dataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="!editPaiPrestResSemiRess"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Familiare/Utente</label>
              <b-form-input v-model="jsonData.familiareUtente" class="text-upper" :disabled="!editPaiPrestResSemiRess"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../utility/UtilityMixin";
import AdiPaiRisultatiObiettiviComponent from "./AdiPaiRisultatiObiettiviComponent.vue";
import AdiPaiUnitaValutazioneIntegrataComponent from "./AdiPaiUnitaValutazioneIntegrataComponent.vue";
export default {
  components: { DatePicker, AdiPaiRisultatiObiettiviComponent, AdiPaiUnitaValutazioneIntegrataComponent },
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      type: Object,
    },
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/adipaiprestazioniresidenzialisemiresidenziali",
      pathResourceTipiAssistenza: "/adipaiprestazioniresidenzialisemiprogrammata",
      pathResourceTipologieRapporti: "/adipaiprestazioniresidenzialisemitipologia",
      currentPage: 1,
      perPage: 100,
      id: "-1",
      fonte: "",
      filtro: {},
      editPaiPrestResSemiRess: false,
      tipiAssistenzaProgrammataOptions: [],
      tipologieRapportiOptions: [],
    };
  },
  mounted() {
    let me = this;
    me.jsonData.id = this.$route.params.id;
    me.fonte = this.$route.query.fonte;
    me.idPropostaAccesso = this.$route.query.idPropostaAccesso;
    me.loadData();
    me.loadDefaultData();
    me.impostaPermessi();
    me.jsonData.idPropostaAccesso = me.idPropostaAccesso;
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      me.editPaiPrestResSemiRess = false;
      if (me.fonte === null || me.fonte === undefined || me.fonte === "undefined" || me.fonte === "") {
        me.fonte = "/richieste";
      }
      if (me.fonte === "/richieste" || me.fonte === "/pua") {
        me.editPaiPrestResSemiRess = UtilityMixin.methods.verificaPermessi(Vue.prototype.user, "adipaiprestazioniresidenzialisemiresidenziali", 2);
      }
    },
    loadDefaultData() {
      let me = this;
      me.loadTipiAssistenzaProgrammata();
      me.loadTipologieRapporti();
    },
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.jsonData.id;

      me.$emit("showmodalloading", true);
      axios
        .get(link)
        .then((response) => {
          response.data.data.listaPaiUvi.push({ titolo: "", nomeCognome: "" });
          response.data.data.listaObiettiviUtente.push({ descrizione: "", personaObiettivo: "UTENTE" });
          response.data.data.listaObiettiviFamiglia.push({ descrizione: "", personaObiettivo: "FAMIGLIA" });
          this.$emit("update", response.data.data);
          me.$emit("showmodalloading", false);
        })
        .catch(() => {
          me.$emit("showmodalloading", false);
        });
    },
    loadTipiAssistenzaProgrammata() {
      let me = this;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceTipiAssistenza;
      axios.get(link, { params: me.filtro }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipiAssistenzaProgrammataOptions.push({ value: element.descrizione, text: element.descrizione });
        });
        me.tipiAssistenzaProgrammataOptions.unshift({ value: "", text: "-Seleziona Valore-" });
      });
    },
    loadTipologieRapporti() {
      let me = this;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceTipologieRapporti;
      axios.get(link, { params: me.filtro }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipologieRapportiOptions.push({ value: element.descrizione, text: element.descrizione });
        });
        me.tipologieRapportiOptions.unshift({ value: "", text: "-Seleziona Valore-" });
      });
    },

    onUpdateObiettiviUtente(array) {
      let me = this;
      me.jsonData.listaObiettiviUtente = null;
      me.jsonData.listaObiettiviUtente = array;
    },
    onUpdateObiettiviFamiglia(array) {
      let me = this;
      me.jsonData.listaObiettiviFamiglia = null;
      me.jsonData.listaObiettiviFamiglia = array;
    },
    onUpdatePaiUvi(array) {
      let me = this;
      me.jsonData.listaPaiUvi = null;
      me.jsonData.listaPaiUvi = array;
    },
  },
};
</script>
