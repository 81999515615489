<template>
    <wincare-old-list-component :nomeTabellaRiferimento="nomeTabella" :utente="utente" :titolo="titolo"></wincare-old-list-component>
</template>

<script>
import WincareOldListComponent from "../../components/WincareOldListComponent.vue";
export default {
    components: { WincareOldListComponent },
    props: {
        utente: Object,
    },
    data() {
        return {
            titolo: "ECOCARDIO DATI",
            nomeTabella: "ECOCARDIO_DATI",
        };
    },
};
</script>

<style>
.sa-td-wrap-text div {
    overflow-wrap: anywhere;
}
</style>
