<template>
    <sa-page-layout :pathResource="pathResource" :btnRefreshVisible="true" :showModalLoading="showModalLoading" @refresh="onRefresh">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Dal</label>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="timestamp"></date-picker>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Data Al</label>
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="timestamp"></date-picker>
                    </b-col>
                </b-row>
                <b-row style="margin-top: 5px">
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(index)="row">
                        {{ row.index + 1 }}
                    </template>
                    <template v-slot:cell(tipoEvento)="{ item }">
                        <!-- <router-link class="sa-edit-link" @click="getLinkEvento(item)">{{ item.tipoEvento }}</router-link> -->
                        <a class="sa-edit-link" @click="getLinkEvento(item)">{{ item.tipoEvento }}</a>
                    </template>
                </b-table>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout, DatePicker },
    data() {
        return {
            pathResource: "/wincareold/prodotti",
            currentPage: 1,
            perPage: 100,
            linkWincareOldTipoEvento: "/wincareoldadmineventi",
            showModalLoading: false,
            filtro: { dataDal: null, dataAl: null, tipoEvento: "" },
            items: [],
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: false,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Tipo Evento",
                    key: "tipoEvento",
                    sortable: true,
                },
                {
                    label: "Numero Documenti",
                    key: "numero",
                    sortable: true,
                    thStyle: "width: 10rem",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        let sessionStorage = window.sessionStorage;
        if (sessionStorage["filtro"]) {
            me.filtro = JSON.parse(sessionStorage["filtro"]);
        }
        // me.filtro.dataDal = new Date().getTime();
        // me.filtro.dataAl = new Date().getTime();
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
        getLinkEvento(value) {
            let me = this;
            me.filtro.tipoEvento = value.tipoEvento;
            let link = me.linkWincareOldTipoEvento + "?nomeTabella=" + me.filtro.tipoEvento + "&dataDal=" + me.filtro.dataDal + "&dataAl=" + me.filtro.dataAl;
            // console.log(link);
            sessionStorage.setItem("WincareOldAdmin", JSON.stringify(me.filtro));
            me.$router.replace(link).catch((err) => {
                err;
            });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = { dataDal: null, dataAl: null };
            me.filtro.dataDal = new Date().getTime();
            me.filtro.dataAl = new Date().getTime();
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>
