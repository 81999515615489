<template>
    <sa-page-layout :btnRefreshVisible="true" :btnNewVisible="true" :linkedit="linkedit" @refresh="onRefresh" :showModalLoading="showModalLoading" :pathResource="pathResource" :titolo="titolo">
        <template slot="toolbar">
            <b-button class="float-sm-left btn-toolbar sa-btn-icon-text" variant="outline-secondary sa-margin-left" size="sm" @click="onFirma">
                <b-icon icon="vector-pen"></b-icon>
                Firma
            </b-button>
        </template>
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataDal" type="date" placeholder="Data Dal"></date-picker>
                    </b-col>
                    <b-col sm="6">
                        <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="filtro.dataAl" type="date" placeholder="Data Al"></date-picker>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table ref="tbtEventi" selectable select-mode="multi" sticky-header id="tbl" :filter="filtro" stacked="lg" striped hover :items="jsonData" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" @row-selected="onRowSelected" class="sa-b-table">
                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true">&check;</span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true">&nbsp;</span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>

                    <template v-slot:cell(cognomeNomeDatabase)="{ item }">{{ item.cognomeDatabase }} {{ item.nomeDatabase }}</template>
                    <template v-slot:cell(cognomeNomeReferto)="{ item }">{{ item.cognomeReferto }} {{ item.nomeReferto }}</template>
                    <template v-slot:cell(firmaWincare)="{ item }">
                        <b-icon v-if="item.firmaWincare" icon="check" font-scale="2.5" variant="success" />
                        <b-icon v-else icon="x" font-scale="2.5" variant="danger" />
                    </template>
                    <template v-slot:cell(firmaDigitale)="{ item }">
                        <b-icon v-if="item.firmaDigitale" icon="check" font-scale="2.5" variant="success" />
                        <b-icon v-else icon="x" font-scale="2.5" variant="danger" />
                    </template>
                    <template v-slot:cell(validoPerFirma)="{ item }">
                        <b-icon v-if="item.validoPerFirma" icon="check" font-scale="2.5" variant="success" />
                        <b-icon v-else icon="x" font-scale="2.5" variant="danger" />
                    </template>
                    <template v-slot:cell(actions)="row">
                        <b-input-group>
                            <b-button size="sm" class="no-text" variant="outline-info" style="margin-right: 3px" @click="onEdit(row.item, row.index, $event.target)">
                                <b-icon icon="pencil" variant="outline-primary" />
                            </b-button>
                            <!-- <b-button size="sm" class="no-text" variant="outline-danger" style="margin-right: 3px" @click="onDelete(row.item, row.index, $event.target)"><b-icon icon="trash" variant="outline-danger"></b-icon></b-button>
                            <b-button size="sm" class="no-text" variant="outline-info" @click="onView(row.item, row.index, $event.target)">
                                <b-icon icon="calendar-event" variant="outline-primary" />
                            </b-button> -->
                        </b-input-group>
                    </template>
                </b-table>
            </div>
            <b-modal ref="mdlFirmaDigitaleRemota" no-close-on-backdrop centered title="Firma Digitale Remota" hide-footer>
                <firma-remota-aruba-component :utente="utente" :filesName="filesSelezionati" @signstart="onSignStart" @signend="onSignEnd"></firma-remota-aruba-component>
            </b-modal>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import FirmaRemotaArubaComponent from "../../utility/components/FirmaRemotaArubaComponent.vue";
export default {
    props: {
        titolo: String,
        utente: Object,
        nomeTabellaRiferimento: String,
    },
    components: { DatePicker, SaPageLayout, FirmaRemotaArubaComponent },
    computed: {
        rows() {
            return this.jsonData.length;
        },
    },
    data() {
        return {
            showModalLoading: false,
            linkedit: null,
            pathResource: "/wincareold",
            currentPage: 1,
            perPage: 50,
            filtro: { dataDal: null, dataAl: null, nomeTabella: this.nomeTabellaRiferimento },
            // filterOn: [],
            filesSelezionati: [],
            rowSelected: [],
            fields: [
                {
                    label: "",
                    key: "selected",
                },
                {
                    label: "Cod. Paz.",
                    key: "codPaz",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Data Evento",
                    key: "dataEvento",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
                {
                    label: "Esaminatore",
                    key: "esaminatore",
                    sortable: true,
                },
                {
                    label: "Cognome Nome Database",
                    key: "cognomeNomeDatabase",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("patients.lblBirthDate"),
                    key: "dataNascita",
                    sortable: true,
                    formatter: (value) => {
                        return moment(new Date(value)).format("DD/MM/YYYY");
                    },
                },
                {
                    label: "Cognome Nome Referto",
                    key: "cognomeNomeReferto",
                    sortable: true,
                },
                {
                    label: "File PDF",
                    key: "filePdf",
                    sortable: true,
                    tdClass: "sa-td-wrap-text",
                },
                {
                    label: "Valido per Firma",
                    key: "validoPerFirma",
                    class: "text-center",
                    sortable: true,
                },
                {
                    label: "Firma Wincare",
                    key: "firmaWincare",
                    class: "text-center",
                    sortable: true,
                },
                {
                    label: "Firma Digitale",
                    key: "firmaDigitale",
                    class: "text-center",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 4rem",
                },
            ],

            jsonData: [],
            // traduzione: "",
        };
    },
    mounted: function () {
        let me = this;
        me.filtro.dataDal = new Date().setHours(0, 0, 0, 0);
        me.filtro.dataAl = new Date().setHours(23, 59, 59, 0);
        this.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            // let filtroRemoto = { dataDal: moment(new Date(me.filtro.dataDal)).format("YYYY-MM-DD 00:00:00"), dataAl: moment(new Date(me.filtro.dataAl)).format("YYYY-MM-DD 23:59:59") };
            axios
                // .get(link, { params: filtroRemoto })
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.jsonData = [];
                    me.jsonData = response.data.data;
                    // me.data = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(function (error) {
                    me.showModalLoading = false;
                    me.$bvToast.toast(error.response.data.messaggio, {
                        title: "Agende",
                        variant: "danger",
                        solid: true,
                    });
                });
        },
        onRefresh() {
            let me = this;
            me.loadData();
        },
        onSubmit() {
            let me = this;
            me.loadData();
        },
        onEdit(row) {
            let me = this;
            me.filesSelezionati[0] = row.filePdf;
            me.$refs.mdlFirmaDigitaleRemota.show();
        },
        onReset() {
            let me = this;
            me.data = [];
            me.loadData();
        },
        onSignStart() {
            let me = this;
            me.showModalLoading = true;
        },
        onSignEnd() {
            let me = this;
            this.rowSelected = [];
            this.$refs.tbtEventi.clearSelected();
            me.$refs.mdlFirmaDigitaleRemota.hide();
            let timer = setInterval(() => {
                clearInterval(timer);
                me.loadData();
            }, 3000);
        },
        onRowSelected(items) {
            this.rowSelected = items;
            console.log(this.rowSelected);
        },
        onFirma() {
            let me = this;
            me.filesSelezionati = [];
            console.log(this.rowSelected);
            if (this.rowSelected.length > 0) {
                for (let x = 0; x < this.rowSelected.length; x++) {
                    me.filesSelezionati[x] = this.rowSelected[x].filePdf;
                    me.$refs.mdlFirmaDigitaleRemota.show();
                }
            }
        },
    },
};
</script>

<style>
.sa-td-wrap-text div {
    overflow-wrap: anywhere;
}
</style>
