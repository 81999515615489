<template>
  <b-card class="sa-card" header-tag="header" footer-tag="footer" title="">
    <template #header>
      <h5 class="mt-0">
        <span>
          <i class="fas fa-capsules"></i>
          {{ titolo }}
        </span>
      </h5>
    </template>
    <ul class="list-unstyled mb-3">
      <li v-for="item in terapia" :key="item.id">
        <strong>{{ item.posologia }}</strong>
        <strong>{{ item.farmaco }}</strong>
        <span class="text-danger">{{ item.frequenza }}</span>
        <span class="text-danger">{{ startTherapy() }} {{ formatDate(item.dataInizio) }}</span>
        <span class="text-danger">{{ item.tempo }}</span>
        <i class="blockquote-footer">{{ item.nomeMedico }} {{ item.cognomeMedico }}</i>
      </li>
    </ul>
  </b-card>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  data() {
    return {
      terapia: [
        //     { id: 1, farmaco: "Farmaco 1" },
        //     { id: 2, farmaco: "Farmaco 1" },
        //     { id: 3, farmaco: "Farmaco 1" },
        //     { id: 4, farmaco: "Farmaco 1" },
        //     { id: 5, farmaco: "Farmaco 1" },
        //     { id: 6, farmaco: "Farmaco 1" },
        //     { id: 7, farmaco: "Farmaco 1" },
        //     { id: 8, farmaco: "Farmaco 1" },
        //     { id: 9, farmaco: "Farmaco 1" },
        //     { id: 10, farmaco: "Farmaco 1" },
        //     { id: 11, farmaco: "Farmaco 1" },
        //     { id: 12, farmaco: "Farmaco 1" },
        //     { id: 13, farmaco: "Farmaco 1" },
        //     { id: 14, farmaco: "Farmaco 1" },
        //     { id: 15, farmaco: "Farmaco 1" },
        //     { id: 16, farmaco: "Farmaco 1" },
        //     { id: 17, farmaco: "Farmaco 1" },
        //     { id: 18, farmaco: "Farmaco 1" },
        //     { id: 19, farmaco: "Farmaco 1" },
        //     { id: 20, farmaco: "Farmaco 1" },
        //     { id: 21, farmaco: "Farmaco 1" },
        //     { id: 22, farmaco: "Farmaco 1" },
        //     { id: 23, farmaco: "Farmaco 1" },
        //     { id: 24, farmaco: "Farmaco 1" },
        //     { id: 25, farmaco: "Farmaco 1" },
      ],
    };
  },
  computed: {
    titolo() {
      return this.$i18n.t("patient.menu.therapy");
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    // me.loadTerapie();
  },
  methods: {
    loadTerapie() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + "/pazientiterapie/attiva";
      axios
        .get(link, { params: { idPaziente: me.id } })
        .then((response) => {
          me.terapia = response.data.data;
        })
        .catch((e) => {
          console.log("Error: " + e.response.status + " " + link);
        });
    },
    formatDate(value) {
      if (value) {
        return moment(new Date(value)).format("DD-MM-YYYY");
      } else {
        return "---";
      }
    },
    startTherapy() {
      return this.$i18n.t("patient.lblStartTherapy");
    },
  },
};
</script>
<style scoped>
.sa-page-paziente-terapia-attiva .card-body {
  overflow: auto;
}
</style>
