<template>
  <div>
    <b-card class="sa-card" header="Atto Morte" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Numero</label>
          <b-form-input v-model="jsonData.attoMorteNumero"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Parte</label>
          <b-form-select v-model="jsonData.attoMorteParte" :options="parteQuesitiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Serie</label>
          <b-form-select v-model="jsonData.attoMorteSerie" :options="serieQuesitiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Numero Scheda</label>
          <b-form-input v-model="jsonData.numeroScheda"></b-form-input>
        </b-col>
      </b-row>
      <b-row v-if="isEta">
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Codice Fiscale del Padre</label>
          <b-form-input v-model="jsonData.codiceFiscalePadre"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Codice Fiscale della Madre</label>
          <b-form-input v-model="jsonData.codiceFiscaleMadre"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Denominazione di Comune e Provincia" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Provincia</label>
          <b-form-select v-model="jsonData.provinciaMorteSigla" :options="provinceMorteOptions" :value="''" value-field="sigla" text-field="provincia" @input="onInputProvinciaMorte"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Comune</label>
          <b-form-select v-model="jsonData.comuneDecessoCodiceIstat" :options="comuniMorteOptions" :value="''" value-field="codiceIstat" text-field="comune" @input="onInputComuneMorte"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">CAP</label>
          <b-form-input v-model="jsonData.capMorte"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Quartiere di Morte</label>
          <b-form-select v-model="jsonData.quartiereDecesso" :options="quartieriMorteOptions" :value="''" value-field="id" text-field="zonaQuartiere" :disabled="disableQuartiere"></b-form-select>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Nascita</label>
          <date-picker v-model="jsonData.dataNascita" format="DD-MM-YYYY" value-type="timestamp" type="date" disabled></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ora Nascita</label>
          <date-picker v-model="jsonData.oraNascita" format="HH:mm" value-type="timestamp" type="time"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Data Morte</label>
          <date-picker v-model="jsonData.dataMorte" format="DD-MM-YYYY" value-type="timestamp" type="date" disabled></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Ora Morte</label>
          <date-picker v-model="jsonData.oraMorte" format="HH:mm" value-type="timestamp" type="time"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="2" lg="2" xl="2">
          <label class="sa-label-data">Età</label>
          <span class="sa-data">{{ jsonData.eta }}</span>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  props: {
    jsonData: {
      type: Object,
      require: true,
      default: function () {
        return {
          attoMorteNumero: "",
          attoMorteParte: "",
          attoMorteSerie: "",
          provinciaMorteSigla: "",
          comuneDecessoCodiceIstat: "",
          capMorte: "",
          quartiereDecesso: "",
          dataMorte: null,
          oraMorte: null,
          dataNascita: null,
          oraNascita: null,
          eta: "",
          /* CAMPI DA AGGIUNGERE */
          codiceFiscalePadre: "",
          codiceFiscaleMadre: "",
        };
      },
    },
  },
  components: { DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResourceProvinceMorte: "/province",
      pathResourceComuniMorte: "/comuni/provincia",
      pathResourceQuartieriMorte: "/quartieri",
      isEta: false,
      disableQuartiere: true,
      parteQuesitiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "1", text: "I" },
        { value: "2", text: "II" },
      ],
      serieQuesitiOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "B", text: "B" },
        { value: "C", text: "C" },
      ],

      provinceMorteOptions: [{ sigla: "", provincia: "- Seleziona Provincia-" }],
      comuniMorteOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
      quartieriMorteOptions: [{ id: "", zonaQuartiere: "-Seleziona Quartiere-" }],
    };
  },
  mounted() {
    let me = this;
    me.loadProvinceMorte();
    if (me.jsonData.provinciaMorteSigla !== "" && me.jsonData.provinciaMorteSigla !== null) {
      me.loadComuniMorte(me.jsonData.provinciaMorteSigla);
    }
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        let me = this;
        if (jsonData.eta < 1) {
          me.isEta = true;
        } else {
          me.isEta = false;
          me.jsonData.codiceFiscalePadre = "";
          me.jsonData.codiceFiscaleMadre = "";
        }
      },
      deep: true,
    },
  },
  methods: {
    loadProvinceMorte() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvinceMorte;
      axios.get(link).then((response) => {
        me.provinceMorteOptions = response.data.data;
        me.provinceMorteOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
      });
    },
    onInputProvinciaMorte(provinciaMorte) {
      let me = this;
      if (provinciaMorte !== "") {
        // me.jsonData.comuneDecessoCodiceIstat = "";
        me.loadComuniMorte(provinciaMorte);
      } else {
        me.jsonData.comuneDecessoCodiceIstat = "";
        me.jsonData.capMorte = "";
        me.comuniMorteOptions = [{ codiceIstat: "", comune: "-Seleziona Comune-" }];
      }
    },
    loadComuniMorte(provinciaMorte) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceComuniMorte + "/" + provinciaMorte;
      axios.get(link).then((response) => {
        me.comuniMorteOptions = response.data.data;
        me.comuniMorteOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });
        me.loadQuartieriMorte("Napoli");
      });
    },
    onInputComuneMorte(comuneMorte) {
      let me = this;
      let comuneMorteDenominazione = "";
      me.comuniMorteOptions.forEach((element) => {
        if (element.codiceIstat === comuneMorte) {
          comuneMorteDenominazione = element.comune;
          me.jsonData.capMorte = element.cap;
          me.jsonData.quartiereDecesso = "";
        }
      });
      me.loadQuartieriMorte(comuneMorteDenominazione);
    },
    loadQuartieriMorte(comuneMorteDenominazione) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceQuartieriMorte;
      me.disableQuartiere = true;
      axios.get(link, { params: { comune: comuneMorteDenominazione } }).then((response) => {
        me.quartieriMorteOptions = response.data.data;
        me.quartieriMorteOptions.unshift({ id: "", zonaQuartiere: "-Seleziona Quartiere-" });
        if (me.quartieriMorteOptions.length > 1) {
          me.disableQuartiere = false;
        }
      });
    },
  },
};
</script>
