<template>
  <div class="sa-tab-scroll">
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="UTENTE" header-tag="header" footer-tag="footer" title="">
          <adi-pai-risultati-obiettivi-component :items="jsonData.listaObiettiviUtente" :personaObiettivo="'UTENTE'" @updateData="onUpdateObiettiviUtente" :edit="!editPaiCureDomiciliari"></adi-pai-risultati-obiettivi-component>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="PIANO DELLE AZIONI/INTERVENTI PROGRAMMATI - TABELLA RIEPILOGATIVA" header-tag="header" footer-tag="footer" title="">
          <adi-pai-cure-domiciliari-tabella-riepilogativa-component :items="jsonData.listaAzioniInterventiProgrammati" @updateTabellaRiepilogativa="onUpdateTabellaRiepilogativa" :edit="!editPaiCureDomiciliari"></adi-pai-cure-domiciliari-tabella-riepilogativa-component>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="PIANO DELLE AZIONI/INTERVENTI PROGRAMMATI - PIANO TERAPEUTICO" header-tag="header" footer-tag="footer" title="">
          <adi-pai-cure-domiciliari-piano-terapeutico-component :items="jsonData.listaPianoTerapeutico" @updatePianoTerapeutico="onUpdatePianoTerapeutico" :edit="!editPaiCureDomiciliari"></adi-pai-cure-domiciliari-piano-terapeutico-component>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="COMPONENTI DELL' UNITA' DI VALUTAZIONE INTEGRATA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
              <adi-pai-unita-valutazione-integrata-component :items="jsonData.listaUvi" @updatePaiUvi="onUpdatePaiUvi" :edit="!editPaiCureDomiciliari"></adi-pai-unita-valutazione-integrata-component>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <b-card class="sa-card" header="COMPONENTI DELL' UNITA' DI VALUTAZIONE INTEGRATA" header-tag="header" footer-tag="footer" title="">
          <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Data</label>
              <date-picker v-model="jsonData.dataOra" format="DD-MM-YYYY HH:mm" value-type="timestamp" type="datetime" :disabled="!editPaiCureDomiciliari"></date-picker>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
              <label class="sa-label-data">Care Giver/Utente</label>
              <b-form-input v-model="jsonData.utente" class="text-upper" :disabled="!editPaiCureDomiciliari"></b-form-input>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../utility/UtilityMixin";
import AdiPaiRisultatiObiettiviComponent from "./AdiPaiRisultatiObiettiviComponent.vue";
import AdiPaiCureDomiciliariTabellaRiepilogativaComponent from "./AdiPaiCureDomiciliariTabellaRiepilogativaComponent.vue";
import AdiPaiCureDomiciliariPianoTerapeuticoComponent from "./AdiPaiCureDomiciliariPianoTerapeuticoComponent.vue";
import AdiPaiUnitaValutazioneIntegrataComponent from "./AdiPaiUnitaValutazioneIntegrataComponent.vue";
export default {
  mixins: [UtilityMixin],
  components: { DatePicker, AdiPaiRisultatiObiettiviComponent, AdiPaiCureDomiciliariTabellaRiepilogativaComponent, AdiPaiCureDomiciliariPianoTerapeuticoComponent, AdiPaiUnitaValutazioneIntegrataComponent },
  props: {
    jsonData: {
      type: Object,
    },
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/adipaicuredomiciliari",
      currentPage: 1,
      perPage: 100,
      fonte: "",
      id: "-1",
      filtro: {},
      editPaiCureDomiciliari: false,
    };
  },
  mounted() {
    let me = this;
    me.jsonData.id = this.$route.params.id;
    me.fonte = this.$route.query.fonte;
    me.idPropostaAccesso = this.$route.query.idPropostaAccesso;
    me.loadData();
    me.loadDefaultData();
    me.impostaPermessi();
    me.jsonData.idPropostaAccesso = me.idPropostaAccesso;
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    impostaPermessi() {
      let me = this;
      me.editPaiCureDomiciliari = false;
      if (me.fonte === null || me.fonte === undefined || me.fonte === "undefined" || me.fonte === "") {
        me.fonte = "/richieste";
      }
      if (me.fonte === "/richieste" || me.fonte === "/pua") {
        me.editPaiCureDomiciliari = UtilityMixin.methods.verificaPermessi(me.utente, "adipaicuredomiciliari", 2);
      }
    },
    loadDefaultData() {},
    loadData() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.jsonData.id;
      me.$emit("showmodalloading", true);
      axios
        .get(link)
        .then((response) => {
          response.data.data.listaObiettiviUtente.push({ descrizione: "", personaObiettivo: "UTENTE" });
          response.data.data.listaAzioniInterventiProgrammati.push({ tipologiaPrestazione: "", numeroAccessi: "", oreDiePerGiorniSettimane: "" });
          response.data.data.listaPianoTerapeutico.push({ descrizione: "" });
          this.$emit("update", response.data.data);
          me.$emit("showmodalloading", false);
        })
        .catch(() => {
          me.$emit("showmodalloading", false);
        });
    },
    onUpdateObiettiviUtente(array) {
      let me = this;
      me.jsonData.listaObiettiviUtente = null;
      me.jsonData.listaObiettiviUtente = array;
    },
    onUpdateTabellaRiepilogativa(array) {
      let me = this;
      me.jsonData.listaAzioniInterventiProgrammati = null;
      me.jsonData.listaAzioniInterventiProgrammati = array;
    },
    onUpdatePianoTerapeutico(array) {
      let me = this;
      me.jsonData.listaPianoTerapeutico = null;
      me.jsonData.listaPianoTerapeutico = array;
    },
    onUpdatePaiUvi(array) {
      let me = this;
      me.jsonData.listaUvi = null;
      me.jsonData.listaUvi = array;
    },
  },
};
</script>
