<template>
  <sa-page-layout ref="pgaView" :data="jsonData" :pathResource="pathResource" :btnAnnullaVisible="true" :btnSaveNotBackVisible="true" :linkNotBack="linkNotBack" :btnSaveVisible="true" :linkback="linkback" :showModalLoading="showModalLoading" @aftersave="onAfterSave" class="sa-no-space">
    <template slot="toolbar">
      <b-button variant="primary btn-toolbar sa-margin-left float-sm-left" size="sm" v-b-modal.mdlSinonimi>
        <b-icon icon="pencil"></b-icon>
        Sinonimi ICDX
      </b-button>
    </template>
    <template slot="toolbar-title">
      <div class="sa-component-patient-info">
        <b-row>
          <b-col>
            <img :src="patientAvatar" class="rounded-circle avatar-toolbar img-thumbnail float-left mr-2" alt="profile-image" />
            <h4 class="m-0">
              <!-- <router-link v-if="patientLink" class="sa-edit-link" :to="linkPatient">{{ jsonData.cognome }} {{ jsonData.nome }}</router-link> -->
              <span class="">{{ jsonData.cognome }} {{ jsonData.nome }}</span>
            </h4>
            <p class="text-muted">
              <i>{{ jsonData.codiceFiscale }}</i>
              <br />
              <span>
                <b>Data Nascita:</b>
                {{ formatDate(jsonData.dataNascita) }}
              </span>
              <span>
                <b>Eta :</b>
                {{ jsonData.eta }}
              </span>
              <br />
            </p>
          </b-col>
        </b-row>
      </div>
    </template>
    <template slot="table-body">
      <b-tabs v-model="tabIndex" content-class="mt-3" class="sa-tab" style="height: 100%" fill>
        <b-tab>
          <template #title>
            <span>Dati Iniziali</span>
          </template>
          <div class="sa-tab-scroll">
            <scheda-morte-dati-iniziali-component :jsonData="jsonData" :key="datiInizialiKey"></scheda-morte-dati-iniziali-component>
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <span>Decesso</span>
          </template>
          <div class="sa-tab-scroll">
            <scheda-morte-dati-decesso-component :jsonData="jsonData" :key="datiDecessoKey"></scheda-morte-dati-decesso-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Cause di Morte</span>
          </template>
          <div class="sa-tab-scroll">
            <scheda-morte-cause-morte-component :jsonData="jsonData" :codiciIcdxOptions="codiciIcdxOptions" :key="causeMorteKey" @showmodalloading="onShowModalLoading" :utente="utente"></scheda-morte-cause-morte-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Morte Violenta</span>
          </template>
          <div class="sa-tab-scroll">
            <scheda-morte-violenta-component :jsonData="jsonData" :codiciIcdxOptions="codiciIcdxOptions" :key="causeMorteKey"></scheda-morte-violenta-component>
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <span>B. 1-2-3-4</span>
          </template>
          <div class="sa-tab-scroll">
            <rencam-scheda-morte-quesiti-parte-uno-edit-component :jsonData="jsonData"></rencam-scheda-morte-quesiti-parte-uno-edit-component>
          </div>
        </b-tab>
        <b-tab lazy>
          <template #title>
            <span>B. 5-6-7-8</span>
          </template>
          <div class="sa-tab-scroll">
            <rencam-scheda-morte-quesiti-parte-due-edit-component :jsonData="jsonData"></rencam-scheda-morte-quesiti-parte-due-edit-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span>B. 9-10-11-12-13-14</span>
          </template>
          <div class="sa-tab-scroll">
            <rencam-scheda-morte-quesiti-parte-tre-edit-component :jsonData="jsonData"></rencam-scheda-morte-quesiti-parte-tre-edit-component>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <span>Lista Stati</span>
          </template>
          <div class="sa-tab-scroll">
            <rencam-scheda-morte-lista-stati-component :jsonData="jsonData"></rencam-scheda-morte-lista-stati-component>
          </div>
        </b-tab>
      </b-tabs>
    </template>

    <template slot="table-footer">
      <sinonimi-icdx-component></sinonimi-icdx-component>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import SchedaMorteDatiInizialiComponent from "../components/SchedaMorteDatiInizialiComponent.vue";
import SchedaMorteDatiDecessoComponent from "../components/SchedaMorteDatiDecessoComponent.vue";
import SchedaMorteCauseMorteComponent from "../components/SchedaMorteCauseMorteComponet.vue";
import SchedaMorteViolentaComponent from "../components/SchedaMorteViolentaComponent.vue";
import SinonimiIcdxComponent from "../../sinonimi/components/SinonimiIcdxComponent.vue";
// import SchedaMorteDatiInizialiComponet from "./SchedaMorteDatiInizialiComponent.vue";
// import SchedaDiMorteMedicoEdit from "./SchedaDiMorteMedicoEdit.vue";
// import SchedaDiMorteStatoCivileEdit from "./SchedaDiMorteStatoCivileEdit.vue";
// import SchedaMorteTabViolentaComponent from "../../components/schedadimorte/SchedaMorteTabViolentaComponent.vue";
// import SchedaMorteTabAttoMorteComponent from "../../components/schedadimorte/SchedaMorteTabAttoMorteComponent.vue";
// import SchedaMorteTabResidenzaComponent from "../../components/schedadimorte/SchedaMorteTabResidenzaComponent.vue";
// import SchedaMorteTabProfessioneComponent from "../../components/schedadimorte/SchedaMorteTabProfessioneComponent.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import female from "@/assets/images/female.jpg";
import male from "@/assets/images/male.jpg";
import RencamSchedaMorteQuesitiParteUnoEditComponent from "../components/RencamSchedaMorteQuesitiParteUnoEditComponent.vue";
import RencamSchedaMorteQuesitiParteDueEditComponent from "../components/RencamSchedaMorteQuesitiParteDueEditComponent.vue";
import RencamSchedaMorteQuesitiParteTreEditComponent from "../components/RencamSchedaMorteQuesitiParteTreEditComponent.vue";
import RencamSchedaMorteListaStatiComponent from "../components/RencamSchedaMorteListaStatiComponent.vue";
export default {
  mixins: [UtilityMixin],
  // SchedaDiMorteStatoCivileEdit
  components: {
    SaPageLayout,
    SchedaMorteDatiInizialiComponent,
    SchedaMorteDatiDecessoComponent,
    SchedaMorteCauseMorteComponent,
    SchedaMorteViolentaComponent,
    SinonimiIcdxComponent,
    RencamSchedaMorteQuesitiParteUnoEditComponent,
    RencamSchedaMorteQuesitiParteDueEditComponent,
    RencamSchedaMorteQuesitiParteTreEditComponent,
    RencamSchedaMorteListaStatiComponent,
  },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      isSchedaMorte: true,
      linkNotBack: null,
      showModalLoading: false,
      isCauseSchedaMorte: false,
      pathResource: "/rencamschedemorte",
      linkback: null,
      data: {},
      codiciIcdxOptions: [],
      tabIndex: 0,
      datiInizialiKey: 0,
      datiDecessoKey: 0,
      causeMorteKey: 0,
      pathRestValidation: "/validation",
      btnFirmaDisabled: false,
      edit: true,
      editmedico: true,
      editStatoCivile: true,
      id: "-1",
      filtro: {},
      jsonData: {
        cognome: "",
        nome: "",
        dataNascita: null,
        codiceFiscale: "",
        sesso: "",
        dataMorte: null,
        eta: "",
        localitaNascita: null,
        provinciaNascitaSigla: "",
        comuneNascitaCodiceIstat: "",
        luogoDecesso: "",
        luogoDecessoAltro: "",
        riscontroDiagnostico: "",
        provinciaMorteSigla: "",
        comuneDecessoCodiceIstat: "",
        quartiereDecesso: "",
        regioneAslDecesso: "",
        distrettoDecesso: "",
        codiceAsl: "",
        statoGravidanza: "",
        cognomeNomeMedico: "",
        tipoMedico: "",
        codiceIcdX: "",
        codiceIcdXMorteViolenta: "",
        codiceIcdIx: "",
        descrizioneCodificataIcdIx: "",
        descrizioneCodificataIcdX: "",
        descrizioneCodificataIcdXMorteViolenta: "",
        modalitaTraumatismo: "",
        infortunioLavoro: "",
        mezzoTrasportoVittima: "",
        ruoloVittima: "",
        tipoIncidente: "",
        oggettoVeicoloCoinvolto: "",
        dataOraAccidente: null,
        luogoAccidente: "",
        luogoAccidenteAltriLuoghi: "",
        attoMorteNumero: "",
        attoMorteParte: "",
        attoMorteSerie: "",
        capMorte: "",
        oraMorte: null,
        oraNascita: null,
        statoCivile: "",
        annoNascitaConiuge: "",
        annoMatrimonio: "",
        provinciaResidenzaSigla: "",
        comuneResidenzaCodiceIstat: "",
        capResidenza: "",
        quartiereResidenza: "",
        indirizzoResidenza: "",
        gradoIstruzione: "",
        condizioneProfessionale: "",
        posizioneProfessionale: "",
        ramoAttivita: "",
        cittadinanza: "",
        cittadinanzaStraniera: "",
        professione: "",
        dataCompilazione: null,
        listaCauseMorte: [
          { cause: "Causa Iniziale", descrizioneMalattia: "", descrizioneCodificataIcd: "", codiceIcd: null, tempoAnni: 0, tempoGiorni: 0, tempoMesi: 0 },
          { cause: "Eventuali", descrizioneMalattia: "", descrizioneCodificataIcd: "", codiceIcd: null, tempoAnni: 0, tempoGiorni: 0, tempoMesi: 0 },
          { cause: "Eventuali", descrizioneMalattia: "", descrizioneCodificataIcd: "", codiceIcd: null, tempoAnni: 0, tempoGiorni: 0, tempoMesi: 0 },
          { cause: "Eventuali", descrizioneMalattia: "", descrizioneCodificataIcd: "", codiceIcd: null, tempoAnni: 0, tempoGiorni: 0, tempoMesi: 0 },
        ],
        /*CAMPI DA AGGIUNGERE DATI INIZIALI*/
        tipoSchedaMorte: "",
        /*CAMPI DA AGGIUNGERE QUESITI PARTE UNO*/
        codiceFiscalePadre: "",
        codiceFiscaleMadre: "",
        /*CAMPI DA AGGIUNGERE QUESITI PARTE DUE*/
        genereParto: "",
        filiazione: "",
        dataNascitaMadre: null,
        residenzaMadre: "",
        provinciaResidenzaMadre: "",
        comuneResidenzaMadre: "",
        indirizzoMadre: "",
        /*CAMPI DA AGGIUNGERE QUESITI PARTE TRE*/
        gradoIstruzioneMadre: "",
        gradoIstruzionePadre: "",
        professioneMadre: "",
        professionePadre: "",
        condizioneProfessionaleMadre: "",
        condizioneProfessionalePadre: "",
        posizioneProfessionaleMadre: "",
        posizioneProfessionalePadre: "",
        ramoAttivitaMadre: "",
        ramoAttivitaPadre: "",
        cittadinanzaMadre: "",
        cittadinanzaStranieraMadre: "",
        cittadinanzaPadre: "",
        cittadinanzaStranieraPadre: "",
        pesoNascita: "",
        etaGestazione: "",
        parita: "",
        etaMadre: "",
      },
    };
  },

  computed: {
    patientAvatar: {
      get: function () {
        let me = this;
        if (me.jsonData.sesso === "M") {
          return male;
        } else {
          return female;
        }
      },
    },
  },
  mounted: function () {
    let me = this;
    me.id = this.$route.params.id;
    me.linkback = "/rencamschedemorte";
    let path = this.$route.path.replace(/\/edit\/.*/, "");
    me.linkNotBack = path + "{editid}?";
    me.loadData();
  },
  watch: {
    utente() {
      // let me = this;
      // me.impostaPermessi();
    },
  },
  methods: {
    loadData() {
      let me = this;
      if (me.id === "-1") {
        me.showModalLoading = false;
      } else {
        let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
        me.showModalLoading = true;
        axios
          .get(link + me.id)
          .then((response) => {
            me.jsonData = response.data.data;
            me.datiInizialiKey = me.datiInizialiKey + 1;
            me.datiDecessoKey = me.datiDecessoKey + 2;
            me.causeMorteKey = me.causeMorteKey + 3;
            me.setEta();
            // console.log(me.jsonData.eta);
            // me.showModalLoading = false;
          })
          .catch((e) => {
            e;
            // me.showModalLoading = false;
          });
        me.showModalLoading = false;
      }
    },
    onShowModalLoading(value) {
      let me = this;
      me.showModalLoading = value;
    },
    onFirmaUpdate() {
      let me = this;
      me.loadData();
    },
    setEta() {
      let me = this;
      me.jsonData.eta = UtilityMixin.methods.calcoloEtaYearOnly(me.jsonData.dataNascita, new Date(me.jsonData.dataMorte));
    },
    onAfterSave(data) {
      let me = this;
      me.jsonData = data;
      if (me.id === "-1") {
        me.id = me.jsonData.id;
      }
      me.loadData();
    },
    // calcoloEtaInt(value) {
    //     if (value) {
    //         let today = new Date();
    //         let birthDate = new Date(value);
    //         let age = today.getFullYear() - birthDate.getFullYear();
    //         let ageMonth = today.getMonth() - birthDate.getMonth();
    //         let ageDays = today.getDate() - birthDate.getDate();
    //         if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
    //             age--;
    //             ageMonth = 12;
    //         }

    //         if (ageDays < 0) {
    //             ageMonth--;
    //             ageDays = birthDate.getDate() - today.getDate();
    //         }
    //         return age;
    //     } else {
    //         return 0;
    //     }
    // },
  },
};
</script>
<style scoped></style>
