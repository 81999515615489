import LeonidaAccettazione from "../accettazione";
import LeonidaPrenotazione from "../prenotazione";
import Leonida from "../";

const routes = [
    { name: "LeonidaWorklist", path: "/worklist", component: Leonida.LeonidaWorkList, meta: { title: "WorkList" } },
    { name: "LeonidaVisitaMedicoSportiva", path: "/worklist/visitamedicosportiva/view/:id", component: Leonida.LeonidaVisitaMedicoSportivaView, meta: { title: "Visita Medico Sportiva" }, props: true },
    { name: "AccertamentiSupplementariDettagliEdit", path: "/accertamentisupplementaridettagli/edit/:id", component: Leonida.AccertamentiSupplementatriDettagliEdit, meta: { title: "Accertamenti Supplementari Dettagli" } },
    { name: "AccertamentiSupplementariDettagliView", path: "/worklist/accertamentisupplementaridettagli/view/:id", component: Leonida.AccertamentiSupplementatriDettagliViewOld, meta: { title: "Accertamenti Supplementari Dettagli" } },
    { name: "LeonidaPrenotazioniList", path: "/prenotazioni", component: LeonidaPrenotazione.LeonidaPrenotazioneList, meta: { title: "Lista Prenotazioni" } },
    { name: "LeonidaPrenotazioniView", path: "/prenotazioni/view/:id", component: LeonidaPrenotazione.LeonidaPrenotazioneView, meta: { title: "Prenotazione" } },
    { name: "LeonidaPrenotazioniEdit", path: "/prenotazioni/edit/:id", component: LeonidaPrenotazione.LeonidaPrenotazioneEdit, meta: { title: "Prenotazione" } },
    { name: "LeonidaAccettazioneList", path: "/leonidaaccettazioni", component: LeonidaAccettazione.LeonidaAccettazioneList, meta: { title: "Accettazione" } },
    { name: "LeonidaAccettazioneEdit", path: "/leonidaaccettazioni/edit/:id", component: LeonidaAccettazione.LeonidaAccettazioneEdit, meta: { title: "Accettazione" } },
    { name: "LeonidaAccettazioneView", path: "/leonidaaccettazioni/view/:id", component: LeonidaAccettazione.LeonidaAccettazioneView, meta: { title: "Accettazione" } },
    { name: "Atleti", path: "/atleti", component: Leonida.AtletiList, meta: { title: "Lista Atleti" } },
    { name: "AtletaView", path: "/atleti/view/:id", component: Leonida.AtletaView, meta: { title: "Atleta View" } },
    { name: "AtletaEdit", path: "/atleti/edit/:id", component: Leonida.AtletaEdit, meta: { title: "Atleta" } },
    { name: "PendingList", path: "/pendinglist", component: Leonida.PendingList, meta: { title: "Pending List" } },
    { name: "VisiteList", path: "/visite", component: Leonida.VisiteList, meta: { title: "Visite Medico Sportive" } },
    { name: "LeonidaAccettazionePazienteEdit", path: "/accettazione/edit/:id", component: Leonida.LeonidaAccettazionePazienteEdit, meta: { title: "Accettazione" } },
    { name: "AccertamentiSupplementariDettagliEdit", path: "/accertamentisupplementaridettagli/edit/:id", component: Leonida.AccertamentiSupplementatriDettagliEdit, meta: { title: "Accertamenti Supplementari Dettagli" } },
    { name: "LeonidaFederazioniSportiveNazionaliList", path: "/federazionisportivenazionali", component: Leonida.LeonidaFederazioniSportiveNazionaliList, meta: { title: "Federazioni Sportive Nazionali" } },
    { name: "LeonidaFederazioniSportiveNazionaliView", path: "/federazionisportivenazionali/view/:id", component: Leonida.LeonidaFederazioniSportiveNazionaliView, meta: { title: "Federazioni Sportive Nazionali" } },
    { name: "LeonidaFederazioniSportiveNazionaliEdit", path: "/federazionisportivenazionali/edit/:id", component: Leonida.LeonidaFederazioniSportiveNazionaliEdit, meta: { title: "Federazioni Sportive Nazionali" } },
    { name: "LeonidaArchivioVisiteList", path: "/archiviovisite", component: Leonida.LeonidaArchivioVisiteList, meta: { title: "Archivio Visite" } },
    { name: "LeonidaAccettazioneTariffeList", path: "/tariffe", component: Leonida.LeonidaAccettazioneTariffeList, meta: { title: "Lista Tariffe" } },
    { name: "LeonidaAccettazioneTariffeView", path: "/tariffe/view/:id", component: Leonida.LeonidaAccettazioneTariffeView, meta: { title: "Tariffe" } },
    { name: "LeonidaAccettazioneTariffeEdit", path: "/tariffe/edit/:id", component: Leonida.LeonidaAccettazioneTariffeEdit, meta: { title: "Tariffe" } },
];

export default routes;
