<template>
    <div class="sa-tab-scheda">
        <div class="sa-tab-scheda-row-fill" header="" header-tag="header" footer-tag="footer" title="">
            <b-card class="sa-card" header="VALORI" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow-y: auto; overflow-x: hidden">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <label class="sa-label-data">Altezza(cm)</label>
                        <b-form-input v-model="esameObiettivo.altezza" disabled></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="6" xl="6">
                        <label class="sa-label-data">Peso(kg)</label>
                        <b-form-input v-model="esameObiettivo.peso" disabled></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">FVC (L/s)</label>
                        <b-form-input type="number" v-model="data.fvc" placeholder="FVC"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">FEV1 (L)</label>
                        <b-form-input type="number" v-model="data.fev1" placeholder="FEV1"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                        <label class="sa-label-data">FEV1/FVC% (%)</label>
                        <b-form-input type="number" v-model="data.fev1Fvc" placeholder="FEV/FVC"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                        <label class="sa-label-data">PEF(L/s)</label>
                        <b-form-input type="number" v-model="data.pef" placeholder="PEF"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                        <label class="sa-label-data">FEF-25-75% (L/s)</label>
                        <b-form-input type="number" v-model="data.fef2575" placeholder="FEF-25-75%"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">MEF25% (L/s)</label>
                        <b-form-input type="number" v-model="data.mef25" placeholder="MEF25%"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">MEF50% (L/s)</label>
                        <b-form-input type="number" v-model="data.mef50" placeholder="MEF50%"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">MEF75% (L/s)</label>
                        <b-form-input type="number" v-model="data.mef75" placeholder="MEF75"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">MVV(L/m)</label>
                        <b-form-input type="number" v-model="data.mvv" placeholder="MVV"></b-form-input>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <div>
            <b-card class="sa-card" header="VALORI NORMALI" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow-y: auto; overflow-x: hidden">
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">FVC (L/s)</label>
                        <b-form-input type="number" v-model="data.fvcNormale" placeholder="FVC"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">FEV1 (L)</label>
                        <b-form-input type="number" v-model="data.fev1Normale" placeholder="FEV1"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                        <label class="sa-label-data">FEV1/FVC% (%)</label>
                        <b-form-input type="number" v-model="data.fev1FvcNormale" placeholder="FVC"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                        <label class="sa-label-data">PEF(L/s)</label>
                        <b-form-input type="number" v-model="data.pefNormale" placeholder="PEF"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="2" xl="2">
                        <label class="sa-label-data">FEF-25-75% (L/s)</label>
                        <b-form-input type="number" v-model="data.fef2575Normale" placeholder="FEF-25-75%"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">MEF25% (L/s)</label>
                        <b-form-input type="number" v-model="data.mef25Normale" placeholder="MEF25%"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">MEF50% (L/s)</label>
                        <b-form-input type="number" v-model="data.mef50Normale" placeholder="MEF50% "></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">MEF75% (L/s)</label>
                        <b-form-input type="number" v-model="data.mef75Normale" placeholder="MEF75%"></b-form-input>
                    </b-col>
                    <b-col xs="12" sm="12" md="12" lg="3" xl="3">
                        <label class="sa-label-data">MVV(L/m)</label>
                        <b-form-input type="number" v-model="data.mvvNormale" placeholder="MVV"></b-form-input>
                    </b-col>
                </b-row>
            </b-card>
        </div>
        <div>
            <b-card class="sa-card" header="CONCLUSIONI" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow-y: auto; overflow-x: hidden">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Conclusioni</label>
                        <b-form-textarea v-model="data.conclusioni" type="text" no-resize placeholder="Conclusioni"></b-form-textarea>
                    </b-col>
                </b-row>
            </b-card>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    fvc: null,
                    fev1: null,
                    fev1Fvc: null,
                    pef: null,
                    fef2575: null,
                    mef25: null,
                    mef50: null,
                    mef75: null,
                    mvv: null,
                    conclusioni: "",
                    firma: "",
                    /*Valori Nomali */

                    fvcNormale: null,
                    fev1Normale: null,
                    fev1FvcNormale: null,
                    pefNormale: null,
                    fef2575Normale: null,
                    mef25Normale: null,
                    mef50Normale: null,
                    mef75Normale: null,
                    mvvNormale: null,
                };
            },
        },
        esameObiettivo: {
            Type: Object,
            default: function () {
                return {};
            },
        },
    },
};
</script>
