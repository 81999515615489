<template>
    <sa-page-layout :titolo="title" :showModalLoading="showModalLoading" :linkedit="linkedit" :btnNewVisible="false" class="sa-no-space">
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <div>
                    <b-card v-for="item in items" :key="item.id" class="sa-tree-row">
                        <i class="sa-callapse bi bi-chevron-right arrow" v-b-toggle="getCallapseId(item.routeId)"></i><span class="sa-tree-title">{{ item.text }}</span>
                        <b-collapse visible :id="getCallapseId(item.routeId)" class="mt-2">
                            <b-row>
                                <b-col v-for="child in item.children" :key="child.routeId" cols="6" xs="6" sm="4" md="4" lg="4" xl="2">
                                    <b-form-checkbox class="sa-tree-item" v-model="child.selected" name="check-button" switch>{{ child.text }}</b-form-checkbox>
                                </b-col>
                            </b-row>
                        </b-collapse>
                    </b-card>
                </div>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            checked: true,
            showModalLoading: false,
            linkedit: "",
            perPage: 100,
            filtro: {},
            items: [],
            gruppo: { nome: "" },
            voceMenuAssegnate: [],
            idGruppo: null,
            pathResource: "/pazienteeventimenu/configuration",
            pathResourceGruppo: "/gruppi",
            pathResourceMenuAssegnata: "/pazienteeventigruppimenu",
            fields: [
                {
                    label: "Gruppo",
                    key: "gruppo",
                    sortable: true,
                },
                {
                    label: "Menu",
                    key: "eventoMenu",
                    sortable: true,
                },
                {
                    label: "",
                    key: "actions",
                    thStyle: "width: 3rem",
                },
            ],
        };
    },
    computed: {
        title: {
            get() {
                return "Configurazione Menu Eventi Paziente - " + this.gruppo.nome;
            },
        },
    },
    mounted() {
        let me = this;
        me.idGruppo = this.$route.params.id;
        me.loadData();
    },
    methods: {
        setSelected(children) {
            let me = this;
            children.forEach((element) => {
                me.voceMenuAssegnate.forEach((voceMenu) => {
                    if (voceMenu.idPazienteEventoMenu === element.routeId) {
                        element.selected = true;
                    } else {
                        element.selected = false;
                    }
                });
            });
        },
        getCallapseId(value) {
            return "callapse-" + value;
        },
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceGruppo + "/" + me.idGruppo;
            axios
                .get(link)
                .then((response) => {
                    me.gruppo = response.data.data;
                    me.showModalLoading = false;
                    me.loadMenuAssegnaato();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        loadMenuAssegnaato() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResourceMenuAssegnata + "?idGruppo=" + me.gruppo.id;
            axios
                .get(link)
                .then((response) => {
                    me.voceMenuAssegnate = response.data.data;
                    me.showModalLoading = false;
                    me.loadMenu();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        loadMenu() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        me.setSelected(element.children);
                        me.items.push(element);
                    });
                    me.showModalLoading = false;
                })
                .catch((e) => {
                    console.log(e);
                });
        },
    },
};
</script>

<style>
.sa-tree-item .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #4cd964;
    background-color: #fff;
    box-shadow: none;
}

.sa-tree-item.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #4cd964;
    transform: translateX(0.75rem);
}

.sa-tree-item label {
    font-size: 0.89rem;
    white-space: nowrap;
}
.sa-tree-title {
    font-weight: bold;
    margin-left: 0.1rem;
    font-size: 1rem;
}
.card.sa-tree-row {
    background-color: var(--bg-sidebar);
}
.collapsed::before {
    transition: all 0.5s ease;
}
.not-collapsed::before {
    transform: rotate(90deg);
    transition: all 0.5s ease;
    /* display: block;
    position: relative; */
}
</style>
