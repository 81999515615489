<template>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
        <b-row>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Nome Tabella:</label>
                <b-form-input v-model="jsonData.dbName"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                <label class="sa-label-data">Nome Visualizzato:</label>
                <b-form-input v-model="jsonData.viewName"></b-form-input>
            </b-col>
            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                <label class="sa-label-data">Note:</label>
                <b-form-textarea v-model="jsonData.note" rows="7"></b-form-textarea>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import axios from "axios";
export default {
    data() {
        return {
            id: "-1",
            pathResource: "/dataanalysistables",
            jsonData: { dbName: "", viewName: "", note: "" },
        };
    },
    mounted: function () {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
        loadData() {
            let me = this;
            this.$emit("beforeLoadData");
            if (me.id !== "-1") {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
                axios.get(link + me.id).then((response) => {
                    me.jsonData = response.data.data;
                    this.$emit("afterLoadData");
                });
            } else {
                this.$emit("afterLoadData");
            }
        },
    },
};
</script>

<style></style>
