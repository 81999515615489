<template>
  <sa-page-layout :btnSaveNotBackVisible="true" :linkNotBack="linkback" :data="jsonData" :showModalLoading="showModalLoading" :pathResource="pathResource" class="sa-no-space">
    <template slot="table-body">
      <digital-sign-user-edit-component :idUtente="utente.id" @update="onUpdateJsonData"></digital-sign-user-edit-component>
    </template>
  </sa-page-layout>
</template>

<script>
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
import DigitalSignUserEditComponent from "../components/DigitalSignUserEditComponent.vue";
export default {
  components: { SaPageLayout, DigitalSignUserEditComponent },
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  data() {
    return {
      pathResource: "/digitalsignusers",
      linkback: "/digitalsignuser{editid}",
      showModalLoading: false,
      //   id: "-1",
      jsonData: { id: null, username: null, pin: null, gestore: null, tipo: null },
    };
  },
  methods: {
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
  },
};
</script>

<style></style>
