<template>
    <div class="sa-tab-scheda">
        <div class="sa-tab-scheda-row-fill">
            <b-row style="background-color: var(--bg-main-container); height: 100%">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="height: 33%">
                    <b-card class="sa-card" header="Esame ORL" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                {{ data.esameOrl }}
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="height: 33%">
                    <b-card class="sa-card" header="ESAME AUDIOMETRICO" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                {{ data.esameAudiometrico }}
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12" style="height: 33%">
                    <b-card class="sa-card" header="ESAME NEUROLOGICO" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow-y: auto; overflow-x: hidden">
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                {{ data.esameNeurologico }}
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>

        <b-card class="sa-card" header="FIRMA" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    {{ data.firma }}
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    esameOrl: "",
                    esameAudiometrico: "",
                    esameNeurologico: "",
                };
            },
        },
    },
};
</script>
