<template>
    <sa-page-layout :titolo="titolo" :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" :data="jsonData" :showModalLoading="showModalLoading" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Report Name</label>
                        <span class="sa-data">{{ jsonData.reportName }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Nome File</label>
                        <span class="sa-data">{{ jsonData.nomeFile }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                        <label class="sa-label-data">Descrizione</label>
                        <span class="sa-data">{{ jsonData.descrizione }}</span>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Versione</label>
                        <span class="sa-data">{{ jsonData.versione }}</span>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Stato</label>
                        <span class="sa-data">{{ jsonData.stato }}</span>
                    </b-col>
                </b-row>
                <hr />
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                        <label class="sa-label-data">Test Data</label>
                        <!-- <span class="sa-data">{{ jsonData.testData }}</span> -->
                        <b-form-textarea disabled no-resize rows="30" v-model="jsonData.testData"></b-form-textarea>
                    </b-col>
                </b-row>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/printserver/edit/" + me.id;
        me.loadData();
    },
    data() {
        return {
            titolo: "Print Server View",
            pathResource: "/printserver",
            linkback: "/printserver",
            id: "",
            linkedit: null,
            showModalLoading: false,
            jsonData: {},
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios.get(link + me.id).then((response) => {
                me.jsonData = response.data.data;
                me.showModalLoading = false;
            });
        },
    },
};
</script>
