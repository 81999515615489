<template>
  <div>
    <b-button size="sm" class="no-text" variant="outline-info" @click="onCondivisioneClick()">
      <b-icon icon="share" variant="outline-primary" />
    </b-button>

    <b-modal ref="mdlCondivisione" size="xl" title="Utenti" modal-class="sa-modal-table" style="height: 100%; overflow-y: hidden" scrollable>
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="tblCondivisione" id="tblCondivisione" stacked="lg" striped hover :items="items" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(cognomenome)="{ item }">
            <span>{{ item.lastname }} {{ item.lastname }} ({{ item.username }})</span>
          </template>
          <template v-slot:cell(statocondivisione)="{ item }">
            <b-form-checkbox :checked="isActive(item.idSezione, item.idRisorsa)" switch @change="onChangeCondivisione(item)">
              <span v-if="item.scadenzaCondivisione">
                Fino al:
                <b>{{ getScadenzaCondivisione(item.scadenzaCondivisione) }}</b>
              </span>
            </b-form-checkbox>
          </template>
        </b-table>
      </div>
      <template #modal-footer>
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
            <b-button size="sm" variant="danger" @click="onCloseClick()">Chiudi</b-button>
          </b-col>
        </b-row>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import UtilityMixin from "../UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    idRisorsa: {
      type: String,
      required: true,
    },
    idSezione: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      pathResource: "/condivisione",
      items: [],
      fields: [
        {
          label: "Cognome e Nome",
          key: "cognomenome",
          sortable: true,
        },
        {
          label: "Profilo",
          key: "profilo",
          sortable: true,
          thStyle: "width: 18rem",
        },
        {
          label: "Stato Cond.",
          key: "statocondivisione",
          thStyle: "width: 18rem",
        },
      ],
    };
  },
  methods: {
    loadCondivisioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      let queryParams = { idSezione: me.idSezione, idRisorsa: me.idRisorsa };
      axios.get(link, { params: queryParams }).then((response) => {
        me.items = [];
        me.items = response.data.data;
        me.$refs.mdlCondivisione.show();
      });
    },
    onCloseClick() {
      let me = this;
      me.$refs.mdlCondivisione.hide();
    },
    onCondivisioneClick() {
      this.loadCondivisioni();
    },
    isActive(idSezione) {
      let returnValue = idSezione ? true : false;
      return returnValue;
    },
    getScadenzaCondivisione(scadenzaCondivisione) {
      return UtilityMixin.methods.formatDateTime(scadenzaCondivisione);
    },
    onChangeCondivisione(item) {
      let me = this;
      if (!item.idRisorsa) {
        me.creaCondivisione(item);
      } else {
        me.rimuoviCondivisione(item);
      }
    },
    creaCondivisione(item) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      let params = { usernameUtenteCondivisore: item.username, idRisorsa: me.idRisorsa, idSezione: me.idSezione };
      axios.post(link, params).then(() => {
        me.loadCondivisioni();
      });
    },
    rimuoviCondivisione(item) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + item.id;
      axios.delete(link).then(() => {
        me.loadCondivisioni();
      });
    },
  },
};
</script>

<style></style>
