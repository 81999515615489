<template>
    <sa-page-layout :showModalLoading="showModalLoading" :pathResource="pathResource" :data="jsonData" :btnEditVisible="false" :btnBackVisible="true" :linkback="linkback" :linkedit="linkedit" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-card class="sa-card" header="Scheda L.A.P." header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Codice Fiscale Medico</label>
                            <span class="sa-data">{{ jsonData.codiceFiscaleMedico }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Medico</label>
                            <span class="sa-data">{{ jsonData.nominativoMedico }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Anno Riferimento</label>
                            <span class="sa-data">{{ jsonData.annoRiferimento }}</span>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Numero Pazienti</label>
                            <span class="sa-data">{{ jsonData.numeroPazienti }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Numero Pazienti Diabetici</label>
                            <span class="sa-data">{{ jsonData.pazientiDiabetici }}</span>
                        </b-col>
                        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                            <label class="sa-label-data">Totale L.A.P.</label>
                            <span class="sa-data">{{ jsonData.totaleLap }}</span>
                        </b-col>
                    </b-row>
                </b-card>
                <b-card class="sa-card" header="Dettaglio L.A.P." header-tag="header" footer-tag="footer" title="">
                    <b-row>
                        <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                            <div class="b-table-sticky-header">
                                <b-table sticky-header ref="tbl" id="tbl" stacked="xl" striped hover :items="jsonData.listaLap" :fields="fieldsLapDettagli" :current-page="1" :per-page="100" sort-icon-left head-variant="light" class="sa-b-table">
                                    <template v-slot:cell(index)="row">
                                        {{ row.index + 1 }}
                                    </template>
                                    <template v-slot:cell(punto)="{ item }">
                                        <router-link class="sa-edit-link" :to="'/caduceolapmanagement/lapmedici/indicatore/' + jsonData.codiceFiscaleMedico + '_' + jsonData.annoRiferimento + '_' + item.punto + '?id=' + id">{{ item.punto }}</router-link>
                                    </template>
                                </b-table>
                            </div>
                        </b-col>
                    </b-row>
                </b-card>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    data() {
        return {
            id: "",
            linkedit: null,
            linkback: null,
            pathResource: "/lap",
            showModalLoading: false,
            jsonData: {
                id: "-1",
                codiceFiscaleMedico: null,
                nominativoMedico: "",
                totaleLap: 1000,
                numeroPazienti: 1518,
                pazientiDiabetici: 82,
                annoRiferimento: 2018,
                listaLap: [],
            },
            fieldsLapDettagli: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 1rem",
                },
                {
                    label: "Punto",
                    key: "punto",
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Descrizione",
                    key: "descrizione",
                    sortable: true,
                },
                {
                    label: "Percentuale",
                    key: "percentuale",
                    sortable: true,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Pazienti",
                    key: "pazienti",
                    sortable: true,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Differenza",
                    key: "differenza",
                    sortable: true,
                    thStyle: "width: 2rem",
                },
                {
                    label: "Obiettivo",
                    key: "obiettivo",
                    sortable: true,
                    thStyle: "width: 2rem",
                },
                {
                    label: "L.A.P.",
                    key: "lap",
                    sortable: true,
                    thStyle: "width: 2rem",
                },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        console.log(me.id);
        me.linkback = "/caduceolapmanagement/lapmedici";
        // if (me.id === "-1") {
        //     me.linkback = "/leonidaaccettazioni";
        // } else {
        //     me.linkback = "/leonidaaccettazioni/view/" + me.id;
        // }
        // if (this.$route.query.idPrenotazione) {
        //     me.idPrenotazione = this.$route.query.idPrenotazione;
        //     me.loadPrenotazione();
        // }
        if (me.id !== "-1") {
            me.loadData();
        }
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                    me.showModalLoading = false;
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },
    },
};
</script>

<style></style>
