<template>
    <sa-page-layout class="sa-no-space">
        <template slot="toolbar-title"></template>
        <template slot="toolbar-row"></template>
        <template slot="table-filter"></template>
        <template slot="table-header"></template>
        <template slot="table-body">
            <div class="sa-logo-home"><img :src="logo" :srcset="`${logo}`" /></div>
        </template>
        <template slot="table-footer"></template>
    </sa-page-layout>
</template>

<script>
import Vue from "vue";
import SaPageLayout from "../../../template/layout/components/SaPageLayout.vue";
export default {
    props: {
        utente: { type: Object },
    },
    components: { SaPageLayout },
    data() {
        return {
            logo: "css/" + Vue.prototype.appConfig.css + "/images/logo.png",
        };
    },
};
</script>

<style></style>
