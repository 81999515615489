<template>
    <div>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCV") }}
                        <eventi-storico-misure-component misura="cv" misuraLabel="Cv" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.cv }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCVF") }}
                        <eventi-storico-misure-component misura="cvf" misuraLabel="Cv" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.cvf }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblUBJVEMS") }}
                        <eventi-storico-misure-component misura="vems" misuraLabel="Vems" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.vems }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblVemsCvf") }}
                        <eventi-storico-misure-component misura="indice_tiffeneau" misuraLabel="indice tiffeneau" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.indiceTiffeneau }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblMVV") }}
                        <eventi-storico-misure-component misura="mvv" misuraLabel="Mvv" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.mvv }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCVNormal") }}
                        <eventi-storico-misure-component misura="normale_cv" misuraLabel="Mvv" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.normaleCv }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblCVFNormal") }}
                        <eventi-storico-misure-component misura="normale_cvf" misuraLabel="Normale Cvf" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.normaleCvf }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblUBJVEMSNormal") }}
                        <eventi-storico-misure-component misura="normale_vems" misuraLabel="normale vems" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.normaleVems }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblVemsCvfNormale") }}
                        <eventi-storico-misure-component misura="normale_indice_tiffeneau" misuraLabel="normale vems" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.normaleIndiceTiffeneau }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="2" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblMVVNormale") }}
                        <eventi-storico-misure-component misura="normale_mvv" misuraLabel="normale mvv" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <span class="sa-data">{{ jsonData.normaleMvv }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblReport") }}</label>
                    <span class="sa-data">{{ jsonData.refertoUrine }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("therapy.lblStatus") }}</label>
                    <span class="sa-data">{{ jsonData.firma }}</span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>
<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { EventiStoricoMisureComponent },
    props: {
        jsonData: {
            Type: Object,
            require: true,
        },
        tipoEvento: {
            Type: String,
            require: true,
        },
        gruppo: {
            Type: String,
            require: true,
        },
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.jsonData.idPaziente;
        },
    },
};
</script>
