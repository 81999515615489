<template>
    <b-card>
        <b-card-body>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <p>
                        <b>{{ getLabelTraduora("patient.afa.lblWeight") }}:</b>
                        {{ data.peso }}
                    </p>
                </b-col>

                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <p>
                        <b>{{ getLabelTraduora("patient.afa.lblHeight") }}:</b>
                        {{ data.altezza }}
                    </p>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <p>
                        <b>{{ getLabelTraduora("patient.afa.lblBMI") }}:</b>
                        {{ data.bmi }}
                    </p>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
                    <p>
                        <b>{{ getLabelTraduora("patient.afa.lblBSA") }}:</b>
                        {{ data.bsa }}
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblTBW") }}
                        <eventi-storico-misure-component misura="tbw" misuraLabel="TBW" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" step="0.01" v-model="data.tbw" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblECW") }}
                        <eventi-storico-misure-component misura="ecw" misuraLabel="ECW" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" step="0.01" v-model="data.ecw" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblBasalMetabolism") }}
                        <eventi-storico-misure-component misura="metabolismo_basale" misuraLabel="Metabolismo Basale" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" step="0.01" v-model="data.metabolismoBasale" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblLeanMass") }}
                        <eventi-storico-misure-component misura="massa_magra" misuraLabel="Massa Magra" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input type="number" step="0.01" v-model="data.massaMagra" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblFatMass") }}
                        <eventi-storico-misure-component misura="massa_grassa" misuraLabel="Massa Grassa" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.massaGrassa" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblMuscleMass") }}
                        <eventi-storico-misure-component misura="massa_muscolare" misuraLabel="Massa Muscolare" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input type="number" step="0.01" v-model="data.massaMuscolare" :disabled="!edit"></b-form-input>
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { EventiStoricoMisureComponent },
    props: {
        edit: {
            Type: Boolean,
        },
        data: {
            Type: Object,
            require: true,
        },
        gruppo: {
            Type: String,
            require: true,
        },
        tipoEvento: {
            Type: String,
            require: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.data.idPaziente;
        },
    },
    methods: {},
};
</script>
