<template>
  <div>
    <b-card class="sa-card" header="LUOGO DECESSO" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Luogo Del Decesso</label>
          <b-form-select v-model="jsonData.luogoDecesso" :options="localitaDecessoOptions" :value="''" value-field="id" text-field="descrizione" @input="onInputLuogoDecesso"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Indicare Altro Luogo Decesso</label>
          <b-form-input v-model="jsonData.luogoDecessoAltro" :disabled="altroLuogoDecessoDisabled"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Territorio in cui è avvenuto il decesso" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Provincia</label>
          <b-form-select v-model="jsonData.provinciaMorteSigla" :options="provinceMorteOptions" :value="''" value-field="sigla" text-field="provincia" @input="onInputProvinciaMorte"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Comune</label>
          <b-form-select v-model="jsonData.comuneDecessoCodiceIstat" :options="comuniMorteOptions" :value="''" value-field="codiceIstat" text-field="comune" @input="onInputComuneMorte"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Quartiere di Morte</label>
          <b-form-select v-model="jsonData.quartiereDecesso" :options="quartieriMorteOptions" :value="''" value-field="id" text-field="zonaQuartiere" :disabled="disableQuartiere"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">Distretto</label>
          <!-- <b-form-input v-model="jsonData.distrettoDecesso"></b-form-input> -->
          <b-form-select v-model="jsonData.distrettoDecesso" :options="distrettiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">Regione A.S.L.</label>
          <b-form-select v-model="jsonData.regioneAslDecesso" :options="regioniDecessoOptions" :value="''" value-field="codiceIstat" text-field="regione" @input="onInputRegioneDecesso"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="4" xl="4">
          <label class="sa-label-data">Codice A.S.L.</label>
          <b-form-select v-model="jsonData.codiceAsl" :options="aslCodiciOptions" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Riscontro Diagnostico</label>
          <b-form-select v-model="jsonData.riscontroDiagnostico" :options="YesNoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="6" xl="6">
          <label class="sa-label-data">Stato di Gravidanza della deceduta negli ultimi 12 mesi di vita (da compilare per le donne decedute in eta' fertile)</label>
          <b-form-select v-model="jsonData.statoGravidanza" :options="statiGravidanzaOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.sesso, 'F')"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dettagli nascita" header-tag="header" footer-tag="footer" title="" v-if="jsonData.tipoSchedaMorte === 'Morte nel 1° anno di vita'">
      <b-row>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">Peso Nascita (gr):</label>
          <b-form-input v-model="jsonData.pesoNascita"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">Eta Gestazionale (N. Settimane):</label>
          <b-form-input v-model="jsonData.etaGestazione"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">Parita':</label>
          <b-form-input v-model="jsonData.parita"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="12" lg="3" xl="3">
          <label class="sa-label-data">Eta' Madre (anni):</label>
          <b-form-input v-model="jsonData.etaMadre"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="DETTAGLI COMPLILAZIONE" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Compilazione</label>
          <date-picker format="DD-MM-YYYY" value-type="timestamp" v-model="jsonData.dataCompilazione" :disabled-date="disabledRange" type="date"></date-picker>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Cognome Nome Medico</label>
          <b-form-input v-model="jsonData.cognomeNomeMedico"></b-form-input>
        </b-col>
        <b-col xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Medico</label>
          <b-form-select v-model="jsonData.tipoMedico" :options="tipoMedicoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import DatePicker from "vue2-datepicker";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  props: {
    jsonData: {
      type: Array[Object],
      default: function () {
        return {
          luogoDecesso: "",
          riscontroDiagnostico: "",
          luogoDecessoAltro: "",
          provinciaMorteSigla: "",
          comuneDecessoCodiceIstat: "",
          quartiereDecesso: "",
          distrettoDecesso: "",
          regioneAslDecesso: "",
          codiceAsl: "",
          statoGravidanza: "",
          cognomeNomeMedico: "",
          tipoMedico: "",
          dataCompilazione: null,
        };
      },
    },
  },
  components: { DatePicker },
  data() {
    return {
      pathResourceLuoghiDecesso: "/rencamschedemorteluoghidecesso",
      pathResourceProvinceMorte: "/province",
      pathResourceComuniMorte: "/comuni/provincia",
      pathResourceQuartieriMorte: "/quartieri",
      pathResourceDistretti: "/rencamschedamortedistretti",
      pathResourceRegioni: "/regioni",
      pathResourceAsl: "/asl/regione",
      pathResourceStatoGravidanza: "/rencamschedemortestatogravidanza",
      pathResourceTipologiaMedico: "/rencamschedemortetipologiamedico",
      altroLuogoDecessoDisabled: true,
      localitaDecessoOptions: [{ id: "", descrizione: "-Seleziona Valore-" }],
      provinceMorteOptions: [{ sigla: "", provincia: "- Seleziona Provincia-" }],
      comuniMorteOptions: [{ codiceIstat: "", comune: "-Seleziona Comune-" }],
      quartieriMorteOptions: [{ id: "", zonaQuartiere: "-Seleziona Quartiere-" }],
      statiGravidanzaOptions: [{ value: "", text: "-Seleziona Stato Gravidanza-" }],
      tipoMedicoOptions: [{ value: "", text: "-Seleziona Tipo Medico-" }],
      aslCodiciOptions: [{ value: "", text: "-Seleziona Valore-" }],
      regioniDecessoOptions: [{ codiceIstat: "", regione: "-Seleziona Regione-" }],
      mediciOptions: [{ value: "", text: "-Seleziona Medico-" }],
      distrettiOptions: [{ value: "", text: "-Seleziona Distretti-" }],
      disableQuartiere: true,
      YesNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "Si" },
        { value: "NO", text: "No" },
      ],
      filtro: { forPage: 10000, page: 1 },
    };
  },
  mounted() {
    let me = this;
    me.loadProvinceMorte();
    me.loadDefaultData();
    if (me.jsonData.provinciaMorteSigla != "" && me.jsonData.provinciaMorteSigla != null) {
      me.loadComuniMorte(me.jsonData.provinciaMorteSigla, true);
      me.loadAsl(me.jsonData.regioneAslDecesso + "0");
    }
  },
  watch: {
    // jsonData: {
    //     handler(jsonData) {
    //         console.log(jsonData);
    //         // this.$emit("update", jsonData);
    //     },
    //     deep: true,
    // },
  },
  methods: {
    loadDefaultData() {
      let me = this;
      me.loadLocalitaDecesso();
      me.loadStatoGravidanza();
      me.loadRegioni();
      me.loadTipologiaMedico();
      me.loadDistretti();
    },
    disabledRange: function (date) {
      let me = this;
      // console.log();
      return date < new Date(me.jsonData.dataMorte) || date > new Date(new Date());
    },
    loadLocalitaDecesso() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceLuoghiDecesso;
      axios.get(link, { params: me.filtro }).then((response) => {
        me.localitaDecessoOptions = response.data.data.list;
        me.localitaDecessoOptions.unshift({ id: "", descrizione: "-Seleziona Valore-" });
      });
    },
    onInputLuogoDecesso(luogoDecesso) {
      let me = this;
      if (luogoDecesso === "9") {
        me.altroLuogoDecessoDisabled = false;
      } else {
        me.altroLuogoDecessoDisabled = true;
        me.jsonData.luogoDecessoAltro = "";
      }
    },

    loadProvinceMorte() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceProvinceMorte;
      axios.get(link).then((response) => {
        me.provinceMorteOptions = response.data.data;
        me.provinceMorteOptions.unshift({ sigla: "", provincia: "- Seleziona Provincia-" });
      });
    },
    onInputProvinciaMorte(provinciaMorte) {
      let me = this;
      if (provinciaMorte !== "") {
        me.jsonData.comuneDecessoCodiceIstat = "";
        me.loadComuniMorte(provinciaMorte);
        me.loadAsl(provinciaMorte);
      } else {
        me.jsonData.comuneDecessoCodiceIstat = "";
        me.jsonData.capMorte = "";
        me.comuniMorteOptions = [{ codiceIstat: "", comune: "-Seleziona Comune-" }];
      }
    },
    loadComuniMorte(provinciaMorte, loadQuartieri = false) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceComuniMorte + "/" + provinciaMorte;
      axios.get(link).then((response) => {
        me.comuniMorteOptions = response.data.data;
        me.comuniMorteOptions.unshift({ codiceIstat: "", comune: "-Seleziona Comune-" });

        if (loadQuartieri) {
          me.comuniMorteOptions.forEach((element) => {
            if (element.codiceIstat === me.jsonData.comuneDecessoCodiceIstat) {
              me.loadQuartieriMorte(element.comune);
            }
          });
        }
      });
    },
    onInputComuneMorte(comuneMorte) {
      let me = this;
      let comuneMorteDenominazione = "";
      me.comuniMorteOptions.forEach((element) => {
        if (element.codiceIstat === comuneMorte) {
          comuneMorteDenominazione = element.comune;
          me.jsonData.capMorte = element.cap;
          me.jsonData.quartiereDecesso = "";
        }
      });
      me.loadQuartieriMorte(comuneMorteDenominazione);
    },
    loadQuartieriMorte(comuneMorteDenominazione) {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceQuartieriMorte;
      me.disableQuartiere = true;
      axios.get(link, { params: { comune: comuneMorteDenominazione } }).then((response) => {
        me.quartieriMorteOptions = response.data.data;
        me.quartieriMorteOptions.unshift({ id: "", zonaQuartiere: "-Seleziona Quartiere-" });
        if (me.quartieriMorteOptions.length > 1) {
          me.disableQuartiere = false;
        }
      });
    },
    loadRegioni() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceRegioni;
      axios.get(link).then((response) => {
        me.regioniDecessoOptions = response.data.data;
        me.regioniDecessoOptions.unshift({ codiceIstat: "", regione: "-Seleziona Regione-" });
      });
    },
    onInputRegioneDecesso(codiceRegione) {
      let me = this;
      me.aslCodiciOptions = [{ value: "", text: "-Seleziona Valore-" }];
      let regione = codiceRegione + "0";
      me.loadAsl(regione);
    },
    loadAsl(regione) {
      let me = this;
      while (regione.charAt(0) === "0") {
        regione = regione.substring(1);
      }
      let link = process.env.VUE_APP_PATH_API + me.pathResourceAsl;
      axios.get(link, { params: { codiceRegione: regione } }).then((response) => {
        response.data.data.forEach((element) => {
          me.aslCodiciOptions.push({ value: element.denominazione, text: element.denominazione + " (" + element.codiceAzienda + ") " });
        });
      });
    },
    setRegione(provinciaMorte) {
      let me = this;
      me.provinceMorteOptions.forEach((provincia) => {
        if (provincia.provincia === provinciaMorte) {
          me.regioniDecessoOptions.forEach((regione) => {
            if (provincia.regione === regione.codiceIstat) {
              me.jsonData.regioneAslDecesso = regione.codiceIstat;
            }
          });
        }
      });
    },
    loadStatoGravidanza() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceStatoGravidanza;
      axios.get(link, { params: { forPage: 10000, page: 1 } }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.statiGravidanzaOptions.push({ value: element.id, text: element.descrizione });
        });
      });
    },

    loadTipologiaMedico() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceTipologiaMedico;
      axios.get(link, { params: { forPage: 10000, page: 1 } }).then((response) => {
        response.data.data.list.forEach((element) => {
          me.tipoMedicoOptions.push({
            value: element.id,
            text: element.descrizione,
          });
        });
      });
    },
    loadDistretti() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceDistretti;
      me.distrettiOptions = [];
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.distrettiOptions.push({ value: element.id, text: element.asl + " (" + element.distretto + ")" });
        });
        me.distrettiOptions.unshift({ value: "", text: "-Seleziona Distretti-" });
      });
    },
  },
};
</script>

<style></style>
