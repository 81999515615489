<template>
    <div class="sa-tab-scheda">
        <div class="sa-tab-scheda-row-fill" header="" header-tag="header" footer-tag="footer" title="">
            <b-row style="height: 100%">
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <b-card class="sa-card" header="Urine" header-tag="header" footer-tag="footer" title="" style="height: calc(100% - 0.5rem); overflow: auto">
                        <b-row>
                            <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Aspetto</label>
                                <span class="sa-data">{{ data.aspetto }}</span>
                            </b-col>
                            <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Colore</label>
                                <span class="sa-data">{{ data.colore }}</span>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Peso Specifico</label>
                                <span class="sa-data">{{ data.pesoSpecifico }}</span>
                            </b-col>
                            <b-col xs="6" sm="6" md="6" lg="6" xl="6">
                                <label class="sa-label-data">Reazione</label>
                                <span class="sa-data">{{ data.reazione }}</span>
                            </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                            <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                <label class="sa-label-data">Referto</label>
                                <span class="sa-data">{{ data.referto }}</span>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
            </b-row>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        data: {
            Type: Object,
            default: function () {
                return {
                    aspetto: "ddd",
                    colore: "",
                    pesoSpecifico: null,
                    firma: "",
                    reazione: "",
                    referto: "",
                };
            },
        },
    },
    data() {
        return {};
    },
};
</script>
