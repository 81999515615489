<template>
  <sa-page-layout :showModalLoading="showModalLoading" :linkedit="linkedit" :btnNewVisible="true">
    <template slot="toolbar-title">
      <!-- edit/-1 -->
      <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
    </template>
    <template slot="table-body">
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ strumentoRows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="strumentoRows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
      <hr />
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="strumentoItems" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
          <template v-slot:cell(descrizione)="{ item }">
            <router-link class="sa-edit-link" :to="'/paziente/gastroenterologia/configurazioneegds/strumento/view/' + item.id + '?idPaziente=' + idPaziente"> {{ item.descrizione }} </router-link>
          </template>
          <template v-slot:cell(id)="{ item }">
            <b-button @click="cancel(item.id)" variant="danger text-center" size="sm"><b-icon icon="trash"></b-icon></b-button>
          </template>
        </b-table>
      </div>
      <hr />
      <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
        <b-col cols="6">
          <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ strumentoRows }}</p>
        </b-col>
        <b-col cols="6">
          <b-pagination v-model="currentPage" :total-rows="strumentoRows" :per-page="perPage" align="right" size="sm"></b-pagination>
        </b-col>
      </b-row>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../../utility/UtilityMixin";
export default {
  mixins: [UtilityMixin],
  components: { SaPageLayout, PazienteAnagrafica },
  props: {
    utente: {
      type: Object,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      showModalLoading: false,
      linkedit: "/paziente/gastroenterologia/configurazioneegds/strumento",
      pathResouce: "/gastroenterologiaegdsstrumento",
      currentPage: 1,
      id: -1,
      perPage: 10,
      filtro: {},
      strumentoItems: [],
      fields: [
        {
          label: this.$i18n.t("agende.lblDescription"),
          key: "descrizione",
          sortable: true,
        },
        {
          label: "",
          key: "id",
          sortable: false,
        },
      ],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    strumentoRows() {
      return this.strumentoItems.length;
    },
    linkPatient: {
      get: function() {
        let returnValue = "";
        if (this.utente.gruppo) {
          if (this.utente.gruppo.menuGruppo) {
            if (this.utente.gruppo.menuGruppo.length > 1) {
              return "/paziente/view/" + this.idPaziente;
            } else {
              if (this.utente.gruppo.menuGruppo.length == 1) {
                if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                  return "/paziente/view/" + this.idPaziente;
                } else {
                  return "/paziente/homepaziente";
                }
              }
            }
          }
        }
        return returnValue;
      },
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.id = this.$route.params.id;

      let link1 = process.env.VUE_APP_PATH_API + me.pathResouce;
      axios
        .get(link1)
        .then((response) => {
          me.strumentoItems = response.data.data;
          console.log(response.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
    cancel(id) {
      let me = this;
      console.log(id);
      me.showModalLoading = true;
      let link1 = process.env.VUE_APP_PATH_API + me.pathResouce + "/" + id;
      axios
        .delete(link1)
        .then(() => {
          me.loadData();
          me.showModalLoading = false;
        })
        .catch((e) => {
          console.log(e);
          me.showModalLoading = false;
        });
    },
  },
};
</script>
