<template>
  <div class="sa-tab-scroll">
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Presenza Sintomi:</label>
          <b-form-select v-model="jsonData.presenzaSintomi" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Inizio Sintomi:</label>
          <date-picker v-model="jsonData.dataInizioSintomi" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ittero:</label>
          <b-form-select v-model="jsonData.ittero" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Febbre:</label>
          <b-form-select v-model="jsonData.febbre" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Dolore Addominale:</label>
          <b-form-select v-model="jsonData.doloreAddominale" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Perdita Appetito/Peso:</label>
          <b-form-select v-model="jsonData.perditaAppetitoPeso" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Nausea:</label>
          <b-form-select v-model="jsonData.nausea" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vomito:</label>
          <b-form-select v-model="jsonData.vomito" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diarrea:</label>
          <b-form-select v-model="jsonData.diarrea" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Urine Scure:</label>
          <b-form-select v-model="jsonData.urineScure" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Feci Chiare:</label>
          <b-form-select v-model="jsonData.feciChiare" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Mal di Testa:</label>
          <b-form-select v-model="jsonData.malTesta" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Dolori Articolari:</label>
          <b-form-select v-model="jsonData.doloriArticolari" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Astenia:</label>
          <b-form-select v-model="jsonData.astenia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Quadro Clinico Neurologico:</label>
          <b-form-select v-model="jsonData.quadroClinicoNeurologico" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="!chekCondition(jsonData.quadroClinicoNeurologico, 'SI')" class="sa-card" header="Quadro Clinico Neurologico" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Paralisi di Ball:</label>
          <b-form-select v-model="jsonData.paralisiBell" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Mielite Traversa Acuta:</label>
          <b-form-select v-model="jsonData.mieliteTraversaAcuta" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Sindrome di Guillain-Barre':</label>
          <b-form-select v-model="jsonData.sindromeGuillainBarre" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Amiotrofia Nevralgica:</label>
          <b-form-select v-model="jsonData.amiotrofiaNevralgica" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Meningo-Encefalite:</label>
          <b-form-select v-model="jsonData.meningoEncefalite" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Ricovero:</label>
          <b-form-select v-model="jsonData.ricoveroOspedaliero" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Durata degenza:</label>
          <b-form-input v-model="jsonData.durataDegenza" :disabled="chekCondition(jsonData.ricoveroOspedaliero, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Epatite Fuminante:</label>
          <b-form-select v-model="jsonData.epatiteFuminante" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Anamnesi" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Gravidanza in Corso:</label>
          <b-form-select v-model="jsonData.gravidanzaCorso" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Settimana di Gestazione:</label>
          <b-form-input v-model="jsonData.settimanaGestazione" :disabled="chekCondition(jsonData.gravidanzaCorso, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Malattia Epatica Cronica:</label>
          <b-form-select v-model="jsonData.malattiaEptaicaCronica" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
          <label class="sa-label-data">Immunodepressione:</label>
          <b-form-select v-model="jsonData.immunodepressione" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card v-if="!chekCondition(jsonData.immunodepressione, 'SI')" class="sa-card" header="Causa Immunodepressione" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Terapia Immunosoppressiva:</label>
          <b-form-select v-model="jsonData.immunodepressioneCausaTerapiaImmunosoppressiva" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Asplenia:</label>
          <b-form-select v-model="jsonData.immunodepressioneCausaAsplenia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Malnutrizione:</label>
          <b-form-select v-model="jsonData.immunodepressioneCausaMalnutrizione" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trapianto Organo/Midollo Osseo:</label>
          <b-form-select v-model="jsonData.immunodepressioneCausaTrapaintoOrganoMidollo" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anno Trapianto:</label>
          <b-form-input v-model="jsonData.immunodepressioneCausaTrapaintoOrganoMidolloAnno" :disabled="chekCondition(jsonData.immunodepressioneCausaTrapaintoOrganoMidollo, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Hiv:</label>
          <b-form-select v-model="jsonData.immunodepressioneCausaHiv" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Diabete:</label>
          <b-form-select v-model="jsonData.immunodepressioneCausaDiabete" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tumore:</label>
          <b-form-select v-model="jsonData.immunodepressioneCausaTumore" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altro:</label>
          <b-form-input v-model="jsonData.immunodepressioneCausaAltro"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Complicanze" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Encefalopatia:</label>
          <b-form-select v-model="jsonData.encefalite" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Fulminante:</label>
          <b-form-select v-model="jsonData.fulminante" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trapianto di Fegato:</label>
          <b-form-select v-model="jsonData.trapiantoFegato" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Decesso:</label>
          <b-form-select v-model="jsonData.decesso" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Data Decesso:</label>
          <date-picker v-model="jsonData.dataDecesso" format="DD-MM-YYYY" value-type="timestamp" type="timestamp" :disabled="chekCondition(jsonData.decesso, 'SI')"></date-picker>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Causa Decesso:</label>
          <b-form-input v-model="jsonData.decessoCausaIniziale" :disabled="chekCondition(jsonData.decesso, 'SI')"></b-form-input>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Fattore di Rischio Alimentare (2 mesi precedenti)" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Acqua non Controllata:</label>
          <b-form-select v-model="jsonData.acquaNonControllata" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Frutti di Mare Crudi/Poco Cotti:</label>
          <b-form-select v-model="jsonData.fruttiMareCrudiPocoCotti" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Frutti di Mare:</label>
          <b-form-select v-model="jsonData.fruttiMareCrudiPocoCottiDettagli" :options="fruttiMareOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.fruttiMareCrudiPocoCotti, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altri Frutti Di Mare:</label>
          <b-form-input v-model="jsonData.fruttiMareCrudiPocoCottiAltro" :disabled="chekCondition(jsonData.fruttiMareCrudiPocoCottiDettagli, 'ALTRO')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Frutta Fresca non Lavata:</label>
          <b-form-select v-model="jsonData.fruttaFrescaNonLavata" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Frutta Fresca:</label>
          <b-form-select v-model="jsonData.fruttaFrescaNonLavataDettagli" :options="fruttaOptions" :value="''" value-field="value" text-field="text" :disabled="chekCondition(jsonData.fruttaFrescaNonLavata, 'SI')"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Altra Frutta Fresca:</label>
          <b-form-input v-model="jsonData.fruttaFrescaNonLavataAltro" :disabled="chekCondition(jsonData.fruttaFrescaNonLavataDettagli, 'ALTRO')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Verdura Cruda non Lavata:</label>
          <b-form-select v-model="jsonData.verduraCrudaNonLavata" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Verdura:</label>
          <b-form-input v-model="jsonData.verduraCrudaNonLavataDettagli" :disabled="chekCondition(jsonData.verduraCrudaNonLavata, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Carne Maiale Cruda/Poco Cotta:</label>
          <b-form-select v-model="jsonData.carneMaialeCrudaPocoCotta" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Insaccati/Salsicce Maiale non Stagionati:</label>
          <b-form-select v-model="jsonData.insaccatiSalsicceMaialeNonStagionati" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Salsicce di Fegato Maiale non Stagionate:</label>
          <b-form-select v-model="jsonData.salsicceFegatoMaialeNonStagionate" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Carne Cinghiale Cruda/Poco Cotta:</label>
          <b-form-select v-model="jsonData.carneCinghialecrudaPocoCotta" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Salsicce Cinghiale non Stagionate:</label>
          <b-form-select v-model="jsonData.salsicceCinghialeNonStagionate" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Carni Altra Selvaggina Cruda/Poco Cotta:</label>
          <b-form-select v-model="jsonData.carneAltraSelvvagginaCrudaPocoCotta" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="3" lg="3" xl="3">
          <label class="sa-label-data">Specificare Animale:</label>
          <b-form-input v-model="jsonData.carneAltraSelvvagginaCrudaPocoCottaAnimale" :disabled="chekCondition(jsonData.carneAltraSelvvagginaCrudaPocoCotta, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Carne Cruda/Poco Cotta di Altro Animale:</label>
          <b-form-select v-model="jsonData.carneCrudaPocoCottaAltroAnimale" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Animale:</label>
          <b-form-input v-model="jsonData.carneCrudaPocoCottaAltroAnimaleDettagli" :disabled="chekCondition(jsonData.carneCrudaPocoCottaAltroAnimale, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Frattaglie:</label>
          <b-form-select v-model="jsonData.frattaglie" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Altri Fattori Di Rischio (2 mesi precedenti)" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Viaggio (1 o piu' notto fuori citta di residenza):</label>
          <b-form-select v-model="jsonData.viaggi" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
      <b-row v-if="!chekCondition(jsonData.viaggi, 'SI')">
        <b-table sticky-header ref="tblViaggi" stacked="xl" striped hover itemscope :items="jsonData.listaViaggi" :fields="fieldsViaggi" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="jsonData">
            {{ jsonData.index + 1 }}
          </template>
          <template #cell(dove)="item">
            <b-form-input v-model="item.item.dove" placeholder="Luogo"></b-form-input>
          </template>
          <template #cell(giornoDal)="item">
            <date-picker v-model="item.item.giornoDal" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
          </template>
          <template #cell(giornoAl)="item">
            <date-picker v-model="item.item.giornoAl" format="DD-MM-YYYY" value-type="timestamp" type="timestamp"></date-picker>
          </template>
          <template #cell(actions)="row">
            <b-button v-if="row.index == jsonData.listaViaggi.length - 1" size="sm" variant="btn btn-outline-success waves-effect waves-light width-sm no-text" @click="onAddViaggi(jsonData.listaViaggi, row.item)">
              <b-icon icon="plus"></b-icon>
            </b-button>
            <b-button v-if="row.index !== jsonData.listaViaggi.length - 1" size="sm" variant="btn btn-outline-danger waves-effect waves-light width-sm no-text" @click="onDelete(jsonData.listaViaggi, row)">
              <b-icon icon="trash"></b-icon>
            </b-button>
          </template>
        </b-table>
      </b-row>
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Vita/Lavoro in Fattoria/Allevamento:</label>
          <b-form-select v-model="jsonData.viveLavoraFattoriaAllevamento" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Pratica Giardinaggio:</label>
          <b-form-select v-model="jsonData.praticaGiardinaggio" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Possesso Orto:</label>
          <b-form-select v-model="jsonData.orto" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Utilizzo Letame come Concime:</label>
          <b-form-select v-model="jsonData.letameConcime" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Pratica Caccia:</label>
          <b-form-select v-model="jsonData.caccia" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Possesso Animali Domestici:</label>
          <b-form-select v-model="jsonData.animaliDomestici" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Animale:</label>
          <b-form-input v-model="jsonData.animaliDomesticiDettaglio" :disabled="chekCondition(jsonData.animaliDomestici, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Possesso Animali Allevamento:</label>
          <b-form-select v-model="jsonData.animaliAllevamento" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Specificare Animale:</label>
          <b-form-input v-model="jsonData.animaliAllevamentoDettaglio" :disabled="chekCondition(jsonData.animaliAllevamento, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Contatto Soggetti con Stessa Diagnosi:</label>
          <b-form-select v-model="jsonData.contattiSoggettiStessaDiagnosi" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Tipo Rapporto:</label>
          <b-form-input v-model="jsonData.contattiSoggettiStessaDiagnosiTipoRapporto" :disabled="chekCondition(jsonData.contattiSoggettiStessaDiagnosi, 'SI')"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Trasfusione Sangue/Emoderivati:</label>
          <b-form-select v-model="jsonData.trasfusioneSangueEmoderivati" :options="siNoNonNotoOptions" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati di Laboratorio" header-tag="header" footer-tag="footer" title="">
      <b-row>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Igm Anti-HEV:</label>
          <b-form-select v-model="jsonData.igmAntiHev" :options="positivoNegativoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">IgG Anti-HEV:</label>
          <b-form-select v-model="jsonData.iggAntiHev" :options="positivoNegativoNonRicercatoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">HEV-RNA Su Siero:</label>
          <b-form-select v-model="jsonData.hevRnaSiero" :options="positivoNegativoNonRicercatoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">HEV-RNA Su Feci:</label>
          <b-form-select v-model="jsonData.hevRnaFeci" :options="positivoNegativoNonRicercatoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Genotipo:</label>
          <b-form-input v-model="jsonData.rnaPositivoGenotipo" :disabled="checkGenotipo()"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Valore Massimo AST/GPT (UI/L):</label>
          <b-form-input v-model="jsonData.valoreMassimoAstGpt"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Valore Massimo ALT/GPT (UI/L):</label>
          <b-form-input v-model="jsonData.valoreMassimoAltGot"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Bilirubina Totale (MG/DL):</label>
          <b-form-input v-model="jsonData.bilirubinaTotale"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Bilirubina Diretta (MG/DL):</label>
          <b-form-input v-model="jsonData.bilirubinaDiretta"></b-form-input>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anti-HBc:</label>
          <b-form-select v-model="jsonData.antiHbc" :options="positivoNegativoNonRicercatoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">IgM Anti-HAV:</label>
          <b-form-select v-model="jsonData.igmAntiHav" :options="positivoNegativoNonRicercatoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">IgM Anti-HBc:</label>
          <b-form-select v-model="jsonData.igmAntiHbc" :options="positivoNegativoNonRicercatoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">Anti-HCV:</label>
          <b-form-select v-model="jsonData.antiHcv" :options="positivoNegativoNonRicercatoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">HbsAg:</label>
          <b-form-select v-model="jsonData.hbsag" :options="positivoNegativoNonRicercatoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
        <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
          <label class="sa-label-data">HCV-RNA:</label>
          <b-form-select v-model="jsonData.hcvRna" :options="positivoNegativoNonRicercatoOption" :value="''" value-field="value" text-field="text"></b-form-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card class="sa-card" header="Dati Notifica" header-tag="header" footer-tag="footer" title="">
      <indagine-epidemiologica-dati-notifica-component :jsonData="jsonData" :isEdit="true" @update="onUpdateJsonData"></indagine-epidemiologica-dati-notifica-component>
    </b-card>
  </div>
</template>
<script>
import axios from "axios";
import Vue from "vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import DatePicker from "vue2-datepicker";
import IndagineEpidemiologicaDatiNotificaComponent from "./IndagineEpidemiologicaDatiNotificaComponent.vue";
export default {
  props: {
    utente: {
      type: Object,
      default: function () {
        return null;
      },
    },
  },
  components: { IndagineEpidemiologicaDatiNotificaComponent, DatePicker },
  mixins: [UtilityMixin],
  data() {
    return {
      pathResource: "/malattieinfettiveieepatitee",
      pathResourceMalattiaInfettivaSegnalazione: "/malattieinfettivesegnalazioni",
      linkback: null,
      currentPage: 1,
      perPage: 100,
      showModalLoading: false,
      jsonData: {
        titoloStudio: "",
        professione: "",
        presenzaSintomi: "",
        dataInizioSintomi: null,
        ittero: "",
        febbre: "",
        doloreAddominale: "",
        perditaAppetitoPeso: "",
        nausea: "",
        vomito: "",
        diarrea: "",
        urineScure: "",
        feciChiare: "",
        malTesta: "",
        doloriArticolari: "",
        astenia: "",
        quadroClinicoNeurologico: "",
        paralisiBell: "",
        mieliteTraversaAcuta: "",
        sindromeGuillainBarre: "",
        amiotrofiaNevralgica: "",
        meningoEncefalite: "",
        quadroClinicoNeurologicoAltro: "",
        ricoveroOspedaliero: "",
        durataDegenza: "",
        epatiteFuminante: "",
        gravidanzaCorso: "",
        settimanaGestazione: "",
        malattiaEptaicaCronica: "",
        immunodepressione: "",
        immunodepressioneCausaTerapiaImmunosoppressiva: "",
        immunodepressioneCausaAsplenia: "",
        immunodepressioneCausaMalnutrizione: "",
        immunodepressioneCausaTrapaintoOrganoMidollo: "",
        immunodepressioneCausaTrapaintoOrganoMidolloAnno: "",
        immunodepressioneCausaHiv: "",
        immunodepressioneCausaDiabete: "",
        immunodepressioneCausaTumore: "",
        immunodepressioneCausaAltro: "",
        encefalite: "",
        fulminante: "",
        trapiantoFegato: "",
        decesso: "",
        dataDecesso: "",
        decessoCausaIniziale: "",
        acquaNonControllata: "",
        fruttiMareCrudiPocoCotti: "",
        fruttiMareCrudiPocoCottiDettagli: "",
        fruttiMareCrudiPocoCottiAltro: "",
        fruttaFrescaNonLavata: "",
        fruttaFrescaNonLavataDettagli: "",
        fruttaFrescaNonLavataAltro: "",
        fruttiBoscoSurgelati: "",
        fruttiBoscoSurgelatiDettagli: "",
        fruttiBoscoSurgelatiAltro: "",
        verduraCrudaNonLavata: "",
        verduraCrudaNonLavataDettagli: "",
        carneMaialeCrudaPocoCotta: "",
        insaccatiSalsicceMaialeNonStagionati: "",
        salsicceFegatoMaialeNonStagionate: "",
        carneCinghialecrudaPocoCotta: "",
        salsicceCinghialeNonStagionate: "",
        carneAltraSelvvagginaCrudaPocoCotta: "",
        carneAltraSelvvagginaCrudaPocoCottaAnimale: "",
        carneCrudaPocoCottaAltroAnimale: "",
        carneCrudaPocoCottaAltroAnimaleDettagli: "",
        frattaglie: "",
        viveLavoraFattoriaAllevamento: "",
        praticaGiardinaggio: "",
        orto: "",
        letameConcime: "",
        caccia: "",
        animaliDomestici: "",
        animaliDomesticiDettaglio: "",
        animaliAllevamento: "",
        animaliAllevamentoDettaglio: "",
        contattiSoggettiStessaDiagnosi: "",
        contattiSoggettiStessaDiagnosiTipoRapporto: "",
        trasfusioneSangueEmoderivati: "",
        igmAntiHev: "",
        iggAntiHev: "",
        hevRnaSiero: "",
        hevRnaFeci: "",
        rnaPositivoGenotipo: "",
        valoreMassimoAstGpt: "",
        valoreMassimoAltGot: "",
        bilirubinaTotale: "",
        bilirubinaDiretta: "",
        antiHbc: "",
        igmAntiHav: "",
        igmAntiHbc: "",
        hbsag: "",
        hcvRna: "",
        sanitarioNotificante: "",
        sanitarioNotificanteContatto: "",
        viaggi: "",
        antiHcv: "",
        dataNotifica: null,
        listaViaggi: [],
      },
      siNoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
      ],
      siNoNonNotoOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "SI", text: "SI" },
        { value: "NO", text: "NO" },
        { value: "NON NOTO", text: "NON NOTO" },
      ],
      fruttiMareOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "COZZE", text: "COZZE" },
        { value: "VONGOLE", text: "VONGOLE" },
        { value: "FASOLARI", text: "FASOLARI" },
        { value: "DATTERI DI MARE", text: "DATTERI DI MARE" },
        { value: "CANNOLICCHI", text: "CANNOLICCHI" },
        { value: "CAPESANTE", text: "CAPESANTE" },
        { value: "OSTRICHE", text: "OSTRICHE" },
        { value: "TARTUFI DI MARE", text: "TARTUFI DI MARE" },
        { value: "TELLINE", text: "TELLINE" },
        { value: "ALTRO", text: "ALTRO" },
      ],
      fruttaOptions: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "FRAGOLE", text: "FRAGOLE" },
        { value: "MIRTILLI", text: "MIRTILLI" },
        { value: "LAMPONI", text: "LAMPONI" },
        { value: "RIBES", text: "RIBES" },
        { value: "FRUTTI DI BOSCO MISTI", text: "FRUTTI DI BOSCO MISTI" },
        { value: "ALTRO", text: "ALTRO" },
      ],
      positivoNegativoOption: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
      ],
      positivoNegativoNonRicercatoOption: [
        { value: "", text: "-Seleziona Valore-" },
        { value: "POSITIVO", text: "POSITIVO" },
        { value: "NEGATIVO", text: "NEGATIVO" },
        { value: "NON RICERCATO", text: "NON RICERCATO" },
      ],
      fieldsViaggi: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Luogo",
          key: "dove",
          sortable: false,
        },
        {
          label: "Dal",
          key: "giornoDal",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "Al",
          key: "giornoAl",
          thStyle: "width: 11rem",
          sortable: false,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 4rem",
          tdClass: "text-center",
        },
      ],
    };
  },
  computed: {
    idPaziente() {
      return this.$route.query.idPaziente;
    },
    linkPatient() {
      return UtilityMixin.methods.getLinkPatient(this.utente, this.idPaziente);
    },
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.jsonData.idAnagrafica = me.idPaziente;
    me.jsonData.id = me.id;
    me.loadMalattiaInfettivaSegnalazione();
    me.loadData();
  },
  watch: {
    jsonData: {
      handler(jsonData) {
        this.$emit("update", jsonData);
      },
      deep: true,
    },
    "jsonData.viaggi": function (value) {
      let me = this;
      if (value === "SI") {
        me.jsonData.listaViaggi.push({ dove: "", giornoDal: null, giornoAl: null });
      } else {
        me.jsonData.listaViaggi = [];
      }
    },
    "jsonData.ricoveroOspedaliero": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.durataDegenza = "";
      }
    },
    "jsonData.gravidanzaCorso": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.settimanaGestazione = "";
      }
    },
    "jsonData.decesso": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.decessoCausaIniziale = "";
        me.jsonData.dataDecesso = null;
      }
    },
    "jsonData.fruttiMareCrudiPocoCotti": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.fruttiMareCrudiPocoCottiDettagli = "";
      }
    },
    "jsonData.fruttiMareCrudiPocoCottiDettagli": function (value) {
      let me = this;
      if (value !== "ALTRO") {
        me.jsonData.fruttiMareCrudiPocoCottiAltro = "";
      }
    },
    "jsonData.fruttaFrescaNonLavata": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.fruttaFrescaNonLavataDettagli = "";
      }
    },
    "jsonData.fruttaFrescaNonLavataDettagli": function (value) {
      let me = this;
      if (value !== "ALTRO") {
        me.jsonData.fruttaFrescaNonLavataAltro = "";
      }
    },
    "jsonData.verduraCrudaNonLavata": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.verduraCrudaNonLavataDettagli = "";
      }
    },
    "jsonData.carneAltraSelvvagginaCrudaPocoCotta": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.carneAltraSelvvagginaCrudaPocoCottaAnimale = "";
      }
    },
    "jsonData.carneCrudaPocoCottaAltroAnimale": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.carneCrudaPocoCottaAltroAnimaleDettagli = "";
      }
    },
    "jsonData.animaliDomestici": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.animaliDomesticiDettaglio = "";
      }
    },
    "jsonData.animaliAllevamento": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.animaliAllevamentoDettaglio = "";
      }
    },
    "jsonData.contattiSoggettiStessaDiagnosi": function (value) {
      let me = this;
      if (value !== "SI") {
        me.jsonData.contattiSoggettiStessaDiagnosiTipoRapporto = "";
      }
    },
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.setDatiMedico();
          me.showModalLoading = false;
        });
    },
    loadMalattiaInfettivaSegnalazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.pathResourceMalattiaInfettivaSegnalazione + "/";
      axios
        .get(link + me.id)
        .then((response) => {
          me.setDatiMalattia(response.data.data);
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    setDatiMedico() {
      let me = this;
      me.jsonData.sanitarioNotificante = Vue.prototype.user.firstname + " " + Vue.prototype.user.lastname;
    },
    setDatiMalattia(data) {
      let me = this;
      me.jsonData.id = data.id;
    },
    onUpdateJsonData(data) {
      let me = this;
      me.jsonData = data;
    },
    chekCondition(value, condizione) {
      let returnValue = true;
      if (value === condizione) {
        returnValue = false;
      }
      return returnValue;
    },
    onAddViaggi(array, value) {
      if (value.dove !== "" && value.giornoDal !== null && value.giornoAl !== null) {
        array.push({ dove: "", giornoDal: null, giornoAl: null });
      } else {
        this.$bvModal
          .msgBoxOk("Le Date di Inizio e Fine sono OBBLIGATORIE!", {
            title: "ATTENZIONE",
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          })
          .then((value) => {
            console.log(value);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    checkGenotipo() {
      let me = this;
      let returnValue = true;
      if (me.jsonData.hevRnaFeci === "POSITIVO" || me.jsonData.hevRnaSiero === "POSITIVO") {
        returnValue = false;
      } else {
        me.jsonData.rnaPositivoGenotipo = "";
      }
      return returnValue;
    },
    onDelete(array, row) {
      if (row.index != array.length) {
        this.$bvModal
          .msgBoxConfirm("Sicuro di voler procedere alla cancellazione?", {
            title: "Titolo",
            buttonSize: "sm",
            okVariant: "secondary",
            cancelVariant: "danger",
            okTitle: "SI",
            cancelTitle: "NO",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              array.splice(row.index, 1);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
};
</script>
