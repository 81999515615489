<template>
    <sa-page-layout :btnRefreshVisible="true" :btnNewVisible="true" :pathResource="pathResource" @refresh="onRefresh" :linkedit="linkedit">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                    <b-col sm="6">
                        <b-form-input v-model="filtro.nome" type="search" id="denominazione" placeholder="Nome Struttura"></b-form-input>
                    </b-col>
                    <b-col sm="6">
                        <b-form-input v-model="filtro.cognome" type="search" id="codiceStruttura" placeholder="Codice Struttura"></b-form-input>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" v-on:click="onSubmit" variant="info">{{ this.$i18n.t("structure.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("structure.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("structure.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" :filter="filter" responsive stacked="lg" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(denominazione)="{ item }">
                        <router-link class="sa-edit-link" :to="'/struttura/view/' + item.id"> {{ item.denominazione }} </router-link>
                    </template>
                    <template v-slot:cell(logo)="{ item }">
                        <img :src="item.logo" style="width: 3rem" />
                    </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row class="sa-label-info" style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col lg="3">
                    <p>{{ this.$i18n.t("structure.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col lg="9">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
//import moment from "moment";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
    components: { SaPageLayout },
    computed: {
        rows() {
            return this.items.length;
        },
    },
    data() {
        return {
            perPage: 10,
            currentPage: 1,
            filter: null,
            id: "-1",
            pathResource: "/strutture",
            linkedit: "/struttura",
            filtro: {},
            filterOn: [],
            fields: [
                {
                    label: this.$i18n.t("structure.lblLogo"),
                    key: "logo",
                    sortable: false,
                    thStyle: "width: 0.25rem",
                },
                {
                    label: this.$i18n.t("structure.lblName"),
                    key: "denominazione",
                    sortable: true,
                    //thStyle: "width: 7rem",
                },
                {
                    label: this.$i18n.t("structure.lblCompanyCode"),
                    key: "codiceStruttura",
                    //thStyle: "width: rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("structure.lblTelephoneNumber"),
                    key: "telefono",
                    sortable: true,
                },
                { class: "sa-table-column-action-single", key: "actions", label: "" },
            ],
            items: [],
        };
    },
    mounted: function () {
        let me = this;
        me.loadData();
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.isBusy = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.items = response.data.data;
                    //me.$refs["SaPageList"].close();
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onReset() {
            let me = this;
            me.filtro = {};
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },

        onRefresh() {
            let me = this;
            me.loadData();
        },
    },
};
</script>
