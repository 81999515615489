<template>
    <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :linkback="linkback" :linkedit="linkedit" :pathResource="pathResource" class="sa-no-space">
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <leonida-prenotazione-view-component :jsonData="jsonData"></leonida-prenotazione-view-component>
            </div>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import LeonidaPrenotazioneViewComponent from "./LeonidaPrenotazioneViewComponent.vue";
export default {
    components: { SaPageLayout, LeonidaPrenotazioneViewComponent },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.linkedit = "/prenotazioni/edit/" + me.id;
        me.loadData();
    },
    data() {
        return {
            linkback: "/prenotazioni",
            linkedit: null,
            pathResource: "/prenotazioni",
            id: "",
            jsonData: {
                nome: "",
                cognome: "",
                dataNascita: null,
                sport: "",
                tipoVisita: "",
                noteAggiuntive: "",
                tipoAtleta: "",
                dataVisita: null,
                oraVisita: "",
                telefono: "",
                email: "",
                scadenzaCertificato: null,
                dataPrenotazione: null,
            },
        };
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            axios
                .get(link + me.id)
                .then((response) => {
                    me.jsonData = response.data.data;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
    },
};
</script>
