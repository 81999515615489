<template>
    <eventi-view-component :jsonData="jsonData" :pathResource="pathResource" :pathResourceFirma="pathResourceFirma" :utente="utente" :titoloEvento="titoloEvento" :linkPrintData="linkPrintData">
        <template slot="tab-content-event">
            <monitoraggio-pressione-view-component :idEvento="id" @update="onUpdateJsonData"></monitoraggio-pressione-view-component>
        </template>
    </eventi-view-component>
</template>
<script>
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EventiMixins from "../../../utility/mixins/EventiMixins";
import EventiViewComponent from "../../../base/components/EventiViewComponent.vue";
import MonitoraggioPressioneViewComponent from "./MonitoraggioPressioneViewComponent.vue";
export default {
    mixins: [UtilityMixins, EventiMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { MonitoraggioPressioneViewComponent, EventiViewComponent },
    data() {
        return {
            titoloEvento: "Monitoraggio Pressione",
            linkback: null,
            pathResource: "/cardiomonitoraggipressione",
            pathResourceFirma: "/cardiomonitoraggipressione",
            jsonData: {},
            id: null,
            linkPrintData: null,
            tabIndex: 0,
            showModalLoading: false,
            fontScale: 2,
            linkedit: null,
            pathRestValidation: "/validation",
            isThisFirmato: false,
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixins.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.jsonData.idPaziente = this.$route.query.idPaziente;
        me.id = this.$route.params.id;
    },
    methods: {
        updateStatoFirma(firmato) {
            console.log(firmato);
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
            me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
        },
    },
};
</script>
