import Configurazione from "../";

const routes = [
    { name: "ModuliList", path: "/moduli", component: Configurazione.ModuliList },
    { name: "ModuliEdit", path: "/moduli/edit/:id", component: Configurazione.ModuliEdit },
    { name: "ParamsList", path: "/configparameter", component: Configurazione.ParamsList },
    { name: "ParamsEdit", path: "/configparameter/edit/:id", component: Configurazione.ParamsEdit },
    // { name: "CodiciIcdIx", path: "/codiciicdix", component: Configurazione.CodiciIcdIxList },
];

export default routes;
