<!---<div>
    <b-row>
      <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblDrug") }}:</b-col>
      <b-col cols="12" md="4">
        <span class="sa-data">{{ items.farmaco }}</span>
      </b-col>
      <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblQuantity") }}:</b-col>
      <b-col cols="12" md="4">
        <template>
          <span class="sa-data">{{ items.quantita }}</span>
        </template>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblFrequency") }}:</b-col>
      <b-col cols="12" md="4">
        <span class="sa-data">{{ items.frequenza }}</span>
      </b-col>

      <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblPosology") }}:</b-col>
      <b-col cols="12" md="4">
        <span class="sa-data">{{ items.posologia }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblStartDate") }}:</b-col>
      <b-col cols="12" md="4">
        <span class="sa-data">{{ formatDate(items.dataInizio) }}</span>
      </b-col>
      <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblEndDate") }}:</b-col>
      <b-col cols="12" md="4">
        <span class="sa-data">{{ formatDate(items.dataFine) }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblStatus") }}:</b-col>
      <b-col cols="12" md="">
        <span class="sa-data">{{ items.stato }}</span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" md="2" class="sa-text-right">{{ this.$i18n.t("therapy.lblNote") }}:</b-col>
      <b-col cols="12" md="10">
        <span class="sa-data">{{ items.note }}</span>
      </b-col>
    </b-row>
    <div class="sa-tab-scroll">
      <div style="display: flex; height: 100%;flex-direction: column;">
        <div style="max-height: 10rem"></div>
        <div style="height: 100%">
          <div class="b-table-sticky-header">
            <b-table sticky-header ref="table" responsive stacked="lg" striped hover :items="somministrazioneItems" :fields="fields" sort-icon-left head-variant="light" class="sa-b-table">
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div> --->
<template>
    <div>
        <div></div>
        <div>
            <div class="b-table-sticky-header" style="padding-top: 10px;">
                <b-table sticky-header ref="table" responsive stacked="xl" striped hover :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template v-slot:cell(farmaco)="{ elements }">
                        <router-link class="sa-edit-link" :to="'/paziente/terapia/view/' + elements.id + '?idPaziente=' + idPaziente">{{ items.farmaco }}</router-link>
                    </template>
                    <template #cell(actions)="row">
                        <b-button :disabled="row.item.stato !== 'ATTIVA'" v-b-modal.modalSomministrazione size="sm" variant="btn btn-purple waves-effect waves-light width-sm no-text" @click="onOpenModal(row.item, row.index, $event.target)">
                            <b-icon icon="pencil"></b-icon>
                        </b-button>
                    </template>
                </b-table>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import moment from "moment";
export default {
    components: {},
    props: {
        utente: {
            type: Object,
            default: function() {
                return null;
            },
        },
        id: {
            Type: String,
            default: function() {
                return "-1";
            },
        },
    },
    data() {
        return {
            pathResource: "/pazientiterapia",
            linkSomministrazione: "/pazienteterapiesomministrazioni",
            items: [],
            currentPage: 1,
            perPage: 10,
            somministrazioneItems: [],
            elements: [],
            showModalLoading: false,
            fields: [
                {
                    label: this.$i18n.t("therapy.lblDrug"),
                    key: "farmaco",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("therapy.lblQuantity"),
                    key: "quantita",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("therapy.lblFrequency"),
                    key: "descrizione",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("therapy.lblPosology"),
                    key: "posologia",
                    thStyle: "width: 5rem",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("therapy.lblStatus"),
                    key: "stato",
                    sortable: true,
                    thStyle: "width: 5rem",
                    //tdClass: "text-right",
                },
                {
                    label: this.$i18n.t("therapy.lblNote"),
                    key: "note",
                    sortable: true,
                },
                {
                    label: this.$i18n.t("therapy.lblStartDate"),
                    key: "dataInizio",
                    sortable: true,
                    thStyle: "width: 7rem",
                    formatter: (value) => {
                        return moment(value).format("DD-MM-YYYY");
                    },
                },
                {
                    label: this.$i18n.t("therapy.lblAdministration"),
                    key: "actions",
                    sortable: false,
                    thStyle: "width: 2rem",
                },
            ],
            data: {
                dataInizio: null,
                dataFine: null,
                farmaco: null,
                quantita: null,
                frequenza: null,
                posologia: null,
                stato: null,
                note: null,
            },
        };
    },
    watch: {
        id: {
            immediate: true,
            handler() {
                if (this.id !== "-1") {
                    this.loadData();
                    this.loadSomministrazione();
                }
            },
        },
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient: {
            get: function() {
                if (this.utente.gruppo) {
                    if (this.utente.gruppo.menuGruppo) {
                        if (this.utente.gruppo.menuGruppo.length == 1) {
                            if (this.utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                                return "/paziente/view/" + this.idPaziente;
                            } else if (this.utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                                return "view/" + this.idPaziente;
                            } else {
                                return "homepaziente";
                            }
                        } else {
                            return "";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            },
        },
    },
    mounted: function() {
        let me = this;
        me.linkedit = "/paziente/terapia/edit/" + me.id + "?idPaziente=" + me.idPaziente;
        me.linkback = "/paziente/terapia?idPaziente=" + me.idPaziente;
        console.log(me.id);
    },
    methods: {
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
            me.isBusy = true;
            axios
                .get(link + me.id)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        if (element.stato === "ATTIVO") {
                            me.items.push(element);
                            console.log(element);
                        }
                    });
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },

        loadSomministrazione() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.linkSomministrazione + "?idTerapia=" + me.id;
            me.isBusy = true;
            me.data.idPaziente = me.idPaziente;
            axios
                .get(link, { params: me.data })
                .then((response) => {
                    me.somministrazioneItems = [];
                    me.somministrazioneItems = response.data.data;

                    //me.$refs["SaPageList"].close();
                })
                .catch(() => {
                    me.showModalLoading = false;
                });
        },

        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY");
            } else {
                return "---";
            }
        },
    },
};
</script>
