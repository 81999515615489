<template>
  <sa-page-layout :btnBackVisible="true" :btnEditVisible="true" :data="jsonData" :pathResource="pathResource" :linkedit="linkedit" :linkback="linkback" :showModalLoading="showModalLoading" class="sa-no-space">
    <template slot="table-body">
      <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
        <b-row>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Nome:</label>
            <span class="sa-data">{{ jsonData.nome }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Cognome:</label>
            <span class="sa-data">{{ jsonData.cognome }}</span>
          </b-col>
          <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Email:</label>
            <span class="sa-data">{{ jsonData.email }}</span>
          </b-col>
        </b-row>
      </b-card>
    </template>
  </sa-page-layout>
</template>

<script>
import axios from "axios";
import SaPageLayout from "../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  data() {
    return {
      pathResource: "/emailnotificationreceivers",
      linkback: "/emailnotificationutenti",
      linkedit: null,
      showModalLoading: false,
      jsonData: {},
    };
  },
  mounted() {
    let me = this;
    me.id = this.$route.params.id;
    me.linkedit = "/emailnotificationutenti/edit/" + me.id;
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      let link = process.env.VUE_APP_PATH_API + me.pathResource + "/";
      me.showModalLoading = true;
      axios
        .get(link + me.id)
        .then((response) => {
          me.jsonData = response.data.data;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
  },
};
</script>

<style></style>
