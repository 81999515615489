import Adi from "../";

const routes = [
    { name: "ElencoPazienti", path: "/adipazienti/all", component: Adi.AdiPazientiList, meta: { title: "Elenco Pazienti" } },
    { name: "ElencoPazienti", path: "/adipazienti/archivio", component: Adi.PazientiListArchivio, meta: { title: "Elenco Pazienti" } },
    { name: "ElencoPazienti", path: "/adipazienti/richieste", component: Adi.PazientiListRichieste, meta: { title: "Elenco Pazienti" } },
    // { name: "ElencoPazienti", path: "/adipazienti/pua", component: Adi.PazientiListRichiestePua, meta: { title: "Elenco Pazienti" } },
    { name: "AdiPazienteSchedaEdit", path: "/adipazienti/richieste/scheda/edit/:id", component: Adi.AdiPazienteSchedaEdit, meta: { title: "Scheda Paziente" } },
    { name: "DimissioniProtettePazientiList", path: "/adi/dimissioniprotette", component: Adi.DimissioniProtettePazientiList, meta: { title: "Ricerca Del Paziente" } },
    { name: "DimissioniProtetteEdit", path: "/adi/dimissioniprotette/edit/:id", component: Adi.DimissioniProtetteEdit, meta: { title: "Dimissioni Protette" } },
    { name: "DimissioniProtetteSezioneClinicaEdit", path: "/adi/sezioneclinica/edit/:id", component: Adi.DimissioniProtetteSezioneClinicaEdit, meta: { title: "Sezione Clinica" } },
    { name: "AdiPaiPrestazioniResidenzialiSemiResidenzialiList", path: "/adipazienti/richieste/adipai/prestazioniresidenziali", component: Adi.AdiPaiPrestazioniResidenzialiSemiResidenzialiList, meta: { title: "Pai Prestazioni Residenziali Semi-Residenziali" } },
    { name: "AdiPaiPrestazioniResidenzialiSemiResidenzialiEdit", path: "/adipazienti/richieste/adipai/prestazioniresidenziali/edit/:id", component: Adi.AdiPaiPrestazioniResidenzialiSemiResidenzialiEdit, meta: { title: "Pai Prestazioni Residenziali Semi-Residenziali" } },
    { name: "AdiPaiCureDomiciliariList", path: "/adipazienti/richieste/adipai/curedomiciliari", component: Adi.AdiPaiCureDomiciliariList, meta: { title: "Pai Cure Domiciliari" } },
    { name: "AdiPaiCureDomiciliariEdit", path: "/adipazienti/richieste/adipai/curedomiciliari/edit/:id", component: Adi.AdiPaiCureDomiciliariEdit, meta: { title: "Pai Cure Domiciliari" } },
    
    { name: "AdiPuaList", path: "/adi/configurazionepua", component: Adi.AdiPuaList, meta: { title: "Lista Pua" } },
    { name: "AdiPuaView", path: "/adi/configurazionepua/view/:id", component: Adi.AdiPuaView, meta: { title: "Pua" } },
    { name: "AdiPuaEdit", path: "/adi/configurazionepua/edit/:id", component: Adi.AdiPuaEdit, meta: { title: "Pua" } },
    { name: "AdiHomePuaList", path: "/homepua", component: Adi.AdiHomePuaList, meta: { title: "Home Pua" } },
    // { name: "AccessoMmgList", path: "/adimmg", component: Adi.AccessoMmgList, meta: { title: "Accesso MMG List" } },
];

export default routes;
