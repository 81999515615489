<template>
    <eventi-edit-component :jsonData="jsonData" :utente="utente" :pathResource="pathResource" :titoloEvento="titoloEvento" @update="onUpdateJsonData">
        <template slot="tab-content-event">
            <ecocarotidi-edit-component :jsonData="jsonData" @update="onUpdateJsonData"></ecocarotidi-edit-component>
        </template>
    </eventi-edit-component>
</template>
<script>
import EventiEditComponent from "../../../base/components/EventiEditComponent.vue";
import EventiMixin from "../../../utility/mixins/EventiMixins";
import UtilityMixins from "../../../../../utility/UtilityMixin";
import EcocarotidiEditComponent from "./EcocarotidiEditComponent.vue";
export default {
    mixins: [UtilityMixins],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { EcocarotidiEditComponent, EventiEditComponent },
    data() {
        return {
            linkback: null,
            titoloEvento: this.getLabelTraduora("patient.cardiology.lblEchocarotids"),
            pathResource: "/cardioecocarotidi",
            pathRestValidation: "/validation",
            id: "-1",
            isFirmato: false,
            childKey: 0,
            tabIndex: 0,
            showModalLoading: false,
            jsonData: {
                dataEvento: null,
                pas: 0,
                cbmmax: 0,
                cicoldx: "",
                bsa: 0,
                bmi: 0,
                maximtnearcedx: 0,
                meanimtfarbsx: 0,
                meanimtfarbdx: 0,
                cescoresn: 0,
                ciscoredx: 0,
                ciscoresn: 0,
                bscoredx: 0,
                bscoresx: 0,
                ccscoredx: 0,
                maximtnearcidx: 0,
                meanimtfarcisx: 0,
                meanimtfarcidx: 0,
                cicolsn: "",
                cecoldx: "",
                cecolsn: "",
                bcoldx: "",
                bcolsn: "",
                cccoldx: "",
                meanimtfarcesx: 0,
                meanimtfarcedx: 0,
                maximtfarccsx: 0,
                maximtfarccdx: 0,
                maximtfarbsx: 0,
                maximtfarbdx: 0,
                maximtfarcisx: 0,
                maximtfarcidx: 0,
                maximtfarcesx: 0,
                maximtfarcedx: 0,
                meanimtnearccsx: 0,
                meanimtnearccdx: 0,
                meanimtnearbsx: 0,
                meanimtnearbdx: 0,
                meanimtnearcisx: 0,
                meanimtnearcidx: 0,
                meanimtnearcesx: 0,
                meanimtnearcedx: 0,
                maximtnearcesx: 0,
                maximtnearcisx: 0,
                maximtnearbdx: 0,
                maximtnearbsx: 0,
                maximtnearccdx: 0,
                maximtnearccsx: 0,
                peso: 0,
                cccolsn: "",
                altezza: 0,
                pad: 0,
                fc: 0,
                imtmax: 0,
                meanmax: 0,
                cbmmean: 0,
                meanimtfarccsx: 0,
                meanimtfarccdx: 0,
                ccscoresn: 0,
                cescoredx: 0,
                noteLibere: "",
                familIpertensione: "",
                familCardiopatiaIsch: "",
                familCerebrovascolari: "",
                fumo: "",
                obesita: "",
                diabete: "",
                img4: "",
                img2: "",
                img3: "",
                commento1: "",
                commento2: "",
                commento3: "",
                commento4: "",
                img1: "",
                dislipidemia: "",
                ipertensione: "",
                stenosiCeDx: "",
                stenosiCeSx: "",
                stenosiCiDx: "",
                stenosiCiSx: "",
                stenosiCcSx: "",
                aterosclerosiLieve: "",
                stenosiCcDx: "",
                aterosclerosiModerata: "",
                esameNellaNorma: "",
                videotape: "",
                commento: "",
                protocollo: "",
                codicePrescrizione: "",
                dataPrescrizione: null,
                patientid: "",
                studyuid: "",
                vosOr: 0,
                vosCf: 0,
                checkFi: "",
                filmati: "",
                rangeStenosiCcSn: "",
                rangeStenosiCcDx: "",
                rangeStenosiBSn: "",
                rangeStenosiBDx: "",
                rangeStenosiCiSn: "",
                rangeStenosiCiDx: "",
                rangeStenosiCeSn: "",
                rangeStenosiCeDx: "",
                ecogenCcSn: "",
                ecogenCcDx: "",
                ecogenBSn: "",
                ecogenBDx: "",
                ecogenCiSn: "",
                ecogenCiDx: "",
                ecogenCeSn: "",
                ecogenCeDx: "",
                stentCcSn: "",
                stentCcDx: "",
                stentBSn: "",
                stentBDx: "",
                stentCiSn: "",
                stentCiDx: "",
                stentCeSn: "",
                stentCeDx: "",
                endoartCcSn: "",
                endoartCcDx: "",
                endoartBSn: "",
                endoartBDx: "",
                endoartCiSn: "",
                endoartCiDx: "",
                endoartCeSn: "",
                endoartCeDx: "",
                aterosclerosiSevera: "",
                aterosclerosiNonEmoSign: "",
                psvIca: 0,
                psvCca: 0,
                psvRatio: 0,
            },
        };
    },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        this.jsonData.dataEvento = new Date().getTime();
    },
    methods: {
        onFirmaUpdate() {
            let me = this;
            me.loadData();
        },
        onUpdateFile() {
            let me = this;
            me.childKey++;
        },
        onUpdateJsonData(data) {
            let me = this;
            me.jsonData = data;
        },
    },
};
</script>
<style scoped>
.ft-space {
    margin-top: 10px;
}
</style>
