<template>
    <div class="sa-tab-scroll">
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="3" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblSG") }}
                        <eventi-storico-misure-component misura="sg" misuraLabel="Sg" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input v-model="data.sg" tipe="number" step="0.01" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblPh") }}
                        <eventi-storico-misure-component misura="ph" misuraLabel="Ph" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input v-model="data.ph" tipe="number" step="0.01" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblUBJValue") }}
                        <eventi-storico-misure-component misura="ph" misuraLabel="Ph" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>

                    <b-form-input v-model="data.valoreUbg" tipe="number" step="0.01" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblUBJ") }}</label>

                    <b-form-select v-model="data.ubg" no-resize :options="opzioniUrine" :disabled="!edit"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblGLU") }}</label>
                    <b-form-select v-model="data.glu" no-resize :options="opzioniUrine" :disabled="!edit"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblNIT") }}</label>
                    <b-form-select v-model="data.nit" no-resize :options="opzioniUrine" :disabled="!edit"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblLeu") }}</label>
                    <b-form-select v-model="data.leu" no-resize :options="opzioniUrine" :disabled="!edit"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblLeuValue") }}
                        <eventi-storico-misure-component misura="valore_leu" misuraLabel="Leu" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input v-model="data.valoreLeu" tipe="number" step="0.01" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblPRO") }}</label>
                    <b-form-select v-model="data.pro" no-resize :options="opzioniUrine" :disabled="!edit"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblPROValue") }}
                        <eventi-storico-misure-component misura="valore_pro" misuraLabel="Pro" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input v-model="data.valorePro" tipe="number" step="0.01" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblKET") }}</label>
                    <b-form-select v-model="data.ket" no-resize :options="opzioniUrine" :disabled="!edit"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">
                        {{ getLabelTraduora("patient.afa.lblKETValue") }}
                        <eventi-storico-misure-component misura="valore_ket" misuraLabel="Ket" unitaMisura="" :idPaziente="idPaziente" :tipoEvento="tipoEvento" :gruppo="gruppo"></eventi-storico-misure-component>
                    </label>
                    <b-form-input v-model="data.valoreKet" tipe="number" step="0.01" :disabled="!edit"></b-form-input>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblERY") }}</label>
                    <b-form-select v-model="data.ery" no-resize :options="opzioniUrine" :disabled="!edit"></b-form-select>
                </b-col>
                <b-col xs="12" sm="12" md="3" lg="3" xl="3">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblBIL") }}</label>
                    <b-form-select v-model="data.bil" no-resize :options="opzioniUrine" :disabled="!edit"></b-form-select>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("patient.afa.lblReport") }}</label>
                    <b-form-textarea v-model="data.refertoUrine" no-resize :disabled="!edit"></b-form-textarea>
                </b-col>
                <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">{{ getLabelTraduora("therapy.lblStatus") }}</label>
                    <span class="sa-data">
                        {{ data.firma }}
                    </span>
                </b-col>
            </b-row>
        </b-card>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import EventiStoricoMisureComponent from "../../../base/components/EventiStoricoMisureComponent.vue";
export default {
    mixins: [UtilityMixin],
    components: { EventiStoricoMisureComponent },
    props: ["data", "edit"],
    data() {
        return {
            tipoEvento: "IDONEITA",
            gruppo: "MEDICINADELLOSPORT",
            opzioniUrine: [
                { text: this.getLabelTraduora("patient.afa.lblNegative"), value: "patient.afa.lblNegative" },
                { text: this.getLabelTraduora("patient.afa.lblPositive"), value: "patient.afa.lblPositive" },
            ],
        };
    },
    computed: {
        idPaziente() {
            let me = this;
            return me.data.idPaziente;
        },
    },
    methods: {},
};
</script>
