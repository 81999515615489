<template>
  <sa-page-layout :btnRefreshVisible="true" :btnNewVisible="true" :linkedit="linkedit" :showModalLoading="showModalLoading" :pathResource="pathResource" @refresh="onRefresh" :items="items">
    <!-- <sa-page-layout :btnRefreshVisible="true" :btnNewVisible="true" :linkedit="linkedit" :showModalLoading="showModalLoading" :pathResource="pathResource" @refresh="onRefresh" :btnDownalodExcelVisible="true" :nomeFileExcel="nomeFileExcel" :items="items" :pathResourceDownloadExcel="pathResourceDownloadExcel"> -->
    <template slot="table-filter">
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col cols="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Nome</label>
            <b-form-input maxlength="16" v-model="filtro.nome" type="search"></b-form-input>
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Cognome</label>
            <b-form-input maxlength="16" v-model="filtro.cognome" type="search"></b-form-input>
          </b-col>
          <b-col cols="12" sm="12" md="4" lg="4" xl="4">
            <label class="sa-label-data">Email</label>
            <b-form-input maxlength="16" v-model="filtro.email" type="search"></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="sa-padding-right text-right">
            <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
            <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
          </b-col>
        </b-row>
      </b-form>
    </template>
    <template slot="table-body">
      <div class="b-table-sticky-header">
        <b-table sticky-header ref="table" :filter="filtro" stacked="xl" striped hover :items="items" :fields="fields" :current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
          <template #cell(index)="row">
            {{ row.index + 1 }}
          </template>
          <template v-slot:cell(nominativo)="{ item }">
            <router-link class="sa-edit-link" :to="'/malattieinfettive/emaildestinatari/edit/' + item.id">{{ item.cognome }} {{ item.nome }}</router-link>
          </template>
          <template v-slot:cell(actions)="row">
            <b-button size="sm" class="no-text" variant="outline-danger" style="margin-right: 3px" @click="onDelete(row.item, row.index, $event.target)"><b-icon icon="trash" variant="outline-danger"></b-icon></b-button>
          </template>
        </b-table>
      </div>
    </template>
  </sa-page-layout>
</template>
<script>
import axios from "axios";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
export default {
  components: { SaPageLayout },
  data() {
    return {
      pathResource: "/malattieinfettivesegnalazioneemaildestinatari",
      linkedit: "/malattieinfettive/emaildestinatari",
      perPage: 100,
      currentPage: 1,
      showModalLoading: false,
      filtro: { nome: "", cognome: "", email: "" },
      items: [],
      fields: [
        {
          label: "",
          key: "index",
          sortable: false,
          thStyle: "width: 2rem",
        },
        {
          label: "Nominativo",
          key: "nominativo",
          thStyle: "width: 30rem",
          sortable: true,
        },
        {
          label: "Email",
          key: "email",
          sortable: true,
        },
        {
          label: "",
          key: "actions",
          thStyle: "width: 10rem",
        },
      ],
    };
  },
  mounted() {
    let me = this;
    let sessionStorage = window.sessionStorage;
    if (sessionStorage["filtro"]) {
      me.filtro = JSON.parse(sessionStorage["filtro"]);
    }
    me.loadData();
  },
  methods: {
    loadData() {
      let me = this;
      me.showModalLoading = true;
      me.filtro.page = me.currentPage;
      me.filtro.forPage = me.perPage;
      let link = process.env.VUE_APP_PATH_API + me.pathResource;
      axios
        .get(link, { params: me.filtro })
        .then((response) => {
          me.items = [];
          me.rows = response.data.data.recordsNumber;
          me.items = response.data.data.list;
          me.showModalLoading = false;
        })
        .catch(() => {
          me.showModalLoading = false;
        });
    },
    onRefresh() {
      let me = this;
      me.loadData();
    },
    onReset() {
      let me = this;
      me.filtro = { nome: "", cognome: "", email: "" };
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onSubmit() {
      let me = this;
      let sessionStorage = window.sessionStorage;
      sessionStorage["filtro"] = JSON.stringify(me.filtro);
      me.loadData();
    },
    onDelete(row) {
      let me = this;

      this.$bvModal
        .msgBoxConfirm(["Sicuro di voler eliminare Destinatario" + row.nome + " " + row.cognome + " " + row.email + " ?"])
        .then((item) => {
          if (item) {
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            axios.delete(link + "/" + row.id).then(() => {
              me.$bvToast.toast([this.$i18n.t("agenda.lblConfirmDelete")], {
                title: "Agenda",
                variant: "info",
                solid: true,
                noCloseButton: true,
                autoHideDelay: 2000,
              });
              me.loadData();
            });
          }
        })
        .catch((err) => {
          this.$bvModal.msgBoxOk(err.response.data.messaggio, {
            title: [this.$i18n.t("agenda.lblEditTitle")],
            size: "sm",
            okVariant: "outline-danger",
            headerClass: "sa-msg-header-danger",
            footerClass: "p-2 border-top-0",
            centered: true,
          });
        });
    },
  },
};
</script>
