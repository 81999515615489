<template>
    <sa-page-layout :btnBackVisible="true" :linkback="linkback" :pathResource="pathResource" :showModalLoading="showModalLoading">
        <template slot="table-filter">
            <b-form @submit.prevent="onSubmit">
                <b-row>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Codice Fiscale</label>
                        <b-form-input v-model="filtro.codiceFiscale"></b-form-input>
                    </b-col>
                    <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
                        <label class="sa-label-data">Stato</label>
                        <b-form-select v-model="filtro.stato" :options="statiOptions" :value="''" value-field="value" text-field="text"></b-form-select>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="sa-padding-right text-right">
                        <b-button type="submit" variant="info">{{ this.$i18n.t("global.lblFind") }}</b-button>
                        <b-button type="reset" v-on:click="onReset" variant="danger">{{ this.$i18n.t("global.lblResetFilter") }}</b-button>
                    </b-col>
                </b-row>
            </b-form>
        </template>
        <template slot="table-header">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
        <template slot="table-body">
            <div class="b-table-sticky-header">
                <b-table sticky-header ref="table" id="tbl" :filter="filtro" stacked="xl" striped hover :items="items" :fields="fields" current-page="1" :per-page="perPage" sort-icon-left head-variant="light" class="sa-b-table">
                    <template #cell(index)="item">
                        {{ item.index + 1 }}
                    </template>
                    <template v-slot:cell(nominativo)="{ item }"> {{ item.nome }} {{ item.cognome }} </template>
                    <template v-slot:cell(statoAttuale)="{ item }"> {{ item.stato }} {{ item.statoDescrizione }} </template>
                </b-table>
            </div>
        </template>
        <template slot="table-footer">
            <b-row style="margin-left: 0.5rem; margin-right: 0.5rem">
                <b-col cols="6">
                    <p>{{ this.$i18n.t("global.lblTotalRecords") }}: {{ rows }}</p>
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" align="right" size="sm"></b-pagination>
                </b-col>
            </b-row>
        </template>
    </sa-page-layout>
</template>
<script>
import axios from "axios";
import moment from "moment";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
export default {
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    mixins: [UtilityMixin],
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return UtilityMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    components: { SaPageLayout }, //RencamSchedeMorteCaricamentoCsvViewComponent
    data() {
        return {
            pathResource: "/rencamschedemortefileimportdetails",
            id: "-1",
            linkback: "/rencamcaricamentocsv",
            showModalLoading: false,
            idFileImport: "-1",
            currentPage: 1,
            perPage: 100,
            rows: 0,
            filtro: { codiceFiscale: "", idFileImport: "", stato: "" },
            items: [],
            fields: [
                {
                    label: "",
                    key: "index",
                    sortable: true,
                    thStyle: "width: 3rem",
                    tdClass: "text-center",
                },
                {
                    label: "Codice Fiscale",
                    key: "codiceFiscale",
                    sortable: true,
                },
                {
                    label: "Cognome e Nome",
                    key: "nominativo",
                    sortable: true,
                    thStyle: "width: 12rem",
                },
                {
                    label: "Data Nascita",
                    key: "dataNascita",
                    formatter: function (value) {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY");
                        } else {
                            return "----";
                        }
                    },
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Data Decesso",
                    key: "dataDecesso",
                    formatter: function (value) {
                        if (value) {
                            return moment(new Date(value)).format("DD-MM-YYYY");
                        } else {
                            return "----";
                        }
                    },
                    sortable: true,
                    thStyle: "width: 5rem",
                },
                {
                    label: "Stato",
                    key: "statoAttuale",
                    sortable: false,
                    thStyle: "width: 15rem",
                },
                // {
                //     label: "Cognome e Nome",
                //     key: "statoDescrizione",
                //     sortable: true,
                //     thStyle: "width: 10rem",
                // },
            ],
            statiOptions: [
                { value: "", text: "-Seleziona Stato-" },
                { value: "ERROR", text: "ERROR" },
                { value: "CARICATA", text: "CARICATA" },
                { value: "NON CARICATA", text: "NON CARICATA" },
            ],
        };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    watch: {
        currentPage() {
            let me = this;
            me.loadData();
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.filtro.idFileImport = me.id;
            me.filtro.page = me.currentPage;
            me.filtro.forPage = me.perPage;
            let link = process.env.VUE_APP_PATH_API + me.pathResource;
            me.showModalLoading = true;
            axios
                .get(link, { params: me.filtro })
                .then((response) => {
                    me.items = [];
                    me.rows = response.data.data.recordsNumber;
                    me.items = response.data.data.list;
                    me.showModalLoading = false;
                })
                .catch({});
        },
        onReset() {
            let me = this;
            me.filtro = { page: 1, forPage: 100, codiceFiscale: "", idFileImport: "" };
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
        onSubmit() {
            let me = this;
            let sessionStorage = window.sessionStorage;
            sessionStorage["filtro"] = JSON.stringify(me.filtro);
            me.loadData();
        },
    },
};
</script>
