<template>
    <sa-page-layout class="sa-no-space">
        <template slot="toolbar-title">
            <paziente-anagrafica :linkPatient="linkPatient" :idPaziente="idPaziente"></paziente-anagrafica>
        </template>
        <template slot="table-header">
            <h3 class="sa-event-title">{{ titoloEvento }}</h3>
        </template>
        <template slot="table-body">
            <div class="sa-tab-scroll">
                <b-row>
                    <b-col v-for="(chart, index) in charts" :key="chart.id" :id="chart.id + '_Prova'" cols="12" xs="12" sm="12" md="6" lg="6" xl="6" class="sa-col-chart">
                        <b-card class="sa-card" style="height: 100%">
                            <span>
                                <a class="sa-edit-a" @click="onClickIntervallo(chart, index)">
                                    {{ formatDate(chart.dataDal) }} - {{ formatDate(chart.dataAl) }}
                                    <b-icon icon="pencil"></b-icon>
                                </a>
                            </span>
                            <!-- <chart-component :id="chart.id + 'Sisto'" :chartData="setChartData(chart)"></chart-component> -->
                            <chart-component :chartData="chart.chartData"></chart-component>
                        </b-card>
                    </b-col>
                </b-row>
                <!-- <b-modal ref="mdlSelectionDateShow" id="mdlSelectionDateShow" hide-footer hide-header no-close-on-esc no-close-on-backdrop> -->
                <b-modal ref="mdlSelectionDateShow" id="mdlSelectionDateShow" @show="onIntervalloHidden" @hidden="onIntervalloHidden" @ok="onIntervalloOk" no-close-on-esc no-close-on-backdrop>
                    <form ref="form" @submit.stop.prevent="onDateSubmit">
                        <b-form-group label="Dal" label-for="name-input" invalid-feedback="Name is required">
                            <date-picker format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="intervallo.dataDal" type="datetime"></date-picker>
                        </b-form-group>
                        <b-form-group label="Al" label-for="name-input" invalid-feedback="Name is required">
                            <date-picker :disabled-date="notBefore" format="DD-MM-YYYY HH:mm" value-type="timestamp" v-model="intervallo.dataAl" type="datetime"></date-picker>
                        </b-form-group>
                    </form>
                </b-modal>
            </div>
        </template>
    </sa-page-layout>
</template>

<script>
import axios from "axios";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import SaPageLayout from "../../../../template/sa/components/layout/contents/SaPageLayout.vue";
import PazienteAnagrafica from "../../components/PazienteAnagrafica.vue";
import UtilityMixin from "../../../utility/UtilityMixin";
import EventiMixin from "../../eventi/utility/mixins/EventiMixins";
import ChartComponent from "../../../utility/components/ChartComponent.vue";
export default {
    mixins: [UtilityMixin, EventiMixin],
    props: {
        utente: {
            type: Object,
            default: function () {
                return null;
            },
        },
    },
    components: { SaPageLayout, PazienteAnagrafica, ChartComponent, DatePicker },
    computed: {
        idPaziente() {
            return this.$route.query.idPaziente;
        },
        linkPatient() {
            return EventiMixin.getLinkPatient(this.utente, this.idPaziente);
        },
    },
    mounted() {
        let me = this;
        me.loadData();
    },
    data() {
        return {
            titoloEvento: "",
            pathResourcesMisurazioniTipi: "/pazientemisuretipi",
            pathResourcesMisurazioni: "/pazientemisure",
            charts: [],
            chartData: { titolo: null, xAxis: [], series: [], unitaMisura: "" },
            intervallo: { dataDal: null, dataAl: null },
            selectedChart: null,
            selectedIndex: null,
        };
    },
    methods: {
        notBefore: function (date) {
            let me = this;
            let returnValue = false;
            if (me.intervallo.dataDal) {
                returnValue = date <= new Date(me.intervallo.dataDal);
            }
            return returnValue; // || date > new Date(2019, 10, 6);
        },
        loadData() {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcesMisurazioniTipi;
            me.showmolalloading = true;
            axios
                .get(link)
                .then((response) => {
                    response.data.data.forEach((element) => {
                        if (element.tipoGrafico) {
                            element.dataDal = new Date(new Date().getTime() - 3 * 24 * 60 * 60 * 1000); //1641029623000;
                            element.dataAl = new Date(); //1653989623000;
                            element.data = [];
                            me.loadDataMisurazioni(element);
                        }
                    });
                    me.showmolalloading = false;
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        loadDataMisurazioni(chart, index = null) {
            let me = this;
            let link = process.env.VUE_APP_PATH_API + me.pathResourcesMisurazioni;
            axios
                .get(link, { params: { idPaziente: me.idPaziente, dal: me.formatDateTimeCustom(chart.dataDal, "YYYY-MM-DD HH:mm:ss"), al: me.formatDateTimeCustom(chart.dataAl, "YYYY-MM-DD HH:mm:ss"), misura: chart.misura } })
                .then((response) => {
                    chart.data = response.data.data;
                    // console.log(response.data.data);
                    if (index != null) {
                        me.charts[index] = me.setChartData(chart);
                    } else {
                        me.charts.push(me.setChartData(chart));
                    }
                })
                .catch(() => {
                    me.showmolalloading = false;
                });
        },
        setChartData(chart) {
            let me = this;
            // let data1 = chart.data1;
            let dataSeries = [];
            // let series = [];
            let data = chart.data;

            let chartData = { toolbox: { show: false }, titolo: null, xAxis: [], series: [], unitaMisura: "" };
            let values = [];
            let serieObject = [];

            chartData.toolbox = {
                show: true,
                feature: {
                    dataZoom: {
                        yAxisIndex: "none",
                    },
                    dataView: {
                        readOnly: true,
                        optionToContent: function (opt) {
                            var axisData = opt.xAxis[0].data;
                            // console.log(axisData);
                            var series = opt.series;
                            var table = "<table class='sa-misure-data-view' style='width:100%;text-align:center'><tbody>";
                            table += "<tr><td>Data</td><td>Valore</td><td>Device</td><td>Valore</td><td>Device</td></tr>";
                            let l = axisData.length;
                            for (var i = l - 1; i >= 0; i--) {
                                // console.log("Riga: " + i);
                                let eventcls = i % 2 ? "sa-misure-data-view-event-row" : "";
                                table += "<tr class=" + eventcls + ">" + "<td>" + axisData[i] + "</td>";
                                for (var x = 0, y = series.length; x < y; x++) {
                                    let valore = series[x].data[i] ? series[x].data[i] : "-";
                                    let device = series[x].serieObject[i] ? series[x].serieObject[i].idDevice : "-";
                                    table += "<td>" + valore + "</td>";
                                    table += "<td>" + device + "</td>";
                                }
                                table += "</tr>";
                            }
                            table += "</tbody></table>";
                            return table;
                        },
                    },
                    magicType: { type: ["line", "bar"] },
                    restore: { show: false },
                    saveAsImage: {},
                },
            };

            chartData.xAxis = [];
            chartData.titolo = me.getLabelTraduora(chart.misura);
            chartData.unitaMisura = "";
            data.forEach((element) => {
                // console.log(moment(new Date(element.istant)).format("DD-MM-YYYY HH:mm") + " " + element.value + " " + element.idDevice);
                chartData.xAxis.push(moment(new Date(element.istant)).format("DD-MM-YYYY HH:mm"));
                values.push([new Date(element.istant), Number(element.value)]);
                serieObject.push(element);
            });
            // console.log(values);
            chart.data.forEach((element) => {
                let key = "NonRilevato";
                if (element.idDevice) {
                    key = element.idDevice;
                } else {
                    element.idDevice = "NonRilevato";
                }
                if (!dataSeries[key]) {
                    dataSeries[key] = [];
                }
                // chartData.xAxis.push(moment(new Date(element.istant)).format("DD-MM-YYYY HH:mm"));
                dataSeries[key].push(element);
            });
            // console.log(dataSeries);
            // console.log(dataSeries.length);
            // console.log(dataSeries.keys());
            for (var key in dataSeries) {
                // console.log(key);
                let serieValues = [];
                let seriesObject = [];

                dataSeries[key].forEach((element) => {
                    // console.log(element);
                    // chartData.xAxis.push(moment(new Date(element.istant)).format("DD-MM-YYYY HH:mm"));
                    serieValues.push([new Date(element.istant), Number(element.value)]);
                    // seriesObject.push(element);
                });
                let serie = {
                    serieObject: seriesObject,
                    name: key,
                    type: "line",
                    data: serieValues,
                    markPoint: {
                        data: [
                            { type: "max", name: "Max" },
                            { type: "min", name: "Min" },
                        ],
                    },
                    markLine: {
                        data: [{ type: "average", name: "Avg" }],
                    },
                };
                chartData.series.push(serie);
            }
            // console.log("************************************************************************************");

            // chartData.series.push({
            //     serieObject: serieObject,
            //     name: me.getLabelTraduora(chart.misura),
            //     type: "line",
            //     data: values,
            //     markPoint: {
            //         data: [
            //             { type: "max", name: "Max" },
            //             { type: "min", name: "Min" },
            //         ],
            //     },
            //     markLine: {
            //         data: [{ type: "average", name: "Avg" }],
            //     },
            // });

            chart.chartData = chartData;
            return chart;
        },
        onClickIntervallo(chart, index) {
            let me = this;
            me.selectedChart = chart;
            me.selectedIndex = index;
            me.$refs["mdlSelectionDateShow"].show();
        },
        onDateSubmit() {
            console.log("Prova Uno");
        },
        onIntervalloShow() {
            console.log("show");
        },
        onIntervalloHidden() {
            // console.log("hidden");
        },
        onIntervalloOk() {
            let me = this;
            if (me.intervallo.dataDal != null && me.intervallo.dataAl != null) {
                me.selectedChart.dataDal = me.intervallo.dataDal;
                me.selectedChart.dataAl = me.intervallo.dataAl;
                me.loadDataMisurazioni(me.selectedChart, me.selectedIndex);
            }
        },
    },
};
</script>

<style>
.sa-edit-a {
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
}
.sa-edit-a:hover {
    color: var(--a-color) !important;
}

.sa-misure-data-view {
    width: 100%;
    text-align: center;
}
.sa-misure-data-view td {
    font-size: 0.75rem;
}

.sa-misure-data-view-event-row {
    background-color: #eee;
}
</style>
