import Gastro from "../";

const routes = [
  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anamnesipatologica", component: Gastro.AnamensiPatologica.GastroAnamnesiPatologicaList, meta: { title: "menu.paziente.pathologicalHistory"}},
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anamnesipatologica/view/:id", component: Gastro.AnamensiPatologica.GastroAnamnesiPatologicaView, meta: { title: "menu.paziente.pathologicalHistory" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anamnesipatologica/edit/:id", component: Gastro.AnamensiPatologica.GastroAnamnesiPatologicaEdit, meta: { title: "menu.paziente.pathologicalHistory" } },

  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anamnesifisiologica", component: Gastro.AnamnesiFisiologica.GastroAnamnesiFisiologicaList, meta: { title: "menu.paziente.physiologicalAnamnesis" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anamnesifisiologica/view/:id", component: Gastro.AnamnesiFisiologica.GastroAnamnesiFisiologicaView, meta: { title: "menu.paziente.physiologicalAnamnesis" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anamnesifisiologica/edit/:id", component: Gastro.AnamnesiFisiologica.GastroAnamnesiFisiologicaEdit, meta: { title: "menu.paziente.physiologicalAnamnesis" } },

  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anamnesifamiliare", component: Gastro.AnamnesiFamiliare.AnamnesiFamiliareList, meta: { title: "patients.events.lblFamilyAnamnesis" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anamnesifamiliare/view/:id", component: Gastro.AnamnesiFamiliare.AnamnesiFamiliareView, meta: { title: "patients.events.lblFamilyAnamnesis" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anamnesifamiliare/edit/:id", component: Gastro.AnamnesiFamiliare.AnamnesiFamiliareEdit, meta: { title: "patients.events.lblFamilyAnamnesis" } },

  { name: "AnamnesiList", path: "/paziente/gastroenterologia/anorettocolonscopia", component: Gastro.AnoRettoColonScopia.GastroAnoRettoColonscopiaList, meta: { title: "menu.paziente.gastroAnusRectumColonoscopy" } },
  { name: "AnamnesiView", path: "/paziente/gastroenterologia/anorettocolonscopia/view/:id", component: Gastro.AnoRettoColonScopia.GastroAnoRettoColonscopiaView, meta: { title: "menu.paziente.gastroAnusRectumColonoscopy" } },
  { name: "AnamnesiEdit", path: "/paziente/gastroenterologia/anorettocolonscopia/edit/:id", component: Gastro.AnoRettoColonScopia.GastroAnoRettoColonscopiaEdit, meta: { title: "menu.paziente.gastroAnusRectumColonoscopy" } },
  
  { name: "EgdsList", path: "/paziente/gastroenterologia/egds", component: Gastro.Egds.EgdsList, meta: { title: "pazienti.egds.lblVisitEgds " } },
  { name: "EgdsView", path: "/paziente/gastroenterologia/egds/view/:id", component: Gastro.Egds.EgdsView, meta: { title: "pazienti.egds.lblVisitEgds " } },
  { name: "EgdsEdit", path: "/paziente/gastroenterologia/egds/edit/:id", component: Gastro.Egds.EgdsEdit, meta: { title: "pazienti.egds.lblVisitEgds" } },

  { name: "ConfigEgdsStrumentoList", path: "/paziente/gastroenterologia/configurazioneegds/strumento", component: Gastro.ConfigSelectEgdsStrumentoList, meta: { title: "patient.gastroenterology.egdc.configurationValueEcgds" } },
  { name: "ConfigEgdsStrumentoEdit", path: "/paziente/gastroenterologia/configurazioneegds/strumento/edit/:id", component: Gastro.ConfigSelectEgdsStrumentoEdit, meta: { title: "patient.gastroenterology.egdc.configurationValueEcgds" } },
  { name: "ConfigEgdsStrumentoView", path: "/paziente/gastroenterologia/configurazioneegds/strumento/view/:id", component: Gastro.ConfigSelectEgdsStrumentoView, meta: { title: "patient.gastroenterology.egdc.configurationValueEcgds" } },

  {
    name: "ConfigEgdsLavaggioDisinfezioneEdit",
    path: "/paziente/gastroenterologia/configurazioneegds/lavaggiodisinfezione/edit/:id",
    component: Gastro.ConfigSelectEgdsLavaggioDisinfezioneEdit,
    meta: { title: "patient.gastroenterology.egdc.configurationEgds" },
  },
  {
    name: "ConfigEgdsLavaggioDisinfezioneView",
    path: "/paziente/gastroenterologia/configurazioneegds/lavaggiodisinfezione/view/:id",
    component: Gastro.ConfigSelectEgdsLavaggioDisinfezioneView,
    meta: { title: "patient.gastroenterology.egdc.configurationEgds" },
  },
  {
    name: "ConfigEgdsLavaggioDisinfezioneList",
    path: "/paziente/gastroenterologia/configurazioneegds/lavaggiodisinfezione",
    component: Gastro.ConfigSelectEgdsLavaggioDisinfezioneList,
    meta: { title: "patient.gastroenterology.egdc.configurationEgds" },
  },

  { name: "ConfigEgdsIndicazioneEdit", path: "/paziente/gastroenterologia/configurazioneegds/indicazione/edit/:id", component: Gastro.ConfigSelectEgdsIndicazioneEdit, meta: { title: "patient.gastroenterology.egdc.configurationIndicationEgds" } },
  { name: "ConfigEgdsIndicazioneView", path: "/paziente/gastroenterologia/configurazioneegds/indicazione/view/:id", component: Gastro.ConfigSelectEgdsIndicazioneView, meta: { title: "patient.gastroenterology.egdc.configurationIndicationEgds" } },
  { name: "ConfigEgdsIndicazioneList", path: "/paziente/gastroenterologia/configurazioneegds/indicazione", component: Gastro.ConfigSelectEgdsIndicazioneList, meta: { title: "patient.gastroenterology.egdc.configurationIndicationEgds " } },
  
  { name: "VisitaGastroenterologicaList", path: "/paziente/gastroenterologia/visitagastroenterologica", component: Gastro.VisitaGastroenterologica.VisitaGastroenterologicaList, meta: { title: "menu.paziente.GastroenterologicalVisit" } },
  { name: "VisitaGastroenterologicaView", path: "/paziente/gastroenterologia/visitagastroenterologica/view/:id", component: Gastro.VisitaGastroenterologica.VisitaGastroenterologicaView, meta: { title: "menu.paziente.GastroenterologicalVisit" } },
  { name: "VisitaGastroenterologicaEdit", path: "/paziente/gastroenterologia/visitagastroenterologica/edit/:id", component: Gastro.VisitaGastroenterologica.VisitaGastroenterologicaEdit, meta: { title: "menu.paziente.GastroenterologicalVisit" } },

  { name: "EcografiaAddominaleList", path: "/paziente/gastroenterologia/ecografiaaddominale", component: Gastro.EcografiaAddominale.EcografiaAddominaleList, meta: { title: "menu.paziente.abdominalUltrasound" } },
  { name: "EcografiaAddominaleView", path: "/paziente/gastroenterologia/ecografiaaddominale/view/:id", component: Gastro.EcografiaAddominale.EcografiaAddominaleView, meta: { title: "menu.paziente.abdominalUltrasound" } },
  { name: "EcografiaAddominaleEdit", path: "/paziente/gastroenterologia/ecografiaaddominale/edit/:id", component: Gastro.EcografiaAddominale.EcografiaAddominaleEdit, meta: { title: "menu.paziente.abdominalUltrasound" } },

  { name: "EcoendoscopiaList", path: "/paziente/gastroenterologia/ecoendoscopia", component: Gastro.EcoEndoscopia.EcoendoscopiaList, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "EcoendoscopiaView", path: "/paziente/gastroenterologia/ecoendoscopia/view/:id", component: Gastro.EcoEndoscopia.EcoendoscopiaView, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "EcoendoscopiaEdit", path: "/paziente/gastroenterologia/ecoendoscopia/edit/:id", component: Gastro.EcoEndoscopia.EcoendoscopiaEdit, meta: { title: "menu.paziente.Endoscopy" } },
  
  { name: "GastroErcpList", path: "/paziente/gastroenterologia/ercp", component: Gastro.Ercp.GastroErcpList, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "GastroErcpView", path: "/paziente/gastroenterologia/ercp/view/:id", component: Gastro.Ercp.GastroErcpView, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "GastroErcpEdit", path: "/paziente/gastroenterologia/ercp/edit/:id", component: Gastro.Ercp.GastroErcpEdit, meta: { title: "menu.paziente.Endoscopy" } },
  
  { name: "GastroInserzioneVideocapuslaList", path: "/paziente/gastroenterologia/inserzionevideocapsula", component: Gastro.InserzioneVideocapusla.GastroInserzioneVideocapuslaList, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "GastroInserzioneVideocapuslaView", path: "/paziente/gastroenterologia/inserzionevideocapsula/view/:id", component: Gastro.InserzioneVideocapusla.GastroInserzioneVideocapuslaView, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "GastroInserzioneVideocapuslaEdit", path: "/paziente/gastroenterologia/inserzionevideocapsula/edit/:id", component: Gastro.InserzioneVideocapusla.GastroInserzioneVideocapuslaEdit, meta: { title: "menu.paziente.Endoscopy" } },
  
  { name: "PolipectomiaEndoscopicaIntestinoCrassoList", path: "/paziente/gastroenterologia/polipectomiaendoscopicaintestinocrasso", component: Gastro.PolipectomiaEndoscopicaIntestinoCrasso.PolipectomiaEndoscopicaIntestinoCrassoList, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "PolipectomiaEndoscopicaIntestinoCrassoView", path: "/paziente/gastroenterologia/polipectomiaendoscopicaintestinocrasso/view/:id", component: Gastro.PolipectomiaEndoscopicaIntestinoCrasso.PolipectomiaEndoscopicaIntestinoCrassoView, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "PolipectomiaEndoscopicaIntestinoCrassoEdit", path: "/paziente/gastroenterologia/polipectomiaendoscopicaintestinocrasso/edit/:id", component: Gastro.PolipectomiaEndoscopicaIntestinoCrasso.PolipectomiaEndoscopicaIntestinoCrassoEdit, meta: { title: "menu.paziente.Endoscopy" } },

  { name: "GastroPosizionamentoBibList", path: "/paziente/gastroenterologia/posizionamentobib", component: Gastro.PosizionamentoBib.GastroPosizionamentoBibList, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "GastroPosizionamentoBibView", path: "/paziente/gastroenterologia/posizionamentobib/view/:id", component: Gastro.PosizionamentoBib.GastroPosizionamentoBibView, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "GastroPosizionamentoBibEdit", path: "/paziente/gastroenterologia/posizionamentobib/edit/:id", component: Gastro.PosizionamentoBib.GastroPosizionamentoBibEdit, meta: { title: "menu.paziente.Endoscopy" } },
  
  { name: "GastroEnteroscopiaList", path: "/paziente/gastroenterologia/enteroscopia", component: Gastro.Enteroscopia.GastroEnteroscopiaList, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "GastroEnteroscopiaView", path: "/paziente/gastroenterologia/enteroscopia/view/:id", component: Gastro.Enteroscopia.GastroEnteroscopiaView, meta: { title: "menu.paziente.Endoscopy" } },
  { name: "GastroEnteroscopiaEdit", path: "/paziente/gastroenterologia/enteroscopia/edit/:id", component: Gastro.Enteroscopia.GastroEnteroscopiaEdit, meta: { title: "menu.paziente.Endoscopy" } },
  
  { name: "GastroPancolonscopiaList", path: "/paziente/gastroenterologia/pancolonscopia", component: Gastro.Pancolonscopia.GastroPancolonscopiaList, meta: { title: "Pancolonscopia" } },
  { name: "GastroPancolonscopiaView", path: "/paziente/gastroenterologia/pancolonscopia/view/:id", component: Gastro.Pancolonscopia.GastroPancolonscopiaView, meta: { title: "Pancolonscopia" } },
  { name: "GastroPancolonscopiaEdit", path: "/paziente/gastroenterologia/pancolonscopia/edit/:id", component: Gastro.Pancolonscopia.GastroPancolonscopiaEdit, meta: { title: "Nuova Pancolonscopia" } },
  
  { name: "VisitaProctologicaList", path: "/paziente/gastroenterologia/visitaproctologica", component: Gastro.VisitaProctologica.GastroVisitaProctologicaList, meta: { title: "Visita Proctologica" } },
  { name: "VisitaProctologicaView", path: "/paziente/gastroenterologia/visitaproctologica/view/:id", component: Gastro.VisitaProctologica.GastroVisitaProctologicaView, meta: { title: "Visita Proctologica" } },
  { name: "VisitaProctologicaEdit", path: "/paziente/gastroenterologia/visitaproctologica/edit/:id", component: Gastro.VisitaProctologica.GastroVisitaProctologicaEdit, meta: { title: "Nuova Visita Proctologica" } },
  
  { name: "GastroCartellaInfermieristicaList", path: "/paziente/gastroenterologia/cartellainfermieristica", component: Gastro.CartellaInfermieristica.GastroCartellaInfermieristicaList, meta: { title: "Cartella Infermieristica" } },
  { name: "GastroCartellaInfermieristicaView", path: "/paziente/gastroenterologia/cartellainfermieristica/view/:id", component: Gastro.CartellaInfermieristica.GastroCartellaInfermieristicaView, meta: { title: "Cartella Infermieristica" } },
  { name: "GastroCartellaInfermieristicaEdit", path: "/paziente/gastroenterologia/cartellainfermieristica/edit/:id", component: Gastro.CartellaInfermieristica.GastroCartellaInfermieristicaEdit, meta: { title: "Nuova Cartella Infermieristica" } },

];
export default routes;
