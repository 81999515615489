<template>
    <div>
        <b-card class="sa-card" header="Data Evento" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">{{ this.$i18n.t("patient.cardiology.lblEventDate") }}</label>
                    <span class="sa-data">{{ formatDate(jsonData.dataEvento) }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Num. Progressivo</label>
                    <span class="sa-data">{{ jsonData.progressivo }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Provenienza</label>
                    <span class="sa-data">{{ jsonData.provenienza }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Lavaggio/Disinfezione</label>
                    <span class="sa-data">{{ jsonData.lavaggioDisinfezione }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Strumento</label>
                    <span class="sa-data">{{ jsonData.strumento }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="4" lg="4" xl="4">
                    <label class="sa-label-data">Indicazione</label>
                    <span class="sa-data">{{ jsonData.indicazione }}</span>
                </b-col>
            </b-row>
        </b-card>
        <b-card class="sa-card" header="" header-tag="header" footer-tag="footer" title="">
            <b-row>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Referto</label>
                    <span class="sa-data-justify">{{ jsonData.referto }}</span>
                </b-col>
                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                    <label class="sa-label-data">Conclusioni</label>
                    <span class="sa-data">{{ jsonData.conclusioni }}</span>
                </b-col>
            </b-row>
        </b-card>
        <eventi-operatori-component :operatori="jsonData.listaOperatori" :isEdit="isEdit"></eventi-operatori-component>
    </div>
</template>

<script>
import UtilityMixin from "../../../../../utility/UtilityMixin";
import axios from "axios";
import EventiOperatoriComponent from "../../../base/components/EventiOperatoriComponent.vue";
export default {
    components: { EventiOperatoriComponent },
    mixins: [UtilityMixin],
    props: {},
    data() {
        return { isEdit: false, pathResource: "/gastropancolonscopia", id: null, jsonData: {}, linkPrintData: null };
    },
    mounted() {
        let me = this;
        me.id = this.$route.params.id;
        me.loadData();
    },
    computed: {
        isFirmato() {
            let me = this;
            let isFirmato = !me.jsonData.firma;
            console.log(isFirmato, "FIRMA");
            me.$emit("updateStatoFirma", isFirmato);
            return !me.jsonData.firma;
        },
    },
    watch: {
        jsonData: {
            handler(jsonData) {
                this.$emit("update", jsonData);
            },
            deep: true,
        },
    },
    methods: {
        loadData() {
            let me = this;
            me.showModalLoading = true;
            if (me.id === "-1") {
                me.showModalLoading = false;
            } else {
                let link = process.env.VUE_APP_PATH_API + me.pathResource + "/" + me.id;
                axios
                    .get(link)
                    .then((response) => {
                        me.jsonData = response.data.data;
                        me.linkPrintData = "/pazienteeventi/print/" + me.jsonData.id;
                        me.showModalLoading = false;
                    })
                    .catch(() => {
                        me.showModalLoading = false;
                    });
            }
        },
    },
};
</script>

<style></style>
