import moment from "moment";
export default {
    /*data() {
        return {
            showModalLoading: Boolean,
            show: true,
        };
    },*/
    getLinkPatient(utente, idPaziente) {
        if (utente.gruppo) {
            if (utente.gruppo.menuGruppo) {
                if (utente.gruppo.menuGruppo.length == 1) {
                    if (utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                        return "/paziente/view/" + idPaziente;
                    } else if (utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                        return "view/" + idPaziente;
                    } else {
                        return "homepaziente";
                    }
                } else {
                    return "";
                }
            } else {
                return "";
            }
        } else {
            return "";
        }
    },
    methods: {
        formatDateTime(value) {
            if (value) {
                return moment(new Date(value)).format("DD-MM-YYYY HH:mm:ss");
            } else {
                return "---";
            }
        },
        formatDateTimeCustom(value, formato) {
            if (value) {
                return moment(new Date(value)).format(formato);
            } else {
                return null;
            }
        },
        formatDate(value) {
            if (value) {
                return moment(new Date(value)).format("DD/MM/YYYY");
            } else {
                return "---";
            }
        },
        formatTime(value) {
            if (value) {
                return moment(new Date(value)).format("HH:mm");
            } else {
                return "---";
            }
        },
        parseFormatDateTime(value, format) {
            if (value) {
                return moment(value, format).toDate();
            } else {
                return "---";
            }
        },
        verificaPermessi(utente, idSezione, permessoDaVerificare) {
            let returnValue = false;
            if (utente !== null) {
                let gruppo = utente.gruppo;
                if (gruppo) {
                    if (gruppo !== null) {
                        if (gruppo.permessi) {
                            gruppo.permessi.forEach((sezione) => {
                                if (sezione.id === idSezione) {
                                    sezione.permessi.forEach((permesso) => {
                                        if (permesso.permesso === permessoDaVerificare) {
                                            returnValue = permesso.attivo;
                                        }
                                    });
                                }
                            });
                        }
                    }
                }
            }
            return returnValue;
        },
        getLabelTraduora(value) {
            // console.log(this.$i18n);
            return this.$i18n.t(value);
        },
        traduciConValue(label, value) {
            return this.$i18n.t(label, { variable: value });
        },
        getColorByFirma(firma) {
            let colore = firma ? "sa-tab-title-success text-success" : "sa-tab-title-danger text-danger";

            return colore;
        },
        getIconByFirma(firma) {
            let icon = firma ? "check2" : "exclamation-triangle";
            return icon;
        },
        getColorByEta(eta) {
            let colore = "sa-maggiorenne-true";
            if (!this.isMaggiorenne(eta)) {
                colore = "sa-maggiorenne-false";
            } else {
                colore = "sa-maggiorenne-true";
            }
            return colore;
        },
        getLinkPatient(utente, idPaziente) {
            if (utente.gruppo) {
                if (utente.gruppo.menuGruppo) {
                    if (utente.gruppo.menuGruppo.length == 1) {
                        if (utente.gruppo.menuGruppo[0].primaryKey === "homestudiomedico") {
                            return "/paziente/view/" + idPaziente;
                        } else if (utente.gruppo.menuGruppo[0].primaryKey === "pazienti") {
                            return "view/" + idPaziente;
                        } else {
                            return "homepaziente";
                        }
                    } else {
                        return "";
                    }
                } else {
                    return "";
                }
            } else {
                return "";
            }
        },
        // calcoloEta(value) {
        //     if (value) {
        //         let today = new Date();
        //         let birthDate = new Date(value);
        //         let age = today.getFullYear() - birthDate.getFullYear();
        //         let ageMonth = today.getMonth() - birthDate.getMonth();
        //         let ageDays = today.getDate() - birthDate.getDate();
        //         if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
        //             age--;
        //             ageMonth = 12 + ageMonth;
        //         }

        //         if (ageDays < 0) {
        //             ageMonth--;
        //             ageDays = birthDate.getDate() - today.getDate();
        //         }
        //         return age + " " + this.getLabelTraduora("patient.lblYears") + " " + ageMonth + " " + this.getLabelTraduora("patient.lblMonth") + " " + ageDays + " " + this.getLabelTraduora("patient.lblDays");
        //     } else {
        //         return "---";
        //     }
        // },
        calcoloEta(value) {
            if (value) {
              let today = new Date();
              let birthDate = new Date(value);
              let daAggiungere = this.calcolaDaAggiungere(birthDate.getMonth(), birthDate.getFullYear());
              let age = today.getFullYear() - birthDate.getFullYear();
              let ageMonth = today.getMonth() - birthDate.getMonth();
              let ageDays = today.getDate() - birthDate.getDate();
              if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
                age--;
                ageMonth = 12 + ageMonth;
              }
      
              if (ageDays < 0) {
                ageMonth--;
                //   console.log(daAggiungere);
                //   console.log(today.getDate(), " ", birthDate.getDate());
                ageDays = today.getDate() - birthDate.getDate() + daAggiungere;
              }
              return age + " " + this.getLabelTraduora("patient.lblYears") + " " + ageMonth + " " + this.getLabelTraduora("patient.lblMonth") + " " + ageDays + " " + this.getLabelTraduora("patient.lblDays");
            } else {
              return "---";
            }
          },
          calcolaDaAggiungere(mese, anno) {
            //   console.log(mese);
            let returnValue = 31;
            //   console.log(returnValue);
            if (mese == 1) {
              if ((anno % 4 == 0 && anno % 100 != 0) || anno % 400 == 0) {
                returnValue = 29;
              } else {
                returnValue = 28;
              }
            } else if (mese == 3 || mese == 5 || mese == 8 || mese == 10) {
              returnValue = 30;
            }
            return returnValue;
          },
        calcoloEtaYearOnly(dataNascita, dataRiferimento = null) {
            if (dataNascita) {
                if (dataRiferimento == null) {
                    dataRiferimento = new Date();
                }
                // let today = new Date();
                let birthDate = new Date(dataNascita);
                let age = dataRiferimento.getFullYear() - birthDate.getFullYear();
                let ageMonth = dataRiferimento.getMonth() - birthDate.getMonth();
                let ageDays = dataRiferimento.getDate() - birthDate.getDate();
                if (ageMonth < 0 || (ageMonth === 0 && dataRiferimento.getDate() < birthDate.getDate())) {
                    age--;
                    ageMonth = 12 + ageMonth;
                }

                if (ageDays < 0) {
                    ageMonth--;
                    ageDays = birthDate.getDate() - dataRiferimento.getDate();
                }
                return age;
            } else {
                return "---";
            }
        },
        isMaggiorenne(value) {
            let returnValue = false;
            if (value) {
                let today = new Date();
                let birthDate = new Date(value);
                let age = today.getFullYear() - birthDate.getFullYear();
                let ageMonth = today.getMonth() - birthDate.getMonth();
                let ageDays = today.getDate() - birthDate.getDate();
                if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                    ageMonth = 12;
                }
                if (ageDays < 0) {
                    ageMonth--;
                    ageDays = birthDate.getDate() - today.getDate();
                }
                if (age >= 18) {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        isOverAge(value, number) {
            let returnValue = false;
            if (value) {
                let today = new Date();
                let birthDate = new Date(value);
                let age = today.getFullYear() - birthDate.getFullYear();
                let ageMonth = today.getMonth() - birthDate.getMonth();
                let ageDays = today.getDate() - birthDate.getDate();
                if (ageMonth < 0 || (ageMonth === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                    ageMonth = 12;
                }
                if (ageDays < 0) {
                    ageMonth--;
                    ageDays = birthDate.getDate() - today.getDate();
                }
                if (age >= number) {
                    returnValue = true;
                }
            }
            return returnValue;
        },
        chekCondition(value, condizione) {
            let returnValue = true;
            if (value === condizione) {
              returnValue = false;
            }
            return returnValue;
        },
        checkDoubleCondition(value, firstCondition, secondCondition, logicoperator){
            let returnValue = true;
            switch (logicoperator) {
                case "AND":
                    if(this.chekCondition(value, firstCondition) && this.chekCondition(value, secondCondition)){
                        returnValue = false;
                    }
                    break;
                    case "OR":
                    if(!this.chekCondition(value, firstCondition) || !this.chekCondition(value, secondCondition)){
                        returnValue = false;
                    }
                    break;
            }
            return returnValue;
        },
    },
};
