<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Sintesi Dei problemi attuali rilevati:</label>
        <b-form-textarea v-model="items.sintesiProblemiAttualiRilevati" class="text-upper" rows="8" no-resize :disabled="!editSvamaD"></b-form-textarea>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Sulla base della documentazione agli atti e di quanto emerso dall valutazinoe multidimensionale, l'U.V.I. prende le seguenti decisioni:</label>
        <b-form-textarea v-model="items.sintesiBaseDocumentazione" class="text-upper" rows="8" no-resize :disabled="!editSvamaD"></b-form-textarea>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object[Object],
      default: function () {
        return {};
      },
    },
    editSvamaD: Boolean,
  },
};
</script>

<style></style>
