<template>
  <div>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Trasferimento letto - sedia o carrozzina </label>
        <b-form-select v-model="jsonData.trasferimentoLetto" :options="trasferimentoLettoOptions" :value="null" value-field="valore" text-field="descrizione" @input="onInputTotaleMobilita(jsonData.trasferimentoLetto, jsonData.deambulazione, jsonData.usoCarrozzina, jsonData.scale)" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-if="jsonData.trasferimentoLetto === 15">
        <label class="sa-label-data">Deambulazione </label>
        <b-form-select v-model="jsonData.deambulazione" :options="deambulazioneOptions" :value="null" value-field="valore" text-field="descrizione" @input="onInputTotaleMobilita(jsonData.trasferimentoLetto, jsonData.deambulazione, jsonData.usoCarrozzina, jsonData.scale)" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4" v-if="jsonData.trasferimentoLetto === 15">
        <label class="sa-label-data">Uso della carrozzina </label>
        <b-form-select v-model="jsonData.usoCarrozzina" :options="usoDellaCarrozzinaOptions" :value="null" value-field="valore" text-field="descrizione" @input="onInputTotaleMobilita(jsonData.trasferimentoLetto, jsonData.deambulazione, jsonData.usoCarrozzina, jsonData.scale)" :disabled="editSvamaB"></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Scale </label>
        <b-form-select v-model="jsonData.scale" :options="scaleOptions" :value="null" value-field="valore" text-field="descrizione" @input="onInputTotaleMobilita(jsonData.trasferimentoLetto, jsonData.deambulazione, jsonData.usoCarrozzina, jsonData.scale)" :disabled="editSvamaB"></b-form-select>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label>Totale valutazione mobilita'</label>
        <b-form-input v-model="jsonData.punteggioTotaleValutazioneMobilita" class="text-upper" disabled></b-form-input>
      </b-col>
    </b-row>
    <hr />
    <h4>Prevenzione - Trattamento Decubiti</h4>
    <b-row>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Rischio non elevato di lesioni da decubito</label>
        <b-form-select v-model="jsonData.rischioDecubiti" :options="rischiDecubitiOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Presenza di lesioni da decubito</label>
        <b-form-select v-model="jsonData.presenzaDecubiti" :options="presenzaDecubitiOptions" :value="null" value-field="value" text-field="text" :disabled="editSvamaB"></b-form-select>
      </b-col>
    </b-row>
    <b-row v-if="jsonData.rischioDecubiti === 10">
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Condizioni generali</label>
        <b-form-select v-model="jsonData.condizioniGenerali" :options="condizioniGeneraliOptions" :value="null" value-field="value" text-field="text" @input="onIputTotaleRischioDecubiti(jsonData.condizioniGenerali, jsonData.statoMentale, jsonData.attivita, jsonData.mobilita, jsonData.incontinenza)" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Stato Mentale</label>
        <b-form-select v-model="jsonData.statoMentale" :options="statoMentaleOptions" :value="null" value-field="value" text-field="text" @input="onIputTotaleRischioDecubiti(jsonData.condizioniGenerali, jsonData.statoMentale, jsonData.attivita, jsonData.mobilita, jsonData.incontinenza)" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="4" lg="4" xl="4">
        <label class="sa-label-data">Attivita' </label>
        <b-form-select v-model="jsonData.attivita" :options="attivitaOptions" :value="null" value-field="value" text-field="text" @input="onIputTotaleRischioDecubiti(jsonData.condizioniGenerali, jsonData.statoMentale, jsonData.attivita, jsonData.mobilita, jsonData.incontinenza)" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Mobilita' </label>
        <b-form-select v-model="jsonData.mobilita" :options="mobilitaOptions" :value="null" value-field="value" text-field="text" @input="onIputTotaleRischioDecubiti(jsonData.condizioniGenerali, jsonData.statoMentale, jsonData.attivita, jsonData.mobilita, jsonData.incontinenza)" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <label class="sa-label-data">Incontinenza </label>
        <b-form-select v-model="jsonData.incontinenza" :options="incontinenzaOptions" :value="null" value-field="value" text-field="text" @input="onIputTotaleRischioDecubiti(jsonData.condizioniGenerali, jsonData.statoMentale, jsonData.attivita, jsonData.mobilita, jsonData.incontinenza)" :disabled="editSvamaB"></b-form-select>
      </b-col>
      <b-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
        <label class="sa-label-data">Totale rischio decubiti</label>
        <b-form-input v-model="jsonData.totaleRiscioDecubiti" class="text-upper" disabled></b-form-input>
      </b-col>
    </b-row>
    <hr />
    <b-row>
      <label class="sa-label-data">Note</label>
      <b-form-textarea v-model="jsonData.note" rows="8" no-resize :disabled="editSvamaB"></b-form-textarea>
    </b-row>
  </div>
</template>
<script>
import axios from "axios";
import UtilityMixin from "../../utility/UtilityMixin";
export default {
  props: {
    editSvamaB: Boolean,
    jsonData: {
      type: Object,
      // return: {
      //     trasferimentoLetto: 0,
      //     deambulazione: 0,
      //     usoCarrozzina: 0,
      //     scale: 0,
      //     punteggioTotaleValutazioneMobilita: 0,
      //     rischioDecubiti: 0,
      //     presenzaDecubiti: 0,
      //     condizioniGenerali: 0,
      //     statoMentale: 0,
      //     attivita: 0,
      //     mobilita: 0,
      //     incontinenza: 0,
      //     totaleRiscioDecubiti: 0,
      //     note: "",
      // },
    },
  },
  mixins: [UtilityMixin],
  data() {
    return {
      linkValutazioni: "/adivalutazioni",
      trasferimentoLettoOptions: [{ valore: 0, descrizione: "-Seleziona Valore-" }],
      deambulazioneOptions: [{ valore: 0, descrizione: "-Seleziona Valore-" }],
      usoDellaCarrozzinaOptions: [{ valore: 0, descrizione: "-Seleziona Valore-" }],
      scaleOptions: [{ valore: 0, descrizione: "-Seleziona Valore-" }],
      rischiDecubitiOptions: [
        { value: 0, text: "-Seleziona Valore-" },
        { value: 0, text: "Rischio non elevato di lesioni da decubito" },
        { value: 10, text: "Rischio elevato (Exton smith ≤ 10) o arrossamento o abrrasione superficiale (gradi I-II)" },
      ],
      presenzaDecubitiOptions: [
        { value: 0, text: "-Seleziona Valore-" },
        { value: 15, text: "Presenza di una piaga (con interessamento del tessuto sottocutaneo- gradi III-IV)" },
        { value: 25, text: "Presenza di 2 o piu' piaghe (almeno 2 con interessamento del tessuto sottocutaneo- gradi I-II)" },
      ],
      condizioniGeneraliOptions: [
        { value: 0, text: "-Seleziona Valore-" },
        { value: 4, text: "(4) Buone" },
        { value: 3, text: "(3) Discrete" },
        { value: 2, text: "(2) Scadenti" },
        { value: 1, text: "(1) Pessime" },
      ],
      statoMentaleOptions: [
        { value: 0, text: "-Seleziona Valore-" },
        { value: 4, text: "(4) Lucido" },
        { value: 3, text: "(3) Confuso" },
        { value: 2, text: "(2) Apatico" },
        { value: 1, text: "(1) Stuporoso" },
      ],
      attivitaOptions: [
        { value: 0, text: "-Seleziona Valore-" },
        { value: 4, text: "(4) Normale" },
        { value: 3, text: "(3) Cammina con aiuto" },
        { value: 2, text: "(2) Costretto in sedia" },
        { value: 1, text: "(1) Costretto a letto" },
      ],
      mobilitaOptions: [
        { value: 0, text: "-Seleziona Valore-" },
        { value: 4, text: "(4) Normale" },
        { value: 3, text: "(3) Leggermente limitata" },
        { value: 2, text: "(2) Costretto in Molto limitata" },
        { value: 1, text: "(1) Immobile" },
      ],
      incontinenzaOptions: [
        { value: 0, text: "-Seleziona Valore-" },
        { value: 4, text: "(4) No" },
        { value: 3, text: "(3) Occasionale" },
        { value: 2, text: "(2) Urina abituale" },
        { value: 1, text: "(1) Incontinenza doppia" },
      ],
    };
  },
  mounted() {
    let me = this;
    me.loadValutazioniTrasferimentoLetto();
    me.loadValutazioniDeambulazione();
    me.loadValutazioniUsoDellaCarrozzina();
    me.loadValutazioniScale();
  },
  watch: {
    "jsonData.trasferimentoLetto": function (value) {
      let me = this;
      if (value === null) {
        me.jsonData.deambulazione = null;
        me.jsonData.usoCarrozzina = null;
        me.calcolaTotaleMobilita(value, me.jsonData.deambulazione, me.jsonData.usoCarrozzina, me.jsonData.scale);
      }
    },
    "jsonData.rischioDecubiti": function (value) {
      let me = this;
      if (value === null) {
        me.jsonData.condizioniGenerali = null;
        me.jsonData.statoMentale = null;
        me.jsonData.attivita = null;
        me.jsonData.mobilita = null;
        me.jsonData.incontinenza = null;
        me.calcolaTotaleRischioDecubiti(me.jsonData.condizioniGenerali, me.jsonData.statoMentale, me.jsonData.attivita, me.jsonData.mobilita, me.jsonData.incontinenza);
      }
    },
    utente() {
      let me = this;
      me.impostaPermessi();
    },
  },
  methods: {
    onInputTotaleMobilita(trasferimentoLetto, deambulazione, usoCarrozzina, scale) {
      let me = this;
      me.calcolaTotaleMobilita(trasferimentoLetto, deambulazione, usoCarrozzina, scale);
    },
    calcolaTotaleMobilita(trasferimentoLetto, deambulazione, usoCarrozzina, scale) {
      let me = this;
      me.jsonData.punteggioTotaleValutazioneMobilita = trasferimentoLetto + deambulazione + usoCarrozzina + scale;
    },
    onIputTotaleRischioDecubiti(condizioniGenerali, statoMentale, attivita, mobilita, incontinenza) {
      let me = this;
      me.calcolaTotaleRischioDecubiti(condizioniGenerali, statoMentale, attivita, mobilita, incontinenza);
    },
    calcolaTotaleRischioDecubiti(condizioniGenerali, statoMentale, attivita, mobilita, incontinenza) {
      let me = this;
      me.jsonData.totaleRiscioDecubiti = condizioniGenerali + statoMentale + attivita + mobilita + incontinenza;
    },
    loadValutazioniTrasferimentoLetto() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=TRASFERIMENTO LETTO – SEDIA o CARROZZINA";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.trasferimentoLettoOptions.push({ valore: element.valore, descrizione: "(" + element.valore + ") " + element.descrizione });
        });
      });
    },
    loadValutazioniDeambulazione() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=DEAMBULAZIONE";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.deambulazioneOptions.push({ valore: element.valore, descrizione: "(" + element.valore + ") " + element.descrizione });
        });
      });
    },
    loadValutazioniUsoDellaCarrozzina() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=USO DELLA CARROZZINA";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.usoDellaCarrozzinaOptions.push({ valore: element.valore, descrizione: "(" + element.valore + ") " + element.descrizione });
        });
      });
    },
    loadValutazioniScale() {
      let me = this;
      let link = process.env.VUE_APP_PATH_API + me.linkValutazioni + "?page=1&forPage=50&tipoValutazione=SCALE";
      axios.get(link).then((response) => {
        response.data.data.list.forEach((element) => {
          me.scaleOptions.push({ valore: element.valore, descrizione: "(" + element.valore + ") " + element.descrizione });
        });
      });
    },
  },
};
</script>
